import { delay } from 'redux-saga';
import { put, fork, takeEvery } from 'redux-saga/effects';
import { SET_ERRORS, recoverFromErrors } from '../actions';

function* onSetErrors() {
    yield delay(3000);
    yield put(recoverFromErrors());
}

export default function* StatusSaga() {
    yield [fork(takeEvery, SET_ERRORS, onSetErrors)];
}
