import React from 'react';

export default function useEffectPostMount(cb: () => void, dependencies: any[]) {
    const postMount = React.useRef(false);
    React.useEffect(() => {
        if (postMount.current) {
            return cb();
        } else {
            postMount.current = true;
        }
    }, dependencies);
}
