import { put } from 'redux-saga/effects';
import { CompaniesRequests } from '../../../requests/index';
import { setCompanyFeatures } from '../../actions';
import apiCall from '../Effects/apiCall';

export default function* onFetchCompanyFeatures({ companyId }) {
    try {
        const features = yield apiCall(new CompaniesRequests().features, companyId);
        yield put(setCompanyFeatures(companyId, features));
    } catch (response) {
        //
    }
}
