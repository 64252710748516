import { useFormik } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl-sweepbright';
import { CopyButton } from '@/app.components/elements/Buttons/CopyButton';
import FormPanel from '../../forms/FormPanel/FormPanel';
import Input from '../../forms/Input/Input';
import PortalConfigModalBody from './PortalConfigModalBody';

const messagess = defineMessages({
    webhook_url: {
        id: 'channels.validation.webhook_url',
        defaultMessage: 'Webhook must be a valid https:// URL',
    },
    webhook_required: {
        id: 'channels.validation.webhook_required',
        defaultMessage: 'Webhook is required',
    },
});

const useValidationSchema = () => {
    const intl = useIntl();

    return Yup.object().shape({
        name: Yup.string()
            .max(40, 'channels.validation.name_too_long')
            .required('channels.validation.name_required'),
        webhook: Yup.string()
            .url(intl.formatMessage(messagess.webhook_url))
            .matches(/^https:/, intl.formatMessage(messagess.webhook_url))
            .required(intl.formatMessage(messagess.webhook_required)),
    });
};

function PublicAPI({ onSubmit, editing, initialValues, ...props }) {
    const validationSchema = useValidationSchema();

    const { values, handleSubmit, handleChange, handleBlur, touched, submitCount, errors } = useFormik({
        initialValues: {
            enabled: !!editing,
            accessToken: initialValues.credentials?.accessToken ?? '',
            accessTokenSecret: initialValues.credentials?.accessTokenSecret ?? '',
            ...initialValues,
        },
        validationSchema,
        onSubmit,
    });

    const submitted = submitCount > 0;
    const isTouched = touched || submitted;

    return (
        //@ts-ignore
        <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
            <FormPanel>
                <Input
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={isTouched}
                    error={errors.name}
                    label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                    help={
                        <FormattedMessage
                            id="channels.name.description"
                            defaultMessage="This is the name you will see in the Publish section of SweepBright."
                        />
                    }
                />
                <Input
                    name="webhook"
                    value={values.webhook}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={isTouched}
                    error={errors.webhook}
                    label={<FormattedMessage id="channels.name.publicapi.webhook" defaultMessage="Webhook" />}
                    addonAfter={<CopyButton value={values.webhook} />}
                />

                {editing ? (
                    <>
                        <Input
                            readonly
                            disabled
                            name="accessToken"
                            value={values.accessToken}
                            label={
                                <FormattedMessage id="channels.name.publicapi.client-id" defaultMessage="Client ID" />
                            }
                            addonAfter={<CopyButton value={values.accessToken} />}
                        />
                        <Input
                            readonly
                            disabled
                            name="accessTokenSecret"
                            value={values.accessTokenSecret}
                            label={
                                <FormattedMessage
                                    id="channels.name.publicapi.client-secret"
                                    defaultMessage="Client Secret"
                                />
                            }
                            addonAfter={<CopyButton value={values.accessTokenSecret} />}
                        />
                    </>
                ) : null}
            </FormPanel>
        </PortalConfigModalBody>
    );
}

PublicAPI.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
            webhook
            credentials {
                accessToken
                accessTokenSecret
            }
        }
    `,
};

export default PublicAPI;
