import { fromJS, List } from 'immutable';
import NumberFormatSanitizer from '../Sanitizers/NumberFormatSanitizer';

export default class AbstractPatchBuilder<T = any> {
    attributes: T;
    current: any;
    operations: List<any>;

    constructor(attributes: any, current: any) {
        this.attributes = attributes;
        this.current = current;
        this.operations = fromJS([]);
    }

    setAttributes(attributes: any) {
        this.attributes = attributes;
    }

    //////////////////////////////////////////////////////////////////////
    ///////////////////////////// OPERATIONS /////////////////////////////
    //////////////////////////////////////////////////////////////////////

    /**
     * Add an operation to the PATCH
     *
     */
    addOperation(op: string, _path: string, value: any = '') {
        let path = `/${_path.replace(/^\//, '')}`;

        if (typeof value === 'object' && value !== null) {
            // If we're creating a root node and we know for a fact it has already been created
            // we can safely skip the operation
            const isEmptyObject = Object.keys(value).length === 0;
            if (op === 'add' && isEmptyObject && this.currentPathExists(path)) {
                return;
            }
        }

        this.operations = this.operations.push(fromJS({ op, path, value }));
    }

    /**
     * Add an ADD operation
     */
    add(path: string, value: any) {
        this.addOperation('add', path, value);
    }

    /**
     * Add a REPLACE operation
     */
    replace(path: string, value: any) {
        this.addOperation('replace', path, value);
    }

    /**
     * Add a REMOVE operation
     *
     */
    remove(path: string) {
        this.addOperation('remove', path);
    }

    //////////////////////////////////////////////////////////////////////
    ////////////////////////////// HELPERS ///////////////////////////////
    //////////////////////////////////////////////////////////////////////

    /**
     * Create a path if it doesn't exist then set it
     */
    update(path: string, value: any, defaultValue: any = '') {
        if (typeof value !== 'undefined' && value !== null) {
            this.add(path, defaultValue);
            this.replace(path, value);
        }
    }

    updatePerKey(path: string, value: { [key: string]: string }) {
        if (typeof value !== 'object') {
            return;
        }

        Object.keys(value).forEach(key => {
            const currentValue = value[key];
            const currentPath = `${path}/${key}`;

            if (typeof currentValue !== 'undefined') {
                this.add(currentPath, '');
                this.replace(currentPath, currentValue);
            }
        });
    }

    /**
     * Update a number
     */
    updateNumber(path: string, value: number | string) {
        this.update(path, Number(value), '');
    }

    updateFormattedNumber(path: string, value: string | number) {
        this.updateNumber(path, NumberFormatSanitizer.sanitizeFromAccountingSettings(value));
    }

    updateCondition(path: string, value: any) {
        this.update(path, value, 'good');
    }

    updateOrRemove(path: string, value: any) {
        if (value || value === 0) {
            this.update(path, value);
        } else {
            this.remove(path);
        }
    }

    /**
     * Rebuild an enum array form an object of booleans
     * eg. {foo: true, bar: false) => ['foo']
     *
     */
    addEnumFromObjects(path: string, allowed: string[]) {
        const values: string[] = [];
        if (this.attributes[path]) {
            allowed.forEach(allowedValue => {
                if (this.attributes[path][allowedValue] && !values.includes(allowedValue)) {
                    values.push(allowedValue);
                }
            });
        }

        this.update(path, values, []);
    }

    currentPathExists(path: string): boolean {
        return fromJS(this.current || {}).hasIn(path.substr(1).split('/'));
    }
}
