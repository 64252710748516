import React from 'react';

export default function NumberedStep({
    title,
    children,
    step,
    last,
}: {
    title: string;
    children: React.ReactNode;
    step: number;
    last?: boolean;
}) {
    return (
        <div className="flex">
            <div className="w-16">
                <div className="rounded-full w-10 h-10 text-white bg-primary text-lg flex items-center justify-center">
                    {step}
                </div>
            </div>
            <div className={`${last ? 'border-b-0' : 'border-b'} border-gray-300 w-full pt-2`}>
                <h6 className="text-xl m-0">{title}</h6>
                <div className="py-2">{children}</div>
            </div>
        </div>
    );
}
