export const ADD_CONTACT = 'contacts/ADD_CONTACT';
export const ASSIGN_VENDOR_ESTATE = 'contacts/ASSIGN_VENDOR_ESTATE';
export const FETCH_CONTACTS = 'contacts/FETCH_CONTACTS';
export const FETCH_VENDOR_ESTATES = 'contacts/FETCH_VENDOR_ESTATES';
export const MERGE_CONTACTS = 'contacts/MERGE_CONTACTS';
export const REGISTER_INTERACTION = 'contacts/REGISTER_INTERACTION';
export const RESET_CONTACTS = 'contacts/RESET_CONTACTS';
export const SET_CONTACT = 'contacts/SET_CONTACT';
export const SET_CONTACT_INTERACTIONS = 'contacts/SET_CONTACT_INTERACTIONS';
export const SET_CONTACTS = 'contacts/SET_CONTACTS';
export const SET_LOCATION_PREFERENCES = 'contacts/SET_LOCATION_PREFERENCES';
export const SET_VENDOR_ESTATES = 'contacts/SET_VENDOR_ESTATES';
export const UPDATE_CONTACT = 'contacts/UPDATE_CONTACT';

// Interactions
export const FETCH_CONTACT_INTERACTIONS_FOR_PROPERTY = 'contacts/FETCH_CONTACT_INTERACTIONS_FOR_PROPERTY';
export const SET_CONTACT_INTERACTIONS_FOR_PROPERTY = 'contacts/SET_CONTACT_INTERACTIONS_FOR_PROPERTY';

// Interests
export const FETCH_CONTACT_INTERESTS = 'contacts/FETCH_CONTACT_INTERESTS';
export const RESET_CONTACT_INTERACTIONS = 'contacts/RESET_CONTACT_INTERACTIONS';

// Contacts
//////////////////////////////////////////////////////////////////////

export function resetContacts() {
    return { type: RESET_CONTACTS } as const;
}

export function setContacts(contacts: { data: any[] }) {
    return { type: SET_CONTACTS, contacts } as const;
}

export function mergeContacts(contacts: { data: any[] }) {
    return { type: MERGE_CONTACTS, contacts } as const;
}

// Contact
//////////////////////////////////////////////////////////////////////

export function addContact(contact: {}) {
    return { type: ADD_CONTACT, contact } as const;
}

export function updateContact(contactId: string, attributes: {}) {
    return { type: UPDATE_CONTACT, contactId, attributes } as const;
}

export function setContact(contact: Record<string, any>) {
    return { type: SET_CONTACT, contact } as const;
}

// Contact actions
//////////////////////////////////////////////////////////////////////

export function fetchVendorEstates(contactId: string, attributes: {}) {
    return { type: FETCH_VENDOR_ESTATES, contactId, attributes };
}

export function setVendorEstates(contactId: string, estates: object[]) {
    return { type: SET_VENDOR_ESTATES, contactId, estates } as const;
}

export function setLocationPreferences(contactId: string, active: boolean) {
    return { type: SET_LOCATION_PREFERENCES, contactId, active } as const;
}

export function setContactInteractions(contactId: string, interactions: unknown, interests: 'active' | 'inactive') {
    return {
        type: SET_CONTACT_INTERACTIONS,
        contactId,
        interactions,
        interests,
    } as const;
}

export function registerInteraction(payload: { attributes: any; contactId: string; propertyId: string }) {
    return { type: REGISTER_INTERACTION, ...payload };
}

export function setContactInteractionsForProperty(contactId: string, propertyId: string, interactions: any) {
    return {
        type: SET_CONTACT_INTERACTIONS_FOR_PROPERTY,
        contactId,
        propertyId,
        interactions,
    };
}

export type ContactsAction = any;
