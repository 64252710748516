import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ContactCardFragment } from '@/graphql/fragments/contactCard';

export function useEstateBuyers(estateId: string, skip?: boolean) {
    const { data, loading } = useQuery(
        gql`
            query GetEstateBuyers($estateId: ID!) {
                estate(id: $estateId) {
                    id
                    buyers {
                        nodes {
                            ...ContactCardFragment
                        }
                    }
                }
            }
            ${ContactCardFragment}
        `,
        {
            variables: { estateId },
            skip: !estateId || skip,
        },
    );

    const [assignBuyersMutation] = useMutation(gql`
        mutation AssignBuyersToEstates($input: AssignBuyersToEstateInput!) {
            assignBuyersToEstate(input: $input) {
                estate {
                    id
                    buyers {
                        nodes {
                            ...ContactCardFragment
                        }
                    }
                }
            }
        }
        ${ContactCardFragment}
    `);

    const [unassignBuyersMutation] = useMutation(gql`
        mutation UnssignBuyersToEstates($input: UnassignBuyersToEstateInput!) {
            unassignBuyersToEstate(input: $input) {
                estate {
                    id
                    buyers {
                        nodes {
                            ...ContactCardFragment
                        }
                    }
                }
            }
        }
        ${ContactCardFragment}
    `);

    const assignBuyers = React.useCallback(
        (buyerIds: string[]) => {
            return assignBuyersMutation({
                variables: {
                    input: {
                        estateId,
                        buyerIds,
                    },
                },
            });
        },
        [estateId],
    );

    const unassignBuyers = React.useCallback(
        (buyerIds: string[]) => {
            return unassignBuyersMutation({
                variables: {
                    input: {
                        estateId,
                        buyerIds,
                    },
                },
            });
        },
        [estateId],
    );

    const buyers = data?.estate?.buyers?.nodes ?? [];

    return { buyers, loading, assignBuyers, unassignBuyers };
}
