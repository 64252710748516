import { createSelector } from 'reselect';
import { fromJS, Map } from 'immutable';
import { getPropertyFormProps } from './PropertiesSelectors';
import { getPagination } from './StatusSelectors';
import { getVendors } from './ContactsSelectors';

const isInitialized = form => {
    if (!Map.isMap(form)) {
        return false;
    }

    return form.get('_initialized');
};

export const getForms = state => state.form;

export const getFormKey = prefix =>
    createSelector(getForms, (forms = []) => {
        const initializedForms = fromJS(forms).filter(form => {
            if (isInitialized(form)) {
                return true;
            }

            return form.toList().filter(isInitialized).size > 0;
        });

        return initializedForms.findKey((form, formKey) => formKey.includes(`${prefix}/`));
    });

export const getSettingsValues = createSelector(
    getVendors,
    getPropertyFormProps,
    getPagination('vendors'),
    (vendors, formProps, pagination) => ({ vendors, ...formProps, pagination }),
);
