import React from 'react';
import { fromJS } from 'immutable';
import { useQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { formatNegotiatorsList } from '@/app.utils/services/Helpers/negotiators';
import useOffice from '@/app.hooks/useOffice';
import useUser from '@/app.hooks/useUser';
import { GET_OFFICE_NEGOTIATORS_QUERY } from '@/graphql/queries/office/getNegotiators';
import { User } from '@/graphql/generated/types';

export type NegotiatorOption = {
    value: string;
    label: string;
    isCurrentUser: boolean;
    raw: User;
};

export function useOfficeNegotiators(officeId?: string, { query = '' }: { query?: string } = {}) {
    const currentOffice = useOffice();
    officeId = officeId || currentOffice.get('id');
    const user = useUser();
    const intl = useIntl();

    const { data, loading, error } = useQuery(GET_OFFICE_NEGOTIATORS_QUERY, {
        variables: {
            id: officeId,
            query,
        },
        skip: !officeId,
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    });

    const formattedNegotiators: NegotiatorOption[] = React.useMemo(
        () => formatNegotiatorsList({ negotiators: fromJS(data?.office.negotiators ?? []), user, intl }).toJS(),
        [data],
    );

    return { negotiators: formattedNegotiators, loading, hasMorePages: false, error };
}

const OfficeNegotiators: React.FunctionComponent<{
    officeId?: string;
}> = ({ children, ...props }) => {
    const currentOffice = useOffice();
    const { officeId = currentOffice.get('id') } = props;

    const { negotiators: formattedNegotiators, loading, hasMorePages } = useOfficeNegotiators(officeId);

    if (typeof children === 'function') {
        return children(formattedNegotiators, loading, hasMorePages);
    }

    return children;
};

export function withOfficeNegotiators(officeId?: string) {
    return WrappedComponent => {
        function WithOfficeNegotiators(props) {
            const { negotiators, loading } = useOfficeNegotiators(officeId);

            return <WrappedComponent negotiators={negotiators} loading={loading} {...props} />;
        }

        return WithOfficeNegotiators;
    };
}

export default OfficeNegotiators;
