import React from 'react';
import toPhoneNumber from '@/app.redux/reducers/Helpers/toPhoneNumber';

type Props = {
    value?: Maybe<string>;
};

const Phone: React.FC<Props> = props => {
    const { value } = props;

    return <a href={`tel:${value}`}>{toPhoneNumber(value || '')}</a>;
};

export default Phone;
