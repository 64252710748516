/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { injectIntl } from 'react-intl-sweepbright';
import wrapWithPromise from '@/app.utils/services/wrapWithPromise';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { LOGIN } from '../../../app.routing/routes';

const LoginWithCode: React.FunctionComponent<{
    validateCode: () => void;
} & WithRouterProps> = ({ params, router, validateCode }) => {
    const [code] = params.code?.split('?') || [];

    React.useEffect(() => {
        if (code) {
            wrapWithPromise(validateCode)({ code }).catch((formErrors: Record<string, string>) => {
                getBugsnagClient().notify(new Error('failed to authenticate with code ' + code), {
                    severity: 'info',
                    formErrors,
                });
                // login failed, invalid code or some other reason
                router.replace(LOGIN);
            });
        } else {
            // if no code is provided
            router.replace(LOGIN);
        }
    }, [code]);

    return (
        <div className="h-full flex flex-column justify-center items-center">
            <SweepbrightLoader />
        </div>
    );
};

export default withRouter(injectIntl(LoginWithCode));

function SweepbrightLoader() {
    return <img src={require('@/../../resources/assets/img/loading.gif')} style={{ width: '32px', height: 'auto' }} />;
}
