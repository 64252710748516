import { createReducerWithSanitizer } from 'redux-reducer-factory';
import { fromJS, OrderedSet } from 'immutable';
import { SET_USER_VIEWS, SET_USER } from '../../actions';
import sanitizeOrderedSet from '../Helpers/sanitizeOrderedSet';

function sanitizer(entity) {
    return fromJS(entity).update('views', views => views || OrderedSet());
}

export default createReducerWithSanitizer({}, sanitizer, {
    [SET_USER]: (user, action, sanitize) => sanitize(action.user).set('views', user.get('views')),
    [SET_USER_VIEWS]: (user, { views }) => user.set('views', sanitizeOrderedSet(views)),
});
