import { Location } from 'history';
import React from 'react';
import { InjectedRouter, PlainRoute, withRouter, WithRouterProps } from 'react-router';
import { Params } from 'react-router/lib/Router';

declare module 'react-router' {
    interface InjectedRouter<P = Params, Q = any> {
        location: Location<Q>;
        params: P;
        routes: PlainRoute[];
    }

    interface WithRouterProps<P = Params, Q = any> {
        location: Location<Q>;
        params: P;
        router: InjectedRouter<Params, any>;
        routes: PlainRoute[];
    }
}

const RouterContext = React.createContext<InjectedRouter | null>(null);

const RouterProviderInner: React.FunctionComponent<WithRouterProps> = ({ router, children }) => {
    return <RouterContext.Provider value={router}>{children}</RouterContext.Provider>;
};

export const RouterProvider = withRouter(RouterProviderInner);

export default function useRouter() {
    const router = React.useContext(RouterContext);

    return router;
}
