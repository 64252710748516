import React from 'react';
import { IndexRoute, Redirect, Route } from 'react-router';
import * as Routes from '@/app.routing/routes';
import { CompanyAdmin } from '@/app.routing/Middlewares';
import websiteRoutes from '@/app.routing/routes/websiteRoutes';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import PortalsLayout from '@/app.domains/channels/Portals';

import * as Pages from '../allPagesComponents';

const isAgencyWebsiteSectionEnabled = getFeature('admin-channels-website.agencyWebsiteConfigurationSection.enabled');

const portalRoutes = (
    <Route path="portals" component={PortalsLayout}>
        <IndexRoute component={Pages.ChannelsPortals} />
        <Route path="create" component={Pages.ChannelsPortalsCreate} />
        <Route path=":portal" component={Pages.ChannelsPortalsPortal} />
    </Route>
);

const channelsRoutes = store => {
    const childRoutes = [websiteRoutes, portalRoutes];

    return (
        <Route
            path={Routes.CHANNELS}
            component={CompanyAdmin(Pages.Channels)}
            childRoutes={[
                {
                    getIndexRoute(location, cb) {
                        const agencyWebsiteSectionEnabled = isAgencyWebsiteSectionEnabled(store.getState());

                        cb(null, agencyWebsiteSectionEnabled ? <Redirect to="website" /> : <Redirect to="portals" />);
                    },
                    getChildRoutes(context, cb) {
                        const agencyWebsiteSectionEnabled = isAgencyWebsiteSectionEnabled(store.getState());

                        cb(null, childRoutes.slice(agencyWebsiteSectionEnabled ? 0 : 1));
                    },
                },
            ]}
        />
    );
};

export default channelsRoutes;
