export const PROPERTY_IMAGES_SELECT_CLICKED = 'Property Images Select Clicked';
export const PROPERTY_IMAGES_SELECT_CANCEL = 'Property Images Select Cancel';

export const PROPERTY_BACK_BTN_CLICKED = 'Property Back Button Clicked';

// top level sections
export const PROPERTY_ACTIVITY_SECTION_CLICKED = 'Property Activity Section Clicked';
export const PROPERTY_OFFERS_SECTION_CLICKED = 'Property Offers Section Clicked';
export const PROPERTY_SCHEDULE_SECTION_CLICKED = 'Property Schedule Section Clicked';
export const PROPERTY_DETAILS_SECTION_CLICKED = 'Property Details Section Clicked';
export const PROPERTY_PUBLISH_SECTION_CLICKED = 'Property Publish Section Clicked';
export const PROPERTY_VISIT_SECTION_CLICKED = 'Property Visit Section Clicked';
export const PROPERTY_MATCH_SECTION_CLICKED = 'Property Match Section Clicked';
export const PROPERTY_UNITS_SECTION_CLICKED = 'Property Units Section Clicked';

// details subsections
export const PROPERTY_DETAILS_LOCATION_SIDE_MENU_CLICKED = 'Property Details Location Subsection Clicked';
export const PROPERTY_DETAILS_STRUCTURE_SIDE_MENU_CLICKED = 'Property Details Structure Subsection Clicked';
export const PROPERTY_DETAILS_PRICE_SIDE_MENU_CLICKED = 'Property Details Price Subsection Clicked';
export const PROPERTY_DETAILS_DESCRIPTION_SIDE_MENU_CLICKED = 'Property Details Description Subsection Clicked';
export const PROPERTY_DETAILS_IMAGES_SIDE_MENU_CLICKED = 'Property Details Images Subsection Clicked';
export const PROPERTY_DETAILS_PLANS_SIDE_MENU_CLICKED = 'Property Details Plans Subsection Clicked';
export const PROPERTY_DETAILS_FEATURES_SIDE_MENU_CLICKED = 'Property Details Features Subsection Clicked';
export const PROPERTY_DETAILS_LEGAL_SIDE_MENU_CLICKED = 'Property Details Legal Subsection Clicked';
export const PROPERTY_DETAILS_SETTINGS_SIDE_MENU_CLICKED = 'Property Details Settings Subsection Clicked';

// details subsection save

export const PROPERTY_DETAILS_LOCATION_SAVE_BTN_CLICKED = 'Property Details Location Subsection Save Button Clicked';
export const PROPERTY_DETAILS_STRUCTURE_SAVE_BTN_CLICKED = 'Property Details Structure Subsection Save Button Clicked';
export const PROPERTY_DETAILS_PRICE_SAVE_BTN_CLICKED = 'Property Details Price Subsection Save Button Clicked';
export const PROPERTY_DETAILS_DESCRIPTION_SAVE_BTN_CLICKED =
    'Property Details Description Subsection Save Button Clicked';
export const PROPERTY_DETAILS_IMAGES_SAVE_BTN_CLICKED = 'Property Details Images Subsection Save Button Clicked';
export const PROPERTY_DETAILS_PLANS_SAVE_BTN_CLICKED = 'Property Details Plans Subsection Save Button Clicked';
export const PROPERTY_DETAILS_FEATURES_SAVE_BTN_CLICKED = 'Property Details Features Subsection Save Button Clicked';
export const PROPERTY_DETAILS_DOCUMENTS_SAVE_BTN_CLICKED = 'Property Details Documents Save Button Clicked';
export const PROPERTY_DETAILS_LEGAL_SAVE_BTN_CLICKED = 'Property Details Legal Subsection Save Button Clicked';
export const PROPERTY_DETAILS_SETTINGS_SAVE_BTN_CLICKED = 'Property Details Settings Subsection Save Button Clicked';
export const PROPERTY_DETAILS_UNITS_DUPLICATE_BTN_CLICKED =
    'Property Details Units Subsection Duplicate Button Clicked';
