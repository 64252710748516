import PropTypes from 'prop-types';
import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import Checkbox from '@sweepbright/uikit/build/esm/checkbox';
import FormPanel from './FormPanel/FormPanel';

function getBtnProps(checked, confirmation) {
    if (confirmation && !checked) {
        return {
            disabled: true,
        };
    }

    return {};
}

const FormAction = ({ title, variant, label, onClick, confirmation }) => {
    const [checked, setChecked] = React.useState(false);
    const btnProps = getBtnProps(checked, confirmation);

    return (
        <FormPanel title={title} centered>
            {confirmation && (
                <div className="text-left">
                    <Checkbox
                        checked={checked}
                        onChange={event => setChecked(event.target.checked)}
                        data-testid="form_action.confirm"
                    >
                        {confirmation}
                    </Checkbox>
                </div>
            )}
            <Button
                {...btnProps}
                variant={variant}
                block
                onClick={evt => {
                    setChecked(false);
                    onClick(evt);
                }}
            >
                {label}
            </Button>
        </FormPanel>
    );
};

FormAction.propTypes = {
    confirmation: PropTypes.object,
    label: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    bsStyle: PropTypes.string,
    variant: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default FormAction;
