import React from 'react';
import { css } from '@emotion/core';
import { useTheme } from '@/app.utils/theming/ThemeContext';
import Icon, { ICON_SIZE_SMALL, IconName } from '@/app.components/icons/Icon';

export const SideMenuIcon = ({ icon, checked }: { checked: boolean; icon: IconName }) => {
    const theme = useTheme();
    const cssValue = React.useMemo(() => {
        return css`
            color: ${theme.colors.primary};
        `;
    }, [theme.colors.primary]);

    return (
        <span css={cssValue} className="c-side-menu-icon">
            <Icon size={ICON_SIZE_SMALL} icon={icon} />
            {checked && <Icon icon="circle-check" />}
        </span>
    );
};
