import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import { getFeatures } from '../../../../app.redux/selectors/FeaturesSelector';
import { INTEGRATION } from '../../../../app.routing/routes';
import { findCategory } from '../../../../app.data/Integrations';
import SideMenu from '../../../../app.components/navigations/SideMenu/SideMenu';
import SideMenuThumbnail from '../../../../app.components/navigations/SideMenu/SideMenuThumbnail';

const IntegrationCategoryLayout = ({ children, route, features }, { router }) => {
    const category = findCategory(route.path);

    return (
        <LayoutContainer flex>
            <LayoutColumn size="small">
                <SideMenu color="gray-lighter">
                    {category
                        .get('integrations')
                        .filter(integration => features.get(`integrations-${integration.get('id')}`))
                        .map(integration => {
                            const key = integration.get('id');
                            const link = INTEGRATION(category.get('id'), key);

                            return (
                                <SideMenuThumbnail
                                    link={link}
                                    key={key}
                                    active={router.isActive(link, false)}
                                    type="integration"
                                    image={integration.get('image')}
                                >
                                    <FormattedMessage id={`integrations.${integration.get('id')}.name`} />
                                    <br />
                                    {integration.get('via_zapier') && (
                                        <small className="text-muted">
                                            <FormattedMessage
                                                id="integrations.via_zapier"
                                                defaultMessage="Via Zapier"
                                            />
                                        </small>
                                    )}
                                </SideMenuThumbnail>
                            );
                        })}
                </SideMenu>
            </LayoutColumn>
            <LayoutColumn>{children}</LayoutColumn>
        </LayoutContainer>
    );
};

IntegrationCategoryLayout.propTypes = {
    children: PropTypes.node,
    // @ts-ignore
    features: PropTypes.instanceOf(Map),
    route: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }),
};

IntegrationCategoryLayout.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    createStructuredSelector({
        features: getFeatures,
    }),
)(IntegrationCategoryLayout);
