import React from 'react';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import OfficeAside from '../asides/OfficeAside';

function MainNavigation({ children, location }) {
    return (
        <div className="bc-scrollable-container-content-layout bc-scrollable-container-content-layout-fullscreen">
            <LayoutContainer>
                <OfficeAside location={location.pathname} />
                {children}
            </LayoutContainer>
        </div>
    );
}

export default MainNavigation;
