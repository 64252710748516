import gql from 'graphql-tag';

export const GET_ACTIVITY_QUERY = gql`
    query GetActivity($id: ID!) {
        activity(id: $id) {
            __typename
            id
            details {
                id
                __typename
                ... on FeedbackActivityDetails {
                    externalComment
                    internalComment
                    type
                }
                ... on MessageActivityDetails {
                    message
                }
            }
            action
        }
    }
`;
