import gql from 'graphql-tag';

export const STATUSES_QUERY = gql`
    query GetStatuses {
        letStatuses: statuses(negotiation: LET) {
            id
            order
        }
        saleStatuses: statuses(negotiation: SALE) {
            id
            order
        }
    }
`;

export const STATUSES_FOR_NEGOTIATION_QUERY = gql`
    query GetStatusesForNegotiation($negotiation: Negotiation) {
        statuses: statuses(negotiation: $negotiation) {
            id
            order
        }
    }
`;
