import { useCallback, useState } from 'react';
import { MutationTuple, useQuery, useMutation } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import { GET_LEGAL_ENTITIES } from '@/graphql/queries/office/getLegalEntities';
import {
    AddLegalEntityMutation,
    AddLegalEntityMutationVariables,
    DeleteLegalEntityMutation,
    DeleteLegalEntityMutationVariables,
    GetLegalEntitiesQuery,
    GetLegalEntitiesQueryVariables,
    UpdateLegalEntityMutation,
    UpdateLegalEntityMutationVariables,
} from '@/graphql/generated/types';
import { updateConnection } from '@/graphql/utils';
import { ADD_LEGAL_ENTITY } from '@/graphql/mutations/office/addLegalEntity';
import { UPDATE_LEGAL_ENTITY } from '@/graphql/mutations/office/updateLegalEntity';
import { DELETE_LEGAL_ENTITY } from '@/graphql/mutations/office/deleteLegalEntity';
import { ASSIGN_LEGAL_ENTITY } from '@/graphql/mutations/properties/assignLegalEntity';
import { UNASSIGN_LEGAL_ENTITY } from '@/graphql/mutations/properties/unassignLegalEntity';

const useLegalEntities = (officeId?: string) => {
    const [fetchingMore, setFetchingMore] = useState(false);

    const {
        data,
        loading,
        refetch,
        fetchMore,
    }: QueryResult<GetLegalEntitiesQuery, GetLegalEntitiesQueryVariables> = useQuery(GET_LEGAL_ENTITIES, {
        variables: {
            officeId: officeId || '',
        },
        skip: !officeId,
        notifyOnNetworkStatusChange: true,
    });

    const { hasNextPage, currentPage } = data?.office?.legalEntities.pageInfo ?? {
        hasMorePages: false,
        currentPage: 1,
    };

    const memoizedFetchMore = useCallback(
        async ({ page = currentPage + 1 } = {}) => {
            if (fetchingMore || !hasNextPage) return;
            setFetchingMore(true);
            let result;
            try {
                result = await fetchMore({
                    variables: {
                        officeId,
                        page: page,
                    },
                    updateQuery: updateConnection('office.legalEntities'),
                });
            } finally {
                setFetchingMore(false);
            }

            return result;
        },
        [officeId, fetchMore, fetchingMore, hasNextPage, currentPage],
    );

    const [addLegalEntity]: MutationTuple<AddLegalEntityMutation, AddLegalEntityMutationVariables> = useMutation(
        ADD_LEGAL_ENTITY,
        {
            update: () => {},
        },
    );

    const [updateLegalEntity]: MutationTuple<
        UpdateLegalEntityMutation,
        UpdateLegalEntityMutationVariables
    > = useMutation(UPDATE_LEGAL_ENTITY, {
        update: () => {},
    });

    const [deleteLegalEntity]: MutationTuple<
        DeleteLegalEntityMutation,
        DeleteLegalEntityMutationVariables
    > = useMutation(DELETE_LEGAL_ENTITY, {
        update: () => {},
    });

    const [assignLegalEntity]: MutationTuple<any, any> = useMutation(ASSIGN_LEGAL_ENTITY, {
        update: () => {},
    });

    const [unassignLegalEntity]: MutationTuple<any, any> = useMutation(UNASSIGN_LEGAL_ENTITY, {
        update: () => {},
    });

    return {
        data,
        loading,
        refetch,
        addLegalEntity,
        updateLegalEntity,
        deleteLegalEntity,
        assignLegalEntity,
        unassignLegalEntity,
        fetchMore: memoizedFetchMore,
    };
};

export default useLegalEntities;
