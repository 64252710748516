import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import Plan from '@/app.domains/subscription/Plan';
import { getCompanySettings, getStatus, getCompanyInvoiceFields } from '../../app.redux/selectors';
import { updateCompanySettings } from '../../app.redux/actions';

const mapStateToProps = createStructuredSelector({
    initialValues: getCompanySettings,
    status: getStatus,
    fields: getCompanyInvoiceFields,
});

const mapActionsToProps = {
    updateCompanySettings,
};

export default reduxForm(
    {
        form: 'company/settings',
        fields: [],
    },
    mapStateToProps,
    mapActionsToProps,
)(Plan);
