import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Icon } from '@/app.components';
import { MuliDragItem } from '../types';

type Props = {
    index: number;
    label: string;
    el: MuliDragItem[];
};

const MultiDragListBlock: React.FC<Props> = props => {
    const { el, label, index } = props;

    const grid = 8;

    return (
        <Droppable key={index} droppableId={`${index}`}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={{
                        width: 310,
                        padding: grid,
                    }}
                    className={`c-multi-drag-list-block${snapshot.isDraggingOver ? ' dragging' : ''}`}
                    {...provided.droppableProps}
                >
                    <span className="c-multi-drag-list-block__label">{label}</span>

                    {el.map((item, index) => (
                        <Draggable key={item.value} draggableId={item.value} index={index}>
                            {(provided, _snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                        padding: grid * 2,
                                        margin: `0 0 ${grid}px 0`,
                                        ...provided.draggableProps.style,
                                    }}
                                    className="c-multi-drag-list-block-item"
                                >
                                    <div className="c-multi-drag-list-block-item__content">
                                        <Icon size={24} icon="drag" />
                                        <span className="c-multi-drag-list-block-item__label">{item.label}</span>
                                    </div>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default MultiDragListBlock;
