import { useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import useOffice from '@/app.hooks/useOffice';
import { GetLegalEntityQuery, GetLegalEntityQueryVariables } from '@/graphql/generated/types';
import { GET_LEGAL_ENTITY } from '@/graphql/queries/office/getLegalEntity';

const useLegalEntity = (id?: string) => {
    const office = useOffice();
    const officeId = office.get('id');

    const {
        data,
        loading,
        refetch,
        fetchMore,
    }: QueryResult<GetLegalEntityQuery, GetLegalEntityQueryVariables> = useQuery(GET_LEGAL_ENTITY, {
        variables: {
            id: id || '',
            officeId: officeId || '',
        },
        skip: !id || !officeId,
        notifyOnNetworkStatusChange: true,
    });

    return { data, loading, refetch, fetchMore };
};

export default useLegalEntity;
