// @ts-nocheck
import React from 'react';
import Avatar from '@sweepbright/uikit/build/esm/avatar';
import { IntercomAPI } from 'react-intercom';
import { useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import { logoutAttempt } from '@/app.redux/actions/AuthActions';
import * as routes from '@/app.routing/routes';
import useCompany from '@/app.hooks/useCompany';
import helpLinks from '@/app.data/helpLinks';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import useUser from '@/app.hooks/useUser';
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown';
import './UserProfileMenu.scss';

function UserProfileMenu() {
    const user = useUser();
    const dispatch = useDispatch();

    if (!user || !user.get('id')) {
        return null;
    }

    const handleClickSupport = () => {
        IntercomAPI('show');
    };

    function handleLogout() {
        dispatch(logoutAttempt());
    }

    const supportLink = user?.toJS()?.company?.data?.custom_support_url;

    return (
        <NavbarDropdown
            title={<Profile user={user} />}
            toggleClassName="c-user-menu__toggle"
            testId="navbar.user_profile"
        >
            <LinkContainer to={routes.PROFILE} active={false}>
                <Dropdown.MenuItem>
                    <FormattedMessage id="navigation.links.profile" defaultMessage="My Profile" />
                </Dropdown.MenuItem>
            </LinkContainer>
            <Dropdown.Divider />
            {supportLink && (
                <Dropdown.MenuItem
                    as="a"
                    href={supportLink}
                    rel="noopener noreferrer"
                    target={!window.Cypress ? '_blank' : undefined}
                >
                    <FormattedMessage id="navigation.help-learning" defaultMessage="Help & Learning" />
                </Dropdown.MenuItem>
            )}
            <Dropdown.MenuItem onClick={handleClickSupport}>
                <FormattedMessage id="navigation.support" defaultMessage="Support" />
            </Dropdown.MenuItem>
            <Dropdown.MenuItem as="a" href={helpLinks.privacy} target={!window.Cypress ? '_blank' : undefined}>
                <FormattedMessage id="navigation.privacy" defaultMessage="Privacy Policy" />
            </Dropdown.MenuItem>
            <Dropdown.Divider />
            <Dropdown.MenuItem onClick={handleLogout}>
                <FormattedMessage id="navigation.logout" defaultMessage="Log out" />
            </Dropdown.MenuItem>
        </NavbarDropdown>
    );
}

function Profile({ user }) {
    const company = useCompany();

    return (
        <div className="c-user-menu">
            <Avatar
                name={user.get('first_name')}
                className="c-user-menu__avatar"
                image={user.getIn(['photo', 'data', 'url'])}
            />
            <div className="c-user-menu__details">
                <span className="c-user-menu__name">{user.get('first_name')}</span>
                <span className="c-user-menu__company" data-testid="cy-company-name">
                    {company.get('name')}
                </span>
            </div>
        </div>
    );
}

export default React.memo(UserProfileMenu);
