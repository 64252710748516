import { Map, OrderedSet } from 'immutable';
import { createSelector } from 'reselect';
import { languages } from '../../app.data/Localization';
import toPhoneNumber from '../reducers/Helpers/toPhoneNumber';
import CompanyRepository from '../../app.utils/services/Repositories/CompanyRepository';
import { findById } from './CommonSelectors';
import { getFeature } from './FeaturesSelector';
import { getCurrentCompany, getCurrentMember } from './RoutingSelectors';

export const invoiceFields = [
    'invoice_company_name',
    'invoice_vat_number',
    'invoice_email',
    'invoice_zip',
    'invoice_city',
    'invoice_address',
    'invoice_county',
    'invoice_country',
    'invoice_first_name',
    'invoice_last_name',
];

export const getCompanies = state => state.companies;
export const getCompany = createSelector(findById(getCompanies, getCurrentCompany), company => company || new Map());

// Company admins
export const getCompanyAdmins = createSelector(getCompany, company => company.get('admins', new OrderedSet()));
export const getCompanyAdmin = createSelector(findById(getCompanyAdmins, getCurrentMember), member => member || Map());

// Company highlights
export const getCompanyHighlights = createSelector(getCompany, company => company.get('highlights', new OrderedSet()));

// Company features
export const getCompanyFeatures = createSelector(getCompany, company => company.get('features', new Map()));
export const getCompanyFeature = feature => createSelector(getCompanyFeatures, features => features.get(feature));
export const getDotloopIsEnabled = createSelector(
    [getCompanyFeature('dotloop'), getFeature('integrations-dotloop')],
    (enabled, feature) => enabled && feature,
);

export const getCompanyInvoiceFields = createSelector(getCompany, company => {
    let settings = company
        .getIn(['settings', 'data'], new Map())
        .remove('homepage_highlights')
        .remove('description')
        .remove('about')
        .keySeq();
    settings = settings.concat([
        'dirty',
        'invoice.first_name',
        'invoice.last_name',
        'invoice.invoice_company_name',
        'invoice.invoice_vat_number',
        'invoice.invoice_email',
        'invoice.invoice_address',
        'invoice.invoice_country',
    ]);

    return settings.toArray();
});

export const getCompanySettings = createSelector(getCompany, company => {
    let settings = company.getIn(['settings', 'data'], new Map());
    const enabledLanguages = settings.get('languages', new OrderedSet());

    settings = settings.update('contact_phone', toPhoneNumber);
    settings = settings.set('users_amount', company.get('member_allowed'));

    // Invoice details
    settings = settings.updateIn(['invoice', 'invoice_vat_number'], () => settings.get('invoice_vat_number'));
    settings = settings.updateIn(['invoice', 'invoice_address'], () => settings.get('invoice_address'));
    settings = settings.updateIn(['invoice', 'invoice_email'], () => settings.get('invoice_email'));
    settings = settings.updateIn(['invoice', 'invoice_company_name'], () => settings.get('invoice_company_name'));
    settings = settings.updateIn(
        ['invoice', 'invoice_country'],
        () => settings.get('invoice_country') || settings.get('signup_country'),
    );

    return {
        name: company.get('name'),
        photo: company.get('photo', new Map()).toJS(),
        ...settings.toJS(),
        languages: languages
            .reduce((reduction, { value }) => reduction.set(value, enabledLanguages.includes(value)), new Map())
            .toJS(),
    };
});

export const getCompanySetting = setting => createSelector(getCompanySettings, settings => settings[setting]);

export const getCompanyChannelSettings = channel =>
    createSelector([getCompanySettings, getCompany], (settings, company) => {
        let token = company.get('tokens', OrderedSet()).find(item => item.get('channel') === channel) || Map();

        token = token.set('channel', channel);

        return {
            token: token.toJS(),
        };
    });

export const getCompanySubscriptionSettings = createSelector(getCompany, company => {
    const plan = company.getIn(['plan', 'data']) || new Map();

    const membersAllowed = company.get('member_allowed');

    return {
        company_id: company.get('id'),
        payment_method: 'credit_card',
        users_amount: membersAllowed,
        interval: plan.get('interval') || 'month',
        invoice_vat_number: company.getIn(['settings', 'data', 'invoice_vat_number']),
    };
});

export const getCompanyInvoiceSettings = createSelector(getCompany, company => {
    const settings = company.getIn(['settings', 'data']);

    return invoiceFields.reduce((invoiceSettings, field) => {
        // eslint-disable-next-line no-param-reassign
        invoiceSettings[field] = settings.get(field);

        return invoiceSettings;
    }, {});
});

export const getCompanyVatNumber = createSelector(getCompany, company => {
    return company.getIn(['settings', 'data', 'invoice_vat_number'], '');
});

export const getCompanyLanguages = createSelector(getCompany, company => {
    return new CompanyRepository(company).getEnabledLanguages();
});

export const getCompanyEnterprise = createSelector(getCompany, company => {
    return new CompanyRepository(company).getEnterpriseId();
});

export const getCompanyCountryCode = createSelector(getCompany, company =>
    company.getIn(['settings', 'data', 'contact_country']),
);

export const getCompanyEmail = createSelector(getCompany, company => company.getIn(['settings', 'data', 'email']));
