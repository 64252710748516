import React from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';
import { formatNumber } from '../../utils';
import OfferModalDetailsBlock from './OfferModalDetailsBlock';
import OfferModalDetailsFee from './OfferModalDetailsFee';
import OfferModalDetailsDirection from './OfferModalDetailsDirection';

type Props = {
    propertyNegotiation: 'let' | 'sale';
};

const OfferModalDetails: React.FC<Props> = props => {
    const { propertyNegotiation } = props;

    const { values, setFieldValue } = useFormikContext<OfferCardFragmentFragment>();

    const buyerAmountPercent = formatNumber(
        values.financialDetails.buyerGrossAmount
            ? ((values.financialDetails.buyerFixedFee || 0) * 100) / (values.financialDetails.ownerNetAmount || 0) || 0
            : 0,
    );

    const ownerAmountPercent = formatNumber(
        values.financialDetails.ownerNetAmount
            ? ((values.financialDetails.ownerFixedFee || 0) * 100) / (values.financialDetails.buyerGrossAmount || 0) ||
                  0
            : 0,
    );

    const contentDetails = {
        title: <FormattedMessage id="offers.modals.details.title" defaultMessage="Offer Details" />,
        labelBuyer: (
            <FormattedMessage
                id={`offers.modals.details.buyer.${propertyNegotiation}`}
                defaultMessage="Buyer Gross Amount"
            />
        ),
        labelOwner: <FormattedMessage id="offers.modals.details.owner" defaultMessage="Owner Net Amount" />,
        info: <FormattedMessage id="offers.modals.details.info" defaultMessage="Agency Fees Included" />,
    };

    const contentAgency = {
        title: <FormattedMessage id="offers.modals.agency.title" defaultMessage="Agency Fee" />,
        labelBuyerFixed: (
            <FormattedMessage
                id={`offers.modals.agency.buyer.${propertyNegotiation}`}
                defaultMessage="Buyer Fixed Fee"
            />
        ),
        labelBuyerPercentage: (
            <FormattedMessage
                id={`offers.modals.agency.buyerPercentage.${propertyNegotiation}`}
                defaultMessage="Buyer Percentage Fee"
            />
        ),
        buyerGross: (
            <FormattedMessage
                id="offers.modals.agency.buyerGross"
                values={{ value: buyerAmountPercent }}
                defaultMessage="% of Buyer Net Amount"
            />
        ),
        labelOwnerFixed: <FormattedMessage id="offers.modals.agency.owner" defaultMessage="Owner Fixed Fee" />,
        labelOwnerPercentage: (
            <FormattedMessage id="offers.modals.agency.ownerPercentage" defaultMessage="Owner Percentage Fee" />
        ),
        ownerGross: (
            <FormattedMessage
                id="offers.modals.agency.ownerGross"
                values={{ value: ownerAmountPercent }}
                defaultMessage="% of Owner Gross Amount"
            />
        ),
    };

    return (
        <>
            <FormPanel title={contentDetails.title}>
                <OfferModalDetailsDirection
                    values={values}
                    setFieldValue={setFieldValue}
                    propertyNegotiation={propertyNegotiation}
                />

                <OfferModalDetailsFee propertyNegotiation={propertyNegotiation} />

                <OfferModalDetailsBlock
                    info={contentDetails.info}
                    nameFirst="buyerGrossAmount"
                    nameSecond="ownerNetAmount"
                    labelFirst={contentDetails.labelBuyer}
                    labelSecond={contentDetails.labelOwner}
                    disabledFirst={values.financialDetails.direction === 'OWNER_TO_BUYER'}
                    disabledSecond={values.financialDetails.direction === 'BUYER_TO_OWNER'}
                    icon={values?.financialDetails?.direction === 'BUYER_TO_OWNER' ? 'offer-more' : 'offer-less'}
                />
            </FormPanel>

            <FormPanel title={contentAgency.title}>
                <OfferModalDetailsBlock
                    afterSecond="%"
                    icon="offer-plus"
                    nameFirst="buyerFixedFee"
                    nameSecond="buyerPercentageFee"
                    info={contentAgency.buyerGross}
                    minNumberSecond={0}
                    maxNumberSecond={99}
                    labelFirst={contentAgency.labelBuyerFixed}
                    labelSecond={contentAgency.labelBuyerPercentage}
                />

                <OfferModalDetailsBlock
                    afterSecond="%"
                    icon="offer-plus"
                    nameFirst="ownerFixedFee"
                    nameSecond="ownerPercentageFee"
                    info={contentAgency.ownerGross}
                    minNumberSecond={0}
                    maxNumberSecond={99}
                    labelFirst={contentAgency.labelOwnerFixed}
                    labelSecond={contentAgency.labelOwnerPercentage}
                />
            </FormPanel>
        </>
    );
};

export default OfferModalDetails;
