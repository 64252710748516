import React from 'react';
import { Avatar } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import Link from '@/app.components/elements/Link';
import { formatPhoneNumber } from '@/app.utils/services/Helpers/formatPhoneNumber';
import { SimpleUser } from './types';

export const renderName = (_id: string, member: SimpleUser, user: any) => {
    const isCurrentUser = `${user.get('id')}` === member.id;

    const fullName = `${member.firstName || ''} ${member.lastName || ''}`;

    const imageText =
        member?.photo?.data?.url || `${`${member.firstName || '-'}`[0]}${`${member.lastName || '-'}`[0]}`.toUpperCase();

    const link = `/members/${member.id}`;

    return (
        <Link
            to={link}
            data-testid="properties.table_view.price_cell"
            className="flex items-center space-x-2  no-underline text-gray-dark"
        >
            <div className="flex items-center w-48">
                <Avatar type="sm" name={fullName.trim() ? fullName : '- -'} image={imageText} />
                <div className="ml-2 truncate" title={fullName}>
                    {isCurrentUser ? (
                        <FormattedMessage id="me" defaultMessage="Me" />
                    ) : (
                        fullName.trim() || (
                            <span className="text-gray-mid">
                                <FormattedMessage id="forms.labels.name.empty" defaultMessage="No name set" />
                            </span>
                        )
                    )}
                </div>
            </div>
        </Link>
    );
};

export const renderEmail = (_id: string, member: SimpleUser) => {
    return (
        <a className="block w-56 truncate" title={member.email} href={`mailto:${member.email}`}>
            {member.email}
        </a>
    );
};

export const renderPhone = (_id: string, member: SimpleUser) => {
    if (!member.phone) {
        return <span className="block w-56 truncate text-muted">-</span>;
    }

    return (
        <a className="block w-56 truncate" title={member.phone} href={`tel:${member.phone}`}>
            {formatPhoneNumber(member.phone)}
        </a>
    );
};

export const renderRole = (_id: string, member: SimpleUser) => {
    return <FormattedMessage id={`forms.role.roles.${member.role}`} defaultMessage={member.role} />;
};

export const ONE_BY_ONE = 'one-by-one';
export const BULK = 'bulk';
