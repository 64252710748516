import gql from 'graphql-tag';

export default gql`
    fragment timelineActivities on ActivityConnection {
        pageInfo {
            hasNextPage
            endCursor
            startCursor
        }
        nodes {
            __typename
            id
            timestamp
            action
            ... on ContactEmailedActivity {
                emailedContact {
                    id
                    firstName
                    lastName
                }
                emailedBy {
                    id
                    firstName
                    lastName
                }
                about {
                    id
                    attributes
                    isUnit
                    projectId
                }
            }
            ... on ContactCalledActivity {
                about {
                    id
                    attributes
                    isUnit
                    projectId
                }
                calledContact {
                    id
                    firstName
                    lastName
                }
                calledBy {
                    id
                    firstName
                    lastName
                }
            }
            ... on ContactMessagedActivity {
                about {
                    id
                    attributes
                    isUnit
                    projectId
                }
                messagedContact {
                    id
                    firstName
                    lastName
                }
                messagedBy {
                    id
                    firstName
                    lastName
                }
            }
            ... on VisitScheduledActivity {
                attendee {
                    id
                    firstName
                    lastName
                }
                visit {
                    id
                    attendees {
                        id
                        firstName
                        lastName
                    }
                    organizer {
                        id
                        firstName
                        lastName
                    }
                    property {
                        id
                        attributes
                        isUnit
                        projectId
                    }
                }
            }
            ... on PropertyVisitedActivity {
                visitedBy {
                    id
                    firstName
                    lastName
                }
                property {
                    id
                    attributes
                    isUnit
                    projectId
                }
                registeredBy {
                    id
                    firstName
                    lastName
                }
            }
            ... on LeadExpressedInterestActivity {
                interestedLead {
                    id
                    firstName
                    lastName
                }
                propertyInterestedIn {
                    id
                    attributes
                    isUnit
                    projectId
                }
                interestExpressedVia {
                    name
                }
            }
            ... on VendorReportEmailedActivity {
                sentTo {
                    id
                    firstName
                    lastName
                }
                sentBy {
                    id
                    firstName
                    lastName
                }
                about {
                    id
                    attributes
                }
            }
        }
    }
`;
