// @ts-nocheck
import { compare } from 'fast-json-patch';
import { List } from 'immutable';
import uuid from 'uuid';
import get from 'lodash/get';
import pick from 'lodash/pick';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import { FileData } from '@/requests/SweepbrightCrudRequest';
import { getVisibleAmenities } from '@/app.data/Amenities';
import { locationProperties } from '@/app.data/Location';
import shapesByType from '@/app.data/Shapes';
import { InteractionData } from '@/app.data/Interactions';
import { CurrencyAmount } from '@/app.data/Leads';
import { PropertyType } from '@/app.data/Properties';
import { orientableFieldNames } from '@/app.data/Orientations';
import { PropertyFieldVisibilityContext, PropertyNegotiation } from '@/app.data/FieldVisibilityContext';
import { DateTime, INVALID_DATE } from '../../../app.data/Datetime';
import buildDate from '../Helpers/buildDate';
import { isObjectEmpty } from '../Helpers/emptyValidators';
import { rulesMatcher } from '../Fields/rulesMatcher';
import AbstractPatchBuilder from './AbstractPatchBuilder';

type Vendor = {};

type ComposedDate = {};

interface OpenHome {
    id: string;
    start_date: DateTime;
    end_date: DateTime;
}

type Regulations = {
    // add index signature
    [regulation: string]: boolean;
    building_permit: boolean;
    preemptive_right: boolean;
    subdivision_authorisation: boolean;
    urban_planning_breach: boolean;
    flood_risk_area: boolean;
};

export interface ImageData extends FileData {
    ordinal: number;
    equirectangular: boolean;
}

export type PropertySettingsFees = {
    fixed_fee?: number;
    percentage?: number;
    buyer?: {
        fixed_fee: number;
        percentage: number;
    };
    seller?: {
        fixed_fee: number;
        percentage: number;
    };
};

export type PropertySettings = {
    internal_note: string;
    advertisement: {
        from: string;
        allowed: boolean;
    };
    mandate: {
        start_date: string;
        end_date: string;
        exclusive: boolean;
        number: string;
    };
    office: {
        negotiator: string;
        reference: string;
    };
    appointment_service_url: string;
    current_rent: CurrencyAmount;
    auction: {
        start_date: DateTime;
    };
    open_homes: OpenHome[];
    agency_commission?: PropertySettingsFees;
};

export type DocumentData = {
    filename?: string;
    extension?: string;
    description?: string;
    private?: boolean;
    'content-type'?: string;
    uploaded_at?: string;
};

interface Area {
    ordinal: number;
    size: number;
    units: string;
}

interface Areas {
    [structure: string]: Area;
}

interface Structures {
    [structure: string]: number;

    floors: number;
}

export type PropertyData = {
    is_project?: boolean;
    title: string;
    shape: string;
    conditions: any;
    description: any;
    status?: string;
    vendors: Vendor[];
    negotiation: PropertyNegotiation;
    shapes: {};
    type: PropertyType;
    sub_type: string;
    settings: PropertySettings;
    occupancy: {
        vacant: boolean;
        contact_details: string;
        available_from: ComposedDate;
        occupied: boolean;
    };
    accessibility: {
        key_available: boolean;
        alarm?: boolean;
        alarm_code?: string;
    };
    building: {
        renovation: {
            year: string | number;
            description: string;
        };
        construction: {
            architect: string;
            year: string | number;
        };
        units_of_building?: string | number;
        number_of_floor_building?: string | number;
    };
    energy: {
        epc_value: string | null;
        documents: FileData[];
        regulations: Regulations;
        epc_category: string;
        purchased_year: string;
        legal_mentions: { [key: string]: string };
        nabers: string;
        nathers: string;
        epc_reference: string;
    };
    features: any;
    floor_plans: FileData[];
    images: ImageData[];
    general_condition: string;
    location: {
        street?: string;
        country?: string;
        province_or_state?: string;
        city?: string;
        postal_code?: string;
        box?: string;
        floor?: string;
        addition?: string;
        number?: string;
        street_2?: string;
        borough_or_district?: string;
        formatted_address: Record<string, string>;
    };
    orientation: any;
    price: {
        published_price?: {
            amount: number;
        };
        current_price?: {
            amount: number;
        };
        yearly_budgeted_building_costs?: {
            amount: number;
        };
        vat_regime?: number;
        inventory_report_cost?: {
            amount: number;
        };
        reference_rent?: {
            amount: number;
        };
        base_rent?: {
            amount: number;
        };
        rent_supplement?: {
            amount: number;
        };
        life_annuity?: {
            applicable: boolean;
            monthly_rent?: {
                amount: number;
            };
            advance?: {
                amount: number;
            };
            maximum_duration?: number;
        };
    };
    structure: Areas & Structures & { rooms: Area[] };
    tenant_contract: {
        start_date: ComposedDate;
        end_date: ComposedDate;
    };
    video?: object;
    permissions: { [permission: string]: boolean };
    amenities: {
        terrace: boolean;
        garden: boolean;
    };
    legal: {
        cadastral_income: number | '';
        energy?: {
            dpe_date?: string;
            estimated_energy_costs?: {
                year?: string;
                minimum?: {
                    amount: number;
                };
                maximum?: {
                    amount: number;
                };
            };
        };
    };
    interactions?: InteractionData[];
    documents?: Record<string, FileData>;
};

export default class PropertyPatchBuilder extends AbstractPatchBuilder<PropertyData> {
    constructor(attr: Partial<PropertyData>, currentValues: Partial<PropertyData>) {
        super(attr, currentValues);
        /* eslint-disable eqeqeq, no-undefined */
        if (attr == undefined) {
            throw new Error('no attributes passes to the constructor');
        }
        if (attr.type == undefined) {
            throw new Error('no property type specified in the attributes');
        }
    }

    getVisibilityContext(): PropertyFieldVisibilityContext {
        const { type, internalType, negotiation, location: { country } = {} } = this.current;

        return {
            type,
            internalType: internalType.toLowerCase(),
            negotiation,
            country,
        };
    }

    isOfTypes(...types: string[]): boolean {
        return types.includes(this.attributes.type);
    }

    addShapesOperation() {
        if (!this.attributes.shape && !this.attributes.shapes) {
            return;
        }

        const { type } = this.attributes;
        const shapes = shapesByType.get(type, List());

        switch (type) {
            case 'parking':
                if (shapes.contains(this.attributes.shape)) {
                    this.update('shape', this.attributes.shape);
                }
                break;
            case 'land':
                this.addEnumFromObjects('shapes', shapes);
                break;
            default:
                return;
        }
    }

    addRoomsOperations() {
        if (!this.attributes.structure) {
            return;
        }

        // Update general attributes
        this.updateCondition('general_condition', this.attributes.general_condition);
        if (!this.attributes.is_project) {
            if (!this.attributes.sub_type) {
                this.update('sub_type', []);
            } else {
                const subType = this.attributes.sub_type;
                this.update('sub_type', subType === 'none' ? [] : [subType]);
            }
        }

        if (this.attributes.amenities) {
            const visibleAmenities = getVisibleAmenities(this.getVisibilityContext());

            this.addEnumFromObjects('amenities', visibleAmenities);
        }

        if (this.attributes.amenities && this.attributes.orientation) {
            for (const field of orientableFieldNames) {
                if (this.attributes.amenities[field] && this.attributes.orientation[field]) {
                    this.update(`/orientation/${field}`, this.attributes.orientation[field]);
                } else {
                    this.remove(`/orientation/${field}`);
                }
            }
        }

        // Add rooms
        this.replace('/structure/rooms', {});
        // eslint-disable-next-line no-unused-expressions
        this.attributes.structure.rooms?.forEach?.((room, ordinal) => {
            const path = `/structure/rooms/${uuid.v4()}`;
            this.add(path, {
                ...room,
                size: room.size || '',
                ordinal,
            });

            if (room.size) {
                this.updateFormattedNumber(`${path}/size`, room.size || '');
            }
        });

        const ctx = this.getVisibilityContext();
        const propertyRooms = Object.entries(rulesMatcher.getSchemasByPathPrefix(ctx, 'attributes.structure'))
            .filter(([_key, value]) => {
                return [value.type].flat().includes('integer');
            })
            .map(([key]) => key.replace('attributes.structure.', ''));

        // Update room numbers
        propertyRooms.forEach((type: string) => {
            const value = (this.attributes.structure as Structures)[type] || 0;
            this.updateNumber(`/structure/${type}`, value);
        });

        if (this.isOfTypes('house')) {
            this.updateNumber('/structure/floors', this.attributes.structure.floors);
        }
    }

    addLocationOperations() {
        if (!this.attributes.location) {
            return;
        }

        this.add('location', {});
        const disallowedProperties = this.isOfTypes('house', 'land')
            ? ['public', 'floor', 'formatted', 'province_or_county']
            : ['public', 'formatted', 'province_or_county'];
        locationProperties.forEach(attribute => {
            const value = this.attributes.location[attribute];
            const isUnsupportedProperty = disallowedProperties.includes(attribute);
            if (isUnsupportedProperty) {
                return;
            }

            // Do not send any geo location data if coordinates are empty
            if (attribute === 'geo' && value && !value.latitude && !value.longitude) {
                return;
            }

            const defaultValue = attribute === 'country' ? 'FR' : '';
            this.update(`location/${attribute}`, value, defaultValue);
        });
    }

    addDescriptionOperations() {
        if (!this.attributes.title && !this.attributes.description) {
            return;
        }

        this.updatePerKey('description/title', this.attributes.description.title);
        this.updatePerKey('description/description', this.attributes.description.description);
    }

    addPriceOperations() {
        const price = this.attributes.price;
        if (!price) {
            return;
        }

        this.add('price', {});

        if (price.published_price) {
            this.add('price/published_price', {});
            if (isNotEmpty(price.published_price.amount)) {
                this.updateNumber('price/published_price/amount', price.published_price.amount);
            } else {
                this.remove('price/published_price/amount');
            }
        }

        this.update('price/custom_price', price.custom_price);

        this.updatePerKey('price/taxes', price.taxes);
        this.updatePerKey('price/costs', price.costs);

        if (price.current_price) {
            this.add('price/current_price', {});
            if (isNotEmpty(price.current_price.amount)) {
                this.updateNumber('price/current_price/amount', price.current_price.amount);
            } else {
                this.remove('price/current_price/amount');
            }
        }

        if (price.yearly_budgeted_building_costs?.amount) {
            this.add('price/yearly_budgeted_building_costs', {});
            if (isNotEmpty(price.yearly_budgeted_building_costs.amount)) {
                this.updateNumber(
                    'price/yearly_budgeted_building_costs/amount',
                    price.yearly_budgeted_building_costs.amount,
                );
            } else {
                this.remove('price/yearly_budgeted_building_costs/amount');
            }
        } else {
            this.remove('price/yearly_budgeted_building_costs');
        }

        if (price.property_tax?.amount) {
            this.add('price/property_tax', {});
            if (isNotEmpty(price.property_tax.amount)) {
                this.updateNumber('price/property_tax/amount', price.property_tax.amount);
            } else {
                this.remove('price/property_tax/amount');
            }
        } else {
            this.remove('price/property_tax');
        }

        if (price.recurring_costs) {
            this.add('price/recurring_costs', {});
            if (isNotEmpty(price.recurring_costs.amount)) {
                this.updateNumber('price/recurring_costs/amount', price.recurring_costs.amount);
            } else {
                this.remove('price/recurring_costs/amount');
            }
        } else {
            this.remove('price/recurring_costs');
        }

        if (price.guarantee?.amount) {
            this.add('price/guarantee', {});
            if (isNotEmpty(price.guarantee.amount)) {
                this.updateNumber('price/guarantee/amount', price.guarantee.amount);
            } else {
                this.remove('price/guarantee/amount');
            }
        } else {
            this.remove('price/guarantee');
        }

        if (price.inventory_report_cost?.amount) {
            this.add('price/inventory_report_cost', {});
            if (isNotEmpty(price.inventory_report_cost.amount)) {
                this.updateNumber('price/inventory_report_cost/amount', price.inventory_report_cost.amount);
            } else {
                this.remove('price/inventory_report_cost/amount');
            }
        } else {
            this.remove('price/inventory_report_cost');
        }

        if (price.reference_rent?.amount) {
            this.add('price/reference_rent', {});
            if (isNotEmpty(price.reference_rent.amount)) {
                this.updateNumber('price/reference_rent/amount', price.reference_rent.amount);
            } else {
                this.remove('price/reference_rent/amount');
            }
        } else {
            this.remove('price/reference_rent');
        }

        if (price.base_rent?.amount) {
            this.add('price/base_rent', {});
            if (isNotEmpty(price.base_rent.amount)) {
                this.updateNumber('price/base_rent/amount', price.base_rent.amount);
            } else {
                this.remove('price/base_rent/amount');
            }
        } else {
            this.remove('price/base_rent');
        }

        if (price.rent_supplement?.amount) {
            this.add('price/rent_supplement', {});
            if (isNotEmpty(price.rent_supplement.amount)) {
                this.updateNumber('price/rent_supplement/amount', price.rent_supplement.amount);
            } else {
                this.remove('price/rent_supplement/amount');
            }
        } else {
            this.remove('price/rent_supplement');
        }

        if (isNotEmpty(price.vat_regime)) {
            this.add('price/vat_regime', price.vat_regime);
        } else {
            this.remove('price/vat_regime');
        }

        if (price?.life_annuity) {
            if (price?.life_annuity?.applicable !== undefined) {
                this.add('price/life_annuity', {});
                this.update('price/life_annuity/applicable', price.life_annuity.applicable, false);
            }

            if (price.life_annuity.applicable === false) {
                this.remove('price/life_annuity/monthly_rent');
                this.remove('price/life_annuity/advance');
                this.remove('price/life_annuity/maximum_duration');
            }

            if (price.life_annuity.applicable) {
                if (price.life_annuity.monthly_rent?.amount) {
                    this.add('price/life_annuity/monthly_rent', {});
                    if (isNotEmpty(price.life_annuity.monthly_rent.amount)) {
                        this.updateNumber(
                            'price/life_annuity/monthly_rent/amount',
                            price.life_annuity.monthly_rent.amount,
                        );
                    } else {
                        this.remove('price/life_annuity/monthly_rent/amount');
                    }
                } else {
                    this.remove('price/life_annuity/monthly_rent');
                }

                if (price.life_annuity.advance?.amount) {
                    this.add('price/life_annuity/advance', {});
                    if (isNotEmpty(price.life_annuity.advance.amount)) {
                        this.updateNumber('price/life_annuity/advance/amount', price.life_annuity.advance.amount);
                    } else {
                        this.remove('price/life_annuity/advance/amount');
                    }
                } else {
                    this.remove('price/life_annuity/advance');
                }

                if (price.life_annuity.maximum_duration) {
                    this.add('price/life_annuity/maximum_duration', {});
                    if (isNotEmpty(price.life_annuity.maximum_duration)) {
                        this.updateNumber('price/life_annuity/maximum_duration', price.life_annuity.maximum_duration);
                    } else {
                        this.remove('price/life_annuity/maximum_duration');
                    }
                } else {
                    this.remove('price/life_annuity/maximum_duration');
                }
            }
        } else {
            this.remove('price/life_annuity');
        }
    }

    addLegalOperations() {
        if (this.attributes.legal) {
            const legalOperations = compare(pick(this.current, 'legal'), pick(this.attributes, 'legal'));
            legalOperations.forEach(op => {
                const estimatedEnergyCost =
                    (op.path.includes('estimated_energy_costs/minimum') && 'minimum') ||
                    (op.path.includes('estimated_energy_costs/maximum') && 'maximum') ||
                    '';

                if (estimatedEnergyCost) {
                    const path = `/legal/energy/estimated_energy_costs/${estimatedEnergyCost}`;

                    const energyCost = this.attributes.legal.energy.estimated_energy_costs[estimatedEnergyCost];

                    const cost = typeof energyCost === 'object' ? energyCost?.amount : energyCost;

                    if (cost) {
                        this.add(path, {});
                        if (isNotEmpty(cost)) {
                            this.updateNumber(`${path}/amount`, cost);
                        } else {
                            this.remove(`${path}/amount`);
                        }
                    } else {
                        this.remove(path);
                    }
                } else {
                    this.addOperation(op.op, op.path, op.value);
                }
            });
        }
    }

    addDocumentOperations() {
        if (this.attributes.documents) {
            const documentOperations = compare(pick(this.current, 'documents'), pick(this.attributes, 'documents'));

            documentOperations.forEach(op => {
                if ('value' in op) {
                    this.addOperation(op.op, op.path, op.value);
                } else {
                    this.addOperation(op.op, op.path);
                }
            });
        }
    }

    addRegulationOperations() {
        if (!this.attributes.energy.regulations) {
            return;
        }

        const hasRegulations =
            Object.keys(this.attributes.energy.regulations).findIndex(regulation =>
                Boolean(this.attributes.energy.regulations[regulation]),
            ) >= 0;

        if (!hasRegulations) {
            this.remove('energy/regulations');

            return;
        }

        this.add('energy/regulations', {});
        this.updateOrRemove('energy/regulations/building_permit', this.attributes.energy.regulations.building_permit);
        this.updateOrRemove('energy/regulations/preemptive_right', this.attributes.energy.regulations.preemptive_right);
        this.updateOrRemove(
            'energy/regulations/subdivision_authorisation',
            this.attributes.energy.regulations.subdivision_authorisation,
        );
        this.updateOrRemove(
            'energy/regulations/urban_planning_breach',
            this.attributes.energy.regulations.urban_planning_breach,
        );
        this.updateOrRemove('energy/regulations/flood_risk_area', this.attributes.energy.regulations.flood_risk_area);
    }

    addFloorPlanOperations() {
        if (!this.attributes.floor_plans) {
            return;
        }

        const { floor_plans: plans } = this.attributes;

        this.deleteEntities('floor_plans', plans);
        // eslint-disable-next-line no-unused-expressions
        plans.forEach?.(plan => {
            if (!plan.id || plan.deleted) {
                return;
            }

            this.update(`floor_plans/${plan.id}/private`, Boolean(plan.private));
            this.update(`floor_plans/${plan.id}/filename`, plan.filename);
            this.update(`floor_plans/${plan.id}/description`, plan.description);
        });
    }

    addTenantContract() {
        const { tenant_contract: contract } = this.attributes;

        if (!contract) {
            return;
        }

        if (contract.start_date || contract.end_date) {
            this.add('tenant_contract', {});

            if (contract.start_date) {
                this.update('tenant_contract/start_date', contract.start_date);
            }
            if (contract.end_date) {
                this.update('tenant_contract/end_date', contract.end_date);
            }

            return;
        }

        this.remove('tenant_contract');
    }

    addImagesOperations() {
        if (!this.attributes.images) {
            return;
        }

        const newImages = mapValues(keyBy(this.attributes.images, 'id'), img =>
            omit(img, ['progress', 'id', 'data_url']),
        );

        const imageOperations = compare(pick(this.current, 'images'), { images: newImages });

        imageOperations.forEach(op => {
            if (op.op === 'replace' || op.op === 'add') {
                this.addOperation(op.op, op.path, op.value);
            } else {
                this.addOperation(op.op, op.path);
            }
        });
    }

    addPermissionsOperations() {
        if (!this.attributes.permissions) {
            return;
        }

        const ctx = this.getVisibilityContext();
        const permissionFieldsArrayPaths = Object.keys(
            rulesMatcher.getSchemasByPathPrefix(ctx, 'attributes.permissions'),
        )
            .map(path => path.replace(/^attributes\./, ''))
            .map(path => path.split('.'))
            .filter(
                path =>
                    //Like ['permissions', 'farming']
                    path.length === 2,
            );
        if (!permissionFieldsArrayPaths.length) {
            return;
        }

        this.add('permissions', {});

        permissionFieldsArrayPaths.forEach(breadcrumbs => {
            const value = get(this.attributes, breadcrumbs) === true;
            const jsonPatchPath = breadcrumbs.join('/');
            this.update(jsonPatchPath, value);
        });
    }

    addFeaturesOperations() {
        if (!this.attributes.features) {
            return;
        }

        const ctx = this.getVisibilityContext();
        const featuresFieldsArrayPaths = Object.keys(rulesMatcher.getSchemasByPathPrefix(ctx, 'attributes.features'))
            .map(path => path.replace(/^attributes\./, ''))
            .map(path => path.split('.'))
            .filter(
                //like: ['features','comfort','wine_cellar']
                breadcrumbs => breadcrumbs.length === 3,
            );
        const featureTypes = new Set(featuresFieldsArrayPaths.map(([, type]) => type));

        if (!featuresFieldsArrayPaths.length) {
            return;
        }

        this.add('features', {});
        for (const featureType of featureTypes) {
            this.add(`features/${featureType}`, {});
        }

        featuresFieldsArrayPaths.forEach(breadcrumbs => {
            const value = get(this.attributes, breadcrumbs) === true;
            const jsonPatchPath = breadcrumbs.join('/');
            this.update(jsonPatchPath, value);

            if (get(this.attributes, breadcrumbs) === undefined) {
                this.remove(jsonPatchPath);
            }
        });
    }

    addConditionsOperations() {
        if (!this.attributes.conditions || !this.isOfTypes('house', 'apartment')) {
            return;
        }

        this.add('conditions', {});
        this.updateCondition('conditions/kitchen', this.attributes.conditions.kitchen);
        this.updateCondition('conditions/bathroom', this.attributes.conditions.bathroom);
    }

    addBuildingOperations() {
        if (!this.attributes.building) {
            return;
        }

        if (this.isOfTypes('apartment', 'house', 'parking', 'office', 'commercial')) {
            if (this.attributes.building.renovation) {
                this.add('building/renovation', {});

                if (this.attributes.building.renovation.year) {
                    this.updateNumber('building/renovation/year', this.attributes.building.renovation.year);
                } else {
                    this.remove('building/renovation/year');
                }

                this.update('building/renovation/description', this.attributes.building.renovation.description);
            }

            if (this.attributes.building.construction) {
                this.add('building/construction', {});

                if (this.attributes.building.construction.year) {
                    this.updateNumber('building/construction/year', this.attributes.building.construction.year);
                } else {
                    this.remove('building/construction/year');
                }

                if (this.attributes.building.construction.residential_lots) {
                    this.updateNumber(
                        'building/construction/residential_lots',
                        this.attributes.building.construction.residential_lots,
                    );
                } else {
                    this.remove('building/construction/residential_lots');
                }

                this.update('building/construction/architect', this.attributes.building.construction.architect);
            }
        }

        if (this.attributes.building.units_of_building) {
            this.updateNumber('building/units_of_building', this.attributes.building.units_of_building);
        } else {
            this.remove('building/units_of_building');
        }

        if (this.attributes.building.number_of_floor_building) {
            this.updateNumber('building/number_of_floor_building', this.attributes.building.number_of_floor_building);
        } else {
            this.remove('building/number_of_floor_building');
        }
    }

    addOccupancyOperations() {
        if (!this.attributes.occupancy) {
            return;
        }

        this.add('occupancy', {});
        this.update('occupancy/occupied', this.attributes.occupancy.occupied, false);

        this.updateOrRemove('occupancy/available_from', this.attributes.occupancy.available_from || null);

        this.update('occupancy/contact_details', this.attributes.occupancy.contact_details);
    }

    addAccessibilityOperations() {
        if (!this.attributes.accessibility) {
            return;
        }

        this.add('accessibility', {});
        this.update('accessibility/key_available', this.attributes.accessibility.key_available, false);
        if (!this.isOfTypes('land')) {
            this.update('accessibility/alarm', this.attributes.accessibility.alarm, false);
            this.update('accessibility/alarm_code', this.attributes.accessibility.alarm_code);
        }
    }

    addSettingsOperations() {
        const settings = this.attributes.settings;
        if (!settings) {
            return;
        }
        this.add('settings', {});
        this.update('settings/internal_note', settings.internal_note);
        this.update('settings/appointment_service_url', settings.appointment_service_url);
        const fromDate = ({ date, hour, minutes }: DateTime) => buildDate(date, hour, minutes).format();

        if (settings.advertisement && Object.keys(settings.advertisement).length) {
            this.add('settings/advertisement', {});
            this.update('settings/advertisement/allowed', settings.advertisement.allowed);
        }

        if (settings.mandate && Object.keys(settings.mandate).length) {
            const mandateFieldSet = Boolean(
                settings.mandate.start_date ||
                    settings.mandate.end_date ||
                    settings.mandate.exclusive ||
                    settings.mandate.number,
            );
            if (mandateFieldSet) {
                this.add('settings/mandate', {});
            }

            if (settings.mandate.start_date) {
                this.update('settings/mandate/start_date', settings.mandate.start_date);
            } else {
                this.remove('settings/mandate/start_date');
            }

            if (settings.mandate.end_date) {
                this.update('settings/mandate/end_date', settings.mandate.end_date);
            } else {
                this.remove('settings/mandate/end_date');
            }

            if (settings.mandate.number) {
                this.update('settings/mandate/number', settings.mandate.number);
            } else {
                this.remove('settings/mandate/number');
            }

            this.update('settings/mandate/exclusive', Boolean(settings.mandate.exclusive));
        }

        this.addAgencyCommissionOperations();

        const openHomes = normalizeOneHomes(settings.open_homes).filter(openHome => openHome.start_date.date);
        const currentlyHasOpenHomes = this.current && !isObjectEmpty(this.current?.settings?.open_homes);
        if (settings.open_homes) {
            this.add('/settings/open_homes', {});
            if (currentlyHasOpenHomes) {
                this.replace('/settings/open_homes', {});
                this.current.settings.open_homes = [];
            }

            openHomes.forEach(openHome => {
                const key = typeof openHome.id === 'string' ? openHome.id : uuid.v4();
                const path = `/settings/open_homes/${key}`;
                this.add(path, {});
                this.update(`${path}/start_date`, fromDate(openHome.start_date));
                this.update(
                    `${path}/end_date`,
                    fromDate({
                        ...openHome.end_date,
                        date: openHome.start_date.date,
                    }),
                );
            });
        }

        if (settings.office && Object.keys(settings.office).length) {
            this.add('settings/office', {});
            this.update('settings/office/negotiator', settings.office.negotiator);
            this.update('settings/office/reference', settings.office.reference);
        }

        if (settings.current_rent != undefined) {
            this.add('settings/current_rent', {});
            if (isNotEmpty(settings.current_rent.amount)) {
                this.updateNumber('settings/current_rent/amount', settings.current_rent.amount);
            } else {
                this.remove('settings/current_rent/amount');
            }
        }

        if (settings.auction && settings.auction.start_date) {
            this.add('settings/auction', {});
            const startDate = fromDate(settings.auction.start_date);
            if (settings.auction.start_date && startDate !== INVALID_DATE) {
                this.update('settings/auction/start_date', startDate);

                return;
            }
        }

        this.remove('settings/auction');
    }

    addAreaOperations() {
        const { structure } = this.attributes;

        if (!structure) {
            return;
        }

        const ctx = this.getVisibilityContext();
        const propertyAreas = Object.entries(rulesMatcher.getSchemasByPathPrefix(ctx, 'attributes.structure'))
            .filter(([_key, value]) => {
                return value?.properties?.size;
            })
            .map(([key]) => key.replace('attributes.structure.', ''));

        propertyAreas.forEach((area: string) => {
            const value = structure[area];
            if (value?.size) {
                const value = structure[area];
                this.add(`structure/${area}`, value);
                this.updateNumber(`structure/${area}/size`, value.size);
                this.add(`structure/${area}/size_description`, area);
                this.add(`structure/${area}/units`, value.units);
            } else {
                this.remove(`structure/${area}`);
            }
        });
    }

    addVideoOperations() {
        if (!Object.keys(this.attributes).includes('video')) {
            return;
        }

        if (!this.attributes.video) {
            this.remove('video');

            return;
        }

        this.update('video', this.attributes.video);
    }

    addVirtualTourOperations() {
        if (!Object.keys(this.attributes).includes('virtual_tour')) {
            return;
        }

        if (!this.attributes.virtual_tour) {
            this.remove('virtual_tour');

            return;
        }

        this.update('virtual_tour', this.attributes.virtual_tour);
    }

    addAgencyCommissionOperations() {
        const updateCommissions = (commission, prefix) => {
            if (commission.fixed_fee) {
                this.updateNumber(`${prefix}/fixed_fee`, commission.fixed_fee);
            } else {
                this.remove(`${prefix}/fixed_fee`);
            }

            if (commission.percentage) {
                this.updateNumber(`${prefix}/percentage`, commission.percentage);
            } else {
                this.remove(`${prefix}/percentage`);
            }
        };

        const settings = this.attributes.settings;

        if (!('agency_commission' in settings)) {
            return;
        }

        if (
            settings.agency_commission?.fixed_fee ||
            settings.agency_commission?.percentage ||
            settings.agency_commission?.buyer?.fixed_fee ||
            settings.agency_commission?.buyer?.percentage ||
            settings.agency_commission?.seller?.fixed_fee ||
            settings.agency_commission?.seller?.percentage
        ) {
            this.add('settings/agency_commission', {});

            if (settings.agency_commission.fixed_fee || settings.agency_commission.percentage) {
                updateCommissions(settings.agency_commission, 'settings/agency_commission');
            }

            if (settings.agency_commission?.buyer?.fixed_fee || settings.agency_commission?.buyer?.percentage) {
                this.add('settings/agency_commission/buyer', {});
                updateCommissions(settings.agency_commission.buyer, 'settings/agency_commission/buyer');
            } else {
                this.remove('settings/agency_commission/buyer');
            }

            if (settings.agency_commission?.seller?.fixed_fee || settings.agency_commission?.seller?.percentage) {
                this.add('settings/agency_commission/seller', {});
                updateCommissions(settings.agency_commission.seller, 'settings/agency_commission/seller');
            } else {
                this.remove('settings/agency_commission/seller');
            }
        } else {
            this.remove('settings/agency_commission');
        }
    }

    /**
     * Remove entities mark for deletion
     */
    deleteEntities(path: string, entities: FileData[]) {
        if (!entities || !entities.length) {
            return;
        }

        entities.forEach(entity => {
            if (!entity.id) {
                return;
            }

            if (entity.deleted) {
                return this.remove(`${path}/${entity.id}`);
            }
        });
    }

    addStatusOperations() {
        return this.update('status', this.attributes.status, 'available');
    }

    getOperations(): List<any> {
        this.addStatusOperations();
        this.addRoomsOperations();
        this.addLocationOperations();
        this.addDescriptionOperations();
        this.addPriceOperations();
        this.addLegalOperations();
        this.addDocumentOperations();
        this.addTenantContract();
        this.addImagesOperations();
        this.addFeaturesOperations();
        this.addConditionsOperations();
        this.addBuildingOperations();
        this.addOccupancyOperations();
        this.addAccessibilityOperations();
        this.addSettingsOperations();
        this.addAreaOperations();
        this.addShapesOperation();
        this.addPermissionsOperations();
        this.addVideoOperations();
        this.addVirtualTourOperations();
        this.addFloorPlanOperations();

        return this.operations;
    }
}

function isNotEmpty(value) {
    if (value == null) {
        return false;
    }

    if (typeof value === 'string') {
        return value.trim().length > 0;
    }

    return true;
}

function normalizeOneHomes(openHomes) {
    if (!openHomes) {
        return [];
    }

    if (Array.isArray(openHomes)) {
        return openHomes;
    }

    if (typeof openHomes === 'object' && openHomes !== null) {
        return Object.entries(openHomes).map(([id, openHome]) => ({ id, ...openHome }));
    }

    return [];
}
