import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl-sweepbright';
import { getCompanySettings } from '../../../../app.redux/selectors';
import submitThrough from '../../../../app.utils/services/Helpers/submitThrough';
import CommunicationIntegration from '../CommunicationIntegration';

const extractAppId = attributes => {
    return attributes.update('livechat_app_id', livechatLink => {
        const pieces = livechatLink.replace(/\/$/, '').split('/');

        return pieces[pieces.length - 1];
    });
};

const Livechat = ({ fields, handleSubmit, submitting }) => (
    <CommunicationIntegration
        service="LiveChat"
        field={fields.livechat_app_id}
        handleSubmit={submitThrough(handleSubmit, extractAppId)}
        submitting={submitting}
        placeholder={
            <FormattedMessage id="integrations.communications.livechat.placeholder" defaultMessage="1234567" />
        }
        fieldText={
            <FormattedMessage id="integrations.communication.livechat.chat_link" defaultMessage="Livechat Link" />
        }
        formHelpText={
            <FormattedHTMLMessage
                id="integrations.communication.livechat.forms.help"
                defaultMessage={
                    'You can find the Chat Link in your LiveChat Admin. Click <a href="https://my.livechatinc.com/settings/direct-chat-link">here</a> and copy the link.'
                }
            />
        }
        emptyStateText={
            <FormattedHTMLMessage
                id="integrations.communication.livechat.help"
                defaultMessage={
                    'Our integration with LiveChat helps you chat with visitors on your SweepBright website. You need a LiveChat account. Please be aware that your website might become momentarily unavailable when you hit the Connect button. Learn about integrations <a href="https://sweepbright.com/support/">here</a>.'
                }
            />
        }
    />
);

Livechat.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

const form = {
    form: 'livechat',
    fields: ['livechat_app_id'],
};

const mapStateToProps = createStructuredSelector({
    initialValues: getCompanySettings,
});

export default reduxForm(form, mapStateToProps)(Livechat);
