import gql from 'graphql-tag';
import { ContactCardFragment } from '@/graphql/fragments/contactCard';

export const GET_INTERACTED_CONTACTS_QUERY = gql`
    query GetInteractedContacts(
        $propertyId: ID!
        $after: String
        $before: String
        $afterInterestedLeads: String
        $afterNotInterestedLeads: String
        $beforeInterestedLeads: String
        $beforeNotInterestedLeads: String
        $type: ContactType!
        $splitByInterest: Boolean!
    ) {
        estate(id: $propertyId) {
            id
            interestedContacts: interactedContacts(
                type: $type
                interestLevel: "interested"
                after: $afterInterestedLeads
                before: $beforeInterestedLeads
            ) @include(if: $splitByInterest) {
                nodes {
                    ...ContactCardFragment
                }
                pageInfo {
                    hasNextPage
                    endCursor
                    startCursor
                }
            }
            notInterestedContacts: interactedContacts(
                type: $type
                interestLevel: "not_interested"
                after: $afterNotInterestedLeads
                before: $beforeNotInterestedLeads
            ) @include(if: $splitByInterest) {
                nodes {
                    ...ContactCardFragment
                }
                pageInfo {
                    hasNextPage
                    endCursor
                    startCursor
                }
            }
            contacts: interactedContacts(type: $type, after: $after, before: $before) @skip(if: $splitByInterest) {
                nodes {
                    ...ContactCardFragment
                }
                pageInfo {
                    hasNextPage
                    endCursor
                    startCursor
                }
            }
        }
    }
    ${ContactCardFragment}
`;
