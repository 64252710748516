import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import FilterNegotiators from '@/shared/filter/FilterNegotiators';
import PropertyFilterAmenities from '@/new.domains/properties/PropertyFilter/PropertyFilterAmenities';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import ResourceFilter from '@/shared/resource/ResourceFilter';
import { ResourceDomainType } from '@/shared/resource/types';
import { propertyTypes as allPropertyTypes } from '@/app.data/Properties';
import { PreferenceFieldVisibility } from '@/app.utils/services/Fields/PreferenceFieldVisibility';
import FilterLabels from '@/shared/filter/FilterLabels';
import { PROPERTY_CONDITION_OPTIONS } from '@/app.data/Conditions';
import { getPropertiesAmenitiesGroups, getPropertiesSubtypesGroups } from '@/new.domains/properties/utils';

const useMatchPropertyFilters = (
    searchSettings: any | undefined,
    setSearchSettings: Function,
    domainType: ResourceDomainType,
    propertyType: string,
) => {
    const intl = useIntl();

    const showLabels = useFeatureFlag('contact.labels.enabled');
    const showAreas = useFeatureFlag('contact.preferences.areas.enabled');
    const showPropertyFeatures = useFeatureFlag('property.features.filter.enabled');

    const filters = useMemo(() => {
        const content = {
            no_interest: intl.formatMessage({ id: `${domainType}.filter.no_interest` }),
            lead: intl.formatMessage({ id: `${domainType}.filter.lead` }),
        };

        return (
            <>
                <FilterNegotiators
                    showUnassignedOption
                    domainType="property_matches"
                    searchSettings={searchSettings}
                    translationDomainType="contacts"
                    setSearchSettings={setSearchSettings}
                />

                {showLabels && (
                    <FilterLabels
                        domainType="property_matches"
                        searchSettings={searchSettings}
                        translationDomainType="contacts"
                        setSearchSettings={setSearchSettings}
                    />
                )}

                {showPropertyFeatures ? (
                    <ResourceFilter
                        showSearch
                        id="amenities"
                        params={searchSettings}
                        domainType="property_matches"
                        setParams={setSearchSettings}
                        translationDomainType="properties"
                        filters={[
                            {
                                type: 'checkbox',
                                overflow: true,
                                allowEmpty: true,
                                hideOnlyButton: true,
                                paramName: 'preference_amenity',
                                groupOptions: getPropertiesAmenitiesGroups(),
                            },
                        ]}
                    />
                ) : (
                    <PropertyFilterAmenities
                        // @ts-ignore
                        domainType="contacts"
                        // @ts-ignore
                        values={searchSettings}
                        onChange={changedSettings => {
                            // @ts-ignore
                            setSearchSettings({ ...searchSettings, ...changedSettings });
                        }}
                        title={<FormattedMessage id="properties.filter.amenities" defaultMessage="Amenities" />}
                    />
                )}

                {showAreas && (
                    <>
                        <PreferenceFieldVisibility
                            // @ts-ignore
                            type={propertyType}
                            path="min_liveable_area"
                        >
                            <ResourceFilter
                                prefix="sq_m"
                                id="liveable_area"
                                domainType={domainType}
                                params={searchSettings}
                                setParams={setSearchSettings}
                                translationDomainType="contacts"
                                filters={[
                                    {
                                        type: 'number',
                                        paramName: 'preferenceLiveableArea',
                                    },
                                ]}
                            />
                        </PreferenceFieldVisibility>

                        <PreferenceFieldVisibility
                            // @ts-ignore
                            type={propertyType}
                            path="min_net_area"
                        >
                            <ResourceFilter
                                id="net_area"
                                prefix="sq_m"
                                domainType={domainType}
                                params={searchSettings}
                                setParams={setSearchSettings}
                                translationDomainType="contacts"
                                filters={[
                                    {
                                        type: 'number',
                                        paramName: 'preferenceNetArea',
                                    },
                                ]}
                            />
                        </PreferenceFieldVisibility>

                        <PreferenceFieldVisibility
                            // @ts-ignore
                            type={propertyType}
                            path="min_plot_area"
                        >
                            <ResourceFilter
                                prefix="sq_m"
                                id="plot_area"
                                domainType={domainType}
                                params={searchSettings}
                                setParams={setSearchSettings}
                                translationDomainType="contacts"
                                filters={[
                                    {
                                        type: 'number',
                                        paramName: 'preferencePlotArea',
                                    },
                                ]}
                            />
                        </PreferenceFieldVisibility>

                        <PreferenceFieldVisibility
                            // @ts-ignore
                            type={propertyType}
                            path="min_gross_area"
                        >
                            <ResourceFilter
                                prefix="sq_m"
                                id="gross_area"
                                domainType={domainType}
                                params={searchSettings}
                                setParams={setSearchSettings}
                                translationDomainType="contacts"
                                filters={[
                                    {
                                        type: 'number',
                                        paramName: 'preferenceGrossArea',
                                    },
                                ]}
                            />
                        </PreferenceFieldVisibility>
                    </>
                )}

                <ResourceFilter
                    id="condition"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    domainType="property_matches"
                    translationDomainType="properties"
                    filters={[
                        {
                            type: 'radio',
                            paramName: 'preference_min_condition',
                            simpleOptions: [...PROPERTY_CONDITION_OPTIONS],
                        },
                    ]}
                />

                <ResourceFilter
                    prefix="euro"
                    id="price_point"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    domainType="property_matches"
                    translationDomainType="contacts"
                    filters={[
                        {
                            type: 'number',
                            paramName: 'price_point',
                        },
                    ]}
                />

                <ResourceFilter
                    id="preference_subtype"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    domainType="property_matches"
                    translationDomainType="contacts"
                    filters={[
                        {
                            type: 'radio',
                            overflow: true,
                            paramName: 'preference_subtype',
                            groupOptions: getPropertiesSubtypesGroups(),
                        },
                    ]}
                />

                <ResourceFilter
                    id="rooms"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    domainType="property_matches"
                    translationDomainType="contacts"
                    filters={[
                        {
                            type: 'number',
                            showTitle: true,
                            paramName: 'preference_min_bedrooms',
                        },
                    ]}
                />

                <ResourceFilter
                    id="offer_status"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    domainType="property_matches"
                    translationDomainType="properties"
                    filters={[
                        {
                            type: 'radio',
                            paramName: 'offer_status',
                            options: [
                                { value: undefined, key: 'all' },
                                { value: 'accepted', key: 'accepted', displayKey: true },
                                { value: 'pending', key: 'pending', displayKey: true },
                            ],
                        },
                    ]}
                />

                <ResourceFilter
                    disabled
                    filters={[]}
                    id="no_interest"
                    params={searchSettings}
                    domainType={domainType}
                    label={content.no_interest}
                    setParams={setSearchSettings}
                />

                <ResourceFilter
                    disabled
                    id="preference_type"
                    params={searchSettings}
                    domainType={domainType}
                    setParams={setSearchSettings}
                    translationDomainType="contacts"
                    filters={[
                        {
                            type: 'radio',
                            paramName: 'preference_type',
                            simpleOptions: [...allPropertyTypes],
                        },
                    ]}
                />

                <ResourceFilter
                    disabled
                    id="type"
                    filters={[]}
                    label={content.lead}
                    params={searchSettings}
                    domainType={domainType}
                    setParams={setSearchSettings}
                />

                <ResourceFilter
                    disabled
                    id="negotiation"
                    params={searchSettings}
                    domainType={domainType}
                    setParams={setSearchSettings}
                    translationDomainType="contacts"
                    filters={[
                        {
                            type: 'radio',
                            paramName: 'negotiation',
                            simpleOptions: ['let', 'sale'],
                        },
                    ]}
                />
            </>
        );
    }, [
        showAreas,
        showLabels,
        showPropertyFeatures,
        propertyType,
        searchSettings,
        setSearchSettings,
        domainType,
        intl,
    ]);

    return filters;
};

export default useMatchPropertyFilters;
