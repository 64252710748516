import React from 'react';
import classNames from 'classnames';
import LiftSvgIcon from '@resources/assets/svg/icons/lift.svg';
import MailSvgIcon from '@resources/assets/svg/icons/mail.svg';
import MoveBotlerIcon from '@resources/assets/svg/icons/movebotler.svg';
import MoveBotlerFilledIcon from '@resources/assets/svg/icons/movebotler-fill.svg';
import HouseNewSvgIcon from '@resources/assets/svg/icons/house-new.svg';
import HouseMintSvgIcon from '@resources/assets/svg/icons/house-mint.svg';
import HouseGoodSvgIcon from '@resources/assets/svg/icons/house-good.svg';
import HouseFairSvgIcon from '@resources/assets/svg/icons/house-fair.svg';
import HousePoorSvgIcon from '@resources/assets/svg/icons/house-poor.svg';
import HouseFillSvgIcon from '@resources/assets/svg/icons/house-fill.svg';
import StructureSvgIcon from '@resources/assets/svg/icons/structure.svg';
import RoomsSvgIcon from '@resources/assets/svg/icons/rooms.svg';
import ParkingSvgIcon from '@resources/assets/svg/icons/parking.svg';
import GardenSvgIcon from '@resources/assets/svg/icons/garden.svg';
import ApartmentSvgIcon from '@resources/assets/svg/icons/apartment.svg';
import ArchivedSvgIcon from '@resources/assets/svg/icons/archived.svg';
import AddActivitySvgIcon from '@resources/assets/svg/icons/add-activity.svg';
import ChevronDownSvgIcon from '@resources/assets/svg/icons/chevron.svg';
import SpeechBubbleSvgIcon from '@resources/assets/svg/icons/speech-bubble.svg';
import PhoneSvgIcon from '@resources/assets/svg/icons/phone.svg';
import CircleDeleteSvgIcon from '@resources/assets/svg/icons/circle-delete.svg';
import CircleClockSvgIcon from '@resources/assets/svg/icons/circle-clock.svg';
import StatusChangedSvgIcon from '@resources/assets/svg/icons/status-changed.svg';
import ErrorSvgIcon from '@resources/assets/svg/icons/error.svg';
import HelpSvgIcon from '@resources/assets/svg/icons/help.svg';
import SocialSvgIcon from '@resources/assets/svg/icons/social.svg';
import SocialFillSvgIcon from '@resources/assets/svg/icons/social-fill.svg';
import GoogleSvgIcon from '@resources/assets/svg/icons/google.svg';
import GoogleFillSvgIcon from '@resources/assets/svg/icons/google-fill.svg';
import CalendarSvgIcon from '@resources/assets/svg/icons/calendar.svg';
import DescriptionSvgIcon from '@resources/assets/svg/icons/description.svg';
import EditSvgIcon from '@resources/assets/svg/icons/edit.svg';
import CaretRightSvgIcon from '@resources/assets/svg/icons/caret-right.svg';
import CaretLeftSvgIcon from '@resources/assets/svg/icons/caret-left.svg';
import TerraceSvgIcon from '@resources/assets/svg/icons/terrace.svg';
import PoolSvgIcon from '@resources/assets/svg/icons/pool.svg';
import AtticSvgIcon from '@resources/assets/svg/icons/attic.svg';
import BasementSvgIcon from '@resources/assets/svg/icons/basement.svg';
import GuestHouseSvgIcon from '@resources/assets/svg/icons/guesthouse.svg';
import ElectricalGateSvgIcon from '@resources/assets/svg/icons/electrical-gate.svg';
import ManualGateSvgIcon from '@resources/assets/svg/icons/manual-gate.svg';
import FenceSvgIcon from '@resources/assets/svg/icons/fence.svg';
import KeyCardSvgIcon from '@resources/assets/svg/icons/key-card.svg';
import RemoteControlSvgIcon from '@resources/assets/svg/icons/remote-control.svg';
import ClimateControlSvgIcon from '@resources/assets/svg/icons/climate-control.svg';
import CodeSvgIcon from '@resources/assets/svg/icons/code.svg';
import WaterAccessSvgIcon from '@resources/assets/svg/icons/water-access.svg';
import SewerAccessSvgIcon from '@resources/assets/svg/icons/sewer-access.svg';
import DrainageSvgIcon from '@resources/assets/svg/icons/drainage.svg';
import RoadAccessSvgIcon from '@resources/assets/svg/icons/road-access.svg';
import UtilitiesAccessSvgIcon from '@resources/assets/svg/icons/utilities-access.svg';
import PrintAndCopySvgIcon from '@resources/assets/svg/icons/print-and-copy.svg';
import StorageSpaceSvgIcon from '@resources/assets/svg/icons/storage-space.svg';
import ServerRoomSvgIcon from '@resources/assets/svg/icons/server-room.svg';
import ReceptionAreaSvgIcon from '@resources/assets/svg/icons/reception-area.svg';
import WaitingAreaSvgIcon from '@resources/assets/svg/icons/waiting-area.svg';
import CoolingRoomSvgIcon from '@resources/assets/svg/icons/cooling-room.svg';
import DisplayWindowsSvgIcon from '@resources/assets/svg/icons/display-windows.svg';
import HouseSvgIcon from '@resources/assets/svg/icons/house.svg';
import VisitSvgIcon from '@resources/assets/svg/icons/visit.svg';
import NotesSvgIcon from '@resources/assets/svg/icons/notes.svg';
import UserSvgIcon from '@resources/assets/svg/icons/user.svg';
import UpdateSvgIcon from '@resources/assets/svg/icons/update.svg';
import UpdateFatSvgIcon from '@resources/assets/svg/icons/update-phat.svg';
import UnpublishSvgIcon from '@resources/assets/svg/icons/unpublish.svg';
import PublishSvgIcon from '@resources/assets/svg/icons/publish.svg';
import StarSvgIcon from '@resources/assets/svg/icons/star.svg';
import StarFilledSvgIcon from '@resources/assets/svg/icons/star-fill.svg';
import LocationSvgIcon from '@resources/assets/svg/icons/location.svg';
import PriceSvgIcon from '@resources/assets/svg/icons/price.svg';
import CircleCheckSvgIcon from '@resources/assets/svg/icons/circle-check.svg';
import CircleCheckEmptySvgIcon from '@resources/assets/svg/icons/circle-check-empty.svg';
import Face03SvgIcon from '@resources/assets/svg/icons/face-03.svg';
import Face03FillSvgIcon from '@resources/assets/svg/icons/face-03-fill.svg';
import RocketSvgIcon from '@resources/assets/svg/icons/rocket.svg';
import RocketFillSvgIcon from '@resources/assets/svg/icons/rocket-fill.svg';
import RetrySvgIcon from '@resources/assets/svg/icons/retry.svg';
import WarningSvgIcon from '@resources/assets/svg/icons/warning.svg';
import CommercialSvgIcon from '@resources/assets/svg/icons/retail.svg';
import MeasureSvgIcon from '@resources/assets/svg/icons/measure.svg';
import LandSvgIcon from '@resources/assets/svg/icons/land.svg';
import OptionsSvgIcon from '@resources/assets/svg/icons/options.svg';
import SortAscending from '@resources/assets/svg/icons/sort-ascending.svg';
import SortDescending from '@resources/assets/svg/icons/sort-descending.svg';
import ClockSvgIcon from '@resources/assets/svg/icons/clock.svg';
import AddVisitSvgIcon from '@resources/assets/svg/icons/add-visit.svg';
import ParkingInteriorIcon from '@resources/assets/svg/icons/parking-interior.svg';
import ParkingExteriorIcon from '@resources/assets/svg/icons/parking-exterior.svg';
import BalconyIcon from '@resources/assets/svg/icons/balcony.svg';
import CopyIcon from '@resources/assets/svg/icons/copy.svg';
import CopyMIcon from '@resources/assets/svg/icons/copy-m.svg';
import CheckIcon from '@resources/assets/svg/icons/check.svg';
import RefreshIcon from '@resources/assets/svg/icons/refresh.svg';
import OffersIcon from '@resources/assets/svg/icons/offers.svg';
import CashHeapsIcon from '@resources/assets/svg/icons/cash-heaps.svg';
import CircleAddIcon from '@resources/assets/svg/icons/circle-add.svg';
import CircleInfoIcon from '@resources/assets/svg/icons/circle-info.svg';
import CircleCanceledIcon from '@resources/assets/svg/icons/circle-canceled.svg';
import CircleQuestionIcon from '@resources/assets/svg/icons/circle-question.svg';
import CircleReverseIcon from '@resources/assets/svg/icons/circle-reverse.svg';
import CircleSuccessIcon from '@resources/assets/svg/icons/circle-success.svg';
import DotsIcon from '@resources/assets/svg/icons/dots.svg';
import OfferMoreIcon from '@resources/assets/svg/icons/offer-more.svg';
import OfferLessIcon from '@resources/assets/svg/icons/offer-less.svg';
import OfferPlusIcon from '@resources/assets/svg/icons/offer-plus.svg';
import OfferColumnLess from '@resources/assets/svg/icons/offer-column-less.svg';
import OfferColumnMore from '@resources/assets/svg/icons/offer-column-more.svg';
import OfferColumnEqual from '@resources/assets/svg/icons/offer-column-equal.svg';
import ArrowReturnIcon from '@resources/assets/svg/icons/arrow-return.svg';
import WarningYellowIcon from '@resources/assets/svg/icons/warning-yellow.svg';
import Delete from '@resources/assets/svg/icons/delete.svg';
import Drag from '@resources/assets/svg/icons/drag.svg';
import Globe from '@resources/assets/svg/icons/globe.svg';
import Paperclip from '@resources/assets/svg/icons/paperclip.new.svg';

import NewSaleIcon from '@resources/assets/svg/icons/price.new.svg';
import NewLetIcon from '@resources/assets/svg/icons/let.new.svg';
import NewProjectIcon from '@resources/assets/svg/icons/project.new.svg';
import NewHouseIcon from '@resources/assets/svg/icons/house.new.svg';
import NewApartmentIcon from '@resources/assets/svg/icons/apartment.new.svg';
import NewLandIcon from '@resources/assets/svg/icons/land.new.svg';
import NewOfficeIcon from '@resources/assets/svg/icons/office.new.svg';
import NewParkingIcon from '@resources/assets/svg/icons/parking.new.svg';
import NewRetailsIcon from '@resources/assets/svg/icons/retail.new.svg';

import AmenityAttic from '@resources/assets/svg/icons/amenities/attic.svg';
import AmenityBalcony from '@resources/assets/svg/icons/amenities/balcony.svg';
import AmenityBasement from '@resources/assets/svg/icons/amenities/basement.svg';
import AmenityBedroom from '@resources/assets/svg/icons/amenities/bedroom.svg';
import AmenityClimateControl from '@resources/assets/svg/icons/amenities/climate-control.svg';
import AmenityConcierge from '@resources/assets/svg/icons/amenities/concierge.svg';
import AmenityCoolingRoom from '@resources/assets/svg/icons/amenities/cooling-room.svg';
import AmenityDisplayWindows from '@resources/assets/svg/icons/amenities/display-windows.svg';
import AmenityDrainage from '@resources/assets/svg/icons/amenities/drainage.svg';
import AmenityFence from '@resources/assets/svg/icons/amenities/fence.svg';
import AmenityGarden from '@resources/assets/svg/icons/amenities/garden.svg';
import AmenityGuestHouse from '@resources/assets/svg/icons/amenities/guesthouse.svg';
import AmenityLift from '@resources/assets/svg/icons/amenities/lift.svg';
import AmenityManualGate from '@resources/assets/svg/icons/amenities/manual-gate.svg';
import AmenityParkingExterior from '@resources/assets/svg/icons/amenities/parking-exterior.svg';
import AmenityParkingInterior from '@resources/assets/svg/icons/amenities/parking-interior.svg';
import AmenityParking from '@resources/assets/svg/icons/amenities/parking.svg';
import AmenityPool from '@resources/assets/svg/icons/amenities/pool.svg';
import AmenityPrintAndCopy from '@resources/assets/svg/icons/amenities/print-and-copy.svg';
import AmenityReceptionArea from '@resources/assets/svg/icons/amenities/reception-area.svg';
import AmenityRemoteControl from '@resources/assets/svg/icons/amenities/remote-control.svg';
import AmenityRoadAccess from '@resources/assets/svg/icons/amenities/road-access.svg';
import AmenityServerRoom from '@resources/assets/svg/icons/amenities/server-room.svg';
import AmenityServices from '@resources/assets/svg/icons/amenities/services.svg';
import AmenitySewerAccess from '@resources/assets/svg/icons/amenities/sewer-access.svg';
import AmenityStorageSpace from '@resources/assets/svg/icons/amenities/storage-space.svg';
import AmenityTerrace from '@resources/assets/svg/icons/amenities/terrace.svg';
import AmenityUtilitiesAccess from '@resources/assets/svg/icons/amenities/utilities-access.svg';
import AmenityWaitingArea from '@resources/assets/svg/icons/amenities/waiting-area.svg';
import AmenityWaterAccess from '@resources/assets/svg/icons/amenities/water-access.svg';

const svgIcons = {
    'caret-right': CaretRightSvgIcon,
    'caret-left': CaretLeftSvgIcon,
    edit: EditSvgIcon,
    mail: MailSvgIcon,
    movebotler: MoveBotlerIcon,
    'movebotler-fill': MoveBotlerFilledIcon,
    'house-new': HouseNewSvgIcon,
    'house-mint': HouseMintSvgIcon,
    'house-good': HouseGoodSvgIcon,
    'house-fair': HouseFairSvgIcon,
    'house-poor': HousePoorSvgIcon,
    'house-fill': HouseFillSvgIcon,
    'add-activity': AddActivitySvgIcon,
    chevron: ChevronDownSvgIcon,
    structure: StructureSvgIcon,
    rooms: RoomsSvgIcon,
    parking: ParkingSvgIcon,
    'interior-parking': ParkingInteriorIcon,
    'exterior-parking': ParkingExteriorIcon,
    balcony: BalconyIcon,
    garden: GardenSvgIcon,
    apartment: ApartmentSvgIcon,
    archived: ArchivedSvgIcon,
    'speech-bubble': SpeechBubbleSvgIcon,
    phone: PhoneSvgIcon,
    'circle-delete': CircleDeleteSvgIcon,
    'circle-clock': CircleClockSvgIcon,
    'status-changed': StatusChangedSvgIcon,
    error: ErrorSvgIcon,
    help: HelpSvgIcon,
    social: SocialSvgIcon,
    'social-fill': SocialFillSvgIcon,
    google: GoogleSvgIcon,
    'google-fill': GoogleFillSvgIcon,
    calendar: CalendarSvgIcon,
    description: DescriptionSvgIcon,
    terrace: TerraceSvgIcon,
    pool: PoolSvgIcon,
    attic: AtticSvgIcon,
    basement: BasementSvgIcon,
    lift: LiftSvgIcon,
    guesthouse: GuestHouseSvgIcon,
    'electrical-gate': ElectricalGateSvgIcon,
    'manual-gate': ManualGateSvgIcon,
    fence: FenceSvgIcon,
    code: CodeSvgIcon,
    'key-card': KeyCardSvgIcon,
    'remote-control': RemoteControlSvgIcon,
    'climate-control': ClimateControlSvgIcon,
    'water-acces': WaterAccessSvgIcon,
    'sewer-acces': SewerAccessSvgIcon,
    drainage: DrainageSvgIcon,
    'road-acces': RoadAccessSvgIcon,
    'utilities-acces': UtilitiesAccessSvgIcon,
    'print-and-copy': PrintAndCopySvgIcon,
    'storage-space': StorageSpaceSvgIcon,
    'server-room': ServerRoomSvgIcon,
    'reception-area': ReceptionAreaSvgIcon,
    'waiting-area': WaitingAreaSvgIcon,
    'cooling-room': CoolingRoomSvgIcon,
    'display-windows': DisplayWindowsSvgIcon,
    house: HouseSvgIcon,
    visit: VisitSvgIcon,
    notes: NotesSvgIcon,
    user: UserSvgIcon,
    update: UpdateSvgIcon,
    'update-phat': UpdateFatSvgIcon,
    publish: PublishSvgIcon,
    unpublish: UnpublishSvgIcon,
    star: StarSvgIcon,
    'star-fill': StarFilledSvgIcon,
    location: LocationSvgIcon,
    price: PriceSvgIcon,
    'circle-check': CircleCheckSvgIcon,
    'circle-check-empty': CircleCheckEmptySvgIcon,
    'face-03': Face03SvgIcon,
    'face-03-fill': Face03FillSvgIcon,
    rocket: RocketSvgIcon,
    'rocket-fill': RocketFillSvgIcon,
    retry: RetrySvgIcon,
    warning: WarningSvgIcon,
    commercial: CommercialSvgIcon,
    measure: MeasureSvgIcon,
    land: LandSvgIcon,
    options: OptionsSvgIcon,
    'sort-ascending': SortAscending,
    'sort-descending': SortDescending,
    clock: ClockSvgIcon,
    'add-visit': AddVisitSvgIcon,
    copy: CopyIcon,
    'copy-m': CopyMIcon,
    check: CheckIcon,
    refresh: RefreshIcon,
    offers: OffersIcon,
    'cash-heaps': CashHeapsIcon,
    'circle-add': CircleAddIcon,
    'circle-info': CircleInfoIcon,
    'circle-canceled': CircleCanceledIcon,
    'circle-question': CircleQuestionIcon,
    'circle-reverse': CircleReverseIcon,
    'circle-success': CircleSuccessIcon,
    dots: DotsIcon,
    'offer-more': OfferMoreIcon,
    'offer-less': OfferLessIcon,
    'offer-plus': OfferPlusIcon,
    'offer-column-less': OfferColumnLess,
    'offer-column-more': OfferColumnMore,
    'offer-column-equal': OfferColumnEqual,
    'arror-return': ArrowReturnIcon,
    'warning-yellow': WarningYellowIcon,
    'new-sale': NewSaleIcon,
    'new-let': NewLetIcon,
    'new-project': NewProjectIcon,
    'new-house': NewHouseIcon,
    'new-apartment': NewApartmentIcon,
    'new-land': NewLandIcon,
    'new-office': NewOfficeIcon,
    'new-parking': NewParkingIcon,
    'new-commercial': NewRetailsIcon,
    'amenity-attic': AmenityAttic,
    'amenity-balcony': AmenityBalcony,
    'amenity-basement': AmenityBasement,
    'amenity-bedroom': AmenityBedroom,
    'amenity-climate-control': AmenityClimateControl,
    'amenity-concierge': AmenityConcierge,
    'amenity-cooling-room': AmenityCoolingRoom,
    'amenity-display-window': AmenityDisplayWindows,
    'amenity-drainage': AmenityDrainage,
    'amenity-fence': AmenityFence,
    'amenity-garden': AmenityGarden,
    'amenity-guesthouse': AmenityGuestHouse,
    'amenity-lift': AmenityLift,
    'amenity-manual-gate': AmenityManualGate,
    'amenity-exterior-parking': AmenityParkingExterior,
    'amenity-interior-parking': AmenityParkingInterior,
    'amenity-parking': AmenityParking,
    'amenity-pool': AmenityPool,
    'amenity-print-and-copy-area': AmenityPrintAndCopy,
    'amenity-reception-area': AmenityReceptionArea,
    'amenity-remote-control': AmenityRemoteControl,
    'amenity-road-access': AmenityRoadAccess,
    'amenity-server-room': AmenityServerRoom,
    'amenity-services': AmenityServices,
    'amenity-sewer-access': AmenitySewerAccess,
    'amenity-storage-space': AmenityStorageSpace,
    'amenity-terrace': AmenityTerrace,
    'amenity-utilities-access': AmenityUtilitiesAccess,
    'amenity-waiting-area': AmenityWaitingArea,
    'amenity-water-access': AmenityWaterAccess,
    delete: Delete,
    drag: Drag,
    globe: Globe,
    'new-paperclip': Paperclip,
};

export const ICON_SIZE_SMALL = '24';
export const ICON_SIZE_MEDIUM = '32';

//See: https://github.com/sindresorhus/type-fest/blob/main/source/literal-union.d.ts
export type IconName = keyof typeof svgIcons | (string & Record<never, never>);

type Props = {
    icon: IconName;
    type?: string;
    onClick?: () => void;
    size?: number | string;
    style?: React.CSSProperties;
    className?: string;
    useSvg?: boolean;
    inline?: boolean;
};

const Icon: React.FunctionComponent<Props & React.HTMLAttributes<HTMLDivElement>> = (
    { icon, size, type, className, onClick, style, useSvg = true, inline, ...rest },
    forwardedRef,
) => {
    function getSVGSizeProps() {
        if (size) {
            return {
                width: size,
                height: size,
            };
        }

        return {
            width: inline ? undefined : '1em',
            height: '1em',
        };
    }

    if (typeof size === 'number') {
        style = { ...style, width: size, height: size };
    }

    const classes = classNames(
        'glyphicon',
        {
            [`glyphicon-${icon}`]: icon,
            [`glyphicon-${size}`]: size,
            [`glyphicon-${type}`]: type,
        },
        className,
    );

    let actionProps = {};

    if (onClick) {
        // for accessibility
        actionProps = {
            onClick,
            role: 'button',
            tabIndex: '-1',
        };
    }

    if (useSvg && icon in svgIcons) {
        const SvgIcon = svgIcons[icon];

        return (
            <div
                ref={forwardedRef}
                className={classNames('inline-flex items-center justify-center', className)}
                style={style}
                {...rest}
            >
                <SvgIcon className={`c-icon__${icon}`} {...getSVGSizeProps()} {...actionProps} />
            </div>
        );
    }

    return <i ref={forwardedRef} className={classes} style={style} {...actionProps} {...rest} />;
};

// @ts-ignore
export default React.forwardRef(Icon);
