import React, { useContext } from 'react';
import moment from 'moment';
import { Link } from 'react-router';
import { useIntl } from 'react-intl';
import { MenuItem } from 'react-bootstrap';
import Dropdown from '@sweepbright/uikit/build/esm/dropdown';
import { useToasts } from '@sweepbright/notifications';
import { FormattedMessage } from 'react-intl-sweepbright';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';
import { Icon } from '@/app.components';
import { useArchiveOffer, useCancelOffer } from '@/app.hooks/offer/offerMutations';
import { fullName } from '@/app.utils/services/Helpers';
import { formatPrice } from '@/app.utils/services/Helpers/priceFormatting';
import { FormattedLocation } from '@/app.components/localize/FormattedLocation';
import { LayoutContext } from '@/app.components/layouts/utils';
import useContact from '@/app.hooks/useContact';
import { ContactLink } from '@/app.components/card/ActivityCard/elements/elements';
import { getOfferExpires, OfferContext } from '../../utils';

type Props = {
    record: OfferCardFragmentFragment;
};

const OfferCardHeader: React.FC<Props> = props => {
    const { record } = props;

    const intl = useIntl();

    const { domainType, handleOfferStatsRefetch } = useContext(LayoutContext);

    const { refetch, dataAuth, openEditModal } = useContext(OfferContext);

    const { addError } = useToasts();

    const [cancelOffer] = useCancelOffer({ variables: { offerId: record.id || '' } });
    const [archiveOffer] = useArchiveOffer({ variables: { offerId: record.id || '' } });

    const isArchived = !!record.archivedAt;

    const options = {
        format: '%s %v',
        thousand: ' ',
        precision: 0,
    };

    const offerPrice = formatPrice(
        { amount: record.financialDetails.buyerGrossAmount || 0, currency: record.financialDetails.currency },
        options,
    );

    const direction = record.financialDetails.direction;

    const from = direction === 'BUYER_TO_OWNER' ? record.buyers : record.owners;

    const contactId = from && from[0] && from[0]?.id;

    const { contact: contactData, loading: contactLoading } = useContact(contactId);

    const content = {
        from: <FormattedMessage id="offers.card.from" defaultMessage="offer from" />,
        to: <FormattedMessage id="offers.card.to" defaultMessage="to" />,
        counter: <FormattedMessage id="offers.card.counter" defaultMessage="counter" />,
        refused: <FormattedMessage id="offers.card.refused" defaultMessage="refused" />,
        canceled: <FormattedMessage id="offers.card.canceled" defaultMessage="canceled" />,
        registeredBy: <FormattedMessage id="offers.card.registeredBy" defaultMessage="Offer registered by" />,
        unknown: <FormattedMessage id="offers.card.unknown" defaultMessage="Unknown" />,
        edit: <FormattedMessage id="offers.card.edit" defaultMessage="Edit Offer" />,
        cancel: <FormattedMessage id="offers.card.cancel" defaultMessage="Cancel Offer" />,
        archive: <FormattedMessage id="offers.card.archive" defaultMessage="Archive Offer" />,
        expires: <FormattedMessage id="offers.card.expires" defaultMessage="Offer Expires:" />,
    };

    const title = (
        <>
            {offerPrice} {record.parentId ? content.counter : ''} {content.from}{' '}
            {(domainType === 'property' && contactData?.updatedAt && (
                <ContactLink
                    // @ts-ignore
                    contact={contactData}
                />
            )) || (
                <span className="truncate flex-1">
                    {contactLoading && (
                        <FormattedMessage id="components.event_details_popup.loading" defaultMessage="Loading..." />
                    )}
                    {!contactLoading &&
                        (contactData?.firstName || contactData?.lastName
                            ? fullName(contactData?.firstName, contactData?.lastName)
                            : intl.formatMessage({ id: 'general.unknown' }))}
                </span>
            )}{' '}
            {record.status === 'CANCELLED' && content.canceled}
            {record.status === 'REFUSED' && content.refused}
        </>
    );

    const handleRefetch = () => {
        refetch();

        handleOfferStatsRefetch();
    };

    const canEdit = dataAuth?.find(authEl => authEl.requestId === `${record.id}-edit`)?.allowed;
    const canCancel = dataAuth?.find(authEl => authEl.requestId === `${record.id}-cancel`)?.allowed;
    const canArchive = dataAuth?.find(authEl => authEl.requestId === `${record.id}-archive`)?.allowed;

    const offerExpires = getOfferExpires(record.validUntil);

    return (
        <div className="offer-card-header">
            <div className="offer-card-header__main">
                <h4 className="offer-card-header__title">{title}</h4>
                <span className="offer-card-header__info">
                    {domainType === 'contact' && record.property ? (
                        <Link className="truncate" to={`/properties/${record.propertyId}`}>
                            <FormattedLocation location={record?.property?.attributes?.location} />
                        </Link>
                    ) : (
                        <FormattedLocation location={record?.property?.attributes?.location} />
                    )}
                    <span className="offer-card-header__divider"> — </span>
                    {content.registeredBy}
                    <span className="offer-card-header__name">
                        {fullName(record?.creator?.firstName, record?.creator?.lastName) || content.unknown}
                    </span>
                </span>
                {!!offerExpires && (
                    <span className="offer-card-header__expires">
                        {content.expires} {offerExpires}
                    </span>
                )}
            </div>
            <span className="offer-card-header__date">
                {moment(record.archivedAt || record.updatedAt || record.createdAt).format('D MMM, H:mm')}
            </span>
            {!isArchived && (canEdit || canCancel || canArchive) && (
                <div className="offer-card-header__menu">
                    <Dropdown>
                        <Dropdown.Toggle>
                            <Icon icon="dots" size={15} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <>
                                {(record.status === 'PENDING' || record.status === 'ACCEPTED') && (
                                    <>
                                        {canEdit && (
                                            <MenuItem
                                                onSelect={() => {
                                                    openEditModal(record.id || '');
                                                }}
                                            >
                                                {content.edit}
                                            </MenuItem>
                                        )}
                                        {canCancel && (
                                            <MenuItem
                                                onSelect={() => {
                                                    cancelOffer()
                                                        .catch(err => {
                                                            const isForbidden = err?.message?.includes(
                                                                'are not authorized',
                                                            );

                                                            addError({
                                                                message: isForbidden ? (
                                                                    <FormattedMessage id="unauthorised_403" />
                                                                ) : (
                                                                    <FormattedMessage
                                                                        id="form.status.error"
                                                                        defaultMessage="Could not save"
                                                                    />
                                                                ),
                                                            });
                                                        })
                                                        .finally(() => {
                                                            handleRefetch();
                                                        });
                                                }}
                                            >
                                                {content.cancel}
                                            </MenuItem>
                                        )}
                                    </>
                                )}
                                {(record.status === 'REFUSED' || record.status === 'CANCELLED') && canArchive && (
                                    <MenuItem
                                        onSelect={() => {
                                            archiveOffer()
                                                .catch(err => {
                                                    const isForbidden = err?.message?.includes('are not authorized');

                                                    addError({
                                                        message: isForbidden ? (
                                                            <FormattedMessage id="unauthorised_403" />
                                                        ) : (
                                                            <FormattedMessage
                                                                id="form.status.error"
                                                                defaultMessage="Could not save"
                                                            />
                                                        ),
                                                    });
                                                })
                                                .finally(() => {
                                                    handleRefetch();
                                                });
                                        }}
                                    >
                                        {content.archive}
                                    </MenuItem>
                                )}
                            </>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )}
        </div>
    );
};

export default OfferCardHeader;
