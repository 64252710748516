import React, { useCallback } from 'react';
import { Button } from '@sweepbright/uikit';
import Portal from 'react-overlays/Portal';
import { Dropdown as DropdownBase, useDropdownToggle, useDropdownMenu } from 'react-overlays';
import classNames from 'classnames';
import { DropDirection } from 'react-overlays/DropdownContext';
import { UseDropdownMenuOptions } from 'react-overlays/DropdownMenu';
import Icon from '@/app.components/icons/Icon';
import './Dropdown.scss';

type SelectableContextType = (event: any) => void;
const SelectableContext = React.createContext<SelectableContextType | null>(null);

const Toggle: React.FunctionComponent<any> = React.forwardRef(function Toggle(
    { as: Component = Button, testId, ...props },
    ref,
) {
    const [toggleProps, { show, toggle }] = useDropdownToggle();
    const handleClick = () => {
        toggle(!show);
    };

    return (
        <Component
            data-testid={testId}
            // @ts-ignore
            ref={ref}
            {...props}
            {...toggleProps}
            onClick={handleClick}
        />
    );
});

const Divider = () => {
    return (
        <li className="m-0 overflow-hidden flex-shrink-0" role="divider" style={{ height: 1, background: '#e5e5e5' }} />
    );
};

type MenuItemProps = React.HTMLProps<HTMLButtonElement> & { onSelect?: SelectableContextType; as?: any } & any;

const MenuItem = React.forwardRef(function MenuItem(
    { children, className, onSelect, as: Component = 'button', disabled, ...props }: MenuItemProps,
    ref: React.Ref<HTMLButtonElement>,
) {
    const selectCtx = React.useContext(SelectableContext);

    const { onClick } = props;
    const handleClick = React.useCallback(
        (evt: React.MouseEvent<any>) => {
            if (onSelect) {
                onSelect(evt);
            }

            if (selectCtx && !evt.defaultPrevented) {
                selectCtx(evt);
            }
            if (onClick) {
                onClick(evt);
            }
        },
        [onSelect, selectCtx, onClick],
    );
    const componentProps: any = {};
    if (Component === 'button') {
        // if we use a button inside a form
        // we dont want it to trigger a submission
        componentProps.type = 'button';
    }

    return (
        <li role="presentation">
            <Component
                ref={ref}
                {...props}
                {...componentProps}
                className={classNames(
                    'w-full block no-underline text-left px-3 py-2 bg-transparent  focus:outline focus:outline-none focus:shadow-outline',
                    {
                        'text-gray-dark hover:bg-gray-lightest cursor-default': !disabled,
                        'text-gray-light cursor-not-allowed': disabled,
                    },
                    className,
                )}
                onClick={handleClick}
                disabled={disabled}
            >
                {children}
            </Component>
        </li>
    );
});

const Menu: React.FunctionComponent<React.HTMLProps<HTMLUListElement> & {
    popperConfig?: UseDropdownMenuOptions['popperConfig'];
}> = ({ className, popperConfig = {}, ...props }) => {
    const portalContainerRef = React.useRef<HTMLDivElement>(null);
    const { show, props: menuProps } = useDropdownMenu({
        usePopper: true,
        flip: true,
        // lets add this odd 1px offset
        // because the designer asked for it
        offset: [0, 1],
        popperConfig: {
            ...popperConfig,
            modifiers: (popperConfig?.modifiers ?? []).concat([{ name: 'hide' }]),
        },
    });

    return (
        <div ref={portalContainerRef} className="absolute">
            <Portal container={document.querySelector('body')}>
                <ul
                    {...props}
                    {...menuProps}
                    style={{
                        border: '1px solid rgba(0,0,0,.05)',
                        minWidth: 150,
                        boxShadow: '0 6px 12px rgba(0,0,0,0.175)',
                        ...props.style,
                        ...menuProps.style,
                    }}
                    className={classNames(
                        'flex-col bg-white z-50 py-1 px-0 m-0 list-none',
                        {
                            flex: show,
                            hidden: !show,
                        },
                        className,
                    )}
                />
            </Portal>
        </div>
    );
};

const Dropdown = function Dropdown({
    className,
    drop,
    onToggle,
    ...props
}: React.HTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
    drop?: DropDirection;
    onToggle?: (nextShow: boolean) => void;
}) {
    const selectCtx = React.useContext(SelectableContext);

    const [show, setShow] = React.useState(false);

    const handleToggle = useCallback(
        (nextShow: boolean) => {
            setShow(nextShow);
            onToggle?.(nextShow);
        },
        [onToggle],
    );

    const handleSelect = React.useCallback(
        (evt: React.MouseEvent<any>) => {
            if (selectCtx) {
                selectCtx(evt);
            }
            if (!evt.defaultPrevented) {
                setShow(false);
            }
        },
        [selectCtx],
    );

    return (
        <SelectableContext.Provider value={handleSelect}>
            <DropdownBase show={show} onToggle={handleToggle} itemSelector="button:not(:disabled)" drop={drop}>
                {({ props: dropdownProps }) => {
                    return <div {...dropdownProps} {...props} className={classNames(className, 'relative block')} />;
                }}
            </DropdownBase>
        </SelectableContext.Provider>
    );
};

const SubMenu = ({ label, children, disabled }) => {
    return (
        <Dropdown drop="right">
            <Toggle
                as={MenuItem}
                onSelect={(evt: React.MouseEvent<any>) => {
                    evt.preventDefault();
                }}
                disabled={disabled}
            >
                <div className="flex justify-between items-center">
                    {label} <Icon icon="caret-right" />
                </div>
            </Toggle>
            <Menu
                popperConfig={{
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                boundary: document.querySelector('body'),
                            },
                        },
                    ],
                }}
            >
                {children}
            </Menu>
        </Dropdown>
    );
};

Dropdown.Menu = Menu;
Dropdown.Toggle = Toggle;
Dropdown.MenuItem = MenuItem;
Dropdown.Divider = Divider;
Dropdown.SubMenu = SubMenu;

export default Dropdown;
