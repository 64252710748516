import { OrderedMap } from 'immutable';

/**
 * Dedupe options by their specified value, and remove
 * excluded options from the list
 *
 * @param options
 * @param excluded
 */
export default (options, excluded) => {
    return options.reduce((reduction, option) => {
        return excluded.includes(option.value) ? reduction : reduction.set(option.value, option);
    }, new OrderedMap());
};
