import { LoadingIndicator } from '@sweepbright/uikit';
import React from 'react';
import { FormPanel } from '@/app.components';
import { CalendarIntegrationConnect } from '@/app.domains/profile/Integrations/Calendar/elements/CalendarIntegrationConnect';
import { FormattedMessage } from 'react-intl-sweepbright';
import { CalendarIntegrationConnected } from './elements/CalendarIntegrationConnected';

export default function CalendarIntegrationSection({ userId, connected, loading }) {
    return (
        <FormPanel title={<FormattedMessage id="forms.schedule_visit.title" defaultMessage="Schedule Visit" />}>
            {loading && <LoadingIndicator />}
            {connected && !loading && <CalendarIntegrationConnected userId={userId} />}
            {!connected && !loading && <CalendarIntegrationConnect />}
        </FormPanel>
    );
}
