import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'react-bootstrap';
import Icon, { ICON_SIZE_MEDIUM } from '../icons/Icon';

const EmptyAlert = ({ body, icon, title, children }) => (
    <Alert bsStyle="info">
        {icon && <Icon icon={icon} size={ICON_SIZE_MEDIUM} />}
        {icon && <br />}
        {title ? <h2 className="alert-title">{title}</h2> : null}
        <p style={{ marginTop: icon ? 10 : 'auto' }}>{body || children}</p>
    </Alert>
);

EmptyAlert.propTypes = {
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.string,
    children: PropTypes.node,
};

export default EmptyAlert;
