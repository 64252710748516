import { createContext } from 'react';

export const PropertyFormDescriptionContext = createContext<{
    visible: string;
    fieldName: string;
    setVisible: Function;
    showTranslate: boolean;
    generateLoading: boolean;
    translateLoading: boolean;
    handleTranslate: Function;
    setGenerateSettings: Function;
    content: { [key: string]: string };
    generateSettings: { [key: string]: boolean };
    handleGenerate: (fieldName: string, extraText: string) => void;
}>({
    content: {},
    visible: '',
    fieldName: '',
    showTranslate: false,
    setVisible: () => {},
    generateSettings: {},
    generateLoading: false,
    translateLoading: false,
    handleGenerate: () => {},
    handleTranslate: () => {},
    setGenerateSettings: () => {},
});
