export const FETCH_USER = 'users/FETCH_USER';
export const FETCH_USER_FAILED = 'users/FETCH_USER_FAILED';
export const FETCH_USERS = 'users/FETCH_USERS';
export const SET_USER = 'users/SET_USER';
export const SET_USERS = 'users/SET_USERS';
export const ARCHIVE_USER = 'users/ARCHIVE_USER';
export const SET_USER_OFFICE = 'users/SET_USER_OFFICE';

export const FETCH_USER_VIEWS = 'users/FETCH_VIEWS';
export const SET_USER_VIEWS = 'users/SET_VIEWS';
export const MARK_FILE_AS_VIEWED = 'users/MARK_FILE_AS_VIEWED';

export function fetchUsers() {
    return { type: FETCH_USERS };
}

export function setUsers(users) {
    return { type: SET_USERS, users };
}

export function fetchUser() {
    return { type: FETCH_USER };
}

export function fetchUserFailed() {
    return { type: FETCH_USER_FAILED };
}

export function setUser(user) {
    return { type: SET_USER, user };
}

export function archiveUser(userId) {
    return { type: ARCHIVE_USER, userId };
}

export function setUserOffice({ id, resolve, reject }) {
    return { type: SET_USER_OFFICE, officeId: id, resolve, reject };
}

export function setUserViews(userId, views, resource) {
    return { type: SET_USER_VIEWS, userId, views, resource };
}

export function fetchUserViews(resource) {
    return { type: FETCH_USER_VIEWS, resource };
}

export function markFileAsViewed(fileId) {
    return { type: MARK_FILE_AS_VIEWED, fileId };
}
