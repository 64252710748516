import { Map } from 'immutable';
import { InjectedRouter } from 'react-router';
import * as routes from '@/app.routing/routes';
import { isCompanyAdmin, isTeamAdmin } from '@/app.data';
import { defineMessages } from 'react-intl-sweepbright';

// utility function to check is link is active
function isOnRoute(location: string, router: InjectedRouter) {
    return router.isActive(location, false);
}

export type NavLink = {
    divider?: boolean;
    home?: boolean;
    isAllowedFor?: (user?: Map<string, any>) => boolean;
    key?: string;
    to?: (user?: Map<string, any>) => string;
    isActive?: (link: string, router: InjectedRouter) => boolean;
};

export const MAIN_LINKS: NavLink[] = [
    {
        key: 'home',
        to: () => '/',
        isAllowedFor: () => true,
        isActive: (link, router) => {
            return [routes.PROPERTIES, routes.CONTACTS].some(link => isOnRoute(link, router));
        },
        home: true,
    },
    {
        key: 'company',
        to: () => routes.COMPANY,
        isAllowedFor: isCompanyAdmin,
        isActive: (link, router) => {
            return (
                isOnRoute('/company', router) ||
                isOnRoute('/subscription', router) ||
                isOnRoute('/subscription/upgrade', router)
            );
        },
    },
    {
        key: 'members',
        to: () => routes.MEMBERS,
        isActive: (_link, router) => {
            return isOnRoute('/members', router);
        },
        isAllowedFor: user => isTeamAdmin(user) || isCompanyAdmin(user),
    },
    {
        key: 'teams',
        to: () => routes.TEAMS,
        isActive: (_link, router) => {
            return isOnRoute('/teams', router);
        },
        isAllowedFor: user => isTeamAdmin(user) || isCompanyAdmin(user),
    },
    {
        divider: true,
        isAllowedFor: user => isCompanyAdmin(user),
    },
    {
        key: 'leads',
        to: () => routes.LEADS,
        isAllowedFor: isCompanyAdmin,
        isActive: (link, router) => {
            return isOnRoute('/leads', router);
        },
    },
    {
        divider: true,
        isAllowedFor: user => isCompanyAdmin(user),
    },
    {
        key: 'channels',
        isAllowedFor: isCompanyAdmin,
        isActive: isOnRoute,
        to: () => routes.CHANNELS,
    },
    {
        key: 'integrations',
        isAllowedFor: isCompanyAdmin,
        isActive: isOnRoute,
        to: () => routes.INTEGRATIONS,
    },
];

defineMessages({
    'navigation.links.home': {
        id: 'navigation.links.home',
    },
    'navigation.links.profile': {
        id: 'navigation.links.profile',
    },
    'navigation.links.company': {
        id: 'navigation.links.company',
    },
    'navigation.links.teams': {
        id: 'navigation.links.teams',
    },
    'navigation.links.leads': {
        id: 'navigation.links.leads',
    },
    'navigation.links.channels': {
        id: 'navigation.links.channels',
    },
    'navigation.links.integrations': {
        id: 'navigation.links.integrations',
    },
});
