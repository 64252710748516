import React from 'react';
import { Map } from 'immutable';
import Loadable from 'react-loadable';
import UpgradeWizzard from '@/app.domains/subscription/common/UpgradeWizzard';

function loadStep(step: string) {
    return Loadable({
        loader: () => import(/* webpackExclude: /__tests__/ */ `./steps/${step}`),
        loading: () => null,
    });
}

const StepOne = loadStep('StepOne');
const StepTwo = loadStep('StepTwo');

const StepThree = loadStep('StepThree');

const StepFour = loadStep('StepFour');

type Props = {
    company: Map<string, any>;
    fetchCompanyCard: (companyId: string) => void;
};

const Upgrade: React.FunctionComponent<Props> = props => {
    const { company } = props;
    props.fetchCompanyCard(company.get('id'));
    //preload all the steps
    React.useEffect(() => {
        StepOne.preload();
        StepTwo.preload();
        StepThree.preload();
        StepFour.preload();
    }, []);

    return (
        <React.Fragment>
            <UpgradeWizzard>
                <StepOne />
                <StepTwo />
                <StepThree />
                <StepFour />
            </UpgradeWizzard>
        </React.Fragment>
    );
};

export default Upgrade;
