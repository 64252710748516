import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { updatePropertyStatus } from '@/graphql/mutations/properties/updatePropertyStatus';
import { GET_ESTATE_STATUS_QUERY } from '@/app.domains/properties/StatusForm';
import { useToasts } from '@sweepbright/notifications';
import useFeatureFlag from './useFeatureFlag';

export function usePropertyStatus(propertyId) {
    const showEstimate = useFeatureFlag('property.status.estimate');

    const { data } = useQuery(GET_ESTATE_STATUS_QUERY, {
        variables: {
            estateId: propertyId,
        },
        skip: !propertyId,
        returnPartialData: true,
        partialRefetch: true,
    });

    const status = showEstimate
        ? data?.estate?.status || null
        : (data?.estate?.status === 'estimate' && 'prospect') || data?.estate?.status || null;

    const { addNotice, addError } = useToasts();

    const [updateStatus] = useMutation(updatePropertyStatus, {
        onCompleted() {
            addNotice({
                message: <FormattedMessage id="property.status.updated" defaultMessage="Property status updated" />,
            });
        },
        onError() {
            addError({
                message: (
                    <FormattedMessage
                        id="property.status.not_updated"
                        defaultMessage="The property status could not be changed"
                    />
                ),
            });
        },
    });

    const setStatus = useCallback(
        newStatus => {
            return updateStatus({
                variables: {
                    id: propertyId,
                    status: newStatus,
                },
            });
        },
        [propertyId, status],
    );

    return [status, setStatus];
}
