import React from 'react';
import classNames from 'classnames';
import './LayoutColumn.scss';

type Sizes = 'small' | 'tiny' | 'wide' | 'medium' | 'large';

const LayoutColumn: React.FunctionComponent<{ className?: string; size?: Sizes }> = ({
    children,
    className,
    size = 'wide',
    ...props
}) => {
    return (
        <div
            className={classNames(
                'l-column',
                {
                    'l-column--tiny': size === 'tiny',
                    'l-column--small': size === 'small',
                    'l-column--medium': size === 'medium',
                    'l-column--wide': size === 'wide',
                    'l-column--large': size === 'large',
                },
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
};

export default LayoutColumn;
