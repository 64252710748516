import React, { useContext, useState } from 'react';
import { Button } from '@sweepbright/uikit';
import { Formik } from 'formik';
import Modal from '@/app.components/elements/Modal';
import { useToasts } from '@sweepbright/notifications';
import { FormattedMessage } from 'react-intl-sweepbright';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';
import { useCreateOffer, useUpdateOffer } from '@/app.hooks/offer/offerMutations';
import useOffice from '@/app.hooks/useOffice';
import useContact from '@/app.hooks/useContact';
import { LayoutContext } from '@/app.components/layouts/utils';
import { formatOfferData, OfferContext } from '../utils';
import OfferModalDate from './OfferModalDate';
import OfferModalDetails from './OfferModalDetails';
import OfferModalNotes from './OfferModalNotes';
import OfferModalContacts from './OfferModalContacts';
import OfferModalProperty from './OfferModalProperty';

type Props = {
    record?: OfferCardFragmentFragment;
    parentRecord?: OfferCardFragmentFragment;
    counterPropertyId?: string;
    onClose: () => void;
};

const OfferModal: React.FC<Props> = props => {
    const { record, parentRecord, onClose } = props;

    const { handleOfferStatsRefetch } = useContext(LayoutContext);

    const { visible, refetch, defaultPropertyId, defaultContactId, counterParentId } = useContext(OfferContext);

    const { contact } = useContact(visible ? defaultContactId : undefined);

    const { addError } = useToasts();

    const office = useOffice();
    const currency = office.getIn(['settings', 'data', 'currency'], 'EUR');

    const [loading, setLoading] = useState(false);

    const [propertyNegotiation, setPropertyNegotiation] = useState<'let' | 'sale'>('sale');

    const [propertyOfficeId, setPropertyOfficeId] = useState<string | undefined>(undefined);

    const [createOffer] = useCreateOffer({ variables: undefined });
    const [updateOffer] = useUpdateOffer({ variables: undefined });

    const mode = record ? 'EDIT' : 'ADD';

    const formOffer = mode === 'EDIT' ? updateOffer : createOffer;

    const content = {
        title: <FormattedMessage id={`offers.modals.${mode.toLowerCase()}.title`} defaultMessage="Add offer" />,
        titleCounter: (
            <FormattedMessage id={`offers.modals.${mode.toLowerCase()}.counter`} defaultMessage="Add counter-offer" />
        ),
        cancel: <FormattedMessage id="offers.modals.cancel" defaultMessage="Cancel" />,
        submit: <FormattedMessage id={`offers.modals.submit.${mode.toLowerCase()}`} defaultMessage="Add Offer" />,
        loading: <FormattedMessage id="offers.modals.loading" defaultMessage="Submitting..." />,
    };

    const handleRefetch = () => {
        refetch();

        handleOfferStatsRefetch();
    };

    return (
        <Modal
            width={980}
            // @ts-ignore
            show={visible}
            onHide={() => {
                onClose();
                setLoading(false);
            }}
        >
            <Formik
                initialValues={
                    record ||
                    (parentRecord && {
                        ...parentRecord,
                        parentId: parentRecord.id,
                        id: undefined,
                        financialDetails: {
                            ...parentRecord.financialDetails,
                            direction:
                                parentRecord.financialDetails.direction === 'BUYER_TO_OWNER'
                                    ? 'OWNER_TO_BUYER'
                                    : 'BUYER_TO_OWNER',
                        },
                    }) || {
                        financialDetails: {
                            direction: 'BUYER_TO_OWNER',
                            currency: currency || 'EUR',
                            buyerFixedFee: 0,
                            buyerPercentageFee: 0,
                            ownerFixedFee: 0,
                            ownerPercentageFee: 0,
                            buyerGrossAmount: 0,
                            ownerNetAmount: 0,
                        },
                        ...(contact
                            ? {
                                  buyers: contact && contact?.type === 'LEAD' ? [contact] : [],
                                  owners: contact && contact?.type === 'VENDOR' ? [contact] : [],
                              }
                            : {}),
                        ...(counterParentId && { parentId: counterParentId }),
                        ...(defaultPropertyId && { propertyId: defaultPropertyId }),
                    }
                }
                onSubmit={newValues => {
                    setLoading(true);

                    formOffer({
                        variables: {
                            // @ts-ignore
                            offerInput: formatOfferData(newValues, mode),
                        },
                    })
                        .then(() => {
                            handleRefetch();
                        })
                        .catch(err => {
                            const isForbidden = err?.message?.includes('are not authorized');

                            addError({
                                message: isForbidden ? (
                                    <FormattedMessage id="unauthorised_403" />
                                ) : (
                                    <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                                ),
                            });
                        })
                        .finally(() => {
                            onClose();
                            setLoading(false);
                        });
                }}
            >
                {({ values, handleSubmit }) => {
                    const canSubmit =
                        values.propertyId &&
                        values?.buyers &&
                        values?.buyers?.length > 0 &&
                        values?.owners &&
                        values?.owners?.length > 0 &&
                        values.financialDetails.buyerGrossAmount > 0 &&
                        values.financialDetails.ownerNetAmount > 0;

                    return (
                        <form
                            onSubmit={event => {
                                event.preventDefault();
                            }}
                            className="offer-modal"
                        >
                            <Modal.Header>
                                <Modal.Title>{counterParentId ? content.titleCounter : content.title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="offer-modal__body">
                                <div>
                                    <OfferModalDetails propertyNegotiation={propertyNegotiation} />

                                    <OfferModalDate />
                                </div>
                                <div>
                                    <OfferModalContacts
                                        mode={mode}
                                        type="lead"
                                        propertyNegotiation={propertyNegotiation}
                                    />

                                    <OfferModalContacts
                                        mode={mode}
                                        type="vendor"
                                        propertyOfficeId={propertyOfficeId}
                                        propertyNegotiation={propertyNegotiation}
                                    />

                                    <OfferModalProperty
                                        mode={mode}
                                        setPropertyOfficeId={setPropertyOfficeId}
                                        setPropertyNegotiation={setPropertyNegotiation}
                                    />

                                    <OfferModalNotes />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={onClose}>{content.cancel}</Button>
                                <Button variant="primary" disabled={loading || !canSubmit} onClick={handleSubmit}>
                                    {loading ? content.loading : content.submit}
                                </Button>
                            </Modal.Footer>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default OfferModal;
