import accounting, { CurrencySettings, NumberSettings } from 'accounting';
import { Map } from 'immutable';
import { numeralSystems } from '../app.data';
import currencies from '../app.data/Currencies';

export function configure(settings: Map<string, any>) {
    const currency = currencies[settings.get('currency')];
    const numeralSystem = settings.get('numeral_system') || 'decimal_point';
    const numbers: { decimal: string; thousand: string } = numeralSystems.get(numeralSystem).toJS();

    accounting.settings.currency = {
        ...accounting.settings.currency,
        ...numbers,
        symbol: currency.symbol,
        format: '%s %v',
        precision: 0,
    } as CurrencySettings<string>;

    accounting.settings.number = {
        ...accounting.settings.number,
        ...numbers,
    } as NumberSettings;
}

export function getCurrencySettings(): CurrencySettings<string> {
    return accounting.settings.currency;
}

export function getNumberSettings(): NumberSettings {
    return accounting.settings.number;
}

export function roundPrice(value) {
    const ONE_MILLION = 1000000;
    const ONE_THOUSAND = 1000;

    let _value = value;
    let suffix = '';
    if (value > ONE_MILLION) {
        _value = value / ONE_MILLION;
        suffix = 'M';
    } else if (value > ONE_THOUSAND) {
        _value = value / ONE_THOUSAND;
        suffix = 'K';
    }

    return [_value, suffix];
}
