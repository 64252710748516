// @ts-nocheck
import React from 'react';
import { reduxForm, FormProps } from 'redux-form';
import { Map } from 'immutable';
import Button from '@sweepbright/uikit/build/esm/button';
import Select from '@sweepbright/uikit/build/esm/select';
import gql from 'graphql-tag';
import { FormGroup, HelpBlock, ControlLabel, Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import { FormFields } from '@/../shared/utils/types';
import { signInToChannel } from '@/app.redux/actions';
import FacebookRequests from '@/requests/FacebookRequests';
import { POPUP_BLOCKED_ERROR_CODE, MISSING_PERMISSIONS_ERROR_CODE } from '@/app.redux/sagas/Channels/onSignInToChannel';
import { Icon } from '@/app.components';
import { wrapWithPromise } from '../../../app.utils/services';
import FormPanel from '../../forms/FormPanel/FormPanel';
import Input from '../../forms/Input/Input';
import PortalConfigModalBody from './PortalConfigModalBody';
import { validateSocialPortals } from './utils';

type Fields = FormFields<{
    credentials: {
        accessToken: string;
        accessTokenSecret: string;
        pageName: string;
        pageAccessToken: string;
    };
    name: string;
}>;

type Props = {
    fields: Fields;
    portal: Map<string, any>;
    editing: boolean;
    close: (evt?: any) => void;
    showNameField?: boolean;
    signIn: (attributes: { channel: string; missingPermissions: boolean }) => void;
};

const FacebookImpl: React.FunctionComponent<Props & FormProps> = ({
    fields,
    handleSubmit,
    submitFailed,
    error,
    editing,
    ...props
}) => {
    const [facebookConnectionError, setFacebookConnectionError] = React.useState<React.ReactNode | null>(null);
    function handleLoginToFacebook() {
        setFacebookConnectionError(null);
        wrapWithPromise(props.signIn)({
            channel: 'facebook',
            missingPermissions: true,
        })
            .then((res: { access_token: string }) => {
                fields.credentials.accessTokenSecret.onChange(res.access_token);
            })
            .catch(err => {
                if (err.errorCode === POPUP_BLOCKED_ERROR_CODE) {
                    setFacebookConnectionError(
                        <FormattedMessage
                            id="channels.facebook.popup_blocker_error"
                            defaultMessage="Please disable your pop-up blocker and try again."
                        />,
                    );
                } else if (err.errorCode === MISSING_PERMISSIONS_ERROR_CODE) {
                    setFacebookConnectionError(
                        <FormattedMessage
                            id="channels.facebook.permissions_error"
                            defaultMessage="Not all the required permissions were granted. Please add them and try to connect again"
                        />,
                    );
                }
            });
    }

    return (
        <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
            {submitFailed && error ? <Alert bsStyle="danger">{error}</Alert> : null}
            {facebookConnectionError && (
                <Alert bsStyle="warning">
                    <div className="flex">
                        <div className="o-flex__item">{facebookConnectionError}</div>
                        <div>
                            <Icon
                                icon="close"
                                style={{ fontSize: 16 }}
                                onClick={() => {
                                    setFacebookConnectionError(null);
                                }}
                            />
                        </div>
                    </div>
                </Alert>
            )}
            <FormPanel>
                {!fields.credentials.accessTokenSecret.value ? (
                    <Button onClick={handleLoginToFacebook} variant="primary">
                        <FormattedMessage
                            id="forms.social.link.cta"
                            defaultMessage="Link With {channel}"
                            values={{ channel: 'Facebook' }}
                        />
                    </Button>
                ) : (
                    <FacebookPageSelector
                        accessTokenSecret={fields.credentials.accessTokenSecret.value}
                        fields={fields}
                    />
                )}

                <div className="c-spacer-s" />

                <Input
                    {...fields.name}
                    label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                    help={
                        <FormattedMessage
                            id="channels.name.description"
                            defaultMessage="This is the name you will see in the Publish section of SweepBright."
                        />
                    }
                />
            </FormPanel>
        </PortalConfigModalBody>
    );
};

const Facebook = reduxForm(
    {
        form: 'channels/social',
        formKey: 'facebook',
        fields: [
            'credentials.accessToken',
            'credentials.accessTokenSecret',
            'credentials.pageAccessToken',
            'credentials.pageName',
            // the customizable channel name
            'name',
        ],
        validate: validateSocialPortals,
    },
    null,
    {
        signIn: signInToChannel,
    },
)(FacebookImpl);

Facebook.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
            credentials {
                accessToken
                accessTokenSecret
                pageName: name
            }
        }
    `,
};

export default Facebook;

type FacebookPage = {
    id: string;
    name: string;
    access_token: string;
};

function FacebookPageSelector({
    accessTokenSecret,
    fields,
}: {
    error?: string;
    accessTokenSecret: string;
    fields: Fields;
}) {
    const [pages, setPages] = React.useState<FacebookPage[]>([]);

    const pageOptions = React.useMemo(
        () =>
            pages.map(page => ({
                value: page.id,
                label: page.name,
            })),
        [pages],
    );

    const handleChange = (selectedPageId: string) => {
        const page: FacebookPage | undefined = pages.find(page => page.id === selectedPageId);
        setPageFields(page!);
    };

    React.useEffect(() => {
        let isMounted = true;
        new FacebookRequests()
            .withBearerToken(accessTokenSecret)
            .facebookAccounts()
            .then((pages: FacebookPage[]) => {
                if (isMounted) {
                    setPages(pages);
                    // when the list if loaded if no value was previosly selected
                    // we select the first value
                    if (pages.length > 0 && !fields.credentials.accessToken.value) {
                        setPageFields(pages[0]);
                    } else {
                        setPageFields(pages.find(page => page.id === fields.credentials.accessToken.value)!);
                    }
                }
            });

        return () => {
            isMounted = false;
        };
    }, [accessTokenSecret]);

    return (
        <FormGroup validationState={getValidationState()}>
            <ControlLabel>
                <FormattedMessage id="channels.facebook.edit.page_select_label" defaultMessage="Facebook Page" />
            </ControlLabel>
            <Select
                value={fields.credentials.accessToken.value}
                name={fields.credentials.accessToken.name}
                onChange={handleChange}
                options={pageOptions}
                clearable={false}
                placeholder={
                    <FormattedMessage
                        id="channels.facebook.edit.page_select_placeholder"
                        defaultMessage="Select a Facebook page"
                    />
                }
            />
            {getValidationState() === 'error' ? <HelpBlock>{fields.credentials.accessToken.error}</HelpBlock> : null}
        </FormGroup>
    );

    function getValidationState() {
        if (fields.credentials.accessToken.error && fields.credentials.accessToken.touched) {
            return 'error';
        }

        return null;
    }

    function setPageFields(page: FacebookPage) {
        // this is a page access token, it can only be used to interact
        // with that page, we pass it to the API, which
        // uses it to publish to facebook
        fields.credentials.pageAccessToken.onChange(page.access_token);
        fields.credentials.accessToken.onChange(page.id);
        fields.credentials.pageName.onChange(page.name);
    }
}
