import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ContactAvatar } from '@/app.components';
import Phone from '@/app.components/elements/Phone';
import MailAddress from '@/app.components/elements/MailAddress';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { SimpleUser } from '../../types';

type Props = {
    member?: SimpleUser;
};

const MembersPageProfile: React.FC<Props> = props => {
    const { member } = props;

    const imageText =
        member?.photo?.data?.url ||
        `${`${member?.firstName || '-'}`[0]}${`${member?.lastName || '-'}`[0]}`.toUpperCase();

    return (
        <FormPanel>
            <div className="c-util-flex-block c-util-flex-block-align-middle">
                <ContactAvatar large image={imageText} className="c-util-flex-block-element c-spacer-right-l" />
                <div className="o-flex__item">
                    <div className="row">
                        <div className="col-lg-4">
                            <span className="text-muted">
                                <FormattedMessage id="forms.labels.email" defaultMessage="Email" />:
                            </span>
                        </div>
                        <div className="col-lg-11">
                            <span className="text-hyphenate">
                                <MailAddress value={member?.email} />
                            </span>
                        </div>
                    </div>
                    <div className="c-spacer-bottom-s" />
                    <div className="row">
                        <div className="col-lg-4">
                            <span className="text-muted">
                                <FormattedMessage id="forms.labels.phone" defaultMessage="Phone" />:
                            </span>
                        </div>
                        <div className="col-lg-11">
                            <span className="text-hyphenate">
                                <Phone value={member?.phone} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </FormPanel>
    );
};

export default MembersPageProfile;
