import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { propertyTypes } from '@/app.data/Properties';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import TeamWrapper from '@/new.domains/teams/TeamWrapper';
import useOffice from '@/app.hooks/useOffice';
import { TEAM_PRESETS_FOR_PROPERTY } from '../../../../../app.routing/routes';
import SideMenu from '../../../../../app.components/navigations/SideMenu/SideMenu';
import SideMenuLink from '../../../../../app.components/navigations/SideMenu/SideMenuLink';

const PropertyTemplatesLayout = props => {
    const office = useOffice(props?.params?.office);

    if (!office.has('id')) {
        return null;
    }

    return (
        <TeamWrapper {...props}>
            <LayoutContainer flex>
                <LayoutColumn size="medium">
                    <SideMenu color="gray-lighter">
                        {propertyTypes.map(type => {
                            return (
                                <SideMenuLink
                                    key={type}
                                    fuzzyMatch
                                    label={<FormattedMessage id={`property.type.${type}`} />}
                                    icon={type}
                                    to={TEAM_PRESETS_FOR_PROPERTY(office.get('id'), type)}
                                />
                            );
                        })}
                    </SideMenu>
                </LayoutColumn>
                <LayoutColumn>{props.children}</LayoutColumn>
            </LayoutContainer>
        </TeamWrapper>
    );
};

PropertyTemplatesLayout.propTypes = {
    children: PropTypes.node,
    // @ts-ignore
    office: PropTypes.instanceOf(Map),
};

export default PropertyTemplatesLayout;
