import { select, put } from 'redux-saga/effects';
import EstatesRequests from '../../../requests/EstatesRequests';
import { isSaving, fetchProperty } from '../../actions';
import formatApiErrors from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';
import { deleteCompanyToken } from '../../actions/CompaniesActions';
import { getCompany } from '../../selectors/CompaniesSelector';

export default function* onUpdateLoop({ estateId, attributes: { resolve, reject } }) {
    try {
        yield put(isSaving(true));
        yield apiCall(new EstatesRequests().loop, estateId);
        resolve();
        yield put(isSaving(false));
        yield put(fetchProperty(estateId));
    } catch (error) {
        const isUnprocessable = error.response.status === 422;
        const isDotloop = Boolean(
            error.response.data.errors && error.response.data.errors[0].title.includes('Dotloop'),
        );
        if (isUnprocessable && isDotloop) {
            const company = yield select(getCompany);
            yield put(deleteCompanyToken(company.get('id'), 'dotloop'));
            reject({
                _error: 'Impossible to establish connection with Dotloop',
            });
        } else {
            reject(formatApiErrors(error, '_error'));
        }
    }
}
