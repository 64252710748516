import React from 'react';
import { useIntl } from 'react-intl';
import { LoadingIndicator } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import EmptyAlert from '@/app.components/empty/EmptyAlert';
import isScrollEnd from '@/utils/isScrollEnd';
import useUser from '@/app.hooks/useUser';
import { ResourceDomainType, ResourceFilterType } from '@/shared/resource/types';
import ResourceFilterFormMultipleItem from './ResourceFilterFormMultipleItem';
import ResourceFilterFormMultipleGroup from './ResourceFilterFormMultipleGroup';

type Props = {
    id: string;
    query: string;
    tempParams: any;
    setTempParams: any;
    filter: ResourceFilterType;
    intlKey: ResourceDomainType;
    domainType: ResourceDomainType;
};

const ResourceFilterFormMultiple: React.FC<Props> = props => {
    const { id, query, intlKey, filter, setTempParams, tempParams } = props;

    const intl = useIntl();
    const user = useUser();

    const locale = user.get('locale');

    const filteredGroupOptions = filter?.groupOptions?.filter(group => {
        return group.options.some(option => {
            const label = !option.render
                ? option.label ||
                  ((!!option.translateKey || !!option.key || !!option.value) &&
                      intl.formatMessage({
                          id: option.translateKey || `${intlKey}.filter.${id}.${option.key || option.value}`,
                      })) ||
                  ''
                : '';

            return label.toLowerCase().includes(query.toLowerCase());
        });
    });

    return (
        <div
            onScroll={event => {
                if (filter.onScrollEnd) {
                    filter.onScrollEnd(isScrollEnd(event));
                }
            }}
            className={`resource-filter-form__list${filter.overflow ? ' overflow' : ''}${filter.grid ? ' grid' : ''}`}
        >
            {filter?.simpleOptions?.map(option => {
                return (
                    <ResourceFilterFormMultipleItem
                        id={id}
                        key={option}
                        query={query}
                        locale={locale}
                        filter={filter}
                        intlKey={intlKey}
                        option={{
                            value: option,
                        }}
                        tempParams={tempParams}
                        setTempParams={setTempParams}
                    />
                );
            })}

            {filter?.options?.map(option => {
                return (
                    <ResourceFilterFormMultipleItem
                        id={id}
                        query={query}
                        locale={locale}
                        option={option}
                        filter={filter}
                        intlKey={intlKey}
                        key={option.value}
                        tempParams={tempParams}
                        setTempParams={setTempParams}
                    />
                );
            })}

            {filteredGroupOptions?.map(group => {
                const sortedData = group?.sort
                    ? group.options.sort((a, b) => {
                          const aLabel = intl.formatMessage({ id: a.translateKey || '' });
                          const bLabel = intl.formatMessage({ id: b.translateKey || '' });

                          if (a.value === 'other' && group.sortOther) {
                              return 1;
                          }

                          if (b.value === 'other' && group.sortOther) {
                              return -1;
                          }

                          return aLabel.localeCompare(bLabel);
                      })
                    : group.options;

                return (
                    <ResourceFilterFormMultipleGroup id={group.id} label={group.label} key={group.id}>
                        {sortedData.map(option => {
                            return (
                                <ResourceFilterFormMultipleItem
                                    id={id}
                                    query={query}
                                    option={option}
                                    filter={filter}
                                    locale={locale}
                                    intlKey={intlKey}
                                    key={option.value}
                                    tempParams={tempParams}
                                    setTempParams={setTempParams}
                                />
                            );
                        })}
                    </ResourceFilterFormMultipleGroup>
                );
            })}

            {filter.showLoading && <LoadingIndicator message="Loading" />}

            {filter.showEmptyAlert && filter?.options?.length === 0 && (
                <EmptyAlert icon="face-01" body={<FormattedMessage id="general.state.no_results" />} />
            )}
        </div>
    );
};

export default ResourceFilterFormMultiple;
