import gql from 'graphql-tag';

export const GET_COMPANY_MEMBERS_QUERY = gql`
    query GetCompanyMembers($officeId: ID!, $page: Int, $limit: Int, $query: String, $team: String, $role: String) {
        office(id: $officeId) {
            companyMembers(page: $page, limit: $limit, query: $query, team: $team, role: $role) {
                totalCount
                pageInfo {
                    currentPage
                    hasNextPage
                    itemsPerPage
                }
                edges {
                    node {
                        id
                        firstName
                        lastName
                        email
                        role
                        phone
                        photo
                    }
                }
            }
        }
    }
`;
