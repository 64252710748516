import { QueryResult } from '@apollo/react-common';
import { useQuery } from '@apollo/react-hooks';
import useCompany from '@/app.hooks/useCompany';
import { GET_CHANNEL_ACCOUNT } from '@/graphql/queries/channels/getChannelAccount';
import { GetChannelAccountQuery, GetChannelAccountQueryVariables } from '@/graphql/generated/types';

export function useChannelAccount(accountId: string) {
    const company = useCompany();

    const { data, error, loading }: QueryResult<GetChannelAccountQuery, GetChannelAccountQueryVariables> = useQuery(
        GET_CHANNEL_ACCOUNT,
        {
            variables: {
                companyId: company.get('id'),
                accountId,
            },
            skip: !company.get('id'),
        },
    );

    if (error) {
        throw error;
    }

    const channelAccount = !loading && data ? data.channelAccount : undefined;

    return { channelAccount, loading };
}
