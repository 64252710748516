/* eslint-disable no-use-before-define */
import React from 'react';
import { Route, Redirect, IndexRoute, IndexRedirect } from 'react-router';
import { FormattedMessage } from 'react-intl-sweepbright';
import MainNavigation from '@/app.components/navigations/MainNavigation';
import { ToastsProvider } from '@sweepbright/notifications';

import channelRoutes from '@/app.routing/routes/channelsRoutes';
import propertyRoutes from '@/app.routing/routes/propertyRoutes';
import contactRoutes from '@/app.routing/routes/contactRoutes';
import settingsRoutes from '@/app.routing/routes/settingsRoutes';
import companyRoutes from '@/app.routing/routes/companyRoutes';
import teamRoutes from '@/app.routing/routes/teamRoutes';

import CompanyLayout from '@/app.layouts/CompanyLayout';
import DefaultLayout from '@/app.layouts/DefaultLayout';
import LeadsLayout from '@/app.layouts/LeadsLayout';
import TeamsLayout from '@/app.layouts/TeamsLayout';
import MembersLayout from '@/app.layouts/MembersLayout';
import ThreeColumnsLayout from '@/app.layouts/ThreeColumnsLayout';
import ApplicationLayout from '@/app.layouts/ApplicationLayoutContainer';

import * as Pages from './allPagesComponents';
import * as Routes from './routes';
import { Authenticated, Guest, TeamAdmin, CompanyAdmin, DisabledCompanyAdmin } from './Middlewares';

export * from './routes';

const Routing = store => {
    // closure for the Store, needed for SSR checks with the onEnter hook

    return (
        <Route component={ToastsProvider}>
            <Route component={Guest(ApplicationLayout)}>
                <Route
                    // @ts-ignore
                    title="Login"
                    path={Routes.LOGIN}
                    component={Pages.Login}
                />
                <Route
                    // @ts-ignore
                    title="Login"
                    path={Routes.LOGIN_SENT}
                    component={Pages.LoginSent}
                />
                <Route
                    // @ts-ignore
                    title="Login"
                    path={Routes.LOGIN_CODE}
                    component={Pages.LoginCode}
                />
                <Route
                    // @ts-ignore
                    title="Authorize"
                    path={Routes.AUTHORIZE}
                    component={Pages.Authorize}
                />
                <Route
                    // @ts-ignore
                    title="Sign Up"
                    path={Routes.REGISTER}
                    component={Pages.Register}
                />
            </Route>
            <Route component={Authenticated(ApplicationLayout)}>
                <Route
                    // @ts-ignore
                    component={DefaultLayout}
                >
                    <Route path="oauth/authorize" component={Pages.OauthAuthorize} />
                </Route>
            </Route>

            <Route component={Authenticated(DisabledCompanyAdmin(ApplicationLayout))}>
                <Route path="/" component={MainNavigation}>
                    <IndexRoute component={Pages.Home} />
                    <Route path={Routes.CONTACTS}>
                        <IndexRoute component={Pages.Contacts} />
                        {contactRoutes()}
                    </Route>
                    <Route path={Routes.PROPERTIES}>
                        <IndexRoute component={Pages.Properties} />
                        {propertyRoutes()}
                    </Route>
                </Route>

                <Route component={ThreeColumnsLayout}>
                    <Route path={Routes.PROFILE} component={Pages.Profile}>
                        <IndexRedirect to="edit" />
                        <Route
                            // @ts-ignore
                            title="Profile"
                            path="edit"
                            component={Pages.ProfileEdit}
                        />
                        <Route
                            // @ts-ignore
                            title="Calendars"
                            path="calendars"
                            component={Pages.ProfileCalendars}
                        />
                    </Route>

                    {channelRoutes(store)}

                    {settingsRoutes()}
                </Route>
            </Route>

            <Route component={Authenticated(ApplicationLayout)}>
                <Route component={ThreeColumnsLayout}>
                    <Route component={TeamAdmin(LeadsLayout)}>
                        <Route
                            path={Routes.LEADS}
                            // @ts-ignore
                            title="Buyer Leads"
                            component={Pages.Leads}
                        />
                    </Route>
                </Route>

                <Route component={ThreeColumnsLayout}>
                    <Route component={TeamAdmin(TeamsLayout)}>
                        <Route path={Routes.TEAMS}>
                            <IndexRoute component={Pages.Teams} />
                            {teamRoutes()}
                        </Route>
                    </Route>

                    <Route component={TeamAdmin(MembersLayout)}>
                        <Route path="/members" component={Pages.Members} />
                        <Route path="/members/invite" component={Pages.MembersInvite} />
                        <Route path="/members/:member" component={Pages.MembersMember} />
                    </Route>
                </Route>

                <Route component={ThreeColumnsLayout}>
                    <Route component={TeamAdmin(CompanyLayout)}>
                        {companyRoutes()}

                        <Route path={Routes.SUBSCRIPTION}>
                            <IndexRedirect to="overview" />
                            <Redirect from="plan" to="overview" />
                            <Route
                                path="overview"
                                component={Pages.SubscriptionPlan}
                                // @ts-ignore
                                title={
                                    <FormattedMessage
                                        id="titles.company.subscription.subscription"
                                        defaultMessage="Subscription settings"
                                    />
                                }
                            />
                            <Route
                                path="payment"
                                component={Pages.SubscriptionPayment}
                                // @ts-ignore
                                title={
                                    <FormattedMessage
                                        id="titles.company.subscription.payment"
                                        defaultMessage="Payment"
                                    />
                                }
                            />
                            <Route
                                path="invoice"
                                component={Pages.SubscriptionInvoice}
                                // @ts-ignore
                                title={
                                    <FormattedMessage
                                        id="titles.company.subscription.invoice"
                                        defaultMessage="Invoice"
                                    />
                                }
                            />
                        </Route>
                    </Route>
                </Route>

                <Route
                    path={Routes.SUBSCRIPTION_UPGRADE}
                    component={CompanyAdmin(Pages.SubscriptionUpgrade)}
                    // @ts-ignore
                    title={
                        <FormattedMessage
                            id="titles.company.subscription.upgrade"
                            defaultMessage="Upgrade your subscription"
                        />
                    }
                />
            </Route>

            <Route path="*" component={Pages.NotFound} />
        </Route>
    );
};

export default Routing;
