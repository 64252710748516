import { useQuery } from '@apollo/react-hooks';
import { GetCompanyMembersQuery, GetCompanyMembersQueryVariables } from '@/graphql/generated/types';
import { GET_COMPANY_MEMBERS_QUERY } from '@/graphql/queries/office/getCompanyMembers';

export function useCompanyMembers(variables: GetCompanyMembersQueryVariables, skip?: boolean) {
    return useQuery<GetCompanyMembersQuery, GetCompanyMembersQueryVariables>(GET_COMPANY_MEMBERS_QUERY, {
        skip,
        variables,
        fetchPolicy: 'no-cache',
    });
}
