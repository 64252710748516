import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import useTableColumns from '@/app.hooks/useTableColumns';
import useUser from '@/app.hooks/useUser';
import { MembersSearchType } from '../types';
import { renderEmail, renderName, renderPhone, renderRole } from '../utils';

export const useMembersColumns = (_searchSettings: MembersSearchType | undefined) => {
    const user = useUser();

    const columnsById = useMemo(() => {
        return {
            name: {
                key: 'name',
                title: <FormattedMessage id="forms.labels.name" defaultMessage="Name" />,
                dataIndex: 'id',
                render: (id, member) => renderName(id, member, user),
                className: 'w-56',
                cellClassName: 'w-56 truncate',
            },
            email: {
                key: 'email',
                title: <FormattedMessage id="forms.labels.email" defaultMessage="E-mail" />,
                dataIndex: 'email',
                render: (id, member) => renderEmail(id, member),
                className: 'w-64',
                cellClassName: 'w-64',
            },
            phone: {
                key: 'phone',
                title: <FormattedMessage id="forms.labels.phone" defaultMessage="Phone" />,
                dataIndex: 'phone',
                render: (id, member) => renderPhone(id, member),
                className: 'w-64',
                cellClassName: 'w-64',
            },
            role: {
                key: 'role',
                title: <FormattedMessage id="forms.labels.role" defaultMessage="Role" />,
                dataIndex: 'role',
                render: (id, member) => renderRole(id, member),
                className: 'w-64',
                cellClassName: 'w-64',
            },
        };
    }, [user]);

    const initRecord: [string, boolean][] = React.useMemo(
        () => [
            ['email', true],
            ['phone', true],
            ['role', true],
        ],
        [],
    );

    const { columns } = useTableColumns({
        memoryKey: 'members.columns',
        initRecord,
        columnsExtended: columnsById,
        renderMenu: () => null,
        firstColumnId: 'name',
        columnsKeyPrefix: 'members_table.headers',
    });

    return columns;
};
