import { useField } from 'formik';
import { Map } from 'immutable';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import WithPrivateTooltip from '@/app.components/forms/WithPrivateTooltip';
import { InputForFormik } from '@/app.components/forms/Input/Input';
import {
    PropertyFieldVisibility,
    usePropertyFieldsVisibility,
} from '@/app.utils/services/Fields/PropertyFieldVisibility';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';

export type OccupationValues = {
    occupancy: {
        occupied: boolean;
        available_from: string;
        contact_details: string;
    };
    settings: {
        current_rent: {
            amount: string;
        };
    };
    tenant_contract: { start_date: string; end_date: string };
};

const occupationPanelFields = [
    'attributes.occupancy.available_from',
    'attributes.occupancy.occupied',
    'attributes.settings.current_rent',
    'attributes.tenant_contract.start_date',
    'attributes.tenant_contract.end_date',
    'attributes.occupancy.contact_details',
];
/**
 * The panel is visible if any of its fields is visible.
 */
export const useOccupationPanelVisibility = (property: EstateRepository) => {
    return usePropertyFieldsVisibility(property, occupationPanelFields).length > 0;
};

const OccupationPanel: React.FunctionComponent<{
    property: Map<string, any>;
}> = props => {
    const [{ value: occupied }] = useField('occupancy.occupied');
    const propertyRepository = useMemo(() => new EstateRepository(props.property), [props.property]);
    const currency = propertyRepository.getCurrency();

    const classes = {
        labelClassName: 'col-sm-4',
        wrapperClassName: 'col-sm-6',
    };

    const checkboxClasses = { wrapperClassName: 'form-group' };

    return (
        <FormPanel
            key="occupancy"
            title={<FormattedMessage id="forms.labels.occupancy.title" defaultMessage="Occupation" />}
        >
            <div className="form-horizontal">
                <PropertyFieldVisibility property={propertyRepository} path="attributes.occupancy.available_from">
                    <InputForFormik
                        name="occupancy.available_from"
                        type="date"
                        onBlur={() => {}}
                        label={<FormattedMessage id="forms.labels.available_from" defaultMessage="Available From" />}
                        {...classes}
                    />
                </PropertyFieldVisibility>
            </div>
            <PropertyFieldVisibility property={propertyRepository} path="attributes.occupancy.occupied">
                <InputForFormik
                    checked={occupied}
                    name="occupancy.occupied"
                    type="checkbox"
                    label={<FormattedMessage id="forms.labels.occupancy.vacant" defaultMessage="Currently Occupied" />}
                    {...checkboxClasses}
                />
            </PropertyFieldVisibility>
            {occupied && (
                <div className="form-horizontal">
                    <PropertyFieldVisibility property={propertyRepository} path="attributes.settings.current_rent">
                        <InputForFormik
                            name="settings.current_rent.amount"
                            type="price"
                            label={
                                <FormattedMessage
                                    id="forms.labels.rent_per_month"
                                    defaultMessage="Current Rent Per Month"
                                />
                            }
                            {...classes}
                            addonAfter={currency}
                            decimalScale={2}
                        />
                    </PropertyFieldVisibility>
                    <PropertyFieldVisibility property={propertyRepository} path="attributes.tenant_contract.start_date">
                        <InputForFormik
                            name="tenant_contract.start_date"
                            type="date"
                            onBlur={() => {}}
                            label={
                                <FormattedMessage id="forms.labels.start_date" defaultMessage="Contract Start Date" />
                            }
                            {...classes}
                        />
                    </PropertyFieldVisibility>
                    <PropertyFieldVisibility property={propertyRepository} path="attributes.tenant_contract.end_date">
                        <InputForFormik
                            name="tenant_contract.end_date"
                            type="date"
                            onBlur={() => {}}
                            label={<FormattedMessage id="forms.labels.end_date" defaultMessage="Contract End Date" />}
                            {...classes}
                        />
                    </PropertyFieldVisibility>
                    <PropertyFieldVisibility property={propertyRepository} path="attributes.occupancy.contact_details">
                        <InputForFormik
                            name="occupancy.contact_details"
                            type="autosize-textarea"
                            {...classes}
                            label={
                                <WithPrivateTooltip>
                                    <FormattedMessage id="forms.labels.availability" defaultMessage="Availability" />
                                </WithPrivateTooltip>
                            }
                            placeholder={
                                <FormattedMessage
                                    id="forms.placeholders.occupancy.vacant"
                                    defaultMessage="Enter contact details & availability of current tenant"
                                />
                            }
                            wrapperClassName="col-sm-9"
                        />
                    </PropertyFieldVisibility>
                </div>
            )}
        </FormPanel>
    );
};

export default React.memo(OccupationPanel);
