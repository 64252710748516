import PropTypes from 'prop-types';
import React from 'react';

const ThreeColumnsLayout = ({ children }) => (
    <div className="bc-scrollable-container-content-layout bc-scrollable-container-content-layout-fullscreen">
        {React.cloneElement(children)}
    </div>
);

ThreeColumnsLayout.propTypes = {
    children: PropTypes.node,
};

export default ThreeColumnsLayout;
