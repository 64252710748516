import gql from 'graphql-tag';

export const GET_CONTACT_STATS = gql`
    query GetContactStats($filter: ContactStatsFilter!) {
        contactStats(filter: $filter) {
            numberOfPendingOffers
            closestOfferExpirations
            hasAcceptedOffer
            highestOfferBid
        }
    }
`;
