// @ts-nocheck
import { Map } from 'immutable';
import identity from 'lodash/identity';
import get from 'lodash/get';
import { RequestError } from '@sweepbright/margaret-fetcher';

export const HTTP_STATUS_SERVER_MAINTENANCE = 503;

export default function formatApiErrors(
    errorResponse: RequestError,
    defaultField = '_error',
    keyPrefix = '',
    fieldMapper = identity,
): Record<string, string | object> {
    if (errorResponse?.response) {
        if (errorResponse.response.status === HTTP_STATUS_SERVER_MAINTENANCE) {
            return { [defaultField]: 'Sorry, server currently down for maintenance!' };
        }
        // If we have no data, show errors on default field
        const responseBody = errorResponse.response.body as any;
        const errors = get(responseBody, 'data.errors', responseBody.errors);
        if (!errors) {
            return { [defaultField]: errorResponse.message };
        }

        return formatErrors(errors, {
            defaultField,
            keyPrefix,
            fieldMapper,
        });
    }

    return {
        [defaultField]: errorResponse.message,
    };
}

export function formatInviteErrors(invites, response: RequestError) {
    const errors = formatApiErrors(response);

    if (typeof errors.invites === 'string') {
        return {
            _error: errors.invites,
        };
    }

    const invitesErrors = [];
    invites.forEach((invitee, index) => {
        invitesErrors[index] = errors.invites[index] || {};
    });

    errors.invites = invitesErrors;

    return errors;
}

function formatErrors(
    errors,
    options: { defaultField: string; keyPrefix?: string; fieldMapper: (key: string) => [string] },
) {
    if (includesFieldInfo(errors)) {
        // If we do have the fields, bind the messages
        // to each one
        let formatted = Map();
        errors
            .filter(error => Boolean(error.meta.field))
            .forEach(error => {
                let keyPath = error.meta.field.split('.');
                const errorMessage = error.detail || error.title;

                if (options.keyPrefix) {
                    keyPath = [options.keyPrefix].concat(...keyPath);
                }

                formatted = formatted.setIn(options.fieldMapper(keyPath), errorMessage);
            });

        return formatted.toJS();
    } else {
        const [firstError] = errors;
        const message = firstError.title || firstError.detail;

        return { [options.defaultField]: message };
    }
}

function includesFieldInfo(errors) {
    const [firstError] = errors;

    return Boolean(firstError.meta);
}

type ImportErrorLine = {
    errors: string[];
    line_number: number;
    row: {
        [key: string]: any;
    };
};

export function formatImportErrors(errorResponse: RequestError, defaultField = '_error') {
    if (errorResponse.response) {
        const body = errorResponse.response.body as { data: ImportErrorLine[] };

        return body.data;
    } else {
        return { [defaultField]: errorResponse.message };
    }
}
