import { REPLACE_ROOMS } from '@/app.redux/actions/StructureFormActions';
import { Room } from '@/app.domains/properties/transformations/rooms/types';

function createFieldValue(value: any) {
    return { initial: value, value, _isFieldValue: true };
}

export default function StructureFormReducer(state, action) {
    switch (action.type) {
        case REPLACE_ROOMS: {
            const rooms: Room[] = action.payload;

            return {
                ...state,
                structure: {
                    ...state.structure,
                    rooms: rooms.map(room => ({
                        ordinal: createFieldValue(room.ordinal),
                        size: createFieldValue(room.size),
                        size_description: createFieldValue(room.size_description),
                        type: createFieldValue(room.type),
                        units: createFieldValue(room.units),
                    })),
                },
            };
        }
    }

    return state;
}
