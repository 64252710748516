import gql from 'graphql-tag';

export const REGISTER_ESTATE_ACTIVITY = gql`
    mutation RegisterEstateActivity($input: RegisterEstateActivityInput!) {
        registerEstateActivity(input: $input) {
            subject {
                id
            }
            activity {
                id
            }
            contact {
                id
            }
        }
    }
`;
