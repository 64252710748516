import React from 'react';
import { fromJS } from 'immutable';
import { useIntl } from 'react-intl';
import Modal from '@/app.components/elements/Modal';
import { useToasts } from '@sweepbright/notifications';
import { Contact, Estate } from '@/graphql/generated/types';
import { fullName } from '@/app.utils/services/Helpers';
import useOfficeEmailPresets from '@/app.hooks/useOfficeEmailPresets';
import { ResourceDomainType } from '@/shared/resource/types';
import useMatchEmails from '../../hooks/useMatchEmails';
import MatchListModalForm from './MatchListModalForm';

type Props = {
    show: boolean;
    selected: string[];
    isSingle?: boolean;
    onCancel: () => void;
    contacts?: Contact[];
    properties?: Estate[];
    onMailed?: () => void;
    mailableCount?: number;
    formattedSearchParams?: any;
    domainType: ResourceDomainType;
};

const MatchListModal: React.FC<Props> = props => {
    const {
        show,
        isSingle,
        onCancel,
        selected,
        contacts,
        onMailed,
        domainType,
        properties,
        mailableCount,
        formattedSearchParams,
    } = props;

    const intl = useIntl();

    const { addSuccess, addError } = useToasts();

    const [sendEmails] = useMatchEmails({});

    const presets = useOfficeEmailPresets('email_matching_lead_description');

    const handleEmails = (values: { contents: { [key: string]: string } }) => {
        const propertyIds = properties?.map(el => el.id) || [];
        const contactIds = contacts?.map(el => el.id) || [];

        sendEmails({
            variables: {
                input: {
                    contents: values.contents,
                    searchParams: formattedSearchParams,
                    ...(domainType === 'contact_matches'
                        ? {
                              contactIds: contactIds,
                              propertyIds: propertyIds,
                              operation: 'MAIL_MULTIPLE',
                          }
                        : {
                              propertyIds: propertyIds,
                              ...(isSingle
                                  ? {
                                        contactIds: contactIds,
                                        operation: 'MAIL_MULTIPLE',
                                    }
                                  : {
                                        contactIds: selected.length === 0 ? undefined : contactIds,
                                        operation: selected.length === 0 ? 'MAIL_ALL' : 'MAIL_MULTIPLE',
                                    }),
                          }),
                },
            },
        })
            .then(() => {
                const titleId = contactIds.length === 1 ? 'match.success.title.single' : 'match.success.title.zero';
                const messageId = contactIds.length === 1 ? 'match.success.body.with_name' : 'match.success.body.zero';

                addSuccess({
                    title: intl.formatMessage({ id: titleId }),
                    message: intl.formatMessage(
                        {
                            id: messageId,
                        },
                        {
                            count:
                                domainType === 'contact_matches'
                                    ? propertyIds.length
                                    : contactIds.length || mailableCount,
                            name: fullName(contacts && contacts[0]?.firstName, contacts && contacts[0]?.lastName),
                        },
                    ),
                });

                if (onMailed) {
                    onMailed();
                }
            })
            .catch(err => {
                const isForbidden = err?.message?.includes('403');

                addError({
                    message: isForbidden
                        ? intl.formatMessage({ id: 'unauthorised_403' })
                        : intl.formatMessage({ id: 'general.something_went_wrong' }),
                });
            })
            .finally(() => {
                onCancel();
            });
    };

    const isMultiple = domainType === 'property_matches' && contacts && contacts?.length > 1 ? true : false;

    return (
        <Modal show={show} onHide={onCancel} autoFocus>
            <MatchListModalForm
                onCancel={onCancel}
                multiple={isMultiple}
                mailTextPreset={{
                    contents: presets,
                }}
                handleSubmit={handleEmails}
                contact={fromJS(contacts && contacts[0])}
                properties={properties?.map(el => fromJS(el))}
            />
        </Modal>
    );
};

export default MatchListModal;
