// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Input } from '@/app.components';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import ButtonLink from '../../elements/Buttons/ButtonLink';
import './NavbarDropdown.scss';

const ToggleButton: React.FunctionComponent<{
    onClick?: (evt: any) => void;
    className?: string;
}> = React.forwardRef(function ToggleButton(
    { children, onClick, className, ...props },
    ref: React.Ref<HTMLButtonElement>,
) {
    return (
        <ButtonLink
            ref={ref}
            className={classNames('bc-navbar-btn-stretch bc-navbar-btn-stretch-inverse', className)}
            onClick={onClick}
            {...props}
        >
            <span className="c-navbar-dropdown__toggle-text ">{children}</span> <span className="caret" />
        </ButtonLink>
    );
});

const NavbarDropdown: React.FunctionComponent<{
    title: React.ReactNode;
    toggleClassName?: string;
    testId?: string;
    query?: string;
    setQuery?: (query: string) => void;
    onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}> = ({ title, toggleClassName, testId, children, query, setQuery, onScroll }) => {
    const intl = useIntl();

    const content = {
        placeholder: intl.formatMessage({ id: 'forms.search.text' }),
    };

    return (
        <div key="dropdown" className="bc-navbar-divider">
            <div key="border" className="bc-navbar-divider-border" />
            <Dropdown id="sweepbright" className="c-navbar-dropdown" onScroll={onScroll}>
                <Dropdown.Toggle as={ToggleButton} className={toggleClassName} testId={testId}>
                    {title}
                </Dropdown.Toggle>
                <Dropdown.Menu className={`c-navbar-dropdown__menu${setQuery ? ' no-padding-top' : ''}`}>
                    <>
                        {setQuery && (
                            <Input
                                value={query}
                                onChange={e => {
                                    setQuery(e.target.value);
                                }}
                                placeholder={content.placeholder}
                            />
                        )}
                        {children}
                    </>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default NavbarDropdown;
