import React, { ReactNode } from 'react';
import moment from 'moment';
import { useMutation } from 'react-query';
import _ from 'lodash';
import { Alert, ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import Button from '@sweepbright/uikit/build/esm/button';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { Link } from 'react-router';
import { useFormik, FormikErrors } from 'formik';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import ContactCard from '@/shared/contacts/ContactCard';
import { PROFILE_CALENDARS } from '@/app.routing/routes';
import ScheduleVisitDayView, { CronofyEvent } from '@/app.components/modals/ScheduleVisitDayViewCalendarV2';
import useOffice from '@/app.hooks/useOffice';
import Modal from '@/app.components/elements/Modal';
import CloseIconButton from '@/app.components/elements/Buttons/CloseIconButton';
import VisitMainSettings from '@/app.components/modals/ScheduleVisitModal/VisitMainSettings';
import { useEstateNegotiator } from '@/app.domains/properties/PropertyNegotiator';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { upsertVisitMutation, Visit } from '@/app.domains/properties/Schedule/visits_requests';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { useRefetchPropertySchedule } from '@/app.hooks/useSchedule';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import ContactSelect from '@/shared/contacts/ContactSelect';
import Icon from '../../icons/Icon';
import { useOfficeNegotiators } from '../../forms/OfficeNegotiators';
import { useToasts } from '../../../../notifications/src';
import { useVisitMessageTemplates } from './utils';

type Values = {
    id?: string;
    propertyId: string;
    attendees: string[];
    negotiatorId: string;
    date: string;
    hour: number;
    minutes: number;
    duration: number;
    location: string;
    title: string;
    message: string;
};

type Errors = {
    [fieldName in keyof Values]?: ReactNode;
};

type Props = {
    show: boolean;
    onCancel: () => void;
    initialValues?: Partial<Values>;
    // true is we are editing an existing visit
    editingEventWithId?: string;
    onVisitScheduled: (visit: Visit) => Promise<void> | void;
};

const configureCalendarLink = (
    <Link to={PROFILE_CALENDARS}>
        <FormattedMessage
            id="components.schedule_visit_modal.connect_calendar_link"
            defaultMessage="connect your online calendar"
        />
    </Link>
);

const ScheduleVisitModalContainer: React.FunctionComponent<Props> = props => {
    const updateNegotiatorEnabled = useFeatureFlag('visit.updateNegotiator');
    const intl = useIntl();
    const { show, editingEventWithId, onVisitScheduled } = props;
    const toasts = useToasts();
    const refetchPropertySchedule = useRefetchPropertySchedule();
    const [upsertVisit] = useMutation(upsertVisitMutation, {
        onSuccess: (response: any) => {
            onVisitScheduled?.(response.data as Visit);
            refetchPropertySchedule();
        },
        onError: (err: Error) => {
            getBugsnagClient().notify(err);
            toasts.addError({
                message: (
                    <FormattedMessage
                        id="modals.schedule_visit.save_visit.something_went_wrong"
                        defaultMessage="Oops, looks like we could not save your changes. Please try again."
                    />
                ),
            });
        },
    });

    const office = useOffice();
    const officeSettings = office.getIn(['settings', 'data'])?.toJS();

    const { negotiators, loading } = useOfficeNegotiators(office.get('id'));
    const validNegotiators = negotiators.filter(option => option.raw.hasCalendarIntegration);
    const canScheduleVisitForHimself = validNegotiators.find(n => n.isCurrentUser);
    const { negotiator } = useEstateNegotiator(props.initialValues?.propertyId);
    // eslint-disable-next-line eqeqeq
    const initialNegotiatorId = validNegotiators?.find(option => option.value == negotiator?.id)?.value ?? '';
    const noValidNegotiators = !loading && validNegotiators.length === 0;

    const defaultDurationInMins = parseInt(officeSettings?.visit_duration) || 30;

    // when this modal is opened from a property view
    // we dont allow the user to change the property
    const isPropertyFixed = !!props.initialValues?.propertyId;

    const { defaultTemplate } = useVisitMessageTemplates();

    const initialValues = React.useMemo(() => {
        return {
            ...getDefaultStart(),
            attendees: [] as string[],
            propertyId: '',
            duration: defaultDurationInMins,
            negotiatorId: initialNegotiatorId,
            location: '',
            title: intl.formatMessage({
                id: 'components.schedule_visit_modal.form_data.visit',
                defaultMessage: 'Visit',
            }),
            message: defaultTemplate,
            ...props.initialValues,
        };
    }, [defaultDurationInMins, defaultTemplate, initialNegotiatorId, intl, props.initialValues]);

    const {
        values,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        submitCount,
        errors,
        handleChange,
        resetForm,
    } = useFormik<Values>({
        onSubmit: values => {
            const isNew = !values.id;

            return upsertVisit({
                ...values,
                negotiatorId: updateNegotiatorEnabled || isNew ? values.negotiatorId : undefined,
            });
        },
        enableReinitialize: !!editingEventWithId,
        validate,
        initialValues,
    });

    const editing = !!values.id;

    return (
        <Modal
            show={show}
            width="full"
            onHide={props.onCancel}
            className="visits-modal"
            onExited={() => resetForm()}
            onEntering={() => {
                if (!editing) {
                    Object.entries(getDefaultStart()).forEach(([fieldName, value]) => setFieldValue(fieldName, value));
                }
            }}
        >
            <form onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title component="h3">
                        {editing ? (
                            <FormattedMessage id="forms.update_visit.title" defaultMessage="Update Appointment" />
                        ) : (
                            <FormattedMessage
                                id="components.schedule_visit_modal.schedule_appointment"
                                defaultMessage="Schedule Appointment"
                            />
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-full h-full min-h-0 flex py-0 pl-0">
                    {loading ? (
                        <div className="my-2 mx-auto w-full">
                            <LoadingIndicator />
                        </div>
                    ) : noValidNegotiators ? (
                        <div className="w-full my-2 mx-2">
                            <Alert>
                                <FormattedMessage
                                    id="components.schedule_visit_modal.alert_configure_online_calendar"
                                    defaultMessage="Oops... Looks like you still need to"
                                />{' '}
                                {configureCalendarLink}
                            </Alert>
                        </div>
                    ) : (
                        <ErrorBoundary>
                            <VisitForm
                                isPropertyFixed={isPropertyFixed}
                                values={values}
                                errors={errors}
                                validNegotiators={validNegotiators}
                                canScheduleVisitForHimself={!!canScheduleVisitForHimself}
                                submitCount={submitCount}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                            />
                        </ErrorBoundary>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onCancel}>
                        <FormattedMessage id="modals.common.actions.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        icon={<Icon icon="calendar" />}
                        disabled={isSubmitting || loading || noValidNegotiators}
                    >
                        {isSubmitting && (
                            <FormattedMessage
                                id="modals.schedule_visit.actions.submitting"
                                defaultMessage="Submitting..."
                            />
                        )}
                        {!isSubmitting && !editing && (
                            <FormattedMessage id="components.schedule_visit_modal.save" defaultMessage="Save" />
                        )}
                        {!isSubmitting && editing && (
                            <FormattedMessage id="modals.schedule_visit.actions.update" defaultMessage="Update" />
                        )}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

function VisitForm({
    values,
    setFieldValue,
    validNegotiators,
    handleChange,
    errors,
    canScheduleVisitForHimself,
    submitCount,
    isPropertyFixed,
}: {
    values: Values;
    errors: FormikErrors<Values>;
    submitCount: number;
    isPropertyFixed: boolean;
    setFieldValue: (fieldName: string, value: any) => void;
    canScheduleVisitForHimself: boolean;
    validNegotiators: any[];
    handleChange: (value: any) => void;
}) {
    const submitFailed = submitCount > 0;

    const handleChangeTime = React.useCallback(
        ({ hour, minutes, duration }) => {
            setFieldValue('hour', hour);
            setFieldValue('minutes', minutes);
            setFieldValue('duration', duration);
        },
        [setFieldValue],
    );

    const handleChangeDate = React.useCallback(value => setFieldValue('date', value), [setFieldValue]);
    const visitEvent = React.useMemo(() => {
        const visitStart = moment(values.date)
            .set('hours', values.hour || 0)
            .set('minutes', values.minutes || 0);

        const visitEnd = visitStart.clone().add(values.duration, 'minutes');

        return {
            event_id: values.id,
            start: visitStart.toDate(),
            end: visitEnd.toDate(),
            isVisit: true,
            type: 'visit' as CronofyEvent['type'],
            summary: values.title,
        };
    }, [values.id, values.date, values.hour, values.minutes, values.duration, values.title]);
    const handleSetAttendees = React.useCallback(ids => setFieldValue('attendees', ids), [setFieldValue]);

    return (
        <>
            <div className="w-full min-h-0">
                {values.propertyId ? (
                    <ScheduleVisitDayView
                        date={values.date}
                        onChangeDate={handleChangeDate}
                        onChangeTime={handleChangeTime}
                        visitEvent={visitEvent}
                        negotiatorId={values.negotiatorId}
                        propertyId={values.propertyId}
                    />
                ) : (
                    <div className="flex items-center h-full px-4 min-h-0">
                        <div className="w-full text-center">
                            <div className="text-muted text-lg">
                                <FormattedMessage
                                    id="components.schedule_visit_modal.no_property_selected"
                                    defaultMessage="No property selected"
                                />
                            </div>
                            <div className="text-gray-mid">
                                <FormattedMessage
                                    id="components.schedule_visit_modal.select_property_and_show_schedule"
                                    defaultMessage="Select a property and its schedule will show up here."
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex flex-col border-l border-solid border-gray-light">
                <div className="flex min-h-0 my-6" style={{ minWidth: '380px' }}>
                    <VisitMainSettings
                        editing={!!values.id}
                        values={values}
                        errors={errors}
                        submitFailed={submitFailed}
                        onChange={handleChange}
                        handleSetAttendees={handleSetAttendees}
                        canScheduleVisitForHimself={canScheduleVisitForHimself}
                        validNegotiators={validNegotiators}
                        isPropertyFixed={isPropertyFixed}
                    />
                </div>
            </div>
        </>
    );
}

/// helpers
function getTimeError({ date, hour, minutes }: Values): false | React.ReactNode {
    // @ts-ignore
    if (hour === '' || hour == null || minutes === '' || minutes == null) {
        return (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.time.require_error"
                defaultMessage="The time is required"
            />
        );
    } else if (hour && minutes && date) {
        const isOnThePast = moment(date)
            .set('hour', +hour)
            .set('minutes', +minutes)
            .isBefore(moment());
        if (isOnThePast) {
            return (
                <FormattedMessage
                    id="components.schedule_visit_modal.fields.time.past_time_error"
                    defaultMessage="The time is on the past"
                />
            );
        }

        return false;
    }

    return false;
}

export const VisitAttendees = React.memo(function VisitAttendees({
    attendees = [],
    onSetAttendees,
    errors,
}: {
    attendees: string[];
    onSetAttendees: (attendees: string[]) => void;
    errors?: React.ReactNode;
    propertyId?: string;
}) {
    return (
        <div className="max-w-md flex-1 flex flex-col min-h-0">
            <FormGroup className={classNames({ 'has-error': errors })}>
                <ControlLabel>
                    <FormattedMessage id="components.schedule_visit_modal.attendees" defaultMessage="Attendees" />
                </ControlLabel>
                <ContactSelect
                    filterByUser
                    hasError={!!errors}
                    excluded={attendees}
                    onSelect={contact => onSetAttendees(attendees.concat(contact.id))}
                />
                {errors && <HelpBlock>{errors}</HelpBlock>}
            </FormGroup>
            <ul className="list-none p-0 mt-4 min-h-0 overflow-y-auto">
                {attendees.map(id => {
                    return (
                        <li key={id} className="border-b border-solid border-gray-light">
                            <AttendeeCard
                                id={id}
                                onRemove={() => {
                                    onSetAttendees(_.xor(attendees, [id]));
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});

function AttendeeCard({ id, onRemove }) {
    return (
        <ContactCard
            showTitleInfo
            // @ts-ignore
            contact={{ id: id }}
            actions={[<CloseIconButton key="remove" onClick={onRemove} />]}
        />
    );
}

function trim(value: string | undefined) {
    if (!value) {
        return '';
    }

    return value.trim();
}

function validate(values: Values) {
    const errors: Errors = {};

    const title = trim(values.title);
    const message = trim(values.message);
    const location = trim(values.location);

    if (!values.date || !moment(values.date).isValid()) {
        errors.date = (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.date.invalid_date_error"
                defaultMessage="The date is required"
            />
        );
    } else if (moment(values.date, 'YYYY-MM-DD').isBefore(moment().startOf('day'))) {
        errors.date = (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.date.past_date_error"
                defaultMessage="Date is on the past"
            />
        );
    }

    if (!values.negotiatorId) {
        errors.negotiatorId = (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.organizer_id.required_error"
                defaultMessage="You must select an organizer"
            />
        );
    }

    const timeError = getTimeError(values);

    if (timeError) {
        errors.hour = timeError;
        errors.minutes = timeError;
    }
    if (!values.attendees?.length) {
        errors.attendees = (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.message.select_attendees"
                defaultMessage="You need to select at least one attendee"
            />
        );
    }

    const messageMaxLength = 3000;
    const titleMaxLength = 1024;
    const locationMaxLength = 1024;

    if (message && message.length > messageMaxLength) {
        errors.message = (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.message.to_long_error"
                defaultMessage="The message is too long"
            />
        );
    }

    if (!title) {
        errors.title = (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.title.require_error"
                defaultMessage="The title is required"
            />
        );
    } else if (title.length > titleMaxLength) {
        errors.title = (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.title.too_long_error"
                defaultMessage="The title is too long"
            />
        );
    }

    if (!location) {
        errors.location = (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.location.require_error"
                defaultMessage="The location is required"
            />
        );
    } else if (location.length > locationMaxLength) {
        errors.location = (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.location.too_long_error"
                defaultMessage="The location is too long"
            />
        );
    }

    if (!values.propertyId) {
        errors.propertyId = (
            <FormattedMessage
                id="components.schedule_visit_modal.fields.property.require_error"
                defaultMessage="You must select a property"
            />
        );
    }

    return errors;
}

export default ScheduleVisitModalContainer;

function getInitialMinutes(mins: number) {
    if (mins < 15) {
        return 15;
    }
    if (mins < 30) {
        return 30;
    }
    if (mins < 45) {
        return 45;
    }

    return 0;
}

function getDefaultStart() {
    const start = moment();
    // we want to select the start mins ahead
    // a bit
    const minutes = getInitialMinutes(start.get('minutes'));
    if (minutes === 0) {
        start.add(1, 'hour');
    }

    return {
        date: start.format('YYYY-MM-DD'),
        hour: start.get('hour'),
        minutes,
    };
}
