import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { VisitScheduledActivity } from '@/graphql/generated/types';
import { trackHandler, events } from '@/app.utils/analytics';
import { fullName } from '@/app.utils/services/Helpers';
import { ContactLink, PropertyLink } from './elements/elements';
import ActivityCard from './ActivityCard';

export default function VisitScheduledActivityCard({
    activity,
    viewAs,
}: {
    activity: VisitScheduledActivity;
    viewAs: 'property' | 'contact';
}) {
    const attendee = activity.attendee || (activity.visit.attendees && activity.visit.attendees[0]);

    const title =
        viewAs === 'property' ? (
            <FormattedMessage
                id="interaction.lead_scheduled_visit.parameterised"
                defaultMessage="Visit scheduled with {contact}"
                values={{
                    contact: attendee && (
                        <ContactLink
                            contact={attendee}
                            onClick={trackHandler(events.ACTIVITY_CARD_CONTACT_LINK_CLICKED, {
                                activityType: activity.__typename,
                            })}
                        />
                    ),
                }}
            />
        ) : (
            <FormattedMessage
                id="interaction.visit_scheduled.about.parameterised"
                defaultMessage="Visit scheduled at {location}"
                values={{ location: activity.visit.property && <PropertyLink property={activity.visit.property} /> }}
            />
        );

    const scheduledWithText = (
        <FormattedMessage
            id="interaction.scheduled_with"
            defaultMessage="Scheduled with {contact}"
            values={{
                contact:
                    activity?.visit?.organizer &&
                    fullName(activity.visit.organizer?.firstName, activity.visit.organizer?.lastName),
            }}
        />
    );

    const subtitle =
        viewAs === 'property' ? (
            <>
                {activity?.visit?.property?.attributes?.location?.formatted ?? (
                    <FormattedMessage id="address.no_location_defined" defaultMessage="No location defined" />
                )}
                {' — '}
                {scheduledWithText}
            </>
        ) : (
            <>
                {`${fullName(attendee?.firstName, attendee?.lastName)} - `}
                {scheduledWithText}
            </>
        );

    return <ActivityCard icon="calendar" title={title} subtitle={subtitle} date={activity.timestamp} />;
}
