import React from 'react';
import { FormattedMessage } from 'react-intl';
import useOffice from '@/app.hooks/useOffice';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { EstateRepository } from '@/app.utils/services';
import useLegalEntities from '@/app.hooks/useLegallEntities';
import PropertyFormLegalEntitySelect from './PropertyFormLegalEntitySelect';

type Props = { property: EstateRepository; disabled?: boolean; onChange?: (value: any) => void };

const PropertyFormLegalEntity: React.FC<Props> = props => {
    const { property, disabled, onChange } = props;

    const officeId = useOffice().get('id');

    const legalEntitiesEnabled = useFeatureFlag('legal-entities-enabled');

    const { data, loading, refetch, fetchMore } = useLegalEntities(officeId);

    if (!legalEntitiesEnabled || data?.office?.legalEntities?.totalCount === 0 || data === undefined) {
        return null;
    }

    return (
        <div className="form-group flex items-center">
            <label className="form-label col-sm-4">
                <FormattedMessage
                    id="pages.properties.team_settings_panel.legal_entity"
                    defaultMessage="Legal Entity"
                />
            </label>
            <div className="col-sm-8">
                <PropertyFormLegalEntitySelect
                    data={data}
                    refetch={refetch}
                    loading={loading}
                    disabled={disabled}
                    property={property}
                    fetchMore={fetchMore}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default PropertyFormLegalEntity;
