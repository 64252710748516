import React from 'react';
import moment from 'moment';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import { useFormik } from 'formik';
import classNames from 'classnames';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage } from 'react-intl-sweepbright';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import { useToasts } from '@sweepbright/notifications';
import { getBugsnagClient } from '@/app.config/bugsnag';
import {
    formatDate,
    formatDateForPicker,
    formatTime,
    getTimeFromDateAndMinutes,
    validateTimeRange,
} from '@/app.domains/properties/Schedule/timeRangeHelpers';
import Input from './Input/Input';
import TimeInput from './TimeInput';

type Props = {
    onDelete: () => void;
    onChange: (values: { date: string; start: number; end: number; id?: string }) => Promise<void>;
    start?: number;
    end?: number;
    id?: string;
    date?: string;
    startEditing?: boolean;
    disabled?: boolean;
};

export default function OpenHome(props: Props) {
    const { onDelete, date, start, end, id, onChange, startEditing, disabled } = props;

    const [state, setState] = React.useState(id && !startEditing ? '' : 'editing');
    const formId = id ? `open-home-${id}` : 'new-open-home';
    const { addError } = useToasts();

    const dateStr = formatDate(getTimeFromDateAndMinutes(date, start));
    const startStr = formatTime(getTimeFromDateAndMinutes(date, start));
    const endStr = formatTime(getTimeFromDateAndMinutes(date, end));

    return (
        <div className="flex items-center justify-between space-x-2 py-2">
            {state === 'editing' ? (
                <OpenHomeForm
                    initialValues={{
                        date,
                        start,
                        end,
                    }}
                    onSubmit={values =>
                        onChange({ id, ...values }).then(
                            () => {
                                setState('');
                            },
                            err => {
                                getBugsnagClient().notify(err);
                                if (id) {
                                    addError({
                                        message: (
                                            <FormattedMessage
                                                id="pages.properties.oh_pages.could_not_update_open_home"
                                                defaultMessage="Could not update open home"
                                            />
                                        ),
                                    });
                                } else {
                                    addError({
                                        message: (
                                            <FormattedMessage
                                                id="pages.properties.oh_pages.could_not_create_open_home"
                                                defaultMessage="Could not create open home"
                                            />
                                        ),
                                    });
                                }
                            },
                        )
                    }
                    id={formId}
                />
            ) : (
                <div className="flex justify-between flex-1">
                    <div className="truncate">{dateStr}</div>
                    <div className="text-muted flex-shrink-0">
                        {startStr} - {endStr}
                    </div>
                </div>
            )}
            <div
                className={classNames('flex space-x-2', {
                    '-mb-2': state === 'editing',
                })}
            >
                {!state && (
                    <ButtonIcon
                        disabled={disabled}
                        type="edit"
                        variant="link"
                        iconSize={20}
                        onClick={() => setState('editing')}
                    />
                )}
                {state === 'editing' && (
                    <>
                        <Button variant="primary" type="submit" form={formId} disabled={disabled}>
                            {id ? (
                                <FormattedMessage id="pages.properties.oh_pages.update_btn" defaultMessage="Update" />
                            ) : (
                                <FormattedMessage id="pages.properties.oh_pages.save_btn" defaultMessage="Save" />
                            )}
                        </Button>
                        <Button
                            onClick={() => {
                                if (id) {
                                    setState('');
                                } else {
                                    onDelete();
                                }
                            }}
                        >
                            <FormattedMessage id="pages.properties.oh_pages.edit_btn_cancel" defaultMessage="Cancel" />
                        </Button>
                    </>
                )}
                {state === 'deleting' && (
                    <>
                        <Button variant="danger" onClick={onDelete}>
                            <FormattedMessage id="pages.properties.oh_pages.delete_btn" defaultMessage="Delete" />
                        </Button>
                        <Button onClick={() => setState('')}>
                            <FormattedMessage
                                id="pages.properties.oh_pages.delete_btn_cancel"
                                defaultMessage="Cancel"
                            />
                        </Button>
                    </>
                )}
                {!state && (
                    <ButtonIcon
                        disabled={disabled}
                        type="circle-delete"
                        variant="link"
                        iconSize={20}
                        onClick={() => setState('deleting')}
                        className="text-danger"
                    />
                )}
            </div>
        </div>
    );
}

interface OpenHomeFormProps {
    id: string;
    initialValues: {
        date?: string;
        start?: number;
        end?: number;
    };
    onSubmit: (values: { date: string; start: number; end: number }) => void;
}

interface OpenHomeFormValues {
    date: string;
    start: string;
    end: string;
}

function OpenHomeForm({ onSubmit, id, initialValues }: OpenHomeFormProps) {
    const initialValuesMemoized = React.useMemo<OpenHomeFormValues>(() => {
        const start = formatTime(getTimeFromDateAndMinutes(initialValues.date, initialValues.start));
        const end = formatTime(getTimeFromDateAndMinutes(initialValues.date, initialValues.end));
        const date = formatDateForPicker(getTimeFromDateAndMinutes(initialValues.date, initialValues.start));

        return { date, start, end };
    }, [initialValues]);

    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        validate: validateTimeRange,
        validateOnChange: true,
        initialValues: initialValuesMemoized,
        onSubmit: values => {
            onSubmit({
                date: values.date ?? '',
                start: moment(`${values.date} ${values.start}`).diff(moment(values.date), 'minutes'),
                end: moment(`${values.date} ${values.end}`).diff(moment(values.date), 'minutes'),
            });
        },
    });

    const startField = React.useMemo(() => {
        return {
            onChange: handleChange('start'),
            onBlur: handleBlur('start'),
            value: values.start,
        };
    }, [handleBlur, handleChange, values.start]);

    const endField = React.useMemo(() => {
        return {
            onChange: handleChange('end'),
            onBlur: handleBlur('end'),
            value: values.end,
        };
    }, [handleBlur, handleChange, values.end]);

    const startError = touched.start ? errors.start : undefined;
    const endError = touched.end ? errors.end : undefined;
    const dateError = errors.date;

    return (
        <form className="c-open-home-inputs flex flex-1 space-x-2" onSubmit={handleSubmit} id={id}>
            <FormGroup className={classNames('w-6/12', { 'has-error': dateError })}>
                <ControlLabel>
                    <FormattedMessage id="pages.properties.oh_panel.date_label" defaultMessage="Date" />
                </ControlLabel>
                <Input
                    type="date"
                    name="date"
                    renderWrapper={null}
                    value={values.date}
                    onChange={handleChange('date')}
                    error={dateError}
                    touched={true}
                />
                {dateError && <HelpBlock className="absolute">{dateError}</HelpBlock>}
            </FormGroup>

            <FormGroup className={classNames('w-3/12', { 'has-error': startError })}>
                <ControlLabel>
                    <FormattedMessage id="pages.properties.oh_panel.start_time_label" defaultMessage="Start Time" />
                </ControlLabel>
                <TimeInput singleMode field={startField} hasError={!!startError} />
                {startError && <HelpBlock className="absolute">{startError}</HelpBlock>}
            </FormGroup>

            <FormGroup className={classNames('w-3/12', { 'has-error': endError })}>
                <ControlLabel>
                    <FormattedMessage id="pages.properties.oh_panel.end_time_label" defaultMessage="End Time" />
                </ControlLabel>
                <TimeInput singleMode field={endField} hasError={!!endError} />
                {endError && <HelpBlock className="absolute">{endError}</HelpBlock>}
            </FormGroup>
        </form>
    );
}
