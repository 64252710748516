export const SEARCH_FILTERS_BTN_CLICKED = 'Search Filters Modal Opened';
export const SEARCH_FILTERS_MODAL_CLOSED = 'Search Filters Modal Closed';
export const SEARCH_FILTERS_RESETED = 'Search Filters Resetted';
export const SEARCH_FILTERS_APPLIED = 'Search Filters Applied';
export const SEARCH_PROPERTIES_FILTER_TAB_CLICKED = 'Search Properties Filter Tab Clicked';
export const SEARCH_PROPERTIES_SORT_TAB_CLICKED = 'Search Properties Sort Tab Clicked';
export const SEARCH_CONTACTS_FILTER_TAB_CLICKED = 'Search Contacts Filter Tab Clicked';
export const SEARCH_CONTACTS_SORT_TAB_CLICKED = 'Search Contacts Sort Tab Clicked';

export const SEARCH_TAB_ITEM_CLICKED = 'Search Tab Item Clicked';

export const SEARCH_FILTER_CHIP_CLICKED = 'Search Filter Chip Clicked';
export const SEARCH_FILTER_CHIP_APPLY_BTN_CLICKED = 'Search Filter Chip Apply Button Clicked';
