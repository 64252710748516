import React from 'react';
import { Icon } from '@/app.components';

type Props = {
    type: string;
    disabled?: boolean;
    src?: string | null;
    size: 'small' | 'medium' | 'large';
};

const PropertyImage: React.FC<Props> = props => {
    const { src, type, size, disabled } = props;

    const sizes = {
        small: 20,
        medium: 48,
        large: 96,
    };

    const classNames = {
        small: 'w-12 h-10 rounded-sm object-cover',
        medium: 'rounded w-32 h-24 object-cover',
        large: '',
    };

    const styles = {
        large: {
            backgroundColor: '#8e8e93',
            ...(disabled && { filter: 'grayscale(100%)' }),
            backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
        },
    };

    const gradient = (styles[size] || {}).backgroundImage;

    const colors = {
        small: '#bdbdc2',
        medium: '#bdbdc2',
        large: '#AAAAAE',
    };

    const defaultProps = {
        style: { ...styles[size] },
        className: `properties-image ${classNames[size]}`,
    };

    if (!src) {
        return (
            <div {...defaultProps}>
                <Icon icon={`new-${type}`} size={sizes[size]} style={{ color: colors[size] }} />
            </div>
        );
    }

    return (
        <div
            {...defaultProps}
            style={{ ...styles[size], backgroundImage: gradient ? `${gradient}, url(${src})` : `url(${src})` }}
        />
    );
};

export default PropertyImage;
