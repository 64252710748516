import SweepbrightCrudRequest, { FileData } from './SweepbrightCrudRequest';

export default class BucketsRequest extends SweepbrightCrudRequest {
    resource = 'buckets';

    uploadDocument(bucketId: string, attributes: FileData) {
        return this.storeFile(`${bucketId}/files`, attributes);
    }

    resend = (requestId: string, attributes: object) => {
        return this.post(`${this.resource}/${requestId}/resend`, attributes);
    };
}
