// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@sweepbright/uikit/build/esm/button';
import { FilterChip } from '@sweepbright/uikit/build/esm/filterchip';
import { Checkbox, Radio } from '@sweepbright/uikit';
import { useField } from 'formik';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '@/app.components/icons/Icon';
import { propertyTypes } from '@/app.data/Properties';
import { getPropertiesSubtypes } from '@/new.domains/properties/utils';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import Input from './Input/Input';
import FormPanel from './FormPanel/FormPanel';

const propertyIconForType = {
    house: 'house',
    apartment: 'apartments',
    parking: 'parking',
    land: 'land',
    commercial: 'retail',
    office: 'office',
};

const labels = {
    apartment: <FormattedMessage id="modals.common.types_panel.apartment" defaultMessage="Apartment" />,
    commercial: <FormattedMessage id="modals.common.types_panel.commercial" defaultMessage="Commercial Property" />,
    house: <FormattedMessage id="modals.common.types_panel.house" defaultMessage="House" />,
    land: <FormattedMessage id="modals.common.types_panel.land" defaultMessage="Land" />,
    office: <FormattedMessage id="modals.common.types_panel.office" defaultMessage="Office" />,
    parking: <FormattedMessage id="modals.common.types_panel.parking" defaultMessage="Parking" />,
};

const PropertyTypesSection = ({ value = [], onChange, columns }) => {
    return (
        <FormPanel
            title={
                <FormattedMessage
                    id="pages.contacts.sections.preferences.subsections.prop_types.title"
                    defaultMessage="Property Types"
                />
            }
        >
            <Input>
                <div style={{ columnCount: columns }}>
                    {propertyTypes.map(type => {
                        return (
                            <Input
                                key={type}
                                label={labels[type]}
                                type="checkbox"
                                checked={value.includes(type)}
                                onChange={onChange}
                                name="preferences.types"
                                value={type}
                            />
                        );
                    })}
                </div>
            </Input>
        </FormPanel>
    );
};

PropertyTypesSection.propTypes = {
    columns: PropTypes.number,
    types: PropTypes.object.isRequired,
};

PropertyTypesSection.defaultProps = {
    columns: 3,
};

export default PropertyTypesSection;

export function PropertyTypeSection({ value, onChange }) {
    const [field, , { setValue: setSubtypes }] = useField('subtypes');
    const [, , { setValue: setAmenities }] = useField('amenities');

    const handleChange = React.useCallback(evt => {
        onChange(evt);
        // when the type is changed
        // we reset the subtypes and the amenities
        setSubtypes([]);
        setAmenities([]);
    }, []);

    return (
        <FormPanel title={<FormattedMessage id="filters.labels.property_type" defaultMessage="Property Type" />}>
            <ul className="bc-bordered-list">
                {propertyTypes.map(type => {
                    return (
                        <li key={type} className="flex items-center">
                            <Radio name="type" value={type} checked={value === type} onChange={handleChange}>
                                <div className="flex items-center">
                                    <span className="px-2">
                                        <Icon icon={propertyIconForType[type]} style={{ fontSize: '18px' }} />
                                    </span>
                                    {labels[type]}
                                </div>
                            </Radio>
                            <div className="ml-auto">
                                {value && value === type && (
                                    <ErrorBoundary>
                                        <PropertySubtypeFilter type={type} {...field} setValue={setSubtypes} />
                                    </ErrorBoundary>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </FormPanel>
    );
}

function PropertySubtypeFilter({ type, value, onChange, setValue }) {
    const intl = useIntl();

    const subtypeOptions = getPropertiesSubtypes()[type];

    const options = subtypeOptions
        .map(option => {
            return {
                value: option,
                label: intl.formatMessage({ id: `pages.properties.property_subtype_panel.options.${option}` }),
            };
        })
        .sort((a, b) => {
            return a.label.localeCompare(b.label);
        });

    return (
        <FilterChip
            size="small"
            placeholder={<FormattedMessage id="misc.subtypes" defaultMessage="Subtypes" />}
            popupHeader={null}
            value={getValueLabel(value, options)}
            popupClassName="w-72 properties-subtypes-popup"
            onClear={() => {
                setValue([]);
            }}
            renderPopupContent={({ closePopup }) => {
                return (
                    <div>
                        <fieldset>
                            {options.map(option => {
                                const checked = value?.includes(option.value);

                                return (
                                    <div className="flex items-center group -my-2" key={option.value}>
                                        <div className="min-w-0">
                                            <Checkbox
                                                value={option.value}
                                                name="subtypes"
                                                onChange={onChange}
                                                checked={checked}
                                            >
                                                <div className="truncate">{option.label}</div>
                                            </Checkbox>
                                        </div>
                                        <Button
                                            onClick={() => {
                                                setValue([option.value]);
                                                closePopup();
                                            }}
                                            variant="link"
                                            className="ml-auto hidden group-hover:block"
                                        >
                                            <FormattedMessage id="buttons.only" defaultMessage="Only" />
                                        </Button>
                                    </div>
                                );
                            })}
                        </fieldset>
                    </div>
                );
            }}
        />
    );
}

function getValueLabel(value, options) {
    if (!value || value.length === 0) {
        return null;
    }

    if (value?.length === options.length) {
        return null;
    }

    const firstOption = options.find(option => option.value === value[0]);

    if (firstOption) {
        return (
            <>
                {firstOption.label} {value.length > 1 ? `(+ ${value.length - 1})` : null}
            </>
        );
    }

    return null;
}
