// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import { AVAILABLE_USER_LOCALES_CODES } from '@/app.utils/services/Helpers/locales';

const options = AVAILABLE_USER_LOCALES_CODES.map(locale => {
    return {
        value: locale,
        label: <FormattedMessage id={`general.locales.${locale}`} defaultMessage={`general.locales.${locale}`} />,
    };
});

export function LanguageSwitcher({ onChange, value }) {
    const showSwitcher = useSelector(getFeature('locales.languageSwitcher.enabled'));

    if (showSwitcher) {
        return (
            <FormGroup>
                <div className="col-sm-4">
                    <ControlLabel>
                        <FormattedMessage id="general.language.title" defaultMessage="Language" />
                    </ControlLabel>
                </div>
                <div className="col-sm-8">
                    <Select
                        value={value}
                        options={options}
                        onChange={({ value }) => onChange(value)}
                        isSearchable={false}
                    />
                </div>
            </FormGroup>
        );
    } else {
        return null;
    }
}
