import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import Input from '@/app.components/forms/Input/Input';
import { ResourceDomainType, ResourceFilterType } from '@/shared/resource/types';
import { getRadioValue } from '../../utils';

type Props = {
    id: string;
    query: string;
    tempParams: any;
    setTempParams: any;
    filter: ResourceFilterType;
    intlKey: ResourceDomainType;
    domainType: ResourceDomainType;
};

const ResourceFilterFormRadioNumber: React.FC<Props> = props => {
    const { filter, tempParams, setTempParams } = props;

    const intl = useIntl();

    const minName = `min_${filter.paramName}`;
    const maxName = `max_${filter.paramName}`;

    const minValue = tempParams?.[minName];
    const maxValue = tempParams?.[maxName];

    const [tempOption, setTempOption] = useState(getRadioValue(minValue, maxValue));

    return (
        <>
            <Input
                type="price"
                className="m-0"
                decimalScale={0}
                key={filter.paramName}
                value={minValue || maxValue || ''}
                placeholder={<FormattedMessage id="general.any" defaultMessage="Any" />}
                onChange={newValue => {
                    const isCorrect = newValue != null && parseFloat(newValue);

                    const isCorrectMin = tempOption === 'equal' || tempOption === 'above';
                    const isCorrectMax = tempOption === 'equal' || tempOption === 'below';

                    setTempParams({
                        ...tempParams,
                        [minName]: isCorrect && isCorrectMin ? parseFloat(newValue) : undefined,
                        [maxName]: isCorrect && isCorrectMax ? parseFloat(newValue) : undefined,
                    });
                }}
            />

            <div className="resource-filter-form__list">
                {filter?.options?.map(option => {
                    const label =
                        option.label ||
                        intl.formatMessage({
                            id:
                                option.translateKey ||
                                `${props.intlKey}.filter.${props.id}.${option.key || option.value}`,
                        });

                    return (
                        <div key={option.value} className="resource-filter-form__item group">
                            <Input
                                type="radio"
                                label={label}
                                checked={tempOption === option.value}
                                onChange={() => {
                                    const newTempOption = option.value || '';

                                    setTempOption(newTempOption);

                                    setTempParams({
                                        ...tempParams,
                                        [minName]:
                                            newTempOption === 'equal' || newTempOption === 'above'
                                                ? minValue || maxValue
                                                : undefined,
                                        [maxName]:
                                            newTempOption === 'equal' || newTempOption === 'below'
                                                ? minValue || maxValue
                                                : undefined,
                                    });
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default ResourceFilterFormRadioNumber;
