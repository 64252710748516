import { createReducer } from 'redux-reducer-factory';
import { SET_FACEBOOK_ACCOUNTS, SET_CHANNEL_TOKEN, SET_TWITTER_PROFILE, SET_CHANNELS } from '../actions';

export default createReducer(
    {
        facebook: {
            accounts: [],
        },
        items: [],
    },
    {
        [SET_CHANNELS]: (channels, { payload }, sanitize) => channels.set('items', sanitize(payload)),
        [SET_FACEBOOK_ACCOUNTS]: (channels, { accounts }, sanitize) =>
            channels.setIn(['facebook', 'accounts'], sanitize(accounts)),
        [SET_CHANNEL_TOKEN]: (channels, { channel, token }) => channels.setIn([channel, 'access_token'], token),
        [SET_TWITTER_PROFILE]: (channels, { profile }, sanitize) =>
            channels.setIn(['twitter', 'profile'], sanitize(profile)),
    },
);
