import React from 'react';
import { useIntl } from 'react-intl';
import useLabel from '@/app.hooks/useLabel';
import ContactLabelTooltip from './ContactLabelTooltip';

type Props = {
    id?: string;
    color?: string;
    label?: string;
    tooltip?: boolean;
    truncate?: boolean;
    onClick?: () => void;
    description?: Maybe<string>;
};

const ContactLabel: React.FC<Props> = props => {
    const { id, color, label, tooltip, truncate, onClick, description } = props;

    const intl = useIntl();

    const { data } = useLabel(id);

    const content = {
        loading: intl.formatMessage({ id: 'general.state.loading' }),
    };

    return (
        <ContactLabelTooltip tooltip={tooltip} description={description || data?.description}>
            <div className={`contact-label${truncate ? ' shorten' : ''}`}>
                <div className="contact-label__color" style={{ backgroundColor: color || data?.color || '#cdcddc' }} />
                <span className="contact-label__text">{label || data?.name || content.loading}</span>
                {onClick && (
                    <span onClick={onClick} className="contact-label__delete">
                        ×
                    </span>
                )}
            </div>
        </ContactLabelTooltip>
    );
};

export default ContactLabel;
