import gql from 'graphql-tag';

export const getCurrentUserRole = gql`
    query getCurrentUserRole {
        me {
            id
            role
        }
    }
`;
