import React from 'react';
import { DataTable as DataTableBase } from '@sweepbright/uikit';
import classNames from 'classnames';
import './DataTable.scss';

export default function DataTable({
    className,
    ...props
}: {
    className?: string;
    hover?: boolean;
    columns: any;
    dataSource: any;
}) {
    return <DataTableBase {...props} className={classNames('c-data-table', className)} />;
}
