import { useMutation, useQuery, useQueryCache } from 'react-query';
import React, { useMemo } from 'react';
import { LoadingIndicator } from '@sweepbright/uikit';
import classNames from 'classnames';
import {
    createAvailabilityMutation,
    deleteAvailabilityMutation,
    fetchAvailability,
    updateAvailabilityMutation,
} from '@/app.domains/properties/Schedule/visits_requests';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import WithPrivateTooltip from '@/app.components/forms/WithPrivateTooltip';
import AddAvailabilityModal from '@/app.components/modals/AddAvailabilityModal';
import EmptyBlock from '@/app.components/empty/EmptyBlock';
import Icon from '@/app.components/icons/Icon';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import { useToasts } from '@sweepbright/notifications';
import { getWeekDaysMap } from '@/app.data/Weekdays';

export function AvailabilityPanel({ propertyId, disabled }: { propertyId: string; disabled?: boolean }) {
    const queryCache = useQueryCache();
    const { addSuccess, addError } = useToasts();
    const [showAddModal, setShowAddModal] = React.useState(false);
    const queryKey = `properties/${propertyId}/availability`;
    const { data: availabilityRules = [], isLoading } = useQuery([queryKey, propertyId], fetchAvailability);

    const [createAvailability] = useMutation(
        (values: { start: number; end: number; days: string[] }) => createAvailabilityMutation(propertyId, values),
        {
            onSuccess: () => {
                queryCache.invalidateQueries(queryKey);
                addSuccess({
                    message: (
                        <FormattedMessage
                            id="pages.properties.availability_panel.availability_added"
                            defaultMessage="Availability added"
                        />
                    ),
                });
            },
            onError: err => {
                // @ts-ignore
                const isForbidden = err?.message?.includes('403');

                addError({
                    message: isForbidden ? (
                        <FormattedMessage id="unauthorised_403" />
                    ) : (
                        <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                    ),
                });
            },
        },
    );

    return (
        <FormPanel
            title={
                <WithPrivateTooltip>
                    <FormattedMessage
                        id="pages.properties.availability_panel.availability_for_appointments"
                        defaultMessage="Availability for appointments"
                    ></FormattedMessage>
                </WithPrivateTooltip>
            }
            disableAction={disabled}
            action={<FormattedMessage id="pages.properties.availability_panel.add_time" defaultMessage="Add Time" />}
            onAction={() => setShowAddModal(true)}
        >
            <AddAvailabilityModal
                show={showAddModal}
                onClose={() => setShowAddModal(false)}
                onSubmit={async (values: { days: string[]; start: number; end: number }) => {
                    await createAvailability(values);
                    setShowAddModal(false);
                }}
            />
            {isLoading && <LoadingIndicator />}
            {!isLoading && availabilityRules?.length === 0 && (
                <EmptyBlock
                    text={
                        <FormattedMessage
                            id="pages.properties.availability_panel.no_availability_yet"
                            defaultMessage="No availability added yet"
                        />
                    }
                />
            )}
            <ul className="list-none p-0">
                {availabilityRules.map(availability => {
                    return (
                        <li key={availability.id}>
                            <Availability disabled={disabled} availability={availability} />
                        </li>
                    );
                })}
            </ul>
        </FormPanel>
    );
}

function Availability({
    availability,
    disabled,
}: {
    availability: {
        property_id: string;
        id: string;
        days: string[];
        start: number;
        end: number;
    };
    disabled?: boolean;
}) {
    const weekDaysTranslations = useMemo(() => getWeekDaysMap(), []);

    const queryCache = useQueryCache();
    const { addSuccess, addError } = useToasts();
    const [editing, setEditing] = React.useState(false);
    const queryKey = `properties/${availability.property_id}/availability`;
    const [deleteAvailabilityRule, { isLoading: isDeleting }] = useMutation(
        () => deleteAvailabilityMutation(availability.property_id, availability.id),
        {
            onSuccess: () => {
                queryCache.invalidateQueries(queryKey);
                addSuccess({
                    message: (
                        <FormattedMessage
                            id="pages.properties.availability_panel.availability_deleted"
                            defaultMessage="Availability deleted"
                        />
                    ),
                });
            },
            onError: err => {
                // @ts-ignore
                const isForbidden = err?.message?.includes('403');

                addError({
                    message: isForbidden ? (
                        <FormattedMessage id="unauthorised_403" />
                    ) : (
                        <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                    ),
                });
            },
        },
    );

    const [updateAvailabilityRule, { isLoading: isUpdating }] = useMutation(
        (values: { start: number; end: number; days: string[] }) =>
            updateAvailabilityMutation(availability.property_id, availability.id, values),
        {
            onSuccess: () => {
                queryCache.invalidateQueries(queryKey);
                addSuccess({
                    message: (
                        <FormattedMessage
                            id="pages.properties.availability_panel.availability_updated"
                            defaultMessage="Availability updated"
                        />
                    ),
                });
            },
            onError: err => {
                // @ts-ignore
                const isForbidden = err?.message?.includes('403');

                addError({
                    message: isForbidden ? (
                        <FormattedMessage id="unauthorised_403" />
                    ) : (
                        <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                    ),
                });
            },
        },
    );

    const isDisabled = disabled || isDeleting || isUpdating;

    return (
        <div className="flex border-b border-solid border-gray-light py-1 item-center min-w-0">
            <div className="flex-1 flex justify-between items-center min-w-0">
                <div className="flex items-center min-w-0">
                    <Icon icon="clock" size={20} />
                    <div className="truncate ml-2">
                        {availability.days.map(day => weekDaysTranslations[day]).join(', ')}
                    </div>
                </div>
                <div className="text-muted flex-shrink-0">
                    {getTime(availability.start)} - {getTime(availability.end)}
                </div>
            </div>
            <div className="flex items-center ml-4">
                <ButtonIcon
                    type="edit"
                    variant="link"
                    iconSize={20}
                    onClick={() => setEditing(true)}
                    disabled={isDisabled}
                />
                <ButtonIcon
                    type="circle-delete"
                    variant="link"
                    iconSize={20}
                    //@ts-ignore
                    onClick={deleteAvailabilityRule}
                    disabled={isDisabled}
                    className={classNames({ 'text-danger': !isDisabled, 'text-muted': isDisabled })}
                />
                <AddAvailabilityModal
                    editing
                    show={editing}
                    initialValues={{
                        days: availability.days,
                        start: availability.start,
                        end: availability.end,
                    }}
                    onClose={() => setEditing(false)}
                    //@ts-ignore
                    onSubmit={values => {
                        // update availability
                        return updateAvailabilityRule(values).finally(() => {
                            setEditing(false);
                        });
                    }}
                />
            </div>
        </div>
    );
}

function getTime(minutes) {
    return `${String(Math.floor(minutes / 60)).padStart(2, '0')}:${String(Math.floor(minutes % 60)).padStart(2, '0')}`;
}
