// @ts-nocheck
import React, { forwardRef } from 'react';
import { Formik as FormikComponent, FormikProps } from 'formik';
import Portal from '@reach/portal';

const Formik: React.FunctionComponent<FormikProps<any>> = forwardRef(function Formik(
    { children, ...props }: FormikProps,
    ref,
) {
    return (
        <FormikComponent {...props} ref={ref}>
            {formikProps => {
                return (
                    <>
                        <FormikDebugPanel values={formikProps.values} errors={formikProps.errors} />
                        {children(formikProps)}
                    </>
                );
            }}
        </FormikComponent>
    );
});

Formik.displayName = 'FormikWithDebugPanel';

export default APP_ENV === 'local' ? Formik : FormikComponent;

function FormikDebugPanel({ values, errors }) {
    const [expanded, setExpanded] = React.useState(false);

    return (
        <Portal>
            <div
                id="formik-debug-panel"
                style={{
                    display: 'fixed',
                    top: 0,
                    left: 0,
                    maxHeight: '80vh',
                    width: 400,
                    margin: 8,
                    padding: 8,
                    border: '1px solid gray',
                    background: 'white',
                    position: 'relative',
                    zIndex: 2000,
                    overflowY: 'scroll',
                }}
            >
                <div className="flex w-full items-center">
                    <h4>Formik debug panel</h4>
                    <div className="ml-auto" onClick={() => setExpanded(!expanded)} role="button">
                        [{expanded ? '-' : '+'}]
                    </div>
                </div>
                {expanded && (
                    <div>
                        <strong>Values:</strong>
                        <br />
                        <pre>{JSON.stringify(values, (key, value) => (key === 'data_url' ? undefined : value), 2)}</pre>
                    </div>
                )}
                {expanded && (
                    <div>
                        <strong>Errors:</strong>
                        <br />
                        <pre>{JSON.stringify(errors, null, 2)}</pre>
                    </div>
                )}
            </div>
        </Portal>
    );
}
