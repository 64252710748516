import React from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import { useNegotiator } from '@/app.hooks/useNegotiator';
import useUser from '@/app.hooks/useUser';
import ResourceFilter from '@/shared/resource/ResourceFilter';
import { ResourceDomainType } from '@/shared/resource/types';
import FilterNegotiatorsForm from './FilterNegotiatorsForm';

type Props = {
    searchSettings?: any;
    setSearchSettings: Function;
    showUnassignedOption?: boolean;
    domainType: ResourceDomainType;
    translationDomainType?: ResourceDomainType;
};

const FilterNegotiators: React.FC<Props> = props => {
    const { domainType, searchSettings, setSearchSettings, showUnassignedOption, translationDomainType } = props;

    const intl = useIntl();
    const user = useUser();

    const content = {
        title: intl.formatMessage({
            id: 'filters.labels.ownership',
        }),
        mineLabel: intl.formatMessage({
            id: `modals.${translationDomainType || domainType}_search.ownership_pane.mine_label`,
        }),
    };

    const value = searchSettings?.negotiator_ids;

    const userId = `${user.get('id')}`;

    const getValueLabel = () => {
        if (value === null && showUnassignedOption) {
            return <FormattedMessage id="filters.labels.ownership.unassigned" defaultMessage="Unassigned" />;
        }

        if (value && value.length) {
            if (value.length === 1 && value.includes(userId)) {
                return content.mineLabel;
            }

            return <OwnershipLabel value={value} translationDomainType={translationDomainType || domainType} />;
        }

        return '';
    };

    return (
        <ResourceFilter
            id="negotiator_ids"
            label={content.title}
            domainType={domainType}
            params={searchSettings}
            setParams={setSearchSettings}
            customActiveLabel={() => {
                return getValueLabel() || null;
            }}
            translationDomainType={translationDomainType}
            filters={[
                {
                    type: 'custom',
                    paramName: 'negotiator_ids',
                    children: (params, setParams) => (
                        <div>
                            <FilterNegotiatorsForm
                                tempSearchSettings={params}
                                mineLabel={content.mineLabel}
                                setTempSearchSettings={setParams}
                                showUnassignedOption={showUnassignedOption}
                            />
                        </div>
                    ),
                },
            ]}
        />
    );
};

const OwnershipLabel: React.FC<{ value: string[]; translationDomainType?: ResourceDomainType }> = ({
    value,
    translationDomainType,
}) => {
    const { loading, negotiator } = useNegotiator(value[0]);

    if (loading) {
        return (
            <FormattedMessage
                id="modals.properties_search.ownership_pane.loading_placeholder"
                defaultMessage="Loading..."
            />
        );
    }

    const name = (negotiator?.firstName || negotiator?.lastName || 'Unknown')?.split(/\s/)[0];

    return (
        <FormattedMessage
            values={{ name: value.length === 1 ? name : `${name} (+${value.length - 1})` }}
            id={
                translationDomainType === 'properties'
                    ? 'components.ownership_filter.with_negotiator_label'
                    : 'components.contacts_ownership_filter.with_negotiator_label'
            }
        />
    );
};

export default FilterNegotiators;
