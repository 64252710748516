import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { ContactEmailedActivity } from '@/graphql/generated/types';
import { trackHandler, events } from '@/app.utils/analytics';
import { fullName } from '@/app.utils/services/Helpers';
import { ContactLink, PropertyLink } from './elements/elements';
import ActivityCardWithAction from './ActivityCardWithAction';

export default function ContactEmailedActivityCard({
    activity,
    viewAs,
}: {
    activity: ContactEmailedActivity;
    viewAs: 'property' | 'contact';
}) {
    const title =
        viewAs === 'property' ? (
            <FormattedMessage
                id="interaction.lead_email_sent.parameterised"
                defaultMessage="E-mail sent to {contact}"
                values={{
                    contact: activity.emailedContact && (
                        <ContactLink
                            contact={activity.emailedContact}
                            onClick={trackHandler(events.ACTIVITY_CARD_CONTACT_LINK_CLICKED, {
                                activityType: activity.__typename,
                            })}
                        />
                    ),
                }}
            />
        ) : (
            <FormattedMessage
                id="interaction.lead_email_sent.about.parameterised"
                defaultMessage="E-mail sent about {location}"
                values={{
                    location: activity.about && <PropertyLink property={activity.about!} />,
                }}
            />
        );

    const emailedByText = (
        <FormattedMessage
            id="interaction.lead_email_sent.by.parameterised"
            defaultMessage="E-mail sent by {user}"
            values={{
                user: fullName(activity.emailedBy.firstName, activity.emailedBy.lastName),
            }}
        />
    );
    const subtitle =
        viewAs === 'property' ? (
            <>
                {activity.about!.attributes.location.formatted ?? (
                    <FormattedMessage id="address.no_location_defined" defaultMessage="No location defined" />
                )}
                {' — '}
                {emailedByText}
            </>
        ) : (
            <>
                {`${fullName(activity?.emailedContact?.firstName, activity?.emailedContact?.lastName)} - `}
                {emailedByText}
            </>
        );

    return (
        <ActivityCardWithAction
            icon="mail"
            title={title}
            subtitle={subtitle}
            date={activity.timestamp}
            activity={activity}
        />
    );
}
