import React from 'react';
import { useIntl } from 'react-intl';
import { FilterChip } from '@sweepbright/uikit/build/esm/filterchip';
import { FormattedMessage } from 'react-intl-sweepbright';
import Area from '@/app.domains/properties/Area';
import useOffice from '@/app.hooks/useOffice';
import { getDefaultUnitForArea } from '@/app.data';
import { handleActiveAreaType } from '../../utils';
import { PropertySearchType } from '../../types';
import PropertyFilterAreaForm from './PropertyFilterAreaForm';

type Props = {
    searchSettings?: PropertySearchType;
    setSearchSettings: Function;
    options: ['loi_carrez', 'liveable', 'net'] | ['plot', 'gross'];
};

export const ALLOWED_AREA_RANGE = {
    min_area: 0,
    max_area: 10000000,
};

const allowedRange: [number, number] = [ALLOWED_AREA_RANGE.min_area, ALLOWED_AREA_RANGE.max_area];

const PropertyFilterAreaOld: React.FC<Props> = props => {
    const { searchSettings, setSearchSettings, options } = props;

    const intl = useIntl();

    const content = {
        area: intl.formatMessage({
            id: 'filters.labels.area',
        }),
        placeholder: intl.formatMessage({
            id: `properties.filter.area.${options.join('_')}`,
        }),
    };

    const fullOptions = options.map(el => [`min_${el}_area`, `max_${el}_area`]).flat();

    const activeAreaType = handleActiveAreaType(searchSettings, options) || options[0];

    const minValue = Number(searchSettings?.[`min_${activeAreaType}_area`]) || 0;
    const maxValue = Number(searchSettings?.[`max_${activeAreaType}_area`]) || 0;

    const areaRange = [minValue, maxValue];
    const measurementSystem = useOffice().getIn(['settings', 'data', 'measurement_system']);
    const units = getDefaultUnitForArea(activeAreaType, measurementSystem, 'house');

    const valueLabel =
        minValue || maxValue
            ? formatRange(
                  // @ts-ignore
                  areaRange,
                  allowedRange,
                  units,
              )
            : null;

    const value = valueLabel ? (
        <>
            {intl.formatMessage({
                id: `properties.fields.structure.${activeAreaType}_area.size`,
            })}
            : {valueLabel}
        </>
    ) : null;

    const handleClear = () => {
        setSearchSettings({
            ...searchSettings,
            ...Object.fromEntries(fullOptions.map(el => [el, undefined])),
        });
    };

    return (
        <FilterChip
            size="small"
            value={value}
            popupHeader={null}
            onClear={() => {
                handleClear();
            }}
            placeholder={content.placeholder}
            testId="property-subtype-filter-chip"
            renderPopupContent={({ closePopup }) => {
                return (
                    <PropertyFilterAreaForm
                        old
                        options={options}
                        fullOptions={fullOptions}
                        title={content.placeholder}
                        initialValues={searchSettings}
                        onSubmit={newValues => {
                            setSearchSettings(newValues);
                            closePopup();
                        }}
                        initActiveAreaType={activeAreaType}
                    />
                );
            }}
        />
    );
};

function formatRange([low, high], [min, max], units) {
    const content = {
        min: <FormattedMessage id="general.min" defaultMessage="min." />,
        max: <FormattedMessage id="general.max" defaultMessage="max." />,
    };

    if (low && high && low > min && high < max) {
        return (
            <>
                <Area hideUnit size={low} units={units} />
                &#45;
                <Area size={high} units={units} />
            </>
        );
    } else if (low > min) {
        return (
            <>
                {content.min} <Area size={low} units={units} />
            </>
        );
    } else if (high < max) {
        return (
            <>
                {content.max} <Area size={high} units={units} />
            </>
        );
    }

    return null;
}

export default PropertyFilterAreaOld;
