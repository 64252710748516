import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { computeBuyerToOwnerOffer, computeOwnerToBuyerOffer } from '@sweepbright/offer-fees';
import Icon from '@/app.components/icons/Icon';
import { CreateOfferInput, OfferDirection } from '@/graphql/generated/types';
import { FormattedMessage } from 'react-intl-sweepbright';
import { formatPrice } from '@/app.utils/services/Helpers/priceFormatting';
import { refineFinancialDetailsInput } from '@/app.shared/offers/utils';

type Props = {
    propertyNegotiation: 'let' | 'sale';
};

const OfferModalDetailsFee: React.FC<Props> = props => {
    const { propertyNegotiation } = props;

    const { values } = useFormikContext<CreateOfferInput>();

    const content = {
        agency: <FormattedMessage id="offers.total.agency" defaultMessage="Agency Fee:" />,
        buyer: <FormattedMessage id={`offers.total.buyer.${propertyNegotiation}`} defaultMessage="Buyer Pays" />,
        owner: <FormattedMessage id="offers.total.owner" defaultMessage="Owner Pays" />,
    };

    const computedFinancialDetails = useMemo(() => {
        const refined = refineFinancialDetailsInput(values.financialDetails);
        if (!refined) {
            return;
        }

        return refined.direction === OfferDirection.BuyerToOwner
            ? computeBuyerToOwnerOffer(refined)
            : computeOwnerToBuyerOffer(refined);
    }, [values.financialDetails]);

    const total = computedFinancialDetails?.agencyTotalFee || 0;
    const totalBuyer = computedFinancialDetails?.buyerTotalFee || 0;
    const totalOwner = computedFinancialDetails?.ownerTotalFee || 0;

    const currency = values?.financialDetails?.currency || 'EUR';

    const options = {
        format: '%s %v',
        thousand: ' ',
        precision: 0,
    };

    const totalPrice = formatPrice({ amount: total || 0, currency: currency }, options);
    const totalBuyerPrice = formatPrice({ amount: totalBuyer || 0, currency: currency }, options);
    const totalOwnerPrice = formatPrice({ amount: totalOwner || 0, currency: currency }, options);

    const isGood = total && totalBuyer && totalOwner;

    return (
        <div className="offer-modal-details-fee">
            <div className="offer-modal-details-fee__icon">
                <Icon
                    size={16}
                    style={isGood ? { color: '#47C9AD' } : {}}
                    icon={isGood ? 'circle-success' : 'circle-info'}
                />
            </div>
            <div className="offer-modal-details-fee__content">
                <span className="offer-modal-details-fee__title">
                    {content.agency} {total ? totalPrice : '/'}
                </span>
                <span className="offer-modal-details-fee__text">
                    {content.buyer} {totalBuyer ? totalBuyerPrice : '/'} – {content.owner}{' '}
                    {totalOwner ? totalOwnerPrice : '/'}
                </span>
            </div>
        </div>
    );
};

export default OfferModalDetailsFee;
