import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { findById } from './CommonSelectors';
import { getCompanyLanguages } from './CompaniesSelector';
import { getCurrentOffice } from './RoutingSelectors';

export const getOffices = state => state.offices;

// get the id the passed office or gets the office from the route
const getProvidedOrCurrentOffice = createSelector(
    (state, props = {}) => props.officeId,
    getCurrentOffice,
    (providedOfficeId, currentOfficeId) => {
        return providedOfficeId || currentOfficeId;
    },
);

export const getOffice = createSelector(
    findById(getOffices, getProvidedOrCurrentOffice),
    office => office || new Map(),
);

export const getOfficeSettings = createSelector(getOffice, office => office.getIn(['settings', 'data']));

export const getOfficeSetting = setting => createSelector(getOfficeSettings, settings => settings?.get(setting));

// Forms
function formatOffice(_office) {
    const settings = _office.getIn(['settings', 'data'], new Map());

    let office = _office.set('name', _office.get('name'));
    office = office.set('lead_negotiator', office.getIn(['head_negotiator', 'data', 'id']));
    office = office.set(
        'settings',
        settings
            .update('use_bright_matching', enabled => enabled || false)
            .set('default_assignee', settings.getIn(['default_assignee', 'id'])),
    );

    return office.toJS();
}

export const getOfficeFormValues = createSelector(getOffice, formatOffice);

export const getOfficeDefaultMailValues = (type = 'email_matching_lead_description') =>
    createSelector([getOffice, getCompanyLanguages], (office, locales) => ({
        selected: [],
        contents: locales
            .reduce((reduction, { value: locale }) => {
                return reduction.set(locale, office.getIn(['settings', 'data', `${type}_${locale}`], ''));
            }, Map())
            .toJS(),
    }));

export const getOfficeDefaultEmailForFilesRequest = createSelector(
    [getOfficeSettings, (state, props) => props.contentSetting],
    (settings, contentSetting) => settings.get(contentSetting),
);

export const getOfficeById = officeId =>
    createSelector(
        findById(getOffices, () => officeId),
        office => office || new Map(),
    );
