import Button from '@sweepbright/uikit/build/esm/button';
import { FilterChip } from '@sweepbright/uikit/build/esm/filterchip';
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { events, trackHandler } from '@/app.utils/analytics';

import Input from '../../../app.components/forms/Input/Input';

const estateTypeValueLabel = {
    single_project: (
        <FormattedMessage id="modals.properties_search.property_type_pane.project_label" defaultMessage="Projects" />
    ),
    single_property: (
        <FormattedMessage
            id="modals.properties_search.property_type_pane.property_label"
            defaultMessage="Single Properties"
        />
    ),
};

export default function EstateTypeFilter({ value, defaultValue = 'property_project', onChange }) {
    return (
        <FilterChip
            testId="internal-type-filter-chip"
            size="small"
            placeholder={<FormattedMessage id="filters.labels.all_properties" defaultMessage="Projects" />}
            onClear={() => {
                onChange({ property_type: defaultValue });
            }}
            value={value !== defaultValue && estateTypeValueLabel[value]}
            popupHeader={null}
            renderPopupContent={({ closePopup }) => {
                return (
                    <Form
                        initialValues={{ property_type: value }}
                        onSubmit={newValues => {
                            onChange(newValues);
                            closePopup();
                        }}
                    />
                );
            }}
        />
    );
}

const Form = ({ initialValues, onSubmit }) => {
    const [estateType, setEstateType] = React.useState(initialValues.property_type);

    return (
        <form
            onSubmit={evt => {
                evt.preventDefault();
                onSubmit({ property_type: estateType });
            }}
            className="w-64"
        >
            <FormPanel
                popup
                title={
                    <FormattedMessage
                        id="modals.properties_search.ownership_pane.all_label"
                        defaultMessage="Projects"
                    />
                }
                action={
                    <Button
                        variant="link"
                        type="submit"
                        onClick={trackHandler(events.SEARCH_FILTER_CHIP_APPLY_BTN_CLICKED, {
                            search: 'properties',
                            filter: 'property_project',
                        })}
                    >
                        <FormattedMessage id="buttons.apply" defaultMessage="Apply" />
                    </Button>
                }
            >
                <Input
                    checked={estateType === 'single_project'}
                    type="radio"
                    onChange={() => setEstateType('single_project')}
                    label={
                        <FormattedMessage
                            id="modals.properties_search.property_type_pane.project_label"
                            defaultMessage="Project"
                        />
                    }
                />

                <Input
                    checked={estateType === 'single_property'}
                    type="radio"
                    onChange={() => setEstateType('single_property')}
                    label={
                        <FormattedMessage
                            id="modals.properties_search.property_type_pane.property_label"
                            defaultMessage="Single Properties"
                        />
                    }
                />
            </FormPanel>
        </form>
    );
};
