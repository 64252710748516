import React, { useState } from 'react';
import moment from 'moment';
import { Avatar, Tooltip } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import { fullName } from '@/app.utils/services/Helpers';
import Link from '@/app.components/elements/Link';
import { CONTACT } from '@/app.routing/routes';
import Icon from '@/app.components/icons/Icon';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { ContactLastAction } from '@/app.domains/contacts/ContactLastActivity';
import { Contact } from '@/graphql/generated/types';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import { useContactArchiver } from '@/app.hooks/useContactArchiver';
import interactionTypesMessages from '@/app.data/Interactions';
import { NegotiatorRepository } from '@/app.utils/services';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import ContactLabelsModal from '@/shared/contacts/ContactLabelsModal';
import ContactCardType from '@/shared/contacts/ContactCard/ContactCardType';
import { APIContact } from '@sweepbright/webapp-server/graphql/resolvers/helpers/types';
import ContactsTableNote from './Contacts/ContactsTable/ConctactsTableNote';
import { ContactCardData } from './types';
import ContactsTableLabels from './Contacts/ContactsTable/ContactsTableLabels';

export const renderDetails = ({
    type,
    contact,
    intl,
    user,
}: {
    type: 'name' | 'type' | 'email' | 'phone' | 'last_activity' | 'created_at' | 'negotiator' | 'notes' | 'labels';
    contact: Contact;
    intl?: ReactIntl.InjectedIntl;
    user?: any;
}) => {
    if (type === 'name') {
        const value = fullName(contact.firstName, contact.lastName);

        if (value) {
            return (
                <Link className="flex items-center no-underline text-gray-dark" to={CONTACT(contact.id)}>
                    <Avatar type="sm" name={value} />
                    <div className="max-w-xs truncate">
                        <div className="mx-2 flex items-center">{value}</div>
                    </div>
                    {contact.archived && (
                        <Tooltip label={<FormattedMessage id="property.status.archived" defaultMessage="Archived" />}>
                            <Icon icon="archived" />
                        </Tooltip>
                    )}
                </Link>
            );
        }
    }

    if (type === 'type') {
        const value = contact.type;

        if (value) {
            return <ContactCardType contact={contact} />;
        }
    }

    if (type === 'email') {
        const value = contact.email;

        if (value) {
            return (
                <div className="max-w-xs truncate">
                    <a href={`mailto:${value}`} className="text-gray-dark" target="_blank" rel="noopener noreferrer">
                        {value}
                    </a>
                </div>
            );
        }
    }

    if (type === 'phone') {
        const value = contact.phone;

        return (
            <a href={`tel:${value}`} className="text-gray-dark" target="_blank" rel="noopener noreferrer ">
                {value}
            </a>
        );
    }

    if (type === 'last_activity') {
        return (
            <ErrorBoundary key="last_action">
                <Link to={CONTACT(contact.id)} className="truncate text-gray-dark no-underline">
                    <ContactLastAction
                        // @ts-ignore
                        type={contact.type}
                        // @ts-ignore
                        lastAction={contact.lastAction}
                        lastInteraction={contact.lastInteraction}
                        showLastInteraction
                        updatedAt={contact.updatedAt}
                        createdAt={contact.createdAt}
                    />
                </Link>
            </ErrorBoundary>
        );
    }

    if (type === 'created_at') {
        const value = contact.createdAt;

        if (value) {
            return (
                <div data-testid="created-time" className="truncate">
                    <FormattedMessage {...interactionTypesMessages.created} />{' '}
                    <time dateTime={moment(value).format('YYYY-MM-DD HH:mm')}>{moment(value).fromNow()}</time>
                </div>
            );
        }
    }

    if (type === 'negotiator') {
        const value = contact.assignedNegotiators?.[0];

        const label = new NegotiatorRepository(value).getLabel({
            isCurrentUser: value?.id === user?.get('id'),
            intl,
        });

        if (value) {
            return (
                <span className="flex items-center no-underline text-gray-dark">
                    <Avatar type="sm" name={label} image={value?.picture_url} />
                    <div className="max-w-xs truncate">
                        <div className="mx-2 flex items-center">{label}</div>
                    </div>
                </span>
            );
        }
    }

    if (type === 'notes') {
        const value = contact.note;

        if (value) {
            return <ContactsTableNote note={value} />;
        }
    }

    if (type === 'labels') {
        return <ContactsTableLabels contactId={contact.id} />;
    }

    return <span className="text-muted">-</span>;
};

export const renderMenu = (contactId: string) => {
    return <ContactRowMenu contactId={contactId} />;
};

function ContactRowMenu({ contactId }: { contactId: string }) {
    const showLabels = useFeatureFlag('contact.labels.enabled');

    const [visible, setVisible] = useState<'labels' | undefined>(undefined);

    const { isArchived, toggleArchived, loading } = useContactArchiver(contactId);

    return (
        <>
            <Dropdown id="status_dropdown" data-testid="property-status-dropdown">
                <Dropdown.Toggle
                    as="button"
                    className="bg-transparent p-2 flex items-center focus:outline-none focus:shadow-outline rounded cursor-default"
                >
                    <Icon icon="options" size={24} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="pull-right">
                    {showLabels && (
                        <Dropdown.MenuItem
                            key="labels"
                            onSelect={() => {
                                setVisible('labels');
                            }}
                            className="focus:outline-none"
                        >
                            <FormattedMessage id="contact.labels.modal" defaultMessage="Edit Labels" />
                        </Dropdown.MenuItem>
                    )}
                    <Dropdown.MenuItem
                        key="archive"
                        onSelect={toggleArchived}
                        className="focus:outline-none"
                        disabled={loading}
                    >
                        {isArchived ? (
                            <FormattedMessage id="actions.unarchive" defaultMessage="Unarchive" />
                        ) : (
                            <FormattedMessage id="actions.archive" defaultMessage="Archive" />
                        )}
                    </Dropdown.MenuItem>
                </Dropdown.Menu>
            </Dropdown>

            {showLabels && (
                <ContactLabelsModal visible={visible === 'labels'} setVisible={setVisible} contactId={contactId} />
            )}
        </>
    );
}

export function adaptContact(contact: APIContact): ContactCardData {
    const result: ContactCardData & { lastAction: string; lastInteraction: any } = {
        id: contact.id,
        firstName: contact.first_name,
        lastName: contact.last_name,
        // @ts-ignore
        type: contact.type?.toUpperCase(),
        lastAction: contact.latest_action,
        updatedAt: contact.updated_at,
        lastInteraction: contact.latest_interaction?.data,
        archived: contact.is_archived,
        // @ts-ignore
        assignedNegotiators: contact.assignedNegotiators,
    };

    if (contact.photo?.data?.url) {
        // @ts-ignore
        result.photo = {
            url: contact.photo?.data?.url,
        };
    }

    return result;
}
