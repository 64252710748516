import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import Checkbox from '@sweepbright/uikit/build/esm/checkbox';
import Icon, { IconName } from '../icons/Icon';
import Tile from '../elements/Tile/Tile';

export const amenityIconForAmenity: Map<string, IconName> = Map({
    'amenity-fenced': 'amenity-fence',
});

export const getAmenityIcon: (name: string) => IconName = name => {
    const formattedName = name.toLowerCase().replace(/_/g, '-');

    const noNewIconArray = ['code', 'electrical_gate', 'key_card'];

    const iconName = noNewIconArray.includes(name) ? formattedName : `amenity-${formattedName}`;

    return amenityIconForAmenity.get(iconName, iconName as IconName);
};

const Amenity = ({ field, name, title }) => (
    <Tile>
        <label className="c-tile-list__content" htmlFor={field.name}>
            <Icon icon={getAmenityIcon(name)} style={{ fontSize: 32, width: 32, height: 32 }} />
            <Checkbox onChange={field.onChange} checked={field.value} id={field.name} name={field.name}>
                {title}
            </Checkbox>
        </label>
    </Tile>
);

Amenity.propTypes = {
    field: PropTypes.object,
    icon: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.object,
};

export default Amenity;
