import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import useTableColumns from '@/app.hooks/useTableColumns';
import { Contact } from '@/graphql/generated/types';
import { renderOfferColumn } from '@/app.shared/offers/utils';
import useUser from '@/app.hooks/useUser';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { renderDetails, renderMenu } from '../utils';

export const useContactsColumns = () => {
    const intl = useIntl();
    const user = useUser();

    const showLabels = useFeatureFlag('contact.labels.enabled');

    const columnsById = useMemo(() => {
        const defaultProps = {
            intl,
            user,
        };

        return {
            id: {
                key: 'id',
                title: <FormattedMessage id="forms.labels.name" defaultMessage="Name" />,
                dataIndex: 'id',
                render: (_id, contact: Contact) => renderDetails({ ...defaultProps, type: 'name', contact }),
            },
            type: {
                key: 'type',
                title: <FormattedMessage id="contacts.table_view.columns_headers.type" defaultMessage="Type" />,
                dataIndex: 'type',
                render: (_id, contact: Contact) => renderDetails({ ...defaultProps, type: 'type', contact }),
            },
            highest_offer_bid: {
                key: 'highest_offer_bid',
                title: (
                    <FormattedMessage
                        id="contacts.table_view.columns_headers.highest_offer_bid"
                        defaultMessage="Highest Offer"
                    />
                ),
                dataIndex: 'id',
                render: (_id, contact: Contact) => renderOfferColumn('highest_offer_bid', null, contact),
                visible: true,
                className: 'whitespace-no-wrap',
            },
            number_of_pending_offers: {
                key: 'number_of_pending_offers',
                title: (
                    <FormattedMessage
                        id="contacts.table_view.columns_headers.number_of_pending_offers"
                        defaultMessage="Offers"
                    />
                ),
                dataIndex: 'id',
                render: (_id, contact: Contact) => renderOfferColumn('number_of_pending_offers', null, contact),
                visible: true,
                className: 'whitespace-no-wrap',
            },
            closest_offer_expirations: {
                key: 'closest_offer_expirations',
                title: (
                    <FormattedMessage
                        id="contacts.table_view.columns_headers.closest_offer_expirations"
                        defaultMessage="Next Offer Expires"
                    />
                ),
                dataIndex: 'id',
                render: (_id, contact: Contact) => renderOfferColumn('closest_offer_expirations', null, contact),
                visible: true,
                className: 'whitespace-no-wrap',
            },
            email: {
                key: 'email',
                title: (
                    <FormattedMessage id="contacts.table_view.columns_headers.email" defaultMessage="E-mail address" />
                ),
                dataIndex: 'email',
                render: (_id, contact: Contact) => renderDetails({ ...defaultProps, type: 'email', contact }),
            },
            phone: {
                key: 'phone',
                title: <FormattedMessage id="contacts.table_view.columns_headers.phone" defaultMessage="Phone" />,
                dataIndex: 'formattedPhone',
                render: (_id, contact: Contact) => renderDetails({ ...defaultProps, type: 'phone', contact }),
            },
            last_activity: {
                key: 'last_activity',
                title: (
                    <FormattedMessage
                        id="contacts.table_view.columns_headers.last_activity"
                        defaultMessage="Last Activity"
                    />
                ),
                dataIndex: 'id',
                render: (_id, contact: Contact) => renderDetails({ ...defaultProps, type: 'last_activity', contact }),
            },
            created_at: {
                key: 'created_at',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="contacts_table.headers.created_at" defaultMessage="Created Time" />,
                dataIndex: 'id',
                render: (_id, contact: Contact) => renderDetails({ ...defaultProps, type: 'created_at', contact }),
            },
            negotiator: {
                key: 'negotiator',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage id="contacts.table_view.columns_headers.negotiator" defaultMessage="Negotiator" />
                ),
                dataIndex: 'id',
                render: (_id, contact: Contact) => renderDetails({ ...defaultProps, type: 'negotiator', contact }),
            },
            notes: {
                key: 'notes',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="contacts.table_view.columns_headers.notes" defaultMessage="Notes" />,
                dataIndex: 'id',
                render: (_id, contact: Contact) => renderDetails({ ...defaultProps, type: 'notes', contact }),
            },
            ...(showLabels && {
                labels: {
                    key: 'labels',
                    className: 'whitespace-no-wrap',
                    title: <FormattedMessage id="contacts.table_view.columns_headers.labels" defaultMessage="Labels" />,
                    dataIndex: 'labels',
                    render: (_id, contact: Contact) => renderDetails({ ...defaultProps, type: 'labels', contact }),
                },
            }),
        };
    }, [intl, user, showLabels]);

    // @ts-ignore
    const initRecord: [string, boolean][] = React.useMemo(
        () => [
            ['type', true],
            ['highest_offer_bid', true],
            ['number_of_pending_offers', true],
            ['closest_offer_expirations', true],
            ['email', true],
            ['phone', true],
            ['last_activity', true],
            ['created_at', false],
            ['negotiator', false],
            ['notes', false],
            ...[showLabels ? ['labels', true] : []],
        ],
        [showLabels],
    );

    const { columns } = useTableColumns({
        memoryKey: 'contacts.columns',
        initRecord,
        columnsExtended: columnsById,
        renderMenu: renderMenu,
        firstColumnId: 'id',
        columnsKeyPrefix: 'contacts.table_view.columns_headers',
    });

    return columns;
};
