import { PropertySearchType } from '../types';
import { ALLOWED_AREA_RANGE } from '../PropertyFilter/PropertyFilterArea/PropertyFilterArea';

export const formatPropertiesSearch = ({
    page,
    limit,
    officeId,
    atlasSearch,
    showEstimate,
    searchSettings,
    showPropertySharedFilter,
}: {
    page?: number;
    limit?: number;
    officeId: string;
    atlasSearch: boolean;
    showEstimate: boolean;
    showPropertySharedFilter: boolean;
    searchSettings?: PropertySearchType;
}) => {
    const maxPrice =
        searchSettings?.negotiation === 'let' ? searchSettings?.max_let_price : searchSettings?.max_sale_price;
    const minPrice =
        searchSettings?.negotiation === 'let' ? searchSettings?.min_let_price : searchSettings?.min_sale_price;

    const query = searchSettings?.query;

    const getAreaValue = (areaName: string) => {
        const value = searchSettings && areaName in searchSettings && searchSettings[areaName];

        if (value) {
            const floatValue = parseFloat(value);

            if (floatValue < ALLOWED_AREA_RANGE.max_area && floatValue > ALLOWED_AREA_RANGE.min_area) {
                return floatValue;
            }
        }

        return undefined;
    };

    const getAddressFloorValue = (floorName: string) => {
        const minValue = searchSettings?.min_address_floor;
        const maxValue = searchSettings?.max_address_floor;

        if (floorName === 'min_address_floor' && minValue) {
            if (minValue && maxValue) {
                return parseInt(minValue) - 1;
            }

            return parseInt(minValue);
        }

        if (floorName === 'max_address_floor' && maxValue) {
            if (minValue && maxValue) {
                return parseInt(maxValue) + 1;
            }

            return parseInt(maxValue);
        }

        return undefined;
    };

    const getBuildingValue = (buildingName: string) => {
        const value = searchSettings && buildingName in searchSettings && searchSettings[buildingName];

        if (value || value === 0) {
            const intValue = parseInt(value);

            return intValue;
        }

        return undefined;
    };

    const status = showEstimate
        ? searchSettings?.status
        : (!!searchSettings?.status?.length &&
              Array.from(
                  new Set([
                      ...(searchSettings?.status || []),
                      ...(searchSettings?.status?.includes('prospect') ? ['estimate'] : []),
                  ]),
              )) ||
          [];

    return {
        page: page || 1,
        limit: limit || 20,
        query: query || '',
        useAtlasSearch: atlasSearch,
        filters: {
            officeId,
            status: status,
            ownership: undefined,
            type: searchSettings?.type,
            subtypes: searchSettings?.subtypes,
            features: searchSettings?.features,
            amenities: searchSettings?.amenities,
            condition: searchSettings?.condition,
            legalEntity: searchSettings?.legalEntity,
            negotiation: searchSettings?.negotiation,
            offerStatus: searchSettings?.offer_status,
            min_net_area: getAreaValue('min_net_area'),
            max_net_area: getAreaValue('max_net_area'),
            min_floors: getBuildingValue('min_floors'),
            max_floors: getBuildingValue('max_floors'),
            min_plot_area: getAreaValue('min_plot_area'),
            max_plot_area: getAreaValue('max_plot_area'),
            min_bedrooms: getBuildingValue('min_bedrooms'),
            max_bedrooms: getBuildingValue('max_bedrooms'),
            min_gross_area: getAreaValue('min_gross_area'),
            max_gross_area: getAreaValue('max_gross_area'),
            negotiator_ids: searchSettings?.negotiator_ids,
            min_liveable_area: getAreaValue('min_liveable_area'),
            max_liveable_area: getAreaValue('max_liveable_area'),
            maxPrice: maxPrice ? parseFloat(maxPrice) : undefined,
            minPrice: minPrice ? parseFloat(minPrice) : undefined,
            min_living_rooms: getBuildingValue('min_living_rooms'),
            max_living_rooms: getBuildingValue('max_living_rooms'),
            min_loi_carrez_area: getAreaValue('min_loi_carrez_area'),
            max_loi_carrez_area: getAreaValue('max_loi_carrez_area'),
            noInterestContactId: searchSettings?.noInterestContactId,
            propertyState: searchSettings?.propertyState || 'active',
            sortField: query ? undefined : searchSettings?.sort_field,
            sortOrder: query ? undefined : searchSettings?.sort_order,
            min_address_floor: getAddressFloorValue('min_address_floor'),
            max_address_floor: getAddressFloorValue('max_address_floor'),
            ...(showPropertySharedFilter && {
                visibility: searchSettings?.visibility || ['team', 'company'],
            }),
            geo_filter: searchSettings?.geo_filter?.map((el: any) => el?.geometry),
            postal_codes: searchSettings?.postal_codes?.map((el: any) => el?.postalCodes).flat(),
            property_internal_type: searchSettings?.property_internal_type || ['project', 'standalone'],
        },
    };
};
