import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { LEADS } from '@/app.routing/routes';
import SideMenuLink from '../navigations/SideMenu/SideMenuLink';
import LeftNavBar from '../navigations/LeftNavBar';

const LeadsAside: React.FC<{ location: string }> = props => {
    const { location } = props;
    const linkProps = { fuzzyMatch: true, location };

    return (
        <LeftNavBar location={location}>
            <SideMenuLink
                {...linkProps}
                to={LEADS}
                icon="house"
                iconActive="face-03"
                label={<FormattedMessage id="forms.leads.title" defaultMessage="Buyer Leads" />}
            />
        </LeftNavBar>
    );
};

export default LeadsAside;
