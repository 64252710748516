import { createReducer } from 'redux-reducer-factory';
import { OrderedSet } from 'immutable';
import { combineReducers } from 'redux';
import { normalize, schema } from 'normalizr';
import { REGISTER_INTERACTION_FEEDBACK } from '@/app.redux/actions/InteractionActions';
import { SET_CONTACT_INTERACTIONS_FOR_PROPERTY } from '../actions';
import concatSetWith from './Helpers/concatSetWith';

const interactionSchema = new schema.Entity('interactions');

const getKey = action => `${action.contactId}/properties/${action.propertyId}`;
const interactionsById = (state = {}, action) => {
    switch (action.type) {
        case SET_CONTACT_INTERACTIONS_FOR_PROPERTY: {
            const normalizedInteractions = normalize(action.interactions, [interactionSchema]);

            return Object.assign(state, normalizedInteractions.entities.interactions);
        }
        case REGISTER_INTERACTION_FEEDBACK: {
            const { interactionId, payload } = action;

            return Object.assign(state, {
                [interactionId]: {
                    ...state[interactionId],
                    feedback_type: payload.type,
                    feedback_external_comment: payload.external_comments,
                    feedback_internal_comment: payload.internal_comments,
                },
            });
        }
        default:
            return state;
    }
};

const interactionsByContactAndProperty: any = createReducer(
    {},
    {
        [SET_CONTACT_INTERACTIONS_FOR_PROPERTY]: (state, action) => {
            return state.updateIn(
                [getKey(action), 'interactions'],
                OrderedSet(),
                concatSetWith(action.interactions.map(i => i.id)),
            );
        },
    },
);

export default combineReducers({
    byId: interactionsById,
    byContactAndProperty: interactionsByContactAndProperty,
});
