import { useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { fromJS } from 'immutable';
import {
    GetOfficeNegotiatorsForSelectQuery,
    GetOfficeNegotiatorsForSelectQueryVariables,
} from '@/graphql/generated/types';
import { GET_OFFICE_NEGOTIATORS_FOR_SELECT_QUERY } from '@/graphql/queries/office/getOfficeNegotiatorsForSelect';
import { formatNegotiatorsList } from '@/app.utils/services/Helpers/negotiators';
import useUser from './useUser';

interface Params {
    page: number;
    officeId: string;
    query: string;
    additionalNegotiatorId?: Maybe<string>;
    additionalIds?: Maybe<string[]>;
}

function normalize(data: GetOfficeNegotiatorsForSelectQuery): GetOfficeNegotiatorsForSelectQuery['user'][] {
    const negotiators = [...(data.office?.negotiators ?? [])];

    const negotiatiorsByIds = [...(data.office?.negotiatiorsByIds ?? [])];

    const filteredNegotiators = [
        ...negotiatiorsByIds,
        ...negotiators.filter(negotiator => !negotiatiorsByIds.some(({ id }) => id === negotiator.id)),
    ];

    const additionalNegotiator = data.user as Maybe<typeof data.user>;
    const negotiatorsResult =
        additionalNegotiator && !filteredNegotiators.some(n => n.id === additionalNegotiator.id)
            ? [additionalNegotiator, ...filteredNegotiators]
            : filteredNegotiators;

    return negotiatorsResult;
}

export function useOfficeNegotiatorsForSelect({
    page,
    officeId,
    additionalNegotiatorId,
    query,
    additionalIds,
}: Params) {
    const user = useUser();
    const intl = useIntl();
    const variables: GetOfficeNegotiatorsForSelectQueryVariables = {
        page,
        officeId,
        additionalNegotiatorId: additionalNegotiatorId ?? '',
        includeAdditionalNegotiator: !!additionalNegotiatorId,
        query,
        includeIds: !!additionalIds?.length,
        additionalIds: additionalIds ?? [],
    };

    const { data: rawData, ...rest } = useQuery<
        GetOfficeNegotiatorsForSelectQuery,
        GetOfficeNegotiatorsForSelectQueryVariables
    >(GET_OFFICE_NEGOTIATORS_FOR_SELECT_QUERY, {
        variables,
        skip: !officeId,
    });

    const data = useMemo(() => (rawData ? normalize(rawData) : rawData), [rawData]);

    const selectOptions = useMemo(
        () => formatNegotiatorsList({ negotiators: data ? fromJS(data) : fromJS([]), intl, user }).toJS(),
        [data, intl, user],
    );

    return { data, selectOptions, ...rest };
}
