import React from 'react';
import classNames from 'classnames';
import Button from '@sweepbright/uikit/build/esm/button';
import Modal from '@/app.components/elements/Modal';
import Icon from '@/app.components/icons/Icon';
import InteractionFeedbackModal from '@/app.components/modals/InteractionFeedbackModal';
import useEffectPostMount from '@/app.hooks/useEffectPostMount';
import './ActivityAction.scss';
import useContact from '@/app.hooks/useContact';
import VendorInteractionFeedbackModal from '@/app.components/modals/VendorInteractionFeedbackModal';
import { FormattedMessage } from 'react-intl-sweepbright';

export function FeedbackAction({
    variant = 'link',
    onClick,
    onAddedFeedback,
    interactionId,
    onActiveStatusChange,
    className,
    propertyId,
    contactId,
}: {
    className?: string;
    variant?: 'primary' | 'link';
    interactionId: string;
    onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
    onAddedFeedback: () => void;
    // called when the user is interaction with the action
    onActiveStatusChange?: (active: boolean) => void;
    propertyId: Maybe<string>;
    contactId: Maybe<string>;
}) {
    const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);
    useEffectPostMount(() => {
        if (onActiveStatusChange) {
            // we consider the action active when the modal is been shown
            onActiveStatusChange(showFeedbackModal);
        }
    }, [showFeedbackModal]);

    const { contact, loading } = useContact(contactId);

    return (
        <>
            <Button
                variant={variant}
                onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                    setShowFeedbackModal(true);
                    // eslint-disable-next-line no-unused-expressions
                    onClick?.(evt);
                }}
                className={classNames(
                    'c-activity-action c-activity-action--feedback',
                    {
                        'c-activity-action--link': variant === 'link',
                    },
                    className,
                )}
                icon={<Icon icon="edit" />}
            >
                <FormattedMessage id="components.lead_actions.add_feedback" defaultMessage="Add Feedback" />
            </Button>
            <Modal
                show={showFeedbackModal && !loading}
                onHide={() => {
                    setShowFeedbackModal(false);
                }}
            >
                {contact?.type === 'VENDOR' ? (
                    <VendorInteractionFeedbackModal
                        interactionId={interactionId}
                        onAddedFeedback={onAddedFeedback}
                        close={() => setShowFeedbackModal(false)}
                    />
                ) : (
                    <InteractionFeedbackModal
                        interactionId={interactionId}
                        onAddedFeedback={onAddedFeedback}
                        close={() => setShowFeedbackModal(false)}
                        propertyId={propertyId ?? undefined}
                    />
                )}
            </Modal>
        </>
    );
}
