import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@sweepbright/uikit/build/esm/button';
import Modal from '@/app.components/elements/Modal';
import NegotiatorPicker from '@/shared/negotiator/NegotiatorPicker';

const PropertyListTableActionModal = props => {
    const [selectedId, setSelectedId] = useState<string | null>(null);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();

                props.onSubmit(selectedId);
            }}
        >
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="general.assign" defaultMessage="Assign to" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <NegotiatorPicker
                    onSelect={contact => {
                        setSelectedId(contact.value);
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.close} disabled={props.loading}>
                    <FormattedMessage id="forms.add-contact.cancel" defaultMessage="Cancel" />
                </Button>
                <Button type="submit" variant="primary" disabled={!selectedId || props.loading}>
                    <FormattedMessage id="forms.add-contact.cta" defaultMessage="Add" />
                </Button>
            </Modal.Footer>
        </form>
    );
};

export default PropertyListTableActionModal;
