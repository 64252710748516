import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import Button from '@/app.components/elements/Buttons/Button';

const Stepper = ({ max, min, onIncrement, onDecrement, testId, ...inputProps }) => {
    const { value, onChange, name } = inputProps;

    return (
        <div className="bc-numerical-input" data-testid={testId}>
            <Button className="bc-numerical-input-modifier" onClick={onDecrement} disabled={value <= min}>
                -
            </Button>
            <input
                min={min}
                max={max}
                type="text"
                inputMode="numeric"
                className="bc-numerical-input--input"
                value={value}
                onChange={evt => {
                    const numericVal = parseInt(evt.target.value);
                    onChange(isNaN(numericVal) ? '' : numericVal);
                }}
                name={name}
                pattern="[0-9]*"
            />
            <Button className="bc-numerical-input-modifier" onClick={onIncrement}>
                +
            </Button>
        </div>
    );
};

Stepper.propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onDecrement: PropTypes.func.isRequired,
    onIncrement: PropTypes.func.isRequired,
    value: PropTypes.number,
};

export default compose(
    withHandlers({
        onIncrement: props => () => {
            let value = parseInt(props.value, 10) + 1;

            if (typeof props.max !== 'undefined') {
                value = Math.min(props.max, value);
            }

            props.onChange(value);
        },
        onDecrement: props => () => {
            let value = parseInt(props.value, 10) - 1;

            if (typeof props.min !== 'undefined') {
                value = Math.max(props.min, value);
            }

            props.onChange(value);
        },
    }),
)(Stepper);
