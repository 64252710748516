// @ts-nocheck
import { CrudRequest } from '@sweepbright/margaret-fetcher';
import logger from '@/app.utils/services/logger';

export default class CacheableCrudRequest extends CrudRequest {
    // trace the request duration
    private async trace<TResult>(label: string, fn: () => Promise<TResult>): Promise<TResult> {
        if (process && process.env && process.env.NODE_ENV === 'development') {
            const startTime = Date.now();
            try {
                return await fn();
            } finally {
                const duration = Date.now() - startTime;
                logger.info(`${label} (${duration}ms)`);
            }
        } else {
            return fn();
        }
    }

    protected async willSendRequest(path: string) {
        // log the request
        logger.info({ path, ...this.options, headers: formatHeaders(this.options.headers!) });
    }
}

function formatHeaders(headers: Headers | Record<string, string> | string[][]): Record<string, string> {
    const needsFormatting = headers instanceof Headers;
    let headersObject: Record<string, string> = {};
    if (headers && needsFormatting) {
        (headers as Headers).forEach((value, header) => {
            headersObject[header] = value;
        });
    } else if (headers) {
        // no formatting
        headersObject = headers as Record<string, string>;
    }

    return headersObject;
}
