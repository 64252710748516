import React, { FC, useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { InjectedRouter, Link, withRouter } from 'react-router';
import { Params } from 'react-router/lib/Router';
import logo from '@/../../resources/assets/img/logo-navy.svg';
import Button from '@/app.components/elements/Buttons/Button';
import OAuthRequests from '@/requests/OAuthRequests';
import { FormattedMessage } from 'react-intl-sweepbright';
import { PROPERTIES } from '@/app.routing';
import EmptyAlert from '@/app.components/empty/EmptyAlert';
import { Icon } from '@/app.components';
import useUser from '@/app.hooks/useUser';
import { isCompanyAdmin } from '@/app.data';

interface AuthorizeProps
    extends InjectedRouter<
        Params,
        {
            client_id?: string;
            redirect_uri?: string;
            state?: string;
            response_type?: string;
            scope?: string;
        }
    > {}

export const Authorize: FC<AuthorizeProps> = ({ location, replace }) => {
    const [client, setClient] = useState<{ name?: string }>({});
    const [error, setError] = useState(false);

    const user = useUser();

    const handleAuthorize = useCallback(() => {
        const { query } = location;

        const params = {
            client_id: query.client_id,
            redirect_uri: query.redirect_uri,
            state: query.state,
            response_type: query.response_type,
            scope: query.scope,
        };

        new OAuthRequests()
            .authorize(params)
            .then(response => {
                window.location.replace(response.redirect_uri);

                return response;
            })
            .catch(null);
    }, [location]);

    const loadClient = useCallback(async (clientId: string) => {
        const authRequests = new OAuthRequests();
        try {
            const client = await authRequests.getClient(clientId);
            setClient(client);
        } catch (err) {
            setError(true);
        }
    }, []);

    useEffect(
        () => {
            // will redirect to properties page is no client
            const clientId = location.query?.client_id;
            if (clientId) {
                loadClient(clientId);
            } else {
                // eslint-disable-next-line no-unused-expressions
                replace?.(PROPERTIES);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const isAdmin = isCompanyAdmin(user);

    if (error) {
        return <ErrorMessage />;
    }

    if (!client.name) {
        return null;
    }

    if (!isAdmin && client.name === 'Zapier') {
        return (
            <div className="flex flex-col items-center">
                <div className="mb-8">
                    <img src={(logo as unknown) as string} alt="SweepBright" style={{ width: '240px' }} />
                </div>
                <div className="mb-8 text-center alert alert-info">
                    <Icon icon="information" className="mr-1" />
                    <FormattedMessage id="forms.oauth.authorize.zapier.error" />
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet titleTemplate="%s - SweepBright">
                <title>{`Authorize ${client.name}`}</title>
            </Helmet>
            <div className="flex flex-col items-center">
                <div className="mb-8">
                    <img src={(logo as unknown) as string} alt="SweepBright" style={{ width: '240px' }} />
                </div>
                <div className="mb-8 text-center alert alert-info">
                    <Icon icon="information" className="mr-1" />
                    <FormattedMessage
                        id="forms.oauth.authorize.body"
                        defaultMessage="{name} would like permission to access your account."
                        values={{ name: client.name }}
                    />
                </div>
                <div className="pr-4">
                    <Button type="submit" variant="primary" className="btn-lg" onClick={handleAuthorize}>
                        <FormattedMessage id="forms.oauth.authorize.cta" defaultMessage="Authorize" />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default withRouter(Authorize);

const ErrorMessage = () => {
    return (
        <EmptyAlert
            body={
                <FormattedMessage
                    id="pages.oauth.error_message_no_client.body"
                    tagName={React.Fragment}
                    defaultMessage="Oops, looks like we messed up. Please check more {link}. {break} If you landed here by mistake please go {home}."
                    values={{
                        link: (
                            <a href="http://get.sweepbright.help/" rel="noreferrer nofollow">
                                <FormattedMessage id="pages.oauth.error_message_no_client.link" defaultMessage="here" />
                            </a>
                        ),
                        break: <br />,
                        home: (
                            <Link to={PROPERTIES}>
                                <FormattedMessage id="pages.oauth.error_message_no_client.home" defaultMessage="home" />
                            </Link>
                        ),
                    }}
                />
            }
        />
    );
};
