// @ts-nocheck
import React from 'react';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import { Input } from '@/app.components';
import PortalConfigModalBody from '@/app.components/modals/Channels/PortalConfigModalBody';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import { validateWithRules } from '@/app.utils/services/Validator';
import NumberedStep from '@/app.components/modals/Channels/elements/NumberedStep';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import Formik from '@/app.components/forms/helpers/Formik';
import useCompany from '@/app.hooks/useCompany';
import { nameValidationRule } from './utils';

type Props = {
    editing: boolean;
    initialValues: { name: string; credentials?: { accessToken?: string } };
    onSubmit: (evt: FixMeType) => void;
    onSubmitSuccess: () => void;
    showNameField?: boolean;
};

const validation = validateWithRules(
    {
        name: nameValidationRule,
        credentials: {
            type: 'object',
            required: true,
            properties: {
                accessToken: {
                    type: 'string',
                    required: true,
                    allowEmpty: false,
                    messages: {
                        required: 'general.validation.field_required',
                        allowEmpty: 'general.validation.field_required',
                    },
                },
            },
        },
    },
    { humanizeErrorMessage: false },
);

const RealoConfigModalImpl: React.FunctionComponent<Props> = ({
    editing,
    onSubmit,
    initialValues,
    onSubmitSuccess,
    ...props
}) => {
    const companyEmail = useCompany().getIn(['settings', 'data', 'email']);

    return (
        <Formik
            initialValues={{
                ...initialValues,
                credentials: {
                    accessToken: companyEmail,
                    ...(initialValues?.credentials ?? ''),
                },
            }}
            onSubmit={async values => {
                await onSubmit(values);
                await onSubmitSuccess?.();
            }}
            validate={validation}
        >
            {({ values, errors, touched, isSubmitting, handleChange, handleSubmit, handleBlur }) => (
                <ErrorBoundary>
                    <PortalConfigModalBody
                        editing={editing}
                        onSubmit={handleSubmit}
                        submitting={isSubmitting}
                        {...props}
                    >
                        <NumberedStep
                            step={1}
                            title={
                                <FormattedMessage
                                    id="channels.realo_create_account.title"
                                    defaultMessage="Create an account"
                                />
                            }
                        >
                            <p className="text-muted m-0 py-2 leading-none">
                                <FormattedMessage
                                    id="channels.realo_create_account.description"
                                    defaultMessage="If you already have an account, we will match it based on the primary e-mail address. If we don't
             find a matching e-mail address, we will create an account."
                                />
                            </p>
                        </NumberedStep>
                        <NumberedStep
                            step={2}
                            title={
                                <FormattedMessage
                                    id="channels.details_step.title"
                                    defaultMessage="Fill in your details"
                                />
                            }
                            last
                        >
                            <Input
                                name="credentials.accessToken"
                                onChange={handleChange}
                                value={values.credentials?.accessToken ?? ''}
                                label={<FormattedMessage id="forms.labels.email" defaultMessage="E-mail" />}
                                placeholder={
                                    <FormattedMessage id="channels.email.placeholder" defaultMessage="Account e-mail" />
                                }
                                error={errors.credentials?.accessToken}
                                touched={touched.credentials?.accessToken}
                                onBlur={handleBlur}
                                disabled={editing}
                            />
                            {props.showNameField && (
                                <Input
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                                    help={
                                        <FormattedMessage
                                            id="channels.name.description"
                                            defaultMessage="This is the name you will see in the Publish section of SweepBright."
                                        />
                                    }
                                    placeholder="Realo"
                                    error={errors.name}
                                    touched={touched.name}
                                    onBlur={handleBlur}
                                />
                            )}
                        </NumberedStep>
                    </PortalConfigModalBody>
                </ErrorBoundary>
            )}
        </Formik>
    );
};

const RealoConfigModal = connect(
    createStructuredSelector({
        // if the multiple accounts feature is on,
        // we show the name field, otherwise, it is invisible to the user
        // even though the name is still set
        showNameField: getFeature('portals.multipleAccounts.enabled'),
    }),
)(RealoConfigModalImpl);

export default RealoConfigModal;

RealoConfigModal.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
            credentials {
                accessToken
            }
        }
    `,
};
