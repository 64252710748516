import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import SideMenuLink from '../../app.components/navigations/SideMenu/SideMenuLink';
import SideMenu from '../../app.components/navigations/SideMenu/SideMenu';
import * as routes from '../../app.routing/routes';

const Company: React.FunctionComponent = props => {
    const { children } = props;

    const showLabels = useFeatureFlag('contact.labels.enabled');

    return (
        <LayoutContainer flex>
            <LayoutColumn size="small">
                <SideMenu color="gray-lighter">
                    <SideMenuLink
                        fuzzyMatch
                        label={
                            <FormattedMessage
                                id="navigation.aside.company-settings"
                                defaultMessage="Company Settings"
                            />
                        }
                        to={routes.COMPANY_SETTINGS}
                        icon="edit"
                    />
                    {showLabels && (
                        <SideMenuLink
                            fuzzyMatch
                            icon="edit"
                            to={routes.COMPANY_LABELS}
                            label={<FormattedMessage id="contact.labels" />}
                        />
                    )}
                </SideMenu>
            </LayoutColumn>
            <LayoutColumn>
                <ErrorBoundary>{children}</ErrorBoundary>
            </LayoutColumn>
        </LayoutContainer>
    );
};

export default Company;
