import { Map, List } from 'immutable';
import pickBy from 'lodash/pickBy';
import { PropertyType, ALLOWED_PRICE_RANGE } from '@/app.data/Properties';
import { SearchDomain } from '@/app.redux/selectors/SearchSelectors';
import { PriceAggregations } from '@/requests/types';

type TypeMap = { [key in PropertyType]: boolean };

type PropertyParams = {
    [key: string]: any;
    ownership: 'mine' | 'with_negotiator' | 'all';
    status: '';
    search_scope: '';
    type: TypeMap;
    query: string;
    negotiation: 'all' | 'sale' | 'rent';
    max_price: number | '';
    min_price: number | '';
    sale_max_price: number | '';
    sale_min_price: number | '';
    let_max_price: number | '';
    let_min_price: number | '';
    filterByStatus: boolean;
    property_type: 'property_project';
    sort_field: string;
    sort_order: 'asc' | 'desc';
    archived: boolean;
};

type Options = {
    ignoredProperties: Map<SearchDomain, List<string>>;
    normalizeBy: SearchDomain;
    settings: Map<string, any>;
    user?: Map<string, any>;
    meta: {
        priceRanges: PriceAggregations;
    };
};

export function propertyParametersMapper({ settings, user, ignoredProperties, normalizeBy, meta }: Options) {
    function mapper(parameters: Map<string, any>, value: any, key: string): Map<string, any> {
        if (ignoredProperties.get(normalizeBy).includes(key)) {
            return parameters;
        }

        const negotiation: 'let' | 'sale' | 'all' = settings.get('negotiation', 'all');

        // we store independently the price range filter per negotiation
        // but the API support just 'min_price' and 'max_price'
        // so we need to get the value for the current negotiation filter and
        // set it there
        if (key === 'min_price' || key === 'max_price') {
            // get the stored value of sale_min/max_price, let_min/max_price, or min/max_price depending on the negotiation
            const priceValue = settings.get(negotiation === 'all' ? key : `${negotiation}_${key}`);
            if (isOutsideRange(negotiation, parseFloat(priceValue))) {
                return parameters;
            } else {
                return parameters.set(key, priceValue);
            }
        }

        if (key === 'ownership') {
            const ownership = (value as PropertyParams[typeof key]) ?? 'all';
            switch (true) {
                case ownership === 'mine': {
                    if (user?.get('id') != null) {
                        return parameters.set('negotiator', user.get('id')).set('ownership', 'mine');
                    }

                    // this is a fallback
                    return parameters.set('ownership', 'all');
                }
                case ownership === 'with_negotiator' && settings.has('negotiator'): {
                    return parameters.set('negotiator', settings.get('negotiator')).set('ownership', 'with_negotiator');
                }
                case ownership === 'with_negotiator' && !settings.has('negotiator'):
                    return parameters.set('ownership', 'all');

                case ownership === 'all':
                    return parameters.set('ownership', 'all');
            }
        }

        // if we have a ownership key, it will take care of
        // setting the negotiator
        if (key === 'negotiator' && settings.get('ownership')) {
            return parameters;
        }

        if (key === 'type') {
            const { type: filterType }: { type: string | TypeMap } = settings.toJS();

            if (Array.isArray(filterType)) {
                return parameters.set('type', filterType);
            }

            const types = typeof filterType === 'string' ? [filterType] : Object.keys(pickBy(filterType, Boolean));

            return parameters.set('type', types);
        }

        if (key === 'status') {
            let statuses: string | string[] | Record<string, boolean> = settings.toJS()['status'] || [];
            if (typeof statuses === 'string') {
                // handle v1 here, where status is only one possible option
                statuses = [statuses];
            } else if (typeof statuses === 'object' && !Array.isArray(statuses)) {
                statuses = Object.keys(pickBy(statuses, Boolean));
            }

            return parameters.set('status', statuses);
        }

        // the search_scope parameter can be "all" || "matches"
        // so we need to handle this case first
        if (value != null && key === 'search_scope') {
            return parameters.set(key, value);
        }

        // if the value of the parameter is 'all'
        // we don't pass it because this is the default
        if (value !== null && value !== 'all' && value !== '') {
            return parameters.set(key, value);
        }

        return parameters;
    }

    return mapper;

    function isOutsideRange(negotiation: 'let' | 'sale' | 'all', value: number) {
        const range = meta.priceRanges[negotiation];
        if (value <= range.min_price) {
            return true;
        }
        if (value >= range.max_price) {
            return true;
        }

        return false;
    }
}

export const contactsParametersMapper = ({
    settings,
    ignoredProperties,
    normalizeBy,
}: {
    ignoredProperties: Map<string, List<string>>;
    normalizeBy: string;
    settings: Map<string, any>;
}) => (parameters: Map<string, any>, value: any, key: string) => {
    if (ignoredProperties.get(normalizeBy).includes(key)) {
        return parameters;
    }

    if (
        (key === 'min_price' && Number(value) === ALLOWED_PRICE_RANGE.min_price) ||
        (key === 'max_price' && Number(value) === ALLOWED_PRICE_RANGE.max_price)
    ) {
        // if the min_price or max_price is the default,
        // we dont include it, otherwise
        // we wont get properties with no price
        // as a result from the API
        return parameters;
    }

    if (key === 'interest') {
        if (settings.get('filterByInterest')) {
            const interestSettings = settings.get('interest');
            // the interest list could be stored in two ways
            // 1) as an array with the list of interests, ex ['bid', 'visit', ...]
            // 2) as { bid: true, visit: true, no_interest: false}

            /// below we check for the (1)
            if (interestSettings instanceof List) {
                return parameters.set('interest', (interestSettings as List<string>).toJS());
            }

            /// below we check for the (2)
            return parameters.set(
                'interest',
                interestSettings
                    .filter(type => type)
                    .keySeq()
                    .toJS(),
            );
        } else {
            return parameters;
        }
    }

    if (value !== null && value !== 'all' && value !== '') {
        return parameters.set(key, value);
    }

    return parameters;
};
