import CheckBox from '@sweepbright/uikit/build/esm/checkbox';
import React, { useMemo } from 'react';
import { InjectedIntl } from 'react-intl';
import { Icon, PropertyConditionSelector } from '@/app.components';
import { getAmenityIcon } from '@/app.components/forms/Amenity';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import Input from '@/app.components/forms/Input/Input';
import { amenitiesMessages, getVisibleAmenities } from '@/app.data/Amenities';
import { LAND_CONDITIONS, PROPERTY_CONDITIONS } from '@/app.data/Conditions';
import { PropertyFieldVisibilityContext, PropertyInternalType } from '@/app.data/FieldVisibilityContext';
import { FormattedMessage, injectIntl } from 'react-intl-sweepbright';

export default function AmenitiesTabPanel({ values, handleChange, setFieldValue }) {
    const { type, negotiation } = values;
    const visibleAmenities = useMemo(() => {
        if (!type || !negotiation) {
            return [];
        }
        const ctx: PropertyFieldVisibilityContext = {
            internalType: PropertyInternalType.Standalone,
            type,
            negotiation,
            country: 'default',
        };

        return getVisibleAmenities(ctx);
    }, [negotiation, type]);

    return (
        <>
            <ConditionSection
                type="house"
                value={values.condition}
                onChange={evt => setFieldValue('condition', evt.target.value)}
            />
            {['house', 'apartment'].includes(type) && (
                <FormPanel title={<FormattedMessage id="rooms.room" defaultMessage="Rooms" />}>
                    <Input
                        type="stepper"
                        name="minBedrooms"
                        value={values.minBedrooms}
                        onChange={value => setFieldValue('minBedrooms', parseInt(value) || 0)}
                        label={
                            <FormattedMessage
                                id="pages.contacts.forms.labels.min_rooms"
                                defaultMessage="Min. Bedrooms"
                            />
                        }
                        horizontal
                        min={0}
                    />
                </FormPanel>
            )}
            <AmenitiesSection
                // @ts-ignore
                type={values.type}
                value={values.amenities}
                visibleAmenities={visibleAmenities}
                onChange={handleChange}
            />
        </>
    );
}

const AmenitiesSection = injectIntl(function AmenitiesSection({
    value,
    visibleAmenities,
    onChange,
    intl,
}: {
    visibleAmenities: string[];
    value: string[];
    onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    intl: InjectedIntl;
}) {
    return (
        <FormPanel title={<FormattedMessage id="property.details.amenities.title" defaultMessage="Amenities" />}>
            <ul className="bc-bordered-list">
                {visibleAmenities.map(amenity => {
                    return (
                        <li key={amenity}>
                            <CheckBox
                                name="amenities"
                                value={amenity}
                                checked={value.includes(amenity)}
                                onChange={onChange}
                            >
                                <span className="flex items-center">
                                    <Icon icon={getAmenityIcon(amenity)} className="mr-2" />
                                    {intl.formatMessage(amenitiesMessages[amenity])}
                                </span>
                            </CheckBox>
                        </li>
                    );
                })}
            </ul>
        </FormPanel>
    );
});

function ConditionSection({ type, value, onChange }) {
    const conditionsType = type === 'land' ? LAND_CONDITIONS : PROPERTY_CONDITIONS;
    const conditionField = { value, onChange };

    return (
        <FormPanel title={<FormattedMessage id="preferences.min_condition" defaultMessage="Minimum Condition" />}>
            <PropertyConditionSelector
                icon="house"
                // @ts-ignore
                field={conditionField}
                type={conditionsType}
            />
        </FormPanel>
    );
}
