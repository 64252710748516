import React from 'react';
import gql from 'graphql-tag';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { useToasts } from '@sweepbright/notifications';
import withModals from '@/app.utils/Decorators/withModals';
import PropertyListTableActionModal from './PropertyListTableActionModal';

const ASSIGN_ESTATES_MUTATION = gql`
    mutation AssignEstates($input: AssignEstatesToNegotiatorInput!) {
        assignEstatesToNegotiator(input: $input) {
            success
        }
    }
`;

type Props = {
    modal: any;
    open: Function;
    close: Function;
    refetch: Function;
    selected: string[];
    setSelected: Function;
};

const PropertyListTableAction: React.FC<Props> = props => {
    const { refetch, selected, setSelected } = props;

    const intl = useIntl();

    const toasts = useToasts();

    const { addSuccess, addError } = toasts;

    const [assign, { loading }] = useMutation(ASSIGN_ESTATES_MUTATION);

    const content = {
        action: <FormattedMessage id="general.assign" defaultMessage="Assign to" />,
    };

    return (
        <div className="properties-list-table-action">
            {props.modal(
                'select',
                <PropertyListTableActionModal
                    {...props}
                    loading={loading}
                    onSubmit={selectedId => {
                        assign({
                            variables: {
                                input: { userId: selectedId, estateIds: selected },
                            },
                        })
                            .then(() => {
                                setSelected([]);
                                refetch();

                                addSuccess({
                                    title: intl.formatMessage({ id: 'general.update.started' }),
                                    message: intl.formatMessage({ id: 'general.update.started.message' }),
                                });
                            })
                            .catch(err => {
                                const isForbidden = err?.message?.includes('403');

                                addError({
                                    message: isForbidden
                                        ? intl.formatMessage({ id: 'unauthorised_403' })
                                        : intl.formatMessage({ id: 'general.something_went_wrong' }),
                                });
                            })
                            .finally(() => {
                                props.close();
                            });
                    }}
                />,
            )}

            <button
                type="button"
                onClick={() => {
                    props.open('select');
                }}
                style={{ fontSize: '12px' }}
                className="c-chip c-chip--small c-chip--default"
            >
                <span className="mx-1 truncate flex items-center">{content.action}</span>
            </button>
        </div>
    );
};

export default withModals(PropertyListTableAction);
