import { List } from 'immutable';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useCompany from '@/app.hooks/useCompany';
import { getLanguages, LangDefinition } from '@/app.data/Localization';

export default function useCompanyLanguages(): LangDefinition[] {
    const company = useCompany();
    const intl = useIntl();
    const languages = useMemo(() => getLanguages(intl), [intl]);

    const companyLanguages = useMemo(() => {
        const enabledLocales = (company && company.getIn(['settings', 'data', 'languages'])) || List();
        const companyLanguages = enabledLocales.size
            ? languages.filter(language => enabledLocales.includes(language.value))
            : languages;

        return companyLanguages;
    }, [company, languages]);

    return companyLanguages;
}
