const defaultSettings = 'scrollbars=no,toolbar=no,location=no,titlebar=no,directories=no,status=no,menubar=no';

export function getPopupSize(channel) {
    switch (channel) {
        case 'facebook':
            return { width: 640, height: 600 };
        case 'cronofy':
            return { width: 640, height: 740 };
        default:
            return { width: 1020, height: 618 };
    }
}

export function getPopupOffset({ width, height }) {
    const wLeft = window.screenLeft ? window.screenLeft : window.screenX;
    const wTop = window.screenTop ? window.screenTop : window.screenY;

    const left = wLeft + window.innerWidth / 2 - width / 2;
    const top = wTop + window.innerHeight / 2 - height / 2;

    return { top, left };
}

export function getPopupSettings(channel) {
    const { width, height } = getPopupSize(channel);
    const { top, left } = getPopupOffset({ width, height });

    return `${defaultSettings},width=${width},height=${height},top=${top},left=${left}`;
}
