import { useQuery } from '@apollo/react-hooks';
import { GetContactReferenceQuery, GetContactReferenceQueryVariables } from '@/graphql/generated/types';
import { GET_CONTACT_REFERENCE } from '@/graphql/queries/contacts/getContactReference';

export const useContactReference = (id?: string) => {
    const { data } = useQuery<GetContactReferenceQuery, GetContactReferenceQueryVariables>(GET_CONTACT_REFERENCE, {
        skip: !id,
        variables: { id: id || '' },
    });

    return { data };
};
