import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from '@/app.utils/services/Helpers/reactIntlWrapper';

export function UpgradeHeader() {
    return (
        <React.Fragment>
            <h1 className="text-center">
                <FormattedMessage id="subscription.upgrade.title" defaultMessage="Upgrade your subscription" />
            </h1>
            <p className="text-center text-muted">
                <FormattedHTMLMessage
                    id="subscription.upgrade.lead"
                    defaultMessage={'. . . and join a tribe of smart real estate agents &amp; brokers!'}
                />
            </p>
        </React.Fragment>
    );
}
