// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import styled from '@emotion/styled';

// lets wrap the component and add some
// padding to make the target area bigger
const ChevronContainer = ({ className, ...props }: { className?: string }) => (
    <div className={classNames('p-4 inline-flex absolute -mt-1', className)} {...props} />
);

const ChevronUp = styled('span')`
    width: 0;
    height: 0;
    display: inline-block;
    border-width: 0 4px 4px;
    border-style: solid;
    border-color: #404040 transparent;
    visibility: ${props => (props.active ? 'visible' : 'hidden')};
    opacity: 0.4;
    user-select: none;
    th:hover & {
        visibility: visible;
        opacity: 1;
    }
`;

const ChevronDown = styled(ChevronUp)`
    border-bottom: 0;
    border-width: 4px 4px 0;
`;

const TableSortLabel = ({ active, direction, onClick }) => {
    const Chevron = active && direction === 'desc' ? ChevronUp : ChevronDown;

    return (
        <ChevronContainer onClick={onClick}>
            <Chevron active={active} />
        </ChevronContainer>
    );
};

export default TableSortLabel;
