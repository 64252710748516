import { FC, useEffect } from 'react';
import { WithRouterProps } from 'react-router';
import { PROPERTY_DETAILS_DOCUMENTS_PLANS_TAB, PROPERTY_UNIT_DETAILS_DOCUMENTS_PLANS_TAB } from '@/app.routing';

function getRedirectUrl(property: string, unit?: string): string {
    return unit
        ? PROPERTY_UNIT_DETAILS_DOCUMENTS_PLANS_TAB(property, unit)
        : PROPERTY_DETAILS_DOCUMENTS_PLANS_TAB(property);
}

export const PlansRedirect: FC<WithRouterProps<{ property: string; unit?: string }, {}>> = ({
    params: { property, unit },
    router: { replace },
}) => {
    useEffect(() => {
        replace(getRedirectUrl(property, unit));
    });

    return null;
};
