import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col, HelpBlock } from 'react-bootstrap';
import paymentMethods from '@/../../resources/assets/img/payment-methods.svg';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '@/app.components/icons/Icon';
import Input from '@/app.components/forms/Input/Input';

function expiryFormat(value) {
    if (value && Number(value[0]) > 1) {
        value = `0${value}`;
    }

    if (value && value.length > 1 && Number(value[0] + value[1]) > 12) {
        value = `12${value.substring(2, value.length)}`;
    }

    return `${value.substring(0, 2)}/${value.substring(2, 4)}`;
}

export default class CardForm extends Component {
    static propTypes = {
        compact: PropTypes.bool,
        field: PropTypes.object.isRequired,
    };

    renderDisclaimer() {
        const { compact } = this.props;

        if (compact) {
            return (
                <div>
                    <p className="text-center text-success">
                        <Icon icon="lock-locked" />{' '}
                        <FormattedMessage id="card.help" defaultMessage="Secure credit card payment" tagName="strong" />
                    </p>
                    <p className="text-center">
                        <img src={paymentMethods} height={24} alt="" />
                    </p>
                    <hr />
                    <HelpBlock>
                        <FormattedMessage
                            id="card.disclaimer"
                            defaultMessage="We store your credit card details securely on Stripe."
                        />
                    </HelpBlock>
                </div>
            );
        }

        return (
            <div className="flex items-center o-flex--justify-between">
                <p>
                    <span className="text-success">
                        <Icon icon="lock-locked" />{' '}
                        <FormattedMessage id="card.help" defaultMessage="Secure credit card payment" tagName="strong" />
                    </span>
                    <HelpBlock>
                        <FormattedMessage
                            id="card.disclaimer"
                            defaultMessage="We store your credit card details securely on Stripe."
                        />
                    </HelpBlock>
                </p>
                <p>
                    <img src={paymentMethods} height={24} alt="" />
                </p>
            </div>
        );
    }

    render() {
        const { field } = this.props;

        return (
            <div>
                <Row>
                    <Col sm={15}>
                        <Input
                            mask={{ mask: '-', format: '#### #### #### ####' }}
                            type="tel"
                            srOnly
                            placeholder={
                                <FormattedMessage id="forms.labels.card_number" defaultMessage="Card number" />
                            }
                            {...field.number}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <Input
                            mask={{ format: expiryFormat }}
                            type="tel"
                            srOnly
                            label={<FormattedMessage id="forms.labels.expires" defaultMessage="Expires" />}
                            placeholder="MM / YY"
                            {...field.expiry}
                        />
                    </Col>
                    <Col sm={3}>
                        <Input
                            mask={{ format: '###' }}
                            type="tel"
                            srOnly
                            placeholder={<FormattedMessage id="forms.labels.cvc" defaultMessage="CVC" />}
                            {...field.cvc}
                        />
                    </Col>
                </Row>
                {this.renderDisclaimer()}
            </div>
        );
    }
}
