import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage } from 'react-intl-sweepbright';
import { SUBSCRIPTION_UPGRADE } from '../../app.routing/routes';

const UpgradeWarning = ({ isOwner }) => (
    <div>
        <Alert bsStyle={null}>
            <FormattedMessage
                id="upgrade_warning.limit_reached"
                defaultMessage="You have reached the limit of your subscription."
                tagName="p"
            />
            <p>
                {isOwner ? (
                    <FormattedMessage
                        id="upgrade_warning.limit_reached_action_owner"
                        defaultMessage={"It's time to upgrade."}
                        tagName="strong"
                    />
                ) : (
                    <FormattedMessage
                        id="upgrade_warning.limit_reached_action"
                        defaultMessage="Contact a company owner to upgrade."
                        tagName="strong"
                    />
                )}
            </p>
        </Alert>
        {isOwner && (
            <p>
                <LinkContainer to={SUBSCRIPTION_UPGRADE}>
                    <Button variant="primary" block size="lg">
                        <FormattedMessage id="upgrade_warning.cta" defaultMessage="Upgrade Now" />
                    </Button>
                </LinkContainer>
            </p>
        )}
    </div>
);

UpgradeWarning.propTypes = {
    isOwner: PropTypes.bool,
};

export default UpgradeWarning;
