import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class VrView extends Component {
    static propTypes = {
        src: PropTypes.string.isRequired,
    };

    componentDidMount() {
        this.player = new VRView.Player('#vr-view', {
            image: this.props.src,
            width: '100%',
            height: '100%',
        });
    }

    render() {
        return <div id="vr-view" className="w-full h-full" />;
    }
}
