import React from 'react';
import { Button } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';

type Props = {
    visible: boolean;
    loading: boolean;
    onHide: () => void;
    onConfirm: () => void;
    onCancel: () => void;
};

const OfferCardConfirm: React.FC<Props> = props => {
    const { visible, loading, onHide, onConfirm, onCancel } = props;

    const content = {
        title: <FormattedMessage id="offer.card.confirm.title" defaultMessage="Congratulations! Update Commissions?" />,
        text: (
            <FormattedMessage
                id="offer.card.confirm.text"
                defaultMessage="The commissions of this offer are different from the Property Commissions. Would you like to update the Property Commissions now?"
            />
        ),
        cancel: <FormattedMessage id="offer.card.confirm.cancel" defaultMessage="No Thanks" />,
        updating: (
            <FormattedMessage tagName={React.Fragment} id="offer.card.confirm.updating" defaultMessage="Updating..." />
        ),
        update: (
            <FormattedMessage tagName={React.Fragment} id="offer.card.confirm.update" defaultMessage="Update Now" />
        ),
    };

    return (
        <Modal show={visible} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>{content.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{content.text}</Modal.Body>
            <Modal.Footer className="flex justify-end">
                <div className="space-x-1">
                    <Button disabled={loading} onClick={onCancel}>
                        {content.cancel}
                    </Button>
                    <Button variant="primary" onClick={onConfirm} disabled={loading}>
                        {loading ? content.updating : content.update}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default OfferCardConfirm;
