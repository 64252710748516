// @ts-nocheck
import React from 'react';
import { useTransition, animated } from 'react-spring';
import Button from '@sweepbright/uikit/build/esm/button';
import {
    NotificationState,
    NotificationMessage,
    NotificationUpdatedEvent,
    NotificationChange,
    NotificationStatus,
} from '../state';
import { NotificationContainer } from './elements';
import Portal from './Portal';
import { Toast, IColors, IButtonType } from './Toast';

export interface NotificationToast {
    id: string;
    createdAt: number;
    notification: NotificationMessage;
}

const convertMapToToasts = (notifications: Map<string, NotificationMessage>): NotificationToast[] =>
    Array.from(notifications.keys()).map(id => ({
        id,
        createdAt: Date.now(),
        notification: notifications.get(id),
    }));

const convertNotificationEventToToast = (notificationEvent: NotificationUpdatedEvent): NotificationToast => ({
    id: notificationEvent.id,
    createdAt: Date.now(),
    notification: notificationEvent.notification,
});

interface Props {
    state: NotificationState;
    Button?: IButtonType;
    colors?: IColors;
}

const DEFAULT_COLORS = {
    [NotificationStatus.ERROR]: '#ee4b41',
    [NotificationStatus.WARNING]: '#f9e659',
    [NotificationStatus.SUCCESS]: '#47c9ad',
    [NotificationStatus.NOTICE]: '#17a8e6',
};

const DEFAULT_BUTTON: IButtonType = Button;

export const Toasts = React.memo(function Toasts({ state, colors = DEFAULT_COLORS, Button = DEFAULT_BUTTON }: Props) {
    const [refMap] = React.useState(() => new WeakMap<NotificationToast, HTMLDivElement>());

    const [notificationsToShow, setNotificationsToShow] = React.useState(convertMapToToasts(state.getNotifications()));

    const removeNotification = React.useCallback((id: string) => {
        state.removeNotification(id);
    }, []);

    React.useEffect(() => {
        const addListener = state.onNotificationUpdated(event => {
            if (event.type === NotificationChange.ADD) {
                setNotificationsToShow(notifications => [...notifications, convertNotificationEventToToast(event)]);
            }
        });

        return () => {
            addListener();
        };
    }, [state]);

    React.useEffect(() => {
        const removeListener = state.onNotificationRemoved(event => {
            setNotificationsToShow(notifications => notifications.filter(n => n.id !== event.id));
        });

        return () => {
            removeListener();
        };
    }, [state]);

    const transitions = useTransition<NotificationToast, { overflow: string; opacity: number; height: number }>(
        notificationsToShow,
        n => n.id,
        {
            from: { overflow: 'hidden', opacity: 0, height: 0 },
            // @ts-ignore: not typed properly in react-spring
            enter: item => next =>
                next({
                    overflow: 'hidden',
                    opacity: 1,
                    height: refMap.get(item) ? refMap.get(item)!.offsetHeight + 16 : 0,
                }),
            leave: { overflow: 'hidden', opacity: 0, height: 0 },
        },
    );

    return (
        <Portal>
            <NotificationContainer>
                {transitions.map(({ item, props, key }) => (
                    <animated.div key={key} style={props}>
                        <Toast
                            colors={colors}
                            Button={Button}
                            getRef={ref => ref && refMap.set(item, ref)}
                            toast={item}
                            removeToast={(id: string) => {
                                removeNotification(id);
                            }}
                        />
                    </animated.div>
                ))}
            </NotificationContainer>
        </Portal>
    );
});
