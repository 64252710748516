import { List, Map, OrderedSet, fromJS } from 'immutable';
import { createSelector } from 'reselect';
import orderBy from 'lodash/orderBy';
import { getDefaultUnitForArea } from '@/app.data/Rooms';
import { getPropertiesComparator } from '@/app.utils/services/Helpers/sorting';
import { areas } from '../../app.data';
import sortByDateDesc from '../../app.data/Helpers/sortByDateDesc';
import DatetimeSanitizer from '../../app.utils/services/Sanitizers/DatetimeSanitizer';
import NumberFormatSanitizer from '../../app.utils/services/Sanitizers/NumberFormatSanitizer';
import { toTitle } from '../../app.utils/services/String';
import { findById } from './CommonSelectors';
import { getOfficeDefaultEmailForFilesRequest, getOfficeSetting } from './OfficesSelector';
import { getCurrentContact, getCurrentProject, getCurrentProperty } from './RoutingSelectors';
import { getSearchSettings } from './SearchSelectors';
import { getIsSaving } from './StatusSelectors';
import { getUser } from './UsersSelectors';
import { checkSubTypes } from './Helpers/subTypes';

export const getEnabledLocales = createSelector(getUser, user => {
    return user ? user.getIn(['company', 'data', 'settings', 'data', 'languages']).toJS() : ['en'];
});

// for internal use only
const _getProperties = state => state.properties;

export const getProperties = createSelector(_getProperties, properties => {
    return fromJS(properties.allIds.map(propertyId => properties.byId[propertyId]));
});

export const getSearchedProperties = createSelector(
    getSearchSettings('properties'),
    _getProperties,
    (filters, properties) => {
        // TODO: use search settings here
        const list = properties.allIds.reduce((acc, id) => {
            if (!isFiltered(filters, properties.byId[id])) {
                acc.push(properties.byId[id]);
            }

            return acc;
        }, []);

        return fromJS(orderBy(list, getPropertiesComparator(filters), filters.sort_order));
    },
);

export const getPropertyById = id =>
    createSelector(_getProperties, properties => {
        return properties.byId[id] ? fromJS(properties.byId[id]) : Map();
    });

const _getProperty = createSelector(_getProperties, getCurrentProperty, (properties, propertyId) => {
    return properties.byId[propertyId];
});

const _getFirstProperty = createSelector(_getProperties, properties => {
    if (properties.allIds.length > 0) {
        return properties.byId[properties.allIds[0]];
    }
});

export const getCurrentOrFirstProperty = createSelector(
    _getProperty,
    _getFirstProperty,
    (property, firstProperty) => fromJS(property) || fromJS(firstProperty) || Map(),
);

export const getProperty = getCurrentOrFirstProperty;

// can return null
export const getProject = createSelector(_getProperties, getCurrentProject, (properties, projectId) => {
    return properties.byId[projectId] && fromJS(properties.byId[projectId]);
});

// can't return null
export const getMainProperty = createSelector(_getProperty, property => fromJS(property) || Map());

export const getCurrentUnits = createSelector(_getProperty, _getProperties, (project, properties) => {
    if (!project || !project.properties) {
        return List();
    }

    return fromJS(project.properties.data.map(unitId => properties.byId[unitId])).toList();
});

export const getUnitsByProjectId = projectId =>
    createSelector(_getProperties, properties => {
        const units =
            properties.byId[projectId]?.properties?.data?.map(propertyId => properties.byId[propertyId]) ?? [];

        return fromJS(units).toList();
    });

export const getPropertyVisitors = createSelector(
    getMainProperty,
    estate => (estate && estate.get('visitors')) || OrderedSet(),
);

export const getPropertyVisitor = findById(getPropertyVisitors, getCurrentContact);

export const getPropertyChannels = createSelector(
    getMainProperty,
    estate => (estate && estate.get('channels')) || OrderedSet(),
);

export const getPropertyChannel = channel =>
    createSelector(
        getPropertyChannels,
        channels => channels.find(item => item.get('name') === channel) || Map({ name: channel }),
    );

export const getIsPropertyLooped = createSelector(getMainProperty, estate =>
    estate ? estate.getIn(['loop', 'data', 'exists'], false) : false,
);

// Forms
//////////////////////////////////////////////////////////////////////

function transformFiles(_entities) {
    const entities = _entities
        ? _entities
              .map((_entity, id) => {
                  let entity = _entity.set('id', id);
                  entity = entity
                      .update('private', value => (typeof value === 'undefined' ? true : value))
                      .update('progress', progress => progress ?? 100);

                  return entity;
              })
              .toList()
        : List();

    return entities.sort((first, second) => {
        if (!first.get('ordinal')) {
            return first.get('uploaded_at') < second.get('uploaded_at') ? 1 : -1;
        }

        return first.get('ordinal') > second.get('ordinal') ? 1 : -1;
    });
}

/**
 * Format an immutable property for usage
 * in a Redux form
 */
function formatProperty(_property) {
    // Get structure and ensure all attributes are present on it
    let property = _property.merge(_property.get('attributes'));
    property = property.set('vendors', property.getIn(['vendors', 'data'], List()));
    property = property.set('buyers', property.getIn(['buyers', 'data'], List()));
    property = property.updateIn(['structure', 'rooms'], rooms => rooms || Map());
    property = property.updateIn(['energy', 'documents'], documents => documents || Map());
    property = property.updateIn(['floor_plans'], plans => plans || Map());

    property = property.update('conditions', conditions => (conditions ? conditions.toMap() : Map()));
    property = property.updateIn(['conditions', 'bathroom'], condition => condition ?? 'good');
    property = property.updateIn(['conditions', 'kitchen'], condition => condition ?? 'good');
    property = property.updateIn(['price', 'published_price', 'amount'], NumberFormatSanitizer.replaceDecimalSeparator);
    property = property.updateIn(['price', 'current_price', 'amount'], NumberFormatSanitizer.replaceDecimalSeparator);
    property = property.updateIn(['price', 'dotloop_price', 'amount'], NumberFormatSanitizer.replaceDecimalSeparator);
    property = property.updateIn(['settings', 'current_rent', 'amount'], NumberFormatSanitizer.replaceDecimalSeparator);
    property = property.updateIn(['occupancy', 'occupied'], occupied => occupied ?? false);
    property = property.updateIn(['structure', 'rooms'], Map(), rooms =>
        rooms.map(room => {
            if (!room.get('size')) {
                return room;
            }

            return room.update('size', NumberFormatSanitizer.replaceDecimalSeparator);
        }),
    );

    property = property.updateIn(['settings', 'auction', 'start_date'], DatetimeSanitizer.sanitize);
    property = property.updateIn(['settings', 'open_homes'], Map(), openHomes =>
        openHomes.map((openHome, id) => {
            return openHome
                .set('id', id)
                .update('start_date', DatetimeSanitizer.sanitize)
                .update('end_date', DatetimeSanitizer.sanitize);
        }),
    );

    property = property.update('structure', structure =>
        structure.map(area => {
            if (typeof area !== 'object') {
                return area;
            }

            return area.update('size', NumberFormatSanitizer.replaceDecimalSeparator);
        }),
    );

    property = property.update('negotiator', negotiator => negotiator?.getIn(['data', 'id']));

    return property
        .updateIn(['structure', 'rooms'], Map(), rooms => rooms.toList().sortBy(room => room.get('ordinal')))
        .updateIn(['settings', 'open_homes'], Map(), openHomes =>
            openHomes.toList().sortBy(openHome => openHome.get('datetime')),
        )
        .update('documents', transformFiles)
        .update('floor_plans', transformFiles)
        .update('images', transformFiles)
        .toJS();
}

/**
 * Get the initial values of a property-related form
 */
export const getPropertyFormValues = createSelector([getMainProperty], formatProperty);

export const getPropertyFormProps = createSelector(
    [getMainProperty, getIsSaving, getEnabledLocales],
    (property, isSaving, locales) => {
        return {
            property,
            isSaving,
            initialValues: formatProperty(property),
            supportedLangs: locales,
        };
    },
);

export const getPropertyPublishFormValues = createSelector(getMainProperty, property => {
    let settings = property.get('settings') || Map();

    if (!settings.has('publish_price')) {
        settings = settings.set('publish_price', true);
    }

    if (!settings.has('publish_location')) {
        settings = settings.set('publish_location', true);
    }

    // For backward compatibility, preselect all images if which
    // images to publish has not been configured yet
    if (settings.get('images_facebook') === '') {
        const imageKeys = property
            .getIn(['attributes', 'images'], new List())
            .filter(image => !image.get('private'))
            .keySeq()
            .toList();

        settings = settings.set('images_facebook', imageKeys);
    }

    if (settings.get('open_homes')) {
        settings = settings.set('open_homes', openHomes => openHomes.sort(sortByDateDesc('start_date', true)));
    }

    // Overwrite previously set default descriptions for now
    const description = property.getIn(['attributes', 'description', 'publish_default']);
    settings = settings
        .update('description_facebook', current => current)
        .update('description_twitter', current => current || description);

    return { ...settings.toJS(), publish: true };
});

/**
 * Get the initial values for the Rooms property form
 */
export const getRoomsValues = createSelector(
    [getPropertyFormProps, getOfficeSetting('measurement_system')],
    ({ property, isSaving, initialValues: _initialValues }, system) => {
        let initialValues = { ..._initialValues };
        // Flatten property types and amenities
        let selectedAmenities = new Map();
        let selectedShapes = new Map();
        property
            .getIn(['attributes', 'amenities'], [])
            .forEach(amenity => (selectedAmenities = selectedAmenities.set(amenity, true)));
        property
            .getIn(['attributes', 'shapes'], [])
            .forEach(shape => (selectedShapes = selectedShapes.set(shape, true)));

        initialValues.sub_type = checkSubTypes(
            property.get('type'),
            property.getIn(['attributes', 'sub_type'], new List()).first(),
        );
        initialValues.amenities = selectedAmenities.toJS();
        initialValues.shapes = selectedShapes.toJS();
        initialValues.shape = property.getIn(['attributes', 'shape'], '');
        initialValues.general_condition = property.getIn(['attributes', 'general_condition'], 'good');

        areas.forEach(areaType => {
            initialValues.structure[areaType] = {
                units: getDefaultUnitForArea(areaType, system, property.get('type')),
                size_description: toTitle(areaType),
                ...initialValues.structure[areaType],
            };
        });

        return {
            property,
            isSaving,
            initialValues,
            system,
        };
    },
);

export const getRequestInitialValues = createSelector(
    [getOfficeDefaultEmailForFilesRequest, (state, props) => props.initialValues],
    (message, initialValues) => {
        return {
            message,
            ...initialValues,
        };
    },
);

/// helpers
// check if the property is hidden from the
// active filters
function isFiltered(filters, property) {
    if (!filters.archived && property.is_archived) {
        return true;
    }

    return false;
}
