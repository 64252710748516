import { put, select } from 'redux-saga/effects';
import { fetchProperty, setProperty } from '../../actions';
import { ProjectsRequests, PropertiesRequests } from '../../../requests/index';
import { PROPERTIES } from '../../../app.routing/routes';
import { getPropertyById } from '../../selectors';
import apiCall from '../Effects/apiCall';
import isOnRoute from '../Helpers/isOnRoute';

export default function* onFetchProperty({ propertyId, options }) {
    const { force } = options;
    let property = yield select(getPropertyById(propertyId));

    const propertyAlreadyExists = property && property.get('id') != null;

    if (!force && propertyAlreadyExists) {
        // if the property was already loaded, and it is not a
        // force fetch, we just skip it
        return;
    }

    const getPropertyWith = requests => {
        const includes = (isOnRoute(PROPERTIES) ? ['loop'] : []).concat(['properties.vendors', 'properties.buyers']);
        const showRequest = requests.setQueryParameters({ includes }).show;

        return apiCall(showRequest, propertyId);
    };

    try {
        property = yield getPropertyWith(new PropertiesRequests());
    } catch (propertyResponse) {
        // If it's not a property, try fetching a project
        try {
            property = yield getPropertyWith(new ProjectsRequests());
        } catch (projectsResponse) {
            //
        }
    }

    if (property) {
        yield put(setProperty(property));

        // If the property is a unit with a project within it
        // and we do not have it yet, then fetch it has well
        const projectKey = property.project && property.project.data && property.project.data.id;
        const project = yield select(getPropertyById(projectKey));
        if (!project && projectKey) {
            yield put(fetchProperty(projectKey));
        }
    } else {
        // property was not found or the user does not
        // have access to it
    }
}
