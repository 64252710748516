// @ts-nocheck
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import useIntercom from 'use-intercom-hook';
import { intercom } from '@/app.data';
import { getIntercomValues } from '@/app.redux/selectors/UsersSelectors';
import useCompany from '@/app.hooks/useCompany';

type IntercomUser = {
    user_id?: string;
    email?: string;
    name?: string;
    app_id?: string;
    api_base?: string;
};

const IntercomChatBubble: React.FunctionComponent<{ intercomUser: IntercomUser }> = ({ intercomUser }) => {
    const defaultAppId = intercom.appId;
    const company = useCompany();
    const companyAppId = company.get('intercom_app_id');

    useIntercom(companyAppId || defaultAppId, intercomUser || {});

    return null;
};

export default connect(
    createStructuredSelector({
        intercomUser: getIntercomValues,
    }),
)(React.memo(IntercomChatBubble));
