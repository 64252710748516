import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { ContactMessagedActivity } from '@/graphql/generated/types';
import { trackHandler, events } from '@/app.utils/analytics';
import { fullName } from '@/app.utils/services/Helpers';
import { ContactLink, PropertyLink } from './elements/elements';
import ActivityCardWithAction from './ActivityCardWithAction';

export default function ContactMessagedActivityCard({
    activity,
    viewAs,
}: {
    activity: ContactMessagedActivity;
    viewAs: 'property' | 'contact';
}) {
    const title =
        viewAs === 'property' ? (
            <span>
                <FormattedMessage
                    id="interaction.messaged_contact"
                    defaultMessage="Messaged {contact}"
                    values={{
                        contact: activity.messagedContact && (
                            <ContactLink
                                contact={activity.messagedContact}
                                onClick={trackHandler(events.ACTIVITY_CARD_CONTACT_LINK_CLICKED, {
                                    activityType: activity.__typename,
                                })}
                            />
                        ),
                    }}
                />
            </span>
        ) : (
            <span>
                <FormattedMessage
                    id="interaction.message_about_property.parameterised"
                    defaultMessage="Message sent about {property}"
                    values={{
                        property: activity.about && <PropertyLink property={activity!.about!} />,
                    }}
                />
            </span>
        );

    const messagedByText = (
        <FormattedMessage
            id="interaction.messaged_by_contact"
            defaultMessage="Messaged by {contact}"
            values={{
                contact: fullName(activity.messagedBy.firstName, activity.messagedBy.lastName),
            }}
        />
    );
    const subtitle =
        viewAs === 'property' ? (
            <>
                {activity.about!.attributes.location.formatted ?? (
                    <FormattedMessage id="address.no_location_defined" defaultMessage="No location defined" />
                )}
                {' — '}
                {messagedByText}
            </>
        ) : (
            <>
                {`${fullName(activity?.messagedContact?.firstName, activity?.messagedContact?.lastName)} - `}
                {messagedByText}
            </>
        );

    return (
        <ActivityCardWithAction
            icon="speech-bubble"
            title={title}
            subtitle={subtitle}
            date={activity.timestamp}
            activity={activity}
        />
    );
}
