import gql from 'graphql-tag';

export const UPDATE_LABEL = gql`
    mutation UpdateLabel($input: LabelsInput!) {
        updateLabel(input: $input) {
            id
            name
            color
            description
            created_at
            updated_at
        }
    }
`;
