/* eslint-disable promise/catch-or-return */
import React from 'react';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { FormGroup, HelpBlock } from 'react-bootstrap/lib';
import { FormattedMessage } from 'react-intl-sweepbright';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import { fetchUserCalendars } from '@/app.domains/properties/Schedule/visits_requests';

export const CalendarList: React.FunctionComponent<{
    userId: string;
    value?: string;
    onChange: (value: string) => void;
    accessToken?: string;
    error?: string;
}> = ({ userId, value, onChange, error, accessToken }) => {
    const { calendarOptions, loading } = useCalendars({ accessToken, userId });

    return (
        <FormGroup
            className={classNames({
                'has-error': error,
            })}
        >
            <label className="control-label">
                <FormattedMessage id="integrations.calendar.select_calendar" defaultMessage="Select calendar" />
            </label>
            <Select
                testId="cronofy-calendars-select-field"
                options={calendarOptions}
                loading={loading}
                value={value}
                simpleValue
                //@ts-ignore
                onChange={onChange}
                hasError={!!error}
            />
            {error && <HelpBlock>{error}</HelpBlock>}
        </FormGroup>
    );
};

function useCalendars({ accessToken, userId }: { accessToken?: string; userId?: string }) {
    const params = accessToken ? [accessToken, null] : [null, userId];

    const { data, isLoading } = useQuery(['/cronofy/calendars', ...params], fetchUserCalendars, {
        enabled: accessToken || userId,
    });

    const calendarOptions = data?.map(calendar => {
        return {
            value: calendar.calendar_id,
            label: calendar.calendar_name,
        };
    });

    return { calendarOptions, loading: isLoading };
}
