import { put, fork, takeEvery } from 'redux-saga/effects';
import { RESET_PAGINATION, resetProperties, resetContacts } from '../actions';

function* onResetPagination({ resource }) {
    if (['properties', 'visits', 'publish', 'interests'].includes(resource) || resource.includes('properties')) {
        yield put(resetProperties());
    } else if (['contacts', 'vendors'].includes(resource)) {
        yield put(resetContacts());
    }
}

export default function* PaginationSaga() {
    yield [fork(takeEvery, RESET_PAGINATION, onResetPagination)];
}
