import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import { compose } from 'recompose';
import AuthReducer from './AuthReducer';
import ContactsReducer from './ContactsReducer';
import FeaturesReducer from './FeaturesReducer';
import PropertiesReducer from './PropertiesReducer';
import SearchReducer from './SearchReducer';
import StatusReducer from './StatusReducer';
import UsersReducer from './UsersReducer';
import LoadingReducer from './LoadingReducer';
import InteractionsReducer from './InteractionsReducer';
import OfficesReducer from './OfficesReducer';
import CompaniesReducer from './CompaniesReducer';
import ChannelsReducer from './ChannelsReducer';
import limitYear from './Helpers/limitYear';
import PlansReducer from './PlansReducer';
import AggregationsReducer from './AggregationsReducer';
import StructureFormReducer from './StructureFormReducer';
import { normalizeStructureForm } from './Helpers/reduxFormNormalizers/normalizeStructureForm';

export default combineReducers({
    auth: AuthReducer,
    contacts: ContactsReducer,
    features: FeaturesReducer,
    form: compose(
        formReducer.normalize({
            'properties/features': {
                'building.construction.year': limitYear,
                'building.renovation.year': limitYear,
            },
            'properties/rooms': normalizeStructureForm,
        }),
        formReducer.plugin({
            'properties/rooms': StructureFormReducer,
        }),
    ),
    properties: PropertiesReducer,
    routing: routerReducer,
    search: SearchReducer,
    status: StatusReducer,
    users: UsersReducer,
    loading: LoadingReducer,
    interactions: InteractionsReducer,
    offices: OfficesReducer,
    companies: CompaniesReducer,
    channels: ChannelsReducer,
    plans: PlansReducer,
    aggregations: AggregationsReducer,
});
