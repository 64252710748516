import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Waypoint } from 'react-waypoint';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { useToasts } from '@sweepbright/notifications';
import { Icon } from '@/app.components';
import PagePane from '@/app.layouts/PagePane/PagePane';
import Button from '@/app.components/elements/Buttons/Button';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import useLegalEntities from '@/app.hooks/useLegallEntities';
import { LegalEntities } from '@/graphql/generated/types';
import OfficesLegalEntitiesItem from './OfficesLegalEntitiesItem';

type Props = {
    officeId: string;
};

type FormLegalEntities = LegalEntities & {
    type?: 'added' | 'updated' | 'deleted';
};

const OfficesLegalEntities: React.FC<Props> = props => {
    const { officeId } = props;

    const intl = useIntl();

    const content = {
        title: intl.formatMessage({ id: 'offices.legal_entities' }),
        save: intl.formatMessage({ id: 'offices.legal_entities.save' }),
        add: intl.formatMessage({ id: 'offices.legal_entities.add' }),
        saved: intl.formatMessage({ id: 'offices.legal_entities.saved' }),
        error: intl.formatMessage({ id: 'offices.legal_entities.error' }),
    };

    const {
        data,
        loading,
        fetchMore,
        refetch,
        addLegalEntity,
        updateLegalEntity,
        deleteLegalEntity,
    } = useLegalEntities(officeId);

    const { addSuccess, addError } = useToasts();

    const pageInfo = data && data.office && data.office.legalEntities.pageInfo;
    const legalEntities = data && data.office ? data.office.legalEntities.edges.map(el => el.node) : [];

    const [fields, setFields] = useState<FormLegalEntities[]>([]);
    const [addedFields, setAddedFields] = useState<LegalEntities[]>([]);

    const hasMorePages = pageInfo?.hasNextPage || false;

    const defaultObject = {
        id: '',
        name: '',
    };

    const onChange = (updateEntity: LegalEntities) => {
        setFields(prevState => {
            if (prevState.length === 0) {
                return legalEntities.map(el => {
                    if (el.id === updateEntity.id) {
                        return { ...updateEntity, type: 'updated' };
                    }

                    return el;
                });
            }

            if (prevState.find(prevEl => prevEl.id === updateEntity.id)) {
                return prevState.map(prevEl => {
                    return prevEl.id === updateEntity.id ? { ...updateEntity, type: 'updated' } : prevEl;
                });
            }

            return [...prevState, { ...updateEntity, type: 'updated' }];
        });
    };

    const onDelete = (updateEntity: LegalEntities) => {
        if (hasMorePages) {
            fetchMore();
        }

        setFields(prevState => {
            if (prevState.length === 0) {
                return legalEntities.map(el => {
                    if (el.id === updateEntity.id) {
                        return { ...updateEntity, type: 'deleted' };
                    }

                    return el;
                });
            }

            if (prevState.find(prevEl => prevEl.id === updateEntity.id)) {
                return prevState.map(prevEl => {
                    return prevEl.id === updateEntity.id ? { ...updateEntity, type: 'deleted' } : prevEl;
                });
            }

            return [...prevState, { ...updateEntity, type: 'deleted' }];
        });
    };

    const onAddChange = (addEntity: LegalEntities, index?: number) => {
        if (addedFields.length === 0) {
            setAddedFields([addEntity]);
        } else {
            setAddedFields(
                addedFields.map((el, i) => {
                    return i === index ? addEntity : el;
                }),
            );
        }
    };

    const onAddDelete = (_addEntity: LegalEntities, index?: number) => {
        setAddedFields(addedFields.filter((_, i) => i !== index));
    };

    const handleSave = () => {
        const added = addedFields.filter(el => el.name);
        const updated = fields
            .filter(el => el.type === 'updated')
            .filter(el => {
                const prevEl = legalEntities.find(prevEl => prevEl.id === el.id);

                return prevEl && prevEl.name !== el.name;
            });
        const deleted = fields.filter(el => el.type === 'deleted');

        const addedRequest = added.map(el => {
            return addLegalEntity({ variables: { officeId, input: { name: el.name } } });
        });

        const updatedRequest = updated.map(el => {
            return updateLegalEntity({ variables: { officeId, input: { id: el.id, name: el.name } } });
        });

        const deletedRequest = deleted.map(el => {
            return deleteLegalEntity({ variables: { officeId, input: { id: el.id, name: el.name } } });
        });

        Promise.all([...addedRequest, ...updatedRequest, ...deletedRequest])
            .then(() => {
                addSuccess({
                    message: content.saved,
                });
            })
            .catch(() => {
                addError({ message: content.error });
            })
            .finally(() => {
                setFields([]);
                setAddedFields([]);
                refetch();
            });
    };

    return (
        <LayoutContainer>
            <PagePane
                title={content.title}
                actions={[
                    <Button key="button" type="submit" variant="success" onClick={handleSave}>
                        {content.save}
                    </Button>,
                ]}
            >
                {(fields.length ? fields.filter(el => el.type !== 'deleted') : legalEntities).map(el => {
                    return (
                        <OfficesLegalEntitiesItem
                            key={el.id}
                            entity={el}
                            onChange={onChange}
                            onDelete={onDelete}
                            hasData={!!legalEntities.length}
                        />
                    );
                })}

                {!loading && hasMorePages && <Waypoint fireOnRapidScroll onEnter={fetchMore} />}

                {(legalEntities.length || addedFields.length ? addedFields : [defaultObject]).map((el, index) => {
                    return (
                        <OfficesLegalEntitiesItem
                            key={index}
                            entity={el}
                            index={index}
                            disabled={loading}
                            onChange={onAddChange}
                            onDelete={onAddDelete}
                            hasData={!!legalEntities.length}
                        />
                    );
                })}

                {loading && <LoadingIndicator />}

                {!loading && (
                    <p>
                        <Button
                            variant="link"
                            icon={<Icon icon="add" />}
                            onClick={() => {
                                if (addedFields.length === 0 && legalEntities.length === 0) {
                                    setAddedFields([defaultObject, defaultObject]);
                                } else {
                                    setAddedFields([...addedFields, defaultObject]);
                                }
                            }}
                        >
                            {content.add}
                        </Button>
                    </p>
                )}
            </PagePane>
        </LayoutContainer>
    );
};

export default OfficesLegalEntities;
