import React from 'react';
import { Route, IndexRedirect, IndexRoute } from 'react-router';
import { FormattedMessage } from 'react-intl';

import PropertySchedulePage from '@/app.domains/properties/Schedule/PropertySchedulePage';

import * as Pages from '../allPagesComponents';

export default function propertyRoutes() {
    return (
        <Route path=":property" component={Pages.Property}>
            {withNestedUnits(
                <>
                    <Route path="schedule" component={Pages.PropertySchedule}>
                        <IndexRoute
                            // @ts-ignore
                            component={PropertySchedulePage}
                        />
                        <Route path="settings" component={Pages.PropertyScheduleSettings} />
                    </Route>
                    <Route path="timeline">
                        <IndexRoute component={Pages.PropertyTimeline} />
                        <Route path=":contact" component={Pages.PropertyTimelineContact} />
                    </Route>
                    <Route path="offers" component={Pages.PropertyOffers} />
                    <Route path="details" component={Pages.PropertyDetails}>
                        {estateDetailFormRoutes()}
                    </Route>
                    <Route path="publish" component={Pages.PropertyPublish} />
                    <Route path="match" component={Pages.PropertyMatch} />
                    <Route path="offers" component={Pages.PropertyOffers} />
                    <Route path="similar" component={Pages.SimilarPropertiesPage} />
                </>,
            )}
        </Route>
    );
}

function estateDetailFormRoutes() {
    return [
        <IndexRedirect key="index" to="location" />,
        <Route
            key="location"
            path="location"
            // @ts-ignore
            title={<FormattedMessage id="titles.property.location" defaultMessage="Add a location" />}
            component={Pages.PropertyDetailsLocation}
        />,
        <Route key="rooms" path="rooms" component={Pages.PropertyDetailsRooms} />,
        <Route key="description" path="description" component={Pages.PropertyDetailsDescription} />,
        <Route key="price" path="price" component={Pages.PropertyDetailsPrice} />,
        <Route key="images" path="images" component={Pages.PropertyDetailsImages} />,
        <Route
            key="images/requests"
            path="images/requests"
            // @ts-ignore
            title={<FormattedMessage id="titles.property.images.requests" defaultMessage="Pending images requests" />}
            component={Pages.PropertyDetailsImagesRequests}
            type="image"
        />,
        <Route key="legal-and-docs" path="legal-and-docs" component={Pages.PropertyDetailsLegalAndDocs} />,
        <Route key="plans" path="plans" component={Pages.PropertyDetailsPlans} />,
        <Route key="documents" path="documents" component={Pages.PropertyDetailsDocs} />,
        <Route
            key="documents/plans/requests"
            path="documents/plans/requests"
            // @ts-ignore
            title={<FormattedMessage id="titles.property.plans.requests" defaultMessage="Pending plans requests" />}
            component={Pages.PropertyDetailsImagesRequests}
            type="floor-plan"
        />,
        <Route key="legal" path="legal" component={Pages.PropertyDetailsLegal} />,
        <Route
            key="documents/requests"
            path="documents/requests"
            // @ts-ignore
            title={
                <FormattedMessage
                    id="titles.property.legal-and-docs.requests"
                    defaultMessage="Pending documents requests"
                />
            }
            component={Pages.PropertyDetailsImagesRequests}
            type="certificate"
        />,
        <Route key="features" path="features" component={Pages.PropertyDetailsFeatures} />,
        <Route key="settings" path="settings" component={Pages.PropertyDetailsSettings} />,
    ];
}

function withNestedUnits(propertyRoutes) {
    return (
        <>
            {propertyRoutes}
            <Route path="units">
                <IndexRoute component={Pages.PropertyUnits} />
                <Route path=":unit">{propertyRoutes}</Route>
            </Route>
        </>
    );
}
