import React, { useState } from 'react';
import { Icon } from '@/app.components';
import { FormattedMessage } from 'react-intl-sweepbright';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';
import ArrowDown from '@resources/assets/svg/icons/arrow-blue.svg';
import { formatPrice } from '@/app.utils/services/Helpers/priceFormatting';

type Props = {
    record: OfferCardFragmentFragment;
    isClosed: boolean;
};

const OfferCardDetails: React.FC<Props> = props => {
    const { record, isClosed } = props;

    const [visible, setVisible] = useState(false);

    const content = {
        details: <FormattedMessage id="offers.card.details" defaultMessage="Details" />,
        agency: <FormattedMessage id="offers.total.agency" defaultMessage="Agency Fee:" />,
        buyer: (
            <FormattedMessage id={`offers.total.buyer.${record.property?.negotiation}`} defaultMessage="Buyer Pays" />
        ),
        owner: <FormattedMessage id="offers.total.owner" defaultMessage="Owner Pays" />,
    };

    const total = Number(record?.financialDetails?.agencyTotalFee || 0);
    const totalBuyer = Number(record?.financialDetails?.buyerTotalFee || 0);
    const totalSeller = Number(record?.financialDetails?.ownerTotalFee || 0);

    const currency = record?.financialDetails?.currency || 'EUR';

    const options = {
        format: '%s %v',
        thousand: ' ',
        precision: 0,
    };

    const totalPrice = formatPrice({ amount: total + 0.25 || 0, currency: currency }, options);
    const totalBuyerPrice = formatPrice({ amount: totalBuyer || 0, currency: currency }, options);
    const totalSellerPrice = formatPrice({ amount: totalSeller || 0, currency: currency }, options);

    return (
        <div className="offer-card-details">
            <button
                onClick={() => {
                    setVisible(!visible);
                }}
                className={`offer-card-details__button${visible ? ' active' : ''}`}
            >
                {content.details} <ArrowDown />
            </button>

            {visible && (
                <div className="offer-card-details__block">
                    <div className="offer-card-details__icon">
                        <Icon icon="cash-heaps" size={40} style={{ color: isClosed ? '#8E8E93' : '#32AB91' }} />
                    </div>
                    <div className="offer-card-details__content">
                        <span className="offer-card-details__title">
                            {content.agency} {totalPrice}
                        </span>
                        <span className="offer-card-details__text">
                            {content.buyer} {totalBuyerPrice} – {content.owner} {totalSellerPrice}
                        </span>

                        {record.notes && <div className="offer-card-details__notes">{record.notes}</div>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default OfferCardDetails;
