import React from 'react';
import { useIntl } from 'react-intl';
import useOffice from '@/app.hooks/useOffice';

type Props = {
    teamId: string;
};

const TeamsLabel: React.FC<Props> = props => {
    const { teamId } = props;

    const intl = useIntl();

    const office = useOffice(teamId);

    const name = office?.get('name');

    const loading = intl.formatMessage({ id: 'common.state.loading' });

    if (!name) {
        return <>{loading}</>;
    }

    return <>{office.get('name')}</>;
};

export default TeamsLabel;
