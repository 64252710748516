import { fork, takeEvery } from 'redux-saga/effects';
import {
    FETCH_OFFICE_MEMBERS,
    UPDATE_OFFICE,
    UPDATE_OFFICE_MEMBER,
    ARCHIVE_OFFICE_MEMBER,
    OFFICE_WAS_SET,
    INVITE_OFFICE_MEMBERS,
    FETCH_OFFICE,
} from '../actions';
import {
    onFetchOfficeMembers,
    onUpdateOffice,
    onUpdateOfficeMember,
    onArchiveOfficeMember,
    onInviteOfficeMembers,
    onFetchOffice,
    onOfficeWasSet,
} from './Sagas';

export default function* OfficesSaga() {
    yield [
        fork(takeEvery, FETCH_OFFICE_MEMBERS, onFetchOfficeMembers),
        fork(takeEvery, UPDATE_OFFICE, onUpdateOffice),
        fork(takeEvery, UPDATE_OFFICE_MEMBER, onUpdateOfficeMember),
        fork(takeEvery, ARCHIVE_OFFICE_MEMBER, onArchiveOfficeMember),
        fork(takeEvery, INVITE_OFFICE_MEMBERS, onInviteOfficeMembers),
        fork(takeEvery, FETCH_OFFICE, onFetchOffice),
        fork(takeEvery, OFFICE_WAS_SET, onOfficeWasSet),
    ];
}
