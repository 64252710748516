import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuItem } from 'react-bootstrap';
import Button from '@sweepbright/uikit/build/esm/button';
import Dropdown from '@sweepbright/uikit/build/esm/dropdown';
import { useToasts } from '@sweepbright/notifications';
import ToggleButton from '@/app.components/elements/Buttons/ToggleButton';
import { Icon } from '@/app.components';
import { useAcceptOffer, useRefuseOffer } from '@/app.hooks/offer/offerMutations';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';
import useProperty, { useUpdatePropertyAttributes } from '@/app.hooks/useProperty';
import { useEstateBuyers } from '@/app.hooks/useEstateBuyers';
import { LayoutContext } from '@/app.components/layouts/utils';
import { useAuthCheck } from '@/app.hooks/auth/useAuthCheck';
import { checkChangesDifferent, OfferContext } from '../../utils';
import OfferCardConfirm from '../OfferCardConfirm';

type Props = {
    record: OfferCardFragmentFragment;
};

const OfferCardAction: React.FC<Props> = props => {
    const { record } = props;

    const { handleOfferStatsRefetch } = useContext(LayoutContext);

    const isPending = record.status === 'PENDING';

    const { addError, addWarning } = useToasts();

    const { property } = useProperty(isPending ? record.propertyId : null);

    const { assignBuyers, unassignBuyers } = useEstateBuyers(isPending ? record.propertyId : '');

    const updateAttributes = useUpdatePropertyAttributes();

    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    const { refetch, dataAuth, openCounterModal, defaultPropertyId, defaultContactId } = useContext(OfferContext);

    const handleRefetch = () => {
        refetch();

        handleOfferStatsRefetch();
    };

    const [acceptOffer] = useAcceptOffer({ variables: { offerId: record.id || '' } });
    const [refuseOffer] = useRefuseOffer({ variables: { offerId: record.id || '' } });

    const { data: authCheckProperty } = useAuthCheck({
        skip: !record.propertyId,
        variables: {
            input: [
                {
                    operation: 'edit',
                    resourceType: 'property',
                    requestId: 'property-edit',
                    resourceId: record.propertyId,
                },
            ],
        },
    });

    const content = {
        buyersWarning: <FormattedMessage id="offers.update.buyers_warning" />,
        refuse: <FormattedMessage id="offers.card.action.refuse" defaultMessage="Refuse" />,
        refuseCounter: (
            <FormattedMessage id="offers.card.action.refuse.counter" defaultMessage="Refuse With Counter-Offer" />
        ),
    };

    const canEdit = authCheckProperty?.authCheck?.find(el => el.requestId === 'property-edit')?.allowed;
    const canAccept = dataAuth?.find(el => el.requestId === `${record.id}-accept`)?.allowed;
    const canRefuse = dataAuth?.find(el => el.requestId === `${record.id}-refuse`)?.allowed;

    if (!canAccept && !canRefuse) {
        return null;
    }

    const propertyBuyersIds = [...(property?.buyer_ids || [])].sort((a, b) => a.localeCompare(b)) || [];
    const propertyOwnersIds = [...(property?.owner_ids || [])].sort((a, b) => a.localeCompare(b)) || [];

    const offerBuyersIds = record?.buyers?.map(el => el?.id)?.sort((a, b) => a.localeCompare(b)) || [];
    const offerOwnersIds = record?.owners?.map(el => el?.id)?.sort((a, b) => a.localeCompare(b)) || [];

    const isChangesDifferent = checkChangesDifferent({
        record,
        offerBuyersIds,
        offerOwnersIds,
        propertyBuyersIds,
        propertyOwnersIds,
        property,
    });

    const handleUpdateFees = async () => {
        setModalLoading(true);

        const newBuyersIds = offerBuyersIds.filter(el => !propertyBuyersIds.includes(el));
        const removeBuyersIds = propertyBuyersIds.filter(el => !offerBuyersIds.includes(el));

        if (newBuyersIds.length && !removeBuyersIds.length) {
            try {
                await assignBuyers(newBuyersIds);
            } catch (error) {
                addWarning({
                    message: content.buyersWarning,
                });
            }
        }

        if (!newBuyersIds.length && removeBuyersIds.length) {
            try {
                await unassignBuyers(removeBuyersIds);
            } catch (error) {
                addWarning({
                    message: content.buyersWarning,
                });
            }
        }

        if (newBuyersIds.length && removeBuyersIds.length) {
            try {
                await Promise.all([assignBuyers(newBuyersIds), unassignBuyers(removeBuyersIds)]);
            } catch (error) {
                addWarning({
                    message: content.buyersWarning,
                });
            }
        }

        updateAttributes(record.propertyId, {
            price: {
                current_price: {
                    amount: record?.financialDetails?.buyerGrossAmount || 0,
                },
            },
            settings: {
                agency_commission: {
                    buyer: {
                        fixed_fee: record?.financialDetails?.buyerFixedFee || 0,
                        percentage: record?.financialDetails?.buyerPercentageFee || 0,
                    },
                    seller: {
                        fixed_fee: record?.financialDetails?.ownerFixedFee || 0,
                        percentage: record?.financialDetails?.ownerPercentageFee || 0,
                    },
                },
            },
        })
            .catch(err => {
                const isForbidden = err?.message?.includes('are not authorized');

                addError({
                    message: isForbidden ? (
                        <FormattedMessage id="unauthorised_403" />
                    ) : (
                        <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                    ),
                });
            })
            .finally(() => {
                setModalLoading(false);
                setModalVisible(false);
            });

        acceptOffer()
            .catch(err => {
                const isForbidden = err?.message?.includes('are not authorized');

                addError({
                    message: isForbidden ? (
                        <FormattedMessage id="unauthorised_403" />
                    ) : (
                        <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                    ),
                });
            })
            .finally(() => {
                handleRefetch();
            });
    };

    return (
        <div className="offer-card-action">
            {canAccept && (
                <>
                    <OfferCardConfirm
                        visible={modalVisible}
                        loading={modalLoading}
                        onHide={() => {
                            setModalVisible(false);
                            setModalLoading(false);
                        }}
                        onConfirm={handleUpdateFees}
                        onCancel={() => {
                            setModalVisible(false);
                            setModalLoading(false);

                            acceptOffer()
                                .catch(err => {
                                    const isForbidden = err?.message?.includes('are not authorized');

                                    addError({
                                        message: isForbidden ? (
                                            <FormattedMessage id="unauthorised_403" />
                                        ) : (
                                            <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                                        ),
                                    });
                                })
                                .finally(() => {
                                    handleRefetch();
                                });
                        }}
                    />

                    <Button
                        variant="primary"
                        icon={<Icon icon="check" />}
                        onClick={() => {
                            if (isChangesDifferent && canEdit) {
                                setModalVisible(true);
                            } else {
                                acceptOffer()
                                    .catch(err => {
                                        const isForbidden = err?.message?.includes('are not authorized');

                                        addError({
                                            message: isForbidden ? (
                                                <FormattedMessage id="unauthorised_403" />
                                            ) : (
                                                <FormattedMessage
                                                    id="form.status.error"
                                                    defaultMessage="Could not save"
                                                />
                                            ),
                                        });
                                    })
                                    .finally(() => {
                                        handleRefetch();
                                    });
                            }
                        }}
                    >
                        <FormattedMessage id="offers.card.action.accept" defaultMessage="Accept" />
                    </Button>
                </>
            )}

            {canRefuse && (
                <div>
                    <Dropdown className="c-register-activity-action">
                        <ToggleButton bsRole="toggle" variant="secondary" className="btn-default flex items-center">
                            {content.refuse}
                        </ToggleButton>
                        <Dropdown.Menu>
                            <MenuItem
                                onSelect={() => {
                                    refuseOffer()
                                        .catch(err => {
                                            const isForbidden = err?.message?.includes('are not authorized');

                                            addError({
                                                message: isForbidden ? (
                                                    <FormattedMessage id="unauthorised_403" />
                                                ) : (
                                                    <FormattedMessage
                                                        id="form.status.error"
                                                        defaultMessage="Could not save"
                                                    />
                                                ),
                                            });
                                        })
                                        .finally(() => {
                                            handleRefetch();
                                        });
                                }}
                            >
                                {content.refuse}
                            </MenuItem>
                            <MenuItem
                                onSelect={() => {
                                    openCounterModal(
                                        record.id || '',
                                        defaultPropertyId || record.propertyId,
                                        defaultContactId,
                                    );
                                }}
                            >
                                {content.refuseCounter}
                            </MenuItem>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )}
        </div>
    );
};

export default OfferCardAction;
