import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@sweepbright/uikit/build/esm/button';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import { getSearchSettings } from '@/app.redux/selectors/SearchSelectors';
import PropertyPicker from '@/shared/properties/PropertyPicker';
import { getPagination, getProperties } from '../../app.redux/selectors';
import { fetchProperties, resetPagination, setSearch } from '../../app.redux/actions';

const mapStateToProps = createStructuredSelector({
    properties: getProperties,
    pagination: getPagination('highlights'),
    searchSettings: getSearchSettings('highlights'),
});

const mapActionsToProps = {
    fetchProperties: ({ merge = true, ...attributes }) =>
        fetchProperties(
            {
                ...attributes,
                company: true,
                channel: 'website',
            },
            'highlights',
            merge,
        ),
    resetPagination: () => resetPagination('highlights'),
    setSearch: attributes => setSearch('highlights', attributes),
};

@connect(mapStateToProps, mapActionsToProps)
export default class AddHighlight extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        fetchProperties: PropTypes.func.isRequired,
        field: PropTypes.object,
        onSubmit: PropTypes.func.isRequired,
    };

    state = {
        estateId: null,
    };

    handleSubmit = event => {
        event.preventDefault();

        this.props.close();
        this.props.onSubmit({ estateId: this.state.estateId });
    };

    handleSelect = ids => {
        this.setState({ estateId: ids[0] });
    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="modal.highlight.title" defaultMessage="Select property" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PropertyPicker
                        value={this.state.estateId}
                        onSelect={this.handleSelect}
                        excluded={this.props.excluded}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.close}>
                        <FormattedMessage id="modal.highlight.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button type="submit" variant="primary" disabled={!this.state.estateId}>
                        <FormattedMessage id="modal.highlight.cta" defaultMessage="Add" />
                    </Button>
                </Modal.Footer>
            </form>
        );
    }
}
