import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import TeamWrapper from '@/new.domains/teams/TeamWrapper';
import { emailTypes } from '../../../../../app.data';
import { TEAM_PRESETS_FOR_EMAIL } from '../../../../../app.routing/routes';
import SideMenu from '../../../../../app.components/navigations/SideMenu/SideMenu';
import SideMenuLink from '../../../../../app.components/navigations/SideMenu/SideMenuLink';

export const emailSections = {
    email_matching_lead_description: (
        <FormattedMessage id="forms.presets.emails.email_matching_lead_description" defaultMessage="Match" />
    ),
    email_lead_visit: <FormattedMessage id="forms.presets.emails.email_lead_visit" defaultMessage="Schedule Visit" />,
    email_report_description: (
        <FormattedMessage id="forms.presets.emails.email_report_description" defaultMessage="Vendor Report" />
    ),
    email_property_images_request: (
        <FormattedMessage id="forms.presets.emails.email_property_images_request" defaultMessage="Request Images" />
    ),
    email_property_documents_request: (
        <FormattedMessage
            id="forms.presets.emails.email_property_documents_request"
            defaultMessage="Request Documents"
        />
    ),
    email_property_plans_request: (
        <FormattedMessage id="forms.presets.emails.email_property_plans_request" defaultMessage="Request Plans" />
    ),
};

type Props = {
    params: {
        office: string;
    };
};

const EmailTemplatesLayout: React.FunctionComponent<Props> = props => {
    const { office } = props.params;
    if (!office) {
        return null;
    }

    return (
        <TeamWrapper {...props}>
            <LayoutContainer flex>
                <LayoutColumn size="medium">
                    <SideMenu color="gray-lighter" textOnly>
                        {emailTypes.map(type => (
                            <SideMenuLink
                                icon="mail"
                                key={type}
                                label={emailSections[type]}
                                to={TEAM_PRESETS_FOR_EMAIL(office, type)}
                            />
                        ))}
                    </SideMenu>
                </LayoutColumn>
                <LayoutColumn>{props.children}</LayoutColumn>
            </LayoutContainer>
        </TeamWrapper>
    );
};

export default EmailTemplatesLayout;
