import { Negotiation } from '@/graphql/generated/types';

export const formatContactsSearch = ({
    page,
    officeId,
    showAreas,
    atlasSearch,
    searchSettings,
}: {
    page: number;
    officeId: string;
    showAreas: boolean;
    atlasSearch: boolean;
    searchSettings?: any;
}) => {
    const type = searchSettings?.type?.toUpperCase() || undefined;
    const query = searchSettings?.query?.trim() || '';

    return {
        page,
        limit: 50,
        query: query,
        useAtlasSearch: atlasSearch,
        sortField: query ? undefined : searchSettings.sort_field,
        sortOrder: query ? undefined : searchSettings.sort_order,
        filters: {
            type: type,
            officeId: officeId,
            labels: searchSettings.labels,
            archived: searchSettings.archived,
            offerStatus: searchSettings.offer_status,
            negotiation: (searchSettings.negotiation?.toUpperCase() as any) as Negotiation,
            maxPrice: searchSettings.max_price ? parseFloat(searchSettings.max_price) : undefined,
            minPrice: searchSettings.min_price ? parseFloat(searchSettings.min_price) : undefined,
            price_point: searchSettings!.price_point ? parseFloat(searchSettings!.price_point) : undefined,
            negotiator: undefined,
            negotiator_ids: searchSettings.negotiator_ids || undefined,
            noNegotiator: searchSettings.negotiator_ids === null || undefined,
            preference_type: searchSettings.preference_type,
            preference_subtype: searchSettings.preference_subtype,
            preference_min_condition:
                searchSettings.preference_min_condition && Array.isArray(searchSettings.preference_min_condition)
                    ? searchSettings.preference_min_condition[0]
                    : searchSettings.preference_min_condition,
            preference_amenity: searchSettings.preference_amenity,
            preference_min_bedrooms:
                typeof searchSettings.preference_min_bedrooms === 'string'
                    ? searchSettings.preference_min_bedrooms.length > 0
                        ? parseInt(searchSettings.preference_min_bedrooms)
                        : undefined
                    : searchSettings.preference_min_bedrooms,
            preference_postal_code: searchSettings.preference_postal_code,
            noInterestPropertyId: searchSettings.noInterestPropertyId,
            preferenceGeoPoint: searchSettings.preferenceGeoPoint,
            ...(showAreas
                ? {
                      preferenceLiveableArea: searchSettings.preferenceLiveableArea,
                      preferenceNetArea: searchSettings.preferenceNetArea,
                      preferencePlotArea: searchSettings.preferencePlotArea,
                      preferenceGrossArea: searchSettings.preferenceGrossArea,
                  }
                : {}),
        },
    };
};
