import React, { createContext } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl-sweepbright';
import {
    AuthCheckInput,
    Contact,
    CreateOfferInput,
    Estate,
    OfferCardFragmentFragment,
    PropertyCardFragmentFragment,
    UpdateOfferInput,
} from '@/graphql/generated/types';
import { Icon } from '@/app.components';
import Price from '@/app.components/elements/Price/Price';

export const OfferContext = createContext<{
    visible: boolean;
    refetch: () => void;
    openAddModal: () => void;
    openEditModal: (editOfferId: string) => void;
    openCounterModal: (counterParentId: string, counterPropertyId: string, counterContactId: string) => void;
    defaultPropertyId: string;
    defaultContactId: string;
    counterParentId: string;
    dataAuth?: any[];
}>({
    visible: false,
    refetch: () => {},
    openAddModal: () => {},
    openEditModal: () => {},
    openCounterModal: () => {},
    defaultPropertyId: '',
    defaultContactId: '',
    counterParentId: '',
});

export const getOfferExpires = (value: string) => {
    const days = value ? (moment(value).unix() - moment().unix()) / 86400 : 0;

    if (days > 0) {
        const content = {
            lessOneDay: <FormattedMessage id="offers.column.nextOffer.less" defaultMessage="Less than 1 day" />,
            oneDay: <FormattedMessage id="offers.column.nextOffer.one" defaultMessage="1 day" />,
            days: <FormattedMessage id="offers.column.nextOffer.days" defaultMessage="days" />,
        };

        const left = (days < 1 && content.lessOneDay) || (days < 2 && content.oneDay) || (
            <>
                {Math.floor(days)} {content.days}
            </>
        );

        return <span className={`offer-column-expires${days < 2 ? ' warning' : ''}`}>{left}</span>;
    }

    return null;
};

export const renderOfferColumn = (
    type: 'highest_offer_bid' | 'number_of_pending_offers' | 'closest_offer_expirations',
    property: Estate | null,
    contact?: Contact,
) => {
    const record = property || contact;

    if (type === 'highest_offer_bid') {
        const value = record?.offersStats?.highest_offer_bid;
        const currency = record?.offersStats?.currency;

        if (property) {
            const priceObject = property?.attributes.price?.published_price ?? {
                amount: 0,
                currency: currency || 'EUR',
            };
            const priceDiff = Number(value) - Number(priceObject.amount);

            if (!Number.isNaN(priceDiff) && typeof priceDiff === 'number' && priceObject.currency === currency) {
                const status = (priceDiff === 0 && 'equal') || (priceDiff > 0 && 'more') || 'less';

                return (
                    <span className="offer-column-highest">
                        <Icon size={8} icon={`offer-column-${status}`} />
                        <Price amount={value || 0} currency={currency} precision={0} />
                    </span>
                );
            }
        }

        if (!property && value) {
            return <Price amount={value} currency={currency} />;
        }
    }

    if (type === 'number_of_pending_offers') {
        const valueCount = record?.offersStats?.number_of_pending_offers;
        const valueAccepted = record?.offersStats?.has_accepted_offer;

        if (valueAccepted) {
            return (
                <span className="offer-column-count">
                    <FormattedMessage id="offers.status.accepted" defaultMessage="Accepted" />
                </span>
            );
        }

        if (!valueAccepted && valueCount) {
            return <span>{valueCount}</span>;
        }
    }

    if (type === 'closest_offer_expirations') {
        const value = record?.offersStats?.closest_offer_expirations[0];

        const offerExpires = getOfferExpires(value);

        if (offerExpires) {
            return offerExpires;
        }
    }

    return <span className="text-muted">-</span>;
};

export const formatOfferData = (
    values: OfferCardFragmentFragment,
    mode: 'EDIT' | 'ADD',
): CreateOfferInput | UpdateOfferInput => {
    const direction = values.financialDetails.direction;

    const newObject = {
        notes: values.notes,
        validUntil: values.validUntil,
        financialDetails: {
            direction,
            currency: values.financialDetails.currency,
            buyerFixedFee: values.financialDetails.buyerFixedFee,
            buyerPercentageFee: values.financialDetails.buyerPercentageFee,
            ownerFixedFee: values.financialDetails.ownerFixedFee,
            ownerPercentageFee: values.financialDetails.ownerPercentageFee,
            buyerGrossAmount: direction === 'OWNER_TO_BUYER' ? undefined : values.financialDetails.buyerGrossAmount,
            ownerNetAmount: direction === 'BUYER_TO_OWNER' ? undefined : values.financialDetails.ownerNetAmount,
        },
        buyers: values?.buyers?.map(el => el?.id) || [],
        owners: values?.owners?.map(el => el?.id) || [],
    };

    if (mode === 'ADD') {
        // @ts-ignore
        return {
            ...newObject,
            parentId: values.parentId,
            propertyId: values.propertyId,
        };
    }

    if (mode === 'EDIT') {
        // @ts-ignore
        return {
            ...newObject,
            id: values.id!,
        };
    }

    throw new Error('Invalid mode');
};

export const formatNumber = (value: number) => {
    return Math.trunc(value * 100) / 100;
};

export const formatOfferAuthInput = (offersData: OfferCardFragmentFragment[], operation: string): AuthCheckInput[] => {
    return (
        offersData.map(el => {
            return {
                operation,
                resourceType: 'offer',
                resourceId: `${el.id}`,
                requestId: `${el.id}-${operation}`,
                context: {
                    propertyId: el.propertyId,
                    buyerIds: el?.buyers?.map(el => el?.id),
                    ownerIds: el?.owners?.map(el => el?.id),
                    direction: el?.financialDetails?.direction,
                },
            };
        }) || []
    );
};

export const checkChangesDifferent = ({
    record,
    offerBuyersIds,
    offerOwnersIds,
    propertyBuyersIds,
    propertyOwnersIds,
    property,
}: {
    record: OfferCardFragmentFragment;
    offerBuyersIds: any;
    offerOwnersIds: any;
    propertyBuyersIds: any;
    propertyOwnersIds: any;
    property?: Maybe<{ __typename?: 'Estate' | undefined } & PropertyCardFragmentFragment>;
}) => {
    const offerFees = [
        record.financialDetails.buyerFixedFee,
        record.financialDetails.buyerPercentageFee,
        record.financialDetails.ownerFixedFee,
        record.financialDetails.ownerPercentageFee,
    ].join('/');

    const propertyFees = [
        property?.attributes?.settings?.agency_commission?.buyer?.fixed_fee,
        property?.attributes?.settings?.agency_commission?.buyer?.percentage,
        property?.attributes?.settings?.agency_commission?.seller?.fixed_fee,
        property?.attributes?.settings?.agency_commission?.seller?.percentage,
    ].join('/');

    const offerPrice = record.financialDetails.buyerGrossAmount;
    const propertyPrice = property?.attributes?.price?.current_price?.amount;

    return (
        offerFees !== propertyFees ||
        offerPrice !== propertyPrice ||
        offerBuyersIds.join('/') !== propertyBuyersIds.join('/') ||
        offerOwnersIds.join('/') !== propertyOwnersIds.join('/')
    );
};
