// @ts-nocheck
import React from 'react';
import gql from 'graphql-tag';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { Col, Row } from 'react-bootstrap';
import { reduxForm as form } from 'redux-form';
import ListChecker from '@sweepbright/uikit/build/esm/listchecker';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import Validator from '../../app.utils/services/Validator';
import IconCheckbox from '../../app.components/forms/IconCheckbox';
import FormPanel from '../../app.components/forms/FormPanel/FormPanel';

const ADD_ESTATE_MUTATION = gql`
    mutation AddNewEstate($input: AddEstateInput!) {
        addEstate(input: $input) {
            estate {
                id
            }
        }
    }
`;

export default function AddPropertyForm(props: { close: () => void; handleSubmit: () => void; submitting?: boolean }) {
    const [addEstate] = useMutation(ADD_ESTATE_MUTATION);

    const { values, handleChange, handleSubmit, isValid, isSubmitting } = useFormik({
        initialValues: {
            negotiation: 'sale',
            kind: 'single',
            type: 'house',
        },
        validate: Validator.validateWithRules({
            negotiation: {
                type: 'string',
                required: true,
                allowEmpty: false,
            },
            kind: {
                type: 'string',
                required: false,
            },
            type: {
                type: 'string',
                required: true,
                allowEmpty: false,
            },
        }),
        onSubmit: values => {
            return addEstate({
                variables: {
                    input: {
                        type: values.type,
                        kind: values.kind,
                        negotiation: values.negotiation,
                    },
                },
            })
                .then(result => {
                    const estate = result.data.addEstate.estate;

                    return props.onSubmit({ estate });
                })
                .then(result => {
                    return props.onSubmitSuccess(result);
                });
        },
    });

    return (
        <form onSubmit={handleSubmit}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="forms.add-property.title" defaultMessage="New property" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={7}>
                        <FormPanel
                            title={
                                <FormattedMessage
                                    id="forms.add-property.sale-or-let"
                                    defaultMessage="For sale or to let?"
                                />
                            }
                            containerClassName="pb-0 mb-0"
                        >
                            <ListChecker className="nav" withBackground>
                                <IconCheckbox
                                    title={
                                        <FormattedMessage
                                            id="forms.add-property.sale-or-let.sale.title"
                                            defaultMessage="For Sale"
                                        />
                                    }
                                    subtitle={
                                        <FormattedMessage
                                            id="forms.add-property.sale-or-let.sale.subtitle"
                                            defaultMessage="Unique properties for sale"
                                        />
                                    }
                                    icon="new-sale"
                                    value="sale"
                                    onChange={handleChange}
                                    name="negotiation"
                                    checked={values.negotiation === 'sale'}
                                />
                                <IconCheckbox
                                    title={
                                        <FormattedMessage
                                            id="forms.add-property.sale-or-let.let.title"
                                            defaultMessage="To Let"
                                        />
                                    }
                                    subtitle={
                                        <FormattedMessage
                                            id="forms.add-property.sale-or-let.let.subtitle"
                                            defaultMessage="Ideal real estate to let"
                                        />
                                    }
                                    icon="new-let"
                                    value="let"
                                    onChange={handleChange}
                                    name="negotiation"
                                    checked={values.negotiation === 'let'}
                                />
                            </ListChecker>
                        </FormPanel>
                        <FormPanel
                            title={<FormattedMessage id="forms.add-property.unit" defaultMessage="A Single Unit?" />}
                            containerClassName="pb-0 mb-0"
                        >
                            <ListChecker className="nav" withBackground>
                                <IconCheckbox
                                    title={
                                        <FormattedMessage
                                            id="forms.add-property.unit.single.title"
                                            defaultMessage="Single Unit"
                                        />
                                    }
                                    subtitle={
                                        <FormattedMessage
                                            id="forms.add-property.unit.single.subtitle"
                                            defaultMessage="Just one"
                                        />
                                    }
                                    icon="layer"
                                    value="single"
                                    onChange={handleChange}
                                    name="kind"
                                    checked={values.kind === 'single'}
                                />
                                <IconCheckbox
                                    title={
                                        <FormattedMessage
                                            id="forms.add-property.unit.project.title"
                                            defaultMessage="Project"
                                        />
                                    }
                                    subtitle={
                                        <FormattedMessage
                                            id="forms.add-property.unit.project.subtitle"
                                            defaultMessage="Group of similar units"
                                        />
                                    }
                                    icon="new-project"
                                    value="project"
                                    onChange={handleChange}
                                    name="kind"
                                    checked={values.kind === 'project'}
                                />
                            </ListChecker>
                        </FormPanel>
                    </Col>
                    <Col sm={7} smOffset={1}>
                        <FormPanel
                            title={<FormattedMessage id="forms.add-property.type" defaultMessage="What do you have?" />}
                            containerClassName="pb-0 mb-0"
                        >
                            <ListChecker className="nav" withBackground>
                                <IconCheckbox
                                    title={<FormattedMessage id="property.type.house" defaultMessage="House" />}
                                    subtitle={
                                        <FormattedMessage
                                            id="property.type.house.description"
                                            defaultMessage="All shapes and all sizes"
                                        />
                                    }
                                    icon="new-house"
                                    value="house"
                                    onChange={handleChange}
                                    name="type"
                                    checked={values.type === 'house'}
                                />
                                <IconCheckbox
                                    title={<FormattedMessage id="property.type.apartment" defaultMessage="Apartment" />}
                                    subtitle={
                                        <FormattedMessage
                                            id="property.type.apartment.description"
                                            defaultMessage="Surrounded by neighbours"
                                        />
                                    }
                                    icon="new-apartment"
                                    value="apartment"
                                    onChange={handleChange}
                                    name="type"
                                    checked={values.type === 'apartment'}
                                />
                                <IconCheckbox
                                    title={<FormattedMessage id="property.type.parking" defaultMessage="Parking" />}
                                    subtitle={
                                        <FormattedMessage
                                            id="property.type.parking.description"
                                            defaultMessage="Park my ride"
                                        />
                                    }
                                    icon="new-parking"
                                    value="parking"
                                    onChange={handleChange}
                                    name="type"
                                    checked={values.type === 'parking'}
                                />
                                <IconCheckbox
                                    title={<FormattedMessage id="property.type.land" defaultMessage="Land" />}
                                    subtitle={
                                        <FormattedMessage
                                            id="property.type.land.description"
                                            defaultMessage="A blank slate"
                                        />
                                    }
                                    icon="new-land"
                                    value="land"
                                    onChange={handleChange}
                                    name="type"
                                    checked={values.type === 'land'}
                                />
                                <IconCheckbox
                                    title={
                                        <FormattedMessage
                                            id="property.type.commercial"
                                            defaultMessage="Commercial Property"
                                        />
                                    }
                                    subtitle={
                                        <FormattedMessage
                                            id="property.type.commercial_property.description"
                                            defaultMessage="Get down to business"
                                        />
                                    }
                                    icon="new-commercial"
                                    value="commercial"
                                    onChange={handleChange}
                                    name="type"
                                    checked={values.type === 'commercial'}
                                />
                                <IconCheckbox
                                    title={<FormattedMessage id="property.type.office" defaultMessage="Office" />}
                                    subtitle={
                                        <FormattedMessage
                                            id="property.type.office.description"
                                            defaultMessage="Ready-to-go or empty workspace"
                                        />
                                    }
                                    icon="new-office"
                                    value="office"
                                    onChange={handleChange}
                                    name="type"
                                    checked={values.type === 'office'}
                                />
                            </ListChecker>
                        </FormPanel>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.close} disabled={isSubmitting}>
                    <FormattedMessage id="forms.add-property.cancel" defaultMessage="Cancel" />
                </Button>
                <Button disabled={!isValid || isSubmitting} type="submit" variant="primary">
                    {isSubmitting ? (
                        <FormattedMessage id="forms.add-property.cta_busy" defaultMessage="Adding..." />
                    ) : (
                        <FormattedMessage id="forms.add-property.cta" defaultMessage="Add" />
                    )}
                </Button>
            </Modal.Footer>
        </form>
    );
}
