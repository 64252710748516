import { createReducer } from 'redux-reducer-factory';
import { fromJS, Map, Set } from 'immutable';
import { SET_SEARCH, RESET_SEARCH, SET_USER, SET_SEARCH_RESULTS, RESET_SEARCH_RESULTS } from '../actions';
import { propertyTypes } from '../../app.data/Properties';
import concatSetWith from './Helpers/concatSetWith';

function normalizeFilters(filterName, filters, userId) {
    switch (filterName) {
        default: {
            let normalizedFilters = filters;
            const ownership = filters.get('ownership', 'all');
            if (typeof ownership === 'number') {
                if (userId === ownership) {
                    normalizedFilters = normalizedFilters.set('ownership', 'mine');
                } else {
                    normalizedFilters = normalizedFilters.set('ownership', 'with_negotiator');
                    normalizedFilters = normalizedFilters.set('negotiator', ownership);
                }
            }

            return normalizedFilters;
        }
    }
}
const mapTypes = types =>
    types.reduce((reduction, type) => {
        // eslint-disable-next-line no-param-reassign
        reduction[type] = true;

        return reduction;
    }, {});

const mapFilters = {
    property_type: {
        all: 'property_project',
        project: 'single_project',
        property: 'single_property',
    },
};

const defaults = fromJS({
    contacts: {
        query: '',
        type: 'all',
        negotiation: 'all',
        interest: '',
        archived: false,
        filterByInterest: false,
        bright: false,
        min_price: '',
        max_price: '',
        sort_field: 'updated_at',
        sort_order: 'desc',
    },
    properties: {
        bright: false,
        query: '',
        filterByStatus: false,
        status: [],
        archived: false,
        ownership: 'all',
        negotiation: 'all',
        property_type: 'property_project',
        type: mapTypes(propertyTypes),
        min_price: '',
        negotiator_id: '',
        max_price: '',
        sort_field: 'updated_at',
        sort_order: 'desc',
    },
});

export default createReducer(
    {
        defaults,
        contacts: {
            results: [],
            params: defaults.get('contacts'),
        },
        'visits/contacts': {
            results: [],
            params: defaults.get('contacts'),
        },
        properties: {
            results: [],
            params: defaults.get('properties'),
        },
        visits: {
            results: [],
            params: defaults.get('properties'),
        },
        publish: {
            results: [],
            params: defaults.get('properties'),
        },
    },
    {
        [SET_USER]: (search, action, sanitize) => {
            const filters = sanitize(action.user).getIn(['filters', 'data']) || new Map();

            return search.map((parameters, filter) => {
                let filterParameters = parameters;
                if (filters.has(`search_${filter}`)) {
                    filterParameters = parameters.set(
                        'params',
                        normalizeFilters(filter, filters.get(`search_${filter}`), action.user.id),
                    );
                }

                const mappedParams = filterParameters
                    .get('params', new Map())
                    .map((value, param) =>
                        mapFilters[param] && mapFilters[param][value] ? mapFilters[param][value] : value,
                    );

                return filterParameters.set('params', mappedParams);
            });
        },
        [SET_SEARCH]: (search, action, sanitize) => {
            const parameters = sanitize(action.parameters).set('filter_identifier', action.search);

            return search.setIn([action.search, 'params'], parameters);
        },
        [RESET_SEARCH]: (search, action, sanitize) => {
            const parameters = sanitize(defaults.get(action.search, defaults.get('properties'))).set(
                'filter_identifier',
                action.search,
            );

            return search.setIn([action.search, 'params'], parameters);
        },
        [SET_SEARCH_RESULTS]: (search, action) =>
            search.updateIn([action.identifier, 'results'], new Set(), concatSetWith(action.results)),
        [RESET_SEARCH_RESULTS]: (search, action) => search.setIn([action.identifier, 'results'], new Set()),
    },
);
