import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '@/app.components';

type Props = {
    query: string;
    setQuery: Function;
    focused?: boolean;
};

const PaletteSearch: React.FC<Props> = props => {
    const { query, setQuery, focused } = props;

    const intl = useIntl();

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        ref.current?.focus();
    }, [focused]);

    return (
        <div className="palette-search">
            <input
                ref={ref}
                value={query}
                type="text"
                onChange={e => {
                    setQuery(e.target.value);
                }}
                className="palette-search__input"
                placeholder={intl.formatMessage({
                    id: 'palette.search',
                    defaultMessage: 'Search actions',
                })}
            />

            <Icon icon="search" />
        </div>
    );
};

export default PaletteSearch;
