// @ts-nocheck
import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import Button from '@sweepbright/uikit/build/esm/button';
import { withState, compose, withHandlers } from 'recompose';
import { FormattedMessage } from '@/app.utils/services/Helpers/reactIntlWrapper';
import CompaniesRequests from '@/requests/CompaniesRequests';

class VatField extends Component<{
    // external validation error, comming from the form
    error?: string;
    inputRef: any;
    onChange: (value: string) => void;
    setStatus: (newStatus: string) => void;
    setVatId: (value: string) => void;
    status: string;
    validateVat: (vat: string) => Promise<boolean>;
    vatId: string;
}> {
    componentDidUpdate(prevProps) {
        if (this.shouldFocus(prevProps)) {
            this.focus();
        }

        if (this.hasNewError(prevProps)) {
            this.focus();
            this.props.setStatus('invalid');
        }
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
            this.inputRef.current.select();
        }
    }

    hasNewError(prevProps) {
        if (prevProps.error === this.props.error) {
            return false;
        }

        return Boolean(this.props.error);
    }

    shouldFocus(prevProps) {
        if (prevProps.status === 'valid' && this.props.status === 'initial') {
            return true;
        }

        if (prevProps.status === 'validating' && this.props.status === 'invalid') {
            return true;
        }

        if (!prevProps.error && this.props.error) {
            return true;
        }

        return false;
    }

    inputRef = React.createRef<HTMLInputElement>();

    render() {
        const handleChange = evt => this.props.setVatId(evt.target.value);

        return (
            <div className="form-inline">
                <label htmlFor="vat_field">
                    <FormattedMessage id="forms.labels.invoices.vat_number" defaultMessage="VAT ID (Only EU)" />
                </label>
                <FormattedMessage id="forms.placeholders.invoices.vat_number" defaultMessage="e.g. BE 0000.000.000">
                    {placeholder => (
                        <FormControl
                            type="text"
                            placeholder={placeholder}
                            disabled={this.props.status === 'valid'}
                            inputRef={this.inputRef}
                            value={this.props.vatId}
                            onChange={handleChange}
                            style={{ maxWidth: 200, display: 'inline-block', verticalAlign: 'middle' }}
                            id="vat_field"
                        />
                    )}
                </FormattedMessage>
                {(this.props.status === 'initial' || this.props.status === 'invalid') && (
                    <Button
                        variant="primary"
                        onClick={async () => {
                            this.props.setStatus('validating');
                            const isValid = await this.props.validateVat(this.props.vatId);
                            if (isValid) {
                                this.props.setStatus('valid');
                                this.props.onChange(this.props.vatId);
                            } else {
                                this.props.setStatus('invalid');
                                this.props.onChange('');
                            }
                        }}
                    >
                        <FormattedMessage id="component.vat_field.verify_btn.validate_action" defaultMessage="Verify" />
                    </Button>
                )}
                {this.props.status === 'valid' && (
                    <Button
                        onClick={() => {
                            this.props.setStatus('initial');
                            this.props.onChange('');
                        }}
                    >
                        <FormattedMessage id="component.vat_field.verify_btn.change_action" defaultMessage="Change" />
                    </Button>
                )}
                {this.props.status === 'validating' && (
                    <Button disabled>
                        <FormattedMessage
                            id="component.vat_field.verify_btn.validating_action"
                            defaultMessage="Verifying..."
                        />
                    </Button>
                )}
                {this.props.status === 'invalid' && (
                    <div className="help-block text-danger">
                        <FormattedMessage
                            id="component.vat_field.invalid_vat_message"
                            defaultMessage="This is not a valid VAT ID"
                        />
                    </div>
                )}
                {this.props.status === 'valid' && (
                    <div className="help-block text-success">
                        <FormattedMessage
                            id="component.vat_field.valid_vat_message"
                            defaultMessage="This VAT is valid"
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default compose(
    withHandlers({
        validateVat: props => (vatId: string) => {
            return new CompaniesRequests()
                .validateVat(props.companyId, vatId)
                .then(() => true)
                .catch(() => false);
        },
    }),
    withState('vatId', 'setVatId', ownProps => ownProps.value),
    withState('status', 'setStatus', ownProps => {
        // if the field has a value when it is created,
        // passed as props, it means it is a valid VAT, so
        // we make it valid
        if (ownProps.value && !ownProps.error) {
            return 'valid';
        }

        return 'initial';
    }),
)(VatField);
