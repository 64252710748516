import React from 'react';

function isEvent(evtOrValue) {
    return evtOrValue != null && typeof evtOrValue === 'object' && evtOrValue.target;
}

interface Event<T> {
    target: { value: T | undefined | null };
}

interface ChangeHandler<T> {
    (e: Event<T> | T | undefined | null): void;
}

export interface FieldProps<T> {
    value: T | undefined | null;
    onChange: ChangeHandler<T>;
}

function useField(
    initialValue: Maybe<string> = '',
    options: { onChange?: ChangeHandler<string> } = {},
): FieldProps<string> {
    const [value, setValue] = React.useState(initialValue);
    const onChange = React.useCallback(
        evtOrValue => {
            if (isEvent(evtOrValue)) {
                setValue(evtOrValue.target.value);
                if (options.onChange) {
                    options.onChange(evtOrValue.target.value);
                }
            } else {
                setValue(evtOrValue || '');
                if (options.onChange) {
                    options.onChange(evtOrValue || '');
                }
            }
        },
        [options],
    );

    return { value, onChange };
}

export default useField;
