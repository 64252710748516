import { FDLRulesList } from './types';
import { validateRules } from './validateRule';

export function concatenateRulesLists(
  rulesLists: FDLRulesList[]
): FDLRulesList {
  return rulesLists.reduce<FDLRulesList>(
    (result, rulesList) => {
      validateRules(rulesList);
      result.rules = result.rules.concat(rulesList.rules);
      return result;
    },
    {
      apiVersion: 'fdl/v1',
      kind: 'FDLRulesList',
      rules: [],
    }
  );
}
