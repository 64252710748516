import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import PagePane from '@/app.layouts/PagePane/PagePane';
import { AvailabilityPanel } from '@/app.domains/properties/Schedule/AvailabilityPanel';
import { AuctionPanel } from '@/app.domains/properties/Schedule/AuctionPanel';
import { OpenHomesPanel } from '@/app.domains/properties/Schedule/OpenHomesPanel';
import useProperty from '@/app.hooks/useProperty';
import { useAuthCheck } from '@/app.hooks/auth/useAuthCheck';
import { PROPERTY_SCHEDULE } from '@/app.routing';
import useRouter from '@/app.hooks/useRouter';

export default function PropertyScheduleSettingsVisitsPage({ params }) {
    const propertyId = params.unit || params.property;
    const { property } = useProperty(propertyId);

    const router = useRouter();

    const { data } = useAuthCheck({
        skip: !propertyId,
        variables: {
            input: [
                {
                    operation: 'view',
                    resourceId: propertyId,
                    requestId: 'settings-view',
                    resourceType: 'scheduleSettings',
                },
                {
                    operation: 'edit',
                    resourceId: propertyId,
                    requestId: 'settings-edit',
                    resourceType: 'scheduleSettings',
                },
            ],
        },
    });

    const view = data?.authCheck?.find((item: any) => item.requestId === 'settings-view');
    const edit = data?.authCheck?.find((item: any) => item.requestId === 'settings-edit');

    const settingsView = view?.allowed;
    const settingsEdit = edit?.allowed;

    if (settingsView === false) {
        router?.push(PROPERTY_SCHEDULE(propertyId));
    }

    const disabled = property?.archived || !settingsView || !settingsEdit;

    return (
        <PagePane title={<FormattedMessage id="pages.property_schedule_settings.settings" defaultMessage="Settings" />}>
            <div className="flex flex-col space-y-6">
                <AvailabilityPanel disabled={disabled} propertyId={propertyId} />
                <OpenHomesPanel disabled={disabled} propertyId={propertyId} />
                <AuctionPanel disabled={disabled} propertyId={propertyId} />
            </div>
        </PagePane>
    );
}
