import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { ContactCalledActivity } from '@/graphql/generated/types';
import { fullName } from '@/app.utils/services/Helpers';
import { trackHandler, events } from '@/app.utils/analytics';
import { ContactLink, PropertyLink } from './elements/elements';
import ActivityCardWithAction from './ActivityCardWithAction';

export default function ContactCalledActivityCard({
    activity,
    viewAs,
}: {
    activity: ContactCalledActivity;
    viewAs: 'property' | 'contact';
}) {
    const title =
        viewAs === 'property' ? (
            <FormattedMessage
                id="interaction.called.parameterised"
                defaultMessage="Called {contact}"
                values={{
                    contact: activity.calledContact && (
                        <ContactLink
                            contact={activity.calledContact}
                            onClick={trackHandler(events.ACTIVITY_CARD_CONTACT_LINK_CLICKED, {
                                activityType: activity.__typename,
                            })}
                        />
                    ),
                }}
            />
        ) : (
            <FormattedMessage
                id="interaction.called.about.parameterised"
                defaultMessage="Called about {location}"
                values={{
                    location: activity.about && <PropertyLink property={activity.about!} />,
                }}
            />
        );

    const calledByText = (
        <FormattedMessage
            id="interaction.called_by.parameterised"
            defaultMessage="Called by {contact}"
            values={{
                contact: fullName(activity.calledBy.firstName, activity.calledBy.lastName),
            }}
        />
    );

    const subtitle =
        viewAs === 'property' ? (
            <>
                {activity.about!.attributes.location.formatted ?? (
                    <FormattedMessage id="address.no_location_defined" defaultMessage="No location defined" />
                )}
                {' — '}
                {calledByText}
            </>
        ) : (
            <>
                {`${fullName(activity?.calledContact?.firstName, activity?.calledContact?.lastName)} - `}
                {calledByText}
            </>
        );

    return (
        <ActivityCardWithAction
            icon="phone"
            title={title}
            subtitle={subtitle}
            date={activity.timestamp}
            activity={activity}
        />
    );
}
