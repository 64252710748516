import React, { RefAttributes, PropsWithoutRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { withProps } from 'recompose';
import styled from '@emotion/styled';
import { getBugsnagClient } from '@/app.config/bugsnag';

const P = styled.p`
    word-break: break-word;
`;

function Stack({ stack }) {
    const components = stack.split(' in ');

    return (
        <ul>
            {components.slice(1).map((stactEntry, idx) => {
                return <li key={idx}>{stactEntry}</li>;
            })}
        </ul>
    );
}

const ErrorFallbackComponent = ({ componentStack, error }) => {
    if (APP_ENV === 'production') {
        return null;
    }

    // @ts-ignore
    return (
        <div style={{ border: '4px solid red', padding: 10 }}>
            <h2>
                <strong>Oops! An error occurred! 🚨🤕</strong>
            </h2>
            <P>Here’s what we know… 👇👇👇</P>
            <P>
                <strong>Error:</strong> {error.toString()}
            </P>
            <P>
                <strong>Stacktrace:</strong>
                <Stack stack={componentStack} />
            </P>
        </div>
    );
};

const EnhancedErrorBoundary = withProps(() => ({
    FallbackComponent: ErrorFallbackComponent,
    onError: (error: Error) => {
        getBugsnagClient().notify(error);
    },
}))(ErrorBoundary);

export default EnhancedErrorBoundary;

export function withErrorBoundary<Props = any>(
    InnerComponent: React.ComponentType<Props>,
): React.ComponentType<PropsWithoutRef<Props> & RefAttributes<unknown>> {
    function WrapperComponent(props: Props, ref) {
        return (
            <EnhancedErrorBoundary>
                <InnerComponent {...props} ref={ref} />
            </EnhancedErrorBoundary>
        );
    }

    return React.forwardRef(WrapperComponent);
}
