import gql from 'graphql-tag';

export const GET_INTERACTED_PROPERTIES_QUERY = gql`
    query GetInteractedProperties(
        $contactId: ID!
        $afterInterestedProperties: String
        $afterNotInterestedProperties: String
        $beforeInterestedProperties: String
        $beforeNotInterestedProperties: String
    ) {
        contact(id: $contactId) {
            id
            interestedProperties: interactedProperties(
                interestLevel: "interested"
                after: $afterInterestedProperties
                before: $beforeInterestedProperties
            ) {
                nodes {
                    ...PropertyCard
                }
                pageInfo {
                    hasNextPage
                    endCursor
                    startCursor
                }
            }
            notInterestedProperties: interactedProperties(
                interestLevel: "not_interested"
                after: $afterNotInterestedProperties
                before: $beforeNotInterestedProperties
            ) {
                nodes {
                    ...PropertyCard
                }
                pageInfo {
                    hasNextPage
                    endCursor
                    startCursor
                }
            }
        }
    }
    fragment PropertyCard on Estate {
        id
        type
        negotiation
        attributes
        lastInteraction
        negotiator {
            id
            first_name: firstName
            last_name: lastName
        }
    }
`;
