import React from 'react';
import { useIntl } from 'react-intl';
import { Avatar } from '@sweepbright/uikit';
import MediaCard from '@/app.components/card/MediaCard/MediaCard';
import { fullName } from '@/app.utils/services/Helpers';

type Props = {
    entity: any;
};

const NegotiatorPickerCard: React.FC<Props> = props => {
    const { entity } = props;

    const intl = useIntl();

    const firstName = entity?.raw?.firstName || '-';
    const lastName = entity?.raw?.lastName || `${entity?.raw?.firstName ? '' : '-'}`;

    const image = entity?.raw?.photo?.data?.url || null;

    const role = entity?.raw?.role;

    return (
        <MediaCard
            title={entity.label}
            className="contact-card"
            subtitles={[
                <div key="role" className="text-muted">
                    {intl.formatMessage({ id: `forms.role.roles.${role}` })}
                </div>,
            ]}
            image={<Avatar name={fullName(firstName, lastName)} image={image} />}
        />
    );
};

export default NegotiatorPickerCard;
