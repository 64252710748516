import React from 'react';
import { FieldProps } from '@/app.hooks/useField';
import { NegotiatorSelector } from './NegotiatorSelector';

interface NegotiatorSelectorFieldProps {
    field: FieldProps<string>;
    style?: React.CSSProperties;
    officeId: string;
    clearable?: boolean;
}

const NegotiatorSelectorField: React.FunctionComponent<NegotiatorSelectorFieldProps> = ({ field, ...props }) => {
    return <NegotiatorSelector {...props} onChange={field.onChange} value={field.value} />;
};

export default NegotiatorSelectorField;
