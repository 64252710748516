// @ts-nocheck
import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormGroup } from 'react-bootstrap';
import { FormattedDate, FormattedMessage } from '@/app.utils/services/Helpers/reactIntlWrapper';

type Props = {
    image: string;
    onRemove: () => void;
    uploadedAt: string | number;
};

const UploadedLogo: React.FunctionComponent<Props> = props => {
    return (
        <FormGroup horizontal>
            <div className="c-util-flex-block c-util-flex-block-align-middle">
                <div className="c-util-flex-block-element c-spacer-right-l">
                    <img src={props.image} alt="Company Logo" width="100%" />
                </div>
                <div className="o-flex__item">
                    <div className="text-center">
                        <div className="text-muted c-spacer-bottom-s">
                            <FormattedMessage
                                values={{
                                    date: (
                                        <FormattedDate
                                            value={props.uploadedAt}
                                            month="short"
                                            year="numeric"
                                            day="numeric"
                                        />
                                    ),
                                }}
                                id="components.logo_uploaded.uploaded_at"
                                defaultMessage="Uploaded {date}"
                            />
                        </div>
                        <Button variant="ghost-danger" onClick={props.onRemove}>
                            <FormattedMessage id="logo.upload.remove" defaultMessage="Remove logo" />
                        </Button>
                    </div>
                </div>
            </div>
        </FormGroup>
    );
};

export default UploadedLogo;
