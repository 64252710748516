import React from 'react';
import { Col } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import { LoadingIndicator } from '@sweepbright/uikit';
import { useToasts } from '@sweepbright/notifications';
import useRouter from '@/app.hooks/useRouter';
import useUser from '@/app.hooks/useUser';
import FormPane from '@/app.components/forms/FormPane';
import { useUserById } from '@/app.hooks/user/useUserById';
import { SimpleUser } from '@/app.shared/members/types';
import { getCurrentUserRole } from '@/graphql/queries/users/getCurrentUserRole';
import { UPDATE_USER_TEAMS } from '@/graphql/mutations/users/updateUserTeams';
import { useUserTeams } from '@/new.domains/teams/hooks/useUserTeams';
import MembersPageBack from './MembersPageBack';
import MembersPageProfile from './MembersPageProfile';
import MembersPageTeams from './MembersPageTeams';
import MembersPageRole from './MembersPageRole';
import MembersPageDelete from './MembersPageDelete';

const MembersPage = () => {
    const intl = useIntl();
    const user = useUser();
    const router = useRouter();
    const userId = router?.params?.member || '';
    const officeId = router?.params?.office || '';

    const [tempTeams, setTempTeams] = React.useState<any[]>([]);

    const { data, loading } = useUserById(userId);
    const { data: dataCurrentUserRole } = useQuery(getCurrentUserRole);
    const [updateTeams] = useMutation(UPDATE_USER_TEAMS);

    const { addSuccess, addError } = useToasts();

    const entity = data?.user;

    // @ts-ignore
    const member: SimpleUser | undefined = entity;

    const { teams, refetch, hasNextPage, handleFetchMore } = useUserTeams(
        member?.role !== 'company-admin' ? member?.id : '',
    );

    if (loading) {
        return (
            <div className="mt-12">
                <LoadingIndicator />
            </div>
        );
    }

    if ((!entity || !userId) && !loading) {
        addError({ message: intl.formatMessage({ id: 'members.not_found' }) });

        router?.push('/members');

        return null;
    }

    const disabled =
        `${user.get('id')}` === member?.id ||
        (dataCurrentUserRole?.me?.role === 'team-admin' && member?.role === 'company-admin');

    const fullName = `${member?.firstName || ''} ${member?.lastName || ''}`;

    const title = disabled ? (
        <FormattedMessage id="me" defaultMessage="Me" />
    ) : (
        fullName.trim() || (
            <span className="text-gray-mid">
                <FormattedMessage id="forms.labels.name.empty" defaultMessage="No name set" />
            </span>
        )
    );

    const handleSubmit = () => {
        updateTeams({
            variables: {
                userId: member?.id,
                teamsIds: tempTeams.map(el => el.value),
            },
        }).then(() => {
            setTempTeams([]);

            refetch();

            addSuccess({ message: intl.formatMessage({ id: 'members.saved' }) });
        });
    };

    return (
        <>
            <Col xs={11} lg={12} style={{ width: '100%', maxHeight: '100%', overflow: 'auto' }}>
                <FormPane
                    {...({} as any)}
                    title={title}
                    backAction={<MembersPageBack />}
                    onSubmit={
                        tempTeams.length
                            ? () => {
                                  handleSubmit();
                              }
                            : undefined
                    }
                >
                    <>
                        <MembersPageProfile member={member} />

                        {entity?.role !== 'company-admin' && (
                            <MembersPageTeams
                                teams={teams}
                                tempTeams={tempTeams}
                                setTempTeams={setTempTeams}
                                hasNextPage={hasNextPage}
                                handleFetchMore={handleFetchMore}
                            />
                        )}

                        <MembersPageRole member={member} officeId={officeId} />

                        <MembersPageDelete
                            // @ts-ignore
                            member={member}
                            officeId={officeId}
                        />
                    </>
                </FormPane>
            </Col>
        </>
    );
};

export default MembersPage;
