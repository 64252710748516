import React from 'react';

const isScrollEnd = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    // @ts-ignore
    const currentScroll = event.target.scrollTop;
    const maxScroll = event.currentTarget.scrollHeight - event.currentTarget.clientHeight;

    const threshold = 10;

    const isScrollEnd = maxScroll - currentScroll <= threshold;

    return isScrollEnd;
};

export default isScrollEnd;
