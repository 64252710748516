import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { Contact } from '@/graphql/generated/types';
import useUser from '@/app.hooks/useUser';
import useOffice from '@/app.hooks/useOffice';
import useCompany from '@/app.hooks/useCompany';
import { MAX_QUERY_LENGTH } from '@/app.config/search';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import ContactCard from '@/shared/contacts/ContactCard';
import { isTeamMember } from '@/app.data';
import { DEFAULT_LOCALE } from '@sweepbright/webapp-shared/Config/intl';
import { GET_OFFICE_CONTACTS_QUERY } from '@/graphql/queries/contacts/getContacts';
import Select from '../../../app.components/selects/AdvancedSelect/Select';
import ContactSelectCreate from './ContactSelectCreate';

type Props = {
    disabled?: boolean;
    hasError?: boolean;
    excluded?: string[];
    autoFocus?: boolean;
    filterByUser?: boolean;
    initialValue?: string[];
    type?: 'lead' | 'vendor';
    propertyOfficeId?: string;
    onSelect: (contact: Contact) => void;
};

const ContactSelect: React.FC<Props> = props => {
    const { type, onSelect, autoFocus, disabled, hasError, filterByUser, excluded = [], propertyOfficeId } = props;

    const user = useUser();

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [scrolled, setScrolled] = useState(false);
    const [finished, setFinished] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [debouncedQuery, setDebouncedQuery] = useState('');

    const enableFilterByUser = useFeatureFlag('enable-contacts-filter-by-user');

    const { contacts, loading } = useContacts({
        type,
        page,
        propertyOfficeId,
        query: debouncedQuery,
        negotiatorIds: filterByUser && enableFilterByUser && isTeamMember(user) ? [user.get('id')] : undefined,
    });

    useDebounce(
        () => {
            setPage(1);
            setFinished(false);
            setDebouncedQuery(query);
        },
        500,
        [query],
    );

    useEffect(() => {
        if (scrolled && !finished) {
            setPage(prevPage => prevPage + 1);
        }
    }, [scrolled, finished]);

    useEffect(() => {
        if (!loading) {
            if (contacts?.length > 0) {
                const formattedContacts = contacts.map(edge => edge.node);

                if (page === 1) {
                    setData(formattedContacts);
                }

                if (page > 1) {
                    setData(prevData => [...(prevData || []), ...formattedContacts]);
                }
            }

            if (contacts?.length === 0 || !contacts) {
                setFinished(true);
            }
        }

        // eslint-disable-next-line
    }, [page, loading, contacts]);

    const formattedData = data
        .filter(el => !excluded?.includes(el.id))
        .map(el => {
            const fullName = [el.firstName, el.lastName].filter(Boolean).join(' ');

            return {
                card: <ContactCard contact={el} showTitleInfo />,
                contact: el,
                value: el.id,
                label: fullName,
            };
        });

    return (
        <Select
            {...({} as any)}
            loading={loading}
            disabled={disabled}
            hasError={hasError}
            autoFocus={autoFocus}
            filterOptions={false}
            testId="contact-picker"
            options={formattedData}
            onScroll={isScrollEnd => {
                setScrolled(isScrollEnd);
            }}
            onInputChange={newValue => {
                setQuery(newValue);
            }}
            maxInputLength={MAX_QUERY_LENGTH}
            optionRenderer={option => option.card}
            footerRenderer={({ closeMenu }) => {
                return (
                    <ContactSelectCreate
                        type={type}
                        onAdded={contact => {
                            onSelect(contact);
                            closeMenu();
                        }}
                    />
                );
            }}
            onChange={(value, helpers) => {
                if (value) {
                    onSelect(value.contact);
                    // eslint-disable-next-line no-unused-expressions
                    helpers?.clearSelection();
                }
            }}
            placeholder={<FormattedMessage id="search.contact_picker.placeholder" defaultMessage='Search "John Doe"' />}
            noResultsText={() => {
                return (
                    <div>
                        <FormattedMessage
                            id="search.contact_picker.no_contact_found"
                            defaultMessage="No contacts found"
                        />{' '}
                        <br />
                        {type === 'vendor' && (
                            <span className="text-muted" style={{ fontSize: 12 }}>
                                <FormattedMessage
                                    id="search.contact_picker.vendor_search_help"
                                    defaultMessage="Looking for a Vendor? Make sure they are associated with this property"
                                />
                            </span>
                        )}
                    </div>
                );
            }}
        />
    );
};

function useContacts({
    page,
    query,
    type,
    negotiatorIds,
    propertyOfficeId,
}: {
    page?: number;
    query?: string;
    type?: string;
    excluded?: string[];
    negotiatorIds?: string[];
    propertyOfficeId?: string;
}) {
    const company = useCompany();
    const locale = company.get('locale', DEFAULT_LOCALE);

    const currentOffice = useOffice();
    const officeId = currentOffice.get('id');

    const atlasSearch = useFeatureFlag('contacts-atlas-search');

    const contactsByOfficeQuery = useQuery(GET_OFFICE_CONTACTS_QUERY, {
        variables: {
            query,
            page,
            locale,
            ...(query
                ? {}
                : {
                      sortOrder: 'desc',
                      sortField: 'updated_at',
                  }),
            filters: {
                archived: false,
                negotiator_ids: negotiatorIds,
                type: type && type.toUpperCase(),
                officeId: propertyOfficeId || officeId,
            },
            useAtlasSearch: atlasSearch,
        },
        fetchPolicy: 'no-cache',
    });

    const contacts = contactsByOfficeQuery.data?.contacts.edges;
    const loading = contactsByOfficeQuery.loading;

    return { contacts, loading };
}

export default React.memo(ContactSelect);
