import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import { replace } from 'react-router-redux';
import { getUser } from '@/app.redux/selectors/UsersSelectors';
import Loading from '@/app.components/loading/Loading';
import { getIsLoggingIn } from '../../app.redux/selectors';
import { SUBSCRIPTION_PLAN } from '../routes';

const isCompanyDisabled = user => {
    return user.getIn(['company', 'data', 'disabled_at']);
};

export default connectedReduxRedirect({
    authenticatingSelector: getIsLoggingIn,
    authenticatedSelector: store => {
        const user = getUser(store);
        if (!user) {
            return true;
        }
        if (isCompanyDisabled(user)) {
            return false;
        }

        return true;
    },
    redirectPath: SUBSCRIPTION_PLAN,
    AuthenticatingComponent: Loading,
    redirectAction: replace,
    wrapperDisplayName: 'DisabledCompanyAdmin',
});
