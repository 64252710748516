import axios from 'axios';
import AuthManager from 'auth-manager';
import moment from 'moment';
import { Event } from '@/app.components/schedules/types';
import { ScheduleEventType } from '@/graphql/generated/types';

export type Visit = {
    type: 'visit';
    id: string;
    propertyId: string;
};

const client = axios.create({
    baseURL: VISITS_SERVICE_URL,
});
client.interceptors.request.use(c => {
    return {
        ...c,
        headers: {
            ...c.headers,
            authorization: `Bearer ${AuthManager.getToken().access_token}`,
        },
    };
});

export async function fetchPropertySchedule(
    key: string,
    propertyId: string,
    params: { after?: string; before?: string; query: string },
) {
    const events = await client.get(`/properties/${propertyId}/schedule`, { params }).then(res => res.data);

    return events;
}

export async function fetchContactSchedule(
    key: string,
    contactId: string,
    params: { after?: string; before?: string; query: string },
) {
    const events = await client.get(`/contacts/${contactId}/schedule`, { params }).then(res => res.data);

    return events;
}

export async function fetchAvailability(key: string, propertyId: string) {
    const availabilities = await client
        .get(`/properties/${propertyId}/availability`, {
            params: { type: 'period' },
        })
        .then(res => res.data);

    return availabilities.map(availability => availability.period);
}

export async function upsertVisitMutation(values: any) {
    if (values.id) {
        return updateVisitMutation(values.id, values);
    } else {
        return createVisitMutation(values);
    }
}

export async function createVisitMutation(values) {
    return client.post('/visits', {
        location: values.location,
        duration: parseInt(values.duration),
        negotiator_id: values.negotiatorId,
        property_id: values.propertyId,
        date_time: moment(values.date)
            .set('hour', values.hour)
            .set('minutes', values.minutes),
        description: values.message,
        attendees: values.attendees,
        title: values.title,
    });
}

export async function deleteEventMutation(event: Event) {
    if (event.type === ScheduleEventType.Visit) {
        await deleteVisitMutation(event.id);
    } else if (event.type === ScheduleEventType.Openhome) {
        await deleteOpenHomeMutation(event.propertyId, event.id);
    } else if (event.type === ScheduleEventType.Auction) {
        await deleteAuctionMutation(event.propertyId);
    }
}

export function updateVisitMutation(id: string, values) {
    return client.put('/visits/' + id, {
        location: values.location,
        duration: parseInt(values.duration),
        negotiator_id: values.negotiatorId,
        date_time: moment(values.date)
            .set('hour', values.hour)
            .set('minutes', values.minutes),
        description: values.message,
        attendees: values.attendees,
        title: values.title,
        status: values.status,
    });
}

export async function updateVisitStatusMutation(id: string, status) {
    return client.patch(`/visits/${id}`, {
        status,
    });
}

export async function createAvailabilityMutation(
    propertyId: string,
    availability: { days: string[]; start: number; end: number },
) {
    await client.post(`/properties/${propertyId}/availability`, {
        type: 'period',
        period: Object.assign({}, availability, { tzid: getBrowserTzid() }),
    });
}

export async function deleteAvailabilityMutation(propertyId: string, availabilityId: string) {
    await client.delete(`/properties/${propertyId}/availability/${availabilityId}`);
}

export function updateAvailabilityMutation(
    propertyId: string,
    availabilityId: string,
    updates: {
        start: number;
        end: number;
        days: string[];
    },
) {
    return client.put(
        `/properties/${propertyId}/availability/${availabilityId}`,
        Object.assign({}, updates, {
            tzid: getBrowserTzid(),
        }),
    );
}

export async function fetchAvailableSlots(key, propertyId, negotiatorId, date, duration: number) {
    return client
        .get(`/properties/${propertyId}/availability/slots`, {
            params: {
                negotiator_id: negotiatorId,
                duration,
                date: moment.max(moment(date), moment().add(1, 'minutes')).toISOString(),
            },
        })
        .then(res => res.data);
}

export function fetchUserSchedule(key: string, negotiatorId: string) {
    return client
        .get(`/services/cronofy/calendars/events`, {
            params: {
                from: moment().format('YYYY-MM-DD'),
                userId: negotiatorId,
            },
        })
        .then(res => res.data)
        .then(events => {
            return events.map(evt => ({
                ...evt,
                type: 'event',
                calendar: 'negotiator',
                start: moment(evt.start).toDate(),
                end: moment(evt.end).toDate(),
            }));
        });
}

export function fetchUserCalendars(key: string, accessToken: string | null, userId: string | null) {
    return client.get('/services/cronofy/calendars', { params: { accessToken, userId } }).then(res => res.data);
}

export function fetchVisitsPendingConfirmationCount(key: string, propertyId: string) {
    return client.get(`/properties/${propertyId}/schedule/pendingConfirmation`).then(res => res.data);
}

export function fetchOpenHomes(key, propertyId: string) {
    return client.get(`/properties/${propertyId}/openHomes`).then(res => res.data);
}

export function upsertOpenHomeMutation(
    propertyId: string,
    { id, start, end, date }: { id?: string; start: number; end: number; date: string },
) {
    const openHome = {
        dateTime: moment(date).set('minutes', start),
        duration: end - start,
    };

    if (!id) {
        return client.post(`/properties/${propertyId}/openHomes`, openHome).then(res => res.data);
    } else {
        return client.put(`/properties/${propertyId}/openHomes/${id}`, openHome).then(res => res.data);
    }
}

export function deleteOpenHomeMutation(propertyId: string, openHomeId: string) {
    return client.delete(`/properties/${propertyId}/openHomes/${openHomeId}`);
}

export function deleteVisitMutation(visitId: string) {
    return client.delete('/visits/' + visitId);
}

export function fetchPropertyAuction(key: string, propertyId: string) {
    return client
        .get(`properties/${propertyId}/auction`)
        .then(res => res.data)
        .catch((err: any) => {
            if (err.response?.status === 404) {
                return null;
            }
            throw err;
        });
}

export function updateAuctionMutation({
    propertyId,
    dateTime,
    duration,
}: {
    propertyId: string;
    dateTime: Date;
    duration: number;
}) {
    return client.put(`/properties/${propertyId}/auction`, {
        dateTime: dateTime.toISOString(),
        duration,
    });
}

export function deleteAuctionMutation(propertyId: string) {
    return client.delete(`/properties/${propertyId}/auction`);
}

// helper
function getBrowserTzid() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
