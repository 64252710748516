import React from 'react';

import './SideMenuBadge.scss';

type Props = {
    type: 'default' | 'info' | 'error';
    size?: 'sm' | 'md';
    value: number | string | JSX.Element;
};

const SideMenuBadge: React.FC<Props> = props => {
    const { type, size, value } = props;

    return <span className={`side-menu-badge ${type} ${size}`}>{value}</span>;
};

export default SideMenuBadge;
