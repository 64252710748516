// @ts-nocheck
import React from 'react';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { FormattedMessage } from 'react-intl-sweepbright';
import PagePane from '@/app.layouts/PagePane/PagePane';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import useCompany from '@/app.hooks/useCompany';
import { CompaniesRequests } from '@/requests';
import MoveBottlerDisconnected from '../../app.domains/settings/movebotler/MoveBotlerDisconnected';
import MoveBotlerConnected from '../../app.domains/settings/movebotler/MoveBotlerConnected';

enum ConnectionStatus {
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    LOADING = 'loading',
}

const MOVEBOTLER_CONNECTED = 'CONNECTED';
const MOVEBOTLER_DISCONNECTED = 'DISCONNECTED';

export default function MoveBotlerPage() {
    const [state, dispatch] = React.useReducer(reducer, {
        status: ConnectionStatus.LOADING,
    });
    const companyId = useCompany().get('id');

    React.useEffect(() => {
        if (state!.status === ConnectionStatus.LOADING && companyId) {
            let isMounted = true;
            new CompaniesRequests().getMoveBotlerConfig(companyId).then(config => {
                if (isMounted) {
                    if (config.status === 'disabled') {
                        dispatch({ type: MOVEBOTLER_DISCONNECTED });
                    } else if (config.status === 'enabled') {
                        dispatch({ type: MOVEBOTLER_CONNECTED, config });
                    }
                }
            });

            return () => {
                isMounted = false;
            };
        }
    }, [state!.status, companyId]);

    return (
        <LayoutColumn>
            <PagePane
                title={
                    <FormattedMessage
                        id="pages.movebolter.title"
                        defaultMessage="Boost your business with the MoveBotler"
                    />
                }
            >
                {state!.status === ConnectionStatus.LOADING ? <LoadingIndicator /> : null}
                {state!.status === 'disconnected' ? (
                    <MoveBottlerDisconnected
                        companyId={companyId}
                        onConnected={config => {
                            dispatch({ type: MOVEBOTLER_CONNECTED, config });
                        }}
                    />
                ) : null}
                {state!.status === 'connected' ? (
                    <MoveBotlerConnected
                        config={state!.config}
                        companyId={companyId}
                        onDisconnected={() => {
                            dispatch({ type: MOVEBOTLER_DISCONNECTED });
                        }}
                    />
                ) : null}
            </PagePane>
        </LayoutColumn>
    );
}

function reducer(currentState: { status: ConnectionStatus }, action) {
    switch (action.type) {
        case MOVEBOTLER_CONNECTED: {
            return {
                status: ConnectionStatus.CONNECTED,
                config: action.config,
            };
        }
        case MOVEBOTLER_DISCONNECTED: {
            return { status: ConnectionStatus.DISCONNECTED };
        }
    }
}
