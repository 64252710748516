import md5 from 'blueimp-md5';

export default class Cache {
    /**
     * Get a value from cache or compute it
     */
    static put = async (_key, callback) => {
        const key = md5(_key);

        // Look for cached value
        // eslint-disable-next-line no-undefined,eqeqeq
        if (localStorage.getItem(key) != undefined) {
            return Promise.resolve(JSON.parse(localStorage.getItem(key)));
        }

        try {
            // Else compute and cache value
            const value = await callback();
            localStorage.setItem(key, JSON.stringify(value));

            return value;
        } catch (err) {
            return null;
        }
    };
}
