// @ts-nocheck
import Immutable, { fromJS } from 'immutable';
import React from 'react';
import { Link } from 'react-router';
import * as Yup from 'yup';
import { defineMessages, FormattedMessage } from 'react-intl-sweepbright';
import Button from '@/app.components/elements/Buttons/Button';
import wrapWithPromise from '@/app.utils/services/wrapWithPromise';
import Formik from '@/app.components/forms/helpers/Formik';
import { track, events } from '@/app.utils/analytics';
import { LOGIN } from '../../../app.routing/routes';
import { InputForFormik } from '../../../app.components/forms/Input/Input';
import Toolbar from '../../../app.components/elements/Toolbar/Toolbar';
import OnboardingForm from './OnboardingForm';

const LOGIN_CODE_LENGTH = 6;

defineMessages({
    'forms.login.success_message': {
        id: 'forms.login.success_message',
        defaultMessage: 'An email was sent to you with your activation code, check your inbox',
    },
});

const schema = Yup.object({
    code: Yup.string(LOGIN_CODE_LENGTH)
        .min(LOGIN_CODE_LENGTH)
        .required(),
});

type Props = {
    validateCode: () => void;
    resetMessages: () => void;
    messages: Immutable.Set<any>;
};

const LoginSentForm = ({ validateCode, resetMessages, messages }: Props) => {
    React.useEffect(() => {
        return resetMessages;
    }, []);

    const onSubmit = ({ code }) => {
        return wrapWithPromise(validateCode)({ code });
    };

    messages = messages.add(
        Immutable.fromJS({
            style: 'success',
            message: 'forms.login.success_message',
        }),
    );

    return (
        <Formik
            initialValues={{
                code: '',
                _error: '',
            }}
            initialErrors={{
                code: 'required',
            }}
            onSubmit={async (values, actions) => {
                try {
                    await onSubmit(values);
                    track(events.USER_LOGIN_CODE_FORM_SUBMITTED);
                } catch (fieldErrors) {
                    actions.setErrors(fieldErrors);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            validationSchema={schema}
        >
            {props => {
                if (props.errors._error) {
                    messages = messages.add(fromJS({ style: 'danger', message: props.errors._error }));
                }

                return (
                    <OnboardingForm handleSubmit={props.handleSubmit} messages={messages}>
                        <InputForFormik
                            name="code"
                            type="text"
                            label={<FormattedMessage id="forms.sent.code.label" defaultMessage="Authorization code" />}
                            help={
                                <FormattedMessage
                                    id="forms.sent.code.help"
                                    defaultMessage="If you wish to enter the code manually, enter it here"
                                />
                            }
                            error={null}
                        />
                        <Toolbar inPage={false} className="onboarding-toolbar">
                            <Toolbar.Left>
                                <Button type="submit" disabled={Boolean(props.errors.code || props.isSubmitting)}>
                                    {props.isSubmitting ? (
                                        <FormattedMessage
                                            id="forms.login.cta.submitting"
                                            defaultMessage="Submitting..."
                                            tagName={React.Fragment}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="forms.sent.cta"
                                            defaultMessage="Sign in"
                                            tagName={React.Fragment}
                                        />
                                    )}
                                </Button>
                            </Toolbar.Left>
                            <Toolbar.Right>
                                <p className="onboarding-note">
                                    <Link to={LOGIN}>
                                        <FormattedMessage
                                            id="forms.login.different_email"
                                            defaultMessage="Login with another e-mail address"
                                        />
                                    </Link>
                                </p>
                            </Toolbar.Right>
                        </Toolbar>
                    </OnboardingForm>
                );
            }}
        </Formik>
    );
};

export default LoginSentForm;
