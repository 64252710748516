import gql from 'graphql-tag';

export const GET_ESTATES_OPTIONS_QUERY = gql`
    query GetEstatesOptions {
        estatesOptions {
            sorts {
                key
            }
        }
    }
`;
