// @ts-nocheck
import AuthManager from 'auth-manager';
import has from 'lodash/has';
import logger from '@/app.utils/services/logger';

export abstract class AuthProvider {
    getToken(): string | null {
        throw new Error('method not implemented');
    }

    readonly isNullAuthProvider?: boolean = false;
}

export class ClientAuthProvider extends AuthProvider {
    getToken() {
        let token;

        // the external pages (report, etc) are passing the token this way
        // so we should try to retrieve it like this first
        if (has(window, '__SERVER_DATA__.token')) {
            token = window.__SERVER_DATA__.token;
        } else {
            token = AuthManager.getToken();
        }

        if (token) {
            return token.access_token;
        }

        return null;
    }
}

class NullAuthProvider extends AuthProvider {
    isNullAuthProvider = true;
    getToken() {
        logger.error('[NullAuthProvider]: getToken() is not implemented');

        return null;
    }
}

export function getAuthProvider(): AuthProvider {
    return __CLIENT__ ? new ClientAuthProvider() : new NullAuthProvider();
}
