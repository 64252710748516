// @ts-nocheck
import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl-sweepbright';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { ItemsList } from '@/app.domains/contacts/contacts-form/elements/PropertiesList';
import { PropertyCardFragment } from '@/graphql/fragments/propertyCard';
import { updateConnection } from '@/graphql/utils';
import { Estate } from '@/graphql/generated/types';
import PagePane from '@/app.layouts/PagePane/PagePane';
import EmptyAlert from '../../../app.components/empty/EmptyAlert';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import PropertyCard, { adaptProperty } from '../../../app.domains/properties/PropertyCard';
import AddPropertyForm from '../../../app.shared/properties/AddPropertyForm';
import { PROPERTY } from '../../../app.routing/routes';
import withModals, { HasModalProps } from '../../../app.utils/Decorators/withModals';

type Props = {
    addProperty: () => void;
    assignVendorEstate: (contactId: string, propertyId: string) => void;
    params: {
        contact: string;
    };
};

const VENDOR_ESTATES = gql`
    query VendorEstates($vendorId: ID!, $page: Int, $limit: Int) {
        contact(id: $vendorId) {
            id
            ... on Vendor {
                estates(page: $page, limit: $limit) {
                    totalCount
                    pageInfo {
                        currentPage
                        hasNextPage
                    }
                    nodes {
                        id
                        ...PropertyCardFragment
                    }
                }
            }
        }
    }
    ${PropertyCardFragment}
`;

const ASSIGN_ESTATE_TO_VENDOR = gql`
    mutation AssignEstateToVendor($input: AssignVendorsToEstateInput!) {
        assignVendorsToEstate(input: $input) {
            estate {
                id
                ...PropertyCardFragment
            }
        }
    }
    ${PropertyCardFragment}
`;

function useVendorEstates(vendorId: string) {
    const [assignVendorsMutation] = useMutation(ASSIGN_ESTATE_TO_VENDOR, {});

    const { data, loading, fetchMore } = useQuery(VENDOR_ESTATES, {
        variables: {
            vendorId,
            limit: 20,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    });

    const pageInfo = data?.contact?.estates?.pageInfo ?? { currentPage: 1, hasNextPage: false };

    const onFetchMore = React.useCallback(() => {
        if (pageInfo.hasNextPage && !loading) {
            return fetchMore({
                variables: {
                    page: pageInfo.currentPage + 1,
                },
                updateQuery: updateConnection('contact.estates'),
            });
        }

        return null;
    }, [pageInfo, loading]);

    const estates = data?.contact.estates?.nodes ?? [];

    const assignEstate = estateId => {
        return assignVendorsMutation({
            variables: {
                input: {
                    estateId: estateId,
                    vendorIds: [vendorId],
                },
            },
            update: (cache, { data: estateData }) => {
                // add the Estate to the list of estates for that vendor
                data.contact.estates.nodes.unshift(estateData.assignVendorsToEstate.estate);

                return cache.writeQuery({
                    query: VENDOR_ESTATES,
                    variables: {
                        vendorId,
                        limit: 20,
                    },
                    data: data,
                });
            },
        });
    };

    return { pageInfo, estates, loading, onFetchMore, assignEstate };
}

function VendorEstates(props: Props & HasModalProps) {
    const vendorId = props.params.contact;

    const { estates, pageInfo, loading, onFetchMore, assignEstate } = useVendorEstates(vendorId);

    const handleSubmit = ({ estate }: { estate: Estate }) => {
        return assignEstate(estate.id);
    };

    return (
        <PagePane
            title={<FormattedMessage id="properties.contact_properties_title" defaultMessage="View Properties" />}
        >
            <div>
                <FormPanel
                    title={<FormattedMessage id="forms.contacts.properties.title" defaultMessage="Properties" />}
                    action={<FormattedMessage id="forms.contacts.properties.cta" defaultMessage="Add Property" />}
                    onAction={() => props.open('add-property')}
                >
                    <ItemsList
                        hasMore={pageInfo.hasNextPage}
                        onFetchMore={onFetchMore}
                        items={estates}
                        getItemKey={estate => estate.id}
                        renderItem={estate => {
                            return (
                                <ErrorBoundary key={estate.id}>
                                    <PropertyCard property={adaptProperty(estate)} to={PROPERTY(estate.id)} />
                                </ErrorBoundary>
                            );
                        }}
                        loading={loading}
                        placeholder={
                            <EmptyAlert
                                body={
                                    <FormattedMessage
                                        id="forms.contacts.properties.empty"
                                        defaultMessage="No properties"
                                    />
                                }
                            />
                        }
                    />
                </FormPanel>
                {props.modalWithPromise('add-property', <AddPropertyForm />, handleSubmit, {})}
            </div>
        </PagePane>
    );
}

export default withModals(VendorEstates);
