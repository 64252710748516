import { ok, strictEqual } from 'assert';
import { defineMessages } from 'react-intl';
import { rulesMatcher } from '@/app.utils/services/Fields/rulesMatcher';
import { CanGetVisibilityContext, PropertyFieldVisibilityContext } from './FieldVisibilityContext';

export function getVisibleAmenities(repoOrContext: CanGetVisibilityContext | PropertyFieldVisibilityContext): string[] {
    const ctx = 'getVisibilityContext' in repoOrContext ? repoOrContext.getVisibilityContext() : repoOrContext;
    const amenitySchema = rulesMatcher.getSchema(ctx, 'attributes.amenities');
    // amenitySchema should have a structure like the following:
    //
    //   type: array
    //   items:
    //     type: string
    //     enum:
    //       - attic
    //       - balcony
    //       - basement
    //       - ...
    //
    // The following code verifies its validity and guarantees to return an array of strings or otherwise fail
    ok(amenitySchema);
    strictEqual(amenitySchema.type, 'array');
    ok(amenitySchema.items);
    if (amenitySchema.items === true) {
        throw new TypeError('Error parsing amenities schema');
    }
    ok(!Array.isArray(amenitySchema.items));
    const enumItems = amenitySchema.items.enum;
    ok(enumItems);
    const items = enumItems;
    for (const item of items) {
        ok(typeof item === 'string');
    }

    return items as string[];
}

export const amenitiesMessages = defineMessages({
    terrace: {
        id: `property.fields.structure.terrace`,
        defaultMessage: 'Terrace',
    },
    pool: {
        id: `property.fields.structure.pool`,
        defaultMessage: 'Pool',
    },
    attic: {
        id: `property.fields.structure.attic`,
        defaultMessage: 'Attic',
    },
    basement: {
        id: `property.fields.structure.basement`,
        defaultMessage: 'Basement',
    },
    garden: {
        id: `property.fields.structure.garden`,
        defaultMessage: 'Garden',
    },
    parking: {
        id: `property.fields.structure.parking`,
        defaultMessage: 'Parking',
    },
    lift: {
        id: `property.fields.structure.lift`,
        defaultMessage: 'Lift',
    },
    guesthouse: {
        id: `property.fields.structure.guesthouse`,
        defaultMessage: 'Guesthouse',
    },
    electrical_gate: {
        id: `property.fields.structure.electrical_gate`,
        defaultMessage: 'Electrical Gate',
    },
    manual_gate: {
        id: `property.fields.structure.manual_gate`,
        defaultMessage: 'Manual Gate',
    },
    fence: {
        id: `property.fields.structure.fence`,
        defaultMessage: 'Fence',
    },
    remote_control: {
        id: `property.fields.structure.remote_control`,
        defaultMessage: 'Remote Control',
    },
    key_card: {
        id: `property.fields.structure.key_card`,
        defaultMessage: 'Key Card',
    },
    code: {
        id: `property.fields.structure.code`,
        defaultMessage: 'Code',
    },
    climate_control: {
        id: `property.fields.structure.climate_control`,
        defaultMessage: 'Climate Control',
    },
    water_access: {
        id: `property.fields.structure.water_access`,
        defaultMessage: 'Water Access',
    },
    fenced: {
        id: `property.fields.structure.fenced`,
        defaultMessage: 'Fenced',
    },
    utilities_access: {
        id: `property.fields.structure.utilities_access`,
        defaultMessage: 'Utilities Access',
    },
    sewer_access: {
        id: `property.fields.structure.sewer_access`,
        defaultMessage: 'Sewer Access',
    },
    drainage: {
        id: `property.fields.structure.drainage`,
        defaultMessage: 'Drainage',
    },
    road_access: {
        id: `property.fields.structure.road_access`,
        defaultMessage: 'Road Access',
    },
    server_room: {
        id: `property.fields.structure.server_room`,
        defaultMessage: 'Server Room',
    },
    storage_space: {
        id: `property.fields.structure.storage_space`,
        defaultMessage: 'Storage Space',
    },
    print_and_copy_area: {
        id: `property.fields.structure.print_and_copy_area`,
        defaultMessage: 'Print & Copy Area',
    },
    reception_area: {
        id: `property.fields.structure.reception_area`,
        defaultMessage: 'Reception Area',
    },
    waiting_area: {
        id: `property.fields.structure.waiting_area`,
        defaultMessage: 'Waiting Area',
    },
    cooling_room: {
        id: `property.fields.structure.cooling_room`,
        defaultMessage: 'Cooling Room',
    },
    display_window: {
        id: `property.fields.structure.display_window`,
        defaultMessage: 'Display Window',
    },
    balcony: {
        id: `property.fields.structure.balcony`,
        defaultMessage: 'Balcony',
    },
    interior_parking: {
        id: `property.fields.structure.interior_parking`,
        defaultMessage: 'Interior Parking',
    },
    exterior_parking: {
        id: `property.fields.structure.exterior_parking`,
        defaultMessage: 'Exterior Parking',
    },
});
