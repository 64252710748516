import React from 'react';
import { fromJS } from 'immutable';
import { useIntl } from 'react-intl';
import { Checkbox } from '@sweepbright/uikit';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { Estate } from '@/graphql/generated/types';
import EstateRepository, { propertyCoverImage } from '@/app.utils/services/Repositories/EstateRepository';
import PropertyTitle from '@/app.domains/properties/PropertyTitle';
import { StatusChip } from '@/app.domains/properties/StatusForm';
import useCompany from '@/app.hooks/useCompany';
import { LastActivity } from '@/app.shared/properties/LastActivity';
import { FormattedLocation } from '@/app.components/localize/FormattedLocation';
import PropertyImage from '@/new.domains/properties/PropertyShow/PropertyImage';
import PropertyNegotiator from '@/app.domains/properties/PropertyNegotiator';
import MediaCard from '@/app.components/card/MediaCard/MediaCard';
import useProperty from '@/app.hooks/useProperty';
import { usePropertyReference } from '@/new.domains/properties/hooks/usePropertyReference';
import PropertyCardInfo from './PropertyCardInfo';
import PropertyCardAssignee from './PropertyCardAssignee';

type Props = {
    to?: string;
    testId?: string;
    actions?: any[];
    property?: Estate;
    disabled?: boolean;
    selected?: boolean;
    selectable?: boolean;
    onSelect?: () => void;
    showInteraction?: boolean;
    onToggleSelect?: () => void;
    notPassSelectable?: boolean;
    onClick?: (evt?: React.MouseEvent<HTMLElement>) => void;
};

const PropertyCard: React.FC<Props> = props => {
    const { disabled, property: initProperty, selected, onToggleSelect } = props;

    const intl = useIntl();

    const { property: propertyData, error: propertyError } = useProperty(
        initProperty?.updatedAt ? undefined : initProperty?.id,
    );

    const { data: reference } = usePropertyReference(propertyError ? initProperty?.id : undefined);

    const property = (initProperty?.updatedAt ? initProperty : propertyData) || undefined;

    const company = useCompany();
    const repository = new EstateRepository(property, company);
    const image = propertyCoverImage(property, 'thumbnail');

    const checkbox = property?.updatedAt ? (
        <div
            style={{ padding: 4 }}
            onClick={evt => {
                evt.stopPropagation();
                evt.preventDefault();
                // eslint-disable-next-line no-unused-expressions
                onToggleSelect?.();
            }}
        >
            <Checkbox value={property.id} checked={selected} size="lg" disabled={disabled} />
        </div>
    ) : (
        undefined
    );

    return (
        <MediaCard
            useLink
            {...props}
            title={
                property?.updatedAt ? (
                    <div className="truncate">
                        <PropertyTitle property={fromJS(property)} />
                    </div>
                ) : (
                    <div className="truncate flex-1">
                        {intl.formatMessage({ id: 'general.unknown' })}
                        {propertyError && reference?.propertyReference?.team?.name && (
                            <span className="text-muted ml-1">- {reference?.propertyReference?.team?.name}</span>
                        )}
                    </div>
                )
            }
            image={
                <div className="flex items-center h-full">
                    {props.selectable && checkbox}
                    <PropertyImage size="medium" src={image} type={repository.getType() || 'house'} />
                </div>
            }
            subtitles={
                property?.updatedAt
                    ? [
                          <PropertyCardInfo
                              key="line_1"
                              // @ts-ignore
                              property={property}
                          />,
                          <FormattedLocation key="line_2" location={property?.attributes?.location} />,
                          <LastActivity key="line_3" property={fromJS(property)} />,
                          <ErrorBoundary key="line_4">
                              <div className="flex items-center space-x-2">
                                  <StatusChip
                                      status={property?.attributes?.status}
                                      // @ts-ignore
                                      negotiation={property?.negotiation || 'let'}
                                  />
                                  <PropertyNegotiator propertyId={property?.id || ''} />
                              </div>
                          </ErrorBoundary>,
                      ]
                    : [<PropertyCardAssignee key="assignee" propertyReference={reference?.propertyReference} />]
            }
            {...(props.notPassSelectable ? { selected: undefined, selectable: undefined } : {})}
        />
    );
};

export default React.memo(PropertyCard);
