import React from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import { injectIntl, InjectedIntl } from 'react-intl-sweepbright';
import useCompanySetting from '@/app.hooks/useCompanySetting';
import countries from './country-calling-codes.json';

export default injectIntl(function PhoneInput({
    id,
    name,
    value,
    onChange,
    intl,
    placeholder,
}: {
    id: string;
    name: string;
    value: string;
    onChange: (value: string) => void;
    intl: InjectedIntl;
    placeholder: string;
}) {
    const defaultCountry = useCompanySetting('signup_country') ?? 'BE';

    const parsedPhone = parsePhoneNumber(value, defaultCountry);

    return (
        <div className="relative">
            {parsedPhone?.country && value.length > 4 && (
                <div className="absolute left-0 px-2 top-0 bottom-0 flex items-center w-10 focus-within:shadow-outline rounded-sm">
                    <select
                        data-testid="country-select"
                        value={parsedPhone?.country}
                        onChange={e => {
                            const newValue = e.target.value;

                            const prefix = countries.find(country => country.code === newValue)?.callingCode;
                            const phone = parsedPhone?.nationalNumber;

                            onChange(`+${prefix}${phone}`);
                        }}
                        className="absolute h-full w-full left-0 opacity-0"
                    >
                        {countries.map(country => {
                            return (
                                <option key={`${country.code}:${country.callingCode}`} value={country.code}>
                                    {intl.formatMessage({
                                        id: `country.labels.${country.code}`,
                                        defaultMessage: country.name,
                                    })}{' '}
                                    (+{country.callingCode})
                                </option>
                            );
                        })}
                    </select>
                    <img
                        src={`https://cdn.jsdelivr.net/gh/madebybowtie/FlagKit@2.2/Assets/SVG/${parsedPhone?.country}.svg`}
                        role="img"
                        alt={`${parsedPhone?.country} Flag`}
                        height="24"
                        width="24"
                        className="pointer-events-none"
                        data-country={parsedPhone?.country}
                        data-testid="country-flag-image"
                    />
                </div>
            )}
            <input
                id={id}
                type="tel"
                name={name}
                autoComplete="off"
                value={value || ''}
                placeholder={placeholder}
                className={`form-control${parsedPhone?.country && value.length > 4 ? ' pl-12' : ''}`}
                onChange={e => {
                    const newValue = e.target.value;

                    const regex = /[^0-9\+\(\)]/g;

                    const formattedNewValue = newValue
                        .replace(regex, '')
                        .replace(/\(+/g, '(')
                        .replace(/\)+/g, ')')
                        .replace(/\++/g, '+')
                        .trim();

                    const newParsedValue = parsePhoneNumber(newValue, defaultCountry)?.number;

                    onChange(newValue.length > 4 && newParsedValue ? `${newParsedValue}` : formattedNewValue);
                }}
            />
        </div>
    );
});
