import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';

export const visitDurationOptions = [
    {
        value: 10,
        label: <FormattedMessage id="duration.label.min" defaultMessage="10 min" values={{ duration: 10 }} />,
    },
    {
        value: 15,
        label: <FormattedMessage id="duration.label.min" defaultMessage="15 min" values={{ duration: 15 }} />,
    },
    {
        value: 20,
        label: <FormattedMessage id="duration.label.min" defaultMessage="20 min" values={{ duration: 20 }} />,
    },
    {
        value: 30,
        label: <FormattedMessage id="duration.label.min" defaultMessage="30 min" values={{ duration: 30 }} />,
    },
    {
        value: 45,
        label: <FormattedMessage id="duration.label.min" defaultMessage="45 min" values={{ duration: 45 }} />,
    },
    {
        value: 60,
        label: <FormattedMessage id="duration.label.hours" defaultMessage="1 hour" values={{ duration: 1 }} />,
    },
    {
        value: 90,
        label: <FormattedMessage id="duration.label.hour_and_half" defaultMessage="1 h 30 min" />,
    },
    {
        value: 120,
        label: <FormattedMessage id="duration.label.hours" defaultMessage="2 hours" values={{ duration: 2 }} />,
    },
    {
        value: 180,
        label: <FormattedMessage id="duration.label.hours" defaultMessage="3 hours" values={{ duration: 3 }} />,
    },
    {
        value: 240,
        label: <FormattedMessage id="duration.label.hours" defaultMessage="4 hours" values={{ duration: 4 }} />,
    },
];
