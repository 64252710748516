// @ts-nocheck
import get from 'lodash/get';
import set from 'lodash/set';
import keyBy from 'lodash/keyBy';
import updateWith from 'lodash/updateWith';
import cloneDeep from 'lodash/cloneDeep';
import AuthManager from 'auth-manager';
import isPlainObject from 'lodash/isPlainObject';
import PublicationServiceRequests from '@/requests/PublicationServiceRequests';

export const validateEstateFields = (channelId: string, estate: Record<string, any>) => {
    const request = new PublicationServiceRequests();
    request.initialize({
        context: {
            userInfo: {
                access_token: AuthManager.getToken().access_token,
            },
        },
    });

    return attributes => {
        let updatedEstate = cloneDeep({ ...estate, attributes });

        if (estate.isProject) {
            updatedEstate.internal_type = 'project';
        }

        updateWith(updatedEstate, 'attributes.images', (images = []) => {
            return keyBy(
                images.filter(image => !image.private),
                'id',
            );
        });

        return request.validateEstate(channelId, updatedEstate).then(invalidFields => {
            const errors = convertMissingFieldsIntoErrors(invalidFields);

            return errors;
        });
    };
};

export function convertMissingFieldsIntoErrors({ errors = [] } = {}) {
    const prefix = '.attributes.';

    return errors
        .filter(error => error.path.startsWith('.attributes.'))
        .reduce((acc, errorDescriptor) => {
            set(acc, errorDescriptor.path.substring(prefix.length), errorDescriptor.errorMessage);

            return acc;
        }, {});
}

export const transformFields = (valueObject, setFieldValue, prefixPath, errors: Record<string, any> = {}) => {
    return Object.keys(valueObject).reduce((acc, fieldName) => {
        const value = valueObject[fieldName];

        if (typeof value === 'object' && value !== null) {
            if (isPlainObject(value)) {
                acc[fieldName] = transformFields(value, setFieldValue, `${prefixPath}.${fieldName}`, errors);
            } else {
                acc[fieldName] = { value };
            }
        } else {
            acc[fieldName] = {
                value: valueObject[fieldName],
                onChange: evtOrValue => {
                    setFieldValue(`${prefixPath}.${fieldName}`, getEventValue(evtOrValue));
                },
                touched: true,
                error: get(errors, `${prefixPath}.${fieldName}`),
                name: `${prefixPath}.${fieldName}`,
            };
        }

        return acc;
    }, {});
};

export function getEventValue(evtOrValue) {
    if (evtOrValue?.target) {
        evtOrValue = evtOrValue.target.value;
    }

    return evtOrValue;
}
