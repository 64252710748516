import React from 'react';
import gql from 'graphql-tag';
import { Waypoint } from 'react-waypoint';
import { Button, LoadingIndicator } from '@sweepbright/uikit';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormattedMessage, FormattedPlural } from 'react-intl-sweepbright';
import { useToasts } from '@sweepbright/notifications';
import OfficeSelector from '@/app.domains/company/elements/OfficeSelector';
import BuyerLeadsTable from '@/app.components/table/BuyerLeadsTable/BuyerLeadsTable';
import { updateConnection } from '@/graphql/utils';
import { SearchBar } from '@/app.components/forms/Search/AdvancedSearch';
import ErrorBoundary, { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import { ARCHIVE_LEADS_MUTATION } from '@/graphql/mutations/companies/archiveLeads';
import { ASSIGN_LEADS_MUTATION } from '@/graphql/mutations/office/assignLeadsToOffice';
import { getBugsnagClient } from '@/app.config/bugsnag';
import FormPane from '../../app.components/forms/FormPane';
import EmptyAlert from '../../app.components/empty/EmptyAlert';

export const GET_BUYER_LEADS_QUERY = gql`
    query GetBuyerLeads($query: String, $page: Int, $sortField: String, $sortOrder: String) {
        me {
            id
            company {
                id
                leads(type: LEAD, query: $query, page: $page, sortField: $sortField, sortOrder: $sortOrder) {
                    nodes {
                        id
                        firstName
                        lastName
                        email
                        phone
                        createdAt
                        contactRequests {
                            nodes {
                                id
                                message
                                createdAt
                                source
                                preference {
                                    id
                                    type
                                    condition
                                    negotiation
                                    amenities
                                    budget {
                                        minPrice
                                        maxPrice
                                    }
                                    ... on BuyerPreferenceForApartment {
                                        minBedrooms
                                    }
                                    ... on BuyerPreferenceForHouse {
                                        minBedrooms
                                    }
                                    locations {
                                        nodes {
                                            id
                                            type
                                            name
                                            ... on PostalCodesLocationPreference {
                                                data {
                                                    country
                                                    postalCodes
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    pageInfo {
                        currentPage
                        hasNextPage
                    }
                    totalCount
                }
            }
        }
    }
`;

function useUnassignedLeads(searchSettings: { sort_field: string; query: string; sort_order: string }) {
    const variables = {
        query: searchSettings.query,
        sortField: searchSettings.sort_field,
        sortOrder: searchSettings.sort_order,
    };
    const { data, loading, fetchMore } = useQuery(GET_BUYER_LEADS_QUERY, {
        variables,
        notifyOnNetworkStatusChange: true,
        errorPolicy: 'all',
        onError(error) {
            getBugsnagClient().notify(error);
        },
    });

    const [archive, { loading: archiving }] = useMutation(ARCHIVE_LEADS_MUTATION, {
        refetchQueries: [{ query: GET_BUYER_LEADS_QUERY, variables }],
        awaitRefetchQueries: true,
    });

    const [assignLeads, { loading: assigning }] = useMutation(ASSIGN_LEADS_MUTATION, {
        refetchQueries: [{ query: GET_BUYER_LEADS_QUERY, variables }],
        awaitRefetchQueries: true,
    });

    const leads =
        data?.me.company.leads?.nodes.flatMap(node => {
            const { contactRequests, ...contact } = node;

            return contactRequests?.nodes.map(contactRequest => {
                return {
                    ...contact,
                    contactRequest,
                };
            });
        }) ?? [];

    const pageInfo = data?.me.company.leads?.pageInfo ?? {};

    const handleFetchMore = async () => {
        if (!loading && pageInfo.currentPage) {
            await fetchMore({
                variables: {
                    page: pageInfo.currentPage + 1,
                },
                updateQuery: updateConnection('me.company.leads'),
            });
        }
    };

    return { loading, leads, pageInfo, handleFetchMore, archive, archiving, assignLeads, assigning };
}

function UnassignedLeads() {
    const [selectedOffice, setSelectedOffice] = React.useState(null);
    const [selectedLeads, setSelectedLeads] = React.useState<string[]>([]);
    const { addSuccess, addError } = useToasts();
    const [searchSettings, setSearchSettings] = React.useState({
        query: '',
        sort_field: 'updated_at',
        sort_order: 'desc',
    });

    const {
        loading,
        leads,
        pageInfo,
        handleFetchMore,
        archive,
        archiving,
        assignLeads,
        assigning,
    } = useUnassignedLeads(searchSettings);

    const handleAssignLeads = async () => {
        if (selectedOffice) {
            try {
                await assignLeads({ variables: { officeId: selectedOffice, leadIds: selectedLeads } });
                addSuccess({
                    message: (
                        <FormattedPlural
                            value={selectedLeads.length}
                            one={
                                <FormattedMessage
                                    id="forms.leads.success.assigned-one"
                                    defaultMessage="Lead Assigned"
                                />
                            }
                            other={
                                <FormattedMessage
                                    id="forms.leads.success.assigned-other"
                                    defaultMessage="Leads Assigned"
                                />
                            }
                        />
                    ),
                });
            } catch (err) {
                getBugsnagClient().notify(err);
                addError({
                    message: (
                        <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />
                    ),
                });
            } finally {
                setSelectedLeads([]);
            }
        }
    };

    const handleArchiveLeads = () => {
        archive({
            variables: {
                input: {
                    leadIds: selectedLeads,
                },
            },
        })
            .then(
                () => {
                    addSuccess({
                        message: <FormattedMessage id="leads_archived" defaultMessage="Leads archived" />,
                    });
                },
                err => {
                    getBugsnagClient().notify(err);
                    addError({
                        message: (
                            <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />
                        ),
                    });
                },
            )
            .finally(() => {
                setSelectedLeads([]);
            });
    };

    return (
        <LayoutContainer flex>
            <LayoutColumn>
                <ErrorBoundary>
                    <FormPane
                        {...({} as any)}
                        title={<FormattedMessage id="forms.leads.title" defaultMessage="Buyer Leads" />}
                        onSubmit={handleAssignLeads}
                        action={
                            !assigning ? (
                                <FormattedMessage id="assignments.assign_multiple" defaultMessage="Assign Selected" />
                            ) : (
                                <FormattedMessage id="assignments.state.assigning" defaultMessage="Assigning..." />
                            )
                        }
                        disabled={!selectedOffice || !selectedLeads.length || archiving || assigning}
                        actions={[
                            <Button
                                key="archive_action"
                                variant="ghost-danger"
                                disabled={!selectedLeads.length || archiving}
                                onClick={handleArchiveLeads}
                            >
                                {archiving ? (
                                    <FormattedMessage
                                        id="modals.confirmation.actions.archiving"
                                        defaultMessage="Archiving..."
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="modals.confirmation.actions.archive"
                                        defaultMessage="Archive"
                                    />
                                )}
                            </Button>,
                            <OfficeSelector
                                key="office_selector_action"
                                value={selectedOffice}
                                // @ts-ignore
                                onChange={setSelectedOffice}
                            />,
                        ]}
                        size="full"
                    >
                        <div className="h-full overflow-hidden flex flex-col absolute bottom-0 justify-start top-0 left-0 right-0">
                            <div className="p-1">
                                <SearchBar
                                    onSubmit={setSearchSettings}
                                    settings={searchSettings}
                                    sortOptions={[
                                        {
                                            value: 'updated_at',
                                            label: (
                                                <FormattedMessage
                                                    id="search.sorting.updated_at"
                                                    defaultMessage="Updated at"
                                                />
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                            <div className="overflow-y-scroll mt-4">
                                {leads.length > 0 && (
                                    <BuyerLeadsTable
                                        buyers={leads}
                                        selectedBuyers={selectedLeads}
                                        onChangeSelection={setSelectedLeads}
                                    />
                                )}
                                {!loading && pageInfo.hasNextPage && <Waypoint onEnter={handleFetchMore} />}
                                {(pageInfo.hasNextPage || loading) && <LoadingIndicator />}
                            </div>
                            {!loading && leads.length === 0 ? (
                                <EmptyAlert
                                    body={
                                        <FormattedMessage
                                            id="forms.leads.assign.empty"
                                            defaultMessage="Sorry, no leads yet"
                                        />
                                    }
                                    icon="face-03"
                                />
                            ) : null}
                        </div>
                    </FormPane>
                </ErrorBoundary>
            </LayoutColumn>
        </LayoutContainer>
    );
}

export default withErrorBoundary(UnassignedLeads);
