// @ts-nocheck
import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '../../forms/FormPanel/FormPanel';
import Input from '../../forms/Input/Input';

import PortalConfigModalBody from './PortalConfigModalBody';

const validationSchema = Yup.object().shape({
    enabled: Yup.boolean()
        .oneOf([true])
        .required(),
    name: Yup.string()
        .max(40, 'channels.validation.name_too_long')
        .required('channels.validation.name_required'),
    accessToken: Yup.string()
        .max(40)
        .required('general.validation.field_required'),
});

function Ubiflow({ onSubmit, editing, initialValues, ...props }) {
    const { values, handleSubmit, handleChange, handleBlur, touched, submitCount, errors } = useFormik({
        initialValues: {
            enabled: !!editing,
            accessToken: initialValues.credentials?.accessToken ?? '',
            ...initialValues,
        },
        validationSchema,
        onSubmit,
    });

    const submitted = submitCount > 0;
    const isTouched = touched || submitted;

    return (
        <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
            <FormPanel>
                <Input
                    type="checkbox"
                    label={
                        <FormattedMessage
                            id="forms.portal.enabled"
                            defaultMessage="I have a {portal} account"
                            values={{ portal: props.portal.name }}
                        />
                    }
                    name="enabled"
                    checked={values.enabled}
                    onChange={handleChange}
                    disabled={editing}
                />

                <Input
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={isTouched}
                    error={errors.name}
                    label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                    help={
                        <FormattedMessage
                            id="channels.name.description"
                            defaultMessage="This is the name you will see in the Publish section of SweepBright."
                        />
                    }
                />
                <Input
                    name="accessToken"
                    disabled={editing}
                    value={values.accessToken}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={isTouched}
                    error={errors.accessToken}
                    label={<FormattedMessage id="channels.name.ubiflow.advertiser" defaultMessage="Advertiser ID" />}
                />
            </FormPanel>
        </PortalConfigModalBody>
    );
}

Ubiflow.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
            credentials {
                accessToken
            }
        }
    `,
};

export default Ubiflow;
