import gql from 'graphql-tag';

export const GET_LEGAL_ENTITIES = gql`
    query getLegalEntities($officeId: ID!, $page: Int, $limit: Int) {
        office(id: $officeId) {
            id
            legalEntities(page: $page, limit: $limit) {
                totalCount
                pageInfo {
                    currentPage
                    hasNextPage
                }
                edges {
                    node {
                        name
                        id
                        office_id
                    }
                }
            }
        }
    }
`;
