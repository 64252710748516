import gql from 'graphql-tag';
import locationPreference from '@/graphql/fragments/locationPreference';

export const ADD_LOCATION_TEMPLATE = gql`
    mutation addLocationTemplate(
        $officeId: ID!
        $preference: LocationPreferenceInput!
        $geoJSONData: GeoJSONDataInput
        $postalCodesData: PostalCodesDataInput
        $includeLocationData: Boolean! = true
    ) {
        addLocationPreferenceTemplate(
            officeId: $officeId
            preference: $preference
            geoJSONData: $geoJSONData
            postalCodesData: $postalCodesData
        ) {
            officeId
            locationTemplate {
                ...locationPreference
            }
        }
    }
    ${locationPreference}
`;
