import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import * as RouteProps from 'react-router';
import ThreeColumns from '@/app.layouts/ThreeColumnsLayout';
import { fetchUser } from '../../app.redux/actions';
import Navigation from '../navigations/Navigation';
import Aside from '../asides/OfficeAside';
import SideMenu from '../navigations/SideMenu/SideMenu';

const noOp = () => ({});

class Loading extends Component {
    static propTypes = {
        fetchUser: PropTypes.func.isRequired,
        location: RouteProps.locationShape,
    };

    componentDidMount() {
        this.props.fetchUser();
    }

    render() {
        const { location } = this.props;

        return (
            <div className="bc-scrollable-container">
                <Navigation
                    fetchCompanyOffices={noOp}
                    handleAddContact={noOp}
                    handleAddOffice={noOp}
                    handleAddProperty={noOp}
                    handleLogout={noOp}
                    setUserOffice={noOp}
                />
                <div className="bc-scrollable-container-content">
                    <ThreeColumns>
                        <Row className="bc-row-snap-cols-together">
                            <Aside location={location.pathname} />
                            <Col xs={4} lg={3}>
                                <SideMenu color="gray-lighter" />
                            </Col>
                        </Row>
                    </ThreeColumns>
                </div>
            </div>
        );
    }
}

export default connect(null, { fetchUser })(Loading);
