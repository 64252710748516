import React from 'react';
import { useIntl } from 'react-intl';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import Button from '@sweepbright/uikit/build/esm/button';
import { FilterChip } from '@sweepbright/uikit/build/esm/filterchip';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import Input from '@/app.components/forms/Input/Input';
import { events, trackHandler } from '@/app.utils/analytics';
import { getPropertiesAmenities } from '../../utils';
import { PropertySearchType } from '../../types';

type Props = {
    domainType?: 'contacts';
    title: React.ReactNode;
    placeholder?: React.ReactNode;
    defaultValues?: PropertySearchType;
    values: PropertySearchType;
    onChange: (newValue?: PropertySearchType) => void;
};

const propertiesAmenitiesData = getPropertiesAmenities();

const PropertyFilterAmenities: React.FC<Props> = props => {
    const {
        title,
        domainType,
        placeholder = <FormattedMessage id="properties.filter.amenities" defaultMessage="Amenities" />,
        defaultValues = domainType === 'contacts' ? { preference_amenity: undefined } : { amenities: undefined },
        values,
        onChange,
    } = props;

    const intl = useIntl();

    const allAmenities = Object.values(propertiesAmenitiesData).flat();

    // @ts-ignore
    const selected = domainType === 'contacts' ? values.preference_amenity : values.amenities;

    const selectedOptions = (selected || [])
        .filter(el => allAmenities.includes(el))
        .map(el => {
            return intl.formatMessage({
                id: `property.fields.structure.${el}`,
            });
        })
        .sort((a, b) => {
            return a.localeCompare(b);
        });

    const firstSelectedOption = selectedOptions[0];

    const formatAmenitiesValue = () => {
        if (selectedOptions.length > 1) {
            return <span>{`${firstSelectedOption} (+${selectedOptions.length - 1})`}</span>;
        } else if (selectedOptions.length === 1) {
            return firstSelectedOption;
        }

        return null;
    };

    return (
        <FilterChip
            size="small"
            placeholder={placeholder}
            onClear={() => {
                // @ts-ignore
                onChange(defaultValues);
            }}
            popupHeader={null}
            value={formatAmenitiesValue()}
            testId={`${domainType === 'contacts' ? 'contact' : 'property'}-amenities-filter-chip`}
            renderPopupContent={({ closePopup }) => {
                return (
                    <Form
                        title={title}
                        domainType={domainType}
                        onSubmit={newValues => {
                            onChange(newValues);
                            closePopup();
                        }}
                        initialValues={
                            // @ts-ignore
                            (domainType === 'contacts' ? values.preference_amenity : values.amenities) || []
                        }
                    />
                );
            }}
        />
    );
};

const Form = ({
    title,
    initialValues,
    onSubmit,
    domainType,
}: {
    domainType?: 'contacts';
    title: React.ReactNode;
    initialValues: string[];
    onSubmit(values: PropertySearchType);
}) => {
    const intl = useIntl();

    const [activeAmenities, setActiveAmenities] = React.useState<string[]>(initialValues || []);

    const amenitiesData = Object.entries(propertiesAmenitiesData).map(([groupKey, groupValues]) => {
        return {
            title: intl.formatMessage({
                id: `modals.common.types_panel.${groupKey}`,
            }),
            items: groupValues.map(el => {
                return {
                    value: el,
                    id: `${groupKey}-${el}`,
                    label: intl.formatMessage({
                        id: `property.fields.structure.${el}`,
                    }),
                };
            }),
        };
    });

    return (
        <form
            className="w-64"
            onSubmit={evt => {
                evt.preventDefault();
                // @ts-ignore
                onSubmit(
                    domainType === 'contacts'
                        ? {
                              // @ts-ignore
                              preference_amenity: activeAmenities,
                          }
                        : { amenities: activeAmenities },
                );
            }}
        >
            <FormPanel
                title={title}
                popup
                action={
                    <Button
                        variant="link"
                        type="submit"
                        onClick={() => {
                            trackHandler(events.SEARCH_FILTER_CHIP_APPLY_BTN_CLICKED, {
                                search: domainType || 'properties',
                                filter: 'amenities',
                            });
                        }}
                    >
                        <FormattedMessage id="buttons.apply" defaultMessage="Apply" />
                    </Button>
                }
            >
                <div style={{ maxHeight: '200px', overflow: 'auto', padding: '2px' }}>
                    {amenitiesData.map(group => {
                        return (
                            <FormGroup key={group.title}>
                                <ControlLabel>{group.title}</ControlLabel>
                                <div>
                                    {group.items
                                        .sort((a, b) => {
                                            return a.label.localeCompare(b.label);
                                        })
                                        .map(el => {
                                            const checked = activeAmenities.includes(el.value);

                                            return (
                                                <Input
                                                    key={el.id}
                                                    type="checkbox"
                                                    label={el.label}
                                                    checked={checked}
                                                    onChange={() => {
                                                        if (checked) {
                                                            setActiveAmenities(
                                                                activeAmenities.filter(amenity => amenity !== el.value),
                                                            );
                                                        } else {
                                                            setActiveAmenities([...activeAmenities, el.value]);
                                                        }
                                                    }}
                                                />
                                            );
                                        })}
                                </div>
                            </FormGroup>
                        );
                    })}
                </div>
            </FormPanel>
        </form>
    );
};

export default PropertyFilterAmenities;
