import { put } from 'redux-saga/effects';
import { ContactsRequests } from '../../../requests/index';
import { setVendorEstates } from '../../actions';
import withPagination from '../Helpers/withPagination';
import apiCall from '../Effects/apiCall';

export default function* onFetchVendorEstates({ contactId, attributes }) {
    yield withPagination(`contact/${contactId}/estates`, attributes, function* onFetch(settings) {
        const estates = yield apiCall(new ContactsRequests().estates, contactId, settings);
        yield put(
            setVendorEstates(
                contactId,
                estates.data.map(estate => estate.item.data),
            ),
        );

        return estates;
    });
}
