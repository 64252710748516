import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import FileReaderInput from 'react-file-reader-input';
import Button from '@sweepbright/uikit/build/esm/button';
import { useToasts } from '@sweepbright/notifications';
import { Icon } from '@/app.components';
import { OfficeGeoContext } from '../context';

const OfficeGeoImport = () => {
    const intl = useIntl();

    const { addError } = useToasts();

    const { officeId, setVisible, setActiveEntity } = useContext(OfficeGeoContext);

    const isValidGeoJson = (geoJson: any) => {
        const isValid =
            typeof geoJson === 'object' &&
            'type' in geoJson &&
            geoJson?.type &&
            'coordinates' in geoJson &&
            geoJson?.coordinates &&
            geoJson?.type === 'Polygon';

        return isValid;
    };

    const content = {
        button: intl.formatMessage({ id: 'office.geo.import.button' }),
        errorType: intl.formatMessage({ id: 'office.geo.import.error.type' }),
        errorGeoJson: intl.formatMessage({ id: 'office.geo.import.error.geojson' }),
    };

    return (
        <FileReaderInput
            as="text"
            accept=".geojson,.json"
            onChange={(_event, [[, file]]) => {
                if (file.type === 'application/geo+json' || file.type === 'application/json') {
                    const reader = new FileReader();

                    reader.readAsText(file);

                    reader.onload = () => {
                        try {
                            const data = reader.result as string;

                            const parsedData = JSON.parse(data);

                            const type = parsedData?.type;

                            const object =
                                (type === 'FeatureCollection' &&
                                    parsedData?.features &&
                                    parsedData?.features[0] &&
                                    parsedData?.features[0]?.geometry) ||
                                parsedData?.geometry ||
                                parsedData;

                            const geoJson = isValidGeoJson(object) ? object : null;

                            if (geoJson) {
                                setActiveEntity({
                                    type: 'geometry',
                                    title: '',
                                    office_id: officeId,
                                    geometry: [geoJson],
                                });
                                setVisible('geometry');
                            } else {
                                addError({ message: content.errorGeoJson });
                            }
                        } catch (error) {
                            addError({ message: content.errorGeoJson });
                        }
                    };
                } else {
                    addError({ message: content.errorType });
                }
            }}
        >
            <Button icon={<Icon icon="new-paperclip" />}>{content.button}</Button>
        </FileReaderInput>
    );
};

export default OfficeGeoImport;
