export enum PropertyType {
    Apartment = 'apartment',
    Commercial = 'commercial',
    House = 'house',
    Land = 'land',
    Office = 'office',
    Parking = 'parking',
}

export enum PropertyInternalType {
    Standalone = 'standalone',
    Project = 'project',
    Unit = 'unit',
}

export enum PropertyNegotiation {
    Sale = 'sale',
    Let = 'let',
}

export interface PropertyFieldVisibilityContext {
    readonly type: PropertyType;
    readonly internalType: PropertyInternalType;
    readonly negotiation: PropertyNegotiation;
    readonly country: string;
}

export interface PreferenceFieldVisibilityContext {
    readonly type: PropertyType;
}

export interface CanGetVisibilityContext {
    getVisibilityContext(): PropertyFieldVisibilityContext;
}
