import gql from 'graphql-tag';
import { OfferCardFragment } from '@/graphql/fragments/offerCard';

export const GET_CONTACT_OFFERS = gql`
    query GetContactOffers($contactId: ID!, $archived: Boolean!) {
        contact(id: $contactId) {
            id
            offers(archived: $archived) {
                ...OfferCardFragment
            }
        }
    }

    ${OfferCardFragment}
`;
