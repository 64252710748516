// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import { FieldArray } from 'formik';
import * as Yup from 'yup';
import Button from '@sweepbright/uikit/build/esm/button';
import { reduxForm as form } from 'redux-form';
import Modal from '@/app.components/elements/Modal';
import { FormattedMessage } from 'react-intl-sweepbright';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import Formik from '@/app.components/forms/helpers/Formik';
import { unitsBySystem } from '../../app.data';
import { Input, Icon, AreaInput } from '../../app.components';

const validationSchema = Yup.object().shape({
    rooms: Yup.array()
        .of(
            Yup.object().shape({
                size: Yup.string()
                    .max(200)
                    .required(),
                room_description: Yup.string()
                    .max(200)
                    .required(),
            }),
        )
        .min(1),
});

type Values = {
    rooms: Array<{ size: string; room_description: string }>;
};

export default function AddRoomsModal(props: {
    system: 'metric' | 'imperial';
    onSubmit: (values: Values) => void;
    close: () => void;
}) {
    const units = unitsBySystem.get(props.system).get('area');

    return (
        <Formik
            initialValues={{
                rooms: [
                    {
                        size: '',
                        room_description: '',
                    },
                ],
            }}
            onSubmit={values => {
                props.close();
                props.onSubmit(values);
            }}
            validationSchema={validationSchema}
            isInitialValid={false}
        >
            {({ values, handleSubmit, handleChange, setFieldValue, isValid }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal.Header>
                            <Modal.Title>
                                <FormattedMessage id="modals.add-room.title" defaultMessage="Add room" />
                            </Modal.Title>
                        </Modal.Header>
                        <FieldArray name="rooms">
                            {arrayHelpers => {
                                return (
                                    <Modal.Body>
                                        {values.rooms.map((room, idx) => {
                                            return (
                                                <div key={idx} className="flex space-x-2 w-full">
                                                    <div className="w-full">
                                                        <Input
                                                            type="text"
                                                            value={room.room_description}
                                                            name={`rooms.${idx}.room_description`}
                                                            onChange={handleChange}
                                                            placeholder={
                                                                <FormattedMessage
                                                                    id="properties.room_name_field.placeholder"
                                                                    defaultMessage="Room name"
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                    <AreaInput
                                                        value={room.size}
                                                        units={units}
                                                        onChange={(value: string) => {
                                                            setFieldValue(`rooms.${idx}.size`, value);
                                                        }}
                                                    />
                                                    <div className="form-group w-8">
                                                        <ButtonIcon
                                                            onClick={() => arrayHelpers.remove(idx)}
                                                            type="circle-delete"
                                                            variant="link"
                                                            iconSize={24}
                                                            className={classNames({
                                                                'text-danger': values.rooms.length > 1,
                                                            })}
                                                            disabled={values.rooms.length === 1}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                arrayHelpers.push({
                                                    size: '',
                                                    room_description: '',
                                                })
                                            }
                                        >
                                            <Icon icon="add" />{' '}
                                            <FormattedMessage
                                                id="general.action.add_another"
                                                defaultMessage="Add another"
                                            />
                                        </Button>
                                    </Modal.Body>
                                );
                            }}
                        </FieldArray>
                        <Modal.Footer>
                            <Button onClick={props.close}>
                                <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button type="submit" variant="primary" disabled={!isValid}>
                                <FormattedMessage id="general.action.add" defaultMessage="Add" />
                            </Button>
                        </Modal.Footer>
                    </form>
                );
            }}
        </Formik>
    );
}
