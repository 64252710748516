import sagaMiddleware from '@/app.redux/store/Middlewares/Saga';
import { onFetchUser } from '@/app.redux/sagas/UsersSaga';

export default function runInitialSagas(components: any[], isLoggedIn: boolean = false): Promise<any[]> {
    const promises = components
        .reduce(
            (prev, current) => {
                if (current && current.needs) {
                    return [...prev, current.needs];
                }

                return prev;
            },
            isLoggedIn ? [onFetchUser] : [],
        )
        .map(saga => sagaMiddleware.run(saga).done);

    return Promise.all(promises);
}
