import { APIUser } from '@sweepbright/webapp-server/graphql/resolvers/helpers/types';
import { HTTP_STATUS_NOT_FOUND, HTTP_STATUS_FORBIDDEN } from '@/app.utils/services/Helpers/constants/statusCodes';
import SweepbrightCrudRequest, { FileData } from './SweepbrightCrudRequest';

interface InviteItem {
    email: string;
    first_name?: string;
    last_name?: string;
}

interface InviteOfficePayload {
    office_id: string;
}

interface InviteCompanyPayload {
    company_id: string;
}

export type InvitePayload = {
    language?: string;
    invites: InviteItem[];
} & (InviteOfficePayload | InviteCompanyPayload);

export default class UserRequests extends SweepbrightCrudRequest {
    resource = 'users';

    show(userId: string) {
        return super.show(userId).catch(e => {
            if (e.response?.status === HTTP_STATUS_NOT_FOUND || e.response?.status === HTTP_STATUS_FORBIDDEN) {
                return { errorStatus: e.response.status };
            }

            throw e;
        });
    }

    mustShow(userId: string) {
        return super.show(userId);
    }

    setOffice(officeId: string) {
        return this.put(`/users/me/offices/${officeId}/assign`);
    }

    me = () => {
        return this.withQueryParameters({ includes: ['office.settings', 'company.enterprise'] }).get<APIUser>(
            'users/me',
        );
    };

    photos = (userId: string, file: FileData) => {
        return this.storeFile(`${userId}/photo`, file);
    };

    removePhoto = (userId: string) => {
        return this.delete(`users/${userId}/photo`);
    };

    register = (payload: object) => {
        return this.emailGrantRequest('auth/register', payload);
    };

    assignEstates = (userId: string, estateIds: string[]) => {
        return this.post(`users/${userId}/assigned-estates`, {
            ids: estateIds,
        });
    };

    archive = (userId: string) => {
        return this.post(`users/${userId}/archive`);
    };

    views = (userId: string, type: string) => {
        return this.get(`users/${userId}/views/${type}`);
    };

    markViewed = (userId: string, type: string, entityId: string) => {
        return this.put(`users/${userId}/views/${type}/${entityId}`);
    };

    emailGrantRequest = (endpoint: string, payload: object) => {
        const scopes = [
            'areas:read',
            'areas:write',
            'contacts:read',
            'contacts:write',
            'properties:read',
            'properties:write',
            'companies:read',
            'companies:write',
        ];

        return this.post(endpoint, {
            ...payload,
            grant_type: 'email',
            base_url: `${window.location.protocol}//${window.location.host}/authorize`,
            scope: scopes.join(','),
        });
    };

    getIntegrations = () => {
        return this.get('users/me/integrations');
    };

    addIntegration = (attributes: any) => {
        return this.post('users/me/integrations', attributes);
    };

    getIntegration = <T>(integrationName: string): Promise<T | void> => {
        //@ts-ignore
        return this.get<T>(`users/me/integrations/${integrationName}`).catch(err => {
            if (err.response.status === HTTP_STATUS_NOT_FOUND) {
                return null;
            } else {
                throw err;
            }
        });
    };

    removeIntegration = (name: string) => {
        return this.delete(`users/me/integrations/${name}`);
    };

    refreshIntegrationToken = (integrationName: string) => {
        return this.post(`users/me/integrations/${integrationName}/refresh-token`);
    };

    invite = (payload: InvitePayload) => {
        const cleanPayload = {
            invites: payload.invites,
            language: payload.language,
        };

        if ('office_id' in payload) {
            return this.post(`offices/${payload.office_id}/members`, cleanPayload);
        } else {
            return this.post(`companies/${payload.company_id}/admins`, cleanPayload);
        }
    };

    updateRole = (
        _officeId: string,
        userId: string,
        role: 'team-member' | 'editor' | 'team-admin' | 'company-admin',
    ) => {
        return this.put(`users/${userId}/role`, { role });
    };

    updateTeams = (userId: string, teamIds: string[]) => {
        return this.put(`users/${userId}/teams`, { ids: teamIds });
    };

    userTeams = (userId: string, parameters: { page?: number; limit?: number }) => {
        return this.withMeta()
            .withQueryParameters({
                page: parameters.page || 1,
                limit: parameters.limit || 100,
            })
            .get(`users/${userId}/teams`);
    };
}
