import gql from 'graphql-tag';

export const GET_USER_TEAMS = gql`
    query getUserTeams($userId: ID!, $page: Int, $limit: Int) {
        userTeams(userId: $userId, page: $page, limit: $limit) {
            meta {
                pagination {
                    current_page
                    total_pages
                    total
                    per_page
                }
            }
            data {
                id
                name
                settings {
                    data
                }
            }
        }
    }
`;
