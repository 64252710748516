import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { InteractionFeedbackType } from '../../app.data/Conditions';
import { feedbackTitles, InteractionMap, feedbackLabels } from '../../app.data/Interactions';
import PropertiesFeedback from '../properties/PropertiesFeedback';

export const feedbackTypeCaption: { [T in InteractionFeedbackType]: string } = {
    lead_expressed_no_interest: 'no-interest',
    lead_requested_info: 'more-info',
    lead_requested_visit: 'visit',
    lead_made_bid: 'bid',
    lead_made_deal: 'deal',
};

export function InteractionFeedback({ interaction }: { interaction: InteractionMap }) {
    const feedbackType = interaction.get('feedback_type');

    if (feedbackType) {
        const feedback = {
            type: feedbackTypeCaption[feedbackType],
            comment: interaction.get('feedback_external_comment') || (
                <FormattedMessage id="interaction.feedback.empty" defaultMessage="No comment provided." tagName="em" />
            ),
            privateComment: interaction.get('feedback_internal_comment') || (
                <FormattedMessage
                    id="interaction.feedback.private_empty"
                    defaultMessage="No private comment provided."
                    tagName="em"
                />
            ),
            title: feedbackTitles[feedbackType],
            label: feedbackLabels[feedbackTypeCaption[feedbackType]],
        };

        return <PropertiesFeedback {...feedback} />;
    }

    if (interaction.get('comment')) {
        return <PropertiesFeedback comment={interaction.get('comment')} />;
    }

    return null;
}
