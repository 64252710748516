import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Link from '@/app.components/elements/Link';

const EmptyState: React.FunctionComponent<{
    title: React.ReactNode;
    body?: React.ReactNode;
    helpLink?: string | { to: string };
    helpText?: React.ReactNode;
}> = ({
    title,
    body,
    helpLink,
    helpText = <FormattedMessage id="empty-state.help" defaultMessage="Need Help?" />,
    children,
}) => (
    <div className="bc-empty-state">
        <div className="bc-empty-state-title">{title}</div>
        {body && <div className="bc-empty-state-text">{body}</div>}
        {helpLink && typeof helpLink === 'string' && (
            <a href={helpLink} rel="noopener noreferrer" target="_blank" className="bc-empty-state-help">
                {helpText}
            </a>
        )}
        {typeof helpLink === 'object' && 'to' in helpLink && (
            <Link to={helpLink.to} className="bc-empty-state-help">
                {helpText}
            </Link>
        )}
        {children}
    </div>
);

export default EmptyState;
