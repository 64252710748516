import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl-sweepbright';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import SideMenuLink from '@/app.components/navigations/SideMenu/SideMenuLink';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import {
    PROPERTIES,
    PROPERTY_DETAILS,
    PROPERTY_PUBLISH,
    PROPERTY_MATCH,
    PROPERTY_VISIT,
    PROPERTY_UNITS,
    PROPERTY_UNIT_DETAILS,
    PROPERTY_UNIT_PUBLISH,
    PROPERTY_UNIT_VISIT,
    PROPERTY_UNIT_MATCH,
    PROPERTY_UNIT_TIMELINE,
    PROPERTY_TIMELINE,
    PROPERTY_UNIT_SCHEDULE,
    PROPERTY_SCHEDULE,
    PROPERTY_OFFERS,
    PROPERTY_UNIT_OFFERS,
    PROPERTY_SIMILAR,
    PROPERTY_UNIT_SIMILAR,
} from '@/app.routing/routes';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import useProperty from '@/app.hooks/useProperty';
import useOffice from '@/app.hooks/useOffice';
import { track, events } from '@/app.utils/analytics';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import SideMenuBadge from '@/app.components/navigations/SideMenu/SideMenuBadge';
import useEstatePublicationCounters from '@/app.hooks/useEstatePublicationCounters';
import { useEstateStats } from '@/app.hooks/offer/useEstateStats';
import { LayoutContext } from '@/app.components/layouts/utils';
import { useExponentialPolling } from '@/app.hooks/useExponentialPolling';
import PropertyHeader from '../../../app.domains/properties/components/PropertyHeader';

function PropertyPage({ children, params, location, router }) {
    const propertyId = params.property;
    const unitId = params.unit;
    const estateId = unitId || propertyId;
    const { property, loading } = useProperty(estateId);

    const office = useOffice();
    const officeId = office.get('id');

    const timelinePath = unitId ? PROPERTY_UNIT_TIMELINE(propertyId, unitId) : PROPERTY_TIMELINE(propertyId);
    const detailsPath = unitId ? PROPERTY_UNIT_DETAILS(propertyId, unitId) : PROPERTY_DETAILS(propertyId);
    const showSchedule = useSelector(getFeature('properties.visitScheduler.enabled'));

    const showPropertySharedFilter = useFeatureFlag('property.shared.enabled');
    const showPropertyRecommendations = useFeatureFlag('property.recommendations.enabled');

    const isShared =
        showPropertySharedFilter && property?.visibility === 'company' ? property?.office_id !== officeId : false;
    const isArchived = property?.archived;

    React.useLayoutEffect(() => {
        if (loading) {
            return;
        }

        if (!property?.id) {
            router.replace(PROPERTIES);

            return;
        }

        if (unitId && !property.isUnit) {
            router.replace(PROPERTIES);

            return;
        }

        if (property.isUnit && !(property.projectId === propertyId)) {
            router.replace(PROPERTIES);

            return;
        }

        if (!children) {
            if (isShared) {
                router.replace(detailsPath);
            } else {
                // @ts-ignore
                const indexPath = property!.isProject ? detailsPath : timelinePath;

                router.replace(indexPath);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, propertyId, unitId, property, isShared]);

    const isProject = property?.isProject;
    const isUnit = !!unitId;

    // @ts-ignore
    const unitsPublishEnabled = useFeatureFlag('units.publish.enabled');
    // @ts-ignore
    const unitsPublishEmptyStateShown = useFeatureFlag('units.publish.emptyState.shown');

    const isPublishMenuShown = !isUnit || unitsPublishEmptyStateShown || unitsPublishEnabled;

    const { data, refetch: refetchOffersStats } = useEstateStats({
        variables: { filter: { propertyId } },
    });

    const [startExponentialRefetch, stopExponentialRefetch] = useExponentialPolling(
        () => {
            refetchOffersStats();
        },
        {
            initialDelay: 500,
            maxIterations: 8,
        },
    );

    const {
        counters: { errors: publishErrorsCount },
    } = useEstatePublicationCounters(estateId);

    const handleOfferStatsRefetch = () => {
        stopExponentialRefetch();
        startExponentialRefetch();
    };

    const scheduleEnabled = useFeatureFlag('property.shared.visit.enabled');
    const offersEnabled = useFeatureFlag('property.shared.offer.enabled');

    return (
        <LayoutContext.Provider value={{ domainType: 'property', handleOfferStatsRefetch }}>
            <LayoutColumn size="medium" className="flex flex-column bc-bg-gray-lightest">
                <ErrorBoundary>
                    <PropertyHeader
                        // @ts-ignore
                        property={property}
                    />
                </ErrorBoundary>
                <div className="o-flex__item overflow-auto">
                    {!loading && property?.id && (
                        <ul className="c-side-menu" data-testid="property-page-menu">
                            {!property?.isProject && !isShared ? (
                                <SideMenuLink
                                    label={
                                        <FormattedMessage id="navigation.aside.activity" defaultMessage="Activity" />
                                    }
                                    icon="clock"
                                    to={
                                        unitId
                                            ? PROPERTY_UNIT_TIMELINE(propertyId, unitId)
                                            : PROPERTY_TIMELINE(propertyId)
                                    }
                                    location={location}
                                    fuzzyMatch
                                    onClick={() => track(events.PROPERTY_ACTIVITY_SECTION_CLICKED)}
                                />
                            ) : null}

                            {(isShared ? offersEnabled : true) && (
                                <SideMenuLink
                                    label={<FormattedMessage id="offers.title" defaultMessage="Offers" />}
                                    icon="offers"
                                    to={unitId ? PROPERTY_UNIT_OFFERS(propertyId, unitId) : PROPERTY_OFFERS(propertyId)}
                                    location={location}
                                    fuzzyMatch
                                    extra={
                                        (data?.propertyStats?.hasAcceptedOffer && (
                                            <SideMenuBadge
                                                size="sm"
                                                type="info"
                                                value={
                                                    <FormattedMessage
                                                        id="offers.status.accepted"
                                                        defaultMessage="Accepted"
                                                    />
                                                }
                                            />
                                        )) ||
                                        (data?.propertyStats?.numberOfPendingOffers && (
                                            <SideMenuBadge
                                                size="md"
                                                type="default"
                                                value={data.propertyStats.numberOfPendingOffers}
                                            />
                                        )) ||
                                        undefined
                                    }
                                    onClick={() => track(events.PROPERTY_OFFERS_SECTION_CLICKED)}
                                />
                            )}
                            {showSchedule && (isShared ? scheduleEnabled : true) && (
                                <SideMenuLink
                                    label={
                                        <FormattedMessage id="navigation.aside.schedule" defaultMessage="Schedule" />
                                    }
                                    icon="calendar"
                                    to={
                                        unitId
                                            ? PROPERTY_UNIT_SCHEDULE(propertyId, unitId)
                                            : PROPERTY_SCHEDULE(propertyId)
                                    }
                                    location={location}
                                    fuzzyMatch
                                    onClick={() => track(events.PROPERTY_SCHEDULE_SECTION_CLICKED)}
                                />
                            )}
                            <SideMenuLink
                                label={
                                    isProject ? (
                                        <FormattedMessage
                                            id="navigation.aside.project_details"
                                            defaultMessage="Project Details"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="navigation.aside.property_details"
                                            defaultMessage="Property Details"
                                        />
                                    )
                                }
                                icon="house"
                                to={unitId ? PROPERTY_UNIT_DETAILS(propertyId, unitId) : PROPERTY_DETAILS(propertyId)}
                                location={location}
                                fuzzyMatch
                                onClick={() => track(events.PROPERTY_DETAILS_SECTION_CLICKED)}
                            />
                            {!isShared && isPublishMenuShown && (
                                <SideMenuLink
                                    label={<FormattedMessage id="navigation.aside.publish" defaultMessage="Publish" />}
                                    icon="publish"
                                    to={
                                        unitId
                                            ? PROPERTY_UNIT_PUBLISH(propertyId, unitId)
                                            : PROPERTY_PUBLISH(propertyId)
                                    }
                                    location={location}
                                    fuzzyMatch
                                    extra={
                                        publishErrorsCount ? (
                                            <SideMenuBadge type="error" size="md" value={publishErrorsCount} />
                                        ) : (
                                            undefined
                                        )
                                    }
                                    onClick={() => track(events.PROPERTY_PUBLISH_SECTION_CLICKED)}
                                />
                            )}
                            {!isShared && isProject && (
                                <SideMenuLink
                                    label={<FormattedMessage id="navigation.aside.units" defaultMessage="Units" />}
                                    icon="units"
                                    to={PROPERTY_UNITS(propertyId)}
                                    location={location}
                                    fuzzyMatch
                                    onClick={() => track(events.PROPERTY_UNITS_SECTION_CLICKED)}
                                />
                            )}
                            {!isProject && (
                                <SideMenuLink
                                    label={<FormattedMessage id="navigation.aside.match" defaultMessage="Match" />}
                                    icon="matching"
                                    to={unitId ? PROPERTY_UNIT_MATCH(propertyId, unitId) : PROPERTY_MATCH(propertyId)}
                                    location={location}
                                    fuzzyMatch
                                    onClick={() => track(events.PROPERTY_MATCH_SECTION_CLICKED)}
                                />
                            )}
                            {!isShared && !isProject && !showSchedule && (
                                <SideMenuLink
                                    label={<FormattedMessage id="navigation.aside.visit" defaultMessage="Visit" />}
                                    icon="visit"
                                    to={unitId ? PROPERTY_UNIT_VISIT(propertyId, unitId) : PROPERTY_VISIT(propertyId)}
                                    location={location}
                                    fuzzyMatch
                                    onClick={() => track(events.PROPERTY_VISIT_SECTION_CLICKED)}
                                />
                            )}
                            {!isProject && !isArchived && showPropertyRecommendations && (
                                <SideMenuLink
                                    label={<FormattedMessage id="navigation.aside.similar" defaultMessage="Similar" />}
                                    to={
                                        unitId
                                            ? PROPERTY_UNIT_SIMILAR(propertyId, unitId)
                                            : PROPERTY_SIMILAR(propertyId)
                                    }
                                    icon="star"
                                />
                            )}
                        </ul>
                    )}
                </div>
            </LayoutColumn>
            <LayoutColumn className="bc-bg-shadow-left">
                <LayoutContainer>{!loading && property?.id && children}</LayoutContainer>
            </LayoutColumn>
        </LayoutContext.Provider>
    );
}

export default PropertyPage;
