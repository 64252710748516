import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import { events, trackHandler } from '@/app.utils/analytics';
import { ResourceDomainType } from '@/shared/resource/types';

type Props = {
    id: string;
    disabled: boolean;
    domainType: ResourceDomainType;
};

const ResourceFilterFormSubmit: React.FC<Props> = props => {
    const { id, disabled, domainType } = props;

    return (
        <Button
            type="submit"
            variant="link"
            disabled={disabled}
            className="resource-filter-form__submit"
            onClick={() => {
                trackHandler(events.SEARCH_FILTER_CHIP_APPLY_BTN_CLICKED, {
                    filter: id,
                    search: domainType,
                });
            }}
        >
            <FormattedMessage id="buttons.apply" defaultMessage="Apply" />
        </Button>
    );
};

export default ResourceFilterFormSubmit;
