import React from 'react';
import classNames from 'classnames';
import Button from '@sweepbright/uikit/build/esm/button';
import Icon from '@/app.components/icons/Icon';
import { FormattedMessage } from 'react-intl-sweepbright';

interface Props {
    expanded: boolean;
    onToggle: () => void;
}

export function DetailsAction({ expanded, onToggle }: Props) {
    return (
        <Button
            variant="link"
            onClick={onToggle}
            className={classNames('c-activity-action c-activity-action--details', {
                'c-activity-action--details-expanded': expanded,
            })}
            iconSide="right"
            icon={<Icon icon="chevron" inline />}
        >
            {expanded ? (
                <FormattedMessage id="general.action.hide_details" defaultMessage="Hide Details" />
            ) : (
                <FormattedMessage id="general.action.show_details" defaultMessage="Show Details" />
            )}
        </Button>
    );
}
