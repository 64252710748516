import React, { FC } from 'react';
import { Collapse } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import { DetailsAction } from '@/app.components/elements/DetailsAction/DetailsAction';
import {
    EstatePublicationError,
    EstatePublicationErrors,
    EstatePublicationWarning,
    EstatePublicationWarnings,
} from '@/graphql/generated/types';
import { PublicationErrorDetailsItem } from './PublicationErrorDetailsItem';
import { UnitPublicationErrorsList } from './UnitPublicationErrorsList';

interface PublicationErrorDetailsProps {
    channelAccountId: string;
    propertyId: string;
    errors: EstatePublicationErrors;
    warnings?: Maybe<EstatePublicationWarnings>;
}

export const PublicationErrorDetails: FC<PublicationErrorDetailsProps> = ({
    channelAccountId,
    errors,
    warnings,
    propertyId,
}) => {
    const [showDetails, setShowDetails] = React.useState(false);
    function renderErrors(errors: EstatePublicationErrors) {
        // 'errors' array will contain the units' errors for Project estate
        // They should be handled in this component when we have the designs

        const estateErrors = (errors.self ?? [])
            .filter((e): e is EstatePublicationError => !!e)
            .map((error, idx) => {
                return <PublicationErrorDetailsItem key={idx} type="error" error={error} />;
            });

        const unitsErrors = (errors.units ?? []).map(({ unitId, errors }, idx) => {
            return (
                <UnitPublicationErrorsList
                    key={idx}
                    type="error"
                    errors={errors}
                    unitId={unitId}
                    projectId={propertyId}
                />
            );
        });

        const estateWarnings = (warnings?.self ?? [])
            .filter((e): e is EstatePublicationWarning => !!e)
            .map((warning, idx) => {
                return <PublicationErrorDetailsItem key={idx} type="warning" error={warning} />;
            });

        const unitsWarnings = (warnings?.units ?? []).map(({ unitId, warnings }, idx) => {
            return (
                <UnitPublicationErrorsList
                    key={idx}
                    type="warning"
                    errors={warnings}
                    unitId={unitId}
                    projectId={propertyId}
                />
            );
        });

        return (
            <>
                {estateErrors}
                {unitsErrors}
                {estateWarnings}
                {unitsWarnings}
                <div className="my-2 p-2 rounded border border-gray-light bg-gray-100 flex text-muted">
                    <small>
                        <FormattedMessage id="channel.id" defaultMessage="Channel ID:" />{' '}
                        <span>{channelAccountId}</span>
                    </small>
                </div>
            </>
        );
    }

    return (
        <section className="min-w-full">
            <DetailsAction expanded={showDetails} onToggle={() => setShowDetails(!showDetails)} />
            <Collapse in={showDetails} transitionAppear>
                <div>{renderErrors(errors)}</div>
            </Collapse>
        </section>
    );
};
