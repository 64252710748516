import React from 'react';
import FormPanelToolbar from './FormPanelToolbar';

export const FormPanelHeader: React.FunctionComponent<{
    title?: React.ReactNode;
    toolbarActions: React.ReactNode;
    withToolbar: boolean;
}> = ({ title, toolbarActions, withToolbar }) => {
    const left = <h4 className="c-form-panel-title">{title}</h4>;

    if (title) {
        return (
            <header className="c-form-panel-heading">
                {withToolbar ? <FormPanelToolbar left={left} right={toolbarActions} /> : left}
            </header>
        );
    }

    return null;
};
