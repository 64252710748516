import React, { useContext } from 'react';
import classNames from 'classnames';
import { ResourceContext } from '../utils';
import ResourceLoading from '../ResourceLoading';

const ResourceList = () => {
    const { entities, listExtra, listHeader, renderListCard } = useContext(ResourceContext);

    return (
        <div className={`flex h-full py-2${listHeader ? ' flex-col' : ''}`}>
            {listHeader}

            <div className={`overflow-y-auto w-full h-full${listExtra ? ' lg:w-6/12 lg:pr-1' : ''}`}>
                {entities.length > 0 && (
                    <ul className={classNames('bc-bordered-list')}>{entities.map(renderListCard)}</ul>
                )}

                <ResourceLoading />
            </div>
            {listExtra && <div className="hidden lg:block w-6/12 pl-1">{listExtra}</div>}
        </div>
    );
};

export default ResourceList;
