import { Field } from 'formik';
import React from 'react';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import Input from '@/app.components/forms/Input/Input';
import { FormattedMessage } from 'react-intl-sweepbright';
import helpLinks from '@/app.data/helpLinks';

export function AgencySiteSelfSchedulingSection() {
    return (
        <FormPanel title={<FormattedMessage id="forms.agency_site.title" defaultMessage="Agency Site" />}>
            <Field
                name="appointmentServiceUrl"
                render={({ field, form }) => (
                    <Input
                        label={
                            <FormattedMessage
                                id="integrations.calendar.calendly_youcanbookme_link.label"
                                defaultMessage="Link to Your Online Scheduler"
                            />
                        }
                        {...field}
                        error={form.errors[field.name]}
                        touched={form.touched[field.name]}
                        help={
                            <FormattedMessage
                                id="integrations.calendar.agency_scheduling.help"
                                defaultMessage="Paste your personal scheduling link to for example Calendly or YouCanBook.me. Learn more about scheduling tools in {link}."
                                values={{
                                    link: (
                                        <a
                                            href={helpLinks.agencyWebsiteCalendars}
                                            target="__blank"
                                            rel="noreferrer noopener nofollow"
                                        >
                                            <FormattedMessage
                                                id="navigation.help-learning"
                                                defaultMessage="Help & Learning"
                                            />
                                        </a>
                                    ),
                                }}
                            />
                        }
                    />
                )}
            />
        </FormPanel>
    );
}
