import { fork } from 'redux-saga/effects';
import AuthenticationSaga from './AuthenticationSaga';
import ContactsSaga from './ContactsSaga';
import PropertiesSaga from './PropertiesSaga';
import UsersSaga from './UsersSaga';
import PaginationSaga from './PaginationSaga';
import OfficesSaga from './OfficesSaga';
import CompaniesSaga from './CompaniesSaga';
import RoutingSaga from './RoutingSaga';
import ChannelsSaga from './ChannelsSaga';
import StatusSaga from './StatusSaga';
import PlansSaga from './PlansSaga';
import FeaturesSaga from './FeaturesSaga';
import SearchSaga from './SearchSaga';

export default function*() {
    yield [
        fork(RoutingSaga),
        fork(AuthenticationSaga),
        fork(ContactsSaga),
        fork(PropertiesSaga),
        fork(UsersSaga),
        fork(PaginationSaga),
        fork(OfficesSaga),
        fork(CompaniesSaga),
        fork(ChannelsSaga),
        fork(StatusSaga),
        fork(PlansSaga),
        fork(FeaturesSaga),
        fork(SearchSaga),
    ];
}
