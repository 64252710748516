import React from 'react';
import { Tooltip } from '@sweepbright/uikit';

type Props = {
    tooltip?: boolean;
    children: JSX.Element;
    description?: Maybe<string>;
};

const ContactLabelTooltip: React.FC<Props> = props => {
    const { tooltip, children, description } = props;

    if (tooltip && description) {
        return <Tooltip label={<div className="contact-label__tooltip">{description}</div>}>{children}</Tooltip>;
    }

    return <>{children}</>;
};

export default ContactLabelTooltip;
