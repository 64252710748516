import gql from 'graphql-tag';

export const ADD_GEO_TEMPLATE = gql`
    mutation AddGeoTemplate($officeId: ID!, $input: GeoTemplatesInput!) {
        addGeoTemplate(officeId: $officeId, input: $input) {
            _id
            title
            office_id
            type
            postalCodes
            geometry
        }
    }
`;
