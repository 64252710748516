import gql from 'graphql-tag';

export const PropertyCardFragment = gql`
    fragment PropertyCardFragment on Estate {
        __typename
        id
        type
        negotiation
        status
        attributes
        lastInteraction
        createdAt
        updatedAt
        archived
        internalType
        projectId
        isProject
        isUnit
        publicURL
        office_id
        visibility
        error_publications_count
        successful_publications_count
        negotiator {
            id
            firstName
            lastName
            first_name: firstName
            last_name: lastName
        }
        legalEntity
        buyer_ids
        owner_ids
    }
`;
