import { useQuery } from '@apollo/react-hooks';
import { GetLabelQuery, GetLabelQueryVariables } from '@/graphql/generated/types';
import { GET_LABEL } from '@/graphql/queries/company/getLabels';

const useLabel = (id?: string) => {
    const { data, error, loading } = useQuery<GetLabelQuery, GetLabelQueryVariables>(GET_LABEL, {
        skip: !id,
        variables: {
            id: id || '',
        },
    });

    return { data: data?.label, error, loading };
};

export default useLabel;
