import React, { FC } from 'react';
import { PagePane } from '@/app.components';
import EmptyState from '@/app.components/empty/EmptyState';
import Link from '@/app.components/elements/Link';
import useProperty from '@/app.hooks/useProperty';
import { PROPERTY_PUBLISH } from '@/app.routing';
import { FormattedMessage } from 'react-intl-sweepbright';

interface UnitPublishEmptyStateProps {
    unitId: string;
}

export const UnitPublishEmptyState: FC<UnitPublishEmptyStateProps> = ({ unitId }) => {
    const { property } = useProperty(unitId);

    const projectId = property?.projectId;

    return (
        <PagePane title={<FormattedMessage id="publish.title" defaultMessage="Publish" />}>
            <EmptyState
                title={
                    <FormattedMessage
                        id="pages.unit_publish_page.looking_to_publish"
                        defaultMessage="Looking to Publish? Try the Project level!"
                    />
                }
                body={
                    projectId ? (
                        <Link to={PROPERTY_PUBLISH(projectId)}>
                            <FormattedMessage id="pages.unit_publish_page.go_there_now" defaultMessage="Go there now" />
                        </Link>
                    ) : null
                }
            />
        </PagePane>
    );
};
