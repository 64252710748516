export const SIGN_IN_TO_CHANNEL = 'auth/SIGN_IN_TO_CHANNEL';
export const SET_CHANNEL_TOKEN = 'auth/SET_CHANNEL_TOKEN';
export const FETCH_CHANNELS = 'channels/FETCH_CHANNELS';
export const SET_CHANNELS = 'channels/SET_CHANNELS';

// Facebook
export const FETCH_FACEBOOK_ACCOUNTS = 'channels/FETCH_FACEBOOK_ACCOUNTS';
export const SET_FACEBOOK_ACCOUNTS = 'channels/SET_FACEBOOK_ACCOUNTS';

// Twitter
export const FETCH_TWITTER_PROFILE = 'channels/FETCH_TWITTER_PROFILE';
export const SET_TWITTER_PROFILE = 'channels/SET_TWITTER_PROFILE';

export function setChannels(channels: object[]) {
    return { type: SET_CHANNELS, payload: channels } as const;
}

export function signInToChannel(attributes: object) {
    return { type: SIGN_IN_TO_CHANNEL, attributes } as const;
}

export function setChannelToken(channel: object, token: string) {
    return { type: SET_CHANNEL_TOKEN, channel, token } as const;
}

export function fetchFacebookAccounts(token: string) {
    return { type: FETCH_FACEBOOK_ACCOUNTS, token } as const;
}

export function setFacebookAccounts(accounts: object[]) {
    return { type: SET_FACEBOOK_ACCOUNTS, accounts } as const;
}
