import React, { Component } from 'react';
import { ResponsiveEmbed, Alert } from 'react-bootstrap';
import validUrl from 'valid-url';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { FormattedMessage } from 'react-intl-sweepbright';
import {
    EmbedlyResponse,
    EmbedlyResponseLink,
    EmbedlyResponseRich,
    EmbedlyResponseVideo,
} from '@/requests/Embedly/types';
import { ProxyRequests } from '../../requests/index';

interface EmbedlyProps {
    onFailure: () => void;
    onSuccess: () => void;
    url: string;
    allowedTypes: EmbedlyResponse['type'][];
    emptyMessage: string;
}

interface EmbedlyState {
    embed: EmbedlyResponse | null;
    verifying: boolean;
}

export default class Embedly extends Component<EmbedlyProps, EmbedlyState> {
    getDefaultProps() {
        return {
            emptyMessage: (
                <FormattedMessage
                    id="embedly.no-video"
                    defaultMessage={"Sorry, Can't find that video. Please check the link"}
                />
            ),
        };
    }

    state: EmbedlyState = {
        embed: null,
        verifying: true,
    };

    componentDidMount() {
        this.fetchInfo(this.props.url);
    }

    componentDidUpdate({ url }) {
        if (url !== this.props.url) {
            this.fetchInfo(this.props.url);
        }
    }

    UNSAFE_componentWillUnmount() {
        this.props.onSuccess();
    }

    fetchInfo(url) {
        this.props.onFailure();
        this.setState({
            verifying: true,
        });

        if (!validUrl.isUri(url)) {
            this.setState({ embed: null, verifying: false });

            return;
        }

        new ProxyRequests()
            .oembed(url)
            .then(response => {
                this.setState({
                    embed: response,
                    verifying: false,
                });
            })
            .catch(() => {
                this.setState({ embed: null, verifying: false });
            })
            .finally(() => {
                this.props.onSuccess();
            });
    }

    renderVideo(embed: EmbedlyResponseVideo) {
        if (!embed.html) {
            return this.renderEmptyState();
        }

        return (
            <ResponsiveEmbed a16by9>
                <div dangerouslySetInnerHTML={{ __html: embed.html }} />
            </ResponsiveEmbed>
        );
    }

    renderLink(embed: EmbedlyResponseLink) {
        if (!embed.thumbnail_url) {
            return this.renderEmptyState();
        }

        return (
            <a href={this.props.url} target="_blank" rel="noreferrer">
                <img src={embed.thumbnail_url} alt={embed.description} />
            </a>
        );
    }

    renderRich(embed: EmbedlyResponseRich) {
        if (!embed.html) {
            return this.renderEmptyState();
        }

        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: embed.html,
                }}
            ></div>
        );
    }

    renderEmptyState() {
        return <Alert>{this.props.emptyMessage}</Alert>;
    }

    render() {
        const { embed, verifying } = this.state;
        const { allowedTypes } = this.props;

        if (verifying) {
            return <LoadingIndicator />;
        }
        if (!embed) {
            return this.renderEmptyState();
        }

        const isTypeAllowed = allowedTypes.includes(embed.type);
        if (!isTypeAllowed) {
            return this.renderEmptyState();
        }

        switch (embed.type) {
            case 'video':
                return this.renderVideo(embed);
            case 'link':
                return this.renderLink(embed);
            case 'rich':
                return this.renderRich(embed);
            default:
                return this.renderEmptyState();
        }
    }
}
