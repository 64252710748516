import gql from 'graphql-tag';

export default gql`
    fragment locationPreference on LocationPreference {
        id
        name
        type
        createdAt
        __typename
        ... on PostalCodesLocationPreference {
            data @include(if: $includeLocationData) {
                __typename
                country
                postalCodes
            }
        }
        ... on GeoJSONLocationPreference {
            data @include(if: $includeLocationData) {
                __typename
                type
                coordinates
            }
        }
    }
`;
