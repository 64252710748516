import { fromJS, Map } from 'immutable';
import { ContactType } from '@/app.data/types';
import { DEFAULT_LOCALE } from '@sweepbright/webapp-shared/Config/intl';
import { statuses } from '../../../app.components/elements/Status/Status';
import contactTypes from '../../../app.data/ContactTypes';
import AbstractRepository from './AbstractRepository';

export default class ContactRepository extends AbstractRepository {
    contact: Map<string, any>;

    constructor(contact: Map<string, any>, company?: Map<string, any>) {
        super();

        this.contact = fromJS(contact || {});
        this.setLanguageFromCompany(company);
    }

    getInitials() {
        const firstName = this.contact.get('first_name', '');
        const lastName = this.contact.get('last_name', '');

        let initials = '';

        if (firstName) {
            initials += firstName[0];
        }

        if (lastName) {
            initials += lastName[0];
        }

        return initials.toUpperCase();
    }

    getFullName() {
        return `${this.contact.get('first_name') || ''} ${this.contact.get('last_name') || ''}`;
    }

    getEmail(): string | undefined {
        return this.contact.get('email', '');
    }

    getPhone(): string | undefined {
        return this.contact.get('phone');
    }

    getAvatar() {
        const url = this.contact.getIn(['photo', 'data', 'url']);

        return url ? `${url}?p=thumbnail&timestamp=${this.contact.get('updated_at', '')}` : this.getInitials();
    }

    getType(): ContactType | undefined {
        if (this.contact.getIn(['preferences', 'is_investor'])) {
            return contactTypes.INVESTOR;
        }

        return this.contact.get('type');
    }

    isEmailed(): boolean {
        return this.contact.get('emailed') || this.contact.getIn(['flags', 'data', 'was_emailed_for_property']);
    }

    canBeMailedAgain(): boolean {
        return this.isEmailed() && this.contact.getIn(['flags', 'data', 'can_be_mailed_again_for_property']);
    }

    getStatus() {
        if (this.canBeMailedAgain()) {
            return statuses.changedGreen;
        }

        if (this.isEmailed()) {
            return statuses.checked;
        }

        return '';
    }

    isPendingInvitation(): boolean {
        return this.contact.get('pending_invitation', false);
    }

    getLanguage(): string {
        const locale = this.contact.get('locale') || '';
        const language = this.getFirstValidLanguage([locale, this.currentLanguage, DEFAULT_LOCALE]);

        return language.substring(0, 2);
    }

    hasEmail(): boolean {
        const email = this.getEmail();

        return email ? email.length > 0 : false;
    }

    canBeEmailed(): boolean {
        if (!this.hasEmail()) {
            return false;
        }

        if (!this.isSubscribed()) {
            return false;
        }

        return true;
    }

    isSubscribed() {
        return this.contact.get('subscribed', false);
    }

    getPreferences() {
        return this.contact.get('preferences');
    }
}
