import React, { FC, useMemo } from 'react';
import { RulesMatcher } from '@sweepbright/fields-js';
import { PreferenceFDL } from '@sweepbright/fields-data/lib/preference';
import { PreferenceFieldVisibilityContext, PropertyType } from '@/app.data/FieldVisibilityContext';

const rulesMatcher = new RulesMatcher<PreferenceFieldVisibilityContext>(PreferenceFDL);

const usePreferenceFieldVisibility = (type: PropertyType, path: string): boolean => {
    const ctx = useMemo(() => {
        return { type };
    }, [type]);

    const visible = useMemo(() => Boolean(rulesMatcher.getSchema(ctx, path)), [ctx, path]);
    const exists = useMemo(() => Boolean(rulesMatcher.pathExists(path)), [path]);

    if (!exists) {
        throw new Error(`usePreferenceFieldVisibility: Unknown path: ${path}`);
    }

    return visible;
};

export const PreferenceFieldVisibility: FC<{ type: PropertyType; path: string }> = ({ path, type, children }) => {
    const visible = usePreferenceFieldVisibility(type, path);
    if (visible) {
        return <>{children}</>;
    }

    return null;
};
