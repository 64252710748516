// @ts-nocheck
import React from 'react';
import produce from 'immer';
import { FormGroup, MenuItem } from 'react-bootstrap';
import Dropdown from '@sweepbright/uikit/build/esm/dropdown';
import { useMutation, MutationTuple } from '@apollo/react-hooks';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { InMemoryCache } from 'apollo-cache-inmemory';
import orderBy from 'lodash/orderBy';
import { FormattedMessage } from 'react-intl-sweepbright';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import PagePane from '@/app.layouts/PagePane/PagePane';
import Icon from '@/app.components/icons/Icon';
import Input from '@/app.components/forms/Input/Input';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import EmptyAlert from '@/app.components/empty/EmptyAlert';
import LocationPreferenceCard from '@/app.components/card/LocationPreferenceCard/LocationPreferenceCard';
import { GET_OFFICE } from '@/graphql/queries/office/getOffice';
import {
    GetOfficeQuery,
    GeoJsonLocationPreference,
    AddLocationTemplateMutation,
    AddLocationTemplateMutationVariables,
    PostalCodesLocationPreference,
    RemoveLocationTemplateMutation,
    RemoveLocationTemplateMutationVariables,
    UpdateLocationTemplateMutation,
    UpdateLocationTemplateMutationVariables,
} from '@/graphql/generated/types';
import InfiniteScroll from '@/app.layouts/InfiniteScroll';
import ToggleButton from '@/app.components/elements/Buttons/ToggleButton';
import AddZipcodeModal from '@/app.components/modals/LocationPreferences/AddZipcodeModal';
import { ADD_LOCATION_TEMPLATE } from '@/graphql/mutations/office/addLocationTemplate';
import DrawRegionModal from '@/app.components/modals/LocationPreferences/DrawRegionModal';
import { REMOVE_LOCATION_TEMPLATE } from '@/graphql/mutations/office/removeLocationTemplate';
import useLocationTemplates from '@/app.hooks/useLocationTemplates';
import { UPDATE_LOCATION_TEMPLATE } from '@/graphql/mutations/office/updateLocationTemplate';
import TeamWrapper from '@/new.domains/teams/TeamWrapper';

export default function OfficeLocationTemplatesPage(props) {
    const officeId = props.params.office;

    const [visibleModal, setVisibleModal] = React.useState<null | 'add_geojson_modal' | 'add_postal_codes_modal'>(null);

    const { data, loading, queryField, fetchMore } = useLocationTemplates(officeId);

    const [addLocationTemplate]: MutationTuple<
        AddLocationTemplateMutation,
        AddLocationTemplateMutationVariables
    > = useMutation(ADD_LOCATION_TEMPLATE, {
        update: getUpdateOnAdd(officeId),
    });

    const locations = orderBy(
        data && data.office ? data.office.locationTemplates.edges.map(edge => edge.node) : [],
        ['createdAt'],
        ['desc'],
    );
    const hasMorePages = data && data.office ? data.office.locationTemplates.pageInfo.hasNextPage : false;
    const totalCount = data && data.office ? data.office.locationTemplates.totalCount : Number.MAX_VALUE;
    const infiniteScrollProps = {
        onFetch: fetchMore,
        currentPage: data && data.office ? data.office.locationTemplates.pageInfo.currentPage : 0,
        hasMorePages: () => hasMorePages,
    };

    const actions = React.useMemo(() => {
        return [
            <Dropdown key="add_location">
                <ToggleButton bsRole="toggle" variant="primary">
                    <Icon icon="add" className="mr-1" />
                    <FormattedMessage id="location.templates.actions.add" defaultMessage="Add template" />
                </ToggleButton>
                <Dropdown.Menu className="pull-right">
                    <MenuItem onSelect={() => setVisibleModal('add_geojson_modal')}>
                        <FormattedMessage id="forms.actions.preferences.draw_on_map" defaultMessage="Draw on map" />
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem onSelect={() => setVisibleModal('add_postal_codes_modal')}>
                        <FormattedMessage id="location.postal_code" defaultMessage="Postcode / Zip" />
                    </MenuItem>
                </Dropdown.Menu>
            </Dropdown>,
        ];
    }, []);

    return (
        <TeamWrapper {...props}>
            <LayoutContainer>
                <PagePane
                    title={<FormattedMessage id="location.templates.title" defaultMessage="Location Templates" />}
                    actions={actions}
                >
                    <FormPanel>
                        <FormGroup>
                            <Input
                                placeholder={
                                    <FormattedMessage
                                        id="location.templates.search_title"
                                        defaultMessage="Search in location templates"
                                    />
                                }
                                {...queryField}
                            />
                        </FormGroup>
                        {totalCount !== 0 && (
                            <InfiniteScroll {...infiniteScrollProps}>
                                <div className="bc-bordered-list">
                                    {locations.map(location => (
                                        <LocationTemplateListItem
                                            location={location}
                                            key={location.id}
                                            officeId={officeId}
                                        />
                                    ))}
                                </div>
                            </InfiniteScroll>
                        )}
                        {loading || hasMorePages ? <LoadingIndicator /> : null}
                        {!loading && totalCount === 0 ? (
                            <EmptyAlert
                                icon="face-01"
                                body={
                                    <FormattedMessage
                                        id="location.templates.no_templates"
                                        defaultMessage="No location templates yet"
                                    />
                                }
                            />
                        ) : null}
                    </FormPanel>
                    {visibleModal === 'add_postal_codes_modal' ? (
                        <AddZipcodeModal
                            type="template"
                            show={visibleModal === 'add_postal_codes_modal'}
                            onClose={() => setVisibleModal(null)}
                            onSubmit={async (newLocationTemplate: PostalCodesLocationPreference) => {
                                await addLocationTemplate({
                                    variables: {
                                        officeId,
                                        preference: {
                                            name: newLocationTemplate.name,
                                            type: 'postal_codes',
                                        },
                                        postalCodesData: newLocationTemplate.data,
                                    },
                                });

                                setVisibleModal(null);
                                // clear the search field when a new template is added
                                queryField.onChange('');
                            }}
                        />
                    ) : null}
                    {visibleModal === 'add_geojson_modal' ? (
                        <DrawRegionModal
                            type="template"
                            show={visibleModal === 'add_geojson_modal'}
                            onClose={() => setVisibleModal(null)}
                            onSubmit={async (newLocationTemplate: GeoJsonLocationPreference) => {
                                await addLocationTemplate({
                                    variables: {
                                        officeId,
                                        preference: {
                                            name: newLocationTemplate.name,
                                            type: 'geojson',
                                        },
                                        geoJSONData: newLocationTemplate.data,
                                    },
                                });

                                setVisibleModal(null);
                                // clear the search field when a new template is added
                                queryField.onChange('');
                            }}
                        />
                    ) : null}
                </PagePane>
            </LayoutContainer>
        </TeamWrapper>
    );
}

function getUpdateOnAdd(officeId: string) {
    return function update(cache: InMemoryCache, mutationResult: { data: AddLocationTemplateMutation }) {
        const { addLocationPreferenceTemplate } = mutationResult.data;

        // get the lead from the cache
        const data: GetOfficeQuery | null = cache.readQuery({
            query: GET_OFFICE,
            variables: {
                id: officeId,
                locationTemplatesQuery: '',
            },
        });

        const newData = produce(data, draftData => {
            draftData!.office!.locationTemplates.edges.push({
                __typename: 'LocationPreferenceEdge',
                node: addLocationPreferenceTemplate.locationTemplate,
            });
            draftData!.office!.locationTemplates.totalCount++;
        });

        cache.writeQuery({
            query: GET_OFFICE,
            variables: {
                id: officeId,
                locationTemplatesQuery: '',
            },
            data: newData,
        });
    };
}

function LocationTemplateListItem({ location, officeId }) {
    const [removeLocationTemplate]: MutationTuple<
        RemoveLocationTemplateMutation,
        RemoveLocationTemplateMutationVariables
    > = useMutation(REMOVE_LOCATION_TEMPLATE, {
        update: getUpdateOnRemove(),
    });
    const [updateLocationTemplate]: MutationTuple<
        UpdateLocationTemplateMutation,
        UpdateLocationTemplateMutationVariables
    > = useMutation(UPDATE_LOCATION_TEMPLATE, {
        update: getUpdateOnUpdate(officeId),
    });

    return (
        <li>
            <LocationPreferenceCard
                type="template"
                location={location}
                onRemove={async () => {
                    await removeLocationTemplate({
                        variables: {
                            locationId: location.id,
                            officeId,
                        },
                    });
                }}
                onUpdate={(
                    locationVariables: Omit<UpdateLocationTemplateMutationVariables, 'officeId' | 'locationId'>,
                ) => {
                    updateLocationTemplate({
                        variables: {
                            officeId,
                            locationId: location.id,
                            ...locationVariables,
                        },
                    });
                }}
            />
        </li>
    );
}

function getUpdateOnRemove() {
    return function update(
        cache: InMemoryCache,
        { data: { removeLocationPreferenceTemplate } }: { data: RemoveLocationTemplateMutation },
    ) {
        // get the lead from the cache
        const officeId = removeLocationPreferenceTemplate.office.id;
        const data: GetOfficeQuery | null = cache.readQuery({
            query: GET_OFFICE,
            variables: {
                id: officeId,
                locationTemplatesQuery: '',
            },
        });

        const removedLocationId = removeLocationPreferenceTemplate.id;

        const newData = produce(data, draftData => {
            const newEdges = draftData!.office!.locationTemplates.edges.filter(
                edge => edge.node.id !== removedLocationId,
            );

            draftData!.office!.locationTemplates.edges = newEdges;
            draftData!.office!.locationTemplates.totalCount = Math.max(
                0,
                draftData!.office!.locationTemplates.totalCount - 1,
            );
        });

        cache.writeQuery({
            query: GET_OFFICE,
            variables: {
                id: officeId,
                locationTemplatesQuery: '',
            },
            data: newData,
        });
    };
}

function getUpdateOnUpdate(officeId: string) {
    return function updateOnUpdate(
        cache: InMemoryCache,
        {
            data: { updateLocationPreferenceTemplate },
        }: {
            data: UpdateLocationTemplateMutation;
        },
    ) {
        const data: GetOfficeQuery | null = cache.readQuery({
            query: GET_OFFICE,
            variables: {
                id: officeId,
                locationTemplatesQuery: '',
            },
        });

        const updatedLocationPreferenceId = updateLocationPreferenceTemplate.locationTemplate.id;
        if (data && data.office) {
            const newData = produce(data, draftData => {
                const newEdges = draftData!.office!.locationTemplates.edges.filter(
                    edge => edge.node.id !== updatedLocationPreferenceId,
                );

                newEdges.push({ node: updateLocationPreferenceTemplate.locationTemplate });
                draftData!.office!.locationTemplates.edges = newEdges;
            });

            cache.writeQuery({
                query: GET_OFFICE,
                variables: {
                    id: officeId,
                    locationTemplatesQuery: '',
                },
                data: newData,
            });
        }
    };
}
