// @ts-nocheck
import React from 'react';
import Loadable from 'react-loadable';

type MessageActivityDetails = { message: string };

const Span: React.FunctionComponent = ({ children }) => {
    return <span>{children}</span>;
};

const Anchorme: React.ComponentType<{
    target?: 'string';
    rel?: string;
} & React.HTMLProps<HTMLSpanElement>> = Loadable({
    loader: () => import('react-anchorme'),
    render(loaded, props) {
        const Component = loaded.Anchorme as React.FunctionComponent<any>;

        return <Component {...props} />;
    },
    loading: Span,
});

export default function MessageDetails({ details }: { details: MessageActivityDetails }) {
    return (
        <div className="my-1 bg-gray-lightest p-2 rounded">
            <Anchorme target="_blank" rel="noreferrer noopener">
                {details.message}
            </Anchorme>
        </div>
    );
}
