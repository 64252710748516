// @ts-nocheck
import React from 'react';
import matchSorter from 'match-sorter';
import { LoadingIndicator } from '@sweepbright/uikit';
import { Modal } from 'react-bootstrap/lib';
import Button from '@sweepbright/uikit/build/esm/button';
import Formik from '@/app.components/forms/helpers/Formik';
import { SearchBar } from '@/app.components/forms/Search/AdvancedSearch';
import { EstateChannelAccountEdgeFragmentFragment, PublicationStatus } from '@/graphql/generated/types';
import { ChannelCard } from '@/app.domains/properties/components/ChannelCard';
import useEstateChannelAccounts from '@/app.hooks/useEstateChannelAccounts';
import EmptyState from '@/app.components/empty/EmptyState';
import { FormattedMessage } from 'react-intl-sweepbright';

export function SelectChannelAccountStep({
    propertyId,
    onCancel,
    onSubmit,
}: {
    propertyId: string;
    onCancel: () => void;
    onSubmit: (channelAccountEdge: EstateChannelAccountEdgeFragmentFragment) => void;
}) {
    const { channelAccountsConnection, loading } = useEstateChannelAccounts(propertyId);
    const channelAccounts = channelAccountsConnection?.edges ?? [];

    const [query, setQuery] = React.useState('');

    const searchedChannelAccounts = matchSorter(channelAccounts, query, {
        keys: ['node.name'],
    }).filter(
        estateChannelAccountEdge => estateChannelAccountEdge.publicationStatus === PublicationStatus.NotPublished,
    );

    return (
        <Formik
            onSubmit={({ channelAccountId }) => {
                const channelAccountEdge: EstateChannelAccountEdgeFragmentFragment = channelAccounts.find(
                    estateChannelAccountEdge => estateChannelAccountEdge.node.id === channelAccountId,
                )!;

                onSubmit(channelAccountEdge);
            }}
            initialValues={{
                channelAccountId: null,
            }}
            initialErrors={{
                channelAccountId: 'Required',
            }}
            validate={validate}
        >
            {({ handleSubmit, isValid }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal.Header>
                            <Modal.Title>
                                <FormattedMessage
                                    id="publish.select_channel.title"
                                    defaultMessage="Select publication channel"
                                />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ minHeight: 400 }}>
                            <div>
                                <SearchBar
                                    placeholder={
                                        <FormattedMessage
                                            id="search.channels.placeholder"
                                            defaultMessage="Search channels..."
                                        />
                                    }
                                    settings={{ query }}
                                    onSubmit={({ query }) => setQuery(query)}
                                />
                            </div>
                            {loading && searchedChannelAccounts.length === 0 && (
                                <div className="py-2">
                                    <LoadingIndicator />
                                </div>
                            )}
                            {!loading && searchedChannelAccounts.length === 0 && (
                                <EmptyState
                                    title={
                                        <FormattedMessage
                                            id="publish.no_publication_channels.title"
                                            defaultMessage="Whoops, no publication channels found"
                                        />
                                    }
                                    body={
                                        <FormattedMessage
                                            id="publish.no_publication_channels.description"
                                            defaultMessage="Active publication channels will show up here"
                                        />
                                    }
                                />
                            )}
                            <ul
                                className="mt-4 c-list-checker bc-bordered-list overflow-auto c-util-height-md c-list-checker--with-highlights h-full c-contact-picker__list"
                                style={{ maxHeight: 400 }}
                            >
                                {searchedChannelAccounts.map(estateChannelAccountEdge => {
                                    return (
                                        <ChannelCard
                                            key={estateChannelAccountEdge.node.id}
                                            channelAccountId={estateChannelAccountEdge.node.id}
                                            propertyId={propertyId}
                                        />
                                    );
                                })}
                            </ul>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={onCancel}>
                                <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button variant="primary" type="submit" disabled={!isValid}>
                                <FormattedMessage id="misc.next" defaultMessage="Next" />
                            </Button>
                        </Modal.Footer>
                    </form>
                );
            }}
        </Formik>
    );
}

function validate({ channelAccountId }) {
    const errors: { channelAccountId?: string } = {};
    if (!channelAccountId) {
        errors.channelAccountId = 'Required';
    }

    return errors;
}
