import React from 'react';

const FormPanelToolbar: React.FunctionComponent<{ left: React.ReactNode; right: React.ReactNode }> = ({
    left,
    right,
}) => (
    <div className="bc-toolbar bc-toolbar-small">
        <div className="bc-toolbar-left">{left}</div>
        <div className="bc-toolbar-right">{right}</div>
    </div>
);

export default FormPanelToolbar;
