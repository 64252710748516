import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Button from '@/app.components/elements/Buttons/Button';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import useLabels from '@/app.hooks/useLabels';
import PagePane from '@/app.layouts/PagePane/PagePane';
import { Label } from '@/graphql/generated/types';
import Icon from '@/app.components/icons/Icon';
import CompanyLabelsList from './CompanyLabelsList';
import { CompanyLabelsContext } from './context';
import CompanyLabelsModal from './CompanyLabelsModal';
import CompanyLabelsDelete from './CompanyLabelsDelete';

const CompanyLabels = () => {
    const intl = useIntl();

    const showLabels = useFeatureFlag('contact.labels.enabled');

    const { data, loading, refetch, fetchMore, pagination, addLabel, updateLabel, deleteLabel } = useLabels({
        skip: !showLabels,
    });

    const [visible, setVisible] = useState(false);
    const [visibleDelete, setVisibleDelete] = useState(false);
    const [activeEntity, setActiveEntity] = useState<Label | undefined>(undefined);

    const content = {
        title: intl.formatMessage({ id: 'contact.labels' }),
        add: intl.formatMessage({ id: 'contact.labels.add' }),
    };

    return (
        <CompanyLabelsContext.Provider
            value={{
                data,
                loading,
                visible,
                refetch,
                addLabel,
                fetchMore,
                setVisible,
                pagination,
                updateLabel,
                deleteLabel,
                activeEntity,
                visibleDelete,
                setActiveEntity,
                setVisibleDelete,
            }}
        >
            <LayoutContainer>
                <PagePane
                    title={content.title}
                    actions={[
                        <Button
                            key="button"
                            type="submit"
                            variant="primary"
                            onClick={() => {
                                setVisible(true);
                            }}
                        >
                            <Icon icon="add" className="mr-1" />
                            {content.add}
                        </Button>,
                    ]}
                >
                    <CompanyLabelsList />

                    {visible && <CompanyLabelsModal show={visible} />}

                    {visibleDelete && <CompanyLabelsDelete />}
                </PagePane>
            </LayoutContainer>
        </CompanyLabelsContext.Provider>
    );
};

export default CompanyLabels;
