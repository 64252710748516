import React, { FC } from 'react';
import classNames from 'classnames';
import { Channel } from '@/graphql/generated/types';
import Status from '../../app.components/elements/Status/Status';

interface ChannelImageProps {
    channel: Pick<Channel, 'name' | 'logoUrl'>;
    status?: string;
    size?: 'sm' | 'base' | 'lg';
    shape?: 'circle' | 'square';
    disabled?: boolean;
}

const ChannelImage: FC<ChannelImageProps> = ({ channel, status, size = 'base', shape = 'circle', disabled }) => {
    let image, placeholder;

    if (channel.logoUrl) {
        image = channel.logoUrl;
    } else {
        placeholder = channel.name.substr(0, 1);
    }

    return (
        <div className="relative">
            <div
                style={{
                    fontSize: size === 'sm' ? 8 : 16,
                    filter: disabled ? 'grayscale(100%)' : undefined,
                }}
                className={classNames(
                    'overflow-hidden border border-solid border-gray-light',
                    'flex items-center justify-center text-white leading-none',
                    'bg-primary hover:no-underline',
                    {
                        //shapes:
                        'rounded-full': shape === 'circle',
                        'rounded-lg': shape === 'square',

                        //sizes:
                        'w-20': size === 'lg',
                        'h-20': size === 'lg',
                        'text-2xl': size === 'lg',

                        'w-12': size === 'base',
                        'h-12': size === 'base',

                        'h-6': size === 'sm',
                        'w-6': size === 'sm',
                    },
                )}
            >
                {image ? (
                    <img
                        src={image}
                        alt={channel.name}
                        className={classNames('object-contain', {
                            'w-20': size === 'lg',
                            'h-20': size === 'lg',

                            'w-12': size === 'base',
                            'h-12': size === 'base',

                            'h-6': size === 'sm',
                            'w-6': size === 'sm',
                        })}
                    />
                ) : (
                    placeholder
                )}
            </div>
            {status && (
                <div className="absolute top-0 right-0">
                    <Status status={status} />
                </div>
            )}
        </div>
    );
};

export default ChannelImage;
