import React from 'react';
import FileReaderInput from 'react-file-reader-input';
import { FormGroup } from 'react-bootstrap';
import Button from '@sweepbright/uikit/build/esm/button';
import classNames from 'classnames';
import { FormattedMessage } from '@/app.utils/services/Helpers/reactIntlWrapper';
import PreviewRequests from '@/requests/PreviewRequests';
import { getOriginalSize } from '@/app.utils/services/Helpers/image';

type Props = {
    error?: string;
    onUpload: (arg: any) => void;
    setError: (err: string) => void;
};

const LogoUpload = (props: Props) => {
    const [processing, setProcessing] = React.useState(false);

    return (
        <FormGroup className={classNames({ 'has-error': props.error })}>
            <label className="control-label">
                <FormattedMessage defaultMessage="Logo" id="logo.upload.label" />
            </label>
            <FileReaderInput
                as="binary"
                accept=".png"
                onChange={(event, [[, file]]) => {
                    setProcessing(true);
                    props.setError('');
                    const reader = new FileReader();
                    reader.onload = async (loadedEvent: any) => {
                        try {
                            const dataURL = loadedEvent.target.result;
                            const dimensions = await getOriginalSize(dataURL);
                            if (dimensions.width >= 200 && dimensions.height >= 200) {
                                const { url } = await new PreviewRequests().storePreview(file);
                                props.onUpload({ url, dataURL, file });
                            } else {
                                throw new Error('The image size is too small');
                            }
                        } catch (err) {
                            props.setError(err.message || 'Something went wrong and file could not be uploaded');
                            props.onUpload({ url: '', dataURL: '', file: null });
                        } finally {
                            setProcessing(false);
                        }
                    };

                    reader.readAsDataURL(file);
                }}
            >
                <Button variant="primary" disabled={processing}>
                    {processing ? (
                        <FormattedMessage id="logo.upload.processing" defaultMessage="Processing..." />
                    ) : (
                        <FormattedMessage id="logo.upload.add" defaultMessage="Select logo" />
                    )}
                </Button>
            </FileReaderInput>
            <p className="help-block">
                <FormattedMessage
                    id="logo.upload.help_message"
                    defaultMessage="Your logo must be at least 200px by 200px and a PNG file."
                />
            </p>
        </FormGroup>
    );
};

export default LogoUpload;
