import React from 'react';
import { Button } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';

export function EstateArchiverConfirmationModal({
    onConfirm,
    onCancel,
    archived,
    show,
}: {
    onConfirm: () => Promise<any>;
    onCancel: () => void;
    show: boolean;
    archived: boolean;
}) {
    const [confirming, setConfirming] = React.useState(false);

    return (
        <Modal onHide={onCancel} show={show}>
            <Modal.Header>
                <Modal.Title>
                    {archived ? (
                        <FormattedMessage id="modals.unarchive_confirmation.title" defaultMessage="Unarchive" />
                    ) : (
                        <FormattedMessage id="modals.archive_confirmation.title" defaultMessage="Archive" />
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {archived ? (
                    <FormattedMessage id="pages.properties.archive_panel.unarchive_confirmation_label" />
                ) : (
                    <FormattedMessage id="pages.properties.archive_panel.archive_confirmation_label" />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancel}>
                    <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                </Button>
                <Button
                    variant={archived ? 'info' : 'danger'}
                    disabled={confirming}
                    onClick={() => {
                        setConfirming(true);
                        onConfirm().finally(() => {
                            setConfirming(false);
                        });
                    }}
                >
                    {getActionMessage()}
                </Button>
            </Modal.Footer>
        </Modal>
    );

    function getActionMessage() {
        if (confirming) {
            if (archived) {
                return (
                    <FormattedMessage id="modals.confirmation.actions.unarchiving" defaultMessage="Unarchiving..." />
                );
            }

            return <FormattedMessage id="modals.confirmation.actions.archiving" defaultMessage="Archiving..." />;
        }

        if (archived) {
            return <FormattedMessage id="modals.confirmation.actions.unarchive" defaultMessage="Unarchive" />;
        }

        return <FormattedMessage id="modals.confirmation.actions.archive" defaultMessage="Archive" />;
    }
}
