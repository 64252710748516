import React from 'react';
import { useIntl } from 'react-intl';
import { HelpBlock } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';
import { useToasts } from '@sweepbright/notifications';
import { FormattedMessage } from 'react-intl-sweepbright';
import Button from '@/app.components/elements/Buttons/Button';
import withModals from '@/app.utils/Decorators/withModals';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import RemoveMemberModal from '@/app.components/modals/RemoveMemberModal';
import useUser from '@/app.hooks/useUser';
import { useArchiveUser } from '@/app.hooks/user/useArchiveUser';
import useRouter from '@/app.hooks/useRouter';
import { getCurrentUserRole } from '@/graphql/queries/users/getCurrentUserRole';
import { SimpleUser } from '../../types';

type Props = {
    open: any;
    close: any;
    modal: any;
    officeId?: string;
    member?: SimpleUser;
};

const MembersPageDelete: React.FC<Props> = props => {
    const { member, open, close, modal } = props;

    const intl = useIntl();
    const user = useUser();
    const router = useRouter();

    const { data } = useQuery(getCurrentUserRole);

    const [archiveUser] = useArchiveUser({ userId: member?.id || '' });

    const { addError } = useToasts();

    const handleRemoveMember = () => {
        close();

        archiveUser()
            .then(() => {
                router?.push('/members/');
            })
            .catch(() => {
                addError({ message: intl.formatMessage({ id: 'unauthorised_403' }) });
            });
    };

    const disabled =
        `${user.get('id')}` === member?.id || (data?.me?.role === 'team-admin' && member?.role === 'company-admin');

    return (
        <>
            {!disabled && (
                <FormPanel title={<FormattedMessage id="forms.member.delete" defaultMessage="Delete Member" />}>
                    <p>
                        <Button onClick={open.bind(this, 'remove-member')} className="btn-block" variant="ghost-danger">
                            <FormattedMessage id="forms.member.delete" defaultMessage="Delete Member" />
                        </Button>
                    </p>
                    <HelpBlock className="text-center">
                        <FormattedMessage
                            id="forms.member.delete.warning"
                            defaultMessage="This will permanently remove the member from the system. This cannot be undone."
                        />
                    </HelpBlock>
                </FormPanel>
            )}
            {modal('remove-member', <RemoveMemberModal onSubmit={handleRemoveMember} />)}
        </>
    );
};

export default withModals(MembersPageDelete);
