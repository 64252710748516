import libphonenumber from 'google-libphonenumber';

export function formatPhoneNumber(numStr: string, country?: string): string {
    const util = new libphonenumber.PhoneNumberUtil();

    let num;
    try {
        num = util.parse(numStr);
    } catch (e) {
        try {
            num = util.parse(numStr, country);
        } catch (e) {
            return numStr;
        }
    }

    return util.format(num, libphonenumber.PhoneNumberFormat.INTERNATIONAL);
}
