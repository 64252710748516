import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import useOffice from '@/app.hooks/useOffice';
import { getCompanySetting } from '../../app.redux/selectors';

const CurrencyAddonForOffice = ({ toLet = false, rentPeriod = 'month' }: { rentPeriod: string; toLet?: boolean }) => {
    const office = useOffice();

    const currency = office.getIn(['settings', 'data', 'currency'], 'EUR');

    if (toLet) {
        return (
            <span data-testid="currency-per-period-label">
                {currency}/
                <FormattedMessage id={`forms.labels.price.rent.period.${rentPeriod}`} defaultMessage={rentPeriod} />
            </span>
        );
    }

    return <span>{currency}</span>;
};

export default connect(
    createStructuredSelector({
        rentPeriod: getCompanySetting('rent_period'),
    }),
)(CurrencyAddonForOffice);
