import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import SideMenu from '@/app.components/navigations/SideMenu/SideMenu';
import SideMenuLink from '@/app.components/navigations/SideMenu/SideMenuLink';
import { CHANNELS_WEBSITE } from '../../app.routing/routes';

const Website = ({ children }) => (
    <LayoutContainer flex>
        <LayoutColumn size="small">
            <SideMenu color="gray-lighter">
                <SideMenuLink
                    fuzzyMatch
                    label={<FormattedMessage id="channels.home" defaultMessage="Homepage" />}
                    icon="house"
                    to={CHANNELS_WEBSITE('home')}
                />
                <SideMenuLink
                    fuzzyMatch
                    label={<FormattedMessage id="channels.text" defaultMessage="Text" />}
                    icon="notes"
                    to={CHANNELS_WEBSITE('text')}
                />
                <SideMenuLink
                    fuzzyMatch
                    label={<FormattedMessage id="channels.logo" defaultMessage="Look & Feel" />}
                    icon="access"
                    to={CHANNELS_WEBSITE('logo')}
                />
                <SideMenuLink
                    fuzzyMatch
                    label={<FormattedMessage id="channels.contact_settings" defaultMessage="Contact Settings" />}
                    icon="settings"
                    to={CHANNELS_WEBSITE('contact-settings')}
                />
                <SideMenuLink
                    fuzzyMatch
                    label={<FormattedMessage id="channels.domain" defaultMessage="Your Domain" />}
                    icon="networking"
                    to={CHANNELS_WEBSITE('domain')}
                />
            </SideMenu>
        </LayoutColumn>
        <LayoutColumn>{children}</LayoutColumn>
    </LayoutContainer>
);

Website.propTypes = {
    children: PropTypes.node,
};

export default Website;
