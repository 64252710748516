import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm as form } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { Validator } from '../../app.utils/services';
import FormPanel from '../../app.components/forms/FormPanel/FormPanel';
import Input from '../../app.components/forms/Input/Input';

@form({
    form: 'office/add',
    fields: ['name'],
    validate: Validator.validateWithRules({
        name: {
            type: 'string',
            required: true,
            allowEmpty: false,
        },
    }),
})
export default class AddOfficeForm extends Component {
    static propTypes = {
        close: PropTypes.func.isRequired,
        fields: PropTypes.object,
        handleSubmit: PropTypes.func.isRequired,
        valid: PropTypes.bool,
    };

    render() {
        const { fields, handleSubmit, valid, close } = this.props;

        const classes = {
            labelClassName: 'col-sm-4 add-office-form__label',
            wrapperClassName: 'col-sm-9',
        };

        return (
            <form onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="forms.add-office.title" defaultMessage="Add office" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormPanel horizontal>
                        <Input
                            type="text"
                            {...fields.name}
                            {...classes}
                            label={<FormattedMessage id="forms.add-office.name" defaultMessage="Office Name" />}
                        />
                    </FormPanel>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={close}>
                        <FormattedMessage id="forms.add-office.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button disabled={!valid} type="submit" variant="primary">
                        <FormattedMessage id="forms.add-office.cta" defaultMessage="Add" />
                    </Button>
                </Modal.Footer>
            </form>
        );
    }
}
