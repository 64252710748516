import React from 'react';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import { PropertyReference } from '@/graphql/generated/types';
import PropertyCardAssigneeImage from './PropertyCardAssigneeImage';

type Props = {
    propertyReference?: Maybe<PropertyReference>;
};

const PropertyCardAssignee: React.FC<Props> = props => {
    const { propertyReference } = props;

    const assignee = propertyReference?.assignee;

    if (!assignee) {
        return null;
    }

    const name = [assignee.first_name, assignee.last_name].filter(Boolean).join(' ');

    const email = assignee.email;
    const phone = assignee.phone;

    const isOnlyName = !email && !phone;

    return (
        <Tooltip
            label={
                <div className={`property-card-assignee__content${isOnlyName ? ' only-name' : ''}`}>
                    <PropertyCardAssigneeImage large assignee={assignee} />

                    <div className="property-card-assignee__info">
                        <span className="property-card-assignee__name">{name}</span>
                        {email && <span className="property-card-assignee__text">{email}</span>}
                        {phone && <span className="property-card-assignee__text">{phone}</span>}
                    </div>
                </div>
            }
        >
            <div className="property-card-assignee">
                <PropertyCardAssigneeImage assignee={assignee} />
                <small className="text-muted block truncate">{name}</small>
            </div>
        </Tooltip>
    );
};

export default PropertyCardAssignee;
