import React from 'react';
import { Map } from 'immutable';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '../../app.components/icons/Icon';

const Card: React.FunctionComponent<{ card: Map<string, any>; onChangeCard: () => void }> = ({
    card,
    onChangeCard,
}) => {
    return (
        <div>
            <p>
                <strong>{card.get('brand')}</strong>{' '}
                <FormattedMessage
                    id="credit_card.end_with"
                    defaultMessage="card ends with {digits}"
                    values={{
                        digits: <strong data-testid="card_last4">{card.get('last4')}</strong>,
                    }}
                />
            </p>
            <p>
                <FormattedMessage
                    id="credit_card.expires"
                    defaultMessage="Expires {date}"
                    values={{
                        date: (
                            <strong data-testid="card_expiry">
                                {card.get('exp_month')} / {card.get('exp_year')}
                            </strong>
                        ),
                    }}
                />
            </p>
            <p>
                <Button icon={<Icon icon="edit" />} variant="link" onClick={onChangeCard}>
                    <FormattedMessage id="card.edit" defaultMessage="Update Credit Card" />
                </Button>
            </p>
        </div>
    );
};

export default Card;
