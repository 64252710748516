import React, { useContext } from 'react';
import { MenuItem } from 'react-bootstrap';
import Dropdown from '@sweepbright/uikit/build/esm/dropdown';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '@/app.components/icons/Icon';
import ToggleButton from '@/app.components/elements/Buttons/ToggleButton';
import { OfficeGeoContext } from '../context';

const OfficeGeoAction = () => {
    const { setVisible } = useContext(OfficeGeoContext);

    return (
        <Dropdown>
            <ToggleButton bsRole="toggle" variant="primary">
                <Icon icon="add" className="mr-1" />
                <FormattedMessage id="location.templates.actions.add" defaultMessage="Add template" />
            </ToggleButton>
            <Dropdown.Menu className="pull-right">
                <MenuItem onSelect={() => setVisible('geometry')}>
                    <FormattedMessage id="forms.actions.preferences.draw_on_map" defaultMessage="Draw on map" />
                </MenuItem>
                <MenuItem divider />
                <MenuItem onSelect={() => setVisible('postalCode')}>
                    <FormattedMessage id="location.postal_code" defaultMessage="Postcode / Zip" />
                </MenuItem>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default OfficeGeoAction;
