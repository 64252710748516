import { fromJS, List, OrderedSet } from 'immutable';
import { SET_COMPANY, SET_CONTACT, SET_OFFICE, SET_OFFICE_MEMBER, SET_PROPERTY, SET_USER } from '../../actions';

export default function handleWithSubreducer(subreducer, entities, action, key, keyName = 'id') {
    if (entities && entities instanceof OrderedSet) {
        // If we're adding/updating an entity, prune the old version and prepend the new one
        if ([SET_PROPERTY, SET_CONTACT, SET_OFFICE, SET_OFFICE_MEMBER, SET_COMPANY, SET_USER].includes(action.type)) {
            const newEntity = fromJS(
                entities.find(entity => entity.get(keyName) === key) ||
                    action.property ||
                    action.contact ||
                    action.office ||
                    action.member,
                action.company,
            );
            entities = entities.remove(entities.find(entity => entity.get(keyName) === newEntity.get(keyName)));

            return new OrderedSet([subreducer(newEntity, action)]).union(entities);
        }

        // Else we just update the entity in place as usual
        return entities.map(entity => {
            if (entity.get(keyName) === key) {
                return subreducer(entity, action);
            }

            // Try updating unit nested within property otherwise
            const unitIndex = entity
                .getIn(['properties', 'data'], new List())
                .findIndex(unit => unit.get(keyName) === key);
            if (unitIndex !== -1) {
                return entity.updateIn(['properties', 'data', unitIndex], unit => subreducer(unit, action));
            }

            return entity;
        });
    }

    let index = entities.findIndex(entity => entity.get(keyName) === key);
    index = index === -1 ? entities.count() : index;

    return entities.update(index, entity => subreducer(entity, action));
}
