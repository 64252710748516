import React from 'react';
import { InputProps } from 'redux-form';
import classnames from 'classnames';
import { HelpBlock } from 'react-bootstrap';
import isFunction from 'lodash/isFunction';
import invariant from 'tiny-warning';
import useId from '@/app.hooks/useId';

type PlaceholderType = React.ReactNode;

type Props = {
    calculateCharacters: (text: string) => number;
    componentClass?: 'input' | 'textarea';
    field: InputProps<string>;
    horizontal?: boolean;
    label: Node;
    maxLength?: number;
    placeholder: PlaceholderType;
    rows?: number;
    style?: React.CSSProperties;
    id: string;
    extraBottom?: (fieldName: string) => JSX.Element;
};

export default function CharactersCountingInput(props: Props) {
    const {
        calculateCharacters = (value: string) => value.length,
        rows = 5,
        componentClass = 'textarea',
        maxLength,
        field,
        label,
        horizontal,
        placeholder,
        extraBottom,
    } = props;

    const id = useId(props.id);
    /**
     * Get the number of characters left available
     */
    const getCharactersCount = (): number => {
        const value = String(field.value || '');

        return calculateCharacters(value);
    };

    // check if the amount of characters
    // is above the limit
    const isOverBudget = (() => {
        if (maxLength) {
            const validMathLength = Number(props.maxLength);

            return getCharactersCount() > validMathLength;
        }

        return false;
    })();

    const classes = classnames(`bc-text-input-limiter`, {
        'bc-text-input-limiter-over-budget': isOverBudget,
        'col-sm-10': horizontal,
    });

    const labelClassName = classnames('control-label', { 'col-sm-4': horizontal });
    const formGroupClassName = classnames('form-group', { 'has-error': field.error || isOverBudget });

    return (
        <Wrapper horizontal={horizontal}>
            <div className={formGroupClassName} style={{ marginBottom: 24 }}>
                {label && (
                    <label htmlFor={id} className={labelClassName}>
                        {label}
                    </label>
                )}
                <div className={classes}>
                    <div className="relative">
                        <WithPlaceholder placeholder={placeholder}>
                            {placeholderText => {
                                if (componentClass === 'input') {
                                    return (
                                        <input
                                            name={field.name}
                                            className="form-control"
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                            value={field.value}
                                            placeholder={placeholderText}
                                            id={id}
                                        />
                                    );
                                }

                                return (
                                    <textarea
                                        id={id}
                                        name={field.name}
                                        className="form-control"
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        value={field.value}
                                        rows={rows}
                                        placeholder={placeholderText}
                                    />
                                );
                            }}
                        </WithPlaceholder>
                        {extraBottom && extraBottom(field.name)}
                        {maxLength && (
                            <div data-testid="counter" className="bc-text-input-limiter-count">
                                {getCharactersCount()}/{maxLength}
                            </div>
                        )}
                    </div>

                    {field.error && <HelpBlock>{field.error}</HelpBlock>}
                </div>
            </div>
        </Wrapper>
    );
}

function Wrapper({ children, horizontal = false }: { horizontal?: boolean; children: React.ReactNode }) {
    if (horizontal && children) {
        return <div className="form-horizontal">{children}</div>;
    }

    return children as React.ReactElement<any>;
}

// we can have two kind of placeholders passed to the
// component, one is a plain string
// and the other is a FormattedMessage instance
// so, because the placeholder attribute in a input field can be only string,
// or support the toString operation, we provide here
// this little component with a render prop that unifies both behaviours
function WithPlaceholder({
    children,
    placeholder = '',
}: {
    children: (s: string) => React.ReactElement<any>;
    placeholder: PlaceholderType;
}) {
    React.useEffect(() => {
        invariant(isFunction(children), 'You need to pass a render prop as a children');
    }, []);

    if (typeof placeholder === 'string') {
        return children(placeholder);
    }

    if (React.isValidElement(placeholder)) {
        return React.cloneElement(placeholder, {}, children);
    }

    return (children as any) as React.ReactElement<any>;
}
