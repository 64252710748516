import gql from 'graphql-tag';

export const UPDATE_GEO_TEMPLATE = gql`
    mutation UpdateGeoTemplate($officeId: ID!, $input: GeoTemplatesInput!) {
        updateGeoTemplate(officeId: $officeId, input: $input) {
            _id
            title
            office_id
            type
            postalCodes
            geometry
        }
    }
`;
