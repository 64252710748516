// @ts-nocheck
// eslint-disable
import React from 'react';

import { useQuery, useMutation, MutationTuple } from '@apollo/react-hooks';
import orderBy from 'lodash/orderBy';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { FormattedMessage } from 'react-intl-sweepbright';
import DrawRegionModal from '@/app.components/modals/LocationPreferences/DrawRegionModal';
import AddZipcodeModal from '@/app.components/modals/LocationPreferences/AddZipcodeModal';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { ADD_LOCATION_PREFERENCE } from '@/graphql/mutations/contacts/addLocationPreference';
import { updateConnection } from '@/graphql/utils';
import {
    AddLocationPreferenceMutation,
    PostalCodesLocationPreference,
    GeoJsonLocationPreference,
    CloneLocationTemplateMutation,
    CloneLocationTemplateMutationVariables,
    AddLocationPreferenceMutationVariables,
} from '@/graphql/generated/types';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import { CLONE_LOCATION_TEMPLATE } from '@/graphql/mutations/office/cloneLocationTemplate';
import useOffice from '@/app.hooks/useOffice';
import { GET_LOCATION_PREFERENCES_FOR_LEAD_PREFERENCE_QUERY } from '@/graphql/queries/contacts/getLocationPreferencesForLeadPreference';
import { useToasts } from '@sweepbright/notifications';
import { getBugsnagClient } from '@/app.config/bugsnag';
import EmptyAlert from '../../../../app.components/empty/EmptyAlert';
import InfiniteScroll from '../../../../app.layouts/InfiniteScroll';
import ChooseLocationTemplateModal from './ChooseLocationTemplateModal';
import LocationPreferenceListItem from './LocationPreferenceListItem';

const LocationPreferencesSection: React.FunctionComponent<{
    contactId: string;
    preferenceId: string;
}> = props => {
    const { contactId, preferenceId } = props;
    const officeId = useOffice().get('id');

    const { loading, locationsConnection, fetchMore, refetch } = useLocationPreferences({
        contactId,
        preferenceId,
    });

    const { addSuccess, addError } = useToasts();

    const [addLocationPreference]: MutationTuple<
        AddLocationPreferenceMutation,
        AddLocationPreferenceMutationVariables
    > = useMutation(ADD_LOCATION_PREFERENCE, {
        onCompleted() {
            refetch();
            addSuccess({
                message: (
                    <FormattedMessage
                        id="preferences.location_preference_added"
                        defaultMessage="Location preference added"
                    />
                ),
            });
        },
        onError(error) {
            addError({
                message: <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />,
            });
            getBugsnagClient().notify(error);
        },
    });

    const [cloneLocationTemplate]: MutationTuple<
        CloneLocationTemplateMutation,
        CloneLocationTemplateMutationVariables
    > = useMutation(CLONE_LOCATION_TEMPLATE, {
        onCompleted() {
            refetch();
            addSuccess({
                message: (
                    <FormattedMessage
                        id="preferences.location_preference_added"
                        defaultMessage="Location preference added"
                    />
                ),
            });
        },
        onError(error) {
            addError({
                message: <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />,
            });
            getBugsnagClient().notify(error);
        },
    });

    const locations = orderBy(
        (!loading && locationsConnection?.edges.map(edge => edge.node)) || [],
        ['createdAt'],
        ['desc'],
    );

    const [visibleModal, setVisibleModal] = React.useState<
        null | 'new_geojson_area' | 'new_postal_codes_area' | 'choose_template'
    >(null);

    const presetActions = [
        {
            label: <FormattedMessage id="location_preferences.choose_template" defaultMessage="Choose Template" />,
            // @ts-ignore
            handler: () => {
                setVisibleModal('choose_template');
            },
        },
        {
            label: <FormattedMessage id="common.forms.actions.preferences.draw_on_map" defaultMessage="Draw On Map" />,
            // @ts-ignore
            handler: () => {
                setVisibleModal('new_geojson_area');
            },
        },
        {
            label: <FormattedMessage id="common.forms.actions.preferences.postcode" defaultMessage="Postcode / Zip" />,
            // @ts-ignore
            handler: () => {
                setVisibleModal('new_postal_codes_area');
            },
        },
    ];

    const hasMorePages = locationsConnection?.pageInfo.hasNextPage ?? false;

    const infiniteLoaderProps = {
        onFetch: ({ page }: { page: number }) => {
            fetchMore({
                variables: {
                    id: contactId,
                    locationPreferencesPage: page,
                },
                updateQuery: updateConnection('contact.preference.locations'),
            });
        },
        currentPage: (!loading && locationsConnection?.pageInfo.currentPage) || 0,
        hasMorePages: () => hasMorePages,
    };

    return (
        <FormPanel
            title={
                <FormattedMessage
                    id="pages.contacts.sections.preferences.subsections.location.title"
                    defaultMessage="Location preference"
                />
            }
            action={
                <FormattedMessage
                    id="pages.contacts.sections.preferences.actions.add_location_preference"
                    defaultMessage="Add Preference"
                />
            }
            onAction={presetActions}
        >
            <InfiniteScroll {...infiniteLoaderProps}>
                {locations.length === 0 && !loading ? (
                    <EmptyAlert
                        icon="face-01"
                        body={
                            <FormattedMessage
                                id="pages.contacts.sections.preferences.alerts.no_location_prefrences"
                                defaultMessage="No location preferences yet"
                            />
                        }
                    />
                ) : null}

                <div className="bc-bordered-list">
                    {locations.map(location => {
                        return (
                            <LocationPreferenceListItem
                                key={location.id}
                                location={location}
                                leadId={contactId}
                                preferenceId={preferenceId}
                                onDeleted={refetch}
                            />
                        );
                    })}
                </div>
                {loading || hasMorePages ? <LoadingIndicator /> : null}
            </InfiniteScroll>
            {visibleModal === 'new_geojson_area' ? (
                <DrawRegionModal
                    formKey="new_geojson_area"
                    show={visibleModal === 'new_geojson_area'}
                    onClose={() => setVisibleModal(null)}
                    onSubmit={async (newArea: GeoJsonLocationPreference) => {
                        await addLocationPreference({
                            variables: {
                                leadId: contactId,
                                preferenceId,
                                locationPreference: {
                                    name: newArea.name,
                                    type: 'geojson',
                                },
                                geoJSONData: newArea.data,
                            },
                        });

                        setVisibleModal(null);
                    }}
                />
            ) : null}
            {visibleModal === 'new_postal_codes_area' ? (
                <AddZipcodeModal
                    formKey="new_postal_codes_area"
                    show={visibleModal === 'new_postal_codes_area'}
                    onClose={() => setVisibleModal(null)}
                    onSubmit={async (newArea: PostalCodesLocationPreference) => {
                        await addLocationPreference({
                            variables: {
                                leadId: contactId,
                                preferenceId: preferenceId,
                                locationPreference: {
                                    name: newArea.name,
                                    type: 'postal_codes',
                                },
                                postalCodesData: newArea.data,
                            },
                        });
                        setVisibleModal(null);
                    }}
                />
            ) : null}
            <ChooseLocationTemplateModal
                onClose={() => setVisibleModal(null)}
                show={visibleModal === 'choose_template'}
                onSelect={async (locationTemplateId: string) => {
                    await cloneLocationTemplate({
                        variables: {
                            leadId: contactId,
                            preferenceId,
                            locationTemplateId: locationTemplateId,
                            officeId,
                        },
                    });

                    setVisibleModal(null);
                }}
            />
        </FormPanel>
    );
};

export default withErrorBoundary(LocationPreferencesSection);

function useLocationPreferences({ contactId, preferenceId }: { contactId: string; preferenceId: string }) {
    const { data, loading, error, fetchMore, refetch } = useQuery(GET_LOCATION_PREFERENCES_FOR_LEAD_PREFERENCE_QUERY, {
        variables: {
            contactId,
            preferenceId,
        },
        skip: !contactId,
    });

    return {
        locationsConnection: data?.contact?.preference.locations,
        loading,
        error,
        fetchMore,
        refetch,
    };
}
