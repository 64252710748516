import React, { useState } from 'react';
import { events, track } from '@/app.utils/analytics';
import EmailActionButton from '@/app.components/elements/EmailActionButton/EmailActionButton';
import PropertyCard from '@/app.domains/properties/PropertyCard';
import ContactCard from '@/shared/contacts/ContactCard';
import { Contact, Estate } from '@/graphql/generated/types';
import { ContactActions } from '@/app.components';
import { ResourceDomainType } from '@/shared/resource/types';
import MatchListModal from '../MatchListModal';

type Props = {
    to: string;
    entity: any;
    status?: string;
    contact?: Contact;
    property?: Estate;
    selected: string[];
    disabled?: boolean;
    onMailed?: () => void;
    setSelected: Function;
    formattedSearchParams?: any;
    domainType: ResourceDomainType;
};

const MatchListCard: React.FC<Props> = props => {
    const {
        to,
        status,
        entity,
        contact,
        property,
        disabled,
        onMailed,
        selected,
        domainType,
        setSelected,
        formattedSearchParams,
    } = props;

    const [visible, setVisible] = useState(false);

    const trackAction = {
        property_matches: {
            sms: events.PROPERTY_MATCHING_SMS_LEAD_BTN_CLICKED,
            mail: events.PROPERTY_MATCHING_MAIL_MATCHES_BTN_CLICKED,
            call: events.PROPERTY_MATCHING_CALL_LEAD_BTN_CLICKED,
        },
        contact_matches: {
            sms: events.CONTACT_MATCHING_SMS_PROPERTY_BTN_CLICKED,
            mail: events.CONTACT_MATCHING_MAIL_MATCHES_BTN_CLICKED,
            call: events.CONTACT_MATCHING_CALL_PROPERTY_BTN_CLICKED,
        },
    };

    const defaultProps = {
        to,
        selectable: true,
        disabled: disabled,
        selected: selected.includes(entity.id),
        actions: [
            <ContactActions
                key="actions"
                onAction={type => {
                    track(trackAction[domainType][type]);
                }}
                {...(domainType === 'contact_matches'
                    ? {
                          contactId: contact?.id || '',
                      }
                    : {
                          contactId: '',
                          parentContact: entity,
                      })}
                mailChildren={
                    <EmailActionButton
                        status={status}
                        disabled={disabled}
                        hideLabel={disabled}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();

                            setVisible(true);

                            track(trackAction[domainType].mail);
                        }}
                    />
                }
            />,
        ],
        onToggleSelect: () => {
            if (!disabled) {
                setSelected(
                    selected.includes(entity.id) ? selected.filter(id => id !== entity.id) : [...selected, entity.id],
                );
            }
        },
    };

    return (
        <>
            {domainType === 'contact_matches' ? (
                <PropertyCard {...defaultProps} key={entity.id} property={entity} notPassSelectable />
            ) : (
                <ContactCard {...defaultProps} key={entity.id} contact={entity} />
            )}

            <MatchListModal
                isSingle
                show={visible}
                onMailed={onMailed}
                selected={selected}
                onCancel={() => {
                    setVisible(false);
                }}
                domainType={domainType}
                formattedSearchParams={formattedSearchParams}
                contacts={domainType === 'contact_matches' ? [contact] : [entity]}
                properties={domainType === 'contact_matches' ? [entity] : [property]}
            />
        </>
    );
};

export default MatchListCard;
