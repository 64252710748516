// @ts-nocheck
import React, { Component } from 'react';
import { Image as BaseImage } from 'react-bootstrap';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import ErrorBoundary from 'react-error-boundary';
import { FormattedMessage } from 'react-intl-sweepbright';
import {
    // eslint-disable-next-line @typescript-eslint/camelcase
    unstable_createResource,
} from '@/app.utils/react-cache/createResource';

let createResource;

// eslint-disable-next-line @typescript-eslint/camelcase
if (unstable_createResource) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    createResource = unstable_createResource;
}

const hashingFn = ({ src, srcSet }: { src: string; srcSet?: string }) => `${src}${srcSet}`;

export const ImgResource = createResource(({ src, srcSet }: { src: string; srcSet?: string }) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = src;
        if (srcSet) {
            image.srcset = srcSet;
        }
        image.onload = resolve;
        image.onerror = () => reject('failed to load image');
    }) as Promise<Event>;
}, hashingFn);

export const Img: React.FC<React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
>> = props => {
    const { src, srcSet } = props;
    if (src) {
        ImgResource.read({ src, srcSet });
    }

    return <BaseImage {...props} />;
};

export default class ImageLoader extends Component<
    React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
        fallback?: React.ReactNode;
        loader: React.ReactElement;
    }
> {
    static defaultProps = {
        fallback: (
            <div>
                <FormattedMessage id="imageloader.failed_to_load_image" defaultMessage="Failed to load image" />
            </div>
        ),
        loader: (
            <div className="flex items-center justify-center w-full h-full">
                <LoadingIndicator />
            </div>
        ),
    };

    render() {
        const { fallback, loader, src, ...props } = this.props;

        return (
            <React.Suspense fallback={loader}>
                <ErrorBoundary FallbackComponent={() => fallback || null}>
                    <Img src={src} {...props} />
                </ErrorBoundary>
            </React.Suspense>
        );
    }
}
