import { roomTypesList } from '@/app.data';

const MAX_ROOMS = 20;

const rooms: string[] = roomTypesList.toArray();

function normalizeRoomsCount(value) {
    if (value >= MAX_ROOMS) {
        return MAX_ROOMS;
    }

    return value;
}

export const normalizeStructureForm = Object.fromEntries(
    rooms.map(roomType => [`structure.${roomType}`, normalizeRoomsCount]),
);
