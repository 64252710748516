// @ts-nocheck
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import { fetchCompany, fetchCompanyTokens } from '../../app.redux/actions';
import { CHANNELS_PORTALS, CHANNELS_WEBSITE } from '../../app.routing/routes';
import { getCompany } from '../../app.redux/selectors';
import LeftNavBar from '../../app.components/navigations/LeftNavBar';
import SideMenuLink from '../../app.components/navigations/SideMenu/SideMenuLink';

class ChannelsLayout extends Component {
    static propTypes = {
        children: PropTypes.node,
        company: PropTypes.instanceOf(Map),
        fetchCompany: PropTypes.func.isRequired,
        fetchCompanyTokens: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        agencyWebsiteSection: PropTypes.bool,
    };

    componentDidMount() {
        this.props.fetchCompany(this.props.company.get('id'));
        this.props.fetchCompanyTokens(this.props.company.get('id'));
    }

    render() {
        const { agencyWebsiteSection } = this.props;

        return (
            <LayoutContainer>
                <LeftNavBar location={this.props.location.pathname}>
                    {agencyWebsiteSection ? (
                        <SideMenuLink
                            fuzzyMatch
                            label={<FormattedMessage id="navigation.channels.website" defaultMessage="Website" />}
                            icon="website"
                            to={CHANNELS_WEBSITE('')}
                            iconActive="website-fill"
                        />
                    ) : null}
                    <SideMenuLink
                        fuzzyMatch
                        label={<FormattedMessage id="navigation.channels.portals" defaultMessage="Portals" />}
                        icon="portal"
                        to={CHANNELS_PORTALS}
                        iconActive="portal-fill"
                    />
                </LeftNavBar>
                <LayoutColumn>{this.props.children}</LayoutColumn>
            </LayoutContainer>
        );
    }
}

export default connect(
    createStructuredSelector({
        company: getCompany,
        agencyWebsiteSection: getFeature('admin-channels-website.agencyWebsiteConfigurationSection.enabled'),
    }),
    {
        fetchCompany,
        fetchCompanyTokens,
    },
)(ChannelsLayout);
