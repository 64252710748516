import { stopSubmit } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { put } from 'redux-saga/effects';
import {
    fetchCompany,
    fetchCompanyCard,
    fetchCompanyFeatures,
    fetchCompanyTokens,
    fetchPlans,
    fetchUser,
    isSaving,
    setErrors,
} from '../../actions';
import CompaniesRequests from '../../../requests/CompaniesRequests';
import apiCall from '../Effects/apiCall';
import formatApiErrors from '../Helpers/formatApiErrors';

function noop() {
    // do nothing
}

export default function* onUpdateCompanySettings({
    companyId,
    attributes: { resolve = noop, reject = noop, ...attributes },
}) {
    try {
        yield put(isSaving());

        const photo = attributes.photo;
        if (!isEmpty(photo) && photo.file) {
            yield apiCall(new CompaniesRequests().photos, companyId, photo);
        } else if (!isEmpty(photo) && !photo.file && !photo.data.url) {
            yield apiCall(new CompaniesRequests().removePhoto, companyId);
        }

        // This attribute will saved via the file attach upload
        delete attributes.homepage_cover_image;
        if (attributes.homepage_cover_image_file) {
            yield apiCall(
                new CompaniesRequests().images,
                companyId,
                'homepage_cover_image',
                attributes.homepage_cover_image_file,
            );
        }

        const token = attributes.token;
        if (token) {
            if (token.access_token.access_token) {
                yield apiCall(new CompaniesRequests().storeToken, companyId, token);
            } else {
                yield apiCall(new CompaniesRequests().removeToken, companyId, token.channel);
            }

            yield put(fetchCompanyTokens(companyId));
        }

        yield apiCall(new CompaniesRequests().updateSettings, companyId, attributes);
        yield [
            put(isSaving(false)),
            put(fetchCompany(companyId)),
            put(fetchPlans(companyId)),
            put(fetchCompanyCard(companyId)),
            put(fetchCompanyFeatures(companyId)),
            put(fetchUser()),
        ];

        // wait for the card to be set
        resolve();
    } catch (response) {
        const errors = formatApiErrors(response);

        yield [
            put(stopSubmit('channels/settings', errors)),
            put(stopSubmit('company/settings', { invoice: errors })),
            put(setErrors(response.data ? response.data.errors : response)),
        ];

        reject(errors);
    }
}
