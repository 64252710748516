// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import useCompany from '@/app.hooks/useCompany';
import CompanyRepository from '../../app.utils/services/Repositories/CompanyRepository';
import Input from './Input/Input';

const ContactLocales = ({ value, onChange, ...props }) => {
    const company = useCompany();

    return (
        <span>
            {new CompanyRepository(company).getEnabledLanguages().map(({ label, value: locale }) => (
                <Input
                    name="locale"
                    key={locale}
                    type="radio"
                    label={<FormattedMessage id={`general.locales.${locale}`} defaultMessage={label} />}
                    value={locale}
                    checked={value === locale}
                    onChange={onChange}
                    {...props}
                />
            ))}
        </span>
    );
};

export default ContactLocales;
