// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { NotificationStatus } from '../state';
import { NotificationToast } from './Toasts';
import { ErrorIcon } from './icons/ErrorIcon';
import { SuccessIcon } from './icons/SuccessIcon';
import { WarningIcon } from './icons/WarningIcon';
import { InfoIcon } from './icons/InfoIcon';

const getColor = (colors: IColors, status: NotificationStatus) => colors[status];

const getIcon = (status: NotificationStatus) => {
    switch (status) {
        case NotificationStatus.ERROR:
            return ErrorIcon;
        case NotificationStatus.WARNING:
            return WarningIcon;
        case NotificationStatus.SUCCESS:
            return SuccessIcon;
        case NotificationStatus.NOTICE:
            return InfoIcon;
        default:
            return InfoIcon;
    }
};

export interface IColors {
    [NotificationStatus.ERROR]: string;
    [NotificationStatus.WARNING]: string;
    [NotificationStatus.SUCCESS]: string;
    [NotificationStatus.NOTICE]: string;
}

export type IButtonType = React.ComponentType<{
    small?: boolean;
    secondary?: boolean;
    variant?: string;
    style?: React.CSSProperties;
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}>;

export type Props = {
    toast: NotificationToast;
    removeToast: (id: string) => void;
    getRef?: React.LegacyRef<HTMLDivElement>;
    colors: IColors;
    Button: IButtonType;
};

const TIME_ALIVE = {
    [NotificationStatus.SUCCESS]: 7000,
    [NotificationStatus.NOTICE]: 10000,
    [NotificationStatus.WARNING]: 12000,
    [NotificationStatus.ERROR]: 20000,
};

const isSticky = (toast: NotificationToast) => {
    if (toast.notification.status === NotificationStatus.ERROR && toast.notification.actions) {
        return true;
    }

    if (toast.notification.sticky) {
        return true;
    }

    return false;
};

export function Toast({ toast, removeToast, getRef, colors, Button }: Props) {
    const Icon = getIcon(toast.notification.status);
    const mouseOverRef = React.useRef(false);
    React.useEffect(() => {
        if (isSticky(toast)) {
            return;
        }
        let timer;

        function onTimeToLiveExpired() {
            // if when the time to live expires
            // and the mouse is over the toast,
            // we extend the time to live for 100 ms more
            if (mouseOverRef.current) {
                clearTimeout(timer);
                setTimeout(onTimeToLiveExpired, 100);
            } else {
                removeToast(toast.id);
            }
        }

        timer = setTimeout(onTimeToLiveExpired, toast.notification.timeAlive || TIME_ALIVE[toast.notification.status]);

        return () => {
            clearTimeout(timer);
            timer = null;
        };
    }, []);

    return (
        <div
            onMouseEnter={() => {
                mouseOverRef.current = true;
            }}
            onMouseLeave={() => {
                mouseOverRef.current = false;
            }}
            ref={getRef}
            className="flex flex-col bg-white overflow-hidden py-4"
            style={{
                color: '#302e34',
                borderRadius: 2,
                position: 'relative',
                border: '1px solid #dedfe0',
                width: 450,
                overflow: 'hidden',
                marginBottom: '0.5rem',
                userSelect: 'none',
            }}
        >
            <div className="flex items-center w-full px-4">
                <div
                    style={{
                        color: getColor(colors, toast.notification.status),
                        width: 32,
                        fontSize: '24px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Icon />
                </div>
                <div style={{ width: '100%', marginLeft: 10 }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div
                            style={{
                                width: '100%',
                                display: toast.notification.title && toast.notification ? 'block' : 'flex',
                                alignItems: (!toast.notification.title || !toast.notification) && 'center',
                            }}
                        >
                            <div
                                style={{
                                    fontSize: 14,
                                    fontWeight: 'normal',
                                    color: '#302e34',
                                    fontFamily: 'LFT Etica',
                                    lineHeight: 1.43,
                                }}
                            >
                                {toast.notification.title ? toast.notification.title : toast.notification.message}
                            </div>

                            {toast.notification.title && (
                                <div
                                    style={{
                                        color: '#8e8e93',
                                        fontSize: '12px',
                                        fontFamily: 'LFT Etica',
                                    }}
                                >
                                    {toast.notification.message}
                                </div>
                            )}
                        </div>

                        <div className="ml-2 " style={{ width: 60, alignSelf: 'center' }}>
                            <Button variant="link" onClick={() => removeToast(toast.id)}>
                                <FormattedMessage id="general.action.dismiss" defaultMessage="Dismiss" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {toast.notification.actions && (
                    <div
                        style={{
                            display: 'flex',
                            fontSize: '.875rem',
                        }}
                    >
                        {toast.notification.actions.primary && toast.notification.actions.primary[0] && (
                            <Button
                                variant="primary"
                                onClick={() => {
                                    removeToast(toast.id);
                                    toast.notification.actions.primary[0].run();
                                }}
                                style={{
                                    marginTop: '1rem',
                                    marginRight: '0.75rem',
                                }}
                            >
                                {toast.notification.actions.primary[0].label}
                            </Button>
                        )}

                        {toast.notification.actions.secondary && toast.notification.actions.secondary[0] && (
                            <Button
                                variant="ghost"
                                onClick={() => {
                                    toast.notification.actions.secondary[0].run();
                                }}
                                style={{
                                    marginTop: '1rem',
                                    marginLeft: '0.5rem',
                                    lineHeight: 1,
                                }}
                            >
                                {toast.notification.actions.secondary[0].label}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
