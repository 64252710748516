import React from 'react';
import { useSelector } from 'react-redux';
import Shimmer from '@sweepbright/uikit/build/esm/shimmer';
import ContactActions from '@/app.domains/contacts/ContactActions';
import ContactTitle from '@/app.domains/contacts/ContactTitle';
import { FormattedMessage } from 'react-intl-sweepbright';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import SideMenuLink from '@/app.components/navigations/SideMenu/SideMenuLink';
import {
    CONTACT_DETAILS,
    CONTACT_PREFERENCES,
    CONTACT_MATCH,
    CONTACT_TIMELINE,
    CONTACT_VENDOR_PROPERTIES,
    CONTACT_REPORT,
    CONTACT_SCHEDULE,
    CONTACT_OFFERS,
    CONTACTS,
} from '@/app.routing/routes';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import useContact from '@/app.hooks/useContact';
import { ContactType } from '@/graphql/generated/types';
import SideMenuBadge from '@/app.components/navigations/SideMenu/SideMenuBadge';
import { useContactStats } from '@/app.hooks/offer/useContactStats';
import { LayoutContext } from '@/app.components/layouts/utils';
import ContactLabel from '@/shared/contacts/ContactLabel';
import { useExponentialPolling } from '@/app.hooks/useExponentialPolling';
import WrapperAsideProfile from '@/components/Wrapper/WrapperAside/WrapperAsideProfile';
import ContactProfileAction from '@/new.domains/contacts/ContactShow/ContactProfile/ContactProfileAction';
import { useContactLabels } from '@/new.domains/contacts/hooks/useContactLabels';
import ContactTypeComponent from '../../../app.domains/contacts/ContactType';

function ContactPage({ params, location, children, router }) {
    const contactId = params.contact;

    const showLabels = useFeatureFlag('contact.labels.enabled');
    const isLeadTimelineFFEnabled = useSelector(getFeature('lead.timeline.enabled'));
    const isVendorTimelineFFEnabled = useSelector(getFeature('vendor.timeline.enabled'));
    const showSchedule = useSelector(getFeature('properties.visitScheduler.enabled'));
    const { loading, contact } = useContact(contactId);

    const { data: contactLabels } = useContactLabels(contactId);

    const isVendor = contact?.type?.toUpperCase() === ContactType.Vendor;
    const isLead = contact?.type?.toUpperCase() === ContactType.Lead;
    const isTimelineEnabled = canShowTimeline();

    const { data, refetch: refetchOffersStats } = useContactStats({
        variables: { filter: { contactId } },
    });

    const [startExponentialRefetch, stopExponentialRefetch] = useExponentialPolling(
        () => {
            refetchOffersStats();
        },
        {
            initialDelay: 500,
            maxIterations: 8,
        },
    );

    React.useEffect(() => {
        if (!children && !loading) {
            const timelinePath = CONTACT_TIMELINE(contactId);
            const detailsPath = CONTACT_DETAILS(contactId);
            const indexPath = isTimelineEnabled ? timelinePath : detailsPath;

            router.replace(indexPath);
        }
    }, [children, contactId, contact, isTimelineEnabled, router, loading]);

    const handleOfferStatsRefetch = () => {
        stopExponentialRefetch();
        startExponentialRefetch();
    };

    function initials({ firstName, lastName }: { firstName?: string; lastName?: string } = {}): string {
        return [firstName?.charAt(0) ?? '', lastName?.charAt(0) ?? ''].join('');
    }

    return (
        <LayoutContext.Provider value={{ domainType: 'contact', handleOfferStatsRefetch }}>
            <LayoutColumn size="medium" className="flex flex-column bc-bg-gray-lightest">
                {contact && (
                    <WrapperAsideProfile
                        imageLink={CONTACTS}
                        info={
                            <>
                                <div className="flex-grow truncate">
                                    <Shimmer width="80%" isDataLoaded={!loading}>
                                        <h4 className="mt-0 mb-1">
                                            <ContactTitle contact={contact} />
                                        </h4>
                                    </Shimmer>
                                    <Shimmer width="40%" isDataLoaded={!loading}>
                                        <ContactTypeComponent type={contact?.type} />
                                    </Shimmer>
                                </div>
                                <ContactActions contactId={contact?.id} />
                            </>
                        }
                        imageFooter={
                            showLabels && contactLabels?.length ? (
                                <>
                                    {contactLabels.map(el => {
                                        return (
                                            <ContactLabel
                                                tooltip
                                                key={el.id}
                                                label={el.name}
                                                color={el.color}
                                                description={el.description}
                                            />
                                        );
                                    })}
                                </>
                            ) : (
                                undefined
                            )
                        }
                        imageAction={showLabels ? <ContactProfileAction contactId={contact?.id} /> : undefined}
                        imageAlt={initials({ firstName: contact.firstName || '-', lastName: contact.lastName })}
                        imageLinkText={<FormattedMessage id="navigation.aside.contacts" defaultMessage="Contacts" />}
                    />
                )}
                <div className="o-flex__item overflow-auto">
                    {!loading && (
                        <ul className="c-side-menu">
                            {isTimelineEnabled && (
                                <SideMenuLink
                                    label={
                                        <FormattedMessage id="navigation.aside.activity" defaultMessage="Activity" />
                                    }
                                    icon="clock"
                                    to={CONTACT_TIMELINE(contactId)}
                                    location={location}
                                    fuzzyMatch
                                />
                            )}
                            <SideMenuLink
                                label={<FormattedMessage id="offers.title" defaultMessage="Offers" />}
                                icon="offers"
                                to={CONTACT_OFFERS(contactId)}
                                location={location}
                                fuzzyMatch
                                extra={
                                    (data?.contactStats?.hasAcceptedOffer && (
                                        <SideMenuBadge
                                            size="sm"
                                            type="info"
                                            value={
                                                <FormattedMessage
                                                    id="offers.status.accepted"
                                                    defaultMessage="Accepted"
                                                />
                                            }
                                        />
                                    )) ||
                                    (data?.contactStats?.numberOfPendingOffers && (
                                        <SideMenuBadge
                                            size="md"
                                            type="default"
                                            value={data.contactStats.numberOfPendingOffers}
                                        />
                                    )) ||
                                    undefined
                                }
                            />
                            {showSchedule && (
                                <SideMenuLink
                                    label={
                                        <FormattedMessage
                                            id="navigation.aside.contact_schedule"
                                            defaultMessage="Schedule"
                                        />
                                    }
                                    icon="calendar"
                                    to={CONTACT_SCHEDULE(contactId)}
                                    location={location}
                                    fuzzyMatch
                                />
                            )}
                            <SideMenuLink
                                label={
                                    <FormattedMessage
                                        id="pages.contacts.details.title"
                                        defaultMessage="Contact Details"
                                    />
                                }
                                icon="face-03"
                                to={CONTACT_DETAILS(contactId)}
                                location={location}
                                fuzzyMatch
                            />
                            {isLead && (
                                <SideMenuLink
                                    label={
                                        <FormattedMessage
                                            id="pages.contacts.section_labels.preferences"
                                            defaultMessage="Preferences"
                                        />
                                    }
                                    icon="house"
                                    to={CONTACT_PREFERENCES(contactId)}
                                    location={location}
                                    fuzzyMatch
                                />
                            )}
                            {isVendor && (
                                <SideMenuLink
                                    label={
                                        <FormattedMessage
                                            id="pages.contacts.section_labels.report"
                                            defaultMessage="Report"
                                        />
                                    }
                                    icon="description"
                                    to={CONTACT_REPORT(contactId)}
                                    location={location}
                                    fuzzyMatch
                                />
                            )}
                            {isLead ? (
                                <SideMenuLink
                                    label={<FormattedMessage id="navigation.aside.match" defaultMessage="Match" />}
                                    icon="matching"
                                    to={CONTACT_MATCH(contactId)}
                                    location={location}
                                    fuzzyMatch
                                />
                            ) : null}
                            {isVendor ? (
                                <SideMenuLink
                                    label={
                                        <FormattedMessage
                                            id="navigation.aside.properties"
                                            defaultMessage="Properties"
                                        />
                                    }
                                    icon="house"
                                    to={CONTACT_VENDOR_PROPERTIES(contactId)}
                                    location={location}
                                    fuzzyMatch
                                />
                            ) : null}
                        </ul>
                    )}
                </div>
            </LayoutColumn>
            <LayoutColumn className="bc-bg-shadow-left">
                <ErrorBoundary>{!loading && children}</ErrorBoundary>
            </LayoutColumn>
        </LayoutContext.Provider>
    );

    function canShowTimeline() {
        if (isLead) {
            return isLeadTimelineFFEnabled;
        } else if (isVendor) {
            return isVendorTimelineFFEnabled;
        }

        return false;
    }
}

export default ContactPage;
