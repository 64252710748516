import { createReducerWithSanitizer } from 'redux-reducer-factory';
import {
    SET_COMPANIES,
    SET_COMPANY_ADMINS,
    REMOVE_COMPANY_ADMIN,
    SET_COMPANY_LEADS,
    REMOVE_COMPANY_LEAD,
    RESET_COMPANY_LEADS,
    SET_COMPANY,
    SET_COMPANY_TOKENS,
    SET_HIGHLIGHTS,
    SET_COMPANY_CARD,
    SET_COMPANY_FEATURES,
} from '@/app.redux/actions';
import { LOGOUT } from '@/app.redux/actions/AuthActions';
import handleWithSubreducer from './Helpers/handleWithSubreducer';
import companyReducer from './Subreducers/CompanyReducer';
import sanitize from './Helpers/sanitizeOrderedSet';
import concatSetWith from './Helpers/concatSetWith';

const companyActions = [
    SET_COMPANY_ADMINS,
    REMOVE_COMPANY_ADMIN,
    SET_COMPANY_LEADS,
    REMOVE_COMPANY_LEAD,
    RESET_COMPANY_LEADS,
    SET_COMPANY,
    SET_COMPANY_TOKENS,
    SET_HIGHLIGHTS,
    SET_COMPANY_CARD,
    SET_COMPANY_FEATURES,
];

export default createReducerWithSanitizer([], sanitize, {
    [SET_COMPANIES]: (companies, action) => concatSetWith(action.companies)(companies),
    [LOGOUT]: () => sanitize([]),
    [companyActions]: (companies, action) => {
        return handleWithSubreducer(companyReducer, companies, action, action.companyId || action.company.id);
    },
});
