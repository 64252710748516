import gql from 'graphql-tag';

export const GET_USER_BY_ID_QUERY = gql`
    query GetUserById($id: ID!) {
        user(id: $id) {
            id
            firstName
            lastName
            email
            phone
            role
            locale
            photo
        }
    }
`;
