import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl-sweepbright';
import { ARCHIVE_CONTACT_MUTATION, UNARCHIVE_CONTACT_MUTATION } from '@/graphql/mutations/contacts/updateContact';
import { useToasts } from '@sweepbright/notifications';

const GET_CONTACT_ARCHIVED_STATUS_QUERY = gql`
    query GetContactArchivedStatus($contactId: ID!) {
        contact(id: $contactId) {
            id
            archived
        }
    }
`;

export function useContactArchiver(contactId: string) {
    const { data, loading } = useQuery(GET_CONTACT_ARCHIVED_STATUS_QUERY, {
        variables: {
            contactId: contactId,
        },
    });

    const contact = data?.contact;

    const [archiveContact] = useMutation(ARCHIVE_CONTACT_MUTATION);
    const [unarchiveContact] = useMutation(UNARCHIVE_CONTACT_MUTATION);
    const { addSuccess, addError } = useToasts();

    const toggleArchived = React.useCallback(async () => {
        try {
            if (contact?.archived) {
                await unarchiveContact({ variables: { contactId } });
                addSuccess({
                    message: <FormattedMessage id="contact.state.unarchived" defaultMessage="Contact unarchived" />,
                });
            } else {
                await archiveContact({ variables: { contactId } });
                addSuccess({
                    message: <FormattedMessage id="contact.state.archived" defaultMessage="Contact archived" />,
                });
            }
        } catch (err) {
            addError({
                message: <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />,
            });
        }
    }, [contact]);

    return { loading, isArchived: contact?.archived, toggleArchived };
}
