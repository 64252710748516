import React from 'react';
import produce from 'immer';
import { useQuery } from '@apollo/react-hooks';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import ContactCard from '@/shared/contacts/ContactCard';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { PROPERTY_TIMELINE_CONTACT, PROPERTY_UNIT_TIMELINE_CONTACT } from '@/app.routing/routes';
import EmptyState from '@/app.components/empty/EmptyState';
import { GET_INTERACTED_CONTACTS_QUERY } from '@/graphql/queries/properties/getInteractedLeads';
import { ContactActions } from '@/app.components';
import './PropertyLeads.scss';

export default React.forwardRef(function PropertyInteractedContactList(
    { propertyId, projectId, type }: { propertyId: string; projectId: string | undefined; type: 'lead' | 'vendor' },
    forwardedRef,
) {
    const { loading, data, fetchMore } = useQuery(GET_INTERACTED_CONTACTS_QUERY, {
        variables: {
            propertyId,
            type: type.toUpperCase(),
            splitByInterest: true,
        },
        skip: !propertyId,
        errorPolicy: 'ignore',
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    });

    /* eslint-disable no-use-before-define */
    const isEmpty =
        !loading &&
        data?.estate.interestedContacts?.nodes.length === 0 &&
        data?.estate.notInterestedContacts?.nodes.length === 0;
    /* eslint-enable no-use-before-define */

    /* eslint-disable no-use-before-define */
    const interestedContactsConnection = data?.estate.interestedContacts;
    const interestedContacts = interestedContactsConnection?.nodes ?? [];
    const notInterestedContactsConnection = data?.estate.notInterestedContacts;
    const notInterestedContacts = notInterestedContactsConnection?.nodes ?? [];
    /* eslint-enable no-use-before-define */

    React.useImperativeHandle(
        forwardedRef,
        () => {
            return {
                refresh: fetchMoreBefore,
            };

            function fetchMoreBefore() {
                fetchMore({
                    variables: {
                        beforeInterestedLeads: interestedContactsConnection.pageInfo.startCursor,
                        beforeNotInterestedLeads: notInterestedContactsConnection.pageInfo.startCursor,
                    },
                    updateQuery: updateFetchMoreBeforeQuery,
                });
            }
        },
        [interestedContactsConnection, notInterestedContactsConnection, fetchMore],
    );

    if (isEmpty) {
        return (
            <EmptyState
                title={
                    <FormattedMessage
                        id="property.activity.timeline_section.empty.title"
                        defaultMessage="Whoops, no activity yet"
                    />
                }
                body={
                    <FormattedMessage
                        id="property.activity.leads_section.empty.subtitle"
                        defaultMessage="Activities per lead will show up here."
                    />
                }
            />
        );
    }

    return (
        <>
            {interestedContacts.length > 0 ? (
                <LeadSection
                    title={
                        <FormattedMessage id="modals.activity.leads.interested.section" defaultMessage="Interested" />
                    }
                    leads={interestedContacts}
                    propertyId={propertyId}
                    projectId={projectId}
                    loading={loading}
                    hasMore={
                        interestedContactsConnection?.pageInfo.hasNextPage ||
                        interestedContactsConnection?.pageInfo?.endCursor
                    }
                    onFetchMore={() => {
                        fetchMore({
                            variables: {
                                afterInterestedLeads: interestedContactsConnection.pageInfo.endCursor,
                            },
                            updateQuery: updateFetchMoreQuery,
                        });
                    }}
                />
            ) : null}
            {notInterestedContacts.length > 0 ? (
                <LeadSection
                    title={<FormattedMessage id="interaction.lead_not_interested" defaultMessage="Not Interested" />}
                    leads={notInterestedContacts}
                    propertyId={propertyId}
                    projectId={projectId}
                    loading={loading}
                    hasMore={
                        notInterestedContactsConnection?.pageInfo.hasNextPage ||
                        notInterestedContactsConnection?.pageInfo?.endCursor
                    }
                    onFetchMore={() => {
                        fetchMore({
                            variables: {
                                afterNotInterestedLeads: notInterestedContactsConnection.pageInfo.endCursor,
                            },
                            updateQuery: updateFetchMoreQuery,
                        });
                    }}
                />
            ) : null}
        </>
    );
});

function LeadSection({
    leads,
    propertyId,
    projectId,
    title,
    hasMore,
    loading,
    onFetchMore,
}: {
    title: React.ReactNode;
    leads: any[];
    propertyId: string;
    projectId: string | undefined;
    loading: boolean;
    hasMore: boolean;
    onFetchMore: () => void;
}) {
    return (
        <FormPanel title={title}>
            <ul className="bc-bordered-list">
                {leads.map(lead => {
                    return (
                        <li key={lead.id}>
                            <ContactCard
                                contact={lead}
                                to={
                                    projectId
                                        ? PROPERTY_UNIT_TIMELINE_CONTACT(projectId, propertyId, lead.id)
                                        : PROPERTY_TIMELINE_CONTACT(propertyId, lead.id)
                                }
                                actions={[<ContactActions key="contact_actions" contactId={lead.id} />]}
                            />
                        </li>
                    );
                })}
            </ul>
            {hasMore && !loading ? (
                <div className="text-center">
                    <Button onClick={onFetchMore} variant="link">
                        <FormattedMessage id="interaction.contact_list.load_more" defaultMessage="Load More" />
                    </Button>
                </div>
            ) : null}
        </FormPanel>
    );
}

function updateFetchMoreQuery(prevResult, { fetchMoreResult, variables }) {
    const { afterNotInterestedLeads } = variables;

    return produce(prevResult, draft => {
        let listToUpdate = afterNotInterestedLeads ? 'notInterestedContacts' : 'interestedContacts';
        // afterInterestedProperties
        draft.estate[listToUpdate].nodes = draft.estate[listToUpdate].nodes.concat(
            fetchMoreResult.estate[listToUpdate].nodes,
        );

        draft.estate[listToUpdate].pageInfo.endCursor = fetchMoreResult.estate[listToUpdate].pageInfo.endCursor;
        draft.estate[listToUpdate].pageInfo.hasNextPage = fetchMoreResult.estate[listToUpdate].pageInfo.hasNextPage;
    });
}

function updateFetchMoreBeforeQuery(prevResult, { fetchMoreResult }) {
    return produce(prevResult, draft => {
        for (let listToUpdate of ['notInterestedContacts', 'interestedContacts']) {
            // afterInterestedProperties
            draft.estate[listToUpdate].nodes = fetchMoreResult.estate[listToUpdate].nodes.concat(
                draft.estate[listToUpdate].nodes,
            );

            // update the start cursor if more data is available
            // if it is null then we dont update it because there
            // was not more data, but it could be on the future
            if (fetchMoreResult.estate[listToUpdate].pageInfo.startCursor) {
                draft.estate[listToUpdate].pageInfo.startCursor =
                    fetchMoreResult.estate[listToUpdate].pageInfo.startCursor;
            }
        }
    });
}
