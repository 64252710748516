import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import Loadable from 'react-loadable';
import { Alert } from 'react-bootstrap';
import { FormPanel } from '@/app.components';
import Input from '@/app.components/forms/Input/Input';
import { GeoTemplate } from '@/graphql/generated/types';
import { CoordinateTuple } from '@/app.utils/services/GoogleMapsConverter';

const DrawRegionMap = (Loadable({
    loader: () => import('@/app.components/map/DrawRegionMap'),
    loading: () => null,
}) as any) as React.FunctionComponent<any>;

type Props = {
    error?: any;
    isEdit: boolean;
    tempEntity?: GeoTemplate;
    setTempEntity: Function;
};

const OfficeGeoModalGeoJson: React.FC<Props> = props => {
    const { error, isEdit, tempEntity, setTempEntity } = props;

    const intl = useIntl();

    const content = {
        title: intl.formatMessage({ id: 'office.geo.modal.geometry.title' }),
    };

    const handleUpdateRegion = useCallback(
        (type: string, coordinates: Array<CoordinateTuple[]>) => {
            setTempEntity(prevState => {
                return {
                    ...prevState,
                    geometry: [
                        {
                            type: type,
                            coordinates: coordinates,
                        },
                    ],
                };
            });
        },
        [setTempEntity],
    );

    return (
        <FormPanel flex>
            <Input
                type="text"
                placeholder={content.title}
                value={tempEntity?.title || ''}
                onChange={event => {
                    setTempEntity({ ...tempEntity, title: event?.target?.value });
                }}
            />
            {error && <Alert bsStyle="danger">{error}</Alert>}

            <DrawRegionMap
                flex
                editing={isEdit}
                region={tempEntity?.geometry || []}
                onUpdateRegion={handleUpdateRegion}
            />
        </FormPanel>
    );
};

export default OfficeGeoModalGeoJson;
