import React, { useMemo } from 'react';
import { ColumnsSelector } from '@/app.components/table/DataTable/ColumnsSelector';

type Props = {
    memoryKey: string;
    initRecord: [string, boolean][];
    columnsExtended: {
        [key: string]: {
            key: string;
            title: JSX.Element;
            dataIndex?: string;
            className?: string;
            render: any;
        };
    };
    renderMenu: any;
    firstColumnId: string;
    columnsKeyPrefix: string;
};

const useTableColumns = (props: Props) => {
    const { memoryKey, initRecord, columnsExtended, renderMenu, firstColumnId, columnsKeyPrefix } = props;

    const initColumns: [string, boolean][] = useMemo(() => initRecord.filter(([key]) => !!key), [initRecord]);

    const [columns, setColumns] = React.useState<[string, boolean][]>(initColumns);

    React.useEffect(() => {
        try {
            const memoryColumns: [string, boolean][] = JSON.parse(localStorage.getItem(`${memoryKey}`) || '[]');

            const memoryColumnsKeys: string[] = memoryColumns.map(el => el[0]);
            const initColumnsKeys: string[] = initColumns.map(el => el[0]);

            const isValid =
                Array.isArray(memoryColumns) &&
                memoryColumns.every(el => {
                    if (!Array.isArray(el)) {
                        return false;
                    }

                    const [key, value] = el;

                    return initColumnsKeys.includes(key) && typeof key === 'string' && typeof value === 'boolean';
                });

            if (memoryColumnsKeys.length > 0 && isValid) {
                if (initColumnsKeys.length > memoryColumnsKeys.length) {
                    const addColumnsKeys = initColumns.filter(el => !memoryColumnsKeys.includes(el[0]));

                    const addColumns: [string, boolean][] = addColumnsKeys.map(el => [el[0], el[1]]);

                    setColumns([...memoryColumns, ...addColumns]);
                    localStorage.setItem(`${memoryKey}`, JSON.stringify([...memoryColumns, ...addColumns]));
                } else {
                    setColumns(memoryColumns);
                }
            } else {
                setColumns(initColumns);
            }
        } catch {
            console.warn('Could not parse columns from localStorage');
        }
    }, [initColumns]);

    return {
        columns: React.useMemo(() => {
            const columnsById = {
                ...columnsExtended,
                menu: {
                    key: 'menu',
                    dataIndex: 'id',
                    render: renderMenu,
                    title: (
                        <ColumnsSelector
                            columns={columns}
                            setColumns={(newColumns: [string, boolean][]) => {
                                setColumns(newColumns);

                                localStorage.setItem(`${memoryKey}`, JSON.stringify(newColumns));
                            }}
                            columnsKeyPrefix={columnsKeyPrefix}
                        />
                    ),
                    className: 'w-12',
                },
            };

            return [
                columnsById[firstColumnId],
                ...columns.flatMap(([column, active]) => (active && columnsById[column] ? [columnsById[column]] : [])),
                columnsById['menu'],
            ];
        }, [columns, columnsExtended]),
    };
};

export default useTableColumns;
