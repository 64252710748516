// @ts-nocheck
import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { FormattedMessage } from 'react-intl-sweepbright';
import { events, track } from '@/app.utils/analytics';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import Input from '../../../app.components/forms/Input/Input';

// helper function
function convertToStateField({ sortField, sortOrder }: { sortField: string; sortOrder: string }) {
    return `${sortField}__${sortOrder}`;
}

type Props = {
    initialValues: {
        sortField: string;
        sortOrder: string;
    };
    onChange: (attr: { sortField: string; sortOrder: string }) => void;
    options: { label: React.ReactNode; value: string }[];
};

type State = {
    value: string;
};

class SortingSettingsForm extends Component<Props, State> {
    state = {
        value: convertToStateField(this.props.initialValues),
    };

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (!isEqual(nextProps.initialValues, this.props.initialValues)) {
            // initial props changed
            this.setState({ value: convertToStateField(nextProps.initialValues) });
        }
    }

    handleSortFilterChange = (evt: React.FormEvent<HTMLInputElement>) => {
        const { value } = evt.currentTarget;
        // eslint-disable-next-line no-unused-vars
        const [, sortField, sortOrder]: string[] = /^([a-z_]+)__(asc|desc)$/i.exec(value) || [];
        track(events.SORTING_SETTINGS_RADIO_ITEM_CLICKED, { sortField, sortOrder });

        this.setState({ value }, () => {
            this.props.onChange({
                sortField,
                sortOrder,
            });
        });
    };

    render() {
        const { options } = this.props;

        return (
            <div>
                <FormPanel title={<FormattedMessage id="modals.common.sort_panel.title" defaultMessage="Sort by" />}>
                    {options.map(item => (
                        <Input
                            key={item.value}
                            label={item.label}
                            type="radio"
                            value={item.value}
                            onChange={this.handleSortFilterChange}
                            checked={item.value === this.state.value}
                        />
                    ))}
                </FormPanel>
            </div>
        );
    }
}

export default SortingSettingsForm;
