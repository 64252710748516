import { createWithConfirmation } from './withConfirmation';

/**
 * Wraps a Redux-Form-based component with `withConfirmation`
 * which works only if a property is not archived
 */
export const withActivePropertyConfirmation: <P extends object>(
    Composed: React.ComponentType<P>,
) => React.ComponentType<P> = createWithConfirmation({
    shouldRequestSave: ({ editingDisabled }) => !editingDisabled,
});
