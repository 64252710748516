import { apply } from 'json-logic-js';
import { JSONSchema6 } from 'json-schema';
import { BREAK, RulesIndex } from './RulesIndex';
import { FDLRulesList, Rule } from './types';
import { validateRules } from './validateRule';
import cloneDeep from 'lodash/cloneDeep';
import { lastChildOf, parentOf } from '@sweepbright/fields-common/lib/path';

export class RulesMatcher<TContext> {
  private rulesIndex = new RulesIndex();

  constructor(rulesList: FDLRulesList) {
    validateRules(rulesList);
    this.createIndex(rulesList);
  }

  /**
   * Checks if the field exists (regardless of context)
   *
   * @param path
   * @returns
   */
  public pathExists(path: string): boolean {
    const rules = this.getRulesByPath(path);
    return rules.length > 0;
  }

  /**
   * Returns field's schema for the given context
   *
   * @param context
   * @param path
   * @returns
   */
  public getSchema(context: TContext, path: string): JSONSchema6 | false {
    const rules = this.getRulesByPath(path);
    return this.getSchemaFromRules(context, rules);
  }

  public getSchemaRecursively(
    context: TContext,
    prefix: string
  ): JSONSchema6 | false {
    const resultSchema = cloneDeep(this.getSchema(context, prefix));
    if (!resultSchema) {
      return false;
    }

    const resultSchemasByPath = {
      [prefix]: resultSchema,
    };

    this.rulesIndex.walk(prefix, (path, rules) => {
      if (path === prefix) {
        return;
      }

      if (rules === null) {
        return BREAK;
      }

      const currentSchema = this.getSchemaFromRules(context, rules);
      if (currentSchema === false) {
        return BREAK;
      }
      const currentSchemaClone = cloneDeep(currentSchema);

      const required = this.getRequiredFromRules(context, rules);

      resultSchemasByPath[path] = currentSchemaClone;
      const parentPath = parentOf(path);
      if (parentPath === null) {
        return;
      }
      const parentSchema = resultSchemasByPath[parentPath];
      if (!parentSchema) {
        return;
      }

      const propName = lastChildOf(path);
      if (propName === null) {
        return;
      }

      //TODO: add support for arrays
      if (parentSchema.type !== 'object') {
        throw new Error(
          `Cannot compose JSON schema for path '${path}'. Trying to add property '${propName}' to non-object schema at '${parentPath}': ${JSON.stringify(
            parentSchema
          )}`
        );
      }

      parentSchema.properties = parentSchema.properties ?? {};
      parentSchema.properties[propName] = currentSchemaClone;

      if (
        required &&
        !(parentSchema.required && parentSchema.required.includes(propName))
      ) {
        parentSchema.required = parentSchema.required ?? [];
        parentSchema.required.push(propName);
      }
    });

    return resultSchema;
  }

  private getSchemaFromRules(
    context: TContext,
    rules: Rule[]
  ): JSONSchema6 | false {
    const matchingRule = this.getMatchingRule(context, rules);

    return matchingRule?.schema ?? false;
  }

  private getRequiredFromRules(context: TContext, rules: Rule[]): boolean {
    const matchingRule = this.getMatchingRule(context, rules);
    return matchingRule?.required ?? false;
  }

  private getMatchingRule(context: TContext, rules: Rule[]): Rule | null {
    return rules.find((rule) => this.isRuleMatching(context, rule)) ?? null;
  }

  /**
   * Returns all possible fields under the given pathPrefix (regardless of context)
   *
   * @param pathPrefix
   * @returns
   */
  public getAllFieldsByPathPrefix(pathPrefix: string): string[] {
    const rulesByPaths = this.rulesIndex.getAll(pathPrefix);
    return Object.keys(rulesByPaths);
  }

  /**
   * Returns all fields' schemas matching context under the given pathPrefix
   *
   * @param context
   * @param pathPrefix
   */
  public getSchemasByPathPrefix(
    context: TContext,
    pathPrefix: string
  ): {
    [path: string]: JSONSchema6;
  } {
    const rulesByPaths = this.rulesIndex.getAll(pathPrefix);
    return Object.fromEntries(
      Object.keys(rulesByPaths)
        .map((path) => [
          path,
          this.getSchemaFromRules(context, rulesByPaths[path]),
        ])
        .filter((pair): pair is [string, JSONSchema6] => !!pair[1])
    );
  }

  private isRuleMatching(context: TContext, rule: Rule): boolean {
    const { if: condition, schema } = rule;

    //Case 1. No schema
    if (!schema) {
      return false;
    }

    //Case 2.1. if === false
    if (condition === false) {
      return false;
    }

    //Case 2. No if
    if (!condition) {
      return true;
    }

    //Case 3. Return schema if the if-expression check is passed
    return apply(condition, context);
  }

  private getRulesByPath(path: string): Rule[] {
    return this.rulesIndex.get(path) ?? [];
  }

  private createIndex(rulesList: FDLRulesList) {
    for (const rule of rulesList.rules) {
      this.rulesIndex.add(rule);
    }
  }
}
