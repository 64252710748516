// @ts-nocheck
import React from 'react';
import { HelpBlock } from 'react-bootstrap';
import { InputProps } from 'redux-form';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import { useModal } from '../AbstractModal';
import Input from '../../forms/Input/Input';

type Props = {
    action: React.ReactNode;
    field: InputProps;
    help: React.ReactNode;
    label: React.ReactNode;
    placeholder: React.ReactNode;
    title: React.ReactNode;
    onSubmit: () => void;
    submitting: boolean;
};

const CommunicationIntegrationModal: React.FunctionComponent<Props> = (props, ref) => {
    const { title, field, label, action, help, onSubmit, placeholder, submitting } = props;
    const hasError = Boolean(field.error);
    const { show, handleOpen, handleClose } = useModal();

    React.useImperativeHandle(ref, () => ({
        open: handleOpen,
        close: handleClose,
    }));

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input type="text" label={label} placeholder={placeholder} {...field} horizontal />
                <HelpBlock>{help}</HelpBlock>
            </Modal.Body>
            <Modal.Footer className="text-center">
                <Button onClick={handleClose}>
                    <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="primary" onClick={onSubmit} disabled={submitting || hasError}>
                    {action}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default React.forwardRef(CommunicationIntegrationModal);
