import React from 'react';
import { Map } from 'immutable';
import moment from 'moment';
import { FormattedMessage } from 'react-intl-sweepbright';
import useCompany from '@/app.hooks/useCompany';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import InteractionRepository from '@/app.utils/services/Repositories/InteractionRepository';
import interactionTypesMessages from '@/app.data/Interactions';

export const LastActivity: React.FunctionComponent<{ property: Map<string, any> }> = function LastActivity({
    property,
}) {
    const company = useCompany();
    const repository = new EstateRepository(property, company);
    const interaction = repository.getInteraction();

    if (repository.isArchived()) {
        return <FormattedMessage id="property.status.archived" defaultMessage="Archived" />;
    }

    if (interaction) {
        const interactionRepository = new InteractionRepository(interaction);
        const interactionType = interactionRepository.getType();
        const createdAt = interactionRepository.getCreationDate();

        return (
            interaction && (
                <div data-testid="interaction-time" className="truncate">
                    <FormattedMessage {...interactionTypesMessages[interactionType]} />{' '}
                    <time dateTime={moment(createdAt).format('YYYY-MM-DD HH:mm')}>{moment(createdAt).fromNow()}</time>
                </div>
            )
        );
    }

    return null;
};
