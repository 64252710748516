import { defineMessages } from 'react-intl-sweepbright';

export const errorMessages = defineMessages({
    'forms.match.contents.required': {
        id: 'forms.match.contents.required',
        defaultMessage: 'Please fill in your message.',
    },
    'forms.portal.error.enum': {
        id: 'forms.portal.error.enum',
        defaultMessage: 'Portal has to be enabled',
    },
    'email.validation_errors.no_account': {
        id: 'email.validation_errors.no_account',
        defaultMessage: 'No account is associated to this email address',
    },
    'login.errors.auth_code.invalid': {
        id: 'login.errors.auth_code.invalid',
        defaultMessage: 'The authorization code is invalid.',
    },
    'email.validation_errors.invalid': {
        id: 'email.validation_errors.invalid',
        defaultMessage: 'Invalid e-mail address',
    },
    'email.validation_errors.required': {
        id: 'email.validation_errors.required',
        defaultMessage: 'You must enter an e-mail address',
    },
    'general.validation.field_required': {
        id: 'general.validation.field_required',
        defaultMessage: '{fieldName} is required',
    },
    'channels.validation.name_required': {
        id: 'channels.validation.name_required',
        defaultMessage: 'Name is required',
    },
    'channels.validation.name_too_long': {
        id: 'channels.validation.name_too_long',
        defaultMessage: 'Name is too long (maximum is 40 characters)',
    },
    'general.validation.required': {
        id: 'general.validation.required',
        defaultMessage: 'This is a required field',
    },
    'general.validation.positive_value': {
        id: 'general.validation.positive_value',
        defaultMessage: 'Should be a positive value',
    },
});
