import compose from 'recompose/compose';
import { reduxForm as form, propTypes } from 'redux-form';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import gql from 'graphql-tag';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import useUser from '@/app.hooks/useUser';
import { Validator } from '../../../app.utils/services';
import Email from '../../../app.components/elements/Email/PortalEmail';
import FormPanel from '../../forms/FormPanel/FormPanel';
import Input from '../../forms/Input/Input';
import PortalConfigModalBody from './PortalConfigModalBody';
import { nameValidationRule } from './utils';

const HebbesImpl = ({ fields, handleSubmit, editing, ...props }) => {
    const user = useUser();

    return (
        <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
            <FormPanel>
                <Alert>
                    <FormattedMessage
                        id="channels.hebbes.alert.activate"
                        defaultMessage="If you have a Hebbes account, click {link} to active Sweepbright on Hebbes."
                        tagName="p"
                        values={{
                            link: (
                                <Email
                                    portal={props.portal}
                                    to={['helpdesk@hebbes.be']}
                                    subject={
                                        <FormattedMessage
                                            id="emails.hebbes.credentials.subject"
                                            defaultMessage="Please activate my SweepBright account on Hebbes"
                                        />
                                    }
                                    body={
                                        <FormattedMessage
                                            id="emails.hebbes.credentials.body"
                                            defaultMessage="I have a Hebbes account and like to request SweepBright’s activation."
                                        />
                                    }
                                    user={user}
                                />
                            ),
                        }}
                    />
                </Alert>
                <Alert>
                    <FormattedMessage
                        id="channels.hebbes.alert.register"
                        defaultMessage={
                            "If you don't have a Hebbes account, click {link} to be contacted by a Hebbes representative."
                        }
                        tagName="p"
                        values={{
                            link: (
                                <Email
                                    portal={props.portal}
                                    to={['helpdesk@hebbes.be']}
                                    subject={
                                        <FormattedMessage
                                            id="emails.hebbes.activate.subject"
                                            defaultMessage="I would like to create an account for Hebbes"
                                        />
                                    }
                                    body={
                                        <FormattedMessage
                                            id="emails.hebbes.activate.body"
                                            defaultMessage={
                                                "I don't have a Hebbes account and like to be contacted by a Hebbes representative. At the same time, I want to request SweepBright’s activation at Hebbes."
                                            }
                                        />
                                    }
                                    user={user}
                                />
                            ),
                        }}
                    />
                </Alert>
                <Input
                    type="checkbox"
                    label={
                        <FormattedMessage
                            id="forms.portal.hebbes.enabled"
                            defaultMessage="I have received confirmation from Hebbes that Sweepbright is activated."
                        />
                    }
                    {...fields.enable_hebbes}
                    error={null}
                    disabled={editing}
                />
                {props.showNameField && (
                    <Input
                        {...fields.name}
                        label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                        help={
                            <FormattedMessage
                                id="channels.name.description"
                                defaultMessage="This is the name you will see in the Publish section of SweepBright."
                            />
                        }
                    />
                )}
            </FormPanel>
        </PortalConfigModalBody>
    );
};

HebbesImpl.propTypes = {
    ...propTypes,
    editing: PropTypes.bool,
};

const Hebbes = compose(
    form(
        {
            form: 'channels/portal',
            formKey: 'hebbes',
            fields: ['enable_hebbes', 'name'],
            validate: Validator.validateWithRules(
                {
                    enable_hebbes: {
                        type: 'boolean',
                        enum: [true],
                    },
                    name: nameValidationRule,
                },
                { humanizeErrorMessage: false },
            ),
        },
        createStructuredSelector({
            // if the multiple accounts feature is on,
            // we show the name field, otherwise, it is invisible to the user
            // even though the name is still set
            showNameField: getFeature('portals.multipleAccounts.enabled'),
        }),
    ),
)(HebbesImpl);

Hebbes.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
        }
    `,
};

export default Hebbes;
