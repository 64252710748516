import React from 'react';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import MembersAside from '@/app.components/asides/MembersAside';

const MembersLayout = ({ location, children }) => {
    return (
        <LayoutContainer>
            <MembersAside location={location} />

            <LayoutColumn>{React.isValidElement(children) && React.cloneElement(children)}</LayoutColumn>
        </LayoutContainer>
    );
};

export default MembersLayout;
