import React from 'react';
import { useIntl } from 'react-intl';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import { FormattedMessage } from 'react-intl-sweepbright';
import { Contact } from '@/graphql/generated/types';
import { fullName } from '@/app.utils/services/Helpers';
import Icon from '@/app.components/icons/Icon';

import './ContactTitle.scss';

export default function ContactTitle({
    contact,
    teamName,
    showTitleInfo,
}: {
    contact?: Pick<Contact, 'firstName' | 'lastName' | 'archived' | 'email' | 'phone'>;
    showTitleInfo?: boolean;
    teamName?: string;
}) {
    const intl = useIntl();

    const content = {
        unknown: intl.formatMessage({ id: 'general.unknown' }),
    };

    return (
        <div className="c-contact-title">
            <span className="truncate">
                <span>
                    {contact?.firstName || contact?.lastName
                        ? fullName(contact?.firstName, contact?.lastName)
                        : content.unknown}
                </span>

                {contact?.archived && (
                    <Tooltip label={<FormattedMessage id="property.status.archived" defaultMessage="Archived" />}>
                        <Icon icon="archived" className="ml-2" />
                    </Tooltip>
                )}

                {// @ts-ignore
                !contact?.updatedAt && teamName && <span className="text-muted ml-1">- {teamName}</span>}

                {showTitleInfo && (contact?.email || contact?.phone) && (
                    <span className="text-muted ml-1"> — {contact.email || contact.phone}</span>
                )}
            </span>
        </div>
    );
}
