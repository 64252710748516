import React from 'react';
import { Link } from 'react-router';
import MediaCardBase from '@sweepbright/uikit/build/esm/mediacard';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import useRouter from '@/app.hooks/useRouter';
import Icon, { ICON_SIZE_SMALL, IconName } from '../../icons/Icon';
import './MediaCard.scss';

type Props = {
    actions?: any[];
    icon?: IconName;
    iconStyle?: string;
    to?: string;
    image?: React.ReactNode;
    onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
    onSelect?: () => void;
    selectable?: boolean;
    selected?: boolean;
    subtitles?: React.ReactNode[];
    title: React.ReactNode;
    details?: React.ReactNode;
    className?: string;
    testId?: string;
    useLink?: boolean;
};

const MediaCard: React.FunctionComponent<Props> = ({
    to,
    image,
    title,
    subtitles = [],
    icon = 'caret-right',
    iconStyle,
    details,
    onClick,
    selectable,
    selected,
    onSelect,
    children,
    actions = [],
    className,
    testId,
    useLink = false,
}) => {
    const contextualAction = getContextualAction();
    const router = useRouter()!;

    const component = (
        <MediaCardBase
            testId={testId}
            onClick={getOnClickHandler()}
            selectable={selectable}
            onSelect={onSelect}
            selected={selected}
            image={image}
            title={title}
            subtitles={subtitles}
            actions={[...actions, contextualAction]}
            details={details}
            className={className}
        >
            {children}
        </MediaCardBase>
    );

    if (to && !selectable && useLink) {
        return (
            <Link to={to} className="no-underline">
                {component}
            </Link>
        );
    }

    return component;

    /* eslint-enable jsx-a11y/onclick-has-role */

    function getContextualAction() {
        const contextualActionIcon = <Icon icon={icon} size={ICON_SIZE_SMALL} type={iconStyle} />;
        // Render the card's contextual action
        if (onClick || to) {
            if (to && selectable) {
                return <Link to={to}>{contextualActionIcon}</Link>;
            }

            return (
                <ButtonIcon
                    type={icon}
                    variant="link"
                    onClick={onClick}
                    iconSize={24}
                    className="media-card-contextual-action"
                />
            );
        }

        return null;
    }

    // @ts-ignore
    function getOnClickHandler() {
        if (to) {
            if (useLink) {
                return () => {};
            }

            return () => router.push(to);
        }

        if (!selectable) {
            return onClick;
        }
    }
};

export default MediaCard;
