import React, { useEffect } from 'react';
import { Icon } from '@/app.components';
import { PaletteType } from '../types';

type Props = {
    entity: PaletteType;
    active: boolean;
    showPalette: boolean;
    onSelect: (id?: string) => void;
};

const PaletteItem: React.FC<Props> = props => {
    const { entity, active, showPalette, onSelect } = props;

    const handleAction = () => {
        if (entity.type === 'modal') {
            onSelect(entity.id);
        }

        if (entity.type === 'link') {
            window.open(`http://${entity.link}`, '_blank', 'noreferrer')?.focus();

            onSelect();
        }
    };

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (showPalette && active && e.key === 'Enter') {
                e.preventDefault();

                handleAction();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };

        // eslint-disable-next-line
    }, [active, showPalette]);

    return (
        <button onClick={handleAction} className={`palette-item${active ? ' active' : ''}`}>
            <span className="palette-item__title">{entity.title}</span>
            {active && (
                <span className="palette-item__icon">
                    <Icon icon="arror-return" />
                </span>
            )}
        </button>
    );
};

export default PaletteItem;
