import compose from 'recompose/compose';
import { reduxForm as form, propTypes } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl-sweepbright';
import { validateWithRules } from '@/app.utils/services/Validator';
import useUser from '@/app.hooks/useUser';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import NumberedStep from '@/app.components/modals/Channels/elements/NumberedStep';
import Email from '../../../app.components/elements/Email/PortalEmail';
import Input from '../../forms/Input/Input';
import PortalConfigModalBody from './PortalConfigModalBody';
import { nameValidationRule } from './utils';

const ImmovlanImpl = ({ fields, handleSubmit, editing, ...props }) => {
    const user = useUser();

    return (
        <ErrorBoundary>
            <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
                <div className="space-y-4">
                    <NumberedStep
                        step={1}
                        title={
                            <FormattedMessage id="channels.activation_step.title" defaultMessage="Find your details" />
                        }
                    >
                        <p className="text-muted m-0 leading-none">
                            <Email
                                linkText={
                                    <FormattedMessage id="channels.send_email" defaultMessage="Send this e-mail" />
                                }
                                portal={props.portal}
                                to={['infoimmo.vlan@roularta.be']}
                                subject={
                                    <FormattedMessage
                                        id="emails.immovlan.activate.subject"
                                        defaultMessage="Please send me my Immovlan login credentials for SweepBright"
                                    />
                                }
                                body={
                                    <FormattedMessage
                                        id="emails.immovlan.activate.body"
                                        defaultMessage="I have an Immovlan account and like to request my ProCustomerID to link to SweepBright."
                                    />
                                }
                                className="align-baseline"
                                user={user}
                            />
                            <FormattedMessage
                                id="channels.send_email.details_request"
                                defaultMessage=" to request your details from {portal}."
                                values={{ portal: 'Immovlan' }}
                            />
                        </p>
                    </NumberedStep>
                    <NumberedStep
                        step={2}
                        title={
                            <FormattedMessage id="channels.details_step.title" defaultMessage="Fill in your details" />
                        }
                        last
                    >
                        <Input
                            type="text"
                            label={
                                <FormattedMessage
                                    id="forms.labels.immovlan_customer_id"
                                    defaultMessage="ProCustomerID"
                                />
                            }
                            {...fields.accessToken}
                            disabled={editing}
                        />
                        {props.showNameField && (
                            <Input
                                {...fields.name}
                                label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                                help={
                                    <FormattedMessage
                                        id="channels.name.description"
                                        defaultMessage="This is the name you will see in the Publish section of SweepBright."
                                    />
                                }
                            />
                        )}
                    </NumberedStep>
                </div>
            </PortalConfigModalBody>
        </ErrorBoundary>
    );
};

ImmovlanImpl.propTypes = {
    ...propTypes,
    editing: PropTypes.bool,
};

const Immovlan = compose(
    form(
        {
            form: 'channels/portal',
            formKey: 'immovlan',
            fields: ['accessToken', 'name'],
            validate: validateWithRules(
                {
                    accessToken: {
                        type: 'string',
                        required: true,
                        allowEmpty: false,
                        messages: {
                            required: 'general.validation.field_required',
                            allowEmpty: 'general.validation.field_required',
                        },
                    },
                    name: nameValidationRule,
                },
                { humanizeErrorMessage: false },
            ),
        },
        createStructuredSelector({
            // if the multiple accounts feature is on,
            // we show the name field, otherwise, it is invisible to the user
            // even though the name is still set
            showNameField: getFeature('portals.multipleAccounts.enabled'),
        }),
    ),
)(ImmovlanImpl);

Immovlan.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
            accessToken
        }
    `,
};

export default Immovlan;
