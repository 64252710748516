import React from 'react';
import * as Yup from 'yup';
import { fromJS } from 'immutable';
import { FormProps } from 'redux-form';
import { useIntl } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl-sweepbright';
import Button from '@/app.components/elements/Buttons/Button';
import Formik from '@/app.components/forms/helpers/Formik';
import wrapWithPromise from '@/app.utils/services/wrapWithPromise';
import { track, events } from '@/app.utils/analytics';
import Icon, { ICON_SIZE_SMALL } from '../../../app.components/icons/Icon';
import Toolbar from '../../../app.components/elements/Toolbar/Toolbar';
import { errorMessages } from '../../../app.components/errors/ErrorMessages';
import InputLight from '../../../app.components/forms/InputLight';
import OnboardingForm from './OnboardingForm';

type Props = {
    login: (args: { email: string }) => void;
};

const useValidationSchema = () => {
    const intl = useIntl();

    return Yup.object({
        email: Yup.string()
            .email(intl.formatMessage(errorMessages['email.validation_errors.invalid']))
            .required(intl.formatMessage(errorMessages['email.validation_errors.required'])),
    });
};

const LoginForm = ({ login }: Props & FormProps) => {
    const onLogin = wrapWithPromise(login);
    const validationSchema = useValidationSchema();
    const intl = useIntl();

    return (
        //@ts-ignore
        <Formik
            initialValues={{ email: '', _error: '' }}
            onSubmit={async (values: { email: string }, actions) => {
                try {
                    track(events.USER_LOGIN_FORM_SUBMITTED, values);
                    await onLogin(values);
                } catch (fieldErrors) {
                    actions.setErrors(fieldErrors);
                } finally {
                    actions.setSubmitting(false);
                }
            }}
            validationSchema={validationSchema}
        >
            {props => {
                const errorMessage =
                    props.submitCount > 0 &&
                    props.errors.email &&
                    (errorMessages[props.errors.email]
                        ? intl.formatMessage(errorMessages[props.errors.email])
                        : props.errors.email);

                return (
                    <OnboardingForm
                        handleSubmit={props.handleSubmit}
                        messages={props.errors._error && fromJS([{ message: props.errors._error, style: 'danger' }])}
                    >
                        <div className="relative">
                            <InputLight
                                name="email"
                                type="email"
                                value={props.values.email}
                                error={errorMessage}
                                label={
                                    <FormattedMessage id="forms.login.email.label" defaultMessage="E-mail Address" />
                                }
                                icon={<Icon icon="mail" size={ICON_SIZE_SMALL} />}
                                onChange={props.handleChange}
                            />
                        </div>
                        <Toolbar inPage={false} className="onboarding-toolbar">
                            <Toolbar.Left>
                                <div>
                                    <Button type="submit" className="onboarding-submit" disabled={props.isSubmitting}>
                                        {props.isSubmitting ? (
                                            <FormattedMessage
                                                id="forms.login.cta.submitting"
                                                defaultMessage="Submitting..."
                                            />
                                        ) : (
                                            <FormattedMessage id="forms.login.cta" defaultMessage="Log In" />
                                        )}
                                    </Button>
                                </div>
                            </Toolbar.Left>
                        </Toolbar>
                    </OnboardingForm>
                );
            }}
        </Formik>
    );
};

export default injectIntl(LoginForm);
