// @ts-nocheck
import companySettings from '@/app.utils/Decorators/companySettings';
import Settings from '@/app.domains/company/Settings';
import { languages } from '@/app.data/Localization';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';

const selectors = {
    canSetRentPeriod: getFeature('setting-rent-period'),
    enabledCompanyMatching: getFeature('company.matching.enabled'),
};

const contactFields = [
    'registration_number',
    'contact_phone',
    'contact_email',
    'contact_street',
    'contact_street_2',
    'contact_number',
    'contact_additional',
    'contact_box',
    'contact_zip',
    'contact_city',
    'contact_borough_or_district',
    'contact_county',
    'contact_country',
];

const formFields = [
    'default_locale',
    'transaction_types',
    'rent_period',
    'unassigned_leads_admin_id',
    'photo.data.url',
    'photo.data.uploaded_at',
    'photo.data.photo_file_id',
    'photo.file',
    'name',
    'timezone',
    'matching_emails_replyto_multiple_properties',
    'matching_emails_replyto_single_property',
    ...contactFields,
    ...languages.map(language => `languages.${language.value}`),
];

export default companySettings('general', formFields, selectors)(Settings);
