import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import AuthManager from 'auth-manager';
import { replace } from 'react-router-redux';
import CookieService from '@/app.utils/services/CookieService';
import Loading from '../../app.components/loading/Loading';
import { getIsLoggingIn, getIsloggedIn } from '../../app.redux/selectors';

const isGuest = connectedReduxRedirect({
    authenticatingSelector: getIsLoggingIn,
    authenticatedSelector: store => {
        const isLoggedIn = getIsloggedIn(store);
        if (!isLoggedIn) {
            return true;
        }
        const user = AuthManager.getUser();
        const isAllowedToEnterRoute = Boolean(!user || !user.id);

        return isAllowedToEnterRoute;
    },
    redirectAction: location => {
        const cookiesService = CookieService();
        cookiesService.remove('redirect_to', { path: '/' });

        return replace(location);
    },
    AuthenticatingComponent: Loading,
    wrapperDisplayName: 'Guest',
    redirectPath: () => {
        const cookiesService = CookieService();

        return cookiesService.get('redirect_to') || '/';
    },
});

export default isGuest;
