import { reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withProps from 'recompose/withProps';
import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';
import withHooks from 'hook-hoc';
import { useMemo } from 'react';
import { register } from '@/app.redux/actions/AuthActions';
import { useLocale, useSelectLocale } from '@/app.components/localize/Localized';
import Register from '../app.domains/auth/register/RegisterForm';
import { getLoginUrl } from '../app.redux/selectors';
import { Validator } from '../app.utils/services';

const form = {
    form: 'register',
    fields: ['first_name', 'last_name', 'email', 'company_name', 'country', 'referrer', 'locale'],
    validate: Validator.validateWithRules({
        email: {
            type: 'string',
            format: 'email',
            required: true,
            allowEmpty: false,
        },
        company_name: {
            type: 'string',
            required: true,
            allowEmpty: false,
        },
        first_name: {
            type: 'string',
            required: true,
            allowEmpty: false,
        },
        last_name: {
            type: 'string',
            required: true,
            allowEmpty: false,
        },
        country: {
            type: 'string',
            required: true,
            allowEmpty: false,
        },
        locale: {
            type: 'string',
            required: true,
            allowEmpty: false,
        },
        referrer: {
            type: 'string',
            allowEmpty: true,
            required: false,
        },
    }),
};

export default compose(
    withState('success', 'setSuccess', false),
    withProps((ownProps: any) => {
        const enterprise = get(ownProps, 'location.query.enterprise');

        return {
            onSubmitSuccess: () => ownProps.setSuccess(true),
            enterprise,
        };
    }),
    withHooks(() => {
        const initialValues = useMemo(() => ({ locale: useLocale() }), []);
        const selectLocale = useSelectLocale();

        return { initialValues, selectLocale };
    }),
    reduxForm(form, createStructuredSelector({ loginUrl: getLoginUrl }), (dispatch, { enterprise }) => {
        return {
            onSubmit: values => {
                dispatch(register({ ...values, enterprise }));
            },
        };
    }),
)(Register);
