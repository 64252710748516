import { FinancialDetailsInput, OfferDirection } from '@/graphql/generated/types';

type FinancialDetailsInput_BuyerToOwner = {
    direction: OfferDirection.BuyerToOwner;
    buyerGrossAmount: number;
    ownerNetAmount: null;
} & FinancialDetailsInput;

type FinancialDetailsInput_OwnerToBuyer = {
    direction: OfferDirection.OwnerToBuyer;
    buyerGrossAmount: null;
    ownerNetAmount: number;
} & FinancialDetailsInput;

export function refineFinancialDetailsInput(
    fin: FinancialDetailsInput,
): FinancialDetailsInput_BuyerToOwner | FinancialDetailsInput_OwnerToBuyer | null {
    if (fin.direction === OfferDirection.BuyerToOwner) {
        if (!fin.buyerGrossAmount) {
            return null;
        }

        return {
            ...fin,
            direction: OfferDirection.BuyerToOwner,
            buyerGrossAmount: fin.buyerGrossAmount ?? 0,
            ownerNetAmount: null,
        };
    } else {
        if (!fin.ownerNetAmount) {
            return null;
        }

        return {
            ...fin,
            direction: OfferDirection.OwnerToBuyer,
            buyerGrossAmount: null,
            ownerNetAmount: fin.ownerNetAmount ?? 0,
        };
    }
}
