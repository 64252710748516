/* eslint-disable react/prop-types */
import React from 'react';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import CalendarIntegrations from '@/app.domains/profile/Integrations/Calendar/CalendarIntegrations';

const CalendarIntegrationsPage = props => {
    return (
        <LayoutColumn>
            <CalendarIntegrations route={props.route} />
        </LayoutColumn>
    );
};

export default CalendarIntegrationsPage;
