import React, { FC, ReactNode } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import useContact from '@/app.hooks/useContact';
import { useUserById } from '@/app.hooks/user/useUserById';
import { fullName } from '@/app.utils/services/Helpers';
import useUser from '@/app.hooks/useUser';
import { useContactReference } from '@/new.domains/contacts/hooks/useContactReference';
import { Event } from '../types';
import { EventTitle } from './EventTitle';

export const EventCardPopoverBody: FC<{ event: Event }> = ({ event }) => {
    return (
        <article className="p-4">
            <div className="text-xl text-gray-dark">
                <EventTitle event={event} />
            </div>
            <div className="text-muted text-sm mt-1">
                {moment(event.start).format('DD MMMM, HH:mm')} - {moment(event.end).format('HH:mm')}
            </div>
            <div className="text-gray-dark text-sm">{event.location}</div>
            {(!!event.organizerId || !!event.attendees?.length) && (
                <div className="mt-4">
                    <ul className="list-none p-0 m-0 max-w-xs">
                        {event.organizerId && (
                            <li className="mt-1">
                                <NegotiatorCard id={event.organizerId} />
                            </li>
                        )}
                        {event.attendees &&
                            event.attendees.map(attendee => (
                                <li key={attendee.id} className="mt-1">
                                    <ContactCard id={attendee.id} />
                                </li>
                            ))}
                    </ul>
                </div>
            )}
        </article>
    );
};

function ContactCard({ id }: { id: string }) {
    const intl = useIntl();

    const content = {
        unknown: intl.formatMessage({ id: 'general.unknown' }),
    };

    const { contact: contactData, loading, error: contactError } = useContact(id, { cacheFirst: true });

    const { data: reference } = useContactReference(contactError ? id : undefined);

    const entity = contactData || undefined;

    return (
        <div className="flex items-center space-x-2 min-w-0">
            <img
                alt=""
                src={contactData?.photo?.url || undefined}
                className="w-5 h-5 rounded-full flex-none object-contain bg-gray-light"
            />
            <div className="truncate flex-1">
                {loading && (
                    <FormattedMessage id="components.event_details_popup.loading" defaultMessage="Loading..." />
                )}
                {!loading &&
                    (entity?.firstName || entity?.lastName
                        ? fullName(entity?.firstName, entity?.lastName)
                        : content.unknown)}
                {contactError && reference?.contactReference?.team?.name && (
                    <span className="text-muted ml-1">- {reference?.contactReference?.team?.name}</span>
                )}
            </div>
        </div>
    );
}

function NegotiatorCard({ id }: { id: string }) {
    const userId = useUser().get('id');

    const { data, loading } = useUserById(id);

    const isMe = parseInt(data?.user?.id || '') === userId;
    let name: ReactNode = data?.user ? fullName(data?.user?.firstName, data?.user?.lastName) : null;
    if (isMe) {
        name = (
            <>
                {name} (<FormattedMessage id="me" defaultMessage="Me" />)
            </>
        );
    }

    const photo = data?.user?.photo?.data?.url;

    return (
        <div className="flex items-center space-x-2 min-w-0">
            {photo ? (
                <img
                    src={photo}
                    alt={`${name || ''}`}
                    className="w-5 h-5 rounded-full flex-none object-contain bg-gray-light"
                />
            ) : (
                <div className="w-5 h-5 rounded-full flex-none object-contain bg-gray-light" />
            )}
            <div className="truncate flex-1">
                {loading ? (
                    <FormattedMessage id="components.event_details_popup.loading" defaultMessage="Loading..." />
                ) : (
                    name
                )}
            </div>
        </div>
    );
}
