import { select } from 'redux-saga/effects';
import { fetchUserViews } from '@/app.redux/actions';
import { UserRequests } from '../../../requests/index';
import { getUser } from '../../selectors/UsersSelectors';
import apiCall from '../Effects/apiCall';

export default function* onMarkFileAsViewed({ fileId, resource = 'files' }) {
    try {
        const user = yield select(getUser);
        yield apiCall(new UserRequests().markViewed, user.get('id'), 'files', fileId);
        yield put(fetchUserViews(resource));
    } catch (response) {
        //
    }
}
