import React, { FC, useState } from 'react';
import { compose, withProps } from 'recompose';
import { useContactOffers } from '@/app.hooks/offer/useContactOffers';
import OfferPage from '@/app.shared/offers/OfferPage';

const ContactOffersPage: FC<{ contactId: string }> = ({ contactId }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [offersLoading, setOffersLoading] = useState(false);

    const variables = {
        contactId: contactId,
        archived: activeTabIndex === 1 ? true : false,
    };

    const { loading, data, refetch } = useContactOffers({
        variables,
    });

    const offersData = data?.contact?.offers || [];

    const handleRefetch = (newTabIndex?: number) => {
        const tabIndex = newTabIndex || activeTabIndex;

        setOffersLoading(true);

        refetch({ ...variables, archived: tabIndex === 1 ? true : false }).finally(() => {
            setOffersLoading(false);
        });
    };

    const handleActiveTabIndex = (newTabIndex: number) => {
        setActiveTabIndex(newTabIndex);

        handleRefetch(newTabIndex);
    };

    return (
        <OfferPage
            data={offersData}
            contactId={contactId}
            refetch={handleRefetch}
            activeTabIndex={activeTabIndex}
            loading={loading || offersLoading}
            setActiveTabIndex={handleActiveTabIndex}
        />
    );
};

export default compose(
    withProps<{ contactId: string }, { params: { contact?: string } }>(props => ({
        contactId: props.params.contact ?? '',
    })),
)(ContactOffersPage);
