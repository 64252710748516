import React from 'react';
import { Toasts } from '../component/Toasts';
import { NotificationMessage, NotificationState, NotificationStatus } from '../state';

const toastState = new NotificationState();

export const ToastsContext = React.createContext(getHelpers());

export function useToasts() {
    const context = React.useContext(ToastsContext);
    if (context === null) {
        throw new Error('Wrap your component tree with a ToastsProvider');
    }

    return context;
}

export function ToastsProvider({ children }) {
    return (
        <>
            {children}
            <Toasts state={toastState} />
        </>
    );
}

function getHelpers() {
    return {
        ...toastState,
        addNotice(attr: Omit<NotificationMessage, 'status'>) {
            toastState.addNotification({
                ...attr,
                status: NotificationStatus.NOTICE,
            });
        },
        addWarning(attr: Omit<NotificationMessage, 'status'>) {
            toastState.addNotification({
                ...attr,
                status: NotificationStatus.WARNING,
            });
        },
        addSuccess(attr: Omit<NotificationMessage, 'status'>) {
            toastState.addNotification({
                ...attr,
                status: NotificationStatus.SUCCESS,
            });
        },
        addError(attr: Omit<NotificationMessage, 'status'>) {
            toastState.addNotification({
                ...attr,
                status: NotificationStatus.ERROR,
            });
        },
    };
}

// HOC to use with legacy class components
// TODO: add type definitions
export function withToasts(Component) {
    return function WrappedComponent(props) {
        const toasts = useToasts();

        return <Component {...props} toasts={toasts} />;
    };
}
