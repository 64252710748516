// @ts-nocheck
import { withProps } from 'recompose';
import PortalAccountConfiguration from '@/app.domains/channels/PortalAccountConfiguration';

export default withProps(ownProps => {
    // pass the accountId from the path
    return {
        key: ownProps.params.portal,
        accountId: ownProps.params.portal,
    };
})(PortalAccountConfiguration);
