import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { rulesMatcher } from '@/app.utils/services/Fields/rulesMatcher';
import { PropertyFieldVisibilityContext } from '@/app.data/FieldVisibilityContext';

export function getFieldLabel(field: string): JSX.Element {
    return <FormattedMessage id={`property.features.${field}`} defaultMessage={`property.features.${field}`} />;
}

const featureTypesOrder = ['comfort', 'heating_cooling', 'energy', 'security', 'ecology'];

/**
 * Returns a list of feature types (heating_cooling, comfort, ecology etc) visible for the given context
 * @param context
 */
export function getFeatureTypes(context: PropertyFieldVisibilityContext): string[] {
    const allFeatureFields = Object.keys(
        rulesMatcher.getSchemasByPathPrefix(context, 'attributes.features'),
    ).map(path => path.replace(/^attributes\./, ''));

    const featureTypesSet = new Set(allFeatureFields.map(path => path.split('.')[1]).filter(Boolean));

    const orderedFeatureTypes: string[] = [];
    for (const item of featureTypesOrder) {
        if (featureTypesSet.has(item)) {
            orderedFeatureTypes.push(item);
            featureTypesSet.delete(item);
        }
    }

    return [...orderedFeatureTypes, ...featureTypesSet.values()];
}

export function isRenovationSectionVisible(context: PropertyFieldVisibilityContext): boolean {
    const fields = Object.keys(rulesMatcher.getSchemasByPathPrefix(context, 'attributes.building.renovation'));

    return fields.length > 0;
}

export function isConditionsSectionVisible(context: PropertyFieldVisibilityContext): boolean {
    const fields = Object.keys(rulesMatcher.getSchemasByPathPrefix(context, 'attributes.conditions'));

    return fields.length > 0;
}

export function isBuildingSectionVisible(context: PropertyFieldVisibilityContext): boolean {
    return !!(
        rulesMatcher.getSchema(context, 'attributes.building.construction.architect') ||
        rulesMatcher.getSchema(context, 'attributes.building.construction.year') ||
        rulesMatcher.getSchema(context, 'attributes.building.units_of_building') ||
        rulesMatcher.getSchema(context, 'attributes.building.construction.residential_lots') ||
        rulesMatcher.getSchema(context, 'attributes.building.number_of_floor_building')
    );
}
