import { List, Map } from 'immutable';
import { DEFAULT_LANGUAGE } from '@sweepbright/webapp-shared/Config/intl';
import { LangDefinition, languages } from '../../../app.data/Localization';
import { getLocaleForCompany } from '../Helpers/locales';
import CompanyRepository from './CompanyRepository';

export default class AbstractRepository {
    language: string = DEFAULT_LANGUAGE;
    enabledLanguages: LangDefinition[] = languages;

    get currentLanguage(): string {
        return this.language;
    }

    setLanguage(language: string) {
        this.language = language;
    }

    setLanguageFromCompany(company?: Map<string, any>) {
        if (!company) {
            return;
        }

        this.enabledLanguages = new CompanyRepository(company).getEnabledLanguages().toJS();

        this.setLanguage(getLocaleForCompany(company));
    }

    getEnabledLanguages(): string[] {
        return (this.enabledLanguages || []).map(language => language && language.value);
    }

    getFirstValidLanguage(possibleLanguages: string[]): string {
        const valid = List(possibleLanguages)
            .filter(possible => (possible ? this.getEnabledLanguages().includes(possible) : false))
            .first();

        return valid ? String(valid) : '';
    }

    ////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////// HELPERS ////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

    concatenateComponents(components: string[], separator: string = ', ') {
        return components.filter(component => component).join(separator);
    }
}
