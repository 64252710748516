import React, { useLayoutEffect } from 'react';
import addOneClass from 'dom-helpers/addClass';
import removeOneClass from 'dom-helpers/removeClass';

export const UNMOUNTED = 'unmounted';
export const EXITED = 'exited';
export const ENTERING = 'entering';
export const ENTERED = 'entered';
export const EXITING = 'exiting';

interface BaseProps {
    timeout: number;
    ['in']: boolean;
    onEnter?: () => void;
    onEntered?: () => void;
    onEntering?: () => void;
    onExiting?: () => void;
    onExited?: () => void;
    onExit?: () => void;
}

export function useTransition({ timeout, ...props }: BaseProps) {
    const [state, setState] = React.useState(EXITED);

    useLayoutEffect(() => {
        if (props.in) {
            if (state !== ENTERED && state !== ENTERING) {
                // eslint-disable-next-line no-unused-expressions
                props.onEnter?.();
                setTimeout(() => {
                    // eslint-disable-next-line no-unused-expressions
                    props.onEntering?.();
                    setState(ENTERING);
                }, 0);
            }
        } else if (state !== EXITED && state !== EXITING) {
            // eslint-disable-next-line no-unused-expressions
            props.onExit?.();
            setTimeout(() => {
                // eslint-disable-next-line no-unused-expressions
                props.onExiting?.();
                setState(EXITING);
            }, 0);
        }
    }, [props.in, state]);

    useLayoutEffect(() => {
        let timer;
        if (state === ENTERING) {
            timer = setTimeout(() => {
                // eslint-disable-next-line no-unused-expressions
                props.onEntered?.();
                setState(ENTERED);
            }, timeout);
        }
        if (state === EXITING) {
            timer = setTimeout(() => {
                // eslint-disable-next-line no-unused-expressions
                props.onExited?.();
                setState(EXITED);
            }, timeout);
        }

        return () => clearTimeout(timer);
    }, [state]);

    return { state };
}

const _addClass = (node, classes) => node && classes && classes.split(' ').forEach(c => addOneClass(node, c));
const _removeClass = (node, classes) => node && classes && classes.split(' ').forEach(c => removeOneClass(node, c));

export function useCSSTransition({
    classNames,
    ...props
}: {
    classNames: string | Record<string, string>;
} & BaseProps) {
    const appliedClassRef = React.useRef({
        appear: {},
        enter: {},
        exit: {},
    });

    const nodeRef = React.useRef<HTMLElement>(null);

    const { state } = useTransition({
        ...props,
        onEnter: () => {
            removeClasses(nodeRef, 'exit');
            addClass(nodeRef, 'enter', 'base');
        },
        onEntering: () => {
            addClass(nodeRef, 'enter', 'active');
        },
        onEntered: () => {
            removeClasses(nodeRef, 'enter');
            addClass(nodeRef, 'enter', 'done');
        },
        onExit: () => {
            removeClasses(nodeRef, 'enter');
            addClass(nodeRef, 'exit', 'base');
        },
        onExiting: () => {
            addClass(nodeRef, 'exit', 'active');
        },
        onExited: () => {
            removeClasses(nodeRef, 'exit');
            addClass(nodeRef, 'exit', 'done');
        },
    });

    return { state: state, ref: nodeRef };

    function getClassNames(type) {
        const isStringClassNames = typeof classNames === 'string';
        const prefix = isStringClassNames && classNames ? `${classNames}-` : '';

        let baseClassName = isStringClassNames ? `${prefix}${type}` : classNames[type];

        let activeClassName = isStringClassNames ? `${baseClassName}-active` : classNames[`${type}Active`];

        let doneClassName = isStringClassNames ? `${baseClassName}-done` : classNames[`${type}Done`];

        return {
            baseClassName,
            activeClassName,
            doneClassName,
        };
    }

    function addClass(nodeRef, type, phase) {
        let className = getClassNames(type)[`${phase}ClassName`];
        const { doneClassName } = getClassNames('enter');

        if (type === 'appear' && phase === 'done' && doneClassName) {
            className += ` ${doneClassName}`;
        }

        // This is for to force a repaint,
        // which is necessary in order to transition styles when adding a class name.
        if (phase === 'active') {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            nodeRef.current?.scrollTop;
        }

        if (className) {
            appliedClassRef.current[type][phase] = className;
            _addClass(nodeRef.current, className);
        }
    }

    function removeClasses(nodeRef, type) {
        const { base: baseClassName, active: activeClassName, done: doneClassName } = appliedClassRef.current[type];

        appliedClassRef.current[type] = {};

        if (baseClassName) {
            _removeClass(nodeRef.current, baseClassName);
        }
        if (activeClassName) {
            _removeClass(nodeRef.current, activeClassName);
        }
        if (doneClassName) {
            _removeClass(nodeRef.current, doneClassName);
        }
    }
}
