import React, { useContext } from 'react';
import accounting from 'accounting';
import AdvancedSearch, { SearchViewType } from '@/app.components/forms/Search/AdvancedSearch';
import { ResourceContext } from '../utils';

const ResourceSearch = () => {
    const {
        view,
        tabs,
        action,
        content,
        setView,
        hideCount,
        hideSearch,
        totalCount,
        initialTab,
        showMapView,
        countAction,
        sortOptions,
        searchSettings,
        setSearchSettings,
    } = useContext(ResourceContext);

    const countElement = hideCount ? (
        undefined
    ) : (
        <>
            <span className="lowercase text-muted ml-auto p-1">
                {accounting.formatNumber(totalCount)} {content.count}
            </span>
            {countAction}
        </>
    );

    return (
        <AdvancedSearch
            action={action}
            activeView={view}
            filtersTabs={tabs}
            title={content.title}
            // @ts-ignore
            onChangeView={setView}
            hideSearch={hideSearch}
            initialTab={initialTab}
            sortOptions={sortOptions}
            // @ts-ignore
            settings={searchSettings}
            countElement={countElement}
            // @ts-ignore
            onSubmit={setSearchSettings}
            availableViews={
                !view ? [] : [SearchViewType.List, SearchViewType.Table, ...(showMapView ? [SearchViewType.Map] : [])]
            }
        />
    );
};

export default ResourceSearch;
