import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router';
import { Badge } from 'react-bootstrap';
import { STATUS_ICONS } from '@/app.domains/channels/Portals';
import { Description } from '@/app.components/navigations/SideMenu/Description';
import ContactAvatar from '../../../app.domains/contacts/ContactAvatar';
import ChannelImage from '../../../app.shared/channels/ChannelImage';

export default class SideMenuThumbnail extends Component {
    static propTypes = {
        active: PropTypes.bool,
        backgroundColor: PropTypes.string,
        channel: PropTypes.object,
        children: PropTypes.node.isRequired,
        count: PropTypes.number,
        disabled: PropTypes.bool,
        image: PropTypes.string,
        link: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        status: PropTypes.string,
        type: PropTypes.string,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        active: false,
        type: 'property',
    };

    isAvatar() {
        return ['contact', 'channel', 'integration'].includes(this.props.type);
    }

    renderImage() {
        const { channel, status, placeholder, backgroundColor, type } = this.props;
        const image = this.props.image || placeholder;
        const imageClasses = classNames({
            'c-side-menu-image': !this.isAvatar(),
            'c-side-menu-avatar': this.isAvatar(),
        });

        switch (type) {
            case 'contact':
                return (
                    <ContactAvatar
                        key="image"
                        image={image}
                        className={imageClasses}
                        status={status}
                        backgroundColor={backgroundColor}
                    />
                );
            case 'channel':
                return <ChannelImage key="image" channel={channel} status={STATUS_ICONS[status]} />;
            default:
                const style = {
                    backgroundImage: `url(${image})`,
                };

                if (this.isAvatar()) {
                    return (
                        <span key="image" className={imageClasses}>
                            <div className="c-avatar" style={style} />
                        </span>
                    );
                }

                return <span key="image" className={imageClasses} style={style} />;
        }
    }

    renderBadge() {
        return this.props.count > 0 && <Badge className="project-counter">{this.props.count}</Badge>;
    }

    render() {
        const { active, link, children, disabled, onClick } = this.props;
        const descriptionClasses = classNames('c-side-menu-description', {
            'c-side-menu-description-for-avatar': this.isAvatar(),
        });

        const props = {
            onClick,
        };

        if (!disabled) {
            props.to = link;
        }

        const Wrapper = disabled ? 'a' : Link;

        return (
            <li className={classNames({ active, disabled })}>
                <Wrapper {...props}>
                    {this.renderImage()}
                    <Description className={descriptionClasses}>{children}</Description>
                    {this.renderBadge()}
                </Wrapper>
            </li>
        );
    }
}
