import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl-sweepbright';
import { getCompanySettings } from '../../../../app.redux/selectors/CompaniesSelector';
import CommunicationIntegration from '../CommunicationIntegration';

const Intercom = ({ fields, handleSubmit, submitting }) => (
    <CommunicationIntegration
        service="Intercom"
        field={fields.intercom_app_id}
        handleSubmit={handleSubmit}
        submitting={submitting}
        formHelpText={
            <FormattedHTMLMessage
                id="integrations.communication.intercom.forms.help"
                defaultMessage={
                    'Please find your app id in your <a href="https://docs.intercom.com/faqs-and-troubleshooting/getting-set-up/where-can-i-find-my-app-id">Intercom Admin</a>.'
                }
            />
        }
        placeholder={
            <FormattedMessage id="integrations.communications.intercom.placeholder" defaultMessage="abcdef1gh" />
        }
        emptyStateText={
            <FormattedHTMLMessage
                id="integrations.communication.intercom.help"
                defaultMessage={
                    'Our integration with Intercom helps you chat with visitors on your SweepBright website. You need a Intercom account. Please be aware that your website might become momentarily unavailable when you hit the Connect button. Learn about integrations <a href="https://sweepbright.com/support/">here</a>.'
                }
            />
        }
    />
);

Intercom.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
};

const form = {
    form: 'intercom',
    fields: ['intercom_app_id'],
};

const mapStateToProps = createStructuredSelector({
    initialValues: getCompanySettings,
});

export default reduxForm(form, mapStateToProps)(Intercom);
