import Button from '@sweepbright/uikit/build/esm/button';
import PropTypes from 'prop-types';
import React from 'react';

import IconButton from './IconButton';

export default function ConfirmButton(props) {
    const [confirming, setConfirming] = React.useState(false);

    const handleConfirm = () => {
        props.onConfirm();
        setConfirming(false);
    };

    if (confirming) {
        return (
            <div className="c-spacer-left c-util-is-block">
                <Button variant="danger" onClick={handleConfirm}>
                    {props.title}
                </Button>
            </div>
        );
    }

    return <IconButton onClick={() => setConfirming(true)} title={props.title} {...props} />;
}

ConfirmButton.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.object.isRequired,
};
