// @ts-nocheck
import React from 'react';
import { Avatar } from '@sweepbright/uikit';
import moment from 'moment';
import { FormattedMessage } from 'react-intl-sweepbright';
import { BuyerLead, Lead } from '@/graphql/generated/types';
import { fullName } from '@/app.utils/services/Helpers';
import DataTable from '@/app.components/table/DataTableOld/DataTable';
import RequestDetailsCell from '@/app.components/table/BuyerLeadsTable/ContactRequestDetailsCell';

type BuyerLeadData = Lead;

const renderAvatarAndName = (id: string, { firstName, lastName, photo }: BuyerLeadData) => {
    return (
        <div className="flex items-center w-48">
            <Avatar type="sm" name={fullName(firstName, lastName)} image={photo?.url} />
            <div className="ml-2 truncate" title={fullName(firstName, lastName)}>
                {fullName(firstName, lastName)}
            </div>
        </div>
    );
};

const renderAction = (id, contact: BuyerLead) => {
    return <RequestDetailsCell contact={contact} />;
};

const renderDate = (date: string) => {
    return <div className="w-32 truncate">{moment(date).fromNow()}</div>;
};

const renderEmail = value => {
    return (
        <a
            className="block w-56 truncate"
            title={value}
            href={`mailto:${value}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {value}
        </a>
    );
};

const renderPhone = phone => {
    return (
        <a
            className="block w-56 truncate"
            title={phone}
            href={`tel:${phone}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {phone}
        </a>
    );
};

const renderNegotiation = negotiation => {
    if (negotiation) {
        return (
            <FormattedMessage
                id={`common.buyer_negotiation.${negotiation.toLowerCase()}`}
                defaultMessage={negotiation}
            />
        );
    }

    return <span className="text-muted">-</span>;
};

export default function BuyerLeadsTable({
    buyers,
    selectedBuyers,
    onChangeSelection,
}: {
    buyers: BuyerLeadData[];
    selectedBuyers: string[];
    onChangeSelection: (vendorIds: string[]) => void;
}) {
    const columns = [
        {
            key: 'name',
            title: <FormattedMessage id="forms.labels.name" defaultMessage="Name" />,
            dataIndex: 'id',
            render: renderAvatarAndName,
            className: 'w-56',
            cellClassName: 'w-56 truncate',
        },
        {
            key: 'email',
            title: <FormattedMessage id="forms.labels.email" defaultMessage="E-mail" />,
            dataIndex: 'email',
            render: renderEmail,
            className: 'w-64',
            cellClassName: 'w-64',
        },
        {
            key: 'phone',
            title: <FormattedMessage id="forms.labels.phone" defaultMessage="Phone" />,
            dataIndex: 'phone',
            render: renderPhone,
            className: 'w-64',
            cellClassName: 'w-64',
        },
    ];

    return (
        <DataTable
            hover
            selectable
            selection={{
                value: selectedBuyers,
                onChange: onChangeSelection,
            }}
            groups={[{ key: 'id', selectable: false }]}
            groupColumns={{
                id: [
                    {
                        key: 'source',
                        title: <FormattedMessage id="forms.labels.source" defaultMessage="Source" />,
                        dataIndex: 'contactRequest.source',
                        className: 'w-48',
                        cellClassName: 'py-3 w-48',
                    },
                    {
                        key: 'negotiation',
                        title: <FormattedMessage id="forms.labels.negotiation" defaultMessage="Negotiation" />,
                        dataIndex: 'contactRequest.preference.negotiation',
                        className: 'w-48',
                        cellClassName: 'py-3 w-48',
                        render: renderNegotiation,
                    },
                    {
                        key: 'date',
                        title: <FormattedMessage id="forms.labels.incoming_date" defaultMessage="Incoming date" />,
                        dataIndex: 'contactRequest.createdAt',
                        render: renderDate,
                        className: 'w-32',
                        cellClassName: 'py-3 w-32',
                    },
                    {
                        key: 'actions',
                        title: null,
                        dataIndex: 'id',
                        render: renderAction,
                        className: 'w-10',
                        cellClassName: 'py-3 w-10',
                    },
                ],
            }}
            columns={columns}
            dataSource={buyers}
        />
    );
}
