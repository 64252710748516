import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { TEAMS } from '@/app.routing/routes';
import SideMenuLink from '../navigations/SideMenu/SideMenuLink';
import LeftNavBar from '../navigations/LeftNavBar';

const TeamsAside: React.FC<{ location: string }> = props => {
    const { location } = props;

    return (
        <LeftNavBar location={location}>
            <SideMenuLink
                to={TEAMS}
                icon="units"
                fuzzyMatch
                location={location}
                iconActive="units-fill"
                label={<FormattedMessage id="teams.aside" defaultMessage="Teams" />}
            />
        </LeftNavBar>
    );
};

export default TeamsAside;
