import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PagePane from '@/app.layouts/PagePane/PagePane';
import useGeoTemplates from '@/app.hooks/useGeoTemplates';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import { GeoTemplate } from '@/graphql/generated/types';
import OfficeGeoImport from './OfficeGeoImport';
import OfficeGeoAction from './OfficeGeoAction';
import OfficeGeoList from './OfficeGeoList';
import OfficeGeoModal from './OfficeGeoModal';
import { OfficeGeoContext } from './context';
import OfficeGeoDelete from './OfficeGeoDelete';

type Props = {
    officeId: string;
};

const OfficeGeo: React.FC<Props> = props => {
    const { officeId } = props;

    const intl = useIntl();

    const {
        data,
        loading,
        refetch,
        fetchMore,
        pagination,
        addGeoTemplate,
        updateGeoTemplate,
        deleteGeoTemplate,
    } = useGeoTemplates(officeId);

    const [activeEntity, setActiveEntity] = useState<GeoTemplate | undefined>(undefined);
    const [visible, setVisible] = useState<'postalCode' | 'geometry' | null>(null);
    const [visibleDelete, setVisibleDelete] = useState(false);

    const content = {
        title: intl.formatMessage({
            id: 'office.geo',
        }),
    };

    return (
        <OfficeGeoContext.Provider
            value={{
                data,
                loading,
                visible,
                refetch,
                officeId,
                fetchMore,
                setVisible,
                pagination,
                activeEntity,
                setActiveEntity,
                visibleDelete,
                setVisibleDelete,
                addGeoTemplate,
                updateGeoTemplate,
                deleteGeoTemplate,
            }}
        >
            <LayoutContainer>
                <PagePane
                    title={content.title}
                    actions={[<OfficeGeoImport key="import" />, <OfficeGeoAction key="action" />]}
                >
                    <OfficeGeoList />

                    {visible && <OfficeGeoModal type={visible} show={!!visible} />}

                    {visibleDelete && <OfficeGeoDelete />}
                </PagePane>
            </LayoutContainer>
        </OfficeGeoContext.Provider>
    );
};

export default OfficeGeo;
