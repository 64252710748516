import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@sweepbright/uikit/build/esm/button';
import { Input } from '@/app.components';
import Modal from '@/app.components/elements/Modal';
import useLocalStorage from '@/app.hooks/useLocalStorage';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { PropertyFormDescriptionContext } from '@/new.domains/properties/contexts/PropertyFormDescriptionContext';

const PropertiesDescriptionAIModal: React.FC = () => {
    const {
        content,
        visible,
        fieldName,
        setVisible,
        handleGenerate,
        generateLoading,
        generateSettings,
        setGenerateSettings,
    } = useContext(PropertyFormDescriptionContext);

    const [text, setText] = useState('');
    const [sliderChanged, setSliderChanged] = useState(false);

    const [valueAILength, setValueAILength] = useLocalStorage<number>('properties.description.length', 450);

    const handleHide = () => {
        if (!generateLoading) {
            setVisible('');
            setText('');
        }
    };

    useEffect(() => {
        if (valueAILength < 450) {
            setValueAILength(450);
        }
    }, [valueAILength, setValueAILength]);

    return (
        <Modal width={600} onHide={handleHide} show={visible === fieldName}>
            <div>
                <Modal.Header>
                    <Modal.Title>{content.generate}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Input
                            type="checkbox"
                            name="showLocation"
                            label={content.showLocation}
                            checked={generateSettings.showLocation}
                            onChange={() => {
                                setGenerateSettings({
                                    ...generateSettings,
                                    showLocation: !generateSettings.showLocation,
                                });
                            }}
                        />
                        <Input
                            type="checkbox"
                            name="showPrice"
                            label={content.showPrice}
                            checked={generateSettings.showPrice}
                            onChange={() => {
                                setGenerateSettings({
                                    ...generateSettings,
                                    showPrice: !generateSettings.showPrice,
                                });
                            }}
                        />

                        <Input
                            type="range"
                            horizontal
                            min={450}
                            max={1000}
                            value={valueAILength}
                            defaultValue={valueAILength}
                            tipFormatter={value => {
                                return Number(value.toFixed(0));
                            }}
                            addonAfterClassName="input-group-addon__nostyle"
                            onChange={newValue => {
                                if (!sliderChanged) {
                                    setSliderChanged(true);
                                }

                                if (sliderChanged) {
                                    setValueAILength(+Number(`${newValue}`).toFixed(0));
                                }
                            }}
                            addonAfter={<span className="input-group-addon__number">{valueAILength}</span>}
                            label={
                                <FormattedMessage id="description.ai.length" defaultMessage="AI Description Length" />
                            }
                        />

                        <FormPanel title={content.extraText}>
                            <Input
                                name="notes"
                                value={text}
                                type="autosize-textarea"
                                placeholder={content.placeholder}
                                onChange={event => {
                                    setText(event.target.value);
                                }}
                            />

                            <div className="properties-description-ai-tags">
                                {[1, 2, 3, 4, 5].map(el => {
                                    const isActive = generateSettings[`tag${el}`];

                                    return (
                                        <button
                                            key={el}
                                            onClick={() => {
                                                setGenerateSettings(prevState => ({
                                                    ...prevState,
                                                    [`tag${el}`]: !prevState[`tag${el}`],
                                                }));
                                            }}
                                            className={`properties-description-ai-tags__item${
                                                isActive ? ' active' : ''
                                            }`}
                                        >
                                            {content[`tag${el}`]}
                                        </button>
                                    );
                                })}
                            </div>
                        </FormPanel>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleHide} disabled={generateLoading}>
                        {content.cancel}
                    </Button>
                    <Button
                        variant="primary"
                        disabled={generateLoading}
                        onClick={() => {
                            handleGenerate(fieldName, text);
                        }}
                    >
                        {generateLoading ? content.saving : content.save}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default PropertiesDescriptionAIModal;
