import { useQuery } from '@apollo/react-hooks';
import { GetContactLabelsQuery, GetContactLabelsQueryVariables } from '@/graphql/generated/types';
import { GET_CONTACT_LABELS } from '@/graphql/queries/contacts/getContactLabels';

export const useContactLabels = (id?: string) => {
    const { data, refetch } = useQuery<GetContactLabelsQuery, GetContactLabelsQueryVariables>(GET_CONTACT_LABELS, {
        skip: !id,
        variables: { id: id || '' },
    });

    return { data: data?.contactLabels, refetch };
};
