import gql from 'graphql-tag';

export const INVITE_OFFICE_USERS = gql`
    mutation InviteOfficeUsers($invite: InviteInput!, $officeId: ID!) {
        inviteUsers(invite: $invite, officeId: $officeId)
    }
`;

export const INVITE_COMPANY_ADMINS = gql`
    mutation InviteCompanyAdmins($invite: InviteInput!, $companyId: ID!) {
        inviteUsers(invite: $invite, companyId: $companyId)
    }
`;
