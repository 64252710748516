import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { PropertyData } from '@/app.utils/services/PatchBuilders/PropertyPatchBuilder';
import { useLocale } from '@/app.components/localize/Localized';

export const FormattedLocation = function PropertyLocation({
    location = {},
}: {
    location: Partial<PropertyData['location']>;
}) {
    const locale = useLocale();
    const locationText = location?.formatted_address?.[locale];

    return (
        <div title={locationText} className="truncate">
            {locationText || <FormattedMessage id="property.no_location" defaultMessage="No location defined" />}
        </div>
    );
};
