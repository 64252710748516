// @ts-nocheck
import React from 'react';
import Link from '@/app.components/elements/Link';
import { PROPERTY_UNITS, PROPERTIES } from '@/app.routing/routes';
import PropertyInfoCard from '@/app.domains/properties/PropertyInfoCard';
import { trackHandler, events } from '@/app.utils/analytics';
import './PropertyHeader.scss';
import { Estate } from '@/graphql/generated/types';
import { adaptProperty } from '@/app.domains/properties/PropertyCard';
import { FormattedMessage } from 'react-intl-sweepbright';

export default function PropertyHeader({ property }: { property?: Estate }) {
    const loading = !property?.id;
    const { backTo, backToLabel } = useBackLinkProps(property);

    return (
        <PropertyInfoCard className="c-property-header" property={adaptProperty(property ?? {})}>
            {!loading && (
                <Link
                    className="c-property-header__back-btn"
                    to={backTo}
                    onClick={trackHandler(events.PROPERTY_BACK_BTN_CLICKED)}
                >
                    <ArrowLeftIcon /> <span>{backToLabel}</span>
                </Link>
            )}
        </PropertyInfoCard>
    );
}

function ArrowLeftIcon() {
    return (
        <svg
            height="18"
            viewBox="0 0 22 18"
            width="22"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <path
                    id="a"
                    d="m20 11.5h-14.8l5.1-5.1c.2-.2.2-.5 0-.7s-.5-.2-.7 0l-6 6s-.1.1-.1.2c-.1.1-.1.3 0 .4 0 .1.1.1.1.2l6 6c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7l-5.1-5.1h14.7c.3 0 .5-.2.5-.5s-.2-.7-.5-.7z"
                />
                <mask id="b" fill="#fff">
                    <use fill="#fff" fillRule="evenodd" xlinkHref="#a" />
                </mask>
            </defs>
            <g fill="#fff" fillRule="evenodd" transform="translate(-2 -4)">
                <use xlinkHref="#a" />
                <path d="m0 0h24v24h-24z" mask="url(#b)" />
            </g>
        </svg>
    );
}

// helpers

function useBackLinkProps(property?: Estate) {
    const backTo = property?.isUnit ? PROPERTY_UNITS(property!.projectId!) : PROPERTIES;
    const backToLabel = property?.isUnit ? (
        <FormattedMessage id="navigation.back_to.project" defaultMessage="Project" />
    ) : (
        <FormattedMessage id="navigation.back_to.properties" defaultMessage="Properties" />
    );

    return { backTo, backToLabel };
}
