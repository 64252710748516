// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl-sweepbright';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import useRouter from '@/app.hooks/useRouter';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import { INTEGRATIONS, MOVEBOTLER } from '../../app.routing/routes';
import LeftNavBar from '../../app.components/navigations/LeftNavBar';
import SideMenuLink from '../../app.components/navigations/SideMenu/SideMenuLink';

function IntegrationsLayout(props) {
    const { location, children } = props;
    const showMoveBotler = useSelector(getFeature('apps.moveBotler.enabled'));
    const router = useRouter();
    React.useEffect(() => {
        if (!showMoveBotler && location.pathname === MOVEBOTLER) {
            // kick the user out if the feature flag is disabled
            router.replace(INTEGRATIONS);
        }
    }, [showMoveBotler, location]);

    return (
        <LayoutContainer>
            <LeftNavBar location={location.pathname}>
                <SideMenuLink
                    fuzzyMatch
                    label={<FormattedMessage id="navigation.integrations.integrations" defaultMessage="Integrations" />}
                    icon="rocket"
                    to={INTEGRATIONS}
                    iconActive="rocket-fill"
                />
                {showMoveBotler && (
                    <SideMenuLink
                        fuzzyMatch
                        label={<FormattedMessage id="navigation.integrations.movebotler" defaultMessage="MoveBotler" />}
                        icon="movebotler"
                        to={MOVEBOTLER}
                        iconActive="movebotler-fill"
                    />
                )}
            </LeftNavBar>
            {children}
        </LayoutContainer>
    );
}

export default IntegrationsLayout;
