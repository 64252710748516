import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import useCompanyLanguages from '@/app.hooks/useCompanyLanguages';
import { FormattedMessage } from 'react-intl-sweepbright';
import { Icon } from '@/app.components';

export const LanguagesDropdown: FC<{
    onSelect?: (language: string) => void;
    availableLanguages: Set<string>;
    fallback?: ReactNode;
    onFallbackSelect?: () => void;
    onToggle?: (nextShow: boolean) => void;
}> = ({ onSelect, availableLanguages, fallback, onFallbackSelect, onToggle }) => {
    const allLanguages = useCompanyLanguages();
    const languages = useMemo(() => allLanguages.filter(({ value }) => availableLanguages.has(value)), [
        allLanguages,
        availableLanguages,
    ]);

    const clickHandlers = useMemo<((lang: string) => void | null)[]>(
        () => languages.map(({ value }) => onSelect?.bind(null, value)),
        [languages, onSelect],
    );

    const handleFallbackSelect = useCallback(() => onFallbackSelect?.(), [onFallbackSelect]);

    return (
        <Dropdown id="languages_dropdown" data-testid="visits-modal-languages-dropdown" onToggle={onToggle}>
            <Dropdown.Toggle
                as="button"
                className="bg-transparent flex items-center focus:outline-none rounded btn-link"
                type="button"
            >
                <FormattedMessage
                    id="modals.schedule_visit.languages_dropdown.use_template"
                    defaultMessage="Use Template"
                />
                <Icon icon="chevron" size={18} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {languages.map(({ value: langCode, localizedLabel }, i) => (
                    <Dropdown.MenuItem key={langCode} onClick={clickHandlers[i]}>
                        {localizedLabel}
                    </Dropdown.MenuItem>
                ))}
                {fallback ? <Dropdown.MenuItem onClick={handleFallbackSelect}>{fallback}</Dropdown.MenuItem> : null}
            </Dropdown.Menu>
        </Dropdown>
    );
};
