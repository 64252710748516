import invariant from 'tiny-invariant';
import ServiceRequests from '@/requests/ServiceRequest';

export default class SearchServiceRequests extends ServiceRequests {
    serviceName = 'search';
    getBaseUrl = () => {
        invariant(process.env.SEARCH_SERVICE_URL, 'process.env.SEARCH_SERVICE_URL needs to be defined');

        return process.env.SEARCH_SERVICE_URL;
    };

    searchEstates = params => {
        return this.post(`/properties/search`, params);
    };

    getEstatesOptions = () => {
        return this.get(`/estates/options`);
    };

    getContactsOptions = () => {
        return this.get(`/contacts/options`);
    };

    searchContacts = params => {
        return this.post(`/contacts/search`, params);
    };

    getGeoTemplate(officeId: string, id: string) {
        return this.get(`/geo-templates/${id}`, {
            params: {
                office_id: officeId,
            },
        });
    }

    getGeoTemplates(officeId: string, parameters: { page?: number; limit?: number; type?: string }) {
        return this.get(`/geo-templates`, {
            params: {
                office_id: officeId,
                page: parameters.page || 1,
                type: parameters.type,
                limit: parameters.limit,
            },
        });
    }

    addGeoTemplate = (officeId: string, payload: any) => {
        return this.post(`/geo-templates`, {
            office_id: officeId,
            ...payload,
        });
    };

    updateGeoTemplate = (officeId: string, payload: any) => {
        return this.put(`/geo-templates/${payload._id}`, {
            office_id: officeId,
            ...payload,
        });
    };

    deleteGeoTemplate = (_officeId: string, payload: any) => {
        return this.delete(`/geo-templates/${payload._id}`);
    };

    searchPropertyGeoJson = body => {
        const baseUrl = this.getBaseUrl();

        return this.post(`${baseUrl}/properties/search/geoJson`, body || undefined);
    };
}
