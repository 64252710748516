import PropTypes from 'prop-types';
import React from 'react';
import useId from '@/app.hooks/useId';
import Icon, { ICON_SIZE_SMALL, IconName } from '../icons/Icon';

export default function IconCheckbox({
    icon,
    name,
    subtitle,
    title,
    value,
    onChange,
    checked,
}: React.HTMLProps<HTMLInputElement> & { icon: IconName; subtitle: React.ReactNode; title: React.ReactNode }) {
    const id = `${name}-${useId()}`;

    return (
        <li>
            <input name={name} id={id} type="radio" value={value} checked={checked} onChange={onChange} />
            <label htmlFor={id}>
                <div className="bc-media-card">
                    <div className="bc-media-card-image flex items-center">
                        <Icon size={ICON_SIZE_SMALL} icon={icon} />
                    </div>
                    <div className="bc-media-card-info">
                        <div>{title}</div>
                        <small className="text-muted">{subtitle}</small>
                    </div>
                </div>
            </label>
        </li>
    );
}

IconCheckbox.propTypes = {
    icon: PropTypes.string.isRequired,
    input: PropTypes.object,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.string.isRequired,
};
