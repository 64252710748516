import useOffice from '@/app.hooks/useOffice';
import useContacts from '@/new.domains/contacts/hooks/useContacts';
import useProperties from '@/new.domains/properties/hooks/useProperties';
import { ResourceDomainType } from '@/shared/resource/types';

type Props = {
    skip?: boolean;
    preference: any;
    setPage?: Function;
    searchSettings: any;
    domainType: ResourceDomainType;
};

const useMatch = (props: Props) => {
    const { skip, setPage, searchSettings, domainType } = props;

    const office = useOffice();
    const officeId = office.get('id');

    const propertiesPayload = useProperties({
        officeId,
        oldLogic: true,
        handlePage: newPage => {
            if (setPage) {
                setPage(newPage);
            }
        },
        skip: skip || domainType !== 'contact_matches',
        searchSettings: domainType === 'contact_matches' ? searchSettings : {},
    });

    const contactsPayload = useContacts({
        officeId,
        oldLogic: true,
        handlePage: newPage => {
            if (setPage) {
                setPage(newPage);
            }
        },
        skip: skip || domainType !== 'property_matches',
        searchSettings: domainType === 'property_matches' ? searchSettings : {},
    });

    return domainType === 'contact_matches' ? propertiesPayload : contactsPayload;
};

export default useMatch;
