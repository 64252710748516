import SweepbrightCrudRequest from './SweepbrightCrudRequest';

export default class OAuthRequests extends SweepbrightCrudRequest {
    resource = 'oauth';

    getClient = (id: string) => {
        return this.get(`${this.resource}/clients/${id}`);
    };

    authorize = (params: object) => {
        return this.setQueryParameters(params).post(`${this.resource}/authorize`);
    };
}
