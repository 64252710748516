import { fromJS } from 'immutable';
import { AllHtmlEntities } from 'html-entities';
import currenciesSchema from '@sweepbright/api-schemas/schemas/prices/currencies.json';
import currencies from './currencies.json';

const entities = new AllHtmlEntities();
const allowedCurrencies = currenciesSchema.enum;

export const numeralSystems = fromJS({
    decimal_comma: {
        decimal: ',',
        thousand: '.',
    },
    decimal_point: {
        decimal: '.',
        thousand: ',',
    },
});

export default Object.keys(currencies).reduce((all, currency) => {
    if (!allowedCurrencies.includes(currency)) {
        return all;
    }

    const info = currencies[currency];

    all[currency] = {
        ...info,
        symbol: entities.decode(info.symbol),
    };

    return all;
}, {});
