import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Status from '@/app.components/elements/Status/Status';

type Props = {
    status: 'active' | 'inactive' | 'pending';
    iconStatus: 'chacked' | 'error' | 'busy';
};

export default function PortalStatusWarning({ status, iconStatus }: Props) {
    return (
        <div className="flex border border-gray-300 border-solid py-4 pr-12 mb-4">
            <div className="flex flex-none w-16 items-center justify-center">
                <Status status={iconStatus} size={20} style={{ position: 'static' }} />
            </div>
            <div>
                <h5>
                    <FormattedMessage id={`channel.status.${status}.title`} />
                </h5>
                <small className="text-info">
                    <FormattedMessage tagName={React.Fragment} id={`channel.status.${status}.body`} />
                </small>
            </div>
        </div>
    );
}
