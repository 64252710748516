import React from 'react';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { FormattedMessage } from 'react-intl-sweepbright';
import { track, events, trackWithHandler } from '@/app.utils/analytics';
import useEffectPostMount from '@/app.hooks/useEffectPostMount';
import Icon from '../../icons/Icon';
import Input from '../../forms/Input/Input';

type Props = {
    'data-testid'?: string;
    className?: string;
    defaultValues?: any;
    equalityChecker?: (currentValues: any, defaultValues: any) => boolean;
    excludeProjects?: boolean;
    filterable?: boolean;
    filtersModal?: React.ComponentType<any>;
    initialValues: any;
    inputProps?: any;
    modalProps?: any;
    onChange: (values: any) => void;
    text?: React.ReactNode;
    visible?: boolean;
};

const SearchV2 = (props: Props) => {
    const {
        filterable = true,
        text = <FormattedMessage id="forms.search.text" defaultMessage="Search" />,
        visible = true,
        modalProps = {},
        inputProps = {},
        initialValues = {
            query: '',
        },
        defaultValues,
        equalityChecker = () => {
            throw new Error('You need to provide a equality checker for filterable search');
        },
    } = props;

    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [query, setQuery] = React.useState(initialValues.query || '');
    const [appliedFilters, setAppliedFilters] = React.useState(null);

    function valuesDifferentFromDefaults() {
        if (!filterable) {
            return false;
        }
        if (!defaultValues) {
            throw new Error('defaultValues prop is needed for filterable search');
        }

        const currentValues = appliedFilters || omit(initialValues, 'query');

        return !equalityChecker(Object.freeze(currentValues), defaultValues);
    }

    const onChange = React.useCallback(debounce(props.onChange, 300), []);

    useEffectPostMount(() => {
        const currentFilters = appliedFilters || initialValues;
        const attributes = { ...currentFilters, query };
        onChange(attributes);
    }, [query, JSON.stringify(appliedFilters)]);

    const handleOpenModal = trackWithHandler(() => setModalIsOpen(true), events.SEARCH_FILTERS_BTN_CLICKED);

    const handleCloseModal = trackWithHandler(() => setModalIsOpen(false), events.SEARCH_FILTERS_MODAL_CLOSED);

    const handleResetFilters = () => {
        track(events.SEARCH_FILTERS_RESETED);
        setAppliedFilters(defaultValues);
        handleCloseModal();
    };

    const handleApplyFilters = (filters: any) => {
        track(events.SEARCH_FILTERS_APPLIED, { filters });
        setAppliedFilters(filters);
        handleCloseModal();
    };

    const renderModal = () => {
        if (!filterable) {
            return null;
        }
        const FiltersModal = props.filtersModal;
        if (!FiltersModal) {
            throw new Error('filters modal is missing for a filterable search');
        }

        return (
            <FiltersModal
                isOpen={modalIsOpen}
                initialValues={appliedFilters || initialValues}
                onSubmit={handleApplyFilters}
                onClose={handleCloseModal}
                onReset={handleResetFilters}
                {...modalProps}
            />
        );
    };

    const handleChangeQuery = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(evt.target.value);
    };

    if (!visible) {
        return null;
    }

    const filterIcon = valuesDifferentFromDefaults() ? 'sliders-active' : 'sliders';

    return (
        <div className={props.className}>
            <div className="form-group has-feedback">
                <Input
                    type="text"
                    placeholder={text}
                    name="query"
                    value={query}
                    onChange={handleChangeQuery}
                    {...inputProps}
                    data-testid={props['data-testid']}
                />
                {filterable && (
                    <Icon
                        data-testid="filter-icon"
                        icon={filterIcon}
                        className="form-control-feedback form-control-feedback--clickable"
                        onClick={handleOpenModal}
                    />
                )}
            </div>
            {renderModal()}
        </div>
    );
};

export default SearchV2;
