// @ts-nocheck
import React, { Component } from 'react';
import Immutable from 'immutable';
import { reduxForm as form } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl-sweepbright';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';
import FormPane from '@/app.components/forms/FormPane';
import { PropertyFieldVisibility } from '@/app.utils/services/Fields/PropertyFieldVisibility';
import { withActivePropertyConfirmation } from '@/app.utils/Decorators/withActivePropertyConfirmation';
import { getCompanySetting, getFeature } from '../../../../app.redux/selectors/index';
import EstateRepository from '../../../../app.utils/services/Repositories/EstateRepository';
import FormPanel from '../../../../app.components/forms/FormPanel/FormPanel';
import CharactersCountingInput from '../../../../app.components/forms/CharactersCountingInput';
import LocalizedInput, { localizeToFieldsObject } from '../../../../app.components/forms/LocalizedInput';
import WithPrivateTooltip from '../../../../app.components/forms/WithPrivateTooltip';
import Input from '../../../../app.components/forms/Input/Input';
import EstatePriceDropped from '../../../../app.components/elements/Alerts/EstatePriceDropped';
import { getPriceLabels } from '../../../../app.data/Translations/Prices';
import Separator from '../../../../app.components/elements/Separator';

interface PriceFormProps {
    fields: string[];
    property: Immutable.Map;
    rentPeriod: string;
    showNewFields: boolean;
}
interface PriceFormState {
    propertyRepository: EstateRepository;
}

class PriceForm extends Component<PriceFormProps, PriceFormState> {
    static getDerivedStateFromProps({ property }) {
        return {
            propertyRepository: new EstateRepository(property),
        };
    }

    getLabels() {
        const repository = this.state.propertyRepository;
        let labels = {};

        labels = { ...labels, ...getPriceLabels(repository.isSale()) };
        labels.current_price = <WithPrivateTooltip>{labels.current_price}</WithPrivateTooltip>;

        return labels;
    }

    getCurrency() {
        const repository = this.state.propertyRepository;

        return repository.getCurrency();
    }

    renderRentPeriod() {
        const { rentPeriod } = this.props;
        const repository = this.state.propertyRepository;

        if (repository.isLet()) {
            return (
                <span>
                    {repository.getCurrency()}/<FormattedMessage id={`forms.labels.price.rent.period.${rentPeriod}`} />
                </span>
            );
        }

        return repository.getCurrency();
    }

    render() {
        const { property, handleSubmit, status, editingDisabled } = this.props;
        const { propertyRepository } = this.state;
        const fields = this.props.fields.price;
        const labels = this.getLabels();
        const currency = this.renderRentPeriod();
        const currencyWithoutPeriod = this.getCurrency();

        const sizes = {
            labelClassName: 'col-sm-4',
            wrapperClassName: 'col-sm-6',
        };

        return (
            <FormPane
                disabled={editingDisabled}
                disabledText={
                    editingDisabled && <FormattedMessage id="property.status.archived" defaultMessage="Archived" />
                }
                title={
                    property.get('negotiation') === 'sale' ? (
                        <FormattedMessage id="titles.property.price.sale" defaultMessage="Set the price" />
                    ) : (
                        <FormattedMessage id="titles.property.price.rent" defaultMessage="Set the rent" />
                    )
                }
                onSubmit={handleSubmit}
                status={status}
            >
                <FormPanel>
                    {property.get('priceDropped') && <EstatePriceDropped estate={property} />}

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.published_price">
                        <Input
                            horizontal
                            type="price"
                            decimalScale={2}
                            label={labels.price}
                            {...fields.published_price.amount}
                            {...sizes}
                            addonAfter={currency}
                            name="asking_price"
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.current_price">
                        <Input
                            horizontal
                            type="price"
                            decimalScale={2}
                            label={labels.current_price}
                            {...fields.current_price.amount}
                            {...sizes}
                            addonAfter={currency}
                            help={labels.help}
                            name="current_price"
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.property_tax">
                        <Input
                            horizontal
                            type="price"
                            decimalScale={2}
                            label={<FormattedMessage id="forms.labels.property_tax" defaultMessage="Property Tax" />}
                            {...fields.property_tax.amount}
                            {...sizes}
                            addonAfter={currencyWithoutPeriod}
                            help={labels.help}
                            name="property_tax"
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.recurring_costs">
                        <Input
                            horizontal
                            type="price"
                            decimalScale={2}
                            label={
                                <FormattedMessage id="forms.labels.recurring_costs" defaultMessage="Recurring Costs" />
                            }
                            {...fields.recurring_costs.amount}
                            {...sizes}
                            addonAfter={currency}
                            help={labels.help}
                            name="recurring_costs"
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.vat_regime">
                        <Input
                            horizontal
                            {...sizes}
                            {...fields.vat_regime}
                            type="price"
                            decimalScale={2}
                            max={100}
                            min={0}
                            maxLength={3}
                            label={
                                <FormattedMessage id="property.forms.labels.vat_regime" defaultMessage="Vat Regime" />
                            }
                            addonAfter="%"
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility
                        property={propertyRepository}
                        path="attributes.price.yearly_budgeted_building_costs"
                    >
                        <Input
                            horizontal
                            type="price"
                            decimalScale={2}
                            label={
                                <FormattedMessage
                                    id="forms.labels.yearly_budgeted_building_costs"
                                    defaultMessage="Yearly Budgeted Building Costs"
                                />
                            }
                            {...fields.yearly_budgeted_building_costs.amount}
                            {...sizes}
                            addonAfter={currencyWithoutPeriod}
                            name="yearly_budgeted_building_costs"
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.guarantee">
                        <Input
                            horizontal
                            type="price"
                            decimalScale={2}
                            name="guarantee"
                            {...sizes}
                            {...fields.guarantee.amount}
                            addonAfter={currencyWithoutPeriod}
                            label={<FormattedMessage id="forms.labels.guarantee" defaultMessage="Guarantee" />}
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility
                        property={propertyRepository}
                        path="attributes.price.inventory_report_cost"
                    >
                        <Input
                            horizontal
                            {...sizes}
                            type="price"
                            decimalScale={2}
                            name="inventory_report_cost"
                            addonAfter={currencyWithoutPeriod}
                            {...fields.inventory_report_cost.amount}
                            label={
                                <FormattedMessage
                                    id="forms.labels.inventory_report_cost"
                                    defaultMessage="Inventory Report Cost"
                                />
                            }
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.reference_rent">
                        <Input
                            horizontal
                            {...sizes}
                            type="price"
                            decimalScale={2}
                            addonAfter={currency}
                            name="reference_rent"
                            {...fields.reference_rent.amount}
                            label={
                                <FormattedMessage id="forms.labels.reference_rent" defaultMessage="Reference Rent" />
                            }
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.base_rent">
                        <Input
                            horizontal
                            {...sizes}
                            type="price"
                            name="base_rent"
                            decimalScale={2}
                            addonAfter={currency}
                            {...fields.base_rent.amount}
                            label={<FormattedMessage id="forms.labels.base_rent" defaultMessage="Base Rent" />}
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.rent_supplement">
                        <Input
                            horizontal
                            {...sizes}
                            type="price"
                            decimalScale={2}
                            addonAfter={currency}
                            name="rent_supplement"
                            {...fields.rent_supplement.amount}
                            label={
                                <FormattedMessage id="forms.labels.rent_supplement" defaultMessage="Rent Supplement" />
                            }
                        />
                    </PropertyFieldVisibility>

                    {this.props.showNewFields && (
                        <>
                            <Separator />

                            <PropertyFieldVisibility
                                property={propertyRepository}
                                path="attributes.price.life_annuity.applicable"
                            >
                                <Input
                                    type="checkbox"
                                    {...fields.life_annuity.applicable}
                                    label={<FormattedMessage id="property_price_life_annuity" />}
                                />
                            </PropertyFieldVisibility>

                            {!!fields?.life_annuity?.applicable?.value && (
                                <>
                                    <PropertyFieldVisibility
                                        property={propertyRepository}
                                        path="attributes.price.life_annuity.monthly_rent"
                                    >
                                        <Input
                                            horizontal
                                            {...sizes}
                                            type="price"
                                            decimalScale={2}
                                            addonAfter={currency}
                                            name="monthly_rent"
                                            {...fields.life_annuity.monthly_rent.amount}
                                            label={<FormattedMessage id="property_price_life_annuity_monthly_rent" />}
                                        />
                                    </PropertyFieldVisibility>

                                    <PropertyFieldVisibility
                                        property={propertyRepository}
                                        path="attributes.price.life_annuity.advance"
                                    >
                                        <Input
                                            horizontal
                                            {...sizes}
                                            type="price"
                                            name="advance"
                                            decimalScale={2}
                                            addonAfter={currency}
                                            {...fields.life_annuity.advance.amount}
                                            label={<FormattedMessage id="property_price_advance" />}
                                        />
                                    </PropertyFieldVisibility>

                                    <PropertyFieldVisibility
                                        property={propertyRepository}
                                        path="attributes.price.life_annuity.maximum_duration"
                                    >
                                        <Input
                                            min={0}
                                            horizontal
                                            {...sizes}
                                            type="number"
                                            name="advance"
                                            {...fields.life_annuity.maximum_duration}
                                            addonAfter={<FormattedMessage id="property_price_years" />}
                                            label={<FormattedMessage id="property_price_maximum_duration" />}
                                        />
                                    </PropertyFieldVisibility>
                                </>
                            )}
                        </>
                    )}

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.costs">
                        <Separator />
                        <LocalizedInput
                            component={CharactersCountingInput}
                            label={<FormattedMessage id="forms.labels.costs" defaultMessage="Costs" />}
                            field={fields.costs}
                            rows={5}
                        />
                    </PropertyFieldVisibility>

                    <PropertyFieldVisibility property={propertyRepository} path="attributes.price.taxes">
                        <Separator />
                        <LocalizedInput
                            component={CharactersCountingInput}
                            label={<FormattedMessage id="forms.labels.taxes" defaultMessage="Taxes" />}
                            field={fields.taxes}
                            rows={5}
                        />
                    </PropertyFieldVisibility>
                </FormPanel>
            </FormPane>
        );
    }
}

export default compose(
    withProps(props => ({
        estateId: props.params.unit || props.params.property,
    })),
    withPropertyDetails('price'),
    connect(
        createStructuredSelector({
            rentPeriod: getCompanySetting('rent_period'),
            showNewFields: getFeature('property.feature.fields.june2024'),
        }),
    ),
    form({
        form: 'properties/price',
        fields: localizeToFieldsObject(['price.costs', 'price.taxes']).concat([
            'price.current_price.amount',
            'price.custom_price',
            'price.published_price.amount',
            'price.vat_regime',
            'price.yearly_budgeted_building_costs.amount',
            'price.property_tax.amount',
            'price.recurring_costs.amount',
            'price.guarantee.amount',
            'price.inventory_report_cost.amount',
            'price.reference_rent.amount',
            'price.base_rent.amount',
            'price.rent_supplement.amount',
            'price.life_annuity.applicable',
            'price.life_annuity.monthly_rent.amount',
            'price.life_annuity.advance.amount',
            'price.life_annuity.maximum_duration',
        ]),
    }),
    withActivePropertyConfirmation,
)(PriceForm);
