import keyBy from 'lodash/keyBy';

export default function mergeByKey<T extends {}>(key: string, arr1: T[], arr2: T[]): T[] {
    const byKey1: { [key: string]: T } = keyBy(arr1, e => e[key] as string);
    const byKey2: { [key: string]: T } = keyBy(arr2, e => e[key] as string);

    const merged: {
        [key: string]: T;
    } = Object.assign({}, byKey1, byKey2);

    return Object.values(merged);
}
