import React from 'react';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import PropTypes from 'prop-types';

const WithTooltip = ({ children, message }) => {
    if (message) {
        return (
            <Tooltip label={message}>
                <div>{children}</div>
            </Tooltip>
        );
    }

    return children;
};

WithTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    message: PropTypes.node,
};

export default WithTooltip;
