import React from 'react';
import { Button } from '@sweepbright/uikit';
import Modal from '@/app.components/elements/Modal';
import { FormattedMessage } from 'react-intl-sweepbright';
import useUser from '@/app.hooks/useUser';

function VendorReportPreviewModal({ show, onCancel, vendorId }) {
    const userId = useUser().get('id');
    const iFrameRef = React.useRef<HTMLIFrameElement>(null);
    const [previewLoaded, setPreviewLoaded] = React.useState(false);

    const handlePrint = () => {
        if (iFrameRef.current !== null) {
            // eslint-disable-next-line no-unused-expressions
            iFrameRef.current.contentWindow?.postMessage({ action: 'print' }, VENDOR_REPORT_PREVIEW_URL);
        }
    };

    return (
        <Modal show={show} onHide={onCancel} width="full">
            <Modal.Header>
                <Modal.Title className="flex justify-between items-center">
                    <FormattedMessage id="modals.vendor_report_preview.title" defaultMessage="Vendor Report Preview" />
                    <div className="flex space-x-2">
                        <Button onClick={handlePrint} disabled={!previewLoaded}>
                            <FormattedMessage id="buttons.print" defaultMessage="Print" />
                        </Button>
                        <Button onClick={onCancel}>
                            <FormattedMessage id="buttons.close" defaultMessage="Close" />
                        </Button>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="flex flex-col">
                <div className="p-1 w-full h-screen overflow-hidden border border-gray-light border-solid">
                    <iframe
                        onLoad={() => {
                            setPreviewLoaded(true);
                        }}
                        ref={iFrameRef}
                        style={{ border: 0 }}
                        frameBorder={0}
                        className="w-full h-full"
                        src={`${VENDOR_REPORT_PREVIEW_URL}/view?userId=${userId}&vendorId=${vendorId}`}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default VendorReportPreviewModal;
