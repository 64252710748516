import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { Input } from '@/app.components';
import { LegalEntities } from '@/graphql/generated/types';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import { CopyButton } from '@/app.components/elements/Buttons/CopyButton';

type Props = {
    index?: number;
    hasData: boolean;
    disabled?: boolean;
    entity: LegalEntities;
    onChange: (newEntity: LegalEntities, index?: number) => void;
    onDelete: (newEntity: LegalEntities, index?: number) => void;
};

const OfficesLegalEntitiesItem: React.FC<Props> = props => {
    const { index, entity, hasData, disabled, onChange, onDelete } = props;

    const intl = useIntl();

    const content = {
        name: intl.formatMessage({ id: 'offices.legal_entities.name' }),
        id: intl.formatMessage({ id: 'offices.legal_entities.id' }),
        add: intl.formatMessage({ id: 'offices.legal_entities.add' }),
    };

    const contentPlaceholder = {
        name: intl.formatMessage({ id: 'offices.legal_entities.name.placeholder' }),
        id: intl.formatMessage({ id: 'offices.legal_entities.id.placeholder' }),
    };

    const canDelete = index === 0 && !hasData ? !!entity.id : true;

    return (
        <Row style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Col sm={7}>
                <Input
                    type="text"
                    label={content.name}
                    readOnly={disabled}
                    value={entity.name || ''}
                    placeholder={contentPlaceholder.name}
                    onChange={event => {
                        onChange({ ...entity, name: event.target.value }, index);
                    }}
                />
            </Col>
            <Col sm={6}>
                <Input
                    readOnly
                    type="text"
                    label={content.id}
                    value={entity.id || ''}
                    placeholder={contentPlaceholder.id}
                    addonAfter={<CopyButton value={entity.id || ''} />}
                />
            </Col>
            {canDelete && (
                <Col sm={2} style={{ marginBottom: '15px' }}>
                    <ButtonIcon
                        iconSize={24}
                        variant="link"
                        type="circle-delete"
                        onClick={() => {
                            onDelete(entity, index);
                        }}
                    />
                </Col>
            )}
        </Row>
    );
};

export default OfficesLegalEntitiesItem;
