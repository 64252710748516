import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import moment from 'moment';
import { LoadingIndicator } from '@sweepbright/uikit';
import _ from 'lodash';
import { useToasts } from '@sweepbright/notifications';
import AdvancedSearch from '@/app.components/forms/Search/AdvancedSearch';
import { FormattedMessage } from 'react-intl-sweepbright';
import { deleteEventMutation } from '@/app.domains/properties/Schedule/visits_requests';
import EmptyState from '@/app.components/empty/EmptyState';
import { DayEvents } from '@/app.components/schedules/DayEvents';
import { useContactSchedule } from '@/app.hooks/useSchedule';
import { GetContactScheduleQueryVariables } from '@/graphql/generated/types';

export default function ContactSchedule({ contactId }: { contactId: string }) {
    const [query, setQuery] = React.useState('');
    const [tab, setTab] = React.useState('upcoming');

    const params = React.useMemo(() => {
        const params: GetContactScheduleQueryVariables = { contactId, query };
        if (tab === 'upcoming') {
            params.after = moment().toISOString();
        } else if (tab === 'past') {
            params.before = moment().toISOString();
        }

        return params;
    }, [contactId, query, tab]);

    const { addError } = useToasts();

    const { data, loading: isLoading, refetch } = useContactSchedule(params);
    const { schedule, pendingConfirmationCount } = data ?? {};

    const eventsByDay = _.groupBy(schedule ?? [], event => {
        return moment(event.start).startOf('day');
    });
    const [deleteEvent] = useMutation(deleteEventMutation, {
        onSuccess: () => {
            refetch();
        },
        onError: err => {
            // @ts-ignore
            const isForbidden = err?.message?.includes('403');

            addError({
                message: isForbidden ? (
                    <FormattedMessage id="unauthorised_403" />
                ) : (
                    <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                ),
            });
        },
    });

    const handleUpdateEvent = useCallback(() => {
        refetch();
    }, [refetch]);

    return (
        <div>
            <AdvancedSearch
                settings={{
                    query,
                }}
                initialTab={tab}
                filtersTabs={[
                    {
                        id: 'upcoming',
                        label: (
                            <FormattedMessage id="pages.contact_schedule.schedule_upcoming" defaultMessage="Upcoming" />
                        ),
                        onTabSelected: () => setTab('upcoming'),
                    },
                    {
                        id: 'past',
                        label: (
                            <span className="flex space-x-2">
                                <FormattedMessage id="pages.contact_schedule.schedule_past" defaultMessage="Past" />
                                {pendingConfirmationCount ? (
                                    <div className="text-white bg-primary px-2 rounded-full leading-none text-xs flex items-center">
                                        {pendingConfirmationCount}
                                    </div>
                                ) : null}
                            </span>
                        ),
                        onTabSelected: () => setTab('past'),
                    },
                ]}
                onSubmit={({ query }) => {
                    setQuery(query);
                }}
            />
            {isLoading && <LoadingIndicator />}
            {!isLoading && Object.keys(eventsByDay).length === 0 && tab === 'upcoming' && (
                <EmptyState
                    title={
                        <FormattedMessage
                            id="pages.contact_schedule.no_upcoming_events"
                            defaultMessage="No upcoming events"
                        />
                    }
                    body={
                        <FormattedMessage
                            id="pages.contact_schedule.upcoming_events_showup_here"
                            defaultMessage="Upcoming events will show up here"
                        />
                    }
                />
            )}
            {!isLoading && Object.keys(eventsByDay).length === 0 && tab === 'past' && (
                <EmptyState
                    title={
                        <FormattedMessage id="pages.contact_schedule.no_past_events" defaultMessage="No past events" />
                    }
                    body={
                        <FormattedMessage
                            id="pages.contact_schedule.history_will_showup_here"
                            defaultMessage="A history of your past events will show up here"
                        />
                    }
                />
            )}
            {!isLoading &&
                Object.entries(eventsByDay).map(([day, events]) => {
                    return (
                        <DayEvents
                            key={day}
                            day={day}
                            events={events}
                            onDeleteEvent={deleteEvent}
                            onUpdateEvent={handleUpdateEvent}
                        />
                    );
                })}
        </div>
    );
}
