import classNames from 'classnames';
import { css } from '@emotion/core';
import React from 'react';
import { useTheme } from '@/app.utils/theming/ThemeContext';

export const Description: React.FunctionComponent<{
    className?: string;
}> = ({ children, className, ...rest }) => {
    const theme = useTheme();

    return (
        <div
            css={css`
                .unread:before {
                    background-color: ${theme.colors.primary};
                }
            `}
            className={classNames(className)}
            {...rest}
        >
            {children}
        </div>
    );
};
