import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, FormGroup, HelpBlock, Row } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl-sweepbright';
import BrandAndLogo from '@/app.domains/company/BrandAndLogo';
import Location from '@/app.components/forms/Location/Location';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import PhoneInput from '@/app.components/forms/PhoneInput/PhoneInput';
import { CopyButton } from '@/app.components/elements/Buttons/CopyButton';
import CompanyFormMatching from '@/new.domains/company/CompanyForm/CompanyFormMatching';
import { languages } from '../../app.data/Localization';
import withConfirmation from '../../app.utils/Decorators/withConfirmation';
import FormPane from '../../app.components/forms/FormPane';
import FormPanel from '../../app.components/forms/FormPanel/FormPanel';
import Input from '../../app.components/forms/Input/Input';
import UnassignedLeadsNegotiator from './Leads/UnassignedLeadsNegotiator';

const transactionOptions = [
    {
        value: 'let_and_sale',
        label: <FormattedMessage id="forms.labels.transaction_types.let_and_sale" defaultMessage="We Sell And Let" />,
    },
    {
        value: 'sale',
        label: <FormattedMessage id="forms.labels.transaction_types.sale" defaultMessage="We Only Sell" />,
    },
    {
        value: 'let',
        label: <FormattedMessage id="forms.labels.transaction_types.let" defaultMessage="We Only Let" />,
    },
];

const rentPeriodOptions = [
    {
        value: 'week',
        label: <FormattedMessage id="forms.labels.rent_period.week" defaultMessage="Per Week" />,
    },
    {
        value: 'month',
        label: <FormattedMessage id="forms.labels.rent_period.month" defaultMessage="Per Month" />,
    },
];

const localizedLanguages = languages.map(({ value, label }) => {
    return {
        value,
        label: <FormattedMessage id={`general.locales.${value}`} defaultMessage={label} />,
    };
});

@withConfirmation
export default class Settings extends Component {
    static propTypes = {
        canSetRentPeriod: PropTypes.bool,
        enabledCompanyMatching: PropTypes.bool,
        fields: PropTypes.object,
        handleSubmit: PropTypes.func,
        status: PropTypes.instanceOf(Map),
        values: PropTypes.object,
    };

    renderTransactionTypes() {
        const { fields } = this.props;

        return (
            <FormPanel
                title={
                    <FormattedMessage
                        id="forms.titles.company_settings.transaction_types"
                        defaultMessage="Transaction Types"
                    />
                }
            >
                <Input>
                    {transactionOptions.map(option => (
                        <Input
                            type="radio"
                            {...fields.transaction_types}
                            key={option.value}
                            label={option.label}
                            value={option.value}
                            checked={fields.transaction_types.value === option.value}
                        />
                    ))}
                </Input>
                <HelpBlock>
                    <FormattedMessage
                        id="forms.help.company_settings.transaction_types.line_one"
                        defaultMessage="This setting affects available options in the SweepBright app and on your agency website."
                    />
                    <br />
                    <FormattedHTMLMessage
                        id="forms.help.company_settings.transaction_types.line_two"
                        defaultMessage={
                            '<a href="https://sweepbright.com/support/">Learn more about transaction types</a>&nbsp;in Help &amp; Learning.'
                        }
                    />
                </HelpBlock>
            </FormPanel>
        );
    }

    renderRentPeriod() {
        const { fields, canSetRentPeriod } = this.props;

        // Only show rent period section if the feature is enabled for this user
        if (!canSetRentPeriod) {
            return;
        }

        return (
            <FormPanel
                title={<FormattedMessage id="forms.titles.company_settings.rent_period" defaultMessage="Rent period" />}
            >
                <Input>
                    {rentPeriodOptions.map(option => (
                        <Input
                            type="radio"
                            {...fields.rent_period}
                            key={option.value}
                            label={option.label}
                            value={option.value}
                            checked={fields.rent_period.value === option.value}
                        />
                    ))}
                </Input>
            </FormPanel>
        );
    }

    renderLanguages() {
        const { fields, values } = this.props;
        const selected = localizedLanguages.filter(language => {
            return values.languages[language.value] === true;
        });

        return (
            <FormPanel title={<FormattedMessage id="languages.title" defaultMessage="Languages" />}>
                <FormGroup>
                    {localizedLanguages.map(({ value, label }) => {
                        const isDefaultLocale = value === fields.default_locale.value;
                        label = isDefaultLocale ? (
                            <span>
                                {label}{' '}
                                <span className="badge">
                                    <FormattedMessage
                                        id="general.default"
                                        defaultMessage="Default"
                                        tagName={React.Fragment}
                                    />
                                </span>
                            </span>
                        ) : (
                            label
                        );

                        return (
                            <Input
                                key={value}
                                type="checkbox"
                                value={value}
                                label={label}
                                disabled={isDefaultLocale}
                                id={`checkbox-lang-${value}`}
                                {...fields.languages[value]}
                            />
                        );
                    })}
                </FormGroup>

                {selected.length > 1 && (
                    <Input
                        label={<FormattedMessage id="languages.default_language" defaultMessage="Default Language" />}
                        help={
                            <FormattedHTMLMessage
                                id="forms.company_settings.default_language.help"
                                defaultMessage={`
                            Contacts of which we do not know the language, will get e-mails in the default language.
                            <br/>
                            The default company language will be applied on e-mails for contacts without language and on published properties' links of portals.
                            `}
                            />
                        }
                        {...fields.default_locale}
                    >
                        <Select
                            options={selected}
                            onChange={({ value } = {}) => fields.default_locale.onChange(value)}
                            value={fields.default_locale.value}
                            valueKey="value"
                            onBlur={null}
                            clearable={false}
                            autoComplete={false}
                            testId="select-default-locale"
                        />
                    </Input>
                )}
            </FormPanel>
        );
    }

    renderCompanyDetails() {
        const { fields, company } = this.props;

        const companyId = company.get('id');

        return (
            <FormPanel
                title={
                    <FormattedMessage id="pages.company.company_details_panel.title" defaultMessage="Company Details" />
                }
            >
                <Row>
                    <Col sm={15}>
                        <Input
                            readonly
                            disabled
                            type="text"
                            value={companyId}
                            label={<FormattedMessage id="company.id" defaultMessage="Company ID" />}
                            addonAfter={<CopyButton value={companyId} />}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={5}>
                        <Input
                            type="email"
                            label={
                                <FormattedMessage
                                    id="pages.website.contact_info_panel.email_label"
                                    defaultMessage="E-Mail"
                                />
                            }
                            {...fields.contact_email}
                        />
                    </Col>
                    <Col sm={5}>
                        <Input
                            label={
                                <FormattedMessage
                                    id="pages.website.contact_info_panel.phone_label"
                                    defaultMessage="Phone"
                                />
                            }
                        >
                            <PhoneInput {...fields.contact_phone} />
                        </Input>
                    </Col>
                    <Col sm={5}>
                        <Input
                            type="text"
                            label={
                                <FormattedMessage
                                    id="forms.labels.registration_number"
                                    defaultMessage="Company Registration Number"
                                />
                            }
                            {...fields.registration_number}
                        />
                    </Col>
                </Row>
                <Location
                    entityType="company"
                    location={{
                        street: fields.contact_street,
                        street_2: fields.contact_street_2,
                        number: fields.contact_number,
                        addition: fields.contact_additional,
                        box: fields.contact_box,
                        postal_code: fields.contact_zip,
                        city: fields.contact_city,
                        borough_or_district: fields.contact_borough_or_district,
                        province_or_state: fields.contact_county,
                        country: fields.contact_country,
                    }}
                />
            </FormPanel>
        );
    }

    render() {
        const { status, handleSubmit } = this.props;

        return (
            <FormPane
                title={<FormattedMessage id="forms.titles.company_settings" defaultMessage="Company Settings" />}
                status={status}
                onSubmit={handleSubmit}
            >
                <UnassignedLeadsNegotiator inputProps={this.props.fields.unassigned_leads_admin_id} />
                {this.renderTransactionTypes()}
                {this.renderRentPeriod()}
                {this.renderLanguages()}
                <TimezoneSettingsSection {...this.props.fields.timezone} />
                <BrandAndLogo
                    brandNameField={this.props.fields.name}
                    logo={this.props.fields.photo.data.url}
                    photoFileId={this.props.fields.photo.data.photo_file_id}
                    logoUploadedAt={this.props.fields.photo.data.uploaded_at.initialValue}
                    setLogoFile={this.props.fields.photo.file.onChange}
                />
                {this.renderCompanyDetails()}

                {this.props.enabledCompanyMatching && (
                    <FormPanel title={<FormattedMessage id="company.form.matching" defaultMessage="Matching" />}>
                        <CompanyFormMatching
                            type="multiple"
                            value={this.props.fields.matching_emails_replyto_multiple_properties.value}
                            onChange={this.props.fields.matching_emails_replyto_multiple_properties.onChange}
                        />
                        <CompanyFormMatching
                            type="single"
                            value={this.props.fields.matching_emails_replyto_single_property.value}
                            onChange={this.props.fields.matching_emails_replyto_single_property.onChange}
                        />
                    </FormPanel>
                )}
            </FormPane>
        );
    }
}

function TimezoneSettingsSection({ value, onChange }) {
    const [options, setOptions] = React.useState([]);
    React.useEffect(() => {
        import('moment-timezone').then(res => {
            setOptions(
                res.tz
                    .names()
                    .map(name => ({ value: name, label: `${name.replace(/_/, ' ')} (${res.tz(name).format('z')})` })),
            );
        });
    }, []);

    return (
        <FormPanel
            title={
                <FormattedMessage id="pages.company_settings.timezone_panel.title" defaultMessage="Timezone settings" />
            }
        >
            <Input
                label={<FormattedMessage id="pages.company_settings.tz.label" defaultMessage="Company Timezone" />}
                horizontal
                labelClassName="col-sm-6"
                wrapperClassName="col-sm-9"
            >
                <Select
                    name="timezone"
                    options={options}
                    clearable={false}
                    value={value}
                    onChange={option => {
                        onChange(option.value);
                    }}
                />
            </Input>
            <FormGroup>
                <Col className="col-sm-9 col-sm-push-6">
                    <HelpBlock>
                        <FormattedMessage
                            id="pages.company_settings.tz.help"
                            defaultMessage="We use the time zone setting when scheduling visits."
                        />
                    </HelpBlock>
                </Col>
            </FormGroup>
        </FormPanel>
    );
}
