// @ts-nocheck
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import classnames from 'classnames';
import Toolbar from '../Toolbar/Toolbar';
import Icon from '../../icons/Icon';

type State = {
    dismissed: boolean;
};

type Props = {
    title: React.ReactNode;
    action: React.ReactNode;
    children: React.ReactNode;
    className: string;
};

export default class DismissableAlert extends Component<Props, State> {
    state = {
        dismissed: false,
    };

    render() {
        const { title, children, action, className } = this.props;

        if (this.state.dismissed) {
            return null;
        }

        return (
            <Alert bsStyle={null} className={classnames(className, 'alert-dismissible alert-has-bc-toolbar')}>
                <Toolbar inPage={false} size="small">
                    <Toolbar.Left>
                        <h4 className="h4">{title}</h4>
                    </Toolbar.Left>
                    <Toolbar.Right>
                        <button type="button" className="btn-reset" onClick={() => this.setState({ dismissed: true })}>
                            <Icon icon="close" />
                        </button>
                    </Toolbar.Right>
                </Toolbar>
                <div className="alert-content text-left">{children}</div>
                {action && (
                    <Toolbar inPage={false}>
                        <Toolbar.Left>{action}</Toolbar.Left>
                    </Toolbar>
                )}
            </Alert>
        );
    }
}
