import React from 'react';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import OfficeGeo from '@/new.domains/offices/Offices/OfficeGeo';
import TeamWrapper from '@/new.domains/teams/TeamWrapper';

const LocationFilterTemplates = props => {
    const officeId = props.params.office;

    const geoTemplatesEnabled = useFeatureFlag('geo-templates-enabled');

    if (!geoTemplatesEnabled) {
        return null;
    }

    return (
        <TeamWrapper {...props}>
            <OfficeGeo officeId={officeId} />
        </TeamWrapper>
    );
};

export default LocationFilterTemplates;
