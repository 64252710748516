import React, { useContext } from 'react';
import { Waypoint } from 'react-waypoint';
import { LoadingIndicator } from '@sweepbright/uikit';
import { ResourceContext } from '../utils';

const ResourceLoading = () => {
    const { loading, entities, hasNextPage, getEmptyState, handleFetchMore } = useContext(ResourceContext);

    return (
        <>
            {!loading && hasNextPage && (
                <Waypoint
                    // @ts-ignore
                    onEnter={handleFetchMore}
                >
                    <span style={{ width: '20px', height: '20px', display: 'block' }}></span>
                </Waypoint>
            )}
            {!loading && entities.length === 0 && getEmptyState()}
            {loading && <LoadingIndicator message="Loading" />}
        </>
    );
};

export default ResourceLoading;
