// @ts-nocheck
import React from 'react';
import { List } from 'immutable';
import { reduxForm, FormProps } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import { FormFields } from '@sweepbright/webapp-shared/utils/types';
import DrawRegionModalInner from '@/app.components/modals/LocationPreferences/DrawRegionModalInner';
import { GeoJsonLocationPreference } from '@sweepbright/webapp-server/graphql/generated/types';
import Modal from '@/app.components/elements/Modal';

type Props = {
    fields: FormFields<GeoJsonLocationPreference>;
    onRemoveLocation: () => void;
    shapes: List<any>;
    onSubmit: () => void;
    initialShown: boolean;
    onEntered: () => void;
    show: boolean;
    onClose: () => void;
    // when the location is a template or not, used
    // primarily for displaying the text on the different UI elements
    type: 'template' | 'preference';
};

export type FormErrors = {
    name?: string;
    data?: {
        coordinates: string;
    };
};

type State = {
    errors?: { [key: string]: React.ReactNode };
    visible?: boolean;
};

class DrawRegionModal extends React.Component<Props & FormProps, State> {
    state: State = {};

    /**
     * Reset the currently drawn shapes and name to their initial valuess
     */
    reset = () => {
        // Reset map
        this.setState({ errors: undefined });
        // reset the form
        this.props.resetForm();
        // // Need to move back to original location.
    };

    nameFieldRef = React.createRef<HTMLInputElement>();

    onEntered = () => {
        this.setState({ visible: true });
    };

    render() {
        const field = this.props.fields;
        const exists = Boolean(field.id.value);

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onClose}
                onEntered={this.onEntered}
                onExiting={() => this.setState({ visible: false })}
                onExited={() => this.reset()}
                width="xl"
            >
                <DrawRegionModalInner
                    editing={exists}
                    onCancel={this.props.onClose}
                    onResetForm={this.reset}
                    handleSubmit={this.props.handleSubmit}
                    fields={this.props.fields}
                    onRemoveLocation={this.props.onRemoveLocation}
                    visible={this.state.visible}
                    submitFailed={this.props.submitFailed}
                    type={this.props.type}
                />
            </Modal>
        );
    }
}

export default reduxForm({
    form: 'draw_location_modal',
    fields: ['id', 'name', 'data.type', 'data.coordinates'],
    validate: (values: GeoJsonLocationPreference) => {
        const { name, data } = values;
        const errors: FormErrors = {};

        if (!name || name.toString().trim().length === 0) {
            errors.name = (
                <FormattedMessage
                    id="location.validation_errors.area_name_required"
                    defaultMessage="The area name is required"
                />
            );
        }
        if (!data.coordinates) {
            errors.data = {
                coordinates: (
                    <FormattedMessage
                        id="location.validation_errors.no_map_region"
                        defaultMessage="No region was drawn in the map"
                    />
                ),
            };
        }

        return errors;
    },
})(DrawRegionModal);
