// @ts-nocheck
import React from 'react';
import { useIntl } from 'react-intl';
import { FormGroup, Col, ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import Select from '@/app.components/selects/AdvancedSelect/Select';

type Option = {
    value: any;
    label: string;
};

export default function SelectInput({
    onChange,
    label,
    value,
    name,
    options,
    sorted,
    ...props
}: {
    name: string;
    label: React.ReactNode;
    value: any;
    onChange: (value: any) => void;
    options: Option[];
    sorted?: boolean;
    clearable?: boolean;
    isSearchable: boolean;
}) {
    const intl = useIntl();

    const formattedOptions = options.map(option => ({
        value: option.value,
        label: sorted ? (
            intl.formatMessage({ id: option.label, defaultMessage: option.label })
        ) : (
            <FormattedMessage id={option.label} defaultMessage={option.label} />
        ),
    }));

    const sortedOptions = sorted
        ? formattedOptions.sort((a, b) => {
              if (a.value === 'other') {
                  return 1;
              }
              if (b.value === 'other') {
                  return -1;
              }

              return a.label.localeCompare(b.label);
          })
        : formattedOptions;

    return (
        <div className="form-horizontal">
            <FormGroup>
                <Col sm={4}>
                    <ControlLabel>{label}</ControlLabel>
                </Col>
                <Col sm={8}>
                    <Select
                        value={value}
                        name={name}
                        options={sortedOptions}
                        onChange={(selectedOption: Option) => onChange(selectedOption?.value ?? '')}
                        {...props}
                    />
                </Col>
            </FormGroup>
        </div>
    );
}
