import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Hr } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import CloseIconButton from '@/app.components/elements/Buttons/CloseIconButton';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import PropertySelect from '@/shared/properties/PropertySelect';
import useProperty from '@/app.hooks/useProperty';
import { CreateOfferInput } from '@/graphql/generated/types';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import PropertyCard from '@/shared/properties/PropertyCard';

type Props = {
    mode: 'ADD' | 'EDIT';
    setPropertyOfficeId: Function;
    setPropertyNegotiation: Function;
};

const OfferModalProperty: React.FC<Props> = props => {
    const { mode, setPropertyOfficeId, setPropertyNegotiation } = props;

    const disabledShared = useFeatureFlag('disable-shared-properties-offers');

    const { values, initialValues, setFieldValue } = useFormikContext<CreateOfferInput>();

    const { property } = useProperty(values.propertyId);

    const isCounterOffer = !!initialValues.parentId;

    const vendors = property?.owner_ids || [];

    useEffect(() => {
        if (mode === 'ADD' && vendors?.length > 0 && !isCounterOffer) {
            setFieldValue(
                'owners',
                vendors?.map(el => ({ id: el })),
            );
        }
        // eslint-disable-next-line
    }, [mode, vendors, isCounterOffer]);

    useEffect(() => {
        if (property) {
            setPropertyNegotiation(property.negotiation);
        }

        if (mode === 'ADD' && property && !isCounterOffer) {
            const feesObject = property?.attributes?.settings?.agency_commission || {};

            setFieldValue('financialDetails.buyerFixedFee', feesObject?.buyer?.fixed_fee || 0);
            setFieldValue('financialDetails.buyerPercentageFee', feesObject?.buyer?.percentage || 0);
            setFieldValue('financialDetails.ownerFixedFee', feesObject?.seller?.fixed_fee || 0);
            setFieldValue('financialDetails.ownerPercentageFee', feesObject?.seller?.percentage || 0);
        }
        // eslint-disable-next-line
    }, [mode, property, isCounterOffer]);

    useEffect(() => {
        setPropertyOfficeId(property?.office_id || undefined);
        // eslint-disable-next-line
    }, [property]);

    const canSelectProperty = !values.propertyId;
    const canRemoveProperty = mode === 'ADD';

    return (
        <div className="offer-modal-property">
            <FormPanel title={<FormattedMessage id="offers.modals.property.title" defaultMessage="Property" />}>
                <ErrorBoundary>
                    {canSelectProperty && (
                        <PropertySelect
                            value={values.propertyId}
                            disabledShared={disabledShared}
                            onSelect={propertyId => {
                                setFieldValue('propertyId', propertyId);
                            }}
                        />
                    )}
                </ErrorBoundary>
                {values.propertyId ? (
                    <PropertyCard
                        // @ts-ignore
                        property={{ id: values.propertyId }}
                        actions={
                            canRemoveProperty
                                ? [
                                      <CloseIconButton
                                          key="remove"
                                          onClick={() => {
                                              if (mode === 'ADD') {
                                                  setFieldValue('buyers', initialValues.buyers);
                                                  setFieldValue('owners', initialValues.owners);
                                              }

                                              setFieldValue('propertyId', '');

                                              setFieldValue('financialDetails.buyerFixedFee', 0);
                                              setFieldValue('financialDetails.buyerPercentageFee', 0);
                                              setFieldValue('financialDetails.ownerFixedFee', 0);
                                              setFieldValue('financialDetails.ownerPercentageFee', 0);
                                          }}
                                      />,
                                  ]
                                : []
                        }
                    />
                ) : null}
            </FormPanel>

            <Hr />
        </div>
    );
};

export default OfferModalProperty;
