import { useQuery } from '@apollo/react-hooks';
import { GetUserQuery, GetUserQueryVariables } from '@/graphql/generated/types';
import { GET_USER } from '@/graphql/queries/users/getUser';

export function useNegotiator(negotiatorId: string) {
    const { data, loading } = useQuery<GetUserQuery, GetUserQueryVariables>(GET_USER, {
        variables: {
            id: negotiatorId,
        },
        skip: !negotiatorId,
    });
    const negotiator = data?.user;

    return { negotiator, loading };
}
