import React from 'react';
import useOffice from '@/app.hooks/useOffice';

type Props = {
    id: string;
};

const TeamsName: React.FC<Props> = props => {
    const { id } = props;

    const office = useOffice(id);

    return <span>{office.get('name')}</span>;
};

export default TeamsName;
