import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import Button from '@sweepbright/uikit/build/esm/button';
import { CalendarList } from '@/app.domains/profile/Integrations/Calendar/elements/CalendarsList';
import { FormattedMessage } from 'react-intl-sweepbright';
import helpLinks from '@/app.data/helpLinks';
import { CalendarIntegrationsFormValues } from '../CalendarIntegrationsFormValues';

export const CalendarIntegrationConnected: FC<{ userId: string }> = ({ userId }) => {
    const { values, handleChange, errors, setFieldValue } = useFormikContext<CalendarIntegrationsFormValues>();
    const profileName = values.account?.profile.name;

    return (
        <>
            <p>
                <FormattedMessage id="connection.connected_as" defaultMessage="Connected as:" />{' '}
                <small className="text-uppercase text-info">{profileName}</small>
            </p>

            <CalendarList
                userId={userId}
                value={values.calendarId}
                onChange={handleChange('calendarId')}
                error={errors.calendarId}
                accessToken={values.hasCalendarIntegration ? undefined : values.account?.accessToken}
            />

            <Button
                variant="ghost-danger"
                onClick={() => {
                    setFieldValue('connected', false);
                }}
                block
            >
                <FormattedMessage id="integrations.calendar.disconnect_calendar" defaultMessage="Disconnect Calendar" />
            </Button>
            <p className="help-block">
                <FormattedMessage
                    id="integrations.calendar.disconnect_calendar_help_block"
                    defaultMessage="When you disconnect, you will not be able to Schedule a Visit from Sweepbright anymore. {link}."
                    values={{
                        link: (
                            <a href={helpLinks.calendarIntegration} target="__blank" rel="noreferrer noopener nofollow">
                                <FormattedMessage
                                    id="forms.help.help_link"
                                    defaultMessage="Learn more in Help & Learning"
                                />
                            </a>
                        ),
                    }}
                />
            </p>
        </>
    );
};
