import React from 'react';
import { Map } from 'immutable';
import { LinkContainer } from 'react-router-bootstrap';
import NavbarDropdown from '@/app.components/navigations/NavbarDropdown/NavbarDropdown';
import Icon from '@/app.components/icons/Icon';
import { FormattedMessage } from '@/app.utils/services/Helpers/reactIntlWrapper';
import { MAIN_LINKS, NavLink } from '@/app.components/navigations/links';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';

const MainNavigationMenu = ({ user, activeLink }: { activeLink: NavLink; user: Map<string, any> }) => {
    const activeLinkText = getTextForLink(activeLink);

    return (
        <NavbarDropdown title={activeLinkText}>
            {MAIN_LINKS.filter((link: NavLink) => link.isAllowedFor!(user)).map((link: NavLink, index) => {
                if (link.divider) {
                    return <Dropdown.Divider key={`divider_${index}`} />;
                }

                const active = activeLink && activeLink.key === link.key;

                return (
                    <LinkContainer active={false} key={link.key} to={link.to!(user)}>
                        <Dropdown.MenuItem>
                            {getTextForLink(link)}
                            {active && <Icon icon="check" className="pull-right" />}
                        </Dropdown.MenuItem>
                    </LinkContainer>
                );
            })}
        </NavbarDropdown>
    );

    function getTextForLink(link: NavLink) {
        if (!link || link.home) {
            return <FormattedMessage id="navigation.links.home" defaultMessage="SweepBright" />;
        }

        return <FormattedMessage id={`navigation.links.${link.key}`} />;
    }
};

export default React.memo(MainNavigationMenu);
