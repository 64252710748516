// @ts-nocheck
import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Button } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import { ProjectUnitsList } from '@/app.domains/properties/UnitsList';
import PagePane from '@/app.layouts/PagePane/PagePane';
import Icon from '@/app.components/icons/Icon';
import { PROPERTY_UNIT, PROPERTY_UNIT_DETAILS } from '@/app.routing/routes';
import { useToasts } from '@sweepbright/notifications';
import useProperty, { useUpdateProperty } from '@/app.hooks/useProperty';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';

const ADD_UNIT_MUTATION = gql`
    mutation AddUnitToProject($input: AddUnitToProjectInput!) {
        addUnitToProject(input: $input) {
            project {
                id
                isProject
                units {
                    nodes {
                        id
                        projectId
                        isUnit
                    }
                }
            }
            unit {
                id
                type
                negotiation
            }
        }
    }
`;

function PropertyUnitsPage({ router, params }) {
    const projectId = params.property;

    const { property: project, loading } = useProperty(projectId);
    const [addUnit, { loading: addingUnit }] = useMutation(ADD_UNIT_MUTATION);
    const { addSuccess, addError } = useToasts();
    const handleUpdateUnit = useUpdateProperty();

    const handleAddUnit = async () => {
        try {
            const result = await addUnit({
                variables: {
                    input: {
                        projectId: projectId,
                    },
                },
            });

            const unitId = result.data.addUnitToProject.unit?.id;
            router.push(PROPERTY_UNIT_DETAILS(projectId, unitId));

            addSuccess({ message: <FormattedMessage id="unit.state.added" defaultMessage="Unit added" /> });
        } catch (err) {
            // handle error here?
            // we should show an alert, but we dont have any yet.
            addError({ message: <FormattedMessage id="unit.state.couldnt_add" defaultMessage="Could not add unit" /> });
        } finally {
        }
    };

    return (
        <PagePane
            size="full"
            title={<FormattedMessage id="titles.property.units" defaultMessage="Units" />}
            actions={[
                <Button
                    key="action"
                    variant="primary"
                    onClick={handleAddUnit}
                    disabled={addingUnit}
                    data-testid="add-unit-button"
                    icon={<Icon icon="add-office" />}
                >
                    {addingUnit ? (
                        <FormattedMessage id="general.state.adding" defaultMessage="Adding..." />
                    ) : (
                        <FormattedMessage id="property.units.add" defaultMessage="Add Unit" />
                    )}
                </Button>,
            ]}
        >
            <div className="flex flex-col min-w-0">
                <div className="flex-1">
                    {!loading && (
                        <ErrorBoundary>
                            <ProjectUnitsList
                                project={project!}
                                route={PROPERTY_UNIT}
                                onUpdateUnit={handleUpdateUnit}
                            />
                        </ErrorBoundary>
                    )}
                </div>
            </div>
        </PagePane>
    );
}

export default PropertyUnitsPage;
