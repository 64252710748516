import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Authorize from '../app.domains/auth/authorize/Authorize';
import { authorize } from '../app.redux/actions/AuthActions';
import { getCurrentMessages } from '../app.redux/selectors';

const mapActionsToProps = { authorize };
const mapStateToProps = createStructuredSelector({
    messages: getCurrentMessages,
});

export default connect(mapStateToProps, mapActionsToProps)(Authorize);
