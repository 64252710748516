import React from 'react';
import Link from '@/app.components/elements/Link';

import IconArrow from '../../../../../../../resources/assets/svg/icons/arrow-left-icon.svg';

type Props = {
    src?: string;
    alt?: string;
    link?: string;
    action?: JSX.Element;
    linkText?: string | JSX.Element;
    footer?: JSX.Element | JSX.Element[];
};

const WrapperAsideProfileImage: React.FC<Props> = props => {
    const { src, alt, link, action, linkText, footer } = props;

    return (
        <div className="c-wrapper-aside-profile-image">
            <div className="c-wrapper-aside-profile-image-header">
                {!!link && (
                    <Link to={link} className="c-wrapper-aside-profile-image-header__link">
                        <IconArrow /> {linkText}
                    </Link>
                )}

                {action}
            </div>

            {!!footer && <div className="c-wrapper-aside-profile-image-footer">{footer}</div>}

            <div
                className="c-wrapper-aside-profile-image__background"
                {...(src ? { style: { backgroundImage: `url(${src})` } } : {})}
            >
                {!src && alt}
            </div>
        </div>
    );
};

export default WrapperAsideProfileImage;
