import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';

const labels = {
    delete_title: {
        template: <FormattedMessage id="location.templates.deletion.title" defaultMessage="Delete location template" />,
        preference: (
            <FormattedMessage id="location.preferences.deletion.title" defaultMessage="Delete location preference" />
        ),
    },
    delete_body: {
        template: (
            <FormattedMessage
                id="location.templates.deletion.body"
                defaultMessage="You are about to delete this location template"
            />
        ),
        preference: (
            <FormattedMessage
                id="location.preferences.deletion.body"
                defaultMessage="You are about to delete this location preference"
            />
        ),
    },
    delete_btn: {
        template: <FormattedMessage id="location.templates.actions.delete" defaultMessage="Delete Template" />,
        preference: <FormattedMessage id="preferences.actions.delete_preference" defaultMessage="Delete Preference" />,
    },
};

const DeleteLocationModal = props => {
    const { show, onClose, handleSubmit, submitting, type = 'preference' } = props;

    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{labels.delete_title[type]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{labels.delete_body[type]}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} disabled={submitting}>
                    <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={handleSubmit} disabled={submitting}>
                    {labels.delete_btn[type]}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

DeleteLocationModal.propTypes = {
    handleSubmit: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
    form: 'delete_location_form',
    // we dont need fields, just the submitting form state :)
    fields: [],
})(DeleteLocationModal);
