import React from 'react';
import useLegalEntity from '@/new.domains/teams/hooks/useLegalEntity';
import ResourceFilter from '@/shared/resource/ResourceFilter';
import { ResourceDomainType } from '@/shared/resource/types';
import FilterLegalEntitySelect from './FilterLegalEntitySelect';

type Props = {
    searchSettings: any;
    setSearchSettings: any;
    domainType: ResourceDomainType;
    translationDomainType: ResourceDomainType;
};

const FilterLegalEntity: React.FC<Props> = props => {
    const { searchSettings, setSearchSettings, domainType, translationDomainType } = props;

    const { data } = useLegalEntity(searchSettings?.legalEntity && searchSettings?.legalEntity[0]);

    const legalEntityName = data?.office?.legalEntity?.data?.name;

    return (
        <ResourceFilter
            id="legal_entity"
            domainType={domainType}
            params={searchSettings}
            setParams={setSearchSettings}
            translationDomainType={translationDomainType}
            customActiveLabel={() => {
                if (legalEntityName) {
                    const legalEntities = searchSettings?.legalEntity || [];

                    if (legalEntities.length > 1) {
                        return `${legalEntityName} (+${legalEntities.length - 1})`;
                    }

                    return legalEntityName || '';
                }

                return null;
            }}
            filters={[
                {
                    type: 'custom',
                    paramName: 'legalEntity',
                    children: (params, setParams) => (
                        <FilterLegalEntitySelect
                            value={params?.legalEntity}
                            firstEntity={data?.office?.legalEntity?.data}
                            onChange={newValue => {
                                setParams(prevState => {
                                    return { ...prevState, legalEntity: newValue };
                                });
                            }}
                        />
                    ),
                },
            ]}
        />
    );
};

export default FilterLegalEntity;
