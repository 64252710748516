export const toStr = (val: string | number | undefined | boolean | null | any) => {
    if (val === undefined || val === null) {
        return undefined;
    }

    if (typeof val === 'string') {
        if (val === 'null') {
            return undefined;
        }

        return val;
    }

    if (Array.isArray(val)) {
        if (val.length === 0) {
            return undefined;
        }

        return val;
    }

    return `${val}`;
};
