import get from 'lodash/get';
import React, { FC, useMemo } from 'react';
import { EstateRepository } from '@/app.utils/services';
import { rulesMatcher } from '@/app.utils/services/Fields/rulesMatcher';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { FormPanel, Input } from '../../../app.components/index';
import { getFieldLabel } from './helpers';

interface FeaturesCheckboxesSectionProps {
    property: EstateRepository;
    pathPrefix: string;
    fields: any;
    title: JSX.Element;
}

const FeaturesCheckboxesSection: FC<FeaturesCheckboxesSectionProps> = ({ property, pathPrefix, fields, title }) => {
    const showNewFields = useFeatureFlag('property.feature.fields.june2024');

    const visibleFields = useMemo(() => {
        const context = property.getVisibilityContext();

        const newFields = [
            'septic_tank',
            'accessibility',
            'triple_glazing',
            'ceiling_heating',
            'wood_stove_standalone',
            'wood_stove_insert',
            'pellets_standalone',
            'pellets_insert',
            'geothermal',
            'central_heating_building',
            'district_heating',
            'insert_pellets_stove',
            'building_central_heating',
            'individual_central_heating',
            'room_specific_heating',
        ];

        const schemas = rulesMatcher.getSchemasByPathPrefix(context, `attributes.${pathPrefix}`);
        const booleanFieldsPaths = Object.keys(schemas)
            .filter(path => schemas[path].type === 'boolean')
            .map(path => path.replace(/^attributes\./, ''))
            .filter(path => {
                const fieldName = path.slice(`${pathPrefix}.`.length);

                return !showNewFields ? !newFields.includes(fieldName) : true;
            });

        return booleanFieldsPaths;
    }, [pathPrefix, property, showNewFields]);

    if (!visibleFields.length) {
        return null;
    }

    const prefixLength = `${pathPrefix}.`.length;
    const baseName = fieldPath => fieldPath.slice(prefixLength);

    return (
        <FormPanel title={title}>
            {visibleFields.map(fieldPath => {
                const reduxFormField = get(fields, fieldPath);

                return (
                    <Input
                        key={fieldPath}
                        type="checkbox"
                        {...reduxFormField}
                        label={getFieldLabel(baseName(fieldPath))}
                    />
                );
            })}
        </FormPanel>
    );
};

export default FeaturesCheckboxesSection;
