import { put } from 'redux-saga/effects';
import { CompaniesRequests } from '../../../requests/index';
import { fetchCompanyTokens } from '../../actions';
import apiCall from '../Effects/apiCall';

export default function* onDeleteCompanyToken({ companyId, service }) {
    try {
        yield apiCall(new CompaniesRequests().removeToken, companyId, service);
        yield put(fetchCompanyTokens(companyId));
    } catch (response) {
        //
    }
}
