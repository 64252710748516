import { put } from 'redux-saga/effects';
import { CompaniesRequests } from '../../../requests/index';
import { setCompanyCard } from '../../actions';
import apiCall from '../Effects/apiCall';

export default function* onFetchCompanyCard({ companyId }) {
    try {
        const card = yield apiCall(new CompaniesRequests().activeCard, companyId);
        yield put(setCompanyCard(companyId, card));
    } catch (response) {
        //
    }
}
