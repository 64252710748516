// computes the original dimensions of an image
// when this is uploaded, this function needs the DOM
// to work properly and can not run in node
export function getOriginalSize(imgSrc) {
    const imgElement = document.createElement('img');
    imgElement.src = imgSrc;

    return new Promise(resolve => {
        imgElement.addEventListener('load', function onLoad() {
            resolve({ width: this.naturalWidth, height: this.naturalHeight });
        });
    });
}
