import PropTypes from 'prop-types';
import React from 'react';
import { css } from '@emotion/core';
import classNames from 'classnames';
import Link from '@/app.components/elements/Link';
import { useTheme } from '@/app.utils/theming/ThemeContext';
import Icon from '../../icons/Icon';

const IconButton = React.forwardRef(function IconButton(
    { title, icon, iconStyle, size, external = false, to, className, testId, ...props },
    ref,
) {
    const Wrapper = external ? 'a' : Link;
    const theme = useTheme();

    return (
        <Wrapper
            ref={ref}
            className={classNames('btn btn-link btn-icon', className)}
            css={css`
                color: ${theme.colors.primary};
            `}
            {...props}
            href={external && to}
            to={!external ? to : undefined}
            data-testid={testId}
        >
            <Icon icon={icon} size={sizeValue(size)} type={iconStyle} />
            {title && <span className="sr-only">{title}</span>}
        </Wrapper>
    );
});

IconButton.SIZE_SMALL = Symbol('SIZE_SMALL');
IconButton.SIZE_MEDIUM = Symbol('SIZE_MEDIUM');
IconButton.SIZE_LARGE = Symbol('SIZE_LARGE');

IconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    iconStyle: PropTypes.string,
    to: PropTypes.string,
    title: PropTypes.object,
    size: PropTypes.oneOf([IconButton.SIZE_SMALL, IconButton.SIZE_MEDIUM, IconButton.SIZE_LARGE]),
    external: PropTypes.bool,
    className: PropTypes.string,
};

function sizeValue(size) {
    if (size === IconButton.SIZE_SMALL) {
        return 16;
    } else if (size === IconButton.SIZE_MEDIUM) {
        return 24;
    } else if (size === IconButton.SIZE_LARGE) {
        return 32;
    }

    return size;
}

IconButton.defaultProps = {
    size: IconButton.SIZE_MEDIUM,
};

export default IconButton;
