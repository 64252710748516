import accounting from 'accounting';
import { getCurrencySettings } from '@/app.config/accounting';
import currencies from '@/app.data/Currencies';

export type MoneyFormatOptions = {
    symbol?: string; // default currency symbol is '$'
    format?: string; // controls output: %s = symbol, %v = value/number (can be object: see below)
    decimal?: string; // decimal point separator
    thousand?: string; // thousands separator
    precision?: number; // decimal places
};

export function formatPrice(price: { amount: number; currency: string }, options?: MoneyFormatOptions) {
    const defaultOptions = getCurrencySettings();
    const formattedAmount = accounting.formatMoney(price.amount, {
        ...defaultOptions,
        ...options,
        symbol: currencies[price.currency].symbol,
    });

    return formattedAmount;
}
