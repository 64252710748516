// @ts-nocheck
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function defaultOptionRenderer<Option>(option: Option, _: { isOpen: any }) {
    return option.label;
}

export const defaultInputRenderer = React.forwardRef(function Input(props, ref) {
    return <input type="text" ref={ref} autoComplete="off" {...props} />;
});

export function defaultFilterOption(option: any, inputValue: string, { ignoreCase }) {
    if (!inputValue || inputValue.length === 0) {
        return true;
    }
    if (!option && !option.label) {
        return false;
    }
    let label = String(option.label);
    if (ignoreCase) {
        label = label.toLowerCase();
    }

    return label.includes(inputValue);
}

export function defaultFilterOptions(
    options: any[],
    inputValue: string = '',
    { filterOption = defaultFilterOption, ignoreCase = true } = {},
) {
    inputValue = inputValue.trim();
    if (ignoreCase) {
        inputValue = inputValue.toLowerCase();
    }

    return options.filter(option =>
        filterOption(option, inputValue, {
            ignoreCase,
        }),
    );
}

export function noop() {
    // do  nothing
}
