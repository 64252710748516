import { put, call } from 'redux-saga/effects';
import { BucketsRequest } from '../../../requests/index';
import { resetEstateRequests, fetchEstateRequests } from '../../actions';
import formatApiErrors from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';

export default function* onResendRequest({ estateId, requestId, attributes: { resolve, reject, ...attributes } }) {
    try {
        yield apiCall(new BucketsRequest().resend, requestId, attributes);

        yield put(resetEstateRequests(estateId));
        yield put(
            fetchEstateRequests(estateId, {
                page: 1,
                file_type: attributes.file_type,
            }),
        );

        yield call(resolve);
    } catch (response) {
        yield call(reject, formatApiErrors(response, '_error'));
    }
}
