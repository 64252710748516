import { IconName } from '@/app.components/icons/Icon';
import getLocationHash from '../../app.utils/services/Helpers/getLocationHash';

export const ADD_MESSAGE = 'status/ADD_MESSAGE';
export const SET_MESSAGE = 'status/SET_MESSAGE';
export const RESET_MESSAGES = 'status/RESET_MESSAGES';
export const IS_SAVING = 'status/IS_SAVING';
export const RECOVER_FROM_ERRORS = 'status/RECOVER_FROM_ERRORS';
export const SET_ERRORS = 'status/SET_ERRORS';

export type MessageDescriptor = {
    title: React.ReactNode;
    // can be a string or a FormattedMessage
    // there is no way to express this in TS properly at the moment :(
    message: React.ReactNode;
    icon?: IconName;
    style?: string;
    // the values for the FormattedMessage translation
    values?: object;
};

export function addMessage(message: MessageDescriptor, style = 'success') {
    return { type: ADD_MESSAGE, message, style, hash: getLocationHash() };
}

export function setMessage(message: MessageDescriptor, style = 'success') {
    return { type: SET_MESSAGE, message, style, hash: getLocationHash() };
}

export function isSaving(saving = true) {
    return { type: IS_SAVING, saving };
}

export function setErrors(errors) {
    return { type: SET_ERRORS, errors };
}

export function recoverFromErrors() {
    return { type: RECOVER_FROM_ERRORS };
}

export function resetMessages() {
    return { type: RESET_MESSAGES };
}
