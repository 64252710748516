export const SET_SEARCH = 'search/SET_SEARCH';
export const RESET_SEARCH = 'search/RESET_SEARCH';
export const SET_SEARCH_RESULTS = 'search/SET_RESULTS';
export const RESET_SEARCH_RESULTS = 'search/RESET_RESULTS';

export const SEARCH_PROPERTY_BUYERS = 'search/PROPERTY_BUYERS';

export function setSearch(search, parameters = {}) {
    return { type: SET_SEARCH, search, parameters };
}

export function resetSearch(search) {
    return { type: RESET_SEARCH, search };
}

export function searchPropertyBuyers(propertyId, attributes) {
    return { type: SEARCH_PROPERTY_BUYERS, propertyId, attributes };
}

export function setSearchResults(identifier, results) {
    return { type: SET_SEARCH_RESULTS, identifier, results };
}

export function resetSearchResults(identifier) {
    return { type: RESET_SEARCH_RESULTS, identifier };
}
