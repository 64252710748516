/* eslint-disable no-unused-vars */
import React from 'react';
import ButtonIconBase from '@sweepbright/uikit/build/esm/button_icon';
import Icon, { IconName } from '../../icons/Icon';

export default React.forwardRef(function ButtonIcon<TagProps = any>(
    {
        onClick,
        testId,
        ...props
    }: {
        type: IconName;
        variant: string;
        onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
        disabled?: boolean;
        tag?: React.ReactElement<any>;
        testId?: string;
        className?: string;
        iconSize?: string | number;
    } & TagProps,
    ref,
) {
    // TODO: move this logic to the UIKit component
    const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        if (!props.disabled) {
            // eslint-disable-next-line no-unused-expressions
            onClick?.(evt);
        } else {
            evt.stopPropagation();
        }
    };

    return (
        <ButtonIconBase data-testid={testId} tag="a" iconComponent={Icon} {...props} onClick={handleClick} ref={ref} />
    );
});
