import gql from 'graphql-tag';

export const updatePropertyStatus = gql`
    mutation updateEstateStatus($id: ID!, $status: String!) {
        updateEstateStatus(estateId: $id, status: $status) {
            id
            status
            attributes
        }
    }
`;
