export const AUTHORIZE = 'auth/AUTHORIZE';
export const VALIDATE_CODE = 'auth/VALIDATE_CODE';
export const IS_LOGGING_IN = 'auth/IS_LOGGING_IN';
export const LOGGED_IN = 'auth/LOGGED_IN';
export const LOGIN = 'auth/LOGIN';
export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_ATTEMPT = 'auth/LOGOUT_ATTEMPT';
export const REGISTER = 'auth/REGISTER';
export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN';
export const SET_CODE = 'auth/SET_CODE';

export function authorize(token, expiresIn = 604800) {
    return { type: AUTHORIZE, token, expiresIn } as const;
}

export function validateCode({
    code,
    resolve,
    reject,
}: {
    code: string;
    resolve?: () => void;
    reject?: (err: Error) => void;
}) {
    return { type: VALIDATE_CODE, code, resolve, reject } as const;
}

export function login({
    email,
    password,
    resolve,
    reject,
}: {
    email: string;
    password: string;
    resolve?: () => void;
    reject?: (err: Record<string, any>) => void;
}) {
    return { type: LOGIN, email, password, resolve, reject } as const;
}

export function register(attributes: object) {
    return { type: REGISTER, attributes } as const;
}

export function isLoggingIn(loggingIn: boolean = true) {
    return { type: IS_LOGGING_IN, loggingIn } as const;
}

export function loggedIn(user: object, expiresIn: Date | number) {
    return { type: LOGGED_IN, user, expiresIn } as const;
}

export function logout() {
    return { type: LOGOUT } as const;
}

export function logoutAttempt() {
    return { type: LOGOUT_ATTEMPT };
}

export function refreshToken() {
    return { type: REFRESH_TOKEN } as const;
}

export function setCode(code: string) {
    return { type: SET_CODE, code } as const;
}

export type AuthAction = ReturnType<
    | typeof authorize
    | typeof validateCode
    | typeof login
    | typeof register
    | typeof isLoggingIn
    | typeof loggedIn
    | typeof logout
    | typeof refreshToken
    | typeof setCode
>;
