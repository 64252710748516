import React from 'react';
import uniq from 'lodash/uniq';
import { useQuery } from '@apollo/react-hooks';
import { STATUSES_QUERY } from '@/graphql/queries/properties/getStatuses';
import useFeatureFlag from './useFeatureFlag';

export default function useNegotiationStatuses(skip?: boolean) {
    const showEstimate = useFeatureFlag('property.status.estimate');

    const { data, loading } = useQuery(STATUSES_QUERY, { skip });

    const statusesPerNegotiation = React.useMemo(() => {
        if (!loading && data) {
            const letStatuses = data.letStatuses.map(s => s.id).filter(el => (el === 'estimate' ? showEstimate : true));
            const saleStatuses = data.saleStatuses
                .map(s => s.id)
                .filter(el => (el === 'estimate' ? showEstimate : true));

            return {
                let: letStatuses,
                sale: saleStatuses,
                all: uniq([...saleStatuses, ...letStatuses]),
            };
        }

        return {
            let: [],
            sale: [],
            all: [],
        };
    }, [data, loading, showEstimate]);

    return statusesPerNegotiation;
}
