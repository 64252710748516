import React from 'react';
import { CompaniesRequests } from '@/requests';
import { FormattedMessage } from 'react-intl-sweepbright';
import { FormAction } from '@/app.components';
import useCompany from '@/app.hooks/useCompany';

export default function MoveBotlerConnected({ onDisconnected, companyId, config }) {
    const [disconnecting, setDisconnecting] = React.useState(false);
    const company = useCompany();

    function handleDisconnect() {
        setDisconnecting(true);
        new CompaniesRequests()
            .disconnectMoveBotler(companyId)
            .then(() => {
                onDisconnected();
            })
            .finally(() => {
                setDisconnecting(false);
            });
    }

    const date = new Date(config.last_updated_at);
    const formatedDateLabel = `${date.getDate()}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;

    return (
        <div>
            <p>
                <FormattedMessage id="connection.connected_as" defaultMessage="Connected as:" />{' '}
                <small className="text-uppercase text-info">{company.get('name')}</small>
            </p>

            <p>
                <FormattedMessage id="connection.status" defaultMessage="Status:" />{' '}
                <small className="uppercase text-info">
                    <span style={{ color: '#32AB91' }}>
                        <FormattedMessage id="integrations.status.connected" defaultMessage="Active" />
                    </span>{' '}
                    <FormattedMessage
                        id="integrations.since_date.label"
                        defaultMessage="(since {date})"
                        values={{
                            date: formatedDateLabel,
                        }}
                    />
                </small>
            </p>

            <hr />
            <FormAction
                onClick={handleDisconnect}
                variant="danger"
                label={
                    disconnecting ? (
                        <FormattedMessage
                            id="integrations.movebotler.state.deactivation"
                            defaultMessage="Deactivating MoveBotler..."
                        />
                    ) : (
                        <FormattedMessage
                            id="integrations.movebotler.action.deactivate"
                            defaultMessage="Deactivate MoveBotler"
                        />
                    )
                }
                confirmation={
                    <FormattedMessage
                        id="integrations.movebotler.deactivation_disclaimer"
                        defaultMessage="I understand that by deactivating the MoveBotler, all ongoing mail flows will be interrupted."
                    />
                }
            />
        </div>
    );
}
