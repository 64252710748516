import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import { ContactRepository } from '../../../app.utils/services';
import formatAddress from '../../../app.utils/services/Helpers/formatAddress';

const ContactRow = ({ contact }) => {
    const contactRepository = new ContactRepository(contact);
    let pieces = [
        contactRepository.getFullName(),
        formatAddress(contact.get('address')),
        contact.get('phone'),
        contact.get('email'),
    ];

    // Filter out empty pieces
    pieces = pieces.map(piece => String(piece === null ? '' : piece).trim()).filter(piece => piece && piece !== ',');

    return (
        <div key={contact.get('id')} className="c-spacer-bottom">
            {pieces.map((piece, key) => (
                <span key={key}>
                    {piece}
                    <br />
                </span>
            ))}
        </div>
    );
};

ContactRow.propTypes = {
    contact: PropTypes.instanceOf(Map),
};

export default ContactRow;
