import { put } from 'redux-saga/effects';
import { setSearchResults } from '../../actions';
import { PropertiesRequests } from '../../../requests/index';
import withPagination from '../Helpers/withPagination';
import apiCall from '../Effects/apiCall';

export default function* onSearchPropertyBuyers({ propertyId, attributes }) {
    yield withPagination('search/property/buyers', attributes, function* onFetch(settings) {
        const { page, ...params } = settings;

        const shouldSearchInLeads = Object.keys(params).filter(option => !['filter_identifier'].includes(option))
            .length;

        const request = shouldSearchInLeads ? new PropertiesRequests().leads : new PropertiesRequests().visitors;
        const results = yield apiCall(request, propertyId, {
            ...settings,
            page,
        });

        yield put(setSearchResults('search/property/buyers', results.data));

        return results;
    });
}
