import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { WithRouterProps } from 'react-router';
import { FormattedMessage } from 'react-intl-sweepbright';
import { HasModalProps } from '@/app.utils/Decorators/withModals';
import Modal from '@/app.components/elements/Modal';
import useUser from '@/app.hooks/useUser';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import EmptyAlert from '@/app.components/empty/EmptyAlert';
import { AGENCY_WEBSITE_PATH } from '@/app.utils/constants';

import PaletteItem from './PaletteItem';
import PaletteList from './PaletteList';
import PaletteSearch from './PaletteSearch';
import PaletteModals from './PaletteModals';

type Props = {
    fetchCompanyOffices: (companyId: string) => void;
    handleAddContact: () => Promise<any>;
    handleAddOffice: () => Promise<any>;
    loggedIn: boolean;
    user: Map<string, any>;
    agencyWebsiteVisitButton: boolean;
};

const Palette: React.FunctionComponent<Props & HasModalProps & WithRouterProps> = props => {
    const [showPalette, setShowPalette] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean | string>(false);

    const [query, setQuery] = useState<string>('');
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const [modalHasEntered, setModalHasEntered] = useState<boolean>(false);

    const intl = useIntl();
    const user = useUser();

    const canVisit = useSelector(getFeature('properties.visitScheduler.enabled'));

    const agencyWebsiteUrl = user.getIn(AGENCY_WEBSITE_PATH);

    const data = [
        {
            id: 'palette-property',
            type: 'modal',
            title: intl.formatMessage({ id: 'navigation.add-property' }),
        },
        {
            id: 'palette-contact',
            type: 'modal',
            title: intl.formatMessage({ id: 'navigation.add-contact' }),
        },
        ...(canVisit
            ? [
                  {
                      id: 'palette-visit',
                      type: 'modal',
                      title: intl.formatMessage({ id: 'navigation.add-visit' }),
                  },
              ]
            : []),
        ...(agencyWebsiteUrl
            ? [
                  {
                      id: 'palette-agency-website',
                      type: 'link',
                      link: agencyWebsiteUrl,
                      title: intl.formatMessage({ id: 'navigation.visit-agency-site' }),
                  },
              ]
            : []),
    ];

    const filteredData = useMemo(() => {
        return data.filter(el => {
            return el.title.toLowerCase().includes(query.toLowerCase());
        });
    }, [data, query]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const isAnyModalOpen = !!document.querySelector('[data-reach-dialog-overlay=""]');
            const isPaletteModalOpen = !!document.querySelector('[data-testid="palette-modal"]');

            if ((e.metaKey && e.key === 'k') || (e.ctrlKey && e.key === 'k')) {
                e.preventDefault();

                if (isAnyModalOpen && !isPaletteModalOpen) {
                    setShowPalette(false);
                } else if (isAnyModalOpen && isPaletteModalOpen) {
                    setShowPalette(false);
                } else if (!isAnyModalOpen) {
                    setShowPalette(!showPalette);
                }
            }

            if (showPalette) {
                if (e.key === 'ArrowDown' || (!e.shiftKey && e.key === 'Tab')) {
                    e.preventDefault();

                    setActiveIndex(prev => (prev === filteredData.length - 1 ? 0 : prev + 1));
                }

                if (e.key === 'ArrowUp' || (e.shiftKey && e.key === 'Tab')) {
                    e.preventDefault();

                    setActiveIndex(prev => (prev === 0 ? filteredData.length - 1 : prev - 1));
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [visible, showPalette, filteredData]);

    useEffect(() => {
        if (query) {
            setActiveIndex(0);
        }
    }, [query]);

    const onClose = () => {
        setVisible(false);
        setShowPalette(false);
    };

    if (!query && filteredData.length === 0) {
        return null;
    }

    return (
        <>
            <PaletteModals {...({} as any)} {...props} visible={visible} setVisible={setVisible} />

            <Modal
                width={530}
                onHide={() => {
                    onClose();
                }}
                show={showPalette}
                testId="palette-modal"
                onEntered={() => {
                    setModalHasEntered(true);
                }}
                onExited={() => {
                    setModalHasEntered(false);
                }}
            >
                <div className="palette">
                    <div className="palette__wrapper">
                        <PaletteSearch focused={modalHasEntered} query={query} setQuery={setQuery} />

                        <PaletteList>
                            <>
                                {filteredData.length > 0 &&
                                    filteredData.map((el, index) => {
                                        return (
                                            <PaletteItem
                                                key={el.id}
                                                entity={el}
                                                showPalette={showPalette}
                                                active={activeIndex === index}
                                                onSelect={id => {
                                                    if (el.type === 'modal') {
                                                        props.open(el.id);
                                                        setVisible(id || true);
                                                    }

                                                    setShowPalette(false);
                                                }}
                                            />
                                        );
                                    })}

                                {filteredData.length === 0 && (
                                    <EmptyAlert
                                        icon="face-03"
                                        body={
                                            <FormattedMessage
                                                id="general.state.no_results"
                                                defaultMessage="No results"
                                            />
                                        }
                                    />
                                )}
                            </>
                        </PaletteList>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Palette;
