import React from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { GET_PROPERTY_QUERY } from '@/graphql/queries/properties/getProperty';
import { Estate, GetPropertyQuery, GetPropertyQueryVariables } from '@/graphql/generated/types';
import PropertyPatchBuilder, { PropertyData } from '@/app.utils/services/PatchBuilders/PropertyPatchBuilder';
import { UPDATE_ESTATE_MUTATION } from '@/graphql/mutations/properties/updateEstate';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { PropertyType } from '@/app.data/Properties';
import { formatPropertyAttributes } from '@/app.domains/properties/withPropertyDetails';
import { PropertyNegotiation } from '@/app.data/FieldVisibilityContext';

export function useUpdatePropertyWithOperations() {
    const client = useApolloClient();

    const updateProperty = React.useCallback(
        (estateId: string, operations: any[], estateType: string) => {
            return client.mutate({
                mutation: UPDATE_ESTATE_MUTATION,
                variables: {
                    estateId: estateId,
                    operations,
                    estateType: estateType,
                },
            });
        },
        [client],
    );

    return updateProperty;
}

export function useUpdatePropertyAttributes() {
    const client = useApolloClient();
    const updatePropertyWithOperations = useUpdatePropertyWithOperations();

    const updatePropertyAttributes = React.useCallback(
        (
            propertyId: string,
            value: {
                price: {
                    current_price: PropertyData['price']['current_price'];
                };
                settings: { agency_commission: PropertyData['settings']['agency_commission'] };
            },
        ) => {
            return client
                .query({
                    query: GET_PROPERTY_QUERY,
                    variables: {
                        id: propertyId,
                    },
                })
                .then(({ data }) => {
                    const property = data.estate;
                    const operations = [
                        { op: 'add', path: '/price/current_price', value: value.price.current_price },
                        { op: 'add', path: '/settings/agency_commission', value: value.settings.agency_commission },
                    ];

                    return updatePropertyWithOperations(propertyId, operations, property.internalType);
                });
        },
        [client, updatePropertyWithOperations],
    );

    return updatePropertyAttributes;
}

export function useUpdateProperty() {
    const client = useApolloClient();
    const updatePropertyWithOperations = useUpdatePropertyWithOperations();

    const updateProperty = React.useCallback(
        (propertyId: string, attributes: Partial<PropertyData>) => {
            return client
                .query({
                    query: GET_PROPERTY_QUERY,
                    variables: {
                        id: propertyId,
                    },
                })
                .then(({ data }) => {
                    const property = data.estate;
                    const operations = getUpdateOperations(
                        property,
                        formatPropertyAttributes(property.attributes, property.type),
                        formatPropertyAttributes(attributes, property.type),
                    );

                    return updatePropertyWithOperations(propertyId, operations, property.internalType);
                });
        },
        [client, updatePropertyWithOperations],
    );

    return updateProperty;
}

export default function useProperty(
    estateId?: Maybe<string>,
    { onCompleted }: { onCompleted?: (data: any) => void } = {},
) {
    const { data, error, loading } = useQuery<GetPropertyQuery, GetPropertyQueryVariables>(GET_PROPERTY_QUERY, {
        variables: {
            id: estateId!,
        },
        skip: !estateId,
        partialRefetch: true,
        returnPartialData: true,
        onCompleted(data) {
            // eslint-disable-next-line no-unused-expressions
            onCompleted?.(data.estate);
        },
        onError(err) {
            getBugsnagClient().notify(err);
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
    });

    const updateProperty = useUpdateProperty();

    return {
        property: data?.estate,
        error,
        loading,
        updateProperty: React.useCallback(
            (attributes: Partial<PropertyData>) => updateProperty(estateId!, attributes),
            [estateId, updateProperty],
        ),
    };
}

function getUpdateOperations(property: Estate, attributes: PropertyData, nextAttributes: Partial<PropertyData>) {
    const patchBuilderAttributes = {
        ...attributes,
        type: property.type as PropertyType,
        internalType: property.internalType,
        negotiation: property.negotiation as PropertyNegotiation,
    };

    const pathBuilderUpdatedAttributes = {
        ...nextAttributes,
        type: property.type as PropertyType,
    };

    const operations = new PropertyPatchBuilder(pathBuilderUpdatedAttributes, patchBuilderAttributes)
        .getOperations()
        .toJS();

    return operations;
}
