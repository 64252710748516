// @ts-nocheck
import React from 'react';
import RadioBase from '@sweepbright/uikit/build/esm/radio';

const RadioGroupContext = React.createContext();
export default function Radio({ value, onChange, disabled, checked, ...props }) {
    const ctx = React.useContext(RadioGroupContext);

    return (
        <RadioBase
            value={value}
            onChange={() => ctx?.onChange(value) ?? onChange?.(value)}
            disabled={ctx?.disabled ?? disabled}
            checked={ctx ? ctx.value === value : checked}
            {...props}
            name={ctx?.name}
        />
    );
}

Radio.Button = function RadioButton({
    children: label,
    value,
    disabled,
}: {
    disabled?: boolean;
    children: React.ReactNode;
    value: string;
}) {
    const ctx = React.useContext(RadioGroupContext);

    return (
        <label className="radio-inline">
            <input
                name={ctx.name}
                type="radio"
                value={value}
                onChange={() => ctx.onChange(value)}
                checked={ctx.value === value}
                disabled={ctx.disabled || disabled}
            />
            {label}
        </label>
    );
};

Radio.Group = function RadioGroup({ children, onChange, value, disabled, name }) {
    const ctx = React.useMemo(() => {
        return {
            onChange,
            value,
            disabled,
            name,
        };
    }, [onChange, value, disabled, name]);

    return (
        <RadioGroupContext.Provider value={ctx}>
            <div>{children}</div>
        </RadioGroupContext.Provider>
    );
};
