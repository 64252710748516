import { Map } from 'immutable';
import getLocationHash from '../../app.utils/services/Helpers/getLocationHash';
import createSelector from './createImmutableSelector';
import { getCurrentOffice } from './RoutingSelectors';

export const getStatus = state => state.status || new Map();

export const getIsSaving = createSelector(getStatus, status => status.get('is_saving'));

export const getMessages = createSelector(getStatus, status => status.get('messages'));

export const hasMessages = createSelector(getMessages, messages => !messages.isEmpty());

export const getCurrentMessages = createSelector([getMessages, getLocationHash], (messages, hash) => {
    return messages.filter(message => message.get('hash') === hash);
});

export const getPagination = resource =>
    createSelector(getStatus, status => status.getIn(['pagination', resource], new Map()));

export const getCurrentOfficePagination = resource =>
    createSelector([getCurrentOffice, getStatus], (office, status) => {
        return status.getIn(['pagination', `office/${office}/${resource}`], new Map());
    });
