// @ts-nocheck
import React from 'react';
import * as yup from 'yup';
import { FieldArray } from 'formik';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import Input from '@/app.components/forms/Input/Input';
import ContactCard from '@/shared/contacts/ContactCard';
import CloseIconButton from '@/app.components/elements/Buttons/CloseIconButton';
import { events, trackWithHandler, trackHandler, track } from '@/app.utils/analytics';
import CurrencyAddonForOffice from '@/app.components/forms/CurrencyAddonForOffice';
import Modal from '@/app.components/elements/Modal';
import Formik from '@/app.components/forms/helpers/Formik';
import ContactSelect from '@/shared/contacts/ContactSelect';

const validationSchema = (type: 'buyer' | 'tenant') =>
    yup.object().shape({
        transactionPrice: yup
            .number()
            .required('general.validation.required')
            .positive('general.validation.positive_value'),
        buyers: yup
            .array()
            .required(`modal.confirm-deal.${type}-validation`)
            .min(1, `modal.confirm-deal.${type}-validation`),
    });

export default function ConfirmDealDetailsModal({
    isLet,
    show,
    initialValues,
    onCancel,
    onConfirm,
}: {
    isLet: boolean;
    propertyId: string;
    show: boolean;
    initialValues: {
        transactionPrice: number;
        buyers: string[];
    };
    onConfirm: (values: { transactionPrice; buyers }) => void;
    onCancel: () => void;
}) {
    React.useEffect(() => {
        track(events.DEAL_DETAILS_MODAL_OPEN);
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={() => {
                return validationSchema(isLet ? 'tenant' : 'buyer');
            }}
            onSubmit={onConfirm}
            enableReinitialize
            render={({ values, handleSubmit, setFieldValue, errors, touched, isSubmitting }) => {
                return (
                    <>
                        <Modal show={show} testId="confirm-deal-details-modal">
                            <form onSubmit={handleSubmit}>
                                <Modal.Header>
                                    <Modal.Title>
                                        <FormattedMessage
                                            id="modal.property_deal_details.title"
                                            defaultMessage="Save deal details"
                                        />
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div
                                        className="text-center"
                                        style={{
                                            margin: '25px 0',
                                        }}
                                    >
                                        <div style={{ width: 80, height: 120, margin: '0 auto' }}>
                                            <CongratulationsIcon />
                                        </div>
                                        <h4>
                                            <FormattedMessage
                                                id="modal.property_deal_details.header.title"
                                                defaultMessage="Congratulations!"
                                            />
                                        </h4>
                                        <p className="text-muted">
                                            <FormattedMessage
                                                id="modal.property_deal_details.header.subtitle"
                                                defaultMessage="Complete the details of the deal"
                                            />
                                        </p>
                                    </div>
                                    <div>
                                        <Input
                                            label={
                                                isLet ? (
                                                    <FormattedMessage
                                                        id="forms.labels.current_price_let"
                                                        defaultMessage="Negotiated Rent"
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        id="forms.labels.current_price_sell"
                                                        defaultMessage="Negotiated Price"
                                                    />
                                                )
                                            }
                                            type="price"
                                            name="transactionPrice"
                                            onChange={value => setFieldValue('transactionPrice', value)}
                                            decimalScale={2}
                                            addonAfter={<CurrencyAddonForOffice toLet={isLet} />}
                                            value={values.transactionPrice}
                                            error={errors.transactionPrice}
                                            touched={touched.transactionPrice}
                                        />
                                        <FieldArray
                                            name="buyers"
                                            render={arrayHelpers => {
                                                return (
                                                    <>
                                                        <FormGroup
                                                            validationState={touched.buyers && errors.buyers && 'error'}
                                                        >
                                                            <ControlLabel>
                                                                {isLet ? (
                                                                    <FormattedMessage
                                                                        id="estate.transaction_preview.labels.tenant"
                                                                        defaultMessage="Tenant"
                                                                    />
                                                                ) : (
                                                                    <FormattedMessage
                                                                        id="estate.transaction_preview.labels.buyer"
                                                                        defaultMessage="Buyer"
                                                                    />
                                                                )}
                                                            </ControlLabel>
                                                            <ContactSelect
                                                                type="lead"
                                                                onSelect={selectedBuyer => {
                                                                    arrayHelpers.push(selectedBuyer);
                                                                }}
                                                                hasError={touched.buyers && errors.buyers}
                                                                excluded={values.buyers.map(buyer => buyer.id)}
                                                            />
                                                            {touched.buyers && errors.buyers && (
                                                                <HelpBlock>
                                                                    <FormattedMessage
                                                                        id={errors.buyers}
                                                                        defaultMessage="Select at least one buyer"
                                                                    />
                                                                </HelpBlock>
                                                            )}
                                                        </FormGroup>
                                                        <ul className="bc-bordered-list">
                                                            {values.buyers.map((buyer, idx) => (
                                                                <li key={buyer.id}>
                                                                    <ContactCard
                                                                        contact={buyer}
                                                                        actions={[
                                                                            <CloseIconButton
                                                                                key="remove"
                                                                                onClick={() => arrayHelpers.remove(idx)}
                                                                            />,
                                                                        ]}
                                                                    />
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                );
                                            }}
                                        />
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        onClick={trackWithHandler(
                                            onCancel,
                                            events.DEAL_DETAILS_MODAL_CANCEL_BTN_CLICKED,
                                        )}
                                        disabled={isSubmitting}
                                    >
                                        <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        data-testid="save-deal-btn"
                                        disabled={isSubmitting}
                                        onClick={trackHandler(events.DEAL_DETAILS_MODAL_SAVE_BTN_CLICKED)}
                                    >
                                        {isSubmitting ? (
                                            <FormattedMessage id="form.status.saving" defaultMessage="Saving..." />
                                        ) : (
                                            <FormattedMessage id="form.status.save" defaultMessage="Save" />
                                        )}
                                    </Button>
                                </Modal.Footer>
                            </form>
                        </Modal>
                    </>
                );
            }}
        />
    );
}

function CongratulationsIcon() {
    return (
        <svg viewBox="0 0 129 164.99" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M69.68 57.56C48.26 55.4 28 70.59 24.28 91.83c-3.77 21.5 8.71 43.68 22.44 59.25 4.33 4.91 10 11.37 16.42 13.54 4.67 1.56 9.17-2.09 12.61-4.84 17.73-14.2 31.31-37.61 31.63-60.56C107 78 91.07 59.72 69.68 57.56zm10.47 93c-3.39 3.55-8.48 9.44-13.55 10.53-4.33.93-9.41-4.72-12.17-7.38-12.76-12.25-23.56-29.16-26.43-46.84-3.32-20.41 9-40.75 29.77-45 23.63-4.83 45.45 13.56 45.85 37.34-.26 19.3-10.38 37.68-23.47 51.38z"
                fill="#31322d"
            />
            <path
                d="M77.5 99.22a12 12 0 11-12-12 12 12 0 0112 12zM10 76.72a5 5 0 105 5 5 5 0 00-5-5zm-8 25a2 2 0 102 2 2 2 0 00-2-2zm96 22a6 6 0 106 6 6 6 0 00-6-6zm20-38a4 4 0 104 4 4 4 0 00-4-4zm8-29a3 3 0 103 3 3 3 0 00-3-3zm-76 56a3 3 0 103 3 3 3 0 00-3-3zM27.33 5.28c1.33-1.15 4-2.26 5.74-1.2 3 1.86 1.54 5.57.3 7.63s-3.2 3.67-4.5 5.71a7.59 7.59 0 00-.59 7.68 8.13 8.13 0 006.18 4.43c4.67.65 9.42-4.65 13.61-2.57 7.32 3.64-1.5 9.74-3.37 14a7.26 7.26 0 00.76 7.7c3.94 4.89 11.26 3 14.16-1.84 1.24-2.08-2-4-3.24-1.89-3 5-10.87 2.44-7.07-4.34.85-1.51 2.32-2.69 3.35-4.09 1.83-2.51 2.85-5.46 1.75-8.5a8.38 8.38 0 00-5.63-5.15c-4.1-1.15-6.63 1.2-10.36 2.23-2.24.62-4.59.92-6.09-1.15s-.28-4.17 1-6.16c1.13-1.72 2.7-3.05 3.68-4.9 1.3-2.47 2-5.53 1-8.23C35.86-1.41 28.84-1 24.67 2.63c-1.83 1.58.83 4.22 2.66 2.65zm61.43 46.3a28.79 28.79 0 0128.29-21.32c2.41 0 2.41-3.7 0-3.75a32.59 32.59 0 00-31.91 24.08c-.61 2.33 3 3.33 3.62 1zm-57.69 8.56a31.39 31.39 0 00-28.81-11.7c-2.36.31-2.39 4.07 0 3.75A28 28 0 0128.42 62.8c1.49 1.89 4.13-.78 2.65-2.66zM74 27.72a4 4 0 104 4 4 4 0 00-4-4zm16-15a3 3 0 103 3 3 3 0 00-3-3zm-32-7a2 2 0 102 2 2 2 0 00-2-2z"
                fill="#17a8e6"
            />
        </svg>
    );
}
