import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import renderNothing from 'recompose/renderNothing';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';

export default (feature, NotEnabled) =>
    compose(
        connect(
            createStructuredSelector({
                isEnabled: getFeature(feature),
            }),
        ),
        branch(props => !props.isEnabled, NotEnabled ? renderComponent(NotEnabled) : renderNothing),
    );
