import { put } from 'redux-saga/effects';
import { fetchCompany } from '../../actions';
import { UserRequests } from '../../../requests/index';
import apiCall from '../Effects/apiCall';

export default function* onArchiveUser({ userId }) {
    const request = new UserRequests();
    request.withQueryParameter('includes', ['company']);

    const respone = yield apiCall(request.archive, userId);
    yield put(fetchCompany(respone.company.data.id));
}
