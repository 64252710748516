import gql from 'graphql-tag';
import { ContactCardFragment } from '../../fragments/contactCard';

export const ContactInformationForm = gql`
    fragment ContactInformationForm on Contact {
        firstName
        lastName
        email
        phone
        type
        photo {
            id
            uploadedAt
            url
        }
        company
        address
        locale
        notes {
            text
        }
        pronouns
        createdAt
        updatedAt
        assignedNegotiators {
            id
            first_name
            last_name
            picture_url
        }
        officeId
    }
`;

export const GET_CONTACT_QUERY = gql`
    query getContact($id: ID!) {
        contact(id: $id) {
            ...ContactCardFragment
            ...ContactInformationForm
        }
    }
    ${ContactCardFragment}
    ${ContactInformationForm}
`;
