// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { LeadExpressedInterestActivity } from '@/graphql/generated/types';
import { trackHandler, events } from '@/app.utils/analytics';
import { fullName } from '@/app.utils/services/Helpers';
import ActivityCardWithAction from '@/app.components/card/ActivityCard/ActivityCardWithAction';
import { ContactLink, PropertyLink } from './elements/elements';

export default function LeadExpressedInterestActivityCard({
    activity,
    viewAs,
}: {
    activity: LeadExpressedInterestActivity;
    viewAs: 'property' | 'contact';
}) {
    const title =
        viewAs === 'property' ? (
            <FormattedMessage
                id="interaction.expressed_interest.parameterised"
                defaultMessage="{contact} expressed interest"
                values={{
                    contact: activity.interestedLead && (
                        <ContactLink
                            contact={activity.interestedLead}
                            onClick={trackHandler(events.ACTIVITY_CARD_CONTACT_LINK_CLICKED, {
                                activityType: activity.__typename,
                            })}
                        />
                    ),
                }}
            />
        ) : (
            <FormattedMessage
                id="interaction.lead_expressed_interest.about.parameterised"
                defaultMessage="Expressed interest about {location}"
                values={{
                    location: activity.propertyInterestedIn ? (
                        <PropertyLink property={activity.propertyInterestedIn} />
                    ) : (
                        undefined
                    ),
                }}
            />
        );

    const viaText = activity.interestExpressedVia?.name ? (
        <FormattedMessage
            id="interaction.expressed_interest_via"
            defaultMessage="Expressed interest via {method}"
            values={{ method: activity.interestExpressedVia?.name }}
        />
    ) : (
        <FormattedMessage id="general.interactions.lead_expressed_interest" defaultMessage="Expressed interest" />
    );

    const subtitle =
        viewAs === 'property' ? (
            <span>
                {activity?.propertyInterestedIn?.attributes?.location?.formatted ?? (
                    <FormattedMessage id="address.no_location_defined" defaultMessage="No location defined" />
                )}
                {' — '}
                {viaText}
            </span>
        ) : (
            <>
                {fullName(activity?.interestedLead?.firstName, activity?.interestedLead?.lastName)}
                {' - '}
                {viaText}
            </>
        );

    return (
        <ActivityCardWithAction
            icon="face-04"
            title={title}
            subtitle={subtitle}
            date={activity.timestamp}
            activity={activity}
        />
    );
}
