import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import RadioListInput from '@/app.components/forms/Radio/RadioListInput';
import { handleActiveAreaType } from '@/new.domains/properties/utils';
import PropertyFilterAreaRange from '../FilterAreaRange';

type Props = {
    old?: boolean;
    options: string[];
    fullOptions: string[];
    tempSearchSettings?: any;
    initActiveAreaType: string;
    setTempSearchSettings: Function;
};

export const ALLOWED_AREA_RANGE = {
    min_area: 0,
    max_area: 10000000,
};

const allowedRange: [number, number] = [ALLOWED_AREA_RANGE.min_area, ALLOWED_AREA_RANGE.max_area];

const FilterAreaForm: React.FC<Props> = props => {
    const { old, options, fullOptions, initActiveAreaType, tempSearchSettings, setTempSearchSettings } = props;

    const activeAreaType = handleActiveAreaType(tempSearchSettings, options) || initActiveAreaType;

    return (
        <>
            <PropertyFilterAreaRange
                popup
                old={old}
                areaType={activeAreaType}
                max={allowedRange[1]}
                style={{ paddingBottom: 0, marginBottom: 0 }}
                minField={{
                    name: `min_${activeAreaType}_area`,
                    value: tempSearchSettings?.[`min_${activeAreaType}_area`],
                    onChange: value => {
                        // @ts-ignore
                        setTempSearchSettings(prevState => {
                            return { ...prevState, [`min_${activeAreaType}_area`]: value };
                        });
                    },
                }}
                maxField={{
                    name: `max_${activeAreaType}_area`,
                    value: tempSearchSettings?.[`max_${activeAreaType}_area`],
                    onChange: value => {
                        // @ts-ignore
                        setTempSearchSettings(prevState => {
                            return { ...prevState, [`max_${activeAreaType}_area`]: value };
                        });
                    },
                }}
            />

            <RadioListInput
                {...({} as any)}
                value={activeAreaType}
                onChange={changedAreaType => {
                    const oldAreaTypes = Object.fromEntries(
                        fullOptions.filter(el => !el.includes(changedAreaType)).map(el => [el, undefined]),
                    );

                    if (changedAreaType !== activeAreaType) {
                        // @ts-ignore
                        setTempSearchSettings(prevState => {
                            return {
                                ...prevState,
                                ...oldAreaTypes,
                                [`min_${changedAreaType}_area`]:
                                    tempSearchSettings?.[`min_${activeAreaType}_area`] || ALLOWED_AREA_RANGE.min_area,
                                [`max_${changedAreaType}_area`]:
                                    tempSearchSettings?.[`max_${activeAreaType}_area`] || ALLOWED_AREA_RANGE.max_area,
                            };
                        });
                    }
                }}
                label={<FormattedMessage id="area_type" defaultMessage="Area Type" />}
                options={options.map(el => {
                    return {
                        value: el,
                        label: `properties.fields.structure.${el}_area.size`,
                    };
                })}
            />
        </>
    );
};

export default FilterAreaForm;
