// @ts-nocheck
import React from 'react';
import lagRadar from '@sweepbright/webapp-shared/utils/lagRadar';

function LagRadar() {
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        if (APP_ENV === 'local' && visible) {
            const removeLagRadar = lagRadar({
                frames: 60, // number of frames to draw, more = worse performance
                speed: 0.0017, // how fast the sweep moves (rads per ms)
                size: 300, // outer frame px
                inset: 3, // circle inset px
                parent: document.body, // DOM node to attach to
            });

            return () => removeLagRadar();
        }
    }, [visible]);

    React.useEffect(() => {
        document.addEventListener('keydown', onKeyDown);

        return () => document.removeEventListener('keydown', onKeyDown);
        function onKeyDown(evt) {
            // toggle the lag radar with the "t" key
            if (evt.key === 't' && evt.target === document.body) {
                setVisible(v => !v);
            }
        }
    }, []);

    return null;
}

export default LagRadar;
