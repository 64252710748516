import gql from 'graphql-tag';
import { fromJS, List, Map } from 'immutable';
import pick from 'lodash/pick';
import set from 'lodash/set';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import { events, track } from '@/app.utils/analytics';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { PropertyType } from '@/app.data';
import { UserError } from '@/graphql/generated/types';
import { UPDATE_ESTATE_MUTATION } from '@/graphql/mutations/properties/updateEstate';
import { withApolloMutation, withApolloQuery } from '@/graphql/withApollo';
import { getOfficeSetting } from '@/app.redux/selectors';
import PropertyPatchBuilder, {
    DocumentData,
    PropertyData,
} from '@/app.utils/services/PatchBuilders/PropertyPatchBuilder';
import DatetimeSanitizer from '@/app.utils/services/Sanitizers/DatetimeSanitizer';
import NumberFormatSanitizer from '@/app.utils/services/Sanitizers/NumberFormatSanitizer';
import { withToasts } from '@sweepbright/notifications';
import { aggregateRoomsSyncCommands } from './transformations/rooms/aggregateRoomsSyncCommands';
import { RoomsSyncCommandSource } from './transformations/rooms/RoomsSyncCommandSource';

// helpers
const sectionToEventMap = {
    settings: events.PROPERTY_DETAILS_SETTINGS_SAVE_BTN_CLICKED,
    units: events.PROPERTY_DETAILS_UNITS_DUPLICATE_BTN_CLICKED,
    price: events.PROPERTY_DETAILS_PRICE_SAVE_BTN_CLICKED,
    description: events.PROPERTY_DETAILS_DESCRIPTION_SAVE_BTN_CLICKED,
    'legal-and-docs': events.PROPERTY_DETAILS_LEGAL_SAVE_BTN_CLICKED,
    documents: events.PROPERTY_DETAILS_DOCUMENTS_SAVE_BTN_CLICKED,
    features: events.PROPERTY_DETAILS_FEATURES_SAVE_BTN_CLICKED,
    rooms: events.PROPERTY_DETAILS_STRUCTURE_SAVE_BTN_CLICKED,
    images: events.PROPERTY_DETAILS_IMAGES_SAVE_BTN_CLICKED,
    location: events.PROPERTY_DETAILS_LOCATION_SAVE_BTN_CLICKED,
};

export const GET_ESTATE_DETAILS_FORMS_QUERY = gql`
    query GetEstateDetailForms($estateId: ID!) {
        estate(id: $estateId) {
            id
            type
            status
            negotiation
            isProject
            isUnit
            projectId
            attributes
            archived
            internalType
            visibility
            legalEntity
            office_id
            buyer_ids
            owner_ids
        }
    }
`;

export const withPropertyDetails = (section?: keyof typeof sectionToEventMap) =>
    compose(
        withToasts,
        connect(
            createStructuredSelector({
                system: getOfficeSetting('measurement_system'),
            }),
        ),
        withApolloQuery(GET_ESTATE_DETAILS_FORMS_QUERY, {
            options: props => {
                const estateId = props.estateId;

                return {
                    variables: {
                        estateId,
                    },
                    returnPartialData: true,
                    partialRefetch: true,
                    notifyOnNetworkStatusChange: true,
                    onError(error) {
                        getBugsnagClient().notify(error);
                    },
                    errorPolicy: 'all',
                };
            },
            props: ({ data, ...queryResultProps }, ownProps) => {
                return {
                    ...ownProps,
                    ...queryResultProps,
                    initialValues: data?.estate?.isProject
                        ? formatProjectAttributes(data?.estate?.attributes ?? {})
                        : formatPropertyAttributes(data?.estate?.attributes ?? {}, data?.estate?.type, ownProps.system),
                    property: fromJS(data?.estate ?? {}),
                    editingDisabled: data?.estate?.archived,
                };
            },
        }),
        withApolloMutation(UPDATE_ESTATE_MUTATION, {
            props: (mutate, { loading }, ownProps) => {
                const handleSubmit = async (values, options: any) => {
                    if (section) {
                        track(sectionToEventMap[section] as any);
                    }

                    try {
                        const type = ownProps.property.get('type');
                        const patchBuilderAttributes = {
                            ...(ownProps.property.get('attributes')?.toJS() ?? {}),
                            type,
                            is_project: ownProps.property.get('internalType') === 'PROJECT',
                            internalType: ownProps.property.get('internalType'),
                            negotiation: ownProps.property.get('negotiation'),
                        };

                        const pathBuilderUpdatedAttributes = {
                            ...values,
                            documents: untransformFiles(values.documents),
                            type,
                            is_project: ownProps.property.get('internalType') === 'PROJECT',
                        };

                        const operations = new PropertyPatchBuilder(
                            pathBuilderUpdatedAttributes,
                            patchBuilderAttributes,
                        )
                            .getOperations()
                            .toJS();
                        const result = await mutate({
                            variables: {
                                estateId: ownProps.property.get('id'),
                                operations,
                                estateType: ownProps.property.get('internalType'),
                            },
                            ...options,
                        });

                        if (result.data.updateEstate.success) {
                            ownProps.toasts.addSuccess({
                                message: <FormattedMessage id="property.saved" defaultMessage="Property saved" />,
                            });
                        } else {
                            const errors = parseUserErrors(result.data.updateEstate.userErrors);
                            // for redux form we throw the errors object
                            throw errors;
                        }
                    } catch (errors) {
                        const isForbidden = errors?.message?.includes('403');

                        ownProps.toasts.addError({
                            message: isForbidden ? (
                                <FormattedMessage id="unauthorised_403" />
                            ) : (
                                <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                            ),
                        });
                        throw errors;
                    }
                };

                return {
                    status: fromJS({
                        is_saving: loading,
                    }),
                    onSubmit: handleSubmit,
                    // ownProps is passed last, so
                    // we can pass an onSubmit
                    // for testing
                    ...ownProps,
                };
            },
        }),
    );

/**
 * Format an immutable property for usage
 * in a Redux form
 */
export function formatPropertyAttributes(
    attributes: Partial<PropertyData>,
    propertyType: PropertyType,
    measurementSystem?: 'imperial' | 'metric',
) {
    let _attributes = fromJS(attributes);

    _attributes = _attributes.set('vendors', _attributes.getIn(['vendors', 'data'], List()));
    _attributes = _attributes.set('buyers', _attributes.getIn(['buyers', 'data'], List()));
    _attributes = _attributes.updateIn(['structure', 'rooms'], rooms => rooms || Map());
    _attributes = _attributes.updateIn(['energy', 'documents'], documents => documents || Map());

    _attributes = _attributes.update('conditions', conditions => (conditions ? conditions.toMap() : Map()));
    _attributes = _attributes.updateIn(['conditions', 'bathroom'], condition => condition ?? 'good');
    _attributes = _attributes.updateIn(['conditions', 'kitchen'], condition => condition ?? 'good');
    _attributes = _attributes.updateIn(['occupancy', 'occupied'], occupied => occupied ?? false);
    _attributes = _attributes.updateIn(['settings', 'auction', 'start_date'], DatetimeSanitizer.sanitize);
    _attributes = _attributes.updateIn(['settings', 'open_homes'], Map(), openHomes =>
        openHomes.map((openHome, id) => {
            return openHome
                .set('id', id)
                .update('start_date', DatetimeSanitizer.sanitize)
                .update('end_date', DatetimeSanitizer.sanitize);
        }),
    );

    // the shapes come from the API as a list
    // but the form is using a boolean map
    // so we need to transform it
    // until we manage to update that huge form
    _attributes = _attributes.updateIn(['shapes'], shapes => {
        shapes = shapes?.toJS() ?? [];

        return fromJS(
            shapes.reduce((acc, shape) => {
                acc[shape] = true;

                return acc;
            }, {} as Record<string, boolean>),
        );
    });

    // amenities
    let selectedAmenities = Map();
    _attributes.getIn(['amenities'], []).forEach(amenity => {
        selectedAmenities = selectedAmenities.set(amenity, true);
    });
    _attributes = _attributes.set('amenities', selectedAmenities);
    // end amenities

    _attributes = _attributes.update('negotiator', negotiator => negotiator?.getIn(['data', 'id']));

    const roomsSync = new RoomsSyncCommandSource(
        propertyType,
        _attributes.getIn(['amenities'], {}).toJS(),
        _attributes.getIn(['structure'], {}).toJS(),
        measurementSystem,
    );

    return _attributes
        .updateIn(['structure', 'rooms'], Map(), iRooms => {
            const rooms = iRooms
                .toList()
                .sortBy(room => room.get('ordinal'))
                .toJS();
            const actions = roomsSync.synchronizeRooms(rooms);

            return aggregateRoomsSyncCommands(propertyType, rooms, actions);
        })
        .updateIn(['settings', 'open_homes'], Map(), openHomes =>
            openHomes.toList().sortBy(openHome => openHome.get('datetime')),
        )
        .update('documents', transformFiles)
        .update('floor_plans', transformFiles)
        .update('images', transformFiles)
        .toJS();
}

/**
 * Format an immutable property for usage
 * in a Redux form
 */
function formatProjectAttributes(attributes: PropertyData) {
    let _attributes = fromJS(attributes);

    _attributes = _attributes.set('vendors', _attributes.getIn(['vendors', 'data'], List()));
    _attributes = _attributes.set('buyers', _attributes.getIn(['buyers', 'data'], List()));
    _attributes = _attributes.updateIn(['structure', 'rooms'], rooms => rooms || Map());
    _attributes = _attributes.updateIn(['energy', 'documents'], documents => documents || Map());
    _attributes = _attributes.updateIn(['floor_plans'], plans => plans || Map());

    _attributes = _attributes.update('conditions', conditions => (conditions ? conditions.toMap() : Map()));
    _attributes = _attributes.updateIn(['conditions', 'bathroom'], condition => condition ?? 'good');
    _attributes = _attributes.updateIn(['conditions', 'kitchen'], condition => condition ?? 'good');

    _attributes = _attributes.updateIn(['occupancy', 'occupied'], occupied => occupied ?? false);
    _attributes = _attributes.updateIn(['structure', 'rooms'], Map(), rooms =>
        rooms.map(room => {
            if (!room.get('size')) {
                return room;
            }

            return room.update('size', NumberFormatSanitizer.replaceDecimalSeparator);
        }),
    );

    _attributes = _attributes.updateIn(['settings', 'auction', 'start_date'], DatetimeSanitizer.sanitize);
    _attributes = _attributes.updateIn(['settings', 'open_homes'], Map(), openHomes =>
        openHomes.map((openHome, id) => {
            return openHome
                .set('id', id)
                .update('start_date', DatetimeSanitizer.sanitize)
                .update('end_date', DatetimeSanitizer.sanitize);
        }),
    );

    _attributes = _attributes.update('negotiator', negotiator => negotiator?.getIn(['data', 'id']));

    return _attributes
        .updateIn(['structure', 'rooms'], Map(), rooms => rooms.toList().sortBy(room => room.get('ordinal')))
        .updateIn(['settings', 'open_homes'], Map(), openHomes =>
            openHomes.toList().sortBy(openHome => openHome.get('datetime')),
        )
        .update('documents', transformFiles)
        .update('floor_plans', transformFiles)
        .update('images', transformFiles)
        .toJS();
}

function transformFiles(_entities) {
    const entities =
        _entities
            ?.map((_entity, id) => {
                let entity = _entity.set('id', id);
                entity = entity
                    .update('private', value => (typeof value === 'undefined' ? true : value))
                    .update('progress', progress => progress ?? 100);

                return entity;
            })
            .toList() ?? List();

    return entities.sort((first, second) => {
        if (!first.get('ordinal')) {
            return first.get('uploaded_at') < second.get('uploaded_at') ? 1 : -1;
        }

        return first.get('ordinal') > second.get('ordinal') ? 1 : -1;
    });
}

// files in forms are represented
// in an array but when sending them to the API
// they need to be represented as a map
export function untransformFiles(
    filesAsArray: (DocumentData & { id: string; deleted?: boolean })[] = [],
): Maybe<Record<string, DocumentData>> {
    if (filesAsArray?.length > 0) {
        return filesAsArray
            .filter(doc => !doc.deleted)
            .reduce((acc, doc) => {
                acc[doc.id] = pick(doc, [
                    'filename',
                    'extension',
                    'description',
                    'private',
                    'content-type',
                    'uploaded_at',
                    'bucket',
                ]);

                return acc;
            }, {} as Record<string, DocumentData>);
    }

    return null;
}

function parseUserErrors(userErrors: UserError[]) {
    return userErrors.reduce((acc, error) => {
        if (error.field) {
            set(acc, error.field.join('.'), error.message);
        }

        return acc;
    }, {});
}
