import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { filter } from 'graphql-anywhere';
import PropTypes from 'prop-types';
import { Col, FormGroup, ControlLabel } from 'react-bootstrap';
import { createStructuredSelector } from 'reselect';
import { useToasts } from '@sweepbright/notifications';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import useCompany from '@/app.hooks/useCompany';
import { useChannelAccount } from '@/app.utils/Decorators/portalDetail';
import { useSaveChannelAccount, useRemoveChannelAccount } from '@/app.domains/channels/accountMutations';
import { useModal } from '@/app.components/modals/AbstractModal';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import modals from '@/app.components/modals/Channels';
import PortalConnection from '@/app.domains/channels/PortalConnection';
import FormPane from '@/app.components/forms/FormPane';
import Icon from '@/app.components/icons/Icon';
import BackButton from '@/app.components/elements/Buttons/BackButton';
import { CHANNELS_PORTALS } from '@/app.routing';
import TeamsSelect from '@/shared/teams/TeamsSelect';

const PortalAccountConfiguration = ({ router, allowEditing, accountId }) => {
    const company = useCompany();
    const { addSuccess, addError } = useToasts();
    const { channelAccount = {}, loading } = useChannelAccount(accountId);
    const [saveChannelAccount] = useSaveChannelAccount(channelAccount.channel);
    const removeChannelAccount = useRemoveChannelAccount(channelAccount);
    const { handleOpen, getModalProps, handleClose } = useModal(false);

    const [tempTeamId, setTempTeamId] = useState(channelAccount?.office_id || null);

    const handleSubmit = useCallback(
        async attributes => {
            try {
                const res = await saveChannelAccount({ ...attributes, id: channelAccount.id });
                handleClose();
                addSuccess({
                    title: <FormattedMessage id="portals.alerts.updated.title" defaultMessage="Portal updated." />,
                    message: (
                        <FormattedMessage
                            id="portals.alerts.updated.body"
                            defaultMessage="Great! Your settings are updated."
                        />
                    ),
                });

                return res;
            } catch (error) {
                const isForbidden = error?.message?.includes('403');

                addError({
                    message: isForbidden ? (
                        <FormattedMessage id="unauthorised_403" />
                    ) : (
                        <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                    ),
                });
            }
        },
        [addSuccess, addError, channelAccount.id, handleClose, saveChannelAccount],
    );

    useEffect(() => {
        if (channelAccount?.office_id && !loading) {
            setTempTeamId(channelAccount.office_id);
        }
    }, [channelAccount, loading]);

    if (loading) {
        return null;
    }

    const channel = channelAccount.channel;
    const portalId = channel.id;

    const additionalAccessId = channelAccount?.credentials?.additionalAccessId;

    if (!portalId) {
        throw new Error('missing portal id');
    }

    const handleDisconnect = async () => {
        await removeChannelAccount();
        addSuccess({
            message: <FormattedMessage id="portals.portal_removed" defaultMessage="Portal removed successfully" />,
        });
        router.replace('/channels/portals');
    };

    const ModalContent = modals[portalId] || modals.default;

    return (
        <FormPane
            title={
                <>
                    <BackButton to={CHANNELS_PORTALS} />
                    {channelAccount.channel.name}
                </>
            }
            onSubmit={() => {
                const variables = {
                    ...filter(ModalContent.fragments.channelAccountEdit, channelAccount),
                    ...(additionalAccessId && { additionalAccessId }),
                    office_id: tempTeamId === 'everyone' ? null : tempTeamId,
                };

                handleSubmit({
                    ...variables,
                    ...(channelAccount.channel.id === 'facebook' && {
                        credentials: {
                            ...variables.credentials,
                            pageName: channelAccount?.credentials?.name,
                            accessToken: channelAccount?.credentials?.accessToken,
                            pageAccessToken: channelAccount?.credentials?.additionalAccessId,
                            accessTokenSecret: channelAccount?.credentials?.accessTokenSecret,
                        },
                    }),
                });
            }}
        >
            <PortalConnection
                onDisconnect={handleDisconnect}
                service={channel.name}
                name={channelAccount.name}
                identifier={channelAccount.accessToken}
                status={channelAccount.status}
            >
                {allowEditing ? (
                    <Button variant="link" onClick={handleOpen} icon={<Icon icon="edit" />}>
                        <FormattedMessage id="portal.connection.edit" defaultMessage="Edit Connection" />
                    </Button>
                ) : null}

                <FormGroup style={{ marginTop: '10px' }}>
                    <Col>
                        <ControlLabel>
                            <FormattedMessage id="general.visible_to" defaultMessage="Visible To" />
                        </ControlLabel>
                    </Col>
                    <Col>
                        <TeamsSelect
                            type="old"
                            showValueOnTop
                            allowClear={false}
                            onChange={newValue => {
                                setTempTeamId(newValue);
                            }}
                            value={tempTeamId || 'everyone'}
                            additionalOptions={[{ id: 'everyone', name: 'Everyone' }]}
                        />
                    </Col>
                </FormGroup>
            </PortalConnection>
            {allowEditing ? (
                <Modal {...getModalProps()} restoreFocus={false}>
                    <ModalContent
                        portal={channel}
                        company={company}
                        editing
                        initialValues={{
                            ...filter(ModalContent.fragments.channelAccountEdit, channelAccount),
                            ...(additionalAccessId && { additionalAccessId }),
                            office_id: tempTeamId === 'everyone' ? null : tempTeamId,
                        }}
                        onSubmit={handleSubmit}
                        close={handleClose}
                    />
                </Modal>
            ) : null}
        </FormPane>
    );
};

PortalAccountConfiguration.propTypes = {
    accountId: PropTypes.string,
    router: PropTypes.object.isRequired,
    allowEditing: PropTypes.bool,
};

export default compose(
    withRouter,
    withErrorBoundary,
    connect(
        createStructuredSelector({
            // if the multiple accounts feature is off
            // don't show the edit dialog
            allowEditing: getFeature('portals.multipleAccounts.enabled'),
        }),
    ),
)(PortalAccountConfiguration);
