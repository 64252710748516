// @ts-nocheck
/* eslint-disable   jsx-a11y/anchor-has-content */
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { Component, useMemo } from 'react';
import { Col, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { reduxForm as form } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { defineMessages, FormattedMessage } from 'react-intl-sweepbright';
import { replaceRooms } from '@/app.redux/actions/StructureFormActions';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import Button from '@/app.components/elements/Buttons/Button';
import FormPane from '@/app.components/forms/FormPane';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';
import { amenitiesMessages, getVisibleAmenities } from '@/app.data/Amenities';
import { LAND_CONDITIONS, PROPERTY_CONDITIONS } from '@/app.data/Conditions';
import {
    areas,
    orientations,
    roomsOrderPerType,
    roomTypes,
    roomTypesList,
    shapesByType,
    unitsBySystem,
} from '@/app.data/index';
import { rulesMatcher } from '@/app.utils/services/Fields/rulesMatcher';
import { orientableFieldNames } from '@/app.data/Orientations';
import { getDefaultUnitForArea } from '@/app.data/Rooms';
import withModals from '@/app.utils/Decorators/withModals';
import useOffice from '@/app.hooks/useOffice';
import { getOfficeSetting } from '@/app.redux/selectors/index';
import { RoomSorter } from '@/app.utils/services/index';
import { toSingular, toTitle } from '@/app.utils/services/String';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import { withActivePropertyConfirmation } from '@/app.utils/Decorators/withActivePropertyConfirmation';
import {
    Amenity,
    AreaInput,
    FormPanel,
    Icon,
    Input,
    PropertyConditionSelector,
    TileList,
} from '../../../../app.components/index';
import { AddRoomsModal } from '../../../../app.components/modals/index';
import { PropertySubtypes } from '../../../../app.domains/properties/elements/PropertySubtypes';
import { aggregateRoomsSyncCommands } from '../../../../app.domains/properties/transformations/rooms/aggregateRoomsSyncCommands';
import { RoomsSyncCommandSource } from '../../../../app.domains/properties/transformations/rooms/RoomsSyncCommandSource';

const CUSTOM_ROOM = 'custom';

const roomTypesMessages = defineMessages({
    bathroom: {
        id: `pages.properties.rooms_panel.title.types.bathroom`,
        defaultMessage: 'Bathroom',
    },
    living_room: {
        id: `pages.properties.rooms_panel.title.types.living_room`,
        defaultMessage: 'Living Room',
    },
    kitchens: {
        id: `pages.properties.rooms_panel.title.types.kitchen`,
        defaultMessage: 'Kitchens',
    },
    bedrooms: {
        id: `pages.properties.rooms_panel.title.types.bedrooms`,
        defaultMessage: 'Bedrooms',
    },
    bathrooms: {
        id: `pages.properties.rooms_panel.title.types.bathrooms`,
        defaultMessage: 'Bathrooms',
    },
    toilets: {
        id: `pages.properties.rooms_panel.title.types.toilets`,
        defaultMessage: 'WCs',
    },
    floors: {
        id: `pages.properties.rooms_panel.title.types.floors`,
        defaultMessage: 'Floors',
    },
    meeting_rooms: {
        id: `pages.properties.rooms_panel.title.types.meeting_rooms`,
        defaultMessage: 'Meeting Rooms',
    },
    conference_rooms: {
        id: `pages.properties.rooms_panel.title.types.conference_rooms`,
        defaultMessage: 'Conference Rooms',
    },
    open_office_spaces: {
        id: `pages.properties.rooms_panel.title.types.open_office_spaces`,
        defaultMessage: 'Open Office Spaces',
    },
    closed_office_spaces: {
        id: `pages.properties.rooms_panel.title.types.closed_office_spaces`,
        defaultMessage: 'Closed Office Spaces',
    },
    parking_spaces: {
        id: `pages.properties.rooms_panel.title.types.parking_spaces`,
        defaultMessage: 'Parking Spaces',
    },
    showrooms: {
        id: `pages.properties.rooms_panel.title.types.showrooms`,
        defaultMessage: 'Showrooms',
    },
    manufacturing_areas: {
        id: `pages.properties.rooms_panel.title.types.manufacturing_areas`,
        defaultMessage: 'Manufacturing Areas',
    },
    storage_rooms: {
        id: `pages.properties.rooms_panel.title.types.storage_rooms`,
        defaultMessage: 'Storage Rooms',
    },
    shower_rooms: {
        id: `pages.properties.rooms_panel.title.types.shower_rooms`,
        defaultMessage: 'Shower Rooms',
    },
});

const includesTypeInDescription = (description, type) => description.toLowerCase().includes(toSingular(type));
const isCustomRoom = room => room.type.value === CUSTOM_ROOM;
const isRoomOfType = (room, type) => {
    if (room.type.value === CUSTOM_ROOM) {
        return includesTypeInDescription(room.size_description.value, type);
    }

    return room.type.value === type;
};

class StructureForm extends Component {
    static maxRooms = 20;

    static propTypes = {
        fields: PropTypes.object.isRequired,
        property: PropTypes.instanceOf(Immutable.Map).isRequired,
        system: PropTypes.oneOf(['imperial', 'metric']),
        open: PropTypes.func,
        modal: PropTypes.func,
    };

    static defaultProps = {
        system: 'metric',
    };

    static getDerivedStateFromProps({ property }) {
        const propertyRepository = new EstateRepository(property);

        return {
            visibleAmenities: getVisibleAmenities(propertyRepository),
        };
    }

    addRoom(room) {
        const {
            property,
            fields: { dirty },
        } = this.props;
        const sorter = new RoomSorter(
            this.props.fields.structure.rooms.map(item => item.type.value),
            roomsOrderPerType.get(property.get('type')),
        );

        const ordinal = sorter.getOrdinalFor(room.type);

        this.props.fields.structure.rooms.addField(
            {
                ...room,
                ordinal,
            },
            ordinal,
        );
        dirty.onChange(true);
    }

    /**
     * Merge rooms created in the Add Rooms modal
     * to the normal rooms fields
     */
    mergeFutureRooms = fields => {
        fields.rooms.forEach(room => {
            this.addRoom({
                size: room.size || '',
                type: CUSTOM_ROOM,
                size_description: room.room_description || (
                    <FormattedMessage id="property.fields.structure.new_room" defaultMessage="New room" />
                ),
                units: unitsBySystem.get(this.props.system).get('area'),
            });

            // If the room added is of a known type, increment
            // the number of rooms of that type
            this.getStructureForPropertyType().forEach(type => {
                if (includesTypeInDescription(room.room_description, type)) {
                    const currentValue = Number(this.props.fields.structure[type].value);
                    this.props.fields.structure[type].onChange(currentValue + 1);
                }
            });
        });
    };

    synchronizeRooms = () => {
        const { values, property, system } = this.props;
        const propertyType = property.get('type');
        const amenities = values.amenities;
        const structure = values.structure;

        const roomsSync = new RoomsSyncCommandSource(propertyType, amenities, structure, system);
        const actions = roomsSync.synchronizeRooms(values.structure.rooms);

        const newRooms = aggregateRoomsSyncCommands(propertyType, values.structure.rooms, actions);
        this.props.replaceRooms(newRooms);
    };

    handleChange = (event, onChange) => {
        onChange(event);
        setTimeout(this.synchronizeRooms, 0);
    };

    handleRemoveCustomRoom(room, key) {
        this.props.fields.dirty.onChange(true);
        this.props.fields.structure.rooms.removeField(key);

        // If the room removed is of a known type, decrement
        // the number of rooms of that type
        this.getStructureForPropertyType().forEach(type => {
            if (isRoomOfType(room, type)) {
                const currentValue = Number(this.props.fields.structure[type].value);
                this.props.fields.structure[type].onChange(currentValue - 1);
            }
        });
    }

    getStructureForPropertyType() {
        return roomTypes.get(this.props.property.get('type'), []);
    }

    renderAmenities() {
        const { visibleAmenities } = this.state;

        return (
            <FormPanel
                title={<FormattedMessage id="pages.properties.amenities_panel.title" defaultMessage="Amenities" />}
            >
                <TileList grid bordered>
                    {visibleAmenities.map(amenity => {
                        const field = this.props.fields.amenities[amenity];
                        const title = amenitiesMessages[amenity] ? (
                            <FormattedMessage {...amenitiesMessages[amenity]} />
                        ) : (
                            `${amenity} not translated`.toUpperCase()
                        );

                        return (
                            <Amenity
                                key={amenity}
                                name={amenity}
                                title={title}
                                field={{
                                    ...field,
                                    onChange: event => {
                                        this.handleChange(event, field.onChange);
                                    },
                                }}
                            />
                        );
                    })}
                </TileList>
            </FormPanel>
        );
    }

    renderRooms() {
        return <RoomsSection property={this.props.property} fields={this.props.fields} onChange={this.handleChange} />;
    }

    renderShapes() {
        const { fields } = this.props;
        const type = this.props.property.get('type');
        const propertyShapes = shapesByType.get(type, new Immutable.Map());
        const { value, onChange } = fields.shape;

        if (propertyShapes.isEmpty()) {
            return null;
        }

        switch (type) {
            case 'parking':
                return (
                    <FormPanel
                        title={<FormattedMessage id="pages.properties.shape_panel.title" defaultMessage="Shape" />}
                    >
                        <Select
                            value={value}
                            onChange={option => {
                                if (option?.value) {
                                    onChange(option.value);
                                } else {
                                    onChange(null);
                                }
                            }}
                            options={propertyShapes.map(shape => {
                                return {
                                    value: shape,
                                    label: (
                                        <FormattedMessage
                                            id={`property.shape.${shape}`}
                                            defaultMessage={toTitle(shape)}
                                        />
                                    ),
                                };
                            })}
                            isSearchable={false}
                        />
                    </FormPanel>
                );
            default:
                const panelTitle =
                    type === 'land' ? (
                        <FormattedMessage id="pages.properties.shape_panel.title" defaultMessage="Shape" />
                    ) : (
                        <FormattedMessage id="pages.properties.shapes_panel.title" defaultMessage="Shapes" />
                    );

                return (
                    <FormPanel title={panelTitle}>
                        {propertyShapes.map(shape => {
                            return (
                                <Input
                                    key={shape}
                                    type="checkbox"
                                    label={
                                        <FormattedMessage
                                            id={`property.shape.${shape}`}
                                            defaultMessage={toTitle(shape)}
                                        />
                                    }
                                    value={shape}
                                    checked={fields.shapes[shape]?.checked ?? false}
                                    onChange={fields.shapes[shape]?.onChange}
                                />
                            );
                        })}
                    </FormPanel>
                );
        }
    }

    renderAreas() {
        return (
            <ErrorBoundary>
                <RoomAreasSection
                    property={this.props.property}
                    propertyType={this.props.property.get('type')}
                    fields={this.props.fields}
                    onAddCustomRoom={() => this.props.open('add-rooms')}
                    onRemoveCustomRoom={(room, key) => this.handleRemoveCustomRoom(room, key)}
                />
            </ErrorBoundary>
        );
    }

    renderOrientation() {
        const amenitiesChecked = orientableFieldNames.filter(
            fieldName => this.props.fields.amenities[fieldName]?.value,
        );

        const formatedOrientations = Object.keys(orientations).map(orientation => {
            return {
                value: orientation,
                label: (
                    <FormattedMessage id={`orientations.${orientation}`} defaultMessage={orientations[orientation]} />
                ),
            };
        });

        // Only render orientation if anything is selected
        if (amenitiesChecked.length > 0) {
            const orientationSelects = amenitiesChecked.map(amenity => {
                const title = (
                    <FormattedMessage
                        id={`property.fields.structure.${amenity}`}
                        defaultMessage={`property.fields.structure.${amenity}`}
                    />
                );

                return (
                    <FormGroup key={amenity}>
                        <Col sm={4}>
                            <ControlLabel>{title}</ControlLabel>
                        </Col>
                        <Col sm={6}>
                            <Select
                                isSearchable={false}
                                value={this.props.fields.orientation[amenity].value}
                                onChange={({ value }) => this.props.fields.orientation[amenity].onChange(value)}
                                options={formatedOrientations}
                            />
                        </Col>
                    </FormGroup>
                );
            });

            return (
                <FormPanel title={<FormattedMessage id="orientation.title" defaultMessage="Orientation" />} horizontal>
                    {orientationSelects}
                </FormPanel>
            );
        }
    }

    renderConditions() {
        const conditionsType = this.props.property.get('type') === 'land' ? LAND_CONDITIONS : PROPERTY_CONDITIONS;

        return (
            <FormPanel
                title={
                    <FormattedMessage id="pages.properties.condition_panel.title" defaultMessage="General condition" />
                }
                horizontal
            >
                <PropertyConditionSelector
                    icon="house"
                    field={this.props.fields.general_condition}
                    type={conditionsType}
                />
            </FormPanel>
        );
    }

    render() {
        const { handleSubmit, status, editingDisabled } = this.props;

        return (
            <FormPane
                disabled={editingDisabled}
                disabledText={
                    editingDisabled && <FormattedMessage id="property.status.archived" defaultMessage="Archived" />
                }
                title={<FormattedMessage id="titles.property.rooms" defaultMessage="Add property details" />}
                onSubmit={handleSubmit}
                status={status}
            >
                <div className="space-y-4">
                    <FormPanel
                        title={
                            <FormattedMessage
                                id="pages.properties.property_subtype_panel.title"
                                defaultMessage="Property Subtype"
                            />
                        }
                    >
                        <PropertySubtypes
                            subType={this.props.fields.sub_type}
                            type={this.props.property.get('type')}
                            country={this.props.property.getIn(['attributes', 'location', 'country'])}
                        />
                    </FormPanel>
                    {this.renderRooms()}
                    {this.renderShapes()}
                    {this.renderAmenities()}
                    {this.renderAreas()}
                    {this.renderConditions()}
                    {this.renderOrientation()}
                    {this.props.modal(
                        'add-rooms',
                        <AddRoomsModal onSubmit={this.mergeFutureRooms} system={this.props.system} />,
                    )}
                </div>
            </FormPane>
        );
    }
}

//TODO: check if withActivePropertyConfirmation works here
const StructureReduxForm = compose(form({}), withActivePropertyConfirmation, withModals)(StructureForm);

const StructureReduxFormWithFields = props => {
    const { property } = props;
    const formConfig = useMemo(() => {
        const propRepo = new EstateRepository(property);
        const visibleAmenities = getVisibleAmenities(propRepo);
        const amenityFields = visibleAmenities.map(amenity => `amenities.${amenity}`);

        return {
            form: 'properties/rooms',
            fields: ([] as string[]).concat(
                amenityFields,
                roomTypesList.map(structure => `structure.${structure}`).toArray(),
                shapesByType.reduce(shapesList => shapesList.map(shape => `shapes.${shape}`)).toArray(),
                areas
                    .reduce(
                        (reduction, area) =>
                            reduction.union([
                                `structure.${area}.size`,
                                `structure.${area}.size_description`,
                                `structure.${area}.units`,
                            ]),
                        Immutable.OrderedSet(),
                    )
                    .toArray(),
                orientableFieldNames.map(field => `orientation.${field}`),
                [
                    'dirty',
                    'type',
                    'sub_type',
                    'general_condition',
                    'shape',
                    'floor_plans[].id',
                    'floor_plans[].filename',
                    'floor_plans[].description',
                    'floor_plans[].file',
                    'floor_plans[].data_url',
                    'floor_plans[].x',
                    'floor_plans[].y',
                    'structure.rooms[].size',
                    'structure.rooms[].type',
                    'structure.rooms[].size_description',
                    'structure.rooms[].units',
                    'structure.rooms[].ordinal',
                ],
            ),
        };
    }, [property]);

    return <StructureReduxForm {...props} {...formConfig} />;
};

export default compose(
    withProps(props => ({
        estateId: props.params.unit || props.params.property,
    })),
    withPropertyDetails('rooms'),
    connect(
        createStructuredSelector({
            system: getOfficeSetting('measurement_system'),
        }),
        dispatch =>
            bindActionCreators(
                {
                    replaceRooms,
                },
                dispatch,
            ),
    ),
)(StructureReduxFormWithFields);

// rooms section

export function RoomsSection({ property, fields, onChange }) {
    const propertyRepository = new EstateRepository(property);
    const context = propertyRepository.getVisibilityContext();

    const propertyRoomTypes = Object.entries(rulesMatcher.getSchemasByPathPrefix(context, 'attributes.structure'))
        .filter(([_key, value]) => {
            return [value.type].flat().includes('integer');
        })
        .map(([key]) => key.replace('attributes.structure.', ''));

    if (!propertyRoomTypes.length) {
        return null;
    }

    return (
        <FormPanel title={<FormattedMessage id="pages.properties.rooms_panel.title" defaultMessage="Rooms" />}>
            <div className="grid grid-cols-2 col-gap-4">
                {propertyRoomTypes.map(roomType => {
                    const value = fields.structure[roomType]?.value ?? '';
                    const roomTypeLabel = roomTypesMessages[roomType] ? (
                        <FormattedMessage {...roomTypesMessages[roomType]} />
                    ) : (
                        `ROOM TYPE ${roomType} MISSING translation`
                    );

                    return (
                        <Input
                            key={roomType}
                            type="stepper"
                            min={0}
                            max={StructureForm.maxRooms}
                            label={roomTypeLabel}
                            {...fields.structure[roomType]}
                            value={value}
                            onChange={event => onChange(event, fields.structure[roomType].onChange)}
                        />
                    );
                })}
            </div>
        </FormPanel>
    );
}

export function RoomAreasSection({
    fields,
    property,
    propertyType,
    onAddCustomRoom,
    onRemoveCustomRoom,
}: {
    fields: FixMeType;
    property: FixMeType;
    propertyType: string;
    onAddCustomRoom?: () => void;
    onRemoveCustomRoom?: (room: FixMeType, key: string) => void;
}) {
    const { structure } = fields;

    const propertyRepository = new EstateRepository(property);
    const context = propertyRepository.getVisibilityContext();

    const propertyAreas = Object.entries(rulesMatcher.getSchemasByPathPrefix(context, 'attributes.structure'))
        .filter(([_key, value]) => {
            return value?.properties?.size;
        })
        .map(([key]) => key.replace('attributes.structure.', ''));

    const customRooms = structure.rooms.filter(isCustomRoom);
    const rooms = structure.rooms.filter(room => !isCustomRoom(room));
    const measurementSystem = useOffice().getIn(['settings', 'data', 'measurement_system']) ?? 'metric';
    const actionProps = !['parking', 'land'].includes(propertyType) &&
        onAddCustomRoom && {
            action: <FormattedMessage id="pages.properties.areas_panel.actions.add_rooms" defaultMessage="Add Rooms" />,
            onAction: onAddCustomRoom,
        };

    return (
        <>
            <FormPanel
                key="fixed-areas"
                title={<FormattedMessage id="pages.properties.areas_panel.title" defaultMessage="Areas" />}
                {...actionProps}
            >
                {propertyAreas.map(area => {
                    const label = <FormattedMessage id={`properties.fields.structure.${area}.size`} />;
                    const units =
                        structure[area]?.units?.value || getDefaultUnitForArea(area, measurementSystem, propertyType);

                    return <AreaInput key={area} label={label} {...structure[area]?.size} units={units} />;
                })}
            </FormPanel>
            {rooms.length > 0 && (
                <FormPanel key="fixed-rooms">
                    {rooms.map((room, key) => {
                        const title = <FormattedMessage id={`property.fields.structure.areas.${room.type.value}`} />;

                        return <AreaInput key={key} label={title} {...room.size} units={room.units.value} />;
                    })}
                </FormPanel>
            )}
            {onAddCustomRoom && customRooms.length > 0 && (
                <FormPanel key="custom-rooms">
                    {structure.rooms.map((room, key) => {
                        if (!isCustomRoom(room)) {
                            return null;
                        }

                        return (
                            <div className="form-horizontal" key={key}>
                                <FormGroup key={key}>
                                    <Col className="col-sm-7">
                                        <FormControl
                                            type="text"
                                            placeholder={
                                                <FormattedMessage
                                                    id="property.custom_room.placeholder"
                                                    defaultMessage="e.g.: Billiards"
                                                />
                                            }
                                            {...room.size_description}
                                        />
                                    </Col>
                                    <Col className="col-sm-6">
                                        <AreaInput units={room.units.value} {...room.size} />
                                    </Col>
                                    <Col className="col-sm-2">
                                        <div className="input-group pull-right">
                                            <Button
                                                variant="danger"
                                                className="btn-icon"
                                                onClick={() => onRemoveCustomRoom(room, key)}
                                            >
                                                <Icon icon="trash" />
                                            </Button>
                                        </div>
                                    </Col>
                                </FormGroup>
                            </div>
                        );
                    })}
                </FormPanel>
            )}
        </>
    );
}
