import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { GeoTemplate } from '@/graphql/generated/types';
import { OfficeGeoContext } from '../context';
import OfficeGeoModalPostcode from './OfficeGeoModalPostcode';
import OfficeGeoModalGeoJson from './OfficeGeoModalGeoJson';

type Props = {
    show: boolean;
    type: 'postalCode' | 'geometry';
};

const OfficeGeoModal: React.FC<Props> = props => {
    const { show, type } = props;

    const {
        refetch,
        officeId,
        activeEntity,
        setVisible,
        setActiveEntity,
        setVisibleDelete,
        addGeoTemplate,
        updateGeoTemplate,
    } = useContext(OfficeGeoContext);

    const [tempEntity, setTempEntity] = useState<GeoTemplate | undefined>(
        activeEntity || { type, title: '', office_id: officeId },
    );

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const intl = useIntl();

    const fitleredTempPostcodes = tempEntity?.postalCodes?.filter(postcode => !!postcode);

    const handleSubmit = event => {
        event.preventDefault();

        setLoading(true);

        const activeFunction = activeEntity?._id ? updateGeoTemplate : addGeoTemplate;

        const baseObject = {
            type: type,
            office_id: officeId,
            _id: activeEntity?._id,
            title: tempEntity?.title,
        };

        activeFunction({
            variables: {
                officeId: officeId,
                input: {
                    ...baseObject,
                    ...(type === 'postalCode' &&
                        fitleredTempPostcodes?.length && {
                            postalCodes: fitleredTempPostcodes,
                        }),
                    ...(type === 'geometry' && {
                        geometry: tempEntity?.geometry,
                    }),
                },
            },
        })
            .then(() => {
                refetch();

                setError(undefined);
                setVisibleDelete(false);
                setVisible(null);
            })
            .catch(err => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleClose = () => {
        setVisible(null);
        setActiveEntity(undefined);
    };

    const mode = activeEntity?._id ? 'update' : 'create';

    const content = {
        title: intl.formatMessage({ id: `office.geo.modal.${type}.${mode}` }),
        delete: intl.formatMessage({ id: `general.action.delete` }),
        cancel: intl.formatMessage({ id: `general.action.cancel` }),
        save: intl.formatMessage({ id: `general.action.save` }),
    };

    const isEdit = mode === 'update';

    const isDisabledGeometry = type === 'geometry' ? Boolean(!tempEntity?.title || !tempEntity?.geometry) : false;
    const isDisabledPostcode =
        type === 'postalCode' ? Boolean(!tempEntity?.title || !fitleredTempPostcodes?.length) : false;

    const isDisabled = loading || isDisabledGeometry || isDisabledPostcode;

    return (
        <Modal show={show} onHide={handleClose} width={type === 'geometry' ? 'xl' : undefined}>
            <form
                noValidate
                onSubmit={handleSubmit}
                {...(type === 'geometry' && {
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                })}
            >
                <Modal.Header>
                    <Modal.Title>{content.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body
                    {...(type === 'geometry' && {
                        className: 'flex',
                        style: { minHeight: '70vh' },
                    })}
                >
                    <>
                        {type === 'postalCode' && (
                            <OfficeGeoModalPostcode
                                error={error}
                                tempEntity={tempEntity}
                                setTempEntity={setTempEntity}
                            />
                        )}
                    </>
                    <>
                        {type === 'geometry' && (
                            <OfficeGeoModalGeoJson
                                error={error}
                                isEdit={isEdit}
                                tempEntity={tempEntity}
                                setTempEntity={setTempEntity}
                            />
                        )}
                    </>
                </Modal.Body>

                <Modal.Footer>
                    <div className="pull-left">
                        {isEdit && (
                            <Button
                                variant="danger"
                                disabled={loading}
                                onClick={() => {
                                    setVisible(null);
                                    setVisibleDelete(true);
                                }}
                            >
                                {content.delete}
                            </Button>
                        )}
                    </div>
                    <Button onClick={handleClose} disabled={loading}>
                        {content.cancel}
                    </Button>
                    <Button type="submit" variant="primary" disabled={isDisabled}>
                        {content.save}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default OfficeGeoModal;
