import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import BorderedList from '@/app.components/forms/BorderedList.New';
import { FormattedMessage } from 'react-intl-sweepbright';
import useOffice from '@/app.hooks/useOffice';
import useSelectField from '@/app.hooks/useSelectField';
import { useOfficeNegotiatorsForSelect } from '@/app.hooks/useOfficeNegotiatorsForSelect';
import Input from '../../../app.components/forms/Input/Input';
import EmptyState from '../../../app.components/empty/EmptyState';
import NegotiatorPickerCard from './NegotiatorPickerCard';

type Props = {
    onSelect: (contact: any) => void;
};

const NegotiatorPicker: React.FC<Props> = props => {
    const { onSelect } = props;

    const office = useOffice();
    const officeId = office.get('id');

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [scrolled, setScrolled] = useState(false);
    const [finished, setFinished] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [debouncedQuery, setDebouncedQuery] = useState('');

    useDebounce(
        () => {
            setPage(1);
            setFinished(false);
            setDebouncedQuery(query);
        },
        500,
        [query],
    );

    const { selectOptions, loading } = useOfficeNegotiatorsForSelect({
        page,
        officeId,
        query: debouncedQuery,
    });

    useEffect(() => {
        if (scrolled && !finished) {
            setPage(prevPage => prevPage + 1);
        }
    }, [scrolled, finished]);

    const dataHash = useMemo(() => {
        return data.map(el => el.value).join(',');
    }, [data]);

    useEffect(() => {
        if (!loading) {
            if (selectOptions.length > 0) {
                if (page === 1) {
                    setData(selectOptions);
                }

                if (page > 1) {
                    const filteredSelectOptions = selectOptions.filter(
                        (option: any) => !data.find(el => el.value === option.value),
                    );
                    setData(prevData => [...(prevData || []), ...filteredSelectOptions]);
                }
            }

            if (selectOptions.length === 0) {
                setFinished(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, loading, selectOptions, dataHash]);

    const formattedData = useMemo(() => {
        return data
            .filter(el => {
                return el.label.toLowerCase().includes(debouncedQuery.toLowerCase());
            })
            .map(el => {
                return {
                    value: el.value,
                    label: el.label,
                    option: <NegotiatorPickerCard entity={el} />,
                };
            });
    }, [data, debouncedQuery]);

    const selectField = useSelectField([], {
        onSelected: ids => {
            const selectedContact = data.find(el => el.value === ids[0]);

            onSelect(selectedContact);
        },
    });

    return (
        <div className="flex flex-column">
            <Input
                value={query}
                onChange={e => {
                    setQuery(e.target.value);
                }}
                wrapperClassName="form-group has-feedback"
                placeholder={
                    <FormattedMessage id="search.contact_picker.placeholder" defaultMessage='Search "John Doe"' />
                }
            />
            {!loading && formattedData.length === 0 && renderEmptyState()}
            <BorderedList
                highlights
                name="negotiator"
                loading={loading}
                options={formattedData}
                selectField={selectField}
                onFetchMore={() => {
                    setScrolled(!scrolled);
                }}
                hasMore={!finished && !loading}
                className="h-full c-contact-picker__list"
            />
        </div>
    );

    function renderEmptyState() {
        return <EmptyState title={<FormattedMessage id="general.state.no_results" defaultMessage="No results" />} />;
    }
};

export default NegotiatorPicker;
