import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '../icons/Icon';

const WithPrivateTooltip = ({ position, children }) => {
    const overlay = (
        <Tooltip
            label={<FormattedMessage id="forms.tooltips.private" defaultMessage="Private, we never publish this" />}
        >
            <span>
                <Icon icon="lock-locked" />
            </span>
        </Tooltip>
    );

    const first = position === 'left' ? overlay : children;
    const last = position === 'left' ? children : overlay;

    return (
        <React.Fragment>
            {first} {last}
        </React.Fragment>
    );
};

WithPrivateTooltip.defaultProps = {
    position: 'left',
};

WithPrivateTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    position: PropTypes.oneOf(['left', 'right']),
};

export default WithPrivateTooltip;
