import PropTypes from 'prop-types';
import React from 'react';
import MediaCard from './MediaCard/MediaCard';

const CustomizationOption = ({ image, subtitle, ...props }) => {
    return <MediaCard subtitles={[subtitle]} image={<img src={image} width={96} alt="" />} {...props} />;
};

CustomizationOption.propTypes = {
    ...MediaCard.propTypes,
    image: PropTypes.node,
    subtitle: PropTypes.string,
};

export default CustomizationOption;
