import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl-sweepbright';
import ArchiveAction from '@/app.components/forms/ArchiveAction';
import { useToasts } from '@sweepbright/notifications';
import { EstateType } from '@sweepbright/webapp-server/graphql/generated/types';

export const ARCHIVED_STATUS_QUERY = gql`
    query GetEstateArchivedStatus($estateId: ID!) {
        estate(id: $estateId) {
            id
            archived
            internalType
        }
    }
`;

export const ARCHIVE_ESTATE_MUTATION = gql`
    mutation ArchiveEstate($input: ArchiveEstateInput!) {
        archiveEstate(input: $input) {
            estate {
                id
                archived
            }
        }
    }
`;

export const UNARCHIVE_ESTATE_MUTATION = gql`
    mutation UnarchiveEstate($input: UnarchiveEstateInput!) {
        unarchiveEstate(input: $input) {
            estate {
                id
                archived
            }
        }
    }
`;

type Props = {
    estateId: string;
    internalType?: string;
};

export function useEstateArchiver({ estateId }: { estateId: string }) {
    const { data, loading } = useQuery(ARCHIVED_STATUS_QUERY, {
        variables: {
            estateId,
        },
    });

    const estate = data?.estate;
    const { addSuccess, addError } = useToasts();
    const [archiveEstate] = useMutation(ARCHIVE_ESTATE_MUTATION);
    const [unarchiveEstate] = useMutation(UNARCHIVE_ESTATE_MUTATION);
    const isArchived = estate?.archived;
    const internalType = estate?.internalType;
    const isProject = estate?.internalType === EstateType.Project;
    const actionText = !isArchived ? 'archived' : 'unarchived';

    const toggleArchived = React.useCallback(async () => {
        const mutationOptions = {
            variables: {
                input: {
                    estateId,
                    estateType: internalType,
                },
            },
        };

        try {
            if (!isArchived) {
                await archiveEstate(mutationOptions);
            } else {
                await unarchiveEstate(mutationOptions);
            }

            addSuccess({
                message: isProject ? (
                    <FormattedMessage id={`project.${actionText}`} defaultMessage={`Project ${actionText}`} />
                ) : (
                    <FormattedMessage id={`property.${actionText}`} defaultMessage={`Property ${actionText}`} />
                ),
            });
        } catch (err) {
            addError({
                message: isProject ? (
                    <FormattedMessage
                        id={`project.not_${actionText}`}
                        defaultMessage="Project couldn't be ${actionText}"
                    />
                ) : (
                    <FormattedMessage
                        id={`property.not_${actionText}`}
                        defaultMessage={`Property couldn't be ${actionText}`}
                    />
                ),
            });
        }
    }, [estateId, isArchived, internalType]);

    return { isArchived, toggleArchived, loading };
}

export default function ArchiveEstateSection({ estateId, internalType }: Props) {
    const { isArchived, toggleArchived } = useEstateArchiver({ estateId });

    const isProject = internalType === EstateType.Project;

    return (
        <ArchiveAction
            type="property"
            isArchived={isArchived}
            action={toggleArchived}
            confirmation={
                isProject ? (
                    <FormattedMessage
                        id="pages.properties.archive_panel.project_archive_confirmation_label"
                        defaultMessage="I understand this will unpublish the project and remove it from the active projects list. You can re-activate this project later."
                    />
                ) : (
                    <FormattedMessage
                        id="pages.properties.archive_panel.archive_confirmation_label"
                        defaultMessage="I understand this will unpublish the property and remove it from the active properties list. You can re-activate this property later."
                    />
                )
            }
            unArchiveConfirmation={
                isProject ? (
                    <FormattedMessage
                        id="pages.properties.archive_panel.project_unarchive_confirmation_label"
                        defaultMessage="I understand this will re-activate the project and add it to the active projects list."
                    />
                ) : (
                    <FormattedMessage
                        id="pages.properties.archive_panel.unarchive_confirmation_label"
                        defaultMessage="I understand this will re-activate the property and add it to the active properties list."
                    />
                )
            }
        />
    );
}
