import { fromJS } from 'immutable';
import { createReducer } from 'redux-reducer-factory';
import { SET_FEATURES, UPDATE_FEATURES, SET_FEATURE } from '../actions';

export const defaultFlagValue = ['staging', 'production'].includes(process.env.APP_ENV as string) === false;

export const FEATURE_FLAGS = {
    'create-documents': defaultFlagValue,
    'integrations-accounting': defaultFlagValue,
    'integrations-activecampaign': defaultFlagValue,
    'integrations-basecamp': defaultFlagValue,
    'integrations-bombomb': defaultFlagValue,
    'integrations-calendar': defaultFlagValue,
    'integrations-campaign-monitor': defaultFlagValue,
    'integrations-clicksend': defaultFlagValue,
    'integrations-communication': defaultFlagValue,
    'integrations-contactually': defaultFlagValue,
    'integrations-crm': defaultFlagValue,
    'integrations-direct-marketing': defaultFlagValue,
    'integrations-documents': defaultFlagValue,
    'integrations-dotloop': defaultFlagValue,
    'integrations-evernote': defaultFlagValue,
    'integrations-facebook-lead-ads': defaultFlagValue,
    'integrations-facebook-popular': defaultFlagValue,
    'integrations-freshbooks': defaultFlagValue,
    'integrations-gmail': defaultFlagValue,
    'integrations-google-calendar': defaultFlagValue,
    'integrations-google-docs': defaultFlagValue,
    'integrations-hubspot': defaultFlagValue,
    'integrations-intercom': defaultFlagValue,
    'integrations-intercom-zapier': defaultFlagValue,
    'integrations-lead-capture': defaultFlagValue,
    'integrations-linked-in': defaultFlagValue,
    'integrations-livechat': defaultFlagValue,
    'integrations-livechat-zapier': defaultFlagValue,
    'integrations-mailchimp': defaultFlagValue,
    'integrations-popular': defaultFlagValue,
    'integrations-quickbooks': defaultFlagValue,
    'integrations-salesforce': defaultFlagValue,
    'integrations-services': defaultFlagValue,
    'integrations-slack': defaultFlagValue,
    'integrations-sweepbright': defaultFlagValue,
    'integrations-sweepbright-popular': defaultFlagValue,
    'integrations-task-management': defaultFlagValue,
    'integrations-trello': defaultFlagValue,
    'integrations-twitter': defaultFlagValue,
    'integrations-typeform': defaultFlagValue,
    'integrations-webmerge': defaultFlagValue,
    'integrations-wunderlist': defaultFlagValue,
    'integrations-zillow-tech-connect': defaultFlagValue,
    'openhome-auction': defaultFlagValue,
    'price-custom-price': defaultFlagValue,
    'setting-rent-period': defaultFlagValue,
    'vendor-reports': defaultFlagValue,
    'footer-links-linkedin': defaultFlagValue,
    'admin-team-import-properties.archived-field.enabled': defaultFlagValue,
    'admin-team-import-contacts.archived-field.enabled': defaultFlagValue,
    'navigation.agencyWebsiteVisitButton.enabled': defaultFlagValue,
    'admin-channels-website.agencyWebsiteConfigurationSection.enabled': defaultFlagValue,
    'integrations.useOnlineCalendarsWithCronofy.enabled': defaultFlagValue,
    'portals.multipleAccounts.enabled': defaultFlagValue,
    'images.uploadAppend.enabled': defaultFlagValue,
    'search.priceFilterHistogram.enabled': defaultFlagValue,
    'images.googleDrivePicker.enabled': defaultFlagValue,
    'properties.propertiesMap.enabled': defaultFlagValue,
    'properties.propertiesMap.clusters': defaultFlagValue,
    'social.connectTwitterAccount.enabled': defaultFlagValue,
    'apps.moveBotler.enabled': defaultFlagValue,
    'property.timeline.enabled': defaultFlagValue,
    'lead.timeline.enabled': defaultFlagValue,
    'property.deal_details.enabled': defaultFlagValue,
    'vendor.timeline.enabled': defaultFlagValue,
    'property.downloadData.enabled': defaultFlagValue,
    'company.vendor_leads.enabled': defaultFlagValue,
    'property.publicationNew.enabled': defaultFlagValue,
    'search.v3.enabled': defaultFlagValue,
    'search.contactsV3.enabled': defaultFlagValue,
    'locales.languageSwitcher.enabled': defaultFlagValue,
    'properties.mainSortingSimplified.enabled': defaultFlagValue,
    'contacts.mainSortingSimplified.enabled': defaultFlagValue,
    'contacts.vendorReportPreview.enabled': defaultFlagValue,
    'publish.agentFeedsSettings.enabled': defaultFlagValue,
    'filters.archivedUnits.enabled': defaultFlagValue,
    'properties.visitScheduler.enabled': defaultFlagValue,
    'properties.matchesExport.enabled': defaultFlagValue,
    'visit.updateNegotiator': defaultFlagValue,
    'publish.refreshPublicationStatus.enabled': defaultFlagValue,
    'command-palette': defaultFlagValue,
    'contacts-atlas-search': defaultFlagValue,
    'properties-atlas-search': defaultFlagValue,
    descriptionAI: defaultFlagValue,
    'filters.assignee': defaultFlagValue,
    'legal-entities-enabled': defaultFlagValue,
    'geo-templates-enabled': defaultFlagValue,
    'company.matching.enabled': defaultFlagValue,
    'contacts.new.filters.enabled': false,
    'properties.publication.status.enabled': defaultFlagValue,
    'property.new.internalType.enabled': false,
    'property.shared.enabled': false,
    'property.legalEntity.filter.enabled': false,
    'disable-shared-properties-offers': false,
    'disable-shared-properties-schedule': false,
    'disable-shared-properties-activity': false,
    'property.shared.visit.enabled': false,
    'property.shared.offer.enabled': false,
    'property.recommendations.enabled': defaultFlagValue,
    'enable-contacts-filter-by-user': false,
    'contact.preferences.areas.enabled': false,
    'contact.labels.enabled': false,
    'property.features.filter.enabled': false,
    'property.status.estimate': false,
    'property.feature.fields.june2024': false,
};

export type FeatureFlagName = keyof typeof FEATURE_FLAGS;
export type FeatureFlags = { [F in FeatureFlagName]: boolean };

export default createReducer(FEATURE_FLAGS, {
    [SET_FEATURES]: (features, { payload }) => {
        return fromJS(payload);
    },
    [UPDATE_FEATURES]: (features, { payload }) => {
        return fromJS(payload).reduce((current, value, feature) => {
            return current.set(feature, value.get('current'));
        }, features);
    },
    [SET_FEATURE]: (features, { feature, value }) => {
        return features.set(feature, value);
    },
});
