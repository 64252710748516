export const propertyTypes = ['house', 'apartment', 'parking', 'land', 'commercial', 'office'] as const;
export const ALLOWED_PRICE_RANGE = { min_price: 0, max_price: Infinity };
export const statusTypes = [
    'prospect',
    'available',
    'under_contract',
    'option',
    'bid',
    'sold',
    'agreement',
    'estimate',
];

export type PropertyType = typeof propertyTypes[number];
