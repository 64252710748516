import { PriceAggregations } from '@/requests/types';
export const SET_PRICE_AGGREGATIONS = 'aggregations/SET_PRICE_AGGREGATIONS';

export function setAggregations(aggregations: PriceAggregations, key: 'properties' | 'contacts') {
    return { type: SET_PRICE_AGGREGATIONS, key, aggregations } as const;
}

export function setPropertiesAggregations(aggregations: PriceAggregations) {
    return setAggregations(aggregations, 'properties');
}

export type AggregationAction = ReturnType<typeof setAggregations>;
