import gql from 'graphql-tag';
import locationPreference from '@/graphql/fragments/locationPreference';

export const UPDATE_LOCATION_PREFERENCE = gql`
    mutation updateLocationPreference(
        $leadId: ID!
        $preferenceId: ID
        $locationPreferenceId: ID!
        $preference: LocationPreferenceInput!
        $geoJSONData: GeoJSONDataInput
        $postalCodesData: PostalCodesDataInput
        $includeLocationData: Boolean! = true
    ) {
        updateLocationPreference(
            leadId: $leadId
            locationPreferenceId: $locationPreferenceId
            preference: $preference
            geoJSONData: $geoJSONData
            postalCodesData: $postalCodesData
            preferenceId: $preferenceId
        ) {
            locationPreference {
                ...locationPreference
            }
        }
    }
    ${locationPreference}
`;
