export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    JSON: any;
    Currency: any;
};

export type AccountPublicationSettings = {
    publishPrice?: Maybe<Scalars['Boolean']>;
    publishLocation?: Maybe<Scalars['Boolean']>;
};

export type Activity = {
    id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    timestamp?: Maybe<Scalars['DateTime']>;
    details?: Maybe<ActivityDetails>;
    action?: Maybe<ActivityAction>;
};

export enum ActivityAction {
    Feedback = 'FEEDBACK',
    Details = 'DETAILS',
}

export type ActivityConnection = {
    __typename?: 'ActivityConnection';
    /** A list of edges */
    edges: Array<ActivityEdge>;
    /** A list of nodes */
    nodes: Array<Activity>;
    /** The total number of items in the connection */
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
};

export type ActivityDetails = {
    id: Scalars['ID'];
};

export type ActivityEdge = {
    __typename?: 'ActivityEdge';
    node: Activity;
    cursor?: Maybe<Scalars['String']>;
};

export type AddContactMutationInput = {
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    locale?: Maybe<Scalars['String']>;
    type: Scalars['String'];
    pronouns?: Maybe<Scalars['String']>;
};

export type AddContactMutationPayload = {
    __typename?: 'AddContactMutationPayload';
    success: Scalars['Boolean'];
    /** The created contact after the mutation */
    contact?: Maybe<Contact>;
    validationErrors?: Maybe<Scalars['JSON']>;
};

export type AddEstateInput = {
    type: Scalars['String'];
    kind: Scalars['String'];
    negotiation: Scalars['String'];
};

export type AddEstatePayload = {
    __typename?: 'AddEstatePayload';
    estate: Estate;
};

export type AddIntegrationInput = {
    integration: Scalars['JSON'];
};

export type AddIntegrationPayload = {
    __typename?: 'AddIntegrationPayload';
    user: User;
};

export type AddLeadPreferenceInput = {
    contactId: Scalars['ID'];
};

export type AddLeadPreferencePayload = {
    __typename?: 'AddLeadPreferencePayload';
    /** The created lead preference */
    preference?: Maybe<BuyerPreference>;
};

export type AddLocationPreferenceInput = {
    leadId: Scalars['ID'];
    /** The id of the buyer preference to which the location preference should be added */
    preferenceId?: Maybe<Scalars['ID']>;
    locationPreference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
};

export type AddLocationPreferencePayload = {
    __typename?: 'AddLocationPreferencePayload';
    /** The new location preference */
    locationPreference: LocationPreference;
};

export type AddLocationTemplatePayload = {
    __typename?: 'AddLocationTemplatePayload';
    /** The new location preference template added to the office */
    locationTemplate: LocationPreference;
    /** The id of the office to which the location template was added */
    officeId: Scalars['ID'];
};

export type AddUnitToProjectInput = {
    projectId: Scalars['ID'];
};

export type AddUnitToProjectPayload = {
    __typename?: 'AddUnitToProjectPayload';
    project?: Maybe<Estate>;
    unit?: Maybe<Estate>;
};

export type ArchiveEstateInput = {
    /** The ID of the estate to archive */
    estateId: Scalars['ID'];
    estateType: EstateType;
};

export type ArchiveEstatePayload = {
    __typename?: 'ArchiveEstatePayload';
    /** The estate that was archived */
    estate?: Maybe<Estate>;
};

export type ArchiveLeadsInput = {
    leadIds: Array<Scalars['ID']>;
};

export type ArchiveLeadsPayload = {
    __typename?: 'ArchiveLeadsPayload';
    company: Company;
};

export type AssignBuyersToEstateInput = {
    estateId: Scalars['ID'];
    buyerIds: Array<Scalars['ID']>;
};

export type AssignBuyersToEstatePayload = {
    __typename?: 'AssignBuyersToEstatePayload';
    estate?: Maybe<Estate>;
};

export type AssignEstatesToNegotiatorInput = {
    estateIds: Array<Scalars['ID']>;
    userId: Scalars['ID'];
};

export type AssignEstatesToNegotiatorPayload = {
    __typename?: 'AssignEstatesToNegotiatorPayload';
    success?: Maybe<Scalars['Boolean']>;
    negotiator?: Maybe<User>;
};

export type AssignLeadsInput = {
    /** The office the leads are going to be assigned */
    officeId: Scalars['ID'];
    /** The id of the leads */
    leadIds: Array<Scalars['ID']>;
};

export type AssignLeadsPayload = {
    __typename?: 'AssignLeadsPayload';
    success: Scalars['Boolean'];
    /** The leads that were assigned to the office */
    assignedLeads: Array<Contact>;
};

export type AssignLegalEntityToEstateInput = {
    estateId: Scalars['ID'];
    legalEntityId: Scalars['ID'];
};

export type AssignLegalEntityToPayload = {
    __typename?: 'AssignLegalEntityToPayload';
    estate?: Maybe<Estate>;
};

export type AssignVendorsToEstateInput = {
    estateId: Scalars['ID'];
    vendorIds: Array<Scalars['ID']>;
};

export type AssignVendorsToEstatePayload = {
    __typename?: 'AssignVendorsToEstatePayload';
    estate?: Maybe<Estate>;
};

export type AuthCheckInput = {
    operation: Scalars['String'];
    requestId: Scalars['String'];
    resourceId: Scalars['String'];
    resourceType: Scalars['String'];
    context?: Maybe<AuthCheckIputContext>;
};

export type AuthCheckIputContext = {
    attendees?: Maybe<Array<Maybe<Scalars['String']>>>;
    propertyId?: Maybe<Scalars['String']>;
    direction?: Maybe<Scalars['String']>;
    buyerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
    ownerIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AuthenticatePayload = {
    __typename?: 'AuthenticatePayload';
    success: Scalars['Boolean'];
    accessToken?: Maybe<Scalars['String']>;
};

export type BuyerLead = {
    __typename?: 'BuyerLead';
    id: Scalars['ID'];
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    contactRequests?: Maybe<ContactRequestConnection>;
};

export type BuyerPreference = {
    id: Scalars['ID'];
    negotiation: Negotiation;
    isInvestor?: Maybe<Scalars['Boolean']>;
    max_liveable_area?: Maybe<Scalars['Float']>;
    min_liveable_area?: Maybe<Scalars['Float']>;
    max_plot_area?: Maybe<Scalars['Float']>;
    min_plot_area?: Maybe<Scalars['Float']>;
    max_net_area?: Maybe<Scalars['Float']>;
    min_net_area?: Maybe<Scalars['Float']>;
    max_gross_area?: Maybe<Scalars['Float']>;
    min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    type: Scalars['String'];
    /** The subtypes the user is interested in */
    subtypes?: Maybe<Array<Scalars['String']>>;
    /** The minimun condition of the property */
    condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    amenities?: Maybe<Array<Scalars['String']>>;
    /** Free form text with the wishes */
    wishesText?: Maybe<Scalars['String']>;
    locations?: Maybe<LocationPreferenceConnection>;
};

export type BuyerPreferenceLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceEdge = {
    __typename?: 'BuyerPreferenceEdge';
    node?: Maybe<BuyerPreference>;
};

export type BuyerPreferenceForApartment = BuyerPreference & {
    __typename?: 'BuyerPreferenceForApartment';
    id: Scalars['ID'];
    negotiation: Negotiation;
    isInvestor?: Maybe<Scalars['Boolean']>;
    max_liveable_area?: Maybe<Scalars['Float']>;
    min_liveable_area?: Maybe<Scalars['Float']>;
    max_plot_area?: Maybe<Scalars['Float']>;
    min_plot_area?: Maybe<Scalars['Float']>;
    max_net_area?: Maybe<Scalars['Float']>;
    min_net_area?: Maybe<Scalars['Float']>;
    max_gross_area?: Maybe<Scalars['Float']>;
    min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    type: Scalars['String'];
    /** The subtypes the user is interested in */
    subtypes?: Maybe<Array<Scalars['String']>>;
    /** The minimun condition of the property */
    condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    amenities?: Maybe<Array<Scalars['String']>>;
    /** Free form text with the wishes */
    wishesText?: Maybe<Scalars['String']>;
    locations: LocationPreferenceConnection;
    /** The minimun number of bedrooms */
    minBedrooms?: Maybe<Scalars['Int']>;
    /** Livable area (structure.livable_area.size) */
    minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForApartmentLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceForCommercial = BuyerPreference & {
    __typename?: 'BuyerPreferenceForCommercial';
    id: Scalars['ID'];
    negotiation: Negotiation;
    isInvestor?: Maybe<Scalars['Boolean']>;
    max_liveable_area?: Maybe<Scalars['Float']>;
    min_liveable_area?: Maybe<Scalars['Float']>;
    max_plot_area?: Maybe<Scalars['Float']>;
    min_plot_area?: Maybe<Scalars['Float']>;
    max_net_area?: Maybe<Scalars['Float']>;
    min_net_area?: Maybe<Scalars['Float']>;
    max_gross_area?: Maybe<Scalars['Float']>;
    min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    type: Scalars['String'];
    /** The subtypes the user is interested in */
    subtypes?: Maybe<Array<Scalars['String']>>;
    /** The minimun condition of the property */
    condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    amenities?: Maybe<Array<Scalars['String']>>;
    /** Free form text with the wishes */
    wishesText?: Maybe<Scalars['String']>;
    locations: LocationPreferenceConnection;
    /** Gross area (structure.gross_area.size) */
    minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForCommercialLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceForHouse = BuyerPreference & {
    __typename?: 'BuyerPreferenceForHouse';
    id: Scalars['ID'];
    negotiation: Negotiation;
    isInvestor?: Maybe<Scalars['Boolean']>;
    max_liveable_area?: Maybe<Scalars['Float']>;
    min_liveable_area?: Maybe<Scalars['Float']>;
    max_plot_area?: Maybe<Scalars['Float']>;
    min_plot_area?: Maybe<Scalars['Float']>;
    max_net_area?: Maybe<Scalars['Float']>;
    min_net_area?: Maybe<Scalars['Float']>;
    max_gross_area?: Maybe<Scalars['Float']>;
    min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    type: Scalars['String'];
    /** The subtypes the user is interested in */
    subtypes?: Maybe<Array<Scalars['String']>>;
    /** The minimun condition of the property */
    condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    amenities?: Maybe<Array<Scalars['String']>>;
    /** Free form text with the wishes */
    wishesText?: Maybe<Scalars['String']>;
    locations: LocationPreferenceConnection;
    /** The minimun number of bedrooms */
    minBedrooms?: Maybe<Scalars['Int']>;
    /** Livable area (structure.livable_area.size) */
    minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForHouseLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceForLand = BuyerPreference & {
    __typename?: 'BuyerPreferenceForLand';
    id: Scalars['ID'];
    negotiation: Negotiation;
    isInvestor?: Maybe<Scalars['Boolean']>;
    max_liveable_area?: Maybe<Scalars['Float']>;
    min_liveable_area?: Maybe<Scalars['Float']>;
    max_plot_area?: Maybe<Scalars['Float']>;
    min_plot_area?: Maybe<Scalars['Float']>;
    max_net_area?: Maybe<Scalars['Float']>;
    min_net_area?: Maybe<Scalars['Float']>;
    max_gross_area?: Maybe<Scalars['Float']>;
    min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    type: Scalars['String'];
    /** The subtypes the user is interested in */
    subtypes?: Maybe<Array<Scalars['String']>>;
    /** The minimun condition of the property */
    condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    amenities?: Maybe<Array<Scalars['String']>>;
    /** Free form text with the wishes */
    wishesText?: Maybe<Scalars['String']>;
    locations: LocationPreferenceConnection;
    /** Plot area area (structure.plot_area.size) */
    minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForLandLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceForOffice = BuyerPreference & {
    __typename?: 'BuyerPreferenceForOffice';
    id: Scalars['ID'];
    negotiation: Negotiation;
    isInvestor?: Maybe<Scalars['Boolean']>;
    max_liveable_area?: Maybe<Scalars['Float']>;
    min_liveable_area?: Maybe<Scalars['Float']>;
    max_plot_area?: Maybe<Scalars['Float']>;
    min_plot_area?: Maybe<Scalars['Float']>;
    max_net_area?: Maybe<Scalars['Float']>;
    min_net_area?: Maybe<Scalars['Float']>;
    max_gross_area?: Maybe<Scalars['Float']>;
    min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    type: Scalars['String'];
    /** The subtypes the user is interested in */
    subtypes?: Maybe<Array<Scalars['String']>>;
    /** The minimun condition of the property */
    condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    amenities?: Maybe<Array<Scalars['String']>>;
    /** Free form text with the wishes */
    wishesText?: Maybe<Scalars['String']>;
    locations: LocationPreferenceConnection;
    /** Net area (structure.net_area.size) */
    minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForOfficeLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceForParking = BuyerPreference & {
    __typename?: 'BuyerPreferenceForParking';
    id: Scalars['ID'];
    negotiation: Negotiation;
    isInvestor?: Maybe<Scalars['Boolean']>;
    max_liveable_area?: Maybe<Scalars['Float']>;
    min_liveable_area?: Maybe<Scalars['Float']>;
    max_plot_area?: Maybe<Scalars['Float']>;
    min_plot_area?: Maybe<Scalars['Float']>;
    max_net_area?: Maybe<Scalars['Float']>;
    min_net_area?: Maybe<Scalars['Float']>;
    max_gross_area?: Maybe<Scalars['Float']>;
    min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    type: Scalars['String'];
    /** The subtypes the user is interested in */
    subtypes?: Maybe<Array<Scalars['String']>>;
    /** The minimun condition of the property */
    condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    amenities?: Maybe<Array<Scalars['String']>>;
    /** Free form text with the wishes */
    wishesText?: Maybe<Scalars['String']>;
    locations: LocationPreferenceConnection;
    /** Gross area (structure.gross_area.size) */
    minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForParkingLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferencesConnection = {
    __typename?: 'BuyerPreferencesConnection';
    nodes: Array<BuyerPreference>;
    edges: Array<BuyerPreferenceEdge>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type Channel = {
    __typename?: 'Channel';
    id: Scalars['ID'];
    logoUrl?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    type: Scalars['String'];
    url?: Maybe<Scalars['String']>;
    slug: Scalars['String'];
    countries: Array<Scalars['String']>;
    /** @deprecated Use `settings.supportsUnpublishing` field */
    supportsUnpublishing: Scalars['Boolean'];
    /** @deprecated Use `settings.supportsRepublishing` field */
    supportsRepublishing: Scalars['Boolean'];
    settings: ChannelSettings;
    accounts: Array<ChannelAccount>;
};

export type ChannelAccount = {
    __typename?: 'ChannelAccount';
    id: Scalars['ID'];
    name: Scalars['String'];
    credentials: ChannelAccountCredentials;
    status: Scalars['String'];
    office_id?: Maybe<Scalars['String']>;
    /** @deprecated Use `credentials.accessToken` field */
    accessToken?: Maybe<Scalars['String']>;
    /** @deprecated Use `credentials.accessTokenSecret` field */
    accessTokenSecret?: Maybe<Scalars['String']>;
    channel: Channel;
    webhook?: Maybe<Scalars['String']>;
};

export type ChannelAccountCredentials = {
    __typename?: 'ChannelAccountCredentials';
    channelId: Scalars['String'];
    accessToken?: Maybe<Scalars['String']>;
    accessTokenSecret?: Maybe<Scalars['String']>;
    additionalAccessId?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type ChannelAccountInput = {
    id?: Maybe<Scalars['ID']>;
    channel: Scalars['String'];
    officeId?: Maybe<Scalars['String']>;
    accessToken?: Maybe<Scalars['String']>;
    accessTokenSecret?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    facebookPageName?: Maybe<Scalars['String']>;
    facebookPageAccessToken?: Maybe<Scalars['String']>;
    twitterUsername?: Maybe<Scalars['String']>;
    additionalAccessId?: Maybe<Scalars['String']>;
    webhook?: Maybe<Scalars['String']>;
};

export type ChannelSettings = {
    __typename?: 'ChannelSettings';
    supportsUnpublishing: Scalars['Boolean'];
    supportsRepublishing: Scalars['Boolean'];
    allowsMultipleAccounts: Scalars['Boolean'];
};

export type CloneLocationPreferenceTemplateInput = {
    officeId: Scalars['ID'];
    locationId: Scalars['ID'];
    leadId: Scalars['ID'];
    /** TODO: make this required */
    preferenceId?: Maybe<Scalars['ID']>;
};

export type CloneLocationTemplatePayload = {
    __typename?: 'CloneLocationTemplatePayload';
    /** The clone of the location template now as part of the user location preferences */
    locationPreference: LocationPreference;
};

export type Company = {
    __typename?: 'Company';
    id: Scalars['ID'];
    leads: CompanyLeadsConnection;
    lead?: Maybe<Contact>;
    offices?: Maybe<Array<Office>>;
    admins: CompanyAdminsConnection;
};

export type CompanyLeadsArgs = {
    type: ContactType;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
    sortField?: Maybe<Scalars['String']>;
    sortOrder?: Maybe<Scalars['String']>;
};

export type CompanyLeadArgs = {
    id: Scalars['ID'];
};

export type CompanyAdminsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
};

export type CompanyAdminConnectionEdge = {
    __typename?: 'CompanyAdminConnectionEdge';
    node: User;
};

export type CompanyAdminsConnection = {
    __typename?: 'CompanyAdminsConnection';
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
    edges: Array<CompanyAdminConnectionEdge>;
    nodes: Array<User>;
};

export type CompanyLeadConnectionEdge = {
    __typename?: 'CompanyLeadConnectionEdge';
    node: BuyerLead;
};

export type CompanyLeadsConnection = {
    __typename?: 'CompanyLeadsConnection';
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
    edges: Array<CompanyLeadConnectionEdge>;
    nodes: Array<BuyerLead>;
};

export type CompanyTeam = {
    __typename?: 'CompanyTeam';
    id: Scalars['ID'];
    name: Scalars['String'];
    settings?: Maybe<CompanyTeamSettings>;
    head_negotiator?: Maybe<CompanyTeamHeadNegotiator>;
};

export type CompanyTeamHeadNegotiator = {
    __typename?: 'CompanyTeamHeadNegotiator';
    data?: Maybe<Scalars['JSON']>;
};

export type CompanyTeamResponse = {
    __typename?: 'CompanyTeamResponse';
    data?: Maybe<CompanyTeam>;
};

export type CompanyTeamSettings = {
    __typename?: 'CompanyTeamSettings';
    data?: Maybe<Scalars['JSON']>;
};

export type CompanyTeamsMeta = {
    __typename?: 'CompanyTeamsMeta';
    pagination?: Maybe<CompanyTeamsMetaPagination>;
};

export type CompanyTeamsMetaPagination = {
    __typename?: 'CompanyTeamsMetaPagination';
    current_page?: Maybe<Scalars['Int']>;
    total_pages?: Maybe<Scalars['Int']>;
    total?: Maybe<Scalars['Int']>;
    per_page?: Maybe<Scalars['Int']>;
};

export type CompanyTeamsResponse = {
    __typename?: 'CompanyTeamsResponse';
    data?: Maybe<Array<Maybe<CompanyTeam>>>;
    meta?: Maybe<CompanyTeamsMeta>;
};

export enum ConfigurationStatus {
    MissingUnits = 'MISSING_UNITS',
    MissingFields = 'MISSING_FIELDS',
    NotSupported = 'NOT_SUPPORTED',
    NotConfigured = 'NOT_CONFIGURED',
    Ok = 'OK',
}

export type Contact = {
    id: Scalars['ID'];
    /** The first name of the contact */
    firstName?: Maybe<Scalars['String']>;
    /** The last name of the contact */
    lastName: Scalars['String'];
    /** The email address of the contact */
    email?: Maybe<Scalars['String']>;
    /** The phone number of the contact */
    phone?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    /** The locale used for comunication witht the contact */
    officeId?: Maybe<Scalars['String']>;
    locale?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    notes: Array<Note>;
    /** Wether the contact is subscribed to marketing emails */
    subscribed?: Maybe<Scalars['Boolean']>;
    type: ContactType;
    photo?: Maybe<ContactPhoto>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    archived: Scalars['Boolean'];
    address: Scalars['JSON'];
    pronouns?: Maybe<Scalars['String']>;
    timeline: ContactTimeline;
    interactedProperties: EstatesConnection;
    /** The list of properties that the contact can interact with */
    eligibleEstates: EstatesConnection;
    /** The last interaction with this contact */
    lastInteraction?: Maybe<Scalars['JSON']>;
    assignedNegotiators?: Maybe<Array<ContactAssignedNegotiator>>;
    offers: Array<Offer>;
    offersStats?: Maybe<OffersStats>;
};

export type ContactPhoneArgs = {
    format?: Maybe<PhoneNumberFormat>;
};

export type ContactInteractedPropertiesArgs = {
    interestLevel?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

export type ContactEligibleEstatesArgs = {
    query?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
};

export type ContactOffersArgs = {
    archived: Scalars['Boolean'];
};

export type ContactAssignedNegotiator = {
    __typename?: 'ContactAssignedNegotiator';
    id: Scalars['Int'];
    first_name?: Maybe<Scalars['String']>;
    last_name?: Maybe<Scalars['String']>;
    picture_url?: Maybe<Scalars['String']>;
};

export type ContactCalledActivity = Activity & {
    __typename?: 'ContactCalledActivity';
    id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    timestamp: Scalars['DateTime'];
    calledContact?: Maybe<Contact>;
    calledBy: User;
    about?: Maybe<Estate>;
    details?: Maybe<ActivityDetails>;
    action?: Maybe<ActivityAction>;
};

export type ContactConnection = {
    __typename?: 'ContactConnection';
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
    edges: Array<ContactEdge>;
    nodes: Array<Contact>;
};

export type ContactEdge = {
    __typename?: 'ContactEdge';
    node: Contact;
};

export type ContactEmailedActivity = Activity & {
    __typename?: 'ContactEmailedActivity';
    id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    timestamp: Scalars['DateTime'];
    /** The contact to whom the email was sent */
    emailedContact?: Maybe<Contact>;
    /** The user that send the email */
    emailedBy: User;
    about?: Maybe<Estate>;
    details?: Maybe<ActivityDetails>;
    action?: Maybe<ActivityAction>;
};

export type ContactFiltersInput = {
    type?: Maybe<ContactType>;
    archived?: Maybe<Scalars['Boolean']>;
    offerStatus?: Maybe<FilterOfferStatus>;
    office?: Maybe<Scalars['ID']>;
    labels?: Maybe<Array<Scalars['ID']>>;
    negotiation?: Maybe<Negotiation>;
    negotiator?: Maybe<Scalars['Int']>;
    noNegotiator?: Maybe<Scalars['Boolean']>;
    maxPrice?: Maybe<Scalars['Float']>;
    minPrice?: Maybe<Scalars['Float']>;
    officeId?: Maybe<Scalars['String']>;
    price_point?: Maybe<Scalars['Float']>;
    negotiator_ids?: Maybe<Array<Scalars['ID']>>;
    preference_type?: Maybe<Scalars['String']>;
    preference_subtype?: Maybe<Scalars['String']>;
    preference_min_condition?: Maybe<Scalars['String']>;
    preference_amenity?: Maybe<Array<Maybe<Scalars['String']>>>;
    preference_min_bedrooms?: Maybe<Scalars['Int']>;
    preference_postal_code?: Maybe<Scalars['String']>;
    noInterestPropertyId?: Maybe<Scalars['ID']>;
    preferenceGeoPoint?: Maybe<Array<Maybe<Scalars['Float']>>>;
    preferenceLiveableArea?: Maybe<Scalars['Float']>;
    preferenceNetArea?: Maybe<Scalars['Float']>;
    preferencePlotArea?: Maybe<Scalars['Float']>;
    preferenceGrossArea?: Maybe<Scalars['Float']>;
};

export type ContactMessagedActivity = Activity & {
    __typename?: 'ContactMessagedActivity';
    id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    timestamp: Scalars['DateTime'];
    messagedContact?: Maybe<Contact>;
    messagedBy: User;
    about?: Maybe<Estate>;
    details?: Maybe<ActivityDetails>;
    action?: Maybe<ActivityAction>;
};

export type ContactOffersFilter = {
    contactId: Scalars['ID'];
    archived: Scalars['Boolean'];
};

export type ContactPhoto = {
    __typename?: 'ContactPhoto';
    id: Scalars['ID'];
    url: Scalars['String'];
    uploadedAt?: Maybe<Scalars['String']>;
};

export type ContactReference = {
    __typename?: 'ContactReference';
    assignee?: Maybe<ContactReferenceAssignee>;
    team?: Maybe<ContactReferenceTeam>;
};

export type ContactReferenceAssignee = {
    __typename?: 'ContactReferenceAssignee';
    id: Scalars['ID'];
    first_name?: Maybe<Scalars['String']>;
    last_name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    picture_url?: Maybe<Scalars['String']>;
};

export type ContactReferenceTeam = {
    __typename?: 'ContactReferenceTeam';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type ContactRequest = {
    __typename?: 'ContactRequest';
    id: Scalars['ID'];
    message?: Maybe<Scalars['String']>;
    source?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['DateTime']>;
    preference?: Maybe<BuyerPreference>;
};

export type ContactRequestConnection = {
    __typename?: 'ContactRequestConnection';
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
    edges: Array<ContactRequestConnectionEdge>;
    nodes: Array<ContactRequest>;
};

export type ContactRequestConnectionEdge = {
    __typename?: 'ContactRequestConnectionEdge';
    node?: Maybe<ContactRequest>;
};

export type ContactsOptions = {
    __typename?: 'ContactsOptions';
    sorts: Array<ContactsSortOption>;
};

export type ContactsSortOption = {
    __typename?: 'ContactsSortOption';
    key: Scalars['String'];
};

export type ContactStats = {
    __typename?: 'ContactStats';
    numberOfPendingOffers: Scalars['Int'];
    closestOfferExpirations: Array<Scalars['DateTime']>;
    hasAcceptedOffer: Scalars['Boolean'];
    highestOfferBid: Scalars['Float'];
};

export type ContactStatsFilter = {
    contactId: Scalars['ID'];
};

export type ContactTimeline = {
    __typename?: 'ContactTimeline';
    activities: ActivityConnection;
};

export type ContactTimelineActivitiesArgs = {
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    propertyId?: Maybe<Scalars['String']>;
};

export enum ContactType {
    Lead = 'LEAD',
    Vendor = 'VENDOR',
}

export type CreateOfferInput = {
    parentId?: Maybe<Scalars['String']>;
    financialDetails: FinancialDetailsInput;
    validUntil?: Maybe<Scalars['DateTime']>;
    buyers: Array<Scalars['ID']>;
    owners: Array<Scalars['ID']>;
    propertyId: Scalars['ID'];
    notes?: Maybe<Scalars['String']>;
};

/** Represents an error in the input of a mutation. */
export type DisplayableError = {
    /** Path to the input field which caused the error */
    field?: Maybe<Array<Scalars['String']>>;
    /** The error message */
    message: Scalars['String'];
};

export type DuplicateEstateInput = {
    /** the id of the state to duplicate */
    estateId: Scalars['ID'];
};

export type DuplicateEstatePayload = {
    __typename?: 'DuplicateEstatePayload';
    /** the duplicated estate */
    estate?: Maybe<Estate>;
};

export type Estate = {
    __typename?: 'Estate';
    id: Scalars['ID'];
    internalType: EstateType;
    publications: EstatePublications;
    channelAccount?: Maybe<EstateChannelAccountConnectionEdge>;
    isProject: Scalars['Boolean'];
    isUnit: Scalars['Boolean'];
    projectId?: Maybe<Scalars['String']>;
    status: Scalars['String'];
    type: Scalars['String'];
    negotiation: Scalars['String'];
    rentPeriod?: Maybe<Scalars['String']>;
    archived: Scalars['Boolean'];
    office_id?: Maybe<Scalars['ID']>;
    /** The timeline of activities related with this property */
    timeline: PropertyTimeline;
    attributes: Scalars['JSON'];
    /** A list of contacts that interacted with this property */
    interactedContacts: EstateContactConnection;
    /** A list of contacts available for scheduling a visit with this property */
    visitors: EstateContactConnection;
    /** The list of buyers for the estate */
    buyers: EstateContactConnection;
    /** A list of vendors for the estate */
    vendors: EstateContactConnection;
    /** The negotiator of this property */
    negotiator?: Maybe<User>;
    /** The last interaction with this property */
    lastInteraction?: Maybe<Scalars['JSON']>;
    /** A timestamp of date the estate was created */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** A timestamp of time the estate was updated */
    updatedAt?: Maybe<Scalars['DateTime']>;
    /** A timestamp of time the estate was published */
    first_publication_date?: Maybe<Scalars['DateTime']>;
    /** Public url of the property */
    publicURL?: Maybe<Scalars['String']>;
    /** The project units */
    units?: Maybe<UnitsConnection>;
    /** A unit in the project */
    unit?: Maybe<Estate>;
    visibility?: Maybe<Scalars['String']>;
    pendingRequests?: Maybe<PendingRequestsConnection>;
    matches?: Maybe<EstateMatchesConnection>;
    offers: Array<Offer>;
    error_publications_count?: Maybe<Scalars['Int']>;
    successful_publications_count?: Maybe<Scalars['Int']>;
    offersStats?: Maybe<OffersStats>;
    legalEntity?: Maybe<Scalars['JSON']>;
    buyer_ids?: Maybe<Array<Scalars['ID']>>;
    owner_ids?: Maybe<Array<Scalars['ID']>>;
    similarProperties?: Maybe<Scalars['JSON']>;
};

export type EstateChannelAccountArgs = {
    id: Scalars['ID'];
    lastModified?: Maybe<Scalars['String']>;
};

export type EstateInteractedContactsArgs = {
    interestLevel?: Maybe<Scalars['String']>;
    type: ContactType;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

export type EstateVisitorsArgs = {
    type?: Maybe<Scalars['String']>;
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
};

export type EstateUnitsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    archived?: Maybe<Scalars['Boolean']>;
};

export type EstateUnitArgs = {
    id: Scalars['ID'];
};

export type EstatePendingRequestsArgs = {
    type?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
};

export type EstateMatchesArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    filters?: Maybe<EstateMatchesFilters>;
};

export type EstateOffersArgs = {
    archived: Scalars['Boolean'];
};

export type EstateAgentFeedsPublicationConfig = EstatePublicationConfig & {
    __typename?: 'EstateAgentFeedsPublicationConfig';
    showPrice?: Maybe<Scalars['Boolean']>;
    showLocation?: Maybe<Scalars['Boolean']>;
    priceType?: Maybe<Scalars['String']>;
    features?: Maybe<Array<Scalars['String']>>;
};

export type EstateAgentFeedsPublicationSettings = AccountPublicationSettings & {
    __typename?: 'EstateAgentFeedsPublicationSettings';
    publishPrice?: Maybe<Scalars['Boolean']>;
    publishLocation?: Maybe<Scalars['Boolean']>;
    priceType?: Maybe<Scalars['String']>;
    features?: Maybe<Array<Scalars['String']>>;
};

export type EstateAggregations = {
    __typename?: 'EstateAggregations';
    price: PriceAggregation;
};

export type EstateChannelAccountConnection = {
    __typename?: 'EstateChannelAccountConnection';
    edges: Array<EstateChannelAccountConnectionEdge>;
    pageInfo: PageInfo;
};

export type EstateChannelAccountConnectionEdge = {
    __typename?: 'EstateChannelAccountConnectionEdge';
    id: Scalars['ID'];
    node: ChannelAccount;
    configurationStatus: ConfigurationStatus;
    configurationStatusMessageI18Key?: Maybe<Scalars['String']>;
    publicationStatus: PublicationStatus;
    lastPublication?: Maybe<EstatePublication>;
    missingFields: MissingFieldsEdge;
    unitsMissingFields: MissingFieldsConnection;
    canPublish: Scalars['Boolean'];
    /** Whether the estate has meaninfull changes since the last publication */
    outdated: Scalars['Boolean'];
};

export type EstateConfigForChannelAccount = {
    __typename?: 'EstateConfigForChannelAccount';
    id: Scalars['ID'];
    enabled: Scalars['Boolean'];
    missingFields: Array<Scalars['String']>;
    unitsMissingFields: Array<OldUnitsMissingFields>;
    canPublishProject: Scalars['Boolean'];
    configurationStatus: OldConfigurationStatus;
    publicationStatus: OldPublicationStatus;
    settings?: Maybe<AccountPublicationSettings>;
    account: ChannelAccount;
    lastPublication?: Maybe<EstatePublication>;
    /** Whether there are changes to be published since the last publication */
    outdated: Scalars['Boolean'];
};

export type EstateConnectionEdge = {
    __typename?: 'EstateConnectionEdge';
    node: Estate;
};

export type EstateContactConnection = {
    __typename?: 'EstateContactConnection';
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
    edges: Array<EstateContactEdge>;
    nodes: Array<Contact>;
};

export type EstateContactEdge = {
    __typename?: 'EstateContactEdge';
    node: Contact;
    /** Last interaction between the contact and the propery */
    lastInteraction?: Maybe<Scalars['JSON']>;
};

export type EstateFilters = {
    officeId?: Maybe<Scalars['ID']>;
    ownership?: Maybe<Scalars['ID']>;
    minArea?: Maybe<Scalars['Float']>;
    maxArea?: Maybe<Scalars['Float']>;
    type?: Maybe<Array<Scalars['String']>>;
    maxPrice?: Maybe<Scalars['Float']>;
    minPrice?: Maybe<Scalars['Float']>;
    min_floors?: Maybe<Scalars['Int']>;
    max_floors?: Maybe<Scalars['Int']>;
    areaType?: Maybe<Scalars['String']>;
    status?: Maybe<Array<Scalars['String']>>;
    min_bedrooms?: Maybe<Scalars['Int']>;
    max_bedrooms?: Maybe<Scalars['Int']>;
    archived?: Maybe<Scalars['Boolean']>;
    sortField?: Maybe<Scalars['String']>;
    sortOrder?: Maybe<Scalars['String']>;
    subtypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    geo_filter?: Maybe<Array<Maybe<Scalars['JSON']>>>;
    features?: Maybe<Array<Maybe<Scalars['String']>>>;
    amenities?: Maybe<Array<Maybe<Scalars['String']>>>;
    negotiation?: Maybe<Scalars['String']>;
    min_net_area?: Maybe<Scalars['Float']>;
    max_net_area?: Maybe<Scalars['Float']>;
    condition?: Maybe<Array<Scalars['String']>>;
    visibility?: Maybe<Array<Maybe<Scalars['String']>>>;
    min_plot_area?: Maybe<Scalars['Float']>;
    max_plot_area?: Maybe<Scalars['Float']>;
    propertyType?: Maybe<Scalars['String']>;
    min_gross_area?: Maybe<Scalars['Float']>;
    max_gross_area?: Maybe<Scalars['Float']>;
    negotiator_ids?: Maybe<Array<Scalars['ID']>>;
    min_living_rooms?: Maybe<Scalars['Int']>;
    max_living_rooms?: Maybe<Scalars['Int']>;
    propertyState?: Maybe<Scalars['String']>;
    min_address_floor?: Maybe<Scalars['Int']>;
    max_address_floor?: Maybe<Scalars['Int']>;
    postal_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
    legalEntity?: Maybe<Array<Scalars['String']>>;
    noInterestContactId?: Maybe<Scalars['ID']>;
    min_liveable_area?: Maybe<Scalars['Float']>;
    max_liveable_area?: Maybe<Scalars['Float']>;
    min_loi_carrez_area?: Maybe<Scalars['Float']>;
    max_loi_carrez_area?: Maybe<Scalars['Float']>;
    offerStatus?: Maybe<FilterOfferStatus>;
    property_internal_type?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EstateMatchesConnection = {
    __typename?: 'EstateMatchesConnection';
    edges: Array<EstateMatchesConnectionEdge>;
    nodes: Array<Contact>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
    mailableCount: Scalars['Int'];
};

export type EstateMatchesConnectionEdge = {
    __typename?: 'EstateMatchesConnectionEdge';
    id: Scalars['ID'];
    node: Contact;
    flags: Scalars['JSON'];
};

export type EstateMatchesFilters = {
    bright?: Maybe<Scalars['Boolean']>;
};

export type EstatePublication = {
    __typename?: 'EstatePublication';
    id: Scalars['ID'];
    publishedAt?: Maybe<Scalars['DateTime']>;
    link?: Maybe<Scalars['String']>;
    publishedBy?: Maybe<User>;
    config?: Maybe<EstatePublicationConfig>;
    errors?: Maybe<EstatePublicationErrors>;
    warnings?: Maybe<EstatePublicationWarnings>;
};

export type EstatePublicationConfig = {
    showPrice?: Maybe<Scalars['Boolean']>;
    showLocation?: Maybe<Scalars['Boolean']>;
};

export type EstatePublicationError = {
    __typename?: 'EstatePublicationError';
    message: Scalars['String'];
    translationKey?: Maybe<Scalars['String']>;
    code?: Maybe<Scalars['String']>;
    context?: Maybe<PublicationErrorContext>;
};

export type EstatePublicationErrors = {
    __typename?: 'EstatePublicationErrors';
    self?: Maybe<Array<Maybe<EstatePublicationError>>>;
    units?: Maybe<Array<UnitPublicationErrors>>;
};

export type EstatePublications = {
    __typename?: 'EstatePublications';
    id: Scalars['ID'];
    channelAccounts: EstateChannelAccountConnection;
};

export type EstatePublicationsChannelAccountsArgs = {
    isAdStatus?: Maybe<Scalars['Boolean']>;
    status?: Maybe<Array<Maybe<Scalars['String']>>>;
    isStatusEnabled?: Maybe<Scalars['Boolean']>;
};

export type EstatePublicationSettingsInput = {
    showPrice: Scalars['Boolean'];
    showLocation: Scalars['Boolean'];
    channelAccounts: Array<Scalars['String']>;
};

export type EstatePublicationSettingsResponse = {
    __typename?: 'EstatePublicationSettingsResponse';
    estateChannelAccounts: Array<EstateConfigForChannelAccount>;
};

export type EstatePublicationWarning = {
    __typename?: 'EstatePublicationWarning';
    message: Scalars['String'];
    translationKey?: Maybe<Scalars['String']>;
    code?: Maybe<Scalars['String']>;
    context?: Maybe<PublicationWarningContext>;
};

export type EstatePublicationWarnings = {
    __typename?: 'EstatePublicationWarnings';
    self?: Maybe<Array<Maybe<EstatePublicationWarning>>>;
    units?: Maybe<Array<UnitPublicationWarnings>>;
};

export type EstatesConnection = {
    __typename?: 'EstatesConnection';
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
    nodes: Array<Estate>;
    edges: Array<EstateConnectionEdge>;
};

export type EstatesOptions = {
    __typename?: 'EstatesOptions';
    sorts: Array<EstatesSortOption>;
};

export type EstatesSortOption = {
    __typename?: 'EstatesSortOption';
    key: Scalars['String'];
};

export enum EstateType {
    Project = 'PROJECT',
    Unit = 'UNIT',
    Standalone = 'STANDALONE',
}

export type FacebookPublicationConfig = EstatePublicationConfig & {
    __typename?: 'FacebookPublicationConfig';
    showPrice?: Maybe<Scalars['Boolean']>;
    showLocation?: Maybe<Scalars['Boolean']>;
    post?: Maybe<Scalars['String']>;
};

export type FacebookPublicationSettings = AccountPublicationSettings & {
    __typename?: 'FacebookPublicationSettings';
    publishPrice?: Maybe<Scalars['Boolean']>;
    publishLocation?: Maybe<Scalars['Boolean']>;
    postText?: Maybe<Scalars['String']>;
    images: Array<Scalars['String']>;
};

export type FacebookPublicationSettingsInput = {
    postText: Scalars['String'];
    images: Array<Scalars['String']>;
};

export type FeedbackActivityDetails = ActivityDetails & {
    __typename?: 'FeedbackActivityDetails';
    id: Scalars['ID'];
    type: Scalars['String'];
    externalComment?: Maybe<Scalars['String']>;
    internalComment?: Maybe<Scalars['String']>;
};

export enum FilterOfferStatus {
    Pending = 'pending',
    Accepted = 'accepted',
}

export type FinancialDetails = {
    __typename?: 'FinancialDetails';
    direction: OfferDirection;
    currency: Scalars['Currency'];
    buyerFixedFee: Scalars['Float'];
    ownerFixedFee: Scalars['Float'];
    buyerPercentageFee: Scalars['Float'];
    ownerPercentageFee: Scalars['Float'];
    buyerGrossAmount: Scalars['Float'];
    ownerNetAmount: Scalars['Float'];
    buyerTotalFee: Scalars['Float'];
    ownerTotalFee: Scalars['Float'];
    agencyTotalFee: Scalars['Float'];
    transactionAmount: Scalars['Float'];
};

export type FinancialDetailsInput = {
    direction: OfferDirection;
    currency: Scalars['Currency'];
    buyerFixedFee: Scalars['Float'];
    buyerPercentageFee: Scalars['Float'];
    ownerFixedFee: Scalars['Float'];
    ownerPercentageFee: Scalars['Float'];
    buyerGrossAmount?: Maybe<Scalars['Float']>;
    ownerNetAmount?: Maybe<Scalars['Float']>;
};

export type FrenchPortal = {
    __typename?: 'FrenchPortal';
    automatic?: Maybe<Scalars['Boolean']>;
    code?: Maybe<Scalars['String']>;
    free?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['Int']>;
    logoUrl?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export type FrenchPortalPublicationConfig = EstatePublicationConfig & {
    __typename?: 'FrenchPortalPublicationConfig';
    showPrice?: Maybe<Scalars['Boolean']>;
    showLocation?: Maybe<Scalars['Boolean']>;
    portals?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FrenchPortalPublicationSettings = AccountPublicationSettings & {
    __typename?: 'FrenchPortalPublicationSettings';
    publishPrice?: Maybe<Scalars['Boolean']>;
    publishLocation?: Maybe<Scalars['Boolean']>;
    portals?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GenerateDescriptionInput = {
    extraText?: Maybe<Scalars['String']>;
    inputLanguage: Scalars['String'];
    maxOutputLength?: Maybe<Scalars['Int']>;
    outputLanguage: Scalars['String'];
    propertyId: Scalars['ID'];
};

export type GenerateDescriptionPayload = {
    __typename?: 'GenerateDescriptionPayload';
    propertyId: Scalars['ID'];
    text: Scalars['String'];
};

export type GenericAccountPublicationSettings = AccountPublicationSettings & {
    __typename?: 'GenericAccountPublicationSettings';
    publishPrice?: Maybe<Scalars['Boolean']>;
    publishLocation?: Maybe<Scalars['Boolean']>;
};

export type GenericPublicationConfig = EstatePublicationConfig & {
    __typename?: 'GenericPublicationConfig';
    showPrice?: Maybe<Scalars['Boolean']>;
    showLocation?: Maybe<Scalars['Boolean']>;
};

export type GeoJsonData = {
    __typename?: 'GeoJSONData';
    type: Scalars['String'];
    coordinates: Array<Array<Array<Scalars['Float']>>>;
};

export type GeoJsonDataInput = {
    type: Scalars['String'];
    coordinates: Array<Array<Array<Scalars['Float']>>>;
};

export type GeoJsonLocationPreference = LocationPreference & {
    __typename?: 'GeoJSONLocationPreference';
    id: Scalars['ID'];
    type: Scalars['String'];
    name: Scalars['String'];
    data: GeoJsonData;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type GeoTemplate = {
    __typename?: 'GeoTemplate';
    _id?: Maybe<Scalars['ID']>;
    title: Scalars['String'];
    office_id?: Maybe<Scalars['ID']>;
    type?: Maybe<Scalars['String']>;
    postalCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    geometry?: Maybe<Scalars['JSON']>;
};

export type GeoTemplatesDeletePayload = {
    __typename?: 'GeoTemplatesDeletePayload';
    _id?: Maybe<Scalars['ID']>;
};

export type GeoTemplatesInput = {
    _id?: Maybe<Scalars['ID']>;
    title: Scalars['String'];
    office_id?: Maybe<Scalars['ID']>;
    type?: Maybe<Scalars['String']>;
    postalCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    geometry?: Maybe<Scalars['JSON']>;
};

export type GeoTemplatesPagination = {
    __typename?: 'GeoTemplatesPagination';
    current_page?: Maybe<Scalars['Int']>;
    total_pages?: Maybe<Scalars['Int']>;
    total?: Maybe<Scalars['Int']>;
    per_page?: Maybe<Scalars['Int']>;
};

export type GeoTemplatesPayload = {
    __typename?: 'GeoTemplatesPayload';
    data: Array<GeoTemplate>;
    pagination: GeoTemplatesPagination;
};

export type GetFrenchPortalsInput = {
    channelAccountId: Scalars['ID'];
};

export type GetFrenchPortalsPayload = {
    __typename?: 'GetFrenchPortalsPayload';
    portals: Array<FrenchPortal>;
};

export type HistogramBin = {
    __typename?: 'HistogramBin';
    from: Scalars['Float'];
    to: Scalars['Float'];
    count: Scalars['Int'];
};

export type ImmowebPublicationConfig = EstatePublicationConfig & {
    __typename?: 'ImmowebPublicationConfig';
    showPrice?: Maybe<Scalars['Boolean']>;
    showLocation?: Maybe<Scalars['Boolean']>;
    adSize?: Maybe<Scalars['String']>;
};

export type ImmowebPublicationSettings = AccountPublicationSettings & {
    __typename?: 'ImmowebPublicationSettings';
    publishPrice?: Maybe<Scalars['Boolean']>;
    publishLocation?: Maybe<Scalars['Boolean']>;
    adSize?: Maybe<Scalars['String']>;
};

export type ImmowebPublicationSettingsInput = {
    adSize: Scalars['String'];
};

export type InviteInput = {
    language?: Maybe<Scalars['String']>;
    invites: Array<InviteItemInput>;
};

export type InviteItemInput = {
    email: Scalars['String'];
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
};

export type Label = {
    __typename?: 'Label';
    id: Scalars['ID'];
    name: Scalars['String'];
    color: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    created_at?: Maybe<Scalars['String']>;
    updated_at?: Maybe<Scalars['String']>;
};

export type LabelsInput = {
    id?: Maybe<Scalars['ID']>;
    name: Scalars['String'];
    color: Scalars['String'];
    description?: Maybe<Scalars['String']>;
};

export type LabelsMeta = {
    __typename?: 'LabelsMeta';
    pagination: LabelsPagination;
};

export type LabelsPagination = {
    __typename?: 'LabelsPagination';
    current_page?: Maybe<Scalars['Int']>;
    total_pages?: Maybe<Scalars['Int']>;
    total?: Maybe<Scalars['Int']>;
    per_page?: Maybe<Scalars['Int']>;
};

export type LabelsPayload = {
    __typename?: 'LabelsPayload';
    data: Array<Label>;
    meta?: Maybe<LabelsMeta>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type Lead = Contact & {
    __typename?: 'Lead';
    id: Scalars['ID'];
    /** The first name of the contact */
    firstName: Scalars['String'];
    /** The last name of the contact */
    lastName: Scalars['String'];
    /** The email address of the contact */
    email?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    officeId?: Maybe<Scalars['String']>;
    locale?: Maybe<Scalars['String']>;
    pronouns?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    notes: Array<Note>;
    subscribed?: Maybe<Scalars['Boolean']>;
    type: ContactType;
    photo?: Maybe<ContactPhoto>;
    address: Scalars['JSON'];
    /** The lead preferences */
    allPreferences: BuyerPreferencesConnection;
    preference?: Maybe<BuyerPreference>;
    timeline: ContactTimeline;
    interactedProperties: EstatesConnection;
    /** The list of properties that the contact can interact with */
    eligibleEstates: EstatesConnection;
    /** The last action related with this lead (created, updated, interacted) */
    lastAction?: Maybe<Scalars['String']>;
    /** The last interaction with this contact */
    lastInteraction?: Maybe<Scalars['JSON']>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    archived: Scalars['Boolean'];
    assignedNegotiators?: Maybe<Array<ContactAssignedNegotiator>>;
    offers: Array<Offer>;
    offersStats?: Maybe<OffersStats>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadPhoneArgs = {
    format?: Maybe<PhoneNumberFormat>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadAllPreferencesArgs = {
    page?: Maybe<Scalars['Int']>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadPreferenceArgs = {
    id: Scalars['ID'];
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadInteractedPropertiesArgs = {
    interestLevel?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadEligibleEstatesArgs = {
    query?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadOffersArgs = {
    archived: Scalars['Boolean'];
};

export type LeadExpressedInterestActivity = Activity & {
    __typename?: 'LeadExpressedInterestActivity';
    id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    timestamp?: Maybe<Scalars['DateTime']>;
    interestedLead?: Maybe<Contact>;
    propertyInterestedIn?: Maybe<Estate>;
    interestExpressedVia?: Maybe<LeadSource>;
    details?: Maybe<MessageActivityDetails>;
    action?: Maybe<ActivityAction>;
};

export type LeadSource = {
    __typename?: 'LeadSource';
    name: Scalars['String'];
};

export type LegalEntities = {
    __typename?: 'LegalEntities';
    id?: Maybe<Scalars['ID']>;
    name: Scalars['String'];
    office_id?: Maybe<Scalars['ID']>;
};

export type LegalEntitiesConnection = {
    __typename?: 'LegalEntitiesConnection';
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
    edges: Array<LegalEntitiesEdge>;
    nodes: Array<LegalEntities>;
};

export type LegalEntitiesDeletePayload = {
    __typename?: 'LegalEntitiesDeletePayload';
    id?: Maybe<Scalars['ID']>;
};

export type LegalEntitiesEdge = {
    __typename?: 'LegalEntitiesEdge';
    node: LegalEntities;
};

export type LegalEntitiesInput = {
    id?: Maybe<Scalars['ID']>;
    name: Scalars['String'];
    office_id?: Maybe<Scalars['ID']>;
};

export type LegalEntity = {
    __typename?: 'LegalEntity';
    data?: Maybe<LegalEntities>;
};

export type LocalizedText = {
    __typename?: 'LocalizedText';
    locale: Scalars['String'];
    value?: Maybe<Scalars['String']>;
};

export type LocationPreference = {
    id: Scalars['ID'];
    type: Scalars['String'];
    name: Scalars['String'];
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type LocationPreferenceConnection = {
    __typename?: 'LocationPreferenceConnection';
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
    edges: Array<LocationPreferenceEdge>;
    nodes: Array<LocationPreference>;
};

export type LocationPreferenceEdge = {
    __typename?: 'LocationPreferenceEdge';
    node: LocationPreference;
};

export type LocationPreferenceInput = {
    name: Scalars['String'];
    type: Scalars['String'];
};

export type MatchingConfigInput = {
    id: Scalars['ID'];
    type: Scalars['String'];
    searchParams: Scalars['JSON'];
};

export type MatchingConfigPayload = {
    __typename?: 'MatchingConfigPayload';
    data?: Maybe<Scalars['JSON']>;
};

export type MatchingEmailsInput = {
    contents: Scalars['JSON'];
    operation: Scalars['String'];
    contactIds?: Maybe<Array<Scalars['ID']>>;
    propertyIds?: Maybe<Array<Scalars['ID']>>;
    searchParams: Scalars['JSON'];
};

export type MatchingEmailsPayload = {
    __typename?: 'MatchingEmailsPayload';
    data?: Maybe<Scalars['JSON']>;
};

export type MatchingFlagsInput = {
    contactIds: Array<Scalars['ID']>;
    propertyIds: Array<Scalars['ID']>;
};

export type MatchingFlagsPayload = {
    __typename?: 'MatchingFlagsPayload';
    data?: Maybe<Array<Maybe<MatchingFlagsPayloadData>>>;
};

export type MatchingFlagsPayloadData = {
    __typename?: 'MatchingFlagsPayloadData';
    contactId?: Maybe<Scalars['ID']>;
    propertyId?: Maybe<Scalars['ID']>;
    flags?: Maybe<Scalars['JSON']>;
};

export type MatchingRequirementsInput = {
    id: Scalars['ID'];
    type: Scalars['String'];
};

export type MatchingRequirementsPayload = {
    __typename?: 'MatchingRequirementsPayload';
    data?: Maybe<MatchingRequirementsPayloadData>;
};

export type MatchingRequirementsPayloadData = {
    __typename?: 'MatchingRequirementsPayloadData';
    contactId?: Maybe<Scalars['ID']>;
    propertyId?: Maybe<Scalars['ID']>;
    preferenceId?: Maybe<Scalars['ID']>;
    isMatchable?: Maybe<Scalars['Boolean']>;
    errors?: Maybe<Scalars['JSON']>;
};

export type MessageActivityDetails = ActivityDetails & {
    __typename?: 'MessageActivityDetails';
    id: Scalars['ID'];
    message?: Maybe<Scalars['String']>;
};

export type MissingField = {
    __typename?: 'MissingField';
    path: Scalars['String'];
    errorMessage: Scalars['String'];
};

export type MissingFieldsConnection = {
    __typename?: 'MissingFieldsConnection';
    edges: Array<MissingFieldsEdge>;
};

export type MissingFieldsEdge = {
    __typename?: 'MissingFieldsEdge';
    id: Scalars['ID'];
    estateId: Scalars['ID'];
    node: Array<MissingField>;
};

export type Mutation = {
    __typename?: 'Mutation';
    saveChannelAccount?: Maybe<ChannelAccount>;
    removeChannelAccount: Scalars['ID'];
    saveFacebookPublicationSettingsForEstate: EstateConfigForChannelAccount;
    saveTwitterPublicationSettingsForEstate: EstateConfigForChannelAccount;
    saveImmowebPublicationSettingsForEstate: EstateConfigForChannelAccount;
    createPublication: PublishPropertyPayload;
    removePublication: PublishPropertyPayload;
    updatePublication: PublishPropertyPayload;
    retryPublication: RetryPublicationPayload;
    refreshPublication: RefreshPublicationPayload;
    getFrenchPortals: GetFrenchPortalsPayload;
    saveEstatePublicationSettings: EstatePublicationSettingsResponse;
    addEstate: AddEstatePayload;
    updateEstate: UpdateEstatePayload;
    archiveEstate: ArchiveEstatePayload;
    updateEstateStatus?: Maybe<Estate>;
    unarchiveEstate: UnarchiveEstatePayload;
    duplicateEstate: DuplicateEstatePayload;
    registerEstateActivity: RegisterEstateActivityPayload;
    assignEstatesToNegotiator: AssignEstatesToNegotiatorPayload;
    assignVendorsToEstate: AssignVendorsToEstatePayload;
    unassignVendorsToEstate: UnassignVendorsToEstatePayload;
    assignBuyersToEstate: AssignBuyersToEstatePayload;
    unassignBuyersToEstate: UnassignBuyersToEstatePayload;
    assignLegalEntity?: Maybe<AssignLegalEntityToPayload>;
    unassignLegalEntity?: Maybe<UnassignLegalEntityToPayload>;
    addUnitToProject: AddUnitToProjectPayload;
    generateDescription: GenerateDescriptionPayload;
    translate: TranslatePayload;
    updatePropertyVisibility?: Maybe<UpdatePropertyVisibilityPayload>;
    addLocationPreference: AddLocationPreferencePayload;
    removeLocationPreference: RemoveLocationPayload;
    updateLocationPreference: UpdateLocationPreferencePayload;
    /** Add a location template to an office */
    addLocationPreferenceTemplate: AddLocationTemplatePayload;
    /** Remove a location template from an office */
    removeLocationPreferenceTemplate: RemoveLocationTemplatePayload;
    /** Update an existing office location template */
    updateLocationPreferenceTemplate: UpdateLocationTemplatePayload;
    /** Clone an existing office location template into a lead location preferences */
    cloneLocationPreferenceTemplate: CloneLocationTemplatePayload;
    updateUser?: Maybe<UpdateUserPayload>;
    updateUserOffice: User;
    addIntegration: AddIntegrationPayload;
    removeIntegration: RemoveIntegrationPayload;
    inviteUsers: Scalars['Boolean'];
    updateUserRole: Scalars['Boolean'];
    updateUserTeams: Scalars['Boolean'];
    archiveUser: Scalars['Boolean'];
    authenticate: AuthenticatePayload;
    updateLeadPreference: UpdateLeadPreferencePayload;
    addLeadPreference: AddLeadPreferencePayload;
    removeLeadPreference: RemoveLeadPreferencePayload;
    assignLeadsToOffice: AssignLeadsPayload;
    archiveLeads: ArchiveLeadsPayload;
    createLabel: Label;
    updateLabel: Label;
    deleteLabel?: Maybe<Scalars['JSON']>;
    addContact: AddContactMutationPayload;
    updateContactDetails: UpdateContactMutationPayload;
    updateContactAddress: UpdateContactMutationPayload;
    archiveContact: UpdateContactMutationPayload;
    unarchiveContact: UpdateContactMutationPayload;
    updateContactLabels?: Maybe<Scalars['JSON']>;
    updateContactAssignments: UpdateContactAssignmentsPayload;
    sendVendorReport: SendVendorReportPayload;
    scheduleVisit: ScheduleVisitPayload;
    updateVisitStatus: UpdateVisitStatusPayload;
    createOffer: Offer;
    updateOffer: Offer;
    archiveOffer: Offer;
    restoreOffer: Offer;
    acceptOffer: Offer;
    refuseOffer: Offer;
    cancelOffer: Offer;
    addLegalEntity: LegalEntities;
    updateLegalEntity: LegalEntities;
    deleteLegalEntity?: Maybe<LegalEntitiesDeletePayload>;
    addGeoTemplate: GeoTemplate;
    updateGeoTemplate: GeoTemplate;
    deleteGeoTemplate?: Maybe<GeoTemplatesDeletePayload>;
    matchingEmails: MatchingEmailsPayload;
};

export type MutationSaveChannelAccountArgs = {
    companyId: Scalars['String'];
    input: ChannelAccountInput;
};

export type MutationRemoveChannelAccountArgs = {
    companyId: Scalars['String'];
    accountId: Scalars['String'];
};

export type MutationSaveFacebookPublicationSettingsForEstateArgs = {
    estateId?: Maybe<Scalars['String']>;
    estateChannelAccountId?: Maybe<Scalars['String']>;
    input?: Maybe<FacebookPublicationSettingsInput>;
};

export type MutationSaveTwitterPublicationSettingsForEstateArgs = {
    estateId?: Maybe<Scalars['String']>;
    estateChannelAccountId?: Maybe<Scalars['String']>;
    input?: Maybe<TwitterPublicationSettingsInput>;
};

export type MutationSaveImmowebPublicationSettingsForEstateArgs = {
    estateId?: Maybe<Scalars['String']>;
    estateChannelAccountId?: Maybe<Scalars['String']>;
    input?: Maybe<ImmowebPublicationSettingsInput>;
};

export type MutationCreatePublicationArgs = {
    input: PublishPropertyInput;
};

export type MutationRemovePublicationArgs = {
    input: UnpublishPropertyInput;
};

export type MutationUpdatePublicationArgs = {
    input: PublishPropertyInput;
};

export type MutationRetryPublicationArgs = {
    input: RetryPublicationInput;
};

export type MutationRefreshPublicationArgs = {
    input: RefreshPublicationInput;
};

export type MutationGetFrenchPortalsArgs = {
    input: GetFrenchPortalsInput;
};

export type MutationSaveEstatePublicationSettingsArgs = {
    estateId?: Maybe<Scalars['String']>;
    input: EstatePublicationSettingsInput;
};

export type MutationAddEstateArgs = {
    input: AddEstateInput;
};

export type MutationUpdateEstateArgs = {
    estateId: Scalars['ID'];
    estateType: EstateType;
    operations: Scalars['JSON'];
};

export type MutationArchiveEstateArgs = {
    input: ArchiveEstateInput;
};

export type MutationUpdateEstateStatusArgs = {
    estateId: Scalars['ID'];
    status?: Maybe<Scalars['String']>;
};

export type MutationUnarchiveEstateArgs = {
    input: UnarchiveEstateInput;
};

export type MutationDuplicateEstateArgs = {
    input: DuplicateEstateInput;
};

export type MutationRegisterEstateActivityArgs = {
    input: RegisterEstateActivityInput;
};

export type MutationAssignEstatesToNegotiatorArgs = {
    input: AssignEstatesToNegotiatorInput;
};

export type MutationAssignVendorsToEstateArgs = {
    input: AssignVendorsToEstateInput;
};

export type MutationUnassignVendorsToEstateArgs = {
    input: UnassignVendorsToEstateInput;
};

export type MutationAssignBuyersToEstateArgs = {
    input: AssignBuyersToEstateInput;
};

export type MutationUnassignBuyersToEstateArgs = {
    input: UnassignBuyersToEstateInput;
};

export type MutationAssignLegalEntityArgs = {
    input: AssignLegalEntityToEstateInput;
};

export type MutationUnassignLegalEntityArgs = {
    input: UnassignLegalEntityToEstateInput;
};

export type MutationAddUnitToProjectArgs = {
    input: AddUnitToProjectInput;
};

export type MutationGenerateDescriptionArgs = {
    generateInput: GenerateDescriptionInput;
};

export type MutationTranslateArgs = {
    translateInput: TranslateInput;
};

export type MutationUpdatePropertyVisibilityArgs = {
    propertyId: Scalars['ID'];
    visibility: Scalars['String'];
};

export type MutationAddLocationPreferenceArgs = {
    input: AddLocationPreferenceInput;
};

export type MutationRemoveLocationPreferenceArgs = {
    leadId: Scalars['ID'];
    locationPreferenceId: Scalars['ID'];
    preferenceId?: Maybe<Scalars['ID']>;
};

export type MutationUpdateLocationPreferenceArgs = {
    leadId: Scalars['ID'];
    locationPreferenceId: Scalars['ID'];
    preference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
    preferenceId?: Maybe<Scalars['ID']>;
};

export type MutationAddLocationPreferenceTemplateArgs = {
    officeId: Scalars['ID'];
    preference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
};

export type MutationRemoveLocationPreferenceTemplateArgs = {
    officeId: Scalars['ID'];
    locationId: Scalars['ID'];
};

export type MutationUpdateLocationPreferenceTemplateArgs = {
    officeId: Scalars['ID'];
    locationId: Scalars['ID'];
    preference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
};

export type MutationCloneLocationPreferenceTemplateArgs = {
    input: CloneLocationPreferenceTemplateInput;
};

export type MutationUpdateUserArgs = {
    input: UpdateUserInput;
};

export type MutationUpdateUserOfficeArgs = {
    officeId: Scalars['ID'];
};

export type MutationAddIntegrationArgs = {
    input: AddIntegrationInput;
};

export type MutationRemoveIntegrationArgs = {
    input: RemoveIntegrationInput;
};

export type MutationInviteUsersArgs = {
    invite: InviteInput;
    officeId?: Maybe<Scalars['ID']>;
    companyId?: Maybe<Scalars['ID']>;
};

export type MutationUpdateUserRoleArgs = {
    officeId: Scalars['ID'];
    userId: Scalars['ID'];
    role: UserRole;
};

export type MutationUpdateUserTeamsArgs = {
    userId: Scalars['ID'];
    teamsIds: Array<Scalars['ID']>;
};

export type MutationArchiveUserArgs = {
    userId: Scalars['ID'];
};

export type MutationAuthenticateArgs = {
    code: Scalars['String'];
};

export type MutationUpdateLeadPreferenceArgs = {
    input: UpdateLeadPreferenceInput;
};

export type MutationAddLeadPreferenceArgs = {
    input: AddLeadPreferenceInput;
};

export type MutationRemoveLeadPreferenceArgs = {
    input: RemoveLeadPreferenceInput;
};

export type MutationAssignLeadsToOfficeArgs = {
    input: AssignLeadsInput;
};

export type MutationArchiveLeadsArgs = {
    input: ArchiveLeadsInput;
};

export type MutationCreateLabelArgs = {
    input?: Maybe<LabelsInput>;
};

export type MutationUpdateLabelArgs = {
    input?: Maybe<LabelsInput>;
};

export type MutationDeleteLabelArgs = {
    id: Scalars['ID'];
};

export type MutationAddContactArgs = {
    input: AddContactMutationInput;
};

export type MutationUpdateContactDetailsArgs = {
    input: UpdateContactMutationInput;
};

export type MutationUpdateContactAddressArgs = {
    input: UpdateContactAddressMutationInput;
};

export type MutationArchiveContactArgs = {
    id: Scalars['ID'];
};

export type MutationUnarchiveContactArgs = {
    id: Scalars['ID'];
};

export type MutationUpdateContactLabelsArgs = {
    id: Scalars['ID'];
    labels: Array<Scalars['ID']>;
};

export type MutationUpdateContactAssignmentsArgs = {
    input: UpdateContactAssignmentsInput;
};

export type MutationSendVendorReportArgs = {
    input: SendVendorReportInput;
};

export type MutationScheduleVisitArgs = {
    input: ScheduleVisitInput;
};

export type MutationUpdateVisitStatusArgs = {
    input: UpdateVisitStatusInput;
};

export type MutationCreateOfferArgs = {
    offerInput: CreateOfferInput;
};

export type MutationUpdateOfferArgs = {
    offerInput: UpdateOfferInput;
};

export type MutationArchiveOfferArgs = {
    offerId: Scalars['ID'];
};

export type MutationRestoreOfferArgs = {
    offerId: Scalars['ID'];
};

export type MutationAcceptOfferArgs = {
    offerId: Scalars['ID'];
};

export type MutationRefuseOfferArgs = {
    offerId: Scalars['ID'];
};

export type MutationCancelOfferArgs = {
    offerId: Scalars['ID'];
};

export type MutationAddLegalEntityArgs = {
    officeId: Scalars['ID'];
    input: LegalEntitiesInput;
};

export type MutationUpdateLegalEntityArgs = {
    officeId: Scalars['ID'];
    input: LegalEntitiesInput;
};

export type MutationDeleteLegalEntityArgs = {
    officeId: Scalars['ID'];
    input: LegalEntitiesInput;
};

export type MutationAddGeoTemplateArgs = {
    officeId: Scalars['ID'];
    input: GeoTemplatesInput;
};

export type MutationUpdateGeoTemplateArgs = {
    officeId: Scalars['ID'];
    input: GeoTemplatesInput;
};

export type MutationDeleteGeoTemplateArgs = {
    officeId: Scalars['ID'];
    input: GeoTemplatesInput;
};

export type MutationMatchingEmailsArgs = {
    input: MatchingEmailsInput;
};

export enum Negotiation {
    Sale = 'SALE',
    Let = 'LET',
}

export type Note = {
    __typename?: 'Note';
    text?: Maybe<Scalars['String']>;
};

export type Offer = {
    __typename?: 'Offer';
    id?: Maybe<Scalars['ID']>;
    parentId?: Maybe<Scalars['String']>;
    status: OfferStatus;
    financialDetails: FinancialDetails;
    createdAt: Scalars['DateTime'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    archivedAt?: Maybe<Scalars['DateTime']>;
    validUntil?: Maybe<Scalars['DateTime']>;
    acceptedAt?: Maybe<Scalars['DateTime']>;
    refusedAt?: Maybe<Scalars['DateTime']>;
    cancelledAt?: Maybe<Scalars['DateTime']>;
    buyers: Array<OfferOwner>;
    owners: Array<OfferBuyer>;
    propertyId: Scalars['ID'];
    property?: Maybe<Estate>;
    createdBy: Scalars['ID'];
    creator?: Maybe<User>;
    notes?: Maybe<Scalars['String']>;
};

export type OfferBuyer = {
    __typename?: 'OfferBuyer';
    id: Scalars['ID'];
};

export enum OfferDirection {
    BuyerToOwner = 'BUYER_TO_OWNER',
    OwnerToBuyer = 'OWNER_TO_BUYER',
}

export type OfferOwner = {
    __typename?: 'OfferOwner';
    id: Scalars['ID'];
};

export type OffersStats = {
    __typename?: 'OffersStats';
    number_of_pending_offers: Scalars['Int'];
    highest_offer_bid: Scalars['Float'];
    has_accepted_offer: Scalars['Boolean'];
    closest_offer_expirations: Array<Scalars['DateTime']>;
    currency: Scalars['Currency'];
};

export enum OfferStatus {
    Accepted = 'ACCEPTED',
    Cancelled = 'CANCELLED',
    Pending = 'PENDING',
    Refused = 'REFUSED',
}

export type Office = {
    __typename?: 'Office';
    id: Scalars['ID'];
    /** The name of the office */
    name: Scalars['String'];
    /** The office settings, including the numeral system, currency, etc... */
    settings: OfficeSettings;
    legalEntity?: Maybe<LegalEntity>;
    legalEntities: LegalEntitiesConnection;
    locationTemplates: LocationPreferenceConnection;
    /** A list of users that can be assigned as negotiatiors for a estate in this office */
    negotiators: Array<User>;
    negotiatiorsByIds: Array<User>;
    estates?: Maybe<EstatesConnection>;
    members?: Maybe<TeamMembersConnection>;
    companyMembers?: Maybe<TeamMembersConnection>;
};

export type OfficeLegalEntityArgs = {
    officeId: Scalars['ID'];
    id: Scalars['ID'];
};

export type OfficeLegalEntitiesArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type OfficeLocationTemplatesArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type OfficeNegotiatorsArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
};

export type OfficeNegotiatiorsByIdsArgs = {
    ids: Array<Scalars['ID']>;
};

export type OfficeEstatesArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type OfficeMembersArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type OfficeCompanyMembersArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    team?: Maybe<Scalars['String']>;
    role?: Maybe<Scalars['String']>;
};

export type OfficeSettings = {
    __typename?: 'OfficeSettings';
    currency: Scalars['String'];
    numeralSystem: Scalars['String'];
};

export enum OldConfigurationStatus {
    MissingFields = 'MISSING_FIELDS',
    OnlyUnits = 'ONLY_UNITS',
    NotConfigured = 'NOT_CONFIGURED',
    Ok = 'OK',
}

export enum OldPublicationStatus {
    NotPublished = 'NOT_PUBLISHED',
    Publishing = 'PUBLISHING',
    Unpublishing = 'UNPUBLISHING',
    Published = 'PUBLISHED',
    Unpublished = 'UNPUBLISHED',
    Outdated = 'OUTDATED',
}

export type OldUnitsMissingFields = {
    __typename?: 'OldUnitsMissingFields';
    id: Scalars['ID'];
    unitId?: Maybe<Scalars['String']>;
    missingFields: Array<Scalars['String']>;
};

export type PageInfo = {
    __typename?: 'PageInfo';
    currentPage: Scalars['Int'];
    itemsPerPage: Scalars['Int'];
    hasNextPage: Scalars['Boolean'];
    startCursor?: Maybe<Scalars['String']>;
    endCursor?: Maybe<Scalars['String']>;
};

export type PendingRequest = {
    __typename?: 'PendingRequest';
    id: Scalars['ID'];
    email: Scalars['String'];
    name?: Maybe<Scalars['String']>;
    message?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['String']>;
    resentAt?: Maybe<Scalars['String']>;
};

export type PendingRequestEdge = {
    __typename?: 'PendingRequestEdge';
    node: PendingRequest;
};

export type PendingRequestsConnection = {
    __typename?: 'PendingRequestsConnection';
    edges: Array<PendingRequestEdge>;
    nodes: Array<PendingRequest>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export enum PhoneNumberFormat {
    Raw = 'RAW',
    International = 'INTERNATIONAL',
}

export type PostalCodesData = {
    __typename?: 'PostalCodesData';
    country: Scalars['String'];
    postalCodes: Array<Scalars['String']>;
};

export type PostalCodesDataInput = {
    /** The country the lead is interested in */
    country: Scalars['String'];
    /** The list of postal codes that the lead is interested in */
    postalCodes: Array<Scalars['String']>;
};

export type PostalCodesLocationPreference = LocationPreference & {
    __typename?: 'PostalCodesLocationPreference';
    id: Scalars['ID'];
    type: Scalars['String'];
    name: Scalars['String'];
    data: PostalCodesData;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
};

export type PreferenceBudget = {
    __typename?: 'PreferenceBudget';
    maxPrice?: Maybe<Scalars['Float']>;
    minPrice?: Maybe<Scalars['Float']>;
};

export type PreferenceBudgetInput = {
    minPrice?: Maybe<Scalars['Float']>;
    maxPrice?: Maybe<Scalars['Float']>;
};

export type PriceAggregation = {
    __typename?: 'PriceAggregation';
    maxPrice: Scalars['Float'];
    minPrice: Scalars['Float'];
    count: Scalars['Int'];
    histogram: Array<HistogramBin>;
};

export type PropertyOffersFilter = {
    propertyId: Scalars['ID'];
    archived: Scalars['Boolean'];
};

export type PropertyReference = {
    __typename?: 'PropertyReference';
    assignee?: Maybe<PropertyReferenceAssignee>;
    team?: Maybe<PropertyReferenceTeam>;
};

export type PropertyReferenceAssignee = {
    __typename?: 'PropertyReferenceAssignee';
    id: Scalars['ID'];
    first_name?: Maybe<Scalars['String']>;
    last_name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    picture_url?: Maybe<Scalars['String']>;
};

export type PropertyReferenceTeam = {
    __typename?: 'PropertyReferenceTeam';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type PropertyStats = {
    __typename?: 'PropertyStats';
    numberOfPendingOffers: Scalars['Int'];
    closestOfferExpirations: Array<Scalars['DateTime']>;
    hasAcceptedOffer: Scalars['Boolean'];
    highestOfferBid: Scalars['Float'];
};

export type PropertyStatsFilter = {
    propertyId: Scalars['ID'];
};

export type PropertyStatus = {
    __typename?: 'PropertyStatus';
    /** The id of the negotiation status */
    id: Scalars['ID'];
    order: Scalars['Int'];
    /** The negotiation type for which this status applies */
    negotiation: Negotiation;
};

export type PropertyTimeline = {
    __typename?: 'PropertyTimeline';
    activities: ActivityConnection;
};

export type PropertyTimelineActivitiesArgs = {
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    contactId?: Maybe<Scalars['String']>;
};

export type PropertyVisitedActivity = Activity & {
    __typename?: 'PropertyVisitedActivity';
    id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    timestamp: Scalars['DateTime'];
    visitedBy?: Maybe<Array<Maybe<Contact>>>;
    property?: Maybe<Estate>;
    registeredBy: User;
    details?: Maybe<ActivityDetails>;
    action?: Maybe<ActivityAction>;
};

export type PublicationErrorContext = {
    __typename?: 'PublicationErrorContext';
    field?: Maybe<Scalars['String']>;
};

export enum PublicationStatus {
    NotPublished = 'NOT_PUBLISHED',
    Publishing = 'PUBLISHING',
    Unpublishing = 'UNPUBLISHING',
    Published = 'PUBLISHED',
    Unpublished = 'UNPUBLISHED',
    PublishingFailed = 'PUBLISHING_FAILED',
    UnpublishingFailed = 'UNPUBLISHING_FAILED',
}

export type PublicationWarningContext = {
    __typename?: 'PublicationWarningContext';
    field?: Maybe<Scalars['String']>;
};

export type PublishPropertyInput = {
    propertyId: Scalars['ID'];
    channelAccountId: Scalars['ID'];
    channelId: Scalars['String'];
    config?: Maybe<Scalars['JSON']>;
};

export type PublishPropertyPayload = {
    __typename?: 'PublishPropertyPayload';
    estate?: Maybe<Estate>;
};

export type Query = {
    __typename?: 'Query';
    channelAccounts?: Maybe<Array<ChannelAccount>>;
    channelAccount?: Maybe<ChannelAccount>;
    channels: Array<Channel>;
    channel?: Maybe<Channel>;
    estateChannelAccount?: Maybe<EstateConfigForChannelAccount>;
    estates: EstatesConnection;
    estate?: Maybe<Estate>;
    estatesOptions?: Maybe<EstatesOptions>;
    propertyReference?: Maybe<PropertyReference>;
    estatePublications?: Maybe<EstatePublications>;
    contacts: ContactConnection;
    contact?: Maybe<Contact>;
    contactReference?: Maybe<ContactReference>;
    contactsOptions?: Maybe<ContactsOptions>;
    contactLabels: Array<Label>;
    lead?: Maybe<Lead>;
    authCheck?: Maybe<Scalars['JSON']>;
    label?: Maybe<Label>;
    labels?: Maybe<LabelsPayload>;
    propertiesAggregations: EstateAggregations;
    /** The current authenticated user */
    me: User;
    user?: Maybe<User>;
    office?: Maybe<Office>;
    statuses: Array<PropertyStatus>;
    activity?: Maybe<Activity>;
    propertySchedulePendingConfirmation: ScheduleCount;
    contactSchedulePendingConfirmation: ScheduleCount;
    propertySchedule: Array<ScheduleEvent>;
    contactSchedule: Array<ScheduleEventVisit>;
    offer?: Maybe<Offer>;
    propertyOffers: Array<Offer>;
    propertyStats?: Maybe<PropertyStats>;
    contactOffers: Array<Offer>;
    contactStats?: Maybe<ContactStats>;
    version: Scalars['String'];
    geoTemplate: GeoTemplate;
    geoTemplates: GeoTemplatesPayload;
    team?: Maybe<CompanyTeamResponse>;
    teams?: Maybe<CompanyTeamsResponse>;
    userTeams?: Maybe<CompanyTeamsResponse>;
    matchingFlags: MatchingFlagsPayload;
    matchingConfig: MatchingConfigPayload;
    matchingRequirements: MatchingRequirementsPayload;
};

export type QueryChannelAccountsArgs = {
    companyId?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

export type QueryChannelAccountArgs = {
    companyId?: Maybe<Scalars['String']>;
    accountId?: Maybe<Scalars['String']>;
};

export type QueryChannelsArgs = {
    type?: Maybe<Scalars['String']>;
};

export type QueryChannelArgs = {
    id: Scalars['ID'];
};

export type QueryEstateChannelAccountArgs = {
    estateId: Scalars['ID'];
    estateChannelAccountId: Scalars['ID'];
};

export type QueryEstatesArgs = {
    query?: Maybe<Scalars['String']>;
    filters?: Maybe<EstateFilters>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    useAlgolia?: Maybe<Scalars['Boolean']>;
    locale?: Maybe<Scalars['String']>;
    useAtlasSearch?: Maybe<Scalars['Boolean']>;
    usePostRequest?: Maybe<Scalars['Boolean']>;
};

export type QueryEstateArgs = {
    id: Scalars['ID'];
};

export type QueryPropertyReferenceArgs = {
    id: Scalars['ID'];
};

export type QueryEstatePublicationsArgs = {
    id: Scalars['ID'];
};

export type QueryContactsArgs = {
    query?: Maybe<Scalars['String']>;
    locale?: Maybe<Scalars['String']>;
    sortOrder?: Maybe<Scalars['String']>;
    sortField?: Maybe<Scalars['String']>;
    filters?: Maybe<ContactFiltersInput>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    useAtlasSearch?: Maybe<Scalars['Boolean']>;
};

export type QueryContactArgs = {
    id: Scalars['ID'];
};

export type QueryContactReferenceArgs = {
    id: Scalars['ID'];
};

export type QueryContactLabelsArgs = {
    id: Scalars['ID'];
};

export type QueryLeadArgs = {
    id: Scalars['ID'];
};

export type QueryAuthCheckArgs = {
    input?: Maybe<Array<AuthCheckInput>>;
};

export type QueryLabelArgs = {
    id: Scalars['ID'];
};

export type QueryLabelsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
};

export type QueryPropertiesAggregationsArgs = {
    negotiation?: Maybe<Scalars['String']>;
};

export type QueryUserArgs = {
    id: Scalars['ID'];
    allowNotFound?: Maybe<Scalars['Boolean']>;
};

export type QueryOfficeArgs = {
    id: Scalars['ID'];
};

export type QueryStatusesArgs = {
    negotiation?: Maybe<Negotiation>;
};

export type QueryActivityArgs = {
    id: Scalars['ID'];
};

export type QueryPropertySchedulePendingConfirmationArgs = {
    propertyId: Scalars['ID'];
};

export type QueryContactSchedulePendingConfirmationArgs = {
    contactId: Scalars['ID'];
};

export type QueryPropertyScheduleArgs = {
    propertyId: Scalars['ID'];
    type?: Maybe<Array<ScheduleEventType>>;
    query?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
};

export type QueryContactScheduleArgs = {
    contactId: Scalars['ID'];
    query?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
};

export type QueryOfferArgs = {
    offerId: Scalars['ID'];
};

export type QueryPropertyOffersArgs = {
    filter: PropertyOffersFilter;
};

export type QueryPropertyStatsArgs = {
    filter: PropertyStatsFilter;
};

export type QueryContactOffersArgs = {
    filter: ContactOffersFilter;
};

export type QueryContactStatsArgs = {
    filter: ContactStatsFilter;
};

export type QueryGeoTemplateArgs = {
    officeId: Scalars['ID'];
    id: Scalars['ID'];
};

export type QueryGeoTemplatesArgs = {
    officeId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
};

export type QueryTeamArgs = {
    teamId: Scalars['ID'];
};

export type QueryTeamsArgs = {
    companyId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
};

export type QueryUserTeamsArgs = {
    userId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type QueryMatchingFlagsArgs = {
    input: MatchingFlagsInput;
};

export type QueryMatchingConfigArgs = {
    input: MatchingConfigInput;
};

export type QueryMatchingRequirementsArgs = {
    input: MatchingRequirementsInput;
};

export type RefreshPublicationInput = {
    propertyId: Scalars['ID'];
    channelAccountId: Scalars['ID'];
};

export type RefreshPublicationPayload = {
    __typename?: 'RefreshPublicationPayload';
    success: Scalars['Boolean'];
};

export type RegisterEstateActivityInput = {
    estateId: Scalars['ID'];
    type: Scalars['String'];
    contactId: Scalars['String'];
};

export type RegisterEstateActivityPayload = {
    __typename?: 'RegisterEstateActivityPayload';
    subject: Estate;
    contact?: Maybe<Contact>;
    activity: Activity;
};

export type RemoveIntegrationInput = {
    name: Scalars['String'];
};

export type RemoveIntegrationPayload = {
    __typename?: 'RemoveIntegrationPayload';
    user: User;
};

export type RemoveLeadPreferenceInput = {
    contactId: Scalars['ID'];
    preferenceId: Scalars['ID'];
};

export type RemoveLeadPreferencePayload = {
    __typename?: 'RemoveLeadPreferencePayload';
    contact: Contact;
};

export type RemoveLocationPayload = {
    __typename?: 'RemoveLocationPayload';
    /** The lead the location preference belonged to */
    lead: Lead;
    /** The id of the removed location preference */
    id: Scalars['ID'];
};

export type RemoveLocationTemplatePayload = {
    __typename?: 'RemoveLocationTemplatePayload';
    /** The office the location template belonged to */
    office: Office;
    /** The id of the removed location template */
    id: Scalars['ID'];
};

export type RetryPublicationInput = {
    propertyId: Scalars['ID'];
    channelAccountId: Scalars['ID'];
};

export type RetryPublicationPayload = {
    __typename?: 'RetryPublicationPayload';
    /** The channel account edge */
    channelAccount: EstateChannelAccountConnectionEdge;
};

export type ScheduleCount = {
    __typename?: 'ScheduleCount';
    count: Scalars['Int'];
};

export type ScheduleEvent = {
    id: Scalars['ID'];
    title?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['String']>;
    location?: Maybe<Scalars['String']>;
    propertyId: Scalars['ID'];
    estateLocation?: Maybe<Scalars['String']>;
    type: ScheduleEventType;
    createdAt: Scalars['String'];
    updatedAt: Scalars['String'];
};

export type ScheduleEventEstateLocationArgs = {
    lang?: Scalars['String'];
};

export type ScheduleEventAttendee = {
    __typename?: 'ScheduleEventAttendee';
    id: Scalars['String'];
    resource_type: Scalars['String'];
};

export type ScheduleEventAuction = ScheduleEvent & {
    __typename?: 'ScheduleEventAuction';
    id: Scalars['ID'];
    title?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['String']>;
    location?: Maybe<Scalars['String']>;
    estateLocation?: Maybe<Scalars['String']>;
    propertyId: Scalars['ID'];
    type: ScheduleEventType;
    createdAt: Scalars['String'];
    updatedAt: Scalars['String'];
};

export type ScheduleEventAuctionEstateLocationArgs = {
    lang?: Scalars['String'];
};

export type ScheduleEventOpenHome = ScheduleEvent & {
    __typename?: 'ScheduleEventOpenHome';
    id: Scalars['ID'];
    title?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['String']>;
    location?: Maybe<Scalars['String']>;
    estateLocation?: Maybe<Scalars['String']>;
    propertyId: Scalars['ID'];
    type: ScheduleEventType;
    createdAt: Scalars['String'];
    updatedAt: Scalars['String'];
    companyId?: Maybe<Scalars['String']>;
    officeId?: Maybe<Scalars['String']>;
};

export type ScheduleEventOpenHomeEstateLocationArgs = {
    lang?: Scalars['String'];
};

export enum ScheduleEventType {
    Visit = 'VISIT',
    Auction = 'AUCTION',
    Openhome = 'OPENHOME',
}

export type ScheduleEventVisit = ScheduleEvent & {
    __typename?: 'ScheduleEventVisit';
    id: Scalars['ID'];
    title?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['String']>;
    location?: Maybe<Scalars['String']>;
    estateLocation?: Maybe<Scalars['String']>;
    propertyId: Scalars['ID'];
    type: ScheduleEventType;
    createdAt: Scalars['String'];
    updatedAt: Scalars['String'];
    attendees?: Maybe<Array<ScheduleEventAttendee>>;
    description?: Maybe<Scalars['String']>;
    organizerId?: Maybe<Scalars['String']>;
    status?: Maybe<VisitStatus>;
};

export type ScheduleEventVisitEstateLocationArgs = {
    lang?: Scalars['String'];
};

export type ScheduleVisitInput = {
    estateId: Scalars['ID'];
    contactId: Scalars['ID'];
    dateTime: Scalars['DateTime'];
    negotiatorId: Scalars['ID'];
    message: Scalars['String'];
    duration?: Maybe<Scalars['Int']>;
};

export type ScheduleVisitPayload = {
    __typename?: 'ScheduleVisitPayload';
    success: Scalars['Boolean'];
    /** The contact the visit was scheduled with */
    contact?: Maybe<Contact>;
    /** The estate the visit was scheduled for */
    estate?: Maybe<Estate>;
};

export type SendVendorReportInput = {
    vendorId: Scalars['ID'];
    message?: Maybe<Scalars['String']>;
};

export type SendVendorReportPayload = {
    __typename?: 'SendVendorReportPayload';
    reportId: Scalars['ID'];
    vendor?: Maybe<Vendor>;
};

export type TeamMembersConnection = {
    __typename?: 'TeamMembersConnection';
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
    nodes: Array<User>;
    edges: Array<TeamMembersConnectionEdge>;
};

export type TeamMembersConnectionEdge = {
    __typename?: 'TeamMembersConnectionEdge';
    node: User;
};

export type TranslateInput = {
    inputLanguage: Scalars['String'];
    outputLanguage: Scalars['String'];
    text: Scalars['String'];
};

export type TranslatePayload = {
    __typename?: 'TranslatePayload';
    outputLanguage: Scalars['String'];
    text: Scalars['String'];
};

export type TwitterPublicationConfig = EstatePublicationConfig & {
    __typename?: 'TwitterPublicationConfig';
    showPrice?: Maybe<Scalars['Boolean']>;
    showLocation?: Maybe<Scalars['Boolean']>;
    message?: Maybe<Scalars['String']>;
};

export type TwitterPublicationSettings = AccountPublicationSettings & {
    __typename?: 'TwitterPublicationSettings';
    publishPrice?: Maybe<Scalars['Boolean']>;
    publishLocation?: Maybe<Scalars['Boolean']>;
    tweetText?: Maybe<Scalars['String']>;
};

export type TwitterPublicationSettingsInput = {
    tweetText: Scalars['String'];
};

export type UnarchiveEstateInput = {
    /** The ID of the estate to unarchive */
    estateId: Scalars['ID'];
    estateType: EstateType;
};

export type UnarchiveEstatePayload = {
    __typename?: 'UnarchiveEstatePayload';
    /** The estate that was unarchived */
    estate?: Maybe<Estate>;
};

export type UnassignBuyersToEstateInput = {
    estateId: Scalars['ID'];
    buyerIds: Array<Scalars['ID']>;
};

export type UnassignBuyersToEstatePayload = {
    __typename?: 'UnassignBuyersToEstatePayload';
    estate?: Maybe<Estate>;
};

export type UnassignLegalEntityToEstateInput = {
    estateId: Scalars['ID'];
};

export type UnassignLegalEntityToPayload = {
    __typename?: 'UnassignLegalEntityToPayload';
    estate?: Maybe<Estate>;
};

export type UnassignVendorsToEstateInput = {
    estateId: Scalars['ID'];
    vendorIds: Array<Scalars['ID']>;
};

export type UnassignVendorsToEstatePayload = {
    __typename?: 'UnassignVendorsToEstatePayload';
    estate?: Maybe<Estate>;
};

export type UnitEdge = {
    __typename?: 'UnitEdge';
    node: Estate;
};

export type UnitPublicationErrors = {
    __typename?: 'UnitPublicationErrors';
    unitId: Scalars['ID'];
    errors: Array<EstatePublicationError>;
};

export type UnitPublicationWarnings = {
    __typename?: 'UnitPublicationWarnings';
    unitId: Scalars['ID'];
    warnings: Array<EstatePublicationWarning>;
};

export type UnitsConnection = {
    __typename?: 'UnitsConnection';
    edges: Array<UnitEdge>;
    nodes: Array<Estate>;
    pageInfo: PageInfo;
    totalCount: Scalars['Int'];
};

export type UnpublishPropertyInput = {
    propertyId: Scalars['ID'];
    channelAccountId: Scalars['ID'];
};

export type UpdateContactAddressMutationInput = {
    /** the id of the contact */
    id: Scalars['ID'];
    address?: Maybe<Scalars['JSON']>;
};

export type UpdateContactAssignmentsInput = {
    /** Contact ID */
    id: Scalars['ID'];
    /** Ids of negotiators assigned to this contact */
    assignments: Array<Scalars['Int']>;
};

export type UpdateContactAssignmentsPayload = {
    __typename?: 'UpdateContactAssignmentsPayload';
    success: Scalars['Boolean'];
    errorMessage?: Maybe<Scalars['String']>;
};

export type UpdateContactMutationInput = {
    /** the id of the contact */
    id: Scalars['ID'];
    /** the type of the contact that is being updated */
    type: ContactType;
    /** the prefered locale of the contact */
    locale?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    pronouns?: Maybe<Scalars['String']>;
};

export type UpdateContactMutationPayload = {
    __typename?: 'UpdateContactMutationPayload';
    success: Scalars['Boolean'];
    /** The updated contact after the mutation */
    contact?: Maybe<Contact>;
    validationErrors?: Maybe<Scalars['JSON']>;
};

export type UpdateEstatePayload = {
    __typename?: 'UpdateEstatePayload';
    /** List of errors that ocurred executing the mutation */
    userErrors: Array<UserError>;
    estate?: Maybe<Estate>;
    success?: Maybe<Scalars['Boolean']>;
};

export type UpdateLeadPreferenceInput = {
    contactId: Scalars['ID'];
    preferenceId: Scalars['ID'];
    type: Scalars['String'];
    subtypes?: Maybe<Array<Scalars['String']>>;
    negotiation: Negotiation;
    isInvestor: Scalars['Boolean'];
    condition: Scalars['String'];
    amenities: Array<Scalars['String']>;
    minBedrooms?: Maybe<Scalars['Int']>;
    budget: PreferenceBudgetInput;
    max_liveable_area?: Maybe<Scalars['Float']>;
    min_liveable_area?: Maybe<Scalars['Float']>;
    max_plot_area?: Maybe<Scalars['Float']>;
    min_plot_area?: Maybe<Scalars['Float']>;
    max_net_area?: Maybe<Scalars['Float']>;
    min_net_area?: Maybe<Scalars['Float']>;
    max_gross_area?: Maybe<Scalars['Float']>;
    min_gross_area?: Maybe<Scalars['Float']>;
};

export type UpdateLeadPreferencePayload = {
    __typename?: 'UpdateLeadPreferencePayload';
    /** List of errors that ocurred executing the mutation */
    userErrors: Array<UserError>;
    /** The updated lead preference */
    preference?: Maybe<BuyerPreference>;
    /** Wheter the mutation executed sucessfully or not */
    success: Scalars['Boolean'];
};

export type UpdateLocationPreferencePayload = {
    __typename?: 'UpdateLocationPreferencePayload';
    /** The updated location preference */
    locationPreference: LocationPreference;
};

export type UpdateLocationTemplatePayload = {
    __typename?: 'UpdateLocationTemplatePayload';
    /** The updated location template */
    locationTemplate: LocationPreference;
};

export type UpdateOfferInput = {
    id: Scalars['ID'];
    buyers?: Maybe<Array<Scalars['ID']>>;
    owners?: Maybe<Array<Scalars['ID']>>;
    notes?: Maybe<Scalars['String']>;
    validUntil?: Maybe<Scalars['DateTime']>;
    financialDetails?: Maybe<FinancialDetailsInput>;
};

export type UpdatePropertyVisibilityPayload = {
    __typename?: 'UpdatePropertyVisibilityPayload';
    propertyId?: Maybe<Scalars['ID']>;
};

export type UpdateUserInput = {
    attributes?: Maybe<Scalars['JSON']>;
};

export type UpdateUserPayload = {
    __typename?: 'UpdateUserPayload';
    user?: Maybe<User>;
    success: Scalars['Boolean'];
};

export type UpdateVisitStatusInput = {
    visitID: Scalars['ID'];
    visitStatus: VisitStatus;
    createActivities: Scalars['Boolean'];
};

export type UpdateVisitStatusPayload = {
    __typename?: 'UpdateVisitStatusPayload';
    success: Scalars['Boolean'];
};

/** A user of the Sweepbright app */
export type User = {
    __typename?: 'User';
    id: Scalars['ID'];
    /** The user first name */
    firstName?: Maybe<Scalars['String']>;
    /** The user last name */
    lastName?: Maybe<Scalars['String']>;
    /** The current office the user is in, used when retrieving properties and contacts */
    office: Office;
    email: Scalars['String'];
    /** The user phone number */
    phone?: Maybe<Scalars['String']>;
    /** The user phone number */
    photo?: Maybe<Scalars['JSON']>;
    /** The active user integrations (JSON for now as a scape hatch) */
    integrations?: Maybe<Scalars['JSON']>;
    /** The configuration data for the given integration */
    integration?: Maybe<Scalars['JSON']>;
    calendar?: Maybe<Scalars['JSON']>;
    hasCalendarIntegration?: Maybe<Scalars['Boolean']>;
    appointmentServiceUrl?: Maybe<Scalars['String']>;
    /** The company the user belongs to */
    company?: Maybe<Company>;
    locale?: Maybe<Scalars['String']>;
    bio: Array<LocalizedText>;
    role: Scalars['String'];
    errorStatus?: Maybe<Scalars['Int']>;
    pronouns?: Maybe<Scalars['String']>;
};

/** A user of the Sweepbright app */
export type UserIntegrationArgs = {
    name: Scalars['String'];
};

/** Represents an error in the input of a mutation. */
export type UserError = DisplayableError & {
    __typename?: 'UserError';
    /** Path to the input field which caused the error */
    field?: Maybe<Array<Scalars['String']>>;
    /** The error message */
    message: Scalars['String'];
};

export enum UserRole {
    TeamMember = 'TeamMember',
    Editor = 'Editor',
    TeamAdmin = 'TeamAdmin',
    CompanyAdmin = 'CompanyAdmin',
}

export type Vendor = Contact & {
    __typename?: 'Vendor';
    id: Scalars['ID'];
    /** The first name of the contact */
    firstName: Scalars['String'];
    /** The last name of the contact */
    lastName: Scalars['String'];
    /** The email address of the contact */
    email?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    officeId?: Maybe<Scalars['String']>;
    locale?: Maybe<Scalars['String']>;
    pronouns?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    notes: Array<Note>;
    subscribed?: Maybe<Scalars['Boolean']>;
    type: ContactType;
    photo?: Maybe<ContactPhoto>;
    address: Scalars['JSON'];
    /** The estates owned by the vendor */
    estates: VendorEstatesConnection;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    timeline: ContactTimeline;
    interactedProperties: EstatesConnection;
    /** The list of properties that the contact can interact with */
    eligibleEstates: EstatesConnection;
    archived: Scalars['Boolean'];
    /** The last interaction with this contact */
    lastInteraction?: Maybe<Scalars['JSON']>;
    /** The time of the last report sent to this vendor */
    lastReportSentAt?: Maybe<Scalars['DateTime']>;
    assignedNegotiators?: Maybe<Array<ContactAssignedNegotiator>>;
    offers: Array<Offer>;
    offersStats?: Maybe<OffersStats>;
};

export type VendorPhoneArgs = {
    format?: Maybe<PhoneNumberFormat>;
};

export type VendorEstatesArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type VendorInteractedPropertiesArgs = {
    interestLevel?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

export type VendorEligibleEstatesArgs = {
    query?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
};

export type VendorOffersArgs = {
    archived: Scalars['Boolean'];
};

export type VendorEstatesConnection = {
    __typename?: 'VendorEstatesConnection';
    totalCount: Scalars['Int'];
    pageInfo: PageInfo;
    edges: Array<VendorEstatesConnectionEdge>;
    nodes: Array<Estate>;
};

export type VendorEstatesConnectionEdge = {
    __typename?: 'VendorEstatesConnectionEdge';
    node: Estate;
};

export type VendorReportEmailedActivity = Activity & {
    __typename?: 'VendorReportEmailedActivity';
    id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    timestamp?: Maybe<Scalars['DateTime']>;
    details?: Maybe<ActivityDetails>;
    action?: Maybe<ActivityAction>;
    sentBy: User;
    /** The vendor to which the vendor report was sent */
    sentTo: Vendor;
    /** The estate included in the vendor report */
    about?: Maybe<Estate>;
};

export type Visit = {
    __typename?: 'Visit';
    id: Scalars['ID'];
    property?: Maybe<Estate>;
    /** The duration of the visit (in minutes) */
    duration: Scalars['Int'];
    organizer?: Maybe<User>;
    /** The contacts attending the visit */
    attendees?: Maybe<Array<Maybe<Contact>>>;
};

export type VisitScheduledActivity = Activity & {
    __typename?: 'VisitScheduledActivity';
    id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    timestamp: Scalars['DateTime'];
    visit: Visit;
    details?: Maybe<ActivityDetails>;
    action?: Maybe<ActivityAction>;
    attendee?: Maybe<Contact>;
};

export enum VisitStatus {
    Confirmed = 'confirmed',
    Missed = 'missed',
    Pending = 'pending',
}

type ContactCardFragment_Lead_Fragment = {
    __typename: 'Lead';
    id: string;
    lastAction?: Maybe<string>;
    lastInteraction?: Maybe<any>;
    firstName: string;
    lastName: string;
    email?: Maybe<string>;
    phone?: Maybe<string>;
    type: ContactType;
    archived: boolean;
    subscribed?: Maybe<boolean>;
    updatedAt: any;
    pronouns?: Maybe<string>;
    officeId?: Maybe<string>;
    photo?: Maybe<{ __typename?: 'ContactPhoto'; id: string; url: string; uploadedAt?: Maybe<string> }>;
    assignedNegotiators?: Maybe<
        Array<{
            __typename?: 'ContactAssignedNegotiator';
            id: number;
            first_name?: Maybe<string>;
            last_name?: Maybe<string>;
            picture_url?: Maybe<string>;
        }>
    >;
};

type ContactCardFragment_Vendor_Fragment = {
    __typename: 'Vendor';
    id: string;
    firstName: string;
    lastName: string;
    email?: Maybe<string>;
    phone?: Maybe<string>;
    type: ContactType;
    archived: boolean;
    subscribed?: Maybe<boolean>;
    updatedAt: any;
    pronouns?: Maybe<string>;
    officeId?: Maybe<string>;
    photo?: Maybe<{ __typename?: 'ContactPhoto'; id: string; url: string; uploadedAt?: Maybe<string> }>;
    assignedNegotiators?: Maybe<
        Array<{
            __typename?: 'ContactAssignedNegotiator';
            id: number;
            first_name?: Maybe<string>;
            last_name?: Maybe<string>;
            picture_url?: Maybe<string>;
        }>
    >;
};

export type ContactCardFragmentFragment = ContactCardFragment_Lead_Fragment | ContactCardFragment_Vendor_Fragment;

type PreferenceFormFragment_BuyerPreferenceForHouse_Fragment = {
    __typename?: 'BuyerPreferenceForHouse';
    minBedrooms?: Maybe<number>;
    minArea?: Maybe<number>;
    id: string;
    type: string;
    subtypes?: Maybe<Array<string>>;
    isInvestor?: Maybe<boolean>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
};

type PreferenceFormFragment_BuyerPreferenceForApartment_Fragment = {
    __typename?: 'BuyerPreferenceForApartment';
    minBedrooms?: Maybe<number>;
    minArea?: Maybe<number>;
    id: string;
    type: string;
    subtypes?: Maybe<Array<string>>;
    isInvestor?: Maybe<boolean>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
};

type PreferenceFormFragment_BuyerPreferenceForLand_Fragment = {
    __typename?: 'BuyerPreferenceForLand';
    minArea?: Maybe<number>;
    id: string;
    type: string;
    subtypes?: Maybe<Array<string>>;
    isInvestor?: Maybe<boolean>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
};

type PreferenceFormFragment_BuyerPreferenceForOffice_Fragment = {
    __typename?: 'BuyerPreferenceForOffice';
    minArea?: Maybe<number>;
    id: string;
    type: string;
    subtypes?: Maybe<Array<string>>;
    isInvestor?: Maybe<boolean>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
};

type PreferenceFormFragment_BuyerPreferenceForCommercial_Fragment = {
    __typename?: 'BuyerPreferenceForCommercial';
    minArea?: Maybe<number>;
    id: string;
    type: string;
    subtypes?: Maybe<Array<string>>;
    isInvestor?: Maybe<boolean>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
};

type PreferenceFormFragment_BuyerPreferenceForParking_Fragment = {
    __typename?: 'BuyerPreferenceForParking';
    minArea?: Maybe<number>;
    id: string;
    type: string;
    subtypes?: Maybe<Array<string>>;
    isInvestor?: Maybe<boolean>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
};

export type PreferenceFormFragmentFragment =
    | PreferenceFormFragment_BuyerPreferenceForHouse_Fragment
    | PreferenceFormFragment_BuyerPreferenceForApartment_Fragment
    | PreferenceFormFragment_BuyerPreferenceForLand_Fragment
    | PreferenceFormFragment_BuyerPreferenceForOffice_Fragment
    | PreferenceFormFragment_BuyerPreferenceForCommercial_Fragment
    | PreferenceFormFragment_BuyerPreferenceForParking_Fragment;

type PreferenceCardFragment_BuyerPreferenceForHouse_Fragment = {
    __typename?: 'BuyerPreferenceForHouse';
    minBedrooms?: Maybe<number>;
    id: string;
    isInvestor?: Maybe<boolean>;
    subtypes?: Maybe<Array<string>>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    type: string;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
    locations: {
        __typename?: 'LocationPreferenceConnection';
        totalCount: number;
        nodes: Array<
            | {
                  __typename?: 'PostalCodesLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'PostalCodesData'; country: string; postalCodes: Array<string> };
              }
            | {
                  __typename?: 'GeoJSONLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'GeoJSONData'; type: string; coordinates: Array<Array<Array<number>>> };
              }
        >;
    };
};

type PreferenceCardFragment_BuyerPreferenceForApartment_Fragment = {
    __typename?: 'BuyerPreferenceForApartment';
    minBedrooms?: Maybe<number>;
    id: string;
    isInvestor?: Maybe<boolean>;
    subtypes?: Maybe<Array<string>>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    type: string;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
    locations: {
        __typename?: 'LocationPreferenceConnection';
        totalCount: number;
        nodes: Array<
            | {
                  __typename?: 'PostalCodesLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'PostalCodesData'; country: string; postalCodes: Array<string> };
              }
            | {
                  __typename?: 'GeoJSONLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'GeoJSONData'; type: string; coordinates: Array<Array<Array<number>>> };
              }
        >;
    };
};

type PreferenceCardFragment_BuyerPreferenceForLand_Fragment = {
    __typename?: 'BuyerPreferenceForLand';
    id: string;
    isInvestor?: Maybe<boolean>;
    subtypes?: Maybe<Array<string>>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    type: string;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
    locations: {
        __typename?: 'LocationPreferenceConnection';
        totalCount: number;
        nodes: Array<
            | {
                  __typename?: 'PostalCodesLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'PostalCodesData'; country: string; postalCodes: Array<string> };
              }
            | {
                  __typename?: 'GeoJSONLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'GeoJSONData'; type: string; coordinates: Array<Array<Array<number>>> };
              }
        >;
    };
};

type PreferenceCardFragment_BuyerPreferenceForOffice_Fragment = {
    __typename?: 'BuyerPreferenceForOffice';
    id: string;
    isInvestor?: Maybe<boolean>;
    subtypes?: Maybe<Array<string>>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    type: string;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
    locations: {
        __typename?: 'LocationPreferenceConnection';
        totalCount: number;
        nodes: Array<
            | {
                  __typename?: 'PostalCodesLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'PostalCodesData'; country: string; postalCodes: Array<string> };
              }
            | {
                  __typename?: 'GeoJSONLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'GeoJSONData'; type: string; coordinates: Array<Array<Array<number>>> };
              }
        >;
    };
};

type PreferenceCardFragment_BuyerPreferenceForCommercial_Fragment = {
    __typename?: 'BuyerPreferenceForCommercial';
    id: string;
    isInvestor?: Maybe<boolean>;
    subtypes?: Maybe<Array<string>>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    type: string;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
    locations: {
        __typename?: 'LocationPreferenceConnection';
        totalCount: number;
        nodes: Array<
            | {
                  __typename?: 'PostalCodesLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'PostalCodesData'; country: string; postalCodes: Array<string> };
              }
            | {
                  __typename?: 'GeoJSONLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'GeoJSONData'; type: string; coordinates: Array<Array<Array<number>>> };
              }
        >;
    };
};

type PreferenceCardFragment_BuyerPreferenceForParking_Fragment = {
    __typename?: 'BuyerPreferenceForParking';
    id: string;
    isInvestor?: Maybe<boolean>;
    subtypes?: Maybe<Array<string>>;
    amenities?: Maybe<Array<string>>;
    condition?: Maybe<string>;
    negotiation: Negotiation;
    type: string;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
    budget?: Maybe<{ __typename?: 'PreferenceBudget'; maxPrice?: Maybe<number>; minPrice?: Maybe<number> }>;
    locations: {
        __typename?: 'LocationPreferenceConnection';
        totalCount: number;
        nodes: Array<
            | {
                  __typename?: 'PostalCodesLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'PostalCodesData'; country: string; postalCodes: Array<string> };
              }
            | {
                  __typename?: 'GeoJSONLocationPreference';
                  id: string;
                  name: string;
                  type: string;
                  data: { __typename?: 'GeoJSONData'; type: string; coordinates: Array<Array<Array<number>>> };
              }
        >;
    };
};

export type PreferenceCardFragmentFragment =
    | PreferenceCardFragment_BuyerPreferenceForHouse_Fragment
    | PreferenceCardFragment_BuyerPreferenceForApartment_Fragment
    | PreferenceCardFragment_BuyerPreferenceForLand_Fragment
    | PreferenceCardFragment_BuyerPreferenceForOffice_Fragment
    | PreferenceCardFragment_BuyerPreferenceForCommercial_Fragment
    | PreferenceCardFragment_BuyerPreferenceForParking_Fragment;

export type EstateChannelAccountFragmentFragment = {
    __typename?: 'EstateConfigForChannelAccount';
    id: string;
    enabled: boolean;
    publicationStatus: OldPublicationStatus;
    configurationStatus: OldConfigurationStatus;
    missingFields: Array<string>;
    canPublishProject: boolean;
    unitsMissingFields: Array<{ __typename?: 'OldUnitsMissingFields'; id: string; missingFields: Array<string> }>;
    lastPublication?: Maybe<{
        __typename?: 'EstatePublication';
        id: string;
        publishedAt?: Maybe<any>;
        link?: Maybe<string>;
    }>;
    settings?: Maybe<
        | { __typename: 'GenericAccountPublicationSettings' }
        | { __typename: 'FacebookPublicationSettings'; postText?: Maybe<string>; images: Array<string> }
        | { __typename: 'TwitterPublicationSettings'; tweetText?: Maybe<string> }
        | { __typename: 'ImmowebPublicationSettings'; adSize?: Maybe<string> }
        | {
              __typename: 'EstateAgentFeedsPublicationSettings';
              priceType?: Maybe<string>;
              features?: Maybe<Array<string>>;
          }
        | { __typename: 'FrenchPortalPublicationSettings' }
    >;
    account: {
        __typename?: 'ChannelAccount';
        id: string;
        name: string;
        channel: {
            __typename?: 'Channel';
            id: string;
            name: string;
            logoUrl?: Maybe<string>;
            slug: string;
            supportsUnpublishing: boolean;
            supportsRepublishing: boolean;
        };
    };
};

export type EstateChannelAccountEdgeFragmentFragment = {
    __typename?: 'EstateChannelAccountConnectionEdge';
    id: string;
    publicationStatus: PublicationStatus;
    configurationStatus: ConfigurationStatus;
    canPublish: boolean;
    missingFields: {
        __typename?: 'MissingFieldsEdge';
        id: string;
        node: Array<{ __typename?: 'MissingField'; path: string; errorMessage: string }>;
    };
    unitsMissingFields: {
        __typename?: 'MissingFieldsConnection';
        edges: Array<{
            __typename?: 'MissingFieldsEdge';
            id: string;
            estateId: string;
            node: Array<{ __typename?: 'MissingField'; errorMessage: string; path: string }>;
        }>;
    };
    lastPublication?: Maybe<{
        __typename?: 'EstatePublication';
        id: string;
        publishedAt?: Maybe<any>;
        link?: Maybe<string>;
    }>;
    node: {
        __typename?: 'ChannelAccount';
        id: string;
        name: string;
        channel: {
            __typename?: 'Channel';
            id: string;
            name: string;
            logoUrl?: Maybe<string>;
            slug: string;
            supportsUnpublishing: boolean;
            supportsRepublishing: boolean;
        };
    };
};

type LocationPreference_PostalCodesLocationPreference_Fragment = {
    __typename: 'PostalCodesLocationPreference';
    id: string;
    name: string;
    type: string;
    createdAt: any;
    data: { __typename: 'PostalCodesData'; country: string; postalCodes: Array<string> };
};

type LocationPreference_GeoJsonLocationPreference_Fragment = {
    __typename: 'GeoJSONLocationPreference';
    id: string;
    name: string;
    type: string;
    createdAt: any;
    data: { __typename: 'GeoJSONData'; type: string; coordinates: Array<Array<Array<number>>> };
};

export type LocationPreferenceFragment =
    | LocationPreference_PostalCodesLocationPreference_Fragment
    | LocationPreference_GeoJsonLocationPreference_Fragment;

export type OfferCardFragmentFragment = {
    __typename?: 'Offer';
    createdBy: string;
    id?: Maybe<string>;
    notes?: Maybe<string>;
    propertyId: string;
    parentId?: Maybe<string>;
    status: OfferStatus;
    archivedAt?: Maybe<any>;
    createdAt: any;
    updatedAt?: Maybe<any>;
    validUntil?: Maybe<any>;
    financialDetails: {
        __typename?: 'FinancialDetails';
        agencyTotalFee: number;
        transactionAmount: number;
        ownerFixedFee: number;
        direction: OfferDirection;
        currency: any;
        ownerPercentageFee: number;
        ownerTotalFee: number;
        ownerNetAmount: number;
        buyerFixedFee: number;
        buyerPercentageFee: number;
        buyerGrossAmount: number;
        buyerTotalFee: number;
    };
    creator?: Maybe<{ __typename?: 'User'; firstName?: Maybe<string>; lastName?: Maybe<string> }>;
    property?: Maybe<{
        __typename?: 'Estate';
        attributes: any;
        negotiation: string;
        negotiator?: Maybe<{ __typename?: 'User'; id: string; firstName?: Maybe<string>; lastName?: Maybe<string> }>;
    }>;
    buyers: Array<{ __typename?: 'OfferOwner'; id: string }>;
    owners: Array<{ __typename?: 'OfferBuyer'; id: string }>;
};

export type PropertyCardFragmentFragment = {
    __typename: 'Estate';
    id: string;
    type: string;
    negotiation: string;
    status: string;
    attributes: any;
    lastInteraction?: Maybe<any>;
    createdAt?: Maybe<any>;
    updatedAt?: Maybe<any>;
    archived: boolean;
    internalType: EstateType;
    projectId?: Maybe<string>;
    isProject: boolean;
    isUnit: boolean;
    publicURL?: Maybe<string>;
    office_id?: Maybe<string>;
    visibility?: Maybe<string>;
    error_publications_count?: Maybe<number>;
    successful_publications_count?: Maybe<number>;
    legalEntity?: Maybe<any>;
    buyer_ids?: Maybe<Array<string>>;
    owner_ids?: Maybe<Array<string>>;
    negotiator?: Maybe<{
        __typename?: 'User';
        id: string;
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        first_name?: Maybe<string>;
        last_name?: Maybe<string>;
    }>;
};

export type TimelineActivitiesFragment = {
    __typename?: 'ActivityConnection';
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor?: Maybe<string>; startCursor?: Maybe<string> };
    nodes: Array<
        | {
              __typename: 'VendorReportEmailedActivity';
              id: string;
              timestamp?: Maybe<any>;
              action?: Maybe<ActivityAction>;
              sentTo: { __typename?: 'Vendor'; id: string; firstName: string; lastName: string };
              sentBy: { __typename?: 'User'; id: string; firstName?: Maybe<string>; lastName?: Maybe<string> };
              about?: Maybe<{ __typename?: 'Estate'; id: string; attributes: any }>;
          }
        | {
              __typename: 'ContactEmailedActivity';
              id: string;
              timestamp: any;
              action?: Maybe<ActivityAction>;
              emailedContact?: Maybe<
                  | { __typename?: 'Lead'; id: string; firstName: string; lastName: string }
                  | { __typename?: 'Vendor'; id: string; firstName: string; lastName: string }
              >;
              emailedBy: { __typename?: 'User'; id: string; firstName?: Maybe<string>; lastName?: Maybe<string> };
              about?: Maybe<{
                  __typename?: 'Estate';
                  id: string;
                  attributes: any;
                  isUnit: boolean;
                  projectId?: Maybe<string>;
              }>;
          }
        | {
              __typename: 'VisitScheduledActivity';
              id: string;
              timestamp: any;
              action?: Maybe<ActivityAction>;
              attendee?: Maybe<
                  | { __typename?: 'Lead'; id: string; firstName: string; lastName: string }
                  | { __typename?: 'Vendor'; id: string; firstName: string; lastName: string }
              >;
              visit: {
                  __typename?: 'Visit';
                  id: string;
                  attendees?: Maybe<
                      Array<
                          Maybe<
                              | { __typename?: 'Lead'; id: string; firstName: string; lastName: string }
                              | { __typename?: 'Vendor'; id: string; firstName: string; lastName: string }
                          >
                      >
                  >;
                  organizer?: Maybe<{
                      __typename?: 'User';
                      id: string;
                      firstName?: Maybe<string>;
                      lastName?: Maybe<string>;
                  }>;
                  property?: Maybe<{
                      __typename?: 'Estate';
                      id: string;
                      attributes: any;
                      isUnit: boolean;
                      projectId?: Maybe<string>;
                  }>;
              };
          }
        | {
              __typename: 'PropertyVisitedActivity';
              id: string;
              timestamp: any;
              action?: Maybe<ActivityAction>;
              visitedBy?: Maybe<
                  Array<
                      Maybe<
                          | { __typename?: 'Lead'; id: string; firstName: string; lastName: string }
                          | { __typename?: 'Vendor'; id: string; firstName: string; lastName: string }
                      >
                  >
              >;
              property?: Maybe<{
                  __typename?: 'Estate';
                  id: string;
                  attributes: any;
                  isUnit: boolean;
                  projectId?: Maybe<string>;
              }>;
              registeredBy: { __typename?: 'User'; id: string; firstName?: Maybe<string>; lastName?: Maybe<string> };
          }
        | {
              __typename: 'ContactCalledActivity';
              id: string;
              timestamp: any;
              action?: Maybe<ActivityAction>;
              about?: Maybe<{
                  __typename?: 'Estate';
                  id: string;
                  attributes: any;
                  isUnit: boolean;
                  projectId?: Maybe<string>;
              }>;
              calledContact?: Maybe<
                  | { __typename?: 'Lead'; id: string; firstName: string; lastName: string }
                  | { __typename?: 'Vendor'; id: string; firstName: string; lastName: string }
              >;
              calledBy: { __typename?: 'User'; id: string; firstName?: Maybe<string>; lastName?: Maybe<string> };
          }
        | {
              __typename: 'ContactMessagedActivity';
              id: string;
              timestamp: any;
              action?: Maybe<ActivityAction>;
              about?: Maybe<{
                  __typename?: 'Estate';
                  id: string;
                  attributes: any;
                  isUnit: boolean;
                  projectId?: Maybe<string>;
              }>;
              messagedContact?: Maybe<
                  | { __typename?: 'Lead'; id: string; firstName: string; lastName: string }
                  | { __typename?: 'Vendor'; id: string; firstName: string; lastName: string }
              >;
              messagedBy: { __typename?: 'User'; id: string; firstName?: Maybe<string>; lastName?: Maybe<string> };
          }
        | {
              __typename: 'LeadExpressedInterestActivity';
              id: string;
              timestamp?: Maybe<any>;
              action?: Maybe<ActivityAction>;
              interestedLead?: Maybe<
                  | { __typename?: 'Lead'; id: string; firstName: string; lastName: string }
                  | { __typename?: 'Vendor'; id: string; firstName: string; lastName: string }
              >;
              propertyInterestedIn?: Maybe<{
                  __typename?: 'Estate';
                  id: string;
                  attributes: any;
                  isUnit: boolean;
                  projectId?: Maybe<string>;
              }>;
              interestExpressedVia?: Maybe<{ __typename?: 'LeadSource'; name: string }>;
          }
    >;
};

export type UserFragmentFragment = {
    __typename?: 'User';
    id: string;
    office: {
        __typename?: 'Office';
        id: string;
        name: string;
        settings: { __typename?: 'OfficeSettings'; currency: string; numeralSystem: string };
    };
};

export type AddLabelMutationVariables = Exact<{
    input: LabelsInput;
}>;

export type AddLabelMutation = {
    __typename?: 'Mutation';
    createLabel: {
        __typename?: 'Label';
        id: string;
        name: string;
        color: string;
        description?: Maybe<string>;
        created_at?: Maybe<string>;
        updated_at?: Maybe<string>;
    };
};

export type ArchiveLeadsMutationVariables = Exact<{
    input: ArchiveLeadsInput;
}>;

export type ArchiveLeadsMutation = {
    __typename?: 'Mutation';
    archiveLeads: { __typename?: 'ArchiveLeadsPayload'; company: { __typename?: 'Company'; id: string } };
};

export type DeleteLabelMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteLabelMutation = { __typename?: 'Mutation'; deleteLabel?: Maybe<any> };

export type UpdateLabelMutationVariables = Exact<{
    input: LabelsInput;
}>;

export type UpdateLabelMutation = {
    __typename?: 'Mutation';
    updateLabel: {
        __typename?: 'Label';
        id: string;
        name: string;
        color: string;
        description?: Maybe<string>;
        created_at?: Maybe<string>;
        updated_at?: Maybe<string>;
    };
};

export type AddLocationPreferenceMutationVariables = Exact<{
    leadId: Scalars['ID'];
    preferenceId?: Maybe<Scalars['ID']>;
    locationPreference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
    includeLocationData?: Scalars['Boolean'];
}>;

export type AddLocationPreferenceMutation = {
    __typename?: 'Mutation';
    addLocationPreference: {
        __typename?: 'AddLocationPreferencePayload';
        locationPreference:
            | ({
                  __typename?: 'PostalCodesLocationPreference';
              } & LocationPreference_PostalCodesLocationPreference_Fragment)
            | ({ __typename?: 'GeoJSONLocationPreference' } & LocationPreference_GeoJsonLocationPreference_Fragment);
    };
};

export type RemoveLocationPreferenceMutationVariables = Exact<{
    leadId: Scalars['ID'];
    preferenceId?: Maybe<Scalars['ID']>;
    locationPreferenceId: Scalars['ID'];
}>;

export type RemoveLocationPreferenceMutation = {
    __typename?: 'Mutation';
    removeLocationPreference: {
        __typename?: 'RemoveLocationPayload';
        id: string;
        lead: { __typename?: 'Lead'; id: string };
    };
};

export type UpdateContactDetailsMutationVariables = Exact<{
    input: UpdateContactMutationInput;
}>;

export type UpdateContactDetailsMutation = {
    __typename?: 'Mutation';
    updateContactDetails: {
        __typename?: 'UpdateContactMutationPayload';
        success: boolean;
        validationErrors?: Maybe<any>;
        contact?: Maybe<
            | {
                  __typename?: 'Lead';
                  id: string;
                  firstName: string;
                  lastName: string;
                  phone?: Maybe<string>;
                  email?: Maybe<string>;
                  company?: Maybe<string>;
                  locale?: Maybe<string>;
                  pronouns?: Maybe<string>;
                  notes: Array<{ __typename?: 'Note'; text?: Maybe<string> }>;
              }
            | {
                  __typename?: 'Vendor';
                  id: string;
                  firstName: string;
                  lastName: string;
                  phone?: Maybe<string>;
                  email?: Maybe<string>;
                  company?: Maybe<string>;
                  locale?: Maybe<string>;
                  pronouns?: Maybe<string>;
                  notes: Array<{ __typename?: 'Note'; text?: Maybe<string> }>;
              }
        >;
    };
};

export type UpdateContactAddressMutationVariables = Exact<{
    input: UpdateContactAddressMutationInput;
}>;

export type UpdateContactAddressMutation = {
    __typename?: 'Mutation';
    updateContactAddress: {
        __typename?: 'UpdateContactMutationPayload';
        contact?: Maybe<
            { __typename?: 'Lead'; id: string; address: any } | { __typename?: 'Vendor'; id: string; address: any }
        >;
    };
};

export type ArchiveContactMutationVariables = Exact<{
    contactId: Scalars['ID'];
}>;

export type ArchiveContactMutation = {
    __typename?: 'Mutation';
    archiveContact: {
        __typename?: 'UpdateContactMutationPayload';
        contact?: Maybe<
            | { __typename?: 'Lead'; id: string; archived: boolean }
            | { __typename?: 'Vendor'; id: string; archived: boolean }
        >;
    };
};

export type UnarchiveContactMutationVariables = Exact<{
    contactId: Scalars['ID'];
}>;

export type UnarchiveContactMutation = {
    __typename?: 'Mutation';
    unarchiveContact: {
        __typename?: 'UpdateContactMutationPayload';
        contact?: Maybe<
            | { __typename?: 'Lead'; id: string; archived: boolean }
            | { __typename?: 'Vendor'; id: string; archived: boolean }
        >;
    };
};

export type UpdateContactAssignmentsMutationVariables = Exact<{
    input: UpdateContactAssignmentsInput;
}>;

export type UpdateContactAssignmentsMutation = {
    __typename?: 'Mutation';
    updateContactAssignments: {
        __typename?: 'UpdateContactAssignmentsPayload';
        success: boolean;
        errorMessage?: Maybe<string>;
    };
};

export type UpdateContactLabelsMutationVariables = Exact<{
    id: Scalars['ID'];
    labels: Array<Scalars['ID']>;
}>;

export type UpdateContactLabelsMutation = { __typename?: 'Mutation'; updateContactLabels?: Maybe<any> };

export type UpdateLeadPreferenceMutationMutationVariables = Exact<{
    input: UpdateLeadPreferenceInput;
}>;

export type UpdateLeadPreferenceMutationMutation = {
    __typename?: 'Mutation';
    updateLeadPreference: {
        __typename?: 'UpdateLeadPreferencePayload';
        success: boolean;
        preference?: Maybe<
            | ({ __typename?: 'BuyerPreferenceForHouse' } & PreferenceFormFragment_BuyerPreferenceForHouse_Fragment)
            | ({
                  __typename?: 'BuyerPreferenceForApartment';
              } & PreferenceFormFragment_BuyerPreferenceForApartment_Fragment)
            | ({ __typename?: 'BuyerPreferenceForLand' } & PreferenceFormFragment_BuyerPreferenceForLand_Fragment)
            | ({ __typename?: 'BuyerPreferenceForOffice' } & PreferenceFormFragment_BuyerPreferenceForOffice_Fragment)
            | ({
                  __typename?: 'BuyerPreferenceForCommercial';
              } & PreferenceFormFragment_BuyerPreferenceForCommercial_Fragment)
            | ({ __typename?: 'BuyerPreferenceForParking' } & PreferenceFormFragment_BuyerPreferenceForParking_Fragment)
        >;
        userErrors: Array<{ __typename?: 'UserError'; field?: Maybe<Array<string>>; message: string }>;
    };
};

export type UpdateLocationPreferenceMutationVariables = Exact<{
    leadId: Scalars['ID'];
    preferenceId?: Maybe<Scalars['ID']>;
    locationPreferenceId: Scalars['ID'];
    preference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
    includeLocationData?: Scalars['Boolean'];
}>;

export type UpdateLocationPreferenceMutation = {
    __typename?: 'Mutation';
    updateLocationPreference: {
        __typename?: 'UpdateLocationPreferencePayload';
        locationPreference:
            | ({
                  __typename?: 'PostalCodesLocationPreference';
              } & LocationPreference_PostalCodesLocationPreference_Fragment)
            | ({ __typename?: 'GeoJSONLocationPreference' } & LocationPreference_GeoJsonLocationPreference_Fragment);
    };
};

export type SendMatchingEmailsMutationVariables = Exact<{
    input: MatchingEmailsInput;
}>;

export type SendMatchingEmailsMutation = {
    __typename?: 'Mutation';
    matchingEmails: { __typename?: 'MatchingEmailsPayload'; data?: Maybe<any> };
};

export type CreateOfferMutationVariables = Exact<{
    offerInput: CreateOfferInput;
}>;

export type CreateOfferMutation = {
    __typename?: 'Mutation';
    createOffer: { __typename?: 'Offer' } & OfferCardFragmentFragment;
};

export type UpdateOfferMutationVariables = Exact<{
    offerInput: UpdateOfferInput;
}>;

export type UpdateOfferMutation = {
    __typename?: 'Mutation';
    updateOffer: { __typename?: 'Offer' } & OfferCardFragmentFragment;
};

export type ArchiveOfferMutationVariables = Exact<{
    offerId: Scalars['ID'];
}>;

export type ArchiveOfferMutation = {
    __typename?: 'Mutation';
    archiveOffer: { __typename?: 'Offer' } & OfferCardFragmentFragment;
};

export type RestoreOfferMutationVariables = Exact<{
    offerId: Scalars['ID'];
}>;

export type RestoreOfferMutation = {
    __typename?: 'Mutation';
    restoreOffer: { __typename?: 'Offer' } & OfferCardFragmentFragment;
};

export type AcceptOfferMutationVariables = Exact<{
    offerId: Scalars['ID'];
}>;

export type AcceptOfferMutation = {
    __typename?: 'Mutation';
    acceptOffer: { __typename?: 'Offer' } & OfferCardFragmentFragment;
};

export type RefuseOfferMutationVariables = Exact<{
    offerId: Scalars['ID'];
}>;

export type RefuseOfferMutation = {
    __typename?: 'Mutation';
    refuseOffer: { __typename?: 'Offer' } & OfferCardFragmentFragment;
};

export type CancelOfferMutationVariables = Exact<{
    offerId: Scalars['ID'];
}>;

export type CancelOfferMutation = {
    __typename?: 'Mutation';
    cancelOffer: { __typename?: 'Offer' } & OfferCardFragmentFragment;
};

export type AddGeoTemplateMutationVariables = Exact<{
    officeId: Scalars['ID'];
    input: GeoTemplatesInput;
}>;

export type AddGeoTemplateMutation = {
    __typename?: 'Mutation';
    addGeoTemplate: {
        __typename?: 'GeoTemplate';
        _id?: Maybe<string>;
        title: string;
        office_id?: Maybe<string>;
        type?: Maybe<string>;
        postalCodes?: Maybe<Array<Maybe<string>>>;
        geometry?: Maybe<any>;
    };
};

export type AddLegalEntityMutationVariables = Exact<{
    officeId: Scalars['ID'];
    input: LegalEntitiesInput;
}>;

export type AddLegalEntityMutation = {
    __typename?: 'Mutation';
    addLegalEntity: { __typename?: 'LegalEntities'; id?: Maybe<string>; name: string; office_id?: Maybe<string> };
};

export type AddLocationTemplateMutationVariables = Exact<{
    officeId: Scalars['ID'];
    preference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
    includeLocationData?: Scalars['Boolean'];
}>;

export type AddLocationTemplateMutation = {
    __typename?: 'Mutation';
    addLocationPreferenceTemplate: {
        __typename?: 'AddLocationTemplatePayload';
        officeId: string;
        locationTemplate:
            | ({
                  __typename?: 'PostalCodesLocationPreference';
              } & LocationPreference_PostalCodesLocationPreference_Fragment)
            | ({ __typename?: 'GeoJSONLocationPreference' } & LocationPreference_GeoJsonLocationPreference_Fragment);
    };
};

export type AssignLeadsToOfficeMutationVariables = Exact<{
    officeId: Scalars['ID'];
    leadIds: Array<Scalars['ID']>;
}>;

export type AssignLeadsToOfficeMutation = {
    __typename?: 'Mutation';
    assignLeadsToOffice: {
        __typename?: 'AssignLeadsPayload';
        success: boolean;
        assignedLeads: Array<{ __typename?: 'Lead'; id: string } | { __typename?: 'Vendor'; id: string }>;
    };
};

export type CloneLocationTemplateMutationVariables = Exact<{
    officeId: Scalars['ID'];
    locationTemplateId: Scalars['ID'];
    preferenceId?: Maybe<Scalars['ID']>;
    leadId: Scalars['ID'];
    includeLocationData?: Scalars['Boolean'];
}>;

export type CloneLocationTemplateMutation = {
    __typename?: 'Mutation';
    cloneLocationPreferenceTemplate: {
        __typename?: 'CloneLocationTemplatePayload';
        locationPreference:
            | ({
                  __typename?: 'PostalCodesLocationPreference';
              } & LocationPreference_PostalCodesLocationPreference_Fragment)
            | ({ __typename?: 'GeoJSONLocationPreference' } & LocationPreference_GeoJsonLocationPreference_Fragment);
    };
};

export type DeleteGeoTemplateMutationVariables = Exact<{
    officeId: Scalars['ID'];
    input: GeoTemplatesInput;
}>;

export type DeleteGeoTemplateMutation = {
    __typename?: 'Mutation';
    deleteGeoTemplate?: Maybe<{ __typename?: 'GeoTemplatesDeletePayload'; _id?: Maybe<string> }>;
};

export type DeleteLegalEntityMutationVariables = Exact<{
    officeId: Scalars['ID'];
    input: LegalEntitiesInput;
}>;

export type DeleteLegalEntityMutation = {
    __typename?: 'Mutation';
    deleteLegalEntity?: Maybe<{ __typename?: 'LegalEntitiesDeletePayload'; id?: Maybe<string> }>;
};

export type RemoveLocationTemplateMutationVariables = Exact<{
    officeId: Scalars['ID'];
    locationId: Scalars['ID'];
}>;

export type RemoveLocationTemplateMutation = {
    __typename?: 'Mutation';
    removeLocationPreferenceTemplate: {
        __typename?: 'RemoveLocationTemplatePayload';
        id: string;
        office: { __typename?: 'Office'; id: string };
    };
};

export type UpdateGeoTemplateMutationVariables = Exact<{
    officeId: Scalars['ID'];
    input: GeoTemplatesInput;
}>;

export type UpdateGeoTemplateMutation = {
    __typename?: 'Mutation';
    updateGeoTemplate: {
        __typename?: 'GeoTemplate';
        _id?: Maybe<string>;
        title: string;
        office_id?: Maybe<string>;
        type?: Maybe<string>;
        postalCodes?: Maybe<Array<Maybe<string>>>;
        geometry?: Maybe<any>;
    };
};

export type UpdateLegalEntityMutationVariables = Exact<{
    officeId: Scalars['ID'];
    input: LegalEntitiesInput;
}>;

export type UpdateLegalEntityMutation = {
    __typename?: 'Mutation';
    updateLegalEntity: { __typename?: 'LegalEntities'; id?: Maybe<string>; name: string; office_id?: Maybe<string> };
};

export type UpdateLocationTemplateMutationVariables = Exact<{
    officeId: Scalars['ID'];
    locationId: Scalars['ID'];
    preference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
    includeLocationData?: Scalars['Boolean'];
}>;

export type UpdateLocationTemplateMutation = {
    __typename?: 'Mutation';
    updateLocationPreferenceTemplate: {
        __typename?: 'UpdateLocationTemplatePayload';
        locationTemplate:
            | ({
                  __typename?: 'PostalCodesLocationPreference';
              } & LocationPreference_PostalCodesLocationPreference_Fragment)
            | ({ __typename?: 'GeoJSONLocationPreference' } & LocationPreference_GeoJsonLocationPreference_Fragment);
    };
};

export type GenerateDescriptionMutationVariables = Exact<{
    generateInput: GenerateDescriptionInput;
}>;

export type GenerateDescriptionMutation = {
    __typename?: 'Mutation';
    generateDescription: { __typename?: 'GenerateDescriptionPayload'; propertyId: string; text: string };
};

export type TranslateMutationVariables = Exact<{
    translateInput: TranslateInput;
}>;

export type TranslateMutation = {
    __typename?: 'Mutation';
    translate: { __typename?: 'TranslatePayload'; outputLanguage: string; text: string };
};

export type AssignLegalEntityMutationVariables = Exact<{
    input: AssignLegalEntityToEstateInput;
}>;

export type AssignLegalEntityMutation = {
    __typename?: 'Mutation';
    assignLegalEntity?: Maybe<{
        __typename?: 'AssignLegalEntityToPayload';
        estate?: Maybe<{ __typename?: 'Estate'; id: string }>;
    }>;
};

export type UpdatePublicationMutationVariables = Exact<{
    input: PublishPropertyInput;
    channelAccountId: Scalars['ID'];
}>;

export type UpdatePublicationMutation = {
    __typename?: 'Mutation';
    updatePublication: {
        __typename?: 'PublishPropertyPayload';
        estate?: Maybe<{
            __typename?: 'Estate';
            id: string;
            channelAccount?: Maybe<
                { __typename?: 'EstateChannelAccountConnectionEdge' } & EstateChannelAccountEdgeFragmentFragment
            >;
        }>;
    };
};

export type CreatePublicationMutationVariables = Exact<{
    input: PublishPropertyInput;
    channelAccountId: Scalars['ID'];
}>;

export type CreatePublicationMutation = {
    __typename?: 'Mutation';
    createPublication: {
        __typename?: 'PublishPropertyPayload';
        estate?: Maybe<{
            __typename?: 'Estate';
            id: string;
            channelAccount?: Maybe<
                { __typename?: 'EstateChannelAccountConnectionEdge' } & EstateChannelAccountEdgeFragmentFragment
            >;
        }>;
    };
};

export type RemovePublicationMutationVariables = Exact<{
    input: UnpublishPropertyInput;
    channelAccountId: Scalars['ID'];
}>;

export type RemovePublicationMutation = {
    __typename?: 'Mutation';
    removePublication: {
        __typename?: 'PublishPropertyPayload';
        estate?: Maybe<{
            __typename?: 'Estate';
            id: string;
            channelAccount?: Maybe<
                { __typename?: 'EstateChannelAccountConnectionEdge' } & EstateChannelAccountEdgeFragmentFragment
            >;
        }>;
    };
};

export type RetryPublicationMutationVariables = Exact<{
    input: RetryPublicationInput;
}>;

export type RetryPublicationMutation = {
    __typename?: 'Mutation';
    retryPublication: {
        __typename?: 'RetryPublicationPayload';
        channelAccount: {
            __typename?: 'EstateChannelAccountConnectionEdge';
        } & EstateChannelAccountEdgeFragmentFragment;
    };
};

export type RefreshPublicationMutationVariables = Exact<{
    input: RefreshPublicationInput;
}>;

export type RefreshPublicationMutation = {
    __typename?: 'Mutation';
    refreshPublication: { __typename?: 'RefreshPublicationPayload'; success: boolean };
};

export type GetFrenchPortalsMutationVariables = Exact<{
    input: GetFrenchPortalsInput;
}>;

export type GetFrenchPortalsMutation = {
    __typename?: 'Mutation';
    getFrenchPortals: {
        __typename?: 'GetFrenchPortalsPayload';
        portals: Array<{
            __typename?: 'FrenchPortal';
            automatic?: Maybe<boolean>;
            code?: Maybe<string>;
            free?: Maybe<boolean>;
            id?: Maybe<number>;
            logoUrl?: Maybe<string>;
            name?: Maybe<string>;
            url?: Maybe<string>;
        }>;
    };
};

export type RegisterEstateActivityMutationVariables = Exact<{
    input: RegisterEstateActivityInput;
}>;

export type RegisterEstateActivityMutation = {
    __typename?: 'Mutation';
    registerEstateActivity: {
        __typename?: 'RegisterEstateActivityPayload';
        subject: { __typename?: 'Estate'; id: string };
        activity:
            | { __typename?: 'VendorReportEmailedActivity'; id: string }
            | { __typename?: 'ContactEmailedActivity'; id: string }
            | { __typename?: 'VisitScheduledActivity'; id: string }
            | { __typename?: 'PropertyVisitedActivity'; id: string }
            | { __typename?: 'ContactCalledActivity'; id: string }
            | { __typename?: 'ContactMessagedActivity'; id: string }
            | { __typename?: 'LeadExpressedInterestActivity'; id: string };
        contact?: Maybe<{ __typename?: 'Lead'; id: string } | { __typename?: 'Vendor'; id: string }>;
    };
};

export type UnassignLegalEntityMutationVariables = Exact<{
    input: UnassignLegalEntityToEstateInput;
}>;

export type UnassignLegalEntityMutation = {
    __typename?: 'Mutation';
    unassignLegalEntity?: Maybe<{
        __typename?: 'UnassignLegalEntityToPayload';
        estate?: Maybe<{ __typename?: 'Estate'; id: string }>;
    }>;
};

export type UpdateEstateMutationVariables = Exact<{
    estateId: Scalars['ID'];
    operations: Scalars['JSON'];
    estateType: EstateType;
}>;

export type UpdateEstateMutation = {
    __typename?: 'Mutation';
    updateEstate: {
        __typename?: 'UpdateEstatePayload';
        success?: Maybe<boolean>;
        estate?: Maybe<{ __typename?: 'Estate'; id: string } & PropertyCardFragmentFragment>;
        userErrors: Array<{ __typename?: 'UserError'; field?: Maybe<Array<string>>; message: string }>;
    };
};

export type UpdateEstateStatusMutationVariables = Exact<{
    id: Scalars['ID'];
    status: Scalars['String'];
}>;

export type UpdateEstateStatusMutation = {
    __typename?: 'Mutation';
    updateEstateStatus?: Maybe<{ __typename?: 'Estate'; id: string; status: string; attributes: any }>;
};

export type UpdatePropertyVisibilityMutationVariables = Exact<{
    propertyId: Scalars['ID'];
    visibility: Scalars['String'];
}>;

export type UpdatePropertyVisibilityMutation = {
    __typename?: 'Mutation';
    updatePropertyVisibility?: Maybe<{ __typename?: 'UpdatePropertyVisibilityPayload'; propertyId?: Maybe<string> }>;
};

export type ArchiveUserMutationVariables = Exact<{
    userId: Scalars['ID'];
}>;

export type ArchiveUserMutation = { __typename?: 'Mutation'; archiveUser: boolean };

export type InviteOfficeUsersMutationVariables = Exact<{
    invite: InviteInput;
    officeId: Scalars['ID'];
}>;

export type InviteOfficeUsersMutation = { __typename?: 'Mutation'; inviteUsers: boolean };

export type InviteCompanyAdminsMutationVariables = Exact<{
    invite: InviteInput;
    companyId: Scalars['ID'];
}>;

export type InviteCompanyAdminsMutation = { __typename?: 'Mutation'; inviteUsers: boolean };

export type UpdateUserOfficeMutationVariables = Exact<{
    officeId: Scalars['ID'];
}>;

export type UpdateUserOfficeMutation = {
    __typename?: 'Mutation';
    updateUserOffice: { __typename?: 'User' } & UserFragmentFragment;
};

export type UpdateUserRoleMutationVariables = Exact<{
    officeId: Scalars['ID'];
    userId: Scalars['ID'];
    role: UserRole;
}>;

export type UpdateUserRoleMutation = { __typename?: 'Mutation'; updateUserRole: boolean };

export type UpdateUserTeamsMutationVariables = Exact<{
    userId: Scalars['ID'];
    teamsIds: Array<Scalars['ID']>;
}>;

export type UpdateUserTeamsMutation = { __typename?: 'Mutation'; updateUserTeams: boolean };

export type GetActivityQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetActivityQuery = {
    __typename?: 'Query';
    activity?: Maybe<
        | {
              __typename: 'VendorReportEmailedActivity';
              id: string;
              action?: Maybe<ActivityAction>;
              details?: Maybe<
                  | { __typename: 'MessageActivityDetails'; message?: Maybe<string>; id: string }
                  | {
                        __typename: 'FeedbackActivityDetails';
                        externalComment?: Maybe<string>;
                        internalComment?: Maybe<string>;
                        type: string;
                        id: string;
                    }
              >;
          }
        | {
              __typename: 'ContactEmailedActivity';
              id: string;
              action?: Maybe<ActivityAction>;
              details?: Maybe<
                  | { __typename: 'MessageActivityDetails'; message?: Maybe<string>; id: string }
                  | {
                        __typename: 'FeedbackActivityDetails';
                        externalComment?: Maybe<string>;
                        internalComment?: Maybe<string>;
                        type: string;
                        id: string;
                    }
              >;
          }
        | {
              __typename: 'VisitScheduledActivity';
              id: string;
              action?: Maybe<ActivityAction>;
              details?: Maybe<
                  | { __typename: 'MessageActivityDetails'; message?: Maybe<string>; id: string }
                  | {
                        __typename: 'FeedbackActivityDetails';
                        externalComment?: Maybe<string>;
                        internalComment?: Maybe<string>;
                        type: string;
                        id: string;
                    }
              >;
          }
        | {
              __typename: 'PropertyVisitedActivity';
              id: string;
              action?: Maybe<ActivityAction>;
              details?: Maybe<
                  | { __typename: 'MessageActivityDetails'; message?: Maybe<string>; id: string }
                  | {
                        __typename: 'FeedbackActivityDetails';
                        externalComment?: Maybe<string>;
                        internalComment?: Maybe<string>;
                        type: string;
                        id: string;
                    }
              >;
          }
        | {
              __typename: 'ContactCalledActivity';
              id: string;
              action?: Maybe<ActivityAction>;
              details?: Maybe<
                  | { __typename: 'MessageActivityDetails'; message?: Maybe<string>; id: string }
                  | {
                        __typename: 'FeedbackActivityDetails';
                        externalComment?: Maybe<string>;
                        internalComment?: Maybe<string>;
                        type: string;
                        id: string;
                    }
              >;
          }
        | {
              __typename: 'ContactMessagedActivity';
              id: string;
              action?: Maybe<ActivityAction>;
              details?: Maybe<
                  | { __typename: 'MessageActivityDetails'; message?: Maybe<string>; id: string }
                  | {
                        __typename: 'FeedbackActivityDetails';
                        externalComment?: Maybe<string>;
                        internalComment?: Maybe<string>;
                        type: string;
                        id: string;
                    }
              >;
          }
        | {
              __typename: 'LeadExpressedInterestActivity';
              id: string;
              action?: Maybe<ActivityAction>;
              details?: Maybe<{ __typename: 'MessageActivityDetails'; message?: Maybe<string>; id: string }>;
          }
    >;
};

export type AuthCheckQueryVariables = Exact<{
    input?: Maybe<Array<AuthCheckInput>>;
}>;

export type AuthCheckQuery = { __typename?: 'Query'; authCheck?: Maybe<any> };

export type GetChannelAccountQueryVariables = Exact<{
    companyId?: Maybe<Scalars['String']>;
    accountId?: Maybe<Scalars['String']>;
}>;

export type GetChannelAccountQuery = {
    __typename?: 'Query';
    channelAccount?: Maybe<{
        __typename?: 'ChannelAccount';
        id: string;
        name: string;
        accessToken?: Maybe<string>;
        accessTokenSecret?: Maybe<string>;
        status: string;
        office_id?: Maybe<string>;
        webhook?: Maybe<string>;
        credentials: {
            __typename?: 'ChannelAccountCredentials';
            name?: Maybe<string>;
            accessToken?: Maybe<string>;
            accessTokenSecret?: Maybe<string>;
            additionalAccessId?: Maybe<string>;
        };
        channel: {
            __typename?: 'Channel';
            id: string;
            name: string;
            slug: string;
            logoUrl?: Maybe<string>;
            type: string;
            countries: Array<string>;
        };
    }>;
};

export type GetCompanyAdminsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
}>;

export type GetCompanyAdminsQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'User';
        id: string;
        company?: Maybe<{
            __typename?: 'Company';
            id: string;
            admins: {
                __typename?: 'CompanyAdminsConnection';
                totalCount: number;
                pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean; itemsPerPage: number };
                edges: Array<{
                    __typename?: 'CompanyAdminConnectionEdge';
                    node: {
                        __typename?: 'User';
                        id: string;
                        firstName?: Maybe<string>;
                        lastName?: Maybe<string>;
                        email: string;
                        role: string;
                        phone?: Maybe<string>;
                        photo?: Maybe<any>;
                    };
                }>;
            };
        }>;
    };
};

export type GetLabelsQueryVariables = Exact<{
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
}>;

export type GetLabelsQuery = {
    __typename?: 'Query';
    labels?: Maybe<{
        __typename?: 'LabelsPayload';
        meta?: Maybe<{
            __typename?: 'LabelsMeta';
            pagination: {
                __typename?: 'LabelsPagination';
                current_page?: Maybe<number>;
                total_pages?: Maybe<number>;
                total?: Maybe<number>;
                per_page?: Maybe<number>;
            };
        }>;
        data: Array<{
            __typename?: 'Label';
            id: string;
            name: string;
            color: string;
            description?: Maybe<string>;
            created_at?: Maybe<string>;
            updated_at?: Maybe<string>;
        }>;
    }>;
};

export type GetLabelQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetLabelQuery = {
    __typename?: 'Query';
    label?: Maybe<{
        __typename?: 'Label';
        id: string;
        name: string;
        color: string;
        description?: Maybe<string>;
        created_at?: Maybe<string>;
        updated_at?: Maybe<string>;
    }>;
};

export type GetCompanyLeadsQueryVariables = Exact<{
    type: ContactType;
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
}>;

export type GetCompanyLeadsQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'User';
        id: string;
        company?: Maybe<{
            __typename?: 'Company';
            id: string;
            leads: {
                __typename?: 'CompanyLeadsConnection';
                totalCount: number;
                pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean; itemsPerPage: number };
                edges: Array<{
                    __typename?: 'CompanyLeadConnectionEdge';
                    node: {
                        __typename?: 'BuyerLead';
                        id: string;
                        firstName?: Maybe<string>;
                        lastName?: Maybe<string>;
                        createdAt?: Maybe<any>;
                    };
                }>;
            };
        }>;
    };
};

type ContactInformationForm_Lead_Fragment = {
    __typename?: 'Lead';
    firstName: string;
    lastName: string;
    email?: Maybe<string>;
    phone?: Maybe<string>;
    type: ContactType;
    company?: Maybe<string>;
    address: any;
    locale?: Maybe<string>;
    pronouns?: Maybe<string>;
    createdAt: any;
    updatedAt: any;
    officeId?: Maybe<string>;
    photo?: Maybe<{ __typename?: 'ContactPhoto'; id: string; uploadedAt?: Maybe<string>; url: string }>;
    notes: Array<{ __typename?: 'Note'; text?: Maybe<string> }>;
    assignedNegotiators?: Maybe<
        Array<{
            __typename?: 'ContactAssignedNegotiator';
            id: number;
            first_name?: Maybe<string>;
            last_name?: Maybe<string>;
            picture_url?: Maybe<string>;
        }>
    >;
};

type ContactInformationForm_Vendor_Fragment = {
    __typename?: 'Vendor';
    firstName: string;
    lastName: string;
    email?: Maybe<string>;
    phone?: Maybe<string>;
    type: ContactType;
    company?: Maybe<string>;
    address: any;
    locale?: Maybe<string>;
    pronouns?: Maybe<string>;
    createdAt: any;
    updatedAt: any;
    officeId?: Maybe<string>;
    photo?: Maybe<{ __typename?: 'ContactPhoto'; id: string; uploadedAt?: Maybe<string>; url: string }>;
    notes: Array<{ __typename?: 'Note'; text?: Maybe<string> }>;
    assignedNegotiators?: Maybe<
        Array<{
            __typename?: 'ContactAssignedNegotiator';
            id: number;
            first_name?: Maybe<string>;
            last_name?: Maybe<string>;
            picture_url?: Maybe<string>;
        }>
    >;
};

export type ContactInformationFormFragment =
    | ContactInformationForm_Lead_Fragment
    | ContactInformationForm_Vendor_Fragment;

export type GetContactQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetContactQuery = {
    __typename?: 'Query';
    contact?: Maybe<
        | ({ __typename?: 'Lead' } & ContactCardFragment_Lead_Fragment & ContactInformationForm_Lead_Fragment)
        | ({ __typename?: 'Vendor' } & ContactCardFragment_Vendor_Fragment & ContactInformationForm_Vendor_Fragment)
    >;
};

export type GetContactLabelsQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetContactLabelsQuery = {
    __typename?: 'Query';
    contactLabels: Array<{
        __typename?: 'Label';
        id: string;
        name: string;
        color: string;
        description?: Maybe<string>;
        created_at?: Maybe<string>;
        updated_at?: Maybe<string>;
    }>;
};

export type GetContactsOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetContactsOptionsQuery = {
    __typename?: 'Query';
    contactsOptions?: Maybe<{
        __typename?: 'ContactsOptions';
        sorts: Array<{ __typename?: 'ContactsSortOption'; key: string }>;
    }>;
};

export type GetContactReferenceQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetContactReferenceQuery = {
    __typename?: 'Query';
    contactReference?: Maybe<{
        __typename?: 'ContactReference';
        assignee?: Maybe<{
            __typename?: 'ContactReferenceAssignee';
            id: string;
            first_name?: Maybe<string>;
            last_name?: Maybe<string>;
            phone?: Maybe<string>;
            email?: Maybe<string>;
            picture_url?: Maybe<string>;
        }>;
        team?: Maybe<{ __typename?: 'ContactReferenceTeam'; id: string; name: string }>;
    }>;
};

export type GetContactTimelineQueryVariables = Exact<{
    id: Scalars['ID'];
    propertyId?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
}>;

export type GetContactTimelineQuery = {
    __typename?: 'Query';
    contact?: Maybe<
        | {
              __typename?: 'Lead';
              id: string;
              timeline: {
                  __typename?: 'ContactTimeline';
                  activities: { __typename?: 'ActivityConnection' } & TimelineActivitiesFragment;
              };
          }
        | {
              __typename?: 'Vendor';
              id: string;
              timeline: {
                  __typename?: 'ContactTimeline';
                  activities: { __typename?: 'ActivityConnection' } & TimelineActivitiesFragment;
              };
          }
    >;
};

export type GetContactsQueryVariables = Exact<{
    filters?: Maybe<ContactFiltersInput>;
    query?: Maybe<Scalars['String']>;
    sortOrder?: Maybe<Scalars['String']>;
    sortField?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    locale?: Maybe<Scalars['String']>;
    useAtlasSearch?: Maybe<Scalars['Boolean']>;
}>;

export type GetContactsQuery = {
    __typename?: 'Query';
    contacts: {
        __typename?: 'ContactConnection';
        totalCount: number;
        pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
        edges: Array<{
            __typename?: 'ContactEdge';
            node:
                | {
                      __typename?: 'Lead';
                      lastAction?: Maybe<string>;
                      lastInteraction?: Maybe<any>;
                      id: string;
                      firstName: string;
                      lastName: string;
                      email?: Maybe<string>;
                      phone?: Maybe<string>;
                      type: ContactType;
                      note?: Maybe<string>;
                      locale?: Maybe<string>;
                      archived: boolean;
                      subscribed?: Maybe<boolean>;
                      createdAt: any;
                      updatedAt: any;
                      officeId?: Maybe<string>;
                      formattedPhone?: Maybe<string>;
                      photo?: Maybe<{ __typename?: 'ContactPhoto'; id: string; url: string }>;
                      offersStats?: Maybe<{
                          __typename?: 'OffersStats';
                          number_of_pending_offers: number;
                          highest_offer_bid: number;
                          has_accepted_offer: boolean;
                          closest_offer_expirations: Array<any>;
                          currency: any;
                      }>;
                      assignedNegotiators?: Maybe<
                          Array<{
                              __typename?: 'ContactAssignedNegotiator';
                              id: number;
                              first_name?: Maybe<string>;
                              last_name?: Maybe<string>;
                              picture_url?: Maybe<string>;
                          }>
                      >;
                  }
                | {
                      __typename?: 'Vendor';
                      id: string;
                      firstName: string;
                      lastName: string;
                      email?: Maybe<string>;
                      phone?: Maybe<string>;
                      type: ContactType;
                      note?: Maybe<string>;
                      locale?: Maybe<string>;
                      archived: boolean;
                      subscribed?: Maybe<boolean>;
                      createdAt: any;
                      updatedAt: any;
                      officeId?: Maybe<string>;
                      formattedPhone?: Maybe<string>;
                      photo?: Maybe<{ __typename?: 'ContactPhoto'; id: string; url: string }>;
                      offersStats?: Maybe<{
                          __typename?: 'OffersStats';
                          number_of_pending_offers: number;
                          highest_offer_bid: number;
                          has_accepted_offer: boolean;
                          closest_offer_expirations: Array<any>;
                          currency: any;
                      }>;
                      assignedNegotiators?: Maybe<
                          Array<{
                              __typename?: 'ContactAssignedNegotiator';
                              id: number;
                              first_name?: Maybe<string>;
                              last_name?: Maybe<string>;
                              picture_url?: Maybe<string>;
                          }>
                      >;
                  };
        }>;
    };
};

export type GetEstatesToVisitQueryVariables = Exact<{
    id: Scalars['ID'];
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
}>;

export type GetEstatesToVisitQuery = {
    __typename?: 'Query';
    contact?: Maybe<
        | {
              __typename?: 'Lead';
              id: string;
              eligibleEstates: {
                  __typename?: 'EstatesConnection';
                  totalCount: number;
                  pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
                  nodes: Array<{ __typename?: 'Estate' } & PropertyCardFragmentFragment>;
              };
          }
        | {
              __typename?: 'Vendor';
              id: string;
              eligibleEstates: {
                  __typename?: 'EstatesConnection';
                  totalCount: number;
                  pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
                  nodes: Array<{ __typename?: 'Estate' } & PropertyCardFragmentFragment>;
              };
          }
    >;
};

export type GetInteractedPropertiesQueryVariables = Exact<{
    contactId: Scalars['ID'];
    afterInterestedProperties?: Maybe<Scalars['String']>;
    afterNotInterestedProperties?: Maybe<Scalars['String']>;
    beforeInterestedProperties?: Maybe<Scalars['String']>;
    beforeNotInterestedProperties?: Maybe<Scalars['String']>;
}>;

export type GetInteractedPropertiesQuery = {
    __typename?: 'Query';
    contact?: Maybe<
        | {
              __typename?: 'Lead';
              id: string;
              interestedProperties: {
                  __typename?: 'EstatesConnection';
                  nodes: Array<{ __typename?: 'Estate' } & PropertyCardFragment>;
                  pageInfo: {
                      __typename?: 'PageInfo';
                      hasNextPage: boolean;
                      endCursor?: Maybe<string>;
                      startCursor?: Maybe<string>;
                  };
              };
              notInterestedProperties: {
                  __typename?: 'EstatesConnection';
                  nodes: Array<{ __typename?: 'Estate' } & PropertyCardFragment>;
                  pageInfo: {
                      __typename?: 'PageInfo';
                      hasNextPage: boolean;
                      endCursor?: Maybe<string>;
                      startCursor?: Maybe<string>;
                  };
              };
          }
        | {
              __typename?: 'Vendor';
              id: string;
              interestedProperties: {
                  __typename?: 'EstatesConnection';
                  nodes: Array<{ __typename?: 'Estate' } & PropertyCardFragment>;
                  pageInfo: {
                      __typename?: 'PageInfo';
                      hasNextPage: boolean;
                      endCursor?: Maybe<string>;
                      startCursor?: Maybe<string>;
                  };
              };
              notInterestedProperties: {
                  __typename?: 'EstatesConnection';
                  nodes: Array<{ __typename?: 'Estate' } & PropertyCardFragment>;
                  pageInfo: {
                      __typename?: 'PageInfo';
                      hasNextPage: boolean;
                      endCursor?: Maybe<string>;
                      startCursor?: Maybe<string>;
                  };
              };
          }
    >;
};

export type PropertyCardFragment = {
    __typename?: 'Estate';
    id: string;
    type: string;
    negotiation: string;
    attributes: any;
    lastInteraction?: Maybe<any>;
    negotiator?: Maybe<{ __typename?: 'User'; id: string; first_name?: Maybe<string>; last_name?: Maybe<string> }>;
};

export type GetLeadQueryVariables = Exact<{
    contactId: Scalars['ID'];
}>;

export type GetLeadQuery = {
    __typename?: 'Query';
    lead?: Maybe<{ __typename?: 'Lead'; id: string } & ContactCardFragment_Lead_Fragment>;
};

export type GetLeadPreferenceQueryVariables = Exact<{
    contactId: Scalars['ID'];
    preferenceId: Scalars['ID'];
}>;

export type GetLeadPreferenceQuery = {
    __typename?: 'Query';
    contact?: Maybe<
        | {
              __typename?: 'Lead';
              id: string;
              archived: boolean;
              preference?: Maybe<
                  | ({
                        __typename?: 'BuyerPreferenceForHouse';
                    } & PreferenceFormFragment_BuyerPreferenceForHouse_Fragment)
                  | ({
                        __typename?: 'BuyerPreferenceForApartment';
                    } & PreferenceFormFragment_BuyerPreferenceForApartment_Fragment)
                  | ({ __typename?: 'BuyerPreferenceForLand' } & PreferenceFormFragment_BuyerPreferenceForLand_Fragment)
                  | ({
                        __typename?: 'BuyerPreferenceForOffice';
                    } & PreferenceFormFragment_BuyerPreferenceForOffice_Fragment)
                  | ({
                        __typename?: 'BuyerPreferenceForCommercial';
                    } & PreferenceFormFragment_BuyerPreferenceForCommercial_Fragment)
                  | ({
                        __typename?: 'BuyerPreferenceForParking';
                    } & PreferenceFormFragment_BuyerPreferenceForParking_Fragment)
              >;
          }
        | { __typename?: 'Vendor'; id: string; archived: boolean }
    >;
};

export type GetLocationPreferencesForLeadPreferenceQueryVariables = Exact<{
    contactId: Scalars['ID'];
    preferenceId: Scalars['ID'];
    includeLocationData?: Scalars['Boolean'];
}>;

export type GetLocationPreferencesForLeadPreferenceQuery = {
    __typename?: 'Query';
    contact?: Maybe<
        | {
              __typename?: 'Lead';
              id: string;
              preference?: Maybe<
                  | {
                        __typename?: 'BuyerPreferenceForHouse';
                        id: string;
                        locations: {
                            __typename?: 'LocationPreferenceConnection';
                            totalCount: number;
                            pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
                            edges: Array<{
                                __typename?: 'LocationPreferenceEdge';
                                node:
                                    | ({
                                          __typename?: 'PostalCodesLocationPreference';
                                      } & LocationPreference_PostalCodesLocationPreference_Fragment)
                                    | ({
                                          __typename?: 'GeoJSONLocationPreference';
                                      } & LocationPreference_GeoJsonLocationPreference_Fragment);
                            }>;
                        };
                    }
                  | {
                        __typename?: 'BuyerPreferenceForApartment';
                        id: string;
                        locations: {
                            __typename?: 'LocationPreferenceConnection';
                            totalCount: number;
                            pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
                            edges: Array<{
                                __typename?: 'LocationPreferenceEdge';
                                node:
                                    | ({
                                          __typename?: 'PostalCodesLocationPreference';
                                      } & LocationPreference_PostalCodesLocationPreference_Fragment)
                                    | ({
                                          __typename?: 'GeoJSONLocationPreference';
                                      } & LocationPreference_GeoJsonLocationPreference_Fragment);
                            }>;
                        };
                    }
                  | {
                        __typename?: 'BuyerPreferenceForLand';
                        id: string;
                        locations: {
                            __typename?: 'LocationPreferenceConnection';
                            totalCount: number;
                            pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
                            edges: Array<{
                                __typename?: 'LocationPreferenceEdge';
                                node:
                                    | ({
                                          __typename?: 'PostalCodesLocationPreference';
                                      } & LocationPreference_PostalCodesLocationPreference_Fragment)
                                    | ({
                                          __typename?: 'GeoJSONLocationPreference';
                                      } & LocationPreference_GeoJsonLocationPreference_Fragment);
                            }>;
                        };
                    }
                  | {
                        __typename?: 'BuyerPreferenceForOffice';
                        id: string;
                        locations: {
                            __typename?: 'LocationPreferenceConnection';
                            totalCount: number;
                            pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
                            edges: Array<{
                                __typename?: 'LocationPreferenceEdge';
                                node:
                                    | ({
                                          __typename?: 'PostalCodesLocationPreference';
                                      } & LocationPreference_PostalCodesLocationPreference_Fragment)
                                    | ({
                                          __typename?: 'GeoJSONLocationPreference';
                                      } & LocationPreference_GeoJsonLocationPreference_Fragment);
                            }>;
                        };
                    }
                  | {
                        __typename?: 'BuyerPreferenceForCommercial';
                        id: string;
                        locations: {
                            __typename?: 'LocationPreferenceConnection';
                            totalCount: number;
                            pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
                            edges: Array<{
                                __typename?: 'LocationPreferenceEdge';
                                node:
                                    | ({
                                          __typename?: 'PostalCodesLocationPreference';
                                      } & LocationPreference_PostalCodesLocationPreference_Fragment)
                                    | ({
                                          __typename?: 'GeoJSONLocationPreference';
                                      } & LocationPreference_GeoJsonLocationPreference_Fragment);
                            }>;
                        };
                    }
                  | {
                        __typename?: 'BuyerPreferenceForParking';
                        id: string;
                        locations: {
                            __typename?: 'LocationPreferenceConnection';
                            totalCount: number;
                            pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
                            edges: Array<{
                                __typename?: 'LocationPreferenceEdge';
                                node:
                                    | ({
                                          __typename?: 'PostalCodesLocationPreference';
                                      } & LocationPreference_PostalCodesLocationPreference_Fragment)
                                    | ({
                                          __typename?: 'GeoJSONLocationPreference';
                                      } & LocationPreference_GeoJsonLocationPreference_Fragment);
                            }>;
                        };
                    }
              >;
          }
        | { __typename?: 'Vendor'; id: string }
    >;
};

export type GetContactPreferencesQueryVariables = Exact<{
    contactId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
}>;

export type GetContactPreferencesQuery = {
    __typename?: 'Query';
    contact?: Maybe<
        | {
              __typename?: 'Lead';
              id: string;
              allPreferences: {
                  __typename?: 'BuyerPreferencesConnection';
                  totalCount: number;
                  nodes: Array<
                      | ({
                            __typename?: 'BuyerPreferenceForHouse';
                        } & PreferenceCardFragment_BuyerPreferenceForHouse_Fragment)
                      | ({
                            __typename?: 'BuyerPreferenceForApartment';
                        } & PreferenceCardFragment_BuyerPreferenceForApartment_Fragment)
                      | ({
                            __typename?: 'BuyerPreferenceForLand';
                        } & PreferenceCardFragment_BuyerPreferenceForLand_Fragment)
                      | ({
                            __typename?: 'BuyerPreferenceForOffice';
                        } & PreferenceCardFragment_BuyerPreferenceForOffice_Fragment)
                      | ({
                            __typename?: 'BuyerPreferenceForCommercial';
                        } & PreferenceCardFragment_BuyerPreferenceForCommercial_Fragment)
                      | ({
                            __typename?: 'BuyerPreferenceForParking';
                        } & PreferenceCardFragment_BuyerPreferenceForParking_Fragment)
                  >;
                  pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
              };
          }
        | { __typename?: 'Vendor'; id: string }
    >;
};

export type GetMatchingConfigQueryVariables = Exact<{
    input: MatchingConfigInput;
}>;

export type GetMatchingConfigQuery = {
    __typename?: 'Query';
    matchingConfig: { __typename?: 'MatchingConfigPayload'; data?: Maybe<any> };
};

export type GetMatchingFlagsQueryVariables = Exact<{
    input: MatchingFlagsInput;
}>;

export type GetMatchingFlagsQuery = {
    __typename?: 'Query';
    matchingFlags: {
        __typename?: 'MatchingFlagsPayload';
        data?: Maybe<
            Array<
                Maybe<{
                    __typename?: 'MatchingFlagsPayloadData';
                    propertyId?: Maybe<string>;
                    contactId?: Maybe<string>;
                    flags?: Maybe<any>;
                }>
            >
        >;
    };
};

export type GetMatchingRequirementsQueryVariables = Exact<{
    input: MatchingRequirementsInput;
}>;

export type GetMatchingRequirementsQuery = {
    __typename?: 'Query';
    matchingRequirements: {
        __typename?: 'MatchingRequirementsPayload';
        data?: Maybe<{
            __typename?: 'MatchingRequirementsPayloadData';
            contactId?: Maybe<string>;
            propertyId?: Maybe<string>;
            preferenceId?: Maybe<string>;
            isMatchable?: Maybe<boolean>;
            errors?: Maybe<any>;
        }>;
    };
};

export type GetContactOffersQueryVariables = Exact<{
    contactId: Scalars['ID'];
    archived: Scalars['Boolean'];
}>;

export type GetContactOffersQuery = {
    __typename?: 'Query';
    contact?: Maybe<
        | { __typename?: 'Lead'; id: string; offers: Array<{ __typename?: 'Offer' } & OfferCardFragmentFragment> }
        | { __typename?: 'Vendor'; id: string; offers: Array<{ __typename?: 'Offer' } & OfferCardFragmentFragment> }
    >;
};

export type GetContactStatsQueryVariables = Exact<{
    filter: ContactStatsFilter;
}>;

export type GetContactStatsQuery = {
    __typename?: 'Query';
    contactStats?: Maybe<{
        __typename?: 'ContactStats';
        numberOfPendingOffers: number;
        closestOfferExpirations: Array<any>;
        hasAcceptedOffer: boolean;
        highestOfferBid: number;
    }>;
};

export type GetEstateOffersQueryVariables = Exact<{
    estateId: Scalars['ID'];
    archived: Scalars['Boolean'];
}>;

export type GetEstateOffersQuery = {
    __typename?: 'Query';
    estate?: Maybe<{
        __typename?: 'Estate';
        id: string;
        offers: Array<{ __typename?: 'Offer' } & OfferCardFragmentFragment>;
    }>;
};

export type GetEstateStatsQueryVariables = Exact<{
    filter: PropertyStatsFilter;
}>;

export type GetEstateStatsQuery = {
    __typename?: 'Query';
    propertyStats?: Maybe<{
        __typename?: 'PropertyStats';
        numberOfPendingOffers: number;
        closestOfferExpirations: Array<any>;
        hasAcceptedOffer: boolean;
        highestOfferBid: number;
    }>;
};

export type GetOfferQueryVariables = Exact<{
    offerId: Scalars['ID'];
}>;

export type GetOfferQuery = {
    __typename?: 'Query';
    offer?: Maybe<{ __typename?: 'Offer' } & OfferCardFragmentFragment>;
};

export type GetCompanyMembersQueryVariables = Exact<{
    officeId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
    team?: Maybe<Scalars['String']>;
    role?: Maybe<Scalars['String']>;
}>;

export type GetCompanyMembersQuery = {
    __typename?: 'Query';
    office?: Maybe<{
        __typename?: 'Office';
        companyMembers?: Maybe<{
            __typename?: 'TeamMembersConnection';
            totalCount: number;
            pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean; itemsPerPage: number };
            edges: Array<{
                __typename?: 'TeamMembersConnectionEdge';
                node: {
                    __typename?: 'User';
                    id: string;
                    firstName?: Maybe<string>;
                    lastName?: Maybe<string>;
                    email: string;
                    role: string;
                    phone?: Maybe<string>;
                    photo?: Maybe<any>;
                };
            }>;
        }>;
    }>;
};

export type GetGeoTemplatesQueryVariables = Exact<{
    officeId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
}>;

export type GetGeoTemplatesQuery = {
    __typename?: 'Query';
    geoTemplates: {
        __typename?: 'GeoTemplatesPayload';
        pagination: {
            __typename?: 'GeoTemplatesPagination';
            current_page?: Maybe<number>;
            total_pages?: Maybe<number>;
            total?: Maybe<number>;
            per_page?: Maybe<number>;
        };
        data: Array<{
            __typename?: 'GeoTemplate';
            _id?: Maybe<string>;
            title: string;
            office_id?: Maybe<string>;
            type?: Maybe<string>;
            postalCodes?: Maybe<Array<Maybe<string>>>;
            geometry?: Maybe<any>;
        }>;
    };
};

export type GetGeoTemplateQueryVariables = Exact<{
    officeId: Scalars['ID'];
    id: Scalars['ID'];
}>;

export type GetGeoTemplateQuery = {
    __typename?: 'Query';
    geoTemplate: {
        __typename?: 'GeoTemplate';
        _id?: Maybe<string>;
        title: string;
        office_id?: Maybe<string>;
        type?: Maybe<string>;
        postalCodes?: Maybe<Array<Maybe<string>>>;
        geometry?: Maybe<any>;
    };
};

export type GetLegalEntitiesQueryVariables = Exact<{
    officeId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
}>;

export type GetLegalEntitiesQuery = {
    __typename?: 'Query';
    office?: Maybe<{
        __typename?: 'Office';
        id: string;
        legalEntities: {
            __typename?: 'LegalEntitiesConnection';
            totalCount: number;
            pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
            edges: Array<{
                __typename?: 'LegalEntitiesEdge';
                node: { __typename?: 'LegalEntities'; name: string; id?: Maybe<string>; office_id?: Maybe<string> };
            }>;
        };
    }>;
};

export type GetLegalEntityQueryVariables = Exact<{
    officeId: Scalars['ID'];
    id: Scalars['ID'];
}>;

export type GetLegalEntityQuery = {
    __typename?: 'Query';
    office?: Maybe<{
        __typename?: 'Office';
        id: string;
        legalEntity?: Maybe<{
            __typename?: 'LegalEntity';
            data?: Maybe<{ __typename?: 'LegalEntities'; name: string; id?: Maybe<string>; office_id?: Maybe<string> }>;
        }>;
    }>;
};

export type GetOfficeNegotiatorsQueryVariables = Exact<{
    id: Scalars['ID'];
    query?: Maybe<Scalars['String']>;
}>;

export type GetOfficeNegotiatorsQuery = {
    __typename?: 'Query';
    office?: Maybe<{
        __typename?: 'Office';
        id: string;
        negotiators: Array<{
            __typename?: 'User';
            id: string;
            firstName?: Maybe<string>;
            lastName?: Maybe<string>;
            email: string;
            photo?: Maybe<any>;
            hasCalendarIntegration?: Maybe<boolean>;
        }>;
    }>;
};

export type GetOfficeQueryVariables = Exact<{
    id: Scalars['ID'];
    locationTemplatesPage?: Maybe<Scalars['Int']>;
    locationTemplatesQuery?: Maybe<Scalars['String']>;
    includeLocationData?: Scalars['Boolean'];
}>;

export type GetOfficeQuery = {
    __typename?: 'Query';
    office?: Maybe<{
        __typename?: 'Office';
        id: string;
        locationTemplates: {
            __typename?: 'LocationPreferenceConnection';
            totalCount: number;
            pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
            edges: Array<{
                __typename?: 'LocationPreferenceEdge';
                node:
                    | ({
                          __typename?: 'PostalCodesLocationPreference';
                      } & LocationPreference_PostalCodesLocationPreference_Fragment)
                    | ({
                          __typename?: 'GeoJSONLocationPreference';
                      } & LocationPreference_GeoJsonLocationPreference_Fragment);
            }>;
        };
    }>;
};

export type GetOfficeMembersQueryVariables = Exact<{
    officeId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
}>;

export type GetOfficeMembersQuery = {
    __typename?: 'Query';
    office?: Maybe<{
        __typename?: 'Office';
        members?: Maybe<{
            __typename?: 'TeamMembersConnection';
            totalCount: number;
            pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean; itemsPerPage: number };
            edges: Array<{
                __typename?: 'TeamMembersConnectionEdge';
                node: {
                    __typename?: 'User';
                    id: string;
                    firstName?: Maybe<string>;
                    lastName?: Maybe<string>;
                    email: string;
                    role: string;
                    phone?: Maybe<string>;
                    photo?: Maybe<any>;
                };
            }>;
        }>;
    }>;
};

export type GetOfficeNegotiatorsForSelectQueryVariables = Exact<{
    page: Scalars['Int'];
    officeId: Scalars['ID'];
    query: Scalars['String'];
    includeAdditionalNegotiator: Scalars['Boolean'];
    additionalNegotiatorId: Scalars['ID'];
    includeIds: Scalars['Boolean'];
    additionalIds: Array<Scalars['ID']>;
}>;

export type GetOfficeNegotiatorsForSelectQuery = {
    __typename?: 'Query';
    office?: Maybe<{
        __typename?: 'Office';
        id: string;
        negotiators: Array<{
            __typename?: 'User';
            id: string;
            firstName?: Maybe<string>;
            lastName?: Maybe<string>;
            email: string;
            photo?: Maybe<any>;
            role: string;
            errorStatus?: Maybe<number>;
            hasCalendarIntegration?: Maybe<boolean>;
        }>;
        negotiatiorsByIds: Array<{
            __typename?: 'User';
            id: string;
            firstName?: Maybe<string>;
            lastName?: Maybe<string>;
            email: string;
            photo?: Maybe<any>;
            role: string;
            errorStatus?: Maybe<number>;
            hasCalendarIntegration?: Maybe<boolean>;
        }>;
    }>;
    user?: Maybe<{
        __typename?: 'User';
        id: string;
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        email: string;
        photo?: Maybe<any>;
        role: string;
        errorStatus?: Maybe<number>;
        hasCalendarIntegration?: Maybe<boolean>;
    }>;
};

export type GetAllAccountsQueryVariables = Exact<{
    companyId?: Maybe<Scalars['String']>;
}>;

export type GetAllAccountsQuery = {
    __typename?: 'Query';
    channelAccounts?: Maybe<
        Array<{
            __typename?: 'ChannelAccount';
            id: string;
            name: string;
            office_id?: Maybe<string>;
            accessToken?: Maybe<string>;
            accessTokenSecret?: Maybe<string>;
            status: string;
            channel: {
                __typename?: 'Channel';
                id: string;
                name: string;
                slug: string;
                logoUrl?: Maybe<string>;
                type: string;
            };
        }>
    >;
};

export type GetPortalsAccountsQueryVariables = Exact<{
    companyId?: Maybe<Scalars['String']>;
}>;

export type GetPortalsAccountsQuery = {
    __typename?: 'Query';
    channelAccounts?: Maybe<
        Array<{
            __typename?: 'ChannelAccount';
            id: string;
            name: string;
            accessToken?: Maybe<string>;
            accessTokenSecret?: Maybe<string>;
            status: string;
            channel: {
                __typename?: 'Channel';
                id: string;
                name: string;
                slug: string;
                logoUrl?: Maybe<string>;
                type: string;
            };
        }>
    >;
};

export type EstateChannelAccountFragment = {
    __typename?: 'EstateChannelAccountConnectionEdge';
    id: string;
    publicationStatus: PublicationStatus;
    configurationStatus: ConfigurationStatus;
    configurationStatusMessageI18Key?: Maybe<string>;
    outdated: boolean;
    canPublish: boolean;
    missingFields: {
        __typename: 'MissingFieldsEdge';
        id: string;
        estateId: string;
        node: Array<{ __typename?: 'MissingField'; path: string; errorMessage: string }>;
    };
    unitsMissingFields: {
        __typename?: 'MissingFieldsConnection';
        edges: Array<{
            __typename: 'MissingFieldsEdge';
            id: string;
            estateId: string;
            node: Array<{ __typename?: 'MissingField'; path: string; errorMessage: string }>;
        }>;
    };
    lastPublication?: Maybe<{
        __typename?: 'EstatePublication';
        id: string;
        publishedAt?: Maybe<any>;
        link?: Maybe<string>;
        publishedBy?: Maybe<{ __typename?: 'User'; id: string; firstName?: Maybe<string>; lastName?: Maybe<string> }>;
        config?: Maybe<
            | { __typename?: 'GenericPublicationConfig'; showPrice?: Maybe<boolean>; showLocation?: Maybe<boolean> }
            | {
                  __typename?: 'ImmowebPublicationConfig';
                  adSize?: Maybe<string>;
                  showPrice?: Maybe<boolean>;
                  showLocation?: Maybe<boolean>;
              }
            | {
                  __typename?: 'FacebookPublicationConfig';
                  post?: Maybe<string>;
                  showPrice?: Maybe<boolean>;
                  showLocation?: Maybe<boolean>;
              }
            | {
                  __typename?: 'EstateAgentFeedsPublicationConfig';
                  priceType?: Maybe<string>;
                  features?: Maybe<Array<string>>;
                  showPrice?: Maybe<boolean>;
                  showLocation?: Maybe<boolean>;
              }
            | {
                  __typename?: 'TwitterPublicationConfig';
                  message?: Maybe<string>;
                  showPrice?: Maybe<boolean>;
                  showLocation?: Maybe<boolean>;
              }
            | {
                  __typename?: 'FrenchPortalPublicationConfig';
                  portals?: Maybe<Array<Maybe<string>>>;
                  showPrice?: Maybe<boolean>;
                  showLocation?: Maybe<boolean>;
              }
        >;
        errors?: Maybe<{
            __typename?: 'EstatePublicationErrors';
            self?: Maybe<
                Array<Maybe<{ __typename?: 'EstatePublicationError'; message: string; translationKey?: Maybe<string> }>>
            >;
            units?: Maybe<
                Array<{
                    __typename?: 'UnitPublicationErrors';
                    unitId: string;
                    errors: Array<{
                        __typename?: 'EstatePublicationError';
                        message: string;
                        translationKey?: Maybe<string>;
                    }>;
                }>
            >;
        }>;
        warnings?: Maybe<{
            __typename?: 'EstatePublicationWarnings';
            self?: Maybe<
                Array<
                    Maybe<{ __typename?: 'EstatePublicationWarning'; message: string; translationKey?: Maybe<string> }>
                >
            >;
            units?: Maybe<
                Array<{
                    __typename?: 'UnitPublicationWarnings';
                    unitId: string;
                    warnings: Array<{
                        __typename?: 'EstatePublicationWarning';
                        message: string;
                        translationKey?: Maybe<string>;
                    }>;
                }>
            >;
        }>;
    }>;
    node: {
        __typename?: 'ChannelAccount';
        id: string;
        name: string;
        status: string;
        channel: {
            __typename?: 'Channel';
            id: string;
            countries: Array<string>;
            name: string;
            logoUrl?: Maybe<string>;
            slug: string;
            supportsUnpublishing: boolean;
            supportsRepublishing: boolean;
        };
    };
};

export type GetEstateChannelAccountsQueryVariables = Exact<{
    propertyId: Scalars['ID'];
    isOldPublishing?: Maybe<Scalars['Boolean']>;
    isAdStatus?: Maybe<Scalars['Boolean']>;
    status?: Maybe<Array<Maybe<Scalars['String']>>>;
    isStatusEnabled?: Maybe<Scalars['Boolean']>;
}>;

export type GetEstateChannelAccountsQuery = {
    __typename?: 'Query';
    estate?: Maybe<{
        __typename?: 'Estate';
        id: string;
        publications: {
            __typename?: 'EstatePublications';
            id: string;
            channelAccounts: {
                __typename?: 'EstateChannelAccountConnection';
                edges: Array<{ __typename?: 'EstateChannelAccountConnectionEdge' } & EstateChannelAccountFragment>;
                pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean };
            };
        };
    }>;
};

export type GetEstateChannelAccountQueryVariables = Exact<{
    estateId: Scalars['ID'];
    channelAccountId: Scalars['ID'];
    lastModified?: Maybe<Scalars['String']>;
}>;

export type GetEstateChannelAccountQuery = {
    __typename?: 'Query';
    estate?: Maybe<{
        __typename?: 'Estate';
        id: string;
        channelAccount?: Maybe<{ __typename?: 'EstateChannelAccountConnectionEdge' } & EstateChannelAccountFragment>;
    }>;
};

export type GetEstatesOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEstatesOptionsQuery = {
    __typename?: 'Query';
    estatesOptions?: Maybe<{
        __typename?: 'EstatesOptions';
        sorts: Array<{ __typename?: 'EstatesSortOption'; key: string }>;
    }>;
};

export type GetInteractedContactsQueryVariables = Exact<{
    propertyId: Scalars['ID'];
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    afterInterestedLeads?: Maybe<Scalars['String']>;
    afterNotInterestedLeads?: Maybe<Scalars['String']>;
    beforeInterestedLeads?: Maybe<Scalars['String']>;
    beforeNotInterestedLeads?: Maybe<Scalars['String']>;
    type: ContactType;
    splitByInterest: Scalars['Boolean'];
}>;

export type GetInteractedContactsQuery = {
    __typename?: 'Query';
    estate?: Maybe<{
        __typename?: 'Estate';
        id: string;
        interestedContacts: {
            __typename?: 'EstateContactConnection';
            nodes: Array<
                | ({ __typename?: 'Lead' } & ContactCardFragment_Lead_Fragment)
                | ({ __typename?: 'Vendor' } & ContactCardFragment_Vendor_Fragment)
            >;
            pageInfo: {
                __typename?: 'PageInfo';
                hasNextPage: boolean;
                endCursor?: Maybe<string>;
                startCursor?: Maybe<string>;
            };
        };
        notInterestedContacts: {
            __typename?: 'EstateContactConnection';
            nodes: Array<
                | ({ __typename?: 'Lead' } & ContactCardFragment_Lead_Fragment)
                | ({ __typename?: 'Vendor' } & ContactCardFragment_Vendor_Fragment)
            >;
            pageInfo: {
                __typename?: 'PageInfo';
                hasNextPage: boolean;
                endCursor?: Maybe<string>;
                startCursor?: Maybe<string>;
            };
        };
        contacts: {
            __typename?: 'EstateContactConnection';
            nodes: Array<
                | ({ __typename?: 'Lead' } & ContactCardFragment_Lead_Fragment)
                | ({ __typename?: 'Vendor' } & ContactCardFragment_Vendor_Fragment)
            >;
            pageInfo: {
                __typename?: 'PageInfo';
                hasNextPage: boolean;
                endCursor?: Maybe<string>;
                startCursor?: Maybe<string>;
            };
        };
    }>;
};

export type GetOfficeEstatesQueryVariables = Exact<{
    query?: Maybe<Scalars['String']>;
    filters: EstateFilters;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    locale?: Maybe<Scalars['String']>;
    useAtlasSearch?: Maybe<Scalars['Boolean']>;
    usePostRequest?: Maybe<Scalars['Boolean']>;
}>;

export type GetOfficeEstatesQuery = {
    __typename?: 'Query';
    estates: {
        __typename?: 'EstatesConnection';
        totalCount: number;
        edges: Array<{
            __typename?: 'EstateConnectionEdge';
            node: {
                __typename?: 'Estate';
                id: string;
                type: string;
                rentPeriod?: Maybe<string>;
                internalType: EstateType;
                isProject: boolean;
                negotiation: string;
                createdAt?: Maybe<any>;
                updatedAt?: Maybe<any>;
                attributes: any;
                archived: boolean;
                status: string;
                office_id?: Maybe<string>;
                visibility?: Maybe<string>;
                error_publications_count?: Maybe<number>;
                successful_publications_count?: Maybe<number>;
                first_publication_date?: Maybe<any>;
                negotiator?: Maybe<{
                    __typename?: 'User';
                    id: string;
                    firstName?: Maybe<string>;
                    lastName?: Maybe<string>;
                    photo?: Maybe<any>;
                }>;
                offersStats?: Maybe<{
                    __typename?: 'OffersStats';
                    number_of_pending_offers: number;
                    highest_offer_bid: number;
                    has_accepted_offer: boolean;
                    closest_offer_expirations: Array<any>;
                    currency: any;
                }>;
            };
        }>;
        pageInfo: { __typename?: 'PageInfo'; currentPage: number; hasNextPage: boolean; itemsPerPage: number };
    };
};

export type GetPropertyQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetPropertyQuery = {
    __typename?: 'Query';
    estate?: Maybe<{ __typename?: 'Estate' } & PropertyCardFragmentFragment>;
};

export type GetPropertyReferenceQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetPropertyReferenceQuery = {
    __typename?: 'Query';
    propertyReference?: Maybe<{
        __typename?: 'PropertyReference';
        assignee?: Maybe<{
            __typename?: 'PropertyReferenceAssignee';
            id: string;
            first_name?: Maybe<string>;
            last_name?: Maybe<string>;
            phone?: Maybe<string>;
            email?: Maybe<string>;
            picture_url?: Maybe<string>;
        }>;
        team?: Maybe<{ __typename?: 'PropertyReferenceTeam'; id: string; name: string }>;
    }>;
};

export type GetPropertyTimelineQueryVariables = Exact<{
    propertyId: Scalars['ID'];
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    contactId?: Maybe<Scalars['String']>;
}>;

export type GetPropertyTimelineQuery = {
    __typename?: 'Query';
    estate?: Maybe<{
        __typename?: 'Estate';
        id: string;
        timeline: {
            __typename?: 'PropertyTimeline';
            activities: { __typename?: 'ActivityConnection' } & TimelineActivitiesFragment;
        };
    }>;
};

export type GetSimilarPropertiesQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetSimilarPropertiesQuery = {
    __typename?: 'Query';
    estate?: Maybe<{ __typename?: 'Estate'; id: string; similarProperties?: Maybe<any> }>;
};

export type GetStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type GetStatusesQuery = {
    __typename?: 'Query';
    letStatuses: Array<{ __typename?: 'PropertyStatus'; id: string; order: number }>;
    saleStatuses: Array<{ __typename?: 'PropertyStatus'; id: string; order: number }>;
};

export type GetStatusesForNegotiationQueryVariables = Exact<{
    negotiation?: Maybe<Negotiation>;
}>;

export type GetStatusesForNegotiationQuery = {
    __typename?: 'Query';
    statuses: Array<{ __typename?: 'PropertyStatus'; id: string; order: number }>;
};

export type GetContactScheduleQueryVariables = Exact<{
    contactId: Scalars['ID'];
    query?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
}>;

export type GetContactScheduleQuery = {
    __typename?: 'Query';
    contactSchedulePendingConfirmation: { __typename?: 'ScheduleCount'; count: number };
    contactSchedule: Array<{
        __typename?: 'ScheduleEventVisit';
        id: string;
        title?: Maybe<string>;
        date?: Maybe<string>;
        start?: Maybe<string>;
        end?: Maybe<string>;
        propertyId: string;
        type: ScheduleEventType;
        createdAt: string;
        updatedAt: string;
        description?: Maybe<string>;
        organizerId?: Maybe<string>;
        status?: Maybe<VisitStatus>;
        location?: Maybe<string>;
        attendees?: Maybe<Array<{ __typename?: 'ScheduleEventAttendee'; id: string; resource_type: string }>>;
    }>;
};

export type GetPropertyScheduleQueryVariables = Exact<{
    propertyId: Scalars['ID'];
    type?: Maybe<Array<ScheduleEventType>>;
    query?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    lang?: Maybe<Scalars['String']>;
}>;

export type GetPropertyScheduleQuery = {
    __typename?: 'Query';
    propertySchedulePendingConfirmation: { __typename?: 'ScheduleCount'; count: number };
    propertySchedule: Array<
        | {
              __typename?: 'ScheduleEventVisit';
              description?: Maybe<string>;
              organizerId?: Maybe<string>;
              status?: Maybe<VisitStatus>;
              location?: Maybe<string>;
              id: string;
              title?: Maybe<string>;
              date?: Maybe<string>;
              start?: Maybe<string>;
              end?: Maybe<string>;
              propertyId: string;
              type: ScheduleEventType;
              createdAt: string;
              updatedAt: string;
              attendees?: Maybe<Array<{ __typename?: 'ScheduleEventAttendee'; id: string; resource_type: string }>>;
          }
        | {
              __typename?: 'ScheduleEventAuction';
              estateLocation?: Maybe<string>;
              id: string;
              title?: Maybe<string>;
              date?: Maybe<string>;
              start?: Maybe<string>;
              end?: Maybe<string>;
              propertyId: string;
              type: ScheduleEventType;
              createdAt: string;
              updatedAt: string;
          }
        | {
              __typename?: 'ScheduleEventOpenHome';
              companyId?: Maybe<string>;
              officeId?: Maybe<string>;
              estateLocation?: Maybe<string>;
              id: string;
              title?: Maybe<string>;
              date?: Maybe<string>;
              start?: Maybe<string>;
              end?: Maybe<string>;
              propertyId: string;
              type: ScheduleEventType;
              createdAt: string;
              updatedAt: string;
          }
    >;
};

export type GetTeamQueryVariables = Exact<{
    teamId: Scalars['ID'];
}>;

export type GetTeamQuery = {
    __typename?: 'Query';
    team?: Maybe<{
        __typename?: 'CompanyTeamResponse';
        data?: Maybe<{
            __typename?: 'CompanyTeam';
            id: string;
            name: string;
            head_negotiator?: Maybe<{ __typename?: 'CompanyTeamHeadNegotiator'; data?: Maybe<any> }>;
            settings?: Maybe<{ __typename?: 'CompanyTeamSettings'; data?: Maybe<any> }>;
        }>;
    }>;
};

export type GetTeamsQueryVariables = Exact<{
    companyId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
}>;

export type GetTeamsQuery = {
    __typename?: 'Query';
    teams?: Maybe<{
        __typename?: 'CompanyTeamsResponse';
        meta?: Maybe<{
            __typename?: 'CompanyTeamsMeta';
            pagination?: Maybe<{
                __typename?: 'CompanyTeamsMetaPagination';
                current_page?: Maybe<number>;
                total_pages?: Maybe<number>;
                total?: Maybe<number>;
                per_page?: Maybe<number>;
            }>;
        }>;
        data?: Maybe<
            Array<
                Maybe<{
                    __typename?: 'CompanyTeam';
                    id: string;
                    name: string;
                    settings?: Maybe<{ __typename?: 'CompanyTeamSettings'; data?: Maybe<any> }>;
                }>
            >
        >;
    }>;
};

export type GetUserTeamsQueryVariables = Exact<{
    userId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
}>;

export type GetUserTeamsQuery = {
    __typename?: 'Query';
    userTeams?: Maybe<{
        __typename?: 'CompanyTeamsResponse';
        meta?: Maybe<{
            __typename?: 'CompanyTeamsMeta';
            pagination?: Maybe<{
                __typename?: 'CompanyTeamsMetaPagination';
                current_page?: Maybe<number>;
                total_pages?: Maybe<number>;
                total?: Maybe<number>;
                per_page?: Maybe<number>;
            }>;
        }>;
        data?: Maybe<
            Array<
                Maybe<{
                    __typename?: 'CompanyTeam';
                    id: string;
                    name: string;
                    settings?: Maybe<{ __typename?: 'CompanyTeamSettings'; data?: Maybe<any> }>;
                }>
            >
        >;
    }>;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = { __typename?: 'Query'; me: { __typename?: 'User' } & UserFragmentFragment };

export type GetCurrentUserRoleQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserRoleQuery = { __typename?: 'Query'; me: { __typename?: 'User'; id: string; role: string } };

export type GetUserQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetUserQuery = {
    __typename?: 'Query';
    user?: Maybe<{ __typename?: 'User'; id: string; firstName?: Maybe<string>; lastName?: Maybe<string> }>;
};

export type GetUserByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetUserByIdQuery = {
    __typename?: 'Query';
    user?: Maybe<{
        __typename?: 'User';
        id: string;
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        email: string;
        phone?: Maybe<string>;
        role: string;
        locale?: Maybe<string>;
        photo?: Maybe<any>;
    }>;
};

export type GetUserWithCalendarQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserWithCalendarQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'User';
        id: string;
        calendar?: Maybe<any>;
        hasCalendarIntegration?: Maybe<boolean>;
        appointmentServiceUrl?: Maybe<string>;
    };
};
