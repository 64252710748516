import invariant from 'tiny-invariant';
import axios from 'axios';

type Context = {
    userInfo: {
        access_token: string;
    };
};

export default class ServiceRequests {
    serviceName!: string;
    httpClient = axios.create();

    initialize = ({ context }: { context: Context }) => {
        if (context.userInfo) {
            this.httpClient.defaults.headers.common['Authorization'] = `Bearer ${context.userInfo.access_token}`;
        }
        this.httpClient.defaults.baseURL = this.getBaseUrl();
        this.httpClient.interceptors.response.use(handleRequestSuccess, handleRequestFailure);
    };

    getBaseUrl = () => {
        invariant(this.serviceName, 'service name needs to be defined');

        return `https://${this.serviceName}.services.${API_URL}`;
    };

    get = (path: string, config: { params?: Record<string, any> } = {}) => {
        return this.httpClient.get(path, config);
    };

    post = (path: string, body?: any, config?: { params?: Record<string, any> }) => {
        return this.httpClient.post(path, body, config);
    };

    put = (path: string, body?: any, config?: { params?: Record<string, any> }) => {
        return this.httpClient.put(path, body, config);
    };

    delete = (path: string, config?: { params?: Record<string, any> }) => {
        return this.httpClient.delete(path, config);
    };
}

function handleRequestSuccess(response) {
    return response.data;
}

function handleRequestFailure(error: any) {
    if (error.response) {
        const httpError = new Error(`${error.response.status}: ${error.message}`);
        error.response = {
            status: error.response.status,
            body: error.response.body,
        };

        return Promise.reject(httpError);
    }

    return Promise.reject(error);
}
