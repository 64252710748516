import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { reduxForm, propTypes as formPropTypes } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { FormattedMessage } from 'react-intl-sweepbright';
import { withToasts } from '@sweepbright/notifications';
import wrapWithPromise from '../services/wrapWithPromise';
import { getCompanySettings, getCompany, getStatus } from '../../app.redux/selectors';
import { updateCompanySettings } from '../../app.redux/actions';
import withConfirmation from './withConfirmation';

/**
 * @param {string} name
 * @param {string[]} fields
 * @param {any} selectors
 * @param {any} actions
 * @param {Function} validate
 * @param {object} options
 * @param {JSX.Element} options.error
 * @param {JSX.Element} options.success
 * @param {Function} options.errorsMapper
 */
export default function companySettings(name, fields = [], selectors, actions, validate, options = {}) {
    const {
        success: successMessage = <FormattedMessage id="company.settings.saved" defaultMessage="Settings saved" />,
        error: errorMessage = <FormattedMessage id="form.status.error" defaultMessage="Could not save" />,
        errorsMapper = e => e,
    } = options;

    const mapStateToProps = createStructuredSelector({
        ...selectors,
        initialValues: getCompanySettings,
        company: getCompany,
        status: getStatus,
    });

    const mapActionsToProps = {
        ...actions,
        updateCompanySettings,
    };

    const form = {
        form: `settings/${name}`,
        fields: [...fields, 'dirty'],
        validate,
        onSubmit: async (attributes, dispatch, props) => {
            try {
                await wrapWithPromise(data => props.updateCompanySettings(props.company.get('id'), data))(attributes);
                props.toasts.addSuccess({
                    message: successMessage,
                });
            } catch (err) {
                props.toasts.addError({
                    message: errorMessage,
                });
                // rethrow the error so the form shows the field errors
                throw errorsMapper(err);
            }
        },
    };

    return compose(
        withToasts,
        withState('saved', 'setSaved', false),
        withHandlers({
            onSubmitSuccess: props => () => props.setSaved(true),
        }),
        connect(mapStateToProps, mapActionsToProps),
        reduxForm(form),
        withConfirmation,
    );
}

export const propTypes = {
    ...formPropTypes,
    company: PropTypes.instanceOf(Map),
    saved: PropTypes.bool,
    status: PropTypes.instanceOf(Map),
    route: PropTypes.object,
};
