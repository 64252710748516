// @ts-nocheck
import pkg from '../../../../package.json';
import { events } from './event-types';

function setUser(user) {
    // analytics
    if (window && window.mixpanel) {
        mixpanel.identify(user.email);
        mixpanel.people.set({ $email: user.email, $last_login: new Date() });
    }
}

function unsetUser() {
    // TODO(@krvajal) Unset user, expected to be called after logout
}

function track(event: ValueOf<typeof events>, options: Record<string, any> = {}) {
    if (window && window.mixpanel) {
        mixpanel.track(event, {
            appVersion: pkg.version,
            ...options,
        });
    }
}

function trackWithHandler(originalHandler, event: ValueOf<typeof events>, options: Record<string, any> = {}) {
    return function wrappedEventHandler(...args) {
        track(event, options);

        return originalHandler(...args);
    };
}

export function trackHandler(event: ValueOf<typeof events>, options: Record<string, any> = {}) {
    return function() {
        track(event, options);
    };
}

function setupAnalytics() {
    // nothing to do for mixpanel
}

export { events, track, setUser, unsetUser, setupAnalytics, trackWithHandler };
