// @ts-nocheck
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import unfetch from 'unfetch';
import AuthManager from 'auth-manager';
import { GraphQLError } from 'graphql';
import { InMemoryCache, IntrospectionFragmentMatcher, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError, ErrorResponse } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import logger from '@sweepbright/webapp-client/app.utils/services/logger';
import { getBugsnagClient } from '@sweepbright/webapp-client/app.config/bugsnag';
import introspectionQueryResultData from './fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
});

// create the links
const httpLink = new HttpLink({
    credentials: 'include',
    fetch: unfetch,
});

const onErrorLink = onError(onGraphQLClientError);

const authLink = setContext((request, previousContext) => {
    // get the authentication token from local storage if it exists
    const auth = AuthManager.getToken();

    const headers = Object.assign(
        {},
        previousContext.headers,
        auth.access_token && { authorization: 'Bearer ' + auth.access_token },
    );

    const graphqlApiUrl = GRAPHQL_API_URL.replace(/\/+$/, '');

    return {
        headers,
        uri: `${graphqlApiUrl}/graphql?operationName=${request.operationName}`,
    };
});

// single reference to the apollo client
// used across the app
let apolloClientSingleton: ApolloClient<NormalizedCacheObject> | null = null;

function createApolloClient() {
    if (apolloClientSingleton) {
        throw new Error('the Apollo Client is already initialized');
    }

    const cache = new InMemoryCache({
        fragmentMatcher,
        freezeResults: true,
        cacheRedirects: {
            Query: {
                lead: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Lead', id: args.id }),
                contact: (_, args, { getCacheKey }) => {
                    // this is providing caching hits only for Leads
                    // not for Vendors, I dont know a way to provide it for both
                    return getCacheKey({ __typename: 'Lead', id: args.id });
                },
                estate: (_, args, { getCacheKey }) => {
                    return getCacheKey({ __typename: 'Estate', id: args.id });
                },
            },
            Estate: {
                channelAccount(estate, args, { getCacheKey }) {
                    return getCacheKey({
                        __typename: 'EstateChannelAccountConnectionEdge',
                        id: `${estate.id}#${args.id}`,
                    });
                },
            },
        },
    });

    apolloClientSingleton = new ApolloClient({
        name: 'webapp-client',
        link: ApolloLink.from([authLink, onErrorLink, httpLink]),
        cache,
        assumeImmutableResults: true,
    });

    return apolloClientSingleton;
}

function getApolloClient() {
    return apolloClientSingleton;
}

export { createApolloClient, getApolloClient };

function onGraphQLClientError(response: ErrorResponse) {
    const { graphQLErrors, networkError } = response;
    if (graphQLErrors) {
        graphQLErrors.forEach(handleGraphQLError);
    }
    if (networkError) {
        logger.error(`[Network error]: ${networkError}`);
    }
}

function handleGraphQLError(graphQLError: GraphQLError) {
    const { message, locations, path } = graphQLError;

    if (typeof message === 'string' && !message.includes('403')) {
        getBugsnagClient().notify(graphQLError instanceof Error ? graphQLError : new Error(message), {
            metaData: {
                locations,
                path,
            },
        });
        logger.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations &&
                JSON.stringify(locations, null, 2)}, Path: ${path}`,
        );
    }
}
