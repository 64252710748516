import React from 'react';
import { FormattedMessage } from 'react-intl';
import Input from '@/app.components/forms/Input/Input';
import { ResourceFilterType } from '@/shared/resource/types';

type Props = {
    tempParams: any;
    setTempParams: any;
    filter: ResourceFilterType;
};

const ResourceFilterFormText: React.FC<Props> = props => {
    const { filter, tempParams, setTempParams } = props;

    const value = tempParams?.[filter.paramName];

    return (
        <Input
            type="text"
            value={value}
            onChange={event => {
                setTempParams({
                    ...tempParams,
                    [filter.paramName]: event?.target?.value,
                });
            }}
            placeholder={<FormattedMessage id="general.any" defaultMessage="Any" />}
        />
    );
};

export default ResourceFilterFormText;
