import gql from 'graphql-tag';

export const GET_PROPERTY_SCHEDULE = gql`
    query GetPropertySchedule(
        $propertyId: ID!
        $type: [ScheduleEventType!]
        $query: String
        $before: String
        $after: String
        $limit: Int
        $lang: String
    ) {
        propertySchedulePendingConfirmation(propertyId: $propertyId) {
            count
        }
        propertySchedule(
            propertyId: $propertyId
            type: $type
            query: $query
            before: $before
            after: $after
            limit: $limit
        ) {
            id
            title
            date
            start
            end
            propertyId
            type
            createdAt
            updatedAt
            ... on ScheduleEventVisit {
                attendees {
                    id
                    resource_type
                }
                description
                organizerId
                status
                location
            }
            ... on ScheduleEventOpenHome {
                companyId
                officeId
                estateLocation(lang: $lang)
            }
            ... on ScheduleEventAuction {
                estateLocation(lang: $lang)
            }
        }
    }
`;
