import React from 'react';

const LoginFooter = () => {
    return (
        <div className="login-footer c-spacer-l text-center">
            <p>© Copyright SweepBright NV {new Date().getFullYear()}</p>
        </div>
    );
};

export default LoginFooter;
