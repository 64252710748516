import { useEffect, useState } from 'react';
import { MutationTuple, useMutation, useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import { GET_GEO_TEMPLATES } from '@/graphql/queries/office/getGeoTemplates';
import {
    GetGeoTemplatesQuery,
    GetGeoTemplatesQueryVariables,
    AddGeoTemplateMutation,
    AddGeoTemplateMutationVariables,
    UpdateGeoTemplateMutation,
    UpdateGeoTemplateMutationVariables,
    DeleteGeoTemplateMutation,
    DeleteGeoTemplateMutationVariables,
    GeoTemplate,
} from '@/graphql/generated/types';
import { ADD_GEO_TEMPLATE } from '@/graphql/mutations/office/addGeoTemplate';
import { UPDATE_GEO_TEMPLATE } from '@/graphql/mutations/office/updateGeoTemplate';
import { DELETE_GEO_TEMPLATE } from '@/graphql/mutations/office/deleteGeoTemplate';

const useGeoTemplates = (officeId?: string, type?: 'postalCode' | 'geometry') => {
    const [page, setPage] = useState(1);
    const [templates, setTemplates] = useState<GeoTemplate[]>([]);

    const { data, loading }: QueryResult<GetGeoTemplatesQuery, GetGeoTemplatesQueryVariables> = useQuery(
        GET_GEO_TEMPLATES,
        {
            skip: !officeId || !page,
            fetchPolicy: 'no-cache',
            variables: {
                type,
                page,
                officeId: officeId || '',
            },
        },
    );

    const pagination = data?.geoTemplates?.pagination;

    useEffect(() => {
        if (!loading) {
            const newData = data?.geoTemplates.data || [];

            if (newData.length === 0 && page === 1) {
                setTemplates([]);
            }

            if (newData.length > 0) {
                if (page === 1) {
                    setTemplates(newData);
                }

                if (page > 1) {
                    setTemplates(prevData => [...(prevData || []), ...newData]);
                }
            }
        }
    }, [page, loading, data]);

    const currentPage = pagination?.current_page || 1;
    const totalPages = pagination?.total_pages || 1;
    const hasNextPage = pagination ? currentPage < totalPages : false;

    const fetchMore = () => {
        if (hasNextPage && !loading) {
            setPage(prevState => prevState + 1);
        }
    };

    const refetch = () => {
        setPage(0);
        setPage(1);
    };

    const [addGeoTemplate]: MutationTuple<AddGeoTemplateMutation, AddGeoTemplateMutationVariables> = useMutation(
        ADD_GEO_TEMPLATE,
        {
            update: () => {},
        },
    );

    const [updateGeoTemplate]: MutationTuple<
        UpdateGeoTemplateMutation,
        UpdateGeoTemplateMutationVariables
    > = useMutation(UPDATE_GEO_TEMPLATE, {
        update: () => {},
    });

    const [deleteGeoTemplate]: MutationTuple<
        DeleteGeoTemplateMutation,
        DeleteGeoTemplateMutationVariables
    > = useMutation(DELETE_GEO_TEMPLATE, {
        update: () => {},
    });

    return {
        refetch,
        loading,
        pagination,
        hasNextPage,
        addGeoTemplate,
        data: templates,
        updateGeoTemplate,
        deleteGeoTemplate,
        fetchMore: fetchMore,
    };
};

export default useGeoTemplates;
