import { Map } from 'immutable';
import countries from '@sweepbright/api-schemas/schemas/enums/countries.json';
import properties from '@sweepbright/api-schemas/schemas/locations/location.json';

export const allowedCountries = countries.enum;
export const locationProperties = Object.keys(properties.properties);

export const unwrap = field => {
    if (field == null) {
        return '';
    }

    const value = typeof field === 'object' ? field.value : field;

    return String(value ?? '').trim();
};

/**
 * Get a Google Maps components object from
 * a Sweepbright location object
 */
export const getComponentsFromLocation = ({
    number,
    street,
    city,
    postal_code: postalCode,
    country,
    province_or_state: provinceOrState,
}) => {
    const address = [
        unwrap(number),
        unwrap(street),
        unwrap(city),
        unwrap(provinceOrState),
        unwrap(postalCode),
        unwrap(country),
    ]
        .filter(Boolean)
        .join(' ');

    const components = new Map({
        address: address.trim(),
        locality: unwrap(city),
        postalCode: unwrap(postalCode),
        country: unwrap(country),
    });

    return components.filter(Boolean).count() ? components : new Map();
};
