import { useQuery } from '@apollo/react-hooks';
import { GetGeoTemplateQuery, GetGeoTemplateQueryVariables } from '@/graphql/generated/types';
import { GET_GEO_TEMPLATE } from '@/graphql/queries/office/getGeoTemplates';

const useGeoTemplate = (officeId: string, templateId: string) => {
    const { data, loading } = useQuery<GetGeoTemplateQuery, GetGeoTemplateQueryVariables>(GET_GEO_TEMPLATE, {
        variables: {
            id: templateId,
            officeId: officeId,
        },
        skip: !templateId || !officeId,
    });

    const geoTemplate = data?.geoTemplate;

    return { geoTemplate, loading };
};

export default useGeoTemplate;
