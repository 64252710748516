import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { PROPERTIES, CONTACTS } from '@/app.routing/routes';
import { track, events } from '@/app.utils/analytics';
import SideMenuLink from '../navigations/SideMenu/SideMenuLink';
import LeftNavBar from '../navigations/LeftNavBar';

const OfficeAside: React.FunctionComponent<{ location: string }> = props => {
    const { location } = props;
    const linkProps = { fuzzyMatch: true, location };

    return (
        <LeftNavBar location={location}>
            <SideMenuLink
                {...linkProps}
                to={PROPERTIES}
                label={<FormattedMessage id="navigation.aside.properties" defaultMessage="Properties" />}
                icon="house"
                iconActive="house-fill"
                onClick={() => {
                    track(events.SIDE_NAVIGATION_PROPERTIES_CLICKED);
                }}
            />
            <SideMenuLink
                {...linkProps}
                to={CONTACTS}
                label={<FormattedMessage id="navigation.aside.contacts" defaultMessage="Contacts" />}
                icon="face-03"
                iconActive="face-03-fill"
                onClick={() => {
                    track(events.SIDE_NAVIGATION_CONTACTS_CLICKED);
                }}
            />
        </LeftNavBar>
    );
};

export default OfficeAside;
