import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { Event } from '../types';

export const EventTitle: FC<{ event: Event }> = ({ event }) => {
    switch (event.__typename) {
        case 'ScheduleEventAuction':
            return <FormattedMessage id="schedule_visit.event_title.auction" defaultMessage="Auction" />;
        case 'ScheduleEventOpenHome':
            return <FormattedMessage id="schedule_visit.event_title.open_home" defaultMessage="Open Home" />;
        case 'ScheduleEventVisit':
        default:
            return <>{event.title}</>;
    }
};
