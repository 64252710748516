import gql from 'graphql-tag';

export const GET_CONTACT_SCHEDULE = gql`
    query GetContactSchedule($contactId: ID!, $query: String, $before: String, $after: String, $limit: Int) {
        contactSchedulePendingConfirmation(contactId: $contactId) {
            count
        }
        contactSchedule(contactId: $contactId, query: $query, before: $before, after: $after, limit: $limit) {
            id
            title
            date
            start
            end
            propertyId
            type
            createdAt
            updatedAt
            attendees {
                id
                resource_type
            }
            description
            organizerId
            status
            location
        }
    }
`;
