import React, { useMemo } from 'react';
import { PropertyReferenceAssignee } from '@/graphql/generated/types';

type Props = {
    large?: boolean;
    assignee?: PropertyReferenceAssignee;
};

const PropertyCardAssigneeImage: React.FC<Props> = props => {
    const { large = false, assignee } = props;

    const dropcap = assignee?.last_name?.[0] ?? assignee?.first_name?.[0] ?? null;

    const style = useMemo(() => {
        if (assignee?.picture_url) {
            return { backgroundImage: `url(${assignee.picture_url})` };
        }

        return {
            backgroundImage: `linear-gradient(#cccccc, #aaaaaa)`,
        };
    }, [assignee]);

    return (
        <div style={style} className={`property-card-assignee-image${large ? ' large' : ''}`}>
            {assignee?.picture_url ? null : dropcap}
        </div>
    );
};

export default PropertyCardAssigneeImage;
