// @ts-nocheck
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl-sweepbright';
import { ItemsList } from '@/app.domains/contacts/contacts-form/elements/PropertiesList';
import FormPane from '@/app.components/forms/FormPane';
import BackButton from '@/app.components/elements/Buttons/BackButton';
import * as Routes from '@/app.routing/routes';
import { updateConnection } from '@/graphql/utils';
import { cancelEstateRequest, resendEstateRequest } from '../../../../../app.redux/actions';
import withModals from '../../../../../app.utils/Decorators/withModals';
import FileRequestModal from '../../../../../app.components/modals/FileRequestModal';
import ConfirmButton from '../../../../../app.components/elements/Buttons/ConfirmButton';
import IconButton from '../../../../../app.components/elements/Buttons/IconButton';
import MediaCard from '../../../../../app.components/card/MediaCard/MediaCard';
import FormPanel from '../../../../../app.components/forms/FormPanel/FormPanel';

const Requests = props => {
    const estateId = props.params.unit || props.params.property;
    const { type, title } = props.route;

    const { data, loading, fetchMore } = useQuery(
        gql`
            query GetEstatePendingRequests($estateId: ID!, $type: String!, $page: Int) {
                estate(id: $estateId) {
                    id
                    pendingRequests(type: $type, page: $page) {
                        nodes {
                            id
                            name
                            email
                            createdAt
                            resentAt
                        }
                        pageInfo {
                            currentPage
                            hasNextPage
                        }
                        totalCount
                    }
                }
            }
        `,
        {
            variables: {
                estateId: estateId,
                type,
            },
            fetchPolicy: 'cache-and-network',
        },
    );

    const requests = data?.estate.pendingRequests?.nodes ?? [];
    const pageInfo = data?.estate.pendingRequests.pageInfo ?? {};

    const handleFetchMore = () => {
        if (!loading && pageInfo.hasNextPage) {
            fetchMore({
                variables: {
                    page: pageInfo.currentPage + 1,
                },
                updateQuery: updateConnection('estate.pendingRequests'),
            });
        }
    };

    const getModalProps = () => {
        switch (type) {
            case 'image':
                return {
                    title: <FormattedMessage id="property.images.request.title" defaultMessage="Ask photographer" />,
                    description: (
                        <FormattedMessage
                            id="property.images.request.body"
                            defaultMessage="This person will receive a link where they can upload images. You will be able to review the images within this property."
                        />
                    ),
                    action: <FormattedMessage id="property.images.request.cta" defaultMessage="Request Images" />,
                    contentSetting: 'email_property_images_request',
                };
            case 'certificate':
            default: {
                return {
                    title: <FormattedMessage id="modal.request-documents.title" defaultMessage="Request documents" />,
                    description: (
                        <FormattedMessage
                            id="modal.request-documents.body"
                            defaultMessage="This person will receive a link where they can upload documents. You can review the uploaded documents under Legal & Docs in this property."
                        />
                    ),
                    action: <FormattedMessage id="modal.request-documents.cta" defaultMessage="Request Documents" />,
                    contentSetting: 'email_property_documents_request',
                };
            }
        }
    };

    const renderRequest = request => {
        const title = (
            <div>
                <span>{request.name}</span>
                <br />
                <small className="text-muted">
                    {request.resentAt ? (
                        <FormattedMessage
                            id="request.sent_again"
                            defaultMessage="Sent again {timestamp}"
                            values={{
                                timestamp: moment(request.resentAt).fromNow(),
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="request.sent_on"
                            defaultMessage="Sent {timestamp}"
                            values={{
                                timestamp: moment(request.createdAt).fromNow(),
                            }}
                        />
                    )}
                </small>
            </div>
        );

        const modalKey = `request_${request.id}`;

        const actions = [
            <IconButton
                key="resend"
                title={<FormattedMessage id="requests.resend" defaultMessage="Request Again" />}
                icon="circle-refresh"
                onClick={() => props.open(modalKey)}
            />,
            <ConfirmButton
                key="cancel"
                onConfirm={() => props.cancelEstateRequest(estateId, request.id)}
                title={<FormattedMessage id="requests.cancel" defaultMessage="Cancel Request" />}
                icon="circle-delete"
                iconStyle="danger"
            />,
        ];

        const requestModalProps = {
            propertyId: estateId,
            type,
            ...getModalProps(),
            initialValues: {
                email: request.email,
                name: request.name,
                message: request.message,
            },
        };

        return (
            <>
                <MediaCard title={title} actions={actions} />
                {props.modalWithPromise(modalKey, <FileRequestModal {...requestModalProps} />, attributes =>
                    props.resendEstateRequest(estateId, request.id, {
                        ...attributes,
                        file_type: type,
                    }),
                )}
            </>
        );
    };

    return (
        <FormPane title={title} backAction={<BackButton to={getBackRoute()} />}>
            <FormPanel>
                <ItemsList
                    getItemKey={item => item.id}
                    onFetchMore={handleFetchMore}
                    hasMore={pageInfo.hasNextPage}
                    items={requests}
                    renderItem={renderRequest}
                    loading={loading}
                />
            </FormPanel>
        </FormPane>
    );

    function getBackRoute() {
        const isUnit = !!props.params.unit;
        const projectId = props.params.property;
        switch (type) {
            case 'image': {
                return isUnit
                    ? Routes.PROPERTY_UNIT_DETAILS_IMAGES(projectId, estateId)
                    : Routes.PROPERTY_DETAILS_IMAGES(estateId);
            }
            case 'floor-plan': {
                return isUnit
                    ? Routes.PROPERTY_UNIT_DETAILS_DOCUMENTS_PLANS_TAB(projectId, estateId)
                    : Routes.PROPERTY_DETAILS_DOCUMENTS_PLANS_TAB(estateId);
            }
            case 'certificate': {
                return isUnit
                    ? Routes.PROPERTY_UNIT_DETAILS_DOCUMENTS(projectId, estateId)
                    : Routes.PROPERTY_DETAILS_DOCUMENTS(estateId);
            }
            default:
                throw new Error('invalid request type');
        }
    }
};

export default compose(
    connect(null, {
        cancelEstateRequest,
        resendEstateRequest,
    }),
    withModals,
)(Requests);
