import React from 'react';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';
import OfferCardAction from './OfferCardAction';
import OfferCardDetails from './OfferCardDetails';
import OfferCardHeader from './OfferCardHeader';
import OfferCardIcon from './OfferCardIcon';

type Props = {
    record: OfferCardFragmentFragment;
};

const OfferCard: React.FC<Props> = props => {
    const { record } = props;

    const isClosed = !!record.archivedAt || record.status === 'CANCELLED' || record.status === 'REFUSED';

    return (
        <div className={`offer-card${isClosed ? ' archive' : ''}`}>
            <div className="offer-card__status">
                <OfferCardIcon record={record} isClosed={isClosed} />
            </div>
            <div className="offer-card__main">
                <OfferCardHeader record={record} />

                <OfferCardDetails record={record} isClosed={isClosed} />

                {!isClosed && record.status === 'PENDING' && <OfferCardAction record={record} />}
            </div>
        </div>
    );
};

export default OfferCard;
