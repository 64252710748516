import React from 'react';
import { Map } from 'immutable';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { PlainRoute } from 'react-router';
import { checkAppointmentServiceUrl } from '@sweepbright/webapp-shared/utils/validations';
import FormPane from '@/app.components/forms/FormPane';
import CalendarIntegrationSection from '@/app.domains/profile/Integrations/Calendar/CalendarIntegrationSection';
import UserRequests from '@/requests/UserRequests';
import { AgencySiteSelfSchedulingSection } from '@/app.domains/profile/Integrations/Calendar/AgencySiteSelfSchedulingSection';
import { fetchUser } from '@/app.redux/actions/UsersActions';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormikWithConfirmation from '@/app.components/forms/helpers/FormikWithConfirmation';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import { useToasts } from '@sweepbright/notifications';
import { GET_OFFICE_NEGOTIATORS_QUERY } from '@/graphql/queries/office/getNegotiators';
import useOffice from '@/app.hooks/useOffice';
import { GET_USER_CALENDAR } from '@/graphql/queries/users/getUserWithCalendar';
import { GetUserWithCalendarQuery } from '@/graphql/generated/types';

type Calendar = {
    calendarId: string;
    auth: {
        accessToken: string;
        refreshToken: string;
    };
    profile: {
        name: string;
    };
};

const CalendarIntegrations: React.FunctionComponent<{ route: PlainRoute }> = ({ route }) => {
    const {
        calendarIntegration,
        loading,
        appointmentServiceUrl,
        refetch,
        addIntegration,
        removeIntegration,
        userId,
        hasCalendarIntegration,
    } = useCalendarIntegration();
    const dispatch = useDispatch();
    const { addSuccess } = useToasts();
    const connected = !!calendarIntegration;

    return (
        <FormikWithConfirmation
            route={route}
            initialValues={{
                calendarId: calendarIntegration?.calendarId,
                appointmentServiceUrl,
                connected,
                hasCalendarIntegration,
                account: {
                    accessToken: calendarIntegration?.auth.accessToken,
                    refreshToken: calendarIntegration?.auth.refreshToken,
                    profile: calendarIntegration?.profile,
                },
            }}
            enableReinitialize={true}
            validate={validateForm}
            onSubmit={async values => {
                if (values.connected) {
                    // connect account
                    await addIntegration({
                        variables: {
                            integration: createCronofyIntegration(values.account, values.calendarId),
                        },
                    });
                } else {
                    // disconnect account
                    await removeIntegration();
                }

                // @ts-ignore
                await new UserRequests().patch('/users/me', {
                    // @ts-ignore
                    appointment_service_url: values.appointmentServiceUrl,
                });
                await refetch();
                dispatch(fetchUser());
                addSuccess({
                    message: (
                        <FormattedMessage
                            id="integrations.calendar.settings_saved"
                            defaultMessage="Calendar settings saved successfully'"
                        />
                    ),
                });
            }}
        >
            {props => (
                //@ts-ignore
                <FormPane
                    title={
                        <FormattedMessage
                            id="integrations.calendar.configure_your_calendars"
                            defaultMessage="Configure your calendars"
                        />
                    }
                    onSubmit={props.handleSubmit}
                    status={Map({
                        is_saving: props.isSubmitting,
                    })}
                >
                    <CalendarIntegrationSection userId={userId} loading={loading} connected={props.values.connected} />
                    <AgencySiteSelfSchedulingSection />
                </FormPane>
            )}
        </FormikWithConfirmation>
    );
};

export default withErrorBoundary(CalendarIntegrations);

function useCalendarIntegration() {
    const office = useOffice();

    const { data, loading, refetch } = useQuery<GetUserWithCalendarQuery>(GET_USER_CALENDAR);

    const [addIntegration] = useMutation(
        gql`
            mutation AddCalendarIntegration($integration: JSON!) {
                addIntegration(input: { integration: $integration }) {
                    user {
                        id
                        integrations
                    }
                }
            }
        `,
        {
            refetchQueries: [
                {
                    query: GET_OFFICE_NEGOTIATORS_QUERY,
                    variables: {
                        id: office.get('id'),
                    },
                },
            ],
        },
    );

    const [removeIntegration] = useMutation(
        gql`
            mutation RemoveCalendarIntegration {
                removeIntegration(input: { name: "cronofy" }) {
                    user {
                        id
                        integrations
                    }
                }
            }
        `,
        {
            refetchQueries: [
                {
                    query: GET_OFFICE_NEGOTIATORS_QUERY,
                    variables: {
                        id: office.get('id'),
                    },
                },
            ],
        },
    );

    const calendarIntegration: Maybe<Calendar> = data?.me.calendar;

    // eslint-disable-next-line no-use-before-define
    const appointmentServiceUrl: Maybe<string> = data?.me.appointmentServiceUrl;

    return {
        userId: data?.me.id,
        hasCalendarIntegration: data?.me.hasCalendarIntegration,
        calendarIntegration,
        appointmentServiceUrl,
        loading,
        refetch,
        addIntegration,
        removeIntegration,
    };
}

function createCronofyIntegration(
    account: {
        accessToken?: string;
        refreshToken?: string;
        profile?: any;
    },
    calendarId,
) {
    return {
        name: 'cronofy',
        access_token: {
            access_token: account.accessToken,
            refresh_token: account.refreshToken,
        },
        configuration: {
            calendar_id: calendarId,
            profile: account.profile,
        },
    };
}

function validateForm(values) {
    const errors: {
        calendarId?: React.ReactNode;
        appointmentServiceUrl?: React.ReactNode;
    } = {};

    if (values.connected) {
        if (!values.calendarId) {
            errors.calendarId = (
                <FormattedMessage
                    id="integrations.calendar.validation.not_selected"
                    defaultMessage="You need to choose a calendar"
                />
            );
        }
    }

    if (values.appointmentServiceUrl) {
        // validate it is a url

        const isValidServiceUrl = checkAppointmentServiceUrl(values.appointmentServiceUrl.trim());
        if (!isValidServiceUrl) {
            errors.appointmentServiceUrl = (
                <FormattedMessage
                    id="integrations.calendar.validation.url_invalid"
                    defaultMessage="This does not look like a valid service URL"
                />
            );
        }
    }

    return errors;
}
