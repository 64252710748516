import gql from 'graphql-tag';
import { ContactCardFragment } from '../../fragments/contactCard';

export const GET_LEAD_QUERY = gql`
    query getLead($contactId: ID!) {
        lead(id: $contactId) {
            id
            ...ContactCardFragment
        }
    }
    ${ContactCardFragment}
`;
