import compose from 'recompose/compose';
import { reduxForm as form, propTypes } from 'redux-form';
import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import { validateWithRules } from '@/app.utils/services/Validator';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import useUser from '@/app.hooks/useUser';
import NumberedStep from '@/app.components/modals/Channels/elements/NumberedStep';
import Email from '../../../app.components/elements/Email/PortalEmail';
import Input from '../../forms/Input/Input';
import PortalConfigModalBody from './PortalConfigModalBody';
import { nameValidationRule } from './utils';

const DomainGroupImpl = ({ fields, handleSubmit, editing, ...props }) => {
    const user = useUser();

    return (
        <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
            <div className="space-y-4">
                <NumberedStep
                    step={1}
                    title={<FormattedMessage id="channels.activation_step.title" defaultMessage="Find your details" />}
                >
                    <p className="text-muted m-0 leading-none">
                        <Email
                            linkText={<FormattedMessage id="channels.send_email" defaultMessage="Send this e-mail" />}
                            portal={props.portal}
                            to={['support@domain.com.au']}
                            subject={
                                <FormattedMessage
                                    id="emails.domain.activate.subject"
                                    defaultMessage="I would like to setup SweepBright as Uploader"
                                />
                            }
                            body={
                                <FormattedMessage
                                    id="emails.domain.activate.body"
                                    defaultMessage="I have a Domain account and hereby inform you that I’m setting SweepBright as Uploader."
                                />
                            }
                            user={user}
                        />
                        <FormattedMessage
                            id="channels.send_email.notify_domain_group"
                            defaultMessage=" to notify Domain Group that you activated SweepBright."
                        />
                    </p>
                </NumberedStep>
                <NumberedStep
                    step={2}
                    title={<FormattedMessage id="channels.details_step.title" defaultMessage="Fill in your details" />}
                    last
                >
                    <Input
                        type="text"
                        label={
                            <FormattedMessage id="forms.labels.domain_agency_id" defaultMessage="Domain Agency ID" />
                        }
                        {...fields.accessToken}
                        disabled={editing}
                    />
                    {props.showNameField && (
                        <Input
                            {...fields.name}
                            label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                            help={
                                <FormattedMessage
                                    id="channels.name.description"
                                    defaultMessage="This is the name you will see in the Publish section of SweepBright."
                                />
                            }
                        />
                    )}
                </NumberedStep>
            </div>
        </PortalConfigModalBody>
    );
};

DomainGroupImpl.propTypes = {
    ...propTypes,
    editing: PropTypes.bool,
};

const DomainGroup = compose(
    form(
        {
            form: 'channels/portal',
            formKey: 'domain-group',
            fields: ['accessToken', 'name'],
            validate: validateWithRules(
                {
                    accessToken: {
                        type: 'string',
                        required: true,
                        allowEmpty: false,
                        messages: {
                            required: 'general.validation.field_required',
                            allowEmpty: 'general.validation.field_required',
                        },
                    },
                    name: nameValidationRule,
                },
                { humanizeErrorMessage: false },
            ),
        },
        createStructuredSelector({
            // if the multiple accounts feature is on,
            // we show the name field, otherwise, it is invisible to the user
            // even though the name is still set
            showNameField: getFeature('portals.multipleAccounts.enabled'),
        }),
    ),
)(DomainGroupImpl);

DomainGroup.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
            accessToken
        }
    `,
};

export default DomainGroup;
