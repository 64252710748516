// eslint-disable-next-line
const reactIntlModule = require('react-intl');

let reactIntlPhraseAppModule = {};
// we only use the PhraseApp version of the FormattedMessage if
// we are on the client and  __ENABLE_PHRASEAPP__ ===  'true'
const useOriginalModule = __SERVER__ || !__ENABLE_PHRASEAPP__;
if (!useOriginalModule) {
    reactIntlPhraseAppModule = require('react-intl-phraseapp');
}

exports.defineMessages = reactIntlModule.defineMessages;
exports.IntlProvider = reactIntlModule.IntlProvider;
exports.injectIntl = reactIntlModule.injectIntl;
exports.InjectedIntl = reactIntlModule.InjectedIntl;

exports.FormattedMessage = useOriginalModule
    ? reactIntlModule.FormattedMessage
    : reactIntlPhraseAppModule.FormattedMessage;

exports.FormattedHTMLMessage = reactIntlModule.FormattedHTMLMessage;

exports.FormattedPlural = reactIntlModule.FormattedPlural;
exports.FormattedDate = reactIntlModule.FormattedDate;
