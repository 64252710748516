import React from 'react';
import { getValues } from 'redux-form';
import { useSelector } from 'react-redux';
import CompaniesRequests from '@/requests/CompaniesRequests';
import formatApiErrors from '@/app.redux/sagas/Helpers/formatApiErrors';
import { APIEstimate } from '@sweepbright/webapp-server/graphql/resolvers/helpers/types';

function useEstimate({
    companyId,
    interval,
    usersAmount = 1,
    coupons,
    skip,
}: {
    companyId: string;
    interval: string;
    coupons?: string[];
    usersAmount: number;
    skip?: boolean;
}) {
    const [calculating, setCalculating] = React.useState(false);
    const [estimate, setEstimate] = React.useState<Maybe<APIEstimate>>(null);
    const [errors, setErrors] = React.useState<Record<string, any>>({});

    // @ts-ignore
    React.useEffect(() => {
        setErrors({});
        if (!skip) {
            let active = true;
            setCalculating(true);
            new CompaniesRequests()
                .estimate(companyId, {
                    interval,
                    coupons,
                    users_amount: usersAmount,
                })
                .then(
                    (estimate: APIEstimate) => {
                        if (active) {
                            setEstimate(estimate);
                        }
                    },
                    error => {
                        if (active) {
                            if (error.response?.status === 422) {
                                setErrors(formatApiErrors(error));
                            }
                        }
                    },
                )
                .finally(() => {
                    if (active) {
                        setCalculating(false);
                    }
                });

            return () => {
                active = false;
            };
        } else {
            setCalculating(false);
        }
    }, [companyId, interval, usersAmount, coupons, skip]);

    return { calculating, estimate, interval, errors };
}

function EstimateCalculator({ render, companyId }) {
    // get the wizard form values
    const values = useSelector(getFormValues('subscription/upgrade'));

    const { estimate: monthlyEstimate, calculating: calculatingMonthly, errors: monthlyEstimateErrors } = useEstimate({
        companyId,
        usersAmount: values.users_amount,
        coupons: values.coupons,
        interval: 'month',
    });

    const { estimate: yearlyEstimate, calculating: calculatingYearly, errors: yearlyEstimateErrors } = useEstimate({
        companyId,
        usersAmount: values.users_amount,
        coupons: values.coupons,
        interval: 'year',
    });

    return render({
        calculating: calculatingYearly || calculatingMonthly,
        monthlyEstimate,
        yearlyEstimate,
        errors: values.interval === 'month' ? monthlyEstimateErrors : yearlyEstimateErrors,
    });
}

function getFormValues(formName: string) {
    return state => {
        return getValues(state.form[formName]) ?? {};
    };
}

export default React.memo(EstimateCalculator);
