// @ts-nocheck
import { push } from 'react-router-redux';
import { startSubmit, stopSubmit } from 'redux-form';
import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { IntercomAPI } from 'react-intercom';
import CookieService from '@/app.utils/services/CookieService';
import { setCode } from '@/app.redux/actions/AuthActions';
import AuthRequests, { RegisterAttributes } from '@/requests/AuthRequests';
import { LOGIN } from '../../../app.routing/routes';
import formatApiErrors from '../Helpers/formatApiErrors';

export default function* onRegister({ attributes }: { attributes: RegisterAttributes }) {
    yield put(startSubmit('register'));
    const cookieService = CookieService();
    yield call(cookieService.remove, 'redirect_to', { path: '/' });
    try {
        const response = yield call(new AuthRequests().register, attributes);

        yield put(stopSubmit('register'));

        const company = response.data.company.data || {};
        const intercomAppId = company.intercom_app_id;

        /* Insert just the basic data into Intercom, as `getIntercomValues` const can't yet be defined. */
        if (intercomAppId) {
            // TODO: What if the user has already been invited? Check Handlers/Register:60
            IntercomAPI('update', {
                app_id: intercomAppId,
                api_base: company.intercom_api_base,
                user_id: response.data.id,
                user_hash: response.data.intercom_hash,
                name: `${attributes.first_name} ${attributes.last_name}`,
                email: attributes.email,
                company: {
                    id: company.id,
                    name: company.name,
                    source: company.referrer,
                    enterprise: attributes.enterprise,
                },
            });
        }

        const code = response.meta && response.meta.login_code;
        if (code) {
            yield put(setCode(code));
            yield call(delay, 2000);
            yield put(push(`${LOGIN}/${code}`));
        }
    } catch (response) {
        yield put(stopSubmit('register', formatApiErrors(response)));
    }
}
