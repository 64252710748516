// @ts-nocheck
import parsePhoneNumber from 'libphonenumber-js';

export default function toPhoneNumber(value: string | number = '') {
    if (typeof value === 'string' || typeof value === 'number') {
        try {
            const parsedNumber = parsePhoneNumber(String(value), 'BE');
            // If the phone number supplied isn't valid then an empty object `{}` is returned.

            return parsedNumber.formatInternational();
        } catch (error) {
            return value;
        }
    }

    return '';
}
