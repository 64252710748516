import { languages } from '@/app.data/Localization';

export function WithLanguage({ fields, children }) {
    return languages.map(language => {
        if (!fields.languages.value[language.value]) {
            return null;
        }

        return children(language);
    });
}
