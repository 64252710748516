import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import { Formik } from 'formik';
import { Map } from 'immutable';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import FileRepository, { FileData } from '@/app.utils/services/Repositories/FileRepository';
import Modal from '@/app.components/elements/Modal';
import ImageLoader from '../images/ImageLoader';
import Input from '../forms/Input/Input';
import VrView from '../elements/VrView';
import { useModal } from './AbstractModal';

type Props = {
    file: FileData;
    onFileDelete: (file: FileData) => void;
    onSubmit: (values: FileData) => void;
    property: Map<string, any>;
    type: string;
    title: React.ReactNode;
    disabled?: boolean;
    onEntered: () => void;
};

const FileEditionModal: React.FunctionComponent<Props> = (props, ref) => {
    const { file, property, type, title, onEntered, disabled } = props;

    const { show, handleOpen, handleClose } = useModal();

    React.useImperativeHandle(ref, () => ({
        open: handleOpen,
        close: handleClose,
    }));

    const removeFile = () => {
        props.onFileDelete(file);
        handleClose();
    };

    const onSubmit = (values: FileData) => {
        // Submit form
        handleClose();
        props.onSubmit(values);
    };

    const renderPreview = () => {
        const repository = new FileRepository(property.get('id'), file);

        const filePath = repository.getFileUrl(type, 'original', 'documents');
        const thumbnailPath = repository.getThumbnail(type, 'thumbnail', 'documents');

        if (!filePath) {
            return null;
        }

        const preview =
            filePath.includes('https') && file.equirectangular ? (
                <VrView src={filePath} />
            ) : (
                <p>
                    <ImageLoader
                        //@ts-ignore
                        src={thumbnailPath}
                        responsive
                        style={{ minWidth: '100%' }}
                        fallback={
                            <p className="text-center">
                                <FormattedMessage
                                    id="forms.labels.preview.not_available"
                                    defaultMessage="No preview available"
                                />
                            </p>
                        }
                    />
                </p>
            );

        return (
            <FormGroup>
                <Col sm={3}>
                    <ControlLabel>
                        <FormattedMessage id="forms.labels.preview" defaultMessage="Preview" />
                    </ControlLabel>
                </Col>
                <Col sm={11}>
                    <p>
                        <Button
                            bsSize="large"
                            block
                            variant="ghost"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={filePath}
                        >
                            <FormattedMessage id="forms.cta.download_file" defaultMessage="Download File" />
                        </Button>
                    </p>
                    {preview}
                </Col>
            </FormGroup>
        );
    };

    const classes = {
        labelClassName: 'col-sm-3',
        wrapperClassName: 'col-sm-11',
    };

    return (
        <Formik onSubmit={onSubmit} initialValues={file} enableReinitialize={true}>
            {({ values, handleSubmit, handleChange, resetForm }) => {
                return (
                    <Modal
                        show={show}
                        onHide={handleClose}
                        onEntered={onEntered}
                        onExited={() => resetForm()}
                        testId="file-edition-modal"
                    >
                        <form onSubmit={handleSubmit} className="form-horizontal">
                            <Modal.Header>
                                <Modal.Title>{title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Input
                                    type="text"
                                    label={
                                        <FormattedMessage
                                            id="forms.labels.file.description"
                                            defaultMessage="Description"
                                        />
                                    }
                                    name="description"
                                    value={values.description ?? ''}
                                    onChange={handleChange}
                                    placeholder={file.filename}
                                    {...classes}
                                />

                                <FormGroup>
                                    <Input
                                        type="checkbox"
                                        label={<FormattedMessage id="forms.labels.private" defaultMessage="Private" />}
                                        name="private"
                                        checked={values.private}
                                        onChange={handleChange}
                                        wrapperClassName="col-sm-push-3 col-sm-11"
                                    />
                                </FormGroup>
                                {renderPreview()}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button disabled={disabled} variant="danger" className="pull-left" onClick={removeFile}>
                                    <FormattedMessage id="forms.edit-file.delete" defaultMessage="Delete" />
                                </Button>
                                <Button onClick={handleClose}>
                                    <FormattedMessage id="forms.edit-file.cancel" defaultMessage="Cancel" />
                                </Button>
                                <Button disabled={disabled} type="submit" variant="primary">
                                    <FormattedMessage id="forms.edit-file.cta" defaultMessage="Save" />
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                );
            }}
        </Formik>
    );
};

//@ts-ignore
export default React.forwardRef(FileEditionModal);
