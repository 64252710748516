import { createReducer } from 'redux-reducer-factory';
import { Map } from 'immutable';
import { FETCH_USER_FAILED } from '@/app.redux/actions';
import { IS_LOGGING_IN, LOGGED_IN, LOGOUT, SET_CODE } from '../actions/AuthActions';

export default createReducer(Map(), {
    [IS_LOGGING_IN]: (auth, action) => {
        return auth.set('is_logging_in', Boolean(action.loggingIn));
    },
    [LOGGED_IN]: auth => {
        return auth.set('logged_in', true).set('is_logging_in', false);
    },
    [LOGOUT]: auth => auth.set('logged_in', false).set('is_logging_in', false),
    [SET_CODE]: (auth, action) => {
        return auth.set('code', action.code);
    },
    [FETCH_USER_FAILED]: auth => auth.set('logged_in', false).set('is_logging_in', false),
});
