import React, { FC, useCallback } from 'react';
import ErrorBoundary from 'react-error-boundary';
import Popover from '@/app.components/elements/Popover';

export const useEventPopover = (ref: React.RefObject<HTMLDivElement>) => {
    const [isShown, setPopoverShown] = React.useState(false);
    const show = useCallback(() => setPopoverShown(true), [setPopoverShown]);
    const hide = useCallback(() => setPopoverShown(false), [setPopoverShown]);

    return { ref, isShown, show, hide };
};

export const EventCardPopover: FC<{
    popover: ReturnType<typeof useEventPopover>;
    children: () => JSX.Element;
}> = ({ popover, children }) => {
    return (
        <Popover
            className="c-popover__content c-popover__content--medium p-0"
            show={popover.isShown}
            placement="top"
            referenceElement={popover.ref.current ?? undefined}
            onClickOutside={() => {
                popover.hide();
            }}
        >
            <ErrorBoundary>{children()}</ErrorBoundary>
        </Popover>
    );
};
