// @ts-nocheck
import React from 'react';
import { Map } from 'immutable';
import Modal from '@/app.components/elements/Modal';
import modals from '@/app.components/modals/Channels';
import Tile from '@/app.components/elements/Tile/Tile';
import { ButtonLink, Icon } from '@/app.components';
import Button from '@/app.components/elements/Buttons/Button';
import { useModal } from '@/app.components/modals/AbstractModal';
import { useSaveChannelAccount } from '@/app.domains/channels/accountMutations';
import { useToasts } from '@sweepbright/notifications/src';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { Channel } from '@/graphql/generated/types';
import { FormattedMessage } from 'react-intl-sweepbright';
import ChannelImage from '@/app.shared/channels/ChannelImage';

const AddChannelAccountTile = ({
    channel,
    company,
    onAccountAdded,
}: {
    channel: Channel;
    company: Map<string, any>;
    onAccountAdded: (channel: Channel, id?: string) => void;
}) => {
    const { handleOpen, getModalProps, handleClose } = useModal(false);
    const { addError } = useToasts();
    const ModalContent = modals[channel.id] || modals.default;
    const [saveChannelAccount] = useSaveChannelAccount(channel);

    const handleSubmit = async (attributes: {
        name: string;
        credentials: { accessToken: string; accessTokenSecret: string };
    }) => {
        try {
            const response = await saveChannelAccount(attributes);
            const savedId = response?.data?.saveChannelAccount?.id;

            handleClose();
            onAccountAdded(channel, savedId);
        } catch (err) {
            addError({
                message: <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />,
            });
            getBugsnagClient().notify(err);
        }
    };

    return (
        <Tile className="px-1">
            <ButtonLink className="c-spacer-bottom no-underline" onClick={handleOpen}>
                <ChannelImage channel={channel} size="lg" shape="square" />
            </ButtonLink>
            <Button className="whitespace-normal items-baseline" variant="link" onClick={handleOpen}>
                <Icon className="mr-1" icon="add" />
                <span className="btn-label">{channel.name}</span>
            </Button>
            <Modal {...getModalProps()} autoFocus>
                <ModalContent
                    portal={channel}
                    company={company}
                    onSubmit={handleSubmit}
                    close={handleClose}
                    initialValues={{
                        name: channel.name,
                    }}
                />
            </Modal>
        </Tile>
    );
};

export default AddChannelAccountTile;
