import { difference, orderBy, range } from 'lodash';
import pluralize from 'pluralize';
import { PropertyType, roomsWithoutAreaLinePerType, roomTypes as iRoomTypesByPropertyType } from '@/app.data';
import { toTitle } from '@/app.utils/services/String';
import { getDefaultUnitForArea, unitsBySystem } from '../../../../app.data/Rooms';
import { CommandAddRoom, CommandRemoveRoom, Room, RoomsSyncCommand } from './types';

const roomTypesByPropertyType = iRoomTypesByPropertyType.toJS();
const blacklistedRoomsPerType = roomsWithoutAreaLinePerType.toJS();

const CUSTOM_ROOM = 'custom';

/**
 * Class which is used for synchronization between amenity flags, rooms counts and the list of rooms area objects.
 *
 * Produces a list of commands ("add room", "remove room") which then is applied on actual rooms by aggregate function.
 */
export class RoomsSyncCommandSource {
    constructor(
        private _propertyType: PropertyType,
        private _amenities: { [key: string]: boolean },
        private _structure: { [key: string]: number },
        private _measurementSystem: 'imperial' | 'metric' = 'metric',
    ) {}

    public synchronizeRooms(rooms: Room[]): RoomsSyncCommand[] {
        const desiredRoomCounts = this.desiredRoomCounts;
        const actualRoomCounts = this.getActualRoomCounts(rooms);

        const toAdd = this.getCountsOfRoomsToAdd(desiredRoomCounts, actualRoomCounts);
        const toRemove = this.getCountsOfRoomsToRemove(desiredRoomCounts, actualRoomCounts);

        const addingCommands = this.generateAddingCommands(toAdd);
        const removingCommands = this.generateRemovingCommands(toRemove, rooms);

        return [...addingCommands, ...removingCommands];
    }

    private generateAddingCommands = (countsOfRoomsToAdd: [string, number][]): CommandAddRoom[] => {
        return countsOfRoomsToAdd.flatMap(([type, count]) => {
            let units = unitsBySystem.get(this._measurementSystem).get('area');
            if (this.allowedAmenities.includes(type)) {
                units = getDefaultUnitForArea(type, this._measurementSystem, this._propertyType);
            }

            const room: CommandAddRoom['payload'] = {
                type,
                units,
                size_description: pluralize.singular(toTitle(type)),
            };

            return range(0, count).map(() => ({
                type: 'add',
                payload: room,
            }));
        });
    };

    private generateRemovingCommands = (
        countsOfRoomsToRemove: [string, number][],
        rooms: Room[],
    ): CommandRemoveRoom[] => {
        return countsOfRoomsToRemove.flatMap(([roomType, count]) => {
            const roomsOfType = rooms.filter(room => room.type === roomType);
            const orderedRoomsOfType: Room[] = orderBy(roomsOfType, ['size'], ['asc']);

            return orderedRoomsOfType.slice(0, count).map(
                room =>
                    ({
                        type: 'remove',
                        payload: { size: room.size, type: roomType },
                    } as CommandRemoveRoom),
            );
        });
    };

    private getCountsOfRoomsToAdd(
        desiredRoomCounts: { [type: string]: number },
        actualRoomCounts: { [type: string]: number },
    ): [string, number][] {
        return Object.entries(desiredRoomCounts)
            .map(([roomType, desiredCount]) => {
                const actualCount = actualRoomCounts[roomType] ?? 0;

                return [roomType, desiredCount - actualCount];
            })
            .filter((pair): pair is [string, number] => pair[1] > 0);
    }

    private getCountsOfRoomsToRemove(
        desiredRoomCounts: { [type: string]: number },
        actualRoomCounts: { [type: string]: number },
    ): [string, number][] {
        return Object.entries(actualRoomCounts)
            .map(([roomType, actualCount]) => {
                const desiredCount = desiredRoomCounts[roomType] ?? 0;

                return [roomType, actualCount - desiredCount];
            })
            .filter((pair): pair is [string, number] => pair[0] !== CUSTOM_ROOM && pair[1] > 0);
    }

    private get blacklistedRoomTypes() {
        return blacklistedRoomsPerType[this._propertyType];
    }

    private get allowedCountableRoomTypes(): string[] {
        const allTypes = roomTypesByPropertyType[this._propertyType] ?? [];

        const result = difference(allTypes, this.blacklistedRoomTypes);

        return result;
    }

    private get allowedAmenities(): string[] {
        const allAmenities = Object.entries(this._amenities)
            .filter(([, value]) => Boolean(value))
            .map(([key]) => key);

        const result = difference(allAmenities, this.blacklistedRoomTypes);

        return result;
    }

    private get desiredRoomCounts() {
        const countableRoomsCounts = this.allowedCountableRoomTypes.map(roomType => {
            const countAsStr = (this._structure[roomType] as unknown) as string;
            const count = parseInt(countAsStr) || 0;

            return [roomType, count];
        });

        const amenitiesCounts = this.allowedAmenities.map(roomType => {
            return [roomType, this._amenities[roomType] ? 1 : 0];
        });

        return {
            ...Object.fromEntries(countableRoomsCounts),
            ...Object.fromEntries(amenitiesCounts),
        };
    }

    private getActualRoomCounts(rooms: Room[]) {
        return rooms.reduce((result, room) => {
            if (!result[room.type]) {
                result[room.type] = 0;
            }
            result[room.type]++;

            return result;
        }, {} as { [type: string]: number });
    }
}
