// @ts-nocheck
/* eslint-disable filenames/match-exported, filenames/match-regex */
import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { InputProps } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { FormFields } from '@sweepbright/webapp-shared/utils/types';
import { track, events } from '@/app.utils/analytics';
import PropertiesFiltersSettings from '@/app.domains/properties/PropertiesFiltersSettings';
import PropertiesSortingSettings from '@/app.domains/properties/PropertiesSortingSettings';
import FormikWithFields from '../forms/FormikWithFields';
import { propertyTypes, statusTypes } from '../../app.data/Properties';
import Tabs from '../tab';

type Props = {
    excludeProjects?: boolean;
    initialValues: Record<string, any>;
    isOpen: boolean;
    onClose: () => void;
    onReset: () => void;
    onSubmit: (arg: Record<string, any>) => void;
};

const fieldNames = [
    ...Array.from(propertyTypes, (type: string) => `type.${type}`),
    ...Array.from(statusTypes, status => `status.${status}`),
    'filterByStatus',
    'query',
    'ownership',
    'negotiation',
    'property_type',
    'archived',
    'bright',
    'negotiator',
    'min_price',
    'max_price',
    'sort_field',
    'sort_order',
];

class PropertiesSearchModal extends React.Component<Props> {
    static contextTypes = {
        // @ts-ignore
        user: PropTypes.instanceOf(Map).isRequired,
    };

    componentDidMount() {
        // TODO: Record analytics here
    }

    handleChangeSortingSettings = (fields: FormFields<{ sort_field: string; sort_order: string }>) => ({
        sortField,
        sortOrder,
    }: {
        sortField: string;
        sortOrder: string;
    }) => {
        fields.sort_field.onChange(sortField);
        fields.sort_order.onChange(sortOrder);
    };

    renderModal() {
        const { excludeProjects } = this.props;
        const { user } = this.context;

        const pathname = String(typeof window !== 'undefined' && window.location.pathname);
        const isMatch = pathname.includes('match');
        const isVisit = pathname.includes('visit');
        const isPublish = pathname.includes('publish');

        return (
            <FormikWithFields
                initialValues={this.props.initialValues}
                fieldNames={fieldNames}
                onSubmit={this.props.onSubmit}
            >
                {({ handleSubmit, fields }) => {
                    const onFilterBySaleStatus = (event: React.FormEvent<HTMLInputElement>) => {
                        fields.filterByStatus.onChange(event);
                        if (!event.currentTarget.checked) {
                            Object.values(fields.status).forEach((statusField: InputProps) =>
                                statusField.onChange(false),
                            );
                        }
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <Modal.Header>
                                <Modal.Title>
                                    <FormattedMessage
                                        id="modals.properties_search.title"
                                        defaultMessage="Filter and sort properties"
                                    />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Tabs
                                    tabs={[
                                        {
                                            id: 'filter_settings',
                                            label: (
                                                <FormattedMessage
                                                    id="modals.properties_search.filter.tab_title"
                                                    defaultMessage="Filter"
                                                />
                                            ),
                                            onClick() {
                                                track(events.SEARCH_PROPERTIES_FILTER_TAB_CLICKED);
                                            },
                                        },
                                        {
                                            id: 'sorting_settings',
                                            label: (
                                                <FormattedMessage
                                                    id="modals.properties_search.sort.tab_title"
                                                    defaultMessage="Sort"
                                                />
                                            ),
                                            onClick() {
                                                track(events.SEARCH_PROPERTIES_SORT_TAB_CLICKED);
                                            },
                                        },
                                    ]}
                                >
                                    <Tabs.Item>
                                        <PropertiesFiltersSettings
                                            fields={fields}
                                            user={user}
                                            onFilterBySaleStatus={onFilterBySaleStatus}
                                            showArchivedFilter={!isMatch && !isVisit && !isPublish}
                                            excludeProjects={excludeProjects}
                                        />
                                    </Tabs.Item>
                                    <Tabs.Item>
                                        <PropertiesSortingSettings
                                            onChange={this.handleChangeSortingSettings(fields)}
                                            initialValues={{
                                                sortField: fields.sort_field.value,
                                                sortOrder: fields.sort_order.value,
                                            }}
                                        />
                                    </Tabs.Item>
                                </Tabs>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="pull-left">
                                    <Button variant="danger" onClick={this.props.onReset}>
                                        <FormattedMessage id="modals.common.actions.reset" defaultMessage="Reset" />
                                    </Button>
                                </div>
                                <div className="pull-right">
                                    <Button onClick={this.props.onClose}>
                                        <FormattedMessage id="modals.common.actions.cancel" defaultMessage="Cancel" />
                                    </Button>
                                    <Button type="submit" variant="primary">
                                        <FormattedMessage id="modals.common.actions.apply" defaultMessage="Apply" />
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </form>
                    );
                }}
            </FormikWithFields>
        );
    }

    render() {
        return (
            <Modal show={this.props.isOpen} onHide={this.props.onClose}>
                {this.renderModal()}
            </Modal>
        );
    }
}

export default PropertiesSearchModal;
