import { EstateChannelAccountFragment, PublicationStatus } from '@/graphql/generated/types';
import useEstateChannelAccounts from './useEstateChannelAccounts';

interface Counters {
    successes: number;
    errors: number;
}

const zeroCounters: Readonly<Counters> = {
    successes: 0,
    errors: 0,
};

function getCounters(channelAccounts: EstateChannelAccountFragment[]): Readonly<Counters> {
    const counters: Counters = {
        successes: 0,
        errors: 0,
    };

    channelAccounts.forEach(channelAccount => {
        switch (channelAccount.publicationStatus) {
            case PublicationStatus.Published:
            case PublicationStatus.Unpublishing: {
                counters.successes++;
                break;
            }

            case PublicationStatus.PublishingFailed:
            case PublicationStatus.UnpublishingFailed: {
                counters.errors++;
                break;
            }
        }
    });

    return counters;
}

export default function useEstatePublicationCounters(propertyId: string) {
    const {
        loading,
        channelAccountsConnection: { edges: channelAccounts = [] } = {},
    } = useEstateChannelAccounts(propertyId, false, true, ['failed']);

    return {
        loading,
        counters: loading ? zeroCounters : getCounters(channelAccounts),
    };
}
