import React from 'react';
import { Checkbox } from '@sweepbright/uikit';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import produce from 'immer';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import Icon from '@/app.components/icons/Icon';
import { FormattedMessage } from 'react-intl-sweepbright';

const SortableColumn = SortableElement(
    ({
        column,
        checked,
        onChange,
        keyPrefix,
    }: {
        column: string;
        checked: boolean;
        onChange: () => void;
        keyPrefix: string;
    }) => (
        <div className="px-4 flex items-center justify-between">
            <div className="-my-1">
                <Checkbox checked={checked} onChange={onChange}>
                    <FormattedMessage id={`${keyPrefix}.${column}`} defaultMessage={column} />
                </Checkbox>
            </div>
            <DragHandle />
        </div>
    ),
);

// generate a column toggle using immer

const toggleVisibilityAtIndex = produce((columns, idx) => {
    columns[idx][1] = !columns[idx][1];
});

const SortableColumnsList = SortableContainer(({ columns, onToggle, columnsKeyPrefix }) => {
    return (
        <div className="py-2 w-full max-w-md " style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {columns.map(([column, checked], idx) => {
                return (
                    <SortableColumn
                        key={column}
                        column={column}
                        index={idx}
                        checked={checked}
                        onChange={() => onToggle(idx)}
                        keyPrefix={columnsKeyPrefix}
                    />
                );
            })}
        </div>
    );
});

export function ColumnsSelector({
    columns,
    setColumns,
    columnsKeyPrefix,
}: {
    columns: Array<[string, boolean]>;
    setColumns: (updatedColumns: Array<[string, boolean]>) => void;
    columnsKeyPrefix: string;
}) {
    return (
        <div className="relative">
            <Dropdown id="status_dropdown" data-testid="property-status-dropdown">
                <Dropdown.Toggle
                    as="button"
                    className="bg-transparent cursor-default flex items-center focus:outline-none focus:shadow-outline rounded"
                    type="button"
                >
                    <Icon icon="options" size={24} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="relative">
                        <SortableColumnsList
                            columns={columns}
                            lockAxis="y"
                            lockToContainerEdges
                            useDragHandle
                            lockOffset="0%"
                            columnsKeyPrefix={columnsKeyPrefix}
                            helperClass="z-50 bg-white shadow"
                            onSortEnd={({ oldIndex, newIndex }) => {
                                setColumns(arrayMove(columns, oldIndex, newIndex));
                            }}
                            onToggle={idx => {
                                setColumns(toggleVisibilityAtIndex(columns, idx));
                            }}
                        />
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

const DragHandle = SortableHandle(() => (
    <div className="ml-5 flex items-center" style={{ cursor: 'row-resize' }}>
        <DragIcon className="w-4 h-4" />
    </div>
));

function DragIcon({ className }) {
    return (
        <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 6C13.5 6.82843 14.1716 7.5 15 7.5C15.8284 7.5 16.5 6.82843 16.5 6C16.5 5.17157 15.8284 4.5 15 4.5C14.1716 4.5 13.5 5.17157 13.5 6ZM15 13.5C14.1716 13.5 13.5 12.8284 13.5 12C13.5 11.1716 14.1716 10.5 15 10.5C15.8284 10.5 16.5 11.1716 16.5 12C16.5 12.8284 15.8284 13.5 15 13.5ZM15 19.5C14.1716 19.5 13.5 18.8284 13.5 18C13.5 17.1716 14.1716 16.5 15 16.5C15.8284 16.5 16.5 17.1716 16.5 18C16.5 18.8284 15.8284 19.5 15 19.5ZM9 19.5C8.17157 19.5 7.5 18.8284 7.5 18C7.5 17.1716 8.17157 16.5 9 16.5C9.82843 16.5 10.5 17.1716 10.5 18C10.5 18.8284 9.82843 19.5 9 19.5ZM7.5 12C7.5 12.8284 8.17157 13.5 9 13.5C9.82843 13.5 10.5 12.8284 10.5 12C10.5 11.1716 9.82843 10.5 9 10.5C8.17157 10.5 7.5 11.1716 7.5 12ZM9 7.5C8.17157 7.5 7.5 6.82843 7.5 6C7.5 5.17157 8.17157 4.5 9 4.5C9.82843 4.5 10.5 5.17157 10.5 6C10.5 6.82843 9.82843 7.5 9 7.5Z"
                fill="#302E34"
            />
        </svg>
    );
}
