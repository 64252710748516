import DomainGroup from './DomainGroup';
import Hebbes from './Hebbes';
import Immovlan from './Immovlan';
import Immoweb from './Immoweb';
import ImmowebV2 from './ImmowebV2';
import Rea from './Rea';
import Generic from './Generic';
import Feed from './Feed';
import Zoopla from './Zoopla';
import OnTheMarket from './OnTheMarket';
import Rightmove from './Rightmove';
import Facebook from './Facebook';
import Twitter from './Twitter';
import Realo from './Realo';
import Ubiflow from './Ubiflow';
import PublicAPI from './PublicAPI';

export default {
    'domain-group': DomainGroup,
    hebbes: Hebbes,
    immovlan: Immovlan,
    immoweb: Immoweb,
    rea: Rea,
    zumper: Feed,
    zillow: Feed,
    zoopla: Zoopla,
    default: Generic,
    rightmove: Rightmove,
    onthemarket: OnTheMarket,
    facebook: Facebook,
    twitter: Twitter,
    realo: Realo,
    seloger: Ubiflow,
    'immoweb-v2': ImmowebV2,
    'logicimmo-fr': Ubiflow,
    leboncoin: Ubiflow,
    bienici: Ubiflow,
    'le-figaro-immo': Ubiflow,
    'belles-demeures': Ubiflow,
    'meilleurs-agents': Ubiflow,
    publicapi: PublicAPI,
    'vitrine-media': Ubiflow,
    amepi: Ubiflow,
    'bureau-locaux': Ubiflow,
    'cession-pme': Ubiflow,
    'green-acres': Ubiflow,
    'hotellerie-restauration': Ubiflow,
    'ouest-france': Ubiflow,
    'paru-vendu': Ubiflow,
    'propriete-le-figaro': Ubiflow,
    'seloger-bureau-commerce': Ubiflow,
    ubiflow: Ubiflow,
};
