import React from 'react';
import { useIntl } from 'react-intl';
import { useLocalStorage } from 'react-use';
import Button from '@sweepbright/uikit/build/esm/button';
import useOffice from '@/app.hooks/useOffice';
import Resource from '@/shared/resource';
import Link from '@/app.components/elements/Link';
import { ResourceContext } from '@/shared/resource/utils';
import EmptyState from '@/app.components/empty/EmptyState';
import { MEMBERS_INVITE } from '@/app.routing';
import { useMembers } from '../hooks/useMembers';
import { useMembersColumns } from '../hooks/useMembersColumns';
import { useMembersFilters } from '../hooks/useMembersFilters';

import { MembersSearchType } from '../types';

const MembersList = () => {
    const intl = useIntl();

    const office = useOffice();
    const officeId = office.get('id');

    const [searchSettings, setSearchSettings] = useLocalStorage<MembersSearchType>('members.filters', {
        query: '',
    });

    const { loading, error, members, totalCount, hasNextPage, handleFetchMore } = useMembers(searchSettings);

    const columns = useMembersColumns(searchSettings);

    const filters = useMembersFilters(searchSettings, setSearchSettings);

    const content = {
        title: intl.formatMessage({ id: 'members.title' }),
        noEntities: intl.formatMessage({ id: 'members.empty' }),
        errorTitle: intl.formatMessage({ id: 'members.error.title' }),
        errorSubtitle: intl.formatMessage({ id: 'members.error.subtitle' }),
        emptyTitle: intl.formatMessage({ id: 'members.empty.title' }),
        count: intl.formatMessage({ id: 'members.count' }, { count: totalCount }),
        invite: intl.formatMessage({ id: 'members.invite' }),
    };

    const tabs = [
        {
            filters,
            label: '',
            id: 'members',
            onTabSelected: () => {},
        },
    ];

    const getEmptyState = () => {
        return error ? (
            <EmptyState title={content.errorTitle} body={content.errorSubtitle} />
        ) : (
            <EmptyState title={content.emptyTitle} />
        );
    };

    return (
        <ResourceContext.Provider
            value={{
                tabs,
                error,
                columns,
                loading,
                content,
                filters,
                officeId,
                totalCount,
                hasNextPage,
                getEmptyState,
                searchSettings,
                handleFetchMore,
                entities: members,
                setSearchSettings,
                initialTab: tabs[0].id,
                hasEntities: totalCount > 0,
                tableWrapperHeight: 'calc(100vh - 214px)',
                action: (
                    <Link key="invite" to={MEMBERS_INVITE}>
                        <Button variant="primary" className="pull-right">
                            {content.invite}
                        </Button>
                    </Link>
                ),
            }}
        >
            <Resource />
        </ResourceContext.Provider>
    );
};

export default MembersList;
