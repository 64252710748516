// @ts-nocheck
import React from 'react';
import produce, { Draft } from 'immer';
import { useQuery } from '@apollo/react-hooks';
import {
    BuyerPreferencesConnection,
    GetContactPreferencesQuery,
    GetContactPreferencesQueryVariables,
    Lead,
} from '@/graphql/generated/types';
import { GET_CONTACT_PREFERENCES_QUERY } from '@/graphql/queries/contacts/getPreferences';

const updateContactPreferenceQuery = produce(
    (
        prevDraft: Draft<GetContactPreferencesQuery>,
        { fetchMoreResult }: { fetchMoreResult: GetContactPreferencesQuery },
    ) => {
        if (fetchMoreResult) {
            if (prevDraft?.contact?.__typename === 'Lead') {
                prevDraft.contact.allPreferences.nodes = prevDraft?.contact?.allPreferences?.nodes.concat(
                    fetchMoreResult?.contact?.allPreferences.nodes,
                );

                prevDraft.contact.allPreferences.pageInfo = fetchMoreResult?.contact?.allPreferences.pageInfo;
            }
        }
    },
);

export function useContactPreferences(contactId: string) {
    const { data, loading, fetchMore: _fetchMore } = useQuery<
        GetContactPreferencesQuery,
        GetContactPreferencesQueryVariables
    >(GET_CONTACT_PREFERENCES_QUERY, {
        variables: {
            contactId,
        },
        fetchPolicy: 'cache-and-network',
    });

    const fetchMore = React.useCallback(
        page => {
            return _fetchMore({
                variables: {
                    page,
                },
                updateQuery: updateContactPreferenceQuery,
            });
        },
        [updateContactPreferenceQuery],
    );

    return {
        preferencesConnection: (data?.contact as Lead)?.allPreferences as BuyerPreferencesConnection,
        loading,
        fetchMore,
    };
}
