import { LocationClient, SearchPlaceIndexForTextCommand } from '@aws-sdk/client-location';
import countries from '@/app.data/countries.json';

async function withAPIKey(apiKey) {
    return {
        getLocationClientConfig: () => ({
            signer: {
                sign: async requestToSign => {
                    requestToSign.query = {
                        key: apiKey,
                        ...(requestToSign.query ?? {}),
                    };

                    return requestToSign;
                },
            },
            credentials: async () => ({}),
        }),
    };
}

async function getCoordinates(request) {
    const input = {
        MaxResults: 1,
        Text: request.address,
        IndexName: AWS_LOCATION_INDEX_NAME,
    };

    const authHelper = await withAPIKey(AWS_LOCATION_API_KEY);

    const client = new LocationClient({
        region: 'eu-west-1',
        apiVersion: '2020-11-19',
        ...authHelper.getLocationClientConfig(),
    });

    const command = new SearchPlaceIndexForTextCommand(input);

    const response = await client.send(command);

    return { lat: response.Results[0].Place.Geometry.Point[1], lng: response.Results[0].Place.Geometry.Point[0] };
}

export default class Geocoder {
    /**
     * Get the coordinates from an object of address components
     */
    static async getCoordinatesFromComponents(components = Map()) {
        const address = components.get('address');
        const country = components.get('country');

        const geocodingRequest = {
            address: address,
        };

        const fallbackRequest = {
            address: countries[country],
        };

        try {
            const location = await getCoordinates(geocodingRequest);

            if (location) {
                return {
                    lat: location.lat,
                    lng: location.lng,
                };
            }
        } catch (error) {
            console.error(error);
        }

        const fallbackLocation = await getCoordinates(fallbackRequest);

        if (fallbackLocation) {
            return {
                lat: fallbackLocation.lat,
                lng: fallbackLocation.lng,
            };
        }
    }
}
