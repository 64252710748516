import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { EstateRepository } from '@/app.utils/services';
import FeaturesCheckboxesSection from './FeaturesCheckboxesSection';
import { getFeatureTypes, getFieldLabel } from './helpers';

interface FeaturesCheckboxesSectionsListProps {
    property: EstateRepository;
    fields: any;
}

export const FeaturesCheckboxesSectionsList: FC<FeaturesCheckboxesSectionsListProps> = ({ property, fields }) => {
    const featureTypes = useMemo(() => {
        const context = property.getVisibilityContext();
        const featureTypes = getFeatureTypes(context);

        return featureTypes;
    }, [property]);

    return (
        <>
            {featureTypes.map(featureType => {
                const title = getFieldLabel(featureType);

                return (
                    <FeaturesCheckboxesSection
                        property={property}
                        fields={fields}
                        pathPrefix={`features.${featureType}`}
                        title={title}
                        key={featureType}
                    />
                );
            })}

            <FeaturesCheckboxesSection
                property={property}
                fields={fields}
                pathPrefix="permissions"
                title={<FormattedMessage id="pages.properties.permissions_panel.title" defaultMessage="Permissions" />}
                key="Permission"
            />
        </>
    );
};
