import { fromJS, List } from 'immutable';
import { defineMessages } from 'react-intl';

defineMessages({
    // Categories
    'integrations.documents.name': {
        id: 'integrations.documents.name',
        defaultMessage: 'Document Management',
    },
    'integrations.communication.name': {
        id: 'integrations.communication.name',
        defaultMessage: 'Communication',
    },
    'integrations.direct-marketing.name': {
        id: 'integrations.direct-marketing.name',
        defaultMessage: 'Direct Marketing',
    },
    'integrations.task-management.name': {
        id: 'integrations.task-management.name',
        defaultMessage: 'Task Management',
    },
    'integrations.accounting.name': {
        id: 'integrations.accounting.name',
        defaultMessage: 'Accounting',
    },
    'integrations.crm.name': {
        id: 'integrations.crm.name',
        defaultMessage: 'CRM',
    },
    'integrations.lead-capture.name': {
        id: 'integrations.lead-capture.name',
        defaultMessage: 'Lead Capture',
    },
    // Integrations
    'integrations.intercom.name': {
        id: 'integrations.intercom.name',
        defaultMessage: 'Intercom',
    },
    'integrations.intercom.title': {
        id: 'integrations.intercom.title',
        defaultMessage: 'Talk to your visitors with Intercom',
    },
    'integrations.livechat.name': {
        id: 'integrations.livechat.name',
        defaultMessage: 'Livechat',
    },
    'integrations.livechat.title': {
        id: 'integrations.livechat.title',
        defaultMessage: 'Talk to your visitors with Livechat',
    },
    'integrations.webmerge.name': {
        id: 'integrations.webmerge.name',
        defaultMessage: 'Webmerge',
    },
    'integrations.webmerge.title': {
        id: 'integrations.webmerge.title',
        defaultMessage: 'Push data from SweepBright to WebMerge with Zapier',
    },
    'integrations.webmerge.help': {
        id: 'integrations.webmerge.help',
        defaultMessage:
            'Our integration with Zapier helps you to easily push data you collect on SweepBright to WebMerge. You need a Zapier account. For more info, contact <a href="#">customer support</a>',
    },
    'integrations.google-docs.name': {
        id: 'integrations.google-docs.name',
        defaultMessage: 'Google Docs',
    },
    'integrations.google-docs.title': {
        id: 'integrations.google-docs.title',
        defaultMessage: 'Google',
    },
    'integrations.google-docs.help': {
        id: 'integrations.google-docs.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.slack.name': {
        id: 'integrations.slack.name',
        defaultMessage: 'Slack',
    },
    'integrations.slack.title': {
        id: 'integrations.slack.title',
        defaultMessage: 'Slack',
    },
    'integrations.slack.help': {
        id: 'integrations.slack.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.dotloop.name': {
        id: 'integrations.dotloop.name',
        defaultMessage: 'Dotloop',
    },
    'integrations.dotloop.title': {
        id: 'integrations.dotloop.title',
        defaultMessage: 'Push data from SweepBright to Dotloop',
    },
    'integrations.sweepbright.name': {
        id: 'integrations.sweepbright.name',
        defaultMessage: 'SweepBright',
    },
    'integrations.sweepbright.title': {
        id: 'integrations.sweepbright.title',
        defaultMessage: 'Generate documents using SweepBright’s built-in formats',
    },
    'integrations.mailchimp.name': {
        id: 'integrations.mailchimp.name',
        defaultMessage: 'MailChimp',
    },
    'integrations.mailchimp.title': {
        id: 'integrations.mailchimp.title',
        defaultMessage: 'MailChimp',
    },
    'integrations.mailchimp.help': {
        id: 'integrations.mailchimp.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.bombbomb.name': {
        id: 'integrations.bombbomb.name',
        defaultMessage: 'BombBomb',
    },
    'integrations.bombbomb.title': {
        id: 'integrations.bombbomb.title',
        defaultMessage: 'BombBomb',
    },
    'integrations.bombbomb.help': {
        id: 'integrations.bombbomb.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.activecampaign.name': {
        id: 'integrations.activecampaign.name',
        defaultMessage: 'ActiveCampaign',
    },
    'integrations.activecampaign.title': {
        id: 'integrations.activecampaign.title',
        defaultMessage: 'ActiveCampaign',
    },
    'integrations.activecampaign.help': {
        id: 'integrations.activecampaign.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.basecamp.name': {
        id: 'integrations.basecamp.name',
        defaultMessage: 'BaseCamp',
    },
    'integrations.basecamp.title': {
        id: 'integrations.basecamp.title',
        defaultMessage: 'BaseCamp',
    },
    'integrations.basecamp.help': {
        id: 'integrations.basecamp.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.trello.name': {
        id: 'integrations.trello.name',
        defaultMessage: 'Trello',
    },
    'integrations.trello.title': {
        id: 'integrations.trello.title',
        defaultMessage: 'Trello',
    },
    'integrations.trello.help': {
        id: 'integrations.trello.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.evernote.name': {
        id: 'integrations.evernote.name',
        defaultMessage: 'Evernote',
    },
    'integrations.evernote.title': {
        id: 'integrations.evernote.title',
        defaultMessage: 'Evernote',
    },
    'integrations.evernote.help': {
        id: 'integrations.evernote.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.quickbooks.name': {
        id: 'integrations.quickbooks.name',
        defaultMessage: 'Quickbooks',
    },
    'integrations.quickbooks.title': {
        id: 'integrations.quickbooks.title',
        defaultMessage: 'Quickbooks',
    },
    'integrations.quickbooks.help': {
        id: 'integrations.quickbooks.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.freshbooks.name': {
        id: 'integrations.freshbooks.name',
        defaultMessage: 'Freshbooks',
    },
    'integrations.freshbooks.title': {
        id: 'integrations.freshbooks.title',
        defaultMessage: 'Freshbooks',
    },
    'integrations.freshbooks.help': {
        id: 'integrations.freshbooks.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.salesforce.name': {
        id: 'integrations.salesforce.name',
        defaultMessage: 'Salesforce',
    },
    'integrations.salesforce.title': {
        id: 'integrations.salesforce.title',
        defaultMessage: 'Salesforce',
    },
    'integrations.salesforce.help': {
        id: 'integrations.salesforce.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.contactually.name': {
        id: 'integrations.contactually.name',
        defaultMessage: 'Contactually',
    },
    'integrations.contactually.title': {
        id: 'integrations.contactually.title',
        defaultMessage: 'Contactually',
    },
    'integrations.contactually.help': {
        id: 'integrations.contactually.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.hubspot.name': {
        id: 'integrations.hubspot.name',
        defaultMessage: 'Hubspot',
    },
    'integrations.hubspot.title': {
        id: 'integrations.hubspot.title',
        defaultMessage: 'Hubspot',
    },
    'integrations.hubspot.help': {
        id: 'integrations.hubspot.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.intercom-zapier.name': {
        id: 'integrations.intercom-zapier.name',
        defaultMessage: 'Intercom',
    },
    'integrations.intercom-zapier.title': {
        id: 'integrations.intercom-zapier.title',
        defaultMessage: 'Intercom',
    },
    'integrations.intercom-zapier.help': {
        id: 'integrations.intercom-zapier.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.livechat-zapier.name': {
        id: 'integrations.livechat-zapier.name',
        defaultMessage: 'Livechat',
    },
    'integrations.livechat-zapier.title': {
        id: 'integrations.livechat-zapier.title',
        defaultMessage: 'Livechat',
    },
    'integrations.livechat-zapier.help': {
        id: 'integrations.livechat-zapier.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.zillow-tech-connect.name': {
        id: 'integrations.zillow-tech-connect.name',
        defaultMessage: 'Zillow Tech Connect',
    },
    'integrations.zillow-tech-connect.title': {
        id: 'integrations.zillow-tech-connect.title',
        defaultMessage: 'Zillow Tech Connect',
    },
    'integrations.zillow-tech-connect.help': {
        id: 'integrations.zillow-tech-connect.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.facebook-lead-ads.name': {
        id: 'integrations.facebook-lead-ads.name',
        defaultMessage: 'Facebook Lead Ads',
    },
    'integrations.facebook-lead-ads.title': {
        id: 'integrations.facebook-lead-ads.title',
        defaultMessage: 'Facebook Lead Ads',
    },
    'integrations.facebook-lead-ads.help': {
        id: 'integrations.facebook-lead-ads.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.campaign-monitor.name': {
        id: 'integrations.campaign-monitor.name',
        defaultMessage: 'Campaign monitor',
    },
    'integrations.campaign-monitor.title': {
        id: 'integrations.campaign-monitor.title',
        defaultMessage: 'Campaign monitor',
    },
    'integrations.campaign-monitor.help': {
        id: 'integrations.campaign-monitor.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.wunderlist.name': {
        id: 'integrations.wunderlist.name',
        defaultMessage: 'Wunderlist',
    },
    'integrations.wunderlist.title': {
        id: 'integrations.wunderlist.title',
        defaultMessage: 'Wunderlist',
    },
    'integrations.wunderlist.help': {
        id: 'integrations.wunderlist.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.services.name': {
        id: 'integrations.services.name',
        defaultMessage: 'Services',
    },
    'integrations.services.title': {
        id: 'integrations.services.title',
        defaultMessage: 'Services',
    },
    'integrations.services.help': {
        id: 'integrations.services.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.popular.name': {
        id: 'integrations.popular.name',
        defaultMessage: 'Popular',
    },
    'integrations.popular.title': {
        id: 'integrations.popular.title',
        defaultMessage: 'Popular',
    },
    'integrations.popular.help': {
        id: 'integrations.popular.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.facebook-popular.name': {
        id: 'integrations.facebook-popular.name',
        defaultMessage: 'Facebook',
    },
    'integrations.facebook-popular.title': {
        id: 'integrations.facebook-popular.title',
        defaultMessage: 'Create posts with data from SweepBright',
    },
    'integrations.facebook-popular.help': {
        id: 'integrations.facebook-popular.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.linked-in.name': {
        id: 'integrations.linked-in.name',
        defaultMessage: 'LinkedIn',
    },
    'integrations.linked-in.title': {
        id: 'integrations.linked-in.title',
        defaultMessage: 'Create posts with data from SweepBright',
    },
    'integrations.linked-in.help': {
        id: 'integrations.linked-in.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.twitter.name': {
        id: 'integrations.twitter.name',
        defaultMessage: 'Twitter',
    },
    'integrations.twitter.title': {
        id: 'integrations.twitter.title',
        defaultMessage: 'Create tweets with data from SweepBright',
    },
    'integrations.twitter.help': {
        id: 'integrations.twitter.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.gmail.name': {
        id: 'integrations.gmail.name',
        defaultMessage: 'Gmail',
    },
    'integrations.gmail.title': {
        id: 'integrations.gmail.title',
        defaultMessage: 'Use Gmail with data from SweepBright',
    },
    'integrations.gmail.help': {
        id: 'integrations.gmail.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.google-calendar.name': {
        id: 'integrations.google-calendar.name',
        defaultMessage: 'Twitter',
    },
    'integrations.google-calendar.title': {
        id: 'integrations.google-calendar.title',
        defaultMessage: 'Use Google Calendar with data from SweepBright',
    },
    'integrations.google-calendar.help': {
        id: 'integrations.google-calendar.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.typeform.name': {
        id: 'integrations.typeform.name',
        defaultMessage: 'Typeform',
    },
    'integrations.typeform.title': {
        id: 'integrations.typeform.title',
        defaultMessage: 'Use Typeform with data from SweepBright',
    },
    'integrations.typeform.help': {
        id: 'integrations.typeform.help',
        defaultMessage: '&nbsp;',
    },
    'integrations.clicksend.name': {
        id: 'integrations.clicksend.name',
        defaultMessage: 'ClickSend',
    },
    'integrations.clicksend.title': {
        id: 'integrations.clicksend.title',
        defaultMessage: 'Use ClickSend with data from SweepBright',
    },
    'integrations.clicksend.help': {
        id: 'integrations.clicksend.help',
        defaultMessage: '&nbsp;',
    },
});

export const categories = fromJS([
    {
        id: 'documents',
        icon: 'document',
        integrations: [
            {
                id: 'sweepbright',
                image: require('@/../../resources/assets/img/integrations/sweepbright.png'),
            },
            {
                id: 'webmerge',
                image: require('@/../../resources/assets/img/integrations/webmerge.png'),
                via_zapier: true,
                templates: ['19024', '19025', '19029', '19031'],
            },
            {
                id: 'dotloop',
                image: require('@/../../resources/assets/img/integrations/dotloop.png'),
            },
            {
                id: 'google-docs',
                via_zapier: true,
                templates: [],
                image: require('@/../../resources/assets/img/integrations/google-docs.png'),
            },
        ],
    },
    {
        id: 'communication',
        icon: 'communication',
        integrations: [
            {
                id: 'intercom',
                image: require('@/../../resources/assets/img/integrations/intercom.png'),
            },
            {
                id: 'livechat',
                image: require('@/../../resources/assets/img/integrations/livechat.png'),
            },
            {
                id: 'slack',
                via_zapier: true,
                templates: ['18523', '17474', '17535'],
                image: require('@/../../resources/assets/img/integrations/slack.png'),
            },
            {
                id: 'clicksend',
                via_zapier: true,
                templates: ['21821', '21822'],
                image: require('@/../../resources/assets/img/integrations/clicksend.jpg'),
            },
        ],
    },
    {
        id: 'direct-marketing',
        icon: 'direct-marketing',
        integrations: [
            {
                id: 'mailchimp',
                via_zapier: true,
                templates: ['16668', '18521'],
                image: require('@/../../resources/assets/img/integrations/mailchimp.png'),
            },
            {
                id: 'bombbomb',
                via_zapier: true,
                templates: ['18741', '18742'],
                image: require('@/../../resources/assets/img/integrations/bombbomb.jpg'),
            },
            {
                id: 'activecampaign',
                via_zapier: true,
                templates: [],
                image: require('@/../../resources/assets/img/integrations/active_campaign.png'),
            },
            {
                id: 'campaign-monitor',
                via_zapier: true,
                templates: ['18739', '18740'],
                image: require('@/../../resources/assets/img/integrations/campaign-monitor.png'),
            },
        ],
    },
    {
        id: 'task-management',
        icon: 'task-management',
        integrations: [
            {
                id: 'basecamp',
                via_zapier: true,
                templates: ['18522', '17572', '18524', '19863'],
                image: require('@/../../resources/assets/img/integrations/basecamp.png'),
            },
            {
                id: 'trello',
                via_zapier: true,
                templates: [],
                image: require('@/../../resources/assets/img/integrations/trello.png'),
            },
            {
                id: 'evernote',
                via_zapier: true,
                templates: ['18838', '18839'],
                image: require('@/../../resources/assets/img/integrations/evernote.png'),
            },
            {
                id: 'wunderlist',
                via_zapier: true,
                templates: ['18840', '18841'],
                image: require('@/../../resources/assets/img/integrations/wunderlist.png'),
            },
        ],
    },
    {
        id: 'accounting',
        icon: 'accounting',
        integrations: [
            {
                id: 'quickbooks',
                via_zapier: true,
                templates: ['17610'],
                image: require('@/../../resources/assets/img/integrations/quickbooks.png'),
            },
            {
                id: 'freshbooks',
                via_zapier: true,
                templates: [],
                image: require('@/../../resources/assets/img/integrations/freshbooks.jpg'),
            },
        ],
    },
    {
        id: 'crm',
        icon: 'crm',
        integrations: [
            {
                id: 'salesforce',
                via_zapier: true,
                templates: ['18842', '18843', '18844'],
                image: require('@/../../resources/assets/img/integrations/salesforce.png'),
            },
            {
                id: 'contactually',
                via_zapier: true,
                templates: ['18525', '18526', '18527', '18527'],
                image: require('@/../../resources/assets/img/integrations/contactually.png'),
            },
            {
                id: 'hubspot',
                via_zapier: true,
                templates: ['17571', '17554', '18737', '18738'],
                image: require('@/../../resources/assets/img/integrations/hubspot.png'),
            },
        ],
    },
    {
        id: 'lead-capture',
        icon: 'lead-capture',
        integrations: [
            {
                id: 'intercom-zapier',
                via_zapier: true,
                templates: ['20280'],
                image: require('@/../../resources/assets/img/integrations/intercom.png'),
            },
            {
                id: 'livechat-zapier',
                via_zapier: true,
                templates: [],
                image: require('@/../../resources/assets/img/integrations/livechat.png'),
            },
            {
                id: 'zillow-tech-connect',
                via_zapier: true,
                templates: ['20282'],
                image: require('@/../../resources/assets/img/integrations/zillow.jpg'),
            },
            {
                id: 'facebook-lead-ads',
                via_zapier: true,
                templates: ['20281'],
                image: require('@/../../resources/assets/img/integrations/facebook.png'),
            },
            {
                id: 'typeform',
                via_zapier: true,
                templates: ['20283'],
                image: require('@/../../resources/assets/img/integrations/typeform.png'),
            },
        ],
    },
    {
        id: 'services',
        icon: 'services',
        integrations: [],
    },
    {
        id: 'popular',
        icon: 'popular',
        integrations: [
            {
                id: 'facebook-popular',
                via_zapier: true,
                templates: ['18959'],
                image: require('@/../../resources/assets/img/integrations/facebook.png'),
            },
            {
                id: 'linked-in',
                via_zapier: true,
                templates: ['18988'],
                image: require('@/../../resources/assets/img/integrations/linked-in.png'),
            },
            {
                id: 'twitter',
                via_zapier: true,
                templates: ['18987'],
                image: require('@/../../resources/assets/img/integrations/twitter.png'),
            },
            {
                id: 'gmail',
                via_zapier: true,
                templates: ['19860', '19861', '19862'],
                image: require('@/../../resources/assets/img/integrations/gmail.png'),
            },
            {
                id: 'google-calendar',
                via_zapier: true,
                templates: ['19865'],
                image: require('@/../../resources/assets/img/integrations/google-calendar.png'),
            },
        ],
    },
]);

export const integrations = categories.reduce((items, category) => {
    return items.concat(category.get('integrations'));
}, new List());

export function findCategory(category) {
    return categories.find(item => item.get('id') === category);
}

export function findIntegration(integration) {
    return integrations.find(item => item.get('id') === integration);
}
