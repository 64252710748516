import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';

type OriginalInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const InputLight: FC<OriginalInputProps & {
    label?: ReactNode;
    error?: string | false;
    icon?: ReactNode;
}> = ({ label, error, icon, className, ...inputProps }) => {
    const hasError = !!error;
    const hasIcon = !!icon;
    const classErrorText = 'text-error';
    const classErrorBorder = 'border-error';

    const labelClass = clsx(hasError && classErrorText);
    const inputClass = clsx(
        className,
        'border rounded-sm focus:outline-none p-3 w-full my-1',
        hasError ? `${classErrorBorder} focus:shadow` : 'border-gray-400 focus:shadow-outline',
        hasIcon && 'pl-12',
    );
    const iconClass = clsx('absolute top-0 py-4 pl-3', hasError && classErrorText);

    return (
        <label>
            <div className={labelClass}>{label}</div>
            <div className="relative">
                <input className={inputClass} {...inputProps} />
                {hasIcon && <div className={iconClass}>{icon}</div>}
            </div>
            {hasError && <div className={`text-sm ${classErrorText}`}>{error}</div>}
        </label>
    );
};

export default InputLight;
