import React, { useEffect, useMemo, useState } from 'react';
import { Col, ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import Immutable, { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useToasts } from '@sweepbright/notifications';
import { FormattedMessage } from 'react-intl-sweepbright';
import NegotiatorSelectorField from '@/app.components/forms/elements/NegotiatorSelectorField';
import { updateOffice } from '@/app.redux/actions';
import { getStatus } from '@/app.redux/selectors';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import DurationSelector from '@/app.components/selectors/DurationSelector';
import TeamWrapper from '@/new.domains/teams/TeamWrapper';
import useOffice from '@/app.hooks/useOffice';
import Input from '../../../app.components/forms/Input/Input';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import FormPane from '../../../app.components/forms/FormPane';
import currencies from '../../../app.data/Currencies';

const currencyOptions = Object.values(currencies as Record<string, { code: string; name: string }>)
    .map(currency => ({
        value: currency.code,
        label: currency.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

type Props = {
    params: { office: string };
    status: Map<string, any>;
    updateOffice: Function;
};

const EditOfficeSettingsPage = (props: Props) => {
    const {
        params: { office: officeId },
        status,
        updateOffice,
    } = props;

    const { office, refetch } = useOffice(officeId, true);

    const [lastOfficeId, setLastOfficeId] = useState('');

    const [formFields, setFormFields] = useState<any>({});

    const [loading, setLoading] = useState(false);

    const { addSuccess, addError } = useToasts();

    let errors = status?.get('errors') ?? Immutable.List();

    if (!(errors instanceof Immutable.List)) {
        errors = Immutable.List([errors]);
    }

    useEffect(() => {
        const isError = !!errors.count();
        const isSaving = status?.get('is_saving');

        if (loading && !isSaving) {
            if (isError) {
                addError({
                    message: (
                        <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />
                    ),
                });
            }

            if (!isError) {
                addSuccess({
                    message: <FormattedMessage id="forms.office.saved" defaultMessage="Team saved successfully" />,
                });
            }

            refetch();

            setLoading(false);
        }

        // eslint-disable-next-line
    }, [errors, status, loading]);

    const officePlain = useMemo(() => {
        return office.toJS();
    }, [office]);

    useEffect(() => {
        if (officeId !== lastOfficeId) {
            if (officePlain?.id) {
                setLastOfficeId(officeId);

                setFormFields({
                    ...officePlain,
                    lead_negotiator: officePlain?.head_negotiator?.data?.id,
                    settings: {
                        ...officePlain.settings,
                        data: {
                            ...officePlain?.settings?.data,
                            use_bright_matching: officePlain?.settings?.data?.use_bright_matching || false,
                            default_assignee: officePlain?.settings?.data?.default_assignee?.id,
                        },
                    },
                });
            }
        }
    }, [officeId, lastOfficeId, officePlain]);

    return (
        <TeamWrapper {...props}>
            <FormPane
                {...({} as any)}
                title={<FormattedMessage id="forms.office.title" defaultMessage="Office" />}
                onSubmit={() => {
                    updateOffice(officeId, {
                        name: formFields.name,
                        settings: formFields?.settings?.data,
                        lead_negotiator: formFields.lead_negotiator,
                    });

                    setLoading(true);
                }}
                status={props.status}
                cols={{ md: 15, lg: 9 }}
            >
                <FormPanel>
                    <div className="form-horizontal">
                        <FormGroup>
                            <Col sm={4}>
                                <ControlLabel>
                                    <FormattedMessage id="forms.labels.office_name" defaultMessage="Office Name" />
                                </ControlLabel>
                            </Col>
                            <Col sm={11}>
                                <Input
                                    type="text"
                                    name="name"
                                    renderWrapper={null}
                                    value={formFields.name}
                                    onChange={e => {
                                        setFormFields({ ...formFields, name: e.target.value });
                                    }}
                                />
                            </Col>
                        </FormGroup>
                    </div>
                </FormPanel>
                <FormPanel
                    title={
                        <FormattedMessage id="forms.office.measurement.title" defaultMessage="System of Measurement" />
                    }
                >
                    <Input
                        type="radio"
                        value="metric"
                        checked={formFields?.settings?.data?.measurement_system === 'metric'}
                        label={<FormattedMessage id="forms.labels.metric" defaultMessage="Metric" />}
                        onChange={e => {
                            setFormFields({
                                ...formFields,
                                settings: {
                                    ...formFields?.settings,
                                    data: { ...formFields?.settings?.data, measurement_system: e.target.value },
                                },
                            });
                        }}
                    />
                    <Input
                        type="radio"
                        value="imperial"
                        checked={formFields?.settings?.data?.measurement_system === 'imperial'}
                        onChange={e => {
                            setFormFields({
                                ...formFields,
                                settings: {
                                    ...formFields?.settings,
                                    data: { ...formFields?.settings?.data, measurement_system: e.target.value },
                                },
                            });
                        }}
                        label={<FormattedMessage id="forms.labels.imperial" defaultMessage="Imperial" />}
                    />
                </FormPanel>
                <FormPanel title={<FormattedMessage id="forms.office.numeral.title" defaultMessage="Numeral system" />}>
                    <Input
                        type="radio"
                        value="decimal_comma"
                        label={
                            <FormattedMessage
                                id="forms.labels.decimal_comma"
                                defaultMessage="Decimal Comma (1.000,00)"
                            />
                        }
                        checked={formFields?.settings?.data?.numeral_system === 'decimal_comma'}
                        onChange={e => {
                            setFormFields({
                                ...formFields,
                                settings: {
                                    ...formFields?.settings,
                                    data: { ...formFields?.settings?.data, numeral_system: e.target.value },
                                },
                            });
                        }}
                    />
                    <Input
                        type="radio"
                        value="decimal_point"
                        label={
                            <FormattedMessage
                                id="forms.labels.decimal_point"
                                defaultMessage="Decimal Point (1,000.00)"
                            />
                        }
                        checked={formFields?.settings?.data?.numeral_system === 'decimal_point'}
                        onChange={e => {
                            setFormFields({
                                ...formFields,
                                settings: {
                                    ...formFields?.settings,
                                    data: { ...formFields?.settings?.data, numeral_system: e.target.value },
                                },
                            });
                        }}
                    />
                </FormPanel>
                <FormPanel title={<FormattedMessage id="forms.office.currency.title" defaultMessage="Currency" />}>
                    <Input
                        value={formFields?.settings?.data?.currency}
                        onChange={value => {
                            setFormFields({
                                ...formFields,
                                settings: {
                                    ...formFields?.settings,
                                    data: { ...formFields?.settings?.data, currency: value },
                                },
                            });
                        }}
                    >
                        <Select
                            simpleValue
                            value={formFields?.settings?.data?.currency}
                            onChange={value => {
                                setFormFields({
                                    ...formFields,
                                    settings: {
                                        ...formFields?.settings,
                                        data: { ...formFields?.settings?.data, currency: value },
                                    },
                                });
                            }}
                            options={currencyOptions}
                        />
                    </Input>
                </FormPanel>
                <FormPanel
                    title={<FormattedMessage id="forms.office.defaults.title" defaultMessage="Defaults" />}
                    horizontal
                >
                    <FormGroup>
                        <Col sm={6}>
                            <ControlLabel>
                                <FormattedMessage
                                    id="forms.office.defaults.notifications"
                                    defaultMessage="Send Notifications To"
                                />
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <NegotiatorSelectorField
                                officeId={officeId}
                                field={{
                                    value: formFields?.lead_negotiator,
                                    onChange: value => {
                                        setFormFields({
                                            ...formFields,
                                            lead_negotiator: value,
                                        });
                                    },
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col className="col-sm-9 col-sm-push-6">
                            <HelpBlock>
                                <FormattedMessage
                                    id="forms.office.defaults.notifications.help"
                                    defaultMessage="This person will receive a notification when a new lead is assigned to this team."
                                />
                            </HelpBlock>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col sm={6}>
                            <ControlLabel htmlFor="visit_duration">
                                <FormattedMessage
                                    id="forms.office.defaults.visit_duration"
                                    defaultMessage="Default Visit Duration"
                                />
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <DurationSelector
                                id="visit_duration"
                                value={formFields?.settings?.data?.visit_duration}
                                onChange={value => {
                                    setFormFields({
                                        ...formFields,
                                        settings: {
                                            ...formFields?.settings,
                                            data: { ...formFields?.settings?.data, visit_duration: value },
                                        },
                                    });
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col sm={6}>
                            <ControlLabel htmlFor="default_assignee">
                                <FormattedMessage
                                    id="forms.office.assignee.label"
                                    defaultMessage="Default properties assignee"
                                />
                            </ControlLabel>
                        </Col>
                        <Col sm={9}>
                            <NegotiatorSelectorField
                                officeId={officeId}
                                field={{
                                    value: formFields?.settings?.data?.default_assignee,
                                    onChange: value => {
                                        setFormFields({
                                            ...formFields,
                                            settings: {
                                                ...formFields?.settings,
                                                data: { ...formFields?.settings?.data, default_assignee: value },
                                            },
                                        });
                                    },
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col className="col-sm-9 col-sm-push-6">
                            <HelpBlock>
                                <FormattedMessage
                                    id="forms.office.assignee.help"
                                    defaultMessage="This person will receive a notification when a new lead is assigned to this team."
                                />
                            </HelpBlock>
                        </Col>
                    </FormGroup>
                </FormPanel>
            </FormPane>
        </TeamWrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    status: getStatus,
});

export default compose(
    connect(mapStateToProps, {
        updateOffice,
    }),
)(
    // @ts-ignore
    EditOfficeSettingsPage,
);
