import React from 'react';
import Link from '@/app.components/elements/Link';
import { useTheme } from '@/app.utils/theming/ThemeContext';

const Logo: React.FunctionComponent<{ onClick: (evt: React.MouseEvent<HTMLAnchorElement>) => void }> = ({
    onClick,
}) => {
    const theme = useTheme();

    return (
        <div className="c-logo">
            <Link to="/" onClick={onClick}>
                <img src={theme.logo} alt="logo" />
            </Link>
        </div>
    );
};

export default Logo;
