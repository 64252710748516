import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import { Estate } from '@/graphql/generated/types';
import useTableColumns from '@/app.hooks/useTableColumns';
import { renderOfferColumn } from '@/app.shared/offers/utils';
import useOffice from '@/app.hooks/useOffice';
import useUser from '@/app.hooks/useUser';
import { isTeamMember } from '@/app.data';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import {
    renderCity,
    renderDetails,
    renderLastActivity,
    renderMenu,
    renderPostalCode,
    renderPrice,
    renderPublishCount,
    renderPublishErrors,
    renderStatus,
    renderStreet,
    renderSubtype,
    renderType,
} from '../utils';
import { PropertySearchType } from '../types';

type Props = {
    selected?: string[];
    setSelected?: Function;
    searchSettings: PropertySearchType | undefined;
};

export const usePropertiesColumns = (props: Props) => {
    const { selected, setSelected, searchSettings } = props;

    const intl = useIntl();
    const user = useUser();
    const office = useOffice();
    const currency = office.getIn(['settings', 'data', 'currency'], 'EUR');

    const selectEnabled = !isTeamMember(user);

    const showPropertySharedFilter = useFeatureFlag('property.shared.enabled');

    const columnsById = useMemo(() => {
        return {
            price: {
                key: 'price',
                title:
                    searchSettings?.negotiation === 'sale' ? (
                        <FormattedMessage id="properties_table.headers.sale_price" defaultMessage="Asking Price" />
                    ) : (
                        <FormattedMessage id="properties_table.headers.rent_price" defaultMessage="Requested Rent" />
                    ),
                dataIndex: 'id',
                render: (id, property: Estate) =>
                    renderPrice({
                        id,
                        property,
                        office,
                        selectEnabled: selectEnabled,
                        selected,
                        setSelected,
                        showPropertySharedFilter,
                    }),
                visible: true,
            },
            highest_offer_bid: {
                key: 'highest_offer_bid',
                title: (
                    <FormattedMessage id="properties_table.headers.highest_offer_bid" defaultMessage="Highest Offer" />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderOfferColumn('highest_offer_bid', property),
                visible: true,
                className: 'whitespace-no-wrap',
            },
            number_of_pending_offers: {
                key: 'number_of_pending_offers',
                title: (
                    <FormattedMessage id="properties_table.headers.number_of_pending_offers" defaultMessage="Offers" />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderOfferColumn('number_of_pending_offers', property),
                visible: true,
                className: 'whitespace-no-wrap',
            },
            closest_offer_expirations: {
                key: 'closest_offer_expirations',
                title: (
                    <FormattedMessage
                        id="properties_table.headers.closest_offer_expirations"
                        defaultMessage="Next Offer Expires"
                    />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderOfferColumn('closest_offer_expirations', property),
                visible: true,
                className: 'whitespace-no-wrap',
            },
            successful_publications_count: {
                key: 'successful_publications_count',
                title: (
                    <FormattedMessage
                        id="properties_table.headers.successful_publications_count"
                        defaultMessage="Published"
                    />
                ),
                dataIndex: 'id',
                render: renderPublishCount,
                visible: true,
                className: 'whitespace-no-wrap',
            },
            error_publications_count: {
                key: 'error_publications_count',
                title: (
                    <FormattedMessage
                        id="properties_table.headers.error_publications_count"
                        defaultMessage="Publish Errors"
                    />
                ),
                dataIndex: 'id',
                render: renderPublishErrors,
                visible: true,
                className: 'whitespace-no-wrap',
            },
            type: {
                key: 'type',
                title: <FormattedMessage id="properties_table.headers.type" defaultMessage="Type" />,
                dataIndex: 'id',
                render: renderType,
                visible: true,
            },
            subtype: {
                key: 'subtype',
                title: <FormattedMessage id="properties_table.headers.subtype" defaultMessage="Subtype" />,
                dataIndex: 'id',
                render: renderSubtype,
                visible: true,
            },
            street: {
                key: 'street',
                title: <FormattedMessage id="properties_table.headers.street" defaultMessage="Street" />,
                dataIndex: 'id',
                render: renderStreet,
            },
            city: {
                key: 'city',
                title: <FormattedMessage id="properties_table.headers.city" defaultMessage="City" />,
                dataIndex: 'id',
                render: renderCity,
            },
            postal_code: {
                key: 'postal_code',
                title: <FormattedMessage id="properties_table.headers.postal_code" defaultMessage="Postcode / Zip" />,
                dataIndex: 'id',
                render: renderPostalCode,
                className: 'whitespace-no-wrap',
            },
            status: {
                key: 'status',
                title: <FormattedMessage id="properties_table.headers.status" defaultMessage="Status" />,
                dataIndex: 'id',
                render: renderStatus,
            },
            activity: {
                key: 'activity',
                title: <FormattedMessage id="properties_table.headers.last_edit" defaultMessage="Last Edit" />,
                dataIndex: 'id',
                render: renderLastActivity,
            },
            mandate_number: {
                key: 'mandate_number',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="properties_table.headers.mandate_number" defaultMessage="Mandate Nr." />,
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('mandate_number', property, currency),
            },
            mandate_start_date: {
                key: 'mandate_start_date',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage
                        id="properties_table.headers.mandate_start_date"
                        defaultMessage="Mandate Start Date"
                    />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('mandate_start_date', property, currency),
            },
            mandate_end_date: {
                key: 'mandate_end_date',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage
                        id="properties_table.headers.mandate_end_date"
                        defaultMessage="Mandate End Date"
                    />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('mandate_end_date', property, currency),
            },
            mandate_exclusive: {
                key: 'mandate_exclusive',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage id="properties_table.headers.mandate_exclusive" defaultMessage="Mandate Type" />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('mandate_exclusive', property, currency),
            },
            buyer_fixed_fee: {
                key: 'buyer_fixed_fee',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage id="properties_table.headers.buyer_fixed_fee" defaultMessage="Buyer Fixed Fee" />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('buyer_fixed_fee', property, currency),
            },
            buyer_percentage: {
                key: 'buyer_percentage',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage
                        id="properties_table.headers.buyer_percentage"
                        defaultMessage="Buyer Percentage Fee"
                    />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('buyer_percentage', property, currency),
            },
            seller_fixed_fee: {
                key: 'seller_fixed_fee',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage id="properties_table.headers.seller_fixed_fee" defaultMessage="Owner Fixed Fee" />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('seller_fixed_fee', property, currency),
            },
            seller_percentage: {
                key: 'seller_percentage',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage
                        id="properties_table.headers.seller_percentage"
                        defaultMessage="Owner Percentage Fee"
                    />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('seller_percentage', property, currency),
            },
            epc_value: {
                key: 'epc_value',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="properties_table.headers.epc_value" defaultMessage="Energy Score" />,
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('epc_value', property, currency),
            },
            co2_emissions: {
                key: 'co2_emissions',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="properties_table.headers.co2_emissions" defaultMessage="CO² Emissions" />,
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('co2_emissions', property, currency),
            },
            dpe: {
                key: 'dpe',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="properties_table.headers.dpe" defaultMessage="DPE" />,
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('dpe', property, currency, intl),
            },
            greenhouse_emissions: {
                key: 'greenhouse_emissions',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage
                        id="properties_table.headers.greenhouse_emissions"
                        defaultMessage="Greenhouse Gas Emissions"
                    />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('greenhouse_emissions', property, currency, intl),
            },
            created_at: {
                key: 'created_at',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="properties_table.headers.created_at" defaultMessage="Created Time" />,
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('created_at', property, currency),
            },
            negotiator: {
                key: 'negotiator',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="properties_table.headers.negotiator" defaultMessage="Negotiator" />,
                dataIndex: 'id',
                render: (_id, property: Estate) =>
                    renderDetails(
                        'negotiator',
                        property,
                        currency,
                        intl,
                        // @ts-ignore
                        user,
                    ),
            },
            loi_carrez_area: {
                key: 'loi_carrez_area',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage
                        id="properties_table.headers.loi_carrez_area"
                        defaultMessage="Liveable (Carrez act)"
                    />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('loi_carrez_area', property, currency),
            },
            liveable_area: {
                key: 'liveable_area',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="properties_table.headers.liveable_area" defaultMessage="Liveable Area" />,
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('liveable_area', property, currency),
            },
            gross_area: {
                key: 'gross_area',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="properties_table.headers.gross_area" defaultMessage="Gross Area" />,
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('gross_area', property, currency),
            },
            plot_area: {
                key: 'plot_area',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="properties_table.headers.plot_area" defaultMessage="Plot Area" />,
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('plot_area', property, currency),
            },
            net_area: {
                key: 'net_area',
                className: 'whitespace-no-wrap',
                title: <FormattedMessage id="properties_table.headers.net_area" defaultMessage="Net Area" />,
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('net_area', property, currency),
            },
            first_publication_date: {
                key: 'first_publication_date',
                className: 'whitespace-no-wrap',
                title: (
                    <FormattedMessage
                        id="properties_table.headers.first_publication_date"
                        defaultMessage="First Published"
                    />
                ),
                dataIndex: 'id',
                render: (_id, property: Estate) => renderDetails('first_publication_date', property, currency),
            },
        };
    }, [intl, user, currency, office, searchSettings, selected, setSelected, selectEnabled, showPropertySharedFilter]);

    const initRecord: [string, boolean][] = React.useMemo(
        () => [
            ['highest_offer_bid', true],
            ['number_of_pending_offers', true],
            ['closest_offer_expirations', true],
            ['successful_publications_count', true],
            ['error_publications_count', true],
            ['type', true],
            ['subtype', true],
            ['street', true],
            ['city', true],
            ['postal_code', true],
            ['status', true],
            ['activity', true],
            ['mandate_number', false],
            ['mandate_start_date', false],
            ['mandate_end_date', false],
            ['mandate_exclusive', false],
            ['buyer_fixed_fee', false],
            ['buyer_percentage', false],
            ['seller_fixed_fee', false],
            ['seller_percentage', false],
            ['epc_value', false],
            ['co2_emissions', false],
            ['dpe', false],
            ['greenhouse_emissions', false],
            ['created_at', false],
            ['negotiator', false],
            ['loi_carrez_area', false],
            ['liveable_area', false],
            ['gross_area', false],
            ['plot_area', false],
            ['net_area', false],
            ['first_publication_date', false],
        ],
        [],
    );

    const { columns } = useTableColumns({
        memoryKey: 'properties.columns',
        initRecord,
        columnsExtended: columnsById,
        renderMenu: renderMenu,
        firstColumnId: 'price',
        columnsKeyPrefix: 'properties_table.headers',
    });

    return columns;
};
