export const LAND_CONDITIONS = Symbol('LAND_CONDITIONS');
export const PROPERTY_CONDITIONS = Symbol('PROPERTY_CONDITIONS');
export const USER_CONDITIONS = Symbol('USER_CONDITIONS');

export enum PropertyCondition {
    POOR = 'poor',
    FAIR = 'fair',
    GOOD = 'good',
    MINT = 'mint',
    NEW = 'new',
}

export enum VendorFeedback {
    POOR = 'poor',
    FAIR = 'fair',
    GOOD = 'good',
    MINT = 'mint',
    NEW = 'new',
}

export const LAND_CONDITIONS_OPTIONS = ['poor', 'fair', 'good'] as const;
export const PROPERTY_CONDITION_OPTIONS = [...LAND_CONDITIONS_OPTIONS, 'mint', 'new'];

export const PROPERTY_CONDITION_COLOR = {
    [PropertyCondition.POOR]: '#ee4b41',
    [PropertyCondition.FAIR]: '#ed2d98',
    [PropertyCondition.GOOD]: '#25a3f5',
    [PropertyCondition.MINT]: '#3cc2dd',
    [PropertyCondition.NEW]: '#53e1c4',
};

export const VENDOR_FEEDBACK_COLOR = {
    [VendorFeedback.POOR]: '#87878d',
    [VendorFeedback.FAIR]: '#3cc2dd',
    [VendorFeedback.GOOD]: '#53e1c4',
    [VendorFeedback.MINT]: '#f1b818',
    [VendorFeedback.NEW]: '#fe8b38',
};

export type InteractionFeedbackType =
    | 'lead_expressed_no_interest'
    | 'lead_requested_info'
    | 'lead_requested_visit'
    | 'lead_made_bid'
    | 'lead_made_deal';

export const USER_CONDITION_OPTIONS: { [T in InteractionFeedbackType]: string } = {
    lead_expressed_no_interest: 'No interest',
    lead_requested_info: 'Info',
    lead_requested_visit: 'Visit',
    lead_made_bid: 'Bid',
    lead_made_deal: 'Deal',
};

export default {
    [LAND_CONDITIONS]: LAND_CONDITIONS_OPTIONS,
    [PROPERTY_CONDITIONS]: PROPERTY_CONDITION_OPTIONS,
    [USER_CONDITIONS]: USER_CONDITION_OPTIONS,
};
