import compose from 'recompose/compose';
import { reduxForm as form, propTypes } from 'redux-form';
import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import { validateWithRules } from '@/app.utils/services/Validator';
import useUser from '@/app.hooks/useUser';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import NumberedStep from '@/app.components/modals/Channels/elements/NumberedStep';
import Email from '../../../app.components/elements/Email/PortalEmail';
import Input from '../../forms/Input/Input';
import PortalConfigModalBody from './PortalConfigModalBody';
import { nameValidationRule } from './utils';

const ImmowebImpl = ({ fields, handleSubmit, editing, ...props }) => {
    const user = useUser();

    return (
        <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
            <div className="space-y-4">
                <NumberedStep
                    step={1}
                    title={<FormattedMessage id="channels.activation_step.title" defaultMessage="Find your details" />}
                >
                    <p className="text-muted m-0 leading-none">
                        <Email
                            linkText={<FormattedMessage id="channels.send_email" defaultMessage="Send this e-mail" />}
                            portal={props.portal}
                            to={['agentschappen@immoweb.be']}
                            subject={
                                <FormattedMessage
                                    id="emails.immoweb.credentials.subject"
                                    defaultMessage="Please send me my Immoweb login credentials for SweepBright"
                                />
                            }
                            body={
                                <FormattedMessage
                                    id="emails.immoweb.credentials.body"
                                    defaultMessage="I have an Immoweb account and like to request my credentials to link to SweepBright."
                                />
                            }
                            className="align-baseline"
                            user={user}
                        />
                        <FormattedMessage
                            id="channels.send_email.details_request"
                            defaultMessage=" to request your details from {portal}."
                            values={{ portal: 'Immoweb' }}
                        />
                    </p>
                </NumberedStep>
                <NumberedStep
                    step={2}
                    title={<FormattedMessage id="channels.details_step.title" defaultMessage="Fill in your details" />}
                    last
                >
                    <Input
                        type="text"
                        label={<FormattedMessage id="forms.labels.ftp_username" defaultMessage="FTP Username" />}
                        placeholder={
                            <FormattedMessage id="forms.placeholders.immoweb.username" defaultMessage="username" />
                        }
                        {...fields.accessToken}
                        disabled={editing}
                    />
                    <Input
                        type="password"
                        label={<FormattedMessage id="forms.labels.ftp_password" defaultMessage="FTP Password" />}
                        {...fields.accessTokenSecret}
                        autoComplete="new-password"
                        disabled={editing}
                    />
                    {props.showNameField && (
                        <Input
                            {...fields.name}
                            label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                            help={
                                <FormattedMessage
                                    id="channels.name.description"
                                    defaultMessage="This is the name you will see in the Publish section of SweepBright."
                                />
                            }
                        />
                    )}
                </NumberedStep>
            </div>
        </PortalConfigModalBody>
    );
};

ImmowebImpl.propTypes = {
    ...propTypes,
    editing: PropTypes.bool,
};

const Immoweb = compose(
    form(
        {
            form: 'channels/portal',
            formKey: 'immoweb',
            fields: ['accessToken', 'accessTokenSecret', 'name'],
            validate: validateWithRules(
                {
                    accessToken: {
                        type: 'string',
                        required: true,
                        allowEmpty: false,
                        messages: {
                            required: 'general.validation.field_required',
                            allowEmpty: 'general.validation.field_required',
                        },
                    },
                    accessTokenSecret: {
                        type: 'string',
                        required: true,
                        allowEmpty: false,
                        messages: {
                            required: 'general.validation.field_required',
                            allowEmpty: 'general.validation.field_required',
                        },
                    },
                    name: nameValidationRule,
                },
                { humanizeErrorMessage: false },
            ),
        },
        createStructuredSelector({
            // if the multiple accounts feature is on,
            // we show the name field, otherwise, it is invisible to the user
            // even though the name is still set
            showNameField: getFeature('portals.multipleAccounts.enabled'),
        }),
    ),
)(ImmowebImpl);

Immoweb.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
            accessToken
            accessTokenSecret
        }
    `,
};

export default Immoweb;
