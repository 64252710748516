import React from 'react';

//check if the value is on the close range [start,end]
function isInRange(value: number | '', start?: number, end?: number): boolean {
    if (typeof value === 'string') {
        return true;
    }

    const minValue = start || value - 1;
    const maxValue = end || value + 1;

    return value > minValue && value < maxValue;
}

// Year input component with the right validations
// it allows to:
// 1) increase/decrease the current value using the arrow keys,
// 2) also validates that only integer values are typed in the field
// 3) accepts max/min props to limit the date to that range
// 4) and does not allow no numeric input

const YearInput = ({
    min = 0,
    max,
    inputTag = 'input',
    onChange,
    value,
    ...props
}: {
    inputTag?: React.ElementType;
    max?: number;
    min?: number;
    onFocus: () => void;
    onChange: (value: string) => void;
    value: string;
}) => {
    const Input = inputTag;
    const sanitizedValue = value || '';
    const handleKeyDown = evt => {
        const currentValue = parseInt(value, 10);
        const setToCurrentYear = () => {
            onChange(new Date().getFullYear().toString());
        };

        if (evt.key === 'ArrowDown') {
            if (currentValue) {
                const minValue = min || currentValue - 1;
                onChange(Math.max(currentValue - 1, minValue).toString());
            } else {
                setToCurrentYear();
            }
        } else if (evt.key === 'ArrowUp') {
            if (currentValue) {
                const maxValue = max || currentValue + 1;
                onChange(Math.min(currentValue + 1, maxValue).toString());
            } else {
                setToCurrentYear();
            }
        }
    };

    return (
        <Input
            value={sanitizedValue}
            type="text"
            min={min}
            max={max}
            onKeyDown={handleKeyDown}
            onChange={evt => {
                const stringValue = evt.target.value.substr(0, 4);
                if (stringValue) {
                    const parsedValue = parseInt(stringValue, 10) || '';
                    if (isInRange(parsedValue, min, max)) {
                        onChange(parsedValue.toString());
                    }
                } else {
                    onChange('');
                }
            }}
            {...props}
        />
    );
};

export default YearInput;
