import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import { replace } from 'react-router-redux';
import { getIsLoggingIn } from '@/app.redux/selectors';
import Loading from '@/app.components/loading/Loading';
import { PROFILE } from '../routes';
import { getUser } from '../../app.redux/selectors/UsersSelectors';
import { isCompanyAdmin, isTeamAdmin } from '../../app.data';

const isAuthenticatedAsTeamAdmin = connectedReduxRedirect({
    authenticatingSelector: getIsLoggingIn,
    authenticatedSelector: store => {
        const user = getUser(store);

        return user && (isCompanyAdmin(user) || isTeamAdmin(user));
    },
    redirectPath: PROFILE,
    AuthenticatingComponent: Loading,
    redirectAction: replace,
    wrapperDisplayName: 'TeamAdmin',
});

export default isAuthenticatedAsTeamAdmin;
