// @ts-nocheck
import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import Radio from '@/app.components/forms/Radio/Radio';
import { FormattedMessage } from 'react-intl-sweepbright';

export default function RadioListInput({ name, label, value, onChange, options }) {
    return (
        <FormGroup>
            <ControlLabel>{label}</ControlLabel>
            <div>
                <Radio.Group name={name} onChange={onChange} value={value}>
                    {options.map(option => (
                        <Radio key={option.value} value={option.value} disabled={option?.disabled}>
                            <FormattedMessage id={option.label} defaultMessage={option.label} />
                        </Radio>
                    ))}
                </Radio.Group>
            </div>
        </FormGroup>
    );
}
