import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-sweepbright';
import { PropertyFieldVisibilityContext, PropertyInternalType, PropertyType } from '../FieldVisibilityContext';

const { Unit, Standalone } = PropertyInternalType;
const { Apartment, Parking, Commercial, Office } = PropertyType;

const fieldLabels = defineMessages({
    street: {
        id: 'common.forms.labels.location.street',
        defaultMessage: 'Street',
    },
    street_2: {
        id: 'common.forms.labels.location.street_2',
        defaultMessage: 'Street 2',
    },
    number: {
        id: 'common.forms.labels.location.number',
        defaultMessage: 'Number',
    },
    addition: {
        id: 'common.forms.labels.location.addition',
        defaultMessage: 'Addition',
    },
    box: {
        id: 'common.forms.labels.location.box',
        defaultMessage: 'Box',
    },
    floor: {
        id: 'common.forms.labels.location.floor',
        defaultMessage: 'Floor',
    },
    postal_code: {
        id: 'common.forms.labels.location.postal_code',
        defaultMessage: 'Postcode / Zip',
    },
    city: {
        id: 'common.forms.labels.location.city',
        defaultMessage: 'City',
    },
    borough_or_district: {
        id: 'common.forms.labels.location.borough_or_district',
        defaultMessage: 'Borough / District',
    },
    province_or_state: {
        id: 'common.forms.labels.location.province_or_state',
        defaultMessage: 'State',
    },
    country: {
        id: 'common.forms.labels.location.country',
        defaultMessage: 'Country',
    },
});

const allCountries = () => true;

const visibilityRulesByCountry: { [fieldName: string]: (country: string | undefined) => boolean } = {
    street: allCountries,
    street_2: allCountries,
    number: allCountries,
    addition: allCountries,
    box: allCountries,
    floor: allCountries,
    postal_code: allCountries,
    city: allCountries,
    country: allCountries,

    borough_or_district: country => !country || country !== 'BE',
    province_or_state: country => !country || country !== 'BE',
};

const visibilityRulesWithoutCountry: { [fieldName: string]: (ctx: PropertyFieldVisibilityContext) => boolean } = {
    street: () => true,
    street_2: () => true,
    number: () => true,
    addition: () => true,
    box: () => true,
    floor: ({ internalType, type }) =>
        [Unit, Standalone].includes(internalType) && [Apartment, Parking, Commercial, Office].includes(type),
    postal_code: () => true,
    city: () => true,
    country: () => true,
    borough_or_district: () => true,
    province_or_state: () => true,
};

export const isFieldVisibleInCountry = (field: string, country: string | undefined): boolean => {
    if (!visibilityRulesByCountry || !visibilityRulesByCountry[field]) {
        return false;
    }

    return visibilityRulesByCountry[field](country);
};

export const isFieldVisible = (field: string, ctx: PropertyFieldVisibilityContext): boolean => {
    if (!visibilityRulesWithoutCountry || !visibilityRulesWithoutCountry[field]) {
        return false;
    }

    return isFieldVisibleInCountry(field, ctx.country) && visibilityRulesWithoutCountry[field](ctx);
};

export function getLabelsForCountry(country: string): { [fieldName: string]: JSX.Element } {
    return Object.fromEntries(
        Object.entries(fieldLabels)
            .filter(([fieldName]) => isFieldVisibleInCountry(fieldName, country))
            .map(([fieldName, intlMessage]) => [
                fieldName,
                // eslint-disable-next-line react/jsx-key
                <FormattedMessage {...intlMessage} />,
            ]),
    );
}
