import invariant from 'tiny-invariant';
import ServiceRequests from '@/requests/ServiceRequest';

export default class PublicationServiceRequests extends ServiceRequests {
    serviceName = 'ad-status';

    getBaseUrl = () => {
        invariant(AD_STATUS_SERVICE_URL, 'AD_STATUS_SERVICE_URL needs to be defined');

        return AD_STATUS_SERVICE_URL;
    };

    getChannelAccountsStatus = (
        estateId: string,
        isAdStatus?: boolean,
        params?: { status?: string[]; isStatusEnabled?: boolean },
    ) => {
        if (isAdStatus) {
            const status = !params?.status?.length ? ['active', 'failed'] : params.status;

            return this.get(`/estates/${estateId}/channel-accounts`, {
                params: params?.isStatusEnabled ? { status } : undefined,
            }).then(res => res.data);
        }

        return this.get(`${API_PROTOCOL}://${API_URL}/properties/${estateId}/channel-accounts`).then(res => res.data);
    };

    getSingleChannelAccountStatus = (
        estateId: string,
        channelAccountId: string,
        params?: { last_modified?: string },
    ) => {
        return this.get(`/estates/${estateId}/channel-accounts/${channelAccountId}`, {
            params,
        }).then(res => res.data);
    };

    retryPublication = (estateId: string, channelAccountId: string) => {
        return this.post(`/estates/${estateId}/channel-accounts/${channelAccountId}/retry`).then(res => res.data);
    };

    validateEstate = async (channelId: string, estate: Record<string, any>) => {
        return this.post(`/channels/${channelId}/validate`, estate);
    };
}
