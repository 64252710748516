import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl-sweepbright';
import { ThemeProvider, DEFAULT_THEME } from '@/app.utils/theming/ThemeContext';
import OfficeContext from '@/app.domains/office/OfficeContext';
import UserContext from '@/app.shared/members/UserContext';
import CompanyContext from '@/app.domains/company/CompanyContext';
import { setUser } from '@/app.utils/analytics';
import { getCompany } from '@/app.redux/selectors';
import { getBugsnagClient } from '@/app.config/bugsnag';
import IntercomChatBubble from '@/app.components/app/IntercomChatBubble';
import { RouterProvider } from '@/app.hooks/useRouter';
import LagRadar from '@/app.components/app/LagRadar';
import { MessagesProvider } from '@/app.hooks/useMessages';
import Navigation from '@/app.components/navigations/Navigation';

class ApplicationLayout extends Component {
    static propTypes = {
        addCompanyOffice: PropTypes.func.isRequired,
        addContact: PropTypes.func.isRequired,
        auth: PropTypes.instanceOf(Immutable.Map).isRequired,
        children: PropTypes.node,
        features: PropTypes.instanceOf(Immutable.Map),
        fetchCompanyFeatures: PropTypes.func,
        fetchFeatures: PropTypes.func,
        intercomUser: PropTypes.object,
        offices: PropTypes.instanceOf(Immutable.OrderedSet),
        routes: PropTypes.array.isRequired,
        user: PropTypes.instanceOf(Immutable.Map),
        company: PropTypes.instanceOf(Immutable.Map),
    };

    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static childContextTypes = {
        company: PropTypes.instanceOf(Immutable.Map),
        office: PropTypes.instanceOf(Immutable.Map),
        user: PropTypes.instanceOf(Immutable.Map),
    };

    state = {
        // use the default theme
        theme: DEFAULT_THEME,
    };

    getChildContext() {
        const { user } = this.props;

        if (user) {
            const office = user.getIn(['office', 'data']);
            const company = user.getIn(['company', 'data']);

            return { user, company, office };
        }

        return { user, company: Immutable.Map(), office: Immutable.Map() };
    }

    componentDidMount() {
        const { user } = this.props;

        this.props.fetchFeatures();

        if (user) {
            this.props.fetchCompanyFeatures(user.getIn(['company', 'data', 'id']));

            setUser(user.toJS());
        }
    }

    componentDidUpdate() {
        const { user } = this.props;
        if (!user) {
            return;
        }

        this.updateBugsnagMeta();
    }

    updateBugsnagMeta = () => {
        const { user } = this.props;

        const firstName = user.get('first_name');
        const lastName = user.get('last_name');
        if (__CLIENT__) {
            const bugsnagClient = getBugsnagClient();
            bugsnagClient.user = {
                id: user.get('id'),
                name: `${firstName} ${lastName}`,
                email: user.get('email'),
            };

            bugsnagClient.metaData = {
                company: {
                    id: user.getIn(['company', 'data', 'id']),
                    name: user.getIn(['company', 'data', 'name']),
                },
                office: {
                    id: user.getIn(['office', 'data', 'id']),
                    name: user.getIn(['office', 'data', 'name']),
                },
            };
        }
    };

    shouldDisplayNavigation() {
        const { router } = this.context;

        return (
            !router.isActive('login') &&
            !router.isActive('login/sent') &&
            !router.isActive('register') &&
            this.props.auth.get('logged_in')
        );
    }

    getEnterprise = user => {
        const enterprise = user && user.getIn(['company', 'data', 'enterprise', 'data']);

        return enterprise;
    };

    getTheme = user => {
        const enterprise = this.getEnterprise(user);

        if (enterprise) {
            const theme = enterprise.get('theme');

            if (!theme) {
                console.warn(`The theme ${theme} name is not available, using default theme instead`);

                return DEFAULT_THEME;
            }

            return theme;
        }

        return DEFAULT_THEME;
    };

    render() {
        const { addContact, addCompanyOffice, company, ...navigationProps } = this.props;

        const user = this.props.user || Immutable.Map();
        const theme = this.getTheme(user);
        const office = user.getIn(['office', 'data'], Immutable.Map());

        return (
            <RouterProvider>
                <ThemeProvider theme={theme}>
                    <MessagesProvider>
                        <CompanyContext.Provider value={company}>
                            <OfficeContext.Provider value={office}>
                                <UserContext.Provider value={user}>
                                    <div className="bc-scrollable-container">
                                        <Helmet
                                            title="SweepBright"
                                            htmlAttributes={{ lang: user.get('locale'), translate: 'no' }}
                                        />
                                        {this.shouldDisplayNavigation() && (
                                            <Navigation
                                                {...navigationProps}
                                                handleAddContact={addContact}
                                                handleAddOffice={addCompanyOffice}
                                            />
                                        )}
                                        <div className="bc-scrollable-container-content">{this.props.children}</div>
                                        <IntercomChatBubble />
                                        <LagRadar />
                                    </div>
                                </UserContext.Provider>
                            </OfficeContext.Provider>
                        </CompanyContext.Provider>
                    </MessagesProvider>
                </ThemeProvider>
            </RouterProvider>
        );
    }
}

export default compose(
    connect(
        createStructuredSelector({
            company: getCompany,
        }),
    ),
    injectIntl,
    withRouter,
)(ApplicationLayout);
