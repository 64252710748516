import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@/app.components';
import { AddContactModal } from '@/app.components/modals/AddContactModalBody';

type Props = {
    type?: 'lead' | 'vendor';
    onAdded: (contact) => void;
};

const ContactSelectCreate: React.FC<Props> = props => {
    const { type, onAdded } = props;

    const [isCreatingContact, setIsCreatingContact] = useState(false);

    const handleClose = () => {
        setIsCreatingContact(false);
    };

    return (
        <div>
            <button
                className="p-2 flex items-center space-x-2 border-0 bg-transparent block w-full text-primary cursor-default"
                type="button"
                onClick={evt => {
                    evt.stopPropagation();
                    setIsCreatingContact(true);
                }}
            >
                <Icon icon="add" />
                <span className="ml-2">
                    <FormattedMessage id="search.contact_picker.create_contact" defaultMessage="Create contact" />
                </span>
            </button>
            <div
                onMouseUp={evt => {
                    evt.stopPropagation();
                }}
            >
                <AddContactModal
                    type={type}
                    show={isCreatingContact}
                    onClose={handleClose}
                    onAdded={contact => {
                        setIsCreatingContact(false);
                        onAdded(contact);
                    }}
                />
            </div>
        </div>
    );
};

export default ContactSelectCreate;
