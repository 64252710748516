import isNil from 'lodash/isNil';
import { DateTime } from '@/app.data/Datetime';

type CheckerFn = (obj?: object) => boolean;

const emptyBy = (checker: CheckerFn) => (object: object = {}) =>
    checker(object) || Object.values(object).filter(checker).length === Object.values(object).length;

export const isObjectEmpty = emptyBy(isNil);

export const isDateEmpty = (attributes: DateTime) => !attributes.date && !attributes.hour && !attributes.minutes;
