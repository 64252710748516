// eslint-disable: no-shadowed-variable
import React, { useState } from 'react';
import { OrderedSet, Map } from 'immutable';
import { withRouter, WithRouterProps } from 'react-router';
import { compose } from 'recompose';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getOffices } from '@/app.redux/selectors';
import { setUserOffice } from '@/app.redux/actions/UsersActions';
import { getCurrentUser } from '@/graphql/queries/users/getCurrentUser';
import { UPDATE_USER_OFFICE } from '@/graphql/mutations/users/setCurrentOffice';
import wrapWithPromise from '@/app.utils/services/wrapWithPromise';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import { useTeams } from '@/new.domains/teams/hooks/useTeams';
import isScrollEnd from '@/utils/isScrollEnd';
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown';
import Icon from '../../icons/Icon';

export const OfficesMenu: React.FunctionComponent<{
    offices: OrderedSet<Map<string, any>>;
    currentOffice: Map<string, any>;
    setUserOffice: (args: { id: string }) => void;
    user: Map<string, any>;
} & WithRouterProps> = ({ offices = OrderedSet(), setUserOffice }) => {
    const [query, setQuery] = useState('');

    const { loading, data } = useQuery(getCurrentUser);

    const { teams, loading: teamsLoading, handleFetchMore } = useTeams({ query });

    const [updateUserOffice, { client }] = useMutation(UPDATE_USER_OFFICE);
    const currentOffice = !loading && data ? data.me.office : null;

    if (offices.size <= 1) {
        return null;
    }

    async function onSelectUserOffice(officeId: string) {
        await client?.clearStore();
        // run the GraphQL mutation to update the data
        const { data } = await updateUserOffice({
            variables: {
                officeId,
            },
        });

        location.replace('/');

        // sync the resulting data of the mutation back to the redux store,
        // where it is accessed from by other components
        await wrapWithPromise(setUserOffice)({ id: data.updateUserOffice.office.id });
        // changing offices should take the user back to the home page
        // otherwise the could end up in a property/contacts page of a different office
    }

    if (loading) {
        return null;
    }

    const handleRemoveGeoFilter = () => {
        const propertiesFilters = window.localStorage.getItem('properties.filters');

        if (propertiesFilters) {
            const parsedPropertiesFilters = JSON.parse(propertiesFilters);

            window.localStorage.setItem(
                'properties.filters',
                JSON.stringify({
                    ...parsedPropertiesFilters,
                    geo_filter: null,
                    postal_codes: null,
                }),
            );
        }
    };

    return (
        <NavbarDropdown
            query={query}
            setQuery={setQuery}
            title={truncate(currentOffice ? currentOffice.name : '', 22)}
            onScroll={event => {
                if (isScrollEnd(event) && !teamsLoading) {
                    handleFetchMore();
                }
            }}
        >
            {teams.map(team => {
                const selected = currentOffice && team.id === currentOffice.id;

                return (
                    <Dropdown.MenuItem
                        key={team.id}
                        className="flex items-center justify-between"
                        onClick={() => {
                            if (!selected) {
                                handleRemoveGeoFilter();
                                onSelectUserOffice(team.id);

                                return;
                            }

                            return undefined;
                        }}
                    >
                        <div style={{ paddingRight: 8 }} className="truncate">
                            {team.name}
                        </div>
                        {selected && <Icon icon="check" className="pull-right" />}
                    </Dropdown.MenuItem>
                );
            })}
        </NavbarDropdown>
    );
};

export default compose(
    withRouter,
    connect(
        createStructuredSelector({
            offices: getOffices,
        }),
        {
            setUserOffice,
        },
    ),
)(OfficesMenu);

function truncate(text = '', characterCount) {
    if (text.trim().length > characterCount) {
        return text.trim().slice(0, characterCount) + '...';
    }

    return text.trim();
}
