import { BucketsRequest } from '../../../requests/index';
import formatApiErrors from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';

export default function* onCreateBucket({ attributes: { resolve, reject, ...attributes } }) {
    try {
        yield apiCall(new BucketsRequest().store, attributes);
        // eslint-disable-next-line no-unused-expressions
        resolve?.();
    } catch (response) {
        const status = response?.response?.status;

        if (status === 403) {
            return reject?.(`403 - You don't have permission to create a bucket`);
        }
        // eslint-disable-next-line no-unused-expressions
        reject?.(formatApiErrors(response));
    }
}
