// @ts-nocheck
import React from 'react';
import Tabs from '@sweepbright/uikit/build/esm/tabs';
import { FormattedMessage } from 'react-intl-sweepbright';
import PagePane from '@/app.layouts/PagePane/PagePane';
import RegisterActivityAction from '@/app.components/activity/RegisterActivityAction/RegisterActivityAction';
import RegisterActivityModal from '@/app.components/modals/RegisterContactActivityModal';
import { useToasts } from '@sweepbright/notifications';
import { events, track } from '@/app.utils/analytics';
import { ContactTimeline } from '../../../../app.domains/contacts/timeline/components/ContactTimeline';
import { ContactInteractedPropertiesList } from '../../../../app.domains/contacts/timeline/components/ContactInteractedPropertiesList';

export default function ContactTimelinePage({ params, location }) {
    const contactId = params.contact;
    const { addSuccess } = useToasts();
    const [visibleRegistrationModalType, setVisibleRegistrationModalType] = React.useState('');
    const [activeTabIndex, setActiveTabIndex] = React.useState(location?.state?.fromPropertyTimeline ? 1 : 0);

    const propertiesListRef = React.useRef();
    const timelineRef = React.useRef();

    function handleRegisterActivity(type: 'visit' | 'call' | 'message' | 'mail') {
        track(events.CONTACT_ACTIVITY_REGISTER_BTN_CLICKED, { activityType: type });
        setVisibleRegistrationModalType(type);
    }

    return (
        <PagePane
            title={<FormattedMessage id="navigation.aside.activity" defaultMessage="Activity" />}
            actions={[<RegisterActivityAction key="action" onRegisterActivity={handleRegisterActivity} />]}
        >
            {visibleRegistrationModalType ? (
                <RegisterActivityModal
                    show={true}
                    contactId={contactId}
                    onClose={() => setVisibleRegistrationModalType('')}
                    type={visibleRegistrationModalType}
                    contactType="vendor"
                    onActivityRegistered={() => {
                        /* eslint-disable no-unused-expressions */
                        timelineRef.current?.refresh();
                        propertiesListRef.current?.refresh();
                        /* eslint-enable no-unused-expressions */

                        addSuccess({
                            title: (
                                <FormattedMessage
                                    id={`timeline.activity_registered_success.title.${visibleRegistrationModalType}`}
                                    defaultMessage={`timeline.activity_registered_success.title.${visibleRegistrationModalType}`}
                                />
                            ),
                            message: (
                                <FormattedMessage
                                    id="timeline.activity_registered_success.confirmation"
                                    defaultMessage="The new activity will show up in the timeline"
                                />
                            ),
                        });

                        setVisibleRegistrationModalType('');
                    }}
                />
            ) : null}
            <Tabs
                defaultIndex={activeTabIndex}
                onChange={(selectedIndex: number) => {
                    if (selectedIndex === 0) track(events.CONTACT_ACTIVITY_TIMELINE_TAB_CLICKED);
                    if (selectedIndex === 1) track(events.CONTACT_ACTIVITY_PROPERTIES_TAB_CLICKED);
                    setActiveTabIndex(selectedIndex);
                }}
            >
                <Tabs.TabList>
                    <Tabs.Tab>
                        <FormattedMessage id="pages.activity.tab_labels.timeline" defaultMessage="Timeline" />
                    </Tabs.Tab>
                    <Tabs.Tab>
                        <FormattedMessage id="modals.activity.properties.title" defaultMessage="Properties" />
                    </Tabs.Tab>
                </Tabs.TabList>
                <Tabs.TabPanels>
                    <Tabs.TabPanel>
                        {activeTabIndex === 0 && <ContactTimeline contactId={contactId} ref={timelineRef} />}
                    </Tabs.TabPanel>
                    <Tabs.TabPanel>
                        {activeTabIndex === 1 && (
                            <ContactInteractedPropertiesList contactId={contactId} ref={propertiesListRef} />
                        )}
                    </Tabs.TabPanel>
                </Tabs.TabPanels>
            </Tabs>
        </PagePane>
    );
}
