import { PaginatedResponse } from '@/requests/types';

export const SET_PAGINATION = 'pagination/SET_PAGINATION';
export const RESET_PAGINATION = 'pagination/RESET_PAGINATION';

export function setPagination(entities: PaginatedResponse, resource: string) {
    return { type: SET_PAGINATION, entities, resource } as const;
}

export function resetPagination(resource: string) {
    return { type: RESET_PAGINATION, resource } as const;
}

export type PaginationAction = ReturnType<typeof setPagination | typeof resetPagination>;
