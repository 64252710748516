import gql from 'graphql-tag';

export const GET_CONTACT_REFERENCE = gql`
    query GetContactReference($id: ID!) {
        contactReference(id: $id) {
            assignee {
                id
                first_name
                last_name
                phone
                email
                picture_url
            }
            team {
                id
                name
            }
        }
    }
`;
