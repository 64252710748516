import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import { EstateRepository } from '@/app.utils/services';
import { GetLegalEntitiesQuery, LegalEntities } from '@/graphql/generated/types';

type Props = {
    loading: boolean;
    disabled?: boolean;
    refetch: () => void;
    fetchMore: () => void;
    property: EstateRepository;
    data?: GetLegalEntitiesQuery;
    onChange?: (value: any) => void;
};

const PropertyFormLegalEntitySelect: React.FC<Props> = props => {
    const { property, loading, fetchMore, data, disabled, onChange } = props;

    const [activeEntity, setActiveEntity] = useState<LegalEntities | null>(property.getLegalEntity() || null);

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        if (scrolled) {
            fetchMore();
        }
    }, [scrolled, fetchMore]);

    const filteredData = data?.office?.legalEntities?.edges.map(el => {
        return {
            value: el?.node?.id,
            label: el?.node?.name,
        };
    });

    return (
        <Select
            clearable
            loading={loading}
            disabled={disabled}
            //@ts-ignore
            options={filteredData}
            value={activeEntity?.id || null}
            onChange={newSelected => {
                const newValue = newSelected?.value;

                setActiveEntity(
                    data?.office?.legalEntities?.edges?.find(el => el?.node?.id === newValue)?.node || null,
                );

                onChange?.(newValue);
            }}
            onScroll={isScrollEnd => {
                setScrolled(isScrollEnd);
            }}
            placeholder={
                loading ? (
                    <FormattedMessage
                        id="pages.properties.team_settings_panel.legal_entity.loading"
                        defaultMessage="Loading legal entities..."
                    />
                ) : (
                    <FormattedMessage
                        id="pages.properties.team_settings_panel.legal_entity.placeholder"
                        defaultMessage="Select..."
                    />
                )
            }
        />
    );
};

export default PropertyFormLegalEntitySelect;
