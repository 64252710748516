import { Map, List, Set } from 'immutable';
import { PropertySearchParameters, ReduxStoreState } from '@/app.redux/selectors/types';
import { propertyParametersMapper, contactsParametersMapper } from '../../app.utils/services/Helpers/parameters';
import createSelector from './createImmutableSelector';
import { getUser } from './UsersSelectors';
import { getPropertiesAggregations } from './AggregationsSelectors';

const toParameters = (
    settings,
    reducer: (parameters: Map<string, any>, value: any, key: string) => Map<string, any>,
) => {
    return settings.reduce(reducer, Map()).toJS();
};

export type SearchDomain = 'contacts' | 'publish' | 'properties' | 'visits' | 'visits/contacts' | 'company/leads';

const ignoredProperties = Map<SearchDomain, List<string>>({
    properties: List(),
    visits: List(),
    publish: List(),
    contacts: List(),
    'visits/contacts': List(['filterByInterest']),
});

export const getSearch = (state: ReduxStoreState) => state.search;

export const getDefaultsSearchSettings = (settings: string) =>
    createSelector(getSearch, search => {
        return search.getIn(['defaults', settings]).toJS();
    });

export const getSearchSettings = (domain: string, normalizeBy: SearchDomain = domain as SearchDomain) =>
    createSelector(getSearch, getUser, getPropertiesAggregations, (search, user, aggregations) => {
        const settings = search.getIn([domain, 'params']) || search.getIn([normalizeBy, 'params']) || Map();

        switch (normalizeBy) {
            case 'visits/contacts':
            case 'contacts': {
                const mapper = contactsParametersMapper({
                    settings,
                    ignoredProperties,
                    normalizeBy,
                });

                return toParameters(settings, mapper);
            }
            case 'visits':
            case 'properties':
            case 'publish': {
                const mapper = propertyParametersMapper({
                    settings,
                    user,
                    ignoredProperties,
                    normalizeBy,
                    meta: {
                        priceRanges: aggregations,
                    },
                });

                return toParameters(settings, mapper);
            }
            case 'company/leads':
                return {
                    name: settings.get('query'),
                    sort_field: settings.get('sort_field'),
                    sort_order: settings.get('sort_order'),
                };
            default:
                return settings.toJS();
        }
    });

export const withArchived = (domain: SearchDomain, entitiesSelector: (state: any, props: any) => Map<string, any>) =>
    createSelector(
        [getSearchSettings(domain), entitiesSelector],
        (searchParameters: PropertySearchParameters | undefined, entities: List<Map<string, any>>) => {
            if (searchParameters) {
                return entities.filter((entity: Map<string, any>) => {
                    return searchParameters.archived || !entity.get('is_archived');
                });
            }
            throw new Error(`search parameters for domain '${domain} where undefined`);
        },
    );

export const getSearchResults = (domain: string) =>
    createSelector(getSearch, (search: Map<string, any>) => {
        return search.getIn([domain, 'results'], Set());
    });
