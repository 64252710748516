import { glue } from '@sweepbright/fields-common/lib/path';
import { Rule } from './types';

export const BREAK = Symbol('BREAK');

export interface IRulesIndex {
  add(rule: Rule): void;
  get(path: string): Rule[] | null;
  getAll(pathPrefix: string): { [path: string]: Rule[] };
  walk(
    pathPrefix: string,
    callback: (path: string, rules: Rule[] | null) => void | typeof BREAK
  ): void;
}

type Node = Map<string, Node>;

export class RulesIndex implements IRulesIndex {
  private root: Node = new Map();
  private byPath: Map<string, Rule[]> = new Map();

  private addPathToHierarchy(path: string) {
    if (path === '') {
      return;
    }
    const parts = path.split('.');
    let current = this.root;
    for (const part of parts) {
      if (!current.has(part)) {
        current.set(part, new Map());
      }

      current = current.get(part)!;
    }
  }

  private getAllPathsUnderPrefix(prefix: string): string[] {
    const paths: string[] = [];

    this.walk(prefix, (path, rules) => {
      if (rules) {
        paths.push(path);
      }
    });

    return paths;
  }

  private traverse(
    node: Node,
    path: string,
    callback: (path: string) => void | typeof BREAK
  ) {
    if (callback(path) === BREAK) {
      return;
    }
    if (node.size === 0) {
      return;
    }
    for (const [key, value] of node) {
      this.traverse(value, glue(path, key), callback);
    }
  }

  private getNodeByPath(path: string): Node | null {
    if (path === '') {
      return this.root;
    }
    const parts = path.split('.');
    let current = this.root;
    for (const part of parts) {
      if (!current.has(part)) {
        return null;
      }

      current = current.get(part)!;
    }

    return current;
  }

  add(rule: Rule): void {
    if (this.byPath.has(rule.path)) {
      this.byPath.get(rule.path)!.push(rule);
    } else {
      this.byPath.set(rule.path, [rule]);
    }
    this.addPathToHierarchy(rule.path);
  }

  get(path: string): Rule[] | null {
    return this.byPath.get(path) ?? null;
  }

  getAll(pathPrefix: string): { [path: string]: Rule[] } {
    const paths = this.getAllPathsUnderPrefix(pathPrefix);
    const rules: { [path: string]: Rule[] } = {};
    for (const path of paths) {
      if (this.byPath.has(path)) {
        rules[path] = this.byPath.get(path)!;
      }
    }

    return rules;
  }

  public walk(
    path: string,
    callback: (path: string, rules: Rule[] | null) => void | typeof BREAK
  ) {
    const node = this.getNodeByPath(path);
    if (!node) {
      return;
    }

    this.traverse(node, path, (path) => callback(path, this.get(path)));
  }
}
