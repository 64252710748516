import React from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import Checkbox from '@sweepbright/uikit/build/esm/checkbox';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { FormattedMessage } from 'react-intl-sweepbright';
import TimeInput from '@/app.components/forms/TimeInput';
import Input from '@/app.components/forms/Input/Input';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';

const OfferModalDate = () => {
    const { values, setFieldValue } = useFormikContext<OfferCardFragmentFragment>();

    const content = {
        title: <FormattedMessage id="offers.modals.date" defaultMessage="Dates" />,
        checkbox: <FormattedMessage id="offers.modals.date.checkbox" defaultMessage="Valid until date" />,
        date: <FormattedMessage id="offers.modals.date.date" defaultMessage="Date" />,
        time: <FormattedMessage id="offers.modals.date.time" defaultMessage="Time" />,
    };

    const isChecked = typeof values.validUntil === 'string';

    const date = values.validUntil ? moment(values.validUntil).format('YYYY-MM-DD') : undefined;
    const time = values.validUntil ? moment(values.validUntil).format('HH:mm') : undefined;

    const format = 'YYYY-MM-DDTHH:mm:ssZ';

    return (
        <FormPanel title={content.title} className="offer-modal-date">
            <div className="text-left">
                <Checkbox
                    checked={isChecked}
                    name="validUntilCheckbox"
                    onChange={() => {
                        setFieldValue('validUntil', isChecked ? null : '');
                    }}
                >
                    {content.checkbox}
                </Checkbox>

                {isChecked && (
                    <div className="offer-modal-date__block">
                        <Input
                            type="date"
                            value={date}
                            clearable={false}
                            minDate={moment()}
                            label={<FormattedMessage id="general.date" defaultMessage="Date" />}
                            onChange={newValue => {
                                setFieldValue('validUntil', moment(newValue).format(format));
                            }}
                        />

                        <FormGroup>
                            <ControlLabel>{content.time}</ControlLabel>
                            <TimeInput
                                singleMode
                                clearable={false}
                                disabled={!values.validUntil}
                                field={{
                                    value: time,
                                    onChange: event => {
                                        const newValue = event.target.value;

                                        const hours = newValue.split(':')[0];
                                        const minutes = newValue.split(':')[1];

                                        setFieldValue(
                                            'validUntil',
                                            moment(date, 'YYYY-MM-DD')
                                                .set('hours', hours)
                                                .set('minutes', minutes)
                                                .format(format),
                                        );
                                    },
                                }}
                            />
                        </FormGroup>
                    </div>
                )}
            </div>
        </FormPanel>
    );
};

export default OfferModalDate;
