import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import MembersInvite from '@/new.domains/members/MembersAdd/MembersAddForm';
import { useInviteOfficeUsers } from '@/app.hooks/user/useInviteUser';
import { ONE_BY_ONE } from '@/new.domains/members/utils';

const MembersAdd = () => {
    const [success, setSuccess] = useState(false);
    const [mode, setMode] = useState(ONE_BY_ONE);

    const [inviteMembers, { loading, error }] = useInviteOfficeUsers({
        officeId: '',
        invite: { language: '', invites: [{ email: '', firstName: '', lastName: '' }] },
    });

    const handleSubmit = (values: any) => {
        inviteMembers({
            variables: {
                officeId: values.officeId,
                invite: {
                    language: values.language,
                    invites: mode === ONE_BY_ONE ? values.field : values.bulkField.split(',').map(email => ({ email })),
                },
            },
        }).then(() => {
            setSuccess(true);
        });
    };

    const inviteErrors =
        error &&
        error?.graphQLErrors[0].extensions?.exception?.response?.body?.errors.map(el => {
            return {
                mode: mode,
                field: el?.meta?.field,
                title: el?.title,
            };
        });

    const content = {
        title: <FormattedMessage id="members.invite" defaultMessage="Invite Members" />,
        success: (
            <FormattedMessage
                id="forms.invitations.members.success.title"
                defaultMessage="Successfully invited team members."
            />
        ),
        submit: <FormattedMessage id="form.invitations.members.submit" defaultMessage="Invite" />,
        submitting: <FormattedMessage id="form.invitations.members.submitting" defaultMessage="Inviting..." />,
    };

    return (
        <MembersInvite
            bulk
            mode={mode}
            setMode={setMode}
            loading={loading}
            content={content}
            success={success}
            handleSubmit={handleSubmit}
            inviteErrors={inviteErrors}
        />
    );
};

export default MembersAdd;
