import moment from 'moment';
import { FormFields } from '@sweepbright/webapp-shared/utils/types';
import { DataBase64UrlBuilder, CDNUrlBuilder, APIUrlBuilder, NullUrlBuilder } from './builders';

export type FileData = Partial<{
    file: File;
    filename: string;
    description: string;
    id: string;
    uploaded_at: string;
    private: boolean;
    progress: number;
    viewed: boolean;
    //  base64 encoded version of the file
    data_url: string;
    deleted: boolean;
    equirectangular: boolean;
}>;

export type FileDataField = FormFields<FileData, File>;

export default class FileRepository {
    propertyId: string;
    file: FileData;

    constructor(propertyId: string, file: FileData) {
        this.propertyId = propertyId;
        this.file = file;
    }

    getName(): string {
        if (this.file.description) {
            return this.file.description;
        }
        if (this.file.filename) {
            return this.file.filename;
        }

        return '';
    }

    getIdentifier(): string {
        if (this.file.id && this.file.id) {
            return this.file.id;
        }

        return this.getName();
    }

    getThumbnail(type: string, preset: string = 'thumbnail', presetGroups?: string) {
        const id = this.file.id;

        if (!id) {
            return;
        }

        if (
            type === 'images' ||
            (this.file['content-type'] && this.file['content-type'].includes('image/')) ||
            presetGroups
        ) {
            return this.getFileUrl(type, preset, presetGroups);
        }

        return `${API_PROTOCOL}://${API_URL}/estates/${this.propertyId}/${type}/${id}/${preset}`;
    }

    getUploadedAt() {
        if (this.file.uploaded_at) {
            return moment(this.file.uploaded_at).fromNow();
        }

        return;
    }

    isUnread() {
        if (this.file.viewed) {
            return !this.file.viewed;
        }

        return true;
    }

    isPrivate() {
        if (this.file.private) {
            return this.file.private;
        }

        return false;
    }

    isUploading() {
        return this.getProgress() < 100;
    }

    getProgress(): number {
        return this.file.progress ?? 100;
    }

    getFileUrl(type: string = 'images', preset?: string, presetGroups?: string) {
        const fileId = this.file.id;
        const dataUrl = this.file.data_url;

        const args = {
            type,
            propertyId: this.propertyId,
            preset,
            fileId,
            dataUrl,
            presetGroups,
        };

        const builder = [DataBase64UrlBuilder, CDNUrlBuilder, APIUrlBuilder, NullUrlBuilder].find(builder =>
            builder.isValidBuilder(args),
        );
        const fileUrl = builder!.buildUrl(args);

        return fileUrl;
    }
}

// helper function
export function extractValuesFromField(field, depth = 0) {
    if (depth > 10) {
        // oops, looks like a infinite recursion
        throw new Error('failed to extract values');
    }

    if (field === null) {
        return field;
    }

    if (typeof field !== 'object') {
        return field;
    }

    if (isLeafField(field)) {
        return field.value;
    }

    return Object.keys(field).reduce((values, key) => {
        values[key] = isLeafField(field) ? field[key].value : extractValuesFromField(field[key], depth + 1);

        return values;
    }, {});

    function isLeafField(subField) {
        if (subField.hasOwnProperty('value')) {
            return true;
        }

        return false;
    }
}
