import gql from 'graphql-tag';

export const GET_SIMILAR_PROPERTIES_QUERY = gql`
    query getSimilarProperties($id: ID!) {
        estate(id: $id) {
            id
            similarProperties
        }
    }
`;
