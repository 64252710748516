import { put, select } from 'redux-saga/effects';
import { UserRequests } from '../../../requests/index';
import { setUserViews } from '../../actions';
import { getUser } from '../../selectors/UsersSelectors';
import apiCall from '../Effects/apiCall';

export default function* onFetchUserViews({ resource = 'files' }) {
    const user = yield select(getUser);
    if (user) {
        try {
            const views = yield apiCall(new UserRequests().views, user.get('id'), resource);
            yield put(setUserViews(user.get('id'), views, resource));
        } catch (err) {
            // failed to fetch user views
        }
    }
}
