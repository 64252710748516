import React from 'react';
import { FormattedMessage } from 'react-intl';
import { COMPANY, SUBSCRIPTION_INVOICE, SUBSCRIPTION_PAYMENT, SUBSCRIPTION_PLAN } from '../../app.routing/routes';
import LeftNavBar from '../navigations/LeftNavBar';
import SideMenuLink from '../navigations/SideMenu/SideMenuLink';

const CompanyAside = props => {
    const { company, location, isAdmin } = props;
    const linkProps = { location };

    return (
        <LeftNavBar location={location}>
            {isAdmin && (
                <SideMenuLink
                    fuzzyMatch
                    {...linkProps}
                    to={COMPANY}
                    label={company.get('name')}
                    icon="company"
                    iconActive="company-fill"
                />
            )}
            <SideMenuLink
                fuzzyMatch
                label={<FormattedMessage id="navigation.subscription.subscription" defaultMessage="Subscription" />}
                icon="cloud"
                to={SUBSCRIPTION_PLAN}
                iconActive="cloud-fill"
            />
            <SideMenuLink
                fuzzyMatch
                label={<FormattedMessage id="navigation.subscription.invoice" defaultMessage="Invoice" />}
                icon="document"
                to={SUBSCRIPTION_INVOICE}
                iconActive="document-fill"
            />
            {company?.getIn(['card', 'id']) && (
                <SideMenuLink
                    fuzzyMatch
                    label={<FormattedMessage id="navigation.subscription.payment" defaultMessage="Payment" />}
                    icon="credit-card"
                    to={SUBSCRIPTION_PAYMENT}
                    iconActive="credit-card-fill"
                />
            )}
        </LeftNavBar>
    );
};

export default CompanyAside;
