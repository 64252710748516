import React, { FC } from 'react';
import { Event } from '../types';
import { EventTitle } from './EventTitle';

export const EventCardCenter: FC<{ event: Event }> = ({ event }) => {
    return (
        <div className="ml-5 cursor-default">
            <div>
                <EventTitle event={event} />
            </div>
            <div className="text-muted">{event.location}</div>
        </div>
    );
};
