// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import { Alert } from 'react-bootstrap';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import memoizeOne from 'memoize-one';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl-sweepbright';
import Button from '@/app.components/elements/Buttons/Button';
import withModals from '@/app.utils/Decorators/withModals';
import UploadButton from '@/app.domains/channels/Website/elements/UploadButton';
import { PreviewRequests } from '@/requests';
import { fetchHighlights } from '../../../app.redux/actions';
import { homepage } from '../../../app.data';
import { getCompanyHighlights } from '../../../app.redux/selectors';
import companySettings, { propTypes } from '../../../app.utils/Decorators/companySettings';
import BorderedList from '../../../app.components/forms/BorderedList';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import FormPane from '../../../app.components/forms/FormPane';
import Input from '../../../app.components/forms/Input/Input';
import MediaCard from '../../../app.components/card/MediaCard/MediaCard';
import TileList from '../../../app.components/elements/Tile/TileList';
import PropertyTile from '../../../app.domains/properties/PropertyTile';
import AddHighlight from '../../../app.components/modals/AddHighlight';
import ImageLoader from '../../../app.components/images/ImageLoader';
import Embedly from '../../../app.components/embed/Embedly';

const SortablePropertyTile = SortableElement(props => <PropertyTile {...props} sortable />);

const SortableList = SortableContainer(({ items, renderItem }) => {
    return (
        <TileList grid cards>
            {items.map(renderItem)}
        </TileList>
    );
});

const maxHighlights = 3;

class WebsiteHome extends Component {
    static propTypes = {
        ...propTypes,
        fetchHighlights: PropTypes.func.isRequired,
        highlights: PropTypes.instanceOf(OrderedSet),
    };

    state = {
        loading: false,
    };

    componentDidMount() {
        const { company, fields } = this.props;

        const ids = fields.homepage_highlights.map(field => field.value);
        this.props.fetchHighlights(company.get('id'), ids);
    }

    componentDidUpdate(oldProps) {
        const { company, fields } = this.props;

        const ids = fields.homepage_highlights.map(field => field.value);
        const oldIds = oldProps.fields.homepage_highlights.map(field => field.value);
        if (JSON.stringify(ids) !== JSON.stringify(oldIds)) {
            this.props.fetchHighlights(company.get('id'), ids);
        }
    }

    renderHighlight = (highlight, index) => {
        const { highlights, fields } = this.props;
        const estate = highlights.find(item => item.get('id') === highlight.value);

        if (estate) {
            return (
                <SortablePropertyTile key={highlight.value} estate={estate} index={index}>
                    <Button
                        onClick={() => {
                            fields.homepage_highlights.removeField(index);
                            fields.dirty.onChange(true);
                        }}
                    >
                        <FormattedMessage
                            id="forms.labels.homepage_highlights.remove"
                            defaultMessage="Remove Highlight"
                        />
                    </Button>
                </SortablePropertyTile>
            );
        }
    };

    handleAddHighlight = ({ estateId }) => {
        if (estateId) {
            this.props.fields.homepage_highlights.addField(estateId, 0);
            this.props.fields.dirty.onChange(true);
        }
    };

    onSortEnd = ({ oldIndex, newIndex }) => {
        const { fields } = this.props;

        fields.homepage_highlights.swapFields(oldIndex, newIndex);
        fields.dirty.onChange(true);
    };

    renderHighlights() {
        const { fields } = this.props;

        const highlightCount = fields.homepage_highlights.length;
        const limitReached = highlightCount >= maxHighlights;

        const error = fields.homepage_highlights.find(field => field.error)?.error;

        const panelProps = {};
        if (limitReached) {
            panelProps.help = (
                <FormattedMessage
                    id="forms.labels.homepage_highlights.limit_reached"
                    defaultMessage="Maximum reached"
                />
            );
        } else {
            panelProps.action = (
                <FormattedMessage id="forms.labels.homepage_highlights.action" defaultMessage="Add Highlight" />
            );
            panelProps.onAction = this.props.open.bind(this, 'add');
        }

        if (error) {
            panelProps.help = <span className="text-danger">{error}</span>;
        }

        return (
            <FormPanel
                title={
                    <FormattedMessage
                        id="forms.labels.homepage_highlights.title"
                        defaultMessage="Highlight {max} properties"
                        values={{ max: maxHighlights }}
                    />
                }
                {...panelProps}
            >
                <SortableList
                    axis="x"
                    items={fields.homepage_highlights}
                    renderItem={this.renderHighlight}
                    onSortEnd={this.onSortEnd}
                />
                {highlightCount === 0 && (
                    <Alert>
                        <FormattedMessage
                            id="forms.labels.homepage_highlights.alert"
                            defaultMessage="Select up to {max} properties to highlight on your homepage"
                            values={{ max: maxHighlights }}
                            tagName="p"
                        />
                    </Alert>
                )}
                {this.props.modal(
                    'add',
                    <AddHighlight
                        excluded={fields.homepage_highlights.map(field => field.value)}
                        field={fields.future_highlight}
                        onSubmit={this.handleAddHighlight}
                    />,
                )}
            </FormPanel>
        );
    }

    onFileAdded = async (event, results) => {
        // Retrieve the file object from the first result
        if (results.length > 0 && results[0][1]) {
            const fileObject = results[0][1];

            // Sets loading state, clears errors
            this.setState({ loading: true });

            if (!fileObject.type.includes('image')) {
                return;
            }

            const { url } = await new PreviewRequests().storePreview(fileObject);

            this.setState(
                {
                    loading: false,
                },
                () => {
                    this.props.fields.homepage_cover_image_file.onChange(fileObject);
                    this.props.fields.homepage_cover_image.onChange(url);
                },
            );
        }
    };

    renderCoverImage() {
        const { fields } = this.props;
        const { loading } = this.state;

        const panelProps = {
            title: <FormattedMessage id="forms.labels.homepage_cover.image" defaultMessage="Custom cover image" />,
            action: <UploadButton onChange={this.onFileAdded} />,
        };

        if (loading) {
            return (
                <FormPanel {...panelProps}>
                    <LoadingIndicator />
                </FormPanel>
            );
        }

        if (!fields.homepage_cover_image.value) {
            return (
                <FormPanel {...panelProps}>
                    <div className="alert alert-info">
                        <p>
                            <FormattedMessage
                                id="forms.help.homepage_cover.image"
                                defaultMessage="For best results, upload a JPG image of 2400px × 888px, less than 1MB."
                            />
                        </p>
                    </div>
                </FormPanel>
            );
        }

        const url = this.getCoverImageUrl(fields.homepage_cover_image.value);

        return (
            <FormPanel {...panelProps}>
                <p>
                    <ImageLoader src={url} className="w-full" />
                </p>
                <p>
                    <small className="text-muted">
                        <FormattedMessage
                            id="forms.help.homepage_cover.image"
                            defaultMessage="For best results, upload a JPG image of 2400px × 888px, less than 1MB."
                        />
                    </small>
                </p>
            </FormPanel>
        );
    }

    getCoverImageUrl = memoizeOne(coverImagePath => {
        let url = new URL(coverImagePath);
        url.searchParams.append('p', 'homepage-cover');
        url = url.toString();

        return url;
    });

    renderCoverVideo() {
        const { fields } = this.props;

        return (
            <FormPanel
                title={<FormattedMessage id="forms.labels.homepage_cover.video" defaultMessage="Custom cover video" />}
            >
                <Input
                    type="url"
                    placeholder={<FormattedMessage id="video_link.placeholder" defaultMessage="Paste link to video" />}
                    {...fields.homepage_cover_video}
                />
                {fields.homepage_cover_video.value && (
                    <Embedly
                        allowedTypes={['video']}
                        url={fields.homepage_cover_video.value}
                        onSuccess={() => this.setState({ disabled: false })}
                        onFailure={() => this.setState({ disabled: true })}
                    />
                )}
                <div className="help-block text-center">
                    <FormattedMessage
                        id="forms.help.homepage_cover.video"
                        defaultMessage="Paste a link from any video service such as YouTube or Vimeo"
                    />
                    <hr />
                </div>
            </FormPanel>
        );
    }

    render() {
        const { fields, route, errors, status, handleSubmit } = this.props;

        return (
            <FormPane
                title={route.title}
                status={status}
                onSubmit={handleSubmit}
                disabled={Object.keys(errors).length > 0}
            >
                <FormPanel
                    title={
                        <FormattedMessage id="forms.home.homepage_cover.title" defaultMessage="Cover image or video" />
                    }
                >
                    <p className="control-label">
                        <FormattedMessage
                            id="forms.labels.homepage_cover"
                            defaultMessage="Add an image or video on your website homepage"
                        />
                    </p>
                    <BorderedList
                        name="cover"
                        field={fields.homepage_cover}
                        options={homepage.map(({ id, name, image }) => ({
                            value: id,
                            option: <MediaCard title={name} image={<img src={image} width={96} alt="" />} />,
                        }))}
                    />
                </FormPanel>
                {['image', 'borderless_image'].includes(fields.homepage_cover.value) && this.renderCoverImage()}
                {fields.homepage_cover.value === 'video' && this.renderCoverVideo()}
                <FormPanel
                    title={<FormattedMessage id="forms.home.homepage_properties.title" defaultMessage="Highlights" />}
                >
                    <p className="control-label">
                        <FormattedMessage id="forms.labels.homepage_properties" defaultMessage="Show on Homepage" />
                    </p>
                    <Input>
                        <Input
                            type="radio"
                            {...fields.homepage_properties}
                            checked={fields.homepage_properties.value === 'latest'}
                            value="latest"
                            label={
                                <FormattedMessage
                                    id="forms.labels.homepage_properties.latest"
                                    defaultMessage="New Properties"
                                />
                            }
                        />
                        <Input
                            type="radio"
                            {...fields.homepage_properties}
                            checked={fields.homepage_properties.value === 'highlights'}
                            value="highlights"
                            label={
                                <FormattedMessage
                                    id="forms.labels.homepage_properties.highlights"
                                    defaultMessage="{max} Highlighted Properties"
                                    values={{ max: maxHighlights }}
                                />
                            }
                        />
                    </Input>
                </FormPanel>
                {fields.homepage_properties.value === 'highlights' && this.renderHighlights()}
            </FormPane>
        );
    }
}

export default compose(
    companySettings(
        'home',
        [
            'homepage_cover',
            'homepage_cover_image',
            'homepage_cover_image_file',
            'homepage_cover_video',
            'homepage_highlights[]',
            'homepage_properties',
        ],
        { highlights: getCompanyHighlights },
        { fetchHighlights },
        undefined,
        {
            success: (
                <FormattedMessage
                    id="forms.labels.homepage_properties.it_can_take_5_minutes"
                    defaultMessage="It can take up to five minutes to update your Agency Site"
                />
            ),
            errorsMapper: errors => {
                if (errors.homepage_highlights) {
                    return {
                        ...errors,
                        homepage_highlights: [errors.homepage_highlights],
                    };
                }

                return errors;
            },
        },
    ),
    withModals,
)(WebsiteHome);
