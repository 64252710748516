// @ts-nocheck
import React from 'react';
import { Badge } from 'react-bootstrap';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { FormattedMessage, FormattedPlural } from 'react-intl-sweepbright';
import MediaCard from '@/app.components/card/MediaCard/MediaCard';
import TextBlock from '../../app.components/elements/TextBlock';

const Requests = ({ to, propertyId, type }: { to: string; propertyId: string; type: string }) => {
    const { data } = useQuery(
        gql`
            query GetPendingRequests($estateId: ID!, $type: String!) {
                estate(id: $estateId) {
                    id
                    pendingRequests(type: $type) {
                        totalCount
                    }
                }
            }
        `,
        {
            variables: {
                estateId: propertyId,
                type,
            },
        },
    );

    const requests = data?.estate.pendingRequests?.totalCount ?? 0;

    if (requests > 0) {
        const title = (
            <div>
                <Badge>{requests}</Badge>{' '}
                <small className="text-muted">
                    <FormattedPlural
                        value={requests}
                        one={<FormattedMessage id="requests.pending.one" defaultMessage="request pending" />}
                        other={<FormattedMessage id="requests.pending.other" defaultMessage="requests pending" />}
                    />
                </small>
            </div>
        );

        return (
            <TextBlock>
                <MediaCard to={to} title={title} />
            </TextBlock>
        );
    }

    return null;
};

export default Requests;
