import gql from 'graphql-tag';

export const UNASSIGN_LEGAL_ENTITY = gql`
    mutation UnassignLegalEntity($input: UnassignLegalEntityToEstateInput!) {
        unassignLegalEntity(input: $input) {
            estate {
                id
            }
        }
    }
`;
