import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { VendorReportEmailedActivity } from '@/graphql/generated/types';
import { fullName } from '@/app.utils/services/Helpers';
import { trackHandler, events } from '@/app.utils/analytics';
import { ContactLink, PropertyLink } from './elements/elements';
import ActivityCardWithAction from './ActivityCardWithAction';

export default function VendorReportEmailedActivityCard({
    activity,
    viewAs,
}: {
    activity: VendorReportEmailedActivity;
    viewAs: 'property' | 'contact';
}) {
    const title =
        viewAs === 'property' ? (
            <span>
                <FormattedMessage
                    id="interaction.vendor_report_sent.parameterised"
                    defaultMessage="Vendor report sent to {contact}"
                    values={{
                        contact: (
                            <ContactLink
                                contact={activity.sentTo}
                                onClick={trackHandler(events.ACTIVITY_CARD_CONTACT_LINK_CLICKED, {
                                    activityType: activity.__typename,
                                })}
                            />
                        ),
                    }}
                />
            </span>
        ) : (
            <span>
                <FormattedMessage
                    id="interaction.vendor_report_sent.about.parameterised"
                    defaultMessage="Vendor report sent about {location}"
                    values={{ location: activity.about && <PropertyLink property={activity.about!} /> }}
                />
            </span>
        );

    const sentByText = (
        <FormattedMessage
            id="interaction.vendor_report_sent_by"
            defaultMessage="Vendor report sent by {negotiator}"
            values={{ negotiator: fullName(activity.sentBy.firstName, activity.sentBy.lastName) }}
        />
    );

    const subtitle =
        viewAs === 'property' ? (
            <>
                {activity.about!.attributes.location.formatted ?? (
                    <FormattedMessage id="address.no_location_defined" defaultMessage="No location defined" />
                )}
                {' — '}
                {sentByText}
            </>
        ) : (
            <>
                {`${fullName(activity.sentTo.firstName, activity.sentTo.lastName)} - `}
                {sentByText}
            </>
        );

    return (
        <ActivityCardWithAction
            icon="description"
            title={title}
            subtitle={subtitle}
            date={activity.timestamp}
            activity={activity}
        />
    );
}
