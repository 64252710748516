import { IntercomAPI } from 'react-intercom';
import { fork, select, put, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { getIntercomValues } from '../selectors/UsersSelectors';
import { resetMessages } from '../actions';

export function* onLocationChange() {
    // reset messages when page changes
    yield put(resetMessages());

    const user = yield select(getIntercomValues);
    if (user) {
        IntercomAPI('update', user);
    }
}

export default function* RoutingSaga() {
    yield [fork(takeEvery, LOCATION_CHANGE, onLocationChange)];
}
