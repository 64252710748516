// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { LoadingIndicator } from '@sweepbright/uikit';
import { useToasts } from '@sweepbright/notifications';
import { FormattedMessage } from 'react-intl-sweepbright';
import useRouter from '@/app.hooks/useRouter';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import EmptyBlock from '@/app.components/empty/EmptyBlock';
import OpenHome from '@/app.components/forms/OpenHomeV2';
import { deleteOpenHomeMutation, fetchOpenHomes, upsertOpenHomeMutation } from './visits_requests';

export function OpenHomesPanel({ propertyId, disabled }: { propertyId: string; disabled?: boolean }) {
    const cache = useQueryCache();
    const activeEventId = useRouter()?.location?.query?.eventId;
    const { data, isLoading } = useQuery([`/properties/${propertyId}/open-homes`, propertyId], fetchOpenHomes);
    const { addSuccess, addError } = useToasts();
    const [adding, setAdding] = React.useState(false);
    const [upsertOpenHome] = useMutation(
        (values: { id?: string; start: number; end: number; date: string }) =>
            upsertOpenHomeMutation(propertyId, values),
        {
            onSuccess: () => {
                cache.refetchQueries(`/properties/${propertyId}/open-homes`);
                setAdding(false);
            },
            throwOnError: true,
        },
    );

    const [deleteOpenHome] = useMutation((id: string) => deleteOpenHomeMutation(propertyId, id), {
        onSuccess: () => {
            cache.refetchQueries(`/properties/${propertyId}/open-homes`);
        },
        throwOnError: true,
    });

    const handleChangeOpenHome = React.useCallback(
        ({ id, ...values }) => {
            return upsertOpenHome({ id, ...values })
                .then(() => {
                    if (id) {
                        addSuccess({
                            message: (
                                <FormattedMessage
                                    id="pages.properties.oh_panel.open_home_updated"
                                    defaultMessage="Open home updated"
                                />
                            ),
                        });
                    } else {
                        addSuccess({
                            message: (
                                <FormattedMessage
                                    id="pages.properties.oh_panel.open_home_added"
                                    defaultMessage="Open home added"
                                />
                            ),
                        });
                    }
                })
                .catch(err => {
                    const isForbidden = err?.message?.includes('403');

                    addError({
                        message: isForbidden ? (
                            <FormattedMessage id="unauthorised_403" />
                        ) : (
                            <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                        ),
                    });
                });
        },
        [upsertOpenHome, addSuccess, addError],
    );

    // sort by created at to maintain order in list when an update is done
    const openHomes = _.orderBy(data ?? [], ['createdAt']);

    return (
        <FormPanel
            title={<FormattedMessage id="pages.properties.oh_panel.title" defaultMessage="Open Home Schedule" />}
            action={
                !isLoading && <FormattedMessage id="pages.properties.oh_panel.action" defaultMessage="Add Open Home" />
            }
            onAction={() => {
                setAdding(true);
            }}
            disableAction={disabled || adding}
        >
            <div className="divide-y divide-gray-lighter mb-2">
                {openHomes.map(openHome => {
                    return (
                        <OpenHome
                            disabled={disabled}
                            key={openHome.id}
                            id={openHome.id}
                            date={openHome.date}
                            start={openHome.start}
                            end={openHome.end}
                            onDelete={() =>
                                deleteOpenHome(openHome.id)
                                    .then(() => {
                                        addSuccess({
                                            message: (
                                                <FormattedMessage
                                                    id="pages.properties.oh_panel.open_home_deleted"
                                                    defaultMessage="Open home deleted"
                                                />
                                            ),
                                        });
                                    })
                                    .catch(err => {
                                        const isForbidden = err?.message?.includes('403');

                                        addError({
                                            message: isForbidden ? (
                                                <FormattedMessage id="unauthorised_403" />
                                            ) : (
                                                <FormattedMessage
                                                    id="form.status.error"
                                                    defaultMessage="Could not save"
                                                />
                                            ),
                                        });
                                    })
                            }
                            onChange={handleChangeOpenHome}
                            startEditing={openHome.id === activeEventId}
                        />
                    );
                })}
            </div>
            {isLoading && <LoadingIndicator />}
            {!adding && data?.length === 0 && !isLoading && (
                <EmptyBlock
                    text={
                        <FormattedMessage
                            id="forms.actions.estate.openhome_empty"
                            defaultMessage="No open home added"
                        />
                    }
                />
            )}
            {adding && <OpenHome onDelete={() => setAdding(false)} onChange={handleChangeOpenHome} />}
        </FormPanel>
    );
}
