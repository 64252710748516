import moment from 'moment';
import React from 'react';
import { EventCard } from './EventCard';

export function DayEvents({ day, events, onDeleteEvent, onUpdateEvent, flagsData = [] }) {
    return (
        <div className="mt-5">
            <div className="uppercase border-b border-solid border-gray-lighter text-xs text-gray-mid leading-loose">
                {moment(day).format('dddd, DD MMMM')}
            </div>
            <ol className="list-none p-0 m-0">
                {events.map(event => {
                    const view = flagsData.find((el: { requestId: string }) => el.requestId === `${event.id}-view`);
                    const edit = flagsData.find((el: { requestId: string }) => el.requestId === `${event.id}-edit`);

                    // @ts-ignore
                    const eventView = view?.allowed;
                    // @ts-ignore
                    const eventEdit = edit?.allowed;

                    return (
                        <li key={event.id}>
                            <EventCard
                                event={event}
                                onDelete={() => onDeleteEvent(event)}
                                onUpdate={onUpdateEvent}
                                viewDisabled={eventView === false}
                                editDisabled={eventEdit === false}
                            />
                        </li>
                    );
                })}
            </ol>
        </div>
    );
}
