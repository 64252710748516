import gql from 'graphql-tag';
import locationPreference from '@/graphql/fragments/locationPreference';

export const CLONE_LOCATION_TEMPLATE = gql`
    mutation cloneLocationTemplate(
        $officeId: ID!
        $locationTemplateId: ID!
        $preferenceId: ID
        $leadId: ID!
        $includeLocationData: Boolean! = true
    ) {
        cloneLocationPreferenceTemplate(
            input: {
                officeId: $officeId
                locationId: $locationTemplateId
                leadId: $leadId
                preferenceId: $preferenceId
            }
        ) {
            locationPreference {
                ...locationPreference
            }
        }
    }
    ${locationPreference}
`;
