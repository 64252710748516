import PropTypes from 'prop-types';
import React from 'react';

const MailAddress = ({ value }) => <a href={`mailto:${value}`}>{value}</a>;

MailAddress.propTypes = {
    value: PropTypes.string,
};

export default MailAddress;
