import gql from 'graphql-tag';

export const GET_CONTACT_LABELS = gql`
    query GetContactLabels($id: ID!) {
        contactLabels(id: $id) {
            id
            name
            color
            description
            created_at
            updated_at
        }
    }
`;
