import React from 'react';
import { Route, IndexRedirect } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { CompanyAdmin } from '../Middlewares';

import * as Pages from '../allPagesComponents';
import * as Routes from '../routes';

export default function companyRoutes() {
    return (
        <Route path={Routes.COMPANY} component={CompanyAdmin(Pages.Company)}>
            <IndexRedirect to="settings" />
            <Route
                path="settings"
                // @ts-ignore
                title={<FormattedMessage id="titles.company.settings" defaultMessage="Company settings" />}
                component={Pages.CompanySettings}
            />
            <Route path="labels" component={Pages.CompanyLabels} />
        </Route>
    );
}
