import { Lead, LeadPreferences } from '@/app.data/Leads';

export function sanitizeLeads(leads: Array<Partial<Lead>>) {
    return leads.map(sanitizeLead);
}

export function sanitizeLead(lead: Lead): Lead {
    return {
        ...lead,
        first_name: (lead.first_name ?? '').trim(),
        last_name: lead.last_name.trim(),
        preferences: sanitizeLeadPreferences(lead.preferences),
    };
}

export function sanitizeLeadPreferences(preferences: LeadPreferences): LeadPreferences {
    return {
        ...preferences,
        types: preferences.types || [],
    };
}
