import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import React, { FC, useCallback, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import Icon from '@/app.components/icons/Icon';

const appear = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const Appear = styled.div`
    fill: #47c9ad;
    animation: ${appear} 0.7s ease;
`;

type Props = {
    value: string;
    iconName?: string;
    className?: string;
    title?: string;
};

export const CopyButton: FC<Props> = props => {
    const { value, iconName, className, title } = props;

    const [copyResult, copy] = useCopyToClipboard();
    const [clickedRecently, setClickedRecently] = useState(false);
    const handleCopyClick = useCallback(() => {
        copy(value);
        setClickedRecently(true);
        setTimeout(() => {
            setClickedRecently(false);
        }, 5000);
    }, [copy, value]);

    const shouldShowSuccess = !!(clickedRecently && copyResult.value);

    const icon = shouldShowSuccess ? (
        <Appear>
            <Icon icon="check" />
        </Appear>
    ) : (
        <Icon icon={iconName || 'copy'} />
    );

    return (
        <div title={title} className={className} onClick={handleCopyClick}>
            {icon}
        </div>
    );
};
