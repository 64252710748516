import gql from 'graphql-tag';
import timeline from '@/graphql/fragments/timeline';

export const GET_CONTACT_TIMELINE_QUERY = gql`
    query GetContactTimeline($id: ID!, $propertyId: String, $after: String, $before: String) {
        contact(id: $id) {
            id
            timeline {
                activities(after: $after, before: $before, propertyId: $propertyId) {
                    ...timelineActivities
                }
            }
        }
    }
    ${timeline}
`;
