import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Link from '@/app.components/elements/Link';
import { PROPERTY_UNITS } from '@/app.routing/routes';
import { PublicationErrorDetailsItemLayout } from '@/app.domains/properties/components/PublicationErrorDetailsItemLayout';

export default function ProjectPublicationWarning({
    projectId,
    action = 'publish',
}: {
    projectId: string;
    action: 'publish' | 'unpublish';
}) {
    return (
        <div className="mb-4">
            <PublicationErrorDetailsItemLayout type="warning">
                {action === 'publish' ? (
                    <FormattedMessage
                        id="publish.project.publishing_description"
                        defaultMessage="Publishing this project will publish its <a>units</a>"
                        values={{
                            // eslint-disable-next-line react/display-name
                            a: chunk => <Link to={PROPERTY_UNITS(projectId)}>{chunk}</Link>,
                        }}
                    />
                ) : (
                    <FormattedMessage
                        id="publish.project.unpublishing_description"
                        defaultMessage="Unpublishing this project will unpublish its <a>units</a>"
                        values={{
                            // eslint-disable-next-line react/display-name
                            a: chunk => <Link to={PROPERTY_UNITS(projectId)}>{chunk}</Link>,
                        }}
                    />
                )}
            </PublicationErrorDetailsItemLayout>
        </div>
    );
}
