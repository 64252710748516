import React from 'react';
import './Timeline.scss';

export default function Timeline({ title, children }) {
    return (
        <div className="c-timeline">
            <TimelineHeader title={title} />
            <TimelineBody>{children}</TimelineBody>
        </div>
    );
}

const TimelineHeader = ({ title }) => {
    return <div className="c-timeline-header">{title && <h4 className="c-timeline__title">{title}</h4>}</div>;
};

const TimelineBody = ({ children }) => {
    return <div className="c-timeline-body">{children}</div>;
};
