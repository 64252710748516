import React from 'react';
import { connect } from 'react-redux';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import { Formik, Field } from 'formik';
import wrapWithPromise from '@/app.utils/services/wrapWithPromise';
import Modal from '@/app.components/elements/Modal';
import ConditionSelector from '@/app.components/forms/ConditionSelector';
import WithPrivateTooltip from '@/app.components/forms/WithPrivateTooltip';
import Button from '@/app.components/elements/Buttons/Button';
import { USER_CONDITIONS } from '@/app.data/Conditions';
import { FormattedMessage } from '@/app.utils/services/Helpers/reactIntlWrapper';
import { InputForFormik } from '@/app.components/forms/Input/Input';
import { FeedbackPayload } from '@/requests/InteractionsRequests';
import Icon from '@/app.components/icons/Icon';
import useProperty from '@/app.hooks/useProperty';
import useOffice from '@/app.hooks/useOffice';
import { useEstateVendors } from '@/app.hooks/useEstateVendors';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { registerInteractionFeedback } from '../../app.redux/actions/InteractionActions';

type Props = {
    close: () => void;
    interactionId: string;
    onAddedFeedback: (attributes: FeedbackPayload) => void;
    registerInteractionFeedback: (interactionId: string, attributes: any) => void;
    propertyId?: string;
};

const InteractionFeedbackModal: React.FunctionComponent<Props> = props => {
    const {
        onAddedFeedback,
        // eslint-disable-next-line
        registerInteractionFeedback,
        interactionId,
        propertyId,
    } = props;

    const { vendors, loading } = useEstateVendors(propertyId);

    const showPropertyShared = useFeatureFlag('property.shared.enabled');

    const { property } = useProperty(propertyId);

    const office = useOffice();
    const officeId = office?.get('id');

    const isShared =
        showPropertyShared && property?.visibility === 'company' && officeId ? property?.office_id !== officeId : false;

    const onSubmit = async (payload: FeedbackPayload) => {
        // Create interaction
        // @ts-ignore
        await wrapWithPromise(registerInteractionFeedback)({ interactionId, payload });
        onAddedFeedback(payload);
        props.close();
    };

    const hasVendors = vendors?.length > 0;

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                type: 'lead_requested_info',
                external_comments: '',
                internal_comments: '',
                send_to_vendor: false,
            }}
        >
            {({ handleSubmit, isSubmitting }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Field name="type">
                                {({ field }) => <ConditionSelector field={field} type={USER_CONDITIONS} icon="face" />}
                            </Field>
                            {!isShared && (
                                <InputForFormik
                                    name="external_comments"
                                    type="autosize-textarea"
                                    rows={5}
                                    label={
                                        <FormattedMessage
                                            id="misc.comment_for_vendor"
                                            defaultMessage="Comment for Owner"
                                        />
                                    }
                                    help={
                                        <FormattedMessage
                                            id="misc.vendor_report.subtitle"
                                            defaultMessage="We will show this text in the Vendor Report"
                                        />
                                    }
                                />
                            )}
                            <InputForFormik
                                name="internal_comments"
                                type="autosize-textarea"
                                rows={5}
                                label={
                                    <WithPrivateTooltip>
                                        <FormattedMessage id="misc.comment" defaultMessage="Comment" />
                                    </WithPrivateTooltip>
                                }
                            />
                            {!isShared && (
                                <div className="flex items-center">
                                    <InputForFormik
                                        name="send_to_vendor"
                                        type="checkbox"
                                        inline
                                        disabled={!hasVendors}
                                        label={
                                            <FormattedMessage
                                                id="modals.interaction_feedback.send_to_vendor"
                                                defaultMessage="Send to vendor now"
                                            />
                                        }
                                        className="min-h-0"
                                    />
                                    {!hasVendors && (
                                        <Tooltip
                                            label={
                                                <FormattedMessage
                                                    id="modals.interaction_feedback.no_vendors_assigned"
                                                    defaultMessage="No vendors assigned to this property yet"
                                                />
                                            }
                                        >
                                            <Icon icon="help" className="ml-1 text-muted: " />
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button onClick={props.close}>
                                <FormattedMessage id="modals.common.actions.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                data-testid="submit-btn"
                                disabled={loading || isSubmitting}
                            >
                                {isSubmitting ? (
                                    <FormattedMessage
                                        id="modals.interaction_feedback.registering"
                                        defaultMessage="Registering..."
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="activity.register_feedback"
                                        defaultMessage="Register Feedback"
                                    />
                                )}
                            </Button>
                        </Modal.Footer>
                    </form>
                );
            }}
        </Formik>
    );
};

// @ts-ignore
export default connect(null, { registerInteractionFeedback })(InteractionFeedbackModal);
