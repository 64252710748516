import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import useProperty from '@/app.hooks/useProperty';
import ProjectPublicationWarning from '@/app.components/modals/PublicationConfig/ProjectPublicationWarning';

export default function ConfirmUnpublishModal({
    show,
    accountName,
    onCancel,
    onConfirm,
    propertyId,
}: {
    propertyId: string;
    show: boolean;
    accountName: string;
    onCancel: () => void;
    onConfirm: () => void;
}) {
    const { property } = useProperty(propertyId);

    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage
                        id="publish.unpublishing_property.title"
                        defaultMessage="Unpublish property from {accountName}"
                        values={{ accountName }}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {property?.isProject && <ProjectPublicationWarning projectId={propertyId} action="unpublish" />}
                <p>
                    <FormattedMessage
                        id="publish.unpublishing_property.disclaimer"
                        defaultMessage="Are you sure that you want to unpublish your property from {accountName}? Your publication will no
                    longer be visible to your audience. This action can’t be undone."
                        values={{ accountName }}
                    />
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancel}>
                    <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={onConfirm}>
                    <FormattedMessage id="publish.action.unpublish" defaultMessage="Unpublish" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
