import { fromJS } from 'immutable';

export default class NegotiatorRepository {
    negotiator = fromJS({});

    constructor(negotiator) {
        this.negotiator = fromJS(negotiator ?? {});
    }

    getLabel({ isCurrentUser, intl } = {}) {
        const me = isCurrentUser ? ` (${intl.formatMessage({ id: 'me', defaultMessage: 'Me' })})` : '';

        if (
            !(this.negotiator.get('first_name') || this.negotiator.get('firstName')) &&
            !(this.negotiator.get('last_name') || this.negotiator.get('lastName'))
        ) {
            return this.negotiator.get('email');
        }

        return `${[
            this.negotiator.get('first_name') || this.negotiator.get('firstName'),
            this.negotiator.get('last_name') || this.negotiator.get('lastName'),
        ].join(' ')}${me}`;
    }
}
