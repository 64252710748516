import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { PROPERTY_CONDITION_OPTIONS } from '@/app.data/Conditions';
import { propertyTypes as allPropertyTypes } from '@/app.data/Properties';
import { getPropertiesAmenitiesGroups, getPropertiesSubtypesGroups } from '@/new.domains/properties/utils';

import ResourceFilter from '@/shared/resource/ResourceFilter';

import { ContactsOwnershipFilter } from '@/app.domains/contacts/filters/ContactsOwnershipFilter';
import FilterNegotiators from '@/shared/filter/FilterNegotiators';
import PropertyFilterAmenities from '@/new.domains/properties/PropertyFilter/PropertyFilterAmenities';
import FilterLabels from '@/shared/filter/FilterLabels';

export const useContactsFilters = (searchSettings: any | undefined, setSearchSettings: Function) => {
    const showLabels = useFeatureFlag('contact.labels.enabled');
    const showNewAssigneeFilter = useFeatureFlag('filters.assignee');
    const showNewFilters = useFeatureFlag('contacts.new.filters.enabled');
    const showAreas = useFeatureFlag('contact.preferences.areas.enabled');
    const showPropertyFeatures = useFeatureFlag('property.features.filter.enabled');

    const filters = useMemo(
        () => (
            <>
                <ResourceFilter
                    id="type"
                    domainType="contacts"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'radio',
                            paramName: 'type',
                            options: [
                                { value: undefined, key: 'all' },
                                { value: 'lead', key: 'lead' },
                                { value: 'vendor', key: 'vendor' },
                            ],
                        },
                    ]}
                />

                {showNewAssigneeFilter ? (
                    <FilterNegotiators
                        showUnassignedOption
                        domainType="contacts"
                        searchSettings={searchSettings}
                        setSearchSettings={setSearchSettings}
                    />
                ) : (
                    <ContactsOwnershipFilter
                        {...({} as any)}
                        values={{
                            negotiator: searchSettings.negotiator,
                            noNegotiator: searchSettings.noNegotiator,
                        }}
                        onChange={changedSettings => {
                            setSearchSettings({ ...searchSettings, ...changedSettings });
                        }}
                    />
                )}

                {showLabels && (
                    <FilterLabels
                        domainType="contacts"
                        searchSettings={searchSettings}
                        translationDomainType="contacts"
                        setSearchSettings={setSearchSettings}
                    />
                )}

                <ResourceFilter
                    id="offer_status"
                    domainType="contacts"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    translationDomainType="properties"
                    filters={[
                        {
                            type: 'radio',
                            paramName: 'offer_status',
                            options: [
                                { value: undefined, key: 'all' },
                                { value: 'accepted', key: 'accepted', displayKey: true },
                                { value: 'pending', key: 'pending', displayKey: true },
                            ],
                        },
                    ]}
                />

                <ResourceFilter
                    id="negotiation"
                    domainType="contacts"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'radio',
                            paramName: 'negotiation',
                            simpleOptions: ['let', 'sale'],
                        },
                    ]}
                />

                <ResourceFilter
                    prefix="euro"
                    id="price_point"
                    domainType="contacts"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'number',
                            paramName: 'price_point',
                        },
                    ]}
                />

                {showNewFilters && (
                    <>
                        <ResourceFilter
                            id="preference_type"
                            domainType="contacts"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'radio',
                                    paramName: 'preference_type',
                                    simpleOptions: [...allPropertyTypes],
                                },
                            ]}
                        />

                        <ResourceFilter
                            domainType="contacts"
                            id="preference_subtype"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'radio',
                                    overflow: true,
                                    paramName: 'preference_subtype',
                                    groupOptions: getPropertiesSubtypesGroups(),
                                },
                            ]}
                        />
                    </>
                )}

                {showAreas && (
                    <>
                        <ResourceFilter
                            prefix="sq_m"
                            id="liveable_area"
                            domainType="contacts"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'number',
                                    paramName: 'preferenceLiveableArea',
                                },
                            ]}
                        />

                        <ResourceFilter
                            id="net_area"
                            prefix="sq_m"
                            domainType="contacts"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'number',
                                    paramName: 'preferenceNetArea',
                                },
                            ]}
                        />

                        <ResourceFilter
                            prefix="sq_m"
                            id="plot_area"
                            domainType="contacts"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'number',
                                    paramName: 'preferencePlotArea',
                                },
                            ]}
                        />

                        <ResourceFilter
                            prefix="sq_m"
                            id="gross_area"
                            domainType="contacts"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'number',
                                    paramName: 'preferenceGrossArea',
                                },
                            ]}
                        />
                    </>
                )}

                {showNewFilters && (
                    <>
                        <ResourceFilter
                            id="condition"
                            domainType="contacts"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            translationDomainType="properties"
                            filters={[
                                {
                                    type: 'radio',
                                    paramName: 'preference_min_condition',
                                    simpleOptions: [...PROPERTY_CONDITION_OPTIONS],
                                },
                            ]}
                        />

                        <ResourceFilter
                            domainType="contacts"
                            params={searchSettings}
                            id="preference_postal_code"
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'text',
                                    paramName: 'preference_postal_code',
                                },
                            ]}
                        />

                        <ResourceFilter
                            id="rooms"
                            domainType="contacts"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'number',
                                    showTitle: true,
                                    paramName: 'preference_min_bedrooms',
                                },
                            ]}
                        />

                        {showPropertyFeatures ? (
                            <ResourceFilter
                                showSearch
                                id="amenities"
                                domainType="contacts"
                                params={searchSettings}
                                setParams={setSearchSettings}
                                translationDomainType="properties"
                                filters={[
                                    {
                                        type: 'checkbox',
                                        overflow: true,
                                        allowEmpty: true,
                                        hideOnlyButton: true,
                                        paramName: 'preference_amenity',
                                        groupOptions: getPropertiesAmenitiesGroups(),
                                    },
                                ]}
                            />
                        ) : (
                            <PropertyFilterAmenities
                                // @ts-ignore
                                domainType="contacts"
                                // @ts-ignore
                                values={searchSettings}
                                onChange={changedSettings => {
                                    // @ts-ignore
                                    setSearchSettings({ ...searchSettings, ...changedSettings });
                                }}
                                title={<FormattedMessage id="properties.filter.amenities" defaultMessage="Amenities" />}
                            />
                        )}
                    </>
                )}

                <ResourceFilter
                    id="archived"
                    domainType="contacts"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'boolean',
                            displayKey: true,
                            paramName: 'archived',
                        },
                    ]}
                />
            </>
        ),
        [
            searchSettings,
            setSearchSettings,
            showLabels,
            showPropertyFeatures,
            showNewAssigneeFilter,
            showNewFilters,
            showAreas,
        ],
    );

    return filters;
};
