import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Shimmer } from '@sweepbright/uikit';
import { injectIntl, InjectedIntl } from 'react-intl-sweepbright';
import useUser from '@/app.hooks/useUser';
import NegotiatorRepository from '@/app.utils/services/Repositories/NegotiatorRepository';
import Icon from '../../app.components/icons/Icon';

const PropertyNegotiator = ({ propertyId, intl }: { propertyId: string; intl: InjectedIntl }) => {
    const user = useUser();
    const { negotiator, loading } = useEstateNegotiator(propertyId);

    if (!negotiator) {
        return <Shimmer width="100px" />;
    }

    const label =
        new NegotiatorRepository(negotiator).getLabel({
            isCurrentUser: negotiator?.id === user.get('id'),
            intl,
        }) ||
        (negotiator?.errorStatus && intl.formatMessage({ id: `property.negotiator.${negotiator.errorStatus}` }));

    return (
        <Shimmer isDataLoaded={!loading} width="100px">
            <div className="flex items-center m-0 w-full min-w-0 leading-none">
                <Icon icon="puppet" className="text-muted" />
                <small className="text-muted block truncate">{label}</small>
            </div>
        </Shimmer>
    );
};

export default React.memo(injectIntl(PropertyNegotiator));

export const PROPERTY_NEGOTIATOR_QUERY = gql`
    query GetPropertyNegotiator($estateId: ID!) {
        estate(id: $estateId) {
            id
            negotiator {
                id
                firstName
                lastName
                errorStatus
            }
        }
    }
`;

const ASSIGN_ESTATE_TO_NEGOTIATOR_MUTATION = gql`
    mutation AssignEstateToNegotiator($input: AssignEstatesToNegotiatorInput!) {
        assignEstatesToNegotiator(input: $input) {
            success
        }
    }
`;

export function useEstateNegotiator(estateId?: string) {
    const { data, loading } = useQuery(PROPERTY_NEGOTIATOR_QUERY, {
        variables: {
            estateId,
        },
        skip: !estateId,
    });

    const [assign, { loading: assigning }] = useMutation(ASSIGN_ESTATE_TO_NEGOTIATOR_MUTATION);

    const setNegotiator = React.useCallback(
        (userId: string) => {
            return assign({
                variables: {
                    input: {
                        estateIds: [estateId],
                        userId,
                    },
                },
                refetchQueries: [`GetPropertyNegotiator`],
                awaitRefetchQueries: true,
            });
        },
        [estateId, assign],
    );

    const negotiator = data?.estate?.negotiator;

    return { negotiator, loading: !estateId || loading || assigning, setNegotiator };
}
