// @ts-nocheck
import React from 'react';
import { InputProps } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import { ALLOWED_PRICE_RANGE, statusTypes as availableStatuses } from '@/app.data/Properties';
import useOffice from '@/app.hooks/useOffice';
import { withErrorBoundary } from '../../app.components/errors/ErrorBoundary';
import FormPanel from '../../app.components/forms/FormPanel/FormPanel';
import Input from '../../app.components/forms/Input/Input';
import PropertyTypesSectionForm from '../../app.components/forms/PropertyTypesSection';
import StatusLabel from '../../app.components/forms/StatusLabel';
import PriceRangeForm from '../../app.components/forms/elements/PriceRangeForm';
import NegotiatorSelectorField from '../../app.components/forms/elements/NegotiatorSelectorField';
import PropertyTypesSection from '../../app.components/forms/elements/PropertyTypesSection';

const PropertiesFiltersSettings = ({
    fields,
    onFilterBySaleStatus,
    excludeProjects,
    showArchivedFilter,
}: {
    excludeProjects?: boolean;
    fields: {
        archived: InputProps;
        filterByStatus: InputProps;
        max_price: InputProps;
        min_price: InputProps;
        negotiation: InputProps;
        negotiator: InputProps;
        ownership: InputProps;
        property_type: InputProps;
        status: InputProps;
        type: InputProps;
    };
    onFilterBySaleStatus: (evt: React.FormEvent<HTMLInputElement>) => void;
    showArchivedFilter: boolean;
}) => {
    const office = useOffice();

    return (
        <div>
            <FormPanel
                title={
                    <FormattedMessage id="modals.properties_search.ownership_pane.title" defaultMessage="Ownership" />
                }
            >
                <Input
                    label={
                        <FormattedMessage
                            id="modals.properties_search.ownership_pane.all_label"
                            defaultMessage="All Properties"
                        />
                    }
                    type="radio"
                    {...fields.ownership}
                    onChange={evt => {
                        fields.ownership.onChange(evt);
                        fields.negotiator.onChange('');
                    }}
                    value="all"
                    checked={fields.ownership.value === 'all'}
                />
                <Input
                    label={
                        <FormattedMessage
                            id="modals.properties_search.ownership_pane.mine_label"
                            defaultMessage="My Properties"
                        />
                    }
                    type="radio"
                    {...fields.ownership}
                    value="mine"
                    checked={fields.ownership.value === 'mine'}
                />
                <Input
                    type="radio"
                    label={
                        <FormattedMessage
                            id="modals.properties_search.ownership_pane.with_negotiator_label"
                            defaultMessage="Choose Negotiator"
                        />
                    }
                    {...fields.ownership}
                    value="with_negotiator"
                    checked={fields.ownership.value === 'with_negotiator'}
                />
                {fields.ownership.value === 'with_negotiator' ? (
                    <NegotiatorSelectorField field={fields.negotiator} officeId={office.get('id')} />
                ) : null}
            </FormPanel>
            <FormPanel
                title={
                    <FormattedMessage
                        id="modals.properties_search.sale_status_pane.title"
                        defaultMessage="Sale status"
                    />
                }
            >
                <div className="form-group">
                    <Input
                        label={
                            <FormattedMessage
                                id="modals.properties_search.sale_status_pane.by_status_label"
                                defaultMessage="Filter by Status"
                            />
                        }
                        type="checkbox"
                        {...fields.filterByStatus}
                        onChange={onFilterBySaleStatus}
                    />
                </div>
                <div className="form-group">
                    {fields.filterByStatus.value &&
                        availableStatuses.map(status => (
                            <Input
                                key={status}
                                type="checkbox"
                                label={<StatusLabel status={status} negotiation="all" />}
                                {...fields.status[status]}
                            />
                        ))}
                </div>
            </FormPanel>
            <FormPanel
                title={
                    <FormattedMessage
                        id="modals.properties_search.negotiation_type_pane.title"
                        defaultMessage="To Let / For sale"
                    />
                }
            >
                <Input
                    type="radio"
                    label={
                        <FormattedMessage
                            id="modals.properties_search.negotiation_type_pane.all_label"
                            defaultMessage="All"
                        />
                    }
                    {...fields.negotiation}
                    value="all"
                    checked={fields.negotiation.value === 'all'}
                />

                <Input
                    type="radio"
                    label={
                        <FormattedMessage
                            id="modals.properties_search.negotiation_type_pane.let_label"
                            defaultMessage="To Let"
                        />
                    }
                    {...fields.negotiation}
                    value="let"
                    checked={fields.negotiation.value === 'let'}
                />

                <Input
                    type="radio"
                    label={
                        <FormattedMessage
                            id="modals.properties_search.negotiation_type_pane.sale_label"
                            defaultMessage="For Sale"
                        />
                    }
                    {...fields.negotiation}
                    value="sale"
                    checked={fields.negotiation.value === 'sale'}
                />
            </FormPanel>
            <PriceRangeForm
                minPriceField={fields.min_price}
                maxPriceField={fields.max_price}
                negotiationType={fields.negotiation.value || 'all'}
                allowedRange={[ALLOWED_PRICE_RANGE.min_price, ALLOWED_PRICE_RANGE.max_price]}
            />
            <PropertyTypesSection fields={fields} excludeProjects={excludeProjects} />
            <PropertyTypesSectionForm columns={1} types={fields.type} />
            {showArchivedFilter && (
                <FormPanel
                    title={
                        <FormattedMessage id="modals.properties_search.archived_pane.title" defaultMessage="Archived" />
                    }
                >
                    <Input
                        label={
                            <FormattedMessage
                                id="modals.properties_search.archived_pane.archived_label"
                                defaultMessage="Show archived"
                            />
                        }
                        type="checkbox"
                        {...fields.archived}
                    />
                </FormPanel>
            )}
        </div>
    );
};

export default withErrorBoundary(PropertiesFiltersSettings);
