import classnames from 'classnames';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import thumbnail from '@/../../resources/assets/img/empty-document.png';
import FileRepository from '@/app.utils/services/Repositories/FileRepository';
import IconButton from '../elements/Buttons/IconButton';
import WithPrivateTooltip from '../forms/WithPrivateTooltip';
import ImageLoader from '../images/ImageLoader';
import Progress from '../elements/Progress';
import MediaCard from './MediaCard/MediaCard';

const DocumentCard = ({ document, property, onClick, type, unread, testId }) => {
    const repository = new FileRepository(property.get('id'), document);

    const title = (
        <span className={classnames({ unread })}>
            {repository.isPrivate() ? (
                <WithPrivateTooltip position="right">{repository.getName()} </WithPrivateTooltip>
            ) : (
                repository.getName()
            )}
        </span>
    );

    const subtitles = [
        <span className="text-muted" key="visibility">
            {repository.isPrivate() ? (
                <FormattedMessage id="document.private" defaultMessage="Private" />
            ) : (
                <FormattedMessage id="document.public" defaultMessage="Public" />
            )}
        </span>,
        <FormattedMessage
            key="uploaded_at"
            id="document.uploaded_at"
            defaultMessage="Added {timestamp}"
            values={{ timestamp: repository.getUploadedAt() }}
        />,
    ];

    const image = repository.isUploading() ? (
        <div className="c-square-thumbnail c-square-thumbnail-fixed-size c-square-thumbnail--spaced c-util-flex-block c-util-flex-block-align-middle bc-bg-gray-lightest text-center">
            <Progress value={repository.getProgress()} />
        </div>
    ) : (
        <div className="c-square-thumbnail c-square-thumbnail-fixed-size">
            <ImageLoader
                src={repository.getThumbnail(type, 'thumbnail', 'documents')}
                fallback={<img src={thumbnail} alt="" />}
            />
        </div>
    );

    return (
        <MediaCard
            image={image}
            title={title}
            subtitles={subtitles}
            icon="edit"
            actions={[
                <IconButton
                    disabled={repository.isUploading()}
                    key="edit"
                    icon="edit"
                    title={<FormattedMessage id="document.edit" defaultMessage="Edit document" />}
                    onClick={onClick}
                    testId={`edit-document-btn-${testId}`}
                />,
            ]}
        />
    );
};

DocumentCard.propTypes = {
    document: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    property: PropTypes.instanceOf(Map),
    type: PropTypes.string.isRequired,
    unread: PropTypes.bool,
};

DocumentCard.defaultProps = {
    unread: true,
};

export default DocumentCard;
