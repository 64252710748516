// @ts-nocheck
import React from 'react';
import matchSorter from 'match-sorter';
import { LoadingIndicator } from '@sweepbright/uikit';
import Button from '@sweepbright/uikit/build/esm/button';
import EmptyState from '@/app.components/empty/EmptyState';
import PagePane from '@/app.layouts/PagePane/PagePane';
import AdvancedSearch from '@/app.components/forms/Search/AdvancedSearch';
import useEstateChannelAccounts from '@/app.hooks/useEstateChannelAccounts';
import useField from '@/app.hooks/useField';
import { ChannelAccountCard } from '@/app.domains/properties/components/ChannelAccountCard';
import { EstateChannelAccountConnectionEdge, PublicationStatus } from '@/graphql/generated/types';
import { NewPublicationModal } from '@/app.domains/properties/components/NewPublicationModal/NewPublicationModal';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { FormattedMessage } from 'react-intl-sweepbright';
import useProperty from '@/app.hooks/useProperty';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { UnitPublishEmptyState } from '../../../app.domains/properties/UnitPublishEmptyState';

enum PublicationTab {
    Active,
    WithErrors,
}

export default function PropertyPublishPage({ params }) {
    const propertyId = params.unit || params.property;

    const queryField = useField('');
    const { property } = useProperty(propertyId);
    const [activeTab, setActiveTab] = React.useState(PublicationTab.Active);

    const [showPublicationModal, setShowPublicationModal] = React.useState(false);

    function onSubmit({ query }) {
        queryField.onChange(query);
    }

    const { channelAccountsConnection, loading, addScheduledPublication } = useEstateChannelAccounts(
        propertyId,
        false,
        true,
    );

    const unitsPublishEmptyStateShown = useFeatureFlag('units.publish.emptyState.shown');

    if (params.unit && unitsPublishEmptyStateShown) {
        return <UnitPublishEmptyState unitId={params.unit} />;
    }

    // eslint-disable-next-line no-use-before-define
    const channelAccountEdges: EstateChannelAccountConnectionEdge[] = (channelAccountsConnection?.edges ??
        []) as EstateChannelAccountConnectionEdge[];
    const errorCount = channelAccountEdges.filter(channelAccountEdgeHasError).length;

    let sortedEstateChannelAccounts = matchSorter(channelAccountEdges, queryField.value, {
        keys: ['node.name'],
    });

    const channelAccountsForActiveTab = sortedEstateChannelAccounts.filter(visibleInActiveTab);

    return (
        <PagePane
            title={<FormattedMessage id="publish.title" defaultMessage="Publish" />}
            actions={[
                <Button
                    key="publish"
                    variant="primary"
                    onClick={() => setShowPublicationModal(!showPublicationModal)}
                    disabled={!property || property.archived}
                >
                    {property?.archived ? (
                        <FormattedMessage id="property.status.archived" defaultMessage="Archived" />
                    ) : (
                        <FormattedMessage id="publish.create_publication" defaultMessage="Create Publication" />
                    )}
                </Button>,
            ]}
        >
            <div>
                <AdvancedSearch
                    initialTabIndex={activeTab}
                    settings={{ query: queryField.value }}
                    filtersTabs={[
                        {
                            id: 'publication_active',
                            label: <FormattedMessage id="integrations.status.connected" defaultMessage="Active" />,
                            filters: null,
                            onTabSelected() {
                                setActiveTab(PublicationTab.Active);
                            },
                        },
                        {
                            id: 'publication_errors',
                            label: (
                                <div>
                                    <FormattedMessage id="publish.errors.section.title" defaultMessage="Errors" />{' '}
                                    {errorCount > 0 && (
                                        <div className="h-5 bg-red-600 text-white rounded-full px-2 text-xs h-5 inline-flex items-center">
                                            {errorCount}
                                        </div>
                                    )}
                                </div>
                            ),
                            filters: null,
                            onTabSelected() {
                                setActiveTab(PublicationTab.WithErrors);
                            },
                        },
                    ]}
                    onSubmit={onSubmit}
                />
            </div>
            <div>
                <ul className="bc-bordered-list">
                    {channelAccountsForActiveTab.map(estateChannelAccountEdge => {
                        return (
                            <li key={estateChannelAccountEdge.id}>
                                <ErrorBoundary>
                                    <ChannelAccountCard
                                        onRetried={() => addScheduledPublication(estateChannelAccountEdge.id)}
                                        channelAccountId={estateChannelAccountEdge.node.id}
                                        propertyId={propertyId}
                                    />
                                </ErrorBoundary>
                            </li>
                        );
                    })}
                </ul>
                {activeTab === PublicationTab.Active && (
                    <>
                        {!loading && channelAccountsForActiveTab.length === 0 && (
                            <EmptyState
                                title={
                                    <FormattedMessage
                                        id="publish.empty_view.title"
                                        defaultMessage="Whoops, no active publications"
                                    />
                                }
                                body={
                                    <FormattedMessage
                                        id="publish.empty_view.description"
                                        defaultMessage="Active publications will show up here"
                                    />
                                }
                            />
                        )}
                    </>
                )}
                {activeTab === PublicationTab.WithErrors && (
                    <>
                        {!loading && channelAccountsForActiveTab.length === 0 && (
                            <EmptyState
                                title={
                                    <FormattedMessage
                                        id="publish.empty_errors.title"
                                        defaultMessage="No publication errors"
                                    />
                                }
                                body={
                                    <FormattedMessage
                                        id="publish.empty_errors.description"
                                        defaultMessage="Publication channels with errors will show up here"
                                    />
                                }
                            />
                        )}
                    </>
                )}
                {loading && <LoadingIndicator />}
            </div>
            <NewPublicationModal
                show={showPublicationModal}
                onCancel={() => {
                    setShowPublicationModal(false);
                }}
                propertyId={propertyId}
                onCompleted={(channelAccountEdgeId?: string) => {
                    setShowPublicationModal(false);
                    addScheduledPublication(channelAccountEdgeId);
                }}
            />
        </PagePane>
    );

    function visibleInActiveTab(channelAccountEdge: EstateChannelAccountConnectionEdge) {
        if (activeTab === PublicationTab.Active) {
            return (
                channelAccountEdge.publicationStatus !== PublicationStatus.NotPublished &&
                !channelAccountEdgeHasError(channelAccountEdge)
            );
        }

        if (activeTab === PublicationTab.WithErrors) {
            return channelAccountEdgeHasError(channelAccountEdge);
        }

        return false;
    }
}

function channelAccountEdgeHasError(channelAccountEdge: EstateChannelAccountConnectionEdge): boolean {
    // we consider as errors only publication errors
    return (
        channelAccountEdge.publicationStatus === PublicationStatus.PublishingFailed ||
        channelAccountEdge.publicationStatus === PublicationStatus.UnpublishingFailed
    );
}
