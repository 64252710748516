import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import MultiDragListBlock from './MultiDragListBlock';
import { move, reorder } from './utils';
import { MuliDragItem } from './types';

type Props = {
    labelFirst: string;
    labelSecond: string;
    itemsFirst: MuliDragItem[];
    setItemsFirst: (items: MuliDragItem[]) => void;
    itemsSecond: MuliDragItem[];
    setItemsSecond: (items: MuliDragItem[]) => void;
};

const MultiDragList: React.FC<Props> = props => {
    const { labelFirst, labelSecond, itemsFirst, setItemsFirst, itemsSecond, setItemsSecond } = props;

    const onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        if (sInd === dInd) {
            const activeItems = sInd === 0 ? [...itemsFirst] : [...itemsSecond];
            const reorderedItems: any[] = reorder(activeItems, source.index, destination.index);

            if (sInd === 0) {
                setItemsFirst([...reorderedItems]);
            } else {
                setItemsSecond([...reorderedItems]);
            }
        } else {
            const sourceItems = sInd === 0 ? [...itemsFirst] : [...itemsSecond];
            const destinationItems = dInd === 0 ? [...itemsFirst] : [...itemsSecond];

            const result = move(sourceItems, destinationItems, source, destination);

            setItemsFirst([...result[0]]);
            setItemsSecond([...result[1]]);
        }
    };

    return (
        <div className="c-multi-drag-list">
            <DragDropContext onDragEnd={onDragEnd}>
                {[itemsFirst, itemsSecond].map((el, index) => {
                    return (
                        <MultiDragListBlock
                            el={el}
                            key={index}
                            index={index}
                            label={index === 0 ? labelFirst : labelSecond}
                        />
                    );
                })}
            </DragDropContext>
        </div>
    );
};

export default MultiDragList;
