export const roles = {
    TeamMember: 'team-member',
    Editor: 'editor',
    TeamAdmin: 'team-admin',
    CompanyAdmin: 'company-admin',
};

export const isRole = (user, role) => {
    if (!user) {
        return false;
    }

    return user.get('role_id') === role;
};

export const isTeamMember = user => isRole(user, roles.TeamMember);
export const isEditor = user => isRole(user, roles.Editor);
export const isTeamAdmin = user => isRole(user, roles.TeamAdmin);
export const isCompanyAdmin = user => isRole(user, roles.CompanyAdmin);
