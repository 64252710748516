import gql from 'graphql-tag';

export const UPDATE_CONTACT_DETAILS_MUTATION = gql`
    mutation UpdateContactDetails($input: UpdateContactMutationInput!) {
        updateContactDetails(input: $input) {
            success
            validationErrors
            contact {
                id
                firstName
                lastName
                phone
                email
                company
                locale
                notes {
                    text
                }
                pronouns
            }
        }
    }
`;

export const UPDATE_CONTACT_ADDRESS_MUTATION = gql`
    mutation UpdateContactAddress($input: UpdateContactAddressMutationInput!) {
        updateContactAddress(input: $input) {
            contact {
                id
                address
            }
        }
    }
`;

export const ARCHIVE_CONTACT_MUTATION = gql`
    mutation ArchiveContact($contactId: ID!) {
        archiveContact(id: $contactId) {
            contact {
                id
                archived
            }
        }
    }
`;

export const UNARCHIVE_CONTACT_MUTATION = gql`
    mutation UnarchiveContact($contactId: ID!) {
        unarchiveContact(id: $contactId) {
            contact {
                id
                archived
            }
        }
    }
`;
