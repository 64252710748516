import React from 'react';
import { InjectedIntl } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl-sweepbright';
import Input from '@/app.components/forms/Input/Input';
import Select, { SelectVariant } from '@/app.components/selects/AdvancedSelect/Select';
import { AVAILABLE_USER_LOCALES_CODES } from '@/app.utils/services/Helpers/locales';

type Props = {
    value: string;
    name?: string;
    label?: React.ReactNode;
    onChange?: (event: any) => void;
    error?: any;
    touched?: boolean;
    variant?: SelectVariant;
    intl?: InjectedIntl;
};

const options = AVAILABLE_USER_LOCALES_CODES.map(locale => {
    return {
        value: locale,
        label: <FormattedMessage id={`general.locales.${locale}`} defaultMessage={`general.locales.${locale}`} />,
    };
});

function LanguageSelector({ value, error, name, onChange, label, variant, touched }: Props) {
    return (
        <Input label={label} error={error} name={name} touched={touched}>
            <Select
                onChange={onChange}
                value={value}
                clearable={false}
                options={options}
                id={name}
                name={name}
                variant={variant}
                testId="language-selector"
                simpleValue
                hasError={touched && error}
            />
        </Input>
    );
}

export default injectIntl(LanguageSelector);
