export * from './Energy';
export * from './Location';
export * from './Localization';
export * from './Settings';
export * from './Roles';
export * from './Subscriptions';
export * from './Currencies';
export * from './Properties';
export { default as conditions } from './Conditions';
export { default as contactTypes } from './ContactTypes';
export { default as orientations } from './Orientations';
export {
    default as roomTypes,
    roomTypesList,
    units,
    unitsBySystem,
    areas,
    areasByType,
    defaultUnit,
    roomsWithoutAreaLinePerType,
    roomsOrderPerType,
    getDefaultUnitForArea,
} from './Rooms';
export { default as shapesByType } from './Shapes';
export {
    default as interactions,
    INTERACTION_FEEDBACK_COLOR,
    interactionIcons,
    getInteractionTypeCount,
    interactionsForReport,
} from './Interactions';
export * from './Themes';

export const localizedEmailTypes = ['email_matching_lead_description', 'email_lead_visit', 'email_report_description'];

export const emailTypes = localizedEmailTypes.concat([
    'email_property_images_request',
    'email_property_documents_request',
    'email_property_plans_request',
]);

export const intercom = {
    appId: INTERCOM_APP_ID,
};

export const stripe = {
    publicKey: STRIPE_PUBLIC_KEY,
};

export const appEnv = {
    env: APP_ENV,
};
