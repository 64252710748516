// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';
import { Map } from 'immutable';
import { LoadingIndicator } from '@sweepbright/uikit';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { isLoading } from '@/app.utils/services/Helpers/pagination';

type Props = {
    onFetch: (attributes: Record<string, any>) => void;
    pagination: Map<string, number>;
    placeholder: any;
    properties: any[];
    renderItem: (property: Map<string, any>) => any;
    loading?: boolean;
};

export const PropertiesList: React.FunctionComponent<Props> = props => {
    const loading = props.loading ?? isLoading(props.pagination);
    const hasMore = props.pagination.get('current_page') < props.pagination.get('total_pages');

    return (
        <ItemsList
            loading={loading}
            onFetchMore={props.onFetch}
            items={props.properties}
            getItemKey={property => property.get('id')}
            renderItem={props.renderItem}
            hasMore={hasMore}
            placeholder={props.placeholder}
        />
    );
};

export const ItemsList: React.FunctionComponent<{
    loading: boolean;
    items: FixMeType[];
    hasMore: boolean;
    onFetchMore: () => void;
    renderItem: (item: FixMeType, idx: number) => JSX.Element;
    getItemKey: (item: FixMeType) => string;
    placeholder?: React.ReactNode;
    listComponent?: any;
    listItemComponent?: any;
    className?: string;
}> = ({
    items,
    loading,
    hasMore,
    renderItem,
    getItemKey,
    onFetchMore,
    placeholder,
    listComponent: List = 'ul',
    listItemComponent: ListItem = 'li',
    className,
}) => {
    return (
        <List className={classNames('bc-bordered-list', className)}>
            {!loading && items.length === 0 && placeholder}
            {items.map((item, idx) => {
                return (
                    <ListItem key={getItemKey(item)}>
                        <ErrorBoundary>{renderItem(item, idx)}</ErrorBoundary>
                    </ListItem>
                );
            })}
            {!loading && hasMore && <Waypoint onEnter={onFetchMore} />}
            {loading && (
                <div className="my-2">
                    <LoadingIndicator />
                </div>
            )}
        </List>
    );
};
