// @ts-nocheck
import React from 'react';
import Portal from '@reach/portal';
import { Manager, Popper as ReactPopper, PopperArrowProps } from 'react-popper';
import * as PopperJS from 'popper.js';
import isFunction from 'lodash/isFunction';
import { useClickOutside } from '@sweepbright/use-click-outside';
import './Popover.scss';

export interface PopoverContentProps {
    updatePopperPosition?: () => void;
}

export type RenderPopperArrowFn = (props: { arrowProps: PopperArrowProps; placement: string }) => JSX.Element;
export type PopoverContent = string | React.ReactElement<any> | ((props: PopoverContentProps) => JSX.Element) | null;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    show: boolean;
    placement?: PopperJS.Placement;
    children: PopoverContent;
    referenceElement: PopperJS.ReferenceObject;
    wrapperClassName?: string;
    renderArrow?: RenderPopperArrowFn;
    eventsEnabled?: boolean;
    onClickOutside?: () => void;
}

export default class Popover extends React.PureComponent<Props> {
    static defaultProps: Partial<Props> = {
        eventsEnabled: true,
        wrapperClassName: 'c-popover',
        className: 'c-popover__content',
        renderArrow: ({ arrowProps, placement }) => (
            <div className="c-popover__arrow" data-placement={placement} {...arrowProps} />
        ),
    };

    contentRef = React.createRef();

    renderInner = (transitionStyles = {}) => {
        const {
            placement,
            referenceElement,
            eventsEnabled,
            onMouseEnter,
            onMouseLeave,
            onClickOutside,
            children,
            className,
            wrapperClassName,
            renderArrow,
        } = this.props;

        return (
            <Portal>
                <ReactPopper
                    placement={placement}
                    referenceElement={referenceElement}
                    eventsEnabled={eventsEnabled}
                    modifiers={{ preventOverflow: { enabled: true, boundariesElement: 'window' } }}
                >
                    {({ ref, style, placement, arrowProps, scheduleUpdate }) => {
                        return (
                            <div
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                                ref={ref}
                                style={{
                                    ...style,
                                    ...transitionStyles,
                                }}
                                data-placement={placement}
                                className={`${wrapperClassName}`}
                            >
                                <ClickOutside targetRef={this.contentRef} onClickOutside={onClickOutside}>
                                    <div ref={this.contentRef} className={className}>
                                        {typeof children === 'string' && children}
                                        {React.isValidElement(children) && React.cloneElement(children)}
                                        {isFunction(children) &&
                                            children({
                                                updatePopperPosition: scheduleUpdate,
                                            })}
                                        {renderArrow &&
                                            renderArrow({
                                                arrowProps,
                                                placement,
                                            })}
                                    </div>
                                </ClickOutside>
                            </div>
                        );
                    }}
                </ReactPopper>
            </Portal>
        );
    };

    render() {
        const { show } = this.props;

        return <Manager>{show && this.renderInner()}</Manager>;
    }
}

function ClickOutside({ children, targetRef, onClickOutside }) {
    useClickOutside(targetRef, {
        onClickOutside,
    });

    return children;
}
