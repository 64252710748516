import { put } from 'redux-saga/effects';
import { EstatesRequests } from '../../../requests/index';
import { setEstateRequests } from '../../actions';
import withPagination from '../Helpers/withPagination';
import apiCall from '../Effects/apiCall';

export default function* onFetchRequests({ estateId, attributes }) {
    yield withPagination('estate/requests', attributes, function* onFetch() {
        const requests = yield apiCall(new EstatesRequests().pendingRequests, estateId, attributes);

        yield put(setEstateRequests(estateId, requests.data));

        return requests;
    });
}
