import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import { useTeams } from '@/new.domains/teams/hooks/useTeams';
import AdvancedSelect from '@/app.components/selects/AdvancedSelect/Select';
import useOffice from '@/app.hooks/useOffice';
import isScrollEnd from '@/utils/isScrollEnd';

type Props = {
    type?: 'old';
    value?: string;
    noMargin?: boolean;
    allowClear?: boolean;
    showValueOnTop?: boolean;
    disabledOptions?: string[];
    style?: React.CSSProperties;
    onChange: (newValue: string, option?: any) => void;
    additionalOptions?: { id: string; name: string }[];
};

const TeamsSelect: React.FC<Props> = props => {
    const {
        type,
        value,
        noMargin,
        onChange,
        style,
        allowClear = true,
        showValueOnTop,
        disabledOptions,
        additionalOptions,
    } = props;

    const intl = useIntl();

    const [query, setQuery] = useState('');

    const { teams, loading, handleFetchMore } = useTeams({ query });

    const office = useOffice(value, false, !value);

    const content = {
        loading: intl.formatMessage({ id: 'general.state.loading' }),
        placeholder: intl.formatMessage({ id: 'common.actions.select' }),
        noResults: intl.formatMessage({ id: 'general.state.no_results' }),
    };

    const filteredTeams = type === 'old' ? teams.filter(team => team.id !== value) : teams;

    const options = additionalOptions
        ? [
              ...additionalOptions,
              ...(showValueOnTop && value && value !== 'everyone'
                  ? [
                        {
                            id: office.get('id'),
                            name: office.get('name'),
                        },
                    ]
                  : []),
              ...filteredTeams,
          ]
        : filteredTeams;

    const formattedValue = value ? options.find(el => el.id === value) && value : null || null;

    const formattedOptions = options.map(el => {
        return {
            value: el.id,
            label: el.name,
            ...(disabledOptions?.includes(el.id) && { disabled: true }),
        };
    });

    if (type === 'old') {
        return (
            <AdvancedSelect
                truncate
                simpleValue
                loading={loading}
                onChange={onChange}
                clearable={allowClear}
                value={formattedValue}
                onInputChange={setQuery}
                options={formattedOptions}
                onScroll={isScrollEnd => {
                    if (isScrollEnd && !loading) {
                        handleFetchMore();
                    }
                }}
                placeholder={loading ? content.loading : content.placeholder}
            />
        );
    }

    return (
        <Select
            showSearch
            style={style}
            virtual={false}
            loading={loading}
            onSearch={setQuery}
            onChange={onChange}
            value={formattedValue}
            allowClear={allowClear}
            maxTagCount="responsive"
            optionFilterProp="label"
            options={formattedOptions}
            className={`filter-default-select${noMargin ? ' no-margin' : ''}`}
            popupClassName="filter-default-select__popup"
            placeholder={loading ? content.loading : content.placeholder}
            onPopupScroll={event => {
                if (isScrollEnd(event) && !loading) {
                    handleFetchMore();
                }
            }}
            notFoundContent={
                <span className="filter-default-select-notfound">{loading ? content.loading : content.noResults}</span>
            }
        />
    );
};

export default TeamsSelect;
