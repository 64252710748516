import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';
import { PROFILE_EDIT, PROFILE_CALENDARS } from '@/app.routing/routes';
import { FormattedMessage } from 'react-intl-sweepbright';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import LeftNavBar from '../navigations/LeftNavBar';
import SideMenuLink from '../navigations/SideMenu/SideMenuLink';

const ProfileAside = props => {
    const { location, showCalendarIntegration } = props;
    const linkProps = { fuzzyMatch: true, location };

    return (
        <LeftNavBar location={location}>
            <SideMenuLink
                {...linkProps}
                to={PROFILE_EDIT}
                label={<FormattedMessage id="navigation.aside.profile" defaultMessage="Profile" />}
                icon="face-03"
                iconActive="face-03-fill"
            />
            {showCalendarIntegration && (
                <SideMenuLink
                    {...linkProps}
                    to={PROFILE_CALENDARS}
                    label={<FormattedMessage id="navigation.aside.calendars" defaultMessage="Calendar" />}
                    icon="calendar"
                    iconActive="calendar-fill"
                />
            )}
        </LeftNavBar>
    );
};

ProfileAside.propTypes = {
    location: PropTypes.string,
    user: PropTypes.instanceOf(Map),
    showCalendarIntegration: PropTypes.instanceOf(Map),
};

export default connect(
    createStructuredSelector({
        showCalendarIntegration: getFeature('integrations.useOnlineCalendarsWithCronofy.enabled'),
    }),
)(ProfileAside);
