import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';

export default attributes => {
    const errors = {};

    try {
        if (!Stripe.card.validateCardNumber(attributes.number)) {
            errors.number = (
                <FormattedMessage id="subscription.card.error.number" defaultMessage="Invalid Card number" />
            );
        }

        if (!Stripe.card.validateCVC(attributes.cvc)) {
            errors.cvc = <FormattedMessage id="subscription.card.error.cvc" defaultMessage="Invalid CVC" />;
        }

        if (!Stripe.card.validateExpiry(attributes.expiry)) {
            errors.expiry = <FormattedMessage id="subscription.card.error.expiry" defaultMessage="Invalid expiry" />;
        }
    } catch (error) {
        errors.number = error.message;
    }

    return errors;
};
