// @ts-nocheck
import React from 'react';
import { Map } from 'immutable';
import { reduxForm as form, HandleSubmitProp } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { validateWithRules } from '@/app.utils/services/Validator';
import useCompany from '@/app.hooks/useCompany';
import Formik from '@/app.components/forms/helpers/Formik';
import ContactRepository from '../../app.utils/services/Repositories/ContactRepository';
import ComposeEmailForm from '../forms/ComposeEmailForm';

type Props = {
    onCancel: () => void;
    contact: Map<string, any>;
    handleSubmit: HandleSubmitProp;
    label?: Node;
    mailTextPreset: Record<string, any>;
    properties?: Array<Map<string, any>>;
};

const getValidation = ({ contact, company }) => attributes => {
    const language = new ContactRepository(contact, company).getLanguage();
    const contents = {
        type: 'object',
        required: true,
        properties: {
            [language]: {
                type: 'string',
                required: true,
                allowEmpty: false,
                messages: {
                    allowEmpty: 'forms.match.contents.required',
                    required: 'forms.match.contents.required',
                },
            },
        },
    };

    return validateWithRules({ contents })(attributes, { humanizeErrorMessage: false });
};

const SendMailForm: React.FunctionComponent<Props> = props => {
    const company = useCompany();
    const { label, contact, onSubmit, properties = [], onCancel, mailTextPreset } = props;

    const locale = new ContactRepository(contact, company).getLanguage();
    const validate = React.useCallback(getValidation({ contact, company }), [contact, company]);

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={mailTextPreset}
            enableReinitialize
            initialErrors={validate(mailTextPreset)}
            validate={validate}
        >
            {({ values, handleSubmit, isSubmitting, handleChange, submitCount, errors }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal.Header>
                            <Modal.Title>
                                {label || <FormattedMessage id="matches.modal.title" defaultMessage="Send mail" />}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ComposeEmailForm
                                field={{
                                    name: `contents.${locale}`,
                                    value: values.contents[locale],
                                    onChange: handleChange,
                                    error: errors.contents?.[locale],
                                }}
                                properties={properties}
                                submitFailed={submitCount > 0}
                            />
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button onClick={onCancel} type="button" disabled={isSubmitting}>
                                <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting} data-testid="send-mail-btn">
                                {isSubmitting ? (
                                    <FormattedMessage id="general.state.sending" defaultMessage="Sending..." />
                                ) : (
                                    label || <FormattedMessage id="matches.modal" defaultMessage="Send Mail" />
                                )}
                            </Button>
                        </Modal.Footer>
                    </form>
                );
            }}
        </Formik>
    );
};

export default SendMailForm;
