// @ts-nocheck
import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, connectHits, Configure } from 'react-instantsearch-dom';
import Input from '@/app.components/forms/Input/Input';
import Select from '@/app.components/selects/AdvancedSelect/Select';

export default class PostalCodeInput extends React.Component<{
    country: any;
    value: any;
    label: any;
    name: any;
    onChange: any;
    error: any;
    touched: any;
    onBlur?: any;
    emptyValue?: string;
}> {
    searchClient = algoliasearch(ALGOLIA_SEARCH_APP_ID, ALGOLIA_SEARCH_API_KEY);

    state = {
        inputValue: '',
    };

    handleInputChange = value => {
        this.setState({ inputValue: value });
    };

    componentDidUpdate(
        prevProps: Readonly<{
            country: any;
            value: any;
            label: any;
            name: any;
            onChange: any;
            error: any;
            touched: any;
        }>,
    ) {
        if (prevProps.country !== this.props.country) {
            this.props.onChange('');
        }
    }

    hasDatasetForCountry() {
        return ['FR', 'BE'].includes(this.props.country?.toUpperCase());
    }

    render() {
        const { value, onChange, label, error, touched, country, ...props } = this.props;
        const searchState = {
            query: this.state.inputValue,
            facetFilters: [`country:${country}`],
        };

        if (this.hasDatasetForCountry()) {
            return (
                <InstantSearch indexName="postcodes" searchClient={this.searchClient}>
                    <Configure {...searchState} />
                    <Input label={label} error={error} name={name} touched={touched} {...props}>
                        <PostcodesSelect
                            emptyValue={props.emptyValue}
                            testId={props['data-testid']}
                            value={value}
                            onChange={onChange}
                            onBlur={props.onBlur}
                            inputValue={this.state.inputValue}
                            onInputChange={this.handleInputChange}
                        />
                    </Input>
                </InstantSearch>
            );
        }

        return (
            <Input
                label={label}
                error={error}
                name={name}
                touched={touched}
                value={value}
                onChange={onChange}
                onBlur={props.onBlur}
                {...props}
            />
        );
    }
}

const PostcodesSelect = connectHits(({ hits, value, onChange, inputValue, onInputChange, testId, emptyValue }) => {
    return (
        <Select
            simpleValue
            testId={testId}
            emptyValue={emptyValue}
            selectedItem={value ? { value, label: value } : null}
            options={hits.map(hit => ({ label: hit.code, value: hit.code }))}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={onInputChange}
            filterOptions={values => values}
            clearable
        />
    );
});
