import { FeedbackPayload, VendorFeedbackPayload } from '@/requests/InteractionsRequests';

export const REGISTER_INTERACTION_FEEDBACK = 'interaction/ADD_CONTACT';

export function registerInteractionFeedback({
    interactionId,
    payload,
    resolve,
    reject,
}: {
    interactionId: string;
    payload: FeedbackPayload | VendorFeedbackPayload;
    resolve?: () => void;
    reject?: (err?: Error) => void;
}) {
    return {
        type: REGISTER_INTERACTION_FEEDBACK,
        payload,
        interactionId,
        resolve,
        reject,
    } as const;
}

export type InteractionAction = ReturnType<typeof registerInteractionFeedback>;
