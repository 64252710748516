import { fromJS } from 'immutable';
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';

export const homepage = [
    {
        id: 'default',
        name: <FormattedMessage id="forms.labels.homepage_cover.default" defaultMessage="Default illustration" />,
        image: require('@/../../resources/assets/img/themes/default-illustration.png'),
    },
    {
        id: 'image',
        name: <FormattedMessage id="forms.labels.homepage_cover.image" defaultMessage="Custom cover image" />,
        image: require('@/../../resources/assets/img/themes/cover-image.png'),
    },
    {
        id: 'borderless_image',
        name: (
            <FormattedMessage
                id="forms.labels.homepage_cover.borderless_image"
                defaultMessage="Borderless cover image"
            />
        ),
        image: require('@/../../resources/assets/img/themes/borderless-cover-image.png'),
    },
    {
        id: 'video',
        name: <FormattedMessage id="forms.labels.homepage_cover.video" defaultMessage="Custom cover video" />,
        image: require('@/../../resources/assets/img/themes/cover-video.png'),
    },
];

export const themes = fromJS([
    {
        id: 'custom',
        name: <FormattedMessage id="themes.label.custom" defaultMessage="Custom" />,
        image: require('@/../../resources/assets/img/themes/custom.png'),
    },
    {
        id: 'custom-dark',
        name: <FormattedMessage id="themes.label.custom_dark" defaultMessage="Dark Custom" />,
        image: require('@/../../resources/assets/img/themes/custom-dark.png'),
    },
    {
        id: 'default',
        name: <FormattedMessage id="themes.label.default" defaultMessage="Default" />,
        image: require('@/../../resources/assets/img/themes/default.png'),
    },
    {
        id: 'aubergine',
        name: <FormattedMessage id="themes.label.aubergine" defaultMessage="Aubergine" />,
        image: require('@/../../resources/assets/img/themes/aubergine.png'),
    },
    {
        id: 'orange',
        name: <FormattedMessage id="themes.label.orange" defaultMessage="Orange" />,
        image: require('@/../../resources/assets/img/themes/orange.png'),
    },
    {
        id: 'salmon',
        name: <FormattedMessage id="themes.label.salmon" defaultMessage="Salmon" />,
        image: require('@/../../resources/assets/img/themes/salmon.png'),
    },
    {
        id: 'gold',
        name: <FormattedMessage id="themes.label.gold" defaultMessage="Gold" />,
        image: require('@/../../resources/assets/img/themes/gold.png'),
    },
    {
        id: 'mint',
        name: <FormattedMessage id="themes.label.mint" defaultMessage="Mint" />,
        image: require('@/../../resources/assets/img/themes/mint.png'),
    },
    {
        id: 'green',
        name: <FormattedMessage id="themes.label.green" defaultMessage="Green" />,
        image: require('@/../../resources/assets/img/themes/green.png'),
    },
    {
        id: 'blue',
        name: <FormattedMessage id="themes.label.blue" defaultMessage="Blue" />,
        image: require('@/../../resources/assets/img/themes/blue.png'),
    },
    {
        id: 'gray',
        name: <FormattedMessage id="themes.label.gray" defaultMessage="Gray" />,
        image: require('@/../../resources/assets/img/themes/gray.png'),
    },
    {
        id: 'default-dark',
        name: <FormattedMessage id="themes.label.default_dark" defaultMessage="Dark Default" />,
        image: require('@/../../resources/assets/img/themes/dark-default.png'),
    },
    {
        id: 'aubergine-dark',
        name: <FormattedMessage id="themes.label.aubergine_dark" defaultMessage="Dark Aubergine" />,
        image: require('@/../../resources/assets/img/themes/dark-aubergine.png'),
    },
    {
        id: 'orange-dark',
        name: <FormattedMessage id="themes.label.orange_dark" defaultMessage="Dark Orange" />,
        image: require('@/../../resources/assets/img/themes/dark-orange.png'),
    },
    {
        id: 'salmon-dark',
        name: <FormattedMessage id="themes.label.salmon_dark" defaultMessage="Dark Salmon" />,
        image: require('@/../../resources/assets/img/themes/dark-salmon.png'),
    },
    {
        id: 'gold-dark',
        name: <FormattedMessage id="themes.label.gold_dark" defaultMessage="Dark Gold" />,
        image: require('@/../../resources/assets/img/themes/dark-gold.png'),
    },
    {
        id: 'mint-dark',
        name: <FormattedMessage id="themes.label.mint_dark" defaultMessage="Dark Mint" />,
        image: require('@/../../resources/assets/img/themes/dark-mint.png'),
    },
    {
        id: 'green-dark',
        name: <FormattedMessage id="themes.label.green_dark" defaultMessage="Dark Green" />,
        image: require('@/../../resources/assets/img/themes/dark-green.png'),
    },
    {
        id: 'blue-dark',
        name: <FormattedMessage id="themes.label.blue_dark" defaultMessage="Dark Blue" />,
        image: require('@/../../resources/assets/img/themes/dark-blue.png'),
    },
    {
        id: 'gray-dark',
        name: <FormattedMessage id="themes.label.gray_dark" defaultMessage="Dark Gray" />,
        image: require('@/../../resources/assets/img/themes/dark-gray.png'),
    },
]);

export const lookAndFeel = [
    {
        name: <FormattedMessage id="pages.website.look_and_feel.typeface" defaultMessage="Typeface" />,
        id: 'theme_typeface',
        action: <FormattedMessage id="pages.website.look_and_feel.select_typefaces" defaultMessage="Select Typeface" />,
        options: [
            {
                id: 'classical',
                name: <FormattedMessage id="themes.typeface.classical" defaultMessage="Classical" />,
                image: require('@/../../resources/assets/img/themes/classical.png'),
            },
            {
                id: 'luxury',
                name: <FormattedMessage id="themes.typeface.luxury" defaultMessage="Luxury" />,
                image: require('@/../../resources/assets/img/themes/luxury.png'),
            },
            {
                id: 'modern',
                name: <FormattedMessage id="themes.typeface.modern" defaultMessage="Modern" />,
                image: require('@/../../resources/assets/img/themes/modern.png'),
            },
            {
                id: 'playful',
                name: <FormattedMessage id="themes.typeface.playful" defaultMessage="Playful" />,
                image: require('@/../../resources/assets/img/themes/playful.png'),
            },
            {
                id: 'timeless',
                name: <FormattedMessage id="themes.typeface.timeless" defaultMessage="Timeless" />,
                image: require('@/../../resources/assets/img/themes/timeless.png'),
            },
        ],
    },
    {
        name: <FormattedMessage id="pages.website.look_and_feel.color_theme" defaultMessage="Color Theme" />,
        modalName: <FormattedMessage id="pages.website.look_and_feel.color_theme" defaultMessage="Color Theme" />,
        id: 'theme',
        action: (
            <FormattedMessage id="pages.website.look_and_feel.select_color_theme" defaultMessage="Select Color Theme" />
        ),
        options: themes.toJS(),
    },
    {
        name: <FormattedMessage id="pages.website.look_and_feel.buttons" defaultMessage="Buttons" />,
        id: 'theme_buttons',
        action: <FormattedMessage id="pages.website.look_and_feel.select_buttons" defaultMessage="Select Buttons" />,
        options: [
            {
                id: 'square',
                name: <FormattedMessage id="themes.buttons_style.square" defaultMessage="Square" />,
                image: require('@/../../resources/assets/img/themes/square.png'),
            },
            {
                id: 'rounded',
                name: <FormattedMessage id="themes.buttons_style.rounded" defaultMessage="Rounded" />,
                image: require('@/../../resources/assets/img/themes/rounded.png'),
            },
            {
                id: 'pill',
                name: <FormattedMessage id="themes.buttons_style.pill" defaultMessage="Pill" />,
                image: require('@/../../resources/assets/img/themes/pill.png'),
            },
        ],
    },
];
