// @ts-nocheck
import React from 'react';
import { InputProps, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import { invoiceFields } from '@/app.redux/selectors';
import FormPane from '@/app.components/forms/FormPane';
import InvoiceDetails, { asyncValidateInvoiceData } from '@/app.domains/subscription/InvoiceDetails';
import wrapWithPromise from '../../app.utils/services/wrapWithPromise';

type Props = {
    company: any;
    fields: InputProps;
    handleSubmit: (fn: (arg: Record<string, any>) => void) => void;
    status: any;
    submitting: boolean;
    updateCompanySettings: (companyId: string, values: any) => void;
    valid: boolean;
};

const InvoiceForm: React.FunctionComponent<Props> = props => {
    const onSubmit = (values: Record<string, any>) => {
        const companyId = props.company.get('id');

        return wrapWithPromise(data => props.updateCompanySettings(companyId, data))({
            ...values,
            companyId,
        }).catch(serverErrors => {
            return serverErrors;
        });
    };

    return (
        <FormPane
            cols={{ sm: 10, lg: 7 }}
            title={<FormattedMessage id="forms.titles.subscriptions.invoice" defaultMessage="Invoice details" />}
            onSubmit={props.handleSubmit(onSubmit)}
            status={props.status}
        >
            <InvoiceDetails fields={props.fields} submitting={props.submitting} valid={props.valid} />
        </FormPane>
    );
};

export default reduxForm({
    form: 'subscription/invoice',
    fields: invoiceFields,
    asyncValidate: asyncValidateInvoiceData,
})(InvoiceForm);
