// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import { INTEGRATION_TYPE } from '../../../app.routing/routes';
import { getFeatures } from '../../../app.redux/selectors/FeaturesSelector';
import { categories } from '../../../app.data/Integrations';
import SideMenu from '../../../app.components/navigations/SideMenu/SideMenu';
import SideMenuLink from '../../../app.components/navigations/SideMenu/SideMenuLink';

@connect(
    createStructuredSelector({
        features: getFeatures,
    }),
)
export default class IntegrationsLayout extends Component {
    static propTypes = {
        children: PropTypes.node,
        features: PropTypes.instanceOf(Map),
        isEnabled: PropTypes.bool,
        location: PropTypes.object.isRequired,
    };

    render() {
        const { children, features } = this.props;

        return (
            <>
                <LayoutColumn size="small">
                    <SideMenu color="gray-lighter" shadow="shadow-left">
                        {categories
                            .filter(category => features.get(`integrations-${category.get('id')}`))
                            .map(category => (
                                <SideMenuLink
                                    key={category.get('id')}
                                    fuzzyMatch
                                    label={<FormattedMessage id={`integrations.${category.get('id')}.name`} />}
                                    icon={category.get('icon')}
                                    to={INTEGRATION_TYPE(category.get('id'))}
                                />
                            ))}
                    </SideMenu>
                </LayoutColumn>
                <LayoutColumn>{children}</LayoutColumn>
            </>
        );
    }
}
