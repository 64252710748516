import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { useQuery } from '@apollo/react-hooks';
import { updateConnection } from '@/graphql/utils';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { GetOfficeEstatesQuery, GetOfficeEstatesQueryVariables } from '@/graphql/generated/types';
import { GET_OFFICE_ESTATES_QUERY } from '@/graphql/queries/properties/getOfficeEstates';
import { PropertySearchType } from '../types';
import { formatPropertiesSearch } from '../utils/formatPropertiesSearch';

type Props = {
    skip?: boolean;
    officeId: string;
    oldLogic?: boolean;
    handlePage?: (page: number) => void;
    searchSettings?: PropertySearchType;
};

const useProperties = (props: Props) => {
    const { skip, officeId, oldLogic, handlePage, searchSettings } = props;

    const [page, setPage] = useState(1);
    const [data, setData] = useState<any[]>([]);

    const showEstimate = useFeatureFlag('property.status.estimate');
    const atlasSearch = useFeatureFlag('properties-atlas-search');
    const showPropertySharedFilter = useFeatureFlag('property.shared.enabled');

    const variables = formatPropertiesSearch({
        page,
        officeId,
        atlasSearch,
        showEstimate,
        searchSettings,
        showPropertySharedFilter,
    });

    const { data: dataProperties, loading: loadingProperties, fetchMore, refetch, error } = useQuery<
        GetOfficeEstatesQuery,
        GetOfficeEstatesQueryVariables
    >(GET_OFFICE_ESTATES_QUERY, {
        skip,
        // @ts-ignore
        variables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: oldLogic ? 'cache-and-network' : 'no-cache',
    });

    useDebounce(
        () => {
            if (!oldLogic) {
                setPage(1);
            }
        },
        oldLogic ? undefined : 100,
        [oldLogic ? undefined : searchSettings],
    );

    useEffect(() => {
        if (!oldLogic) {
            if (!loadingProperties) {
                const newData = dataProperties?.estates?.edges || [];

                if (newData.length === 0 && page === 1) {
                    setData([]);
                }

                if (newData.length > 0) {
                    if (page === 1) {
                        setData(newData);
                    }

                    if (page > 1) {
                        setData(prevData => [...(prevData || []), ...newData]);
                    }
                }
            }
        }
    }, [page, oldLogic, dataProperties, loadingProperties]);

    useEffect(() => {
        if (!oldLogic) {
            if (error) {
                setData([]);
                setPage(1);
            }
        }
    }, [oldLogic, error]);

    useEffect(() => {
        if (handlePage) {
            handlePage(page);
        }
    }, [page, handlePage]);

    const totalCount = dataProperties?.estates?.totalCount || 0;
    const currentPage = dataProperties?.estates?.pageInfo?.currentPage;
    const hasNextPage = dataProperties?.estates?.pageInfo?.hasNextPage || false;

    const handleFetchMore = () => {
        if (hasNextPage && !loadingProperties) {
            setPage(prevState => prevState + 1);
        }
    };

    const formattedData = data.map(el => el.node);

    const oldRefetch = () => {
        refetch({
            ...variables,
            page: 0,
        });
    };

    const handleOldFetchMore = useCallback(() => {
        if (!loadingProperties) {
            fetchMore({
                variables: {
                    page: (currentPage || 0) + 1,
                },
                updateQuery: updateConnection('estates'),
            });
        }
    }, [currentPage, fetchMore, loadingProperties]);

    return {
        error,
        totalCount,
        hasNextPage,
        loading: loadingProperties,
        refetch: oldLogic ? oldRefetch : refetch,
        fetchMore: oldLogic ? handleOldFetchMore : handleFetchMore,
        data: oldLogic ? dataProperties?.estates?.edges?.map(e => e?.node) || [] : formattedData,
    };
};

export default useProperties;
