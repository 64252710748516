import Ajv from 'ajv';
import { FDLRulesList } from './types';

const validate = require('./FDLRulesList.validate.js');
const ajv = new Ajv();

export function validateRules(rules: FDLRulesList) {
  const valid = validate(rules);
  if (!valid) {
    throw new TypeError(ajv.errorsText(validate.errors));
  }
}
