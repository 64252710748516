import { call, put } from 'redux-saga/effects';
import AuthManager from 'auth-manager';
import { fromJS } from 'immutable';
import logger from '@/app.utils/services/logger';
import { clearUserCookies } from '@/app.utils/services/Helpers/cookies';
import { logout } from '@/app.redux/actions/AuthActions';
import { getApolloClient } from '@sweepbright/webapp-shared/graphql/client';

const options = {};

if (__CLIENT__) {
    const parts = fromJS(window.location.hostname.split('.')).skip(1);
    options.domain = parts.toJS().join('.');
}

export default function* onLogout() {
    logger.debug('executing the onLogout saga');

    // cleans up the redux store data
    yield put(logout());

    // https://www.apollographql.com/docs/react/caching/cache-interaction/#resetting-the-store
    const apolloClient = getApolloClient();
    yield call(() => apolloClient?.clearStore());
    yield call(() => AuthManager.logout());

    yield call(clearUserCookies);

    // This is for legacy purposes, user's that still have the cookie stored on a `.sweepbright.com` subdomain
    // will otherwise remain logged in.
    yield call(options => AuthManager.logout(options), options);
}
