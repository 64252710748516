import { InjectedIntl } from 'react-intl';

export type LangDefinition = {
    value: string;
    /**
     * @deprecated Use `localizedLabel`
     */
    label: string;
    localizedLabel: string;
};

const addDefaultLocalizedLabel = (
    lang: Pick<LangDefinition, 'label' | 'value'> & Partial<Pick<LangDefinition, 'localizedLabel'>>,
): LangDefinition => {
    return {
        ...lang,
        localizedLabel: lang.localizedLabel ?? lang.label,
    };
};

export const getLanguages = (intl?: InjectedIntl) => {
    return [
        {
            value: 'en',
            label: 'English',
            localizedLabel: intl?.formatMessage({
                id: 'general.locales.en',
                defaultMessage: 'English',
            }),
        },
        {
            value: 'fr',
            label: 'French',
            localizedLabel: intl?.formatMessage({
                id: 'general.locales.fr',
                defaultMessage: 'French',
            }),
        },
        {
            value: 'nl',
            label: 'Dutch',
            localizedLabel: intl?.formatMessage({
                id: 'general.locales.nl',
                defaultMessage: 'Dutch',
            }),
        },
    ].map(addDefaultLocalizedLabel);
};

/**
 * @deprecated Use `getLanguages(intl?: InjectedIntl)` instead. After that, use `localizedLabel` instead of `label`
 */
export const languages: LangDefinition[] = getLanguages();
