// @ts-nocheck
import { List, Map } from 'immutable';
import React from 'react';
import { InputProps } from 'redux-form';
import invariant from 'tiny-warning';
import { FormattedMessage } from 'react-intl-sweepbright';
import useCompanyLanguages from '@/app.hooks/useCompanyLanguages';
import { languages, LangDefinition } from '../../app.data/Localization';
import Input from './Input/Input';

const SEPARATOR_NESTED = '.';
/* eslint-disable no-unused-vars */
const SEPARATOR_FLAT = '_';

const localizeInputs = (fields: string[], separator: string = SEPARATOR_NESTED) =>
    fields.reduce((reduction, field) => {
        languages.forEach(language => reduction.push(`${field}${separator}${language.value}`));

        return reduction;
    }, [] as string[]);

/**
 * Localizes a field A to {A: {en, fr, nl}}
 */
export const localizeToFieldsObject = localizeInputs;

/**
 * Localizes a field A to [A_en, A_fr, A_nl]
 */
export const localizeToSeparateFields = (fields: string[]) => localizeInputs(fields, '_');

export const localizeValidationRule = (rule: any, companyLanguages: LangDefinition[] = languages) =>
    List(companyLanguages)
        .reduce((reduction, language) => reduction!.set(language!.value, rule), Map())
        .toJS();

type CommonProps = {
    label: React.ReactNode;
    component: React.ComponentType<any>;
    touched: boolean;
};

type FieldProps =
    | {
          field: {
              [locale: string]: InputProps;
          };
          fields?: never;
      }
    | {
          field: string;
          fields: { [fieldWithLocalePrefix: string]: InputProps };
      };

type Props = CommonProps & FieldProps;

const LocalizedInput: React.FunctionComponent<Props> = ({
    component,
    field,
    fields: legacyFields,
    ...props
}: Props) => {
    let companyLanguages = useCompanyLanguages();

    React.useEffect(() => {
        invariant(
            field !== 'string',
            'You are using a legacy API for this component, please pass each independent field ',
        );
    }, []);

    // If we received a non-localized field, return a normal Input for BC
    if (typeof field !== 'string' && field.name) {
        throw new Error('Use a normal field instead');
    }

    let fields;
    if (legacyFields && typeof field === 'string') {
        fields = convertCase1(
            { fields: legacyFields, field },
            Array.from(companyLanguages, f => f.value),
        );
    } else {
        fields = convertCase2(
            { field },
            Array.from(companyLanguages, f => f.value),
        );
    }

    return <LocalizedInputInner component={component} fields={fields} companyLanguages={companyLanguages} {...props} />;
};

const LocalizedInputInner = ({ component: Component = Input, fields, companyLanguages, ...props }) => {
    return (
        <div>
            {companyLanguages.map(languageCodeField => {
                const fieldForLanguage = fields[languageCodeField.value];
                const languageCodeLabel = (
                    <FormattedMessage
                        id={`general.locales.${languageCodeField.value}`}
                        defaultMessage={languageCodeField.label}
                    />
                );

                return (
                    <Component
                        key={languageCodeField.value}
                        field={fieldForLanguage}
                        {...fieldForLanguage}
                        {...props}
                        label={
                            props.label ? (
                                <span>
                                    {props.label} ({languageCodeLabel})
                                </span>
                            ) : (
                                languageCodeLabel
                            )
                        }
                    />
                );
            })}
        </div>
    );
};

export default LocalizedInput;

// lets simplify a bit the API of this component
// and provide the below utility functions
// for the callers so they can transform the field into
// the right shape
// the component is gonna receive a fields object always,
// that has { [lang: string]: InputProps<string>}

// Case 1: in some cases, multilingual fields are stored
//  like { field: "name", fields: { name_en: Field, name_fr: Field }}
// the below function converts it to the right structure
function convertCase1({ field, fields }, languages: string[]) {
    const newFields = languages.reduce((newFields, langCode) => {
        newFields[langCode] = fields[`${field}${SEPARATOR_FLAT}${langCode}`];

        return newFields;
    }, {});

    return newFields;
}

// Case 2: passed { field: {en: Field, fr: Field}}
function convertCase2({ field }, languages: string[]) {
    languages.forEach(lang => {
        invariant(field.hasOwnProperty(lang), `field for locale ${lang} is missing`);
    });

    return field;
}
