// @ts-nocheck
import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    ConfigurationStatus,
    EstateChannelAccountConnectionEdge,
    GetEstateChannelAccountQuery,
    GetEstateChannelAccountQueryVariables,
} from '@/graphql/generated/types';
import { useToasts } from '@sweepbright/notifications';
import { ESTATE_CHANNEL_ACCOUNT_QUERY } from '@/graphql/queries/properties/getEstateChannelAccounts';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { CREATE_PUBLICATION_MUTATION, UPDATE_PUBLICATION_MUTATION } from '@/graphql/mutations/properties/publish';
import { FixInvalidFieldsStep } from '@/app.domains/properties/components/NewPublicationModal/InvalidFields/FixInvalidFieldsStep';
import { ConfigurePublicationStep } from '@/app.domains/properties/components/NewPublicationModal/ConfigurePublicationStep';
import { SelectChannelAccountStep } from '@/app.domains/properties/components/NewPublicationModal/SelectChannelAccountStep';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import Modal from '@/app.components/elements/Modal';
import { FormattedMessage } from 'react-intl-sweepbright';

enum PublicationSteps {
    SELECT_CHANNEL,
    FIX_INVALID_FIELDS,
    CREATE_PUBLICATION,
}

export enum PublicationAction {
    PUBLISH = 'publish',
    UPDATE = 'update',
    RETRY = 'retry',
}

export function NewPublicationModal({
    show,
    onCancel,
    propertyId,
    channelAccount: initialChannelAccount = null,
    onCompleted,
    action = PublicationAction.PUBLISH,
}: {
    show: boolean;
    onCancel: () => void;
    propertyId: string;
    channelAccount?: Maybe<EstateChannelAccountConnectionEdge>;
    onCompleted: (channelAccountEdgeId?: string) => void;
    action?: PublicationAction;
}) {
    const [step, setStep] = React.useState(getInitialStep());
    const [accountEdge, setAccountEdge] = React.useState<Maybe<EstateChannelAccountConnectionEdge>>(
        initialChannelAccount,
    );

    const { addSuccess, addError } = useToasts();

    useQuery<GetEstateChannelAccountQuery, GetEstateChannelAccountQueryVariables>(ESTATE_CHANNEL_ACCOUNT_QUERY, {
        variables: {
            estateId: propertyId,
            channelAccountId: accountEdge?.node.id!,
        },
        skip: !accountEdge || !!initialChannelAccount,
        onError(error) {
            getBugsnagClient().notify(error);
        },
        returnPartialData: true,
        onCompleted(data) {
            setAccountEdge(data.estate!.channelAccount);
            if (hasInvalidFields(data.estate?.channelAccount)) {
                setStep(PublicationSteps.FIX_INVALID_FIELDS);
            } else {
                setStep(PublicationSteps.CREATE_PUBLICATION);
            }
        },
        notifyOnNetworkStatusChange: true,
    });

    const [publish] = useMutation(CREATE_PUBLICATION_MUTATION, {
        onCompleted(data) {
            addSuccess({
                title: (
                    <FormattedMessage
                        id="publication_channel.status.publication_scheduled"
                        defaultMessage="Publication scheduled"
                    />
                ),
                message: (
                    <FormattedMessage
                        id="publish.scheduled.description"
                        defaultMessage="It can take several minutes to be live"
                    />
                ),
            });

            onCompleted(data.createPublication.estate?.channelAccount.id);
        },
        onError(error) {
            getBugsnagClient().notify(error);

            const isForbidden = error?.message?.includes('403');

            addError({
                message: isForbidden ? (
                    <FormattedMessage id="unauthorised_403" />
                ) : (
                    <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                ),
            });
        },
    });

    const [update] = useMutation(UPDATE_PUBLICATION_MUTATION, {
        onCompleted(data) {
            addSuccess({
                title: (
                    <FormattedMessage
                        id="publication_channel.status.publication_scheduled"
                        defaultMessage="Publication scheduled"
                    />
                ),
                message: (
                    <FormattedMessage
                        id="publish.scheduled.description"
                        defaultMessage="It can take several minutes to be live"
                    />
                ),
            });

            onCompleted(data.updatePublication.estate?.channelAccount.id);
        },
        onError(error) {
            getBugsnagClient().notify(error);

            const isForbidden = error?.message?.includes('403');

            addError({
                message: isForbidden ? (
                    <FormattedMessage id="unauthorised_403" />
                ) : (
                    <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                ),
            });
        },
    });

    const handleSubmit = async (config: FixMeType) => {
        if (action === PublicationAction.UPDATE) {
            await update({
                variables: {
                    input: {
                        propertyId,
                        channelAccountId: accountEdge!.node.id,
                        channelId: accountEdge!.node.channel.id,
                        config,
                    },
                    channelAccountId: accountEdge?.node.id,
                },
            });
        } else {
            await publish({
                variables: {
                    input: {
                        propertyId,
                        channelAccountId: accountEdge!.node.id,
                        channelId: accountEdge!.node.channel.id,
                        config,
                    },
                    channelAccountId: accountEdge?.node.id,
                },
            });
        }
    };

    return (
        <Modal
            onHide={onCancel}
            show={show}
            onExited={() => {
                if (!initialChannelAccount) {
                    setStep(PublicationSteps.SELECT_CHANNEL);
                    setAccountEdge(null);
                }
            }}
            autoFocus
            width={accountEdge?.node.channel.id === 'facebook' ? 'xl' : 'xl'}
        >
            {step === PublicationSteps.SELECT_CHANNEL && (
                <SelectChannelAccountStep propertyId={propertyId} onCancel={onCancel} onSubmit={setAccountEdge} />
            )}
            {step === PublicationSteps.FIX_INVALID_FIELDS && (
                <ErrorBoundary>
                    <FixInvalidFieldsStep
                        propertyId={propertyId}
                        channelAccountId={accountEdge?.node.id!}
                        onCancel={() => {
                            if (initialChannelAccount) {
                                onCancel();
                            } else {
                                setStep(PublicationSteps.SELECT_CHANNEL);
                                setAccountEdge(null);
                            }
                        }}
                        onSubmit={() => {
                            // for retry we dont show the config step
                            if (action === PublicationAction.RETRY) {
                                onCompleted(accountEdge?.id);
                            } else {
                                setStep(PublicationSteps.CREATE_PUBLICATION);
                            }
                        }}
                    />
                </ErrorBoundary>
            )}
            {step === PublicationSteps.CREATE_PUBLICATION && (
                <ConfigurePublicationStep
                    account={accountEdge!.node}
                    lastPublication={accountEdge?.lastPublication}
                    propertyId={propertyId}
                    onCancel={onCancel}
                    onSubmit={handleSubmit}
                    action={action}
                />
            )}
        </Modal>
    );

    function hasInvalidFields(channelAccount) {
        return channelAccount?.configurationStatus === ConfigurationStatus.MissingFields;
    }

    function getInitialStep() {
        if (initialChannelAccount) {
            return hasInvalidFields(initialChannelAccount)
                ? PublicationSteps.FIX_INVALID_FIELDS
                : PublicationSteps.CREATE_PUBLICATION;
        }

        return PublicationSteps.SELECT_CHANNEL;
    }
}
