import React from 'react';

export type ImperativeModalActions = {
    open: () => void;
    close: () => void;
};

export type ModalProps = {
    show: boolean;
    onHide: () => void;
    onEntered?: () => void;
    onExiting?: () => void;
    onExited?: () => void;
};

export function useModal(
    initialShown = false,
    ref?: any,
): {
    show: boolean;
    handleClose: () => void;
    handleOpen: () => void;
    visible: boolean;
    getModalProps: () => ModalProps;
} {
    const [show, setShow] = React.useState(initialShown);
    const [visible, setVisible] = React.useState(false);
    const handleClose = React.useCallback(() => {
        setShow(false);
    }, []);

    const handleOpen = React.useCallback(() => {
        setShow(true);
    }, []);

    React.useImperativeHandle(ref, () => ({
        open: handleOpen,
        close: handleClose,
    }));

    const getModalProps = React.useCallback(
        () => ({
            show,
            onHide: () => setShow(false),
            onEntered: () => setVisible(true),
            onExiting: () => setVisible(false),
            autoFocus: true,
        }),
        [handleClose, show],
    );

    return { show, handleClose, handleOpen, visible, getModalProps };
}
