import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import SortingSettingsForm from '../../app.components/forms/elements/SortingSettingsForm';

const options = [
    {
        value: 'updated_at__desc',
        label: (
            <FormattedMessage
                id="modals.properties_search.sort_panel.updated_at__desc"
                defaultMessage="Last edit (new to old)"
            />
        ),
    },

    {
        value: 'updated_at__asc',
        label: (
            <FormattedMessage
                id="modals.properties_search.sort_panel.updated_at__asc"
                defaultMessage="Last edit (old to new)"
            />
        ),
    },

    {
        value: 'published_at__desc',
        label: (
            <FormattedMessage
                id="modals.properties_search.sort_panel.published_at__desc"
                defaultMessage="Last publication (new to old)"
            />
        ),
    },

    {
        value: 'published_at__asc',
        label: (
            <FormattedMessage
                id="modals.properties_search.sort_panel.published_at__asc"
                defaultMessage="Last publication (old to new)"
            />
        ),
    },

    {
        value: 'first_published_at__desc',
        label: (
            <FormattedMessage
                id="modals.properties_search.sort_panel.first_published_at__desc"
                defaultMessage="First publication (new to old)"
            />
        ),
    },

    {
        value: 'first_published_at__asc',
        label: (
            <FormattedMessage
                id="modals.properties_search.sort_panel.first_published_at__asc"
                defaultMessage="First publication (old to new)"
            />
        ),
    },

    {
        value: 'price__desc',
        label: (
            <FormattedMessage
                id="modals.properties_search.sort_panel.price__desc"
                defaultMessage="Price (high to low)"
            />
        ),
    },

    {
        value: 'price__asc',
        label: (
            <FormattedMessage
                id="modals.properties_search.sort_panel.price__asc"
                defaultMessage="Price (low to high)"
            />
        ),
    },

    {
        value: 'city__asc',
        label: <FormattedMessage id="modals.properties_search.sort_panel.city__asc" defaultMessage="City (A to Z)" />,
    },
];

type Props = {
    initialValues: {
        sortField: string;
        sortOrder: string;
    };
    onChange: (attr: { sortField: string; sortOrder: string }) => void;
};

const PropertiesSortingSettings = (props: Props) => <SortingSettingsForm options={options} {...props} />;
export default PropertiesSortingSettings;
