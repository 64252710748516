import React from 'react';
import { Route, IndexRedirect } from 'react-router';
import { emailTypes, propertyTypes } from '@/app.data';

import * as Pages from '../allPagesComponents';

export default function teamRoutes() {
    return (
        <Route path="/teams">
            <Route path=":office">
                <IndexRedirect to="edit" />
                <Route
                    path="edit"
                    // @ts-ignore
                    title="Team"
                    component={Pages.TeamEdit}
                />

                <Route path="legal-entities" component={Pages.TeamLegalEntities} />

                <Route path="location-templates" component={Pages.TeamLocationTemplates} />

                <Route path="location-filter-templates" component={Pages.TeamLocationFilterTemplates} />

                <Route path="templates">
                    <Route
                        path="properties"
                        // @ts-ignore
                        title="Property Templates"
                        component={Pages.TeamTemplatesProperties}
                    >
                        <IndexRedirect to={propertyTypes[0]} />
                        <Route path=":type" component={Pages.TeamTemplatesPropertiesType} />
                    </Route>
                    <Route
                        path="emails"
                        // @ts-ignore
                        title="E-Mail Templates"
                        component={Pages.TeamTemplatesEmails}
                    >
                        <IndexRedirect to={emailTypes[0]} />
                        <Route path=":type" component={Pages.TeamTemplatesEmailsType} />
                    </Route>
                </Route>
            </Route>
        </Route>
    );
}
