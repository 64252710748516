import React from 'react';

type Props = {
    children: JSX.Element | JSX.Element[];
};

const WrapperAsideProfileInfo: React.FC<Props> = props => {
    const { children } = props;

    return <div className="c-wrapper-aside-profile-info">{children}</div>;
};

export default WrapperAsideProfileInfo;
