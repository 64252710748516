import { put } from 'redux-saga/effects';
import ContactsRequests from '../../../requests/ContactsRequests';
import { setContact } from '../../actions';
import formatApiErrors from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';

export default function* onAddContact({ contact: { resolve, reject, ...attributes } }) {
    try {
        const method =
            attributes.type === 'lead' ? new ContactsRequests().storeLead : new ContactsRequests().storeVendor;
        const contact = yield apiCall(method, attributes);

        yield put(setContact(contact));

        // eslint-disable-next-line no-unused-expressions
        resolve?.(contact);
    } catch (response) {
        // eslint-disable-next-line no-unused-expressions
        reject?.(formatApiErrors(response));
    }
}
