import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import Modal from '@/app.components/elements/Modal';
import { useContactLabels } from '@/new.domains/contacts/hooks/useContactLabels';
import { UPDATE_CONTACT_LABELS } from '@/graphql/mutations/contacts/updateContactLabels';
import { UpdateContactLabelsMutation, UpdateContactLabelsMutationVariables } from '@/graphql/generated/types';
import ContactLabelsModalForm from './ContactLabelsModalForm';

type Props = {
    visible: boolean;
    contactId: string;
    setVisible: Function;
};

const ContactLabelsModal: React.FC<Props> = props => {
    const { visible, setVisible, contactId } = props;

    const { data: contactLabels, refetch: refetchContactLabels } = useContactLabels(contactId);

    const [updateContactLabels] = useMutation<UpdateContactLabelsMutation, UpdateContactLabelsMutationVariables>(
        UPDATE_CONTACT_LABELS,
    );

    const onSumbit = (newLabels: string[]) => {
        updateContactLabels({
            variables: {
                id: contactId,
                labels: newLabels,
            },
        }).then(() => {
            setVisible(false);

            refetchContactLabels();
        });
    };

    const onCancel = () => {
        setVisible(false);
    };

    return (
        <Modal show={visible} onHide={onCancel} autoFocus>
            <ContactLabelsModalForm
                onCancel={onCancel}
                onSumbit={onSumbit}
                initLabels={contactLabels?.map(el => el.id) || []}
            />
        </Modal>
    );
};

export default ContactLabelsModal;
