import { put } from 'redux-saga/effects';
import { ContactsRequests } from '../../../requests/index';
import { setContactInteractionsForProperty } from '../../actions';
import withPagination from '../Helpers/withPagination';
import apiCall from '../Effects/apiCall';

export default function* onFetchContactInteractionsForProperty({ propertyId, contactId, attributes }) {
    yield withPagination(`contacts/${contactId}/${propertyId}/interactions`, attributes, function* onFetch(settings) {
        const interactions = yield apiCall(
            new ContactsRequests().interactionsForProperty,
            contactId,
            propertyId,
            settings,
        );
        yield put(setContactInteractionsForProperty(contactId, propertyId, interactions.data));

        return interactions;
    });
}
