import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import classNames from 'classnames';
import Icon from '@/app.components/icons/Icon';

// wrapper around the custom ui kit button to use as a dropdown toggle
const ToggleButton: React.FunctionComponent<any> = React.forwardRef(function ToggleButton(
    { bsClass, className, bsRole, ...props },
    ref,
) {
    return (
        <Button
            ref={ref}
            role={bsRole}
            className={classNames('btn--toggle', className, bsClass)}
            iconRight={<Icon icon="chevron" inline />}
            {...props}
        />
    );
});

export default ToggleButton;
