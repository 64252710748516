// @ts-nocheck
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl-sweepbright';
import { FormFields } from '@sweepbright/webapp-shared/utils/types';
import VatField from '@/app.domains/subscription/VatField';
import CompanyContext from '@/app.domains/company/CompanyContext';
import CountrySelector from '@/app.components/forms/CountrySelector';
import Input from '../../app.components/forms/Input/Input';

const REQUIRED_FIELD_MSG = 'This field is required';
const EMAIL_FIELD_MSG = 'This field needs to be a valid email';

export const invoiceDataSchema = yup.object().shape({
    invoice_company_name: yup.string().required(REQUIRED_FIELD_MSG),
    invoice_vat_number: yup.string(),
    invoice_email: yup
        .string()
        .email(EMAIL_FIELD_MSG)
        .required(REQUIRED_FIELD_MSG),
    invoice_zip: yup.string().required(REQUIRED_FIELD_MSG),
    invoice_city: yup.string().required(REQUIRED_FIELD_MSG),
    invoice_address: yup.string().required(REQUIRED_FIELD_MSG),
    invoice_county: yup.string(),
    invoice_country: yup.string().required(REQUIRED_FIELD_MSG),
    invoice_first_name: yup.string().required(REQUIRED_FIELD_MSG),
    invoice_last_name: yup.string().required(REQUIRED_FIELD_MSG),
});

export type InvoiceData = {
    invoice_vat_number: string;
    invoice_company_name: string;
    invoice_email: string;
    invoice_zip: string;
    invoice_city: string;
    invoice_address: string;
    invoice_county: string;
    invoice_country: string;
    invoice_first_name: string;
    invoice_last_name: string;
};

export function asyncValidateInvoiceData(invoiceData: InvoiceData) {
    return invoiceDataSchema
        .validate(invoiceData, { abortEarly: false })
        .then(() => {
            // return undefined so the form is marked as valid
            //
            return;
        })
        .catch(errors => {
            const formattedErrors = errors.inner.reduce(
                (result, err) => ({
                    ...result,
                    [err.path]: err.message,
                }),
                {},
            );

            throw formattedErrors;
        });
}

type Props = {
    fields: FormFields<InvoiceData>;
    submitting: boolean;
    valid: boolean;
};

class InvoiceDetails extends React.Component<Props> {
    componentDidUpdate(prevProps: Props) {
        // if form was submitting, but now is not submitting because it is invalid
        if (prevProps.submitting && !this.props.valid) {
            const erroredFieldNames =
                Object.keys(this.props.fields).filter(fieldName => this.props.fields[fieldName].invalid) || [];
            const [firstErroredField] = erroredFieldNames;
            if (firstErroredField && this.fieldNodes[firstErroredField]) {
                this.fieldNodes[firstErroredField].focus();
            }
        }
    }

    fieldNodes: { [id in keyof InvoiceData]?: HTMLInputElement } = {};

    render() {
        const { fields } = this.props;

        return (
            <CompanyContext.Consumer>
                {company => (
                    <React.Fragment>
                        <VatField {...fields.invoice_vat_number} companyId={company.get('id')} />
                        <hr />
                        <Row>
                            <Col sm={9}>
                                <Input
                                    label={
                                        <FormattedMessage
                                            id="forms.labels.invoices.company_name"
                                            defaultMessage="Company Name"
                                        />
                                    }
                                    {...fields.invoice_company_name}
                                    inputRef={i => (this.fieldNodes.invoice_company_name = i)}
                                />
                            </Col>

                            <Col sm={6}>
                                <Input
                                    label={
                                        <FormattedMessage id="forms.labels.invoices.email" defaultMessage="E-mail" />
                                    }
                                    {...fields.invoice_email}
                                    inputRef={i => (this.fieldNodes.invoice_email = i)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={8}>
                                <Input
                                    label={
                                        <FormattedMessage
                                            id="forms.labels.invoices.first_name"
                                            defaultMessage="First Name"
                                        />
                                    }
                                    {...fields.invoice_first_name}
                                    inputRef={i => (this.fieldNodes.invoice_first_name = i)}
                                />
                            </Col>
                            <Col sm={7}>
                                <Input
                                    label={
                                        <FormattedMessage
                                            id="forms.labels.invoices.last_name"
                                            defaultMessage="Last Name"
                                        />
                                    }
                                    {...fields.invoice_last_name}
                                    inputRef={i => (this.fieldNodes.invoice_last_name = i)}
                                />
                            </Col>
                        </Row>

                        <Input
                            type="autosize-textarea"
                            label={
                                <FormattedMessage
                                    id="forms.labels.invoices.street_number_box"
                                    defaultMessage="Street, number, box"
                                />
                            }
                            rows={6}
                            {...fields.invoice_address}
                        />
                        <Row>
                            <Col sm={5}>
                                <Input
                                    label={
                                        <FormattedMessage
                                            id="forms.labels.invoices.postcode"
                                            defaultMessage="Postcode / Zip"
                                        />
                                    }
                                    {...fields.invoice_zip}
                                    inputRef={i => (this.fieldNodes.invoice_zip = i)}
                                />
                            </Col>
                            <Col sm={5}>
                                <Input
                                    label={<FormattedMessage id="forms.labels.invoices.city" defaultMessage="City" />}
                                    {...fields.invoice_city}
                                    inputRef={i => (this.fieldNodes.invoice_city = i)}
                                />
                            </Col>
                            <Col sm={5}>
                                <Input
                                    label={
                                        <FormattedMessage
                                            id="forms.labels.invoices.county"
                                            defaultMessage="Province / County"
                                        />
                                    }
                                    {...fields.invoice_county}
                                    inputRef={i => (this.fieldNodes.invoice_county = i)}
                                />
                            </Col>
                        </Row>
                        <CountrySelector
                            {...fields.invoice_country}
                            name="invoice_country"
                            label={<FormattedMessage id="location.country" defaultMessage="Country" />}
                        />
                    </React.Fragment>
                )}
            </CompanyContext.Consumer>
        );
    }
}

export default InvoiceDetails;
