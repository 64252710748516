import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { getCurrentPortal } from './RoutingSelectors';

export const getChannels = state => state.channels;
export const getChannel = channel =>
    createSelector(getChannels, channels => {
        return channels.get(channel) || new Map();
    });

export const getChannelsInfo = createSelector(getChannels, channels => channels.get('items'));

export const getPortal = createSelector([getChannelsInfo, getCurrentPortal], (channels, portal) => {
    return channels.find(channel => channel.get('slug') === portal) || new Map();
});

export const getChannelsInfoByType = type =>
    createSelector(getChannelsInfo, channels => {
        return channels.filter(channel => channel.get('type') === type);
    });

export const getEnabledPortalFields = createSelector(getChannelsInfoByType('portal'), channels => {
    return channels.map(channel => `enable_${channel.get('id')}`).toJS();
});
