import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import Input from '@/app.components/forms/Input/Input';
import { ResourceDomainType, ResourceFilterType } from '../../types';
import ResourceFilterFormMultiple from './ResourceFilterFormMultiple';
import ResourceFilterFormNumber from './ResourceFilterFormNumber';
import ResourceFilterFormBoolean from './ResourceFilterFormBoolean';
import ResourceFilterFormRange from './ResourceFilterFormRange';
import ResourceFilterFormText from './ResourceFilterFormText';
import ResourceFilterFormSubmit from './ResourceFilterFormSubmit';
import ResourceFilterFormRadioNumber from './ResourceFilterFormRadioNumber';

type Props = {
    id: string;
    content: any;
    prefix?: string;
    onSubmit: Function;
    initialValues?: any;
    showSearch?: boolean;
    size: 'small' | 'large';
    intlKey: ResourceDomainType;
    filters: ResourceFilterType[];
    domainType: ResourceDomainType;
};

const ResourceFilterForm: React.FC<Props> = props => {
    const { id, size, prefix, content, filters, intlKey, onSubmit, showSearch, domainType, initialValues } = props;

    const intl = useIntl();

    const [query, setQuery] = useState('');

    const [tempParams, setTempParams] = useState<any | undefined>(initialValues);

    const disabled = filters.some(filter => {
        if (filter.type === 'range') {
            const minValue = tempParams?.[`min_${filter.paramName}`];
            const maxValue = tempParams?.[`max_${filter.paramName}`];

            return minValue && maxValue && maxValue < minValue;
        }

        if (filter.type === 'custom' && filter.id === 'liveable_loi_carrez') {
            const minValue = Number(tempParams?.['min_liveable_area'] || tempParams?.['min_loi_carrez_area']) || 0;
            const maxValue = Number(tempParams?.['max_liveable_area'] || tempParams?.['max_loi_carrez_area']) || 0;

            return minValue && maxValue && maxValue < minValue;
        }

        return false;
    });

    const sizes = {
        small: 'w-64',
        large: 'max-w-sm w-full',
    };

    return (
        <form
            onSubmit={evt => {
                evt.preventDefault();
                onSubmit(tempParams);
            }}
            className={`${sizes[size]} resource-filter-form`}
        >
            <FormPanel
                popup
                title={content.label}
                action={<ResourceFilterFormSubmit id={id} disabled={disabled} domainType={domainType} />}
            >
                {showSearch && (
                    <Input
                        value={query}
                        className="resource-filter-form__search"
                        onChange={event => setQuery(event.target.value)}
                        placeholder={intl.formatMessage({ id: 'forms.search.text' })}
                    />
                )}

                {filters.map(filter => {
                    const key = filter.id ? `${filter.paramName}_${filter.id || ''}` : filter.paramName;

                    if (filter.type === 'number') {
                        return (
                            <ResourceFilterFormNumber
                                id={id}
                                key={key}
                                prefix={prefix}
                                filter={filter}
                                intlKey={intlKey}
                                tempParams={tempParams}
                                setTempParams={setTempParams}
                            />
                        );
                    }

                    if (filter.type === 'range') {
                        return (
                            <ResourceFilterFormRange
                                id={id}
                                key={key}
                                filter={filter}
                                prefix={prefix}
                                intlKey={intlKey}
                                tempParams={tempParams}
                                setTempParams={setTempParams}
                            />
                        );
                    }

                    if (filter.type === 'boolean') {
                        return (
                            <ResourceFilterFormBoolean
                                id={id}
                                key={key}
                                filter={filter}
                                intlKey={intlKey}
                                tempParams={tempParams}
                                setTempParams={setTempParams}
                            />
                        );
                    }

                    if (filter.type === 'radio' || filter.type === 'checkbox') {
                        return (
                            <ResourceFilterFormMultiple
                                id={id}
                                key={key}
                                query={query}
                                filter={filter}
                                intlKey={intlKey}
                                domainType={domainType}
                                tempParams={tempParams}
                                setTempParams={setTempParams}
                            />
                        );
                    }

                    if (filter.type === 'text') {
                        return (
                            <ResourceFilterFormText
                                key={key}
                                filter={filter}
                                tempParams={tempParams}
                                setTempParams={setTempParams}
                            />
                        );
                    }

                    if (filter.type === 'custom') {
                        return (
                            <React.Fragment key={key}>
                                {filter.children && filter.children(tempParams, setTempParams)}
                            </React.Fragment>
                        );
                    }

                    if (filter.type === 'radio-number') {
                        return (
                            <ResourceFilterFormRadioNumber
                                id={id}
                                key={key}
                                query={query}
                                filter={filter}
                                intlKey={intlKey}
                                domainType={domainType}
                                tempParams={tempParams}
                                setTempParams={setTempParams}
                            />
                        );
                    }

                    return (
                        <Input
                            key={key}
                            type="text"
                            placeholder={<FormattedMessage id="general.any" defaultMessage="Any" />}
                        />
                    );
                })}
            </FormPanel>
        </form>
    );
};

export default ResourceFilterForm;
