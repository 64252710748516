import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Link from '@/app.components/elements/Link';
import ButtonLink from '@/app.components/elements/Buttons/ButtonLink';
import { SideMenuIcon } from '@/app.components/navigations/SideMenu/SideMenuIcon';

export default class SideMenuLink extends Component {
    static propTypes = {
        active: PropTypes.bool,
        checked: PropTypes.bool,
        count: PropTypes.number,
        description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        fuzzyMatch: PropTypes.bool,
        hidden: PropTypes.bool,
        icon: PropTypes.string,
        iconActive: PropTypes.string,
        label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        location: PropTypes.object,
        onClick: PropTypes.func,
        project: PropTypes.bool,
        textOnly: PropTypes.bool,
        title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        to: PropTypes.string,
        testId: PropTypes.string,
        extra: PropTypes.element,
    };

    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    static defaultProps = {
        count: 0,
        fuzzyMatch: false,
        hidden: false,
        textOnly: false,
        project: false,
    };

    shouldComponentUpdate(nextProps) {
        return (
            !this.props.location ||
            (this.props.location && this.props.location !== nextProps.location) ||
            this.props.title !== nextProps.title ||
            this.props.label !== nextProps.label
        );
    }

    onClick = evt => {
        // blur the element,
        // so it behaves like Safari
        if (evt.target) {
            evt.target.blur();
        }

        if (this.props.onClick) {
            this.props.onClick(evt);
        }
    };

    renderLink(icon, label, extra) {
        const { to, title, textOnly, testId } = this.props;
        icon = textOnly ? null : icon;
        const classes = classnames({ 'c-side-menu-title': title });

        if (to) {
            return (
                <Link to={to} className={classes} onClick={this.onClick} data-testid={testId}>
                    {icon} {label} {extra}
                </Link>
            );
        }

        return (
            <ButtonLink onClick={this.onClick} className={classes} data-testId={testId}>
                {icon} {label} {extra}
            </ButtonLink>
        );
    }

    render() {
        const { to, title, label, fuzzyMatch, project, hidden, description, extra, count, testId } = this.props;
        let active = this.props.active || (to && this.context.router.isActive(to, !fuzzyMatch));

        if (hidden) {
            return null;
        }

        const linkClasses = classnames({
            active,
            'c-side-menu--project': project,
        });

        if (title && !label) {
            return (
                <li className={linkClasses} data-testid={testId}>
                    <h2 className="c-side-menu-title">{title}</h2>
                </li>
            );
        }

        // Compute appropriate icon
        let icon = this.props.icon || String(label || '').toLowerCase();
        const activeIcon = active && this.props.iconActive;
        icon = classnames({
            [icon]: !activeIcon,
            [activeIcon]: activeIcon,
        });
        const hasCount = Boolean(count && count > 0);

        return (
            <li className={linkClasses}>
                {this.renderLink(
                    <SideMenuIcon icon={icon} checked={this.props.checked} />,
                    <span className="c-side-menu-label">
                        {hasCount && `${count} `}
                        {label}
                        {description && <br />}
                        {description && <small>{description}</small>}
                    </span>,
                    extra,
                )}
            </li>
        );
    }
}
