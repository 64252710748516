// @ts-nocheck
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { IntlProvider } from 'react-intl-sweepbright';
import { getInitialLocale, getMessagesForLocale, loadMessages } from '@/app.utils/services/Helpers/locales';
import { getUserLocale } from '@/app.redux/selectors/UsersSelectors';
import { configMoment } from '@/app.config/moment';

interface LocaleContextValue {
    locale: string;
    setLocale(locale: string): void;
}

const LocaleContext = React.createContext<LocaleContextValue>({ locale: getInitialLocale(), setLocale() {} });

export function LocaleProvider({ children }) {
    const initialLocale = React.useMemo(getInitialLocale, []);
    const [selectedLocale, setSelectedLocale] = useLocalStorage<string>('user:locale', initialLocale);
    const userLocale = useSelector(getUserLocale);

    useEffect(() => {
        if (userLocale) {
            setSelectedLocale(userLocale);
        }
    }, [userLocale, setSelectedLocale]);

    const value = useMemo<LocaleContextValue>(
        () => ({
            locale: userLocale ?? selectedLocale,
            setLocale: setSelectedLocale,
        }),
        [selectedLocale, setSelectedLocale, userLocale],
    );

    return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
}

export function useLocale(): string {
    return React.useContext(LocaleContext).locale;
}

export function useSelectLocale(): (locale: string) => void {
    return React.useContext(LocaleContext).setLocale;
}

const getMomentNormalizedLocale = (locale?: string): string => {
    if (!locale) {
        return 'en';
    }
    const localeLowerCase = locale.toLowerCase();
    const normalizedLocale = localeLowerCase.startsWith('fr') ? 'fr' : localeLowerCase;

    return normalizedLocale;
};

const Localized: React.FunctionComponent = ({ children }) => {
    const locale = useLocale();

    const messages = React.useMemo(() => getMessagesForLocale(locale, loadMessages()), [locale]);

    // keep moment js localized as well
    const momentLocale = useMemo(() => {
        return getMomentNormalizedLocale(locale);
    }, [locale]);
    useMemo(() => moment.locale(momentLocale), [momentLocale]);
    useLayoutEffect(() => {
        let isActive = true;
        // the en locale is available
        // automatically, but the rest we need to load them
        if (momentLocale && momentLocale !== 'en') {
            import(`moment/locale/${momentLocale}`).then(() => {
                if (isActive) {
                    configMoment();
                    moment.locale(momentLocale);
                }
            });
        } else {
            configMoment();
        }

        return () => {
            isActive = false;
        };
    }, [momentLocale]);

    return (
        <IntlProvider locale={locale} messages={messages} textComponent="span">
            {children}
        </IntlProvider>
    );
};

export default Localized;
