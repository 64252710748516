import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Alert } from 'react-bootstrap';
import { Input } from '@/app.components';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { Label } from '@/graphql/generated/types';
import { CompanyLabelsContext } from '../context';

type Props = {
    show: boolean;
};

const CompanyLabelsModal: React.FC<Props> = props => {
    const { show } = props;

    const { refetch, activeEntity, setVisible, setActiveEntity, setVisibleDelete, addLabel, updateLabel } = useContext(
        CompanyLabelsContext,
    );

    const [tempEntity, setTempEntity] = useState<Label>(activeEntity || { id: '', name: '', color: '#CDCDDC' });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const intl = useIntl();

    const handleSubmit = event => {
        event.preventDefault();

        setLoading(true);

        const activeFunction = activeEntity?.id ? updateLabel : addLabel;

        const baseObject = {
            id: tempEntity?.id,
            name: tempEntity?.name,
            color: tempEntity?.color,
            description: tempEntity?.description,
        };

        activeFunction({
            variables: {
                input: {
                    ...baseObject,
                },
            },
        })
            .then(() => {
                refetch();

                setError(undefined);
                setVisibleDelete(false);
                setVisible(null);
            })
            .catch(err => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleClose = () => {
        setVisible(null);
        setActiveEntity(undefined);
    };

    const mode = activeEntity?.id ? 'update' : 'create';

    const content = {
        title: intl.formatMessage({ id: `contact.labels.modal.${mode}` }),
        name: intl.formatMessage({ id: 'contact.labels.modal.name' }),
        color: intl.formatMessage({ id: 'contact.labels.modal.color' }),
        description: intl.formatMessage({ id: 'contact.labels.modal.description' }),
        delete: intl.formatMessage({ id: 'general.action.delete' }),
        cancel: intl.formatMessage({ id: 'general.action.cancel' }),
        save: intl.formatMessage({ id: 'general.action.save' }),
    };

    const isEdit = mode === 'update';

    const isDisabled = loading || Boolean(!tempEntity?.name || !tempEntity?.color);

    return (
        <Modal show={show} onHide={handleClose}>
            <form noValidate onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title>{content.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <>
                        {error && <Alert bsStyle="danger">{error}</Alert>}

                        <Input
                            type="text"
                            label={content.name}
                            value={tempEntity?.name || ''}
                            onChange={event => {
                                const newValue = event?.target?.value || '';

                                setTempEntity({ ...tempEntity, name: newValue.substring(0, 50) });
                            }}
                        />

                        <Row>
                            <Col sm={13}>
                                <Input
                                    type="text"
                                    label={content.color}
                                    value={tempEntity?.color || ''}
                                    onChange={event => {
                                        setTempEntity({ ...tempEntity, color: event?.target?.value || '' });
                                    }}
                                />
                            </Col>

                            <Col sm={2} style={{ marginTop: '24px' }}>
                                <div className="form-group">
                                    <input
                                        key="color"
                                        type="color"
                                        value={tempEntity?.color}
                                        style={{ marginLeft: 'auto' }}
                                        className="c-color-picker__field form-control"
                                        onChange={event => {
                                            setTempEntity({
                                                ...tempEntity,
                                                color: event?.target?.value?.toUpperCase() || '',
                                            });
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Input
                            type="autosize-textarea"
                            label={content.description}
                            value={tempEntity?.description || ''}
                            onChange={event => {
                                const newValue = event?.target?.value || '';

                                setTempEntity({ ...tempEntity, description: newValue.substring(0, 100) });
                            }}
                        />
                    </>
                </Modal.Body>

                <Modal.Footer>
                    <div className="pull-left">
                        {isEdit && (
                            <Button
                                variant="danger"
                                disabled={loading}
                                onClick={() => {
                                    setVisible(null);
                                    setVisibleDelete(true);
                                }}
                            >
                                {content.delete}
                            </Button>
                        )}
                    </div>
                    <Button onClick={handleClose} disabled={loading}>
                        {content.cancel}
                    </Button>
                    <Button type="submit" variant="primary" disabled={isDisabled}>
                        {content.save}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CompanyLabelsModal;
