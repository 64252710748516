import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage } from 'react-intl-sweepbright';
import { useModal } from '@/app.components/modals/AbstractModal';
import Input from '../../../app.components/forms/Input/Input';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';

const ContactsSearchModal = (props, ref) => {
    const { fields } = props;
    const { handleClose, getModalProps } = useModal(false, ref);

    const onSubmit = event => {
        event.preventDefault();

        handleClose();
        props.onSubmit();
    };

    const onFilterByInterestChange = event => {
        fields.filterByInterest.onChange(event);
        if (!event.target.checked) {
            fields.interest.onChange('');
        }
    };

    return (
        <Modal {...getModalProps()}>
            <form onSubmit={onSubmit}>
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="modals.contacts_search.action.title" defaultMessage="Filter contacts" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormPanel title="Show">
                        <label className="radio-inline">
                            <input type="radio" {...fields.type} value="all" checked={fields.type.value === 'all'} />{' '}
                            <FormattedMessage
                                id="modals.contacts_search.contact_type_panel.all_label"
                                defaultMessage="All"
                            />
                        </label>
                        <label className="radio-inline">
                            <input
                                type="radio"
                                {...fields.type}
                                value="vendor"
                                checked={fields.type.value === 'vendor'}
                            />{' '}
                            <FormattedMessage
                                id="modals.contacts_search.contact_type_panel.vendors_label"
                                defaultMessage="Vendors"
                            />
                        </label>
                        <label className="radio-inline">
                            <input
                                type="radio"
                                {...fields.type}
                                value="investor"
                                checked={fields.type.value === 'investor'}
                            />{' '}
                            <FormattedMessage
                                id="modals.contacts_search.contact_type_panel.investors_label"
                                defaultMessage="Investors"
                            />
                        </label>
                        <label className="radio-inline">
                            <input type="radio" {...fields.type} value="lead" checked={fields.type.value === 'lead'} />{' '}
                            <FormattedMessage
                                id="modals.contacts_search.contact_type_panel.leads_label"
                                defaultMessage="Leads"
                            />
                        </label>
                    </FormPanel>
                    <FormPanel
                        title={
                            <FormattedMessage
                                id="modals.search-contacts.filter.negotiation.title"
                                defaultMessage="Buy / Rent"
                            />
                        }
                    >
                        <label className="radio-inline">
                            <input
                                type="radio"
                                {...fields.negotiation}
                                value="all"
                                checked={fields.negotiation.value === 'all'}
                            />
                            <FormattedMessage id="search.contacts.filters.negotiation.all" defaultMessage="All" />
                        </label>
                        <label className="radio-inline">
                            <input
                                type="radio"
                                {...fields.negotiation}
                                value="sale"
                                checked={fields.negotiation.value === 'sale'}
                            />
                            <FormattedMessage id="search.contacts.filters.negotiation.sale" defaultMessage="Buy" />
                        </label>
                        <label className="radio-inline">
                            <input
                                type="radio"
                                {...fields.negotiation}
                                value="let"
                                checked={fields.negotiation.value === 'let'}
                            />
                            <FormattedMessage id="search.contacts.filters.negotiation.let" defaultMessage="Rent" />
                        </label>
                    </FormPanel>
                    <FormPanel title="Interest Level">
                        <Input
                            label="Filter by Interest Level"
                            type="checkbox"
                            {...fields.filterByInterest}
                            onChange={onFilterByInterestChange}
                        />
                        {fields.filterByInterest.value && [
                            <label key="no-interest" className="radio-inline bc-radio-poor">
                                <input
                                    type="radio"
                                    {...fields.interest}
                                    value="no_interest"
                                    checked={fields.interest.value === 'no_interest'}
                                />
                                <FormattedMessage
                                    id="modal.contacts_search.filtering.interest.no_interest"
                                    defaultMessage="No interest"
                                />
                            </label>,
                            <label key="info" className="radio-inline bc-radio-fair">
                                <input
                                    type="radio"
                                    {...fields.interest}
                                    value="info"
                                    checked={fields.interest.value === 'info'}
                                />{' '}
                                <FormattedMessage
                                    id="modal.contacts_search.filtering.interest.info"
                                    defaultMessage="Info"
                                />
                            </label>,
                            <label key="visit" className="radio-inline bc-radio-good">
                                <input
                                    type="radio"
                                    {...fields.interest}
                                    value="visit"
                                    checked={fields.interest.value === 'visit'}
                                />{' '}
                                <FormattedMessage
                                    id="modal.contacts_search.filtering.interest.visit"
                                    defaultMessage="Visit"
                                />
                            </label>,
                            <label key="bid" className="radio-inline bc-radio-mint">
                                <input
                                    type="radio"
                                    {...fields.interest}
                                    value="bid"
                                    checked={fields.interest.value === 'bid'}
                                />{' '}
                                <FormattedMessage
                                    id="modal.contacts_search.filtering.interest.bid"
                                    defaultMessage="Bid"
                                />
                            </label>,
                            <label key="deal" className="radio-inline bc-radio-new">
                                <input
                                    type="radio"
                                    {...fields.interest}
                                    value="deal"
                                    checked={fields.interest.value === 'deal'}
                                />{' '}
                                <FormattedMessage
                                    id="modal.contacts_search.filtering.interest.deal"
                                    defaultMessage="Deal"
                                />
                            </label>,
                        ]}
                    </FormPanel>
                    <FormPanel title="Archived Contacts">
                        <Input label="Show Archived" type="checkbox" {...fields.archived} />
                    </FormPanel>
                </Modal.Body>
                <Modal.Footer>
                    <div className="pull-left">
                        <Button variant="danger" onClick={props.onReset}>
                            <FormattedMessage id="buttons.reset" defaultMessage="Reset" />
                        </Button>
                    </div>
                    <div className="pull-right">
                        <Button onClick={handleClose}>
                            <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                        </Button>
                        <Button type="submit" variant="primary">
                            <FormattedMessage id="buttons.apply" defaultMessage="Apply" />
                        </Button>
                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

ContactsSearchModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
};

export default React.forwardRef(ContactsSearchModal);
