import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import { useTeams } from '@/new.domains/teams/hooks/useTeams';

type Option = { label: string; value: string };

type InnerProps = {
    options: Option[];
    value: string | null;
    onChange: (value: string) => void;
};

const OfficeSelector: React.FunctionComponent<InnerProps> = ({ value, onChange }) => {
    const { loading, teams, handleFetchMore } = useTeams({});

    return (
        <div key="select" style={{ minWidth: 200 }}>
            <Select
                simpleValue
                value={value}
                onChange={onChange}
                clearable={false}
                onScroll={isScrollEnd => {
                    if (isScrollEnd && !loading) {
                        handleFetchMore();
                    }
                }}
                options={teams.map(el => {
                    return { label: el.name, value: el.id };
                })}
                placeholder={<FormattedMessage id="contact_sheet.select_team" defaultMessage="Select a team" />}
            />
        </div>
    );
};

export default OfficeSelector;
