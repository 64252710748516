// @ts-nocheck
import compose from 'recompose/compose';
import { reduxForm as form, FormProps } from 'redux-form';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl-sweepbright';
import { FormFields } from '@/../shared/utils/types';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import NumberedStep from '@/app.components/modals/Channels/elements/NumberedStep';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import helpLinks from '@/app.data/helpLinks';
import { Validator } from '../../../app.utils/services';
import Input from '../../forms/Input/Input';
import PortalConfigModalBody from './PortalConfigModalBody';
import { nameValidationRule } from './utils';

type Props = {
    fields: FormFields<{
        accessToken: string;
        name: string;
    }>;
    portal: Map<string, any>;
    editing: boolean;
    close: (evt?: any) => void;
    showNameField?: boolean;
    handleSubmit: (evt: FixMeType) => void;
};

const RightmoveImpl: React.FunctionComponent<Props & FormProps> = ({ fields, handleSubmit, editing, ...props }) => (
    <ErrorBoundary>
        <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
            <div className="space-y-4">
                <NumberedStep
                    step={1}
                    title={<FormattedMessage id="channels.activation_step.title" defaultMessage="Find your details" />}
                >
                    <p className="text-muted m-0 py-2 eading-none">
                        <a href={helpLinks.rightmove} target="_blank" rel="noopener noreferrer">
                            <FormattedMessage id="channels.fill_out_form_link" defaultMessage="Fill out this form" />
                        </a>
                        <FormattedMessage
                            id="channels.send_email.details_request"
                            defaultMessage=" to request your details from {portal}."
                            values={{ portal: 'Rightmove' }}
                        />
                    </p>
                </NumberedStep>
                <NumberedStep
                    step={2}
                    title={<FormattedMessage id="channels.details_step.title" defaultMessage="Fill in your details" />}
                    last
                >
                    <Input
                        label={<FormattedMessage id="channels.account_key.label" defaultMessage="Account Key" />}
                        type="text"
                        {...fields.accessToken}
                        disabled={editing}
                    />

                    {props.showNameField && (
                        <Input
                            {...fields.name}
                            label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                            help={
                                <FormattedMessage
                                    id="channels.name.description"
                                    defaultMessage="This is the name you will see in the Publish section of SweepBright."
                                />
                            }
                            placeholder={
                                <FormattedMessage
                                    id="pages.publish.channel_name.rightmove"
                                    defaultMessage="Rightmove"
                                />
                            }
                        />
                    )}
                </NumberedStep>
            </div>
        </PortalConfigModalBody>
    </ErrorBoundary>
);

const RightMove = compose(
    form(
        {
            form: 'channels/portal',
            formKey: 'rightmove',
            fields: ['accessToken', 'name'],
            validate: Validator.validateWithRules(
                {
                    accessToken: {
                        type: 'string',
                        required: true,
                        allowEmpty: false,
                        messages: {
                            required: 'general.validation.field_required',
                            allowEmpty: 'general.validation.field_required',
                        },
                    },
                    name: nameValidationRule,
                },
                { humanizeErrorMessage: false },
            ),
        },
        createStructuredSelector({
            // if the multiple accounts feature is on,
            // we show the name field, otherwise, it is invisible to the user
            // even though the name is still set
            showNameField: getFeature('portals.multipleAccounts.enabled'),
        }),
    ),
)(RightmoveImpl);

RightMove.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
            accessToken
        }
    `,
};

export default RightMove;
