import React from 'react';
import produce from 'immer';
import { useQuery } from '@apollo/react-hooks';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import ContactCard from '@/shared/contacts/ContactCard';
import { PROPERTY_TIMELINE_CONTACT, PROPERTY_UNIT_TIMELINE_CONTACT } from '@/app.routing/routes';
import EmptyState from '@/app.components/empty/EmptyState';
import { GET_INTERACTED_CONTACTS_QUERY } from '@/graphql/queries/properties/getInteractedLeads';
import { ContactActions } from '@/app.components';
import './PropertyLeads.scss';

export default React.forwardRef(function PropertyInteractedVendorList(
    {
        propertyId,
        projectId,
    }: {
        propertyId: string;
        projectId: string | undefined;
    },
    forwardedRef,
) {
    const { loading, data, fetchMore } = useQuery(GET_INTERACTED_CONTACTS_QUERY, {
        variables: {
            propertyId,
            type: 'VENDOR',
            splitByInterest: false,
        },
        skip: !propertyId,
        errorPolicy: 'ignore',
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    });

    const isEmpty = !loading && data?.estate.contacts?.nodes.length === 0;
    const vendorsConnection = data?.estate.contacts;

    React.useImperativeHandle(
        forwardedRef,
        () => {
            return {
                refresh: fetchMoreBefore,
            };

            function fetchMoreBefore() {
                fetchMore({
                    variables: {
                        before: vendorsConnection.pageInfo.startCursor,
                        after: vendorsConnection.pageInfo.startCursor,
                    },
                    updateQuery: updateFetchMoreBeforeQuery,
                });
            }
        },
        [vendorsConnection, fetchMore],
    );

    if (isEmpty) {
        return (
            <EmptyState
                title={
                    <FormattedMessage
                        id="property.activity.timeline_section.empty.title"
                        defaultMessage="Whoops, no activity yet"
                    />
                }
                body={
                    <FormattedMessage
                        id="property.activity.vendors_section.empty.subtitle"
                        defaultMessage="Activities per vendor will show up here."
                    />
                }
            />
        );
    }

    return (
        <VendorsSection
            vendors={vendorsConnection?.nodes ?? []}
            propertyId={propertyId}
            projectId={projectId}
            loading={loading}
            hasMore={vendorsConnection?.pageInfo.hasNextPage}
            onFetchMore={() => {
                fetchMore({
                    variables: {
                        afterInterestedLeads: vendorsConnection.pageInfo.endCursor,
                    },
                    updateQuery: updateFetchMoreQuery,
                });
            }}
        />
    );
});

function VendorsSection({
    vendors,
    propertyId,
    projectId,
    hasMore,
    loading,
    onFetchMore,
}: {
    vendors: any[];
    propertyId: string;
    projectId: string | undefined;
    loading: boolean;
    hasMore: boolean;
    onFetchMore: () => void;
}) {
    return (
        <>
            <ul className="bc-bordered-list">
                {vendors.map(vendor => {
                    return (
                        <li key={vendor.id}>
                            <ContactCard
                                contact={vendor}
                                to={
                                    projectId
                                        ? PROPERTY_UNIT_TIMELINE_CONTACT(projectId, propertyId, vendor.id)
                                        : PROPERTY_TIMELINE_CONTACT(propertyId, vendor.id)
                                }
                                actions={[<ContactActions key="contact_actions" contactId={vendor.id} />]}
                            />
                        </li>
                    );
                })}
            </ul>
            {hasMore && !loading ? (
                <div className="text-center">
                    <Button onClick={onFetchMore} variant="link">
                        <FormattedMessage id="interaction.contact_list.load_more" defaultMessage="Load More" />
                    </Button>
                </div>
            ) : null}
        </>
    );
}

function updateFetchMoreQuery(prevResult, { fetchMoreResult }) {
    return produce(prevResult, draft => {
        draft.estate.contacts.nodes = draft.estate.contacts.nodes.concat(fetchMoreResult.estate.contacts.nodes);

        draft.estate.contacts.pageInfo.endCursor = fetchMoreResult.estate.contacts.pageInfo.endCursor;
        draft.estate.contacts.pageInfo.hasNextPage = fetchMoreResult.estate.contacts.pageInfo.hasNextPage;
    });
}

function updateFetchMoreBeforeQuery(prevResult, { fetchMoreResult }) {
    return produce(prevResult, draft => {
        // afterInterestedProperties
        draft.estate.contacts.nodes = fetchMoreResult.estate.contacts.nodes.concat(draft.estate.contacts.nodes);

        // update the start cursor if more data is available
        // if it is null then we dont update it because there
        // was not more data, but it could be on the future
        if (fetchMoreResult.estate.contacts.pageInfo.startCursor) {
            draft.estate.contacts.pageInfo.startCursor = fetchMoreResult.estate.contacts.pageInfo.startCursor;
        }
    });
}
