import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import { getPropertiesSubtypes } from '@/new.domains/properties/utils';

type PropertySubtypesProps = {
    subType: {
        value: string;
        onChange: (value: string | null) => void;
    };
    type: string;
    country: string;
};

export const PropertySubtypes: React.FunctionComponent<PropertySubtypesProps> = ({ subType, type, country }) => {
    const subtypes = getPropertiesSubtypes(country)[type];

    return (
        <Select
            {...({} as any)}
            value={subType.value}
            onChange={option => {
                if (option?.value) {
                    subType.onChange(option.value);
                } else {
                    subType.onChange(null);
                }
            }}
            isSearchable={false}
            placeholder={<FormattedMessage id="property.subtype.placeholder" defaultMessage="No subtype specified" />}
            clearable
            options={subtypes.map(subtype => {
                return {
                    value: subtype,
                    label: (
                        <FormattedMessage
                            id={`pages.properties.property_subtype_panel.options.${subtype}`}
                            defaultMessage={`pages.properties.property_subtype_panel.options.${subtype}`}
                        />
                    ),
                };
            })}
        />
    );
};

export default PropertySubtypes;
