import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, IndexRedirect } from 'react-router';
import featureFlaggedPage from '@/app.utils/Decorators/featureFlaggedPage';
import { CompanyAdmin } from '../Middlewares';

import * as integrations from '../../app.data/Integrations';

import * as Pages from '../allPagesComponents';
import * as Routes from '../routes';

export default function settingsRoutes() {
    return (
        <Route path={Routes.INTEGRATIONS_AND_APPS} component={CompanyAdmin(Pages.Settings)}>
            <IndexRedirect to={Routes.INTEGRATIONS} />
            <Route path={Routes.INTEGRATIONS} component={Pages.SettingsIntegrations}>
                <IndexRedirect to={integrations.categories.first().get('id')} />
                {integrations.categories.map(category => {
                    const items = category.get('integrations');

                    return (
                        <Route
                            path={category.get('id')}
                            key={category.get('id')}
                            component={featureFlaggedPage(`integrations-${category.get('id')}`)(
                                Pages.SettingsIntegrationsCategory,
                            )}
                        >
                            {items.size && <IndexRedirect to={items.first().get('id')} />}
                            {items.map(integration => {
                                const key = integration.get('id');

                                return (
                                    <Route
                                        key={key}
                                        path={key}
                                        // @ts-ignore
                                        title={<FormattedMessage id={`integrations.${key}.title`} />}
                                        component={featureFlaggedPage(`integrations-${key}`)(
                                            Pages.SettingsIntegrationsCategoryFeature,
                                        )}
                                    />
                                );
                            })}
                        </Route>
                    );
                })}
            </Route>
            <Route path={Routes.MOVEBOTLER} component={Pages.SettingsMovebotler} />
        </Route>
    );
}
