import React from 'react';
import { InjectedIntl } from 'react-intl';
import Input from '@/app.components/forms/Input/Input';
import Select, { SelectVariant } from '@/app.components/selects/AdvancedSelect/Select';
import { injectIntl } from 'react-intl-sweepbright';
import countries from '@/app.data/Countries';

type Props = {
    value: string;
    name?: string;
    label?: React.ReactNode;
    onChange?: (event: any) => void;
    error?: any;
    touched?: boolean;
    variant?: SelectVariant;
    intl?: InjectedIntl;
    handleBlur?: any;
};

function CountrySelector({ intl, value, error, name, onChange, label, variant, touched, handleBlur }: Props) {
    const localizedCountries = countries.map((item: { label: string; value: string }) => {
        return {
            ...item,
            // we can not use a formatted message here because we want the options to be searchable
            label: intl?.formatMessage({ id: `country.labels.${item.value}`, defaultMessage: item.label }),
        };
    });

    return (
        <Input label={label} error={error} name={name} touched={touched}>
            <Select
                onChange={onChange}
                value={value}
                clearable={false}
                options={localizedCountries}
                id={name}
                name={name}
                variant={variant}
                testId="country-selector"
                simpleValue
                hasError={touched && error}
                {...(handleBlur && { onInputBlur: handleBlur })}
            />
        </Input>
    );
}

export default injectIntl(CountrySelector);
