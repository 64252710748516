import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Col, Collapse, Row, FormGroup, ControlLabel } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl-sweepbright';
import Button from '@/app.components/elements/Buttons/Button';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import { AVAILABLE_USER_LOCALES_CODES } from '@/app.utils/services/Helpers/locales';
import { InputForFormik } from '@/app.components/forms/Input/Input';
import Icon from '@/app.components/icons/Icon';
import TeamsSelect from '@/shared/teams/TeamsSelect';

const ONE_BY_ONE = 'one-by-one';
const BULK = 'bulk';

type Props = {
    bulk?: boolean;
    mode: string;
    setMode: Function;
    inviteErrors: { mode: string; title: string; field: string }[];
};

const MembersAddFormBlock: React.FC<Props> = props => {
    const { bulk, mode, setMode, inviteErrors } = props;

    const formik = useFormikContext();
    const { values, setFieldValue, errors, setFieldError }: any = formik;

    const languageOptions = AVAILABLE_USER_LOCALES_CODES.map(locale => {
        return {
            value: locale,
            label: <FormattedMessage id={`general.locales.${locale}`} defaultMessage={`general.locales.${locale}`} />,
        };
    });

    const handleAddInvite = () => {
        setFieldValue('field', [...values.field, { email: '', firstName: '', lastName: '' }]);
    };

    const content = {
        duplicate: (
            <FormattedMessage
                id={`form.invitations.${mode}.error.duplicate`}
                defaultMessage={`form.invitations.${mode}.error.duplicate`}
            />
        ),
        invalid: (
            <FormattedMessage
                id={`form.invitations.${mode}.error.invalid`}
                defaultMessage={`form.invitations.${mode}.error.invalid`}
            />
        ),
    };

    useEffect(() => {
        if (inviteErrors) {
            const filteredErrors = inviteErrors.filter(error => error.mode === mode);

            if (mode === 'bulk') {
                const bulkTitle = filteredErrors[0].title;

                const title =
                    (bulkTitle.includes('must be a valid email address') && content.invalid) ||
                    (bulkTitle.includes('is already active for a') && content.duplicate) ||
                    bulkTitle;

                setFieldError('bulkField', title);
            }

            if (mode === 'one-by-one') {
                filteredErrors.forEach(el => {
                    const oneTitle = el.title;

                    const title =
                        (oneTitle.includes('must be a valid email address') && content.invalid) ||
                        (oneTitle.includes('is already active for a') && content.duplicate) ||
                        oneTitle;

                    setFieldError(`field[${el.field.replace(/\D+/g, '')}].email`, title);
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, inviteErrors]);

    return (
        <div>
            <FormGroup style={{ maxWidth: '350px' }}>
                <Col>
                    <ControlLabel>
                        <FormattedMessage id="general.language.title" defaultMessage="Language" />
                    </ControlLabel>
                </Col>
                <Col>
                    <Select
                        name="language"
                        {...({} as any)}
                        isSearchable={false}
                        value={values.language}
                        options={languageOptions}
                        onChange={newValue => {
                            setFieldValue('language', newValue.value);
                        }}
                    />
                </Col>
            </FormGroup>

            <FormGroup style={{ maxWidth: '350px' }}>
                <Col>
                    <ControlLabel>
                        <FormattedMessage id="general.team.title" defaultMessage="Team" />
                    </ControlLabel>
                </Col>
                <Col>
                    <TeamsSelect
                        value={values.officeId}
                        onChange={newValue => {
                            setFieldValue('officeId', newValue);
                        }}
                    />
                </Col>
            </FormGroup>

            <Collapse in={mode === ONE_BY_ONE}>
                <div>
                    {values?.field?.map((_invite, index) => {
                        return (
                            <Row key={index}>
                                <Col sm={7}>
                                    <InputForFormik
                                        type="text"
                                        name={`field[${index}].email`}
                                        label={
                                            <FormattedMessage
                                                id="form.invitations.one-by-one.email.label"
                                                defaultMessage="E-mail Address"
                                            />
                                        }
                                        placeholder={
                                            <FormattedMessage
                                                id="form.invitations.one-by-one.email.placeholder"
                                                defaultMessage="name@example.com"
                                            />
                                        }
                                    />
                                </Col>
                                <Col sm={4}>
                                    <InputForFormik
                                        type="text"
                                        name={`field[${index}].firstName`}
                                        label={
                                            <FormattedMessage
                                                id="form.invitations.one-by-one.first_name.label"
                                                defaultMessage="First Name"
                                            />
                                        }
                                        placeholder={
                                            <FormattedMessage
                                                id="form.invitations.one-by-one.first_name.placeholder"
                                                defaultMessage="Optional"
                                            />
                                        }
                                    />
                                </Col>
                                <Col sm={4}>
                                    <InputForFormik
                                        type="text"
                                        name={`field[${index}].lastName`}
                                        label={
                                            <FormattedMessage
                                                id="form.invitations.one-by-one.last_name.label"
                                                defaultMessage="Last Name"
                                            />
                                        }
                                        placeholder={
                                            <FormattedMessage
                                                id="form.invitations.one-by-one.last_name.placeholder"
                                                defaultMessage="Optional"
                                            />
                                        }
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                    <p>
                        <Button variant="link" onClick={handleAddInvite} icon={<Icon icon="add" />}>
                            <FormattedMessage
                                id="form.invitations.one-by-one.add-another"
                                defaultMessage="Add Another"
                            />
                        </Button>
                        {bulk && (
                            <span>
                                {' '}
                                <FormattedMessage id="form.invitations.one-by-one.or" defaultMessage="or" />{' '}
                                <Button variant="link" onClick={() => setMode(BULK)}>
                                    <FormattedMessage
                                        id="form.invitations.one-by-one.add-many"
                                        defaultMessage="Add Many"
                                    />
                                </Button>
                            </span>
                        )}
                    </p>
                </div>
            </Collapse>

            <Collapse in={mode === BULK}>
                <div>
                    <InputForFormik
                        name="bulkField"
                        type="autosize-textarea"
                        label={<FormattedMessage id="form.invitations.bulk.label" defaultMessage="E-mail Addresses" />}
                        placeholder={
                            <FormattedMessage
                                id="form.invitations.bulk.placeholder"
                                defaultMessage="name@example.com, name2@example.com"
                            />
                        }
                        {...(errors?.bulkField
                            ? {}
                            : {
                                  help: (
                                      <FormattedHTMLMessage
                                          id="form.invitations.bulk.help"
                                          defaultMessage="<strong>Tip</strong>: Copy and paste a list of contacts. Please separate multiple addresses with commas!"
                                      />
                                  ),
                              })}
                    />
                    <p>
                        <Button variant="link" onClick={() => setMode(ONE_BY_ONE)}>
                            <FormattedMessage
                                id="form.invitations.one-by-one.add-one-by-one"
                                defaultMessage="Add One By One"
                            />
                        </Button>
                    </p>
                </div>
            </Collapse>
        </div>
    );
};

export default MembersAddFormBlock;
