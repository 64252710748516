import { put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { OfficesRequests } from '../../../requests/index';
import { TEAM } from '../../../app.routing/routes';
import { setOffices } from '../../actions';
import formatApiErrors from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';

export default function* onAddOffice({ office: { resolve, reject, ...attributes } }) {
    try {
        const office = yield apiCall(new OfficesRequests().store, attributes);

        yield put(setOffices([office]));

        yield put(push(TEAM(office.id)));

        resolve(office);
    } catch (response) {
        reject(formatApiErrors(response));
    }
}
