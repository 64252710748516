// @ts-nocheck
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '@/app.components/icons/Icon';
import MediaCard from '@/app.components/card/MediaCard/MediaCard';
import Price from '@/app.components/elements/Price/Price';
import { BuyerPreference, BuyerPreferenceForApartment, BuyerPreferenceForHouse } from '@/graphql/generated/types';
import SideMenuLink from '@/app.components/navigations/SideMenu/SideMenuLink';
import useOffice from '@/app.hooks/useOffice';

const propertyIconForType = {
    house: 'house',
    apartment: 'apartments',
    parking: 'parking',
    land: 'land',
    commercial: 'retail',
    office: 'office',
};

const preferenceTitleForType = {
    house: <FormattedMessage id="property.type.house" defaultMessage="House" />,
    apartment: <FormattedMessage id="property.type.apartment" defaultMessage="Apartment" />,
    parking: <FormattedMessage id="property.type.parking" defaultMessage="Parking" />,
    land: <FormattedMessage id="property.type.land" defaultMessage="Land" />,
    commercial: <FormattedMessage id="property.type.commercial" defaultMessage="Commercial property" />,
    office: <FormattedMessage id="property.type.office" defaultMessage="Office" />,
};

const renderCardTitle = (propertyType, negotiation) => (
    <span data-testid="preference_title">
        {preferenceTitleForType[propertyType]}
        {' – '}
        <FormattedMessage id={`negotiation.${negotiation.toLowerCase()}`} />
    </span>
);

export default function PreferenceCard({
    preference,
    to,
    onClick,
}: {
    to?: string;
    preference: BuyerPreference;
    onClick?: () => void;
}) {
    const { type, negotiation, budget } = preference;

    const office = useOffice();
    const currency = office.getIn(['settings', 'data', 'currency'], 'EUR');

    const image = (
        <div className="flex items-center h-full px-2">
            <Icon
                icon={propertyIconForType[type]}
                style={{ fontSize: '40px', width: 40, height: 40, color: '#8e8e93' }}
            />
        </div>
    );
    const budgetSubline = getBudgetSubline(budget, currency);
    const bedroomsSubline = getBedroomsSubline(preference);

    return (
        <MediaCard
            {...{
                image,
                title: renderCardTitle(type, negotiation),
            }}
            testId={`preference_${preference.id}`}
            subtitles={[
                <div key="preference_base">
                    {bedroomsSubline && <span>{bedroomsSubline}</span>}
                    {budgetSubline && bedroomsSubline ? <br /> : null}
                    {budgetSubline}
                </div>,
                <span key="preference_location">{getLocationSubline(preference)}</span>,
            ]}
            to={to}
            onClick={onClick}
        />
    );
}

function getBedroomsSubline(preference: BuyerPreferenceForHouse | BuyerPreferenceForApartment) {
    if (preference?.minBedrooms) {
        return (
            <FormattedMessage
                id="preferences.min_bedrooms_number"
                defaultMessage="Min. {number} {number, plural, one {bedroom}, other {bedrooms}}"
                values={{ number: parseInt(preference.minBedrooms) }}
            />
        );
    }

    return null;
}

function getBudgetSubline(budget, currency) {
    if (budget.maxPrice && budget.minPrice) {
        return (
            <FormattedMessage
                id="price.between_min_max"
                defaultMessage="Between {min} and {max}"
                values={{
                    min: <Price amount={budget.minPrice} currency={currency} />,
                    max: <Price amount={budget.maxPrice} currency={currency} />,
                }}
            />
        );
    }

    if (budget.maxPrice) {
        return (
            <FormattedMessage
                id="contact.multiple_preferences.price_description.max"
                defaultMessage="Max. {max_price}"
                values={{ max_price: <Price amount={budget.maxPrice} currency={currency} /> }}
            />
        );
    }

    if (budget.minPrice) {
        return (
            <span>
                <FormattedMessage
                    id="contact.multiple_preferences.price_description.min"
                    defaultMessage="Min. {min_price}"
                    values={{ min_price: <Price amount={budget.minPrice} currency={currency} /> }}
                />
            </span>
        );
    }

    return <FormattedMessage id="preferences.no_price_range" defaultMessage="No price range specified" />;
}

function getLocationSubline(preference: BuyerPreference) {
    if (preference.locations.totalCount === 0) {
        return <FormattedMessage id="location_preferences.empty.title" defaultMessage="No location preferences" />;
    }
    let locations = preference.locations.nodes.map(location => location.name).join(', ');
    if (preference.locations.totalCount > preference.locations.nodes.length) {
        locations += ` (${preference.locations.totalCount - preference.locations.nodes.length}+)`;
    }

    return locations;
}

export function PreferenceLink({
    preference,
    active,
    onClick,
    testId,
}: {
    to?: string;
    preference: BuyerPreference;
    active?: boolean;
    onClick?: () => void;
    testId: string;
}) {
    const { type, negotiation, budget } = preference;

    const office = useOffice();
    const currency = office.getIn(['settings', 'data', 'currency'], 'EUR');

    const budgetSubline = getBudgetSubline(budget, currency);

    return (
        <SideMenuLink
            testId={testId}
            onClick={onClick}
            active={active}
            icon={propertyIconForType[type]}
            label={renderCardTitle(type, negotiation)}
            description={
                <>
                    {budgetSubline}
                    <div className="text-muted">{getLocationSubline(preference)}</div>
                </>
            }
        />
    );
}
