// @ts-nocheck
/* eslint-disable no-shadow */
import React from 'react';
import gql from 'graphql-tag';
import { HelpBlock } from 'react-bootstrap';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage } from 'react-intl-sweepbright';
import { withToasts } from '@sweepbright/notifications';
import helpLinks from '@/app.data/helpLinks';
import PagePane from '@/app.layouts/PagePane/PagePane';
import Icon from '@/app.components/icons/Icon';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import PropertyCard, { adaptProperty } from '@/app.domains/properties/PropertyCard';
import EmptyState from '@/app.components/empty/EmptyState';
import SendMailForm from '@/app.components/modals/SendMailForm';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { getOfficeDefaultMailValues, getOfficeSetting } from '@/app.redux/selectors';
import { PROPERTY } from '@/app.routing/routes';
import Modal from '@/app.components/elements/Modal';
import VendorReportPreviewModal from '@/app.components/modals/VendorReportPreviewModal';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { ItemsList } from '@/app.domains/contacts/contacts-form/elements/PropertiesList';
import { updateConnection } from '@/graphql/utils';
import { getBugsnagClient } from '@/app.config/bugsnag';
import useCompanySetting from '@/app.hooks/useCompanySetting';

const VENDOR_REPORT_ESTATES = gql`
    query GetEstatesForVendorReport($vendorId: ID!, $page: Int) {
        contact(id: $vendorId) {
            id
            email
            subscribed
            locale
            archived
            ... on Vendor {
                lastReportSentAt
                estates(page: $page) {
                    nodes {
                        id
                        lastInteraction
                        type
                        isProject
                        negotiation
                        createdAt
                        updatedAt
                        attributes
                        archived
                        status
                        negotiator {
                            id
                            firstName
                            lastName
                            errorStatus
                        }
                    }
                    pageInfo {
                        hasNextPage
                        currentPage
                    }
                }
            }
        }
    }
`;

function Report(props) {
    const vendorId = props.params.contact;
    const { initialValues } = props;
    const { data, loading, fetchMore } = useQuery(VENDOR_REPORT_ESTATES, {
        variables: {
            vendorId,
        },
    });
    const [sendVendorReport] = useMutation(gql`
        mutation SendVendorReport($input: SendVendorReportInput!) {
            sendVendorReport(input: $input) {
                reportId
                vendor {
                    id
                    lastReportSentAt
                }
            }
        }
    `);
    const companyDefaultLocale = useCompanySetting('default_locale');
    const contact = data?.contact;
    // get all the not archived estates
    const estates = (data?.contact.estates?.nodes ?? []).filter(estate => !estate.archived);
    const pageInfo = data?.contact.estates?.pageInfo ?? { currentPage: 1, hasNextPage: false };
    const [showSendReportModal, setShowSendReportModal] = React.useState(false);
    const [showPreviewModal, setShowPreviewModal] = React.useState(false);
    const showPreview = useFeatureFlag('contacts.vendorReportPreview.enabled');

    const closeSendReportModal = () => {
        setShowSendReportModal(false);
    };

    const openSendReportModal = () => {
        setShowSendReportModal(true);
    };

    const handleSubmit = (attributes: { contents: Record<string, string> }) => {
        sendVendorReport({
            variables: {
                input: {
                    vendorId,
                    message: attributes.contents[contact.locale ?? companyDefaultLocale],
                },
            },
        }).then(
            ({ data }) => {
                const reportId = data.sendVendorReport.reportId;
                closeSendReportModal();
                props.toasts.addSuccess({
                    title: <FormattedMessage id="vendor.report.success.title" defaultMessage="Report Sent" />,
                    message: (
                        <FormattedMessage
                            id="vendor.report.success.body"
                            defaultMessage=" The vendor will receive the feedback via e-mail ({link})."
                            values={{
                                link: (
                                    <a
                                        href={window.location.origin + '/reports/' + reportId}
                                        target={!window.Cypress && '__blank'}
                                    >
                                        <FormattedMessage id="report.view" defaultMessage="View Report" />
                                    </a>
                                ),
                            }}
                        />
                    ),
                    sticky: true,
                });
            },
            err => {
                getBugsnagClient().notify(err);
                throw err;
            },
        );
    };

    const hasFeedback = estates.some(estate => {
        return estate.lastInteraction;
    });

    const handleFetchMore = () => {
        if (pageInfo.hasNextPage && !loading) {
            fetchMore({
                variables: {
                    page: pageInfo.currentPage + 1,
                },
                updateQuery: updateConnection('contact.estates'),
            });
        }
    };

    const handleClosePreviewModal = () => {
        setShowPreviewModal(false);
    };

    const handleOpenPreviewModal = () => {
        setShowPreviewModal(true);
    };

    return (
        <PagePane
            title={<FormattedMessage id="vendor.report.title" defaultMessage="Report" />}
            actions={[
                showPreview ? (
                    <Button
                        key="preview_report"
                        onClick={handleOpenPreviewModal}
                        disabled={!hasFeedback}
                        icon={<Icon icon="access" />}
                    >
                        <FormattedMessage id="vendor.report.preview" defaultMessage="Preview" />
                    </Button>
                ) : null,
                ,
                <Button
                    key="mail_report"
                    variant="primary"
                    data-testid="open_mail_report_modal"
                    onClick={openSendReportModal}
                    disabled={!contact?.email || !hasFeedback || contact?.archived}
                    icon={!contact?.archived && <Icon icon="mail" />}
                >
                    {contact?.archived ? (
                        <FormattedMessage id="contact.status.archived" defaultMessage="Archived" />
                    ) : (
                        <FormattedMessage id="vendor.report.mail" defaultMessage="Mail Report" />
                    )}
                </Button>,
            ]}
        >
            {contact?.lastReportSentAt && (
                <HelpBlock className="text-center">
                    <FormattedMessage
                        id="vendor.report.last_sent_at"
                        defaultMessage="Last sent on {date}"
                        values={{
                            date: moment(contact?.lastReportSentAt).calendar('YYYY-MM-DD'),
                        }}
                    />
                </HelpBlock>
            )}
            <FormPanel title={<FormattedMessage id="vendor.report.properties.title" defaultMessage="Properties" />}>
                <ItemsList
                    loading={loading}
                    items={estates}
                    hasMore={pageInfo.hasNextPage}
                    onFetchMore={handleFetchMore}
                    renderItem={estate => {
                        return (
                            <ErrorBoundary>
                                <PropertyCard
                                    showInteraction
                                    property={adaptProperty(estate)}
                                    to={PROPERTY(estate.id)}
                                />
                            </ErrorBoundary>
                        );
                    }}
                    getItemKey={item => item.id}
                    placeholder={
                        <EmptyState
                            title={
                                <FormattedMessage
                                    id="vendor.report.properties.empty.title"
                                    defaultMessage="Looks like this Vendor doesn't have any property feedback yet"
                                />
                            }
                            body={
                                <FormattedMessage
                                    id="vendor.report.properties.empty.body"
                                    defaultMessage="Don't worry! Find some interested leads and check again later"
                                />
                            }
                            helpLink={helpLinks.home}
                        />
                    }
                />
            </FormPanel>
            <Modal show={showSendReportModal} onHide={closeSendReportModal} autoFocus>
                <SendMailForm
                    contact={contact}
                    label={<FormattedMessage id="vendor.report.mail" defaultMessage="Mail Report" />}
                    mailTextPreset={initialValues}
                    properties={estates.map(adaptProperty)}
                    onSubmit={handleSubmit}
                    onCancel={closeSendReportModal}
                />
            </Modal>
            <VendorReportPreviewModal
                show={showPreviewModal}
                onCancel={handleClosePreviewModal}
                vendorId={props.params.contact}
            />
        </PagePane>
    );
}

const mapStateToProps = createStructuredSelector({
    defaultMessage: getOfficeSetting('email_report_description'),
    initialValues: getOfficeDefaultMailValues('email_report_description'),
});

export default compose(connect(mapStateToProps), withToasts)(Report);
