import React from 'react';
import { Map } from 'immutable';
import Shimmer from '@sweepbright/uikit/build/esm/shimmer';
import useProperty from '@/app.hooks/useProperty';
import useCompany from '@/app.hooks/useCompany';
import PropertiesImage from '@/new.domains/properties/PropertyShow/PropertyImage';
import PropertyTitle from '@/app.domains/properties/PropertyTitle';
import PropertyType from '@/app.domains/properties/PropertyType';
import Area from '@/app.domains/properties/Area';
import { FormattedMessage } from 'react-intl-sweepbright';
import { FormattedLocation } from '@/app.components/localize/FormattedLocation';
import PropertyNegotiator from '@/app.domains/properties/PropertyNegotiator';
import { Estate } from '@/graphql/generated/types';
import { adaptProperty } from '@/app.domains/properties/PropertyCard';
import StatusForm from '@/app.domains/properties/StatusForm';
import { Negotiation } from '@/app.data';
import EstateRepository from '../../../../app.utils/services/Repositories/EstateRepository';

const SimilarPropertyCard = ({ estateId, score }: { estateId: string; score: number }) => {
    // @ts-ignore
    let { property, loading } = useProperty(estateId) as { property: Estate; loading: boolean };

    const propertyAsMap = adaptProperty(property ?? {}) as Map<string, any>;

    const company = useCompany();

    // @ts-ignore
    const repository = new EstateRepository(property, company);
    const coverImage = repository.getImageUrlWithPreset('preview');

    const type = repository.getType();
    const area = repository.getLiveableArea();
    const bedrooms = repository.getNumBedrooms();

    return (
        <div data-score={score} className="shadow-md border-gray-300 border-1">
            <div className="relative h-48">
                <PropertiesImage
                    type={type}
                    size="large"
                    src={coverImage}
                    disabled={['sold', 'rented'].includes(property?.status as string)}
                />
                <div className="absolute" style={{ bottom: 5, left: 5 }}>
                    <StatusForm
                        propertyId={property.id}
                        readonly={true}
                        negotiation={property.negotiation as Negotiation}
                    />
                </div>
            </div>
            <div className="c-property-info__content">
                <Shimmer isDataLoaded={!loading}>
                    <h4 className="c-property-info__title truncate">
                        <PropertyTitle property={propertyAsMap} />
                    </h4>
                    <div className="my-2 text-lg">
                        <PropertyType property={propertyAsMap.toJS()} />
                        {!!area?.size && (
                            <span className="ml-2">
                                <Area {...area} />
                            </span>
                        )}
                        {!!bedrooms && (
                            <span className="ml-2">
                                <FormattedMessage
                                    id="property.features.bedrooms"
                                    defaultMessage="{count} {count, plural, one {bed}  other {beds}}"
                                    values={{ count: bedrooms }}
                                />
                            </span>
                        )}
                    </div>
                </Shimmer>
                <Shimmer width="80%" isDataLoaded={!loading}>
                    <p className="text-muted truncate">
                        <FormattedLocation location={propertyAsMap.getIn(['attributes', 'location'])?.toJS()} />
                    </p>
                </Shimmer>
                <PropertyNegotiator propertyId={propertyAsMap.get('id')} />
            </div>
        </div>
    );
};

export default SimilarPropertyCard;
