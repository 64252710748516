import { put } from 'redux-saga/effects';
import { EstatesRequests } from '../../../requests/index';
import { setPropertyInteractions } from '../../actions';
import withPagination from '../Helpers/withPagination';
import apiCall from '../Effects/apiCall';

export default function* onFetchPropertyInteractions({ propertyId, attributes }) {
    yield withPagination(`properties/interactions/${propertyId}`, attributes, function* onFetch(settings) {
        const interactions = yield apiCall(new EstatesRequests().interactions, propertyId, settings);
        yield put(setPropertyInteractions(propertyId, interactions.data));

        return interactions;
    });
}
