import React from 'react';
import { compose } from 'recompose';
import get from 'lodash/get';
import { FieldArray, useFormikContext } from 'formik';
import { FileData } from '@/app.utils/services/Repositories/FileRepository';
import withModals, { HasModalProps } from './withModals';

export type ResumableFile = {
    file: File;
    fileName: string;
    uniqueIdentifier: string;
    relativePath: string;
    progress: () => number; // between 0 and 1
    abort: () => void;
    cancel: () => void;
    retry: () => void;
    bootstrap: () => void;
    isUploading: () => boolean;
    isComplete: () => boolean;
    markChunksCompleted: () => void;
};

export type WithFileUploadProps = {
    handleAddFile: (resumable: ResumableFile, attr?: any) => void;
    handleFileDeleted: () => void;
    handleFileError: () => void;
    handleFileProgress: () => void;
    handleUploadFailed: () => void;
    handleUploadSuccess: () => void;
    handleFileAttributesUpdated: (values: FileData) => void;
    appendNewUploads: boolean;
    handleRemoveFile: (id: string) => void;
} & HasModalProps;

function withFormikFileUpload<TProps = {}>(uploadFieldName: string) {
    return function(Component: React.ComponentType<TProps>) {
        return function WrapperComponent(props: any) {
            const { values, setFieldValue } = useFormikContext();
            const fileValues: Array<{ id: string }> = get(values, uploadFieldName, []);

            return (
                <FieldArray
                    name={uploadFieldName}
                    render={arrayHelpers => {
                        const handleUpdateProgress = (resumable: ResumableFile) => {
                            const indexOfFileValueToUpdate = fileValues.findIndex(
                                fileValue => fileValue.id === resumable.uniqueIdentifier,
                            );

                            if (indexOfFileValueToUpdate >= 0) {
                                setFieldValue(
                                    `${uploadFieldName}[${indexOfFileValueToUpdate}].progress`,
                                    resumable.progress() * 100.0,
                                );
                            }
                        };

                        const handleDeleteFiles = (ids: string[]) => {
                            const newFileValues = fileValues.filter(file => !ids.includes(file.id));
                            setFieldValue(uploadFieldName, newFileValues);
                        };

                        const handleDeleteFile = (id: string) => {
                            handleDeleteFiles([id]);
                        };

                        const handleAddFile = (resumable: ResumableFile, properties?: Record<string, any>) => {
                            const fileField = {
                                filename: resumable.fileName,
                                id: resumable.uniqueIdentifier,
                                private: false,
                                progress: resumable.progress() * 100.0,
                                uploaded_at: new Date().toISOString(),
                                'content-type': resumable.file.type,
                                ...properties,
                            };

                            if (props.appendNewUploads) {
                                arrayHelpers.push(fileField);
                            } else {
                                // insert the file at the beginning
                                arrayHelpers.unshift(fileField);
                            }
                        };

                        const handleUpdateFile = (file: FileData) => {
                            const indexOfFileValueToUpdate = fileValues.findIndex(
                                fileValue => fileValue.id === file.id,
                            );

                            if (indexOfFileValueToUpdate >= 0) {
                                Object.keys(file).forEach((fileAttributeName: keyof FileData) => {
                                    setFieldValue(
                                        `${uploadFieldName}[${indexOfFileValueToUpdate}].${fileAttributeName}`,
                                        file[fileAttributeName],
                                    );
                                });
                            }
                        };

                        return (
                            <Component
                                handleAddFile={handleAddFile}
                                handleRemoveFile={handleDeleteFile}
                                handleRemoveFiles={handleDeleteFiles}
                                handleFileError={handleDeleteFile}
                                handleFileProgress={handleUpdateProgress}
                                handleFileAttributesUpdated={handleUpdateFile}
                                handleFileDeleted={(file: FileData) => {
                                    const indexOfFileValueToUpdate = fileValues.findIndex(
                                        fileValue => fileValue.id === file.id,
                                    );

                                    if (indexOfFileValueToUpdate >= 0) {
                                        setFieldValue(`${uploadFieldName}[${indexOfFileValueToUpdate}].deleted`, true);
                                    }
                                }}
                                handleUploadSuccess={() => props.setDisabled?.(false)}
                                handleUploadFailed={() => props.setDisabled?.(false)}
                                {...props}
                            />
                        );
                    }}
                />
            );
        };
    };
}

export default function withFileUpload<T>(uploadFieldName: string) {
    return compose(withFormikFileUpload<T>(uploadFieldName), withModals);
}
