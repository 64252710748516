import React from 'react';
import { useIntl } from 'react-intl';
import { ContactCardData } from '@/new.domains/contacts/types';

type Props = {
    contact?: ContactCardData;
};

const ContactCardType: React.FC<Props> = props => {
    const { contact } = props;

    const intl = useIntl();

    const contactType = contact?.type?.toLowerCase();

    const label = intl.formatMessage({
        id: `contact.type.${contactType}`,
        defaultMessage: contactType,
    });

    return <span className={`contact-card-type${contactType === 'vendor' ? ' active' : ''}`}>{label}</span>;
};

export default ContactCardType;
