import React from 'react';
import { ConfigProvider } from 'antd';
import { hot } from 'react-hot-loader/root';
import { Store } from 'redux';
import { Router, browserHistory } from 'react-router';
import Localized, { LocaleProvider } from '@/app.components/localize/Localized';
import getRoutes from '@/app.routing';

import './styles/global.scss';

const App: React.FunctionComponent<{
    store: Store<object>;
}> = ({ store }) => {
    const routesHistory = browserHistory;
    const routes = getRoutes(store);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#17a8e6',
                    fontFamily: 'LFT Etica, sans-serif',
                },
            }}
        >
            <LocaleProvider>
                <Localized>
                    <Router history={routesHistory}>{routes}</Router>
                </Localized>
            </LocaleProvider>
        </ConfigProvider>
    );
};

export default hot(App);
