import gql from 'graphql-tag';

export const GET_CONTACTS_OPTIONS_QUERY = gql`
    query GetContactsOptions {
        contactsOptions {
            sorts {
                key
            }
        }
    }
`;
