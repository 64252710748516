import { EstatesRequests } from '../../../requests/index';
import formatApiErrors from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';

export default function* onCreateDocument({ estateId, attributes: { resolve, reject, ...attributes } }) {
    try {
        yield apiCall(new EstatesRequests().document, estateId, attributes.type);
        resolve();
    } catch (response) {
        reject(formatApiErrors(response, '_error'));
    }
}
