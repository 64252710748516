import gql from 'graphql-tag';

export const GET_MATCHING_FLAGS = gql`
    query GetMatchingFlags($input: MatchingFlagsInput!) {
        matchingFlags(input: $input) {
            data {
                propertyId
                contactId
                flags
            }
        }
    }
`;
