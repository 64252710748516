import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { useClickOutside } from '@sweepbright/use-click-outside';
import Popover, { positionDefault } from '@reach/popover';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import Formik from '@/app.components/forms/helpers/Formik';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { InputForFormik } from '@/app.components/forms/Input/Input';
import './EditableCell.scss';

const EditableCell: FC<{
    value: any;
    label: ReactNode;
    onSubmit: (values: any) => void;
    type?: string;
    addonAfter?: ReactNode;
    className?: string;
    pattern?: string;
    testId?: string;
}> = ({ value, label, onSubmit, children, type = 'text', addonAfter, className, pattern, testId }) => {
    const fieldRef = React.useRef(null);
    const inputRef = React.useRef<Maybe<HTMLInputElement>>(null);
    const popupRef = React.useRef(null);
    const [editing, setEditing] = React.useState(false);

    React.useEffect(() => {
        if (editing) {
            setTimeout(() => inputRef.current?.focus(), 0);
        }
    }, [editing]);

    useClickOutside(popupRef, { onClickOutside: () => setEditing(false) });

    return (
        <>
            <div
                onClick={() => setEditing(!editing)}
                ref={fieldRef}
                data-testid={testId}
                className={classNames(
                    'c-editable-cell',
                    'truncate',
                    {
                        'c-editable-cell--active': editing,
                    },
                    className,
                )}
            >
                {children}
            </div>
            {editing && (
                <Popover targetRef={fieldRef} position={positionDefault} role="dialog">
                    {/*
                    // @ts-ignore */}
                    <Formik
                        initialValues={{ field: value }}
                        onSubmit={async values => {
                            try {
                                await onSubmit(values);
                            } catch (err) {
                                // TODO: do something with this
                                // but probably this should be handled in the
                                // place the component is used
                            } finally {
                                setEditing(false);
                            }
                        }}
                    >
                        {({ handleSubmit, isSubmitting }) => {
                            return (
                                <div ref={popupRef} className="c-editable-cell__popover">
                                    <form onSubmit={handleSubmit}>
                                        <FormPanel title={<span>Edit {label}</span>} popup>
                                            <InputForFormik
                                                addonAfter={addonAfter}
                                                type={type}
                                                inputRef={inputRef}
                                                name="field"
                                                pattern={pattern}
                                            />
                                            <hr style={{ margin: '8px -16px' }} />
                                            <div className="c-editable-cell__popover-footer">
                                                <Button disabled={isSubmitting} onClick={() => setEditing(false)}>
                                                    <FormattedMessage
                                                        id="general.action.cancel"
                                                        defaultMessage="Cancel"
                                                    />
                                                </Button>
                                                <Button disabled={isSubmitting} variant="primary" type="submit">
                                                    {isSubmitting ? (
                                                        <FormattedMessage
                                                            id="form.status.saving"
                                                            defaultMessage="Saving..."
                                                        />
                                                    ) : (
                                                        <FormattedMessage id="form.status.save" defaultMessage="Save" />
                                                    )}
                                                </Button>
                                            </div>
                                        </FormPanel>
                                    </form>
                                </div>
                            );
                        }}
                    </Formik>
                </Popover>
            )}
        </>
    );
};

export default EditableCell;
