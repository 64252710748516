import * as navigationEvents from './navigation';
import * as sideNavigationEvents from './side_navigation';
import * as propertiesNavigation from './properties_navigation';
import * as propertyEvents from './property';
import * as agencyWebsite from './agency_website';
import * as matchingEvents from './matching';
import * as searchEvents from './search';
import * as contactEvents from './contact';
import * as sorting from './sorting';
import * as mapEvents from './map';
import * as dealDetails from './deal_details';
import * as propertyStatus from './property_status';
import * as propertyPublishingEvents from './property/publishing';
import * as propertyActivityEvents from './property/activity';
import * as propertyOffersEvents from './property/offers';
import * as contactActivityEvents from './contact/activity';
import * as contactAPreferenceEvents from './contact/preferences';
import * as activityCardEvents from './activity_card';
import * as userEvents from './user';

export const events = {
    ...navigationEvents,
    ...sideNavigationEvents,
    ...propertiesNavigation,
    ...propertyEvents,
    ...agencyWebsite,
    ...propertyPublishingEvents,
    ...matchingEvents,
    ...searchEvents,
    ...contactEvents,
    ...sorting,
    ...mapEvents,
    ...dealDetails,
    ...propertyStatus,
    ...propertyActivityEvents,
    ...propertyOffersEvents,
    ...activityCardEvents,
    ...contactActivityEvents,
    ...contactAPreferenceEvents,
    ...userEvents,
};
