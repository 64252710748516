import gql from 'graphql-tag';
import { OfferCardFragment } from '@/graphql/fragments/offerCard';

export const GET_ESTATE_OFFERS = gql`
    query GetEstateOffers($estateId: ID!, $archived: Boolean!) {
        estate(id: $estateId) {
            id
            offers(archived: $archived) {
                ...OfferCardFragment
            }
        }
    }

    ${OfferCardFragment}
`;
