// @ts-nocheck
import React from 'react';
import classnames from 'classnames';
import { ButtonGroup, HelpBlock, MenuItem } from 'react-bootstrap';
import Dropdown from '@sweepbright/uikit/build/esm/dropdown';
import { FormattedMessage } from 'react-intl';
import Button from '@/app.components/elements/Buttons/Button';
import ToggleButton from '@/app.components/elements/Buttons/ToggleButton';

import Icon, { IconName } from '../../icons/Icon';
import Toolbar from '../../elements/Toolbar/Toolbar';
import { FormPanelHeader } from './elements/FormPanelHeader';

export type ActionItem = {
    hidden?: boolean;
    label?: React.ReactNode;
    handler?: () => void;
    divider?: boolean;
};

type OnActionType = (() => void) | ActionItem[];

type FormPanelActionProps = {
    onAction?: OnActionType;
    action: React.ReactNode;
    actionIcon?: IconName;
};

const FormPanelAction: React.FunctionComponent<FormPanelActionProps> = ({
    action,
    disableAction,
    onAction,
    actionIcon,
}) => {
    // If we have multiple actions, render that
    if (Array.isArray(onAction)) {
        return (
            <Dropdown id={actionIcon} className="pull-right">
                <ToggleButton variant="link" icon={<Icon icon="add" />} bsRole="toggle">
                    {action}
                </ToggleButton>
                <Dropdown.Menu>
                    {onAction.map((item, key) => {
                        if (item.divider) {
                            return <MenuItem key={key} divider />;
                        }

                        if (item.hidden) {
                            return null;
                        }

                        return (
                            <MenuItem disabled={disableAction} key={key} onClick={disableAction ? null : item.handler}>
                                {item.label}
                            </MenuItem>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    const isASlot = typeof action === 'object' && action != null && action.type.displayName !== 'FormattedMessage';
    if (isASlot) {
        return <Toolbar.Right>{action}</Toolbar.Right>;
    }

    function renderAction() {
        if (typeof action === 'object' && action !== null && action.type.displayName === 'FormattedMessage') {
            return (
                <FormattedMessage {...action.props}>
                    {(localizedAction: string) => {
                        return <span className="btn-label">{localizedAction}</span>;
                    }}
                </FormattedMessage>
            );
        }

        return <span className="btn-label">{action}</span>;
    }

    return (
        <Button
            variant="link"
            onClick={onAction}
            data-testid="action-btn"
            disabled={disableAction}
            icon={onAction && actionIcon && <Icon icon={actionIcon} data-testid="action-icon" />}
        >
            {renderAction()}
        </Button>
    );
};

FormPanelAction.defaultProps = {
    actionIcon: 'add',
};

const FormPanel: React.FunctionComponent<{
    title?: React.ReactNode;
    action?: React.ReactNode;
    disableAction?: boolean;
    onAction?: OnActionType;
    centered?: boolean;
    horizontal?: boolean;
    flex?: boolean;
    popup?: boolean;
    collapse?: boolean;
    style?: React.CSSProperties;
    help?: React.ReactNode;
    actionIcon?: IconName;
    className?: string;
    containerClassName?: string;
    extraActions?: React.ReactNode;
    testId?: string;
}> = ({
    testId,
    title,
    children,
    action,
    disableAction,
    onAction,
    centered = false,
    horizontal = false,
    flex,
    collapse,
    popup,
    style,
    help,
    actionIcon,
    containerClassName,
    className,
    extraActions,
}) => {
    const classes = classnames('c-form-panel', containerClassName, {
        'c-form-panel-flex': flex,
        'c-form-panel--collapse': collapse,
        'c-form-panel--popup': popup,
    });

    const bodyClasses = classnames('c-form-panel-body', className, {
        'form-horizontal': horizontal,
        'center-block-contents': centered,
    });

    const withToolbar: boolean = React.isValidElement(extraActions) || Boolean(action) || React.isValidElement(help);

    const toolbarActions = (
        <>
            {React.isValidElement(extraActions) && extraActions}
            {action && (
                <FormPanelAction
                    action={action}
                    onAction={onAction}
                    actionIcon={actionIcon}
                    disableAction={disableAction}
                />
            )}
            {help && (
                <ButtonGroup className="pull-right">
                    <HelpBlock>{help}</HelpBlock>
                </ButtonGroup>
            )}
        </>
    );

    return (
        <section className={classes} style={style} data-testid={testId}>
            <FormPanelHeader title={title} toolbarActions={toolbarActions} withToolbar={withToolbar} />
            <div className={bodyClasses}>{children}</div>
        </section>
    );
};

export default FormPanel;
