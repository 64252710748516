import gql from 'graphql-tag';

export const GET_LEGAL_ENTITY = gql`
    query getLegalEntity($officeId: ID!, $id: ID!) {
        office(id: $officeId) {
            id
            legalEntity(officeId: $officeId, id: $id) {
                data {
                    name
                    id
                    office_id
                }
            }
        }
    }
`;
