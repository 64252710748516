// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { createStructuredSelector } from 'reselect';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import CompanyAside from '@/app.components/asides/CompanyAside';
import { getCompany, getStatus, getPlans } from '../../app.redux/selectors';
import { fetchCompany, fetchPlans, fetchCompanyCard } from '../../app.redux/actions';

@connect(
    createStructuredSelector({
        plans: getPlans,
        company: getCompany,
        status: getStatus,
    }),
    { fetchCompany, fetchPlans, fetchCompanyCard },
)
export default class SubscriptionLayout extends Component {
    static propTypes = {
        children: PropTypes.node,
        company: PropTypes.instanceOf(Map),
        fetchCompanyCard: PropTypes.func,
        fetchPlans: PropTypes.func,
        location: PropTypes.object.isRequired,
        plans: PropTypes.instanceOf(List),
        status: PropTypes.instanceOf(Map),
    };

    componentDidMount() {
        if (this.props.plans.isEmpty()) {
            this.props.fetchPlans(this.props.company.get('id'));
        }

        this.props.fetchCompanyCard(this.props.company.get('id'));
    }

    render() {
        const { company, children, status, plans } = this.props;

        if (plans.isEmpty()) {
            return null;
        }

        return (
            <LayoutContainer>
                <CompanyAside props={this.props} />
                <LayoutColumn>
                    {company.has('id') &&
                        React.cloneElement(children, {
                            company,
                            status,
                            plans,
                        })}
                </LayoutColumn>
            </LayoutContainer>
        );
    }
}
