// @ts-nocheck
import React from 'react';
import Loadable from 'react-loadable';
import { Modal } from 'react-bootstrap/lib';
import { ChannelAccount } from '@/graphql/generated/types';
import useProperty from '@/app.hooks/useProperty';
import { getBugsnagClient } from '@/app.config/bugsnag';

function Loading({ error }) {
    if (error) {
        getBugsnagClient().notify(error);
    }

    return null;
}

// code split the modals
const GenericPublicationConfigModal = Loadable({
    loader: () => import('./GenericPublicationConfigModal'),
    loading: Loading,
});

const ImmowebPublicationConfigModal = Loadable({
    loader: () => import('./ImmowebPublicationConfigModal'),
    loading: Loading,
});

const TwitterPublicationConfigModal = Loadable({
    loader: () => import('./TwitterPublicationConfigModal'),
    loading: Loading,
});

const FacebookPublicationConfigModal = Loadable({
    loader: () => import('./FacebookPublicationConfigModal'),
    loading: Loading,
});

const AgentFeedsConfigModal = Loadable({
    loader: () => import('./AgentFeedsConfigModal'),
    loading: Loading,
});

const FrenchPortalConfigModal = Loadable({
    loader: () => import('./FrenchPortalConfigModal'),
    loading: Loading,
});

export function PublicationConfigModalInner({
    action,
    account,
    propertyId,
    ...props
}: {
    account: ChannelAccount;
    onCancel: () => void;
    onSubmit: (values: any) => void;
    propertyId: string;
    action: 'update' | 'publish';
    initialValues: FixMeType;
}) {
    const { property } = useProperty(propertyId);

    switch (account.channel.id) {
        case 'facebook': {
            return (
                <FacebookPublicationConfigModal
                    accountName={account.name}
                    property={property}
                    action={action}
                    propertyId={propertyId}
                    account={account}
                    {...props}
                />
            );
        }
        case 'immoweb':
        case 'immoweb-v2': {
            return (
                <ImmowebPublicationConfigModal
                    accountName={account.name}
                    action={action}
                    channel={account.channel}
                    property={property}
                    {...props}
                />
            );
        }
        case 'twitter': {
            return (
                <TwitterPublicationConfigModal
                    accountName={account.name}
                    channel={account.channel}
                    property={property}
                    action={action}
                    {...props}
                />
            );
        }
        case 'zoopla': {
            return (
                <AgentFeedsConfigModal
                    accountName={account.name}
                    channel={account.channel}
                    property={property}
                    action={action}
                    {...props}
                />
            );
        }
        case 'rightmove': {
            return (
                <AgentFeedsConfigModal
                    accountName={account.name}
                    channel={account.channel}
                    property={property}
                    action={action}
                    {...props}
                />
            );
        }
        case 'onthemarket': {
            return (
                <AgentFeedsConfigModal
                    accountName={account.name}
                    channel={account.channel}
                    property={property}
                    action={action}
                    {...props}
                />
            );
        }
        case 'ubiflow': {
            return (
                <FrenchPortalConfigModal
                    accountName={account.name}
                    channel={account.channel}
                    property={property}
                    action={action}
                    account={account}
                    {...props}
                />
            );
        }
        default:
            return (
                <GenericPublicationConfigModal
                    accountName={account.name}
                    action={action}
                    property={property}
                    channel={account.channel}
                    {...props}
                />
            );
    }
}

export default function PublicationConfigModal({ show, onCancel, account, onSubmit, propertyId, action, ...props }) {
    return (
        <Modal show={show} onHide={onCancel}>
            <PublicationConfigModalInner
                show={show}
                account={account}
                onCancel={onCancel}
                onSubmit={onSubmit}
                propertyId={propertyId}
                action={action}
                {...props}
            />
        </Modal>
    );
}
