import classNames from 'classnames';
import { Set, fromJS, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import ListChecker from '@sweepbright/uikit/build/esm/listchecker';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { isLoading, getTotalPages, getCurrentPage } from '@/app.utils/services/Helpers/pagination';
import filterExceptions from '../../app.utils/services/Helpers/filterExceptions';
import InfiniteScroll from '../../app.layouts/InfiniteScroll';

const BorderedList = ({
    className,
    limitHeight,
    name,
    field,
    options,
    excluded,
    highlights,
    multiple,
    selected,
    setSelected,
    pagination = Map(),
    ...rest
}) => {
    const onMultipleChange = value => {
        selected = !selected.length && field.value && field.value.length ? new Set([...field.value]) : selected;
        selected = field.value.includes(value) ? selected.delete(value) : selected.add(value);

        setSelected(selected);
        field.onChange(selected.toJS());
    };

    options = filterExceptions(options, excluded);

    const isInfiniteScrolling = Boolean(rest.onFetch);

    const items = options
        .map(({ option, value }, key) => {
            if (!field) {
                return <li key={key}>{option}</li>;
            }

            const checked = multiple ? field.value.includes(value) : Boolean(field.value && field.value === value);

            const onChange = multiple ? onMultipleChange.bind(null, value) : field.onChange;

            return (
                <li key={key}>
                    <input
                        {...field}
                        onChange={onChange}
                        type={multiple ? 'checkbox' : 'radio'}
                        id={name + key}
                        value={value}
                        checked={checked}
                    />
                    <label htmlFor={name + key}>{option}</label>
                </li>
            );
        })
        .toList();

    return (
        <ListChecker
            multi={multiple && field}
            className={classNames(
                'bc-bordered-list',
                {
                    'c-util-height-md': limitHeight && limitHeight !== 'lg',
                    'c-util-height-lg': limitHeight === 'lg',
                    'overflow-auto': isInfiniteScrolling,
                    'c-list-checker--with-highlights': highlights,
                },
                className,
            )}
        >
            {isInfiniteScrolling ? (
                <InfiniteScroll
                    currentPage={getCurrentPage(pagination)}
                    totalPages={getTotalPages(pagination)}
                    {...rest}
                >
                    {items}
                </InfiniteScroll>
            ) : (
                items
            )}
            {isInfiniteScrolling && isLoading(fromJS(pagination)) && <LoadingIndicator />}
        </ListChecker>
    );
};

BorderedList.defaultProps = {
    excluded: [],
};

BorderedList.propTypes = {
    className: PropTypes.string,
    currentPage: PropTypes.number,
    excluded: PropTypes.array,
    field: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    highlights: PropTypes.bool,
    limitHeight: PropTypes.oneOf(['md', 'lg']),
    multiple: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onFetch: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    resetPagination: PropTypes.func,
    selected: PropTypes.instanceOf(Set),
    pagination: PropTypes.instanceOf(Map),
    setSelected: PropTypes.func,
    totalPages: PropTypes.number,
};

export default compose(
    withState('selected', 'setSelected', ownProps => new Set((ownProps.field && ownProps.field.value) || [])),
)(BorderedList);
