// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@sweepbright/uikit';
import { FormattedMessage, injectIntl } from 'react-intl-sweepbright';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import MultilingualTextAreas from '@/app.domains/channels/Website/elements/MultilingualTextAreas';
import LocalizedInput from '@/app.components/forms/LocalizedInput';
import companySettings, { propTypes } from '../../../app.utils/Decorators/companySettings';
import { isObjectEmpty } from '../../../app.utils/services/Helpers/emptyValidators';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import FormPane from '../../../app.components/forms/FormPane';
import { languages } from '../../../app.data/Localization';

const fieldsPerLanguage = ['description', 'about', 'tagline', 'new_properties'];

let formFields = ['languages', 'languages_group'];

languages.forEach(language => {
    formFields.push(`languages.${language.value}`);
    formFields = formFields.concat(fieldsPerLanguage.map(field => `${field}_${language.value}`));
});

function validate(attributes) {
    const errors = {};

    if (isObjectEmpty(attributes.languages)) {
        errors.languages_group = 'required';
    }

    return errors;
}

@companySettings('text', formFields, {}, {}, validate)
@injectIntl
export default class TextSettingsSection extends Component {
    static propTypes = {
        ...propTypes,
        route: PropTypes.shape({
            title: PropTypes.string.isRequired,
        }),
    };

    state = {
        activeTabIdx: 0,
    };

    renderInputs = (field, label) => {
        const { fields } = this.props;

        return (
            <LocalizedInput
                field={field}
                label={label}
                fields={fields}
                labelClassName="col-sm-4"
                wrapperClassName="col-sm-8"
            />
        );
    };

    render() {
        const { fields, route, errors, status, handleSubmit } = this.props;
        const { activeTabIdx } = this.state;

        return (
            <FormPane
                title={route.title}
                status={status}
                onSubmit={handleSubmit}
                disabled={Object.keys(errors).length > 0}
            >
                <Tabs index={activeTabIdx} onChange={activeTabIdx => this.setState({ activeTabIdx })}>
                    <Tabs.TabList>
                        <Tabs.Tab>
                            <FormattedMessage id="forms.titles.company_settings.text.labels" defaultMessage="Labels" />
                        </Tabs.Tab>
                        <Tabs.Tab>
                            <FormattedMessage
                                id="forms.titles.company_settings.text.about_us"
                                defaultMessage="About Us"
                            />
                        </Tabs.Tab>
                        <Tabs.Tab>
                            <FormattedMessage
                                id="forms.titles.company_settings.text.services"
                                defaultMessage="Services"
                            />
                        </Tabs.Tab>
                    </Tabs.TabList>
                    <Tabs.TabPanels>
                        <Tabs.TabPanel>
                            {activeTabIdx === 0 && (
                                <ErrorBoundary>
                                    {fields.languages_group && fields.languages_group.valid && (
                                        <FormPanel
                                            key="labels"
                                            title={
                                                <FormattedMessage
                                                    id="forms.titles.company_settings.text.labels"
                                                    defaultMessage="Labels"
                                                />
                                            }
                                            horizontal
                                        >
                                            {this.renderInputs(
                                                'tagline',
                                                <FormattedMessage
                                                    id="forms.labels.company_settings.text.tagline"
                                                    defaultMessage="Tagline"
                                                />,
                                            )}
                                            <hr />
                                            {this.renderInputs(
                                                'new_properties',
                                                <FormattedMessage
                                                    id="forms.labels.company_settings.text.properties"
                                                    defaultMessage="New Properties"
                                                />,
                                            )}
                                        </FormPanel>
                                    )}
                                </ErrorBoundary>
                            )}
                        </Tabs.TabPanel>
                        <Tabs.TabPanel>
                            {activeTabIdx === 1 && (
                                <ErrorBoundary>
                                    <MultilingualTextAreas
                                        fieldName="about"
                                        label={
                                            <FormattedMessage
                                                id="forms.labels.company_settings.text.about"
                                                defaultMessage="About Your Agency"
                                            />
                                        }
                                        fields={fields}
                                    />
                                </ErrorBoundary>
                            )}
                        </Tabs.TabPanel>
                        <Tabs.TabPanel>
                            {activeTabIdx === 2 && (
                                <ErrorBoundary>
                                    <MultilingualTextAreas
                                        fieldName="description"
                                        label={
                                            <FormattedMessage
                                                id="forms.labels.company_settings.text.description"
                                                defaultMessage="Describe Your Services"
                                            />
                                        }
                                        fields={fields}
                                    />
                                </ErrorBoundary>
                            )}
                        </Tabs.TabPanel>
                    </Tabs.TabPanels>
                </Tabs>
            </FormPane>
        );
    }
}
