// @ts-nocheck
import { fromJS, List, Map } from 'immutable';
import moment from 'moment';
import { InjectedIntl } from 'react-intl';
import { ReactNode } from 'react';
import { formatPrice, MoneyFormatOptions } from '@/app.utils/services/Helpers/priceFormatting';
import { PropertyType } from '@/app.data/Properties';
import { DEFAULT_LOCALE } from '@sweepbright/webapp-shared/Config/intl';
import {
    PropertyInternalType,
    PropertyFieldVisibilityContext,
    CanGetVisibilityContext,
} from '@/app.data/FieldVisibilityContext';
import { toTitle } from '../String';
import { supportedCountries } from '../Fields/rulesMatcher';
import AbstractRepository from './AbstractRepository';
import FileRepository from './FileRepository';

type FallbackOrIntl = { fallback: React.ReactNode } | { intl: InjectedIntl };
type PriceOptionalArgs = { options?: MoneyFormatOptions; type?: string } & FallbackOrIntl;

export default class EstateRepository extends AbstractRepository implements CanGetVisibilityContext {
    property: Map<string, any>;

    constructor(property: Map<string, any> | Record<string, any> = Map(), company?: Map<string, any>) {
        super();
        if (!property) {
            throw new TypeError('property argument is undefined');
        }

        this.property = fromJS(property);
        this.setLanguageFromCompany(company);
    }

    getId() {
        return this.property.get('id');
    }

    getComposedTitle(intl: InjectedIntl) {
        const propertyType = this.property.get('type', '');
        const propertyTypeIntlKey = `property.type.${propertyType}`;
        const localizedType = propertyType
            ? intl.formatMessage({
                  id: propertyTypeIntlKey,
                  defaultMessage: toTitle(propertyType),
              })
            : '';

        return this.isProject()
            ? `${this.getTitle(intl.locale, this.language)} - ${localizedType} ${intl.formatMessage({
                  id: 'project.label',
                  defaultMessage: 'Project',
              })}`
            : `${this.getPrice({ intl })} - ${localizedType}`;
    }

    getTitle(locale: string, fallbackLocale: string): string {
        return (
            this.property.getIn(['attributes', 'description', 'title', locale]) ||
            this.property.getIn(['attributes', 'description', 'title', fallbackLocale], '')
        );
    }

    getLocationComponents(): Map<string, any> | string {
        const location = this.property.getIn(['attributes', 'location'], Map());
        if (!location.get('city') && !location.get('street')) {
            return 'No location defined';
        }

        return location;
    }

    getBucketLocation(): { first_line: string; second_line: string } | string {
        const location = this.getLocationComponents();
        if (typeof location === 'string') {
            return location;
        }

        return {
            first_line: this.concatenateComponents([location.get('street'), location.get('number')], ' '),
            second_line: this.concatenateComponents([location.get('postal_code'), location.get('city')], ' '),
        };
    }

    getUnit() {
        const location = this.property.getIn(['attributes', 'location'], Map());

        return location.get('box');
    }

    getCurrency(): string {
        return this.property.getIn(['attributes', 'price', 'published_price', 'currency']) || 'EUR';
    }

    getPrice(args: PriceOptionalArgs): React.ReactNode {
        const { type = 'published_price', options } = args;
        if (this.hasPrice(type)) {
            return this.getPriceValue({ type, options });
        }

        if ('fallback' in args) {
            return args.fallback;
        }

        return this.isSale()
            ? args.intl?.formatMessage({ id: 'property.price_not_set', defaultMessage: 'Price not set' })
            : args.intl?.formatMessage({ id: 'property.rent_not_set', defaultMessage: 'Rent not set' });
    }

    getPriceAmount(type = 'published_price') {
        if (this.hasPrice(type)) {
            return this.property.getIn(['attributes', 'price', type, 'amount']);
        }

        return '';
    }

    hasPrice(type: string = 'published_price') {
        return Boolean(this.property.getIn(['attributes', 'price', type, 'amount'], false));
    }

    getPriceValue({ type = 'published_price', options }: { options?: MoneyFormatOptions; type?: string } = {}) {
        const amount = this.property.getIn(['attributes', 'price', type, 'amount'], 0);
        const currency = this.getCurrency();

        const isInt = Number.isInteger(amount);

        const precisionLength = !isInt ? `${amount || 0}`.split('.')[1]?.length : 0;

        const formattedAmount = formatPrice(
            { amount, currency },
            { ...options, precision: options?.precision || precisionLength },
        );

        return formattedAmount;
    }

    getRentPeriod() {
        if (this.isSale()) {
            throw new Error('Property is not for rent');
        }

        return this.property.get('rent_period', 'month').toLowerCase();
    }

    private _getFirstImageUuid() {
        const imageIndex = this.property
            .getIn(['attributes', 'images'], Map())
            .sortBy(image => {
                const modifier = image.get('private') ? 1000000000 : 0;

                return image.get('ordinal') + modifier;
            })
            .keySeq()
            .first();

        return imageIndex;
    }

    getImage(preset: string = 'thumbnail'): string | null {
        const imageUuuid = this._getFirstImageUuid();

        return imageUuuid
            ? new FileRepository(this.property.get('id'), { id: imageUuuid }).getFileUrl('images', preset)
            : null;
    }

    getImageUrlWithPreset(preset: string = 'original'): string | null {
        return this.getImage(preset);
    }

    isProject(): boolean {
        return this.property.get('internal_type') === 'project';
    }

    getOfficeId(): string {
        return this.property.get('office_id');
    }

    isUnit(): boolean {
        return this.property.get('internal_type') === 'unit';
    }

    getRootEstate() {
        return this.isUnit() ? this.property.getIn(['project', 'data'], this.property) : this.property;
    }

    isSale() {
        return this.getNegotiation() === 'sale';
    }

    isLet() {
        return this.getNegotiation() === 'let';
    }

    isType(...types: string[]) {
        return types.includes(this.property.get('type'));
    }

    getSimpleTitle() {
        const type = toTitle(this.property.get('type'));
        const city = this.property.getIn(['attributes', 'location', 'city']);

        return city ? `${type} in ${city}` : type;
    }

    getNegotiation() {
        return this.property.get('negotiation');
    }

    getLocalizedDescription(locale: string, fallbackLocale: string = DEFAULT_LOCALE) {
        const description =
            this.property.getIn(['attributes', 'description', 'description', locale]) ||
            this.property.getIn(['attributes', 'description', 'description', fallbackLocale]);

        return description;
    }

    getDescription(intl) {
        const parts: ReactNode[] = [];
        const bedrooms = this.property.getIn(['attributes', 'structure', 'bedrooms']);

        if (bedrooms) {
            parts.push(
                intl.formatMessage(
                    {
                        id: 'property.bedrooms.count',
                        defaultMessage: '{bedroom_count} {bedroom_count, plural, zero {beds} one {bed} other {beds}}',
                    },
                    { bedroom_count: bedrooms },
                ),
            );
        }

        if (this.isProject()) {
            const units = this.property
                .getIn(['properties', 'data'], List())
                .filter(unit => !unit.get('is_archived', false));

            parts.push(
                intl.formatMessage(
                    {
                        id: 'property.units.count',
                        defaultMessage: '{units_count} {units_count, plural, zero {Units} one {Unit} other {Units}}',
                    },
                    { units_count: units.size },
                ),
            );

            if (units.size) {
                const minPrice = new EstateRepository(units.min(getPrice)).getPrice({ intl });
                const maxPrice = new EstateRepository(units.max(getPrice)).getPrice({ intl });

                parts.push(minPrice);

                if (minPrice !== maxPrice) {
                    parts.push(maxPrice);
                }
            }
        } else {
            parts.push(this.getPrice({ intl }));
        }

        return parts.join(' - ');
    }

    getCustomPriceDescription(intl) {
        const parts: string[] = [];
        const bedrooms = this.property.getIn(['attributes', 'structure', 'bedrooms']);

        if (bedrooms) {
            parts.push(
                intl.formatMessage(
                    {
                        id: 'property.bedrooms.count',
                        defaultMessage: '{bedroom_count} {bedroom_count, plural, zero {beds} one {bed} other {beds}}',
                    },
                    { bedroom_count: bedrooms },
                ),
            );
        }

        parts.push(this.getCustomPrice());

        return parts.join(' - ');
    }

    getCustomPrice(): string {
        return this.property.getIn(['attributes', 'price', 'custom_price'], null) || null;
    }

    getInteractionsFor(contactId: string) {
        return this.property.get('interactions', List()).filter(interaction => {
            return interaction.getIn(['contact', 'data', 'id']) === contactId;
        });
    }

    vendors() {
        return this.property.getIn(['vendors', 'data'], List());
    }

    buyers() {
        return this.property.getIn(['buyers', 'data'], List());
    }

    getStatus() {
        return this.property.getIn(['attributes', 'status'], '');
    }

    getMandate() {
        const mandate = this.property.getIn(['attributes', 'settings', 'mandate'], Map());

        if (mandate.get('start_date') && mandate.get('end_date')) {
            return this.concatenateComponents(
                [
                    moment(mandate.get('start_date')).format('D MMMM YYYY'),
                    moment(mandate.get('end_date')).format('D MMMM YYYY'),
                ],
                ' - ',
            );
        }

        return '';
    }

    getLotSize() {
        switch (this.property.get('type')) {
            case 'apartment':
            case 'office':
            case 'parking':
            case 'commercial':
                return this.property.getIn(
                    ['attributes', 'structure', 'gross_area'],
                    Map({
                        size: null,
                        units: 'sq_m',
                    }),
                );
            default:
                return this.property.getIn(['attributes', 'structure', 'plot_area'], Map());
        }
    }

    getLiveableArea():
        | {
              size: number;
              size_description: string;
              units: string;
          }
        | undefined {
        return fromJS(propertyArea(this.property.toJS()))?.toJS();
    }

    hasLocation() {
        const fields = ['street', 'postal_code', 'city', 'country'];

        let hasLocation = true;
        fields.forEach(field => {
            if (!this.property.getIn(['attributes', 'location', field])) {
                hasLocation = false;
            }
        });

        return hasLocation;
    }

    hasFloors() {
        return this.isType('apartment', 'parking', 'commercial', 'office');
    }

    hasAddition(): boolean {
        if (['BE', 'NL'].includes(this.getCountry())) {
            return true;
        }

        return false;
    }

    hasSplittedComission() {
        return this.getCountry() === 'FR';
    }

    getSubType() {
        return this.property.getIn(['attributes', 'sub_type', 0], this.property.get('type'));
    }

    getType(): PropertyType {
        return this.property.get('type');
    }

    hasBuyers() {
        return this.getBuyers().size;
    }

    getBuyers() {
        return this.property.getIn(['buyers', 'data'], List());
    }

    hasVendors() {
        return this.getVendors().size > 0;
    }

    getVendors() {
        return this.property.getIn(['vendors', 'data'], List());
    }

    getInteraction() {
        if (this.property.hasIn(['last_interaction', 'data'])) {
            return this.property.getIn(['last_interaction', 'data']);
        }

        if (this.property.get('interactions', List()).count() > 0) {
            return this.property.get('interactions', List()).first();
        }

        if (this.property.get('created_at') === this.property.get('updated_at')) {
            return Map({
                interaction_type: 'created',
                created_at: this.property.get('updated_at'),
            });
        }

        return Map({
            interaction_type: 'edited',
            created_at: this.property.get('updated_at'),
        });
    }

    getLegalEntity() {
        return this.property.toJS()?.legalEntity;
    }

    getVisibility() {
        return this.property.get('visibility');
    }

    getCountry(): string {
        return this.property.getIn(['attributes', 'location', 'country']);
    }

    getFloor() {
        return this.property.getIn(['attributes', 'location', 'floor']);
    }

    getAddition(): string | undefined {
        return this.property.getIn(['attributes', 'location', 'addition']);
    }

    getNumBedrooms() {
        return numBedrooms(this.property);
    }

    getProject() {
        return this.property.getIn(['project', 'data']) || Map();
    }

    isArchived() {
        return this.property.get('is_archived');
    }

    getCondition() {
        return this.property.getIn(['attributes', 'general_condition']);
    }

    getBuiltYear(): null | number {
        return this.property.getIn(['attributes', 'building', 'construction', 'year']);
    }

    getAmentities(): string[] {
        if (this.property.hasIn(['attributes', 'amenities'])) {
            return this.property.getIn(['attributes', 'amenities']).toJS();
        }

        return [];
    }

    getVisibilityContext(): PropertyFieldVisibilityContext {
        let country = this.property.getIn(['attributes', 'location', 'country']);
        if (!supportedCountries.includes(country)) {
            country = 'default';
        }

        const type = this.property.get('type');
        const negotiation = this.property.get('negotiation');
        const internalTypeRaw = this.property.get('internalType') || PropertyInternalType.Standalone;
        const internalType = internalTypeRaw.toLowerCase();
        if (!Object.values(PropertyInternalType).includes(internalType)) {
            throw new RangeError(`Wrong internal type '${internalType}'`);
        }

        return {
            country,
            internalType,
            negotiation,
            type,
        };
    }
}

/*
 this is the main area that we show
 on the table view and
 on card view
 */
export function propertyArea(property) {
    switch (property.type) {
        case 'commercial':
        case 'parking':
            return property.attributes?.structure?.gross_area;
        case 'office':
            return property.attributes?.structure?.net_area;
        case 'land':
            return property.attributes?.structure?.plot_area;
        default:
            return property.attributes?.structure?.liveable_area;
    }
}

export function numBedrooms(property) {
    return property.getIn(['attributes', 'structure', 'bedrooms'], 0);
}

export function propertyCoverImage(property, preset) {
    const images: Array<[string, { ordinal: number; private: boolean; preview: boolean }]> = Object.entries(
        (property?.attributes?.images ?? {}) as Record<string, { ordinal: number; private: boolean; preview: boolean }>,
    );
    let lowestPublicOrdinal = Infinity;
    let coverImage: { ordinal: number; private: boolean; id: string } | undefined;
    for (let [id, image] of images) {
        if (Number(image.ordinal) < lowestPublicOrdinal && !image.private && !image.preview) {
            lowestPublicOrdinal = Number(image.ordinal);
            coverImage = {
                id,
                ...image,
            };
        }
    }
    if (coverImage) {
        return new FileRepository(property.id, { id: coverImage.id }).getFileUrl('images', preset);
    }

    return '';
}

function getPrice(property) {
    return property.getIn(['attributes', 'price', 'published_price', 'amount'], 0);
}
