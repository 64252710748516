import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, HelpBlock } from 'react-bootstrap';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { FormattedMessage } from 'react-intl-sweepbright';
import Button from '@/app.components/elements/Buttons/Button';
import { getCompany, getCompanyChannelSettings } from '../../../../app.redux/selectors';
import {
    signInToChannel,
    saveCompanyToken,
    fetchCompanyTokens,
    deleteCompanyToken,
} from '../../../../app.redux/actions';
import wrapWithPromise from '../../../../app.utils/services/wrapWithPromise';
import FormPane from '../../../../app.components/forms/FormPane';
import FormPanel from '../../../../app.components/forms/FormPanel/FormPanel';
import PortalConnection from '../../../channels/PortalConnection';
import { SuccessAlert } from '../../../../app.components/elements/Alerts/Alerts';

const mapStateToProps = createStructuredSelector({
    company: getCompany,
    settings: getCompanyChannelSettings('dotloop'),
});
const mapActionsToProps = {
    signIn: signInToChannel,
    connect: saveCompanyToken,
    disconnect: deleteCompanyToken,
    fetchCompanyTokens,
};

@connect(mapStateToProps, mapActionsToProps)
export default class Dotloop extends Component {
    static propTypes = {
        company: PropTypes.instanceOf(Map),
        connect: PropTypes.func.isRequired,
        disconnect: PropTypes.func.isRequired,
        fetchCompanyTokens: PropTypes.func.isRequired,
        settings: PropTypes.object,
        signIn: PropTypes.func.isRequired,
    };

    state = {
        loading: true,
        integrationConnected: false,
    };

    componentDidMount() {
        wrapWithPromise(this.props.fetchCompanyTokens)({
            companyId: this.props.company.get('id'),
        })
            .then(this.handleFetchTokens)
            .catch();
    }

    handleFetchTokens = () => {
        this.setState({ loading: false });
    };

    handleConnect = () => {
        this.setState({ loading: true });

        wrapWithPromise(this.props.signIn)({ channel: 'dotloop' })
            .then(token => {
                this.props.connect(this.props.company.get('id'), {
                    channel: 'dotloop',
                    access_token: token,
                });

                this.setState({
                    integrationConnected: true,
                    loading: false,
                });

                return token;
            })
            .catch(null);
    };

    renderAlert() {
        if (!this.state.integrationConnected) {
            return;
        }

        const title = (
            <FormattedMessage
                id="titles.company.integrations.connected"
                defaultMessage="Integration connected successfully!"
            />
        );

        return <SuccessAlert title={title} />;
    }

    render() {
        const {
            settings: { token },
            company,
        } = this.props;
        const { loading, integrationConnected } = this.state;
        const hasToken = token.access_token || integrationConnected;
        const title = (
            <FormattedMessage id="integrations.dotloop.title" defaultMessage="Push data from SweepBright to Dotloop" />
        );

        if (loading) {
            return (
                <FormPane title={title} cols={{ md: 15 }}>
                    <LoadingIndicator />
                </FormPane>
            );
        }

        if (hasToken) {
            return (
                <FormPane title={title} cols={{ md: 15 }}>
                    {integrationConnected && this.renderAlert()}
                    <PortalConnection
                        service="dotloop"
                        accessToken={token.access_token}
                        onDisconnect={service => {
                            this.props.disconnect(company.get('id'), service);
                            this.setState({ integrationConnected: false });
                        }}
                    />
                </FormPane>
            );
        }

        return (
            <FormPane title={title} cols={{ md: 15 }}>
                <div>
                    <Alert>
                        <FormattedMessage
                            id="integrations.documents.dotloop.help"
                            defaultMessage="Our integration with Dotloop helps you easily push data you collect on SweepBright to Dotloop. You need a Dotloop account. For more info, please check {article} or contact {support}"
                            values={{
                                article: (
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="http://get.sweepbright.help/integrations/web-application/integrations-integrate-with-dotloop-web"
                                    >
                                        <FormattedMessage
                                            id="common.links.article_link"
                                            defaultMessage="this article"
                                        />
                                    </a>
                                ),
                                support: (
                                    <a target="_blank" rel="noopener noreferrer" href="mailto:support@sweepbright.com">
                                        <FormattedMessage id="common.links.support_link" defaultMessage="support" />
                                    </a>
                                ),
                            }}
                        />
                    </Alert>
                    <FormPanel>
                        <p>
                            <Button variant="primary" onClick={this.handleConnect}>
                                <FormattedMessage
                                    id="integrations.documents.dotloop.connect"
                                    defaultMessage="Connect Dotloop"
                                />
                            </Button>
                        </p>
                        <HelpBlock>
                            <FormattedMessage
                                id="integrations.documents.dotloop.connect.help"
                                defaultMessage={"Add your company's Dotloop account."}
                            />
                        </HelpBlock>
                    </FormPanel>
                </div>
            </FormPane>
        );
    }
}
