import React from 'react';
import Icon, { IconName } from '../../icons/Icon';

export const statuses = {
    checked: 'checked',
    changed: 'changed',
    error: 'error',
    changedGreen: 'changed-green',
    changedOrange: 'changed-orange',
};

export const icons: {
    [status: string]: IconName;
} = {
    checked: 'circle-check',
    changed: 'status-changed',
    error: 'error',
    warning: 'warning',
    'warning-yellow': 'warning-yellow',
    'changed-green': 'update-phat',
    'changed-orange': 'update-phat',
    busy: 'circle-clock',
};

const statusColors = {
    checked: '#47C9AD',
    'changed-green': 'white',
    'changed-orange': 'white',
};
const backgroundColors = {
    'changed-green': '#47C9AD',
    'changed-orange': '#FE8B38',
};

const Status: React.FunctionComponent<{
    size?: number;
    disabled?: boolean;
    status: keyof typeof icons;
    style?: React.CSSProperties;
}> = ({ size = 18, disabled = false, status, style }) => {
    return (
        <Icon
            icon={icons[status]}
            size={size}
            style={{
                pointerEvents: 'none',
                position: 'absolute',
                top: '-2px',
                right: '-2px',
                color: statusColors[status],
                background: backgroundColors[status],
                ...(disabled ? { color: 'gray' } : {}),
                ...style,
            }}
        />
    );
};

export default Status;
