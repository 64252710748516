import gql from 'graphql-tag';

export const GET_GEO_TEMPLATES = gql`
    query getGeoTemplates($officeId: ID!, $page: Int, $limit: Int, $type: String) {
        geoTemplates(officeId: $officeId, page: $page, limit: $limit, type: $type) {
            pagination {
                current_page
                total_pages
                total
                per_page
            }
            data {
                _id
                title
                office_id
                type
                postalCodes
                geometry
            }
        }
    }
`;

export const GET_GEO_TEMPLATE = gql`
    query getGeoTemplate($officeId: ID!, $id: ID!) {
        geoTemplate(officeId: $officeId, id: $id) {
            _id
            title
            office_id
            type
            postalCodes
            geometry
        }
    }
`;
