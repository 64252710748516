import { createContext } from 'react';
import { Label, LabelsPagination } from '@/graphql/generated/types';

export const CompanyLabelsContext = createContext<{
    data: Label[];
    loading: boolean;
    visible: boolean;
    refetch: Function;
    addLabel: Function;
    fetchMore: Function;
    setVisible: Function;
    activeEntity?: Label;
    updateLabel: Function;
    deleteLabel: Function;
    visibleDelete: boolean;
    setActiveEntity: Function;
    setVisibleDelete: Function;
    pagination?: LabelsPagination;
}>({
    data: [],
    loading: true,
    visible: false,
    refetch: () => {},
    addLabel: () => {},
    fetchMore: () => {},
    visibleDelete: false,
    setVisible: () => {},
    updateLabel: () => {},
    deleteLabel: () => {},
    setActiveEntity: () => {},
    setVisibleDelete: () => {},
});
