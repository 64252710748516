// @ts-nocheck
import React from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import companySettings, { propTypes } from '../../../app.utils/Decorators/companySettings';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import FormPane from '../../../app.components/forms/FormPane';
import Input from '../../../app.components/forms/Input/Input';

const Domain = ({ fields, status, handleSubmit }) => (
    <FormPane
        title={<FormattedMessage id="titles.website.domain" defaultMessage="Customize your domain" />}
        status={status}
        onSubmit={handleSubmit}
        size="xl"
    >
        <FormPanel title={<FormattedMessage id="pages.website.domain_panel.title" defaultMessage="Domain" />}>
            <Input
                type="text"
                {...fields.agency_website_hostname}
                readOnly
                wrapperClassName="col-md-10"
                renderWrapper={({ children }) => <Row>{children}</Row>}
                help={
                    <p>
                        <FormattedMessage
                            id="pages.website.domain_panel.help_text_intro"
                            defaultMessage="You can find your SweepBright agency site on the domain name written above. If you want to use another domain or your own domain name, please check {article} or contact {support}"
                            values={{
                                article: (
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="http://get.sweepbright.help/channels/faq/how-do-i-link-my-own-domain-name-to-a-sweepbright-agency-website"
                                    >
                                        <FormattedMessage
                                            id="common.links.article_link"
                                            defaultMessage="this article"
                                        />
                                    </a>
                                ),
                                support: (
                                    <a target="_blank" rel="noopener noreferrer" href="mailto:support@sweepbright.com">
                                        <FormattedMessage id="common.links.support_link" defaultMessage="support" />
                                    </a>
                                ),
                            }}
                        />
                    </p>
                }
            />
        </FormPanel>
        <FormPanel
            title={<FormattedMessage id="pages.website.tracking_panel.title" defaultMessage="Tracking" />}
            horizontal
        >
            <Input
                type="text"
                label={
                    <FormattedMessage
                        id="pages.website.tracking_panel.google_label"
                        defaultMessage="Google Tracking ID"
                    />
                }
                {...fields.google_analytics_id}
                placeholder="UA-123456789-1"
                labelClassName="col-sm-4"
                wrapperClassName="col-sm-8"
                help={
                    <p>
                        <FormattedMessage
                            id="pages.website.tracking_panel.google_help_text_intro"
                            defaultMessage="You can monitor the traffic on your SweepBright website from"
                        />{' '}
                        <a target="_blank" rel="noopener noreferrer" href="https://analytics.google.com">
                            Google Analytics
                        </a>
                        .&nbsp;
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://support.google.com/analytics/answer/1032385?hl=en"
                        >
                            <FormattedMessage
                                id="pages.website.tracking_panel.click_here_link"
                                defaultMessage="Click here"
                            />
                        </a>{' '}
                        <FormattedMessage
                            id="pages.website.tracking_panel.google_help_text"
                            defaultMessage=" to find your Google Tracking ID."
                        />
                    </p>
                }
            />
            <Input
                type="text"
                label={
                    <FormattedMessage
                        id="pages.website.tracking_panel.facebook_label"
                        defaultMessage="Facebook Pixel ID"
                    />
                }
                placeholder="123456789012345"
                maxLength={15}
                labelClassName="col-sm-4"
                wrapperClassName="col-sm-8"
                {...fields.facebook_pixel_id}
                help={
                    <p>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/business/help/952192354843755"
                        >
                            <FormattedMessage
                                id="pages.website.tracking_panel.click_here_link"
                                defaultMessage="Click here"
                            />
                        </a>{' '}
                        <FormattedMessage
                            id="pages.website.tracking_panel.facebook_help_text"
                            defaultMessage="to find your Facebook Pixel ID."
                        />
                    </p>
                }
            />
        </FormPanel>
    </FormPane>
);

Domain.propTypes = {
    ...propTypes,
};

function validateGoogleAnalyticsId(id) {
    const GA3Regex = /^UA-[0-9]{5,}-[0-9]+$/;
    const GA4Regex = /^G-[0-9A-Za-z]{10}$/;

    return GA3Regex.test(id) || GA4Regex.test(id);
}

export default companySettings(
    'domain',
    ['agency_website_hostname', 'facebook_pixel_id', 'google_analytics_id'],
    null,
    null,
    values => {
        const errors = {};
        if (values.facebook_pixel_id) {
            if (!values.facebook_pixel_id.match(/^[0-9]{15}$/)) {
                errors.facebook_pixel_id = (
                    <FormattedMessage
                        id="website.facebook_pixel_id.invalid"
                        defaultMessage="Not a valid Facebook Pixel ID"
                    />
                );
            }
        }
        if (values.google_analytics_id) {
            if (!validateGoogleAnalyticsId(values.google_analytics_id)) {
                errors.google_analytics_id = (
                    <FormattedMessage
                        id="website.google_analytics_id.invalid"
                        defaultMessage="Not a valid Google Tracking ID"
                    />
                );
            }
        }

        return errors;
    },
)(Domain);
