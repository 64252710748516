// @ts-nocheck
import _ from 'lodash';
import CompanyPublishSettingsPatchBuilder from '@/app.utils/services/PatchBuilders/CompanyPublishSettingsPatchBuilder';
import { APIChannelAccount, APIEstimate, APIOffice, APIStatuses } from '@/../server/graphql/resolvers/helpers/types';
import { Negotiation } from '@/app.data/Settings';
import SweepbrightCrudRequest, { FileData } from './SweepbrightCrudRequest';
import { PaginatedResponse, MoveBotlerConfig } from './types';

export default class CompaniesRequests extends SweepbrightCrudRequest {
    resource = 'companies';

    features = (companyId: string) => {
        return this.get(`${this.resource}/${companyId}/features`);
    };

    offices = (companyId: string) => {
        return this.withQueryParameters({
            includes: ['head_negotiator', 'settings'],
            limit: 100,
        }).get<PaginatedResponse<APIOffice>>(`${this.resource}/${companyId}/offices`);
    };

    companyOffices = (companyId: string, parameters: { page?: number; limit?: number; query?: string }) => {
        return this.withMeta()
            .withQueryParameters({
                query: parameters.query,
                page: parameters.page || 1,
                limit: parameters.limit || 100,
                includes: ['head_negotiator', 'settings'],
            })
            .get(`${this.resource}/${companyId}/offices`);
    };

    admins = (companyId: string, params: { limit?: number; page?: number }) => {
        return this.withMeta()
            .withQueryParameters(params)
            .get(`${this.resource}/${companyId}/admins`);
    };

    inviteAdmin = (companyId: string, payload: object) => {
        return this.post(`${this.resource}/${companyId}/admins`, payload);
    };

    unassignedLeads = (
        companyId: string,
        params: {
            limit: Maybe<number>;
            page: Maybe<number>;
            name?: Maybe<string>;
            sort_field?: Maybe<string>;
            sort_order: Maybe<string>;
        },
    ) => {
        return this.withMeta()
            .setQueryParameters(params)
            .get(`${this.resource}/${companyId}/unassigned-leads`);
    };

    contactRequests = (args: { companyId: string; contactId: string }) => {
        return this.withMeta()
            .setQueryParameters({})
            .get(`/companies/${args.companyId}/contacts/${args.contactId}/requests`);
    };

    updateSettings = (companyId: string, attributes: object) => {
        const operations = new CompanyPublishSettingsPatchBuilder(attributes).getOperations();

        return super.patch(`${this.resource}/${companyId}/settings`, operations.toJS());
    };

    photos = (companyId: string, file: FileData) => {
        return this.storeFile(`${companyId}/photo`, file);
    };

    images = (companyId: string, key: string, file: File) => {
        return this.storeFile(`${companyId}/images`, { file, key });
    };

    // @ts-ignore
    removePhoto = (companyId: string) => {
        return this.delete(`${this.resource}/${companyId}/photo`);
    };

    storeToken = (companyId: string, token: object) => {
        return this.post(`${this.resource}/${companyId}/access-tokens`, token);
    };

    removeToken = (companyId: string, channel: string) => {
        return this.delete(`${this.resource}/${companyId}/access-tokens/${channel}`);
    };

    tokens = (companyId: string) => {
        return this.get<PaginatedResponse<any>>(`${this.resource}/${companyId}/access-tokens`);
    };

    cards = (companyId: string, cardToken: string) => {
        return this.put(`${this.resource}/${companyId}/cards`, {
            card_token: cardToken,
        });
    };

    // @ts-ignore
    activeCard = (companyId: string) => {
        return this.get(`${this.resource}/${companyId}/cards/active`);
    };

    subscriptions = (companyId: string, attributes: object) => {
        return this.put(`${this.resource}/${companyId}/subscriptions`, attributes);
    };

    estimate = (companyId: string, attributes: { interval: string; users_amount: number; coupons?: string[] }) => {
        return this.put<APIEstimate>(`${this.resource}/${companyId}/estimates`, attributes).then(response => ({
            ...response,
            interval: attributes.interval,
        }));
    };

    plans = () => {
        return this.get('plans');
    };

    disconnectChannelAccount = (companyId: string, accountId: string) => {
        return this.delete(`${this.resource}/${companyId}/channel-accounts/${accountId}`);
    };

    contactInstaon = (companyId: string) => {
        return this.post(`${this.resource}/${companyId}/instaon`);
    };

    validateVat = (companyId: string, vatId: string) => {
        return this.put(`${this.resource}/${companyId}/validate-vat-number`, {
            vat_number: vatId,
        });
    };

    getChannelsAccounts = (companyId: string, type?: string) => {
        const queryParameters: any = { includes: 'channel' };
        if (type) {
            queryParameters.type = type;
        }

        return this.withQueryParameters(queryParameters).get<APIChannelAccount[]>(
            `companies/${companyId}/channel-accounts`,
        );
    };

    getChannelAccount(companyId: string, channelAccountId: string) {
        return this.withQueryParameters({ includes: 'channel,public_api' }).get<APIChannelAccount>(
            `companies/${companyId}/channel-accounts/${channelAccountId}`,
        );
    }

    createChannelAccount(companyId: string, account: { name: string; channel_id: string; credentials: any }) {
        return this.post(`companies/${companyId}/channel-accounts`, account);
    }

    updateChannelAccount(
        companyId: string,
        account: { id: string; name: string; office_id: string; channel_id: string; credentials: any },
    ) {
        return this.put(`companies/${companyId}/channel-accounts/${account.id}`, account);
    }

    // move botler
    getMoveBotlerConfig = (companyId: string) => {
        return this.get<MoveBotlerConfig>(`companies/${companyId}/movebotler`);
    };

    connectMoveBotler = (companyId: string): Promise<MoveBotlerConfig> => {
        return this.put<MoveBotlerConfig>(`companies/${companyId}/movebotler`);
    };

    disconnectMoveBotler = (companyId: string) => {
        return this.delete<any>(`companies/${companyId}/movebotler`);
    };

    getStatuses = (companyId: string, negotiation?: Negotiation) => {
        return this.withQueryParameters({
            negotiation,
        }).get<APIStatuses>(`/companies/${companyId}/property-statuses`);
    };

    archiveUnassignedLeads = async (companyId, leadIds: readonly string[]) => {
        const batches = _.chunk(leadIds, 20);
        for (let batch of batches) {
            await Promise.all(batch.map(leadId => this.post(`/companies/${companyId}/contacts/${leadId}/archive`)));
        }
    };

    getLabels = (args: { page?: number; limit?: number; query?: string }) => {
        return this.withMeta()
            .withQueryParameters({
                limit: 30,
                page: args.page,
                query: args.query,
            })
            .get('/labels');
    };

    getLabel = (labelId: string) => {
        return this.get(`/labels/${labelId}`);
    };

    createLabel = (attributes: { name: string; description: string; color: string }) => {
        return this.post('/labels', attributes);
    };

    updateLabel = (attributes: { id: string; name: string; description: string; color: string }) => {
        return this.put(`/labels/${attributes.id}`, attributes);
    };

    deleteLabel = (labelId: string) => {
        return this.delete(`/labels/${labelId}`);
    };
}
