import PropTypes from 'prop-types';
import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import Modal from '@/app.components/elements/Modal';
import { FormattedMessage } from 'react-intl-sweepbright';

export default function RemoveMemberModal({ close, onSubmit }) {
    return (
        <React.Fragment>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="modals.remove-member.title" defaultMessage="Delete member?" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage
                    id="modals.remove-member.body"
                    defaultMessage="Are you sure you want to delete this member? This will permanently remove the member from the system and cannot be undone."
                    tagName="p"
                />
            </Modal.Body>
            <Modal.Footer className="text-center">
                <Button onClick={close}>
                    <FormattedMessage id="modals.remove-member.cancel" defaultMessage="Cancel" />
                </Button>
                <Button type="submit" variant="danger" onClick={onSubmit}>
                    <FormattedMessage id="modals.remove-member.cta" defaultMessage="Delete" />
                </Button>
            </Modal.Footer>
        </React.Fragment>
    );
}

RemoveMemberModal.propTypes = {
    close: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
};
