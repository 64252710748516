import { Map } from 'immutable';

export const getCurrentPage = pagination => pagination.get('current_page');
export const getTotalPages = pagination => pagination.get('total_pages');

export function hasMorePages(pagination) {
    return getCurrentPage(pagination) < getTotalPages(pagination);
}

export function isLoading(pagination: Map<string, any>) {
    if (typeof pagination.get('total') === 'undefined') {
        return true;
    }

    if (hasMorePages(pagination)) {
        return true;
    }

    return false;
}
