import { call, cps, put } from 'redux-saga/effects';
import get from 'lodash/get';
import { CompaniesRequests } from '../../../requests/index';
import { fetchUser, fetchCompany } from '../../actions';
import apiCall from '../Effects/apiCall';
import formatApiErrors from '../Helpers/formatApiErrors';
import { createToken } from '../Helpers/stripe';

export default function* onUpgradeSubscription({ attributes: { resolve, reject, ...attributes } }) {
    const request = new CompaniesRequests();
    const companyId = attributes.company_id;

    try {
        // If the credit_card option was selected, we need to first register the card.
        if (attributes.payment_method === 'credit_card' && attributes.card.number) {
            const { card } = attributes;

            const token = yield cps(createToken, {
                number: card.number,
                cvc: card.cvc,
                exp: card.expiry,
            });

            yield apiCall(request.cards, companyId, token.id);
        }

        yield apiCall(request.subscriptions, companyId, {
            users_amount: attributes.users_amount,
            interval: attributes.interval,
            method: attributes.payment_method,
            coupons: get(attributes, 'coupons', []).filter(Boolean),
        });

        yield [put(fetchCompany(companyId)), put(fetchUser())];

        yield call(resolve);
    } catch (error) {
        const data = error.message ? error : error.response;

        yield call(reject, formatApiErrors(data, '_error'));
    }
}
