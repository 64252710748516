import React, { useState } from 'react';
import { themes } from '@sweepbright/uikit/build/esm/theming';

interface CSSFile {
    use();
    unuse();
}

type ThemeFactory = () => Promise<CSSFile>;

export const DEFAULT_THEME = 'sweepbright';
const ThemeContext = React.createContext(themes[DEFAULT_THEME]);

export function ThemeProvider({ theme: themeName = DEFAULT_THEME, children }) {
    const [loading, setLoading] = useState(true);
    React.useLayoutEffect(() => {
        setLoading(true);
        applyTheme(themeName).then(() => {
            setLoading(false);
        });
    }, [themeName]);

    return loading ? null : <ThemeContext.Provider value={themes[themeName]}>{children}</ThemeContext.Provider>;
}

const themeCache: { [themeName: string]: CSSFile } = {};

const themeFactories: { [themeName: string]: ThemeFactory } = {};

const getTheme = async (themeName: string): Promise<CSSFile> => {
    const factory = themeFactories[themeName];
    if (!factory) {
        throw new Error(`No theme ${themeName}`);
    }
    if (!themeCache[themeName]) {
        themeCache[themeName] = await factory();
    }

    return themeCache[themeName];
};

export function registerTheme(theme, themeFactory: ThemeFactory) {
    themeFactories[theme] = themeFactory;
}

export function useTheme() {
    return React.useContext(ThemeContext);
}

async function applyTheme(newTheme) {
    const theme = await getTheme(newTheme);
    Object.keys(themeCache).forEach(theme => themeCache[theme].unuse());
    theme.use();
}
