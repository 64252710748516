import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { GetContactsOptionsQuery, GetContactsOptionsQueryVariables } from '@/graphql/generated/types';
import { GET_CONTACTS_OPTIONS_QUERY } from '@/graphql/queries/contacts/getContactOptions';
import { RELEVANCE_SORT_OPTION } from '@/app.components/forms/Search/AdvancedSearch';

export const useContactsSorts = () => {
    const intl = useIntl();

    const { data } = useQuery<GetContactsOptionsQuery, GetContactsOptionsQueryVariables>(GET_CONTACTS_OPTIONS_QUERY);

    const sorts = useMemo(() => {
        return data?.contactsOptions?.sorts?.map(el => {
            return {
                value: el.key,
                label: intl.formatMessage({ defaultMessage: el.key, id: `contacts.sort.${el.key}` }),
            };
        });
    }, [intl, data]);

    return [RELEVANCE_SORT_OPTION, ...(sorts || [])];
};
