import { call } from 'redux-saga/effects';
import FacebookRequests from '../../../requests/FacebookRequests';
import { getPopupSettings } from '../Helpers/popups';

type PermissionStatus = 'granted' | 'declined';

type PermissionArray = Array<{
    permission: string;
    status: PermissionStatus;
}>;

export const POPUP_BLOCKED_ERROR_CODE = 0xff1;
export const MISSING_PERMISSIONS_ERROR_CODE = 0xff2;
export const UNKNOWN_ERROR_CODE = 0xff3;

const neededFacebookPermissions = [
    // https://developers.facebook.com/docs/permissions/reference/pages_manage_metadata
    'pages_manage_metadata',
    // https://developers.facebook.com/docs/permissions/reference/pages_read_engagement
    'pages_read_engagement',
    // https://developers.facebook.com/docs/permissions/reference/pages_manage_posts
    'pages_manage_posts',
    // https://developers.facebook.com/docs/permissions/reference/pages_show_list
    'pages_show_list',
];

function verifyFacebookPermissions(grantedPermissions: PermissionArray) {
    const result = neededFacebookPermissions.filter(permission => {
        const entry = grantedPermissions.find(item => item.permission === permission);

        return entry && entry.status === 'granted';
    });

    const haveAllNeededPermissions = result.length === neededFacebookPermissions.length;

    return haveAllNeededPermissions;
}

function waitForAccessToken() {
    return new Promise((resolve, reject) => {
        window.addEventListener(
            'message',
            // eslint-disable-next-line prefer-arrow-callback
            function onMessage(evt) {
                if (evt.data.type === 'sign') {
                    window.removeEventListener('message', onMessage);
                    resolve({ account: evt.data.account });
                } else if (evt.data.type === 'sign_error') {
                    reject(evt.data.error);
                }
            },
        );
    });
}

export default function* onSignInToChannel({
    attributes: { channel, resolve, reject },
}: {
    attributes: {
        channel: string;
        missingPermissions: boolean;
        reject: (err: any) => void;
        resolve: (token: { access_token: string; secret?: string; profile?: any }) => void;
    };
}) {
    try {
        const popupSettings = getPopupSettings(channel);
        const popup = yield call(
            window.open,
            `/api/channels/${channel}`,
            `Authenticate with ${channel}`,
            popupSettings,
        );

        let popupMessage: {
            account: { accessToken: string; refreshToken?: string; profile: any; tokenSecret?: string };
        };

        if (popup) {
            try {
                popupMessage = yield call(waitForAccessToken);
            } catch (err) {
                throw {
                    errorCode: UNKNOWN_ERROR_CODE,
                    message: err || 'Oops, we were not able to connect.',
                };
            } finally {
                popup.close();
            }

            const { account } = popupMessage;

            if (channel === 'facebook') {
                // get user permission lists
                //
                const permissions: PermissionArray = yield call(
                    new FacebookRequests().facebookPermissions,
                    account.accessToken,
                );

                //  verify we have the needed permissions
                if (!verifyFacebookPermissions(permissions)) {
                    throw {
                        errorCode: MISSING_PERMISSIONS_ERROR_CODE,
                        message: 'Not all of the required Facebook permissions were granted to the app',
                    };
                }
            }

            resolve({ access_token: account.accessToken, secret: account.tokenSecret, profile: account.profile });
        } else {
            throw {
                errorCode: POPUP_BLOCKED_ERROR_CODE,
                message: 'The auth popup was blocked',
            };
        }
    } catch (error) {
        reject(error);
    }
}
