import React from 'react';
import useRouter from '@/app.hooks/useRouter';
import BackButton from '@/app.components/elements/Buttons/BackButton';

const MembersPageBack = () => {
    const router = useRouter();

    const getLink = () => {
        const location = router?.location.pathname.split('/').filter(el => el);
        location?.pop();

        return `/${location?.join('/') || ''}`;
    };

    const link = getLink();

    return <BackButton to={link} />;
};

export default MembersPageBack;
