import LaunchDarkly from 'launchdarkly-js-client-sdk';
import { eventChannel } from 'redux-saga';
import { takeEvery, select, call, put } from 'redux-saga/effects';
import { FEATURE_FLAGS, defaultFlagValue } from '@/app.redux/reducers/FeaturesReducer';
import { FETCH_FEATURES, setFeatures, updateFeatures } from '../actions';
import { getLaunchDarklyUser } from '../selectors/UsersSelectors';

let client;
let serverClient;

function getServerClient() {
    const LaunchDarklyNode = require('launchdarkly-node-server-sdk');
    if (!serverClient) {
        serverClient = LaunchDarklyNode.init(process.env.LAUNCHDARKLY_API_KEY_SERVER);
    }

    return serverClient;
}

function getFeatures(user) {
    return new Promise(resolve => {
        if (!user) {
            return client.on('ready', () => {
                resolve(client.allFlags());
            });
        }

        return client.identify(user, null, () => {
            resolve(client.allFlags());
        });
    });
}

function createLaunchDarklyChannel() {
    return eventChannel(emit => {
        const handler = features => emit(features);
        client.on('change', handler);

        return () => client.off('change', handler);
    });
}

function* onUpdateFeatures(features) {
    yield put(updateFeatures(features));
}

export function* onFetchFeatures() {
    const { hash, ...user } = yield select(getLaunchDarklyUser);
    try {
        if (!user.anonymous) {
            if (__CLIENT__) {
                if (!LAUNCHDARKLY_API_KEY || window.Cypress) {
                    yield put(setFeatures(FEATURE_FLAGS));

                    return;
                }

                if (!client) {
                    client = LaunchDarkly.initialize(LAUNCHDARKLY_API_KEY, user, {
                        hash,
                    });

                    const channel = yield call(createLaunchDarklyChannel);
                    yield takeEvery(channel, onUpdateFeatures);
                }

                const features = yield call(getFeatures, user);
                yield put(setFeatures(features));
            } else {
                // server side launchdarkly setup
                const features = yield call(getServerFeatureFlags, user);
                yield put(setFeatures(features));
            }
        } else {
            yield put(setFeatures(FEATURE_FLAGS));
        }
    } catch (err) {
        // if something fails, we just set the default values
        yield put(setFeatures(FEATURE_FLAGS));
    }
}

export default function* FeaturesSaga() {
    yield takeEvery(FETCH_FEATURES, onFetchFeatures);
}

async function getServerFeatureFlags(user) {
    const serverClient = await getServerClient().waitForInitialization();

    const featuresList = await Promise.all(
        Object.keys(FEATURE_FLAGS).map(async flag => {
            const value = await serverClient.variation(flag, user, defaultFlagValue);

            return { [flag]: value };
        }),
    );

    const features = featuresList.reduce((features, entry) => {
        return { ...features, ...entry };
    }, {});

    return features;
}
