// @ts-nocheck
import React from 'react';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import Radio from '@/app.components/forms/Radio/Radio';
import { FormattedMessage } from 'react-intl-sweepbright';

export default function RadioGroupInput({ name, label, value, onChange, options, disabled = false }) {
    return (
        <div className="form-horizontal">
            <FormGroup>
                <Col sm={4}>
                    <ControlLabel>{label}</ControlLabel>
                </Col>
                <Col sm={8}>
                    <Radio.Group name={name} onChange={onChange} value={value} disabled={disabled}>
                        {options.map(option => (
                            <Radio.Button key={option.value} value={option.value}>
                                <FormattedMessage id={option.label} defaultMessage={option.label} />
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </Col>
            </FormGroup>
        </div>
    );
}
