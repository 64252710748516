import React from 'react';
import * as Immutable from 'immutable';
import revalidator from 'revalidator';
import { FormattedMessage } from 'react-intl-sweepbright';
import buildDate from './Helpers/buildDate';
import { isObjectEmpty } from './Helpers/emptyValidators';
import SchemaConverter from './SchemaConverter';
import { toTitle } from './String';

type ValidationOutputConfig = {
    humanizeErrorMessage?: boolean;
};

type ValidateFunctionType = (values: any, props?: Record<string, any>) => any;

/**
 * Transform a field id into a readable field name
 */
export const humanizeFieldName = (fieldPath: string[]): string => {
    const [fieldName = 'Field', parent = 'Field'] = [...fieldPath].reverse();
    const isLocalizedField = ['en', 'nl', 'fr'].includes(fieldName);

    return toTitle((isLocalizedField ? parent : fieldName).replace(/_/g, ' ')).replace('Email', 'E-mail');
};

/**
 * Validate certain attributes
 * according to a set of rules defined
 * by the JSON schemas spec
 */
export const validate = (
    _attributes: Record<string, any>,
    rules: object,
    { humanizeErrorMessage = true }: ValidationOutputConfig = {},
): object => {
    const validator = revalidator.validate(_attributes, rules);

    let errors = Immutable.Map();
    let attributes = Immutable.fromJS(_attributes);
    Immutable.fromJS(validator.errors).forEach(error => {
        // If the error was for a field not in the current form
        // simply ignore it
        const property = error.get('property').split('.');
        let message = error.get('message');
        const textErrorRegex = /^(\w+\s?)+.?$/;

        if (property[0] === 'email' && message.match(textErrorRegex)) {
            message = message.replace('email', 'e-mail');
        }

        if (!attributes.hasIn(property) || message === 'must not exist') {
            return;
        }
        if (humanizeErrorMessage) {
            const fieldName = humanizeFieldName(property);
            if (!['Contents'].includes(fieldName)) {
                message = `${fieldName} ${message}`;
            }
        }

        errors = errors.setIn(property, message);
    });

    return errors.toJS();
};

export const validateWithLocalizedPropertySchema = (
    attributes: Record<string, any>,
    props: { property: Immutable.Map<string, any>; supportedLangs: string[] },
) => {
    if (!props.supportedLangs) {
        throw new Error('A `supportedLangs` prop is needed to be able to validate the form');
    }
    const schema = SchemaConverter.getPropertySchema(props.property, props.supportedLangs);
    const errors = validate(attributes, schema);

    return errors;
};
/**
 * Validate that an object of attributes matches
 * the JSON schemas
 */
export const validateWithPropertySchema = (
    attributes: Record<string, any>,
    props: { property: Immutable.Map<string, any> },
) => {
    const schema = SchemaConverter.getPropertySchema(props.property);
    const errors = validate(attributes, schema);

    return errors;
};

/**
 * Returns a validation function with the given rules
 *
 */
export const validateWithRules = (
    rules: object,
    options?: { humanizeErrorMessage?: boolean },
): ValidateFunctionType => {
    return (attributes, config) => validate(attributes, { properties: rules }, { ...config, ...options });
};

type DateTimeErrors = {
    hour?: React.ReactNode;
    minutes?: React.ReactNode;
    date?: React.ReactNode;
    datetime?: React.ReactNode;
};

export const validateDatetime = (attributes: {
    date: string | number;
    hour: string | number;
    minutes: string | number;
}): DateTimeErrors => {
    const isNonNumericFalsey = field => !field && field !== 0;
    const isSet = field => typeof field !== 'undefined' && field !== null && field !== '';
    const errors: DateTimeErrors = {};

    if (isNonNumericFalsey(attributes.date) && (isSet(attributes.hour) || isSet(attributes.minutes))) {
        errors.date = <FormattedMessage id="forms.errors.missing_date" defaultMessage="Please enter date" />;
    }

    if (isNonNumericFalsey(attributes.hour)) {
        errors.datetime = errors.hour = (
            <FormattedMessage id="forms.errors.missing_time" defaultMessage="Please enter time" />
        );
    } else if (isNonNumericFalsey(attributes.minutes)) {
        errors.datetime = errors.minutes = (
            <FormattedMessage id="forms.errors.missing_time" defaultMessage="Please enter time" />
        );
    }

    return errors;
};

export const validateDateRange = (attributes: { end_date: any; start_date: any }) => {
    const errors = {
        start_date: validateDatetime(attributes.start_date),
        end_date: validateDatetime({
            ...attributes.end_date,
            date: attributes.start_date.date,
        }),
    };

    if (!isObjectEmpty(errors.start_date) || !isObjectEmpty(errors.end_date)) {
        return errors;
    }

    const startDate = buildDate(attributes.start_date.date, attributes.start_date.hour, attributes.start_date.minutes);
    const endDate = buildDate(attributes.start_date.date, attributes.end_date.hour, attributes.end_date.minutes);
    const hour = endDate.isBefore(startDate) ? (
        <FormattedMessage id="forms.errors.time_after" defaultMessage="The end time must be after the start time." />
    ) : null;

    return hour && { end_date: { hour } };
};

/**
 * Validate the file types of some fields
 */
export const validateFileTypes = (rules: object) => {
    return attributes => {
        const errors = {};

        Object.keys(rules).forEach(field => {
            if (attributes[field].file) {
                const file = attributes[field].file[0];
                const expected = rules[field];

                if (file && !file.type.includes(expected)) {
                    errors[field] = {
                        file: `Invalid file format, should be: ${expected}`,
                    };
                }
            }
        });

        return errors;
    };
};

export default {
    validateWithRules,
    validate,
    humanizeFieldName,
    validateFileTypes,
    validateDateRange,
    validateDatetime,
    validateWithPropertySchema,
    validateWithLocalizedPropertySchema,
};
