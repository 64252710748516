import { put } from 'redux-saga/effects';
import CompaniesRequests from '../../../requests/CompaniesRequests';
import { setOffice } from '../../actions';
import apiCall from '../Effects/apiCall';

export default function* onFetchCompanyOffices({ companyId }) {
    const offices = yield apiCall(new CompaniesRequests().offices, companyId);

    yield offices.reverse().map(office => put(setOffice(office)));
}
