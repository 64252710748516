import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import axios from 'axios';
import moment from 'moment';
import { ApolloError } from 'apollo-client';
import Button from '@sweepbright/uikit/build/esm/button';
import useOffice from '@/app.hooks/useOffice';
import Resource from '@/shared/resource';
import { useToasts } from '@sweepbright/notifications';
import { events, track } from '@/app.utils/analytics';
import { ResourceContext } from '@/shared/resource/utils';
import EmptyState from '@/app.components/empty/EmptyState';
import useContact from '@/app.hooks/useContact';
import { Icon } from '@/app.components';
import { SearchViewType } from '@/app.components/forms/Search/AdvancedSearch';
import { BuyerPreference } from '@/graphql/generated/types';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { ResourceDomainType } from '@/shared/resource/types';
import { CONTACT, PROPERTY } from '@/app.routing';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import useProperty from '@/app.hooks/useProperty';
import useMatch from '../hooks/useMatch';
import useMatchFlags from '../hooks/useMatchFlags';
import useMatchConfig from '../hooks/useMatchConfig';
import useMatchRequirements from '../hooks/useMatchRequirements';
import { formatFlags } from '../utils/formatFlags';
import MatchListCard from './MatchListCard';
import MatchListModal from './MatchListModal';

type Props = {
    page: number;
    setPage: Function;
    contactId?: string;
    selected: string[];
    propertyId?: string;
    searchSettings: any;
    filters: JSX.Element;
    setSelected: Function;
    defaultSearchParams: any;
    formattedSearchParams: any;
    setSearchSettings: Function;
    loadingPreference?: boolean;
    preference?: BuyerPreference;
    domainType: ResourceDomainType;
};

const MatchList: React.FC<Props> = props => {
    const {
        filters,
        setPage,
        selected,
        domainType,
        contactId,
        propertyId,
        preference,
        setSelected,
        searchSettings,
        setSearchSettings,
        loadingPreference,
        defaultSearchParams,
        formattedSearchParams,
    } = props;

    const intl = useIntl();

    const { addError } = useToasts();

    const office = useOffice();
    const officeId = office.get('id');

    const [showEmailModal, setShowEmailModal] = useState(false);

    const showExport = useFeatureFlag('properties.matchesExport.enabled');

    const { loading, error, data, totalCount, hasNextPage, fetchMore } = useMatch({
        setPage,
        preference,
        domainType,
        skip: !officeId || (domainType === 'contact_matches' ? !preference?.id : false),
        searchSettings: defaultSearchParams,
    });

    const { contact } = useContact(contactId);
    const { property } = useProperty(propertyId);

    const handleError = (err: ApolloError) => {
        const isForbidden = err?.message?.includes('403');

        addError({
            message: isForbidden
                ? intl.formatMessage({ id: 'unauthorised_403' })
                : intl.formatMessage({ id: 'general.something_went_wrong' }),
        });
    };

    const { data: requirementsData, refetch: refetchRequirements } = useMatchRequirements({
        onError: err => {
            handleError(err);
        },
        fetchPolicy: 'no-cache',
        skip: domainType === 'contact_matches' ? !preference?.id : !propertyId,
        variables: { input: { id: preference?.id || propertyId || '', type: domainType } },
    });

    const { data: flagsData, refetch: refetchFlags } = useMatchFlags({
        onError: err => {
            handleError(err);
        },
        fetchPolicy: 'no-cache',
        skip: (domainType === 'contact_matches' ? !contactId : !propertyId) || !data.length,
        variables: {
            input: {
                contactIds: domainType === 'contact_matches' ? [contactId || ''] : data.map(el => el.id),
                propertyIds: domainType === 'property_matches' ? [propertyId || ''] : data.map(el => el.id),
            },
        },
    });

    const { data: configData, refetch: refetchConfig } = useMatchConfig({
        onError: err => {
            handleError(err);
        },
        fetchPolicy: 'no-cache',
        skip: domainType !== 'property_matches' || !propertyId || !data.length,
        variables: {
            input: {
                id: propertyId || '',
                type: domainType,
                searchParams: formattedSearchParams,
            },
        },
    });

    const handleRefetch = () => {
        refetchRequirements();
        refetchFlags();

        if (domainType === 'property_matches') {
            refetchConfig();
        }
    };

    const reqErrors = requirementsData?.matchingRequirements?.data?.errors || [];

    const isErrors = reqErrors?.length > 0;

    const mailableCount = isErrors
        ? 0
        : configData?.matchingConfig?.data?.find(el => el.key === 'MAILABLE_COUNT')?.value || 0;

    const content = {
        title: intl.formatMessage({ id: `${domainType}.title` }),
        export: intl.formatMessage({ id: `${domainType}.export` }),
        selectAll: intl.formatMessage({ id: 'general.select_all' }),
        unselectAll: intl.formatMessage({ id: 'general.unselect_all' }),
        emptyTitle: intl.formatMessage({ id: `${domainType}.empty.title` }),
        tabMatches: intl.formatMessage({ id: `${domainType}.tab.matches` }),
        errorTitle: intl.formatMessage({ id: `${domainType}.error.title` }),
        errorSubtitle: intl.formatMessage({ id: `${domainType}.error.subtitle` }),
        add: intl.formatMessage(
            { id: `${domainType}.add` },
            { count: selected.length ? selected.length : mailableCount },
        ),
    };

    const tabs = [
        {
            label: '',
            id: 'matches',
            filters: filters,
            onTabSelected: () => {},
        },
    ];

    const getEmptyState = () => {
        if (isErrors) {
            return (
                <EmptyState
                    title={content.emptyTitle}
                    body={reqErrors
                        .map(el => {
                            return intl.formatMessage({ id: el.id });
                        })
                        .join(' ')}
                />
            );
        }

        if (domainType === 'contact_matches' && !preference?.id) {
            return <EmptyState title={intl.formatMessage({ id: 'contact_preferences.empty_view.title' })} />;
        }

        return (
            <EmptyState
                body={error ? content.errorSubtitle : undefined}
                title={error ? content.errorTitle : content.emptyTitle}
            />
        );
    };

    const renderListCard = entity => {
        const { disabled, status } = formatFlags({ data: flagsData, id: entity.id, domainType });

        return (
            <li key={entity.id}>
                <MatchListCard
                    entity={entity}
                    status={status}
                    // @ts-ignore
                    contact={contact}
                    // @ts-ignore
                    property={property}
                    disabled={disabled}
                    selected={selected}
                    domainType={domainType}
                    onMailed={handleRefetch}
                    setSelected={setSelected}
                    formattedSearchParams={formattedSearchParams}
                    to={domainType === 'contact_matches' ? PROPERTY(entity.id) : CONTACT(entity.id)}
                />
            </li>
        );
    };

    const filteredIds = data
        .filter(el => {
            const { disabled } = formatFlags({ data: flagsData, id: el.id, domainType });

            return !disabled;
        })
        .map(el => el.id);

    const isAllSelected = selected.length ? filteredIds.length === selected.length : false;

    const filteredData = selected.length
        ? data.filter(el => selected.includes(el.id))
        : data.filter(el => filteredIds.includes(el.id));

    return (
        <ResourceContext.Provider
            value={{
                tabs,
                error,
                content,
                officeId,
                totalCount,
                getEmptyState,
                searchSettings,
                renderListCard,
                hideCount: true,
                hideSearch: true,
                setSearchSettings,
                initialTab: tabs[0].id,
                view: SearchViewType.List,
                handleFetchMore: fetchMore,
                hasEntities: totalCount > 0,
                entities: isErrors ? [] : data,
                resourceClassName: 'match-list',
                loading: loadingPreference || loading,
                hasNextPage: isErrors ? false : hasNextPage,
                action: (
                    <div className="flex gap-3">
                        {domainType === 'property_matches' && showExport && (
                            <Button
                                icon={<Icon icon="export" />}
                                disabled={isErrors ? true : data.length === 0}
                                onClick={() => {
                                    axios
                                        .post(`/download/properties/${propertyId}/matches`, {
                                            params: formattedSearchParams,
                                        })
                                        .then(res => {
                                            const fileName = `${moment().format(
                                                'YYYYDDMMHHmm',
                                            )}_property_${propertyId}_matches_export.csv`;

                                            const url = window.URL.createObjectURL(new Blob([res.data]));
                                            const link = document.createElement('a');
                                            link.href = url;
                                            link.setAttribute('download', fileName);
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                        });
                                }}
                            >
                                {content.export}
                            </Button>
                        )}

                        <Button
                            type="submit"
                            variant="success"
                            className="pull-right"
                            icon={<Icon icon="mail" />}
                            disabled={selected.length === 0 && mailableCount === 0}
                            onClick={() => {
                                setShowEmailModal(true);

                                track(
                                    domainType === 'property_matches'
                                        ? events.PROPERTY_MATCHING_MAIL_MATCHES_BTN_CLICKED
                                        : events.CONTACT_MATCHING_MAIL_MATCHES_BTN_CLICKED,
                                );
                            }}
                        >
                            {content.add}
                        </Button>
                    </div>
                ),
                listHeader: (
                    <FormPanel
                        title={content.tabMatches}
                        action={
                            <Button
                                variant="link"
                                onClick={() => {
                                    if (isAllSelected) {
                                        setSelected([]);
                                    } else {
                                        setSelected(filteredIds);
                                    }
                                }}
                                disabled={isErrors ? true : filteredIds.length === 0}
                            >
                                {isAllSelected ? content.unselectAll : content.selectAll}
                            </Button>
                        }
                    />
                ),
            }}
        >
            <Resource />

            <MatchListModal
                selected={selected}
                show={showEmailModal}
                domainType={domainType}
                onMailed={handleRefetch}
                mailableCount={mailableCount}
                onCancel={() => setShowEmailModal(false)}
                formattedSearchParams={formattedSearchParams}
                contacts={domainType === 'contact_matches' ? [contact] : filteredData}
                properties={domainType === 'contact_matches' ? filteredData : [property]}
            />
        </ResourceContext.Provider>
    );
};

export default MatchList;
