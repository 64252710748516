import React from 'react';
import TabList from '@sweepbright/uikit/build/esm/tablist';
import Accordion from './Accordion';

const TabItem: React.FunctionComponent<{}> = ({ children }) => <React.Fragment>{children}</React.Fragment>;

type Props = {
    children: React.ReactNode;
    tabs: {
        id: string;
        label: React.ReactNode;
        onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
    }[];
};

class Tabs extends React.Component<Props> {
    static Item = TabItem;

    render() {
        return (
            <Accordion>
                {({ openIndex, handleItemClick }) => (
                    <div>
                        <TabList>
                            {this.props.tabs.map((tab, idx) => {
                                const toggleTabHandler = handleItemClick(idx);

                                return (
                                    <TabList.Tab
                                        key={tab.id}
                                        active={idx === openIndex}
                                        label={tab.label}
                                        onClick={(evt: React.MouseEvent<HTMLDivElement>) => {
                                            if (tab.onClick) {
                                                tab.onClick(evt);
                                            }

                                            toggleTabHandler();
                                        }}
                                    />
                                );
                            })}
                        </TabList>
                        {React.Children.toArray(this.props.children)[openIndex]}
                    </div>
                )}
            </Accordion>
        );
    }
}

export default Tabs;
