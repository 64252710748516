import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';

type Props = {
    values: any;
    setFieldValue: (field: string, value: any) => void;
    propertyNegotiation: 'let' | 'sale';
};

const OfferModalDetailsDirection: React.FC<Props> = props => {
    const { values, setFieldValue, propertyNegotiation } = props;

    const content = {
        ofb: (
            <FormattedMessage
                id={`offers.modals.details.direction.${propertyNegotiation === 'let' ? 'tenant' : 'buyer'}`}
                defaultMessage="Offer From Buyer"
            />
        ),
        ofs: <FormattedMessage id="offers.modals.details.direction.owner" defaultMessage="Offer From Owner" />,
    };

    return (
        <div className="offer-modal-details-direction">
            <button
                onClick={() => {
                    setFieldValue('financialDetails.direction', 'BUYER_TO_OWNER');
                }}
                className={`offer-modal-details-direction__button${
                    values?.financialDetails?.direction === 'BUYER_TO_OWNER' ? ' active' : ''
                }`}
            >
                {content.ofb}
            </button>
            <button
                onClick={() => {
                    setFieldValue('financialDetails.direction', 'OWNER_TO_BUYER');
                }}
                className={`offer-modal-details-direction__button${
                    values?.financialDetails?.direction === 'OWNER_TO_BUYER' ? ' active' : ''
                }`}
            >
                {content.ofs}
            </button>
        </div>
    );
};

export default OfferModalDetailsDirection;
