import React, { useContext, useMemo, useState } from 'react';
import Tabs from '@sweepbright/uikit/build/esm/tabs';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import PagePane from '@/app.layouts/PagePane/PagePane';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import OfferList from '@/app.shared/offers/OfferList';
import OfferModal from '@/app.shared/offers/OfferModal';
import Icon from '@/app.components/icons/Icon';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';
import { LayoutContext } from '@/app.components/layouts/utils';
import { useAuthCheck } from '@/app.hooks/auth/useAuthCheck';
import { OfferContext, formatOfferAuthInput } from '../utils';

type Props = {
    loading: boolean;
    contactId?: string;
    propertyId?: string;
    activeTabIndex: number;
    setActiveTabIndex: (index: number) => void;
    data: OfferCardFragmentFragment[];
    refetch: () => void;
};

const OfferPage: React.FC<Props> = props => {
    const { propertyId, contactId, loading, activeTabIndex, setActiveTabIndex, data, refetch } = props;

    const { handleOfferStatsRefetch } = useContext(LayoutContext);

    const [visible, setVisible] = useState(false);

    const [activeOfferId, setActiveOfferId] = useState('');
    const [defaultPropertyId, setDefaultPropertyId] = useState(propertyId || '');
    const [defaultContactId, setDefaultContactId] = useState(contactId || '');
    const [counterParentId, setCounterParentId] = useState('');

    const activeOffer = useMemo(() => {
        return data.find(el => el.id === activeOfferId);
    }, [data, activeOfferId]);

    const parentOffer = useMemo(() => {
        return data.find(el => el.id === counterParentId);
    }, [data, counterParentId]);

    const { data: dataAuth, loading: loadingAuth } = useAuthCheck({
        skip: !data.length,
        variables: {
            input: [
                ...formatOfferAuthInput(data, 'view'),
                ...formatOfferAuthInput(data, 'edit'),
                ...formatOfferAuthInput(data, 'cancel'),
                ...formatOfferAuthInput(data, 'accept'),
                ...formatOfferAuthInput(data, 'refuse'),
                ...formatOfferAuthInput(data, 'archive'),
            ],
        },
    });

    const closeModal = () => {
        setVisible(false);

        setActiveOfferId('');

        setDefaultPropertyId(propertyId || '');
        setDefaultContactId(contactId || '');
        setCounterParentId('');
    };

    const openAddModal = () => {
        setVisible(true);
    };

    const openEditModal = (editOfferId: string) => {
        setActiveOfferId(editOfferId);

        setVisible(true);
    };

    const openCounterModal = (counterParentId: string, counterPropertyId: string, counterContactId: string) => {
        setDefaultPropertyId(counterPropertyId);
        setDefaultContactId(counterContactId);
        setCounterParentId(counterParentId);

        setVisible(true);
    };

    const handleRefetch = () => {
        refetch();

        handleOfferStatsRefetch();
    };

    const content = {
        title: <FormattedMessage id="offers.title" defaultMessage="Offers" />,
        add: <FormattedMessage id="offers.add" defaultMessage="Add Offer" />,
        timeline: <FormattedMessage id="offers.timeline" defaultMessage="Timeline" />,
        archived: <FormattedMessage id="offers.archived" defaultMessage="Archived" />,
    };

    return (
        <OfferContext.Provider
            value={{
                visible,
                refetch: handleRefetch,
                openAddModal,
                openEditModal,
                openCounterModal,
                defaultPropertyId,
                defaultContactId,
                counterParentId,
                dataAuth: dataAuth?.authCheck,
            }}
        >
            <PagePane
                title={content.title}
                actions={[
                    <Button
                        key="add"
                        variant="primary"
                        onClick={() => {
                            setVisible(true);
                        }}
                        icon={<Icon icon="circle-add" />}
                    >
                        {content.add}
                    </Button>,
                ]}
            >
                <OfferModal record={activeOffer} parentRecord={parentOffer} onClose={closeModal} />

                <Tabs
                    defaultIndex={activeTabIndex}
                    onChange={(selectedIndex: number) => {
                        setActiveTabIndex(selectedIndex);
                    }}
                >
                    <Tabs.TabList>
                        <Tabs.Tab key="main">{content.timeline}</Tabs.Tab>
                        <Tabs.Tab key="archived">{content.archived}</Tabs.Tab>
                    </Tabs.TabList>
                    <Tabs.TabPanels>
                        <Tabs.TabPanel key="main">
                            <ErrorBoundary>
                                {activeTabIndex === 0 && (
                                    <OfferList title={content.timeline} data={data} loading={loading || loadingAuth} />
                                )}
                            </ErrorBoundary>
                        </Tabs.TabPanel>
                        <Tabs.TabPanel key="archived">
                            <ErrorBoundary>
                                {activeTabIndex === 1 && (
                                    <OfferList
                                        archived
                                        data={data}
                                        title={content.archived}
                                        loading={loading || loadingAuth}
                                    />
                                )}
                            </ErrorBoundary>
                        </Tabs.TabPanel>
                    </Tabs.TabPanels>
                </Tabs>
            </PagePane>
        </OfferContext.Provider>
    );
};

export default OfferPage;
