import React from 'react';
import { Formik } from 'formik';
import { Col } from 'react-bootstrap';
import Button from '@/app.components/elements/Buttons/Button';
import MembersPageBack from '@/new.domains/members/MembersPage/MembersPageBack';
import { isCompanyAdmin } from '@/app.data';
import useUser from '@/app.hooks/useUser';
import useCompany from '@/app.hooks/useCompany';
import FormPane from '@/app.components/forms/FormPane';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import UpgradeWarning from '@/new.domains/members/UpgradeWarning';
import { SuccessAlert } from '@/app.components/elements/Alerts/Alerts';
import CompanyRepository from '@/app.utils/services/Repositories/CompanyRepository';
import { ONE_BY_ONE } from '@/new.domains/members/utils';
import MembersAddFormBlock from './MembersAddFormBlock';

type Props = {
    loading: boolean;
    bulk?: boolean;
    mode: string;
    setMode: Function;
    handleSubmit: (values: any) => void;
    inviteErrors: { mode: string; title: string; field: string }[];
    success: boolean;
    content: {
        title: JSX.Element;
        success: JSX.Element;
        submit: JSX.Element;
        submitting: JSX.Element;
    };
};

const MembersAddForm: React.FC<Props> = props => {
    const { loading, mode, setMode, success, handleSubmit, inviteErrors, bulk, content } = props;

    const user = useUser();
    const company = useCompany();
    const repository = new CompanyRepository(company);

    const isMaxCapacity = repository.isAtMaximumCapacity();
    const defaultLanguage = company.getIn(['settings', 'data', 'default_locale']);

    return (
        <Col xs={11} lg={12} className="h-full">
            <FormPane {...({} as any)} title={content.title} backAction={<MembersPageBack />}>
                <FormPanel>
                    <Formik
                        onSubmit={() => {}}
                        initialValues={{
                            officeId: '',
                            bulkField: '',
                            language: defaultLanguage || '',
                            field: [{ email: '', firstName: '', lastName: '' }],
                        }}
                    >
                        {({ values }) => {
                            const canSubmit =
                                !loading &&
                                (mode === ONE_BY_ONE
                                    ? values.field.filter(el => el.email).length > 0
                                    : values.bulkField.length > 0) &&
                                !!values.officeId;

                            return (
                                <>
                                    {!success && isMaxCapacity && <UpgradeWarning isOwner={isCompanyAdmin(user)} />}

                                    {!success && !isMaxCapacity && (
                                        <div>
                                            <MembersAddFormBlock
                                                bulk={bulk}
                                                mode={mode}
                                                setMode={setMode}
                                                inviteErrors={inviteErrors}
                                            />
                                            <p>
                                                <Button
                                                    type="submit"
                                                    block
                                                    variant="primary"
                                                    disabled={!canSubmit}
                                                    onClick={() => {
                                                        handleSubmit(values);
                                                    }}
                                                >
                                                    {loading ? content.submitting : content.submit}
                                                </Button>
                                            </p>
                                        </div>
                                    )}

                                    {success && <SuccessAlert title={content.success} />}
                                </>
                            );
                        }}
                    </Formik>
                </FormPanel>
            </FormPane>
        </Col>
    );
};

export default MembersAddForm;
