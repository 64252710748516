import gql from 'graphql-tag';
import estateChannelAccountEdge from '@/graphql/fragments/estateChannelAccountEdge';

export const UPDATE_PUBLICATION_MUTATION = gql`
    mutation UpdatePublication($input: PublishPropertyInput!, $channelAccountId: ID!) {
        updatePublication(input: $input) {
            estate {
                id
                channelAccount(id: $channelAccountId) {
                    ...EstateChannelAccountEdgeFragment
                }
            }
        }
    }
    ${estateChannelAccountEdge}
`;

export const CREATE_PUBLICATION_MUTATION = gql`
    mutation CreatePublication($input: PublishPropertyInput!, $channelAccountId: ID!) {
        createPublication(input: $input) {
            estate {
                id
                channelAccount(id: $channelAccountId) {
                    ...EstateChannelAccountEdgeFragment
                }
            }
        }
    }
    ${estateChannelAccountEdge}
`;

export const REMOVE_PUBLICATION_MUTATION = gql`
    mutation RemovePublication($input: UnpublishPropertyInput!, $channelAccountId: ID!) {
        removePublication(input: $input) {
            estate {
                id
                channelAccount(id: $channelAccountId) {
                    ...EstateChannelAccountEdgeFragment
                }
            }
        }
    }
    ${estateChannelAccountEdge}
`;

export const RETRY_PUBLICATION_MUTATION = gql`
    mutation RetryPublication($input: RetryPublicationInput!) {
        retryPublication(input: $input) {
            channelAccount {
                ...EstateChannelAccountEdgeFragment
            }
        }
    }
    ${estateChannelAccountEdge}
`;

export const REFRESH_PUBLICATION_MUTATION = gql`
    mutation RefreshPublication($input: RefreshPublicationInput!) {
        refreshPublication(input: $input) {
            success
        }
    }
`;

export const GET_FRENCH_PORTALS_MUTATION = gql`
    mutation GetFrenchPortals($input: GetFrenchPortalsInput!) {
        getFrenchPortals(input: $input) {
            portals {
                automatic
                code
                free
                id
                logoUrl
                name
                url
            }
        }
    }
`;
