import * as changeCase from 'change-case';

const labels: { [key: string]: string } = {
    toilets: 'WCs',
    closed_office_spaces: 'Private Office Spaces',
    has_rivers_lakes_or_ponds: 'River, Lake, Pond',
    liveable_area: 'Liveable',
    gross_area: 'Gross',
    plot_area: 'Plot',
    net_area: 'Net',
    living_room: 'Living Rooms',
    kitchen: 'Kitchens',
    display_window_length: 'Display Window',
    ceiling_height: 'Ceiling Height',
    utilities_access: 'Utilities',
    print_and_copy_area: 'Print & Copy Area',
    commercial: 'Commercial Property',
    rea: 'realestate.com.au',
    'realestate.com.au': 'realestate.com.au',
    onthemarket: 'OnTheMarket',
};

export function toTitle(text?: string): string {
    if (typeof text === 'string') {
        return labels[text] ? labels[text] : changeCase.titleCase(changeCase.sentenceCase(text));
    }
    throw new Error('non string value passed to toTitle method');
}

/**
 * Make a word naively singular
 */
export function toSingular(text: string): string {
    if (text.length <= 1) {
        return text;
    }

    return text.slice(0, -1);
}

/**
 * truncate a word
 */
export function truncate(text: string, characters: number = 30): string {
    if (text.length <= characters) {
        return text;
    }

    return `${text.substring(0, characters)}...`;
}
