import { createReducer } from 'redux-reducer-factory';
import { SET_PLANS } from '../actions';

export default createReducer(
    {
        items: [],
    },
    {
        [SET_PLANS]: (plans, { payload }, sanitize) => plans.set('items', sanitize(payload)),
    },
);
