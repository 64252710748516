import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER_TEAMS } from '@/graphql/queries/teams/getUserTeams';
import { CompanyTeam, GetUserTeamsQuery, GetUserTeamsQueryVariables } from '@/graphql/generated/types';

export const useUserTeams = (userId?: string) => {
    const [pageTeams, setPageTeams] = useState(1);

    const [teams, setTeams] = useState<CompanyTeam[]>([]);

    const { data: dataTeams, loading: loadingTeams } = useQuery<GetUserTeamsQuery, GetUserTeamsQueryVariables>(
        GET_USER_TEAMS,
        {
            variables: {
                limit: 10,
                page: pageTeams,
                userId: userId || '',
            },
            fetchPolicy: 'no-cache',
            skip: !userId,
        },
    );

    const pagination = dataTeams?.userTeams?.meta?.pagination;

    const currentPage = pagination?.current_page || 1;
    const totalPages = pagination?.total_pages || 1;
    const totalCount = pagination?.total || 0;
    const hasNextPage = pagination ? currentPage < totalPages : false;

    useEffect(() => {
        if (!loadingTeams) {
            const newData = dataTeams?.userTeams?.data || [];

            if (newData.length === 0 && pageTeams === 1) {
                setTeams([]);
            }

            if (newData.length > 0) {
                if (pageTeams === 1) {
                    // @ts-ignore
                    setTeams(newData);
                }

                if (pageTeams > 1) {
                    // @ts-ignore
                    setTeams(prevData => [...(prevData || []), ...newData]);
                }
            }
        }
    }, [pageTeams, dataTeams, loadingTeams]);

    const fetchMore = () => {
        if (hasNextPage && !loadingTeams) {
            setPageTeams(prevState => prevState + 1);
        }
    };

    const handleRefetch = () => {
        setPageTeams(0);
        setPageTeams(1);
    };

    return {
        teams: teams,
        error: false,
        loading: loadingTeams,
        currentPage: pageTeams,
        refetch: handleRefetch,
        handleFetchMore: fetchMore,
        totalCount: totalCount || 0,
        hasNextPage: hasNextPage || false,
    };
};
