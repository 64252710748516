import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useToasts } from '@sweepbright/notifications';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { CompanyLabelsContext } from '../context';

const CompanyLabelsDelete = () => {
    const intl = useIntl();
    const { addError } = useToasts();

    const {
        refetch,
        activeEntity,
        setActiveEntity,
        setVisible,
        visibleDelete,
        setVisibleDelete,
        deleteLabel,
    } = useContext(CompanyLabelsContext);

    const [loading, setLoading] = useState(false);

    const content = {
        title: intl.formatMessage({ id: 'contact.labels.delete.title' }),
        text: intl.formatMessage({ id: 'contact.labels.delete.text' }),
        cancel: intl.formatMessage({ id: 'general.action.cancel' }),
        delete: intl.formatMessage({ id: 'general.action.delete' }),
        unauthorized: intl.formatMessage({ id: 'unauthorised_403' }),
        error: intl.formatMessage({ id: 'contact.labels.delete.errormessage' }),
    };

    const handleCancel = () => {
        setVisibleDelete(false);
        setVisible(true);
        setActiveEntity(activeEntity);
    };

    const handleSubmit = () => {
        setLoading(true);

        deleteLabel({
            variables: {
                id: activeEntity?.id,
            },
        })
            .catch(err => {
                const isForbidden = err?.message?.includes('403');

                addError({
                    message: isForbidden ? content.unauthorized : content.error,
                });
            })
            .finally(() => {
                refetch();

                setLoading(false);
                setVisibleDelete(false);
                setVisible(null);
                setActiveEntity(undefined);
            });
    };

    const isDisabled = loading;

    return (
        <Modal show={visibleDelete} onHide={() => {}}>
            <Modal.Header>
                <Modal.Title>{content.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{content.text}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCancel} disabled={isDisabled}>
                    {content.cancel}
                </Button>
                <Button variant="danger" onClick={handleSubmit} disabled={isDisabled}>
                    {content.delete}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CompanyLabelsDelete;
