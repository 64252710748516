import SweepbrightCrudRequest from './SweepbrightCrudRequest';

export default class AbstractArchivableRequest extends SweepbrightCrudRequest {
    archive = (propertyKey: string) => {
        return this.post(`${this.resource}/${propertyKey}/archive`);
    };

    unarchive = (propertyKey: string) => {
        return this.post(`${this.resource}/${propertyKey}/unarchive`);
    };
}
