import gql from 'graphql-tag';

export const GET_USER_CALENDAR = gql`
    query GetUserWithCalendar {
        me {
            id
            calendar
            hasCalendarIntegration
            appointmentServiceUrl
        }
    }
`;
