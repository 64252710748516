import React from 'react';
import { InputProps } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import Input from '../../../app.components/forms/Input/Input';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';

const PropertyTypesSection = ({
    fields,
    excludeProjects,
}: {
    excludeProjects?: boolean;
    fields: {
        property_type: InputProps;
    };
}) => {
    // only single properties
    if (excludeProjects) {
        return null;
    }

    return (
        <FormPanel
            title={
                <FormattedMessage id="modals.properties_search.property_type_pane.title" defaultMessage="Properties" />
            }
        >
            <Input
                type="radio"
                label={
                    <FormattedMessage id="modals.properties_search.property_type_pane.all_label" defaultMessage="All" />
                }
                {...fields.property_type}
                value="property_project"
                checked={fields.property_type.value === 'property_project'}
            />
            <Input
                type="radio"
                label={
                    <FormattedMessage
                        id="modals.properties_search.property_type_pane.property_label"
                        defaultMessage="Single Properties"
                    />
                }
                {...fields.property_type}
                value="single_property"
                checked={fields.property_type.value === 'single_property'}
            />
            <Input
                type="radio"
                label={
                    <FormattedMessage
                        id="modals.properties_search.property_type_pane.project_label"
                        defaultMessage="Projects"
                    />
                }
                {...fields.property_type}
                value="single_project"
                checked={fields.property_type.value === 'single_project'}
            />
        </FormPanel>
    );
};

export default PropertyTypesSection;
