// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';
import Downshift, { ControllerStateAndHelpers } from 'downshift';
import Popover, { positionMatchWidth } from '@reach/popover';
import { FormattedMessage } from 'react-intl-sweepbright';
import isScrollEnd from '@/utils/isScrollEnd';
import { defaultOptionRenderer, defaultFilterOptions, defaultInputRenderer, noop } from './utils';
import './styles.scss';

type SelectOption<Value> = {
    value: Value;
    label: React.ReactNode;
};

export type SelectVariant = 'default' | 'lg';

interface SelectPropsSimpleValue<Value> {
    simpleValue: true;
    onChange?: (option: Maybe<Value>, helpers?: ControllerStateAndHelpers<any>) => void;
}

interface SelectPropsOptionValue<Option> {
    simpleValue: false;
    onChange?: (option: Maybe<Option>, helpers?: ControllerStateAndHelpers<any>) => void;
}

type SelectProps<Value, Option> = {
    id?: string;
    testId?: string;
    value: Value;
    hasError?: boolean;
    options: Option[];
    placeholder?: React.ReactNode;
    isSearchable?: boolean;
    disabled?: boolean;
    loading?: boolean;
    autoFocus?: boolean;
    clearable?: boolean;
    noResultsText?: React.ReactNode;
    onScroll?: (isScrollEnd: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
    valueRenderer?: (option: Option, state: { isOpen: boolean }) => React.ReactNode;
    optionRenderer?: any;
    inputRenderer?: (option: Option) => React.ReactNode;
    maxInputLength?: number;
    menuRenderer?: () => React.ReactNode;
    footerRenderer?: (
        helpers: Pick<ControllerStateAndHelpers<Option>, 'closeMenu' | 'clearSelection'>,
    ) => React.ReactNode;
    onMenuScrollToBottom?: () => void;
    showMenuToggle?: boolean;
    maxMenuHeight?: Maybe<number>;
    selectedItem?: Option;
    inputValue?: string;
    onInputChange?: (value: string, helpers?: ControllerStateAndHelpers<any>) => void;
    onInputBlur?: (evt: React.ChangeEvent<HTMLInputElement>, helpers?: ControllerStateAndHelpers<any>) => void;
    filterOptions?: any;
    className?: string;
    variant?: SelectVariant;
    addonAfter?: React.ReactNode;
    name?: string;
    truncate?: boolean;
    emptyValue?: string;
} & (SelectPropsSimpleValue<Value> | SelectPropsOptionValue<Option>);

export default function Select<Value = any, Option extends SelectOption<Value> = SelectOption<Value>>({
    placeholder,
    noResultsText,
    valueRenderer,
    menuRenderer,
    footerRenderer,
    clearable,
    loading,
    autoFocus,
    disabled,
    onChange,
    onMenuScrollToBottom,
    showMenuToggle = true,
    filterOptions,
    options = [],
    optionRenderer,
    inputRenderer,
    maxInputLength,
    inputValue,
    onInputChange,
    onInputBlur,
    hasError,
    value,
    isSearchable = true,
    maxMenuHeight = 300,
    testId,
    id,
    onScroll,
    selectedItem,
    simpleValue,
    className,
    variant = 'default',
    addonAfter,
    name,
    truncate,
    emptyValue,
}: SelectProps<Value, Option>) {
    // eslint-disable-next-line eqeqeq
    selectedItem = selectedItem ?? options.find(option => option.value == value) ?? null;
    const handleChange = React.useCallback(
        (option, helpers) => {
            if (simpleValue) {
                if (typeof emptyValue !== 'undefined') {
                    onChange?.(option?.value ?? emptyValue, helpers);
                } else {
                    // eslint-disable-next-line no-unused-expressions
                    onChange?.(option?.value ?? null, helpers);
                }
            } else {
                // eslint-disable-next-line no-unused-expressions
                onChange?.(option, helpers);
            }
            // eslint-disable-next-line no-unused-expressions
        },
        [onChange, emptyValue, simpleValue],
    );

    return (
        <Downshift
            defaultHighlightedIndex={0}
            onSelect={handleChange}
            inputValue={inputValue}
            itemToString={() => ''}
            onInputValueChange={onInputChange}
            selectedItem={selectedItem}
            selectedItemChanged={(prevItem, item) => {
                // eslint-disable-next-line eqeqeq
                return prevItem?.value != item?.value;
            }}
        >
            {({
                isOpen,
                getInputProps,
                getMenuProps,
                getItemProps,
                inputValue,
                highlightedIndex,
                selectedItem,
                closeMenu,
                openMenu,
                getToggleButtonProps,
                clearSelection,
                selectItem,
                getRootProps,
            }) => {
                return (
                    <SelectInner
                        {...getRootProps()}
                        noResultsText={noResultsText}
                        isOpen={isOpen}
                        getInputProps={getInputProps}
                        getMenuProps={getMenuProps}
                        getItemProps={getItemProps}
                        inputValue={inputValue}
                        onInputBlur={onInputBlur}
                        highlightedIndex={highlightedIndex}
                        selectedItem={selectedItem}
                        getToggleButtonProps={getToggleButtonProps}
                        placeholder={
                            placeholder || <FormattedMessage id="common.actions.select" defaultMessage="Select..." />
                        }
                        valueRenderer={valueRenderer}
                        menuRenderer={menuRenderer}
                        optionRenderer={optionRenderer}
                        inputRenderer={inputRenderer}
                        maxInputLength={maxInputLength}
                        footerRenderer={footerRenderer}
                        clearable={clearable}
                        closeMenu={closeMenu}
                        openMenu={openMenu}
                        clearSelection={clearSelection}
                        loading={loading}
                        onMenuScrollToBottom={onMenuScrollToBottom}
                        autoFocus={autoFocus}
                        filterOptions={filterOptions}
                        options={options}
                        disabled={disabled}
                        hasError={hasError}
                        showMenuToggle={showMenuToggle}
                        isSearchable={isSearchable}
                        maxMenuHeight={maxMenuHeight}
                        testId={testId}
                        selectItem={selectItem}
                        id={id}
                        onScroll={onScroll}
                        className={className}
                        addonAfter={addonAfter}
                        name={name}
                        variant={variant}
                        truncate={truncate}
                    />
                );
            }}
        </Downshift>
    );
}

/// elements

const SelectInner = React.forwardRef(function SelectInner(
    {
        isOpen,
        getInputProps,
        getMenuProps,
        getItemProps,
        inputValue,
        onInputBlur,
        highlightedIndex,
        selectedItem,
        getToggleButtonProps,
        placeholder,
        optionRenderer = defaultOptionRenderer,
        inputRenderer = defaultInputRenderer,
        maxInputLength,
        valueRenderer = defaultOptionRenderer,
        footerRenderer,
        closeMenu,
        openMenu,
        clearSelection,
        clearable = false,
        loading = false,
        onMenuScrollToBottom = noop,
        autoFocus,
        filterOptions = defaultFilterOptions,
        options,
        disabled,
        noResultsText = <FormattedMessage id="general.state.no_results" defaultMessage="No results" />,
        hasError,
        showMenuToggle,
        isSearchable,
        maxMenuHeight,
        testId,
        id,
        onScroll,
        selectItem,
        className,
        variant,
        addonAfter,
        name,
        truncate,
    },
    rootRef,
) {
    const searchRef = React.useRef<HTMLInputElement>(null);
    const controlRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (disabled) {
            // eslint-disable-next-line no-unused-expressions
            searchRef.current?.blur();
        }
    }, [disabled]);

    React.useEffect(() => {
        if (autoFocus) {
            // eslint-disable-next-line no-unused-expressions
            searchRef.current?.focus();
        }
    }, [autoFocus]);

    React.useEffect(() => {
        if (isOpen) {
            // eslint-disable-next-line no-unused-expressions
            searchRef.current?.focus();
        }
    }, [isOpen]);

    function handleClear(evt) {
        evt.stopPropagation();
        // clear the selected item, and the
        // search field
        clearSelection();
        // focus the search field
        // eslint-disable-next-line no-unused-expressions
        searchRef.current?.focus();
        // close the menu
        closeMenu();
    }

    React.useEffect(() => {
        if (inputValue !== '' && !isOpen) {
            openMenu();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    const filteredItems = React.useMemo(() => {
        if (filterOptions) {
            return filterOptions(options, inputValue);
        }

        return options;
    }, [options, filterOptions, inputValue]);

    const Input = inputRenderer;

    const controlProps = isSearchable ? {} : getToggleButtonProps();

    return (
        <div
            data-testid={testId}
            className={classNames(
                'c-select',
                {
                    'c-select--is-open': isOpen,
                    'c-select--disabled': disabled,
                    'c-select--has-error': hasError,
                },
                {
                    'c-select--lg': variant === 'lg',
                },
                className,
            )}
            ref={rootRef}
        >
            <div
                className={classNames('c-select-control__wrapper flex divide-x divide-gray-light items-center')}
                ref={controlRef}
            >
                <div {...controlProps} className="c-select-control">
                    <div
                        className={classNames('c-select-control__value-wrapper', {
                            'c-select-control__value-wrapper--searchable': isSearchable,
                        })}
                    >
                        {inputValue.length === 0 && selectedItem == null && (
                            <div className="c-select-control__placeholder">{placeholder}</div>
                        )}
                        {selectedItem && inputValue.length === 0 ? (
                            <div className="c-select-control__value" data-value={selectedItem?.value}>
                                {valueRenderer(selectedItem, { isOpen })}
                            </div>
                        ) : null}
                        {isSearchable && (
                            <div className="c-select-control__input">
                                <Input
                                    {...getInputProps({
                                        ref: searchRef,
                                        disabled,
                                        onFocus: () => openMenu(),
                                        id,
                                        onBlur: onInputBlur,
                                    })}
                                    name={name}
                                    data-testid={`${testId}-input`}
                                    autoComplete="off"
                                    maxLength={maxInputLength}
                                />
                            </div>
                        )}
                    </div>
                    {clearable && selectedItem && !loading ? (
                        <div className="c-select-control__clear-zone" role="button" onClick={handleClear}>
                            <ClearIcon className="c-select-control__clear" />
                        </div>
                    ) : null}
                    {loading && !disabled ? (
                        <div className="c-select-control__loading-zone">
                            <div className="spinner" />
                        </div>
                    ) : null}
                    {showMenuToggle && (
                        <div className="c-select-control__arrow-zone" {...(isSearchable && getToggleButtonProps())}>
                            <div className="c-select-control__arrow" />
                        </div>
                    )}
                </div>
                {addonAfter}
            </div>
            {isOpen ? (
                <Popover
                    targetRef={controlRef}
                    {...getMenuProps()}
                    position={positionMatchWidth}
                    className={classNames('c-select-menu__outer', {
                        'c-select-menu__outer--lg': variant === 'lg',
                    })}
                >
                    <ul
                        className="c-select-menu"
                        data-testid={`${testId}-menu`}
                        style={{ maxHeight: maxMenuHeight }}
                        onScroll={
                            onScroll
                                ? event => {
                                      onScroll(isScrollEnd(event), event);
                                  }
                                : undefined
                        }
                    >
                        {filteredItems.map((item, index) => (
                            <li
                                key={item.value}
                                className={classNames(
                                    `c-select-menu__option${truncate ? ' truncate' : ''}`,
                                    item.disabled ? 'c-select-menu__option--disabled' : '',
                                )}
                                {...(item.disabled
                                    ? {}
                                    : getItemProps({
                                          key: item.value,
                                          index,
                                          item,
                                          style: {
                                              backgroundColor: highlightedIndex === index ? '#f5f5f5' : 'white',
                                              fontWeight: selectedItem === item ? 'bold' : 'normal',
                                          },
                                      }))}
                            >
                                {optionRenderer(item, {
                                    selected: selectedItem === item,
                                    highlighted: highlightedIndex === index,
                                })}
                            </li>
                        ))}

                        <Waypoint onEnter={onMenuScrollToBottom} />
                    </ul>
                    {showNoResults() ? (
                        <div className="c-select-menu__no-results">
                            {loading ? (
                                <FormattedMessage id="general.state.loading" defaultMessage="Loading..." />
                            ) : (
                                getNoResultsText()
                            )}
                        </div>
                    ) : null}
                    {footerRenderer ? (
                        <div className="c-select-menu__footer">{footerRenderer({ closeMenu, clearSelection })}</div>
                    ) : null}
                </Popover>
            ) : null}
        </div>
    );

    function showNoResults() {
        if (filteredItems.length > 0 || filteredItems.size > 0) {
            return false;
        }

        return true;
    }

    function getNoResultsText() {
        if (loading) {
            return <FormattedMessage id="general.state.loading" defaultMessage="Loading..." />;
        }

        if (typeof noResultsText === 'function') {
            return noResultsText({ inputValue, selectItem });
        }

        return noResultsText;
    }
});

function ClearIcon({ className }) {
    return (
        <svg x="0px" y="0px" viewBox="0 0 11 11" enableBackground="new 0 0 11 11" className={className}>
            <g id="_x2E_png_134_">
                <path
                    fill="currentColor"
                    d="M5.5,0C2.4678,0,0,2.4678,0,5.5S2.4678,11,5.5,11S11,8.5322,11,5.5S8.5322,0,5.5,0z M7.5947,6.8877 c0.1953,0.1953,0.1953,0.5117,0,0.707C7.4971,7.6924,7.3691,7.7412,7.2412,7.7412S6.9854,7.6924,6.8877,7.5947L5.4912,6.1982 L4.0947,7.5947C3.9971,7.6924,3.8691,7.7412,3.7412,7.7412S3.4854,7.6924,3.3877,7.5947c-0.1953-0.1953-0.1953-0.5117,0-0.707 l1.3965-1.3965L3.3877,4.0947c-0.1953-0.1953-0.1953-0.5117,0-0.707s0.5117-0.1953,0.707,0l1.3965,1.3965l1.3965-1.3965 c0.1953-0.1953,0.5117-0.1953,0.707,0s0.1953,0.5117,0,0.707L6.1982,5.4912L7.5947,6.8877z"
                ></path>
            </g>
        </svg>
    );
}
