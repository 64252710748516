import React, { FC } from 'react';
import { OwnershipFilter } from '@/app.components/forms/OwnershipFilter';
import { FormattedMessage } from 'react-intl-sweepbright';

const OwnershipFormatter = (name: string) => {
    return (
        <FormattedMessage
            id="components.contacts_ownership_filter.with_negotiator_label"
            defaultMessage="{name}'s Contacts"
            values={{
                name,
            }}
        />
    );
};

interface Values {
    negotiator: Maybe<string>;
    noNegotiator: Maybe<boolean>;
}

interface ContactsOwnershipFilterProps {
    defaultValues: Values;
    values: Values;
    onChange: (v: Values) => void;
}

export const ContactsOwnershipFilter: FC<ContactsOwnershipFilterProps> = ({ values, onChange, defaultValues }) => {
    return (
        <OwnershipFilter
            showUnassignedOption
            values={values}
            onChange={onChange}
            defaultValues={defaultValues}
            mineLabel={
                <FormattedMessage id="modals.contacts_search.ownership_pane.mine_label" defaultMessage="My Contacts" />
            }
            ownershipFormatter={OwnershipFormatter}
            trackSearchKey="contacts"
        />
    );
};
