import gql from 'graphql-tag';

export const ASSIGN_LEADS_MUTATION = gql`
    mutation AssignLeadsToOffice($officeId: ID!, $leadIds: [ID!]!) {
        assignLeadsToOffice(input: { officeId: $officeId, leadIds: $leadIds }) {
            success
            assignedLeads {
                id
            }
        }
    }
`;
