import gql from 'graphql-tag';

export const UPDATE_LEGAL_ENTITY = gql`
    mutation UpdateLegalEntity($officeId: ID!, $input: LegalEntitiesInput!) {
        updateLegalEntity(officeId: $officeId, input: $input) {
            id
            name
            office_id
        }
    }
`;
