import { defineMessages } from 'react-intl';

export default {
    home: 'http://get.sweepbright.help/',
    contactNoMatches: 'http://get.sweepbright.help/match/web-application/find-the-perfect-match-for-a-contact-web',
    privacy: 'https://sweepbright.com/privacy/',
    match: 'http://get.sweepbright.help/match/web-application/find-the-perfect-match-for-a-property-web',
    addProperty: 'http://get.sweepbright.help/getting-started/let-s-sweepbright/step-2-add-your-first-property-3-min',
    publishProperty: 'http://get.sweepbright.help/getting-started/let-s-sweepbright/step-4-publish-a-property-1-min',
    scheduleVisit: 'http://get.sweepbright.help/visit/web-application/visit-scheduling-a-visit-web',
    addContact: 'http://get.sweepbright.help/contacts/web-application/contacts-add-a-new-lead-or-vendor-web',
    addLocationPreference: 'http://get.sweepbright.help/faq-general/add-location-preferences-webapp',
    units: 'http://get.sweepbright.help/properties/faq/how-to-set-up-a-project-web',
    agencyWebsiteCalendars:
        'https://get.sweepbright.help/en/articles/1978746-add-your-scheduling-link-to-your-listings',
    calendarIntegration: 'http://get.sweepbright.help/my-profile/setting-up-your-calendar',
    moveBotler: 'http://get.sweepbright.help/en/articles/3413747-meet-the-movebotler',
    zoopla: 'https://sweepbrightx.typeform.com/to/wFh2wE',
    rightmove: 'https://sweepbrightx.typeform.com/to/XrdKGC',
    onTheMarket: 'https://sweepbrightx.typeform.com/to/PvxogL',
};

export const localizedHelpLinks = defineMessages({
    moveBotler: {
        id: 'helpLinks.moveBotler',
        defaultMessage: 'https://get.sweepbright.help/en/articles/3413747-meet-the-movebotler',
    },
});
