import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import { Button, LoadingIndicator } from '@sweepbright/uikit';
import classNames from 'classnames';
import { useToasts } from '@sweepbright/notifications';
import useRouter from '@/app.hooks/useRouter';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import { FormattedMessage } from 'react-intl-sweepbright';
import Input from '@/app.components/forms/Input/Input';
import TimeInput from '@/app.components/forms/TimeInput';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import EmptyBlock from '@/app.components/empty/EmptyBlock';
import { fetchPropertyAuction, updateAuctionMutation, deleteAuctionMutation } from './visits_requests';
import { validateTimeRange } from './timeRangeHelpers';

type Values = {
    date: string;
    start: string;
    end: string;
};

export function AuctionPanel({ propertyId, disabled }: { propertyId: string; disabled?: boolean }) {
    const queryCache = useQueryCache();
    const { addSuccess, addError } = useToasts();
    const [state, setState] = React.useState('');
    const editing = state === 'editing';
    const activeEventId = useRouter()?.location?.query?.eventId;

    const { data: auction, isLoading } = useQuery(
        [`/properties/${propertyId}/auction`, propertyId],
        fetchPropertyAuction,
    );
    React.useEffect(() => {
        if (!isLoading && activeEventId && activeEventId === auction?.id) {
            setState('editing');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, activeEventId]);

    const [deleteAuction] = useMutation(() => deleteAuctionMutation(propertyId), {
        onSuccess: () => {
            queryCache.refetchQueries(`/properties/${propertyId}/schedule`);
            queryCache.refetchQueries(`/properties/${propertyId}/auction`);
            addSuccess({
                message: (
                    <FormattedMessage
                        id="pages.properties.auction_panel.auction_deleted"
                        defaultMessage="Auction deleted"
                    />
                ),
            });
        },
        onError: err => {
            // @ts-ignore
            const isForbidden = err?.message?.includes('403');

            addError({
                message: isForbidden ? (
                    <FormattedMessage id="unauthorised_403" />
                ) : (
                    <FormattedMessage
                        id="pages.properties.auction_panel.failed_to_delete_auction"
                        defaultMessage="Failed to delete auction"
                    />
                ),
            });
        },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDeleteAuction = useCallback(() => deleteAuction(), [deleteAuction, propertyId, queryCache]);

    const canAddAuction = !auction && !isLoading && !editing;

    return (
        <FormPanel
            disableAction={disabled}
            title={<FormattedMessage id="pages.properties.auction_panel.auction" defaultMessage="Auction" />}
            action={
                canAddAuction && (
                    <FormattedMessage id="pages.properties.auction_panel.action" defaultMessage="Add Auction" />
                )
            }
            onAction={() => {
                setState('editing');
            }}
        >
            {!isLoading && editing && (
                <AuctionForm
                    disabled={disabled}
                    auction={auction}
                    propertyId={propertyId}
                    onCancel={() => setState('')}
                    onUpdated={() => {
                        addSuccess({
                            message: auction ? (
                                <FormattedMessage
                                    id="pages.properties.auction_panel.acution_updated"
                                    defaultMessage="Auction updated"
                                />
                            ) : (
                                <FormattedMessage
                                    id="pages.properties.auction_panel.auction_added"
                                    defaultMessage="Auction added"
                                />
                            ),
                        });
                        setState('');
                    }}
                />
            )}
            {!isLoading && auction && !editing && (
                <div className="flex justify-between items-center">
                    <div className="flex justify-between flex-1">
                        <div className="truncate">{moment(auction.start).format('MMMM DD, YYYY')}</div>
                        <div className="text-muted flex-shrink-0">
                            {moment(auction.start).format('HH:mm')} - {moment(auction.end).format('HH:mm')}{' '}
                        </div>
                    </div>
                    <div className="ml-2 flex space-x-2">
                        <ButtonIcon
                            disabled={disabled}
                            type="edit"
                            variant="link"
                            iconSize={20}
                            onClick={() => setState('editing')}
                        />
                        <ButtonIcon
                            disabled={disabled}
                            type="circle-delete"
                            variant="link"
                            iconSize={20}
                            onClick={handleDeleteAuction}
                            className="text-danger"
                        />
                    </div>
                </div>
            )}
            {isLoading && <LoadingIndicator />}
            {canAddAuction && (
                <EmptyBlock
                    text={
                        <FormattedMessage
                            id="pages.properties.auction_panel.no_auction_date_specified"
                            defaultMessage="No auction date specified"
                        />
                    }
                />
            )}
        </FormPanel>
    );
}

function AuctionForm({ auction, propertyId, onCancel, onUpdated, disabled }: any) {
    const queryCache = useQueryCache();

    const { addError } = useToasts();

    const [updateAuction] = useMutation(updateAuctionMutation, {
        onSuccess() {
            queryCache.invalidateQueries(`/properties/${propertyId}/schedule`);
            queryCache.invalidateQueries(`/properties/${propertyId}/auction`);
        },
        onError: err => {
            // @ts-ignore
            const isForbidden = err?.message?.includes('403');

            addError({
                message: isForbidden ? (
                    <FormattedMessage id="unauthorised_403" />
                ) : (
                    <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                ),
            });
        },
        throwOnError: true,
    });

    const initialValues: Values = React.useMemo(() => {
        return {
            date: auction?.start ? moment(auction.start).format('YYYY-MM-DD') : '',
            start: auction ? moment(auction.start).format('HH:mm') : '',
            end: auction ? moment(auction.end).format('HH:mm') : '',
        };
    }, [auction]);

    const onSubmit = (values: Values) => {
        const startDateTime = moment(`${values.date} ${values.start}`);
        const endDateTime = moment(`${values.date} ${values.end}`);

        return updateAuction({
            propertyId,
            dateTime: startDateTime.toDate(),
            duration: endDateTime.diff(startDateTime, 'minutes'),
        }).then(onUpdated);
    };

    const { values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit,
        validate: validateTimeRange,
    });

    const startError = touched.start ? errors.start : undefined;
    const endError = touched.end ? errors.end : undefined;
    const dateError = errors.date;

    return (
        <form onSubmit={handleSubmit} className="flex items-center w-full justify-between">
            <div className="flex items-center space-x-2 flex-1">
                <FormGroup className={classNames('w-6/12', { 'has-error': dateError })}>
                    <ControlLabel>
                        <FormattedMessage id="pages.properties.auction_panel.date" defaultMessage="Date" />
                    </ControlLabel>
                    <Input
                        type="date"
                        name="date"
                        renderWrapper={null}
                        value={values.date}
                        onChange={handleChange('date')}
                    />
                    {dateError && <HelpBlock className="absolute">{dateError}</HelpBlock>}
                </FormGroup>
                <FormGroup className={classNames('w-3/12', { 'has-error': startError })}>
                    <ControlLabel>
                        <FormattedMessage id="pages.properties.auction_panel.start_time" defaultMessage="Start Time" />
                    </ControlLabel>
                    <TimeInput
                        hasError={!!startError}
                        singleMode
                        field={{
                            value: values.start,
                            onChange: handleChange('start'),
                            onBlur: handleBlur('start'),
                        }}
                    />
                    {startError && <HelpBlock className="absolute">{startError}</HelpBlock>}
                </FormGroup>
                <FormGroup className={classNames('w-3/12', { 'has-error': endError })}>
                    <ControlLabel>
                        <FormattedMessage id="pages.properties.auction_panel.end_time" defaultMessage="End Time" />
                    </ControlLabel>
                    <TimeInput
                        hasError={!!endError}
                        singleMode
                        field={{
                            value: values.end,
                            onChange: handleChange('end'),
                            onBlur: handleBlur('end'),
                        }}
                    />
                    {endError && <HelpBlock className="absolute">{endError}</HelpBlock>}
                </FormGroup>
            </div>
            <FormGroup className="flex space-x-2 self-end ml-2">
                <Button variant="primary" type="submit" disabled={disabled}>
                    <FormattedMessage id="pages.properties.auction_panel.save" defaultMessage="Save" />
                </Button>
                <Button
                    onClick={() => {
                        resetForm();
                        onCancel();
                    }}
                >
                    <FormattedMessage id="pages.properties.auction_panel.cancel" defaultMessage="Cancel" />
                </Button>
            </FormGroup>
        </form>
    );
}
