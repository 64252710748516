import { Map } from 'immutable';
import React, { Component } from 'react';
import { compose, withProps } from 'recompose';
import { InputProps, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPane from '@/app.components/forms/FormPane';
import YearInput from '@/app.domains/properties/YearInput';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';
import { EstateRepository } from '@/app.utils/services';
import { PropertyFieldVisibility } from '@/app.utils/services/Fields/PropertyFieldVisibility';
import { rulesMatcher } from '@/app.utils/services/Fields/rulesMatcher';
import { withActivePropertyConfirmation } from '@/app.utils/Decorators/withActivePropertyConfirmation';
import { FormPanel, Input, PropertyConditionSelector } from '../../../../app.components/index';
import WithPrivateTooltip from '../../../../app.components/forms/WithPrivateTooltip';
import { FeaturesCheckboxesSectionsList } from '../../../../app.domains/properties/FeaturesForm/FeaturesCheckboxesSectionsList';
import {
    isBuildingSectionVisible,
    isConditionsSectionVisible,
    isRenovationSectionVisible,
} from '../../../../app.domains/properties/FeaturesForm/helpers';

type Props = {
    fields: {
        building: {
            construction: {
                architect: InputProps;
                year: InputProps;
                residential_lots: InputProps;
            };
            renovation: {
                description: InputProps;
                year: InputProps;
            };
            units_of_building: InputProps;
            number_of_floor_building: InputProps;
        };
        conditions: {
            bathroom: InputProps;
            kitchen: InputProps;
        };
        features: {
            [type: string]: InputProps;
        };
        permissions: {
            construction: InputProps;
            farming: InputProps;
            fishing: InputProps;
            planning: InputProps;
        };
        settings: {
            internal_note: InputProps;
        };
    };
    property: Map<string, any>;
};

interface State {
    propertyRepository: EstateRepository;
}

class FeaturesForm extends Component<Props, State> {
    classes = {
        labelClassName: 'col-sm-5',
        wrapperClassName: 'col-sm-8',
    };

    static getDerivedStateFromProps({ property }) {
        return {
            propertyRepository: new EstateRepository(property),
        };
    }

    getFieldLabel(field) {
        return <FormattedMessage id={`property.features.${field}`} defaultMessage={`property.features.${field}`} />;
    }

    renderConditions() {
        const { conditions } = this.props.fields;
        const { propertyRepository } = this.state;
        if (!isConditionsSectionVisible(propertyRepository.getVisibilityContext())) {
            return null;
        }

        return (
            <>
                <PropertyFieldVisibility property={propertyRepository} path="attributes.conditions.bathroom">
                    <FormPanel
                        title={
                            <FormattedMessage
                                id="property.condition_selector.bathroom"
                                defaultMessage="Bathroom condition"
                            />
                        }
                        key="bathroom-condition"
                    >
                        <ErrorBoundary>
                            <PropertyConditionSelector icon="bathroom" field={conditions.bathroom} />
                        </ErrorBoundary>
                    </FormPanel>
                </PropertyFieldVisibility>
                <PropertyFieldVisibility property={propertyRepository} path="attributes.conditions.kitchen">
                    <FormPanel
                        title={
                            <FormattedMessage
                                id="property.condition_selector.kitchen"
                                defaultMessage="Kitchen condition"
                            />
                        }
                        key="kitchen-condition"
                    >
                        <ErrorBoundary>
                            <PropertyConditionSelector icon="kitchen" field={conditions.kitchen} />
                        </ErrorBoundary>
                    </FormPanel>
                </PropertyFieldVisibility>
            </>
        );
    }

    renderBuilding() {
        const { building } = this.props.fields;
        const { propertyRepository } = this.state;

        if (!isBuildingSectionVisible(propertyRepository.getVisibilityContext())) {
            return null;
        }

        return (
            <FormPanel
                title={<FormattedMessage id="pages.properties.building_panel.title" defaultMessage="Building" />}
                horizontal
            >
                <PropertyFieldVisibility
                    property={propertyRepository}
                    path="attributes.building.construction.architect"
                >
                    <Input
                        type="text"
                        label={
                            <WithPrivateTooltip>
                                <FormattedMessage
                                    id="pages.properties.building_panel.architect_label"
                                    defaultMessage="Architect"
                                />
                            </WithPrivateTooltip>
                        }
                        {...this.classes}
                        {...building.construction.architect}
                    />
                </PropertyFieldVisibility>

                <PropertyFieldVisibility property={propertyRepository} path="attributes.building.construction.year">
                    <YearInput
                        inputTag={Input}
                        label={
                            <WithPrivateTooltip>
                                <FormattedMessage
                                    id="pages.properties.building_panel.year_built_label"
                                    defaultMessage="Year Built"
                                />
                            </WithPrivateTooltip>
                        }
                        {...this.classes}
                        //@ts-ignore
                        wrapperClassName="col-sm-4 col-lg-3"
                        {...building.construction.year}
                        max={9999}
                    />
                </PropertyFieldVisibility>

                <PropertyFieldVisibility property={propertyRepository} path="attributes.building.units_of_building">
                    <Input
                        type="price"
                        min="0"
                        label={
                            <FormattedMessage
                                id="pages.properties.building_panel.units_of_building_label"
                                defaultMessage="Units in Building"
                            />
                        }
                        {...this.classes}
                        {...building.units_of_building}
                        wrapperClassName="col-sm-4 col-lg-3"
                    />
                </PropertyFieldVisibility>

                <PropertyFieldVisibility
                    property={propertyRepository}
                    path="attributes.building.construction.residential_lots"
                >
                    <Input
                        type="price"
                        min="0"
                        label={
                            <FormattedMessage
                                id="pages.properties.building_panel.residential_lots_label"
                                defaultMessage="Number of Residential Lots"
                            />
                        }
                        {...this.classes}
                        {...building.construction.residential_lots}
                        wrapperClassName="col-sm-4 col-lg-3"
                    />
                </PropertyFieldVisibility>

                <PropertyFieldVisibility
                    property={propertyRepository}
                    path="attributes.building.number_of_floor_building"
                >
                    <Input
                        min="0"
                        max={9999}
                        type="price"
                        label={
                            <FormattedMessage
                                id="pages.properties.building_panel.number_of_floor_building"
                                defaultMessage="No. of Floors in Building"
                            />
                        }
                        {...this.classes}
                        {...building.number_of_floor_building}
                        wrapperClassName="col-sm-4 col-lg-3"
                    />
                </PropertyFieldVisibility>
            </FormPanel>
        );
    }

    renderRenovation() {
        const { building } = this.props.fields;
        const { propertyRepository } = this.state;

        if (!isRenovationSectionVisible(propertyRepository.getVisibilityContext())) {
            return null;
        }

        return (
            <FormPanel
                title={
                    <WithPrivateTooltip>
                        <FormattedMessage id="pages.properties.renovation_panel.title" defaultMessage="Renovation" />
                    </WithPrivateTooltip>
                }
                horizontal
            >
                <PropertyFieldVisibility property={propertyRepository} path="attributes.building.renovation.year">
                    <YearInput
                        inputTag={Input}
                        label={
                            <FormattedMessage
                                id="pages.properties.renovation_panel.year_renovated_label"
                                defaultMessage="Year Renovated"
                            />
                        }
                        {...this.classes}
                        //@ts-ignore
                        wrapperClassName="col-sm-4 col-lg-3"
                        {...building.renovation.year}
                        max={9999}
                    />
                </PropertyFieldVisibility>

                <PropertyFieldVisibility
                    property={propertyRepository}
                    path="attributes.building.renovation.description"
                >
                    <Input
                        componentClass="textarea"
                        label={
                            <FormattedMessage
                                id="pages.properties.renovation_panel.description_label"
                                defaultMessage="Describe Renovation"
                            />
                        }
                        rows={5}
                        {...this.classes}
                        {...building.renovation.description}
                    />
                </PropertyFieldVisibility>
            </FormPanel>
        );
    }

    renderInternalNotes() {
        const { settings } = this.props.fields;

        return (
            <FormPanel
                title={
                    <WithPrivateTooltip>
                        <FormattedMessage id="properties.fields.settings.internal_note" defaultMessage="Notes" />
                    </WithPrivateTooltip>
                }
            >
                <Input type="autosize-textarea" {...settings.internal_note} />
            </FormPanel>
        );
    }

    renderCheckboxes() {
        return <FeaturesCheckboxesSectionsList fields={this.props.fields} property={this.state.propertyRepository} />;
    }

    renderFeatures() {
        return (
            <div>
                {this.renderConditions()}
                {this.renderCheckboxes()}
                {this.renderBuilding()}
                {this.renderRenovation()}
                {this.renderInternalNotes()}
            </div>
        );
    }

    render() {
        //@ts-ignore
        const { handleSubmit, status, editingDisabled } = this.props;

        return (
            //@ts-ignore
            <FormPane
                disabled={editingDisabled}
                disabledText={<FormattedMessage id="property.status.archived" defaultMessage="Archived" />}
                title={<FormattedMessage id="titles.property.features" defaultMessage="Select the features" />}
                onSubmit={handleSubmit}
                status={status}
            >
                {this.renderFeatures()}
            </FormPane>
        );
    }
}

const formFieldPaths = [
    ...rulesMatcher.getAllFieldsByPathPrefix('attributes.features'),
    ...rulesMatcher.getAllFieldsByPathPrefix('attributes.permissions'),
    ...rulesMatcher.getAllFieldsByPathPrefix('attributes.conditions'),
    ...rulesMatcher.getAllFieldsByPathPrefix('attributes.building'),
].map(field => field.replace(/^attributes\./, ''));

export default compose(
    withProps(props => ({
        //@ts-ignore
        estateId: props.params.unit || props.params.property,
    })),
    withPropertyDetails('features'),
    reduxForm({
        form: 'properties/features',
        fields: [...formFieldPaths, 'settings.internal_note'],
    }),
    withActivePropertyConfirmation,
)(FeaturesForm);
