// @ts-nocheck
import { call, put, select } from 'redux-saga/effects';
import has from 'lodash/has';
import { getSearchSettings } from '@/app.redux/selectors/SearchSelectors';
import { AggregatedPriceResponse } from '@/requests/types';
import { setPropertiesAggregations } from '@/app.redux/actions/AggregationActions';
import { setPagination } from '../../actions/PaginationActions';

export default function* withPagination<T = any>(
    paginationKey: string,
    attributes: Record<string, any>,
    callback: (settings: any) => Generator,
    paginationKeyNormalizer?: string,
) {
    // get the filters for the search
    const searchSettings: Record<string, any> = yield select(getSearchSettings(paginationKey, paginationKeyNormalizer));

    const settings = {
        // by default set page = 1, it can be overwritten after
        page: 1,
        ...searchSettings,
        ...attributes,
    };

    const entities: AggregatedPriceResponse<T> = yield call(callback, settings);

    yield put(setPagination(entities, paginationKey));

    if (has(entities, 'meta.aggregations')) {
        yield put(setPropertiesAggregations(entities.meta.aggregations));
    }
}
