import { createSelector } from 'reselect';
import { getUser } from './UsersSelectors';

export function getParameters(state, props) {
    if (props && props.params) {
        return props.params;
    }

    return {};
}

export const getCurrentProperty = createSelector(getParameters, parameters => {
    return parameters.unit || parameters.property || parameters.project;
});

export const getCurrentRootPropertyIdFromPath = createSelector(getParameters, parameters => {
    return parameters.property;
});

export const getCurrentProject = createSelector(getParameters, parameters => parameters.project);

export const getCurrentContact = createSelector(getParameters, parameters => parameters.contact);

export const getCurrentOffice = createSelector(
    [getParameters, getUser],
    (parameters, user) => parameters.office || user?.getIn(['office', 'data', 'id']),
);

export const getCurrentCompany = createSelector([getParameters, getUser], (parameters, user) => {
    return parameters.company ?? user?.getIn(['company', 'data', 'id']);
});

export const getCurrentMember = createSelector(getParameters, parameters => parseInt(parameters.member, 10));

export const getCurrentPortal = createSelector(getParameters, parameters => parameters.portal);
