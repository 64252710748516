import gql from 'graphql-tag';

export const REMOVE_LOCATION_TEMPLATE = gql`
    mutation removeLocationTemplate($officeId: ID!, $locationId: ID!) {
        removeLocationPreferenceTemplate(officeId: $officeId, locationId: $locationId) {
            id
            office {
                id
            }
        }
    }
`;
