import { Col, FormGroup } from 'react-bootstrap';
import React from 'react';
import useCompanyLanguages from '@/app.hooks/useCompanyLanguages';
import Input from '@/app.components/forms/Input/Input';
import { FormattedMessage } from 'react-intl-sweepbright';

export default function MultilingualInput({ label, name, type, value, onChange }) {
    const languages = useCompanyLanguages();

    return (
        <FormGroup>
            <Col>
                {languages.map(lang => {
                    return (
                        <Input
                            key={lang.value}
                            name={`${name}.${lang.value}`}
                            label={
                                <>
                                    {label} (
                                    <FormattedMessage
                                        id={`general.locales.${lang.value}`}
                                        defaultMessage={lang.label}
                                    />
                                    )
                                </>
                            }
                            type={type}
                            value={value?.[lang.value]}
                            onChange={onChange(`${name}.${lang.value}`)}
                        />
                    );
                })}
            </Col>
        </FormGroup>
    );
}
