import React from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import { InjectedIntl } from 'react-intl';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import { FormattedMessage, injectIntl } from 'react-intl-sweepbright';
import Icon from '@/app.components/icons/Icon';
import CompanyContext from '@/app.domains/company/CompanyContext';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import useCompanySetting from '@/app.hooks/useCompanySetting';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import useOffice from '@/app.hooks/useOffice';

type Props = {
    property: Map<string, any>;
    className?: string;
    as?: React.ComponentType<any>;
    intl: InjectedIntl;
};

const PropertyTitle: React.FunctionComponent<Props> = ({ property, className, as: WrapperComponent = 'div', intl }) => {
    const company = React.useContext<Map<string, any>>(CompanyContext);
    const companyDefaultLocale = useCompanySetting('default_locale');
    const repository = new EstateRepository(property, company);
    const title = getTitle();
    const props = isFragment() ? {} : { className: classNames('flex items-center space-x-2', className) };

    const office = useOffice();
    const officeId = office?.get('id');

    const showPropertySharedFilter = useFeatureFlag('property.shared.enabled');

    const isShared =
        showPropertySharedFilter && repository.getVisibility() === 'company' && officeId
            ? repository.getOfficeId() !== officeId
            : false;

    const content = {
        visibility: <FormattedMessage id="pages.properties.team_settings_panel.visibility" />,
        company: <FormattedMessage id="pages.properties.team_settings_panel.visibility.company" />,
    };

    return (
        <WrapperComponent {...props}>
            <span>{title}</span>
            {repository.isArchived() ? (
                <Tooltip label={<FormattedMessage id="property.status.archived" defaultMessage="Archived" />}>
                    <Icon icon="archived" />
                </Tooltip>
            ) : null}
            {isShared && (
                <Tooltip
                    label={
                        <>
                            {content.visibility}: {content.company}
                        </>
                    }
                >
                    <Icon icon="globe" size={14} style={{ fill: '#8e8e93' }} />
                </Tooltip>
            )}
        </WrapperComponent>
    );

    function isFragment() {
        return WrapperComponent === React.Fragment;
    }

    function getTitle(): React.ReactNode {
        if (repository.isProject()) {
            return repository.getTitle(intl.locale, companyDefaultLocale);
        } else {
            const priceFormatted = repository.getPrice({ intl });
            if (repository.isLet() && repository.hasPrice()) {
                const rentPeriod = repository.getRentPeriod();

                return (
                    <>
                        {priceFormatted}
                        /
                        <FormattedMessage
                            id={`property.rent_period.${rentPeriod}`}
                            defaultMessage={rentPeriod}
                            tagName={React.Fragment}
                        />
                    </>
                );
            } else {
                return priceFormatted;
            }
        }
    }
};

export default injectIntl(PropertyTitle);
