import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import useMatchContactFilters from '@/new.domains/match/hooks/useMatchContactFilters';
import { ResourceDomainType } from '@/shared/resource/types';
import { BuyerPreference } from '@/graphql/generated/types';
import useOffice from '@/app.hooks/useOffice';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import MatchList from '@/new.domains/match/MatchList';
import { formatPropertiesSearch } from '@/new.domains/properties/utils/formatPropertiesSearch';
import { PROPERTY_CONDITION_OPTIONS } from '@/app.data/Conditions';
import { formatPropertiesQuery } from '../../../../../server/domains/properties/utils/formatPropertiesQuery';

type Props = {
    contactId?: string;
    loadingPreference?: boolean;
    preference?: BuyerPreference;
    domainType: ResourceDomainType;
};

const ContactMatch: React.FC<Props> = props => {
    const { contactId, domainType, preference, loadingPreference } = props;

    const office = useOffice();
    const officeId = office.get('id');

    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState<string[]>([]);

    const showEstimate = useFeatureFlag('property.status.estimate');
    const atlasSearch = useFeatureFlag('properties-atlas-search');
    const showPropertySharedFilter = useFeatureFlag('property.shared.enabled');
    const showAreas = useFeatureFlag('contact.preferences.areas.enabled');

    const negotiation = preference?.negotiation?.toLowerCase();

    const [searchSettings, setSearchSettings] = useLocalStorage<any>(`${domainType}.filters`, {
        negotiation: negotiation,
    });

    const defaultSearchParams = {
        ...searchSettings,
        sort_order: 'desc',
        sort_field: 'updated_at',
        noInterestContactId: contactId,
        property_internal_type: ['standalone', 'unit'],
    };

    const formattedSearchParams = formatPropertiesQuery(
        formatPropertiesSearch({
            page,
            officeId,
            showEstimate,
            atlasSearch: atlasSearch,
            searchSettings: defaultSearchParams,
            showPropertySharedFilter: showPropertySharedFilter,
        }),
    );

    const filters = useMatchContactFilters(
        searchSettings,
        setSearchSettings,
        domainType,
        preference?.type || 'house',
        negotiation,
    );

    useEffect(() => {
        if (preference) {
            setSelected([]);

            const minPrice = `min_${negotiation}_price`;
            const maxPrice = `max_${negotiation}_price`;

            const conditions = [...PROPERTY_CONDITION_OPTIONS];

            const filterConditions = () => {
                if (preference?.condition) {
                    const cIndex = conditions.indexOf(preference?.condition);

                    if (cIndex !== -1) {
                        return conditions.splice(cIndex);
                    } else {
                        return [];
                    }
                } else {
                    return undefined;
                }
            };

            const postalCodes = preference?.locations?.nodes
                ?.filter(el => el.type === 'postal_codes')
                .map(
                    el =>
                        // @ts-ignore
                        el?.data,
                )
                .flat();

            const geoFilter = preference?.locations?.nodes
                ?.filter(el => el.type === 'geojson')
                .map(el => {
                    return {
                        geometry:
                            // @ts-ignore
                            el.data,
                    };
                })
                .flat();

            setSearchSettings(prevState => ({
                ...prevState,
                geo_filter: geoFilter,
                type: [preference?.type],
                postal_codes: postalCodes,
                condition: filterConditions(),
                amenities: preference.amenities,
                // @ts-ignore
                min_bedrooms: preference.minBedrooms,
                [minPrice]: preference.budget?.minPrice,
                [maxPrice]: preference.budget?.maxPrice,
                subtypes: preference.subtypes || undefined,
                negotiation: preference?.negotiation?.toLowerCase(),
                ...(showAreas
                    ? {
                          max_net_area: preference?.max_net_area,
                          min_net_area: preference?.min_net_area,
                          max_plot_area: preference?.max_plot_area,
                          min_plot_area: preference?.min_plot_area,
                          max_gross_area: preference?.max_gross_area,
                          min_gross_area: preference?.min_gross_area,
                          max_liveable_area: preference?.max_liveable_area,
                          min_liveable_area: preference?.min_liveable_area,
                      }
                    : {
                          max_net_area: undefined,
                          min_net_area: undefined,
                          max_plot_area: undefined,
                          min_plot_area: undefined,
                          max_gross_area: undefined,
                          min_gross_area: undefined,
                          max_liveable_area: undefined,
                          min_liveable_area: undefined,
                      }),
                status: prevState?.status?.length ? prevState.status : ['under_contract', 'available'],
            }));
        }
    }, [showAreas, preference, negotiation, setSearchSettings]);

    return (
        <MatchList
            page={page}
            setPage={setPage}
            filters={filters}
            selected={selected}
            contactId={contactId}
            domainType={domainType}
            preference={preference}
            setSelected={setSelected}
            searchSettings={searchSettings}
            setSearchSettings={setSearchSettings}
            loadingPreference={loadingPreference}
            defaultSearchParams={defaultSearchParams}
            formattedSearchParams={formattedSearchParams}
        />
    );
};

export default ContactMatch;
