import isObjectLike from 'lodash/isObjectLike';
import produce from 'immer';

export function convertFormField(formField = '') {
    // for new filtering and sorting
    if (typeof formField === 'string') {
        if (formField) {
            return {
                [formField]: true,
            };
        }

        return {};
    }

    return formField;
}

export function readImageAsDataUrl(fileObject): Promise<string | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileObject);
        reader.onload = evt => {
            const result = (evt.target as FileReader).result;
            const stringResult = result ? result.toString() : null;
            resolve(stringResult);
        };

        reader.onerror = () => {
            reject(new Error('Failed to convert the file into a data url string'));
        };
    });
}

// when dealing with inputs, React warns us
// if we set an input value to null, but sometimes,
// in our models we need to set the value to null
// to specify the field is empty and to ve able to validate the data
// agains the json schemas
// this utility wraps the onChangeStatus handler from redux-form or whaterver form library
// you use and it makes sure that we pass null to when the inpout is empty

export function emptyAsNull(onChange) {
    function customOnChange(evtOrValue: React.FormEvent<HTMLInputElement> | string | undefined): void {
        const value = getValue(evtOrValue);
        if (value === '' || value == null) {
            onChange(null);

            return;
        }
        onChange(value);
    }

    return customOnChange;

    function getValue(evtOrValue: React.FormEvent<HTMLInputElement> | string | undefined) {
        if (typeof evtOrValue === 'object' && evtOrValue.currentTarget) {
            return evtOrValue.currentTarget.value;
        }

        return evtOrValue;
    }
}

export const nullifyEmptyFields = produce(function nullifyEmptyFields(values) {
    if (isObjectLike(values)) {
        for (let key in values) {
            if (values[key] === '') {
                values[key] = undefined;
            } else if (isObjectLike(values[key])) {
                nullifyEmptyFields(values[key]);
            }
        }
    }
});
