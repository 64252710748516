import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import useTableColumns from '@/app.hooks/useTableColumns';
import { ChannelsSearchType } from '../types';
import { renderCustomName, renderName, renderStatus, renderVisibleTo } from '../utils';

export const useChannelsColumns = (_searchSettings: ChannelsSearchType | undefined) => {
    const columnsById = useMemo(() => {
        return {
            name: {
                key: 'name',
                title: <FormattedMessage id="forms.labels.name" defaultMessage="Name" />,
                dataIndex: 'name',
                render: (id, channel) => renderName(id, channel),
                className: 'w-64',
                cellClassName: 'w-64',
            },
            custom_name: {
                key: 'custom_name',
                title: <FormattedMessage id="forms.labels.custom_name" defaultMessage="Custom Name" />,
                dataIndex: 'custom_name',
                render: (id, channel) => renderCustomName(id, channel),
                className: 'w-64',
                cellClassName: 'w-64',
            },
            visible_to: {
                key: 'visible_to',
                title: <FormattedMessage id="general.visible_to" defaultMessage="Visible to" />,
                dataIndex: 'visible_to',
                render: (id, channel) => renderVisibleTo(id, channel),
                className: 'w-64',
                cellClassName: 'w-64',
            },
            status: {
                key: 'status',
                title: <FormattedMessage id="forms.labels.status" defaultMessage="Status" />,
                dataIndex: 'status',
                render: (id, channel) => renderStatus(id, channel),
                className: 'w-64',
                cellClassName: 'w-64',
            },
        };
    }, []);

    const initRecord: [string, boolean][] = React.useMemo(
        () => [
            ['custom_name', true],
            ['visible_to', true],
            ['status', true],
        ],
        [],
    );

    const { columns } = useTableColumns({
        memoryKey: 'channels.columns',
        initRecord,
        columnsExtended: columnsById,
        renderMenu: () => null,
        firstColumnId: 'name',
        columnsKeyPrefix: 'channels_table.headers',
    });

    return columns;
};
