import gql from 'graphql-tag';

export const GET_MATCHING_REQUIREMENTS = gql`
    query GetMatchingRequirements($input: MatchingRequirementsInput!) {
        matchingRequirements(input: $input) {
            data {
                contactId
                propertyId
                preferenceId
                isMatchable
                errors
            }
        }
    }
`;
