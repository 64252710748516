// @ts-nocheck
import React from 'react';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import Status from '@/app.components/elements/Status/Status';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import { FormattedMessage } from 'react-intl-sweepbright';
import { ICON_SIZE_SMALL } from '@/app.components/icons/Icon';

type Props = {
    disabled?: boolean;
    onClick: (evt: React.MouseEvent<HTMLElement>) => void;
    status?: string;
    testId?: string;
    hideLabel?: boolean;
};

const EmailActionButton: React.FunctionComponent<Props> = props => {
    const children = (
        <div style={{ position: 'relative' }} onClick={props.onClick}>
            <ButtonIcon
                variant="link"
                disabled={props.disabled}
                type="mail"
                iconSize={ICON_SIZE_SMALL}
                testId={props.testId}
            />
            {props.status && (
                <div className="absolute top-0 right-0">
                    <Status
                        status={props.status}
                        disabled={props.disabled}
                        style={{
                            top: 0,
                            right: 0,
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2,
                            borderRadius: '8px',
                        }}
                    />
                </div>
            )}
        </div>
    );

    if (props.hideLabel) {
        return children;
    }

    return (
        <Tooltip
            label={
                props.disabled ? (
                    <FormattedMessage id="properties.match.action_btn.no_email" defaultMessage="No e-mail address" />
                ) : (
                    <FormattedMessage id="interaction.tooltip.send_email" defaultMessage="Send e-mail" />
                )
            }
        >
            {children}
        </Tooltip>
    );
};

export default EmailActionButton;
