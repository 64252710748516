import gql from 'graphql-tag';
import { PreferenceCardFragment } from '@/graphql/fragments/contactPreference';

export const GET_CONTACT_PREFERENCES_QUERY = gql`
    query GetContactPreferences($contactId: ID!, $page: Int) {
        contact(id: $contactId) {
            id
            ... on Lead {
                allPreferences(page: $page) {
                    nodes {
                        ...PreferenceCardFragment
                    }
                    pageInfo {
                        currentPage
                        hasNextPage
                    }
                    totalCount
                }
            }
        }
    }
    ${PreferenceCardFragment}
`;
