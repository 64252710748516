/* eslint id-length: 0 */
export default {
    N: 'North',
    NE: 'North-East',
    E: 'East',
    SE: 'South-East',
    S: 'South',
    SW: 'South-West',
    W: 'West',
    NW: 'North-West',
};

export const orientableFieldNames = ['garden', 'terrace', 'balcony'];
