import React, { useMemo } from 'react';
import { Button, Checkbox } from '@sweepbright/uikit';
import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import TimeInput from '@/app.components/forms/TimeInput';
import { getWeekDays } from '@/app.data/Weekdays';

type Values = {
    days: string[];
    start: number;
    end: number;
};

type FormValues = {
    days: string[];
    start_hour: string;
    start_minutes: string;
    end_hour: string;
    end_minutes: string;
};

function validate(values: FormValues) {
    const errors: Record<string, any> = {};
    if (!values.days || values.days.length === 0) {
        errors.days = (
            <FormattedMessage
                id="modals.add_availability.select_at_least_one_day"
                defaultMessage="Select at least one day"
            />
        );
    }
    if (values.start_hour === '' && values.start_minutes === '') {
        errors.start_hour = (
            <FormattedMessage
                id="modals.add_availability.start_time_required"
                defaultMessage="Start time is required"
            />
        );
    }

    if (values.end_hour === '' && values.end_minutes === '') {
        errors.end_hour = (
            <FormattedMessage id="modals.add_availability.end_time_required" defaultMessage="End time is required" />
        );
    }

    if (!errors.end_hour) {
        const startTime = parseInt(values.start_hour) * 60 + parseInt(values.start_minutes);
        const endTime = parseInt(values.end_hour) * 60 + parseInt(values.end_minutes);
        if (endTime <= startTime) {
            errors.end_hour = (
                <FormattedMessage
                    id="modals.add_availability.end_time_after_start_time"
                    defaultMessage="End time should be after start time"
                />
            );
        }
    }

    return errors;
}

export default function AddAvailabilityModal({
    show,
    editing,
    onClose,
    onSubmit,
    initialValues = {},
}: {
    show: boolean;
    editing?: boolean;
    onClose: () => void;
    onSubmit: (values: Values) => Promise<void>;
    initialValues?: Partial<Values>;
}) {
    const { values, handleChange, errors, handleSubmit, isSubmitting, submitCount, resetForm } = useFormik<FormValues>({
        initialValues: {
            start_hour: getHourString(initialValues?.start),
            start_minutes: getMinutesString(initialValues?.start),
            end_hour: getHourString(initialValues?.end),
            end_minutes: getMinutesString(initialValues?.end),
            days: initialValues?.days ?? [],
        },
        validate,
        enableReinitialize: true,
        onSubmit: values => {
            return onSubmit({
                days: values.days,
                start: parseInt(values.start_hour) * 60 + parseInt(values.start_minutes),
                end: parseInt(values.end_hour) * 60 + parseInt(values.end_minutes),
            });
        },
    });

    const submitted = submitCount > 0;

    const localizedWeekDays = useMemo(() => getWeekDays(), []);

    return (
        <Modal show={show} onHide={onClose} onExited={resetForm}>
            <form onSubmit={handleSubmit}>
                <Modal.Header>
                    <Modal.Title>
                        {editing ? (
                            <FormattedMessage
                                id="modals.add_availability.edit_availability"
                                defaultMessage="Edit Availability"
                            />
                        ) : (
                            <FormattedMessage
                                id="modals.add_availability.add_availability"
                                defaultMessage="Add Availability"
                            />
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup className={classNames({ 'has-error': submitted && errors.days })}>
                        <ControlLabel>
                            <FormattedMessage id="modals.add_availability.select_days" defaultMessage="Select Days" />
                        </ControlLabel>
                        <div className="grid grid-cols-3">
                            {localizedWeekDays.map(([weekDayKey, weekDayTranslation]) => {
                                return (
                                    <div key={weekDayKey} className="-my-2 ">
                                        <Checkbox
                                            value={weekDayKey}
                                            name="days"
                                            onChange={handleChange}
                                            checked={values.days.includes(weekDayKey)}
                                            error={submitted && errors.days}
                                        >
                                            <span className={classNames({ 'text-danger': submitted && errors.days })}>
                                                {weekDayTranslation}
                                            </span>
                                        </Checkbox>
                                    </div>
                                );
                            })}
                        </div>
                    </FormGroup>
                    <div className="grid grid-cols-2 col-gap-5">
                        <FormGroup className={classNames({ 'has-error': submitted && errors.start_hour })}>
                            <ControlLabel>
                                <FormattedMessage id="modals.add_availability.start_time" defaultMessage="Start Time" />
                            </ControlLabel>
                            <TimeInput
                                fields={{
                                    hour: {
                                        value: values.start_hour,
                                        onChange: handleChange('start_hour'),
                                    },
                                    minutes: {
                                        value: values.start_minutes,
                                        onChange: handleChange('start_minutes'),
                                    },
                                }}
                                hasError={!!(submitted && errors.start_hour)}
                            />
                            {submitted && errors.start_hour && <HelpBlock>{errors.start_hour}</HelpBlock>}
                        </FormGroup>
                        <FormGroup className={classNames({ 'has-error': submitted && errors.end_hour })}>
                            <ControlLabel>
                                <FormattedMessage id="modals.add_availability.end_time" defaultMessage="End Time" />
                            </ControlLabel>
                            <TimeInput
                                fields={{
                                    hour: {
                                        value: values.end_hour,
                                        onChange: handleChange('end_hour'),
                                    },
                                    minutes: {
                                        value: values.end_minutes,
                                        onChange: handleChange('end_minutes'),
                                    },
                                }}
                                hasError={!!(submitted && errors.end_hour)}
                            />
                            {submitted && errors.end_hour && <HelpBlock>{errors.end_hour}</HelpBlock>}
                        </FormGroup>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onClose} disabled={isSubmitting}>
                        <FormattedMessage id="modals.add_availability.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                        {editing ? (
                            <FormattedMessage id="modals.add_availability.update" defaultMessage="Update" />
                        ) : (
                            <FormattedMessage id="modals.add_availability.add" defaultMessage="Add" />
                        )}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

function getHourString(value?: number) {
    return value != null ? String(Math.floor(value / 60)).padStart(2, '0') : '';
}

function getMinutesString(value?: number) {
    return value != null ? String(Math.floor(value % 60)).padStart(2, '0') : '';
}
