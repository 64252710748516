/* eslint-disable no-use-before-define */
import React, { FC } from 'react';
import { InputProps } from 'redux-form';
import Cleave from 'cleave.js/react';
import Select from '@/app.components/selects/AdvancedSelect/Select';

const predefinedTimes = [
    { value: '00:00', label: '00:00' },
    { value: '00:15', label: '00:15' },
    { value: '00:30', label: '00:30' },
    { value: '00:45', label: '00:45' },
    { value: '01:00', label: '01:00' },
    { value: '01:15', label: '01:15' },
    { value: '01:30', label: '01:30' },
    { value: '01:45', label: '01:45' },
    { value: '02:00', label: '02:00' },
    { value: '02:15', label: '02:15' },
    { value: '02:30', label: '02:30' },
    { value: '02:45', label: '02:45' },
    { value: '03:00', label: '03:00' },
    { value: '03:15', label: '03:15' },
    { value: '03:30', label: '03:30' },
    { value: '03:45', label: '03:45' },
    { value: '04:00', label: '04:00' },
    { value: '04:15', label: '04:15' },
    { value: '04:30', label: '04:30' },
    { value: '04:45', label: '04:45' },
    { value: '05:00', label: '05:00' },
    { value: '05:15', label: '05:15' },
    { value: '05:30', label: '05:30' },
    { value: '05:45', label: '05:45' },
    { value: '06:00', label: '06:00' },
    { value: '06:15', label: '06:15' },
    { value: '06:30', label: '06:30' },
    { value: '06:45', label: '06:45' },
    { value: '07:00', label: '07:00' },
    { value: '07:15', label: '07:15' },
    { value: '07:30', label: '07:30' },
    { value: '07:45', label: '07:45' },
    { value: '08:00', label: '08:00' },
    { value: '08:15', label: '08:15' },
    { value: '08:30', label: '08:30' },
    { value: '08:45', label: '08:45' },
    { value: '09:00', label: '09:00' },
    { value: '09:15', label: '09:15' },
    { value: '09:30', label: '09:30' },
    { value: '09:45', label: '09:45' },
    { value: '10:00', label: '10:00' },
    { value: '10:15', label: '10:15' },
    { value: '10:30', label: '10:30' },
    { value: '10:45', label: '10:45' },
    { value: '11:00', label: '11:00' },
    { value: '11:15', label: '11:15' },
    { value: '11:30', label: '11:30' },
    { value: '11:45', label: '11:45' },
    { value: '12:00', label: '12:00' },
    { value: '12:15', label: '12:15' },
    { value: '12:30', label: '12:30' },
    { value: '12:45', label: '12:45' },
    { value: '13:00', label: '13:00' },
    { value: '13:15', label: '13:15' },
    { value: '13:30', label: '13:30' },
    { value: '13:45', label: '13:45' },
    { value: '14:00', label: '14:00' },
    { value: '14:15', label: '14:15' },
    { value: '14:30', label: '14:30' },
    { value: '14:45', label: '14:45' },
    { value: '15:00', label: '15:00' },
    { value: '15:15', label: '15:15' },
    { value: '15:30', label: '15:30' },
    { value: '15:45', label: '15:45' },
    { value: '16:00', label: '16:00' },
    { value: '16:15', label: '16:15' },
    { value: '16:30', label: '16:30' },
    { value: '16:45', label: '16:45' },
    { value: '17:00', label: '17:00' },
    { value: '17:15', label: '17:15' },
    { value: '17:30', label: '17:30' },
    { value: '17:45', label: '17:45' },
    { value: '18:00', label: '18:00' },
    { value: '18:15', label: '18:15' },
    { value: '18:30', label: '18:30' },
    { value: '18:45', label: '18:45' },
    { value: '19:00', label: '19:00' },
    { value: '19:15', label: '19:15' },
    { value: '19:30', label: '19:30' },
    { value: '19:45', label: '19:45' },
    { value: '20:00', label: '20:00' },
    { value: '20:15', label: '20:15' },
    { value: '20:30', label: '20:30' },
    { value: '20:45', label: '20:45' },
    { value: '21:00', label: '21:00' },
    { value: '21:15', label: '21:15' },
    { value: '21:30', label: '21:30' },
    { value: '21:45', label: '21:45' },
    { value: '22:00', label: '22:00' },
    { value: '22:15', label: '22:15' },
    { value: '22:30', label: '22:30' },
    { value: '22:45', label: '22:45' },
    { value: '23:00', label: '23:00' },
    { value: '23:15', label: '23:15' },
    { value: '23:30', label: '23:30' },
    { value: '23:45', label: '23:45' },
];

type FieldProps<T> = InputProps<T>;
type EventHandlers = 'onChange' | 'onUpdate' | 'onBlur' | 'onDragStart' | 'onDrop' | 'onFocus';
type FieldPropsForFormik<T> = {
    [P in keyof FieldProps<T>]: P extends EventHandlers ? FieldProps<T>[P] | void : FieldProps<T>[P];
};

interface TimeInputBaseProps {
    singleMode?: boolean;
    clearable?: boolean;
    hasError?: boolean;
}

interface CombinedFields {
    hour: Partial<FieldPropsForFormik<string>>;
    minutes: Partial<FieldPropsForFormik<string>>;
}
type SingleField = Partial<FieldPropsForFormik<string>>;

type TimeInputProps =
    | (TimeInputBaseProps & {
          singleMode: true;
          field: SingleField;
          disabled?: boolean;
      })
    | (TimeInputBaseProps & {
          singleMode?: false;
          fields: CombinedFields;
          disabled?: boolean;
      });

function getValues(props: TimeInputProps): { hour: string; minutes: string } {
    if (props.singleMode) {
        const parts = (props.field.value ?? '').split(':');
        const [hour, minutes] = parts;

        return { hour, minutes };
    } else {
        const hour = (props.fields.hour.value ?? '').toString();
        const minutes = (props.fields.minutes.value ?? '').toString();

        return { hour, minutes };
    }
}

const TimeInput: FC<TimeInputProps> = props => {
    const { hasError, clearable, disabled } = props;
    const { hour, minutes } = getValues(props);

    const value = hour || minutes ? `${hour.padStart(2, '0')}:${minutes.padStart(2, '0')}` : '';

    function handleChange(selectedOption) {
        const time = selectedOption?.value ?? '';
        if (props.singleMode) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            props.field.onChange && props.field.onChange({ target: { value: time } });
        } else {
            const [hour = '', minutes = ''] = time.split(':');
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            props.fields.hour.onChange && props.fields.hour.onChange(hour);
            requestAnimationFrame(() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                props.fields.minutes.onChange && props.fields.minutes.onChange(minutes);
            });
        }
    }

    const handleBlur = evt => {
        const inputValue = evt.target.value;
        const time = formatDate(inputValue);
        if (time) {
            handleChange({ value: time });
        }
    };

    const options = predefinedTimes;

    return (
        //@ts-ignore
        <Select
            options={options}
            disabled={disabled}
            selectedItem={value ? { value, label: value } : undefined}
            onChange={handleChange}
            onInputBlur={handleBlur}
            placeholder="HH:mm"
            //@ts-ignore
            inputRenderer={MaskedTimeInput}
            clearable={clearable}
            showMenuToggle={false}
            noResultsText={({ inputValue, selectItem }) => {
                const time = formatDate(inputValue);

                return <div onClick={() => selectItem({ value: time, label: time })}>{time}</div>;
            }}
            hasError={hasError}
        />
    );
};

export default TimeInput;

function formatDate(dateString) {
    const [hour, min] = dateString.split(':').map(val => parseInt(val, 10));
    if (hour >= 0 && hour < 24 && min >= 0 && min < 60) {
        return String(hour).padStart(2, '0') + ':' + String(min).padStart(2, '0');
    }

    return '';
}

const MaskedTimeInput = React.forwardRef(function MaskedTimeInput(props, ref) {
    return (
        <Cleave
            htmlRef={htmlRef => {
                //@ts-ignore
                ref.current = htmlRef;
            }}
            options={{
                time: true,
                timePattern: ['h', 'm'],
            }}
            {...props}
        />
    );
});
