import React from 'react';
import { FormattedMessage } from 'react-intl';
import Link from '@/app.components/elements/Link';
import { CHANNELS_PORTAL } from '@/app.routing';
import TeamsName from '@/shared/teams/TeamsName';

export const renderName = (_id: string, channel: any) => {
    return (
        <span>
            <Link to={CHANNELS_PORTAL(channel.id)} className="flex items-center space-x-2  no-underline text-gray-dark">
                {channel.channel.name}
            </Link>
        </span>
    );
};

export const renderCustomName = (_id: string, channel: any) => {
    return <span>{channel.name}</span>;
};

export const renderVisibleTo = (_id: string, channel: any) => {
    if (channel.office_id) {
        return <TeamsName id={channel.office_id} />;
    }

    return <FormattedMessage id="general.everyone" defaultMessage="Everyone" />;
};

export const renderStatus = (_id: string, channel: any) => {
    return <FormattedMessage id={`channel.status.${channel.status}`} defaultMessage={channel.status} />;
};
