import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Input } from '@/app.components';
import { EstateRepository } from '@/app.utils/services';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';

type Props = {
    property: EstateRepository;
    disabled?: boolean;
    isProject?: boolean;
    onChange?: (value: boolean) => void;
};

const PropertyFormVisibility: React.FC<Props> = props => {
    const { property, disabled, isProject, onChange } = props;

    const intl = useIntl();

    const [value, setValue] = useState(property?.getVisibility());

    const showPropertySharedFilter = useFeatureFlag('property.shared.enabled');

    if (!showPropertySharedFilter || isProject) {
        return null;
    }

    return (
        <div className="form-group flex items-center properties-form-visibility">
            <label className="form-label col-sm-4 properties-form-visibility__label">
                {intl.formatMessage({ id: 'pages.properties.team_settings_panel.visibility.label' })}
            </label>
            <div className="col-sm-8">
                <Input
                    type="checkbox"
                    name="visibility"
                    disabled={disabled}
                    checked={value === 'company'}
                    label={intl.formatMessage({ id: 'pages.properties.team_settings_panel.visibility.checkbox' })}
                    onChange={event => {
                        const newValue = event.target.checked;

                        setValue(newValue ? 'company' : 'public');

                        onChange?.(newValue);
                    }}
                />
            </div>
        </div>
    );
};

export default PropertyFormVisibility;
