import gql from 'graphql-tag';
import { OfferCardFragment } from '@/graphql/fragments/offerCard';

export const CREATE_OFFER = gql`
    mutation CreateOffer($offerInput: CreateOfferInput!) {
        createOffer(offerInput: $offerInput) {
            ...OfferCardFragment
        }
    }

    ${OfferCardFragment}
`;

export const UPDATE_OFFER = gql`
    mutation UpdateOffer($offerInput: UpdateOfferInput!) {
        updateOffer(offerInput: $offerInput) {
            ...OfferCardFragment
        }
    }

    ${OfferCardFragment}
`;

export const ARCHIVE_OFFER = gql`
    mutation ArchiveOffer($offerId: ID!) {
        archiveOffer(offerId: $offerId) {
            ...OfferCardFragment
        }
    }

    ${OfferCardFragment}
`;

export const RESTORE_OFFER = gql`
    mutation RestoreOffer($offerId: ID!) {
        restoreOffer(offerId: $offerId) {
            ...OfferCardFragment
        }
    }

    ${OfferCardFragment}
`;

export const ACCEPT_OFFER = gql`
    mutation AcceptOffer($offerId: ID!) {
        acceptOffer(offerId: $offerId) {
            ...OfferCardFragment
        }
    }

    ${OfferCardFragment}
`;

export const REFUSE_OFFER = gql`
    mutation RefuseOffer($offerId: ID!) {
        refuseOffer(offerId: $offerId) {
            ...OfferCardFragment
        }
    }

    ${OfferCardFragment}
`;

export const CANCEL_OFFER = gql`
    mutation CancelOffer($offerId: ID!) {
        cancelOffer(offerId: $offerId) {
            ...OfferCardFragment
        }
    }

    ${OfferCardFragment}
`;
