import { call, fork, join, select, put, take } from 'redux-saga/effects';
import { RequestError } from '@sweepbright/margaret-fetcher';
import { push } from 'react-router-redux';
import logger from '@/app.utils/services/logger';
import { logoutAttempt } from '@/app.redux/actions/AuthActions';
import {
    HTTP_STATUS_BAD_REQUEST,
    HTTP_STATUS_FORBIDDEN,
    HTTP_STATUS_SERVICE_UNAVAILABLE,
    HTTP_STATUS_UNAUTHORIZED,
} from '@/app.utils/services/Helpers/constants/statusCodes';
import { SUBSCRIPTION_PLAN } from '../../../app.routing/routes';
import { onRefreshToken } from '../AuthenticationSaga';
import { getUser } from '../../selectors/UsersSelectors';
import { setMessage } from '../../actions';
import { isCompanyAdmin } from '../../../app.data';
import formatApiErrors from '../Helpers/formatApiErrors';

function* logoutUser(message, type = 'danger') {
    if (message) {
        yield put(setMessage(message, type));
    }

    yield put(logoutAttempt());
}

export default function* apiCall(request, ...args) {
    try {
        return yield call(request, ...args);
    } catch (error) {
        const hasIgnoreErrors = args?.find(arg => typeof arg === 'object' && arg?.ignore_errors);

        if (error instanceof RequestError) {
            logger.error('api request failed');
            // If the error is not because of an API request nothing can be done.
            // on the server just bubble the error up
            if (!error.response || __SERVER__) {
                logger.error(error);

                throw error;
            }

            switch (error.response.status) {
                case HTTP_STATUS_SERVICE_UNAVAILABLE: {
                    // server is in maintenance mode

                    logger.warn('Got a 503 request. Server in maintenance mode?');

                    yield call(logoutUser, 'Server in maintenance mode', 'danger');

                    return;
                }
                case HTTP_STATUS_BAD_REQUEST:
                    const errors = formatApiErrors(error, 'reason');
                    // Access token is missing
                    if (errors.reason.includes('access token')) {
                        logger.warn('Got a 400 code from the server, login out the user');

                        yield call(logoutUser);

                        return;
                    }

                    throw error;
                // Refresh access token on a 401.
                case HTTP_STATUS_UNAUTHORIZED:
                    logger.warn('Got a 401 code from the server. Refreshing user token');

                    const task = yield fork(onRefreshToken);

                    const token = yield join(task);

                    if (token) {
                        let retryRequest = request.withBearerToken(token.access_token);

                        return yield call(retryRequest, ...args);
                    }

                    yield call(logoutUser, 'Your session has timed out. Please log in again.', 'warning');

                    break;
                case HTTP_STATUS_FORBIDDEN:
                    logger.warn('Got a 403 code from the server. Tried to access an unauthorized resource?');

                    const user = yield select(getUser);

                    if (user) {
                        if (isCompanyAdmin(user)) {
                            if (hasIgnoreErrors) {
                                return;
                            }

                            yield put(push(SUBSCRIPTION_PLAN));
                        } else {
                            // TODO IMPORTANT: This is a temporary fix for the 403 error.
                            // yield call(logoutUser, 'Tried to access an unauthorized resource', 'danger');
                            throw error;
                        }

                        yield take('NO_ACTION');
                    }

                    logger.error('Got a 403 code from the server, and no user present');

                default:
                    throw error;
            }
        } else {
            throw error;
        }
    }
}
