import { put, select } from 'redux-saga/effects';
import { configure } from '@/app.config/accounting';
import { getOfficeById } from '@/app.redux/selectors/OfficesSelector';
import { officeWasSet, resetPagination, setUser, setCompany } from '../../actions';
import UserRequests from '../../../requests/UserRequests';
import apiCall from '../Effects/apiCall';

export default function* onSetUserOffice({ officeId, resolve, reject }) {
    try {
        // this is unnecessary but I can't delete it
        // because not sure about the side effects
        const user = yield apiCall(new UserRequests().me);

        const office = yield select(getOfficeById(officeId));

        // configure the office accounting settings,
        // this mutates a global object
        configure(office.getIn(['settings', 'data']));

        yield [
            // put(setOffice(office.toJS())),
            // should not be necessary
            put(setCompany(user.company.data)),
            put(setUser(user)),
            put(resetPagination('properties')),
            put(resetPagination('contacts')),
            // delete this on the new navigation
            put(resetPagination('visits')),
            // delete this on the new navigation
            put(resetPagination('publish')),
            put(resetPagination('interests')),
        ];

        // eslint-disable-next-line no-unused-expressions
        resolve?.();

        yield put(officeWasSet(officeId));
    } catch (err) {
        // eslint-disable-next-line no-unused-expressions
        reject?.(err);
    }
}
