import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import { useField } from 'formik';
import { getPopupSettings } from '@/app.redux/sagas/Helpers/popups';
import { FormattedMessage } from 'react-intl-sweepbright';
import helpLinks from '@/app.data/helpLinks';

export function CalendarIntegrationConnect() {
    const [, , connectedFieldHelpers] = useField('connected');
    const [, , accountFieldHelpers] = useField('account');
    const [, , hasCalendarIntegrationFieldHelpers] = useField('hasCalendarIntegration');

    const handleConnectToCronofy = async () => {
        const popup = window.open('/api/channels/cronofy', 'Cronofy', getPopupSettings('cronofy'));
        if (popup) {
            try {
                const newAccount = await waitForAccessToken();
                const profile = newAccount.profile._json.profiles[0];
                accountFieldHelpers.setValue({
                    accessToken: newAccount.accessToken,
                    refreshToken: newAccount.refreshToken,
                    profile: {
                        name: profile.profile_name,
                        provider: profile.provider_name,
                    },
                });

                return true;
            } catch (err) {
                // show error here
                return false;
            } finally {
                popup.close();
            }
        } else {
            // no popup opened, it was not connected
            return false;
        }
    };

    return (
        <>
            <Button
                variant="primary"
                onClick={async () => {
                    const connected = await handleConnectToCronofy();
                    connectedFieldHelpers.setValue(connected);
                    connectedFieldHelpers.setTouched(true);
                    if (connected) {
                        hasCalendarIntegrationFieldHelpers.setValue(false);
                    }
                }}
            >
                <FormattedMessage id="integrations.calendar.connect_calendar" defaultMessage="Connect Calendar" />
            </Button>

            <p className="help-block">
                <FormattedMessage
                    // TODO: check this line
                    id="forms.profile.scheduling_visits_info.help"
                    defaultMessage="Schedule Visits with your Leads and Vendors directly from SweepBright. {link}."
                    values={{
                        link: (
                            <a href={helpLinks.calendarIntegration} target="__blank" rel="noreferrer noopener nofollow">
                                <FormattedMessage
                                    id="forms.help.help_link"
                                    defaultMessage="Learn more in Help & Learning"
                                />
                            </a>
                        ),
                    }}
                />
            </p>
        </>
    );
}

function waitForAccessToken() {
    return new Promise((resolve, reject) => {
        window.addEventListener(
            'message',
            // eslint-disable-next-line prefer-arrow-callback
            function onMessage(evt) {
                if (evt.data.type === 'sign') {
                    window.removeEventListener('message', onMessage);
                    resolve(evt.data.account);
                } else if (evt.data.type === 'sign_error') {
                    reject(evt.data.error);
                }
            },
        );
    });
}
