import React, { useState } from 'react';
import { Select } from 'antd';
import { useIntl } from 'react-intl';
import { useInterval } from 'react-use';
import useOffice from '@/app.hooks/useOffice';
import useGeoTemplates from '@/app.hooks/useGeoTemplates';
import { PropertySearchType } from '@/new.domains/properties/types';
import isScrollEnd from '@/utils/isScrollEnd';

type Props = {
    marginBottom?: boolean;
    type: 'geometry' | 'postalCode';
    setTempSearchSettings: Function;
    tempSearchSettings?: PropertySearchType;
};

const FilterGeoSelect: React.FC<Props> = props => {
    const { type, marginBottom, tempSearchSettings, setTempSearchSettings } = props;

    const intl = useIntl();
    const office = useOffice();
    const officeId = office.get('id');

    const { data, loading, fetchMore, hasNextPage } = useGeoTemplates(officeId, type);

    const [tempQuery, setTempQuery] = useState('');

    useInterval(
        () => {
            if (tempQuery && !loading) {
                fetchMore();
            }
        },
        tempQuery && hasNextPage ? 100 : null,
    );

    const content = {
        noResults: intl.formatMessage({ id: 'general.state.no_results' }),
        loading: intl.formatMessage({ id: 'general.state.loading' }),
        placeholder: intl.formatMessage({ id: 'common.actions.select' }),
    };

    const filteredData = data.filter(el => {
        return el.type === type;
    });

    const options = filteredData?.map(el => {
        return {
            key: el?._id,
            value: el?._id,
            label: el?.title,
        };
    });

    const isDisabled = filteredData ? filteredData?.length === 0 && loading : false;

    const tempSelected = type === 'geometry' ? tempSearchSettings?.geo_filter : tempSearchSettings?.postal_codes;

    const selected = tempSelected?.map(el => el._id);

    return (
        <Select
            allowClear
            showSearch
            mode="multiple"
            loading={loading}
            options={options}
            disabled={isDisabled}
            maxTagCount="responsive"
            optionFilterProp="label"
            onSearch={newValue => {
                setTempQuery(newValue);
            }}
            value={isDisabled ? [] : selected}
            popupClassName="filter-geo-select__popup"
            placeholder={loading ? content.loading : content.placeholder}
            className={`filter-geo-select${marginBottom ? ' bottom' : ''}`}
            onChange={(newValue: string[]) => {
                setTempSearchSettings(prevState => {
                    const formattedValue = newValue.map(el => {
                        const activeEl = filteredData.find(item => item._id === el);

                        return {
                            _id: activeEl?._id,
                            geometry: activeEl?.geometry && activeEl?.geometry[0],
                            postalCodes: activeEl?.postalCodes,
                        };
                    });

                    const geometry = type === 'geometry' ? formattedValue : prevState.geo_filter;
                    const postalCodes = type === 'postalCode' ? formattedValue : prevState.postal_codes;

                    return {
                        ...prevState,
                        ...(type === 'geometry' && { geo_filter: geometry }),
                        ...(type === 'postalCode' && { postal_codes: postalCodes }),
                    };
                });
            }}
            notFoundContent={
                <span className="filter-geo-select-notfound">{loading ? content.loading : content.noResults}</span>
            }
            onPopupScroll={event => {
                if (isScrollEnd(event) && !loading) {
                    fetchMore();
                }
            }}
        />
    );
};

export default FilterGeoSelect;
