import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Immutable from 'immutable';
import { Alert } from 'react-bootstrap';
import { LOGIN } from '../../../app.routing/routes';

export default class Authorize extends Component {
    static propTypes = {
        authorize: PropTypes.func.isRequired,
        location: PropTypes.object.isRequired,
        messages: PropTypes.instanceOf(Immutable.Set).isRequired,
    };

    static contextTypes = {
        router: PropTypes.object,
    };

    componentDidMount() {
        const query = this.props.location.query;

        this.props.authorize(
            {
                access_token: query.access_token,
            },
            query.expires_in,
        );
    }

    render() {
        const hasErrors = this.props.messages.count() > 0;
        const style = hasErrors ? 'danger' : 'success';
        const message = hasErrors
            ? this.props.messages.first().get('message')
            : 'You will be logged in in a few seconds, hang in there.';

        if (hasErrors && this.context.router) {
            this.context.router.push(LOGIN);
        }

        return (
            <div>
                <Alert bsStyle={style}>{message}</Alert>
            </div>
        );
    }
}
