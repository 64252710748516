import gql from 'graphql-tag';

export const GET_LABELS = gql`
    query getLabels($page: Int, $limit: Int, $query: String) {
        labels(page: $page, limit: $limit, query: $query) {
            meta {
                pagination {
                    current_page
                    total_pages
                    total
                    per_page
                }
            }
            data {
                id
                name
                color
                description
                created_at
                updated_at
            }
        }
    }
`;

export const GET_LABEL = gql`
    query getLabel($id: ID!) {
        label(id: $id) {
            id
            name
            color
            description
            created_at
            updated_at
        }
    }
`;
