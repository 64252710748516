import gql from 'graphql-tag';
import { PropertyCardFragment } from '@/graphql/fragments/propertyCard';

export const GET_PROPERTY_QUERY = gql`
    query getProperty($id: ID!) {
        estate(id: $id) {
            ...PropertyCardFragment
        }
    }
    ${PropertyCardFragment}
`;
