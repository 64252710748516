import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import EstateTypeFilter from '@/app.domains/properties/filters/EstateTypeFilter';
import FilterNegotiators from '@/shared/filter/FilterNegotiators';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import FilterGeo from '@/shared/filter/FilterGeo';
import FilterArea from '@/shared/filter/FilterArea';
import ResourceFilter from '@/shared/resource/ResourceFilter';
import FilterLegalEntity from '@/shared/filter/FilterLegalEntity';
import { propertyTypes as allPropertyTypes } from '@/app.data/Properties';
import { PROPERTY_CONDITION_OPTIONS } from '@/app.data/Conditions';
import PropertyFilterAmenities from '../PropertyFilter/PropertyFilterAmenities';
import PropertyFilterAreaOld from '../PropertyFilter/PropertyFilterArea/PropertyFilterAreaOld';

import { PropertySearchType } from '../types';
import { getPropertiesAmenitiesGroups, getPropertiesFeatures, getPropertiesSubtypesGroups } from '../utils';

export const usePropertiesFilters = (
    searchSettings: PropertySearchType | undefined,
    setSearchSettings: Function,
    statusesPerNegotiation: any,
) => {
    const intl = useIntl();

    const showAreas = useFeatureFlag('contact.preferences.areas.enabled');
    const showGeoTemplateFilter = useFeatureFlag('geo-templates-enabled');
    const showNewFields = useFeatureFlag('property.feature.fields.june2024');
    const showPropertyFeatures = useFeatureFlag('property.features.filter.enabled');
    const showInternalTypeFilter = useFeatureFlag('property.new.internalType.enabled');
    const showPropertySharedFilter = useFeatureFlag('property.shared.enabled');
    const showLegalEntityFilter = useFeatureFlag('property.legalEntity.filter.enabled');

    const filters = useMemo(
        () => (
            <>
                <FilterNegotiators
                    domainType="properties"
                    searchSettings={searchSettings}
                    setSearchSettings={setSearchSettings}
                />

                {showLegalEntityFilter && (
                    <FilterLegalEntity
                        searchSettings={searchSettings}
                        setSearchSettings={setSearchSettings}
                        domainType="properties"
                        translationDomainType="properties"
                    />
                )}

                {showPropertySharedFilter && (
                    <ResourceFilter
                        id="visibility"
                        domainType="properties"
                        params={searchSettings}
                        setParams={setSearchSettings}
                        filters={[
                            {
                                type: 'checkbox',
                                paramName: 'visibility',
                                simpleOptions: ['team', 'company'],
                                defaultValue: ['team', 'company'],
                            },
                        ]}
                    />
                )}

                <ResourceFilter
                    id="status"
                    domainType="properties"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    translationFirstPart={`property.status.${searchSettings?.negotiation || 'sale'}`}
                    filters={[
                        {
                            type: 'checkbox',
                            paramName: 'status',
                            defaultValue: statusesPerNegotiation.sale,
                            options: statusesPerNegotiation[searchSettings?.negotiation || 'sale'].map(el => {
                                return {
                                    value: el,
                                    label: intl.formatMessage({
                                        id: `property.status.${searchSettings?.negotiation || 'sale'}.${el}`,
                                    }),
                                };
                            }),
                        },
                    ]}
                />

                <ResourceFilter
                    id="offer_status"
                    domainType="properties"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'radio',
                            paramName: 'offer_status',
                            options: [
                                { value: undefined, key: 'all' },
                                { value: 'accepted', key: 'accepted', displayKey: true },
                                { value: 'pending', key: 'pending', displayKey: true },
                            ],
                        },
                    ]}
                />

                <ResourceFilter
                    id="type"
                    domainType="properties"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'checkbox',
                            paramName: 'type',
                            defaultValue: [...allPropertyTypes],
                            simpleOptions: [...allPropertyTypes],
                        },
                    ]}
                />

                <ResourceFilter
                    id="subtypes"
                    domainType="properties"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            overflow: true,
                            type: 'checkbox',
                            allowEmpty: true,
                            hideOnlyButton: true,
                            paramName: 'subtypes',
                            groupOptions: getPropertiesSubtypesGroups(),
                        },
                    ]}
                />

                <ResourceFilter
                    id="price"
                    size="large"
                    prefix="euro"
                    domainType="properties"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'range',
                            paramName: searchSettings?.negotiation === 'let' ? 'let_price' : 'sale_price',
                        },
                    ]}
                />

                {showGeoTemplateFilter && (
                    <FilterGeo
                        domainType="properties"
                        searchSettings={searchSettings}
                        setSearchSettings={setSearchSettings}
                    />
                )}

                {showInternalTypeFilter ? (
                    <ResourceFilter
                        id="property_internal_type"
                        domainType="properties"
                        params={searchSettings}
                        setParams={setSearchSettings}
                        filters={[
                            {
                                type: 'radio',
                                multiple: true,
                                paramName: 'property_internal_type',
                                simpleOptions: ['project', 'standalone'],
                            },
                        ]}
                    />
                ) : (
                    <EstateTypeFilter
                        value={searchSettings?.property_type}
                        onChange={changedSettings => {
                            setSearchSettings({ ...searchSettings, ...changedSettings });
                        }}
                    />
                )}

                <ResourceFilter
                    id="condition"
                    domainType="properties"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'checkbox',
                            allowEmpty: true,
                            paramName: 'condition',
                            simpleOptions: [...PROPERTY_CONDITION_OPTIONS],
                        },
                    ]}
                />

                {showPropertyFeatures && (
                    <ResourceFilter
                        showSearch
                        id="features"
                        domainType="properties"
                        params={searchSettings}
                        setParams={setSearchSettings}
                        translationFirstPart="property.features"
                        filters={[
                            {
                                type: 'checkbox',
                                overflow: true,
                                allowEmpty: true,
                                hideOnlyButton: true,
                                paramName: 'features',
                                options: Object.values(getPropertiesFeatures(showNewFields))
                                    .flat()
                                    .map(el => {
                                        return {
                                            value: el,
                                            label: intl.formatMessage({ id: `property.features.${el}` }),
                                        };
                                    })
                                    .sort((a, b) => a.label.localeCompare(b.label)),
                            },
                        ]}
                    />
                )}

                {!showAreas && (
                    <>
                        <PropertyFilterAreaOld
                            searchSettings={searchSettings}
                            setSearchSettings={setSearchSettings}
                            options={['loi_carrez', 'liveable', 'net']}
                        />

                        <PropertyFilterAreaOld
                            searchSettings={searchSettings}
                            setSearchSettings={setSearchSettings}
                            options={['plot', 'gross']}
                        />
                    </>
                )}

                {showAreas && (
                    <>
                        <FilterArea
                            domainType="properties"
                            searchSettings={searchSettings}
                            options={['liveable', 'loi_carrez']}
                            setSearchSettings={setSearchSettings}
                        />

                        <ResourceFilter
                            size="large"
                            id="net_area"
                            prefix="sq_m"
                            domainType="properties"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'range',
                                    paramName: 'net_area',
                                },
                            ]}
                        />

                        <ResourceFilter
                            size="large"
                            prefix="sq_m"
                            id="plot_area"
                            domainType="properties"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'range',
                                    paramName: 'plot_area',
                                },
                            ]}
                        />

                        <ResourceFilter
                            size="large"
                            prefix="sq_m"
                            id="gross_area"
                            domainType="properties"
                            params={searchSettings}
                            setParams={setSearchSettings}
                            filters={[
                                {
                                    type: 'range',
                                    paramName: 'gross_area',
                                },
                            ]}
                        />
                    </>
                )}

                <ResourceFilter
                    id="floor"
                    domainType="properties"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'range',
                            paramName: 'floors',
                        },
                    ]}
                />

                <ResourceFilter
                    id="rooms"
                    domainType="properties"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'range',
                            showTitle: true,
                            paramName: 'bedrooms',
                        },
                        {
                            type: 'range',
                            showTitle: true,
                            paramName: 'living_rooms',
                        },
                    ]}
                />

                {showPropertyFeatures ? (
                    <ResourceFilter
                        showSearch
                        id="amenities"
                        domainType="properties"
                        params={searchSettings}
                        setParams={setSearchSettings}
                        filters={[
                            {
                                type: 'checkbox',
                                overflow: true,
                                allowEmpty: true,
                                hideOnlyButton: true,
                                paramName: 'amenities',
                                groupOptions: getPropertiesAmenitiesGroups(),
                            },
                        ]}
                    />
                ) : (
                    <PropertyFilterAmenities
                        // @ts-ignore
                        values={searchSettings}
                        onChange={changedSettings => {
                            // @ts-ignore
                            setSearchSettings({ ...searchSettings, ...changedSettings });
                        }}
                        title={<FormattedMessage id="properties.filter.amenities" defaultMessage="Amenities" />}
                    />
                )}

                <ResourceFilter
                    id="address_floor"
                    domainType="properties"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'radio-number',
                            paramName: 'address_floor',
                            options: [
                                { value: 'below', translateKey: 'general.below' },
                                { value: 'above', translateKey: 'general.above' },
                                { value: 'equal', translateKey: 'general.equal' },
                            ],
                        },
                    ]}
                />

                <ResourceFilter
                    id="propertyState"
                    domainType="properties"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'radio',
                            defaultValue: 'active',
                            paramName: 'propertyState',
                            simpleOptions: ['active', 'archived', 'all'],
                        },
                    ]}
                />
            </>
        ),
        [
            intl,
            showAreas,
            showNewFields,
            searchSettings,
            showPropertyFeatures,
            statusesPerNegotiation,
            setSearchSettings,
            showGeoTemplateFilter,
            showInternalTypeFilter,
            showPropertySharedFilter,
            showLegalEntityFilter,
        ],
    );

    return filters;
};
