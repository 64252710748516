import React from 'react';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { PublicationConfigModalInner } from '@/app.components/modals/PublicationConfig/PublicationConfigModal';
import { ChannelAccount, EstatePublication } from '@/graphql/generated/types';

export function ConfigurePublicationStep({
    account,
    onCancel,
    onSubmit,
    propertyId,
    action,
    lastPublication,
}: {
    action: 'publish' | 'update';
    onCancel: () => void;
    propertyId: string;
    onSubmit: (config: FixMeType) => Promise<void>;
    account: ChannelAccount;
    lastPublication: Maybe<EstatePublication>;
}) {
    return (
        <ErrorBoundary>
            <PublicationConfigModalInner
                onSubmit={onSubmit}
                onCancel={onCancel}
                account={account}
                action={action}
                propertyId={propertyId}
                initialValues={lastPublication?.config}
            />
        </ErrorBoundary>
    );
}
