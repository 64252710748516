import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Loadable from 'react-loadable';
import { createStructuredSelector } from 'reselect';
import { useLocalStorage } from 'react-use';
import { FormattedMessage } from 'react-intl-sweepbright';
import useOffice from '@/app.hooks/useOffice';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import { SearchViewType } from '@/app.components/forms/Search/AdvancedSearch';
import useNegotiationStatuses from '@/app.hooks/useNegotiationStatuses';
import Resource from '@/shared/resource';
import { ResourceContext } from '@/shared/resource/utils';
import { usePropertiesSorts } from '@/new.domains/properties/hooks/usePropertiesSorts';
import { usePropertiesFilters } from '@/new.domains/properties/hooks/usePropertiesFilters';
import { usePropertiesColumns } from '@/new.domains/properties/hooks/usePropertiesColumns';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import EmptyState from '@/app.components/empty/EmptyState';
import PropertiesListCard from './PropertyList/PropertyListCard';
import { PropertySearchType } from './types';
import PropertyListTableAction from './PropertyList/PropertyListTable/PropertyListTableAction';
import useProperties from './hooks/useProperties';

const PropertiesMap = Loadable({
    loader: () => import('@/app.domains/properties/PropertiesMap'),
    // eslint-disable-next-line react/display-name
    loading: () => <LayoutColumn className="c-properties-map" />,
});

const Properties = () => {
    const office = useOffice();
    const officeId = office.get('id');

    const mapRef = useRef(null);

    const [selected, setSelected] = useState<string[]>([]);

    const [activePropertyId, setActivePropertyId] = useState<string>('');

    const [view, setView] = useLocalStorage<SearchViewType>('properties.view', SearchViewType.List);

    const statusesPerNegotiation = useNegotiationStatuses();

    const [searchSettings, setSearchSettings] = useLocalStorage<PropertySearchType>('properties.filters', {
        negotiation: 'sale',
        sort_field: 'updated_at',
        query: '',
        sort_order: 'desc',
        archived: false,
        offer_status: undefined,
        status: [],
        type: [],
        condition: [],
    });

    const { loading, error, data, refetch, totalCount, hasNextPage, fetchMore } = useProperties({
        searchSettings,
        officeId,
        oldLogic: true,
    });

    const sorts = usePropertiesSorts();

    useEffect(() => {
        if (!searchSettings?.negotiation) {
            // @ts-ignore
            setSearchSettings({
                ...searchSettings,
                negotiation: 'sale',
            });
        }
    }, [searchSettings, setSearchSettings]);

    const filters = usePropertiesFilters(searchSettings, setSearchSettings, statusesPerNegotiation);

    const columns = usePropertiesColumns({ searchSettings, selected, setSelected });

    const content = {
        title: <FormattedMessage id="properties_list.search.title" defaultMessage="Properties" />,
        tabSale: <FormattedMessage id="forms.add-property.sale-or-let.sale.title" defaultMessage="For Sale" />,
        tabLet: <FormattedMessage id="forms.add-property.sale-or-let.let.title" defaultMessage="To Let" />,
        noEntities: (
            <FormattedMessage
                id="properties.empty.title"
                defaultMessage="Looks like you don’t have any properties yet"
            />
        ),
        errorTitle: <FormattedMessage id="misc.empty_view.error.title" defaultMessage="Whoops, something went wrong" />,
        errorSubtitle: (
            <FormattedMessage
                id="properties.load_error.subtitle"
                defaultMessage="Properties can't be loaded at this time"
            />
        ),
        emptyTitle: (
            <FormattedMessage id="search.empty.properties" defaultMessage="Sorry, no properties match your search" />
        ),
        count: (
            <FormattedMessage
                id="properties.count"
                values={{ count: totalCount }}
                defaultMessage={`${totalCount} properties`}
            />
        ),
    };

    const tabs = [
        {
            id: 'sale',
            filters: filters,
            label: content.tabSale,
            onTabSelected: () => {
                setSelected([]);

                // @ts-ignore
                setSearchSettings({
                    ...searchSettings,
                    negotiation: 'sale',
                    status: statusesPerNegotiation['sale'],
                });
            },
        },
        {
            id: 'let',
            filters: filters,
            label: content.tabLet,
            onTabSelected: () => {
                setSelected([]);

                // @ts-ignore
                setSearchSettings({
                    ...searchSettings,
                    negotiation: 'let',
                    status: statusesPerNegotiation['let'],
                });
            },
        },
    ];

    const getEmptyState = () => {
        return error ? (
            <EmptyState title={content.errorTitle} body={content.errorSubtitle} />
        ) : (
            <EmptyState title={content.emptyTitle} />
        );
    };

    const renderListCard = entity => {
        const location = entity.attributes?.location?.geo;

        const lat = location?.latitude;
        const lng = location?.longitude;

        return (
            <li key={entity.id}>
                <PropertiesListCard
                    entity={entity}
                    {...(lat && lng
                        ? {
                              onLocalize: () => {
                                  if (mapRef.current) {
                                      // @ts-ignore
                                      mapRef.current.flyTo({
                                          zoom: 15,
                                          duration: 2000,
                                          center: [lng, lat],
                                      });
                                  }
                              },
                          }
                        : {})}
                />
            </li>
        );
    };

    return (
        <ResourceContext.Provider
            value={{
                tabs,
                view,
                error,
                columns,
                filters,
                loading,
                content,
                setView,
                officeId,
                selected,
                totalCount,
                hasNextPage,
                setSelected,
                getEmptyState,
                searchSettings,
                renderListCard,
                entities: data,
                showMapView: true,
                setSearchSettings,
                sortOptions: sorts,
                handleFetchMore: fetchMore,
                hasEntities: totalCount > 0,
                listExtra: (
                    <PropertiesMap
                        searchMapRef={mapRef}
                        searchSettings={searchSettings}
                        activePropertyId={activePropertyId}
                        setActivePropertyId={setActivePropertyId}
                    />
                ),
                initialTab: get(searchSettings, 'negotiation', 'sale'),
                ...(selected.length > 0 && {
                    countAction: (
                        <PropertyListTableAction
                            // @ts-ignore
                            refetch={refetch}
                            selected={selected || []}
                            setSelected={setSelected}
                        />
                    ),
                }),
            }}
        >
            <Resource />
        </ResourceContext.Provider>
    );
};

const mapStateToProps = createStructuredSelector({
    showPropertiesMap: getFeature('properties.propertiesMap.enabled'),
});

export default connect(mapStateToProps)(Properties);
