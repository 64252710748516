import React from 'react';
import classNames from 'classnames';
import Toolbar from '../../app.components/elements/Toolbar/Toolbar';
import './PagePane.scss';

export default function PagePane({
    title,
    actions = [],
    children,
    backAction,
    cols,
    actionsClassName,
    size = 'lg',
}: {
    title: React.ReactNode;
    children?: React.ReactNode;
    actions?: React.ReactNode[];
    backAction?: React.ReactNode;
    cols?: any;
    actionsClassName?: string;
    size?: 'lg' | 'xl' | 'full';
}) {
    if (!children) {
        return null;
    }

    return (
        <div
            className={classNames('c-page-pane', {
                'c-page-pane-xl': size === 'xl',
                'c-page-pane-lg': size === 'lg',
                'c-page-pane-full': size === 'full',
            })}
        >
            {(title || backAction) && (
                <div className="c-page-pane__header" {...cols}>
                    <div className="bc-navbar bc-navbar-white bc-navbar-medium">
                        <Toolbar>
                            {backAction}
                            <Toolbar.Left>
                                <h2 className="bc-navbar-page-heading" data-testid="page_title">
                                    {title}
                                </h2>
                            </Toolbar.Left>
                            {actions && actions.length > 0 && (
                                <Toolbar.Right className={actionsClassName}>{actions}</Toolbar.Right>
                            )}
                        </Toolbar>
                    </div>
                </div>
            )}
            <div className="c-page-pane__content">{children}</div>
        </div>
    );
}
