import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';
import Button from '@sweepbright/uikit/build/esm/button';
import WithTooltip from '@/app.components/forms/WithTooltip';
import { FormattedMessage } from 'react-intl-sweepbright';
import PagePane from '../../app.layouts/PagePane/PagePane';

export default function FormPane({
    title,
    action,
    savingAction,
    onSubmit,
    tooltip,
    status,
    disabled,
    disabledText,
    hasForm,
    children,
    ...props
}) {
    const isSaving = status?.get('is_saving');
    const variant = isSaving ? 'info' : 'success';

    let actionText = isSaving
        ? savingAction ?? <FormattedMessage id="form.status.saving" defaultMessage="Saving..." />
        : action ?? <FormattedMessage id="form.status.save" defaultMessage="Save" />;
    let errors = status?.get('errors') ?? Immutable.List();

    if (!(errors instanceof Immutable.List)) {
        errors = Immutable.List([errors]);
    }

    if (errors.count()) {
        actionText = <FormattedMessage id="form.status.error" defaultMessage="Could not save" />;
    }

    actionText = disabled && disabledText ? disabledText : actionText;

    const isDisabled = disabled || isSaving || errors.count() > 0;
    const pageAction = onSubmit && hasForm && (
        <WithTooltip message={isDisabled ? '' : tooltip}>
            <Button
                variant={variant}
                className="pull-right"
                disabled={isDisabled}
                onClick={onSubmit}
                data-testid="submit_btn"
                data-status={isSaving ? 'saving' : 'idle'}
            >
                {actionText}
            </Button>
        </WithTooltip>
    );

    return (
        <PagePane title={title} {...props} actions={[...props.actions, pageAction]}>
            {children}
        </PagePane>
    );
}

FormPane.defaultProps = {
    hasForm: true,
    actions: [],
};

FormPane.propTypes = {
    ...PagePane.propTypes,
    action: PropTypes.node,
    disabled: PropTypes.bool,
    hasForm: PropTypes.bool,
    onSubmit: PropTypes.func,
    status: PropTypes.instanceOf(Immutable.Map),
};
