import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    addContact,
    addCompanyOffice,
    fetchCompanyOffices,
    fetchCompanyFeatures,
    fetchFeatures,
} from '@/app.redux/actions';
import { getUser } from '@/app.redux/selectors/UsersSelectors';
import { getAuth } from '@/app.redux/selectors';
import ApplicationLayout from './ApplicationLayout';

const mapActionsToProps = {
    addContact,
    addCompanyOffice,
    fetchCompanyOffices,
    fetchCompanyFeatures,
    fetchFeatures,
};
const mapStateToProps = createStructuredSelector({
    auth: getAuth,
    user: getUser,
});

export default connect(mapStateToProps, mapActionsToProps)(ApplicationLayout);
