// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import { Checkbox } from '@sweepbright/uikit';
import xor from 'lodash/xor';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import TableSortLabel from './elements/TableSortLabel';
import './DataTable.scss';

export const Table = ({ className, stripped, condensed, bordered, hover, ...props }) => (
    <table
        className={classNames(
            'table',
            {
                'table-stripped': stripped,
                'table-bordered': bordered,
                'table-condensed': condensed,
                'table-hover': hover,
            },
            className,
        )}
        {...props}
    />
);

export const TableRow = 'tr';
export const TableBody = 'tbody';
export const TableCell = 'td';
export const TableHeaderCell = 'th';

function defaultRender(data) {
    return data;
}

const DataRow = React.memo(function DataRow({ columns, data, selection, expansion, level = 0, ...rest }) {
    return (
        <TableRow {...rest}>
            {columns.map(({ dataIndex, key, render = defaultRender, level: columnLevel = 0, cellClassName }) => {
                const shouldRenderColumn = columnLevel === level || columnLevel < 0;

                return (
                    <TableCell key={key} className={cellClassName}>
                        {shouldRenderColumn
                            ? render(get(data, dataIndex), data, { selection, expansion, level })
                            : null}
                    </TableCell>
                );
            })}
        </TableRow>
    );
});

const SelectionContext = React.createContext<{
    selection?: { value: string[]; onChange: (values: string[]) => void; selectable?: (id: string) => boolean };
    selectable: boolean;
}>({
    selection: {
        value: [] as string[],
        onChange: () => {
            // do nothing
        },
    },
    selectable: false,
});

const ExpandableRow = ({ rowProps, __items = [], data, columns, level = 0 }) => {
    const { selection, selectable } = React.useContext(SelectionContext);
    const selected = selection?.value.includes(data.id);
    const isRowSelectable = selectable && selection && (!selection.selectable || selection.selectable(data.id));
    const [expanded, setExpanded] = React.useState(false);
    const canBeExpanded = __items.length > 1;
    // move the level up by 1
    if (__items.length === 1) {
        columns = columns.map(column => ({ ...column, level: column.level > level ? column.level - 1 : column.level }));
    }

    const rootRow = [
        <DataRow
            level={level}
            selection={{
                selected,
                onChange: () => {
                    // eslint-disable-next-line no-unused-expressions
                    selection?.onChange(xor(selection.value, [data.id]));
                },
                selectable: isRowSelectable,
            }}
            expansion={
                canBeExpanded
                    ? {
                          expanded,
                          onToggle: () => {
                              setExpanded(!expanded);
                          },
                      }
                    : null
            }
            columns={columns}
            key={data.key}
            data={data}
            {...rowProps}
        />,
    ];

    if (expanded && __items.length > 0) {
        return (
            <>
                {rootRow.concat(
                    __items.map(({ key, __items, ...data }) => {
                        return (
                            <ExpandableRow
                                __items={__items}
                                level={level + 1}
                                columns={columns}
                                key={key}
                                data={data}
                                rowProps={rowProps}
                            />
                        );
                    }),
                )}
            </>
        );
    }

    return rootRow;
};

// render function for the selectable cell
const selectableRender = (_, data, { selection, level = 0 }) => {
    // for now, selection is only possible at the top level
    if (level === 0) {
        return (
            <Checkbox
                checked={selection.selected}
                onChange={selection.onChange}
                disabled={!selection.selectable}
                className="pl-0 -my-2 -mr-2"
            />
        );
    }

    return null;
};

const expandableRender = (
    _,
    data,
    {
        expansion,
    }: {
        expansion: {
            onToggle: () => void;
            expanded;
        };
    },
) => {
    if (!expansion) {
        return null;
    }

    return (
        <button
            type="button"
            className="bg-transparent w-5 h-5 flex items-center mx-auto p-0 cursor-default"
            onClick={expansion.onToggle}
        >
            <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                style={{
                    transform: expansion.expanded ? 'rotateZ(90deg)' : 'rotateZ(0)',
                    transformOrigin: 'center',
                }}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 18.5c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7l5.6-5.6-5.6-5.7c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0l6 6c.2.2.2.5 0 .7l-6 6c0 .1-.2.1-.3.1z"
                    fill="currentColor"
                />
            </svg>
        </button>
    );
};

type ColumnDescriptor = {
    key: string;
    title?: JSX.Element;
    dataIndex: string;
    render?: (...args: any[]) => JSX.Element;
    className?: string;
    cellClassName?: string;
    sorter?: (a: any, b: any) => number;
};

const DataTable = ({
    columns,
    dataSource,
    groups = [],
    groupColumns = {},
    selectable,
    selection,
    className,
    ...props
}: {
    selectable?: boolean;
    selection?: {
        value: string[];
        onChange: (values: string[]) => void;
    };
    hover?: boolean;
    dataSource: any[];
    className?: string;
    groups?: { key: string; selectable?: boolean }[];
    columns: ColumnDescriptor[];
    groupColumns?: Record<string, ColumnDescriptor>;
}) => {
    const [orders, setOrders] = React.useState({});
    const [orderBy, setOrderBy] = React.useState();

    const handleSort = (evt, columnKey) => {
        const sorter = columns.find(col => col.key === columnKey).sorter;
        if (sorter) {
            setOrderBy(columnKey);
            setOrders(getNextOrdersBy(columnKey));
        }
    };
    const hasGroups = groups.length > 0;

    dataSource = buildGroups(dataSource, 0, groups);

    const sortedDataSource = React.useMemo(() => {
        if (orderBy) {
            const sorterColumn = columns.find(col => col.key === orderBy);
            const orderDirection = orders[orderBy] === 'asc' ? -1 : 1;
            const comparator = (a, b) => {
                const _a = a[sorterColumn.dataIndex];
                const _b = b[sorterColumn.dataIndex];

                return orderDirection * sorterColumn.sorter(_a, _b);
            };

            return dataSource.sort(comparator);
        }

        return dataSource;
    }, [orders, orderBy, dataSource]);

    const renderSelectAll = React.useCallback(() => {
        const selectableRowIds = selectable
            ? dataSource.filter(item => !selection.selectable || selection.selectable(item)).map(item => item.id)
            : [];
        const checked = selection?.value.length > 0 && selection?.value.length >= selectableRowIds.length;

        return (
            <div>
                <Checkbox
                    checked={checked}
                    onChange={evt => {
                        if (evt.target.checked) {
                            selection.onChange(selectableRowIds);
                        } else {
                            selection.onChange([]);
                        }
                    }}
                    className="-mr-5"
                />
            </div>
        );
    }, [selection?.value, selection?.onChange, selection?.selectable, dataSource]);

    const memoizedColumns = React.useMemo(() => {
        return [
            selectable && {
                key: 'selection_column',
                title: renderSelectAll(),
                render: selectableRender,
                className: 'w-6',
                // this renders at all levels
                level: -1,
            },
            hasGroups && {
                key: 'expansion_column',
                title: '',
                cellClassName: 'px-0 justify-center w-4',
                className: 'w-4',
                render: expandableRender,
                // this renders at all levels
                level: -1,
            },
            // the default columns
            ...columns,
            ...groups.flatMap((group, idx) =>
                groupColumns[group.key]?.map(column => ({ ...column, level: idx + 1 } ?? [])),
            ),
        ].filter(Boolean);
    }, [columns, selectable, renderSelectAll]);

    return (
        <SelectionContext.Provider value={{ selection, selectable }}>
            <Table className={classNames('datatable', className)} {...props}>
                <TableHeader columns={memoizedColumns} order={orders[orderBy]} orderBy={orderBy} onSort={handleSort} />
                <TableBody>
                    {sortedDataSource.map(({ rowProps = {}, __items, ...data }) => {
                        return (
                            <ExpandableRow
                                __items={__items}
                                key={data.id}
                                data={data}
                                rowProps={rowProps}
                                columns={memoizedColumns}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </SelectionContext.Provider>
    );
};

export default DataTable;

export const TableHeader = ({ columns, order, orderBy, onSort }) => {
    const createSortHandler = columnKey => evt => onSort(evt, columnKey);

    return (
        <thead>
            <TableRow>
                {columns.map(({ key, className, title, sorter }) => (
                    <TableHeaderCell
                        key={key}
                        className={className}
                        onClick={sorter ? createSortHandler(key) : undefined}
                    >
                        {title}
                        {sorter && <TableSortLabel direction={order} active={orderBy === key} />}
                    </TableHeaderCell>
                ))}
            </TableRow>
        </thead>
    );
};

const possibleOrders = ['asc', 'desc'];

function getNextOrdersBy(columnKey: string) {
    return orders => {
        const currentOrderInCol = orders[columnKey] || 'asc';
        const orderIndex = possibleOrders.indexOf(currentOrderInCol);
        const nextOrderInCol = possibleOrders[(orderIndex + 1) % possibleOrders.length];

        return { ...orders, [columnKey]: nextOrderInCol };
    };
}

export function buildGroups(items, level, groups) {
    if (level >= groups.length) {
        return items;
    }
    const group = groups[level];
    const groupedItems = groupBy(items, group.key);
    Object.keys(groupedItems).map(key => {
        groupedItems[key] = buildGroups(groupedItems[key], level + 1, groups);
    });

    return Object.keys(groupedItems).map(key => {
        const [firstItem] = groupedItems[key];

        return {
            key,
            ...firstItem,
            __items: groupedItems[key],
        };
    });
}
