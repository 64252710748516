import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import { getCompany } from '@/app.redux/selectors';
import { fetchCompany } from '@/app.redux/actions';
import LeadsAside from '@/app.components/asides/LeadsAside';

const LeadsLayout = ({ company = Map(), location, children }) => {
    React.useEffect(() => {
        fetchCompany();
    }, [company]);

    return (
        <LayoutContainer>
            <LeadsAside location={location} />

            <LayoutColumn>
                {company.has('id') &&
                    React.isValidElement(children) &&
                    React.cloneElement(children, {
                        // @ts-ignore
                        company,
                    })}
            </LayoutColumn>
        </LayoutContainer>
    );
};

export default connect(
    createStructuredSelector({
        company: getCompany,
    }),
    { fetchCompany },
)(LeadsLayout);
