import { fork, select, put, takeEvery, take } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import {
    FETCH_USER,
    FETCH_USERS,
    setUsers,
    setUser,
    setOffices,
    setCompanies,
    ARCHIVE_USER,
    SET_USER_OFFICE,
    FETCH_USER_VIEWS,
    fetchUserViews,
    MARK_FILE_AS_VIEWED,
    fetchFeatures,
    SET_FEATURES,
    FETCH_USER_FAILED,
    fetchUserFailed,
} from '@/app.redux/actions';
import { isLoggingIn } from '@/app.redux/actions/AuthActions';
import logger from '@/app.utils/services/logger';
import { configure } from '@/app.config/accounting';
import { getContacts } from '../selectors/ContactsSelectors';
import UserRequests from '../../requests/UserRequests';
import apiCall from './Effects/apiCall';
import { onArchiveUser, onSetUserOffice } from './Sagas';
import { onFetchUserViews, onMarkFileAsViewed } from './Users';
import { onLogout } from './Authentication';

export function* onFetchUsers() {
    const users = yield select(getContacts);
    yield put(setUsers(users));
}

export function* onFetchUser() {
    try {
        logger.info('fetching user data and preferences');
        const user = yield apiCall(new UserRequests().me);

        const office = user.office.data;
        configure(fromJS(office?.settings.data ?? {}));

        yield put(fetchFeatures());
        // wait for the features to be loaded
        yield take(SET_FEATURES);

        yield [put(setOffices([user.office.data])), put(setCompanies([user.company.data])), put(setUser(user))];
        yield put(fetchUserViews('files'));
    } catch (err) {
        logger.error('failed to fetch the current user');
        yield put(fetchUserFailed());
    } finally {
        yield put(isLoggingIn(false));
    }
}

export default function* UsersSaga() {
    yield [
        fork(takeEvery, FETCH_USERS, onFetchUsers),
        fork(takeEvery, FETCH_USER, onFetchUser),
        fork(takeEvery, ARCHIVE_USER, onArchiveUser),
        fork(takeEvery, SET_USER_OFFICE, onSetUserOffice),
        fork(takeEvery, FETCH_USER_VIEWS, onFetchUserViews),
        fork(takeEvery, MARK_FILE_AS_VIEWED, onMarkFileAsViewed),
        fork(takeEvery, FETCH_USER_FAILED, onLogout),
    ];
}
