const isProd = process.env.NODE_ENV === 'production';

function createServerLogger() {
    if (__SERVER__) {
        const { createLogger, format, transports } = require('winston');
        const { combine, timestamp, label, simple, json, colorize } = format;
        const logLevel = process.env.LOG_LEVEL || 'info';

        // stack formatter, so we can do something like
        // logger.info(new Error("yo")) => { message: "yo", stack: "Error at..."}
        const errorStackFormat = format(info => {
            if (info instanceof Error) {
                return Object.assign({}, info, {
                    stack: info.stack,
                    message: info.message,
                });
            }

            return info;
        });

        const localEnvFormat = combine(
            colorize({ all: true }),
            errorStackFormat(),
            label({ label: 'node-webapp' }),
            timestamp(),
            simple(),
        );

        const defaultFormat = combine(errorStackFormat(), label({ label: 'node-webapp' }), timestamp(), json());

        const logger = createLogger({
            level: logLevel,
            format: isProd ? defaultFormat : localEnvFormat,
            transports: [new transports.Console()],
            exitOnError: false,
        });
        // for usage with morgan as a stream
        logger.stream = {
            write(message) {
                logger.info(message, {
                    source: 'winston',
                });
            },
        };

        return logger;
    }
    throw new Error('Server can only be used in the server');
}

function createClientLogger() {
    const { datadogLogs } = require('@datadog/browser-logs');

    datadogLogs.init({
        clientToken: 'pub9150b50ff6f660a653d3ef054c7c25cf',
        site: 'datadoghq.com',
        service: 'webapp',
        forwardErrorsToLogs: true,
        env: APP_ENV,
        sampleRate: 100,
    });

    const logger = datadogLogs.logger;
    logger.info('client logger initialized');

    return logger;
}

// Supported log levels are
// ```
//  const levels = {
//   error: 0,
//   warn: 1,
//   info: 2,
//   verbose: 3,
//   debug: 4,
//   silly: 5
// }
// ```;

module.exports = __SERVER__ ? createServerLogger() : createClientLogger();
