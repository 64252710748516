import { useQuery } from '@apollo/react-hooks';
import { GET_CONTACT_QUERY } from '@/graphql/queries/contacts/getContact';
import { GetContactQuery, GetContactQueryVariables } from '@/graphql/generated/types';

interface UseContactOptions {
    onCompleted?: (data: any) => void;
    cacheFirst?: boolean;
}

export default function useContact(contactId: Maybe<string>, { onCompleted, cacheFirst }: UseContactOptions = {}) {
    const { data, loading, error, refetch } = useQuery<GetContactQuery, GetContactQueryVariables>(GET_CONTACT_QUERY, {
        variables: {
            id: contactId!,
        },
        skip: !contactId,
        onCompleted(data) {
            if (data) {
                // eslint-disable-next-line no-unused-expressions
                onCompleted?.(data.contact);
            }
        },
        partialRefetch: true,
        returnPartialData: true,
        fetchPolicy: cacheFirst ? 'cache-first' : 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    });

    return { contact: data?.contact, loading, error, refetch };
}
