import { GetMatchingFlagsQuery } from '@/graphql/generated/types';
import { statuses } from '@/app.components/elements/Status/Status';
import { ResourceDomainType } from '@/shared/resource/types';

export const formatFlags = ({
    data,
    id,
    domainType,
}: {
    data?: GetMatchingFlagsQuery;
    id: string;
    domainType: ResourceDomainType;
}) => {
    const activeFlags = data?.matchingFlags?.data?.find(subEL =>
        domainType === 'contact_matches' ? subEL?.propertyId === id : subEL?.contactId === id,
    )?.flags;

    const flags: { [key: string]: string | number | boolean } | undefined = activeFlags
        ? Object.fromEntries(activeFlags.map(el => [el.type, el.value]))
        : undefined;

    const disabled = !flags || !flags?.CAN_RECEIVE_EMAILS || false;

    const status =
        (flags?.CAN_BE_EMAILED_AGAIN && !!flags?.EMAIL_COUNT && statuses.changedOrange) ||
        (!flags?.CAN_BE_EMAILED_AGAIN && !!flags?.EMAIL_COUNT && statuses.checked) ||
        '';

    return {
        status,
        disabled,
    };
};
