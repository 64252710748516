import get from 'lodash/get';
import { CurrencyAmount } from '@/app.data/Leads';

export const getComparator = (order: string) => {
    const ascendingComparator = (a: number, b: number) => {
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    };

    switch (order) {
        case 'asc':
            return ascendingComparator;
        case 'desc':
            return (a: number, b: number): number => -ascendingComparator(a, b);
        default:
            throw new Error('Invalid order for comparing elements');
    }
};

export function stringComparator(a: string = '', b: string = '') {
    return String(a).localeCompare(String(b));
}

export function numberComparator(a: number, b: number) {
    return a - b;
}

export function getPropertiesComparator(filters: {
    sort_field: 'updated_at' | 'published_at' | 'city' | 'price';
    sort_order: 'asc' | 'desc';
}) {
    return function comparator(property: {
        updated_at: string;
        is_published: string;
        published_at: string;
        attributes: {
            location: {
                city: string;
            };
            price: {
                published_price: CurrencyAmount;
            };
        };
    }) {
        if (filters.sort_field === 'updated_at') {
            return property.updated_at;
        }

        if (filters.sort_field === 'published_at') {
            return property.published_at;
        }

        if (filters.sort_field === 'city') {
            return get(property, 'attributes.location.city');
        }

        if (filters.sort_field === 'price') {
            return get(property, 'attributes.price.published_price.amount', 0);
        }
    };
}
