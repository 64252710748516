// @ts-nocheck
import React from 'react';
import { Map } from 'immutable';
import moment from 'moment';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import { toTitle } from '@/app.utils/services/String';
import interactions, { InteractionType } from '@/app.data/Interactions';
import ContactRepository from '@/app.utils/services/Repositories/ContactRepository';
import { APIContactInteraction } from '@sweepbright/webapp-server/graphql/resolvers/helpers/types';

type Props = {
    contact: Map<string, any>;
    showLastInteraction: boolean;
};

// @ts-ignore
const ContactLastActivity: React.FunctionComponent<Props> = function ContactLastActivity({
    contact,
    showLastInteraction,
}: Props & InjectedIntlProps) {
    return <ContactLastAction showLastInteraction={showLastInteraction} {...getLastActionProps(contact)} />;
};

export default ContactLastActivity;

const ContactLastAction = ({
    showLastInteraction = true,
    lastInteraction,
    lastAction,
    updatedAt,
    createdAt,
    isArchived,
    type = 'contact',
}: {
    type: 'lead' | 'vendor' | 'contact';
    updatedAt: string;
    createdAt: string;
    isArchived?: boolean;
    showLastInteraction: boolean;
    lastAction: 'update' | 'create' | 'interaction';
    lastInteraction: any;
}) => {
    if (isArchived) {
        return <FormattedMessage id="contact.status.archived" defaultMessage="Archived" />;
    }

    if (lastAction === 'interaction') {
        return <ContactInteraction interaction={lastInteraction} showDetailedInteraction={showLastInteraction} />;
    }

    const updatedAtMoment = moment(updatedAt);
    const createdAtMoment = moment(createdAt);

    if (updatedAtMoment.isValid()) {
        if (!createdAt || updatedAtMoment.isAfter(createdAtMoment)) {
            return (
                <FormattedMessage
                    id="components.contact_card.last_edited"
                    defaultMessage="Last edited {time}"
                    values={{ time: updatedAtMoment.fromNow() }}
                />
            );
        }

        return (
            <FormattedMessage
                id="components.contact_card.contact_created_time"
                defaultMessage="{contact} created {time}"
                values={{
                    contact: (
                        <FormattedMessage
                            id={`contact.type.${type.toLowerCase()}`}
                            defaultMessage={toTitle(type)}
                            tagName={React.Fragment}
                        />
                    ),
                    time: createdAtMoment.fromNow(),
                }}
            />
        );
    }

    return null;
};

export const ContactInteraction: React.FunctionComponent<{
    interaction: APIContactInteraction;
    showDetailedInteraction?: boolean;
}> = injectIntl(function ContactInteraction({ interaction, showDetailedInteraction, intl }) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const createdDate = moment(interaction?.created_at).fromNow();

    const interactionType: Maybe<InteractionType> = interaction?.interaction_type;
    if (!interactionType) {
        return null;
    }

    return (
        <span>
            {showDetailedInteraction ? (
                intl.formatMessage(interactions[interactionType!])
            ) : (
                <FormattedMessage
                    id="components.contact_thumbnail.last_interaction"
                    defaultMessage="Last interaction"
                />
            )}{' '}
            {createdDate}
        </span>
    );
});

// helper method to convert a legacy contact (the ones using Map)
// to the new contact type
function getLastActionProps(contact: Map<string, any>) {
    const repository = new ContactRepository(contact);
    const type = repository.getType();
    const lastInteraction = contact.getIn(['latest_interaction', 'data'])?.toJS();

    return {
        type,
        lastInteraction,
        updatedAt: contact.get('updated_at'),
        lastAction: contact.get('latest_action'),
    };
}

export { ContactLastAction, getLastActionProps };
