import { call, put } from 'redux-saga/effects';
import FacebookRequests from '@/requests/FacebookRequests';
import { setFacebookAccounts } from '../../actions';

export default function* onFetchFacebookAccounts({ token }) {
    if (token) {
        try {
            const request = new FacebookRequests().withBearerToken(token);
            const accounts = yield call(request.facebookAccounts);

            yield put(setFacebookAccounts(accounts));
        } catch (error) {
            // ¯\_(ツ)_/¯
        }
    }
}
