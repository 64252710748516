import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import produce from 'immer';
import { GET_PROPERTY_TIMELINE } from '@/graphql/queries/properties/getPropertyTimeline';
import { GetPropertyTimelineQuery, GetPropertyTimelineQueryVariables, PageInfo } from '@/graphql/generated/types';
import { getBugsnagClient } from '@/app.config/bugsnag';

export function useTimelineData({ propertyId, contactId }: { propertyId: string; contactId?: string }) {
    const { data, loading, fetchMore, error, refetch } = useQuery<
        GetPropertyTimelineQuery,
        GetPropertyTimelineQueryVariables
    >(GET_PROPERTY_TIMELINE, {
        variables: {
            propertyId,
            contactId,
        },
        errorPolicy: 'ignore',
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        onError(error) {
            getBugsnagClient().notify(error);
        },
    });

    const activities = data?.estate?.timeline.activities.nodes ?? [];
    const pageInfo: Partial<PageInfo> = data?.estate?.timeline.activities.pageInfo ?? {
        hasNextPage: false,
        endCursor: null,
    };

    const handleFetchAfter = React.useCallback(
        function handleFetchAfter() {
            if (!loading) {
                fetchMore({
                    variables: {
                        after: pageInfo.endCursor,
                    },
                    updateQuery: (
                        prev: GetPropertyTimelineQuery,
                        {
                            fetchMoreResult,
                        }: {
                            fetchMoreResult: GetPropertyTimelineQuery;
                        },
                    ) => {
                        if (!fetchMoreResult) return prev;

                        return produce(prev, draft => {
                            draft.estate!.timeline.activities.pageInfo.endCursor = fetchMoreResult.estate!.timeline.activities.pageInfo.endCursor;
                            draft.estate!.timeline.activities.pageInfo.hasNextPage = fetchMoreResult.estate!.timeline.activities.pageInfo.hasNextPage;

                            fetchMoreResult.estate!.timeline.activities.nodes.forEach(node => {
                                draft.estate!.timeline.activities.nodes.push(node);
                            });
                        });
                    },
                });
            }
        },
        [pageInfo, loading],
    );

    const handleFetchBefore = React.useCallback(
        function handleFetchBefore() {
            fetchMore({
                variables: {
                    before: pageInfo.startCursor,
                },
                updateQuery: (
                    prev: GetPropertyTimelineQuery,
                    {
                        fetchMoreResult,
                    }: {
                        fetchMoreResult: GetPropertyTimelineQuery;
                    },
                ) => {
                    if (!fetchMoreResult) return prev;

                    return produce(prev, draft => {
                        draft.estate!.timeline.activities.pageInfo.startCursor = fetchMoreResult.estate!.timeline.activities.pageInfo.startCursor;
                        for (let i = fetchMoreResult.estate!.timeline.activities.nodes.length - 1; i >= 0; i--) {
                            const node = fetchMoreResult.estate!.timeline.activities.nodes[i];
                            draft.estate!.timeline.activities.nodes.unshift(node);
                        }
                    });
                },
            });
        },
        [pageInfo, loading],
    );

    return {
        pageInfo,
        loading,
        handleFetchAfter,
        handleFetchBefore,
        activities,
        error,
        refetch,
    };
}
