import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import useOffice from '@/app.hooks/useOffice';
import { ContactFilterSettings } from '@/app.domains/contacts/types';
import useLocalStorage from '@/app.hooks/useLocalStorage';
import { SearchViewType } from '@/app.components/forms/Search/AdvancedSearch';
import { ResourceContext } from '@/shared/resource/utils';
import Resource from '@/shared/resource';
import EmptyState from '@/app.components/empty/EmptyState';
import { CONTACT } from '@/app.routing';
import ContactListCard from '@/app.domains/contacts/ContactListCard';
import useContacts from '../hooks/useContacts';
import { useContactsFilters } from '../hooks/useContactsFilters';
import { useContactsColumns } from '../hooks/useContactsColumns';
import { useContactsSorts } from '../hooks/useContactsSorts';

const Contacts = () => {
    const office = useOffice();
    const officeId = office.get('id');

    const [view, setView] = useLocalStorage<SearchViewType>('contacts.view', SearchViewType.List);

    const [searchSettings, setSearchSettings] = useLocalStorage<ContactFilterSettings>('contacts.filters', {
        type: 'lead',
        query: '',
        sort_field: 'updated_at',
        sort_order: 'desc',
    });

    const { loading, error, data, totalCount, hasNextPage, fetchMore } = useContacts({
        officeId,
        oldLogic: true,
        searchSettings: searchSettings,
    });

    const sorts = useContactsSorts();

    const filters = useContactsFilters(searchSettings, setSearchSettings);

    const columns = useContactsColumns();

    const content = {
        title: <FormattedMessage id="navigation.aside.contacts" defaultMessage="Contacts" />,
        tabLead: <FormattedMessage id="modals.contacts_search.contact_type_panel.leads_label" defaultMessage="Leads" />,
        tabVendor: (
            <FormattedMessage id="modals.contacts_search.contact_type_panel.vendors_label" defaultMessage="Vendors" />
        ),
        noEntities: (
            <FormattedMessage id="contacts.empty.title" defaultMessage="Looks like you don’t have any contacts yet" />
        ),
        errorTitle: <FormattedMessage id="misc.empty_view.error.title" defaultMessage="Whoops, something went wrong" />,
        emptyTitle: (
            <FormattedMessage id="search.empty.contacts" defaultMessage="Sorry, no contacts match your search" />
        ),
        count: (
            <FormattedMessage
                id="contacts.count"
                values={{ count: totalCount }}
                defaultMessage={`${totalCount} contacts`}
            />
        ),
    };

    const tabs = [
        {
            label: '',
            id: 'contacts',
            filters: filters,
            onTabSelected: () => {},
        },
    ];

    const getEmptyState = () => {
        return error ? <EmptyState title={content.errorTitle} /> : <EmptyState title={content.emptyTitle} />;
    };

    const renderListCard = entity => {
        return (
            <li key={entity.id}>
                <ContactListCard contact={entity} to={CONTACT(entity.id)} />
            </li>
        );
    };

    return (
        <ResourceContext.Provider
            value={{
                tabs,
                view,
                error,
                columns,
                filters,
                loading,
                content,
                setView,
                officeId,
                totalCount,
                hasNextPage,
                getEmptyState,
                renderListCard,
                entities: data,
                sortOptions: sorts,
                handleFetchMore: fetchMore,
                hasEntities: totalCount > 0,
                searchSettings: searchSettings,
                setSearchSettings: setSearchSettings,
            }}
        >
            <Resource />
        </ResourceContext.Provider>
    );
};

export default Contacts;
