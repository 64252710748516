const floorFileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/gif'];
const imageFileTypes = ['image/png', 'image/jpeg', 'image/gif'];
const fileSize = 500 * 1024 * 1024;

export interface FileUploadLimit {
    readonly size?: number;
    readonly type: string[];
    readonly maxFiles?: number;
}

type UploadType = 'image' | 'certificate' | 'floor-plan' | 'cta' | 'rea';

export const limits: { [key in UploadType]: FileUploadLimit } = {
    image: {
        size: fileSize,
        type: imageFileTypes,
        maxFiles: 25,
    },
    certificate: {
        size: fileSize,
        type: [],
        maxFiles: 25,
    },
    'floor-plan': {
        size: fileSize,
        type: floorFileTypes,
        maxFiles: 25,
    },
    cta: { type: ['.csv'] },
    rea: { type: ['.xml'] },
};
