import React from 'react';
import { Estate } from '@/graphql/generated/types';
import { FormattedMessage } from 'react-intl-sweepbright';

const PropertyType: React.FunctionComponent<{ property: Estate }> = ({ property }) => {
    let propertyType;
    if (property.isProject) {
        propertyType = <FormattedMessage id={`project.type.${property.type}`} defaultMessage={property.type} />;
    } else {
        propertyType = <FormattedMessage id={`property.type.${property.type}`} defaultMessage={property.type} />;
    }

    return <span>{propertyType}</span>;
};

export default PropertyType;
