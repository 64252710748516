import { extractData } from '@sweepbright/margaret-fetcher';
import { APIChannel } from '@/../server/graphql/resolvers/helpers/types';
import SweepbrightCrudRequest from './SweepbrightCrudRequest';

export default class ChannelsRequests extends SweepbrightCrudRequest {
    resource = 'channels';

    constructor() {
        super();
        this.setMiddlewares([extractData]);
    }

    getChannels = () => {
        return this.get<APIChannel[]>(`channels`);
    };

    getChannel = (channelId: string, missingPermissions?: boolean) => {
        return this.withQueryParameter('missing_permissions', missingPermissions).get<APIChannel>(
            `channels/${channelId}`,
        );
    };
}
