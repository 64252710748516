import queryString from 'query-string';

export default class Email {
    /**
     *
     * @type {string}
     */
    subject = '';

    /**
     *
     * @type {string}
     */
    body = '';

    /**
     *
     * @type {Array}
     */
    addresses = [];

    /**
     *
     * @type {Array}
     */
    cc = [];

    constructor(subject, body) {
        this.subject = subject;
        this.body = body;
    }

    updateAddresses(addresses, field) {
        this[field] = Array.isArray(addresses) ? addresses : [addresses];
    }

    /**
     *
     * @param addresses
     */
    to(addresses) {
        this.updateAddresses(addresses, 'addresses');

        return this;
    }

    /**
     *
     * @param addresses
     */
    withCc(addresses) {
        if (typeof addresses === 'undefined' || addresses === '') {
            return this;
        }

        this.updateAddresses(addresses, 'cc');

        return this;
    }

    /**
     *
     * @returns {string}
     */
    toUrl() {
        const params = {
            subject: this.subject,
            body: this.body,
        };

        if (this.cc.length > 0) {
            params.cc = this.cc.join(', ');
        }

        return `mailto:${this.addresses.join(',')}?${queryString.stringify(params)}`;
    }
}
