import React, { useState, useEffect } from 'react';
import { useDebounce } from 'react-use';
import { useQuery } from '@apollo/react-hooks';
import EmptyState from '@/app.components/empty/EmptyState';
import PropertyCard, { adaptProperty } from '@/app.domains/properties/PropertyCard';
import { FormattedMessage } from 'react-intl-sweepbright';
import useOffice from '@/app.hooks/useOffice';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { GET_OFFICE_ESTATES_QUERY } from '@/graphql/queries/properties/getOfficeEstates';
import BorderedList from '@/app.components/forms/BorderedList.New';
import useSelectField from '@/app.hooks/useSelectField';
import Input from '../../../app.components/forms/Input/Input';

function propertyToItem(property) {
    if (property) {
        property = adaptProperty(property?.node || property);

        return {
            value: property.get('id'),
            option: <PropertyCard property={property} />,
        };
    }

    return undefined;
}

const PropertyPicker = ({
    onSelect,
    excluded = [],
}: {
    onSelect: (property) => void;
    excluded?: string[];
    disabled?: boolean;
    hasError?: boolean;
    value?: string;
}) => {
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [finished, setFinished] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [debouncedQuery, setDebouncedQuery] = useState('');

    useDebounce(
        () => {
            setPage(1);
            setFinished(false);
            setDebouncedQuery(query);
        },
        500,
        [query],
    );

    const { estates = [], loading = true } = useEstates({ page, query: debouncedQuery });

    useEffect(() => {
        if (!loading) {
            if (estates.length > 0) {
                if (page === 1) {
                    setData(estates);
                }

                if (page > 1) {
                    setData(prevData => [...(prevData || []), ...estates]);
                }
            }

            if (estates.length === 0) {
                setFinished(true);

                if (page === 1) {
                    setData([]);
                }
            }
        }

        // eslint-disable-next-line
    }, [page, loading, estates]);

    const formattedData = data
        .filter(property => !excluded.includes(property?.node?.id || property?.id))
        .map(propertyToItem);

    const placeholder = <FormattedMessage id="property_picker.placeholder" defaultMessage='Search "Abbey Road"' />;

    const selectField = useSelectField([], {
        onSelected: ids => {
            onSelect(ids);
        },
    });

    return (
        <div className="flex flex-column">
            <Input
                value={query}
                placeholder={placeholder}
                onChange={e => {
                    const newValue = e?.target?.value;

                    setQuery(newValue);
                }}
                wrapperClassName="form-group has-feedback"
            />
            {!loading && formattedData.length === 0 && (
                <EmptyState
                    title={
                        <FormattedMessage
                            id="search.empty.properties.highlight"
                            defaultMessage="Sorry, no properties match your search"
                        />
                    }
                />
            )}
            <BorderedList
                highlights
                loading={loading}
                name="properties"
                hasMore={!finished}
                selectField={selectField}
                // @ts-ignore
                options={formattedData || []}
                className="h-full c-contact-picker__list"
                onPositionChange={args => {
                    const isScrolled = args?.currentPosition === 'inside';

                    if (isScrolled && !finished) {
                        setPage(prevPage => prevPage + 1);
                    }
                }}
            />
        </div>
    );
};

export default React.memo(PropertyPicker);

function useEstates({ page, query }: { page?: number; query?: string }) {
    const officeId = useOffice().get('id');

    const atlasSearch = useFeatureFlag('properties-atlas-search');
    const showInternalTypeFilter = useFeatureFlag('property.new.internalType.enabled');
    const showPropertySharedFilter = useFeatureFlag('property.shared.enabled');

    const allEstatesQueryResult = useQuery(GET_OFFICE_ESTATES_QUERY, {
        variables: {
            query,
            filters: {
                officeId,
                archived: false,
                ...(showInternalTypeFilter
                    ? {
                          property_internal_type: ['standalone', 'unit'],
                      }
                    : {}),
                ...(showPropertySharedFilter
                    ? {
                          visibility: ['team', 'company'],
                      }
                    : {}),
                ...(query
                    ? {}
                    : {
                          sortOrder: 'desc',
                          sortField: 'updated_at',
                      }),
            },
            limit: 10,
            page,
            useAtlasSearch: atlasSearch,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    });

    return {
        estates: allEstatesQueryResult.data?.estates?.edges,
        loading: allEstatesQueryResult.loading,
    };
}
