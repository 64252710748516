import React, { useMemo } from 'react';
import { Waypoint } from 'react-waypoint';
import { fromJS } from 'immutable';
import { Alert } from 'react-bootstrap';
import { LoadingIndicator } from '@sweepbright/uikit';
import { withRouter } from 'react-router';
import { useLocalStorage } from 'react-use';
import Button from '@sweepbright/uikit/build/esm/button';
import { set as immutableSet } from 'object-path-immutable';
import { FormattedMessage } from 'react-intl-sweepbright';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import Icon from '@/app.components/icons/Icon';
import { AreaUnitsLabel } from '@/app.domains/properties/Area';
import { stringComparator, numberComparator } from '@/app.utils/services/Helpers/sorting';
import ErrorBoundary, { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import StatusForm from '@/app.domains/properties/StatusForm';
import CurrencyAddonForOffice from '@/app.components/forms/CurrencyAddonForOffice';
import Link from '@/app.components/elements/Link';
import EditableCell from '@/app.components/table/EditableCell';
import { Estate } from '@/graphql/generated/types';
import useProjectUnits, { DEFAULT_UNITS_FILTERS } from '@/app.hooks/useProjectUnits';
import DataTable from '@/app.components/table/DataTable/DataTable';
import { LastActivity } from '@/app.shared/properties/LastActivity';
import { adaptProperty } from '@/app.domains/properties/PropertyCard';
import { PROPERTY_UNIT, PROPERTY_UNIT_DETAILS } from '@/app.routing/routes';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import { ArchivePropertyMenuItem, DuplicatePropertyMenuItem } from '@/app.domains/properties/PropertyContextActions';
import ArchivedFilter from '@/app.components/forms/Search/filters/ArchivedFilter';
import { useEstateArchiver } from '@/app.domains/properties/components/ArchiveEstateSection';
import EmptyState from '@/app.components/empty/EmptyState';
import placeholder from '../../../../resources/assets/svg/property-placeholder-small.svg';
import { getLabelsForCountry } from '../../app.data/Translations/Location';
import helpLinks from '../../app.data/helpLinks';
import { PropertyType, propertyTypes } from '../../app.data/Properties';

type UnitsFilters = {
    archived: boolean;
};

const getHeaderForType = type => {
    switch (type) {
        case 'parking':
            return <FormattedMessage id="property.unit.headers.gross" defaultMessage="Gross Area" />;
        case 'office':
            return <FormattedMessage id="property.unit.headers.gross" defaultMessage="Gross Area" />;
        case 'commercial':
            return <FormattedMessage id="property.unit.headers.gross" defaultMessage="Gross Area" />;
        case 'land':
            return <FormattedMessage id="property.unit.headers.area" defaultMessage="Area" />;
        default:
            return <FormattedMessage id="property.unit.headers.rooms" defaultMessage="Bedrooms" />;
    }
};

const getEstateRepositoriesList = (units: Estate[]): EstateRepository[] => {
    return units.map(unit => new EstateRepository(unit));
};

const getFirstUnitRepoWithAddition = (unitRepos: EstateRepository[]): EstateRepository | undefined => {
    return unitRepos.find(repo => repo.hasAddition());
};

const getAttributePathForType = type => {
    switch (type) {
        case 'parking':
            return 'attributes.structure.gross_area.size';
        case 'office':
            return 'attributes.structure.gross_area.size';
        case 'commercial':
            // gross area
            return 'attributes.structure.gross_area.size';
        case 'land':
            // area
            return 'attributes.structure.plot_area.size';
        default:
            // bedrooms
            return 'attributes.structure.bedrooms';
    }
};

const UnitsList = (props: {
    type: PropertyType;
    units: Estate[];
    showAlert?: boolean;
    onUpdateUnit: (unitId: string, attributes: FixMeType) => Promise<void>;
    hasNextPage?: boolean;
    onFetchMore: () => void;
    loading: boolean;
}) => {
    const { type, loading, hasNextPage, units, showAlert, onUpdateUnit } = props;

    if (!loading && !units.length) {
        return (
            <EmptyState
                title={
                    <FormattedMessage
                        id="property.units.empty"
                        defaultMessage="Looks like you don’t have any units yet"
                    />
                }
                helpLink={helpLinks.units}
            />
        );
    }

    // The paddingBottom below is needed to prevent a auto scroll bug.
    // See https://github.com/sweepbright/incoming-cs-issues/issues/949
    return (
        <div className="overflow-y-auto pb-40">
            {units.length ? <UnitsDataTable projectType={type} units={units} onUpdateUnit={onUpdateUnit} /> : null}
            {(loading || hasNextPage) && <LoadingIndicator />}
            {!loading && hasNextPage && <Waypoint onEnter={props.onFetchMore} />}
            {showAlert && <UnitsAlert />}
        </div>
    );
};

export const ProjectUnitsList = ({
    project,
    onUpdateUnit,
}: {
    project: Pick<Estate, 'type' | 'id' | 'units'>;
    onUpdateUnit: (unitId: string, attributes: FixMeType) => Promise<void>;
}) => {
    // This is where the default filter variables for the units query
    // are initially saved in the local storage.
    // Currently, we only have 'archived' filter
    const [filters, setFilters] = useLocalStorage('units.filters', DEFAULT_UNITS_FILTERS);
    const archivedFilterEnabled = useFeatureFlag('filters.archivedUnits.enabled');

    const { units, loading, fetchMore, pageInfo } = useProjectUnits(project.id, { filters });
    const projectType: PropertyType | null = (propertyTypes as readonly string[]).includes(project.type)
        ? (project.type as PropertyType)
        : null;

    return (
        <ErrorBoundary>
            {archivedFilterEnabled && filters && <UnitsFilters filters={filters} onFilterChange={setFilters} />}
            {projectType && (
                <UnitsList
                    loading={loading}
                    type={projectType}
                    units={units}
                    onUpdateUnit={onUpdateUnit}
                    hasNextPage={pageInfo.hasNextPage}
                    onFetchMore={fetchMore}
                />
            )}
        </ErrorBoundary>
    );
};

export default withErrorBoundary(UnitsList);

function UnitsFilters({
    filters,
    onFilterChange,
}: {
    filters: UnitsFilters;
    onFilterChange: (val: UnitsFilters) => void;
}) {
    return (
        <div className="c-search__filters" data-testid="units-filters">
            <ArchivedFilter
                title={<FormattedMessage id="filters.labels.archived_units" defaultMessage="Archived Units" />}
                placeholder={<FormattedMessage id="filters.labels.archived_units" defaultMessage="Archived Units" />}
                values={filters}
                defaultValues={DEFAULT_UNITS_FILTERS}
                onChange={newFilterValue => {
                    onFilterChange(newFilterValue);
                }}
            />
        </div>
    );
}

function UnitsAlert() {
    return (
        <Alert bsStyle={undefined}>
            <FormattedMessage
                id="property.unit.alert"
                defaultMessage="To help you add units more quickly, we will copy the data from the project level. {link}"
                tagName="p"
                values={{
                    link: (
                        <Button
                            variant="link"
                            href="http://get.sweepbright.help/properties/faq/how-to-set-up-a-project-web"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <FormattedMessage
                                id="property.unit.alert.help_link"
                                defaultMessage="Learn more about Projects and Units"
                            />
                        </Button>
                    ),
                }}
            />
        </Alert>
    );
}

function renderLastActivity(id: string, { unit }) {
    return (
        <Link
            to={PROPERTY_UNIT_DETAILS(unit.projectId, unit.id)}
            className="px-1 w-40 truncate no-underline text-gray-dark block w-full"
        >
            <LastActivity property={adaptProperty(unit)} />
        </Link>
    );
}

function _UnitsDataTable({
    projectType,
    units,
    onUpdateUnit,
}: {
    projectType: PropertyType;
    units: Estate[];
    onUpdateUnit: (unitId: string, attributes: FixMeType) => Promise<void>;
}) {
    const labels = getLabelsForCountry(units?.[0]?.attributes.location.country);
    const hasFloors = new EstateRepository({ type: projectType }).hasFloors();
    const typeLabel = getHeaderForType(projectType);
    const typePath = getAttributePathForType(projectType);

    const reposList = useMemo(() => getEstateRepositoriesList(units), [units]);
    const repoWithAddition = useMemo(() => getFirstUnitRepoWithAddition(reposList), [reposList]);
    const hasAddition = !!repoWithAddition;
    const additionLabel = repoWithAddition ? getLabelsForCountry(repoWithAddition.getCountry()).addition : undefined;

    const dataSource = useMemo(
        () =>
            reposList.map((repository, i) => {
                return {
                    key: repository.getId(),
                    id: repository.getId(),
                    floor: repository.getFloor(),
                    box: repository.getUnit(),
                    addition: repository.getAddition(),
                    // the formatted price, ready to be displayed
                    price: repository.getPrice({ type: 'published_price', fallback: '-' }),
                    // the actual numeric price, so it can be sorted
                    priceAmount: repository.hasPrice('published_price')
                        ? repository.getPriceAmount('published_price')
                        : '',
                    bedrooms: repository.getNumBedrooms(),
                    size: repository.getLotSize(),
                    status: repository.getStatus(),
                    negotiation: repository.getNegotiation(),
                    // we past the unit as well
                    unit: units[i],
                    rowProps: {
                        'data-testid': `unit-row-${repository.getId()}`,
                    },
                };
            }),
        [reposList, units],
    );

    const columns = React.useMemo(
        () =>
            [
                {
                    key: 'image',
                    dataIndex: 'id',
                    className: 'table-shrink-column',
                    render: imageRender,
                    valid: true,
                },
                {
                    key: 'box',
                    title: labels.box,
                    dataIndex: 'box',
                    valid: true,
                    sorter: stringComparator,
                    className: 'table-shrink-column',
                    // eslint-disable-next-line react/display-name
                    render: (value, { unit }) => (
                        <EditableCell
                            className="w-5"
                            value={value}
                            label={labels.box}
                            onSubmit={getCellSubmitHandler(unit, 'attributes.location.box', onUpdateUnit)}
                            testId="box-field"
                        >
                            {value || <span className="text-muted">-</span>}
                        </EditableCell>
                    ),
                },
                {
                    key: 'floor',
                    title: labels.floor,
                    dataIndex: 'floor',
                    valid: hasFloors,
                    className: 'table-shrink-column',
                    sorter: stringComparator,
                    // eslint-disable-next-line react/display-name
                    render: (value, { unit }) => (
                        <EditableCell
                            className="w-10"
                            value={value}
                            label={labels.floor}
                            onSubmit={getCellSubmitHandler(unit, 'attributes.location.floor', onUpdateUnit)}
                            pattern="\d*"
                            testId="floor-field"
                        >
                            {value || <span className="text-muted">-</span>}
                        </EditableCell>
                    ),
                },
                {
                    key: 'addition',
                    title: additionLabel,
                    dataIndex: 'addition',
                    valid: hasAddition,
                    className: 'table-shrink-column',
                    sorter: stringComparator,
                    // eslint-disable-next-line react/display-name
                    render: (value, { unit }) => (
                        <EditableCell
                            className="w-10"
                            value={value}
                            label={additionLabel}
                            onSubmit={getCellSubmitHandler(unit, 'attributes.location.addition', onUpdateUnit)}
                            pattern=".*"
                            testId="addition-field"
                        >
                            {value || <span className="text-muted">-</span>}
                        </EditableCell>
                    ),
                },
                {
                    key: 'price',
                    title: <FormattedMessage id="property.unit.headers.price" defaultMessage="Price" />,
                    dataIndex: 'priceAmount',
                    valid: true,
                    sorter: numberComparator,
                    // eslint-disable-next-line react/display-name
                    render: (priceAmount, { price, unit }) => {
                        return (
                            <EditableCell
                                className="w-full"
                                value={priceAmount}
                                label={<FormattedMessage id="property.unit.headers.price" defaultMessage="Price" />}
                                type="price"
                                addonAfter={<CurrencyAddonForOffice />}
                                testId="price-field"
                                onSubmit={getCellSubmitHandler(
                                    unit,
                                    'attributes.price.published_price.amount',
                                    onUpdateUnit,
                                )}
                            >
                                {price}
                            </EditableCell>
                        );
                    },
                },
                {
                    key: 'type',
                    title: typeLabel,
                    dataIndex: 'size',
                    // eslint-disable-next-line react/display-name
                    render: (size, { unit }) => {
                        const sizeUnit = size.get('units');

                        return (
                            <EditableCell
                                value={size.get('size')}
                                className="w-full truncate"
                                label={typeLabel}
                                onSubmit={getCellSubmitHandler(unit, typePath, onUpdateUnit)}
                                testId="size-field"
                                addonAfter={<AreaUnitsLabel units={sizeUnit} />}
                            >
                                {!size.get('size') ? (
                                    '-'
                                ) : (
                                    <span>
                                        {size.get('size').toFixed(2)}
                                        <AreaUnitsLabel units={sizeUnit} />
                                    </span>
                                )}
                            </EditableCell>
                        );
                    },
                    sorter: (a, b) => a.get('size', 0) - b.get('size', 0),
                    valid: ['parking', 'office', 'commercial', 'land'].includes(projectType),
                },
                {
                    key: 'type',
                    title: typeLabel,
                    valid: !['parking', 'office', 'commercial', 'land'].includes(projectType),
                    dataIndex: 'bedrooms',
                    sorter: numberComparator,
                    // eslint-disable-next-line react/display-name
                    render: (value, { unit }) => {
                        return (
                            <EditableCell
                                label={typeLabel}
                                value={value}
                                onSubmit={({ field }) => {
                                    field = field ? parseFloat(field) : 0;
                                    getCellSubmitHandler(unit, typePath, onUpdateUnit)({ field });
                                }}
                            >
                                {value}
                            </EditableCell>
                        );
                    },
                },
                {
                    key: 'status',
                    title: <FormattedMessage id="property.unit.headers.status" defaultMessage="Status" />,
                    valid: true,
                    dataIndex: 'status',
                    // eslint-disable-next-line react/display-name
                    render: (status, { id, unit }) => {
                        return (
                            <div className="py-0 px-2">
                                <StatusForm propertyId={id} negotiation={unit.negotiation} />
                            </div>
                        );
                    },
                    sorter: stringComparator,
                },
                {
                    key: 'last_activity',
                    title: <FormattedMessage id="property.unit.headers.last_activity" defaultMessage="Last Activity" />,
                    valid: true,
                    dataIndex: 'id',
                    // eslint-disable-next-line react/display-name
                    render: renderLastActivity,
                },
                {
                    key: 'actions',
                    title: <FormattedMessage id="property.unit.headers.actions" defaultMessage="Actions" />,
                    valid: true,
                    className: 'table-actions-column',
                    dataIndex: 'unit',
                    // eslint-disable-next-line react/display-name
                    render: renderActionsMenu,
                },
            ].filter(column => column.valid),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <DataTable
            columns={columns}
            dataSource={dataSource}
            className="c-data-table--condensed"
            data-testid="units-table"
        />
    );
}

const UnitsDataTable = withRouter(_UnitsDataTable);

function getCellSubmitHandler(unit, path, onUpdateUnit) {
    return ({ field }) => {
        const updatedUnit = immutableSet(unit, path, field);

        return onUpdateUnit(unit.id, { updatedUnit, ...updatedUnit.attributes });
    };
}

function imageRender(id: string, { unit }: { unit: Estate }) {
    const repository = new EstateRepository(fromJS(unit));
    const image: string = repository.getImageUrlWithPreset('thumbnail') || ((placeholder as unknown) as string);

    return (
        <Link
            className="p-2 -ml-2 flex items-center justify-center"
            to={PROPERTY_UNIT_DETAILS(unit.projectId!, unit.id)}
        >
            <img src={image} className="w-12 h-10 rounded-sm object-cover" />
        </Link>
    );
}

function UnitActionsMenu(props: { unit: Estate }) {
    const estateId = props.unit.id;
    const { isArchived, toggleArchived, loading } = useEstateArchiver({ estateId });

    return (
        <div className="px-2">
            <Dropdown id="status_dropdown" data-testid="property-status-dropdown">
                <Dropdown.Toggle
                    as="button"
                    className="bg-transparent cursor-default flex items-center focus:outline-none focus:shadow-outline rounded"
                    type="button"
                >
                    <Icon icon="options" size={24} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="pull-right">
                    <Dropdown.MenuItem as={Link} to={PROPERTY_UNIT(props.unit.projectId!, estateId)}>
                        <FormattedMessage id="property.unit.view" defaultMessage="View" />
                    </Dropdown.MenuItem>
                    <DuplicatePropertyMenuItem propertyId={estateId} isArchived={isArchived} />
                    <ArchivePropertyMenuItem
                        isArchived={isArchived}
                        toggleArchived={toggleArchived}
                        disabled={loading}
                    />
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

function renderActionsMenu(unit: Estate) {
    return <UnitActionsMenu unit={unit} />;
}
