import { createReducerWithSanitizer } from 'redux-reducer-factory';
import { LOGOUT } from '@/app.redux/actions/AuthActions';
import {
    SET_OFFICE_MEMBERS,
    SET_OFFICES,
    RESET_OFFICES,
    SET_OFFICE,
    REMOVE_OFFICE_MEMBER,
    SET_OFFICE_MEMBER,
    RESET_OFFICE_MEMBERS,
} from '../actions';
import handleWithSubreducer from './Helpers/handleWithSubreducer';
import officeReducer from './Subreducers/OfficeReducer';
import sanitize from './Helpers/sanitizeOrderedSet';
import concatSetWith from './Helpers/concatSetWith';

export default createReducerWithSanitizer([], sanitize, {
    [LOGOUT]: () => sanitize([]),
    [SET_OFFICES]: (offices, action) => concatSetWith(action.offices)(offices),
    [[SET_OFFICE_MEMBERS, SET_OFFICE, REMOVE_OFFICE_MEMBER, SET_OFFICE_MEMBER, RESET_OFFICE_MEMBERS]]: (
        offices,
        action,
    ) => {
        return handleWithSubreducer(
            officeReducer,
            offices,
            action,
            action.officeId || (action.office && action.office.id),
        );
    },
    [RESET_OFFICES]: () => sanitize([]),
});
