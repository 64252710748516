type BuilderInfo = {
    type: string;
    propertyId: string;
    preset?: string;
    fileId?: string;
    dataUrl?: string;
    presetGroups?: string;
};

export interface FileUrlBuilder {
    isValidBuilder(attr: BuilderInfo): boolean;

    buildUrl(attr: BuilderInfo): string | null;
}

export const DataBase64UrlBuilder: FileUrlBuilder = {
    isValidBuilder({ dataUrl }) {
        return Boolean(dataUrl);
    },
    buildUrl({ dataUrl }) {
        return dataUrl!;
    },
};

export const NullUrlBuilder: FileUrlBuilder = {
    isValidBuilder() {
        return true;
    },
    buildUrl() {
        return null;
    },
};

export const APIUrlBuilder: FileUrlBuilder = {
    isValidBuilder({ fileId }): boolean {
        return Boolean(fileId);
    },

    buildUrl({ preset, fileId, propertyId, type }: BuilderInfo): string | null {
        let url = `estates/${propertyId}/${type}/${fileId}`;

        if (preset) {
            url = `${url}?p=${preset}`;
        }

        return `${API_PROTOCOL}://${API_URL}/${url}`;
    },
};

export const CDNUrlBuilder = {
    isValidBuilder({ type, fileId, presetGroups }): boolean {
        if (!STATIC_ASSETS_CDN_URL) {
            return false;
        }

        if (!fileId) {
            return false;
        }

        if (presetGroups) {
            return true;
        }

        if (type !== 'images') {
            return false;
        }

        return true;
    },
    buildUrl({ preset = 'original', presetGroups = 'properties', fileId }: BuilderInfo): string | null {
        return `${STATIC_ASSETS_CDN_URL}/${presetGroups}/presets/${preset}/${fileId}`;
    },
};

// builders order by priority
const builders = [DataBase64UrlBuilder, CDNUrlBuilder, APIUrlBuilder, NullUrlBuilder];
export default builders;
