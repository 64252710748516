// @ts-nocheck
import { fromJS, Map } from 'immutable';
import moment from 'moment';
import { createSelector } from 'reselect';
import ContactRepository from '@/app.utils/services/Repositories/ContactRepository';
import { getCompany } from './CompaniesSelector';
import { withArchived } from './SearchSelectors';
import { getUser } from './UsersSelectors';
import { getOffice } from './OfficesSelector';

type Contact = Map<string, any>;

type State = {
    contacts: {
        allIds: string[];
        byId: Record<string, Contact>;
    };
};

export const getContacts = (state: State) => {
    const contacts = state.contacts.allIds.reduce((acc, contactId) => {
        acc.push(state.contacts.byId[contactId]);

        return acc;
    }, [] as Contact[]);

    return fromJS(contacts);
};

export const getContact = (state: State, props: { params: { contact: string } }) =>
    fromJS(state.contacts.byId[props.params.contact]) || Map();

export const getContactById = (contactId: string) => (state: State) => fromJS(state.contacts.byId[contactId]);

export const getVendorEstates = createSelector(getContact, (contact?: Map<string, any>) => {
    if (contact) {
        return contact.get('estates', Map());
    }

    return fromJS([]);
});

export const getSearchedContacts = withArchived('contacts', getContacts);

export const getVendors = createSelector(getContacts, contacts =>
    contacts.filter(contact => contact.get('type') === 'vendor'),
);

function getVisitEmailTemplate(office: Map<string, any>, contact: Map<string, any>, company: Map<string, any>) {
    const lang = new ContactRepository(contact, company).getLanguage();

    return office.getIn(
        ['settings', 'data', `email_lead_visit_${lang}`],
        office.getIn(['settings', 'data', 'email_lead_visit'], ''),
    );
}

export const getScheduleVisitInitialValues = (contactId: string) =>
    createSelector(
        getContactById(contactId),
        getUser,
        getCompany,
        getOffice,
        (contact: Map<string, any>, user?: Map<string, any>, company?: Map<string, any>, office?: Map<string, any>) => {
            const initialValues = {
                date: moment().format('YYYY-MM-DD'),
                hour: parseInt(
                    moment()
                        .add(1, 'hour')
                        .format('HH'),
                    10,
                ),
                minutes: 0,
                message: '',
            };

            if (user && office && company) {
                initialValues.message = getVisitEmailTemplate(office, contact, company);
            }

            return initialValues;
        },
    );
