import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import { FilterChip } from '@sweepbright/uikit/build/esm/filterchip';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import Input from '../../Input/Input';
type ArchivedFilter = {
    archived: boolean;
};

type Props = {
    title: React.ReactNode;
    placeholder?: React.ReactNode;
    defaultValues?: ArchivedFilter;
    values: ArchivedFilter;
    onChange: (val: ArchivedFilter) => void;
};

export default function ArchivedFilter({
    title,
    defaultValues = { archived: false },
    values,
    onChange,
    placeholder = <FormattedMessage id="filters.labels.more" defaultMessage="More" />,
}: Props) {
    return (
        <FilterChip
            size="small"
            placeholder={placeholder}
            onClear={() => {
                onChange(defaultValues);
            }}
            value={
                values.archived ? (
                    <FormattedMessage id="modals.properties_search.archived_pane.title" defaultMessage="Archived" />
                ) : (
                    ''
                )
            }
            popupHeader={null}
            testId="archive-filter-chip"
            renderPopupContent={({ closePopup }) => {
                return (
                    <Form
                        title={title}
                        onSubmit={newValues => {
                            onChange(newValues);
                            closePopup();
                        }}
                        initialValues={values}
                    />
                );
            }}
        />
    );
}

const Form = ({
    title,
    initialValues,
    onSubmit,
}: {
    title: React.ReactNode;
    initialValues: ArchivedFilter;
    onSubmit(values: ArchivedFilter);
}) => {
    const [archived, setArchived] = React.useState<boolean>(initialValues.archived);

    return (
        <form
            onSubmit={evt => {
                evt.preventDefault();
                onSubmit({ archived });
            }}
            className="w-64 resource-filter-form"
        >
            <FormPanel
                title={title}
                popup
                action={
                    <Button variant="link" type="submit">
                        <FormattedMessage id="buttons.apply" defaultMessage="Apply" />
                    </Button>
                }
            >
                <Input
                    checked={archived}
                    type="checkbox"
                    onChange={() => setArchived(archived => !archived)}
                    label={
                        <FormattedMessage
                            id="modals.properties_search.archived_pane.archived_label"
                            defaultMessage="Show Archived"
                        />
                    }
                />
            </FormPanel>
        </form>
    );
};
