import React from 'react';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';
import { Icon } from '@/app.components';

type Props = {
    record: OfferCardFragmentFragment;
    isClosed: boolean;
};

const OfferCardIcon: React.FC<Props> = props => {
    const { record, isClosed } = props;

    const badgeColor = {
        PENDING: '#17A8E6',
        ACCEPTED: '#47C9AD',
        REFUSED: '#EE4B41',
        CANCELLED: '#EE4B41',
        COUNTER: '#EE4B41',
    };

    const badgeIcon = {
        PENDING: 'circle-question',
        ACCEPTED: 'circle-success',
        REFUSED: 'circle-canceled',
        CANCELLED: 'circle-canceled',
        COUNTER: 'circle-reverse',
    };

    const isCounter = Boolean(record.status === 'PENDING' && record.parentId);

    return (
        <div className="offer-card-icon">
            <div>
                <Icon
                    size={16}
                    className="offer-card-icon__badge"
                    // @ts-ignore
                    icon={badgeIcon[isCounter ? 'COUNTER' : record.status]}
                    style={{ color: isClosed ? '#8E8E93' : badgeColor[isCounter ? 'COUNTER' : record.status] }}
                />
                <span className="offer-card-icon__background" />
            </div>
            <Icon
                size={21}
                style={
                    isClosed
                        ? {
                              color: '#8E8E93',
                          }
                        : {}
                }
                icon={record.status === 'ACCEPTED' ? 'offers' : 'price'}
            />
        </div>
    );
};

export default OfferCardIcon;
