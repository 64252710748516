/* eslint-disable react/no-children-prop */
import React from 'react';
import { Formik, FormikProps } from 'formik';
import set from 'lodash/set';
import get from 'lodash/get';

const getChangeFunc = (fieldName: string, setFieldValue) => evt => {
    if (evt && evt.target) {
        switch (evt.target.type) {
            case 'checkbox': {
                setFieldValue(fieldName, evt.target.checked);
                break;
            }
            default: {
                setFieldValue(fieldName, evt.target.value);
                break;
            }
        }

        return;
    }
    // assuming the new value was passed directly,
    // as can be the case with redux-form
    setFieldValue(fieldName, evt || '');
};

function getFields(fieldNames: string[], { values, errors, touched, setFieldValue, setFieldTouched }) {
    return fieldNames.reduce((fields, fieldName) => {
        const changeFunc = getChangeFunc(fieldName, setFieldValue);

        set(fields, fieldName, {
            name: fieldName,
            value: get(values, fieldName, ''),
            checked: get(values, fieldName, ''),
            onChange: changeFunc,
            onUpdate: changeFunc,
            touched: get(touched, fieldName),
            onBlur: () => setFieldTouched(fieldName, true),
            error: get(errors, fieldName),
        });

        return fields;
    }, {});
}

type Props = {
    children?: (attributes: Record<string, any>) => React.ReactNode;
    component?: (attributes: Record<string, any>) => React.ReactNode;
    fieldNames: string[];
    onSubmit: (attributes: Record<string, any>) => void;
    render?: (attributes: Record<string, any>) => React.ReactNode;
    initialValues: Record<string, any>;
};

type RenderProp = (props: FormikProps<any>) => React.ReactNode;

function addFields(renderProp, fieldNames): RenderProp {
    if (renderProp) {
        return (formikBag: FormikProps<any>) => {
            const fields = getFields(fieldNames, formikBag);

            return renderProp({ ...formikBag, fields });
        };
    }

    return () => null;
}
/// this little component enhances the Formik component by adding an extra
// parameter to the *formikBag* that exposes a `fields` object
// this behaves almost the same as the redux-form counterpart
// so it is easy to migrate, an we still get the performance benefits of Formik
const FormikWithFields = ({ fieldNames, children, render, component, ...props }: Props) => {
    return (
        <Formik
            {...props}
            render={render && addFields(render, fieldNames)}
            component={component && addFields(component, fieldNames)}
            children={children && addFields(children, fieldNames)}
        />
    );
};

export default FormikWithFields;
