import React from 'react';
import Tabs from '@sweepbright/uikit/build/esm/tabs';
import { FormattedMessage } from 'react-intl-sweepbright';
import { useToasts } from '@sweepbright/notifications';
import PagePane from '@/app.layouts/PagePane/PagePane';
import RegisterActivityAction from '@/app.components/activity/RegisterActivityAction/RegisterActivityAction';
import ErrorBoundary, { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import RegisterActivityModal from '@/app.components/modals/RegisterPropertyActivityModal';
import { events, track } from '@/app.utils/analytics';
import { ActivityType } from '@/app.domains/properties/timeline/types';
import PropertyTimeline from '../../../../app.domains/properties/timeline/components/PropertyTimeline';
import PropertyInteractedContactList from '../../../../app.domains/properties/timeline/components/PropertyInteractedContactList';
import PropertyInteractedVendorList from '../../../../app.domains/properties/timeline/components/PropertyInteractedVendorList';

function PropertyTimelinePage({ params, location }) {
    const propertyId = params.unit ?? params.property;
    const projectId = params.unit ? params.property : undefined;

    const [visibleRegistrationModalType, setVisibleRegistrationModalType] = React.useState('');
    const [activeTabIndex, setActiveTabIndex] = React.useState(location?.state?.fromLeadTimeline ? 1 : 0);

    function handleRegisterActivity(type: ActivityType) {
        track(events.PROPERTY_ACTIVITY_REGISTER_BTN_CLICKED, { activityType: type });
        setVisibleRegistrationModalType(type);
    }

    const { addSuccess } = useToasts();
    const timelineRef = React.useRef();
    const leadsListRef = React.useRef();
    const vendorsListRef = React.useRef();

    const handleActivityRegistered = () => {
        /* eslint-disable no-unused-expressions */
        //@ts-ignore
        timelineRef.current?.refresh();
        //@ts-ignore
        leadsListRef.current?.refresh();
        //@ts-ignore
        vendorsListRef.current?.refresh();
        /* eslint-enable no-unused-expressions */

        addSuccess({
            title: (
                <FormattedMessage
                    id={`timeline.activity_registered_success.title.${visibleRegistrationModalType}`}
                    defaultMessage={`timeline.activity_registered_success.title.${visibleRegistrationModalType}`}
                />
            ),
            message: (
                <FormattedMessage
                    id="timeline.activity_registered_success.confirmation"
                    defaultMessage="The new activity will show up in the timeline"
                />
            ),
        });

        setVisibleRegistrationModalType('');
    };

    return (
        <PagePane
            title={<FormattedMessage id="details.section.activity" defaultMessage="Activity" />}
            actions={[<RegisterActivityAction key="action" onRegisterActivity={handleRegisterActivity} />]}
        >
            <RegisterActivityModal
                show={!!visibleRegistrationModalType}
                propertyId={propertyId}
                onClose={() => setVisibleRegistrationModalType('')}
                type={visibleRegistrationModalType}
                onActivityRegistered={handleActivityRegistered}
            />
            <Tabs
                defaultIndex={activeTabIndex}
                onChange={(selectedIndex: number) => {
                    if (selectedIndex === 0) track(events.PROPERTY_ACTIVITY_TIMELINE_TAB_CLICKED);
                    if (selectedIndex === 1) track(events.PROPERTY_ACTIVITY_LEADS_TAB_CLICKED);
                    if (selectedIndex === 2) track(events.PROPERTY_ACTIVITY_VENDORS_TAB_CLICKED);
                    setActiveTabIndex(selectedIndex);
                }}
            >
                <Tabs.TabList>
                    <Tabs.Tab key="main">
                        <FormattedMessage id="pages.activity.tab_labels.timeline" defaultMessage="Timeline" />
                    </Tabs.Tab>
                    <Tabs.Tab key="leads">
                        <FormattedMessage id="pages.activity.tab_labels.leads" defaultMessage="Leads" />
                    </Tabs.Tab>
                    <Tabs.Tab key="vendors">
                        <FormattedMessage id="pages.activity.tab_labels.vendors" defaultMessage="Vendors" />
                    </Tabs.Tab>
                </Tabs.TabList>
                <Tabs.TabPanels>
                    <Tabs.TabPanel key="main">
                        <ErrorBoundary>
                            {activeTabIndex === 0 && <PropertyTimeline propertyId={propertyId} ref={timelineRef} />}
                        </ErrorBoundary>
                    </Tabs.TabPanel>
                    <Tabs.TabPanel key="leads">
                        <ErrorBoundary>
                            {activeTabIndex === 1 && (
                                <PropertyInteractedContactList
                                    type="lead"
                                    projectId={projectId}
                                    propertyId={propertyId}
                                    ref={leadsListRef}
                                />
                            )}
                        </ErrorBoundary>
                    </Tabs.TabPanel>
                    <Tabs.TabPanel key="vendors">
                        <ErrorBoundary>
                            {activeTabIndex === 2 && (
                                <PropertyInteractedVendorList
                                    propertyId={propertyId}
                                    projectId={projectId}
                                    ref={vendorsListRef}
                                />
                            )}
                        </ErrorBoundary>
                    </Tabs.TabPanel>
                </Tabs.TabPanels>
            </Tabs>
        </PagePane>
    );
}

export default withErrorBoundary(PropertyTimelinePage);
