import { zip } from 'lodash';
import moment from 'moment';

type WeekDay = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
type WeekDayTuple = [WeekDay, string];

/**
 * Returns week days with localization.
 * WARNING: the fist day of week is returned with respect of the locale
 */
export const getWeekDays = (): WeekDayTuple[] => {
    const weekdays: WeekDay[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const localeData = moment.localeData();

    const localizedDays = localeData.weekdays();
    const daysWithLocalizedNames = zip(weekdays, localizedDays) as [WeekDay, string][];

    const firstDow = localeData.firstDayOfWeek();

    //Shift weekdays if the locale's first day of week is not Sunday
    for (let i = 0; i < firstDow; i++) {
        daysWithLocalizedNames.push(daysWithLocalizedNames.shift() as WeekDayTuple);
    }

    return daysWithLocalizedNames;
};

export const getWeekDaysMap = (): { [weekDay in WeekDay]: string } => {
    return Object.fromEntries(getWeekDays());
};
