import React from 'react';
import { formatPrice } from '@/app.utils/services/Helpers/priceFormatting';

const Price = ({
    amount,
    currency = 'EUR',
    precision,
    suffix,
}: {
    amount: number;
    currency?: string;
    precision?: number;
    suffix?: string;
}) => {
    if (amount != null) {
        const value = formatPrice({ amount, currency }, { precision });

        return (
            <span>
                {value}
                {suffix}
            </span>
        );
    }

    return <span>-</span>;
};

export default Price;
