import gql from 'graphql-tag';

export const ASSIGN_LEGAL_ENTITY = gql`
    mutation AssignLegalEntity($input: AssignLegalEntityToEstateInput!) {
        assignLegalEntity(input: $input) {
            estate {
                id
            }
        }
    }
`;
