import AbstractArchivableRequest from './AbstractArchivableRequest';

export default class AbstractEstatesRequests extends AbstractArchivableRequest {
    patch = (propertyKey: string, operations: any[]) => {
        return super.patch(`${this.resource}/${propertyKey}`, operations);
    };

    assignVendor = (estateId: string, vendorId: string) => {
        return this.put(`estates/${estateId}/assigned-vendors/${vendorId}`);
    };

    unassignVendor = (estateId: string, vendorId: string) => {
        return this.delete(`estates/${estateId}/assigned-vendors/${vendorId}`);
    };

    assignBuyer = (estateId: string, buyerId: string) => {
        return this.put(`estates/${estateId}/buyers/${buyerId}`);
    };

    unassignBuyer = (estateId: string, buyerId: string) => {
        return this.delete(`estates/${estateId}/buyers/${buyerId}`);
    };

    assignLegalEntity = (estateId: string, legalEntityId: string) => {
        return this.put(`estates/${estateId}/assign-legal-entity/${legalEntityId}`);
    };

    unassignLegalEntity = (estateId: string) => {
        return this.put(`estates/${estateId}/unassign-legal-entity`);
    };

    updatePropertyVisibility = (propertyId: string, visibility: string) => {
        return this.post(`properties/${propertyId}/visibility`, { visibility });
    };
}
