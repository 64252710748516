import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';

export default class Stats extends Component {
    static propTypes = {
        currentCount: PropTypes.number.isRequired,
        maxAllowed: PropTypes.number.isRequired,
    };

    render() {
        const { currentCount, maxAllowed } = this.props;

        return (
            <div className="c-stats">
                <div className="c-stats-stat">
                    <div className="c-stats-stat-value">{currentCount}</div>
                    <div className="c-stats-stat-name">
                        <FormattedMessage
                            id="navigation.subscription.stats"
                            defaultMessage="of {maxAllowed} users"
                            values={{ maxAllowed }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
