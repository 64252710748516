import classNames from 'classnames';
import React from 'react';
import ListChecker from '@sweepbright/uikit/build/esm/listchecker';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { Waypoint } from 'react-waypoint';

const BorderedList: React.FunctionComponent<{
    className?: string;
    limitHeight?: 'md' | 'lg';
    name: string;
    loading: boolean;
    highlights?: boolean;
    hasMore?: boolean;
    multiple?: boolean;
    onFetchMore?: () => void;
    onPositionChange?: (args?: any) => void;
    selectField: any;
    options: Array<{ option: React.ReactNode; value: string }>;
}> = ({
    className,
    limitHeight = 'md',
    name,
    selectField,
    options,
    loading,
    highlights,
    multiple,
    hasMore,
    ...props
}) => {
    return (
        <ListChecker
            multi={multiple && selectField}
            className={classNames(
                'bc-bordered-list',
                'overflow-auto',
                {
                    'c-util-height-md': limitHeight === 'md',
                    'c-util-height-lg': limitHeight === 'lg',
                    'c-list-checker--with-highlights': highlights,
                },
                className,
            )}
        >
            {options.map(({ option, value }) => {
                if (!selectField) {
                    return <li key={value}>{option}</li>;
                }

                const checked = selectField.value.includes(value);
                const id = `${name}_${value}`;

                return (
                    <li key={value}>
                        <input
                            type={multiple ? 'checkbox' : 'radio'}
                            name={name}
                            id={id}
                            onChange={selectField.onChange}
                            value={value}
                            checked={checked}
                        />
                        <label htmlFor={id}>{option}</label>
                    </li>
                );
            })}
            {loading && <LoadingIndicator />}
            {hasMore && !loading && <Waypoint onEnter={props.onFetchMore} onPositionChange={props.onPositionChange} />}
        </ListChecker>
    );
};

export default React.memo(BorderedList);
