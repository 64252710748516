// @ts-nocheck
import React from 'react';
import { fromJS } from 'immutable';
import { compose } from 'recompose';
import { load } from 'exifreader';
import Loadable from 'react-loadable';
import { FormattedMessage } from 'react-intl-sweepbright';
import EmptyAlert from '@/app.components/empty/EmptyAlert';
import { FileData } from '@/requests/SweepbrightCrudRequest';
import { useToasts } from '@sweepbright/notifications';
import Resumable from '@/app.components/forms/Resumable';
import { HasModalProps } from '@/app.utils/Decorators/withModals';
import withFileUpload, { ResumableFile, WithFileUploadProps } from '@/app.utils/Decorators/withFormikFileUpload';
import { Alert } from '@/app.components/elements/Alerts/Alerts';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import FileRequestModal from '@/app.components/modals/FileRequestModal';

const ImagesFolder = Loadable({
    loader: () => import('@/app.components/forms/ImagesFolder'),
    loading: () => null,
});

type PropertyImageData = FileData & { ordinal: number };

type ImageGridProps = {
    fields: {
        images: Array<{ value: PropertyImageData; onChange: (image: PropertyImageData) => void }>;
    };
    disabled?: boolean;
    propertyId: string;
    setSuccessMessage: (args: any) => void;
    createBucket: (args: any) => void;
    setDisabled?: (disabled: boolean) => void;
    markFileAsViewed?: (fileId: string) => void;
    handleRemoveFile: (id: string) => void;
    handleRemoveFiles: (ids: string[]) => void;
    isSaving: boolean;
    errors?: {
        public?: string;
        private?: string;
    };
    allowExternalRequests: boolean;
};

const ImageGrids: React.FunctionComponent<ImageGridProps & HasModalProps & WithFileUploadProps> = props => {
    const { fields, propertyId, appendNewUploads, errors, allowExternalRequests = true, disabled } = props;
    const { addSuccess } = useToasts();
    const resumableInput = React.useRef<HTMLInputElement | null>(null);
    const resumableRef = React.useRef<{ addFile: (file: File) => void } | null>(null);

    const handleEditImage = (attributes: PropertyImageData) => {
        const imageField = fields.images.find(img => img.value.id === attributes.id)!;
        imageField.onChange(attributes);
    };

    const commonProps = {
        images: fields.images,
        onEditImage: handleEditImage,
        canDrag: !props.isSaving,
        propertyId: propertyId,
        markFileAsViewed: props.markFileAsViewed,
    };

    const actions = [
        allowExternalRequests && {
            label: <FormattedMessage id="forms.actions.images.request" defaultMessage="Request Via Email" />,
            handler: () => props.open('image_request'),
        },
        allowExternalRequests && { divider: true },
        {
            label: <FormattedMessage id="forms.actions.images.upload" defaultMessage="Upload..." />,
            handler: () => resumableInput.current!.open(),
        },
    ].filter(Boolean);

    const requestModalProps = {
        propertyId,
        title: <FormattedMessage id="property.images.request.title" defaultMessage="Ask photographer" />,
        type: 'image',
        description: (
            <FormattedMessage
                id="property.images.request.body"
                defaultMessage="This person will receive a link where they can upload images. You will be able to review the images within this property."
            />
        ),
        action: <FormattedMessage id="property.images.request.cta" defaultMessage="Request Images" />,
        contentSetting: 'email_property_images_request',
    };

    const handleDeleteImages = (ids: string[]) => {
        props.handleRemoveFiles(ids);
    };

    return (
        <div>
            <Resumable
                ref={(ref: HTMLInputElement) => (resumableInput.current = ref)}
                resumableRef={ref => (resumableRef.current = ref)}
                url={`/estates/${propertyId}/images`}
                maxFiles={50}
                maxFileSize={500 * 1024 * 1024}
                onFilesAdded={addImages}
                fileTypes={['image/png', 'image/jpeg', 'image/gif']}
                onProgress={props.handleFileProgress}
                onSuccess={props.handleUploadSuccess}
                onFileError={props.handleFileError}
                onError={props.handleUploadFailed}
                defaultVisibility="public"
                preview
                simultaneousUploads={3}
            />
            <ImagesFolder
                key="public"
                {...commonProps}
                disabled={disabled}
                action={<FormattedMessage id="property.images.upload.cta" defaultMessage="Add Images" />}
                onAction={actions}
                placeholder={
                    errors?.public ? (
                        <Alert bsStyle="danger">{errors?.public}</Alert>
                    ) : (
                        <EmptyAlert
                            body={<FormattedMessage id="property.images.empty" defaultMessage="No images yet" />}
                        />
                    )
                }
                onChangePrivacy={images => handleChangeImagePrivacy('private', images)}
                onDeleteImages={handleDeleteImages}
                error={errors?.public}
            />
            <ImagesFolder
                key="private"
                disabled={disabled}
                {...commonProps}
                isPrivate
                placeholder={
                    <EmptyAlert body={<FormattedMessage id="property.images.empty" defaultMessage="No images yet" />} />
                }
                onChangePrivacy={images => handleChangeImagePrivacy('public', images)}
                onDeleteImages={handleDeleteImages}
                error={errors?.private}
            />
            {props.modalWithPromise(
                'image_request',
                <FileRequestModal {...requestModalProps} />,
                handleSubmitRequest,
                {},
                handleSubmittedRequest,
            )}
        </div>
    );

    function handleChangeImagePrivacy(privacy: string, selectedImagesIds) {
        let lastOrdinal = 0;
        const isPrivate = privacy === 'private';

        for (let image of fields.images) {
            if (image.value.private === isPrivate) {
                if (image.value.ordinal > lastOrdinal) {
                    lastOrdinal = image.value.ordinal;
                }
            }
        }

        selectedImagesIds.forEach((imageId: string) => {
            const selectedImage = fields.images.find(img => img.value.id === imageId);
            if (selectedImage) {
                selectedImage!.onChange({
                    ...selectedImage.value,
                    private: isPrivate,
                    ordinal: ++lastOrdinal,
                });
            }
        });
    }

    function handleSubmitRequest(attributes) {
        props.createBucket({
            ...attributes,
            file_type: 'image',
            estate_id: propertyId,
        });
    }

    function handleSubmittedRequest() {
        addSuccess({
            title: <FormattedMessage id="property.images.request.success.title" defaultMessage="Request Sent" />,
            message: (
                <FormattedMessage
                    id="property.images.request.success.body"
                    defaultMessage="You will be notified when the images are uploaded."
                />
            ),
        });
    }

    async function addImages(files: ResumableFile[]) {
        // eslint-disable-next-line no-unused-expressions
        props.setDisabled?.(true);

        // we decide here the new ordinal number for a file
        // we either append the file a the end
        // or set it at the beginning.
        let lastOrdinal = appendNewUploads
            ? Math.max(0, ...Array.from(fields.images, image => image.value.ordinal))
            : 0;

        for (let file of files) {
            const equirectangular = await isImageEquirectangular(file.file);

            props.handleAddFile(file, {
                equirectangular: equirectangular,
                ordinal: ++lastOrdinal,
                progress: 0,
                private: false,
            });
        }
    }
};

export default compose(withErrorBoundary, withFileUpload('images'))(ImageGrids);

// image reader helpers
function isImageEquirectangular(file: File) {
    // Parse exif data from image.
    return new Promise(resolve => {
        const exifReader = new FileReader();
        exifReader.onload = event => {
            try {
                const exif = fromJS(load(event.target.result));
                const equirectangular = exif.getIn(['ProjectionType', 'value']) === 'equirectangular';
                resolve(equirectangular);
            } catch (error) {
                resolve(false);
            }
        };
        // https://github.com/mattiasw/ExifReader/blob/master/examples/html/main.js#L37
        exifReader.readAsArrayBuffer(file.slice(0, 128 * 1024));
    });
}
