import React from 'react';
import { compose } from 'recompose';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useLocalStorage } from 'react-use';
import { createStructuredSelector } from 'reselect';
import Button from '@sweepbright/uikit/build/esm/button';
import useOffice from '@/app.hooks/useOffice';
import Resource from '@/shared/resource';
import { ResourceContext } from '@/shared/resource/utils';
import EmptyState from '@/app.components/empty/EmptyState';
import { withModals } from '@/app.utils/Decorators';
import AddOfficeForm from '@/app.components/forms/AddOfficeForm';
import { addCompanyOffice, fetchCompanyOffices } from '@/app.redux/actions';
import { getUser } from '@/app.redux/selectors';
import useUser from '@/app.hooks/useUser';
import { isCompanyAdmin } from '@/app.data';
import { useTeams } from '../hooks/useTeams';
import { useTeamsColumns } from '../hooks/useTeamsColumns';

import { TeamsSearchType } from '../types';

const TeamsList = props => {
    const intl = useIntl();

    const user = useUser();
    const office = useOffice();
    const officeId = office.get('id');

    const [searchSettings, setSearchSettings] = useLocalStorage<TeamsSearchType>('teams.filters', {
        query: '',
    });

    const { loading, error, teams, totalCount, hasNextPage, handleFetchMore } = useTeams(searchSettings);

    const columns = useTeamsColumns(searchSettings);

    const isAdmin = isCompanyAdmin(user);

    const content = {
        title: intl.formatMessage({ id: 'teams.title' }),
        noEntities: intl.formatMessage({ id: 'teams.empty' }),
        errorTitle: intl.formatMessage({ id: 'teams.error.title' }),
        errorSubtitle: intl.formatMessage({ id: 'teams.error.subtitle' }),
        emptyTitle: intl.formatMessage({ id: 'teams.empty.title' }),
        count: intl.formatMessage({ id: 'teams.count' }, { count: totalCount }),
        add: intl.formatMessage({ id: 'navigation.add-office' }),
    };

    const tabs = [
        {
            label: '',
            id: 'teams',
            filters: <></>,
            onTabSelected: () => {},
        },
    ];

    const getEmptyState = () => {
        return error ? (
            <EmptyState title={content.errorTitle} body={content.errorSubtitle} />
        ) : (
            <EmptyState title={content.emptyTitle} />
        );
    };

    return (
        <ResourceContext.Provider
            value={{
                tabs,
                error,
                columns,
                loading,
                content,
                officeId,
                totalCount,
                hasNextPage,
                getEmptyState,
                searchSettings,
                handleFetchMore,
                entities: teams,
                setSearchSettings,
                initialTab: tabs[0].id,
                hasEntities: totalCount > 0,
                action: (
                    <Button
                        variant="primary"
                        className="pull-right"
                        onClick={() => {
                            props.open('add-team');
                        }}
                    >
                        {content.add}
                    </Button>
                ),
                tableWrapperHeight: 'calc(100vh - 214px)',
            }}
        >
            <>
                <Resource />

                {isAdmin && <>{props.modalWithPromise('add-team', <AddOfficeForm />, props.addCompanyOffice)}</>}
            </>
        </ResourceContext.Provider>
    );
};

const mapActionsToProps = {
    addCompanyOffice,
    fetchCompanyOffices,
};
const mapStateToProps = createStructuredSelector({
    user: getUser,
});

export default compose(withRouter, withModals, connect(mapStateToProps, mapActionsToProps))(TeamsList);
