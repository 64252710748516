import React from 'react';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl-sweepbright';
import { SideMenu, SideMenuLink } from '@/app.components';
import * as Routes from '@/app.routing/routes';
import { track, events } from '@/app.utils/analytics';

const PropertyDetailsSideMenu = React.memo(function PropertyDetailsSideMenu(props) {
    const { property, linkProps, paths, router } = props;
    const isSale = property.negotiation === 'sale';
    const isProject = property.isProject;

    return (
        <SideMenu color="gray-lightest">
            <SideMenuLink
                {...linkProps}
                to={paths.LOCATION}
                label={
                    <FormattedMessage id="pages.properties.navigation.side_menu.location" defaultMessage="Location" />
                }
                icon="location"
                onClick={() => track(events.PROPERTY_DETAILS_LOCATION_SIDE_MENU_CLICKED)}
            />
            <SideMenuLink
                {...linkProps}
                to={paths.ROOMS}
                label={
                    <FormattedMessage id="pages.properties.navigation.side_menu.structure" defaultMessage="Structure" />
                }
                hidden={isProject}
                icon="measure"
                onClick={() => track(events.PROPERTY_DETAILS_STRUCTURE_SIDE_MENU_CLICKED)}
            />
            <SideMenuLink
                {...linkProps}
                to={paths.FEATURES}
                label={
                    <FormattedMessage id="pages.properties.navigation.side_menu.features" defaultMessage="Features" />
                }
                icon="features"
                onClick={() => track(events.PROPERTY_DETAILS_FEATURES_SIDE_MENU_CLICKED)}
            />
            <SideMenuLink
                {...linkProps}
                hidden={isProject}
                to={paths.PRICE}
                label={
                    isSale ? (
                        <FormattedMessage
                            id="pages.properties.navigation.side_menu.price_sale"
                            defaultMessage="Price"
                        />
                    ) : (
                        <FormattedMessage id="pages.properties.navigation.side_menu.price_rent" defaultMessage="Rent" />
                    )
                }
                icon="price"
                onClick={() => track(events.PROPERTY_DETAILS_PRICE_SIDE_MENU_CLICKED)}
            />
            <SideMenuLink
                {...linkProps}
                active={router.isActive(Routes.REQUESTS(paths.LEGAL), false)}
                to={paths.LEGAL}
                label={<FormattedMessage id="pages.properties.navigation.side_menu.legal" defaultMessage="Legal" />}
                icon="paperclip"
                onClick={() => track(events.PROPERTY_DETAILS_LEGAL_SIDE_MENU_CLICKED)}
            />
            <SideMenuLink
                {...linkProps}
                active={router.isActive(Routes.REQUESTS(paths.IMAGES), false)}
                to={paths.IMAGES}
                label={<FormattedMessage id="pages.properties.navigation.side_menu.images" defaultMessage="Images" />}
                icon="images"
                onClick={() => track(events.PROPERTY_DETAILS_IMAGES_SIDE_MENU_CLICKED)}
            />
            <SideMenuLink
                {...linkProps}
                to={paths.DESCRIPTION}
                label={
                    <FormattedMessage
                        id="pages.properties.navigation.side_menu.description"
                        defaultMessage="Description"
                    />
                }
                icon="description"
                onClick={() => track(events.PROPERTY_DETAILS_DESCRIPTION_SIDE_MENU_CLICKED)}
            />
            <SideMenuLink
                {...linkProps}
                active={router.isActive(Routes.REQUESTS(paths.PLANS), false)}
                to={paths.PLANS}
                label={
                    <FormattedMessage id="pages.properties.navigation.side_menu.plans" defaultMessage="Docs & Plans" />
                }
                icon="rooms"
                onClick={() => track(events.PROPERTY_DETAILS_PLANS_SIDE_MENU_CLICKED)}
            />
            <SideMenuLink
                {...linkProps}
                to={paths.SETTINGS}
                label={
                    <FormattedMessage id="pages.properties.navigation.side_menu.settings" defaultMessage="Settings" />
                }
                icon="settings"
                onClick={() => track(events.PROPERTY_DETAILS_SETTINGS_SIDE_MENU_CLICKED)}
            />
        </SideMenu>
    );
});

export default withRouter(PropertyDetailsSideMenu);
