import React, { FC, useMemo } from 'react';
import EstateRepository from '../Repositories/EstateRepository';
import { rulesMatcher } from './rulesMatcher';

const usePropertyFieldVisibility = (property: EstateRepository, path: string): boolean => {
    const ctx = useMemo(() => property.getVisibilityContext(), [property]);
    const visible = useMemo(() => Boolean(rulesMatcher.getSchema(ctx, path)), [ctx, path]);
    const exists = useMemo(() => Boolean(rulesMatcher.pathExists(path)), [path]);
    if (!exists) {
        throw new Error(`usePropertyFieldVisibility: Unknown path: ${path}`);
    }

    return visible;
};

export const usePropertyFieldsVisibility = (property: EstateRepository, paths: string[]): string[] => {
    const ctx = useMemo(() => property.getVisibilityContext(), [property]);

    const visiblePaths = useMemo(
        () =>
            paths
                .map(path => {
                    const exists = rulesMatcher.pathExists(path);
                    if (!exists) {
                        throw new Error(`usePropertyFieldsVisibility: Unknown path: ${path}`);
                    }

                    return [path, Boolean(rulesMatcher.getSchema(ctx, path))] as const;
                })
                .filter(([, visible]) => visible)
                .map(([path]) => path),

        [ctx, paths],
    );

    return visiblePaths;
};

export const usePropertyFieldsVisibilityByPrefix = (property: EstateRepository, prefix: string): string[] => {
    const ctx = useMemo(() => property.getVisibilityContext(), [property]);

    return Object.keys(rulesMatcher.getSchemasByPathPrefix(ctx, prefix));
};

export const PropertyFieldVisibility: FC<{ property: EstateRepository; path: string }> = ({
    path,
    property,
    children,
}) => {
    const visible = usePropertyFieldVisibility(property, path);
    if (visible) {
        return <>{children}</>;
    }

    return null;
};
