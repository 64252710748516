import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import produce from 'immer';
import { GetContactTimelineQuery, GetContactTimelineQueryVariables } from '@/graphql/generated/types';
import { GET_CONTACT_TIMELINE_QUERY } from '@/graphql/queries/contacts/getContactTimeline';
import { getBugsnagClient } from '@/app.config/bugsnag';

export function useTimelineData({ contactId, propertyId }) {
    const { loading, data, fetchMore } = useQuery<GetContactTimelineQuery, GetContactTimelineQueryVariables>(
        GET_CONTACT_TIMELINE_QUERY,
        {
            variables: {
                id: contactId,
                propertyId: propertyId,
            },
            errorPolicy: 'ignore',
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network',
            onError(error) {
                getBugsnagClient().notify(error);
            },
        },
    );

    // eslint-disable-next-line no-use-before-define
    const activities = data?.contact?.timeline.activities.nodes ?? [];

    // eslint-disable-next-line no-use-before-define
    const pageInfo = data?.contact?.timeline.activities.pageInfo ?? {
        hasNextPage: false,
        endCursor: null,
    };

    const handleFetchAfter = React.useCallback(
        function handleScroll() {
            if (!loading) {
                fetchMore({
                    variables: {
                        after: pageInfo.endCursor,
                    },
                    updateQuery: (
                        prev: GetContactTimelineQuery,
                        {
                            fetchMoreResult,
                        }: {
                            fetchMoreResult: GetContactTimelineQuery;
                        },
                    ) => {
                        if (!fetchMoreResult) return prev;

                        return produce(prev, draft => {
                            draft.contact!.timeline.activities.pageInfo.endCursor = fetchMoreResult.contact!.timeline.activities.pageInfo.endCursor;
                            draft.contact!.timeline.activities.pageInfo.hasNextPage = fetchMoreResult.contact!.timeline.activities.pageInfo.hasNextPage;

                            for (let newNode of fetchMoreResult.contact!.timeline.activities.nodes) {
                                draft.contact!.timeline.activities.nodes.push(newNode);
                            }
                        });
                    },
                });
            }
        },
        [loading, pageInfo],
    );

    const handleFetchBefore = React.useCallback(
        function handleFetchBefore() {
            fetchMore({
                variables: {
                    before: pageInfo.startCursor,
                },
                updateQuery: (
                    prev: GetContactTimelineQuery,
                    {
                        fetchMoreResult,
                    }: {
                        fetchMoreResult: GetContactTimelineQuery;
                    },
                ) => {
                    if (!fetchMoreResult) return prev;

                    return produce(prev, draft => {
                        draft.contact!.timeline.activities.pageInfo.startCursor = fetchMoreResult.contact!.timeline.activities.pageInfo.startCursor;
                        for (let i = fetchMoreResult.contact!.timeline.activities.nodes.length - 1; i >= 0; i--) {
                            const node = fetchMoreResult.contact!.timeline.activities.nodes[i];
                            draft.contact!.timeline.activities.nodes.unshift(node);
                        }
                    });
                },
            });
        },
        [pageInfo, loading],
    );

    return {
        pageInfo,
        loading,
        handleFetchAfter,
        handleFetchBefore,
        activities,
    };
}
