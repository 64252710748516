/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import Link from '@/app.components/elements/Link';
import { FormattedMessage } from 'react-intl-sweepbright';
import useTableColumns from '@/app.hooks/useTableColumns';
import { CompanyTeam } from '@/graphql/generated/types';
import { TEAM } from '@/app.routing';
import { TeamsSearchType } from '../types';

export const useTeamsColumns = (_searchSettings: TeamsSearchType | undefined) => {
    const columnsById = useMemo(() => {
        return {
            name: {
                key: 'name',
                visible: true,
                dataIndex: 'id',
                className: 'whitespace-no-wrap',
                render: (_id: any, team: CompanyTeam) => {
                    return (
                        <Link to={TEAM(team.id)} className="no-underline text-gray-dark block">
                            {team.name}
                        </Link>
                    );
                },
                title: <FormattedMessage id="teams_table.headers.name" defaultMessage="Name" />,
            },
            currency: {
                key: 'currency',
                visible: true,
                dataIndex: 'currency',
                className: 'whitespace-no-wrap',
                render: (_id: any, team: CompanyTeam) => {
                    return team?.settings?.data?.currency;
                },
                title: <FormattedMessage id="teams_table.headers.currency" defaultMessage="Currency" />,
            },
            measurement_system: {
                key: 'measurement_system',
                visible: true,
                dataIndex: 'measurement_system',
                className: 'whitespace-no-wrap',
                render: (_id: any, team: CompanyTeam) => {
                    const value = team?.settings?.data?.measurement_system;

                    return <FormattedMessage id={`forms.labels.${value}`} />;
                },
                title: (
                    <FormattedMessage
                        id="teams_table.headers.measurement_system"
                        defaultMessage="System of Measurement"
                    />
                ),
            },
            numeral_system: {
                key: 'numeral_system',
                visible: true,
                dataIndex: 'numeral_system',
                className: 'whitespace-no-wrap',
                render: (_id: any, team: CompanyTeam) => {
                    const value = team?.settings?.data?.numeral_system;

                    if (!value) {
                        return <span className="text-muted">-</span>;
                    }

                    return <FormattedMessage id={`forms.labels.${value}`} defaultMessage="Decimal Comma (1.000,00)" />;
                },
                title: <FormattedMessage id="teams_table.headers.numeral_system" defaultMessage="Numeral system" />,
            },
        };
    }, []);

    const initRecord: [string, boolean][] = React.useMemo(
        () => [
            ['currency', true],
            ['measurement_system', true],
            ['numeral_system', true],
        ],
        [],
    );

    const { columns } = useTableColumns({
        memoryKey: 'teams.columns',
        initRecord,
        columnsExtended: columnsById,
        renderMenu: () => null,
        firstColumnId: 'name',
        columnsKeyPrefix: 'teams_table.headers',
    });

    return columns;
};
