import { useMutation } from '@apollo/react-hooks';
import {
    InviteCompanyAdminsMutation,
    InviteCompanyAdminsMutationVariables,
    InviteOfficeUsersMutation,
    InviteOfficeUsersMutationVariables,
} from '@/graphql/generated/types';
import { INVITE_COMPANY_ADMINS, INVITE_OFFICE_USERS } from '../../graphql/mutations/users/inviteUsers';

export const useInviteOfficeUsers = (variables: InviteOfficeUsersMutationVariables) => {
    return useMutation<InviteOfficeUsersMutation, InviteOfficeUsersMutationVariables>(INVITE_OFFICE_USERS, {
        variables,
    });
};

export const useInviteCompanyAdmins = (variables: InviteCompanyAdminsMutationVariables) => {
    return useMutation<InviteCompanyAdminsMutation, InviteCompanyAdminsMutationVariables>(INVITE_COMPANY_ADMINS, {
        variables,
    });
};
