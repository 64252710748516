import React from 'react';
import classnames from 'classnames';
import './Toolbar.scss';

type ElementProps = {
    className?: string;
    style?: React.CSSProperties;
    elementStyle?: React.CSSProperties;
};

const Element: React.FunctionComponent<ElementProps> = ({ children, className, style }) => (
    <div className={classnames('bc-toolbar-element', className)} style={style}>
        {children}
    </div>
);

const ToolbarSide: React.FunctionComponent<ElementProps> = ({ children, className, style, elementStyle }) => {
    return (
        <div className={className} style={style}>
            {React.Children.map(
                children,
                (child, index) =>
                    child && (
                        <Element style={elementStyle} key={index}>
                            {child}
                        </Element>
                    ),
            )}
        </div>
    );
};

const Left: React.FunctionComponent<ElementProps> = ({ children, className, style, elementStyle }) => (
    <ToolbarSide className={classnames('bc-toolbar-left', className)} style={style} elementStyle={elementStyle}>
        {children}
    </ToolbarSide>
);

const Right: React.FunctionComponent<ElementProps> = ({ children, className, style, elementStyle }) => (
    <ToolbarSide className={classnames('bc-toolbar-right', className)} style={style} elementStyle={elementStyle}>
        {children}
    </ToolbarSide>
);

interface ToolbarComponents {
    Left: typeof Left;
    Right: typeof Right;
    Element: typeof Element;
}

const Toolbar: React.FunctionComponent<{ inPage?; size?: string; className?: string }> & ToolbarComponents = ({
    children,
    inPage,
    size,
    className,
}) => {
    const classes = classnames(
        'bc-toolbar',
        {
            [`bc-toolbar-${size}`]: size,
        },
        className,
    );

    const toolbar = <div className={classes}>{children}</div>;
    if (!inPage) {
        return toolbar;
    }

    return <div className="bc-in-page-toolbar">{toolbar}</div>;
};

export default Toolbar;

Toolbar.Left = Left;
Toolbar.Right = Right;
Toolbar.Element = Element;

Toolbar.defaultProps = {
    inPage: true,
};
