import gql from 'graphql-tag';

export const GET_USER = gql`
    query GetUser($id: ID!) {
        user(id: $id) {
            id
            firstName
            lastName
        }
    }
`;
