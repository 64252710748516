import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { Map } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl-sweepbright';
import { FormattedLocation } from '@/app.components/localize/FormattedLocation';
import useCompany from '@/app.hooks/useCompany';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import { EstateRepository } from '../../../app.utils/services';
import { toTitle } from '../../../app.utils/services/String';
import TransactionPreviewRow from './Row';
import ContactRow from './ContactRow';

const Zapier = ({ estate, intl }) => {
    const company = useCompany();
    const repository = new EstateRepository(estate, company);

    const propertyNegotiation = repository.getNegotiation();
    const propertyStatus = repository.getStatus();
    const propertySubType = repository.getSubType();

    const content = {
        status: intl.formatMessage({ id: `property.status.${propertyNegotiation}.${propertyStatus}` }),
        subtype: intl.formatMessage({ id: `property.type.${propertySubType}` }),
    };

    return (
        <FormPanel>
            <Table>
                <thead>
                    <tr className="py-2">
                        <FormattedMessage
                            id="estate.transaction_preview.titles.title"
                            defaultMessage="Title"
                            tagName="th"
                        />
                    </tr>
                </thead>
                <tbody>
                    <TransactionPreviewRow value={repository.getComposedTitle(intl)} />
                </tbody>
            </Table>
            <Table>
                <thead>
                    <tr>
                        <th colSpan={2} className="py-2">
                            <FormattedMessage
                                id="estate.transaction_preview.titles.transaction"
                                defaultMessage="Transaction"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <TransactionPreviewRow
                        label={
                            <FormattedMessage
                                id="estate.transaction_preview.labels.transaction_type"
                                defaultMessage="Transaction Type"
                            />
                        }
                        value={
                            repository.isSale() ? (
                                <FormattedMessage id="estate.transaction_type.sale" defaultMessage="For Sale" />
                            ) : (
                                <FormattedMessage id="estate.transaction_type.let" defaultMessage="For Rent" />
                            )
                        }
                    />
                    <TransactionPreviewRow
                        label={
                            <FormattedMessage id="estate.transaction_preview.labels.status" defaultMessage="Status" />
                        }
                        value={toTitle(content.status)}
                    />
                    <TransactionPreviewRow
                        label={
                            repository.isSale() ? (
                                <FormattedMessage
                                    id="estate.transaction_preview.labels.asking_price"
                                    defaultMessage="Asking Price"
                                />
                            ) : (
                                <FormattedMessage
                                    id="estate.transaction_preview.labels.requested_rent"
                                    defaultMessage="Requested Rent"
                                />
                            )
                        }
                        value={
                            repository.hasPrice('published_price')
                                ? repository.getPrice({ type: 'published_price', intl })
                                : null
                        }
                    />
                    <TransactionPreviewRow
                        label={
                            repository.isSale() ? (
                                <FormattedMessage
                                    id="estate.transaction_preview.labels.negotiated_price"
                                    defaultMessage="Negotiated Price"
                                />
                            ) : (
                                <FormattedMessage
                                    id="estate.transaction_preview.labels.negotiated_rent"
                                    defaultMessage="Negotiated Rent"
                                />
                            )
                        }
                        value={
                            repository.hasPrice('current_price')
                                ? repository.getPrice({ intl, type: 'current_price' })
                                : null
                        }
                    />
                    <TransactionPreviewRow
                        label={
                            repository.isSale() ? (
                                <FormattedMessage id="estate.transaction_preview.labels.buyer" defaultMessage="Buyer" />
                            ) : (
                                <FormattedMessage
                                    id="estate.transaction_preview.labels.tenant"
                                    defaultMessage="Tenant"
                                />
                            )
                        }
                        value={
                            repository.hasBuyers()
                                ? repository
                                      .getBuyers()
                                      .map(buyer => <ContactRow contact={buyer} key={buyer.get('id')} />)
                                : null
                        }
                    />
                    <TransactionPreviewRow
                        label={
                            <FormattedMessage id="estate.transaction_preview.labels.vendor" defaultMessage="Vendor" />
                        }
                        value={
                            repository.hasVendors()
                                ? repository
                                      .getVendors()
                                      .map(vendor => <ContactRow contact={vendor} key={vendor.get('id')} />)
                                : null
                        }
                    />
                    <TransactionPreviewRow
                        label={
                            <FormattedMessage id="estate.transaction_preview.labels.mandate" defaultMessage="Mandate" />
                        }
                        value={repository.getMandate()}
                    />
                </tbody>
            </Table>
            <Table>
                <thead>
                    <tr>
                        <th colSpan={2} className="py-2">
                            <FormattedMessage
                                id="estate.transaction_preview.titles.property"
                                defaultMessage="Property"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <TransactionPreviewRow
                        label={
                            <FormattedMessage
                                id="estate.transaction_preview.labels.property_type"
                                defaultMessage="Property Type"
                            />
                        }
                        value={toTitle(content.subtype)}
                    />
                    {!repository.isType('land') && (
                        <TransactionPreviewRow
                            label={
                                <FormattedMessage
                                    id="estate.transaction_preview.labels.year_buit"
                                    defaultMessage="Year Built"
                                />
                            }
                            value={estate.getIn(['attributes', 'building', 'construction', 'year'])}
                        />
                    )}
                    <TransactionPreviewRow
                        label={
                            <FormattedMessage
                                id="estate.transaction_preview.labels.location"
                                defaultMessage="Location"
                            />
                        }
                        value={
                            repository.hasLocation() ? (
                                <FormattedLocation location={estate.getIn(['attributes', 'location'])?.toJS()} />
                            ) : null
                        }
                    />
                    <TransactionPreviewRow
                        label={
                            <FormattedMessage
                                id="estate.transaction_preview.labels.description"
                                defaultMessage="Description"
                            />
                        }
                        value={estate.getIn(['attributes', 'description', 'description'])}
                    />
                </tbody>
            </Table>
        </FormPanel>
    );
};

Zapier.propTypes = {
    estate: PropTypes.instanceOf(Map).isRequired,
    intl: PropTypes.object,
};

export default injectIntl(Zapier);
