import React from 'react';

type Props = {
    children: JSX.Element | JSX.Element[];
};

const PaletteList: React.FC<Props> = props => {
    const { children } = props;

    return <div className="palette-list">{children}</div>;
};

export default PaletteList;
