import React from 'react';
import { fromJS } from 'immutable';
import { InteractionFeedback } from '../../../../app.domains/contacts/InteractionFeedback';

export default function FeedbackDetails({ details }: { details: any }) {
    const interaction = fromJS(adaptFeedback(details));

    return <InteractionFeedback interaction={interaction} />;
}

function adaptFeedback(feedbackDetails) {
    return {
        feedback_type: feedbackDetails.type,
        feedback_external_comment: feedbackDetails.externalComment,
        feedback_internal_comment: feedbackDetails.internalComment,
    };
}
