import React from 'react';
import { Alert } from 'react-bootstrap';
import Button from '@sweepbright/uikit/build/esm/button';
import { useIntl } from 'react-intl';
import { CompaniesRequests } from '@/requests';
import { FormattedMessage } from 'react-intl-sweepbright';
import MoveBotlerLegalTermsModal from '@/app.components/modals/MoveBotlerLegalTermsModal';
import { localizedHelpLinks } from '@/app.data/helpLinks';

export default function MoveBottlerDisconnected({ onConnected, companyId }) {
    const intl = useIntl();
    const [showLegalTermsModal, setShowLegalTermsModal] = React.useState(false);
    const [connecting, setConnecting] = React.useState(false);
    function handleConnect() {
        setShowLegalTermsModal(false);
        setConnecting(true);
        new CompaniesRequests()
            .connectMoveBotler(companyId)
            .then(config => {
                onConnected(config);
            })
            .finally(() => {
                setConnecting(false);
            });
    }

    return (
        <>
            <Alert bsStyle="info">
                <p>
                    <FormattedMessage
                        id="pages.movebolter.info_alter_text"
                        defaultMessage="Our integration with MoveBotler helps to keep your buyers &amp; tenants engaged. By activating the MoveBotler
                        you give your buyers &amp; tenants the option to get some help with their moving process. They&rsquo;ll get timely
                        personal messages, so they can arrange their move step by step."
                    />
                </p>
                <br />
                <p>
                    <FormattedMessage
                        id="channels.movebotler.info_link"
                        defaultMessage="Learn more about the MoveBotler <a>here</a>"
                        values={{
                            // eslint-disable-next-line react/display-name
                            a: chunk => {
                                const link = intl.formatMessage(localizedHelpLinks.moveBotler);

                                return (
                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                        {chunk}
                                    </a>
                                );
                            },
                        }}
                    />
                </p>
            </Alert>
            <Button
                variant="primary"
                onClick={() => {
                    setShowLegalTermsModal(true);
                }}
                disabled={connecting}
            >
                {connecting ? (
                    <FormattedMessage
                        id="channels.movebotler.state.activating"
                        defaultMessage="Activating MoveBotler..."
                    />
                ) : (
                    <FormattedMessage id="channels.movebotler.actions.activate" defaultMessage="Activate MoveBotler" />
                )}
            </Button>
            <MoveBotlerLegalTermsModal
                show={showLegalTermsModal}
                onCancel={() => setShowLegalTermsModal(false)}
                onAccept={handleConnect}
            />
        </>
    );
}
