import { Map } from 'immutable';
import React, { useMemo } from 'react';
import { EstateRepository } from '@/app.utils/services';
import LegalInfoPanel from './LegalInfoPanel';
import OccupationPanel, { useOccupationPanelVisibility } from './OccupationPanel';

const LegalTabContent: React.FunctionComponent<{
    property: Map<string, any>;
}> = ({ property }) => {
    const estateRepo = useMemo(() => new EstateRepository(property), [property]);
    const showOccupation = useOccupationPanelVisibility(estateRepo);

    return (
        <div className="mt-4 space-y-4">
            <LegalInfoPanel property={property} />
            {showOccupation && <OccupationPanel property={property} />}
        </div>
    );
};

export default React.memo(LegalTabContent);
