import { fromJS, List, Map, Set } from 'immutable';
import { createReducerWithSanitizer } from 'redux-reducer-factory';
import get from 'lodash/get';
import {
    ADD_MESSAGE,
    IS_SAVING,
    RECOVER_FROM_ERRORS,
    RESET_MESSAGES,
    RESET_PAGINATION,
    SET_ERRORS,
    SET_MESSAGE,
    SET_PAGINATION,
} from '../actions';

function sanitize(_state) {
    let state = fromJS(_state);

    // Make messages a unique set
    const messages = state.get('messages', new List());
    if (!Set.isSet(messages)) {
        state = state.set('messages', messages.toSet());
    }

    return state;
}

const formatMessage = ({ message, hash, style }) => {
    return typeof message === 'object' ? Map({ style, hash, ...message }) : Map({ style, hash, message });
};

const EMPTY_PAGINATION = Map({
    total: 0,
    current_page: 0,
    total_pages: 0,
});

export default createReducerWithSanitizer(
    {
        errors: [],
        messages: [],
        pagination: {},
        is_saving: false,
    },
    sanitize,
    {
        [RESET_MESSAGES]: status => status.set('messages', Set()),
        [ADD_MESSAGE]: (status, message) => status.update('messages', messages => messages.add(formatMessage(message))),
        [SET_MESSAGE]: (status, message) => status.set('messages', Set([formatMessage(message)])),
        [IS_SAVING]: (status, action) => status.set('is_saving', action.saving),
        [SET_ERRORS]: (status, action) => status.set('is_saving', false).set('errors', fromJS(action.errors)),
        [SET_PAGINATION]: (status, action) => {
            const pagination = get(action, 'entities.meta.pagination')
                ? fromJS(action.entities.meta.pagination)
                : EMPTY_PAGINATION;

            return status.setIn(['pagination', action.resource], pagination);
        },
        [RESET_PAGINATION]: (status, action) => {
            return status.setIn(['pagination', action.resource], Map());
        },
        [RECOVER_FROM_ERRORS]: status => status.set('errors', List()),
        [[RECOVER_FROM_ERRORS, 'redux-form/RESET']]: status => status.set('errors', List()),
    },
);
