// @ts-nocheck
import React from 'react';
import gql from 'graphql-tag';
import { Map, fromJS } from 'immutable';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import classNames from 'classnames';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl-sweepbright';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import { ICON_SIZE_SMALL } from '@/app.components/icons/Icon';
import {
    CONTACT_ACTION_SMS_BTN_CLICKED,
    CONTACT_ACTION_CALL_BTN_CLICKED,
    CONTACT_ACTION_EMAIL_BTN_CLICKED,
} from '@/app.utils/analytics/event-types/contact';
import { track } from '@/app.utils/analytics';
import Status from '@/app.components/elements/Status/Status';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import './ContactActions.scss';

type ActionFlags = {
    was_emailed_for_property?: boolean;
    can_be_mailed_again_for_property?: boolean;
    was_messaged_for_property?: boolean;
    was_called_for_property?: boolean;
};

const ActionButton: React.FunctionComponent<{
    disabled?: boolean;
    variant?: string;
    className?: string;
} & React.HTMLAttributes<HTMLButtonElement>> = React.forwardRef(function ActionButton(
    { disabled, className, ...props },
    forwardedRef,
) {
    return (
        <ButtonIcon
            ref={forwardedRef}
            disabled={disabled}
            tag={disabled ? 'span' : 'a'}
            className={classNames(
                'c-contact-action__button',
                { 'c-contact-action__button--disabled': disabled },
                className,
            )}
            {...props}
        />
    );
});

export const CONTACT_ACTIONS_QUERY = gql`
    query GetInfoForContactActions($contactId: ID!) {
        contact(id: $contactId) {
            id
            phone
            email
            subscribed
        }
    }
`;

function ContactActions({
    contactId,
    onAction,
    flags,
    mailChildren,
    parentContact,
}: {
    contactId: string;
    mailChildren?: JSX.Element;
    onAction?: (type: string, evt: React.MouseEvent<HTMLElement>) => void;
    flags?: ActionFlags;
    parentContact?: any;
}) {
    const { data } = useQuery(CONTACT_ACTIONS_QUERY, {
        variables: { contactId },
        skip: !contactId,
    });
    const contact = parentContact || data?.contact;

    if (contact) {
        const inmutableContact = fromJS(contact);

        return (
            <ErrorBoundary>
                <div
                    className="c-contact-actions__container"
                    onClick={evt => {
                        // prevent the event from bubbling up when used inside a media card
                        evt.preventDefault();
                        evt.stopPropagation();
                    }}
                >
                    <SMSAction contact={inmutableContact} onClick={evt => onAction?.('sms', evt)} />
                    {mailChildren || (
                        <MailAction contact={inmutableContact} onClick={evt => onAction?.('mail', evt)} flags={flags} />
                    )}
                    <CallAction contact={inmutableContact} onClick={evt => onAction?.('call', evt)} />
                </div>
            </ErrorBoundary>
        );
    }

    return null;
}

type ActionButtonProps = {
    contact: Map<string, any>;
    onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
    flags?: ActionFlags;
};

export function SMSAction({ contact, onClick, flags }: ActionButtonProps) {
    const canSendSMS = Boolean(contact.get('phone'));

    const sendSMS = (evt: React.MouseEvent<HTMLButtonElement>) => {
        track(CONTACT_ACTION_SMS_BTN_CLICKED);
        // eslint-disable-next-line no-unused-expressions
        onClick?.(evt);
        if (!evt.defaultPrevented) {
            window.open(`sms:${contact.get('phone')}`, '_self');
        }
    };

    const status = flags?.was_messaged_for_property ? 'checked' : null;

    return (
        <Tooltip
            label={
                canSendSMS ? (
                    <FormattedMessage id="interaction.send_message" defaultMessage="Send message" />
                ) : (
                    <FormattedMessage
                        id="interaction.tooltip.phone_number_missing"
                        defaultMessage="Phone number is missing"
                    />
                )
            }
        >
            <div style={{ position: 'relative' }}>
                <ActionButton
                    variant="link"
                    data-testid="sms_link"
                    onClick={canSendSMS ? sendSMS : undefined}
                    disabled={!canSendSMS}
                    type="speech-bubble"
                    iconSize={ICON_SIZE_SMALL}
                />
                {status && <Status status={status} disabled={!canSendSMS} />}
            </div>
        </Tooltip>
    );
}

export function MailAction({ contact, onClick, flags }: ActionButtonProps) {
    const [canSendEMail, errorMessage] = getMailableStatus();

    const sendEMail = (evt: React.MouseEvent<HTMLButtonElement>) => {
        track(CONTACT_ACTION_EMAIL_BTN_CLICKED);
        // eslint-disable-next-line no-unused-expressions
        onClick?.(evt);
        if (!evt.defaultPrevented) {
            window.open(`mailto:${contact.get('email')}`, '_blank');
        }
    };

    const status = getMailStatus(flags);

    return (
        <Tooltip
            label={
                canSendEMail ? (
                    <FormattedMessage id="interaction.tooltip.send_email" defaultMessage="Send e-mail" />
                ) : (
                    errorMessage
                )
            }
            id="mail_tooltip"
        >
            <div style={{ position: 'relative' }}>
                <ActionButton
                    variant="link"
                    data-testid="mail_link"
                    onClick={canSendEMail ? sendEMail : undefined}
                    disabled={!canSendEMail}
                    type="mail"
                    iconSize={ICON_SIZE_SMALL}
                />
                {status && <Status status={status} disabled={!canSendEMail} />}
            </div>
        </Tooltip>
    );

    function getMailableStatus(): [boolean, string?] {
        if (!contact.get('email')) {
            return [false, 'E-mail address is missing'];
        }

        if (!contact.get('subscribed')) {
            return [false, 'Contact is unsubscribed'];
        }

        return [true];
    }
}

export function CallAction({ contact, onClick, flags }: ActionButtonProps) {
    const canBeCalled = Boolean(contact.get('phone'));

    const makeCall = (evt: React.MouseEvent<HTMLButtonElement>) => {
        track(CONTACT_ACTION_CALL_BTN_CLICKED);
        // eslint-disable-next-line no-unused-expressions
        onClick?.(evt);
        if (!evt.defaultPrevented) {
            window.open(`tel:${contact.get('phone')}`, '_self');
        }
    };

    const status = flags?.was_called_for_property ? 'checked' : null;

    return (
        <Tooltip
            label={
                canBeCalled ? (
                    contact.get('phone')
                ) : (
                    <FormattedMessage
                        id="interaction.tooltip.phone_number_missing"
                        defaultMessage="Phone number is missing"
                    />
                )
            }
            id="call_tooltip"
        >
            <div style={{ position: 'relative' }}>
                <ActionButton
                    variant="link"
                    data-testid="call_link"
                    onClick={canBeCalled ? makeCall : undefined}
                    disabled={!canBeCalled}
                    type="phone"
                    iconSize={ICON_SIZE_SMALL}
                />
                {status && <Status status={status} disabled={!canBeCalled} />}
            </div>
        </Tooltip>
    );
}

export default React.memo(ContactActions);

// status getters
function getMailStatus(flags?: FixMeType) {
    if (flags?.was_emailed_for_property) {
        if (flags?.can_be_mailed_again_for_property) {
            return 'changed';
        }

        return 'checked';
    }

    return null;
}
