// Auth routes
export const LOGIN = '/login';
export const LOGIN_CODE = '/login/:code';
export const LOGIN_SENT = `${LOGIN}/sent`;
export const REGISTER = '/register';
export const AUTHORIZE = '/authorize';

export const REQUESTS = path => `${path}/requests`;

// Profile routes
export const PROFILE = '/profile';
export const PROFILE_EDIT = `${PROFILE}/edit`;
export const PROFILE_CALENDARS = `${PROFILE}/calendars`;

// Company routes
export const COMPANY = '/company';
export const COMPANY_MEMBERS = `${COMPANY}/members`;
export const COMPANY_MEMBER = member => `${COMPANY_MEMBERS}/${member}`;
export const COMPANY_SETTINGS = `${COMPANY}/settings`;
export const COMPANY_LABELS = `${COMPANY}/labels`;

export const CHANNELS = '/channels';
export const CHANNELS_WEBSITE = website => `${CHANNELS}/website/${website}`;
export const CHANNELS_PORTALS = `${CHANNELS}/portals`;
export const CHANNELS_PORTAL = portal => `${CHANNELS_PORTALS}/${portal}`;
export const CHANNELS_PORTAL_CREATE = `${CHANNELS_PORTALS}/create`;

export const INTEGRATIONS_AND_APPS = '/settings';
export const INTEGRATIONS = `${INTEGRATIONS_AND_APPS}/integrations`;
export const MOVEBOTLER = `${INTEGRATIONS_AND_APPS}/movebotler`;
export const INTEGRATION_TYPE = integration => `${INTEGRATIONS}/${integration}`;
export const INTEGRATION = (type, integration) => `${INTEGRATION_TYPE(type)}/${integration}`;

export const OFFICE = office => `/office/${office}`;

export const SUBSCRIPTION = '/subscription';
export const SUBSCRIPTION_UPGRADE = '/subscription/upgrade';
export const SUBSCRIPTION_PLAN = `${SUBSCRIPTION}/overview`;
export const SUBSCRIPTION_PAYMENT = `${SUBSCRIPTION}/payment`;
export const SUBSCRIPTION_INVOICE = `${SUBSCRIPTION}/invoice`;

// LEADS
export const LEADS = '/leads';

// TEAMS
export const TEAMS = '/teams';
export const TEAM = (teamId: string) => `${TEAMS}/${teamId}`;
export const TEAM_EDIT = (teamId: string) => `${TEAMS}/${teamId}/edit`;
export const TEAM_LEGAL_ENTITIES = team => `${TEAM(team)}/legal-entities`;
export const TEAM_LOCATION_TEMPLATES = (teamId: string) => `${TEAM(teamId)}/location-templates`;
export const TEAM_LOCATION_FILTER_TEMPLATES = (teamId: string) => `${TEAM(teamId)}/location-filter-templates`;
export const TEAM_PRESETS = team => `${TEAM(team)}/templates`;
export const TEAM_PRESETS_FOR_PROPERTY = (team, type) => `${TEAM_PRESETS(team)}/properties/${type}`;
export const TEAM_PRESETS_FOR_EMAIL = (team, type) => `${TEAM_PRESETS(team)}/emails/${type}`;

// MEMBERS
export const MEMBERS = '/members';
export const MEMBERS_INVITE = `${MEMBERS}/invite`;
export const MEMBER = member => `${MEMBERS}/${member}`;

// PROPERTIES

export const PROPERTIES = `/properties`;
export const PROPERTY = (propertyId: string) => `${PROPERTIES}/${propertyId}`;

// timeline
export const PROPERTY_TIMELINE = (propertyId: string) => `${PROPERTY(propertyId)}/timeline`;
export const PROPERTY_TIMELINE_CONTACT = (propertyId: string, contactId: string) =>
    `${PROPERTY(propertyId)}/timeline/${contactId}`;

// details
export const PROPERTY_DETAILS = (propertyId: string) => `${PROPERTY(propertyId)}/details`;
export const PROPERTY_DETAILS_UNITS = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/units`;
export const PROPERTY_DETAILS_LOOP = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/loop`;
export const PROPERTY_DETAILS_LOCATION = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/location`;
export const PROPERTY_DETAILS_ROOMS = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/rooms`;
export const PROPERTY_DETAILS_PRICE = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/price`;
export const PROPERTY_DETAILS_DESCRIPTION = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/description`;
export const PROPERTY_DETAILS_IMAGES = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/images`;
export const PROPERTY_DETAILS_DOCUMENTS = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/documents`;
export const PROPERTY_DETAILS_DOCUMENTS_PLANS_TAB = (propertyId: string) =>
    `${PROPERTY_DETAILS_DOCUMENTS(propertyId)}#plans`;
export const PROPERTY_DETAILS_LEGAL = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/legal`;
export const PROPERTY_DETAILS_FEATURES = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/features`;
export const PROPERTY_DETAILS_SETTINGS = (propertyId: string) => `${PROPERTY_DETAILS(propertyId)}/settings`;
export const PROPERTY_DETAILS_UNIT = (propertyId: string, unitId: string) =>
    `${PROPERTY_DETAILS(propertyId)}/units/${unitId}`;
export const PROPERTY_DETAILS_DOCUMENTS_REQUESTS = (propertyId: string) =>
    REQUESTS(PROPERTY_DETAILS_DOCUMENTS(propertyId));
export const PROPERTY_DETAILS_PLANS_REQUESTS = (propertyId: string) =>
    REQUESTS(`${PROPERTY_DETAILS_DOCUMENTS(propertyId)}/plans`);

// publish
export const PROPERTY_PUBLISH = (propertyId: string) => `${PROPERTY(propertyId)}/publish`;

// match

export const PROPERTY_MATCH = (propertyId: string) => `${PROPERTY(propertyId)}/match`;
export const PROPERTY_MATCH_ALL = (propertyId: string) => `${PROPERTY_MATCH(propertyId)}/contacts`;
export const PROPERTY_MATCH_CONTACT = (propertyId: string, contactId: string) =>
    `${PROPERTY_MATCH(propertyId)}/contacts/${contactId}`;

// visit

export const PROPERTY_VISIT = (propertyId: string) => `${PROPERTY(propertyId)}/visit`;

export const PROPERTY_VISIT_CONTACT = (propertyId: string, contactId: string) =>
    `${PROPERTY_VISIT(propertyId)}/contacts/${contactId}`;

// offers
export const PROPERTY_OFFERS = (propertyId: string) => `${PROPERTY(propertyId)}/offers`;
export const PROPERTY_UNIT_OFFERS = (propertyId: string, unitId: string) =>
    `${PROPERTY(propertyId)}/units/${unitId}/offers`;

// schedule
export const PROPERTY_SCHEDULE = (propertyId: string) => `${PROPERTY(propertyId)}/schedule`;

// units
export const PROPERTY_UNITS = (propertyId: string) => `${PROPERTY(propertyId)}/units`;
export const PROPERTY_UNIT = (propertyId: string, unitId: string) => `${PROPERTY_UNITS(propertyId)}/${unitId}`;

export const PROPERTY_UNIT_TIMELINE = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT(propertyId, unitId)}/timeline`;
export const PROPERTY_UNIT_TIMELINE_CONTACT = (propertyId: string, unitId: string, contactId: string) =>
    `${PROPERTY_UNIT(propertyId, unitId)}/timeline/${contactId}`;
export const PROPERTY_UNIT_DETAILS = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT(propertyId, unitId)}/details`;
export const PROPERTY_UNIT_PUBLISH = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT(propertyId, unitId)}/publish`;
export const PROPERTY_UNIT_VISIT = (propertyId: string, unitId: string) => `${PROPERTY_UNIT(propertyId, unitId)}/visit`;
export const PROPERTY_UNIT_MATCH = (propertyId: string, unitId: string) => `${PROPERTY_UNIT(propertyId, unitId)}/match`;

// unit details
export const PROPERTY_UNIT_DETAILS_LOOP = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS(propertyId, unitId)}/loop`;
export const PROPERTY_UNIT_DETAILS_LOCATION = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS(propertyId, unitId)}/location`;
export const PROPERTY_UNIT_DETAILS_ROOMS = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS(propertyId, unitId)}/rooms`;
export const PROPERTY_UNIT_DETAILS_PRICE = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS(propertyId, unitId)}/price`;
export const PROPERTY_UNIT_DETAILS_DESCRIPTION = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS(propertyId, unitId)}/description`;
export const PROPERTY_UNIT_DETAILS_IMAGES = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS(propertyId, unitId)}/images`;
export const PROPERTY_UNIT_DETAILS_DOCUMENTS = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS(propertyId, unitId)}/documents`;
export const PROPERTY_UNIT_DETAILS_DOCUMENTS_PLANS_TAB = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS_DOCUMENTS(propertyId, unitId)}#plans`;
export const PROPERTY_UNIT_DETAILS_LEGAL = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS(propertyId, unitId)}/legal`;
export const PROPERTY_UNIT_DETAILS_FEATURES = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS(propertyId, unitId)}/features`;
export const PROPERTY_UNIT_DETAILS_SETTINGS = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_DETAILS(propertyId, unitId)}/settings`;
export const PROPERTY_UNIT_DETAILS_DOCUMENTS_REQUESTS = (propertyId: string, unitId: string) =>
    REQUESTS(PROPERTY_UNIT_DETAILS_DOCUMENTS(propertyId, unitId));
export const PROPERTY_UNIT_DETAILS_PLANS_REQUESTS = (propertyId: string, unitId: string) =>
    REQUESTS(`${PROPERTY_UNIT_DETAILS_DOCUMENTS(propertyId, unitId)}/plans`);

// unit visit
export const PROPERTY_UNIT_VISIT_CONTACT = (propertyId: string, unitId: string, contactId: string) =>
    `${PROPERTY_UNIT_VISIT(propertyId, unitId)}/contacts/${contactId}`;

// unit schedule
export const PROPERTY_UNIT_SCHEDULE = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT(propertyId, unitId)}/schedule`;

// unit match

export const PROPERTY_UNIT_MATCH_ALL = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT_MATCH(propertyId, unitId)}/contacts`;
export const PROPERTY_UNIT_MATCH_CONTACT = (propertyId: string, unitId: string, contactId: string) =>
    `${PROPERTY_UNIT_MATCH_ALL(propertyId, unitId)}/${contactId}`;

// similar
export const PROPERTY_SIMILAR = (propertyId: string) => `${PROPERTY(propertyId)}/similar`;
export const PROPERTY_UNIT_SIMILAR = (propertyId: string, unitId: string) =>
    `${PROPERTY_UNIT(propertyId, unitId)}/similar`;

// CONTACTS

export const CONTACTS = '/contacts';
// details
export const CONTACT = (contactId: string) => `${CONTACTS}/${contactId}`;
export const CONTACT_TIMELINE = (contactId: string) => `${CONTACT(contactId)}/timeline`;
export const CONTACT_OFFERS = (contactId: string) => `${CONTACT(contactId)}/offers`;
export const CONTACT_TIMELINE_PROPERTY = (contactId: string, propertyId: string) =>
    `${CONTACT_TIMELINE(contactId)}/${propertyId}`;

export const CONTACT_DETAILS = (contactId: string) => `${CONTACT(contactId)}/details`;
export const CONTACT_DETAILS_INFO = (contactId: string) => `${CONTACT_DETAILS(contactId)}/informations`;
export const CONTACT_DETAILS_PROPERTIES = (contactId: string) => `${CONTACT_DETAILS(contactId)}/properties`;
export const CONTACT_PREFERENCES = (contactId: string) => `${CONTACT(contactId)}/preferences`;
export const CONTACT_DETAILS_PREFERENCE = (contactId: string, preferenceId: string) =>
    `${CONTACT_PREFERENCES(contactId)}/${preferenceId}`;
export const CONTACT_DETAILS_INTERACTIONS = (contactId: string) => `${CONTACT_DETAILS(contactId)}/interactions`;
export const CONTACT_DETAILS_INTERACTION_DETAILS = (contactId: string, propertyId: string) =>
    `${CONTACT_DETAILS_INTERACTIONS(contactId)}/${propertyId}`;
export const CONTACT_DETAILS_NOTES = (contactId: string) => `${CONTACT_DETAILS(contactId)}/notes`;
export const CONTACT_DETAILS_SETTINGS = (contactId: string) => `${CONTACT_DETAILS(contactId)}/settings`;
export const CONTACT_REPORT = (contactId: string) => `${CONTACT(contactId)}/report`;
export const CONTACT_SCHEDULE = (contactId: string) => `${CONTACT(contactId)}/schedule`;

// match
export const CONTACT_MATCH = (contactId: string) => `${CONTACT(contactId)}/match`;
export const CONTACT_MATCH_PREFERENCE = (contactId: string, preferenceId: string) =>
    `${CONTACT(contactId)}/match/${preferenceId}`;

// visit
export const CONTACT_VENDOR_PROPERTIES = (contactId: string) => `${CONTACT(contactId)}/properties`;
