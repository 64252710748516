import FormData from 'form-data';
import uuid from 'uuid';
import { extractData } from '@sweepbright/margaret-fetcher';
import { Headers, HeadersInit } from 'node-fetch';
import { AuthProvider, getAuthProvider } from '@sweepbright/webapp-shared/auth';
import { PropertyData } from '@/app.utils/services/PatchBuilders/PropertyPatchBuilder';
import logger from '@/app.utils/services/logger';
import CacheableCrudRequest from './CacheableCrudRequest';

export interface FileData {
    [attribute: string]: string | boolean | number | File;

    id: string;
    deleted: boolean;
    private: boolean;
    filename: string;
    description: string;
    content_type: string;
    file: File;
}

type ApiVersionCode =
    | 'v20190306'
    | 'v20190122'
    | 'v20190211'
    | 'v20190508'
    | 'v20190820'
    | 'v20191031'
    | 'v20191204'
    | 'v20200212'
    | 'v20200310'
    | 'v20200608'
    | 'v20200902'
    | 'v20221213'
    | 'v20230109'
    | 'v20230829'
    | 'v20240612'
    | 'v20240625';

export const LATEST_SUPPORTED_API_VERSION: ApiVersionCode = 'v20240625';

export function getRequiredApiVersion(): ApiVersionCode {
    return LATEST_SUPPORTED_API_VERSION;
}

export default class SweepbrightCrudRequest extends CacheableCrudRequest {
    getRootUrl = () => {
        if (__CLIENT__) {
            // use proxy server
            return `${window.location.origin}/proxy`;
        } else {
            return `${API_PROTOCOL}://${API_URL}`;
        }
    };

    authProvider: AuthProvider;

    constructor(apiVersion: ApiVersionCode = getRequiredApiVersion(), authProvider: AuthProvider = getAuthProvider()) {
        super();
        this.authProvider = authProvider;

        // eslint-disable-next-line dot-notation
        this.withVersion(apiVersion);

        this.rootUrl = this.getRootUrl();

        this.show = this.show.bind(this);
        this.put = this.put.bind(this);
        this.update = this.update.bind(this);
        this.destroy = this.destroy.bind(this);
        this.store = this.store.bind(this);
        this.withBearerToken = this.withBearerToken.bind(this);

        this.setMiddlewares([extractData]);
    }

    initialize({ context }) {
        if (context.userInfo) {
            this.authProvider = {
                getToken: () => context.userInfo.access_token,
            };
        }
    }

    withAuthProvider = (provider: AuthProvider) => {
        this.authProvider = provider;

        return this;
    };

    protected async willSendRequest(path: string) {
        if (!this.hasToken()) {
            const token = this.authProvider.getToken();

            if (token) {
                this.withBearerToken(token);
            } else {
                logger.warn('[SweepbrightCrudRequest.willSendRequest]: failed to get access token');
            }
        } else {
            if (this.authProvider.isNullAuthProvider) {
                logger.error(
                    `[SweepbrightCrudRequest.willSendRequest]: trying to get token from NullAuthProvider (path: ${path}; resource: ${this.resource})`,
                );
            }
        }

        super.willSendRequest(path);
    }

    protected hasToken(): boolean {
        const headers = this.options.headers;
        if (!headers) {
            return false;
        }
        if ('has' in headers && typeof headers.has === 'function') {
            return headers.has('Authorization');
        }

        return new Headers(this.options.headers as HeadersInit).has('Authorization');
    }

    // just remove the extractData middleware
    withMeta = () => {
        return this.setMiddlewares([]);
    };

    mapEstates = (response: any) => {
        response.data = response.data.map((entity: any) => {
            const item: PropertyData = entity.item.data;
            // TODO: check if this format is still used after we introduced isProject
            item.is_project = entity.type === 'project';

            return item;
        });

        return response;
    };

    createBody = () => {
        const body = new FormData() as any;
        // @krvajal NOT SURE THIS IS VALID
        body.maxDataSize = 50 * 1024 * 1024;

        return body;
    };

    storeFile = (endpoint: string, attributes: Partial<FileData>, headers = {}) => {
        const filename = encodeURIComponent(attributes.filename || '');

        const body = this.createBody();
        body.set('filename', filename);
        body.set('description', attributes.description || '');
        body.set('file', attributes.file);
        body.set('photo', attributes.file);
        body.set('content_type', attributes.file?.type);

        ['private', 'ordinal'].forEach(attribute => {
            if (typeof attributes[attribute] !== 'undefined') {
                body.set(attribute, Number(attributes[attribute]));
            }
        });

        endpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint;

        // Compute endpoint
        let fullEndpoint = `${this.resource}/${endpoint}`;
        if (!fullEndpoint.includes('photo') && !fullEndpoint.includes('previews') && !attributes.key) {
            fullEndpoint += `/${attributes.id || uuid.v4()}`;
        }

        // Attaches file to this entity's settings key
        if (attributes.key) {
            body.append('key', attributes.key);
        }

        const payload = {
            body,
            method: 'POST',
            headers: Object.assign(
                {
                    'X-File-Name': filename,
                    'X-HTTP-Method-Override': 'PUT',
                },
                headers,
                this.options.headers,
            ),
        };

        return this.fetch(fullEndpoint, payload);
    };

    withFile = (endpoint: string, attributes: { file: File }, headers = {}) => {
        const { file } = attributes;

        const body = this.createBody();

        body.append('file', file);

        const payload = {
            body,
            method: 'POST',
            headers: Object.assign(headers, this.options.headers),
        };

        return this.fetch(endpoint, payload);
    };

    withVersion = (apiVersion: ApiVersionCode) => {
        // eslint-disable-next-line dot-notation
        return this.withOptions({
            headers: {
                Accept: `application/vnd.sweepbright.${apiVersion}+json`,
            },
        });
    };
}
