import React from 'react';
import { ClassNames } from '@emotion/core';
import styled from '@emotion/styled';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTheme } from '@/app.utils/theming/ThemeContext';

const ListContainer = styled.div`
    margin-top: ${props => (props.search ? '53px' : '0')};
    height: ${props => (props.search ? 'calc(100% - 53px)' : '100%')} !important;
`;

export default function SideMenu({ className, search, children, color, centered, shadow, textOnly, footer, style }) {
    const theme = useTheme();

    return (
        <ClassNames>
            {({ css, cx }) => {
                let themeClass = '';
                // the gray-dark color is used only on the
                // sidenav and we need to style its hover state
                // properly for the given theme
                if (color === 'gray-dark') {
                    themeClass = css`
                        a:hover {
                            background: ${theme.colors.primary};
                        }
                    `;
                }

                const containerClasses = cx(
                    'bc-scrollable-container-content',
                    {
                        [`bc-bg-${color}`]: color,
                        [`bc-bg-shadow-${shadow}`]: shadow,
                    },
                    themeClass,
                );

                return (
                    <div className={cx('bc-scrollable-container', className)} style={style}>
                        {React.isValidElement(search) && (
                            <div
                                className={cx('c-side-menu__search-container', {
                                    [`bc-bg-${color}`]: color,
                                    [`bc-bg-shadow-${shadow}`]: shadow,
                                })}
                            >
                                {search}
                            </div>
                        )}
                        <XXSideMenu
                            containerClassName={containerClasses}
                            centered={centered}
                            footer={footer}
                            hasSearch={React.isValidElement(search)}
                            textOnly={textOnly}
                        >
                            {children}
                        </XXSideMenu>
                    </div>
                );
            }}
        </ClassNames>
    );
}

SideMenu.defaultProps = {
    color: 'gray-dark',
    centered: false,
    shadow: 'left',
};

SideMenu.propTypes = {
    centered: PropTypes.bool,
    children: PropTypes.node,
    footer: PropTypes.node,
    color: PropTypes.string,
    search: PropTypes.node,
    shadow: PropTypes.string,
    textOnly: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
};

function XXSideMenu(props) {
    const { footer, hasSearch, children, containerClassName, textOnly, centered } = props;

    const className = classNames('c-side-menu', {
        'c-side-menu-centered': centered,
        'c-side-menu-text-only': textOnly,
    });

    return (
        <ListContainer className={containerClassName} search={hasSearch}>
            <ul className={className}>{children}</ul>
            {footer}
        </ListContainer>
    );
}
