// @ts-nocheck
import { Set } from 'immutable';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import { MessageDescriptor } from '@/app.redux/actions/StatusActions';
import Icon from '../../icons/Icon';

const Messages: React.FunctionComponent<{ messages: Set<MessageDescriptor> }> = ({ messages }) => {
    // lets get back to plain JS
    const msgs: MessageDescriptor[] = messages.toJS();

    if (msgs.length > 0) {
        return (
            <div>
                {msgs.map((messageRecord: MessageDescriptor, key) => {
                    return (
                        <Alert key={key} bsStyle={messageRecord.style || 'success'}>
                            {messageRecord.icon && (
                                <Icon
                                    icon={messageRecord.icon}
                                    className={messageRecord.style ? 'alert-success__icon' : undefined}
                                />
                            )}
                            {messageRecord.title && (
                                <h2 className="alert-title">
                                    <FormattedMessage id={messageRecord.title} defaultMessage={messageRecord.title} />
                                </h2>
                            )}
                            {messageRecord.message && (
                                <p>
                                    {typeof messageRecord.message === 'string' ? (
                                        <FormattedMessage
                                            id={messageRecord.message}
                                            values={messageRecord.values}
                                            defaultMessage={messageRecord.message}
                                        />
                                    ) : (
                                        messageRecord.message
                                    )}
                                </p>
                            )}
                        </Alert>
                    );
                })}
            </div>
        );
    }

    return null;
};

export default Messages;
