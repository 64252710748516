import React from 'react';
import { Estate } from '@/graphql/generated/types';
import PropertyCard from '@/app.domains/properties/PropertyCard';
import { PROPERTY } from '@/app.routing/routes';
import { Negotiation } from '@/app.data/Settings';
import PropertiesListCardAction from './PropertiesListCardAction/PropertiesListCardAction';

type Props = {
    onLocalize?: (propertyId: string) => void;
    // @ts-ignore
    entity: Pick<Estate, 'id' | 'internalType' | 'negotiation', 'attributes'>;
};

const PropertiesListCard: React.FC<Props> = props => {
    const { entity, onLocalize } = props;

    const actions = React.useMemo(() => {
        return [
            <PropertiesListCardAction
                key="options"
                propertyId={entity.id}
                onLocalize={onLocalize}
                estateType={entity.internalType}
                negotiation={entity.negotiation as Negotiation}
            />,
        ];
    }, [entity, onLocalize]);

    return <PropertyCard to={PROPERTY(entity.id)} showInteraction property={entity!} actions={actions} />;
};

export default PropertiesListCard;
