import useOffice from '@/app.hooks/useOffice';
import useCompanyLanguages from '@/app.hooks/useCompanyLanguages';

export default function useOfficeEmailPresets(template: string) {
    const languages = useCompanyLanguages();

    const officeSettings = useOffice().getIn(['settings', 'data']);
    const presets = languages.reduce((acc, { value }) => {
        acc[value] = officeSettings?.get(`${template}_${value}`) ?? '';

        return acc;
    }, {} as Record<string, string>);

    return presets;
}
