import React from 'react';
import useOffice from '@/app.hooks/useOffice';
import useCompany from '@/app.hooks/useCompany';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { FormattedMessage } from 'react-intl-sweepbright';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import * as routes from '../../../app.routing/routes';
import SideMenu from '../../../app.components/navigations/SideMenu/SideMenu';
import SideMenuLink from '../../../app.components/navigations/SideMenu/SideMenuLink';
import TeamWrapperInfo from './TeamWrapperInfo';

type Props = {
    params: {
        office: string;
    };
    children: JSX.Element;
};

const TeamWrapper: React.FC<Props> = props => {
    const { params, children } = props;

    const company = useCompany();

    const office = useOffice(params.office);

    const isCompanyEnabled = !company.get('disabled_at');

    const legalEntitiesEnabled = useFeatureFlag('legal-entities-enabled');

    return (
        <LayoutContainer flex>
            <LayoutColumn size="small">
                <TeamWrapperInfo office={office} />
                <SideMenu color="gray-lighter" style={{ top: '246px' }}>
                    <SideMenuLink
                        label={
                            <FormattedMessage id="navigation.office.settings_section" defaultMessage="Team Settings" />
                        }
                        icon="edit"
                        to={routes.TEAM_EDIT(params.office)}
                    />

                    {isCompanyEnabled && legalEntitiesEnabled && (
                        <SideMenuLink
                            fuzzyMatch
                            label={
                                <FormattedMessage
                                    id="navigation.office.legal_entities"
                                    defaultMessage="Legal Entities"
                                />
                            }
                            icon="document"
                            to={routes.TEAM_LEGAL_ENTITIES(params.office)}
                        />
                    )}

                    {isCompanyEnabled && (
                        <SideMenuLink
                            fuzzyMatch
                            label={
                                <FormattedMessage
                                    id="navigation.office.location_templates"
                                    defaultMessage="Location Templates"
                                />
                            }
                            icon="location"
                            to={routes.TEAM_LOCATION_TEMPLATES(params.office)}
                        />
                    )}

                    {isCompanyEnabled && (
                        <SideMenuLink
                            fuzzyMatch
                            label={
                                <FormattedMessage
                                    id="navigation.office.location_filter_templates"
                                    defaultMessage="Location Filter Templates"
                                />
                            }
                            icon="location"
                            to={routes.TEAM_LOCATION_FILTER_TEMPLATES(params.office)}
                        />
                    )}

                    {isCompanyEnabled && (
                        <SideMenuLink
                            fuzzyMatch
                            label={
                                <FormattedMessage
                                    id="navigation.office.templates.properties"
                                    defaultMessage="Property Templates"
                                />
                            }
                            icon="notes"
                            to={routes.TEAM_PRESETS_FOR_PROPERTY(params.office, '')}
                        />
                    )}
                    {isCompanyEnabled && (
                        <SideMenuLink
                            fuzzyMatch
                            label={
                                <FormattedMessage
                                    id="navigation.office.templates.email"
                                    defaultMessage="E-Mail Templates"
                                />
                            }
                            icon="mail"
                            to={routes.TEAM_PRESETS_FOR_EMAIL(params.office, '')}
                        />
                    )}
                </SideMenu>
            </LayoutColumn>
            <LayoutColumn className="bc-bg-shadow-left">
                {office &&
                    React.cloneElement(children, {
                        office,
                    })}
            </LayoutColumn>
        </LayoutContainer>
    );
};

export default TeamWrapper;
