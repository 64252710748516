import React, { FC } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import { FormattedMessage } from 'react-intl-sweepbright';
import ChannelImage from '@/app.shared/channels/ChannelImage';
import Icon, { ICON_SIZE_SMALL } from '@/app.components/icons/Icon';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import MediaCard from '@/app.components/card/MediaCard/MediaCard';
import Status from '@/app.components/elements/Status/Status';
import ConfirmUnpublishModal from '@/app.components/modals/ConfirmUnpublishModal';
import { ChannelStatuses } from '@/app.domains/properties/components/ChannelCard';
import { useToasts } from '@sweepbright/notifications';
import { track, events } from '@/app.utils/analytics';
import {
    ChannelAccount,
    ConfigurationStatus,
    EstateChannelAccountConnectionEdge,
    EstatePublicationErrors,
    GetEstateChannelAccountQuery,
    GetEstateChannelAccountQueryVariables,
    PublicationStatus,
    RefreshPublicationMutation,
    RefreshPublicationMutationVariables,
} from '@/graphql/generated/types';
import {
    REFRESH_PUBLICATION_MUTATION,
    REMOVE_PUBLICATION_MUTATION,
    RETRY_PUBLICATION_MUTATION,
} from '@/graphql/mutations/properties/publish';
import { ESTATE_CHANNEL_ACCOUNT_QUERY } from '@/graphql/queries/properties/getEstateChannelAccounts';
import { getBugsnagClient } from '@/app.config/bugsnag';
import './ChannelAccountCard.scss';
import {
    NewPublicationModal,
    PublicationAction,
} from '@/app.domains/properties/components/NewPublicationModal/NewPublicationModal';
import { fullName } from '@/app.utils/services/Helpers';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { CopyButton } from '@/app.components/elements/Buttons/CopyButton';
import { PublicationErrorDetails } from './PublicationErrorDetails';

const POLLING_INTERVAL_MS = 1000;
const POLLING_WINDOW_DURATION_MS = 60000;

const statusIcons = {
    // publish status
    PUBLISHED: 'checked',
    PUBLISHING: 'busy',
    PUBLISHING_FAILED: 'error',
};

export function ChannelAccountCard({
    propertyId,
    unitId,
    channelAccountId,
    onRetried,
}: {
    channelAccountId: string;
    propertyId: string;
    unitId?: string;
    onRetried: () => void;
}) {
    const pollingTimerRef = React.useRef<Maybe<number>>(null);
    const ffRefreshEnabled = useFeatureFlag('publish.refreshPublicationStatus.enabled');

    const intl = useIntl();

    const { data, startPolling, stopPolling } = useQuery<
        GetEstateChannelAccountQuery,
        GetEstateChannelAccountQueryVariables
    >(ESTATE_CHANNEL_ACCOUNT_QUERY, {
        variables: {
            estateId: unitId || propertyId,
            channelAccountId: channelAccountId,
        },
        onError(error) {
            getBugsnagClient().notify(error);
        },
        returnPartialData: true,
        notifyOnNetworkStatusChange: true,
    });

    React.useEffect(() => {
        return () => {
            if (pollingTimerRef.current) {
                clearTimeout(pollingTimerRef.current);
                pollingTimerRef.current = null;
            }
        };
    }, []);

    function startPollingWindow() {
        // cancel an existing polling window
        if (pollingTimerRef.current) {
            clearTimeout(pollingTimerRef.current);
        }

        startPolling(POLLING_INTERVAL_MS);
        // stop polling after the polling window expires
        pollingTimerRef.current = (setTimeout(() => {
            stopPolling();
        }, POLLING_WINDOW_DURATION_MS) as any) as number;
    }

    const [favorite, setFavorite] = React.useState(false);

    const estateChannelAccount = data?.estate?.channelAccount;

    if (!estateChannelAccount) {
        return null;
    }

    const {
        node: {
            status,
            channel: { countries },
        },
        node: account,
        missingFields,
        unitsMissingFields = [],
        publicationStatus,
        configurationStatus,
        lastPublication,
    } = estateChannelAccount;

    const hasWarnings = lastPublication?.warnings?.self?.length || lastPublication?.warnings?.units?.length;

    const statusIcon =
        hasWarnings && publicationStatus !== 'PUBLISHING' && publicationStatus !== 'UNPUBLISHING'
            ? 'warning-yellow'
            : statusIcons[publicationStatus];

    // Pending status of the channel account should be also handled here.
    // Currently, API doesn't provide one.
    const disabled = status === ChannelStatuses.INACTIVE;

    const image = <ChannelImage key="image" channel={account.channel} status={statusIcon} disabled={disabled} />;

    const title = (
        <span className="inline-flex items-center c-channel-account-card__title">
            {account.name}{' '}
            <CopyButton
                iconName="copy-m"
                value={channelAccountId}
                title={intl.formatMessage({ id: 'channel.copy' })}
                className="ml-1 text-muted cursor-pointer c-channel-account-card__copy"
            />
            <button
                className={classNames('mx-1 p-0 group-hover:visible', { hidden: !favorite })}
                type="button"
                onClick={() => setFavorite(!favorite)}
            >
                <Icon icon={favorite ? 'star-fill' : 'star'} />
            </button>
        </span>
    );

    const hasMissingFields =
        //@ts-ignore
        missingFields?.node?.length > 0 || unitsMissingFields?.edges.some(edge => edge.node.length > 0);

    const errors: EstatePublicationErrors = {
        self: [
            ...(lastPublication?.errors?.self || []),
            ...(missingFields?.node?.map(el => {
                return { message: el.errorMessage };
            }) || []),
        ],
        units: [
            ...(lastPublication?.errors?.units || []),
            // @ts-ignore
            ...(unitsMissingFields?.edges?.map(unit => {
                return { unitId: unit.estateId, errors: unit?.node?.map(el => ({ message: el.errorMessage })) } || [];
            }) || []),
        ].filter(unit => unit.errors.length > 0),
    };

    const warnings = lastPublication?.warnings;

    const actions = [
        <div key="publish actions">
            {canUpdatePublication() && (
                <UpdateAction
                    disabled={disabled}
                    //@ts-ignore
                    accountEdge={estateChannelAccount}
                    propertyId={propertyId}
                    onUpdated={startPollingWindow}
                    hasMissingFields={hasMissingFields}
                />
            )}
            {canUnpublish() && (
                <UnpublishAction
                    disabled={disabled}
                    //@ts-ignore
                    account={account}
                    propertyId={propertyId}
                    onUnpublished={startPollingWindow}
                />
            )}
            {canPublish() && (
                <PublishAction
                    disabled={disabled || configurationStatus !== ConfigurationStatus.Ok}
                    //@ts-ignore
                    accountEdge={estateChannelAccount}
                    propertyId={propertyId}
                    onPublished={startPollingWindow}
                />
            )}
            {canRetryPublication() && (
                <RetryAction
                    disabled={disabled || configurationStatus !== ConfigurationStatus.Ok}
                    //@ts-ignore
                    accountEdge={estateChannelAccount}
                    propertyId={propertyId}
                    onRetried={onRetried}
                    hasMissingFields={hasMissingFields}
                />
            )}

            {canRefresh() && (
                <RefreshAction
                    propertyId={propertyId}
                    channelAccountId={estateChannelAccount.node.id}
                    onRefreshed={startPollingWindow}
                />
            )}
        </div>,
    ];

    return (
        <MediaCard
            image={image}
            title={title}
            subtitles={[getSubline(), getStatus()]}
            details={
                (errors || warnings) && (
                    <PublicationErrorDetails
                        errors={errors}
                        warnings={warnings}
                        propertyId={propertyId}
                        channelAccountId={channelAccountId}
                    />
                )
            }
            actions={actions}
            className="c-channel-account-card group flex-wrap"
            testId={`channel-account-${channelAccountId}`}
        />
    );

    function getSubline() {
        if (publicationStatus === PublicationStatus.Published) {
            return <FormattedMessage id="publication_channel.status.published" defaultMessage="Published" />;
        }

        if (
            publicationStatus === PublicationStatus.NotPublished &&
            configurationStatus === ConfigurationStatus.MissingFields
        ) {
            return (
                <span className="text-danger">
                    <FormattedMessage
                        id="publication_channel.status.has_missing_fields"
                        defaultMessage="Can not publish due to invalid or missing fields"
                    />
                </span>
            );
        }

        return null;
    }

    function getStatus() {
        if (publicationStatus === PublicationStatus.Published) {
            return (
                <span>
                    {lastPublication?.link ? (
                        <span>
                            <a
                                href={lastPublication.link}
                                className="underline"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FormattedMessage id="general.link" defaultMessage="Link" />
                            </a>
                            {' - '}
                        </span>
                    ) : null}
                    {lastPublication?.publishedBy ? (
                        <FormattedMessage
                            id="publication_channel.status.published_time_by_negotiator"
                            defaultMessage="Published {time} by {negotiator}"
                            values={{
                                time:
                                    lastPublication && moment(lastPublication.publishedAt).format('DD MMM YYYY HH:mm'),
                                negotiator: fullName(
                                    lastPublication.publishedBy.firstName,
                                    lastPublication.publishedBy.lastName,
                                ),
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="publication_channel.status.published_time"
                            defaultMessage="Published {time}"
                            values={{
                                time:
                                    lastPublication && moment(lastPublication.publishedAt).format('DD MMM YYYY HH:mm'),
                            }}
                        />
                    )}
                </span>
            );
        }

        if (publicationStatus === PublicationStatus.Publishing) {
            return <FormattedMessage id="publication_channel.status.publishing" defaultMessage="Publishing..." />;
        }

        if (publicationStatus === PublicationStatus.PublishingFailed) {
            return (
                <span className="text-danger">
                    <FormattedMessage
                        id="publication_channel.status.publishing_failed"
                        defaultMessage="Retry or contact Customer Success"
                    />
                </span>
            );
        }

        if (publicationStatus === PublicationStatus.Unpublishing) {
            return <FormattedMessage id="publication_channel.status.unpublishing" defaultMessage="Unpublishing..." />;
        }

        return publicationStatus;
    }

    function canUpdatePublication() {
        if (publicationStatus === PublicationStatus.Published) {
            return account.channel.supportsRepublishing;
        }

        return false;
    }

    function canUnpublish() {
        const isPublished = publicationStatus === PublicationStatus.Published;

        // Facebook and Twitter don't support unpublishing
        return isPublished && account.channel.supportsUnpublishing;
    }

    function canPublish() {
        if (publicationStatus === PublicationStatus.Published) {
            // supportsRepublishing means that existing publication
            // can be updated, so if it is not supported,
            // we can always publish a new one
            // example of this is Facebook and Twitter
            if (!account.channel.supportsRepublishing) {
                return true;
            }
        }

        return false;
    }

    function canRetryPublication() {
        return (
            publicationStatus === PublicationStatus.PublishingFailed ||
            publicationStatus === PublicationStatus.UnpublishingFailed
        );
    }

    function canRefresh() {
        // From 'FR' we assume that this is an Ubiflow portal
        const isFrenchPortal = countries.includes('FR');

        return ffRefreshEnabled && isFrenchPortal && publicationStatus === PublicationStatus.Publishing;
    }
}

function UpdateAction({
    accountEdge,
    propertyId,
    onUpdated,
    hasMissingFields,
    disabled,
}: {
    accountEdge: EstateChannelAccountConnectionEdge;
    propertyId: string;
    onUpdated: () => void;
    hasMissingFields: boolean;
    disabled?: boolean;
}) {
    const [showPublishModal, setShowPublishModal] = React.useState(false);

    return (
        <>
            <NewPublicationModal
                //@ts-ignore
                action="update"
                show={showPublishModal}
                propertyId={propertyId}
                onCancel={() => setShowPublishModal(false)}
                onCompleted={() => {
                    onUpdated();
                    setShowPublishModal(false);
                }}
                channelAccount={accountEdge}
            />
            <Tooltip
                key="republish"
                label={<FormattedMessage id="forms.publish.title.update" defaultMessage="Update publication" />}
            >
                <div className="inline-block relative">
                    <ButtonIcon
                        variant="link"
                        type="update"
                        testId="update_action"
                        iconSize={ICON_SIZE_SMALL}
                        disabled={disabled}
                        onClick={() => {
                            track(events.PROPERTY_CHANNEL_ACCOUNT_CARD_UPDATE_BTN_CLICKED, {
                                channel: accountEdge.node.channel.id,
                            });
                            setShowPublishModal(true);
                        }}
                    />
                    {hasMissingFields && (
                        <div className="absolute top-0 right-0">
                            <Status status="error" />
                        </div>
                    )}
                </div>
            </Tooltip>
        </>
    );
}

function PublishAction({
    disabled,
    accountEdge,
    propertyId,
    onPublished,
}: {
    disabled?: boolean;
    accountEdge: EstateChannelAccountConnectionEdge;
    propertyId: string;
    onPublished: () => void;
}) {
    const [showPublishModal, setShowPublishModal] = React.useState(false);
    const { addSuccess } = useToasts();

    return (
        <>
            <NewPublicationModal
                show={showPublishModal}
                propertyId={propertyId}
                onCancel={() => setShowPublishModal(false)}
                onCompleted={() => {
                    setShowPublishModal(false);
                    addSuccess({
                        title: (
                            <FormattedMessage
                                id="publication_channel.status.publication_scheduled"
                                defaultMessage="Publication scheduled"
                            />
                        ),
                        message: (
                            <FormattedMessage
                                id="publish.scheduled.description"
                                defaultMessage="It can take several minutes to be live"
                            />
                        ),
                    });
                    onPublished();
                }}
                channelAccount={accountEdge}
            />
            <Tooltip
                key="publish"
                label={
                    disabled ? (
                        <FormattedMessage
                            id="publish.tooltip.cant_publish_to_channel"
                            defaultMessage="Can't publish to this channel"
                        />
                    ) : (
                        <FormattedMessage
                            id="publish.tooltip.publish_to_channel"
                            defaultMessage="Publish to this channel"
                        />
                    )
                }
                onClick={() => setShowPublishModal(true)}
            >
                <ButtonIcon
                    variant="link"
                    type="publish"
                    iconSize={20}
                    testId="publish_action"
                    disabled={disabled}
                    onClick={() => {
                        track(events.PROPERTY_CHANNEL_ACCOUNT_CARD_PUBLISH_BTN_CLICKED, {
                            account: accountEdge.node.channel.id,
                        });
                        setShowPublishModal(true);
                    }}
                />
            </Tooltip>
        </>
    );
}

function UnpublishAction({
    account,
    propertyId,
    onUnpublished,
    disabled,
}: {
    account: ChannelAccount;
    propertyId: string;
    onUnpublished: () => void;
    disabled?: boolean;
}) {
    const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);

    const { addSuccess, addError } = useToasts();

    const [unpublish] = useMutation(REMOVE_PUBLICATION_MUTATION, {
        variables: { input: { propertyId, channelAccountId: account.id }, channelAccountId: account.id },
        onCompleted() {
            addSuccess({
                message: (
                    <FormattedMessage
                        id="publish.removal_scheduled.success"
                        defaultMessage="Publication scheduled for removal"
                    />
                ),
            });
            onUnpublished();
        },
        onError(error) {
            getBugsnagClient().notify(error);

            const isForbidden = error?.message?.includes('403');

            addError({
                message: isForbidden ? (
                    <FormattedMessage id="unauthorised_403" />
                ) : (
                    <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                ),
            });
        },
    });

    return (
        <>
            <ConfirmUnpublishModal
                propertyId={propertyId}
                show={showConfirmationModal}
                onCancel={() => setShowConfirmationModal(false)}
                accountName={account.name}
                onConfirm={async () => {
                    setShowConfirmationModal(false);
                    await unpublish();
                }}
            />
            <Tooltip
                key="unpublish"
                label={
                    <FormattedMessage
                        id="publish.tooltip.unpublish_from_channel"
                        defaultMessage="Unpublish from this channel"
                    />
                }
            >
                <ButtonIcon
                    variant="link"
                    type="unpublish"
                    testId="unpublish_action"
                    iconSize={ICON_SIZE_SMALL}
                    disabled={disabled}
                    onClick={() => {
                        track(events.PROPERTY_CHANNEL_ACCOUNT_CARD_UNPUBLISH_BTN_CLICKED, {
                            channel: account.channel.id,
                        });
                        setShowConfirmationModal(true);
                    }}
                />
            </Tooltip>
        </>
    );
}

function RetryAction({
    accountEdge,
    propertyId,
    onRetried,
    hasMissingFields,
    disabled,
}: {
    accountEdge: EstateChannelAccountConnectionEdge;
    propertyId: string;
    onRetried: () => void;
    hasMissingFields: boolean;
    disabled?: boolean;
}) {
    const [showRetryPublishModal, setShowRetryPublishModal] = React.useState(false);
    const { addSuccess, addError } = useToasts();
    const [retry, { loading }] = useMutation(RETRY_PUBLICATION_MUTATION, {
        variables: { input: { propertyId, channelAccountId: accountEdge.node.id } },
        errorPolicy: 'all',
        onCompleted() {
            addSuccess({
                message: <FormattedMessage id="publish.retrying_action" defaultMessage="Retrying publication..." />,
            });
            onRetried();
        },
        onError(error) {
            addError({
                message: <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />,
            });
            getBugsnagClient().notify(error);
        },
    });

    return (
        <>
            <NewPublicationModal
                show={showRetryPublishModal}
                propertyId={propertyId}
                onCancel={() => setShowRetryPublishModal(false)}
                onCompleted={async () => {
                    setShowRetryPublishModal(false);
                    await retry();
                    onRetried();
                }}
                channelAccount={accountEdge}
                action={PublicationAction.RETRY}
            />
            <Tooltip
                key="retry"
                label={<FormattedMessage id="publish.tooltip.retry" defaultMessage="Retry publication" />}
                onClick={() => setShowRetryPublishModal(true)}
            >
                <div className="inline-block relative">
                    <ButtonIcon
                        variant="link"
                        type="retry"
                        iconSize={20}
                        testId="retry_publish_action"
                        disabled={loading || disabled}
                        onClick={() => {
                            track(events.PROPERTY_CHANNEL_ACCOUNT_CARD_RETRY_BTN_CLICKED, {
                                account: accountEdge.node.channel.id,
                            });
                            if (hasMissingFields) {
                                setShowRetryPublishModal(true);
                            } else {
                                retry();
                            }
                        }}
                    />
                    {hasMissingFields && (
                        <div className="absolute top-0 right-0 mr-1 pointer-events-none">
                            <Status status="error" />
                        </div>
                    )}
                </div>
            </Tooltip>
        </>
    );
}

const RefreshAction: FC<{
    propertyId: string;
    channelAccountId: string;
    onRefreshed: () => void;
}> = ({ propertyId, channelAccountId, onRefreshed }) => {
    const { addSuccess, addError } = useToasts();
    const [refresh, { loading }] = useMutation<RefreshPublicationMutation, RefreshPublicationMutationVariables>(
        REFRESH_PUBLICATION_MUTATION,
        {
            variables: { input: { propertyId, channelAccountId } },
            errorPolicy: 'all',
            onCompleted() {
                addSuccess({
                    message: (
                        <FormattedMessage id="publish.refreshing_action" defaultMessage="Refreshing publication..." />
                    ),
                });
                onRefreshed();
            },
            onError(error) {
                addError({
                    message: (
                        <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />
                    ),
                });
                getBugsnagClient().notify(error);
            },
        },
    );

    return (
        <Tooltip
            key="retry"
            label={<FormattedMessage id="publish.tooltip.refresh" defaultMessage="Refresh publication status" />}
        >
            <div className="inline-block relative">
                <ButtonIcon
                    variant="link"
                    type="refresh"
                    testId="refresh_action"
                    iconSize={ICON_SIZE_SMALL}
                    disabled={loading}
                    onClick={async () => {
                        track(events.PROPERTY_CHANNEL_ACCOUNT_CARD_REFRESH_BTN_CLICKED, {
                            account: channelAccountId,
                        });

                        await refresh();
                    }}
                />
            </div>
        </Tooltip>
    );
};
