import React, { useContext, useMemo } from 'react';
import { LoadingIndicator } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import EmptyState from '@/app.components/empty/EmptyState';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';
import OfferCard from '../OfferCard';
import { OfferContext } from '../utils';

type Props = {
    archived?: boolean;
    title: JSX.Element;
    loading: boolean;
    data: OfferCardFragmentFragment[];
};

const OfferList: React.FC<Props> = props => {
    const { archived, title, loading, data } = props;

    const { dataAuth } = useContext(OfferContext);

    const filteredData = useMemo(() => {
        return data.filter(el => {
            const view = dataAuth?.find(authEl => authEl.requestId === `${el.id}-view`)?.allowed;

            if (!view) {
                return false;
            }

            return archived ? !!el.archivedAt : !el.archivedAt;
        });
    }, [data, dataAuth, archived]);

    const isEmpty = useMemo(() => filteredData.length === 0, [filteredData]);

    if (loading) {
        return (
            <div className="offer-list">
                <LoadingIndicator />
            </div>
        );
    }

    return (
        <div className={`offer-list${isEmpty ? ' empty' : ''}`}>
            {isEmpty ? (
                <EmptyState
                    title={<FormattedMessage id="offers.empty.title" defaultMessage="Whoops, no offers yet" />}
                    body={
                        <FormattedMessage
                            id="offers.empty.subtitle"
                            defaultMessage="Offers per property will show up here."
                        />
                    }
                />
            ) : (
                <>
                    <div className="offer-list__header">
                        <h4 className="offer-list__title">{title}</h4>
                    </div>

                    <div className="offer-list__wrapper">
                        {filteredData.map(el => {
                            return <OfferCard key={el.id} record={el} />;
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

export default OfferList;
