// @ts-nocheck
import React from 'react';
import produce from 'immer';
import { Shimmer } from '@sweepbright/uikit';
import { useMutation } from '@apollo/react-hooks';
import Button from '@sweepbright/uikit/build/esm/button';
import Hr from '@sweepbright/uikit/build/esm/hr';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import Modal from '@/app.components/elements/Modal';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { REGISTER_ESTATE_ACTIVITY } from '@/graphql/mutations/properties/registerEstateActivity';
import {
    RegisterEstateActivityMutation,
    RegisterEstateActivityMutationVariables,
    GetInteractedPropertiesQuery,
    GetInteractedPropertiesQueryVariables,
} from '@/graphql/generated/types';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import PropertySelect from '@/shared/properties/PropertySelect';
import PropertyCard, { adaptProperty } from '@/app.domains/properties/PropertyCard';
import { GET_INTERACTED_PROPERTIES_QUERY } from '@/graphql/queries/contacts/getInteractedProperties';
import useContact from '@/app.hooks/useContact';
import useProperty from '@/app.hooks/useProperty';
import CloseIconButton from '../elements/Buttons/CloseIconButton';
import ContactCard from '../../shared/contacts/ContactCard';

export default function RegisterContactActivityModal({
    show,
    contactId,
    propertyId,
    onClose,
    type,
    onActivityRegistered,
}: {
    show: boolean;
    contactId: string;
    propertyId?: string;
    onClose: () => void;
    type: string;
    onActivityRegistered: (id: string) => void;
}) {
    const [selectedProperty, setSelectedProperty] = React.useState<Maybe<string>>(propertyId);
    const { contact, loading: loadingContact } = useContact(contactId);

    const disabledShared = useFeatureFlag('disable-shared-properties-activity');

    const { property } = useProperty(selectedProperty);

    const [registerActivity, { loading }] = useMutation<
        RegisterEstateActivityMutation,
        RegisterEstateActivityMutationVariables
    >(REGISTER_ESTATE_ACTIVITY, {
        onCompleted(result) {
            onActivityRegistered(result.registerEstateActivity.activity.id);
        },
        update(store, mutationResult: { data: RegisterEstateActivityMutation }) {
            try {
                const data = store.readQuery<GetInteractedPropertiesQuery, GetInteractedPropertiesQueryVariables>({
                    query: GET_INTERACTED_PROPERTIES_QUERY,
                    variables: {
                        contactId,
                    },
                });

                // eslint-disable-next-line no-use-before-define
                const updatedQueryData = removePropertyFromCache(
                    data,
                    mutationResult.data.registerEstateActivity.subject.id,
                );

                store.writeQuery({
                    query: GET_INTERACTED_PROPERTIES_QUERY,
                    data: updatedQueryData,
                    variables: {
                        contactId,
                    },
                });
            } catch (err) {}
        },
    });

    function handleRegister() {
        if (!selectedProperty) {
            return;
        }

        registerActivity({
            variables: {
                input: {
                    estateId: selectedProperty,
                    contactId,
                    type: type,
                },
            },
        });
    }

    const canSelectProperty = !propertyId;

    return (
        <Modal show={show} onHide={noop}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage
                        id={`modals.register_activity.${type}.title`}
                        defaultMessage={`modals.register_activity.${type}.title`}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormPanel
                    title={<FormattedMessage id="modals.register_activity.contact.title" defaultMessage="Contact" />}
                >
                    <Shimmer isDataLoaded={!loadingContact}>{contact && <ContactCard contact={contact} />}</Shimmer>
                    <Hr />
                </FormPanel>
                <FormPanel>
                    <FormPanel
                        title={
                            <FormattedMessage id="modals.register_activity.property.title" defaultMessage="Property" />
                        }
                    >
                        <ErrorBoundary>
                            {canSelectProperty && (
                                <PropertySelect
                                    value={null}
                                    contactId={contactId}
                                    disabled={selectedProperty}
                                    onSelect={setSelectedProperty}
                                    disabledShared={disabledShared}
                                />
                            )}
                        </ErrorBoundary>
                        {property ? (
                            <div className="my-2 mx-0">
                                <PropertyCard
                                    property={adaptProperty(property)}
                                    actions={
                                        canSelectProperty
                                            ? [
                                                  <CloseIconButton
                                                      key="remove"
                                                      onClick={() => setSelectedProperty(null)}
                                                  />,
                                              ]
                                            : []
                                    }
                                />
                            </div>
                        ) : null}
                    </FormPanel>
                </FormPanel>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>
                    <FormattedMessage id="modals.common.actions.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="primary" disabled={!selectedProperty || loading} onClick={handleRegister}>
                    {loading ? (
                        <FormattedMessage
                            id="modals.interaction_feedback.registering"
                            defaultMessage="Registering..."
                        />
                    ) : (
                        <FormattedMessage id="activity.register" defaultMessage="Register" />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function noop() {}

const removePropertyFromCache = produce((cachedQuery: GetIntereactedPropertiesQuery | null, propertyId: string) => {
    const indexInInterestedList = cachedQuery?.lead?.interestedProperties.nodes.findIndex(
        property => property.id === propertyId,
    );
    const indexInNotInsterestedList = cachedQuery?.lead?.notInterestedProperties.nodes.findIndex(
        property => property.id === propertyId,
    );
    if (indexInInterestedList != null && indexInInterestedList >= 0) {
        // eslint-disable-next-line no-unused-expressions
        cachedQuery?.lead?.interestedProperties.nodes.splice(indexInInterestedList, 1);
    }

    if (indexInNotInsterestedList != null && indexInNotInsterestedList >= 0) {
        // eslint-disable-next-line no-unused-expressions
        cachedQuery?.lead?.notInterestedProperties.nodes.splice(indexInNotInsterestedList, 1);
    }
});
