import React from 'react';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import Area from '@/app.domains/properties/Area';
import Price from '@/app.components/elements/Price/Price';
import { getNumberSettings, roundPrice } from '@/app.config/accounting';
import { ResourceFilterType } from '../types';

type Props = {
    intl: any;
    id: string;
    params: any;
    content: any;
    prefix?: string;
    intlKey: string;
    currency?: string;
    filter?: ResourceFilterType;
    filters: ResourceFilterType[];
    translationFirstPart?: string;
    customActiveLabel?: () => string | JSX.Element | false | null | undefined;
};

export const getRadioValue = (minValue, maxValue) => {
    if (minValue && maxValue) {
        return 'equal';
    }

    if (minValue) {
        return 'above';
    }

    return 'below';
};

export const formatFilter = (props: Props) => {
    const { filters, customActiveLabel } = props;

    if (customActiveLabel) {
        return customActiveLabel() || null;
    }

    const formatters = {
        text: formatFilterText,
        boolean: formatFilterBoolean,
        number: formatFilterNumber,
        range: formatFilterRange,
        radio: formatFilterMultiple,
        checkbox: formatFilterMultiple,
        'radio-number': formatFilterRadioNumber,
    };

    if (filters.length === 1) {
        const defaultProps = {
            ...props,
            filter: filters[0],
        };

        return formatters[filters[0].type](defaultProps) || null;
    }

    if (filters.length > 1) {
        const result = filters
            .map(filter => {
                const defaultProps = {
                    ...props,
                    filter,
                };

                return formatters[filter.type](defaultProps) || null;
            })
            .filter(Boolean);

        if (result.length) {
            if (result.length === 1) {
                return result[0];
            }

            if (result.length > 1) {
                return (
                    <>
                        {result[0]} (+{result.length - 1})
                    </>
                );
            }
        }
    }

    return null;
};

export const formatFilterText = (props: Props) => {
    const { content, params, filter } = props;

    const value = params?.[filter?.paramName || ''];

    if (value || value === 0) {
        return `${content.label}: ${value}`;
    }

    return null;
};

export const formatFilterBoolean = (props: Props) => {
    const { id, intl, params, filter, intlKey } = props;

    const value = params?.[filter?.paramName || ''];

    if (value === true) {
        const hasDisplayKey = !!filter?.displayKey;

        return intl.formatMessage({
            id: `${intlKey}.filter.${id}.true${hasDisplayKey ? '.value' : ''}`,
        });
    }

    return null;
};

export const formatFilterNumber = (props: Props) => {
    const { id, params, content, prefix, currency, filter } = props;

    const value = params?.[filter?.paramName || ''];

    if (value || value === 0) {
        if (prefix === 'sq_m') {
            return (
                <>
                    <FormattedMessage id={`properties.fields.structure.${id}.size`} />
                    {': '}
                    <Area size={value} units={prefix} />
                </>
            );
        }

        if (prefix === 'euro') {
            return (
                <>
                    {content.label}: <Price amount={value} currency={currency} />
                </>
            );
        }

        return `${content.label}: ${value}`;
    }

    return null;
};

export const formatFilterRange = (props: Props) => {
    const { id, intl, intlKey, params, prefix, currency, content, filter } = props;

    const low = params?.[`min_${filter?.paramName}`];
    const high = params?.[`max_${filter?.paramName}`];

    const min = 0;
    const max = prefix === 'euro' ? Infinity : 10000000;

    const labels = {
        min: (prefix === 'sq_m' && content.above) || (prefix === 'euro' && content.minCapitalize) || content.min,
        max: (prefix === 'sq_m' && content.below) || (prefix === 'euro' && content.maxCapitalize) || content.max,
    };

    const activeType = (prefix === 'euro' && 'euro') || (prefix === 'sq_m' && 'sq_m') || 'default';

    let title = '';

    let elem: {
        low: JSX.Element;
        high: JSX.Element;
        lowShort?: JSX.Element;
    } = {
        low: <></>,
        high: <></>,
    };

    if (activeType === 'euro') {
        title = '';

        elem.low = <Price amount={roundPrice(low)[0]} currency={currency} suffix={roundPrice(low)[1]} />;
        elem.high = <Price amount={roundPrice(high)[0]} currency={currency} suffix={roundPrice(high)[1]} />;
    }

    if (activeType === 'sq_m') {
        title = `${intl.formatMessage({
            id: `properties.fields.structure.${filter?.paramName || 'liveable_area'}.size`,
        })}: `;

        elem.low = <Area size={low} units={prefix} />;
        elem.high = <Area size={high} units={prefix} />;
        elem.lowShort = <Area hideUnit size={low} units={prefix} />;
    }

    if (activeType === 'default') {
        title = filter?.showTitle
            ? `${intl.formatMessage({ id: `${intlKey}.filter.${id}.${filter.paramName}` })}: `
            : `${content.label}: `;

        const { decimal, thousand } = getNumberSettings();

        elem.low = (
            <NumberFormat value={low} displayType="text" thousandSeparator={thousand} decimalSeparator={decimal} />
        );
        elem.high = (
            <NumberFormat value={high} displayType="text" thousandSeparator={thousand} decimalSeparator={decimal} />
        );
    }

    if (low || high) {
        if (low && high && low > min && high < max) {
            return (
                <>
                    {title}
                    {elem?.lowShort || elem.low}
                    {activeType === 'euro' ? ' ' : ''}&#45;{activeType === 'euro' ? ' ' : ''}
                    {elem.high}
                </>
            );
        } else if (low >= min) {
            return (
                <>
                    {title}
                    {labels.min} {elem.low}
                </>
            );
        } else if (high <= max) {
            return (
                <>
                    {title}
                    {labels.max} {elem.high}
                </>
            );
        }
    }

    return null;
};

export const formatFilterMultiple = (props: Props) => {
    const { id, intl, params, filter, intlKey, translationFirstPart } = props;

    const value = params?.[filter?.paramName || ''];

    if (value && typeof value === 'string') {
        if (filter?.defaultValue === value) {
            return null;
        }

        if (filter?.groupOptions?.length) {
            const activeGroupOption = filter.groupOptions.find(group =>
                group.options.some(option => option.value === value),
            );

            if (activeGroupOption) {
                return intl.formatMessage({
                    id: activeGroupOption.options.find(option => option.value === value)?.translateKey || '',
                });
            }
        }

        const hasDisplayKey = Array.isArray(filter?.options) && filter?.options?.some(option => option.displayKey);

        return intl.formatMessage({
            id: `${intlKey}.filter.${id}.${value}${hasDisplayKey ? '.value' : ''}`,
        });
    }

    if (value && Array.isArray(value) && value.length > 0) {
        const allOptions = filter?.options || [];
        const allSimpleOptions = filter?.simpleOptions || [];
        const allGroupOptions = filter?.groupOptions?.map(el => el?.options).flat() || [];

        const optionsLength = allGroupOptions?.length || allSimpleOptions?.length || allOptions?.length || 0;

        if (value.length === optionsLength || (value.length > 0 && optionsLength === 0)) {
            return null;
        }

        const activeGroupOption = allGroupOptions.length
            ? allGroupOptions.find(option => value.includes(option.value))
            : undefined;

        const firstPart = intl.formatMessage({
            id:
                (!!activeGroupOption && typeof activeGroupOption === 'object' && activeGroupOption.translateKey) ||
                (!!translationFirstPart && `${translationFirstPart}.${value[0]}`) ||
                `${intlKey}.filter.${id}.${value[0]}`,
        });

        const count = value.length - 1;

        const secondPart = value.length > 1 ? ` (+${count})` : '';

        return `${firstPart}${secondPart}`;
    }

    return null;
};

export const formatFilterRadioNumber = (props: Props) => {
    const { params, filter, content } = props;

    const minValue = params?.[`min_${filter?.paramName}`];
    const maxValue = params?.[`max_${filter?.paramName}`];

    const optionType = getRadioValue(minValue, maxValue);

    const valueLabel = content[optionType];

    if (minValue || maxValue) {
        return `${valueLabel}: ${minValue || maxValue}`;
    }

    return null;
};
