// @ts-nocheck
import React from 'react';
import { compose, withProps } from 'recompose';
import Tabs from '@sweepbright/uikit/build/esm/tabs';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl-sweepbright';
import { REQUESTS, PROPERTY_DETAILS_IMAGES, PROPERTY_UNIT_DETAILS_IMAGES } from '@/app.routing/routes';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';
import FormPane from '@/app.components/forms/FormPane';
import FormikWithConfirmation from '@/app.components/forms/helpers/FormikWithConfirmation';
import { createBucket, markFileAsViewed } from '../../../../../app.redux/actions/index';
import Embedly from '../../../../../app.components/embed/Embedly';
import FormPanel from '../../../../../app.components/forms/FormPanel/FormPanel';
import Input from '../../../../../app.components/forms/Input/Input';
import Requests from '../../../../../app.domains/properties/Requests';
import ImageGrids from '../../../../../app.domains/properties/elements/ImageGrids';

const ImagesForm = props => {
    const [uploading, setUploading] = React.useState(false);

    const { property, status, editingDisabled } = props;

    const renderVideo = (values, handleChange) => {
        return (
            <FormPanel className="mt-4">
                <Input
                    type="text"
                    value={values.video}
                    name="video"
                    onChange={handleChange}
                    placeholder={
                        <FormattedMessage id="forms.placeholders.video" defaultMessage="Paste link to video" />
                    }
                />
                {values.video && (
                    <div className="my-4">
                        <hr />
                        <Embedly
                            allowedTypes={['link', 'video', 'rich']}
                            url={values.video}
                            onSuccess={() => setUploading(false)}
                            onFailure={() => setUploading(false)}
                        />
                    </div>
                )}
            </FormPanel>
        );
    };

    const renderVirtualTour = (values, handleChange) => {
        return (
            <FormPanel className="mt-4">
                <Input
                    type="text"
                    value={values.virtual_tour}
                    name="virtual_tour"
                    onChange={handleChange}
                    placeholder={
                        <FormattedMessage
                            id="forms.placeholders.virtual_tour"
                            defaultMessage="Paste link to a virtual tour"
                        />
                    }
                />
                {values.virtual_tour && (
                    <div className="my-4">
                        <hr />
                        <Embedly
                            allowedTypes={['link', 'video', 'rich']}
                            url={values.virtual_tour}
                            onSuccess={() => setUploading(false)}
                            onFailure={() => setUploading(false)}
                            emptyMessage={
                                <FormattedMessage
                                    id="embedly.no-content"
                                    defaultMessage={"Sorry, can't find the content. Please check the link"}
                                />
                            }
                        />
                    </div>
                )}
            </FormPanel>
        );
    };

    return (
        <FormikWithConfirmation
            skipConfirmation={editingDisabled}
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
            route={props.route}
            enableReinitialize
        >
            {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => {
                const fields = {
                    images: values.images.map((value, idx) => {
                        return {
                            value,
                            onChange: value => {
                                setFieldValue(`images.${idx}`, value);
                            },
                        };
                    }),
                };

                return (
                    <FormPane
                        title={<FormattedMessage id="titles.property.images" defaultMessage="Add images and video" />}
                        onSubmit={handleSubmit}
                        status={status}
                        disabled={uploading || editingDisabled}
                        disabledText={
                            editingDisabled && (
                                <FormattedMessage id="property.status.archived" defaultMessage="Archived" />
                            )
                        }
                    >
                        <Tabs>
                            <Tabs.TabList>
                                <Tabs.Tab>
                                    <FormattedMessage id="forms.images.tabs.images" defaultMessage="Images" />
                                </Tabs.Tab>
                                <Tabs.Tab>
                                    <FormattedMessage id="forms.images.tabs.video" defaultMessage="Video" />
                                </Tabs.Tab>
                                <Tabs.Tab>
                                    <FormattedMessage
                                        id="forms.images.tabs.virtual_tour"
                                        defaultMessage="Virtual Tour"
                                    />
                                </Tabs.Tab>
                            </Tabs.TabList>
                            <Tabs.TabPanels>
                                <Tabs.TabPanel>
                                    <div className="mt-4">
                                        <PropertyImageRequests property={property} />
                                        <ImageGrids
                                            disabled={editingDisabled}
                                            fields={fields}
                                            propertyId={props.property.get('id')}
                                            createBucket={props.createBucket}
                                            setDisabled={setUploading}
                                            markFileAsViewed={props.markFileAsViewed}
                                            isSaving={isSubmitting}
                                            appendNewUploads
                                        />
                                    </div>
                                </Tabs.TabPanel>
                                <Tabs.TabPanel>{renderVideo(values, handleChange)}</Tabs.TabPanel>
                                <Tabs.TabPanel>{renderVirtualTour(values, handleChange)}</Tabs.TabPanel>
                            </Tabs.TabPanels>
                        </Tabs>
                    </FormPane>
                );
            }}
        </FormikWithConfirmation>
    );
};

export default compose(
    withProps(props => ({
        estateId: props.params.unit || props.params.property,
    })),
    withPropertyDetails('images'),
    connect(null, { createBucket, markFileAsViewed }),
)(ImagesForm);

function PropertyImageRequests({ property }) {
    const repository = new EstateRepository(property);
    const path = repository.isUnit() ? getUnitRequestsPath(repository) : getPropertyRequestsPath(repository);

    return <Requests type="image" to={REQUESTS(path)} propertyId={property.get('id')} />;

    function getUnitRequestsPath(repository) {
        return PROPERTY_UNIT_DETAILS_IMAGES(repository.getProject().get('id'), repository.getId());
    }

    function getPropertyRequestsPath(repository) {
        return PROPERTY_DETAILS_IMAGES(repository.getId());
    }
}
