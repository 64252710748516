import { createStore, compose } from 'redux';
import AuthManager from 'auth-manager';
import Immutable from 'immutable';
import { getBugsnagClient } from '@/app.config/bugsnag';
import reducers from '../reducers';
import sagas from '../sagas';
import middlewares from './Middlewares';
import devtools from './Middlewares/Devtools';
import sagaMiddleware from './Middlewares/Saga';

export function createStoreHelper(initialState = {}, history) {
    const isUserLoggedIn = AuthManager.isLoggedIn();

    const authState = {
        auth: {
            logged_in: isUserLoggedIn,
            is_logging_in: false,
        },
    };

    const enhancer = compose(...[middlewares(history), __DEVTOOLS__ && devtools].filter(Boolean));

    const store = createStore(reducers, { ...initialState, ...authState }, enhancer);
    const sagaRunner = sagaMiddleware.run(sagas);

    sagaRunner.done.catch(e => {
        getBugsnagClient().notify(e);
    });

    if (__DEVTOOLS__ && typeof window !== 'undefined') {
        require('immutable-devtools')(Immutable);
    }

    // Play nice with HMR
    // Because of this update https://github.com/reduxjs/react-redux/releases/tag/v2.0.0
    if (module.hot) {
        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers/index');

            if (typeof nextRootReducer === 'object' && typeof nextRootReducer.default === 'function') {
                store.replaceReducer(nextRootReducer.default);
            } else if (typeof nextRootReducer === 'function') {
                store.replaceReducer(nextRootReducer);
            }
        });
    }

    // export the sagaRunner and the store
    // usefull for testing
    return { store, sagaRunner };
}

export default function(initialState = {}, history) {
    const { store } = createStoreHelper(initialState, history);
    if (APP_ENV !== 'production') {
        window.store = store;
    }

    return store;
}
