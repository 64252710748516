import { put, select } from 'redux-saga/effects';
import { getContactById } from '@/app.redux/selectors/ContactsSelectors';
import SearchServiceRequests from '@/requests/SearchServiceRequests';
import { fetchProperties, fetchVendorEstates, mergeProperties, setProperties } from '../../actions';
import apiCall from '../Effects/apiCall';
import withPagination from '../Helpers/withPagination';

export default function* onFetchProperties(action) {
    yield withPagination(action.paginationKey, action.attributes, function* onFetch(settings) {
        // DEPRECATED: This is a temporary fix for removing /estates endpoint
        const properties: any = yield apiCall(new SearchServiceRequests().searchEstates, {
            page: settings.page,
            ...(settings.query ? { query: settings.query } : {}),
        });

        yield put(action.merge ? mergeProperties(properties) : setProperties(properties));

        return properties;
    });
}

export function* onFetchRelatedProperties(action: {
    contactId: string;
    attributes?: any;
    paginationKey: string;
    merge?: boolean;
}) {
    const { contactId, attributes, paginationKey, merge } = action;
    const contact = yield select(getContactById(contactId));
    if (contact.get('type') === 'vendor') {
        yield put(fetchVendorEstates(contactId, attributes));
    } else {
        yield put(fetchProperties(attributes, paginationKey, merge));
    }
}
