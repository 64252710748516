import PropTypes from 'prop-types';
import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import Modal from '@/app.components/elements/Modal';
import { FormattedMessage } from 'react-intl-sweepbright';

export default function UpdateMemberRightsModal({ close, onSubmit }) {
    return (
        <React.Fragment>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="modals.member-rights.title" defaultMessage="Update rights?" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage
                    id="modals.member-rights.body"
                    defaultMessage="Are you sure you want to update the rights of this member? This might grant him access to information that is confidential or block him from accessing certain data."
                    tagName="p"
                />
            </Modal.Body>
            <Modal.Footer className="text-center">
                <Button onClick={close}>
                    <FormattedMessage id="modals.member-rights.cancel" defaultMessage="Cancel" />
                </Button>
                <Button type="submit" variant="danger" onClick={onSubmit}>
                    <FormattedMessage id="modals.member-rights.cta" defaultMessage="Update Rights" />
                </Button>
            </Modal.Footer>
        </React.Fragment>
    );
}

UpdateMemberRightsModal.propTypes = {
    close: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};
