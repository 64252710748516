import { extractData } from '@sweepbright/margaret-fetcher';
import find from 'lodash/find';
import { APIChannelAccount, APIEstate } from '@/../server/graphql/resolvers/helpers/types';
import AbstractEstatesRequests from './AbstractEstatesRequests';
import { PriceAggregations } from './types';

export default class EstatesRequests extends AbstractEstatesRequests {
    resource = 'estates';

    index = (settings: Record<string, any> = {}) => {
        return this.withMeta()
            .setQueryParameters({ ...settings, bins: 30 })
            .withMiddleware(this.mapEstates)
            .get(this.resource);
    };

    getEstate = (estateId: string, { includes }: { includes?: string[] } = {}) => {
        return this.withoutMiddlewares()
            .withMiddleware(extractData)
            .withQueryParameters({ includes })
            .get<APIEstate>(`estates/${estateId}`);
    };

    loop = (id: string) => {
        return this.put(`${this.resource}/${id}/loop`);
    };

    removeNegotiator = (estateId: string, negotiatorId: string) => {
        return this.delete(`${this.resource}/${estateId}/negotiators/${negotiatorId}`);
    };

    // TODO: remove method
    updatePublishSettings = (estateId: string, attributes: object) => {
        return this.put(`${this.resource}/${estateId}/publish/settings`, attributes);
    };

    createPublication = (estateId: string, channelAccountId: string, config: FixMeType) => {
        return this.post(`/estates/${estateId}/channel-accounts/${channelAccountId}/ads`, {
            config: config,
        });
    };

    updatePublication = async (estateId: string, channelAccountId: string, config: FixMeType) => {
        return this.put(`/estates/${estateId}/channel-accounts/${channelAccountId}/ads`, {
            config,
        });
    };

    removePublication = async (estateId: string, channelAccountId: string) => {
        this.delete(`/estates/${estateId}/channel-accounts/${channelAccountId}/ads`);
    };

    refreshPublication = async (estateId: string, channelAccountId: string) => {
        this.post(`/estates/${estateId}/channel-accounts/${channelAccountId}/ads/refresh-status`);
    };

    // TODO: remove method
    publish = (
        estateId: string,
        attributes: {
            showPrice: boolean;
            showLocation: boolean;
            channelAccounts: string[];
        },
    ) => {
        return this.withQueryParameter(
            'includes',
            'latest_publication,estate_channel_account,channel,estate_channel_validation',
        ).post<APIChannelAccount[]>(`estates/${estateId}/publications`, {
            publish_price: attributes.showPrice,
            publish_location: attributes.showLocation,
            channel_accounts: attributes.channelAccounts,
        });
    };

    publishSettings = (estateId: string) => {
        return this.get(`${this.resource}/${estateId}/publish/settings`);
    };

    channels = (estateId: string) => {
        return this.get(`${this.resource}/${estateId}/publish/channels`);
    };

    interactions = (estateId: string, settings: object) => {
        return this.withMeta()
            .setQueryParameters(settings)
            .withQueryParameter('includes', ['contact'])
            .get(`properties/${estateId}/interactions`);
    };

    interactionsOverview = (estateId: string) => {
        return this.get(`properties/${estateId}/interactions-overview`);
    };

    zip = (estateId: string) => {
        return this.withoutMiddlewares()
            .withOptions({
                headers: {
                    Accept: 'application/vnd.sweepbright.v1+zip',
                },
            })
            .get(`${this.resource}/${estateId}`);
    };

    document = (estateId: string, type: string) => {
        return this.post(`${this.resource}/${estateId}/documents`, {
            type,
        });
    };

    pendingRequests = (estateId: string, params: { page?: number; file_type: string }) => {
        return this.withMeta()
            .setQueryParameters(params)
            .get(`estates/${estateId}/buckets`);
    };

    getChannelAccounts = (estateId: string) => {
        return this.withQueryParameter(
            'includes',
            'latest_publication,estate_channel_account,channel,estate_channel_validation',
        ).get<APIChannelAccount[]>(`/properties/${estateId}/channel-accounts`);
    };

    getChannelAccountById = async (estateId: string, channelAccountId: string) => {
        return this.get<APIChannelAccount>(`/estates/${estateId}/channel-accounts/${channelAccountId}`);
    };

    getChannelAccount = async (estateId: string, estateChannelAccountId: string) => {
        // so, given the current API limitations, I fetch all the channel accounts
        // and filter back the one I need
        const allChannelAccounts = await this.getChannelAccounts(estateId);
        // get the channel account associated with the given estateChannelAccountId
        const channelAccount = find(allChannelAccounts, ['estate_channel_account.data.id', estateChannelAccountId]);
        if (!channelAccount) {
            throw new Error(
                'channel account not found for estate ' +
                    estateId +
                    'and estate_channel_account ' +
                    estateChannelAccountId,
            );
        }

        return channelAccount;
    };

    saveChannelAccountConfig = (estateId: string, channelAccountId: string, settings: any) => {
        return this.withQueryParameter(
            'includes',
            'latest_publication,estate_channel_account,channel,estate_channel_validation',
        ).put(`/estates/${estateId}/channel-accounts/${channelAccountId}`, settings);
    };

    getPriceAggregations = (negotiation: 'sale' | 'let' | 'all', bins = 30): Promise<PriceAggregations> => {
        return this.withMeta()
            .setQueryParameters({ negotiation, bins, limit: 1 })
            .get('/estates')
            .then((res: { meta: { aggregations: PriceAggregations } }) => res.meta.aggregations);
    };

    getTimeline = (
        estateId: string,
        {
            limit,
            after,
            before,
            contactId,
        }: {
            limit?: number;
            after?: string;
            before?: string;
            contactId?: string;
        },
    ) => {
        const parameters: {
            limit?: number;
            after?: string;
            before?: string;
            contact?: string;
        } = {};

        if (limit) {
            parameters.limit = limit;
        }

        if (after) {
            parameters.after = after;
        }

        if (before) {
            parameters.before = before;
        }

        if (contactId) {
            parameters.contact = contactId;
        }

        return this.withMeta()
            .withQueryParameters(parameters)
            .get(`/estates/${estateId}/timeline`);
    };

    getInteractedContacts = (
        estateId: string,
        settings: { interested?: boolean; after?: string; before?: string; type: 'lead' | 'vendor' },
    ) => {
        const queryParameters: {
            is_interested?: boolean;
            includes?: string;
            after?: string;
            before?: string;
            limit?: number;
            type?: 'lead' | 'vendor';
        } = {
            includes: 'latest_activity',
            limit: 10,
        };

        if ('interested' in settings) {
            queryParameters.is_interested = settings.interested;
        }

        if ('after' in settings) {
            queryParameters.after = settings.after;
        }

        if ('before' in settings) {
            queryParameters.before = settings.before;
        }
        if ('type' in settings) {
            queryParameters.type = settings.type;
        }

        return this.withMeta()
            .withQueryParameters(queryParameters)
            .get(`/estates/${estateId}/interacted-contacts`);
    };

    getVendors = (estateId: string) => {
        return this.get(`/estates/${estateId}/vendors`);
    };

    getBuyers = (estateId: string) => {
        return this.get(`/estates/${estateId}/leads`);
    };

    getSubtypes = (params: { type: string; country: string }) => {
        return this.withQueryParameters(params).get(`/estates/config/subtypes`);
    };
}
