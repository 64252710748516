import gql from 'graphql-tag';

const EstateChannelAccountEdgeFragment = gql`
    fragment EstateChannelAccountEdgeFragment on EstateChannelAccountConnectionEdge {
        id
        publicationStatus
        configurationStatus
        missingFields {
            id
            node {
                path
                errorMessage
            }
        }
        unitsMissingFields {
            edges {
                id
                estateId
                node {
                    errorMessage
                    path
                }
            }
        }
        canPublish
        lastPublication {
            id
            publishedAt
            link
        }
        node {
            id
            name
            channel {
                id
                name
                logoUrl
                slug
                supportsUnpublishing
                supportsRepublishing
            }
        }
    }
`;

export default EstateChannelAccountEdgeFragment;
