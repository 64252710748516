// @ts-nocheck
import React from 'react';
import Downshift from 'downshift';
import { MenuItem } from 'react-bootstrap';
import classNames from 'classnames';
import { Map } from 'immutable';
import debounce from 'lodash/debounce';
import { DEFAULT_LOCALE } from '@sweepbright/webapp-shared/Config/intl';
import Input from '@/app.components/forms/Input/Input';
import {
    formattedDescription,
    getAddressComponent,
    PlaceDetail,
    PlacesAutocompleteService,
    Suggestion,
    GooglePlacesAutocompleteService,
} from '@/app.utils/services/Helpers/address';
import { withCompany } from '@/app.hooks/useCompany';

type LocationDescriptor = Partial<{
    streetNumber: string;
    latitude: number;
    longitude: number;
    postal_code: string;
    city: string;
    street: string;
    addition: string;
    floor: string;
    box: string;
    state: string;
    district: string;
    formattedAddress: string;
}>;

type Props = {
    country?: string;
    label: string;
    language: string;
    onChange: (address: string) => void;
    onBlur: (evt: React.FormEvent<HTMLInputElement>) => void;
    onLocationSelected: (location: LocationDescriptor) => void;
    value: string;
    company: Map<string, any>;
    inputRef?: React.Ref<HTMLInputElement>;
    touched?: boolean;
    error?: React.ReactNode;
    allowClear?: boolean;
};

type State = {
    suggestions: Suggestion[];
};

class AddressAutocomplete extends React.Component<Props, State> {
    static defaultProps = {
        language: 'fr',
    };

    getSuggestions = debounce(async query => {
        if (!query) {
            this.setState({ suggestions: [] });

            return;
        }
        const suggestions = await this.autocompleteService.getQuerySuggestions(query, {
            country: this.props.country,
            language: this.props.company.get('locale', DEFAULT_LOCALE),
        });
        this.setState({ suggestions });
    }, 500);

    state = {
        suggestions: [],
    };

    autocompleteService: PlacesAutocompleteService = new GooglePlacesAutocompleteService();

    getPlaceDetails = async (place: Suggestion) => {
        try {
            const placeDetails: PlaceDetail = await this.autocompleteService.getPlaceDetails(place.place_id, {
                language: this.props.company.get('locale', DEFAULT_LOCALE),
            });

            const postCode = getAddressComponent(placeDetails, 'postal_code')?.long_name;
            const city =
                getAddressComponent(placeDetails, 'locality')?.long_name ||
                getAddressComponent(placeDetails, 'postal_town')?.long_name;
            const street = getAddressComponent(placeDetails, 'route')?.long_name;
            const streetNumber = getAddressComponent(placeDetails, 'street_number')?.long_name;
            const state = getAddressComponent(placeDetails, 'administrative_area_level_1');
            const district =
                getAddressComponent(placeDetails, 'administrative_area_level_2')?.long_name ||
                getAddressComponent(placeDetails, 'locality')?.long_name;
            const loc = {
                streetNumber,
                latitude: placeDetails.geometry.location.lat,
                longitude: placeDetails.geometry.location.lng,
                postal_code: postCode,
                city,
                street,
                addition: '',
                floor: '',
                box: '',
                state,
                district,
                formattedAddress: placeDetails.formatted_address,
            };
            this.props.onLocationSelected(loc);
        } catch (err) {
            // failed to get the address, return the current input value then
        } finally {
            // clear the suggestion
            this.setState({ suggestions: [] });
        }
    };

    handleChange = (value: string | Suggestion) => {
        if (typeof value === 'string') {
            this.props.onChange(value);
            this.getSuggestions(value);
        }
    };

    render() {
        const hasResults = this.state.suggestions.length > 0;

        return (
            <Downshift
                selectedItem={null}
                inputValue={this.props.value}
                onInputValueChange={this.handleChange}
                onChange={this.getPlaceDetails}
                itemToString={item =>
                    (({
                        item,
                    } as any) as string)
                }
            >
                {({ getInputProps, getItemProps, highlightedIndex, getMenuProps, isOpen }) => (
                    <div className="dropdown open">
                        <Input
                            type="text"
                            label={this.props.label}
                            allowClear={this.props.allowClear}
                            {...getInputProps({
                                // we need to pass the onBlur up
                                // so the field is marked as dirty when editing
                                onBlur: this.props.onBlur,
                                touched: this.props.touched,
                                error: this.props.error,
                            })}
                            inputRef={this.props.inputRef}
                            data-testid={this.props['data-testid']}
                            autoComplete="off"
                            name="address"
                        />
                        {hasResults && isOpen ? (
                            <ul className="dropdown-menu" {...getMenuProps({ style: { width: '100%' } })}>
                                {this.state.suggestions.map((item: Suggestion, index) => (
                                    <MenuItem
                                        {...getItemProps({ item, index })}
                                        key={item.id}
                                        active={highlightedIndex === index}
                                    >
                                        <div
                                            className={classNames('truncate w-full', {
                                                'text-white': highlightedIndex === index,
                                            })}
                                        >
                                            <span dangerouslySetInnerHTML={{ __html: formattedDescription(item) }} />
                                        </div>
                                    </MenuItem>
                                ))}
                            </ul>
                        ) : null}
                    </div>
                )}
            </Downshift>
        );
    }
}

export default withCompany(AddressAutocomplete);
