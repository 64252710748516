import moment from 'moment';

export function configMoment() {
    // get the current moment locale
    const locale = moment.locale();

    // we want to set the
    // first day of the week to respect the
    // ISO-8601 for Europe
    // if we move to the Estates at some point we may have to revisit this
    moment.updateLocale('en', {
        week: {
            dow: 1, // First day of week is Monday
            doy: 4, // First week of year must contain 4 January (7 + 1 - 4)
        },
    });

    moment.relativeTimeRounding(Math.floor);
    moment.relativeTimeThreshold('m', 60);
    moment.relativeTimeThreshold('h', 24);
    moment.relativeTimeThreshold('d', 30);
    moment.locale(locale);
}
