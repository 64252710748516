import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'react-use';
import Immutable, { Map } from 'immutable';
import { compose, withProps } from 'recompose';
import { reduxForm as form } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import PropertyMap from '@/app.domains/properties/PropertyMap';
import FormPane from '@/app.components/forms/FormPane';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';
import { isFieldVisible } from '@/app.data/Translations/Location';
import { withActivePropertyConfirmation } from '@/app.utils/Decorators/withActivePropertyConfirmation';
import { FormPanel } from '../../../../app.components/index';
import Location from '../../../../app.components/forms/Location/Location';
import { getComponentsFromLocation, locationProperties } from '../../../../app.data/index';
import { statesByCountry } from '../../../../app.data/Countries';
import { EstateRepository, Validator } from '../../../../app.utils/services/index';

const LocationForm: React.FunctionComponent<{
    values: any;
    property: Map<string, any>;
    handleSubmit: () => void;
    status: any;
    fields: any;
    editingDisabled: boolean;
}> = props => {
    const { fields, values, property, handleSubmit, status, editingDisabled } = props;
    const { location } = fields;

    const [editing, setEditing] = useState(true);

    const type = property.get('type');
    const addressComponents = React.useMemo(() => getComponentsFromLocation(values.location), [values.location]);

    useDebounce(
        () => {
            setEditing(true);
        },
        100,
        [values.location],
    );

    return (
        //@ts-ignore
        <FormPane
            disabled={editingDisabled}
            disabledText={
                editingDisabled && <FormattedMessage id="property.status.archived" defaultMessage="Archived" />
            }
            title={<FormattedMessage id="titles.property.location" defaultMessage="Add a location" />}
            onSubmit={handleSubmit}
            status={status}
        >
            <FormPanel>
                <ErrorBoundary>
                    <Location
                        entityType="property"
                        property={property}
                        location={location}
                        onBlur={() => {
                            setEditing(false);
                        }}
                    />
                </ErrorBoundary>
                <ErrorBoundary>
                    <PropertyMap type={type} editing={editing} addressComponents={addressComponents} />
                </ErrorBoundary>
            </FormPanel>
        </FormPane>
    );
};

LocationForm.propTypes = {
    fields: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    //@ts-ignore
    property: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export default compose(
    withProps(props => ({
        //@ts-ignore
        estateId: props.params.unit || props.params.property,
    })),
    withPropertyDetails('location'),
    form({
        form: 'properties/location',
        fields: locationProperties.map(type => `location.${type}`),
        validate: (attributes, props) => {
            let errors = props.property.get('id')
                ? Immutable.fromJS(Validator.validateWithPropertySchema(attributes, props))
                : Immutable.Map();

            if (Object.keys(statesByCountry).includes(attributes.location.country)) {
                if (!attributes.location.province_or_state) {
                    errors = errors.setIn(
                        ['location', 'province_or_state'],
                        <FormattedMessage id="forms.rules.required" defaultMessage="This is a required field" />,
                    );
                }
            }

            const repo = new EstateRepository(
                props.property.setIn(['attributes', 'location'], Map(attributes.location)),
            );
            const visibilityContext = repo.getVisibilityContext();

            errors = errors.update('location', Immutable.Map(), location =>
                location.filter((value, key) => {
                    return isFieldVisible(key, visibilityContext);
                }),
            );

            return errors.toJS();
        },
    }),
    withActivePropertyConfirmation,
)(LocationForm);
