import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { PartialOffer } from '../mappers/offer';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } &
    { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    JSON: any;
    Currency: any;
};

export type AccountPublicationSettings = {
    readonly publishPrice?: Maybe<Scalars['Boolean']>;
    readonly publishLocation?: Maybe<Scalars['Boolean']>;
};

export type Activity = {
    readonly id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    readonly timestamp?: Maybe<Scalars['DateTime']>;
    readonly details?: Maybe<ActivityDetails>;
    readonly action?: Maybe<ActivityAction>;
};

export enum ActivityAction {
    Feedback = 'FEEDBACK',
    Details = 'DETAILS',
}

export type ActivityConnection = {
    readonly __typename?: 'ActivityConnection';
    /** A list of edges */
    readonly edges: ReadonlyArray<ActivityEdge>;
    /** A list of nodes */
    readonly nodes: ReadonlyArray<Activity>;
    /** The total number of items in the connection */
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
};

export type ActivityDetails = {
    readonly id: Scalars['ID'];
};

export type ActivityEdge = {
    readonly __typename?: 'ActivityEdge';
    readonly node: Activity;
    readonly cursor?: Maybe<Scalars['String']>;
};

export type AddContactMutationInput = {
    readonly firstName?: Maybe<Scalars['String']>;
    readonly lastName?: Maybe<Scalars['String']>;
    readonly email?: Maybe<Scalars['String']>;
    readonly phone?: Maybe<Scalars['String']>;
    readonly locale?: Maybe<Scalars['String']>;
    readonly type: Scalars['String'];
    readonly pronouns?: Maybe<Scalars['String']>;
};

export type AddContactMutationPayload = {
    readonly __typename?: 'AddContactMutationPayload';
    readonly success: Scalars['Boolean'];
    /** The created contact after the mutation */
    readonly contact?: Maybe<Contact>;
    readonly validationErrors?: Maybe<Scalars['JSON']>;
};

export type AddEstateInput = {
    readonly type: Scalars['String'];
    readonly kind: Scalars['String'];
    readonly negotiation: Scalars['String'];
};

export type AddEstatePayload = {
    readonly __typename?: 'AddEstatePayload';
    readonly estate: Estate;
};

export type AddIntegrationInput = {
    readonly integration: Scalars['JSON'];
};

export type AddIntegrationPayload = {
    readonly __typename?: 'AddIntegrationPayload';
    readonly user: User;
};

export type AddLeadPreferenceInput = {
    readonly contactId: Scalars['ID'];
};

export type AddLeadPreferencePayload = {
    readonly __typename?: 'AddLeadPreferencePayload';
    /** The created lead preference */
    readonly preference?: Maybe<BuyerPreference>;
};

export type AddLocationPreferenceInput = {
    readonly leadId: Scalars['ID'];
    /** The id of the buyer preference to which the location preference should be added */
    readonly preferenceId?: Maybe<Scalars['ID']>;
    readonly locationPreference: LocationPreferenceInput;
    readonly geoJSONData?: Maybe<GeoJsonDataInput>;
    readonly postalCodesData?: Maybe<PostalCodesDataInput>;
};

export type AddLocationPreferencePayload = {
    readonly __typename?: 'AddLocationPreferencePayload';
    /** The new location preference */
    readonly locationPreference: LocationPreference;
};

export type AddLocationTemplatePayload = {
    readonly __typename?: 'AddLocationTemplatePayload';
    /** The new location preference template added to the office */
    readonly locationTemplate: LocationPreference;
    /** The id of the office to which the location template was added */
    readonly officeId: Scalars['ID'];
};

export type AddUnitToProjectInput = {
    readonly projectId: Scalars['ID'];
};

export type AddUnitToProjectPayload = {
    readonly __typename?: 'AddUnitToProjectPayload';
    readonly project?: Maybe<Estate>;
    readonly unit?: Maybe<Estate>;
};

export type ArchiveEstateInput = {
    /** The ID of the estate to archive */
    readonly estateId: Scalars['ID'];
    readonly estateType: EstateType;
};

export type ArchiveEstatePayload = {
    readonly __typename?: 'ArchiveEstatePayload';
    /** The estate that was archived */
    readonly estate?: Maybe<Estate>;
};

export type ArchiveLeadsInput = {
    readonly leadIds: ReadonlyArray<Scalars['ID']>;
};

export type ArchiveLeadsPayload = {
    readonly __typename?: 'ArchiveLeadsPayload';
    readonly company: Company;
};

export type AssignBuyersToEstateInput = {
    readonly estateId: Scalars['ID'];
    readonly buyerIds: ReadonlyArray<Scalars['ID']>;
};

export type AssignBuyersToEstatePayload = {
    readonly __typename?: 'AssignBuyersToEstatePayload';
    readonly estate?: Maybe<Estate>;
};

export type AssignEstatesToNegotiatorInput = {
    readonly estateIds: ReadonlyArray<Scalars['ID']>;
    readonly userId: Scalars['ID'];
};

export type AssignEstatesToNegotiatorPayload = {
    readonly __typename?: 'AssignEstatesToNegotiatorPayload';
    readonly success?: Maybe<Scalars['Boolean']>;
    readonly negotiator?: Maybe<User>;
};

export type AssignLeadsInput = {
    /** The office the leads are going to be assigned */
    readonly officeId: Scalars['ID'];
    /** The id of the leads */
    readonly leadIds: ReadonlyArray<Scalars['ID']>;
};

export type AssignLeadsPayload = {
    readonly __typename?: 'AssignLeadsPayload';
    readonly success: Scalars['Boolean'];
    /** The leads that were assigned to the office */
    readonly assignedLeads: ReadonlyArray<Contact>;
};

export type AssignLegalEntityToEstateInput = {
    readonly estateId: Scalars['ID'];
    readonly legalEntityId: Scalars['ID'];
};

export type AssignLegalEntityToPayload = {
    readonly __typename?: 'AssignLegalEntityToPayload';
    readonly estate?: Maybe<Estate>;
};

export type AssignVendorsToEstateInput = {
    readonly estateId: Scalars['ID'];
    readonly vendorIds: ReadonlyArray<Scalars['ID']>;
};

export type AssignVendorsToEstatePayload = {
    readonly __typename?: 'AssignVendorsToEstatePayload';
    readonly estate?: Maybe<Estate>;
};

export type AuthCheckInput = {
    readonly operation: Scalars['String'];
    readonly requestId: Scalars['String'];
    readonly resourceId: Scalars['String'];
    readonly resourceType: Scalars['String'];
    readonly context?: Maybe<AuthCheckIputContext>;
};

export type AuthCheckIputContext = {
    readonly attendees?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    readonly propertyId?: Maybe<Scalars['String']>;
    readonly direction?: Maybe<Scalars['String']>;
    readonly buyerIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    readonly ownerIds?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type AuthenticatePayload = {
    readonly __typename?: 'AuthenticatePayload';
    readonly success: Scalars['Boolean'];
    readonly accessToken?: Maybe<Scalars['String']>;
};

export type BuyerLead = {
    readonly __typename?: 'BuyerLead';
    readonly id: Scalars['ID'];
    readonly firstName?: Maybe<Scalars['String']>;
    readonly lastName?: Maybe<Scalars['String']>;
    readonly email?: Maybe<Scalars['String']>;
    readonly phone?: Maybe<Scalars['String']>;
    readonly createdAt?: Maybe<Scalars['DateTime']>;
    readonly updatedAt?: Maybe<Scalars['DateTime']>;
    readonly contactRequests?: Maybe<ContactRequestConnection>;
};

export type BuyerPreference = {
    readonly id: Scalars['ID'];
    readonly negotiation: Negotiation;
    readonly isInvestor?: Maybe<Scalars['Boolean']>;
    readonly max_liveable_area?: Maybe<Scalars['Float']>;
    readonly min_liveable_area?: Maybe<Scalars['Float']>;
    readonly max_plot_area?: Maybe<Scalars['Float']>;
    readonly min_plot_area?: Maybe<Scalars['Float']>;
    readonly max_net_area?: Maybe<Scalars['Float']>;
    readonly min_net_area?: Maybe<Scalars['Float']>;
    readonly max_gross_area?: Maybe<Scalars['Float']>;
    readonly min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    readonly type: Scalars['String'];
    /** The subtypes the user is interested in */
    readonly subtypes?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** The minimun condition of the property */
    readonly condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    readonly budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    readonly amenities?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** Free form text with the wishes */
    readonly wishesText?: Maybe<Scalars['String']>;
    readonly locations?: Maybe<LocationPreferenceConnection>;
};

export type BuyerPreferenceLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceEdge = {
    readonly __typename?: 'BuyerPreferenceEdge';
    readonly node?: Maybe<BuyerPreference>;
};

export type BuyerPreferenceForApartment = BuyerPreference & {
    readonly __typename?: 'BuyerPreferenceForApartment';
    readonly id: Scalars['ID'];
    readonly negotiation: Negotiation;
    readonly isInvestor?: Maybe<Scalars['Boolean']>;
    readonly max_liveable_area?: Maybe<Scalars['Float']>;
    readonly min_liveable_area?: Maybe<Scalars['Float']>;
    readonly max_plot_area?: Maybe<Scalars['Float']>;
    readonly min_plot_area?: Maybe<Scalars['Float']>;
    readonly max_net_area?: Maybe<Scalars['Float']>;
    readonly min_net_area?: Maybe<Scalars['Float']>;
    readonly max_gross_area?: Maybe<Scalars['Float']>;
    readonly min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    readonly type: Scalars['String'];
    /** The subtypes the user is interested in */
    readonly subtypes?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** The minimun condition of the property */
    readonly condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    readonly budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    readonly amenities?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** Free form text with the wishes */
    readonly wishesText?: Maybe<Scalars['String']>;
    readonly locations: LocationPreferenceConnection;
    /** The minimun number of bedrooms */
    readonly minBedrooms?: Maybe<Scalars['Int']>;
    /** Livable area (structure.livable_area.size) */
    readonly minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForApartmentLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceForCommercial = BuyerPreference & {
    readonly __typename?: 'BuyerPreferenceForCommercial';
    readonly id: Scalars['ID'];
    readonly negotiation: Negotiation;
    readonly isInvestor?: Maybe<Scalars['Boolean']>;
    readonly max_liveable_area?: Maybe<Scalars['Float']>;
    readonly min_liveable_area?: Maybe<Scalars['Float']>;
    readonly max_plot_area?: Maybe<Scalars['Float']>;
    readonly min_plot_area?: Maybe<Scalars['Float']>;
    readonly max_net_area?: Maybe<Scalars['Float']>;
    readonly min_net_area?: Maybe<Scalars['Float']>;
    readonly max_gross_area?: Maybe<Scalars['Float']>;
    readonly min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    readonly type: Scalars['String'];
    /** The subtypes the user is interested in */
    readonly subtypes?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** The minimun condition of the property */
    readonly condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    readonly budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    readonly amenities?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** Free form text with the wishes */
    readonly wishesText?: Maybe<Scalars['String']>;
    readonly locations: LocationPreferenceConnection;
    /** Gross area (structure.gross_area.size) */
    readonly minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForCommercialLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceForHouse = BuyerPreference & {
    readonly __typename?: 'BuyerPreferenceForHouse';
    readonly id: Scalars['ID'];
    readonly negotiation: Negotiation;
    readonly isInvestor?: Maybe<Scalars['Boolean']>;
    readonly max_liveable_area?: Maybe<Scalars['Float']>;
    readonly min_liveable_area?: Maybe<Scalars['Float']>;
    readonly max_plot_area?: Maybe<Scalars['Float']>;
    readonly min_plot_area?: Maybe<Scalars['Float']>;
    readonly max_net_area?: Maybe<Scalars['Float']>;
    readonly min_net_area?: Maybe<Scalars['Float']>;
    readonly max_gross_area?: Maybe<Scalars['Float']>;
    readonly min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    readonly type: Scalars['String'];
    /** The subtypes the user is interested in */
    readonly subtypes?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** The minimun condition of the property */
    readonly condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    readonly budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    readonly amenities?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** Free form text with the wishes */
    readonly wishesText?: Maybe<Scalars['String']>;
    readonly locations: LocationPreferenceConnection;
    /** The minimun number of bedrooms */
    readonly minBedrooms?: Maybe<Scalars['Int']>;
    /** Livable area (structure.livable_area.size) */
    readonly minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForHouseLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceForLand = BuyerPreference & {
    readonly __typename?: 'BuyerPreferenceForLand';
    readonly id: Scalars['ID'];
    readonly negotiation: Negotiation;
    readonly isInvestor?: Maybe<Scalars['Boolean']>;
    readonly max_liveable_area?: Maybe<Scalars['Float']>;
    readonly min_liveable_area?: Maybe<Scalars['Float']>;
    readonly max_plot_area?: Maybe<Scalars['Float']>;
    readonly min_plot_area?: Maybe<Scalars['Float']>;
    readonly max_net_area?: Maybe<Scalars['Float']>;
    readonly min_net_area?: Maybe<Scalars['Float']>;
    readonly max_gross_area?: Maybe<Scalars['Float']>;
    readonly min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    readonly type: Scalars['String'];
    /** The subtypes the user is interested in */
    readonly subtypes?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** The minimun condition of the property */
    readonly condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    readonly budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    readonly amenities?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** Free form text with the wishes */
    readonly wishesText?: Maybe<Scalars['String']>;
    readonly locations: LocationPreferenceConnection;
    /** Plot area area (structure.plot_area.size) */
    readonly minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForLandLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceForOffice = BuyerPreference & {
    readonly __typename?: 'BuyerPreferenceForOffice';
    readonly id: Scalars['ID'];
    readonly negotiation: Negotiation;
    readonly isInvestor?: Maybe<Scalars['Boolean']>;
    readonly max_liveable_area?: Maybe<Scalars['Float']>;
    readonly min_liveable_area?: Maybe<Scalars['Float']>;
    readonly max_plot_area?: Maybe<Scalars['Float']>;
    readonly min_plot_area?: Maybe<Scalars['Float']>;
    readonly max_net_area?: Maybe<Scalars['Float']>;
    readonly min_net_area?: Maybe<Scalars['Float']>;
    readonly max_gross_area?: Maybe<Scalars['Float']>;
    readonly min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    readonly type: Scalars['String'];
    /** The subtypes the user is interested in */
    readonly subtypes?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** The minimun condition of the property */
    readonly condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    readonly budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    readonly amenities?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** Free form text with the wishes */
    readonly wishesText?: Maybe<Scalars['String']>;
    readonly locations: LocationPreferenceConnection;
    /** Net area (structure.net_area.size) */
    readonly minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForOfficeLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferenceForParking = BuyerPreference & {
    readonly __typename?: 'BuyerPreferenceForParking';
    readonly id: Scalars['ID'];
    readonly negotiation: Negotiation;
    readonly isInvestor?: Maybe<Scalars['Boolean']>;
    readonly max_liveable_area?: Maybe<Scalars['Float']>;
    readonly min_liveable_area?: Maybe<Scalars['Float']>;
    readonly max_plot_area?: Maybe<Scalars['Float']>;
    readonly min_plot_area?: Maybe<Scalars['Float']>;
    readonly max_net_area?: Maybe<Scalars['Float']>;
    readonly min_net_area?: Maybe<Scalars['Float']>;
    readonly max_gross_area?: Maybe<Scalars['Float']>;
    readonly min_gross_area?: Maybe<Scalars['Float']>;
    /** The type of property the lead is interested in */
    readonly type: Scalars['String'];
    /** The subtypes the user is interested in */
    readonly subtypes?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** The minimun condition of the property */
    readonly condition?: Maybe<Scalars['String']>;
    /** The lead budget */
    readonly budget?: Maybe<PreferenceBudget>;
    /** A list of amenites */
    readonly amenities?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** Free form text with the wishes */
    readonly wishesText?: Maybe<Scalars['String']>;
    readonly locations: LocationPreferenceConnection;
    /** Gross area (structure.gross_area.size) */
    readonly minArea?: Maybe<Scalars['Float']>;
};

export type BuyerPreferenceForParkingLocationsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type BuyerPreferencesConnection = {
    readonly __typename?: 'BuyerPreferencesConnection';
    readonly nodes: ReadonlyArray<BuyerPreference>;
    readonly edges: ReadonlyArray<BuyerPreferenceEdge>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int'];
};

export type Channel = {
    readonly __typename?: 'Channel';
    readonly id: Scalars['ID'];
    readonly logoUrl?: Maybe<Scalars['String']>;
    readonly name: Scalars['String'];
    readonly description?: Maybe<Scalars['String']>;
    readonly type: Scalars['String'];
    readonly url?: Maybe<Scalars['String']>;
    readonly slug: Scalars['String'];
    readonly countries: ReadonlyArray<Scalars['String']>;
    /** @deprecated Use `settings.supportsUnpublishing` field */
    readonly supportsUnpublishing: Scalars['Boolean'];
    /** @deprecated Use `settings.supportsRepublishing` field */
    readonly supportsRepublishing: Scalars['Boolean'];
    readonly settings: ChannelSettings;
    readonly accounts: ReadonlyArray<ChannelAccount>;
};

export type ChannelAccount = {
    readonly __typename?: 'ChannelAccount';
    readonly id: Scalars['ID'];
    readonly name: Scalars['String'];
    readonly credentials: ChannelAccountCredentials;
    readonly status: Scalars['String'];
    readonly office_id?: Maybe<Scalars['String']>;
    /** @deprecated Use `credentials.accessToken` field */
    readonly accessToken?: Maybe<Scalars['String']>;
    /** @deprecated Use `credentials.accessTokenSecret` field */
    readonly accessTokenSecret?: Maybe<Scalars['String']>;
    readonly channel: Channel;
    readonly webhook?: Maybe<Scalars['String']>;
};

export type ChannelAccountCredentials = {
    readonly __typename?: 'ChannelAccountCredentials';
    readonly channelId: Scalars['String'];
    readonly accessToken?: Maybe<Scalars['String']>;
    readonly accessTokenSecret?: Maybe<Scalars['String']>;
    readonly additionalAccessId?: Maybe<Scalars['String']>;
    readonly name?: Maybe<Scalars['String']>;
};

export type ChannelAccountInput = {
    readonly id?: Maybe<Scalars['ID']>;
    readonly channel: Scalars['String'];
    readonly officeId?: Maybe<Scalars['String']>;
    readonly accessToken?: Maybe<Scalars['String']>;
    readonly accessTokenSecret?: Maybe<Scalars['String']>;
    readonly name?: Maybe<Scalars['String']>;
    readonly facebookPageName?: Maybe<Scalars['String']>;
    readonly facebookPageAccessToken?: Maybe<Scalars['String']>;
    readonly twitterUsername?: Maybe<Scalars['String']>;
    readonly additionalAccessId?: Maybe<Scalars['String']>;
    readonly webhook?: Maybe<Scalars['String']>;
};

export type ChannelSettings = {
    readonly __typename?: 'ChannelSettings';
    readonly supportsUnpublishing: Scalars['Boolean'];
    readonly supportsRepublishing: Scalars['Boolean'];
    readonly allowsMultipleAccounts: Scalars['Boolean'];
};

export type CloneLocationPreferenceTemplateInput = {
    readonly officeId: Scalars['ID'];
    readonly locationId: Scalars['ID'];
    readonly leadId: Scalars['ID'];
    /** TODO: make this required */
    readonly preferenceId?: Maybe<Scalars['ID']>;
};

export type CloneLocationTemplatePayload = {
    readonly __typename?: 'CloneLocationTemplatePayload';
    /** The clone of the location template now as part of the user location preferences */
    readonly locationPreference: LocationPreference;
};

export type Company = {
    readonly __typename?: 'Company';
    readonly id: Scalars['ID'];
    readonly leads: CompanyLeadsConnection;
    readonly lead?: Maybe<Contact>;
    readonly offices?: Maybe<ReadonlyArray<Office>>;
    readonly admins: CompanyAdminsConnection;
};

export type CompanyLeadsArgs = {
    type: ContactType;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
    sortField?: Maybe<Scalars['String']>;
    sortOrder?: Maybe<Scalars['String']>;
};

export type CompanyLeadArgs = {
    id: Scalars['ID'];
};

export type CompanyAdminsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
};

export type CompanyAdminConnectionEdge = {
    readonly __typename?: 'CompanyAdminConnectionEdge';
    readonly node: User;
};

export type CompanyAdminsConnection = {
    readonly __typename?: 'CompanyAdminsConnection';
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
    readonly edges: ReadonlyArray<CompanyAdminConnectionEdge>;
    readonly nodes: ReadonlyArray<User>;
};

export type CompanyLeadConnectionEdge = {
    readonly __typename?: 'CompanyLeadConnectionEdge';
    readonly node: BuyerLead;
};

export type CompanyLeadsConnection = {
    readonly __typename?: 'CompanyLeadsConnection';
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
    readonly edges: ReadonlyArray<CompanyLeadConnectionEdge>;
    readonly nodes: ReadonlyArray<BuyerLead>;
};

export type CompanyTeam = {
    readonly __typename?: 'CompanyTeam';
    readonly id: Scalars['ID'];
    readonly name: Scalars['String'];
    readonly settings?: Maybe<CompanyTeamSettings>;
    readonly head_negotiator?: Maybe<CompanyTeamHeadNegotiator>;
};

export type CompanyTeamHeadNegotiator = {
    readonly __typename?: 'CompanyTeamHeadNegotiator';
    readonly data?: Maybe<Scalars['JSON']>;
};

export type CompanyTeamResponse = {
    readonly __typename?: 'CompanyTeamResponse';
    readonly data?: Maybe<CompanyTeam>;
};

export type CompanyTeamSettings = {
    readonly __typename?: 'CompanyTeamSettings';
    readonly data?: Maybe<Scalars['JSON']>;
};

export type CompanyTeamsMeta = {
    readonly __typename?: 'CompanyTeamsMeta';
    readonly pagination?: Maybe<CompanyTeamsMetaPagination>;
};

export type CompanyTeamsMetaPagination = {
    readonly __typename?: 'CompanyTeamsMetaPagination';
    readonly current_page?: Maybe<Scalars['Int']>;
    readonly total_pages?: Maybe<Scalars['Int']>;
    readonly total?: Maybe<Scalars['Int']>;
    readonly per_page?: Maybe<Scalars['Int']>;
};

export type CompanyTeamsResponse = {
    readonly __typename?: 'CompanyTeamsResponse';
    readonly data?: Maybe<ReadonlyArray<Maybe<CompanyTeam>>>;
    readonly meta?: Maybe<CompanyTeamsMeta>;
};

export enum ConfigurationStatus {
    MissingUnits = 'MISSING_UNITS',
    MissingFields = 'MISSING_FIELDS',
    NotSupported = 'NOT_SUPPORTED',
    NotConfigured = 'NOT_CONFIGURED',
    Ok = 'OK',
}

export type Contact = {
    readonly id: Scalars['ID'];
    /** The first name of the contact */
    readonly firstName?: Maybe<Scalars['String']>;
    /** The last name of the contact */
    readonly lastName: Scalars['String'];
    /** The email address of the contact */
    readonly email?: Maybe<Scalars['String']>;
    /** The phone number of the contact */
    readonly phone?: Maybe<Scalars['String']>;
    readonly company?: Maybe<Scalars['String']>;
    /** The locale used for comunication witht the contact */
    readonly officeId?: Maybe<Scalars['String']>;
    readonly locale?: Maybe<Scalars['String']>;
    readonly note?: Maybe<Scalars['String']>;
    readonly notes: ReadonlyArray<Note>;
    /** Wether the contact is subscribed to marketing emails */
    readonly subscribed?: Maybe<Scalars['Boolean']>;
    readonly type: ContactType;
    readonly photo?: Maybe<ContactPhoto>;
    readonly createdAt: Scalars['DateTime'];
    readonly updatedAt: Scalars['DateTime'];
    readonly archived: Scalars['Boolean'];
    readonly address: Scalars['JSON'];
    readonly pronouns?: Maybe<Scalars['String']>;
    readonly timeline: ContactTimeline;
    readonly interactedProperties: EstatesConnection;
    /** The list of properties that the contact can interact with */
    readonly eligibleEstates: EstatesConnection;
    /** The last interaction with this contact */
    readonly lastInteraction?: Maybe<Scalars['JSON']>;
    readonly assignedNegotiators?: Maybe<ReadonlyArray<ContactAssignedNegotiator>>;
    readonly offers: ReadonlyArray<Offer>;
    readonly offersStats?: Maybe<OffersStats>;
};

export type ContactPhoneArgs = {
    format?: Maybe<PhoneNumberFormat>;
};

export type ContactInteractedPropertiesArgs = {
    interestLevel?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

export type ContactEligibleEstatesArgs = {
    query?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
};

export type ContactOffersArgs = {
    archived: Scalars['Boolean'];
};

export type ContactAssignedNegotiator = {
    readonly __typename?: 'ContactAssignedNegotiator';
    readonly id: Scalars['Int'];
    readonly first_name?: Maybe<Scalars['String']>;
    readonly last_name?: Maybe<Scalars['String']>;
    readonly picture_url?: Maybe<Scalars['String']>;
};

export type ContactCalledActivity = Activity & {
    readonly __typename?: 'ContactCalledActivity';
    readonly id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    readonly timestamp: Scalars['DateTime'];
    readonly calledContact?: Maybe<Contact>;
    readonly calledBy: User;
    readonly about?: Maybe<Estate>;
    readonly details?: Maybe<ActivityDetails>;
    readonly action?: Maybe<ActivityAction>;
};

export type ContactConnection = {
    readonly __typename?: 'ContactConnection';
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
    readonly edges: ReadonlyArray<ContactEdge>;
    readonly nodes: ReadonlyArray<Contact>;
};

export type ContactEdge = {
    readonly __typename?: 'ContactEdge';
    readonly node: Contact;
};

export type ContactEmailedActivity = Activity & {
    readonly __typename?: 'ContactEmailedActivity';
    readonly id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    readonly timestamp: Scalars['DateTime'];
    /** The contact to whom the email was sent */
    readonly emailedContact?: Maybe<Contact>;
    /** The user that send the email */
    readonly emailedBy: User;
    readonly about?: Maybe<Estate>;
    readonly details?: Maybe<ActivityDetails>;
    readonly action?: Maybe<ActivityAction>;
};

export type ContactFiltersInput = {
    readonly type?: Maybe<ContactType>;
    readonly archived?: Maybe<Scalars['Boolean']>;
    readonly offerStatus?: Maybe<FilterOfferStatus>;
    readonly office?: Maybe<Scalars['ID']>;
    readonly labels?: Maybe<ReadonlyArray<Scalars['ID']>>;
    readonly negotiation?: Maybe<Negotiation>;
    readonly negotiator?: Maybe<Scalars['Int']>;
    readonly noNegotiator?: Maybe<Scalars['Boolean']>;
    readonly maxPrice?: Maybe<Scalars['Float']>;
    readonly minPrice?: Maybe<Scalars['Float']>;
    readonly officeId?: Maybe<Scalars['String']>;
    readonly price_point?: Maybe<Scalars['Float']>;
    readonly negotiator_ids?: Maybe<ReadonlyArray<Scalars['ID']>>;
    readonly preference_type?: Maybe<Scalars['String']>;
    readonly preference_subtype?: Maybe<Scalars['String']>;
    readonly preference_min_condition?: Maybe<Scalars['String']>;
    readonly preference_amenity?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    readonly preference_min_bedrooms?: Maybe<Scalars['Int']>;
    readonly preference_postal_code?: Maybe<Scalars['String']>;
    readonly noInterestPropertyId?: Maybe<Scalars['ID']>;
    readonly preferenceGeoPoint?: Maybe<ReadonlyArray<Maybe<Scalars['Float']>>>;
    readonly preferenceLiveableArea?: Maybe<Scalars['Float']>;
    readonly preferenceNetArea?: Maybe<Scalars['Float']>;
    readonly preferencePlotArea?: Maybe<Scalars['Float']>;
    readonly preferenceGrossArea?: Maybe<Scalars['Float']>;
};

export type ContactMessagedActivity = Activity & {
    readonly __typename?: 'ContactMessagedActivity';
    readonly id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    readonly timestamp: Scalars['DateTime'];
    readonly messagedContact?: Maybe<Contact>;
    readonly messagedBy: User;
    readonly about?: Maybe<Estate>;
    readonly details?: Maybe<ActivityDetails>;
    readonly action?: Maybe<ActivityAction>;
};

export type ContactOffersFilter = {
    readonly contactId: Scalars['ID'];
    readonly archived: Scalars['Boolean'];
};

export type ContactPhoto = {
    readonly __typename?: 'ContactPhoto';
    readonly id: Scalars['ID'];
    readonly url: Scalars['String'];
    readonly uploadedAt?: Maybe<Scalars['String']>;
};

export type ContactReference = {
    readonly __typename?: 'ContactReference';
    readonly assignee?: Maybe<ContactReferenceAssignee>;
    readonly team?: Maybe<ContactReferenceTeam>;
};

export type ContactReferenceAssignee = {
    readonly __typename?: 'ContactReferenceAssignee';
    readonly id: Scalars['ID'];
    readonly first_name?: Maybe<Scalars['String']>;
    readonly last_name?: Maybe<Scalars['String']>;
    readonly phone?: Maybe<Scalars['String']>;
    readonly email?: Maybe<Scalars['String']>;
    readonly picture_url?: Maybe<Scalars['String']>;
};

export type ContactReferenceTeam = {
    readonly __typename?: 'ContactReferenceTeam';
    readonly id: Scalars['ID'];
    readonly name: Scalars['String'];
};

export type ContactRequest = {
    readonly __typename?: 'ContactRequest';
    readonly id: Scalars['ID'];
    readonly message?: Maybe<Scalars['String']>;
    readonly source?: Maybe<Scalars['String']>;
    readonly createdAt?: Maybe<Scalars['DateTime']>;
    readonly preference?: Maybe<BuyerPreference>;
};

export type ContactRequestConnection = {
    readonly __typename?: 'ContactRequestConnection';
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
    readonly edges: ReadonlyArray<ContactRequestConnectionEdge>;
    readonly nodes: ReadonlyArray<ContactRequest>;
};

export type ContactRequestConnectionEdge = {
    readonly __typename?: 'ContactRequestConnectionEdge';
    readonly node?: Maybe<ContactRequest>;
};

export type ContactsOptions = {
    readonly __typename?: 'ContactsOptions';
    readonly sorts: ReadonlyArray<ContactsSortOption>;
};

export type ContactsSortOption = {
    readonly __typename?: 'ContactsSortOption';
    readonly key: Scalars['String'];
};

export type ContactStats = {
    readonly __typename?: 'ContactStats';
    readonly numberOfPendingOffers: Scalars['Int'];
    readonly closestOfferExpirations: ReadonlyArray<Scalars['DateTime']>;
    readonly hasAcceptedOffer: Scalars['Boolean'];
    readonly highestOfferBid: Scalars['Float'];
};

export type ContactStatsFilter = {
    readonly contactId: Scalars['ID'];
};

export type ContactTimeline = {
    readonly __typename?: 'ContactTimeline';
    readonly activities: ActivityConnection;
};

export type ContactTimelineActivitiesArgs = {
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    propertyId?: Maybe<Scalars['String']>;
};

export enum ContactType {
    Lead = 'LEAD',
    Vendor = 'VENDOR',
}

export type CreateOfferInput = {
    readonly parentId?: Maybe<Scalars['String']>;
    readonly financialDetails: FinancialDetailsInput;
    readonly validUntil?: Maybe<Scalars['DateTime']>;
    readonly buyers: ReadonlyArray<Scalars['ID']>;
    readonly owners: ReadonlyArray<Scalars['ID']>;
    readonly propertyId: Scalars['ID'];
    readonly notes?: Maybe<Scalars['String']>;
};

/** Represents an error in the input of a mutation. */
export type DisplayableError = {
    /** Path to the input field which caused the error */
    readonly field?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** The error message */
    readonly message: Scalars['String'];
};

export type DuplicateEstateInput = {
    /** the id of the state to duplicate */
    readonly estateId: Scalars['ID'];
};

export type DuplicateEstatePayload = {
    readonly __typename?: 'DuplicateEstatePayload';
    /** the duplicated estate */
    readonly estate?: Maybe<Estate>;
};

export type Estate = {
    readonly __typename?: 'Estate';
    readonly id: Scalars['ID'];
    readonly internalType: EstateType;
    readonly publications: EstatePublications;
    readonly channelAccount?: Maybe<EstateChannelAccountConnectionEdge>;
    readonly isProject: Scalars['Boolean'];
    readonly isUnit: Scalars['Boolean'];
    readonly projectId?: Maybe<Scalars['String']>;
    readonly status: Scalars['String'];
    readonly type: Scalars['String'];
    readonly negotiation: Scalars['String'];
    readonly rentPeriod?: Maybe<Scalars['String']>;
    readonly archived: Scalars['Boolean'];
    readonly office_id?: Maybe<Scalars['ID']>;
    /** The timeline of activities related with this property */
    readonly timeline: PropertyTimeline;
    readonly attributes: Scalars['JSON'];
    /** A list of contacts that interacted with this property */
    readonly interactedContacts: EstateContactConnection;
    /** A list of contacts available for scheduling a visit with this property */
    readonly visitors: EstateContactConnection;
    /** The list of buyers for the estate */
    readonly buyers: EstateContactConnection;
    /** A list of vendors for the estate */
    readonly vendors: EstateContactConnection;
    /** The negotiator of this property */
    readonly negotiator?: Maybe<User>;
    /** The last interaction with this property */
    readonly lastInteraction?: Maybe<Scalars['JSON']>;
    /** A timestamp of date the estate was created */
    readonly createdAt?: Maybe<Scalars['DateTime']>;
    /** A timestamp of time the estate was updated */
    readonly updatedAt?: Maybe<Scalars['DateTime']>;
    /** A timestamp of time the estate was published */
    readonly first_publication_date?: Maybe<Scalars['DateTime']>;
    /** Public url of the property */
    readonly publicURL?: Maybe<Scalars['String']>;
    /** The project units */
    readonly units?: Maybe<UnitsConnection>;
    /** A unit in the project */
    readonly unit?: Maybe<Estate>;
    readonly visibility?: Maybe<Scalars['String']>;
    readonly pendingRequests?: Maybe<PendingRequestsConnection>;
    readonly matches?: Maybe<EstateMatchesConnection>;
    readonly offers: ReadonlyArray<Offer>;
    readonly error_publications_count?: Maybe<Scalars['Int']>;
    readonly successful_publications_count?: Maybe<Scalars['Int']>;
    readonly offersStats?: Maybe<OffersStats>;
    readonly legalEntity?: Maybe<Scalars['JSON']>;
    readonly buyer_ids?: Maybe<ReadonlyArray<Scalars['ID']>>;
    readonly owner_ids?: Maybe<ReadonlyArray<Scalars['ID']>>;
    readonly similarProperties?: Maybe<Scalars['JSON']>;
};

export type EstateChannelAccountArgs = {
    id: Scalars['ID'];
    lastModified?: Maybe<Scalars['String']>;
};

export type EstateInteractedContactsArgs = {
    interestLevel?: Maybe<Scalars['String']>;
    type: ContactType;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

export type EstateVisitorsArgs = {
    type?: Maybe<Scalars['String']>;
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
};

export type EstateUnitsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    archived?: Maybe<Scalars['Boolean']>;
};

export type EstateUnitArgs = {
    id: Scalars['ID'];
};

export type EstatePendingRequestsArgs = {
    type?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
};

export type EstateMatchesArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    filters?: Maybe<EstateMatchesFilters>;
};

export type EstateOffersArgs = {
    archived: Scalars['Boolean'];
};

export type EstateAgentFeedsPublicationConfig = EstatePublicationConfig & {
    readonly __typename?: 'EstateAgentFeedsPublicationConfig';
    readonly showPrice?: Maybe<Scalars['Boolean']>;
    readonly showLocation?: Maybe<Scalars['Boolean']>;
    readonly priceType?: Maybe<Scalars['String']>;
    readonly features?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type EstateAgentFeedsPublicationSettings = AccountPublicationSettings & {
    readonly __typename?: 'EstateAgentFeedsPublicationSettings';
    readonly publishPrice?: Maybe<Scalars['Boolean']>;
    readonly publishLocation?: Maybe<Scalars['Boolean']>;
    readonly priceType?: Maybe<Scalars['String']>;
    readonly features?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type EstateAggregations = {
    readonly __typename?: 'EstateAggregations';
    readonly price: PriceAggregation;
};

export type EstateChannelAccountConnection = {
    readonly __typename?: 'EstateChannelAccountConnection';
    readonly edges: ReadonlyArray<EstateChannelAccountConnectionEdge>;
    readonly pageInfo: PageInfo;
};

export type EstateChannelAccountConnectionEdge = {
    readonly __typename?: 'EstateChannelAccountConnectionEdge';
    readonly id: Scalars['ID'];
    readonly node: ChannelAccount;
    readonly configurationStatus: ConfigurationStatus;
    readonly configurationStatusMessageI18Key?: Maybe<Scalars['String']>;
    readonly publicationStatus: PublicationStatus;
    readonly lastPublication?: Maybe<EstatePublication>;
    readonly missingFields: MissingFieldsEdge;
    readonly unitsMissingFields: MissingFieldsConnection;
    readonly canPublish: Scalars['Boolean'];
    /** Whether the estate has meaninfull changes since the last publication */
    readonly outdated: Scalars['Boolean'];
};

export type EstateConfigForChannelAccount = {
    readonly __typename?: 'EstateConfigForChannelAccount';
    readonly id: Scalars['ID'];
    readonly enabled: Scalars['Boolean'];
    readonly missingFields: ReadonlyArray<Scalars['String']>;
    readonly unitsMissingFields: ReadonlyArray<OldUnitsMissingFields>;
    readonly canPublishProject: Scalars['Boolean'];
    readonly configurationStatus: OldConfigurationStatus;
    readonly publicationStatus: OldPublicationStatus;
    readonly settings?: Maybe<AccountPublicationSettings>;
    readonly account: ChannelAccount;
    readonly lastPublication?: Maybe<EstatePublication>;
    /** Whether there are changes to be published since the last publication */
    readonly outdated: Scalars['Boolean'];
};

export type EstateConnectionEdge = {
    readonly __typename?: 'EstateConnectionEdge';
    readonly node: Estate;
};

export type EstateContactConnection = {
    readonly __typename?: 'EstateContactConnection';
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
    readonly edges: ReadonlyArray<EstateContactEdge>;
    readonly nodes: ReadonlyArray<Contact>;
};

export type EstateContactEdge = {
    readonly __typename?: 'EstateContactEdge';
    readonly node: Contact;
    /** Last interaction between the contact and the propery */
    readonly lastInteraction?: Maybe<Scalars['JSON']>;
};

export type EstateFilters = {
    readonly officeId?: Maybe<Scalars['ID']>;
    readonly ownership?: Maybe<Scalars['ID']>;
    readonly minArea?: Maybe<Scalars['Float']>;
    readonly maxArea?: Maybe<Scalars['Float']>;
    readonly type?: Maybe<ReadonlyArray<Scalars['String']>>;
    readonly maxPrice?: Maybe<Scalars['Float']>;
    readonly minPrice?: Maybe<Scalars['Float']>;
    readonly min_floors?: Maybe<Scalars['Int']>;
    readonly max_floors?: Maybe<Scalars['Int']>;
    readonly areaType?: Maybe<Scalars['String']>;
    readonly status?: Maybe<ReadonlyArray<Scalars['String']>>;
    readonly min_bedrooms?: Maybe<Scalars['Int']>;
    readonly max_bedrooms?: Maybe<Scalars['Int']>;
    readonly archived?: Maybe<Scalars['Boolean']>;
    readonly sortField?: Maybe<Scalars['String']>;
    readonly sortOrder?: Maybe<Scalars['String']>;
    readonly subtypes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    readonly geo_filter?: Maybe<ReadonlyArray<Maybe<Scalars['JSON']>>>;
    readonly features?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    readonly amenities?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    readonly negotiation?: Maybe<Scalars['String']>;
    readonly min_net_area?: Maybe<Scalars['Float']>;
    readonly max_net_area?: Maybe<Scalars['Float']>;
    readonly condition?: Maybe<ReadonlyArray<Scalars['String']>>;
    readonly visibility?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    readonly min_plot_area?: Maybe<Scalars['Float']>;
    readonly max_plot_area?: Maybe<Scalars['Float']>;
    readonly propertyType?: Maybe<Scalars['String']>;
    readonly min_gross_area?: Maybe<Scalars['Float']>;
    readonly max_gross_area?: Maybe<Scalars['Float']>;
    readonly negotiator_ids?: Maybe<ReadonlyArray<Scalars['ID']>>;
    readonly min_living_rooms?: Maybe<Scalars['Int']>;
    readonly max_living_rooms?: Maybe<Scalars['Int']>;
    readonly propertyState?: Maybe<Scalars['String']>;
    readonly min_address_floor?: Maybe<Scalars['Int']>;
    readonly max_address_floor?: Maybe<Scalars['Int']>;
    readonly postal_codes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    readonly legalEntity?: Maybe<ReadonlyArray<Scalars['String']>>;
    readonly noInterestContactId?: Maybe<Scalars['ID']>;
    readonly min_liveable_area?: Maybe<Scalars['Float']>;
    readonly max_liveable_area?: Maybe<Scalars['Float']>;
    readonly min_loi_carrez_area?: Maybe<Scalars['Float']>;
    readonly max_loi_carrez_area?: Maybe<Scalars['Float']>;
    readonly offerStatus?: Maybe<FilterOfferStatus>;
    readonly property_internal_type?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type EstateMatchesConnection = {
    readonly __typename?: 'EstateMatchesConnection';
    readonly edges: ReadonlyArray<EstateMatchesConnectionEdge>;
    readonly nodes: ReadonlyArray<Contact>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int'];
    readonly mailableCount: Scalars['Int'];
};

export type EstateMatchesConnectionEdge = {
    readonly __typename?: 'EstateMatchesConnectionEdge';
    readonly id: Scalars['ID'];
    readonly node: Contact;
    readonly flags: Scalars['JSON'];
};

export type EstateMatchesFilters = {
    readonly bright?: Maybe<Scalars['Boolean']>;
};

export type EstatePublication = {
    readonly __typename?: 'EstatePublication';
    readonly id: Scalars['ID'];
    readonly publishedAt?: Maybe<Scalars['DateTime']>;
    readonly link?: Maybe<Scalars['String']>;
    readonly publishedBy?: Maybe<User>;
    readonly config?: Maybe<EstatePublicationConfig>;
    readonly errors?: Maybe<EstatePublicationErrors>;
    readonly warnings?: Maybe<EstatePublicationWarnings>;
};

export type EstatePublicationConfig = {
    readonly showPrice?: Maybe<Scalars['Boolean']>;
    readonly showLocation?: Maybe<Scalars['Boolean']>;
};

export type EstatePublicationError = {
    readonly __typename?: 'EstatePublicationError';
    readonly message: Scalars['String'];
    readonly translationKey?: Maybe<Scalars['String']>;
    readonly code?: Maybe<Scalars['String']>;
    readonly context?: Maybe<PublicationErrorContext>;
};

export type EstatePublicationErrors = {
    readonly __typename?: 'EstatePublicationErrors';
    readonly self?: Maybe<ReadonlyArray<Maybe<EstatePublicationError>>>;
    readonly units?: Maybe<ReadonlyArray<UnitPublicationErrors>>;
};

export type EstatePublications = {
    readonly __typename?: 'EstatePublications';
    readonly id: Scalars['ID'];
    readonly channelAccounts: EstateChannelAccountConnection;
};

export type EstatePublicationsChannelAccountsArgs = {
    isAdStatus?: Maybe<Scalars['Boolean']>;
    status?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    isStatusEnabled?: Maybe<Scalars['Boolean']>;
};

export type EstatePublicationSettingsInput = {
    readonly showPrice: Scalars['Boolean'];
    readonly showLocation: Scalars['Boolean'];
    readonly channelAccounts: ReadonlyArray<Scalars['String']>;
};

export type EstatePublicationSettingsResponse = {
    readonly __typename?: 'EstatePublicationSettingsResponse';
    readonly estateChannelAccounts: ReadonlyArray<EstateConfigForChannelAccount>;
};

export type EstatePublicationWarning = {
    readonly __typename?: 'EstatePublicationWarning';
    readonly message: Scalars['String'];
    readonly translationKey?: Maybe<Scalars['String']>;
    readonly code?: Maybe<Scalars['String']>;
    readonly context?: Maybe<PublicationWarningContext>;
};

export type EstatePublicationWarnings = {
    readonly __typename?: 'EstatePublicationWarnings';
    readonly self?: Maybe<ReadonlyArray<Maybe<EstatePublicationWarning>>>;
    readonly units?: Maybe<ReadonlyArray<UnitPublicationWarnings>>;
};

export type EstatesConnection = {
    readonly __typename?: 'EstatesConnection';
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
    readonly nodes: ReadonlyArray<Estate>;
    readonly edges: ReadonlyArray<EstateConnectionEdge>;
};

export type EstatesOptions = {
    readonly __typename?: 'EstatesOptions';
    readonly sorts: ReadonlyArray<EstatesSortOption>;
};

export type EstatesSortOption = {
    readonly __typename?: 'EstatesSortOption';
    readonly key: Scalars['String'];
};

export enum EstateType {
    Project = 'PROJECT',
    Unit = 'UNIT',
    Standalone = 'STANDALONE',
}

export type FacebookPublicationConfig = EstatePublicationConfig & {
    readonly __typename?: 'FacebookPublicationConfig';
    readonly showPrice?: Maybe<Scalars['Boolean']>;
    readonly showLocation?: Maybe<Scalars['Boolean']>;
    readonly post?: Maybe<Scalars['String']>;
};

export type FacebookPublicationSettings = AccountPublicationSettings & {
    readonly __typename?: 'FacebookPublicationSettings';
    readonly publishPrice?: Maybe<Scalars['Boolean']>;
    readonly publishLocation?: Maybe<Scalars['Boolean']>;
    readonly postText?: Maybe<Scalars['String']>;
    readonly images: ReadonlyArray<Scalars['String']>;
};

export type FacebookPublicationSettingsInput = {
    readonly postText: Scalars['String'];
    readonly images: ReadonlyArray<Scalars['String']>;
};

export type FeedbackActivityDetails = ActivityDetails & {
    readonly __typename?: 'FeedbackActivityDetails';
    readonly id: Scalars['ID'];
    readonly type: Scalars['String'];
    readonly externalComment?: Maybe<Scalars['String']>;
    readonly internalComment?: Maybe<Scalars['String']>;
};

export enum FilterOfferStatus {
    Pending = 'pending',
    Accepted = 'accepted',
}

export type FinancialDetails = {
    readonly __typename?: 'FinancialDetails';
    readonly direction: OfferDirection;
    readonly currency: Scalars['Currency'];
    readonly buyerFixedFee: Scalars['Float'];
    readonly ownerFixedFee: Scalars['Float'];
    readonly buyerPercentageFee: Scalars['Float'];
    readonly ownerPercentageFee: Scalars['Float'];
    readonly buyerGrossAmount: Scalars['Float'];
    readonly ownerNetAmount: Scalars['Float'];
    readonly buyerTotalFee: Scalars['Float'];
    readonly ownerTotalFee: Scalars['Float'];
    readonly agencyTotalFee: Scalars['Float'];
    readonly transactionAmount: Scalars['Float'];
};

export type FinancialDetailsInput = {
    readonly direction: OfferDirection;
    readonly currency: Scalars['Currency'];
    readonly buyerFixedFee: Scalars['Float'];
    readonly buyerPercentageFee: Scalars['Float'];
    readonly ownerFixedFee: Scalars['Float'];
    readonly ownerPercentageFee: Scalars['Float'];
    readonly buyerGrossAmount?: Maybe<Scalars['Float']>;
    readonly ownerNetAmount?: Maybe<Scalars['Float']>;
};

export type FrenchPortal = {
    readonly __typename?: 'FrenchPortal';
    readonly automatic?: Maybe<Scalars['Boolean']>;
    readonly code?: Maybe<Scalars['String']>;
    readonly free?: Maybe<Scalars['Boolean']>;
    readonly id?: Maybe<Scalars['Int']>;
    readonly logoUrl?: Maybe<Scalars['String']>;
    readonly name?: Maybe<Scalars['String']>;
    readonly url?: Maybe<Scalars['String']>;
};

export type FrenchPortalPublicationConfig = EstatePublicationConfig & {
    readonly __typename?: 'FrenchPortalPublicationConfig';
    readonly showPrice?: Maybe<Scalars['Boolean']>;
    readonly showLocation?: Maybe<Scalars['Boolean']>;
    readonly portals?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type FrenchPortalPublicationSettings = AccountPublicationSettings & {
    readonly __typename?: 'FrenchPortalPublicationSettings';
    readonly publishPrice?: Maybe<Scalars['Boolean']>;
    readonly publishLocation?: Maybe<Scalars['Boolean']>;
    readonly portals?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type GenerateDescriptionInput = {
    readonly extraText?: Maybe<Scalars['String']>;
    readonly inputLanguage: Scalars['String'];
    readonly maxOutputLength?: Maybe<Scalars['Int']>;
    readonly outputLanguage: Scalars['String'];
    readonly propertyId: Scalars['ID'];
};

export type GenerateDescriptionPayload = {
    readonly __typename?: 'GenerateDescriptionPayload';
    readonly propertyId: Scalars['ID'];
    readonly text: Scalars['String'];
};

export type GenericAccountPublicationSettings = AccountPublicationSettings & {
    readonly __typename?: 'GenericAccountPublicationSettings';
    readonly publishPrice?: Maybe<Scalars['Boolean']>;
    readonly publishLocation?: Maybe<Scalars['Boolean']>;
};

export type GenericPublicationConfig = EstatePublicationConfig & {
    readonly __typename?: 'GenericPublicationConfig';
    readonly showPrice?: Maybe<Scalars['Boolean']>;
    readonly showLocation?: Maybe<Scalars['Boolean']>;
};

export type GeoJsonData = {
    readonly __typename?: 'GeoJSONData';
    readonly type: Scalars['String'];
    readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<Scalars['Float']>>>;
};

export type GeoJsonDataInput = {
    readonly type: Scalars['String'];
    readonly coordinates: ReadonlyArray<ReadonlyArray<ReadonlyArray<Scalars['Float']>>>;
};

export type GeoJsonLocationPreference = LocationPreference & {
    readonly __typename?: 'GeoJSONLocationPreference';
    readonly id: Scalars['ID'];
    readonly type: Scalars['String'];
    readonly name: Scalars['String'];
    readonly data: GeoJsonData;
    readonly createdAt: Scalars['DateTime'];
    readonly updatedAt: Scalars['DateTime'];
};

export type GeoTemplate = {
    readonly __typename?: 'GeoTemplate';
    readonly _id?: Maybe<Scalars['ID']>;
    readonly title: Scalars['String'];
    readonly office_id?: Maybe<Scalars['ID']>;
    readonly type?: Maybe<Scalars['String']>;
    readonly postalCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    readonly geometry?: Maybe<Scalars['JSON']>;
};

export type GeoTemplatesDeletePayload = {
    readonly __typename?: 'GeoTemplatesDeletePayload';
    readonly _id?: Maybe<Scalars['ID']>;
};

export type GeoTemplatesInput = {
    readonly _id?: Maybe<Scalars['ID']>;
    readonly title: Scalars['String'];
    readonly office_id?: Maybe<Scalars['ID']>;
    readonly type?: Maybe<Scalars['String']>;
    readonly postalCodes?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
    readonly geometry?: Maybe<Scalars['JSON']>;
};

export type GeoTemplatesPagination = {
    readonly __typename?: 'GeoTemplatesPagination';
    readonly current_page?: Maybe<Scalars['Int']>;
    readonly total_pages?: Maybe<Scalars['Int']>;
    readonly total?: Maybe<Scalars['Int']>;
    readonly per_page?: Maybe<Scalars['Int']>;
};

export type GeoTemplatesPayload = {
    readonly __typename?: 'GeoTemplatesPayload';
    readonly data: ReadonlyArray<GeoTemplate>;
    readonly pagination: GeoTemplatesPagination;
};

export type GetFrenchPortalsInput = {
    readonly channelAccountId: Scalars['ID'];
};

export type GetFrenchPortalsPayload = {
    readonly __typename?: 'GetFrenchPortalsPayload';
    readonly portals: ReadonlyArray<FrenchPortal>;
};

export type HistogramBin = {
    readonly __typename?: 'HistogramBin';
    readonly from: Scalars['Float'];
    readonly to: Scalars['Float'];
    readonly count: Scalars['Int'];
};

export type ImmowebPublicationConfig = EstatePublicationConfig & {
    readonly __typename?: 'ImmowebPublicationConfig';
    readonly showPrice?: Maybe<Scalars['Boolean']>;
    readonly showLocation?: Maybe<Scalars['Boolean']>;
    readonly adSize?: Maybe<Scalars['String']>;
};

export type ImmowebPublicationSettings = AccountPublicationSettings & {
    readonly __typename?: 'ImmowebPublicationSettings';
    readonly publishPrice?: Maybe<Scalars['Boolean']>;
    readonly publishLocation?: Maybe<Scalars['Boolean']>;
    readonly adSize?: Maybe<Scalars['String']>;
};

export type ImmowebPublicationSettingsInput = {
    readonly adSize: Scalars['String'];
};

export type InviteInput = {
    readonly language?: Maybe<Scalars['String']>;
    readonly invites: ReadonlyArray<InviteItemInput>;
};

export type InviteItemInput = {
    readonly email: Scalars['String'];
    readonly firstName?: Maybe<Scalars['String']>;
    readonly lastName?: Maybe<Scalars['String']>;
};

export type Label = {
    readonly __typename?: 'Label';
    readonly id: Scalars['ID'];
    readonly name: Scalars['String'];
    readonly color: Scalars['String'];
    readonly description?: Maybe<Scalars['String']>;
    readonly created_at?: Maybe<Scalars['String']>;
    readonly updated_at?: Maybe<Scalars['String']>;
};

export type LabelsInput = {
    readonly id?: Maybe<Scalars['ID']>;
    readonly name: Scalars['String'];
    readonly color: Scalars['String'];
    readonly description?: Maybe<Scalars['String']>;
};

export type LabelsMeta = {
    readonly __typename?: 'LabelsMeta';
    readonly pagination: LabelsPagination;
};

export type LabelsPagination = {
    readonly __typename?: 'LabelsPagination';
    readonly current_page?: Maybe<Scalars['Int']>;
    readonly total_pages?: Maybe<Scalars['Int']>;
    readonly total?: Maybe<Scalars['Int']>;
    readonly per_page?: Maybe<Scalars['Int']>;
};

export type LabelsPayload = {
    readonly __typename?: 'LabelsPayload';
    readonly data: ReadonlyArray<Label>;
    readonly meta?: Maybe<LabelsMeta>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type Lead = Contact & {
    readonly __typename?: 'Lead';
    readonly id: Scalars['ID'];
    /** The first name of the contact */
    readonly firstName: Scalars['String'];
    /** The last name of the contact */
    readonly lastName: Scalars['String'];
    /** The email address of the contact */
    readonly email?: Maybe<Scalars['String']>;
    readonly phone?: Maybe<Scalars['String']>;
    readonly company?: Maybe<Scalars['String']>;
    readonly officeId?: Maybe<Scalars['String']>;
    readonly locale?: Maybe<Scalars['String']>;
    readonly pronouns?: Maybe<Scalars['String']>;
    readonly note?: Maybe<Scalars['String']>;
    readonly notes: ReadonlyArray<Note>;
    readonly subscribed?: Maybe<Scalars['Boolean']>;
    readonly type: ContactType;
    readonly photo?: Maybe<ContactPhoto>;
    readonly address: Scalars['JSON'];
    /** The lead preferences */
    readonly allPreferences: BuyerPreferencesConnection;
    readonly preference?: Maybe<BuyerPreference>;
    readonly timeline: ContactTimeline;
    readonly interactedProperties: EstatesConnection;
    /** The list of properties that the contact can interact with */
    readonly eligibleEstates: EstatesConnection;
    /** The last action related with this lead (created, updated, interacted) */
    readonly lastAction?: Maybe<Scalars['String']>;
    /** The last interaction with this contact */
    readonly lastInteraction?: Maybe<Scalars['JSON']>;
    readonly createdAt: Scalars['DateTime'];
    readonly updatedAt: Scalars['DateTime'];
    readonly archived: Scalars['Boolean'];
    readonly assignedNegotiators?: Maybe<ReadonlyArray<ContactAssignedNegotiator>>;
    readonly offers: ReadonlyArray<Offer>;
    readonly offersStats?: Maybe<OffersStats>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadPhoneArgs = {
    format?: Maybe<PhoneNumberFormat>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadAllPreferencesArgs = {
    page?: Maybe<Scalars['Int']>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadPreferenceArgs = {
    id: Scalars['ID'];
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadInteractedPropertiesArgs = {
    interestLevel?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadEligibleEstatesArgs = {
    query?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
};

/** A lead is a type of contact that is interested on buying/renting a property */
export type LeadOffersArgs = {
    archived: Scalars['Boolean'];
};

export type LeadExpressedInterestActivity = Activity & {
    readonly __typename?: 'LeadExpressedInterestActivity';
    readonly id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    readonly timestamp?: Maybe<Scalars['DateTime']>;
    readonly interestedLead?: Maybe<Contact>;
    readonly propertyInterestedIn?: Maybe<Estate>;
    readonly interestExpressedVia?: Maybe<LeadSource>;
    readonly details?: Maybe<MessageActivityDetails>;
    readonly action?: Maybe<ActivityAction>;
};

export type LeadSource = {
    readonly __typename?: 'LeadSource';
    readonly name: Scalars['String'];
};

export type LegalEntities = {
    readonly __typename?: 'LegalEntities';
    readonly id?: Maybe<Scalars['ID']>;
    readonly name: Scalars['String'];
    readonly office_id?: Maybe<Scalars['ID']>;
};

export type LegalEntitiesConnection = {
    readonly __typename?: 'LegalEntitiesConnection';
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
    readonly edges: ReadonlyArray<LegalEntitiesEdge>;
    readonly nodes: ReadonlyArray<LegalEntities>;
};

export type LegalEntitiesDeletePayload = {
    readonly __typename?: 'LegalEntitiesDeletePayload';
    readonly id?: Maybe<Scalars['ID']>;
};

export type LegalEntitiesEdge = {
    readonly __typename?: 'LegalEntitiesEdge';
    readonly node: LegalEntities;
};

export type LegalEntitiesInput = {
    readonly id?: Maybe<Scalars['ID']>;
    readonly name: Scalars['String'];
    readonly office_id?: Maybe<Scalars['ID']>;
};

export type LegalEntity = {
    readonly __typename?: 'LegalEntity';
    readonly data?: Maybe<LegalEntities>;
};

export type LocalizedText = {
    readonly __typename?: 'LocalizedText';
    readonly locale: Scalars['String'];
    readonly value?: Maybe<Scalars['String']>;
};

export type LocationPreference = {
    readonly id: Scalars['ID'];
    readonly type: Scalars['String'];
    readonly name: Scalars['String'];
    readonly createdAt: Scalars['DateTime'];
    readonly updatedAt: Scalars['DateTime'];
};

export type LocationPreferenceConnection = {
    readonly __typename?: 'LocationPreferenceConnection';
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
    readonly edges: ReadonlyArray<LocationPreferenceEdge>;
    readonly nodes: ReadonlyArray<LocationPreference>;
};

export type LocationPreferenceEdge = {
    readonly __typename?: 'LocationPreferenceEdge';
    readonly node: LocationPreference;
};

export type LocationPreferenceInput = {
    readonly name: Scalars['String'];
    readonly type: Scalars['String'];
};

export type MatchingConfigInput = {
    readonly id: Scalars['ID'];
    readonly type: Scalars['String'];
    readonly searchParams: Scalars['JSON'];
};

export type MatchingConfigPayload = {
    readonly __typename?: 'MatchingConfigPayload';
    readonly data?: Maybe<Scalars['JSON']>;
};

export type MatchingEmailsInput = {
    readonly contents: Scalars['JSON'];
    readonly operation: Scalars['String'];
    readonly contactIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
    readonly propertyIds?: Maybe<ReadonlyArray<Scalars['ID']>>;
    readonly searchParams: Scalars['JSON'];
};

export type MatchingEmailsPayload = {
    readonly __typename?: 'MatchingEmailsPayload';
    readonly data?: Maybe<Scalars['JSON']>;
};

export type MatchingFlagsInput = {
    readonly contactIds: ReadonlyArray<Scalars['ID']>;
    readonly propertyIds: ReadonlyArray<Scalars['ID']>;
};

export type MatchingFlagsPayload = {
    readonly __typename?: 'MatchingFlagsPayload';
    readonly data?: Maybe<ReadonlyArray<Maybe<MatchingFlagsPayloadData>>>;
};

export type MatchingFlagsPayloadData = {
    readonly __typename?: 'MatchingFlagsPayloadData';
    readonly contactId?: Maybe<Scalars['ID']>;
    readonly propertyId?: Maybe<Scalars['ID']>;
    readonly flags?: Maybe<Scalars['JSON']>;
};

export type MatchingRequirementsInput = {
    readonly id: Scalars['ID'];
    readonly type: Scalars['String'];
};

export type MatchingRequirementsPayload = {
    readonly __typename?: 'MatchingRequirementsPayload';
    readonly data?: Maybe<MatchingRequirementsPayloadData>;
};

export type MatchingRequirementsPayloadData = {
    readonly __typename?: 'MatchingRequirementsPayloadData';
    readonly contactId?: Maybe<Scalars['ID']>;
    readonly propertyId?: Maybe<Scalars['ID']>;
    readonly preferenceId?: Maybe<Scalars['ID']>;
    readonly isMatchable?: Maybe<Scalars['Boolean']>;
    readonly errors?: Maybe<Scalars['JSON']>;
};

export type MessageActivityDetails = ActivityDetails & {
    readonly __typename?: 'MessageActivityDetails';
    readonly id: Scalars['ID'];
    readonly message?: Maybe<Scalars['String']>;
};

export type MissingField = {
    readonly __typename?: 'MissingField';
    readonly path: Scalars['String'];
    readonly errorMessage: Scalars['String'];
};

export type MissingFieldsConnection = {
    readonly __typename?: 'MissingFieldsConnection';
    readonly edges: ReadonlyArray<MissingFieldsEdge>;
};

export type MissingFieldsEdge = {
    readonly __typename?: 'MissingFieldsEdge';
    readonly id: Scalars['ID'];
    readonly estateId: Scalars['ID'];
    readonly node: ReadonlyArray<MissingField>;
};

export type Mutation = {
    readonly __typename?: 'Mutation';
    readonly saveChannelAccount?: Maybe<ChannelAccount>;
    readonly removeChannelAccount: Scalars['ID'];
    readonly saveFacebookPublicationSettingsForEstate: EstateConfigForChannelAccount;
    readonly saveTwitterPublicationSettingsForEstate: EstateConfigForChannelAccount;
    readonly saveImmowebPublicationSettingsForEstate: EstateConfigForChannelAccount;
    readonly createPublication: PublishPropertyPayload;
    readonly removePublication: PublishPropertyPayload;
    readonly updatePublication: PublishPropertyPayload;
    readonly retryPublication: RetryPublicationPayload;
    readonly refreshPublication: RefreshPublicationPayload;
    readonly getFrenchPortals: GetFrenchPortalsPayload;
    readonly saveEstatePublicationSettings: EstatePublicationSettingsResponse;
    readonly addEstate: AddEstatePayload;
    readonly updateEstate: UpdateEstatePayload;
    readonly archiveEstate: ArchiveEstatePayload;
    readonly updateEstateStatus?: Maybe<Estate>;
    readonly unarchiveEstate: UnarchiveEstatePayload;
    readonly duplicateEstate: DuplicateEstatePayload;
    readonly registerEstateActivity: RegisterEstateActivityPayload;
    readonly assignEstatesToNegotiator: AssignEstatesToNegotiatorPayload;
    readonly assignVendorsToEstate: AssignVendorsToEstatePayload;
    readonly unassignVendorsToEstate: UnassignVendorsToEstatePayload;
    readonly assignBuyersToEstate: AssignBuyersToEstatePayload;
    readonly unassignBuyersToEstate: UnassignBuyersToEstatePayload;
    readonly assignLegalEntity?: Maybe<AssignLegalEntityToPayload>;
    readonly unassignLegalEntity?: Maybe<UnassignLegalEntityToPayload>;
    readonly addUnitToProject: AddUnitToProjectPayload;
    readonly generateDescription: GenerateDescriptionPayload;
    readonly translate: TranslatePayload;
    readonly updatePropertyVisibility?: Maybe<UpdatePropertyVisibilityPayload>;
    readonly addLocationPreference: AddLocationPreferencePayload;
    readonly removeLocationPreference: RemoveLocationPayload;
    readonly updateLocationPreference: UpdateLocationPreferencePayload;
    /** Add a location template to an office */
    readonly addLocationPreferenceTemplate: AddLocationTemplatePayload;
    /** Remove a location template from an office */
    readonly removeLocationPreferenceTemplate: RemoveLocationTemplatePayload;
    /** Update an existing office location template */
    readonly updateLocationPreferenceTemplate: UpdateLocationTemplatePayload;
    /** Clone an existing office location template into a lead location preferences */
    readonly cloneLocationPreferenceTemplate: CloneLocationTemplatePayload;
    readonly updateUser?: Maybe<UpdateUserPayload>;
    readonly updateUserOffice: User;
    readonly addIntegration: AddIntegrationPayload;
    readonly removeIntegration: RemoveIntegrationPayload;
    readonly inviteUsers: Scalars['Boolean'];
    readonly updateUserRole: Scalars['Boolean'];
    readonly updateUserTeams: Scalars['Boolean'];
    readonly archiveUser: Scalars['Boolean'];
    readonly authenticate: AuthenticatePayload;
    readonly updateLeadPreference: UpdateLeadPreferencePayload;
    readonly addLeadPreference: AddLeadPreferencePayload;
    readonly removeLeadPreference: RemoveLeadPreferencePayload;
    readonly assignLeadsToOffice: AssignLeadsPayload;
    readonly archiveLeads: ArchiveLeadsPayload;
    readonly createLabel: Label;
    readonly updateLabel: Label;
    readonly deleteLabel?: Maybe<Scalars['JSON']>;
    readonly addContact: AddContactMutationPayload;
    readonly updateContactDetails: UpdateContactMutationPayload;
    readonly updateContactAddress: UpdateContactMutationPayload;
    readonly archiveContact: UpdateContactMutationPayload;
    readonly unarchiveContact: UpdateContactMutationPayload;
    readonly updateContactLabels?: Maybe<Scalars['JSON']>;
    readonly updateContactAssignments: UpdateContactAssignmentsPayload;
    readonly sendVendorReport: SendVendorReportPayload;
    readonly scheduleVisit: ScheduleVisitPayload;
    readonly updateVisitStatus: UpdateVisitStatusPayload;
    readonly createOffer: Offer;
    readonly updateOffer: Offer;
    readonly archiveOffer: Offer;
    readonly restoreOffer: Offer;
    readonly acceptOffer: Offer;
    readonly refuseOffer: Offer;
    readonly cancelOffer: Offer;
    readonly addLegalEntity: LegalEntities;
    readonly updateLegalEntity: LegalEntities;
    readonly deleteLegalEntity?: Maybe<LegalEntitiesDeletePayload>;
    readonly addGeoTemplate: GeoTemplate;
    readonly updateGeoTemplate: GeoTemplate;
    readonly deleteGeoTemplate?: Maybe<GeoTemplatesDeletePayload>;
    readonly matchingEmails: MatchingEmailsPayload;
};

export type MutationSaveChannelAccountArgs = {
    companyId: Scalars['String'];
    input: ChannelAccountInput;
};

export type MutationRemoveChannelAccountArgs = {
    companyId: Scalars['String'];
    accountId: Scalars['String'];
};

export type MutationSaveFacebookPublicationSettingsForEstateArgs = {
    estateId?: Maybe<Scalars['String']>;
    estateChannelAccountId?: Maybe<Scalars['String']>;
    input?: Maybe<FacebookPublicationSettingsInput>;
};

export type MutationSaveTwitterPublicationSettingsForEstateArgs = {
    estateId?: Maybe<Scalars['String']>;
    estateChannelAccountId?: Maybe<Scalars['String']>;
    input?: Maybe<TwitterPublicationSettingsInput>;
};

export type MutationSaveImmowebPublicationSettingsForEstateArgs = {
    estateId?: Maybe<Scalars['String']>;
    estateChannelAccountId?: Maybe<Scalars['String']>;
    input?: Maybe<ImmowebPublicationSettingsInput>;
};

export type MutationCreatePublicationArgs = {
    input: PublishPropertyInput;
};

export type MutationRemovePublicationArgs = {
    input: UnpublishPropertyInput;
};

export type MutationUpdatePublicationArgs = {
    input: PublishPropertyInput;
};

export type MutationRetryPublicationArgs = {
    input: RetryPublicationInput;
};

export type MutationRefreshPublicationArgs = {
    input: RefreshPublicationInput;
};

export type MutationGetFrenchPortalsArgs = {
    input: GetFrenchPortalsInput;
};

export type MutationSaveEstatePublicationSettingsArgs = {
    estateId?: Maybe<Scalars['String']>;
    input: EstatePublicationSettingsInput;
};

export type MutationAddEstateArgs = {
    input: AddEstateInput;
};

export type MutationUpdateEstateArgs = {
    estateId: Scalars['ID'];
    estateType: EstateType;
    operations: Scalars['JSON'];
};

export type MutationArchiveEstateArgs = {
    input: ArchiveEstateInput;
};

export type MutationUpdateEstateStatusArgs = {
    estateId: Scalars['ID'];
    status?: Maybe<Scalars['String']>;
};

export type MutationUnarchiveEstateArgs = {
    input: UnarchiveEstateInput;
};

export type MutationDuplicateEstateArgs = {
    input: DuplicateEstateInput;
};

export type MutationRegisterEstateActivityArgs = {
    input: RegisterEstateActivityInput;
};

export type MutationAssignEstatesToNegotiatorArgs = {
    input: AssignEstatesToNegotiatorInput;
};

export type MutationAssignVendorsToEstateArgs = {
    input: AssignVendorsToEstateInput;
};

export type MutationUnassignVendorsToEstateArgs = {
    input: UnassignVendorsToEstateInput;
};

export type MutationAssignBuyersToEstateArgs = {
    input: AssignBuyersToEstateInput;
};

export type MutationUnassignBuyersToEstateArgs = {
    input: UnassignBuyersToEstateInput;
};

export type MutationAssignLegalEntityArgs = {
    input: AssignLegalEntityToEstateInput;
};

export type MutationUnassignLegalEntityArgs = {
    input: UnassignLegalEntityToEstateInput;
};

export type MutationAddUnitToProjectArgs = {
    input: AddUnitToProjectInput;
};

export type MutationGenerateDescriptionArgs = {
    generateInput: GenerateDescriptionInput;
};

export type MutationTranslateArgs = {
    translateInput: TranslateInput;
};

export type MutationUpdatePropertyVisibilityArgs = {
    propertyId: Scalars['ID'];
    visibility: Scalars['String'];
};

export type MutationAddLocationPreferenceArgs = {
    input: AddLocationPreferenceInput;
};

export type MutationRemoveLocationPreferenceArgs = {
    leadId: Scalars['ID'];
    locationPreferenceId: Scalars['ID'];
    preferenceId?: Maybe<Scalars['ID']>;
};

export type MutationUpdateLocationPreferenceArgs = {
    leadId: Scalars['ID'];
    locationPreferenceId: Scalars['ID'];
    preference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
    preferenceId?: Maybe<Scalars['ID']>;
};

export type MutationAddLocationPreferenceTemplateArgs = {
    officeId: Scalars['ID'];
    preference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
};

export type MutationRemoveLocationPreferenceTemplateArgs = {
    officeId: Scalars['ID'];
    locationId: Scalars['ID'];
};

export type MutationUpdateLocationPreferenceTemplateArgs = {
    officeId: Scalars['ID'];
    locationId: Scalars['ID'];
    preference: LocationPreferenceInput;
    geoJSONData?: Maybe<GeoJsonDataInput>;
    postalCodesData?: Maybe<PostalCodesDataInput>;
};

export type MutationCloneLocationPreferenceTemplateArgs = {
    input: CloneLocationPreferenceTemplateInput;
};

export type MutationUpdateUserArgs = {
    input: UpdateUserInput;
};

export type MutationUpdateUserOfficeArgs = {
    officeId: Scalars['ID'];
};

export type MutationAddIntegrationArgs = {
    input: AddIntegrationInput;
};

export type MutationRemoveIntegrationArgs = {
    input: RemoveIntegrationInput;
};

export type MutationInviteUsersArgs = {
    invite: InviteInput;
    officeId?: Maybe<Scalars['ID']>;
    companyId?: Maybe<Scalars['ID']>;
};

export type MutationUpdateUserRoleArgs = {
    officeId: Scalars['ID'];
    userId: Scalars['ID'];
    role: UserRole;
};

export type MutationUpdateUserTeamsArgs = {
    userId: Scalars['ID'];
    teamsIds: ReadonlyArray<Scalars['ID']>;
};

export type MutationArchiveUserArgs = {
    userId: Scalars['ID'];
};

export type MutationAuthenticateArgs = {
    code: Scalars['String'];
};

export type MutationUpdateLeadPreferenceArgs = {
    input: UpdateLeadPreferenceInput;
};

export type MutationAddLeadPreferenceArgs = {
    input: AddLeadPreferenceInput;
};

export type MutationRemoveLeadPreferenceArgs = {
    input: RemoveLeadPreferenceInput;
};

export type MutationAssignLeadsToOfficeArgs = {
    input: AssignLeadsInput;
};

export type MutationArchiveLeadsArgs = {
    input: ArchiveLeadsInput;
};

export type MutationCreateLabelArgs = {
    input?: Maybe<LabelsInput>;
};

export type MutationUpdateLabelArgs = {
    input?: Maybe<LabelsInput>;
};

export type MutationDeleteLabelArgs = {
    id: Scalars['ID'];
};

export type MutationAddContactArgs = {
    input: AddContactMutationInput;
};

export type MutationUpdateContactDetailsArgs = {
    input: UpdateContactMutationInput;
};

export type MutationUpdateContactAddressArgs = {
    input: UpdateContactAddressMutationInput;
};

export type MutationArchiveContactArgs = {
    id: Scalars['ID'];
};

export type MutationUnarchiveContactArgs = {
    id: Scalars['ID'];
};

export type MutationUpdateContactLabelsArgs = {
    id: Scalars['ID'];
    labels: ReadonlyArray<Scalars['ID']>;
};

export type MutationUpdateContactAssignmentsArgs = {
    input: UpdateContactAssignmentsInput;
};

export type MutationSendVendorReportArgs = {
    input: SendVendorReportInput;
};

export type MutationScheduleVisitArgs = {
    input: ScheduleVisitInput;
};

export type MutationUpdateVisitStatusArgs = {
    input: UpdateVisitStatusInput;
};

export type MutationCreateOfferArgs = {
    offerInput: CreateOfferInput;
};

export type MutationUpdateOfferArgs = {
    offerInput: UpdateOfferInput;
};

export type MutationArchiveOfferArgs = {
    offerId: Scalars['ID'];
};

export type MutationRestoreOfferArgs = {
    offerId: Scalars['ID'];
};

export type MutationAcceptOfferArgs = {
    offerId: Scalars['ID'];
};

export type MutationRefuseOfferArgs = {
    offerId: Scalars['ID'];
};

export type MutationCancelOfferArgs = {
    offerId: Scalars['ID'];
};

export type MutationAddLegalEntityArgs = {
    officeId: Scalars['ID'];
    input: LegalEntitiesInput;
};

export type MutationUpdateLegalEntityArgs = {
    officeId: Scalars['ID'];
    input: LegalEntitiesInput;
};

export type MutationDeleteLegalEntityArgs = {
    officeId: Scalars['ID'];
    input: LegalEntitiesInput;
};

export type MutationAddGeoTemplateArgs = {
    officeId: Scalars['ID'];
    input: GeoTemplatesInput;
};

export type MutationUpdateGeoTemplateArgs = {
    officeId: Scalars['ID'];
    input: GeoTemplatesInput;
};

export type MutationDeleteGeoTemplateArgs = {
    officeId: Scalars['ID'];
    input: GeoTemplatesInput;
};

export type MutationMatchingEmailsArgs = {
    input: MatchingEmailsInput;
};

export enum Negotiation {
    Sale = 'SALE',
    Let = 'LET',
}

export type Note = {
    readonly __typename?: 'Note';
    readonly text?: Maybe<Scalars['String']>;
};

export type Offer = {
    readonly __typename?: 'Offer';
    readonly id?: Maybe<Scalars['ID']>;
    readonly parentId?: Maybe<Scalars['String']>;
    readonly status: OfferStatus;
    readonly financialDetails: FinancialDetails;
    readonly createdAt: Scalars['DateTime'];
    readonly updatedAt?: Maybe<Scalars['DateTime']>;
    readonly archivedAt?: Maybe<Scalars['DateTime']>;
    readonly validUntil?: Maybe<Scalars['DateTime']>;
    readonly acceptedAt?: Maybe<Scalars['DateTime']>;
    readonly refusedAt?: Maybe<Scalars['DateTime']>;
    readonly cancelledAt?: Maybe<Scalars['DateTime']>;
    readonly buyers: ReadonlyArray<OfferOwner>;
    readonly owners: ReadonlyArray<OfferBuyer>;
    readonly propertyId: Scalars['ID'];
    readonly property?: Maybe<Estate>;
    readonly createdBy: Scalars['ID'];
    readonly creator?: Maybe<User>;
    readonly notes?: Maybe<Scalars['String']>;
};

export type OfferBuyer = {
    readonly __typename?: 'OfferBuyer';
    readonly id: Scalars['ID'];
};

export enum OfferDirection {
    BuyerToOwner = 'BUYER_TO_OWNER',
    OwnerToBuyer = 'OWNER_TO_BUYER',
}

export type OfferOwner = {
    readonly __typename?: 'OfferOwner';
    readonly id: Scalars['ID'];
};

export type OffersStats = {
    readonly __typename?: 'OffersStats';
    readonly number_of_pending_offers: Scalars['Int'];
    readonly highest_offer_bid: Scalars['Float'];
    readonly has_accepted_offer: Scalars['Boolean'];
    readonly closest_offer_expirations: ReadonlyArray<Scalars['DateTime']>;
    readonly currency: Scalars['Currency'];
};

export enum OfferStatus {
    Accepted = 'ACCEPTED',
    Cancelled = 'CANCELLED',
    Pending = 'PENDING',
    Refused = 'REFUSED',
}

export type Office = {
    readonly __typename?: 'Office';
    readonly id: Scalars['ID'];
    /** The name of the office */
    readonly name: Scalars['String'];
    /** The office settings, including the numeral system, currency, etc... */
    readonly settings: OfficeSettings;
    readonly legalEntity?: Maybe<LegalEntity>;
    readonly legalEntities: LegalEntitiesConnection;
    readonly locationTemplates: LocationPreferenceConnection;
    /** A list of users that can be assigned as negotiatiors for a estate in this office */
    readonly negotiators: ReadonlyArray<User>;
    readonly negotiatiorsByIds: ReadonlyArray<User>;
    readonly estates?: Maybe<EstatesConnection>;
    readonly members?: Maybe<TeamMembersConnection>;
    readonly companyMembers?: Maybe<TeamMembersConnection>;
};

export type OfficeLegalEntityArgs = {
    officeId: Scalars['ID'];
    id: Scalars['ID'];
};

export type OfficeLegalEntitiesArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type OfficeLocationTemplatesArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type OfficeNegotiatorsArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
};

export type OfficeNegotiatiorsByIdsArgs = {
    ids: ReadonlyArray<Scalars['ID']>;
};

export type OfficeEstatesArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type OfficeMembersArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type OfficeCompanyMembersArgs = {
    query?: Maybe<Scalars['String']>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    team?: Maybe<Scalars['String']>;
    role?: Maybe<Scalars['String']>;
};

export type OfficeSettings = {
    readonly __typename?: 'OfficeSettings';
    readonly currency: Scalars['String'];
    readonly numeralSystem: Scalars['String'];
};

export enum OldConfigurationStatus {
    MissingFields = 'MISSING_FIELDS',
    OnlyUnits = 'ONLY_UNITS',
    NotConfigured = 'NOT_CONFIGURED',
    Ok = 'OK',
}

export enum OldPublicationStatus {
    NotPublished = 'NOT_PUBLISHED',
    Publishing = 'PUBLISHING',
    Unpublishing = 'UNPUBLISHING',
    Published = 'PUBLISHED',
    Unpublished = 'UNPUBLISHED',
    Outdated = 'OUTDATED',
}

export type OldUnitsMissingFields = {
    readonly __typename?: 'OldUnitsMissingFields';
    readonly id: Scalars['ID'];
    readonly unitId?: Maybe<Scalars['String']>;
    readonly missingFields: ReadonlyArray<Scalars['String']>;
};

export type PageInfo = {
    readonly __typename?: 'PageInfo';
    readonly currentPage: Scalars['Int'];
    readonly itemsPerPage: Scalars['Int'];
    readonly hasNextPage: Scalars['Boolean'];
    readonly startCursor?: Maybe<Scalars['String']>;
    readonly endCursor?: Maybe<Scalars['String']>;
};

export type PendingRequest = {
    readonly __typename?: 'PendingRequest';
    readonly id: Scalars['ID'];
    readonly email: Scalars['String'];
    readonly name?: Maybe<Scalars['String']>;
    readonly message?: Maybe<Scalars['String']>;
    readonly createdAt?: Maybe<Scalars['String']>;
    readonly resentAt?: Maybe<Scalars['String']>;
};

export type PendingRequestEdge = {
    readonly __typename?: 'PendingRequestEdge';
    readonly node: PendingRequest;
};

export type PendingRequestsConnection = {
    readonly __typename?: 'PendingRequestsConnection';
    readonly edges: ReadonlyArray<PendingRequestEdge>;
    readonly nodes: ReadonlyArray<PendingRequest>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int'];
};

export enum PhoneNumberFormat {
    Raw = 'RAW',
    International = 'INTERNATIONAL',
}

export type PostalCodesData = {
    readonly __typename?: 'PostalCodesData';
    readonly country: Scalars['String'];
    readonly postalCodes: ReadonlyArray<Scalars['String']>;
};

export type PostalCodesDataInput = {
    /** The country the lead is interested in */
    readonly country: Scalars['String'];
    /** The list of postal codes that the lead is interested in */
    readonly postalCodes: ReadonlyArray<Scalars['String']>;
};

export type PostalCodesLocationPreference = LocationPreference & {
    readonly __typename?: 'PostalCodesLocationPreference';
    readonly id: Scalars['ID'];
    readonly type: Scalars['String'];
    readonly name: Scalars['String'];
    readonly data: PostalCodesData;
    readonly createdAt: Scalars['DateTime'];
    readonly updatedAt: Scalars['DateTime'];
};

export type PreferenceBudget = {
    readonly __typename?: 'PreferenceBudget';
    readonly maxPrice?: Maybe<Scalars['Float']>;
    readonly minPrice?: Maybe<Scalars['Float']>;
};

export type PreferenceBudgetInput = {
    readonly minPrice?: Maybe<Scalars['Float']>;
    readonly maxPrice?: Maybe<Scalars['Float']>;
};

export type PriceAggregation = {
    readonly __typename?: 'PriceAggregation';
    readonly maxPrice: Scalars['Float'];
    readonly minPrice: Scalars['Float'];
    readonly count: Scalars['Int'];
    readonly histogram: ReadonlyArray<HistogramBin>;
};

export type PropertyOffersFilter = {
    readonly propertyId: Scalars['ID'];
    readonly archived: Scalars['Boolean'];
};

export type PropertyReference = {
    readonly __typename?: 'PropertyReference';
    readonly assignee?: Maybe<PropertyReferenceAssignee>;
    readonly team?: Maybe<PropertyReferenceTeam>;
};

export type PropertyReferenceAssignee = {
    readonly __typename?: 'PropertyReferenceAssignee';
    readonly id: Scalars['ID'];
    readonly first_name?: Maybe<Scalars['String']>;
    readonly last_name?: Maybe<Scalars['String']>;
    readonly phone?: Maybe<Scalars['String']>;
    readonly email?: Maybe<Scalars['String']>;
    readonly picture_url?: Maybe<Scalars['String']>;
};

export type PropertyReferenceTeam = {
    readonly __typename?: 'PropertyReferenceTeam';
    readonly id: Scalars['ID'];
    readonly name: Scalars['String'];
};

export type PropertyStats = {
    readonly __typename?: 'PropertyStats';
    readonly numberOfPendingOffers: Scalars['Int'];
    readonly closestOfferExpirations: ReadonlyArray<Scalars['DateTime']>;
    readonly hasAcceptedOffer: Scalars['Boolean'];
    readonly highestOfferBid: Scalars['Float'];
};

export type PropertyStatsFilter = {
    readonly propertyId: Scalars['ID'];
};

export type PropertyStatus = {
    readonly __typename?: 'PropertyStatus';
    /** The id of the negotiation status */
    readonly id: Scalars['ID'];
    readonly order: Scalars['Int'];
    /** The negotiation type for which this status applies */
    readonly negotiation: Negotiation;
};

export type PropertyTimeline = {
    readonly __typename?: 'PropertyTimeline';
    readonly activities: ActivityConnection;
};

export type PropertyTimelineActivitiesArgs = {
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    contactId?: Maybe<Scalars['String']>;
};

export type PropertyVisitedActivity = Activity & {
    readonly __typename?: 'PropertyVisitedActivity';
    readonly id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    readonly timestamp: Scalars['DateTime'];
    readonly visitedBy?: Maybe<ReadonlyArray<Maybe<Contact>>>;
    readonly property?: Maybe<Estate>;
    readonly registeredBy: User;
    readonly details?: Maybe<ActivityDetails>;
    readonly action?: Maybe<ActivityAction>;
};

export type PublicationErrorContext = {
    readonly __typename?: 'PublicationErrorContext';
    readonly field?: Maybe<Scalars['String']>;
};

export enum PublicationStatus {
    NotPublished = 'NOT_PUBLISHED',
    Publishing = 'PUBLISHING',
    Unpublishing = 'UNPUBLISHING',
    Published = 'PUBLISHED',
    Unpublished = 'UNPUBLISHED',
    PublishingFailed = 'PUBLISHING_FAILED',
    UnpublishingFailed = 'UNPUBLISHING_FAILED',
}

export type PublicationWarningContext = {
    readonly __typename?: 'PublicationWarningContext';
    readonly field?: Maybe<Scalars['String']>;
};

export type PublishPropertyInput = {
    readonly propertyId: Scalars['ID'];
    readonly channelAccountId: Scalars['ID'];
    readonly channelId: Scalars['String'];
    readonly config?: Maybe<Scalars['JSON']>;
};

export type PublishPropertyPayload = {
    readonly __typename?: 'PublishPropertyPayload';
    readonly estate?: Maybe<Estate>;
};

export type Query = {
    readonly __typename?: 'Query';
    readonly channelAccounts?: Maybe<ReadonlyArray<ChannelAccount>>;
    readonly channelAccount?: Maybe<ChannelAccount>;
    readonly channels: ReadonlyArray<Channel>;
    readonly channel?: Maybe<Channel>;
    readonly estateChannelAccount?: Maybe<EstateConfigForChannelAccount>;
    readonly estates: EstatesConnection;
    readonly estate?: Maybe<Estate>;
    readonly estatesOptions?: Maybe<EstatesOptions>;
    readonly propertyReference?: Maybe<PropertyReference>;
    readonly estatePublications?: Maybe<EstatePublications>;
    readonly contacts: ContactConnection;
    readonly contact?: Maybe<Contact>;
    readonly contactReference?: Maybe<ContactReference>;
    readonly contactsOptions?: Maybe<ContactsOptions>;
    readonly contactLabels: ReadonlyArray<Label>;
    readonly lead?: Maybe<Lead>;
    readonly authCheck?: Maybe<Scalars['JSON']>;
    readonly label?: Maybe<Label>;
    readonly labels?: Maybe<LabelsPayload>;
    readonly propertiesAggregations: EstateAggregations;
    /** The current authenticated user */
    readonly me: User;
    readonly user?: Maybe<User>;
    readonly office?: Maybe<Office>;
    readonly statuses: ReadonlyArray<PropertyStatus>;
    readonly activity?: Maybe<Activity>;
    readonly propertySchedulePendingConfirmation: ScheduleCount;
    readonly contactSchedulePendingConfirmation: ScheduleCount;
    readonly propertySchedule: ReadonlyArray<ScheduleEvent>;
    readonly contactSchedule: ReadonlyArray<ScheduleEventVisit>;
    readonly offer?: Maybe<Offer>;
    readonly propertyOffers: ReadonlyArray<Offer>;
    readonly propertyStats?: Maybe<PropertyStats>;
    readonly contactOffers: ReadonlyArray<Offer>;
    readonly contactStats?: Maybe<ContactStats>;
    readonly version: Scalars['String'];
    readonly geoTemplate: GeoTemplate;
    readonly geoTemplates: GeoTemplatesPayload;
    readonly team?: Maybe<CompanyTeamResponse>;
    readonly teams?: Maybe<CompanyTeamsResponse>;
    readonly userTeams?: Maybe<CompanyTeamsResponse>;
    readonly matchingFlags: MatchingFlagsPayload;
    readonly matchingConfig: MatchingConfigPayload;
    readonly matchingRequirements: MatchingRequirementsPayload;
};

export type QueryChannelAccountsArgs = {
    companyId?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

export type QueryChannelAccountArgs = {
    companyId?: Maybe<Scalars['String']>;
    accountId?: Maybe<Scalars['String']>;
};

export type QueryChannelsArgs = {
    type?: Maybe<Scalars['String']>;
};

export type QueryChannelArgs = {
    id: Scalars['ID'];
};

export type QueryEstateChannelAccountArgs = {
    estateId: Scalars['ID'];
    estateChannelAccountId: Scalars['ID'];
};

export type QueryEstatesArgs = {
    query?: Maybe<Scalars['String']>;
    filters?: Maybe<EstateFilters>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    useAlgolia?: Maybe<Scalars['Boolean']>;
    locale?: Maybe<Scalars['String']>;
    useAtlasSearch?: Maybe<Scalars['Boolean']>;
    usePostRequest?: Maybe<Scalars['Boolean']>;
};

export type QueryEstateArgs = {
    id: Scalars['ID'];
};

export type QueryPropertyReferenceArgs = {
    id: Scalars['ID'];
};

export type QueryEstatePublicationsArgs = {
    id: Scalars['ID'];
};

export type QueryContactsArgs = {
    query?: Maybe<Scalars['String']>;
    locale?: Maybe<Scalars['String']>;
    sortOrder?: Maybe<Scalars['String']>;
    sortField?: Maybe<Scalars['String']>;
    filters?: Maybe<ContactFiltersInput>;
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    useAtlasSearch?: Maybe<Scalars['Boolean']>;
};

export type QueryContactArgs = {
    id: Scalars['ID'];
};

export type QueryContactReferenceArgs = {
    id: Scalars['ID'];
};

export type QueryContactLabelsArgs = {
    id: Scalars['ID'];
};

export type QueryLeadArgs = {
    id: Scalars['ID'];
};

export type QueryAuthCheckArgs = {
    input?: Maybe<ReadonlyArray<AuthCheckInput>>;
};

export type QueryLabelArgs = {
    id: Scalars['ID'];
};

export type QueryLabelsArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
};

export type QueryPropertiesAggregationsArgs = {
    negotiation?: Maybe<Scalars['String']>;
};

export type QueryUserArgs = {
    id: Scalars['ID'];
    allowNotFound?: Maybe<Scalars['Boolean']>;
};

export type QueryOfficeArgs = {
    id: Scalars['ID'];
};

export type QueryStatusesArgs = {
    negotiation?: Maybe<Negotiation>;
};

export type QueryActivityArgs = {
    id: Scalars['ID'];
};

export type QueryPropertySchedulePendingConfirmationArgs = {
    propertyId: Scalars['ID'];
};

export type QueryContactSchedulePendingConfirmationArgs = {
    contactId: Scalars['ID'];
};

export type QueryPropertyScheduleArgs = {
    propertyId: Scalars['ID'];
    type?: Maybe<ReadonlyArray<ScheduleEventType>>;
    query?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
};

export type QueryContactScheduleArgs = {
    contactId: Scalars['ID'];
    query?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
};

export type QueryOfferArgs = {
    offerId: Scalars['ID'];
};

export type QueryPropertyOffersArgs = {
    filter: PropertyOffersFilter;
};

export type QueryPropertyStatsArgs = {
    filter: PropertyStatsFilter;
};

export type QueryContactOffersArgs = {
    filter: ContactOffersFilter;
};

export type QueryContactStatsArgs = {
    filter: ContactStatsFilter;
};

export type QueryGeoTemplateArgs = {
    officeId: Scalars['ID'];
    id: Scalars['ID'];
};

export type QueryGeoTemplatesArgs = {
    officeId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
};

export type QueryTeamArgs = {
    teamId: Scalars['ID'];
};

export type QueryTeamsArgs = {
    companyId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    query?: Maybe<Scalars['String']>;
};

export type QueryUserTeamsArgs = {
    userId: Scalars['ID'];
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type QueryMatchingFlagsArgs = {
    input: MatchingFlagsInput;
};

export type QueryMatchingConfigArgs = {
    input: MatchingConfigInput;
};

export type QueryMatchingRequirementsArgs = {
    input: MatchingRequirementsInput;
};

export type RefreshPublicationInput = {
    readonly propertyId: Scalars['ID'];
    readonly channelAccountId: Scalars['ID'];
};

export type RefreshPublicationPayload = {
    readonly __typename?: 'RefreshPublicationPayload';
    readonly success: Scalars['Boolean'];
};

export type RegisterEstateActivityInput = {
    readonly estateId: Scalars['ID'];
    readonly type: Scalars['String'];
    readonly contactId: Scalars['String'];
};

export type RegisterEstateActivityPayload = {
    readonly __typename?: 'RegisterEstateActivityPayload';
    readonly subject: Estate;
    readonly contact?: Maybe<Contact>;
    readonly activity: Activity;
};

export type RemoveIntegrationInput = {
    readonly name: Scalars['String'];
};

export type RemoveIntegrationPayload = {
    readonly __typename?: 'RemoveIntegrationPayload';
    readonly user: User;
};

export type RemoveLeadPreferenceInput = {
    readonly contactId: Scalars['ID'];
    readonly preferenceId: Scalars['ID'];
};

export type RemoveLeadPreferencePayload = {
    readonly __typename?: 'RemoveLeadPreferencePayload';
    readonly contact: Contact;
};

export type RemoveLocationPayload = {
    readonly __typename?: 'RemoveLocationPayload';
    /** The lead the location preference belonged to */
    readonly lead: Lead;
    /** The id of the removed location preference */
    readonly id: Scalars['ID'];
};

export type RemoveLocationTemplatePayload = {
    readonly __typename?: 'RemoveLocationTemplatePayload';
    /** The office the location template belonged to */
    readonly office: Office;
    /** The id of the removed location template */
    readonly id: Scalars['ID'];
};

export type RetryPublicationInput = {
    readonly propertyId: Scalars['ID'];
    readonly channelAccountId: Scalars['ID'];
};

export type RetryPublicationPayload = {
    readonly __typename?: 'RetryPublicationPayload';
    /** The channel account edge */
    readonly channelAccount: EstateChannelAccountConnectionEdge;
};

export type ScheduleCount = {
    readonly __typename?: 'ScheduleCount';
    readonly count: Scalars['Int'];
};

export type ScheduleEvent = {
    readonly id: Scalars['ID'];
    readonly title?: Maybe<Scalars['String']>;
    readonly date?: Maybe<Scalars['String']>;
    readonly start?: Maybe<Scalars['String']>;
    readonly end?: Maybe<Scalars['String']>;
    readonly location?: Maybe<Scalars['String']>;
    readonly propertyId: Scalars['ID'];
    readonly estateLocation?: Maybe<Scalars['String']>;
    readonly type: ScheduleEventType;
    readonly createdAt: Scalars['String'];
    readonly updatedAt: Scalars['String'];
};

export type ScheduleEventEstateLocationArgs = {
    lang?: Scalars['String'];
};

export type ScheduleEventAttendee = {
    readonly __typename?: 'ScheduleEventAttendee';
    readonly id: Scalars['String'];
    readonly resource_type: Scalars['String'];
};

export type ScheduleEventAuction = ScheduleEvent & {
    readonly __typename?: 'ScheduleEventAuction';
    readonly id: Scalars['ID'];
    readonly title?: Maybe<Scalars['String']>;
    readonly date?: Maybe<Scalars['String']>;
    readonly start?: Maybe<Scalars['String']>;
    readonly end?: Maybe<Scalars['String']>;
    readonly location?: Maybe<Scalars['String']>;
    readonly estateLocation?: Maybe<Scalars['String']>;
    readonly propertyId: Scalars['ID'];
    readonly type: ScheduleEventType;
    readonly createdAt: Scalars['String'];
    readonly updatedAt: Scalars['String'];
};

export type ScheduleEventAuctionEstateLocationArgs = {
    lang?: Scalars['String'];
};

export type ScheduleEventOpenHome = ScheduleEvent & {
    readonly __typename?: 'ScheduleEventOpenHome';
    readonly id: Scalars['ID'];
    readonly title?: Maybe<Scalars['String']>;
    readonly date?: Maybe<Scalars['String']>;
    readonly start?: Maybe<Scalars['String']>;
    readonly end?: Maybe<Scalars['String']>;
    readonly location?: Maybe<Scalars['String']>;
    readonly estateLocation?: Maybe<Scalars['String']>;
    readonly propertyId: Scalars['ID'];
    readonly type: ScheduleEventType;
    readonly createdAt: Scalars['String'];
    readonly updatedAt: Scalars['String'];
    readonly companyId?: Maybe<Scalars['String']>;
    readonly officeId?: Maybe<Scalars['String']>;
};

export type ScheduleEventOpenHomeEstateLocationArgs = {
    lang?: Scalars['String'];
};

export enum ScheduleEventType {
    Visit = 'VISIT',
    Auction = 'AUCTION',
    Openhome = 'OPENHOME',
}

export type ScheduleEventVisit = ScheduleEvent & {
    readonly __typename?: 'ScheduleEventVisit';
    readonly id: Scalars['ID'];
    readonly title?: Maybe<Scalars['String']>;
    readonly date?: Maybe<Scalars['String']>;
    readonly start?: Maybe<Scalars['String']>;
    readonly end?: Maybe<Scalars['String']>;
    readonly location?: Maybe<Scalars['String']>;
    readonly estateLocation?: Maybe<Scalars['String']>;
    readonly propertyId: Scalars['ID'];
    readonly type: ScheduleEventType;
    readonly createdAt: Scalars['String'];
    readonly updatedAt: Scalars['String'];
    readonly attendees?: Maybe<ReadonlyArray<ScheduleEventAttendee>>;
    readonly description?: Maybe<Scalars['String']>;
    readonly organizerId?: Maybe<Scalars['String']>;
    readonly status?: Maybe<VisitStatus>;
};

export type ScheduleEventVisitEstateLocationArgs = {
    lang?: Scalars['String'];
};

export type ScheduleVisitInput = {
    readonly estateId: Scalars['ID'];
    readonly contactId: Scalars['ID'];
    readonly dateTime: Scalars['DateTime'];
    readonly negotiatorId: Scalars['ID'];
    readonly message: Scalars['String'];
    readonly duration?: Maybe<Scalars['Int']>;
};

export type ScheduleVisitPayload = {
    readonly __typename?: 'ScheduleVisitPayload';
    readonly success: Scalars['Boolean'];
    /** The contact the visit was scheduled with */
    readonly contact?: Maybe<Contact>;
    /** The estate the visit was scheduled for */
    readonly estate?: Maybe<Estate>;
};

export type SendVendorReportInput = {
    readonly vendorId: Scalars['ID'];
    readonly message?: Maybe<Scalars['String']>;
};

export type SendVendorReportPayload = {
    readonly __typename?: 'SendVendorReportPayload';
    readonly reportId: Scalars['ID'];
    readonly vendor?: Maybe<Vendor>;
};

export type TeamMembersConnection = {
    readonly __typename?: 'TeamMembersConnection';
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
    readonly nodes: ReadonlyArray<User>;
    readonly edges: ReadonlyArray<TeamMembersConnectionEdge>;
};

export type TeamMembersConnectionEdge = {
    readonly __typename?: 'TeamMembersConnectionEdge';
    readonly node: User;
};

export type TranslateInput = {
    readonly inputLanguage: Scalars['String'];
    readonly outputLanguage: Scalars['String'];
    readonly text: Scalars['String'];
};

export type TranslatePayload = {
    readonly __typename?: 'TranslatePayload';
    readonly outputLanguage: Scalars['String'];
    readonly text: Scalars['String'];
};

export type TwitterPublicationConfig = EstatePublicationConfig & {
    readonly __typename?: 'TwitterPublicationConfig';
    readonly showPrice?: Maybe<Scalars['Boolean']>;
    readonly showLocation?: Maybe<Scalars['Boolean']>;
    readonly message?: Maybe<Scalars['String']>;
};

export type TwitterPublicationSettings = AccountPublicationSettings & {
    readonly __typename?: 'TwitterPublicationSettings';
    readonly publishPrice?: Maybe<Scalars['Boolean']>;
    readonly publishLocation?: Maybe<Scalars['Boolean']>;
    readonly tweetText?: Maybe<Scalars['String']>;
};

export type TwitterPublicationSettingsInput = {
    readonly tweetText: Scalars['String'];
};

export type UnarchiveEstateInput = {
    /** The ID of the estate to unarchive */
    readonly estateId: Scalars['ID'];
    readonly estateType: EstateType;
};

export type UnarchiveEstatePayload = {
    readonly __typename?: 'UnarchiveEstatePayload';
    /** The estate that was unarchived */
    readonly estate?: Maybe<Estate>;
};

export type UnassignBuyersToEstateInput = {
    readonly estateId: Scalars['ID'];
    readonly buyerIds: ReadonlyArray<Scalars['ID']>;
};

export type UnassignBuyersToEstatePayload = {
    readonly __typename?: 'UnassignBuyersToEstatePayload';
    readonly estate?: Maybe<Estate>;
};

export type UnassignLegalEntityToEstateInput = {
    readonly estateId: Scalars['ID'];
};

export type UnassignLegalEntityToPayload = {
    readonly __typename?: 'UnassignLegalEntityToPayload';
    readonly estate?: Maybe<Estate>;
};

export type UnassignVendorsToEstateInput = {
    readonly estateId: Scalars['ID'];
    readonly vendorIds: ReadonlyArray<Scalars['ID']>;
};

export type UnassignVendorsToEstatePayload = {
    readonly __typename?: 'UnassignVendorsToEstatePayload';
    readonly estate?: Maybe<Estate>;
};

export type UnitEdge = {
    readonly __typename?: 'UnitEdge';
    readonly node: Estate;
};

export type UnitPublicationErrors = {
    readonly __typename?: 'UnitPublicationErrors';
    readonly unitId: Scalars['ID'];
    readonly errors: ReadonlyArray<EstatePublicationError>;
};

export type UnitPublicationWarnings = {
    readonly __typename?: 'UnitPublicationWarnings';
    readonly unitId: Scalars['ID'];
    readonly warnings: ReadonlyArray<EstatePublicationWarning>;
};

export type UnitsConnection = {
    readonly __typename?: 'UnitsConnection';
    readonly edges: ReadonlyArray<UnitEdge>;
    readonly nodes: ReadonlyArray<Estate>;
    readonly pageInfo: PageInfo;
    readonly totalCount: Scalars['Int'];
};

export type UnpublishPropertyInput = {
    readonly propertyId: Scalars['ID'];
    readonly channelAccountId: Scalars['ID'];
};

export type UpdateContactAddressMutationInput = {
    /** the id of the contact */
    readonly id: Scalars['ID'];
    readonly address?: Maybe<Scalars['JSON']>;
};

export type UpdateContactAssignmentsInput = {
    /** Contact ID */
    readonly id: Scalars['ID'];
    /** Ids of negotiators assigned to this contact */
    readonly assignments: ReadonlyArray<Scalars['Int']>;
};

export type UpdateContactAssignmentsPayload = {
    readonly __typename?: 'UpdateContactAssignmentsPayload';
    readonly success: Scalars['Boolean'];
    readonly errorMessage?: Maybe<Scalars['String']>;
};

export type UpdateContactMutationInput = {
    /** the id of the contact */
    readonly id: Scalars['ID'];
    /** the type of the contact that is being updated */
    readonly type: ContactType;
    /** the prefered locale of the contact */
    readonly locale?: Maybe<Scalars['String']>;
    readonly firstName?: Maybe<Scalars['String']>;
    readonly lastName?: Maybe<Scalars['String']>;
    readonly phone?: Maybe<Scalars['String']>;
    readonly email?: Maybe<Scalars['String']>;
    readonly company?: Maybe<Scalars['String']>;
    readonly note?: Maybe<Scalars['String']>;
    readonly pronouns?: Maybe<Scalars['String']>;
};

export type UpdateContactMutationPayload = {
    readonly __typename?: 'UpdateContactMutationPayload';
    readonly success: Scalars['Boolean'];
    /** The updated contact after the mutation */
    readonly contact?: Maybe<Contact>;
    readonly validationErrors?: Maybe<Scalars['JSON']>;
};

export type UpdateEstatePayload = {
    readonly __typename?: 'UpdateEstatePayload';
    /** List of errors that ocurred executing the mutation */
    readonly userErrors: ReadonlyArray<UserError>;
    readonly estate?: Maybe<Estate>;
    readonly success?: Maybe<Scalars['Boolean']>;
};

export type UpdateLeadPreferenceInput = {
    readonly contactId: Scalars['ID'];
    readonly preferenceId: Scalars['ID'];
    readonly type: Scalars['String'];
    readonly subtypes?: Maybe<ReadonlyArray<Scalars['String']>>;
    readonly negotiation: Negotiation;
    readonly isInvestor: Scalars['Boolean'];
    readonly condition: Scalars['String'];
    readonly amenities: ReadonlyArray<Scalars['String']>;
    readonly minBedrooms?: Maybe<Scalars['Int']>;
    readonly budget: PreferenceBudgetInput;
    readonly max_liveable_area?: Maybe<Scalars['Float']>;
    readonly min_liveable_area?: Maybe<Scalars['Float']>;
    readonly max_plot_area?: Maybe<Scalars['Float']>;
    readonly min_plot_area?: Maybe<Scalars['Float']>;
    readonly max_net_area?: Maybe<Scalars['Float']>;
    readonly min_net_area?: Maybe<Scalars['Float']>;
    readonly max_gross_area?: Maybe<Scalars['Float']>;
    readonly min_gross_area?: Maybe<Scalars['Float']>;
};

export type UpdateLeadPreferencePayload = {
    readonly __typename?: 'UpdateLeadPreferencePayload';
    /** List of errors that ocurred executing the mutation */
    readonly userErrors: ReadonlyArray<UserError>;
    /** The updated lead preference */
    readonly preference?: Maybe<BuyerPreference>;
    /** Wheter the mutation executed sucessfully or not */
    readonly success: Scalars['Boolean'];
};

export type UpdateLocationPreferencePayload = {
    readonly __typename?: 'UpdateLocationPreferencePayload';
    /** The updated location preference */
    readonly locationPreference: LocationPreference;
};

export type UpdateLocationTemplatePayload = {
    readonly __typename?: 'UpdateLocationTemplatePayload';
    /** The updated location template */
    readonly locationTemplate: LocationPreference;
};

export type UpdateOfferInput = {
    readonly id: Scalars['ID'];
    readonly buyers?: Maybe<ReadonlyArray<Scalars['ID']>>;
    readonly owners?: Maybe<ReadonlyArray<Scalars['ID']>>;
    readonly notes?: Maybe<Scalars['String']>;
    readonly validUntil?: Maybe<Scalars['DateTime']>;
    readonly financialDetails?: Maybe<FinancialDetailsInput>;
};

export type UpdatePropertyVisibilityPayload = {
    readonly __typename?: 'UpdatePropertyVisibilityPayload';
    readonly propertyId?: Maybe<Scalars['ID']>;
};

export type UpdateUserInput = {
    readonly attributes?: Maybe<Scalars['JSON']>;
};

export type UpdateUserPayload = {
    readonly __typename?: 'UpdateUserPayload';
    readonly user?: Maybe<User>;
    readonly success: Scalars['Boolean'];
};

export type UpdateVisitStatusInput = {
    readonly visitID: Scalars['ID'];
    readonly visitStatus: VisitStatus;
    readonly createActivities: Scalars['Boolean'];
};

export type UpdateVisitStatusPayload = {
    readonly __typename?: 'UpdateVisitStatusPayload';
    readonly success: Scalars['Boolean'];
};

/** A user of the Sweepbright app */
export type User = {
    readonly __typename?: 'User';
    readonly id: Scalars['ID'];
    /** The user first name */
    readonly firstName?: Maybe<Scalars['String']>;
    /** The user last name */
    readonly lastName?: Maybe<Scalars['String']>;
    /** The current office the user is in, used when retrieving properties and contacts */
    readonly office: Office;
    readonly email: Scalars['String'];
    /** The user phone number */
    readonly phone?: Maybe<Scalars['String']>;
    /** The user phone number */
    readonly photo?: Maybe<Scalars['JSON']>;
    /** The active user integrations (JSON for now as a scape hatch) */
    readonly integrations?: Maybe<Scalars['JSON']>;
    /** The configuration data for the given integration */
    readonly integration?: Maybe<Scalars['JSON']>;
    readonly calendar?: Maybe<Scalars['JSON']>;
    readonly hasCalendarIntegration?: Maybe<Scalars['Boolean']>;
    readonly appointmentServiceUrl?: Maybe<Scalars['String']>;
    /** The company the user belongs to */
    readonly company?: Maybe<Company>;
    readonly locale?: Maybe<Scalars['String']>;
    readonly bio: ReadonlyArray<LocalizedText>;
    readonly role: Scalars['String'];
    readonly errorStatus?: Maybe<Scalars['Int']>;
    readonly pronouns?: Maybe<Scalars['String']>;
};

/** A user of the Sweepbright app */
export type UserIntegrationArgs = {
    name: Scalars['String'];
};

/** Represents an error in the input of a mutation. */
export type UserError = DisplayableError & {
    readonly __typename?: 'UserError';
    /** Path to the input field which caused the error */
    readonly field?: Maybe<ReadonlyArray<Scalars['String']>>;
    /** The error message */
    readonly message: Scalars['String'];
};

export enum UserRole {
    TeamMember = 'TeamMember',
    Editor = 'Editor',
    TeamAdmin = 'TeamAdmin',
    CompanyAdmin = 'CompanyAdmin',
}

export type Vendor = Contact & {
    readonly __typename?: 'Vendor';
    readonly id: Scalars['ID'];
    /** The first name of the contact */
    readonly firstName: Scalars['String'];
    /** The last name of the contact */
    readonly lastName: Scalars['String'];
    /** The email address of the contact */
    readonly email?: Maybe<Scalars['String']>;
    readonly phone?: Maybe<Scalars['String']>;
    readonly company?: Maybe<Scalars['String']>;
    readonly officeId?: Maybe<Scalars['String']>;
    readonly locale?: Maybe<Scalars['String']>;
    readonly pronouns?: Maybe<Scalars['String']>;
    readonly note?: Maybe<Scalars['String']>;
    readonly notes: ReadonlyArray<Note>;
    readonly subscribed?: Maybe<Scalars['Boolean']>;
    readonly type: ContactType;
    readonly photo?: Maybe<ContactPhoto>;
    readonly address: Scalars['JSON'];
    /** The estates owned by the vendor */
    readonly estates: VendorEstatesConnection;
    readonly createdAt: Scalars['DateTime'];
    readonly updatedAt: Scalars['DateTime'];
    readonly timeline: ContactTimeline;
    readonly interactedProperties: EstatesConnection;
    /** The list of properties that the contact can interact with */
    readonly eligibleEstates: EstatesConnection;
    readonly archived: Scalars['Boolean'];
    /** The last interaction with this contact */
    readonly lastInteraction?: Maybe<Scalars['JSON']>;
    /** The time of the last report sent to this vendor */
    readonly lastReportSentAt?: Maybe<Scalars['DateTime']>;
    readonly assignedNegotiators?: Maybe<ReadonlyArray<ContactAssignedNegotiator>>;
    readonly offers: ReadonlyArray<Offer>;
    readonly offersStats?: Maybe<OffersStats>;
};

export type VendorPhoneArgs = {
    format?: Maybe<PhoneNumberFormat>;
};

export type VendorEstatesArgs = {
    page?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
};

export type VendorInteractedPropertiesArgs = {
    interestLevel?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
};

export type VendorEligibleEstatesArgs = {
    query?: Maybe<Scalars['String']>;
    limit?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
};

export type VendorOffersArgs = {
    archived: Scalars['Boolean'];
};

export type VendorEstatesConnection = {
    readonly __typename?: 'VendorEstatesConnection';
    readonly totalCount: Scalars['Int'];
    readonly pageInfo: PageInfo;
    readonly edges: ReadonlyArray<VendorEstatesConnectionEdge>;
    readonly nodes: ReadonlyArray<Estate>;
};

export type VendorEstatesConnectionEdge = {
    readonly __typename?: 'VendorEstatesConnectionEdge';
    readonly node: Estate;
};

export type VendorReportEmailedActivity = Activity & {
    readonly __typename?: 'VendorReportEmailedActivity';
    readonly id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    readonly timestamp?: Maybe<Scalars['DateTime']>;
    readonly details?: Maybe<ActivityDetails>;
    readonly action?: Maybe<ActivityAction>;
    readonly sentBy: User;
    /** The vendor to which the vendor report was sent */
    readonly sentTo: Vendor;
    /** The estate included in the vendor report */
    readonly about?: Maybe<Estate>;
};

export type Visit = {
    readonly __typename?: 'Visit';
    readonly id: Scalars['ID'];
    readonly property?: Maybe<Estate>;
    /** The duration of the visit (in minutes) */
    readonly duration: Scalars['Int'];
    readonly organizer?: Maybe<User>;
    /** The contacts attending the visit */
    readonly attendees?: Maybe<ReadonlyArray<Maybe<Contact>>>;
};

export type VisitScheduledActivity = Activity & {
    readonly __typename?: 'VisitScheduledActivity';
    readonly id: Scalars['ID'];
    /** Identifies the date and time of the activity */
    readonly timestamp: Scalars['DateTime'];
    readonly visit: Visit;
    readonly details?: Maybe<ActivityDetails>;
    readonly action?: Maybe<ActivityAction>;
    readonly attendee?: Maybe<Contact>;
};

export enum VisitStatus {
    Confirmed = 'confirmed',
    Missed = 'missed',
    Pending = 'pending',
}

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
    fragment: string;
    resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
    selectionSet: string;
    resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
    | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
    | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
    | ResolverFn<TResult, TParent, TContext, TArgs>
    | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo,
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
    subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
    resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
    subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
    resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
    | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
    | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
    | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
    | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
    parent: TParent,
    context: TContext,
    info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
    obj: T,
    context: TContext,
    info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
    next: NextResolverFn<TResult>,
    parent: TParent,
    args: TArgs,
    context: TContext,
    info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
    Query: ResolverTypeWrapper<{}>;
    String: ResolverTypeWrapper<Scalars['String']>;
    ChannelAccount: ResolverTypeWrapper<ChannelAccount>;
    ID: ResolverTypeWrapper<Scalars['ID']>;
    ChannelAccountCredentials: ResolverTypeWrapper<ChannelAccountCredentials>;
    Channel: ResolverTypeWrapper<Channel>;
    Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
    ChannelSettings: ResolverTypeWrapper<ChannelSettings>;
    EstateConfigForChannelAccount: ResolverTypeWrapper<
        Omit<EstateConfigForChannelAccount, 'lastPublication'> & {
            lastPublication?: Maybe<ResolversTypes['EstatePublication']>;
        }
    >;
    OldUnitsMissingFields: ResolverTypeWrapper<OldUnitsMissingFields>;
    OldConfigurationStatus: OldConfigurationStatus;
    OldPublicationStatus: OldPublicationStatus;
    AccountPublicationSettings:
        | ResolversTypes['GenericAccountPublicationSettings']
        | ResolversTypes['FacebookPublicationSettings']
        | ResolversTypes['TwitterPublicationSettings']
        | ResolversTypes['ImmowebPublicationSettings']
        | ResolversTypes['EstateAgentFeedsPublicationSettings']
        | ResolversTypes['FrenchPortalPublicationSettings'];
    EstatePublication: ResolverTypeWrapper<
        Omit<EstatePublication, 'publishedBy'> & { publishedBy?: Maybe<ResolversTypes['User']> }
    >;
    DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
    User: ResolverTypeWrapper<
        Omit<User, 'office' | 'company'> & {
            office: ResolversTypes['Office'];
            company?: Maybe<ResolversTypes['Company']>;
        }
    >;
    Office: ResolverTypeWrapper<
        Omit<Office, 'negotiators' | 'negotiatiorsByIds' | 'estates' | 'members' | 'companyMembers'> & {
            negotiators: ReadonlyArray<ResolversTypes['User']>;
            negotiatiorsByIds: ReadonlyArray<ResolversTypes['User']>;
            estates?: Maybe<ResolversTypes['EstatesConnection']>;
            members?: Maybe<ResolversTypes['TeamMembersConnection']>;
            companyMembers?: Maybe<ResolversTypes['TeamMembersConnection']>;
        }
    >;
    OfficeSettings: ResolverTypeWrapper<OfficeSettings>;
    LegalEntity: ResolverTypeWrapper<LegalEntity>;
    LegalEntities: ResolverTypeWrapper<LegalEntities>;
    Int: ResolverTypeWrapper<Scalars['Int']>;
    LegalEntitiesConnection: ResolverTypeWrapper<LegalEntitiesConnection>;
    PageInfo: ResolverTypeWrapper<PageInfo>;
    LegalEntitiesEdge: ResolverTypeWrapper<LegalEntitiesEdge>;
    LocationPreferenceConnection: ResolverTypeWrapper<LocationPreferenceConnection>;
    LocationPreferenceEdge: ResolverTypeWrapper<LocationPreferenceEdge>;
    LocationPreference: ResolversTypes['PostalCodesLocationPreference'] | ResolversTypes['GeoJSONLocationPreference'];
    EstatesConnection: ResolverTypeWrapper<
        Omit<EstatesConnection, 'nodes' | 'edges'> & {
            nodes: ReadonlyArray<ResolversTypes['Estate']>;
            edges: ReadonlyArray<ResolversTypes['EstateConnectionEdge']>;
        }
    >;
    Estate: ResolverTypeWrapper<
        Omit<
            Estate,
            | 'publications'
            | 'channelAccount'
            | 'interactedContacts'
            | 'visitors'
            | 'buyers'
            | 'vendors'
            | 'negotiator'
            | 'units'
            | 'unit'
            | 'matches'
            | 'offers'
        > & {
            publications: ResolversTypes['EstatePublications'];
            channelAccount?: Maybe<ResolversTypes['EstateChannelAccountConnectionEdge']>;
            interactedContacts: ResolversTypes['EstateContactConnection'];
            visitors: ResolversTypes['EstateContactConnection'];
            buyers: ResolversTypes['EstateContactConnection'];
            vendors: ResolversTypes['EstateContactConnection'];
            negotiator?: Maybe<ResolversTypes['User']>;
            units?: Maybe<ResolversTypes['UnitsConnection']>;
            unit?: Maybe<ResolversTypes['Estate']>;
            matches?: Maybe<ResolversTypes['EstateMatchesConnection']>;
            offers: ReadonlyArray<ResolversTypes['Offer']>;
        }
    >;
    EstateType: EstateType;
    EstatePublications: ResolverTypeWrapper<
        Omit<EstatePublications, 'channelAccounts'> & {
            channelAccounts: ResolversTypes['EstateChannelAccountConnection'];
        }
    >;
    EstateChannelAccountConnection: ResolverTypeWrapper<
        Omit<EstateChannelAccountConnection, 'edges'> & {
            edges: ReadonlyArray<ResolversTypes['EstateChannelAccountConnectionEdge']>;
        }
    >;
    EstateChannelAccountConnectionEdge: ResolverTypeWrapper<
        Omit<EstateChannelAccountConnectionEdge, 'lastPublication'> & {
            lastPublication?: Maybe<ResolversTypes['EstatePublication']>;
        }
    >;
    ConfigurationStatus: ConfigurationStatus;
    PublicationStatus: PublicationStatus;
    MissingFieldsEdge: ResolverTypeWrapper<MissingFieldsEdge>;
    MissingField: ResolverTypeWrapper<MissingField>;
    MissingFieldsConnection: ResolverTypeWrapper<MissingFieldsConnection>;
    PropertyTimeline: ResolverTypeWrapper<PropertyTimeline>;
    ActivityConnection: ResolverTypeWrapper<ActivityConnection>;
    ActivityEdge: ResolverTypeWrapper<ActivityEdge>;
    Activity:
        | ResolversTypes['VendorReportEmailedActivity']
        | ResolversTypes['ContactEmailedActivity']
        | ResolversTypes['VisitScheduledActivity']
        | ResolversTypes['PropertyVisitedActivity']
        | ResolversTypes['ContactCalledActivity']
        | ResolversTypes['ContactMessagedActivity']
        | ResolversTypes['LeadExpressedInterestActivity'];
    ActivityDetails: ResolversTypes['MessageActivityDetails'] | ResolversTypes['FeedbackActivityDetails'];
    ActivityAction: ActivityAction;
    JSON: ResolverTypeWrapper<Scalars['JSON']>;
    ContactType: ContactType;
    EstateContactConnection: ResolverTypeWrapper<
        Omit<EstateContactConnection, 'edges' | 'nodes'> & {
            edges: ReadonlyArray<ResolversTypes['EstateContactEdge']>;
            nodes: ReadonlyArray<ResolversTypes['Contact']>;
        }
    >;
    EstateContactEdge: ResolverTypeWrapper<Omit<EstateContactEdge, 'node'> & { node: ResolversTypes['Contact'] }>;
    Contact: ResolversTypes['Lead'] | ResolversTypes['Vendor'];
    PhoneNumberFormat: PhoneNumberFormat;
    Note: ResolverTypeWrapper<Note>;
    ContactPhoto: ResolverTypeWrapper<ContactPhoto>;
    ContactTimeline: ResolverTypeWrapper<ContactTimeline>;
    ContactAssignedNegotiator: ResolverTypeWrapper<ContactAssignedNegotiator>;
    Offer: ResolverTypeWrapper<PartialOffer>;
    OfferStatus: OfferStatus;
    FinancialDetails: ResolverTypeWrapper<FinancialDetails>;
    OfferDirection: OfferDirection;
    Currency: ResolverTypeWrapper<Scalars['Currency']>;
    Float: ResolverTypeWrapper<Scalars['Float']>;
    OfferOwner: ResolverTypeWrapper<OfferOwner>;
    OfferBuyer: ResolverTypeWrapper<OfferBuyer>;
    OffersStats: ResolverTypeWrapper<OffersStats>;
    UnitsConnection: ResolverTypeWrapper<
        Omit<UnitsConnection, 'edges' | 'nodes'> & {
            edges: ReadonlyArray<ResolversTypes['UnitEdge']>;
            nodes: ReadonlyArray<ResolversTypes['Estate']>;
        }
    >;
    UnitEdge: ResolverTypeWrapper<Omit<UnitEdge, 'node'> & { node: ResolversTypes['Estate'] }>;
    PendingRequestsConnection: ResolverTypeWrapper<PendingRequestsConnection>;
    PendingRequestEdge: ResolverTypeWrapper<PendingRequestEdge>;
    PendingRequest: ResolverTypeWrapper<PendingRequest>;
    EstateMatchesFilters: EstateMatchesFilters;
    EstateMatchesConnection: ResolverTypeWrapper<
        Omit<EstateMatchesConnection, 'edges' | 'nodes'> & {
            edges: ReadonlyArray<ResolversTypes['EstateMatchesConnectionEdge']>;
            nodes: ReadonlyArray<ResolversTypes['Contact']>;
        }
    >;
    EstateMatchesConnectionEdge: ResolverTypeWrapper<
        Omit<EstateMatchesConnectionEdge, 'node'> & { node: ResolversTypes['Contact'] }
    >;
    EstateConnectionEdge: ResolverTypeWrapper<Omit<EstateConnectionEdge, 'node'> & { node: ResolversTypes['Estate'] }>;
    TeamMembersConnection: ResolverTypeWrapper<
        Omit<TeamMembersConnection, 'nodes' | 'edges'> & {
            nodes: ReadonlyArray<ResolversTypes['User']>;
            edges: ReadonlyArray<ResolversTypes['TeamMembersConnectionEdge']>;
        }
    >;
    TeamMembersConnectionEdge: ResolverTypeWrapper<
        Omit<TeamMembersConnectionEdge, 'node'> & { node: ResolversTypes['User'] }
    >;
    Company: ResolverTypeWrapper<
        Omit<Company, 'lead' | 'offices' | 'admins'> & {
            lead?: Maybe<ResolversTypes['Contact']>;
            offices?: Maybe<ReadonlyArray<ResolversTypes['Office']>>;
            admins: ResolversTypes['CompanyAdminsConnection'];
        }
    >;
    CompanyLeadsConnection: ResolverTypeWrapper<CompanyLeadsConnection>;
    CompanyLeadConnectionEdge: ResolverTypeWrapper<CompanyLeadConnectionEdge>;
    BuyerLead: ResolverTypeWrapper<BuyerLead>;
    ContactRequestConnection: ResolverTypeWrapper<ContactRequestConnection>;
    ContactRequestConnectionEdge: ResolverTypeWrapper<ContactRequestConnectionEdge>;
    ContactRequest: ResolverTypeWrapper<ContactRequest>;
    BuyerPreference:
        | ResolversTypes['BuyerPreferenceForHouse']
        | ResolversTypes['BuyerPreferenceForApartment']
        | ResolversTypes['BuyerPreferenceForLand']
        | ResolversTypes['BuyerPreferenceForOffice']
        | ResolversTypes['BuyerPreferenceForCommercial']
        | ResolversTypes['BuyerPreferenceForParking'];
    Negotiation: Negotiation;
    PreferenceBudget: ResolverTypeWrapper<PreferenceBudget>;
    CompanyAdminsConnection: ResolverTypeWrapper<
        Omit<CompanyAdminsConnection, 'edges' | 'nodes'> & {
            edges: ReadonlyArray<ResolversTypes['CompanyAdminConnectionEdge']>;
            nodes: ReadonlyArray<ResolversTypes['User']>;
        }
    >;
    CompanyAdminConnectionEdge: ResolverTypeWrapper<
        Omit<CompanyAdminConnectionEdge, 'node'> & { node: ResolversTypes['User'] }
    >;
    LocalizedText: ResolverTypeWrapper<LocalizedText>;
    EstatePublicationConfig:
        | ResolversTypes['GenericPublicationConfig']
        | ResolversTypes['ImmowebPublicationConfig']
        | ResolversTypes['FacebookPublicationConfig']
        | ResolversTypes['EstateAgentFeedsPublicationConfig']
        | ResolversTypes['TwitterPublicationConfig']
        | ResolversTypes['FrenchPortalPublicationConfig'];
    EstatePublicationErrors: ResolverTypeWrapper<EstatePublicationErrors>;
    EstatePublicationError: ResolverTypeWrapper<EstatePublicationError>;
    PublicationErrorContext: ResolverTypeWrapper<PublicationErrorContext>;
    UnitPublicationErrors: ResolverTypeWrapper<UnitPublicationErrors>;
    EstatePublicationWarnings: ResolverTypeWrapper<EstatePublicationWarnings>;
    EstatePublicationWarning: ResolverTypeWrapper<EstatePublicationWarning>;
    PublicationWarningContext: ResolverTypeWrapper<PublicationWarningContext>;
    UnitPublicationWarnings: ResolverTypeWrapper<UnitPublicationWarnings>;
    EstateFilters: EstateFilters;
    FilterOfferStatus: FilterOfferStatus;
    EstatesOptions: ResolverTypeWrapper<EstatesOptions>;
    EstatesSortOption: ResolverTypeWrapper<EstatesSortOption>;
    PropertyReference: ResolverTypeWrapper<PropertyReference>;
    PropertyReferenceAssignee: ResolverTypeWrapper<PropertyReferenceAssignee>;
    PropertyReferenceTeam: ResolverTypeWrapper<PropertyReferenceTeam>;
    ContactFiltersInput: ContactFiltersInput;
    ContactConnection: ResolverTypeWrapper<
        Omit<ContactConnection, 'edges' | 'nodes'> & {
            edges: ReadonlyArray<ResolversTypes['ContactEdge']>;
            nodes: ReadonlyArray<ResolversTypes['Contact']>;
        }
    >;
    ContactEdge: ResolverTypeWrapper<Omit<ContactEdge, 'node'> & { node: ResolversTypes['Contact'] }>;
    ContactReference: ResolverTypeWrapper<ContactReference>;
    ContactReferenceAssignee: ResolverTypeWrapper<ContactReferenceAssignee>;
    ContactReferenceTeam: ResolverTypeWrapper<ContactReferenceTeam>;
    ContactsOptions: ResolverTypeWrapper<ContactsOptions>;
    ContactsSortOption: ResolverTypeWrapper<ContactsSortOption>;
    Label: ResolverTypeWrapper<Label>;
    Lead: ResolverTypeWrapper<
        Omit<Lead, 'interactedProperties' | 'eligibleEstates' | 'offers'> & {
            interactedProperties: ResolversTypes['EstatesConnection'];
            eligibleEstates: ResolversTypes['EstatesConnection'];
            offers: ReadonlyArray<ResolversTypes['Offer']>;
        }
    >;
    BuyerPreferencesConnection: ResolverTypeWrapper<BuyerPreferencesConnection>;
    BuyerPreferenceEdge: ResolverTypeWrapper<BuyerPreferenceEdge>;
    AuthCheckInput: AuthCheckInput;
    AuthCheckIputContext: AuthCheckIputContext;
    LabelsPayload: ResolverTypeWrapper<LabelsPayload>;
    LabelsMeta: ResolverTypeWrapper<LabelsMeta>;
    LabelsPagination: ResolverTypeWrapper<LabelsPagination>;
    EstateAggregations: ResolverTypeWrapper<EstateAggregations>;
    PriceAggregation: ResolverTypeWrapper<PriceAggregation>;
    HistogramBin: ResolverTypeWrapper<HistogramBin>;
    PropertyStatus: ResolverTypeWrapper<PropertyStatus>;
    ScheduleCount: ResolverTypeWrapper<ScheduleCount>;
    ScheduleEventType: ScheduleEventType;
    ScheduleEvent:
        | ResolversTypes['ScheduleEventVisit']
        | ResolversTypes['ScheduleEventAuction']
        | ResolversTypes['ScheduleEventOpenHome'];
    ScheduleEventVisit: ResolverTypeWrapper<ScheduleEventVisit>;
    ScheduleEventAttendee: ResolverTypeWrapper<ScheduleEventAttendee>;
    VisitStatus: VisitStatus;
    PropertyOffersFilter: PropertyOffersFilter;
    PropertyStatsFilter: PropertyStatsFilter;
    PropertyStats: ResolverTypeWrapper<PropertyStats>;
    ContactOffersFilter: ContactOffersFilter;
    ContactStatsFilter: ContactStatsFilter;
    ContactStats: ResolverTypeWrapper<ContactStats>;
    GeoTemplate: ResolverTypeWrapper<GeoTemplate>;
    GeoTemplatesPayload: ResolverTypeWrapper<GeoTemplatesPayload>;
    GeoTemplatesPagination: ResolverTypeWrapper<GeoTemplatesPagination>;
    CompanyTeamResponse: ResolverTypeWrapper<CompanyTeamResponse>;
    CompanyTeam: ResolverTypeWrapper<CompanyTeam>;
    CompanyTeamSettings: ResolverTypeWrapper<CompanyTeamSettings>;
    CompanyTeamHeadNegotiator: ResolverTypeWrapper<CompanyTeamHeadNegotiator>;
    CompanyTeamsResponse: ResolverTypeWrapper<CompanyTeamsResponse>;
    CompanyTeamsMeta: ResolverTypeWrapper<CompanyTeamsMeta>;
    CompanyTeamsMetaPagination: ResolverTypeWrapper<CompanyTeamsMetaPagination>;
    MatchingFlagsInput: MatchingFlagsInput;
    MatchingFlagsPayload: ResolverTypeWrapper<MatchingFlagsPayload>;
    MatchingFlagsPayloadData: ResolverTypeWrapper<MatchingFlagsPayloadData>;
    MatchingConfigInput: MatchingConfigInput;
    MatchingConfigPayload: ResolverTypeWrapper<MatchingConfigPayload>;
    MatchingRequirementsInput: MatchingRequirementsInput;
    MatchingRequirementsPayload: ResolverTypeWrapper<MatchingRequirementsPayload>;
    MatchingRequirementsPayloadData: ResolverTypeWrapper<MatchingRequirementsPayloadData>;
    Mutation: ResolverTypeWrapper<{}>;
    ChannelAccountInput: ChannelAccountInput;
    FacebookPublicationSettingsInput: FacebookPublicationSettingsInput;
    TwitterPublicationSettingsInput: TwitterPublicationSettingsInput;
    ImmowebPublicationSettingsInput: ImmowebPublicationSettingsInput;
    PublishPropertyInput: PublishPropertyInput;
    PublishPropertyPayload: ResolverTypeWrapper<
        Omit<PublishPropertyPayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    UnpublishPropertyInput: UnpublishPropertyInput;
    RetryPublicationInput: RetryPublicationInput;
    RetryPublicationPayload: ResolverTypeWrapper<
        Omit<RetryPublicationPayload, 'channelAccount'> & {
            channelAccount: ResolversTypes['EstateChannelAccountConnectionEdge'];
        }
    >;
    RefreshPublicationInput: RefreshPublicationInput;
    RefreshPublicationPayload: ResolverTypeWrapper<RefreshPublicationPayload>;
    GetFrenchPortalsInput: GetFrenchPortalsInput;
    GetFrenchPortalsPayload: ResolverTypeWrapper<GetFrenchPortalsPayload>;
    FrenchPortal: ResolverTypeWrapper<FrenchPortal>;
    EstatePublicationSettingsInput: EstatePublicationSettingsInput;
    EstatePublicationSettingsResponse: ResolverTypeWrapper<
        Omit<EstatePublicationSettingsResponse, 'estateChannelAccounts'> & {
            estateChannelAccounts: ReadonlyArray<ResolversTypes['EstateConfigForChannelAccount']>;
        }
    >;
    AddEstateInput: AddEstateInput;
    AddEstatePayload: ResolverTypeWrapper<Omit<AddEstatePayload, 'estate'> & { estate: ResolversTypes['Estate'] }>;
    UpdateEstatePayload: ResolverTypeWrapper<
        Omit<UpdateEstatePayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    UserError: ResolverTypeWrapper<UserError>;
    DisplayableError: ResolversTypes['UserError'];
    ArchiveEstateInput: ArchiveEstateInput;
    ArchiveEstatePayload: ResolverTypeWrapper<
        Omit<ArchiveEstatePayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    UnarchiveEstateInput: UnarchiveEstateInput;
    UnarchiveEstatePayload: ResolverTypeWrapper<
        Omit<UnarchiveEstatePayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    DuplicateEstateInput: DuplicateEstateInput;
    DuplicateEstatePayload: ResolverTypeWrapper<
        Omit<DuplicateEstatePayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    RegisterEstateActivityInput: RegisterEstateActivityInput;
    RegisterEstateActivityPayload: ResolverTypeWrapper<
        Omit<RegisterEstateActivityPayload, 'subject' | 'contact'> & {
            subject: ResolversTypes['Estate'];
            contact?: Maybe<ResolversTypes['Contact']>;
        }
    >;
    AssignEstatesToNegotiatorInput: AssignEstatesToNegotiatorInput;
    AssignEstatesToNegotiatorPayload: ResolverTypeWrapper<
        Omit<AssignEstatesToNegotiatorPayload, 'negotiator'> & { negotiator?: Maybe<ResolversTypes['User']> }
    >;
    AssignVendorsToEstateInput: AssignVendorsToEstateInput;
    AssignVendorsToEstatePayload: ResolverTypeWrapper<
        Omit<AssignVendorsToEstatePayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    UnassignVendorsToEstateInput: UnassignVendorsToEstateInput;
    UnassignVendorsToEstatePayload: ResolverTypeWrapper<
        Omit<UnassignVendorsToEstatePayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    AssignBuyersToEstateInput: AssignBuyersToEstateInput;
    AssignBuyersToEstatePayload: ResolverTypeWrapper<
        Omit<AssignBuyersToEstatePayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    UnassignBuyersToEstateInput: UnassignBuyersToEstateInput;
    UnassignBuyersToEstatePayload: ResolverTypeWrapper<
        Omit<UnassignBuyersToEstatePayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    AssignLegalEntityToEstateInput: AssignLegalEntityToEstateInput;
    AssignLegalEntityToPayload: ResolverTypeWrapper<
        Omit<AssignLegalEntityToPayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    UnassignLegalEntityToEstateInput: UnassignLegalEntityToEstateInput;
    UnassignLegalEntityToPayload: ResolverTypeWrapper<
        Omit<UnassignLegalEntityToPayload, 'estate'> & { estate?: Maybe<ResolversTypes['Estate']> }
    >;
    AddUnitToProjectInput: AddUnitToProjectInput;
    AddUnitToProjectPayload: ResolverTypeWrapper<
        Omit<AddUnitToProjectPayload, 'project' | 'unit'> & {
            project?: Maybe<ResolversTypes['Estate']>;
            unit?: Maybe<ResolversTypes['Estate']>;
        }
    >;
    GenerateDescriptionInput: GenerateDescriptionInput;
    GenerateDescriptionPayload: ResolverTypeWrapper<GenerateDescriptionPayload>;
    TranslateInput: TranslateInput;
    TranslatePayload: ResolverTypeWrapper<TranslatePayload>;
    UpdatePropertyVisibilityPayload: ResolverTypeWrapper<UpdatePropertyVisibilityPayload>;
    AddLocationPreferenceInput: AddLocationPreferenceInput;
    LocationPreferenceInput: LocationPreferenceInput;
    GeoJSONDataInput: GeoJsonDataInput;
    PostalCodesDataInput: PostalCodesDataInput;
    AddLocationPreferencePayload: ResolverTypeWrapper<AddLocationPreferencePayload>;
    RemoveLocationPayload: ResolverTypeWrapper<Omit<RemoveLocationPayload, 'lead'> & { lead: ResolversTypes['Lead'] }>;
    UpdateLocationPreferencePayload: ResolverTypeWrapper<UpdateLocationPreferencePayload>;
    AddLocationTemplatePayload: ResolverTypeWrapper<AddLocationTemplatePayload>;
    RemoveLocationTemplatePayload: ResolverTypeWrapper<
        Omit<RemoveLocationTemplatePayload, 'office'> & { office: ResolversTypes['Office'] }
    >;
    UpdateLocationTemplatePayload: ResolverTypeWrapper<UpdateLocationTemplatePayload>;
    CloneLocationPreferenceTemplateInput: CloneLocationPreferenceTemplateInput;
    CloneLocationTemplatePayload: ResolverTypeWrapper<CloneLocationTemplatePayload>;
    UpdateUserInput: UpdateUserInput;
    UpdateUserPayload: ResolverTypeWrapper<Omit<UpdateUserPayload, 'user'> & { user?: Maybe<ResolversTypes['User']> }>;
    AddIntegrationInput: AddIntegrationInput;
    AddIntegrationPayload: ResolverTypeWrapper<Omit<AddIntegrationPayload, 'user'> & { user: ResolversTypes['User'] }>;
    RemoveIntegrationInput: RemoveIntegrationInput;
    RemoveIntegrationPayload: ResolverTypeWrapper<
        Omit<RemoveIntegrationPayload, 'user'> & { user: ResolversTypes['User'] }
    >;
    InviteInput: InviteInput;
    InviteItemInput: InviteItemInput;
    UserRole: UserRole;
    AuthenticatePayload: ResolverTypeWrapper<AuthenticatePayload>;
    UpdateLeadPreferenceInput: UpdateLeadPreferenceInput;
    PreferenceBudgetInput: PreferenceBudgetInput;
    UpdateLeadPreferencePayload: ResolverTypeWrapper<UpdateLeadPreferencePayload>;
    AddLeadPreferenceInput: AddLeadPreferenceInput;
    AddLeadPreferencePayload: ResolverTypeWrapper<AddLeadPreferencePayload>;
    RemoveLeadPreferenceInput: RemoveLeadPreferenceInput;
    RemoveLeadPreferencePayload: ResolverTypeWrapper<
        Omit<RemoveLeadPreferencePayload, 'contact'> & { contact: ResolversTypes['Contact'] }
    >;
    AssignLeadsInput: AssignLeadsInput;
    AssignLeadsPayload: ResolverTypeWrapper<
        Omit<AssignLeadsPayload, 'assignedLeads'> & { assignedLeads: ReadonlyArray<ResolversTypes['Contact']> }
    >;
    ArchiveLeadsInput: ArchiveLeadsInput;
    ArchiveLeadsPayload: ResolverTypeWrapper<
        Omit<ArchiveLeadsPayload, 'company'> & { company: ResolversTypes['Company'] }
    >;
    LabelsInput: LabelsInput;
    AddContactMutationInput: AddContactMutationInput;
    AddContactMutationPayload: ResolverTypeWrapper<
        Omit<AddContactMutationPayload, 'contact'> & { contact?: Maybe<ResolversTypes['Contact']> }
    >;
    UpdateContactMutationInput: UpdateContactMutationInput;
    UpdateContactMutationPayload: ResolverTypeWrapper<
        Omit<UpdateContactMutationPayload, 'contact'> & { contact?: Maybe<ResolversTypes['Contact']> }
    >;
    UpdateContactAddressMutationInput: UpdateContactAddressMutationInput;
    UpdateContactAssignmentsInput: UpdateContactAssignmentsInput;
    UpdateContactAssignmentsPayload: ResolverTypeWrapper<UpdateContactAssignmentsPayload>;
    SendVendorReportInput: SendVendorReportInput;
    SendVendorReportPayload: ResolverTypeWrapper<
        Omit<SendVendorReportPayload, 'vendor'> & { vendor?: Maybe<ResolversTypes['Vendor']> }
    >;
    Vendor: ResolverTypeWrapper<
        Omit<Vendor, 'estates' | 'interactedProperties' | 'eligibleEstates' | 'offers'> & {
            estates: ResolversTypes['VendorEstatesConnection'];
            interactedProperties: ResolversTypes['EstatesConnection'];
            eligibleEstates: ResolversTypes['EstatesConnection'];
            offers: ReadonlyArray<ResolversTypes['Offer']>;
        }
    >;
    VendorEstatesConnection: ResolverTypeWrapper<
        Omit<VendorEstatesConnection, 'edges' | 'nodes'> & {
            edges: ReadonlyArray<ResolversTypes['VendorEstatesConnectionEdge']>;
            nodes: ReadonlyArray<ResolversTypes['Estate']>;
        }
    >;
    VendorEstatesConnectionEdge: ResolverTypeWrapper<
        Omit<VendorEstatesConnectionEdge, 'node'> & { node: ResolversTypes['Estate'] }
    >;
    ScheduleVisitInput: ScheduleVisitInput;
    ScheduleVisitPayload: ResolverTypeWrapper<
        Omit<ScheduleVisitPayload, 'contact' | 'estate'> & {
            contact?: Maybe<ResolversTypes['Contact']>;
            estate?: Maybe<ResolversTypes['Estate']>;
        }
    >;
    UpdateVisitStatusInput: UpdateVisitStatusInput;
    UpdateVisitStatusPayload: ResolverTypeWrapper<UpdateVisitStatusPayload>;
    CreateOfferInput: CreateOfferInput;
    FinancialDetailsInput: FinancialDetailsInput;
    UpdateOfferInput: UpdateOfferInput;
    LegalEntitiesInput: LegalEntitiesInput;
    LegalEntitiesDeletePayload: ResolverTypeWrapper<LegalEntitiesDeletePayload>;
    GeoTemplatesInput: GeoTemplatesInput;
    GeoTemplatesDeletePayload: ResolverTypeWrapper<GeoTemplatesDeletePayload>;
    MatchingEmailsInput: MatchingEmailsInput;
    MatchingEmailsPayload: ResolverTypeWrapper<MatchingEmailsPayload>;
    VendorReportEmailedActivity: ResolverTypeWrapper<
        Omit<VendorReportEmailedActivity, 'sentBy' | 'sentTo' | 'about'> & {
            sentBy: ResolversTypes['User'];
            sentTo: ResolversTypes['Vendor'];
            about?: Maybe<ResolversTypes['Estate']>;
        }
    >;
    ContactEmailedActivity: ResolverTypeWrapper<
        Omit<ContactEmailedActivity, 'emailedContact' | 'emailedBy' | 'about'> & {
            emailedContact?: Maybe<ResolversTypes['Contact']>;
            emailedBy: ResolversTypes['User'];
            about?: Maybe<ResolversTypes['Estate']>;
        }
    >;
    VisitScheduledActivity: ResolverTypeWrapper<
        Omit<VisitScheduledActivity, 'visit' | 'attendee'> & {
            visit: ResolversTypes['Visit'];
            attendee?: Maybe<ResolversTypes['Contact']>;
        }
    >;
    Visit: ResolverTypeWrapper<
        Omit<Visit, 'property' | 'organizer' | 'attendees'> & {
            property?: Maybe<ResolversTypes['Estate']>;
            organizer?: Maybe<ResolversTypes['User']>;
            attendees?: Maybe<ReadonlyArray<Maybe<ResolversTypes['Contact']>>>;
        }
    >;
    PropertyVisitedActivity: ResolverTypeWrapper<
        Omit<PropertyVisitedActivity, 'visitedBy' | 'property' | 'registeredBy'> & {
            visitedBy?: Maybe<ReadonlyArray<Maybe<ResolversTypes['Contact']>>>;
            property?: Maybe<ResolversTypes['Estate']>;
            registeredBy: ResolversTypes['User'];
        }
    >;
    ContactCalledActivity: ResolverTypeWrapper<
        Omit<ContactCalledActivity, 'calledContact' | 'calledBy' | 'about'> & {
            calledContact?: Maybe<ResolversTypes['Contact']>;
            calledBy: ResolversTypes['User'];
            about?: Maybe<ResolversTypes['Estate']>;
        }
    >;
    ContactMessagedActivity: ResolverTypeWrapper<
        Omit<ContactMessagedActivity, 'messagedContact' | 'messagedBy' | 'about'> & {
            messagedContact?: Maybe<ResolversTypes['Contact']>;
            messagedBy: ResolversTypes['User'];
            about?: Maybe<ResolversTypes['Estate']>;
        }
    >;
    LeadExpressedInterestActivity: ResolverTypeWrapper<
        Omit<LeadExpressedInterestActivity, 'interestedLead' | 'propertyInterestedIn'> & {
            interestedLead?: Maybe<ResolversTypes['Contact']>;
            propertyInterestedIn?: Maybe<ResolversTypes['Estate']>;
        }
    >;
    LeadSource: ResolverTypeWrapper<LeadSource>;
    MessageActivityDetails: ResolverTypeWrapper<MessageActivityDetails>;
    ScheduleEventAuction: ResolverTypeWrapper<ScheduleEventAuction>;
    ScheduleEventOpenHome: ResolverTypeWrapper<ScheduleEventOpenHome>;
    FeedbackActivityDetails: ResolverTypeWrapper<FeedbackActivityDetails>;
    PostalCodesLocationPreference: ResolverTypeWrapper<PostalCodesLocationPreference>;
    PostalCodesData: ResolverTypeWrapper<PostalCodesData>;
    GeoJSONLocationPreference: ResolverTypeWrapper<GeoJsonLocationPreference>;
    GeoJSONData: ResolverTypeWrapper<GeoJsonData>;
    BuyerPreferenceForHouse: ResolverTypeWrapper<BuyerPreferenceForHouse>;
    BuyerPreferenceForApartment: ResolverTypeWrapper<BuyerPreferenceForApartment>;
    BuyerPreferenceForLand: ResolverTypeWrapper<BuyerPreferenceForLand>;
    BuyerPreferenceForOffice: ResolverTypeWrapper<BuyerPreferenceForOffice>;
    BuyerPreferenceForCommercial: ResolverTypeWrapper<BuyerPreferenceForCommercial>;
    BuyerPreferenceForParking: ResolverTypeWrapper<BuyerPreferenceForParking>;
    GenericAccountPublicationSettings: ResolverTypeWrapper<GenericAccountPublicationSettings>;
    FacebookPublicationSettings: ResolverTypeWrapper<FacebookPublicationSettings>;
    TwitterPublicationSettings: ResolverTypeWrapper<TwitterPublicationSettings>;
    ImmowebPublicationSettings: ResolverTypeWrapper<ImmowebPublicationSettings>;
    EstateAgentFeedsPublicationSettings: ResolverTypeWrapper<EstateAgentFeedsPublicationSettings>;
    FrenchPortalPublicationSettings: ResolverTypeWrapper<FrenchPortalPublicationSettings>;
    GenericPublicationConfig: ResolverTypeWrapper<GenericPublicationConfig>;
    ImmowebPublicationConfig: ResolverTypeWrapper<ImmowebPublicationConfig>;
    FacebookPublicationConfig: ResolverTypeWrapper<FacebookPublicationConfig>;
    EstateAgentFeedsPublicationConfig: ResolverTypeWrapper<EstateAgentFeedsPublicationConfig>;
    TwitterPublicationConfig: ResolverTypeWrapper<TwitterPublicationConfig>;
    FrenchPortalPublicationConfig: ResolverTypeWrapper<FrenchPortalPublicationConfig>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
    Query: {};
    String: Scalars['String'];
    ChannelAccount: ChannelAccount;
    ID: Scalars['ID'];
    ChannelAccountCredentials: ChannelAccountCredentials;
    Channel: Channel;
    Boolean: Scalars['Boolean'];
    ChannelSettings: ChannelSettings;
    EstateConfigForChannelAccount: Omit<EstateConfigForChannelAccount, 'lastPublication'> & {
        lastPublication?: Maybe<ResolversParentTypes['EstatePublication']>;
    };
    OldUnitsMissingFields: OldUnitsMissingFields;
    AccountPublicationSettings:
        | ResolversParentTypes['GenericAccountPublicationSettings']
        | ResolversParentTypes['FacebookPublicationSettings']
        | ResolversParentTypes['TwitterPublicationSettings']
        | ResolversParentTypes['ImmowebPublicationSettings']
        | ResolversParentTypes['EstateAgentFeedsPublicationSettings']
        | ResolversParentTypes['FrenchPortalPublicationSettings'];
    EstatePublication: Omit<EstatePublication, 'publishedBy'> & { publishedBy?: Maybe<ResolversParentTypes['User']> };
    DateTime: Scalars['DateTime'];
    User: Omit<User, 'office' | 'company'> & {
        office: ResolversParentTypes['Office'];
        company?: Maybe<ResolversParentTypes['Company']>;
    };
    Office: Omit<Office, 'negotiators' | 'negotiatiorsByIds' | 'estates' | 'members' | 'companyMembers'> & {
        negotiators: ReadonlyArray<ResolversParentTypes['User']>;
        negotiatiorsByIds: ReadonlyArray<ResolversParentTypes['User']>;
        estates?: Maybe<ResolversParentTypes['EstatesConnection']>;
        members?: Maybe<ResolversParentTypes['TeamMembersConnection']>;
        companyMembers?: Maybe<ResolversParentTypes['TeamMembersConnection']>;
    };
    OfficeSettings: OfficeSettings;
    LegalEntity: LegalEntity;
    LegalEntities: LegalEntities;
    Int: Scalars['Int'];
    LegalEntitiesConnection: LegalEntitiesConnection;
    PageInfo: PageInfo;
    LegalEntitiesEdge: LegalEntitiesEdge;
    LocationPreferenceConnection: LocationPreferenceConnection;
    LocationPreferenceEdge: LocationPreferenceEdge;
    LocationPreference:
        | ResolversParentTypes['PostalCodesLocationPreference']
        | ResolversParentTypes['GeoJSONLocationPreference'];
    EstatesConnection: Omit<EstatesConnection, 'nodes' | 'edges'> & {
        nodes: ReadonlyArray<ResolversParentTypes['Estate']>;
        edges: ReadonlyArray<ResolversParentTypes['EstateConnectionEdge']>;
    };
    Estate: Omit<
        Estate,
        | 'publications'
        | 'channelAccount'
        | 'interactedContacts'
        | 'visitors'
        | 'buyers'
        | 'vendors'
        | 'negotiator'
        | 'units'
        | 'unit'
        | 'matches'
        | 'offers'
    > & {
        publications: ResolversParentTypes['EstatePublications'];
        channelAccount?: Maybe<ResolversParentTypes['EstateChannelAccountConnectionEdge']>;
        interactedContacts: ResolversParentTypes['EstateContactConnection'];
        visitors: ResolversParentTypes['EstateContactConnection'];
        buyers: ResolversParentTypes['EstateContactConnection'];
        vendors: ResolversParentTypes['EstateContactConnection'];
        negotiator?: Maybe<ResolversParentTypes['User']>;
        units?: Maybe<ResolversParentTypes['UnitsConnection']>;
        unit?: Maybe<ResolversParentTypes['Estate']>;
        matches?: Maybe<ResolversParentTypes['EstateMatchesConnection']>;
        offers: ReadonlyArray<ResolversParentTypes['Offer']>;
    };
    EstatePublications: Omit<EstatePublications, 'channelAccounts'> & {
        channelAccounts: ResolversParentTypes['EstateChannelAccountConnection'];
    };
    EstateChannelAccountConnection: Omit<EstateChannelAccountConnection, 'edges'> & {
        edges: ReadonlyArray<ResolversParentTypes['EstateChannelAccountConnectionEdge']>;
    };
    EstateChannelAccountConnectionEdge: Omit<EstateChannelAccountConnectionEdge, 'lastPublication'> & {
        lastPublication?: Maybe<ResolversParentTypes['EstatePublication']>;
    };
    MissingFieldsEdge: MissingFieldsEdge;
    MissingField: MissingField;
    MissingFieldsConnection: MissingFieldsConnection;
    PropertyTimeline: PropertyTimeline;
    ActivityConnection: ActivityConnection;
    ActivityEdge: ActivityEdge;
    Activity:
        | ResolversParentTypes['VendorReportEmailedActivity']
        | ResolversParentTypes['ContactEmailedActivity']
        | ResolversParentTypes['VisitScheduledActivity']
        | ResolversParentTypes['PropertyVisitedActivity']
        | ResolversParentTypes['ContactCalledActivity']
        | ResolversParentTypes['ContactMessagedActivity']
        | ResolversParentTypes['LeadExpressedInterestActivity'];
    ActivityDetails: ResolversParentTypes['MessageActivityDetails'] | ResolversParentTypes['FeedbackActivityDetails'];
    JSON: Scalars['JSON'];
    EstateContactConnection: Omit<EstateContactConnection, 'edges' | 'nodes'> & {
        edges: ReadonlyArray<ResolversParentTypes['EstateContactEdge']>;
        nodes: ReadonlyArray<ResolversParentTypes['Contact']>;
    };
    EstateContactEdge: Omit<EstateContactEdge, 'node'> & { node: ResolversParentTypes['Contact'] };
    Contact: ResolversParentTypes['Lead'] | ResolversParentTypes['Vendor'];
    Note: Note;
    ContactPhoto: ContactPhoto;
    ContactTimeline: ContactTimeline;
    ContactAssignedNegotiator: ContactAssignedNegotiator;
    Offer: PartialOffer;
    FinancialDetails: FinancialDetails;
    Currency: Scalars['Currency'];
    Float: Scalars['Float'];
    OfferOwner: OfferOwner;
    OfferBuyer: OfferBuyer;
    OffersStats: OffersStats;
    UnitsConnection: Omit<UnitsConnection, 'edges' | 'nodes'> & {
        edges: ReadonlyArray<ResolversParentTypes['UnitEdge']>;
        nodes: ReadonlyArray<ResolversParentTypes['Estate']>;
    };
    UnitEdge: Omit<UnitEdge, 'node'> & { node: ResolversParentTypes['Estate'] };
    PendingRequestsConnection: PendingRequestsConnection;
    PendingRequestEdge: PendingRequestEdge;
    PendingRequest: PendingRequest;
    EstateMatchesFilters: EstateMatchesFilters;
    EstateMatchesConnection: Omit<EstateMatchesConnection, 'edges' | 'nodes'> & {
        edges: ReadonlyArray<ResolversParentTypes['EstateMatchesConnectionEdge']>;
        nodes: ReadonlyArray<ResolversParentTypes['Contact']>;
    };
    EstateMatchesConnectionEdge: Omit<EstateMatchesConnectionEdge, 'node'> & { node: ResolversParentTypes['Contact'] };
    EstateConnectionEdge: Omit<EstateConnectionEdge, 'node'> & { node: ResolversParentTypes['Estate'] };
    TeamMembersConnection: Omit<TeamMembersConnection, 'nodes' | 'edges'> & {
        nodes: ReadonlyArray<ResolversParentTypes['User']>;
        edges: ReadonlyArray<ResolversParentTypes['TeamMembersConnectionEdge']>;
    };
    TeamMembersConnectionEdge: Omit<TeamMembersConnectionEdge, 'node'> & { node: ResolversParentTypes['User'] };
    Company: Omit<Company, 'lead' | 'offices' | 'admins'> & {
        lead?: Maybe<ResolversParentTypes['Contact']>;
        offices?: Maybe<ReadonlyArray<ResolversParentTypes['Office']>>;
        admins: ResolversParentTypes['CompanyAdminsConnection'];
    };
    CompanyLeadsConnection: CompanyLeadsConnection;
    CompanyLeadConnectionEdge: CompanyLeadConnectionEdge;
    BuyerLead: BuyerLead;
    ContactRequestConnection: ContactRequestConnection;
    ContactRequestConnectionEdge: ContactRequestConnectionEdge;
    ContactRequest: ContactRequest;
    BuyerPreference:
        | ResolversParentTypes['BuyerPreferenceForHouse']
        | ResolversParentTypes['BuyerPreferenceForApartment']
        | ResolversParentTypes['BuyerPreferenceForLand']
        | ResolversParentTypes['BuyerPreferenceForOffice']
        | ResolversParentTypes['BuyerPreferenceForCommercial']
        | ResolversParentTypes['BuyerPreferenceForParking'];
    PreferenceBudget: PreferenceBudget;
    CompanyAdminsConnection: Omit<CompanyAdminsConnection, 'edges' | 'nodes'> & {
        edges: ReadonlyArray<ResolversParentTypes['CompanyAdminConnectionEdge']>;
        nodes: ReadonlyArray<ResolversParentTypes['User']>;
    };
    CompanyAdminConnectionEdge: Omit<CompanyAdminConnectionEdge, 'node'> & { node: ResolversParentTypes['User'] };
    LocalizedText: LocalizedText;
    EstatePublicationConfig:
        | ResolversParentTypes['GenericPublicationConfig']
        | ResolversParentTypes['ImmowebPublicationConfig']
        | ResolversParentTypes['FacebookPublicationConfig']
        | ResolversParentTypes['EstateAgentFeedsPublicationConfig']
        | ResolversParentTypes['TwitterPublicationConfig']
        | ResolversParentTypes['FrenchPortalPublicationConfig'];
    EstatePublicationErrors: EstatePublicationErrors;
    EstatePublicationError: EstatePublicationError;
    PublicationErrorContext: PublicationErrorContext;
    UnitPublicationErrors: UnitPublicationErrors;
    EstatePublicationWarnings: EstatePublicationWarnings;
    EstatePublicationWarning: EstatePublicationWarning;
    PublicationWarningContext: PublicationWarningContext;
    UnitPublicationWarnings: UnitPublicationWarnings;
    EstateFilters: EstateFilters;
    EstatesOptions: EstatesOptions;
    EstatesSortOption: EstatesSortOption;
    PropertyReference: PropertyReference;
    PropertyReferenceAssignee: PropertyReferenceAssignee;
    PropertyReferenceTeam: PropertyReferenceTeam;
    ContactFiltersInput: ContactFiltersInput;
    ContactConnection: Omit<ContactConnection, 'edges' | 'nodes'> & {
        edges: ReadonlyArray<ResolversParentTypes['ContactEdge']>;
        nodes: ReadonlyArray<ResolversParentTypes['Contact']>;
    };
    ContactEdge: Omit<ContactEdge, 'node'> & { node: ResolversParentTypes['Contact'] };
    ContactReference: ContactReference;
    ContactReferenceAssignee: ContactReferenceAssignee;
    ContactReferenceTeam: ContactReferenceTeam;
    ContactsOptions: ContactsOptions;
    ContactsSortOption: ContactsSortOption;
    Label: Label;
    Lead: Omit<Lead, 'interactedProperties' | 'eligibleEstates' | 'offers'> & {
        interactedProperties: ResolversParentTypes['EstatesConnection'];
        eligibleEstates: ResolversParentTypes['EstatesConnection'];
        offers: ReadonlyArray<ResolversParentTypes['Offer']>;
    };
    BuyerPreferencesConnection: BuyerPreferencesConnection;
    BuyerPreferenceEdge: BuyerPreferenceEdge;
    AuthCheckInput: AuthCheckInput;
    AuthCheckIputContext: AuthCheckIputContext;
    LabelsPayload: LabelsPayload;
    LabelsMeta: LabelsMeta;
    LabelsPagination: LabelsPagination;
    EstateAggregations: EstateAggregations;
    PriceAggregation: PriceAggregation;
    HistogramBin: HistogramBin;
    PropertyStatus: PropertyStatus;
    ScheduleCount: ScheduleCount;
    ScheduleEvent:
        | ResolversParentTypes['ScheduleEventVisit']
        | ResolversParentTypes['ScheduleEventAuction']
        | ResolversParentTypes['ScheduleEventOpenHome'];
    ScheduleEventVisit: ScheduleEventVisit;
    ScheduleEventAttendee: ScheduleEventAttendee;
    PropertyOffersFilter: PropertyOffersFilter;
    PropertyStatsFilter: PropertyStatsFilter;
    PropertyStats: PropertyStats;
    ContactOffersFilter: ContactOffersFilter;
    ContactStatsFilter: ContactStatsFilter;
    ContactStats: ContactStats;
    GeoTemplate: GeoTemplate;
    GeoTemplatesPayload: GeoTemplatesPayload;
    GeoTemplatesPagination: GeoTemplatesPagination;
    CompanyTeamResponse: CompanyTeamResponse;
    CompanyTeam: CompanyTeam;
    CompanyTeamSettings: CompanyTeamSettings;
    CompanyTeamHeadNegotiator: CompanyTeamHeadNegotiator;
    CompanyTeamsResponse: CompanyTeamsResponse;
    CompanyTeamsMeta: CompanyTeamsMeta;
    CompanyTeamsMetaPagination: CompanyTeamsMetaPagination;
    MatchingFlagsInput: MatchingFlagsInput;
    MatchingFlagsPayload: MatchingFlagsPayload;
    MatchingFlagsPayloadData: MatchingFlagsPayloadData;
    MatchingConfigInput: MatchingConfigInput;
    MatchingConfigPayload: MatchingConfigPayload;
    MatchingRequirementsInput: MatchingRequirementsInput;
    MatchingRequirementsPayload: MatchingRequirementsPayload;
    MatchingRequirementsPayloadData: MatchingRequirementsPayloadData;
    Mutation: {};
    ChannelAccountInput: ChannelAccountInput;
    FacebookPublicationSettingsInput: FacebookPublicationSettingsInput;
    TwitterPublicationSettingsInput: TwitterPublicationSettingsInput;
    ImmowebPublicationSettingsInput: ImmowebPublicationSettingsInput;
    PublishPropertyInput: PublishPropertyInput;
    PublishPropertyPayload: Omit<PublishPropertyPayload, 'estate'> & { estate?: Maybe<ResolversParentTypes['Estate']> };
    UnpublishPropertyInput: UnpublishPropertyInput;
    RetryPublicationInput: RetryPublicationInput;
    RetryPublicationPayload: Omit<RetryPublicationPayload, 'channelAccount'> & {
        channelAccount: ResolversParentTypes['EstateChannelAccountConnectionEdge'];
    };
    RefreshPublicationInput: RefreshPublicationInput;
    RefreshPublicationPayload: RefreshPublicationPayload;
    GetFrenchPortalsInput: GetFrenchPortalsInput;
    GetFrenchPortalsPayload: GetFrenchPortalsPayload;
    FrenchPortal: FrenchPortal;
    EstatePublicationSettingsInput: EstatePublicationSettingsInput;
    EstatePublicationSettingsResponse: Omit<EstatePublicationSettingsResponse, 'estateChannelAccounts'> & {
        estateChannelAccounts: ReadonlyArray<ResolversParentTypes['EstateConfigForChannelAccount']>;
    };
    AddEstateInput: AddEstateInput;
    AddEstatePayload: Omit<AddEstatePayload, 'estate'> & { estate: ResolversParentTypes['Estate'] };
    UpdateEstatePayload: Omit<UpdateEstatePayload, 'estate'> & { estate?: Maybe<ResolversParentTypes['Estate']> };
    UserError: UserError;
    DisplayableError: ResolversParentTypes['UserError'];
    ArchiveEstateInput: ArchiveEstateInput;
    ArchiveEstatePayload: Omit<ArchiveEstatePayload, 'estate'> & { estate?: Maybe<ResolversParentTypes['Estate']> };
    UnarchiveEstateInput: UnarchiveEstateInput;
    UnarchiveEstatePayload: Omit<UnarchiveEstatePayload, 'estate'> & { estate?: Maybe<ResolversParentTypes['Estate']> };
    DuplicateEstateInput: DuplicateEstateInput;
    DuplicateEstatePayload: Omit<DuplicateEstatePayload, 'estate'> & { estate?: Maybe<ResolversParentTypes['Estate']> };
    RegisterEstateActivityInput: RegisterEstateActivityInput;
    RegisterEstateActivityPayload: Omit<RegisterEstateActivityPayload, 'subject' | 'contact'> & {
        subject: ResolversParentTypes['Estate'];
        contact?: Maybe<ResolversParentTypes['Contact']>;
    };
    AssignEstatesToNegotiatorInput: AssignEstatesToNegotiatorInput;
    AssignEstatesToNegotiatorPayload: Omit<AssignEstatesToNegotiatorPayload, 'negotiator'> & {
        negotiator?: Maybe<ResolversParentTypes['User']>;
    };
    AssignVendorsToEstateInput: AssignVendorsToEstateInput;
    AssignVendorsToEstatePayload: Omit<AssignVendorsToEstatePayload, 'estate'> & {
        estate?: Maybe<ResolversParentTypes['Estate']>;
    };
    UnassignVendorsToEstateInput: UnassignVendorsToEstateInput;
    UnassignVendorsToEstatePayload: Omit<UnassignVendorsToEstatePayload, 'estate'> & {
        estate?: Maybe<ResolversParentTypes['Estate']>;
    };
    AssignBuyersToEstateInput: AssignBuyersToEstateInput;
    AssignBuyersToEstatePayload: Omit<AssignBuyersToEstatePayload, 'estate'> & {
        estate?: Maybe<ResolversParentTypes['Estate']>;
    };
    UnassignBuyersToEstateInput: UnassignBuyersToEstateInput;
    UnassignBuyersToEstatePayload: Omit<UnassignBuyersToEstatePayload, 'estate'> & {
        estate?: Maybe<ResolversParentTypes['Estate']>;
    };
    AssignLegalEntityToEstateInput: AssignLegalEntityToEstateInput;
    AssignLegalEntityToPayload: Omit<AssignLegalEntityToPayload, 'estate'> & {
        estate?: Maybe<ResolversParentTypes['Estate']>;
    };
    UnassignLegalEntityToEstateInput: UnassignLegalEntityToEstateInput;
    UnassignLegalEntityToPayload: Omit<UnassignLegalEntityToPayload, 'estate'> & {
        estate?: Maybe<ResolversParentTypes['Estate']>;
    };
    AddUnitToProjectInput: AddUnitToProjectInput;
    AddUnitToProjectPayload: Omit<AddUnitToProjectPayload, 'project' | 'unit'> & {
        project?: Maybe<ResolversParentTypes['Estate']>;
        unit?: Maybe<ResolversParentTypes['Estate']>;
    };
    GenerateDescriptionInput: GenerateDescriptionInput;
    GenerateDescriptionPayload: GenerateDescriptionPayload;
    TranslateInput: TranslateInput;
    TranslatePayload: TranslatePayload;
    UpdatePropertyVisibilityPayload: UpdatePropertyVisibilityPayload;
    AddLocationPreferenceInput: AddLocationPreferenceInput;
    LocationPreferenceInput: LocationPreferenceInput;
    GeoJSONDataInput: GeoJsonDataInput;
    PostalCodesDataInput: PostalCodesDataInput;
    AddLocationPreferencePayload: AddLocationPreferencePayload;
    RemoveLocationPayload: Omit<RemoveLocationPayload, 'lead'> & { lead: ResolversParentTypes['Lead'] };
    UpdateLocationPreferencePayload: UpdateLocationPreferencePayload;
    AddLocationTemplatePayload: AddLocationTemplatePayload;
    RemoveLocationTemplatePayload: Omit<RemoveLocationTemplatePayload, 'office'> & {
        office: ResolversParentTypes['Office'];
    };
    UpdateLocationTemplatePayload: UpdateLocationTemplatePayload;
    CloneLocationPreferenceTemplateInput: CloneLocationPreferenceTemplateInput;
    CloneLocationTemplatePayload: CloneLocationTemplatePayload;
    UpdateUserInput: UpdateUserInput;
    UpdateUserPayload: Omit<UpdateUserPayload, 'user'> & { user?: Maybe<ResolversParentTypes['User']> };
    AddIntegrationInput: AddIntegrationInput;
    AddIntegrationPayload: Omit<AddIntegrationPayload, 'user'> & { user: ResolversParentTypes['User'] };
    RemoveIntegrationInput: RemoveIntegrationInput;
    RemoveIntegrationPayload: Omit<RemoveIntegrationPayload, 'user'> & { user: ResolversParentTypes['User'] };
    InviteInput: InviteInput;
    InviteItemInput: InviteItemInput;
    AuthenticatePayload: AuthenticatePayload;
    UpdateLeadPreferenceInput: UpdateLeadPreferenceInput;
    PreferenceBudgetInput: PreferenceBudgetInput;
    UpdateLeadPreferencePayload: UpdateLeadPreferencePayload;
    AddLeadPreferenceInput: AddLeadPreferenceInput;
    AddLeadPreferencePayload: AddLeadPreferencePayload;
    RemoveLeadPreferenceInput: RemoveLeadPreferenceInput;
    RemoveLeadPreferencePayload: Omit<RemoveLeadPreferencePayload, 'contact'> & {
        contact: ResolversParentTypes['Contact'];
    };
    AssignLeadsInput: AssignLeadsInput;
    AssignLeadsPayload: Omit<AssignLeadsPayload, 'assignedLeads'> & {
        assignedLeads: ReadonlyArray<ResolversParentTypes['Contact']>;
    };
    ArchiveLeadsInput: ArchiveLeadsInput;
    ArchiveLeadsPayload: Omit<ArchiveLeadsPayload, 'company'> & { company: ResolversParentTypes['Company'] };
    LabelsInput: LabelsInput;
    AddContactMutationInput: AddContactMutationInput;
    AddContactMutationPayload: Omit<AddContactMutationPayload, 'contact'> & {
        contact?: Maybe<ResolversParentTypes['Contact']>;
    };
    UpdateContactMutationInput: UpdateContactMutationInput;
    UpdateContactMutationPayload: Omit<UpdateContactMutationPayload, 'contact'> & {
        contact?: Maybe<ResolversParentTypes['Contact']>;
    };
    UpdateContactAddressMutationInput: UpdateContactAddressMutationInput;
    UpdateContactAssignmentsInput: UpdateContactAssignmentsInput;
    UpdateContactAssignmentsPayload: UpdateContactAssignmentsPayload;
    SendVendorReportInput: SendVendorReportInput;
    SendVendorReportPayload: Omit<SendVendorReportPayload, 'vendor'> & {
        vendor?: Maybe<ResolversParentTypes['Vendor']>;
    };
    Vendor: Omit<Vendor, 'estates' | 'interactedProperties' | 'eligibleEstates' | 'offers'> & {
        estates: ResolversParentTypes['VendorEstatesConnection'];
        interactedProperties: ResolversParentTypes['EstatesConnection'];
        eligibleEstates: ResolversParentTypes['EstatesConnection'];
        offers: ReadonlyArray<ResolversParentTypes['Offer']>;
    };
    VendorEstatesConnection: Omit<VendorEstatesConnection, 'edges' | 'nodes'> & {
        edges: ReadonlyArray<ResolversParentTypes['VendorEstatesConnectionEdge']>;
        nodes: ReadonlyArray<ResolversParentTypes['Estate']>;
    };
    VendorEstatesConnectionEdge: Omit<VendorEstatesConnectionEdge, 'node'> & { node: ResolversParentTypes['Estate'] };
    ScheduleVisitInput: ScheduleVisitInput;
    ScheduleVisitPayload: Omit<ScheduleVisitPayload, 'contact' | 'estate'> & {
        contact?: Maybe<ResolversParentTypes['Contact']>;
        estate?: Maybe<ResolversParentTypes['Estate']>;
    };
    UpdateVisitStatusInput: UpdateVisitStatusInput;
    UpdateVisitStatusPayload: UpdateVisitStatusPayload;
    CreateOfferInput: CreateOfferInput;
    FinancialDetailsInput: FinancialDetailsInput;
    UpdateOfferInput: UpdateOfferInput;
    LegalEntitiesInput: LegalEntitiesInput;
    LegalEntitiesDeletePayload: LegalEntitiesDeletePayload;
    GeoTemplatesInput: GeoTemplatesInput;
    GeoTemplatesDeletePayload: GeoTemplatesDeletePayload;
    MatchingEmailsInput: MatchingEmailsInput;
    MatchingEmailsPayload: MatchingEmailsPayload;
    VendorReportEmailedActivity: Omit<VendorReportEmailedActivity, 'sentBy' | 'sentTo' | 'about'> & {
        sentBy: ResolversParentTypes['User'];
        sentTo: ResolversParentTypes['Vendor'];
        about?: Maybe<ResolversParentTypes['Estate']>;
    };
    ContactEmailedActivity: Omit<ContactEmailedActivity, 'emailedContact' | 'emailedBy' | 'about'> & {
        emailedContact?: Maybe<ResolversParentTypes['Contact']>;
        emailedBy: ResolversParentTypes['User'];
        about?: Maybe<ResolversParentTypes['Estate']>;
    };
    VisitScheduledActivity: Omit<VisitScheduledActivity, 'visit' | 'attendee'> & {
        visit: ResolversParentTypes['Visit'];
        attendee?: Maybe<ResolversParentTypes['Contact']>;
    };
    Visit: Omit<Visit, 'property' | 'organizer' | 'attendees'> & {
        property?: Maybe<ResolversParentTypes['Estate']>;
        organizer?: Maybe<ResolversParentTypes['User']>;
        attendees?: Maybe<ReadonlyArray<Maybe<ResolversParentTypes['Contact']>>>;
    };
    PropertyVisitedActivity: Omit<PropertyVisitedActivity, 'visitedBy' | 'property' | 'registeredBy'> & {
        visitedBy?: Maybe<ReadonlyArray<Maybe<ResolversParentTypes['Contact']>>>;
        property?: Maybe<ResolversParentTypes['Estate']>;
        registeredBy: ResolversParentTypes['User'];
    };
    ContactCalledActivity: Omit<ContactCalledActivity, 'calledContact' | 'calledBy' | 'about'> & {
        calledContact?: Maybe<ResolversParentTypes['Contact']>;
        calledBy: ResolversParentTypes['User'];
        about?: Maybe<ResolversParentTypes['Estate']>;
    };
    ContactMessagedActivity: Omit<ContactMessagedActivity, 'messagedContact' | 'messagedBy' | 'about'> & {
        messagedContact?: Maybe<ResolversParentTypes['Contact']>;
        messagedBy: ResolversParentTypes['User'];
        about?: Maybe<ResolversParentTypes['Estate']>;
    };
    LeadExpressedInterestActivity: Omit<LeadExpressedInterestActivity, 'interestedLead' | 'propertyInterestedIn'> & {
        interestedLead?: Maybe<ResolversParentTypes['Contact']>;
        propertyInterestedIn?: Maybe<ResolversParentTypes['Estate']>;
    };
    LeadSource: LeadSource;
    MessageActivityDetails: MessageActivityDetails;
    ScheduleEventAuction: ScheduleEventAuction;
    ScheduleEventOpenHome: ScheduleEventOpenHome;
    FeedbackActivityDetails: FeedbackActivityDetails;
    PostalCodesLocationPreference: PostalCodesLocationPreference;
    PostalCodesData: PostalCodesData;
    GeoJSONLocationPreference: GeoJsonLocationPreference;
    GeoJSONData: GeoJsonData;
    BuyerPreferenceForHouse: BuyerPreferenceForHouse;
    BuyerPreferenceForApartment: BuyerPreferenceForApartment;
    BuyerPreferenceForLand: BuyerPreferenceForLand;
    BuyerPreferenceForOffice: BuyerPreferenceForOffice;
    BuyerPreferenceForCommercial: BuyerPreferenceForCommercial;
    BuyerPreferenceForParking: BuyerPreferenceForParking;
    GenericAccountPublicationSettings: GenericAccountPublicationSettings;
    FacebookPublicationSettings: FacebookPublicationSettings;
    TwitterPublicationSettings: TwitterPublicationSettings;
    ImmowebPublicationSettings: ImmowebPublicationSettings;
    EstateAgentFeedsPublicationSettings: EstateAgentFeedsPublicationSettings;
    FrenchPortalPublicationSettings: FrenchPortalPublicationSettings;
    GenericPublicationConfig: GenericPublicationConfig;
    ImmowebPublicationConfig: ImmowebPublicationConfig;
    FacebookPublicationConfig: FacebookPublicationConfig;
    EstateAgentFeedsPublicationConfig: EstateAgentFeedsPublicationConfig;
    TwitterPublicationConfig: TwitterPublicationConfig;
    FrenchPortalPublicationConfig: FrenchPortalPublicationConfig;
};

export type AccountPublicationSettingsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AccountPublicationSettings'] = ResolversParentTypes['AccountPublicationSettings']
> = {
    __resolveType: TypeResolveFn<
        | 'GenericAccountPublicationSettings'
        | 'FacebookPublicationSettings'
        | 'TwitterPublicationSettings'
        | 'ImmowebPublicationSettings'
        | 'EstateAgentFeedsPublicationSettings'
        | 'FrenchPortalPublicationSettings',
        ParentType,
        ContextType
    >;
    publishPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    publishLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
};

export type ActivityResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Activity'] = ResolversParentTypes['Activity']
> = {
    __resolveType: TypeResolveFn<
        | 'VendorReportEmailedActivity'
        | 'ContactEmailedActivity'
        | 'VisitScheduledActivity'
        | 'PropertyVisitedActivity'
        | 'ContactCalledActivity'
        | 'ContactMessagedActivity'
        | 'LeadExpressedInterestActivity',
        ParentType,
        ContextType
    >;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    timestamp?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    details?: Resolver<Maybe<ResolversTypes['ActivityDetails']>, ParentType, ContextType>;
    action?: Resolver<Maybe<ResolversTypes['ActivityAction']>, ParentType, ContextType>;
};

export type ActivityConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ActivityConnection'] = ResolversParentTypes['ActivityConnection']
> = {
    edges?: Resolver<ReadonlyArray<ResolversTypes['ActivityEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['Activity']>, ParentType, ContextType>;
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityDetailsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ActivityDetails'] = ResolversParentTypes['ActivityDetails']
> = {
    __resolveType: TypeResolveFn<'MessageActivityDetails' | 'FeedbackActivityDetails', ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
};

export type ActivityEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ActivityEdge'] = ResolversParentTypes['ActivityEdge']
> = {
    node?: Resolver<ResolversTypes['Activity'], ParentType, ContextType>;
    cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddContactMutationPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AddContactMutationPayload'] = ResolversParentTypes['AddContactMutationPayload']
> = {
    success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    contact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
    validationErrors?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddEstatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AddEstatePayload'] = ResolversParentTypes['AddEstatePayload']
> = {
    estate?: Resolver<ResolversTypes['Estate'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddIntegrationPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AddIntegrationPayload'] = ResolversParentTypes['AddIntegrationPayload']
> = {
    user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddLeadPreferencePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AddLeadPreferencePayload'] = ResolversParentTypes['AddLeadPreferencePayload']
> = {
    preference?: Resolver<Maybe<ResolversTypes['BuyerPreference']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddLocationPreferencePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AddLocationPreferencePayload'] = ResolversParentTypes['AddLocationPreferencePayload']
> = {
    locationPreference?: Resolver<ResolversTypes['LocationPreference'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddLocationTemplatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AddLocationTemplatePayload'] = ResolversParentTypes['AddLocationTemplatePayload']
> = {
    locationTemplate?: Resolver<ResolversTypes['LocationPreference'], ParentType, ContextType>;
    officeId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddUnitToProjectPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AddUnitToProjectPayload'] = ResolversParentTypes['AddUnitToProjectPayload']
> = {
    project?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    unit?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArchiveEstatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ArchiveEstatePayload'] = ResolversParentTypes['ArchiveEstatePayload']
> = {
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ArchiveLeadsPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ArchiveLeadsPayload'] = ResolversParentTypes['ArchiveLeadsPayload']
> = {
    company?: Resolver<ResolversTypes['Company'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignBuyersToEstatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AssignBuyersToEstatePayload'] = ResolversParentTypes['AssignBuyersToEstatePayload']
> = {
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignEstatesToNegotiatorPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AssignEstatesToNegotiatorPayload'] = ResolversParentTypes['AssignEstatesToNegotiatorPayload']
> = {
    success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    negotiator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignLeadsPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AssignLeadsPayload'] = ResolversParentTypes['AssignLeadsPayload']
> = {
    success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    assignedLeads?: Resolver<ReadonlyArray<ResolversTypes['Contact']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignLegalEntityToPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AssignLegalEntityToPayload'] = ResolversParentTypes['AssignLegalEntityToPayload']
> = {
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignVendorsToEstatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AssignVendorsToEstatePayload'] = ResolversParentTypes['AssignVendorsToEstatePayload']
> = {
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthenticatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['AuthenticatePayload'] = ResolversParentTypes['AuthenticatePayload']
> = {
    success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuyerLeadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BuyerLead'] = ResolversParentTypes['BuyerLead']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    contactRequests?: Resolver<Maybe<ResolversTypes['ContactRequestConnection']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuyerPreferenceResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BuyerPreference'] = ResolversParentTypes['BuyerPreference']
> = {
    __resolveType: TypeResolveFn<
        | 'BuyerPreferenceForHouse'
        | 'BuyerPreferenceForApartment'
        | 'BuyerPreferenceForLand'
        | 'BuyerPreferenceForOffice'
        | 'BuyerPreferenceForCommercial'
        | 'BuyerPreferenceForParking',
        ParentType,
        ContextType
    >;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    negotiation?: Resolver<ResolversTypes['Negotiation'], ParentType, ContextType>;
    isInvestor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    max_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    subtypes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    condition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    budget?: Resolver<Maybe<ResolversTypes['PreferenceBudget']>, ParentType, ContextType>;
    amenities?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    wishesText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    locations?: Resolver<
        Maybe<ResolversTypes['LocationPreferenceConnection']>,
        ParentType,
        ContextType,
        RequireFields<BuyerPreferenceLocationsArgs, never>
    >;
};

export type BuyerPreferenceEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BuyerPreferenceEdge'] = ResolversParentTypes['BuyerPreferenceEdge']
> = {
    node?: Resolver<Maybe<ResolversTypes['BuyerPreference']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuyerPreferenceForApartmentResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BuyerPreferenceForApartment'] = ResolversParentTypes['BuyerPreferenceForApartment']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    negotiation?: Resolver<ResolversTypes['Negotiation'], ParentType, ContextType>;
    isInvestor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    max_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    subtypes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    condition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    budget?: Resolver<Maybe<ResolversTypes['PreferenceBudget']>, ParentType, ContextType>;
    amenities?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    wishesText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    locations?: Resolver<
        ResolversTypes['LocationPreferenceConnection'],
        ParentType,
        ContextType,
        RequireFields<BuyerPreferenceForApartmentLocationsArgs, never>
    >;
    minBedrooms?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    minArea?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuyerPreferenceForCommercialResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BuyerPreferenceForCommercial'] = ResolversParentTypes['BuyerPreferenceForCommercial']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    negotiation?: Resolver<ResolversTypes['Negotiation'], ParentType, ContextType>;
    isInvestor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    max_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    subtypes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    condition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    budget?: Resolver<Maybe<ResolversTypes['PreferenceBudget']>, ParentType, ContextType>;
    amenities?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    wishesText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    locations?: Resolver<
        ResolversTypes['LocationPreferenceConnection'],
        ParentType,
        ContextType,
        RequireFields<BuyerPreferenceForCommercialLocationsArgs, never>
    >;
    minArea?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuyerPreferenceForHouseResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BuyerPreferenceForHouse'] = ResolversParentTypes['BuyerPreferenceForHouse']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    negotiation?: Resolver<ResolversTypes['Negotiation'], ParentType, ContextType>;
    isInvestor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    max_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    subtypes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    condition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    budget?: Resolver<Maybe<ResolversTypes['PreferenceBudget']>, ParentType, ContextType>;
    amenities?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    wishesText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    locations?: Resolver<
        ResolversTypes['LocationPreferenceConnection'],
        ParentType,
        ContextType,
        RequireFields<BuyerPreferenceForHouseLocationsArgs, never>
    >;
    minBedrooms?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    minArea?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuyerPreferenceForLandResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BuyerPreferenceForLand'] = ResolversParentTypes['BuyerPreferenceForLand']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    negotiation?: Resolver<ResolversTypes['Negotiation'], ParentType, ContextType>;
    isInvestor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    max_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    subtypes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    condition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    budget?: Resolver<Maybe<ResolversTypes['PreferenceBudget']>, ParentType, ContextType>;
    amenities?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    wishesText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    locations?: Resolver<
        ResolversTypes['LocationPreferenceConnection'],
        ParentType,
        ContextType,
        RequireFields<BuyerPreferenceForLandLocationsArgs, never>
    >;
    minArea?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuyerPreferenceForOfficeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BuyerPreferenceForOffice'] = ResolversParentTypes['BuyerPreferenceForOffice']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    negotiation?: Resolver<ResolversTypes['Negotiation'], ParentType, ContextType>;
    isInvestor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    max_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    subtypes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    condition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    budget?: Resolver<Maybe<ResolversTypes['PreferenceBudget']>, ParentType, ContextType>;
    amenities?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    wishesText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    locations?: Resolver<
        ResolversTypes['LocationPreferenceConnection'],
        ParentType,
        ContextType,
        RequireFields<BuyerPreferenceForOfficeLocationsArgs, never>
    >;
    minArea?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuyerPreferenceForParkingResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BuyerPreferenceForParking'] = ResolversParentTypes['BuyerPreferenceForParking']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    negotiation?: Resolver<ResolversTypes['Negotiation'], ParentType, ContextType>;
    isInvestor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    max_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_liveable_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_plot_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_net_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    max_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    min_gross_area?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    subtypes?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    condition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    budget?: Resolver<Maybe<ResolversTypes['PreferenceBudget']>, ParentType, ContextType>;
    amenities?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    wishesText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    locations?: Resolver<
        ResolversTypes['LocationPreferenceConnection'],
        ParentType,
        ContextType,
        RequireFields<BuyerPreferenceForParkingLocationsArgs, never>
    >;
    minArea?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuyerPreferencesConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['BuyerPreferencesConnection'] = ResolversParentTypes['BuyerPreferencesConnection']
> = {
    nodes?: Resolver<ReadonlyArray<ResolversTypes['BuyerPreference']>, ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['BuyerPreferenceEdge']>, ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Channel'] = ResolversParentTypes['Channel']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    countries?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
    supportsUnpublishing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    supportsRepublishing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    settings?: Resolver<ResolversTypes['ChannelSettings'], ParentType, ContextType>;
    accounts?: Resolver<ReadonlyArray<ResolversTypes['ChannelAccount']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelAccountResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ChannelAccount'] = ResolversParentTypes['ChannelAccount']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    credentials?: Resolver<ResolversTypes['ChannelAccountCredentials'], ParentType, ContextType>;
    status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    office_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    accessTokenSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    channel?: Resolver<ResolversTypes['Channel'], ParentType, ContextType>;
    webhook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelAccountCredentialsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ChannelAccountCredentials'] = ResolversParentTypes['ChannelAccountCredentials']
> = {
    channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    accessTokenSecret?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    additionalAccessId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelSettingsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ChannelSettings'] = ResolversParentTypes['ChannelSettings']
> = {
    supportsUnpublishing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    supportsRepublishing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    allowsMultipleAccounts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CloneLocationTemplatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CloneLocationTemplatePayload'] = ResolversParentTypes['CloneLocationTemplatePayload']
> = {
    locationPreference?: Resolver<ResolversTypes['LocationPreference'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    leads?: Resolver<
        ResolversTypes['CompanyLeadsConnection'],
        ParentType,
        ContextType,
        RequireFields<CompanyLeadsArgs, 'type'>
    >;
    lead?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType, RequireFields<CompanyLeadArgs, 'id'>>;
    offices?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Office']>>, ParentType, ContextType>;
    admins?: Resolver<
        ResolversTypes['CompanyAdminsConnection'],
        ParentType,
        ContextType,
        RequireFields<CompanyAdminsArgs, never>
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyAdminConnectionEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyAdminConnectionEdge'] = ResolversParentTypes['CompanyAdminConnectionEdge']
> = {
    node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyAdminsConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyAdminsConnection'] = ResolversParentTypes['CompanyAdminsConnection']
> = {
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['CompanyAdminConnectionEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['User']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyLeadConnectionEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyLeadConnectionEdge'] = ResolversParentTypes['CompanyLeadConnectionEdge']
> = {
    node?: Resolver<ResolversTypes['BuyerLead'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyLeadsConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyLeadsConnection'] = ResolversParentTypes['CompanyLeadsConnection']
> = {
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['CompanyLeadConnectionEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['BuyerLead']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyTeamResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyTeam'] = ResolversParentTypes['CompanyTeam']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    settings?: Resolver<Maybe<ResolversTypes['CompanyTeamSettings']>, ParentType, ContextType>;
    head_negotiator?: Resolver<Maybe<ResolversTypes['CompanyTeamHeadNegotiator']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyTeamHeadNegotiatorResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyTeamHeadNegotiator'] = ResolversParentTypes['CompanyTeamHeadNegotiator']
> = {
    data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyTeamResponseResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyTeamResponse'] = ResolversParentTypes['CompanyTeamResponse']
> = {
    data?: Resolver<Maybe<ResolversTypes['CompanyTeam']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyTeamSettingsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyTeamSettings'] = ResolversParentTypes['CompanyTeamSettings']
> = {
    data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyTeamsMetaResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyTeamsMeta'] = ResolversParentTypes['CompanyTeamsMeta']
> = {
    pagination?: Resolver<Maybe<ResolversTypes['CompanyTeamsMetaPagination']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyTeamsMetaPaginationResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyTeamsMetaPagination'] = ResolversParentTypes['CompanyTeamsMetaPagination']
> = {
    current_page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    total_pages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    per_page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyTeamsResponseResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['CompanyTeamsResponse'] = ResolversParentTypes['CompanyTeamsResponse']
> = {
    data?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['CompanyTeam']>>>, ParentType, ContextType>;
    meta?: Resolver<Maybe<ResolversTypes['CompanyTeamsMeta']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact']
> = {
    __resolveType: TypeResolveFn<'Lead' | 'Vendor', ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<ContactPhoneArgs, never>>;
    company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    officeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    notes?: Resolver<ReadonlyArray<ResolversTypes['Note']>, ParentType, ContextType>;
    subscribed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['ContactType'], ParentType, ContextType>;
    photo?: Resolver<Maybe<ResolversTypes['ContactPhoto']>, ParentType, ContextType>;
    createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    address?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
    pronouns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    timeline?: Resolver<ResolversTypes['ContactTimeline'], ParentType, ContextType>;
    interactedProperties?: Resolver<
        ResolversTypes['EstatesConnection'],
        ParentType,
        ContextType,
        RequireFields<ContactInteractedPropertiesArgs, never>
    >;
    eligibleEstates?: Resolver<
        ResolversTypes['EstatesConnection'],
        ParentType,
        ContextType,
        RequireFields<ContactEligibleEstatesArgs, never>
    >;
    lastInteraction?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    assignedNegotiators?: Resolver<
        Maybe<ReadonlyArray<ResolversTypes['ContactAssignedNegotiator']>>,
        ParentType,
        ContextType
    >;
    offers?: Resolver<
        ReadonlyArray<ResolversTypes['Offer']>,
        ParentType,
        ContextType,
        RequireFields<ContactOffersArgs, 'archived'>
    >;
    offersStats?: Resolver<Maybe<ResolversTypes['OffersStats']>, ParentType, ContextType>;
};

export type ContactAssignedNegotiatorResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactAssignedNegotiator'] = ResolversParentTypes['ContactAssignedNegotiator']
> = {
    id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    picture_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactCalledActivityResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactCalledActivity'] = ResolversParentTypes['ContactCalledActivity']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    calledContact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
    calledBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
    about?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    details?: Resolver<Maybe<ResolversTypes['ActivityDetails']>, ParentType, ContextType>;
    action?: Resolver<Maybe<ResolversTypes['ActivityAction']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactConnection'] = ResolversParentTypes['ContactConnection']
> = {
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['ContactEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['Contact']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactEdge'] = ResolversParentTypes['ContactEdge']
> = {
    node?: Resolver<ResolversTypes['Contact'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactEmailedActivityResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactEmailedActivity'] = ResolversParentTypes['ContactEmailedActivity']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    emailedContact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
    emailedBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
    about?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    details?: Resolver<Maybe<ResolversTypes['ActivityDetails']>, ParentType, ContextType>;
    action?: Resolver<Maybe<ResolversTypes['ActivityAction']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactMessagedActivityResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactMessagedActivity'] = ResolversParentTypes['ContactMessagedActivity']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    messagedContact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
    messagedBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
    about?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    details?: Resolver<Maybe<ResolversTypes['ActivityDetails']>, ParentType, ContextType>;
    action?: Resolver<Maybe<ResolversTypes['ActivityAction']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactPhotoResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactPhoto'] = ResolversParentTypes['ContactPhoto']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    uploadedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactReferenceResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactReference'] = ResolversParentTypes['ContactReference']
> = {
    assignee?: Resolver<Maybe<ResolversTypes['ContactReferenceAssignee']>, ParentType, ContextType>;
    team?: Resolver<Maybe<ResolversTypes['ContactReferenceTeam']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactReferenceAssigneeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactReferenceAssignee'] = ResolversParentTypes['ContactReferenceAssignee']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    picture_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactReferenceTeamResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactReferenceTeam'] = ResolversParentTypes['ContactReferenceTeam']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactRequestResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactRequest'] = ResolversParentTypes['ContactRequest']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    preference?: Resolver<Maybe<ResolversTypes['BuyerPreference']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactRequestConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactRequestConnection'] = ResolversParentTypes['ContactRequestConnection']
> = {
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['ContactRequestConnectionEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['ContactRequest']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactRequestConnectionEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactRequestConnectionEdge'] = ResolversParentTypes['ContactRequestConnectionEdge']
> = {
    node?: Resolver<Maybe<ResolversTypes['ContactRequest']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactsOptionsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactsOptions'] = ResolversParentTypes['ContactsOptions']
> = {
    sorts?: Resolver<ReadonlyArray<ResolversTypes['ContactsSortOption']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactsSortOptionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactsSortOption'] = ResolversParentTypes['ContactsSortOption']
> = {
    key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactStatsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactStats'] = ResolversParentTypes['ContactStats']
> = {
    numberOfPendingOffers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    closestOfferExpirations?: Resolver<ReadonlyArray<ResolversTypes['DateTime']>, ParentType, ContextType>;
    hasAcceptedOffer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    highestOfferBid?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactTimelineResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ContactTimeline'] = ResolversParentTypes['ContactTimeline']
> = {
    activities?: Resolver<
        ResolversTypes['ActivityConnection'],
        ParentType,
        ContextType,
        RequireFields<ContactTimelineActivitiesArgs, never>
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CurrencyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Currency'], any> {
    name: 'Currency';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
    name: 'DateTime';
}

export type DisplayableErrorResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['DisplayableError'] = ResolversParentTypes['DisplayableError']
> = {
    __resolveType: TypeResolveFn<'UserError', ParentType, ContextType>;
    field?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type DuplicateEstatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['DuplicateEstatePayload'] = ResolversParentTypes['DuplicateEstatePayload']
> = {
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Estate'] = ResolversParentTypes['Estate']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    internalType?: Resolver<ResolversTypes['EstateType'], ParentType, ContextType>;
    publications?: Resolver<ResolversTypes['EstatePublications'], ParentType, ContextType>;
    channelAccount?: Resolver<
        Maybe<ResolversTypes['EstateChannelAccountConnectionEdge']>,
        ParentType,
        ContextType,
        RequireFields<EstateChannelAccountArgs, 'id'>
    >;
    isProject?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    isUnit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    projectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    negotiation?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    rentPeriod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    office_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    timeline?: Resolver<ResolversTypes['PropertyTimeline'], ParentType, ContextType>;
    attributes?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
    interactedContacts?: Resolver<
        ResolversTypes['EstateContactConnection'],
        ParentType,
        ContextType,
        RequireFields<EstateInteractedContactsArgs, 'type'>
    >;
    visitors?: Resolver<
        ResolversTypes['EstateContactConnection'],
        ParentType,
        ContextType,
        RequireFields<EstateVisitorsArgs, never>
    >;
    buyers?: Resolver<ResolversTypes['EstateContactConnection'], ParentType, ContextType>;
    vendors?: Resolver<ResolversTypes['EstateContactConnection'], ParentType, ContextType>;
    negotiator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
    lastInteraction?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    first_publication_date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    publicURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    units?: Resolver<
        Maybe<ResolversTypes['UnitsConnection']>,
        ParentType,
        ContextType,
        RequireFields<EstateUnitsArgs, never>
    >;
    unit?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType, RequireFields<EstateUnitArgs, 'id'>>;
    visibility?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    pendingRequests?: Resolver<
        Maybe<ResolversTypes['PendingRequestsConnection']>,
        ParentType,
        ContextType,
        RequireFields<EstatePendingRequestsArgs, never>
    >;
    matches?: Resolver<
        Maybe<ResolversTypes['EstateMatchesConnection']>,
        ParentType,
        ContextType,
        RequireFields<EstateMatchesArgs, never>
    >;
    offers?: Resolver<
        ReadonlyArray<ResolversTypes['Offer']>,
        ParentType,
        ContextType,
        RequireFields<EstateOffersArgs, 'archived'>
    >;
    error_publications_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    successful_publications_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    offersStats?: Resolver<Maybe<ResolversTypes['OffersStats']>, ParentType, ContextType>;
    legalEntity?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    buyer_ids?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>;
    owner_ids?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ID']>>, ParentType, ContextType>;
    similarProperties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateAgentFeedsPublicationConfigResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateAgentFeedsPublicationConfig'] = ResolversParentTypes['EstateAgentFeedsPublicationConfig']
> = {
    showPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    showLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    priceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    features?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateAgentFeedsPublicationSettingsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateAgentFeedsPublicationSettings'] = ResolversParentTypes['EstateAgentFeedsPublicationSettings']
> = {
    publishPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    publishLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    priceType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    features?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateAggregationsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateAggregations'] = ResolversParentTypes['EstateAggregations']
> = {
    price?: Resolver<ResolversTypes['PriceAggregation'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateChannelAccountConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateChannelAccountConnection'] = ResolversParentTypes['EstateChannelAccountConnection']
> = {
    edges?: Resolver<ReadonlyArray<ResolversTypes['EstateChannelAccountConnectionEdge']>, ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateChannelAccountConnectionEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateChannelAccountConnectionEdge'] = ResolversParentTypes['EstateChannelAccountConnectionEdge']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    node?: Resolver<ResolversTypes['ChannelAccount'], ParentType, ContextType>;
    configurationStatus?: Resolver<ResolversTypes['ConfigurationStatus'], ParentType, ContextType>;
    configurationStatusMessageI18Key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    publicationStatus?: Resolver<ResolversTypes['PublicationStatus'], ParentType, ContextType>;
    lastPublication?: Resolver<Maybe<ResolversTypes['EstatePublication']>, ParentType, ContextType>;
    missingFields?: Resolver<ResolversTypes['MissingFieldsEdge'], ParentType, ContextType>;
    unitsMissingFields?: Resolver<ResolversTypes['MissingFieldsConnection'], ParentType, ContextType>;
    canPublish?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    outdated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateConfigForChannelAccountResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateConfigForChannelAccount'] = ResolversParentTypes['EstateConfigForChannelAccount']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    missingFields?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
    unitsMissingFields?: Resolver<ReadonlyArray<ResolversTypes['OldUnitsMissingFields']>, ParentType, ContextType>;
    canPublishProject?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    configurationStatus?: Resolver<ResolversTypes['OldConfigurationStatus'], ParentType, ContextType>;
    publicationStatus?: Resolver<ResolversTypes['OldPublicationStatus'], ParentType, ContextType>;
    settings?: Resolver<Maybe<ResolversTypes['AccountPublicationSettings']>, ParentType, ContextType>;
    account?: Resolver<ResolversTypes['ChannelAccount'], ParentType, ContextType>;
    lastPublication?: Resolver<Maybe<ResolversTypes['EstatePublication']>, ParentType, ContextType>;
    outdated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateConnectionEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateConnectionEdge'] = ResolversParentTypes['EstateConnectionEdge']
> = {
    node?: Resolver<ResolversTypes['Estate'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateContactConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateContactConnection'] = ResolversParentTypes['EstateContactConnection']
> = {
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['EstateContactEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['Contact']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateContactEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateContactEdge'] = ResolversParentTypes['EstateContactEdge']
> = {
    node?: Resolver<ResolversTypes['Contact'], ParentType, ContextType>;
    lastInteraction?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateMatchesConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateMatchesConnection'] = ResolversParentTypes['EstateMatchesConnection']
> = {
    edges?: Resolver<ReadonlyArray<ResolversTypes['EstateMatchesConnectionEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['Contact']>, ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    mailableCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstateMatchesConnectionEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstateMatchesConnectionEdge'] = ResolversParentTypes['EstateMatchesConnectionEdge']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    node?: Resolver<ResolversTypes['Contact'], ParentType, ContextType>;
    flags?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstatePublicationResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatePublication'] = ResolversParentTypes['EstatePublication']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    publishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    publishedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
    config?: Resolver<Maybe<ResolversTypes['EstatePublicationConfig']>, ParentType, ContextType>;
    errors?: Resolver<Maybe<ResolversTypes['EstatePublicationErrors']>, ParentType, ContextType>;
    warnings?: Resolver<Maybe<ResolversTypes['EstatePublicationWarnings']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstatePublicationConfigResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatePublicationConfig'] = ResolversParentTypes['EstatePublicationConfig']
> = {
    __resolveType: TypeResolveFn<
        | 'GenericPublicationConfig'
        | 'ImmowebPublicationConfig'
        | 'FacebookPublicationConfig'
        | 'EstateAgentFeedsPublicationConfig'
        | 'TwitterPublicationConfig'
        | 'FrenchPortalPublicationConfig',
        ParentType,
        ContextType
    >;
    showPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    showLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
};

export type EstatePublicationErrorResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatePublicationError'] = ResolversParentTypes['EstatePublicationError']
> = {
    message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    translationKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    context?: Resolver<Maybe<ResolversTypes['PublicationErrorContext']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstatePublicationErrorsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatePublicationErrors'] = ResolversParentTypes['EstatePublicationErrors']
> = {
    self?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['EstatePublicationError']>>>, ParentType, ContextType>;
    units?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UnitPublicationErrors']>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstatePublicationsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatePublications'] = ResolversParentTypes['EstatePublications']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    channelAccounts?: Resolver<
        ResolversTypes['EstateChannelAccountConnection'],
        ParentType,
        ContextType,
        RequireFields<EstatePublicationsChannelAccountsArgs, never>
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstatePublicationSettingsResponseResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatePublicationSettingsResponse'] = ResolversParentTypes['EstatePublicationSettingsResponse']
> = {
    estateChannelAccounts?: Resolver<
        ReadonlyArray<ResolversTypes['EstateConfigForChannelAccount']>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstatePublicationWarningResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatePublicationWarning'] = ResolversParentTypes['EstatePublicationWarning']
> = {
    message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    translationKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    context?: Resolver<Maybe<ResolversTypes['PublicationWarningContext']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstatePublicationWarningsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatePublicationWarnings'] = ResolversParentTypes['EstatePublicationWarnings']
> = {
    self?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['EstatePublicationWarning']>>>, ParentType, ContextType>;
    units?: Resolver<Maybe<ReadonlyArray<ResolversTypes['UnitPublicationWarnings']>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstatesConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatesConnection'] = ResolversParentTypes['EstatesConnection']
> = {
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['Estate']>, ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['EstateConnectionEdge']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstatesOptionsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatesOptions'] = ResolversParentTypes['EstatesOptions']
> = {
    sorts?: Resolver<ReadonlyArray<ResolversTypes['EstatesSortOption']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EstatesSortOptionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['EstatesSortOption'] = ResolversParentTypes['EstatesSortOption']
> = {
    key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacebookPublicationConfigResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['FacebookPublicationConfig'] = ResolversParentTypes['FacebookPublicationConfig']
> = {
    showPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    showLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    post?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacebookPublicationSettingsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['FacebookPublicationSettings'] = ResolversParentTypes['FacebookPublicationSettings']
> = {
    publishPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    publishLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    postText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    images?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedbackActivityDetailsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['FeedbackActivityDetails'] = ResolversParentTypes['FeedbackActivityDetails']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    externalComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    internalComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FinancialDetailsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['FinancialDetails'] = ResolversParentTypes['FinancialDetails']
> = {
    direction?: Resolver<ResolversTypes['OfferDirection'], ParentType, ContextType>;
    currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
    buyerFixedFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    ownerFixedFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    buyerPercentageFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    ownerPercentageFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    buyerGrossAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    ownerNetAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    buyerTotalFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    ownerTotalFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    agencyTotalFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    transactionAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FrenchPortalResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['FrenchPortal'] = ResolversParentTypes['FrenchPortal']
> = {
    automatic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    free?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FrenchPortalPublicationConfigResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['FrenchPortalPublicationConfig'] = ResolversParentTypes['FrenchPortalPublicationConfig']
> = {
    showPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    showLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    portals?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FrenchPortalPublicationSettingsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['FrenchPortalPublicationSettings'] = ResolversParentTypes['FrenchPortalPublicationSettings']
> = {
    publishPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    publishLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    portals?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerateDescriptionPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['GenerateDescriptionPayload'] = ResolversParentTypes['GenerateDescriptionPayload']
> = {
    propertyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenericAccountPublicationSettingsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['GenericAccountPublicationSettings'] = ResolversParentTypes['GenericAccountPublicationSettings']
> = {
    publishPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    publishLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenericPublicationConfigResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['GenericPublicationConfig'] = ResolversParentTypes['GenericPublicationConfig']
> = {
    showPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    showLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoJsonDataResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['GeoJSONData'] = ResolversParentTypes['GeoJSONData']
> = {
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    coordinates?: Resolver<
        ReadonlyArray<ReadonlyArray<ReadonlyArray<ResolversTypes['Float']>>>,
        ParentType,
        ContextType
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoJsonLocationPreferenceResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['GeoJSONLocationPreference'] = ResolversParentTypes['GeoJSONLocationPreference']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    data?: Resolver<ResolversTypes['GeoJSONData'], ParentType, ContextType>;
    createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTemplateResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['GeoTemplate'] = ResolversParentTypes['GeoTemplate']
> = {
    _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    office_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    postalCodes?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
    geometry?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTemplatesDeletePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['GeoTemplatesDeletePayload'] = ResolversParentTypes['GeoTemplatesDeletePayload']
> = {
    _id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTemplatesPaginationResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['GeoTemplatesPagination'] = ResolversParentTypes['GeoTemplatesPagination']
> = {
    current_page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    total_pages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    per_page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoTemplatesPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['GeoTemplatesPayload'] = ResolversParentTypes['GeoTemplatesPayload']
> = {
    data?: Resolver<ReadonlyArray<ResolversTypes['GeoTemplate']>, ParentType, ContextType>;
    pagination?: Resolver<ResolversTypes['GeoTemplatesPagination'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetFrenchPortalsPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['GetFrenchPortalsPayload'] = ResolversParentTypes['GetFrenchPortalsPayload']
> = {
    portals?: Resolver<ReadonlyArray<ResolversTypes['FrenchPortal']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistogramBinResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['HistogramBin'] = ResolversParentTypes['HistogramBin']
> = {
    from?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    to?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImmowebPublicationConfigResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ImmowebPublicationConfig'] = ResolversParentTypes['ImmowebPublicationConfig']
> = {
    showPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    showLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    adSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImmowebPublicationSettingsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ImmowebPublicationSettings'] = ResolversParentTypes['ImmowebPublicationSettings']
> = {
    publishPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    publishLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    adSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
    name: 'JSON';
}

export type LabelResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Label'] = ResolversParentTypes['Label']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    created_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabelsMetaResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LabelsMeta'] = ResolversParentTypes['LabelsMeta']
> = {
    pagination?: Resolver<ResolversTypes['LabelsPagination'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabelsPaginationResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LabelsPagination'] = ResolversParentTypes['LabelsPagination']
> = {
    current_page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    total_pages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    per_page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabelsPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LabelsPayload'] = ResolversParentTypes['LabelsPayload']
> = {
    data?: Resolver<ReadonlyArray<ResolversTypes['Label']>, ParentType, ContextType>;
    meta?: Resolver<Maybe<ResolversTypes['LabelsMeta']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Lead'] = ResolversParentTypes['Lead']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<LeadPhoneArgs, never>>;
    company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    officeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    pronouns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    notes?: Resolver<ReadonlyArray<ResolversTypes['Note']>, ParentType, ContextType>;
    subscribed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['ContactType'], ParentType, ContextType>;
    photo?: Resolver<Maybe<ResolversTypes['ContactPhoto']>, ParentType, ContextType>;
    address?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
    allPreferences?: Resolver<
        ResolversTypes['BuyerPreferencesConnection'],
        ParentType,
        ContextType,
        RequireFields<LeadAllPreferencesArgs, never>
    >;
    preference?: Resolver<
        Maybe<ResolversTypes['BuyerPreference']>,
        ParentType,
        ContextType,
        RequireFields<LeadPreferenceArgs, 'id'>
    >;
    timeline?: Resolver<ResolversTypes['ContactTimeline'], ParentType, ContextType>;
    interactedProperties?: Resolver<
        ResolversTypes['EstatesConnection'],
        ParentType,
        ContextType,
        RequireFields<LeadInteractedPropertiesArgs, never>
    >;
    eligibleEstates?: Resolver<
        ResolversTypes['EstatesConnection'],
        ParentType,
        ContextType,
        RequireFields<LeadEligibleEstatesArgs, never>
    >;
    lastAction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    lastInteraction?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    assignedNegotiators?: Resolver<
        Maybe<ReadonlyArray<ResolversTypes['ContactAssignedNegotiator']>>,
        ParentType,
        ContextType
    >;
    offers?: Resolver<
        ReadonlyArray<ResolversTypes['Offer']>,
        ParentType,
        ContextType,
        RequireFields<LeadOffersArgs, 'archived'>
    >;
    offersStats?: Resolver<Maybe<ResolversTypes['OffersStats']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadExpressedInterestActivityResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LeadExpressedInterestActivity'] = ResolversParentTypes['LeadExpressedInterestActivity']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    timestamp?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    interestedLead?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
    propertyInterestedIn?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    interestExpressedVia?: Resolver<Maybe<ResolversTypes['LeadSource']>, ParentType, ContextType>;
    details?: Resolver<Maybe<ResolversTypes['MessageActivityDetails']>, ParentType, ContextType>;
    action?: Resolver<Maybe<ResolversTypes['ActivityAction']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeadSourceResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LeadSource'] = ResolversParentTypes['LeadSource']
> = {
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalEntitiesResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LegalEntities'] = ResolversParentTypes['LegalEntities']
> = {
    id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    office_id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalEntitiesConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LegalEntitiesConnection'] = ResolversParentTypes['LegalEntitiesConnection']
> = {
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['LegalEntitiesEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['LegalEntities']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalEntitiesDeletePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LegalEntitiesDeletePayload'] = ResolversParentTypes['LegalEntitiesDeletePayload']
> = {
    id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalEntitiesEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LegalEntitiesEdge'] = ResolversParentTypes['LegalEntitiesEdge']
> = {
    node?: Resolver<ResolversTypes['LegalEntities'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegalEntityResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LegalEntity'] = ResolversParentTypes['LegalEntity']
> = {
    data?: Resolver<Maybe<ResolversTypes['LegalEntities']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocalizedTextResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LocalizedText'] = ResolversParentTypes['LocalizedText']
> = {
    locale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationPreferenceResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LocationPreference'] = ResolversParentTypes['LocationPreference']
> = {
    __resolveType: TypeResolveFn<
        'PostalCodesLocationPreference' | 'GeoJSONLocationPreference',
        ParentType,
        ContextType
    >;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
};

export type LocationPreferenceConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LocationPreferenceConnection'] = ResolversParentTypes['LocationPreferenceConnection']
> = {
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['LocationPreferenceEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['LocationPreference']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationPreferenceEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['LocationPreferenceEdge'] = ResolversParentTypes['LocationPreferenceEdge']
> = {
    node?: Resolver<ResolversTypes['LocationPreference'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchingConfigPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['MatchingConfigPayload'] = ResolversParentTypes['MatchingConfigPayload']
> = {
    data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchingEmailsPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['MatchingEmailsPayload'] = ResolversParentTypes['MatchingEmailsPayload']
> = {
    data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchingFlagsPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['MatchingFlagsPayload'] = ResolversParentTypes['MatchingFlagsPayload']
> = {
    data?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['MatchingFlagsPayloadData']>>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchingFlagsPayloadDataResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['MatchingFlagsPayloadData'] = ResolversParentTypes['MatchingFlagsPayloadData']
> = {
    contactId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    propertyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    flags?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchingRequirementsPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['MatchingRequirementsPayload'] = ResolversParentTypes['MatchingRequirementsPayload']
> = {
    data?: Resolver<Maybe<ResolversTypes['MatchingRequirementsPayloadData']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchingRequirementsPayloadDataResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['MatchingRequirementsPayloadData'] = ResolversParentTypes['MatchingRequirementsPayloadData']
> = {
    contactId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    propertyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    preferenceId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    isMatchable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    errors?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageActivityDetailsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['MessageActivityDetails'] = ResolversParentTypes['MessageActivityDetails']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissingFieldResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['MissingField'] = ResolversParentTypes['MissingField']
> = {
    path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    errorMessage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissingFieldsConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['MissingFieldsConnection'] = ResolversParentTypes['MissingFieldsConnection']
> = {
    edges?: Resolver<ReadonlyArray<ResolversTypes['MissingFieldsEdge']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MissingFieldsEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['MissingFieldsEdge'] = ResolversParentTypes['MissingFieldsEdge']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    estateId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    node?: Resolver<ReadonlyArray<ResolversTypes['MissingField']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
    saveChannelAccount?: Resolver<
        Maybe<ResolversTypes['ChannelAccount']>,
        ParentType,
        ContextType,
        RequireFields<MutationSaveChannelAccountArgs, 'companyId' | 'input'>
    >;
    removeChannelAccount?: Resolver<
        ResolversTypes['ID'],
        ParentType,
        ContextType,
        RequireFields<MutationRemoveChannelAccountArgs, 'companyId' | 'accountId'>
    >;
    saveFacebookPublicationSettingsForEstate?: Resolver<
        ResolversTypes['EstateConfigForChannelAccount'],
        ParentType,
        ContextType,
        RequireFields<MutationSaveFacebookPublicationSettingsForEstateArgs, never>
    >;
    saveTwitterPublicationSettingsForEstate?: Resolver<
        ResolversTypes['EstateConfigForChannelAccount'],
        ParentType,
        ContextType,
        RequireFields<MutationSaveTwitterPublicationSettingsForEstateArgs, never>
    >;
    saveImmowebPublicationSettingsForEstate?: Resolver<
        ResolversTypes['EstateConfigForChannelAccount'],
        ParentType,
        ContextType,
        RequireFields<MutationSaveImmowebPublicationSettingsForEstateArgs, never>
    >;
    createPublication?: Resolver<
        ResolversTypes['PublishPropertyPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationCreatePublicationArgs, 'input'>
    >;
    removePublication?: Resolver<
        ResolversTypes['PublishPropertyPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationRemovePublicationArgs, 'input'>
    >;
    updatePublication?: Resolver<
        ResolversTypes['PublishPropertyPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdatePublicationArgs, 'input'>
    >;
    retryPublication?: Resolver<
        ResolversTypes['RetryPublicationPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationRetryPublicationArgs, 'input'>
    >;
    refreshPublication?: Resolver<
        ResolversTypes['RefreshPublicationPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationRefreshPublicationArgs, 'input'>
    >;
    getFrenchPortals?: Resolver<
        ResolversTypes['GetFrenchPortalsPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationGetFrenchPortalsArgs, 'input'>
    >;
    saveEstatePublicationSettings?: Resolver<
        ResolversTypes['EstatePublicationSettingsResponse'],
        ParentType,
        ContextType,
        RequireFields<MutationSaveEstatePublicationSettingsArgs, 'input'>
    >;
    addEstate?: Resolver<
        ResolversTypes['AddEstatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAddEstateArgs, 'input'>
    >;
    updateEstate?: Resolver<
        ResolversTypes['UpdateEstatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateEstateArgs, 'estateId' | 'estateType' | 'operations'>
    >;
    archiveEstate?: Resolver<
        ResolversTypes['ArchiveEstatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationArchiveEstateArgs, 'input'>
    >;
    updateEstateStatus?: Resolver<
        Maybe<ResolversTypes['Estate']>,
        ParentType,
        ContextType,
        RequireFields<MutationUpdateEstateStatusArgs, 'estateId'>
    >;
    unarchiveEstate?: Resolver<
        ResolversTypes['UnarchiveEstatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUnarchiveEstateArgs, 'input'>
    >;
    duplicateEstate?: Resolver<
        ResolversTypes['DuplicateEstatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationDuplicateEstateArgs, 'input'>
    >;
    registerEstateActivity?: Resolver<
        ResolversTypes['RegisterEstateActivityPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationRegisterEstateActivityArgs, 'input'>
    >;
    assignEstatesToNegotiator?: Resolver<
        ResolversTypes['AssignEstatesToNegotiatorPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAssignEstatesToNegotiatorArgs, 'input'>
    >;
    assignVendorsToEstate?: Resolver<
        ResolversTypes['AssignVendorsToEstatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAssignVendorsToEstateArgs, 'input'>
    >;
    unassignVendorsToEstate?: Resolver<
        ResolversTypes['UnassignVendorsToEstatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUnassignVendorsToEstateArgs, 'input'>
    >;
    assignBuyersToEstate?: Resolver<
        ResolversTypes['AssignBuyersToEstatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAssignBuyersToEstateArgs, 'input'>
    >;
    unassignBuyersToEstate?: Resolver<
        ResolversTypes['UnassignBuyersToEstatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUnassignBuyersToEstateArgs, 'input'>
    >;
    assignLegalEntity?: Resolver<
        Maybe<ResolversTypes['AssignLegalEntityToPayload']>,
        ParentType,
        ContextType,
        RequireFields<MutationAssignLegalEntityArgs, 'input'>
    >;
    unassignLegalEntity?: Resolver<
        Maybe<ResolversTypes['UnassignLegalEntityToPayload']>,
        ParentType,
        ContextType,
        RequireFields<MutationUnassignLegalEntityArgs, 'input'>
    >;
    addUnitToProject?: Resolver<
        ResolversTypes['AddUnitToProjectPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAddUnitToProjectArgs, 'input'>
    >;
    generateDescription?: Resolver<
        ResolversTypes['GenerateDescriptionPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationGenerateDescriptionArgs, 'generateInput'>
    >;
    translate?: Resolver<
        ResolversTypes['TranslatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationTranslateArgs, 'translateInput'>
    >;
    updatePropertyVisibility?: Resolver<
        Maybe<ResolversTypes['UpdatePropertyVisibilityPayload']>,
        ParentType,
        ContextType,
        RequireFields<MutationUpdatePropertyVisibilityArgs, 'propertyId' | 'visibility'>
    >;
    addLocationPreference?: Resolver<
        ResolversTypes['AddLocationPreferencePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAddLocationPreferenceArgs, 'input'>
    >;
    removeLocationPreference?: Resolver<
        ResolversTypes['RemoveLocationPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationRemoveLocationPreferenceArgs, 'leadId' | 'locationPreferenceId'>
    >;
    updateLocationPreference?: Resolver<
        ResolversTypes['UpdateLocationPreferencePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateLocationPreferenceArgs, 'leadId' | 'locationPreferenceId' | 'preference'>
    >;
    addLocationPreferenceTemplate?: Resolver<
        ResolversTypes['AddLocationTemplatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAddLocationPreferenceTemplateArgs, 'officeId' | 'preference'>
    >;
    removeLocationPreferenceTemplate?: Resolver<
        ResolversTypes['RemoveLocationTemplatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationRemoveLocationPreferenceTemplateArgs, 'officeId' | 'locationId'>
    >;
    updateLocationPreferenceTemplate?: Resolver<
        ResolversTypes['UpdateLocationTemplatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateLocationPreferenceTemplateArgs, 'officeId' | 'locationId' | 'preference'>
    >;
    cloneLocationPreferenceTemplate?: Resolver<
        ResolversTypes['CloneLocationTemplatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationCloneLocationPreferenceTemplateArgs, 'input'>
    >;
    updateUser?: Resolver<
        Maybe<ResolversTypes['UpdateUserPayload']>,
        ParentType,
        ContextType,
        RequireFields<MutationUpdateUserArgs, 'input'>
    >;
    updateUserOffice?: Resolver<
        ResolversTypes['User'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateUserOfficeArgs, 'officeId'>
    >;
    addIntegration?: Resolver<
        ResolversTypes['AddIntegrationPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAddIntegrationArgs, 'input'>
    >;
    removeIntegration?: Resolver<
        ResolversTypes['RemoveIntegrationPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationRemoveIntegrationArgs, 'input'>
    >;
    inviteUsers?: Resolver<
        ResolversTypes['Boolean'],
        ParentType,
        ContextType,
        RequireFields<MutationInviteUsersArgs, 'invite'>
    >;
    updateUserRole?: Resolver<
        ResolversTypes['Boolean'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateUserRoleArgs, 'officeId' | 'userId' | 'role'>
    >;
    updateUserTeams?: Resolver<
        ResolversTypes['Boolean'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateUserTeamsArgs, 'userId' | 'teamsIds'>
    >;
    archiveUser?: Resolver<
        ResolversTypes['Boolean'],
        ParentType,
        ContextType,
        RequireFields<MutationArchiveUserArgs, 'userId'>
    >;
    authenticate?: Resolver<
        ResolversTypes['AuthenticatePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAuthenticateArgs, 'code'>
    >;
    updateLeadPreference?: Resolver<
        ResolversTypes['UpdateLeadPreferencePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateLeadPreferenceArgs, 'input'>
    >;
    addLeadPreference?: Resolver<
        ResolversTypes['AddLeadPreferencePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAddLeadPreferenceArgs, 'input'>
    >;
    removeLeadPreference?: Resolver<
        ResolversTypes['RemoveLeadPreferencePayload'],
        ParentType,
        ContextType,
        RequireFields<MutationRemoveLeadPreferenceArgs, 'input'>
    >;
    assignLeadsToOffice?: Resolver<
        ResolversTypes['AssignLeadsPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAssignLeadsToOfficeArgs, 'input'>
    >;
    archiveLeads?: Resolver<
        ResolversTypes['ArchiveLeadsPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationArchiveLeadsArgs, 'input'>
    >;
    createLabel?: Resolver<
        ResolversTypes['Label'],
        ParentType,
        ContextType,
        RequireFields<MutationCreateLabelArgs, never>
    >;
    updateLabel?: Resolver<
        ResolversTypes['Label'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateLabelArgs, never>
    >;
    deleteLabel?: Resolver<
        Maybe<ResolversTypes['JSON']>,
        ParentType,
        ContextType,
        RequireFields<MutationDeleteLabelArgs, 'id'>
    >;
    addContact?: Resolver<
        ResolversTypes['AddContactMutationPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationAddContactArgs, 'input'>
    >;
    updateContactDetails?: Resolver<
        ResolversTypes['UpdateContactMutationPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateContactDetailsArgs, 'input'>
    >;
    updateContactAddress?: Resolver<
        ResolversTypes['UpdateContactMutationPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateContactAddressArgs, 'input'>
    >;
    archiveContact?: Resolver<
        ResolversTypes['UpdateContactMutationPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationArchiveContactArgs, 'id'>
    >;
    unarchiveContact?: Resolver<
        ResolversTypes['UpdateContactMutationPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUnarchiveContactArgs, 'id'>
    >;
    updateContactLabels?: Resolver<
        Maybe<ResolversTypes['JSON']>,
        ParentType,
        ContextType,
        RequireFields<MutationUpdateContactLabelsArgs, 'id' | 'labels'>
    >;
    updateContactAssignments?: Resolver<
        ResolversTypes['UpdateContactAssignmentsPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateContactAssignmentsArgs, 'input'>
    >;
    sendVendorReport?: Resolver<
        ResolversTypes['SendVendorReportPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationSendVendorReportArgs, 'input'>
    >;
    scheduleVisit?: Resolver<
        ResolversTypes['ScheduleVisitPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationScheduleVisitArgs, 'input'>
    >;
    updateVisitStatus?: Resolver<
        ResolversTypes['UpdateVisitStatusPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateVisitStatusArgs, 'input'>
    >;
    createOffer?: Resolver<
        ResolversTypes['Offer'],
        ParentType,
        ContextType,
        RequireFields<MutationCreateOfferArgs, 'offerInput'>
    >;
    updateOffer?: Resolver<
        ResolversTypes['Offer'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateOfferArgs, 'offerInput'>
    >;
    archiveOffer?: Resolver<
        ResolversTypes['Offer'],
        ParentType,
        ContextType,
        RequireFields<MutationArchiveOfferArgs, 'offerId'>
    >;
    restoreOffer?: Resolver<
        ResolversTypes['Offer'],
        ParentType,
        ContextType,
        RequireFields<MutationRestoreOfferArgs, 'offerId'>
    >;
    acceptOffer?: Resolver<
        ResolversTypes['Offer'],
        ParentType,
        ContextType,
        RequireFields<MutationAcceptOfferArgs, 'offerId'>
    >;
    refuseOffer?: Resolver<
        ResolversTypes['Offer'],
        ParentType,
        ContextType,
        RequireFields<MutationRefuseOfferArgs, 'offerId'>
    >;
    cancelOffer?: Resolver<
        ResolversTypes['Offer'],
        ParentType,
        ContextType,
        RequireFields<MutationCancelOfferArgs, 'offerId'>
    >;
    addLegalEntity?: Resolver<
        ResolversTypes['LegalEntities'],
        ParentType,
        ContextType,
        RequireFields<MutationAddLegalEntityArgs, 'officeId' | 'input'>
    >;
    updateLegalEntity?: Resolver<
        ResolversTypes['LegalEntities'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateLegalEntityArgs, 'officeId' | 'input'>
    >;
    deleteLegalEntity?: Resolver<
        Maybe<ResolversTypes['LegalEntitiesDeletePayload']>,
        ParentType,
        ContextType,
        RequireFields<MutationDeleteLegalEntityArgs, 'officeId' | 'input'>
    >;
    addGeoTemplate?: Resolver<
        ResolversTypes['GeoTemplate'],
        ParentType,
        ContextType,
        RequireFields<MutationAddGeoTemplateArgs, 'officeId' | 'input'>
    >;
    updateGeoTemplate?: Resolver<
        ResolversTypes['GeoTemplate'],
        ParentType,
        ContextType,
        RequireFields<MutationUpdateGeoTemplateArgs, 'officeId' | 'input'>
    >;
    deleteGeoTemplate?: Resolver<
        Maybe<ResolversTypes['GeoTemplatesDeletePayload']>,
        ParentType,
        ContextType,
        RequireFields<MutationDeleteGeoTemplateArgs, 'officeId' | 'input'>
    >;
    matchingEmails?: Resolver<
        ResolversTypes['MatchingEmailsPayload'],
        ParentType,
        ContextType,
        RequireFields<MutationMatchingEmailsArgs, 'input'>
    >;
};

export type NoteResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Note'] = ResolversParentTypes['Note']
> = {
    text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OfferResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Offer'] = ResolversParentTypes['Offer']
> = {
    id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    parentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    status?: Resolver<ResolversTypes['OfferStatus'], ParentType, ContextType>;
    financialDetails?: Resolver<ResolversTypes['FinancialDetails'], ParentType, ContextType>;
    createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    validUntil?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    acceptedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    refusedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    cancelledAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    buyers?: Resolver<ReadonlyArray<ResolversTypes['OfferOwner']>, ParentType, ContextType>;
    owners?: Resolver<ReadonlyArray<ResolversTypes['OfferBuyer']>, ParentType, ContextType>;
    propertyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    property?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    createdBy?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    creator?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
    notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OfferBuyerResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['OfferBuyer'] = ResolversParentTypes['OfferBuyer']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OfferOwnerResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['OfferOwner'] = ResolversParentTypes['OfferOwner']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OffersStatsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['OffersStats'] = ResolversParentTypes['OffersStats']
> = {
    number_of_pending_offers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    highest_offer_bid?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    has_accepted_offer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    closest_offer_expirations?: Resolver<ReadonlyArray<ResolversTypes['DateTime']>, ParentType, ContextType>;
    currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OfficeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Office'] = ResolversParentTypes['Office']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    settings?: Resolver<ResolversTypes['OfficeSettings'], ParentType, ContextType>;
    legalEntity?: Resolver<
        Maybe<ResolversTypes['LegalEntity']>,
        ParentType,
        ContextType,
        RequireFields<OfficeLegalEntityArgs, 'officeId' | 'id'>
    >;
    legalEntities?: Resolver<
        ResolversTypes['LegalEntitiesConnection'],
        ParentType,
        ContextType,
        RequireFields<OfficeLegalEntitiesArgs, never>
    >;
    locationTemplates?: Resolver<
        ResolversTypes['LocationPreferenceConnection'],
        ParentType,
        ContextType,
        RequireFields<OfficeLocationTemplatesArgs, never>
    >;
    negotiators?: Resolver<
        ReadonlyArray<ResolversTypes['User']>,
        ParentType,
        ContextType,
        RequireFields<OfficeNegotiatorsArgs, never>
    >;
    negotiatiorsByIds?: Resolver<
        ReadonlyArray<ResolversTypes['User']>,
        ParentType,
        ContextType,
        RequireFields<OfficeNegotiatiorsByIdsArgs, 'ids'>
    >;
    estates?: Resolver<
        Maybe<ResolversTypes['EstatesConnection']>,
        ParentType,
        ContextType,
        RequireFields<OfficeEstatesArgs, never>
    >;
    members?: Resolver<
        Maybe<ResolversTypes['TeamMembersConnection']>,
        ParentType,
        ContextType,
        RequireFields<OfficeMembersArgs, never>
    >;
    companyMembers?: Resolver<
        Maybe<ResolversTypes['TeamMembersConnection']>,
        ParentType,
        ContextType,
        RequireFields<OfficeCompanyMembersArgs, never>
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OfficeSettingsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['OfficeSettings'] = ResolversParentTypes['OfficeSettings']
> = {
    currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    numeralSystem?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OldUnitsMissingFieldsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['OldUnitsMissingFields'] = ResolversParentTypes['OldUnitsMissingFields']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    unitId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    missingFields?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']
> = {
    currentPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    itemsPerPage?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingRequestResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PendingRequest'] = ResolversParentTypes['PendingRequest']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    resentAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingRequestEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PendingRequestEdge'] = ResolversParentTypes['PendingRequestEdge']
> = {
    node?: Resolver<ResolversTypes['PendingRequest'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingRequestsConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PendingRequestsConnection'] = ResolversParentTypes['PendingRequestsConnection']
> = {
    edges?: Resolver<ReadonlyArray<ResolversTypes['PendingRequestEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['PendingRequest']>, ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostalCodesDataResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PostalCodesData'] = ResolversParentTypes['PostalCodesData']
> = {
    country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    postalCodes?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostalCodesLocationPreferenceResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PostalCodesLocationPreference'] = ResolversParentTypes['PostalCodesLocationPreference']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    data?: Resolver<ResolversTypes['PostalCodesData'], ParentType, ContextType>;
    createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PreferenceBudgetResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PreferenceBudget'] = ResolversParentTypes['PreferenceBudget']
> = {
    maxPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    minPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriceAggregationResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PriceAggregation'] = ResolversParentTypes['PriceAggregation']
> = {
    maxPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    minPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    histogram?: Resolver<ReadonlyArray<ResolversTypes['HistogramBin']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyReferenceResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PropertyReference'] = ResolversParentTypes['PropertyReference']
> = {
    assignee?: Resolver<Maybe<ResolversTypes['PropertyReferenceAssignee']>, ParentType, ContextType>;
    team?: Resolver<Maybe<ResolversTypes['PropertyReferenceTeam']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyReferenceAssigneeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PropertyReferenceAssignee'] = ResolversParentTypes['PropertyReferenceAssignee']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    picture_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyReferenceTeamResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PropertyReferenceTeam'] = ResolversParentTypes['PropertyReferenceTeam']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyStatsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PropertyStats'] = ResolversParentTypes['PropertyStats']
> = {
    numberOfPendingOffers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    closestOfferExpirations?: Resolver<ReadonlyArray<ResolversTypes['DateTime']>, ParentType, ContextType>;
    hasAcceptedOffer?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    highestOfferBid?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyStatusResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PropertyStatus'] = ResolversParentTypes['PropertyStatus']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    negotiation?: Resolver<ResolversTypes['Negotiation'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyTimelineResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PropertyTimeline'] = ResolversParentTypes['PropertyTimeline']
> = {
    activities?: Resolver<
        ResolversTypes['ActivityConnection'],
        ParentType,
        ContextType,
        RequireFields<PropertyTimelineActivitiesArgs, never>
    >;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PropertyVisitedActivityResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PropertyVisitedActivity'] = ResolversParentTypes['PropertyVisitedActivity']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    visitedBy?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Contact']>>>, ParentType, ContextType>;
    property?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    registeredBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
    details?: Resolver<Maybe<ResolversTypes['ActivityDetails']>, ParentType, ContextType>;
    action?: Resolver<Maybe<ResolversTypes['ActivityAction']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicationErrorContextResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PublicationErrorContext'] = ResolversParentTypes['PublicationErrorContext']
> = {
    field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicationWarningContextResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PublicationWarningContext'] = ResolversParentTypes['PublicationWarningContext']
> = {
    field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishPropertyPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['PublishPropertyPayload'] = ResolversParentTypes['PublishPropertyPayload']
> = {
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
    channelAccounts?: Resolver<
        Maybe<ReadonlyArray<ResolversTypes['ChannelAccount']>>,
        ParentType,
        ContextType,
        RequireFields<QueryChannelAccountsArgs, never>
    >;
    channelAccount?: Resolver<
        Maybe<ResolversTypes['ChannelAccount']>,
        ParentType,
        ContextType,
        RequireFields<QueryChannelAccountArgs, never>
    >;
    channels?: Resolver<
        ReadonlyArray<ResolversTypes['Channel']>,
        ParentType,
        ContextType,
        RequireFields<QueryChannelsArgs, never>
    >;
    channel?: Resolver<
        Maybe<ResolversTypes['Channel']>,
        ParentType,
        ContextType,
        RequireFields<QueryChannelArgs, 'id'>
    >;
    estateChannelAccount?: Resolver<
        Maybe<ResolversTypes['EstateConfigForChannelAccount']>,
        ParentType,
        ContextType,
        RequireFields<QueryEstateChannelAccountArgs, 'estateId' | 'estateChannelAccountId'>
    >;
    estates?: Resolver<
        ResolversTypes['EstatesConnection'],
        ParentType,
        ContextType,
        RequireFields<QueryEstatesArgs, never>
    >;
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType, RequireFields<QueryEstateArgs, 'id'>>;
    estatesOptions?: Resolver<Maybe<ResolversTypes['EstatesOptions']>, ParentType, ContextType>;
    propertyReference?: Resolver<
        Maybe<ResolversTypes['PropertyReference']>,
        ParentType,
        ContextType,
        RequireFields<QueryPropertyReferenceArgs, 'id'>
    >;
    estatePublications?: Resolver<
        Maybe<ResolversTypes['EstatePublications']>,
        ParentType,
        ContextType,
        RequireFields<QueryEstatePublicationsArgs, 'id'>
    >;
    contacts?: Resolver<
        ResolversTypes['ContactConnection'],
        ParentType,
        ContextType,
        RequireFields<QueryContactsArgs, never>
    >;
    contact?: Resolver<
        Maybe<ResolversTypes['Contact']>,
        ParentType,
        ContextType,
        RequireFields<QueryContactArgs, 'id'>
    >;
    contactReference?: Resolver<
        Maybe<ResolversTypes['ContactReference']>,
        ParentType,
        ContextType,
        RequireFields<QueryContactReferenceArgs, 'id'>
    >;
    contactsOptions?: Resolver<Maybe<ResolversTypes['ContactsOptions']>, ParentType, ContextType>;
    contactLabels?: Resolver<
        ReadonlyArray<ResolversTypes['Label']>,
        ParentType,
        ContextType,
        RequireFields<QueryContactLabelsArgs, 'id'>
    >;
    lead?: Resolver<Maybe<ResolversTypes['Lead']>, ParentType, ContextType, RequireFields<QueryLeadArgs, 'id'>>;
    authCheck?: Resolver<
        Maybe<ResolversTypes['JSON']>,
        ParentType,
        ContextType,
        RequireFields<QueryAuthCheckArgs, never>
    >;
    label?: Resolver<Maybe<ResolversTypes['Label']>, ParentType, ContextType, RequireFields<QueryLabelArgs, 'id'>>;
    labels?: Resolver<
        Maybe<ResolversTypes['LabelsPayload']>,
        ParentType,
        ContextType,
        RequireFields<QueryLabelsArgs, never>
    >;
    propertiesAggregations?: Resolver<
        ResolversTypes['EstateAggregations'],
        ParentType,
        ContextType,
        RequireFields<QueryPropertiesAggregationsArgs, never>
    >;
    me?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
    user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
    office?: Resolver<Maybe<ResolversTypes['Office']>, ParentType, ContextType, RequireFields<QueryOfficeArgs, 'id'>>;
    statuses?: Resolver<
        ReadonlyArray<ResolversTypes['PropertyStatus']>,
        ParentType,
        ContextType,
        RequireFields<QueryStatusesArgs, never>
    >;
    activity?: Resolver<
        Maybe<ResolversTypes['Activity']>,
        ParentType,
        ContextType,
        RequireFields<QueryActivityArgs, 'id'>
    >;
    propertySchedulePendingConfirmation?: Resolver<
        ResolversTypes['ScheduleCount'],
        ParentType,
        ContextType,
        RequireFields<QueryPropertySchedulePendingConfirmationArgs, 'propertyId'>
    >;
    contactSchedulePendingConfirmation?: Resolver<
        ResolversTypes['ScheduleCount'],
        ParentType,
        ContextType,
        RequireFields<QueryContactSchedulePendingConfirmationArgs, 'contactId'>
    >;
    propertySchedule?: Resolver<
        ReadonlyArray<ResolversTypes['ScheduleEvent']>,
        ParentType,
        ContextType,
        RequireFields<QueryPropertyScheduleArgs, 'propertyId' | 'limit'>
    >;
    contactSchedule?: Resolver<
        ReadonlyArray<ResolversTypes['ScheduleEventVisit']>,
        ParentType,
        ContextType,
        RequireFields<QueryContactScheduleArgs, 'contactId' | 'limit'>
    >;
    offer?: Resolver<Maybe<ResolversTypes['Offer']>, ParentType, ContextType, RequireFields<QueryOfferArgs, 'offerId'>>;
    propertyOffers?: Resolver<
        ReadonlyArray<ResolversTypes['Offer']>,
        ParentType,
        ContextType,
        RequireFields<QueryPropertyOffersArgs, 'filter'>
    >;
    propertyStats?: Resolver<
        Maybe<ResolversTypes['PropertyStats']>,
        ParentType,
        ContextType,
        RequireFields<QueryPropertyStatsArgs, 'filter'>
    >;
    contactOffers?: Resolver<
        ReadonlyArray<ResolversTypes['Offer']>,
        ParentType,
        ContextType,
        RequireFields<QueryContactOffersArgs, 'filter'>
    >;
    contactStats?: Resolver<
        Maybe<ResolversTypes['ContactStats']>,
        ParentType,
        ContextType,
        RequireFields<QueryContactStatsArgs, 'filter'>
    >;
    version?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    geoTemplate?: Resolver<
        ResolversTypes['GeoTemplate'],
        ParentType,
        ContextType,
        RequireFields<QueryGeoTemplateArgs, 'officeId' | 'id'>
    >;
    geoTemplates?: Resolver<
        ResolversTypes['GeoTemplatesPayload'],
        ParentType,
        ContextType,
        RequireFields<QueryGeoTemplatesArgs, 'officeId'>
    >;
    team?: Resolver<
        Maybe<ResolversTypes['CompanyTeamResponse']>,
        ParentType,
        ContextType,
        RequireFields<QueryTeamArgs, 'teamId'>
    >;
    teams?: Resolver<
        Maybe<ResolversTypes['CompanyTeamsResponse']>,
        ParentType,
        ContextType,
        RequireFields<QueryTeamsArgs, 'companyId'>
    >;
    userTeams?: Resolver<
        Maybe<ResolversTypes['CompanyTeamsResponse']>,
        ParentType,
        ContextType,
        RequireFields<QueryUserTeamsArgs, 'userId'>
    >;
    matchingFlags?: Resolver<
        ResolversTypes['MatchingFlagsPayload'],
        ParentType,
        ContextType,
        RequireFields<QueryMatchingFlagsArgs, 'input'>
    >;
    matchingConfig?: Resolver<
        ResolversTypes['MatchingConfigPayload'],
        ParentType,
        ContextType,
        RequireFields<QueryMatchingConfigArgs, 'input'>
    >;
    matchingRequirements?: Resolver<
        ResolversTypes['MatchingRequirementsPayload'],
        ParentType,
        ContextType,
        RequireFields<QueryMatchingRequirementsArgs, 'input'>
    >;
};

export type RefreshPublicationPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RefreshPublicationPayload'] = ResolversParentTypes['RefreshPublicationPayload']
> = {
    success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegisterEstateActivityPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RegisterEstateActivityPayload'] = ResolversParentTypes['RegisterEstateActivityPayload']
> = {
    subject?: Resolver<ResolversTypes['Estate'], ParentType, ContextType>;
    contact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
    activity?: Resolver<ResolversTypes['Activity'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveIntegrationPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RemoveIntegrationPayload'] = ResolversParentTypes['RemoveIntegrationPayload']
> = {
    user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveLeadPreferencePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RemoveLeadPreferencePayload'] = ResolversParentTypes['RemoveLeadPreferencePayload']
> = {
    contact?: Resolver<ResolversTypes['Contact'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveLocationPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RemoveLocationPayload'] = ResolversParentTypes['RemoveLocationPayload']
> = {
    lead?: Resolver<ResolversTypes['Lead'], ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveLocationTemplatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RemoveLocationTemplatePayload'] = ResolversParentTypes['RemoveLocationTemplatePayload']
> = {
    office?: Resolver<ResolversTypes['Office'], ParentType, ContextType>;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RetryPublicationPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['RetryPublicationPayload'] = ResolversParentTypes['RetryPublicationPayload']
> = {
    channelAccount?: Resolver<ResolversTypes['EstateChannelAccountConnectionEdge'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleCountResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ScheduleCount'] = ResolversParentTypes['ScheduleCount']
> = {
    count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleEventResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ScheduleEvent'] = ResolversParentTypes['ScheduleEvent']
> = {
    __resolveType: TypeResolveFn<
        'ScheduleEventVisit' | 'ScheduleEventAuction' | 'ScheduleEventOpenHome',
        ParentType,
        ContextType
    >;
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    propertyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    estateLocation?: Resolver<
        Maybe<ResolversTypes['String']>,
        ParentType,
        ContextType,
        RequireFields<ScheduleEventEstateLocationArgs, 'lang'>
    >;
    type?: Resolver<ResolversTypes['ScheduleEventType'], ParentType, ContextType>;
    createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type ScheduleEventAttendeeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ScheduleEventAttendee'] = ResolversParentTypes['ScheduleEventAttendee']
> = {
    id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    resource_type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleEventAuctionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ScheduleEventAuction'] = ResolversParentTypes['ScheduleEventAuction']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    estateLocation?: Resolver<
        Maybe<ResolversTypes['String']>,
        ParentType,
        ContextType,
        RequireFields<ScheduleEventAuctionEstateLocationArgs, 'lang'>
    >;
    propertyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['ScheduleEventType'], ParentType, ContextType>;
    createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleEventOpenHomeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ScheduleEventOpenHome'] = ResolversParentTypes['ScheduleEventOpenHome']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    estateLocation?: Resolver<
        Maybe<ResolversTypes['String']>,
        ParentType,
        ContextType,
        RequireFields<ScheduleEventOpenHomeEstateLocationArgs, 'lang'>
    >;
    propertyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['ScheduleEventType'], ParentType, ContextType>;
    createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    companyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    officeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleEventVisitResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ScheduleEventVisit'] = ResolversParentTypes['ScheduleEventVisit']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    estateLocation?: Resolver<
        Maybe<ResolversTypes['String']>,
        ParentType,
        ContextType,
        RequireFields<ScheduleEventVisitEstateLocationArgs, 'lang'>
    >;
    propertyId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    type?: Resolver<ResolversTypes['ScheduleEventType'], ParentType, ContextType>;
    createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    attendees?: Resolver<Maybe<ReadonlyArray<ResolversTypes['ScheduleEventAttendee']>>, ParentType, ContextType>;
    description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    organizerId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    status?: Resolver<Maybe<ResolversTypes['VisitStatus']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScheduleVisitPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['ScheduleVisitPayload'] = ResolversParentTypes['ScheduleVisitPayload']
> = {
    success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    contact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendVendorReportPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['SendVendorReportPayload'] = ResolversParentTypes['SendVendorReportPayload']
> = {
    reportId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    vendor?: Resolver<Maybe<ResolversTypes['Vendor']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMembersConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['TeamMembersConnection'] = ResolversParentTypes['TeamMembersConnection']
> = {
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['User']>, ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['TeamMembersConnectionEdge']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TeamMembersConnectionEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['TeamMembersConnectionEdge'] = ResolversParentTypes['TeamMembersConnectionEdge']
> = {
    node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TranslatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['TranslatePayload'] = ResolversParentTypes['TranslatePayload']
> = {
    outputLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TwitterPublicationConfigResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['TwitterPublicationConfig'] = ResolversParentTypes['TwitterPublicationConfig']
> = {
    showPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    showLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TwitterPublicationSettingsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['TwitterPublicationSettings'] = ResolversParentTypes['TwitterPublicationSettings']
> = {
    publishPrice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    publishLocation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    tweetText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnarchiveEstatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UnarchiveEstatePayload'] = ResolversParentTypes['UnarchiveEstatePayload']
> = {
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnassignBuyersToEstatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UnassignBuyersToEstatePayload'] = ResolversParentTypes['UnassignBuyersToEstatePayload']
> = {
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnassignLegalEntityToPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UnassignLegalEntityToPayload'] = ResolversParentTypes['UnassignLegalEntityToPayload']
> = {
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnassignVendorsToEstatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UnassignVendorsToEstatePayload'] = ResolversParentTypes['UnassignVendorsToEstatePayload']
> = {
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UnitEdge'] = ResolversParentTypes['UnitEdge']
> = {
    node?: Resolver<ResolversTypes['Estate'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitPublicationErrorsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UnitPublicationErrors'] = ResolversParentTypes['UnitPublicationErrors']
> = {
    unitId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    errors?: Resolver<ReadonlyArray<ResolversTypes['EstatePublicationError']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitPublicationWarningsResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UnitPublicationWarnings'] = ResolversParentTypes['UnitPublicationWarnings']
> = {
    unitId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    warnings?: Resolver<ReadonlyArray<ResolversTypes['EstatePublicationWarning']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnitsConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UnitsConnection'] = ResolversParentTypes['UnitsConnection']
> = {
    edges?: Resolver<ReadonlyArray<ResolversTypes['UnitEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['Estate']>, ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateContactAssignmentsPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UpdateContactAssignmentsPayload'] = ResolversParentTypes['UpdateContactAssignmentsPayload']
> = {
    success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateContactMutationPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UpdateContactMutationPayload'] = ResolversParentTypes['UpdateContactMutationPayload']
> = {
    success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    contact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
    validationErrors?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateEstatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UpdateEstatePayload'] = ResolversParentTypes['UpdateEstatePayload']
> = {
    userErrors?: Resolver<ReadonlyArray<ResolversTypes['UserError']>, ParentType, ContextType>;
    estate?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateLeadPreferencePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UpdateLeadPreferencePayload'] = ResolversParentTypes['UpdateLeadPreferencePayload']
> = {
    userErrors?: Resolver<ReadonlyArray<ResolversTypes['UserError']>, ParentType, ContextType>;
    preference?: Resolver<Maybe<ResolversTypes['BuyerPreference']>, ParentType, ContextType>;
    success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateLocationPreferencePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UpdateLocationPreferencePayload'] = ResolversParentTypes['UpdateLocationPreferencePayload']
> = {
    locationPreference?: Resolver<ResolversTypes['LocationPreference'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateLocationTemplatePayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UpdateLocationTemplatePayload'] = ResolversParentTypes['UpdateLocationTemplatePayload']
> = {
    locationTemplate?: Resolver<ResolversTypes['LocationPreference'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdatePropertyVisibilityPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UpdatePropertyVisibilityPayload'] = ResolversParentTypes['UpdatePropertyVisibilityPayload']
> = {
    propertyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUserPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UpdateUserPayload'] = ResolversParentTypes['UpdateUserPayload']
> = {
    user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
    success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateVisitStatusPayloadResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UpdateVisitStatusPayload'] = ResolversParentTypes['UpdateVisitStatusPayload']
> = {
    success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    office?: Resolver<ResolversTypes['Office'], ParentType, ContextType>;
    email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    photo?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    integrations?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    integration?: Resolver<
        Maybe<ResolversTypes['JSON']>,
        ParentType,
        ContextType,
        RequireFields<UserIntegrationArgs, 'name'>
    >;
    calendar?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    hasCalendarIntegration?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    appointmentServiceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
    locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    bio?: Resolver<ReadonlyArray<ResolversTypes['LocalizedText']>, ParentType, ContextType>;
    role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    errorStatus?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
    pronouns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserErrorResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['UserError'] = ResolversParentTypes['UserError']
> = {
    field?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
    message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Vendor'] = ResolversParentTypes['Vendor']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
    email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<VendorPhoneArgs, never>>;
    company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    officeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    pronouns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
    notes?: Resolver<ReadonlyArray<ResolversTypes['Note']>, ParentType, ContextType>;
    subscribed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
    type?: Resolver<ResolversTypes['ContactType'], ParentType, ContextType>;
    photo?: Resolver<Maybe<ResolversTypes['ContactPhoto']>, ParentType, ContextType>;
    address?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
    estates?: Resolver<
        ResolversTypes['VendorEstatesConnection'],
        ParentType,
        ContextType,
        RequireFields<VendorEstatesArgs, never>
    >;
    createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    timeline?: Resolver<ResolversTypes['ContactTimeline'], ParentType, ContextType>;
    interactedProperties?: Resolver<
        ResolversTypes['EstatesConnection'],
        ParentType,
        ContextType,
        RequireFields<VendorInteractedPropertiesArgs, never>
    >;
    eligibleEstates?: Resolver<
        ResolversTypes['EstatesConnection'],
        ParentType,
        ContextType,
        RequireFields<VendorEligibleEstatesArgs, never>
    >;
    archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
    lastInteraction?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
    lastReportSentAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    assignedNegotiators?: Resolver<
        Maybe<ReadonlyArray<ResolversTypes['ContactAssignedNegotiator']>>,
        ParentType,
        ContextType
    >;
    offers?: Resolver<
        ReadonlyArray<ResolversTypes['Offer']>,
        ParentType,
        ContextType,
        RequireFields<VendorOffersArgs, 'archived'>
    >;
    offersStats?: Resolver<Maybe<ResolversTypes['OffersStats']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorEstatesConnectionResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['VendorEstatesConnection'] = ResolversParentTypes['VendorEstatesConnection']
> = {
    totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
    edges?: Resolver<ReadonlyArray<ResolversTypes['VendorEstatesConnectionEdge']>, ParentType, ContextType>;
    nodes?: Resolver<ReadonlyArray<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorEstatesConnectionEdgeResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['VendorEstatesConnectionEdge'] = ResolversParentTypes['VendorEstatesConnectionEdge']
> = {
    node?: Resolver<ResolversTypes['Estate'], ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VendorReportEmailedActivityResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['VendorReportEmailedActivity'] = ResolversParentTypes['VendorReportEmailedActivity']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    timestamp?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
    details?: Resolver<Maybe<ResolversTypes['ActivityDetails']>, ParentType, ContextType>;
    action?: Resolver<Maybe<ResolversTypes['ActivityAction']>, ParentType, ContextType>;
    sentBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
    sentTo?: Resolver<ResolversTypes['Vendor'], ParentType, ContextType>;
    about?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['Visit'] = ResolversParentTypes['Visit']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    property?: Resolver<Maybe<ResolversTypes['Estate']>, ParentType, ContextType>;
    duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
    organizer?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
    attendees?: Resolver<Maybe<ReadonlyArray<Maybe<ResolversTypes['Contact']>>>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VisitScheduledActivityResolvers<
    ContextType = any,
    ParentType extends ResolversParentTypes['VisitScheduledActivity'] = ResolversParentTypes['VisitScheduledActivity']
> = {
    id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
    timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
    visit?: Resolver<ResolversTypes['Visit'], ParentType, ContextType>;
    details?: Resolver<Maybe<ResolversTypes['ActivityDetails']>, ParentType, ContextType>;
    action?: Resolver<Maybe<ResolversTypes['ActivityAction']>, ParentType, ContextType>;
    attendee?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
    __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
    AccountPublicationSettings?: AccountPublicationSettingsResolvers<ContextType>;
    Activity?: ActivityResolvers<ContextType>;
    ActivityConnection?: ActivityConnectionResolvers<ContextType>;
    ActivityDetails?: ActivityDetailsResolvers<ContextType>;
    ActivityEdge?: ActivityEdgeResolvers<ContextType>;
    AddContactMutationPayload?: AddContactMutationPayloadResolvers<ContextType>;
    AddEstatePayload?: AddEstatePayloadResolvers<ContextType>;
    AddIntegrationPayload?: AddIntegrationPayloadResolvers<ContextType>;
    AddLeadPreferencePayload?: AddLeadPreferencePayloadResolvers<ContextType>;
    AddLocationPreferencePayload?: AddLocationPreferencePayloadResolvers<ContextType>;
    AddLocationTemplatePayload?: AddLocationTemplatePayloadResolvers<ContextType>;
    AddUnitToProjectPayload?: AddUnitToProjectPayloadResolvers<ContextType>;
    ArchiveEstatePayload?: ArchiveEstatePayloadResolvers<ContextType>;
    ArchiveLeadsPayload?: ArchiveLeadsPayloadResolvers<ContextType>;
    AssignBuyersToEstatePayload?: AssignBuyersToEstatePayloadResolvers<ContextType>;
    AssignEstatesToNegotiatorPayload?: AssignEstatesToNegotiatorPayloadResolvers<ContextType>;
    AssignLeadsPayload?: AssignLeadsPayloadResolvers<ContextType>;
    AssignLegalEntityToPayload?: AssignLegalEntityToPayloadResolvers<ContextType>;
    AssignVendorsToEstatePayload?: AssignVendorsToEstatePayloadResolvers<ContextType>;
    AuthenticatePayload?: AuthenticatePayloadResolvers<ContextType>;
    BuyerLead?: BuyerLeadResolvers<ContextType>;
    BuyerPreference?: BuyerPreferenceResolvers<ContextType>;
    BuyerPreferenceEdge?: BuyerPreferenceEdgeResolvers<ContextType>;
    BuyerPreferenceForApartment?: BuyerPreferenceForApartmentResolvers<ContextType>;
    BuyerPreferenceForCommercial?: BuyerPreferenceForCommercialResolvers<ContextType>;
    BuyerPreferenceForHouse?: BuyerPreferenceForHouseResolvers<ContextType>;
    BuyerPreferenceForLand?: BuyerPreferenceForLandResolvers<ContextType>;
    BuyerPreferenceForOffice?: BuyerPreferenceForOfficeResolvers<ContextType>;
    BuyerPreferenceForParking?: BuyerPreferenceForParkingResolvers<ContextType>;
    BuyerPreferencesConnection?: BuyerPreferencesConnectionResolvers<ContextType>;
    Channel?: ChannelResolvers<ContextType>;
    ChannelAccount?: ChannelAccountResolvers<ContextType>;
    ChannelAccountCredentials?: ChannelAccountCredentialsResolvers<ContextType>;
    ChannelSettings?: ChannelSettingsResolvers<ContextType>;
    CloneLocationTemplatePayload?: CloneLocationTemplatePayloadResolvers<ContextType>;
    Company?: CompanyResolvers<ContextType>;
    CompanyAdminConnectionEdge?: CompanyAdminConnectionEdgeResolvers<ContextType>;
    CompanyAdminsConnection?: CompanyAdminsConnectionResolvers<ContextType>;
    CompanyLeadConnectionEdge?: CompanyLeadConnectionEdgeResolvers<ContextType>;
    CompanyLeadsConnection?: CompanyLeadsConnectionResolvers<ContextType>;
    CompanyTeam?: CompanyTeamResolvers<ContextType>;
    CompanyTeamHeadNegotiator?: CompanyTeamHeadNegotiatorResolvers<ContextType>;
    CompanyTeamResponse?: CompanyTeamResponseResolvers<ContextType>;
    CompanyTeamSettings?: CompanyTeamSettingsResolvers<ContextType>;
    CompanyTeamsMeta?: CompanyTeamsMetaResolvers<ContextType>;
    CompanyTeamsMetaPagination?: CompanyTeamsMetaPaginationResolvers<ContextType>;
    CompanyTeamsResponse?: CompanyTeamsResponseResolvers<ContextType>;
    Contact?: ContactResolvers<ContextType>;
    ContactAssignedNegotiator?: ContactAssignedNegotiatorResolvers<ContextType>;
    ContactCalledActivity?: ContactCalledActivityResolvers<ContextType>;
    ContactConnection?: ContactConnectionResolvers<ContextType>;
    ContactEdge?: ContactEdgeResolvers<ContextType>;
    ContactEmailedActivity?: ContactEmailedActivityResolvers<ContextType>;
    ContactMessagedActivity?: ContactMessagedActivityResolvers<ContextType>;
    ContactPhoto?: ContactPhotoResolvers<ContextType>;
    ContactReference?: ContactReferenceResolvers<ContextType>;
    ContactReferenceAssignee?: ContactReferenceAssigneeResolvers<ContextType>;
    ContactReferenceTeam?: ContactReferenceTeamResolvers<ContextType>;
    ContactRequest?: ContactRequestResolvers<ContextType>;
    ContactRequestConnection?: ContactRequestConnectionResolvers<ContextType>;
    ContactRequestConnectionEdge?: ContactRequestConnectionEdgeResolvers<ContextType>;
    ContactsOptions?: ContactsOptionsResolvers<ContextType>;
    ContactsSortOption?: ContactsSortOptionResolvers<ContextType>;
    ContactStats?: ContactStatsResolvers<ContextType>;
    ContactTimeline?: ContactTimelineResolvers<ContextType>;
    Currency?: GraphQLScalarType;
    DateTime?: GraphQLScalarType;
    DisplayableError?: DisplayableErrorResolvers<ContextType>;
    DuplicateEstatePayload?: DuplicateEstatePayloadResolvers<ContextType>;
    Estate?: EstateResolvers<ContextType>;
    EstateAgentFeedsPublicationConfig?: EstateAgentFeedsPublicationConfigResolvers<ContextType>;
    EstateAgentFeedsPublicationSettings?: EstateAgentFeedsPublicationSettingsResolvers<ContextType>;
    EstateAggregations?: EstateAggregationsResolvers<ContextType>;
    EstateChannelAccountConnection?: EstateChannelAccountConnectionResolvers<ContextType>;
    EstateChannelAccountConnectionEdge?: EstateChannelAccountConnectionEdgeResolvers<ContextType>;
    EstateConfigForChannelAccount?: EstateConfigForChannelAccountResolvers<ContextType>;
    EstateConnectionEdge?: EstateConnectionEdgeResolvers<ContextType>;
    EstateContactConnection?: EstateContactConnectionResolvers<ContextType>;
    EstateContactEdge?: EstateContactEdgeResolvers<ContextType>;
    EstateMatchesConnection?: EstateMatchesConnectionResolvers<ContextType>;
    EstateMatchesConnectionEdge?: EstateMatchesConnectionEdgeResolvers<ContextType>;
    EstatePublication?: EstatePublicationResolvers<ContextType>;
    EstatePublicationConfig?: EstatePublicationConfigResolvers<ContextType>;
    EstatePublicationError?: EstatePublicationErrorResolvers<ContextType>;
    EstatePublicationErrors?: EstatePublicationErrorsResolvers<ContextType>;
    EstatePublications?: EstatePublicationsResolvers<ContextType>;
    EstatePublicationSettingsResponse?: EstatePublicationSettingsResponseResolvers<ContextType>;
    EstatePublicationWarning?: EstatePublicationWarningResolvers<ContextType>;
    EstatePublicationWarnings?: EstatePublicationWarningsResolvers<ContextType>;
    EstatesConnection?: EstatesConnectionResolvers<ContextType>;
    EstatesOptions?: EstatesOptionsResolvers<ContextType>;
    EstatesSortOption?: EstatesSortOptionResolvers<ContextType>;
    FacebookPublicationConfig?: FacebookPublicationConfigResolvers<ContextType>;
    FacebookPublicationSettings?: FacebookPublicationSettingsResolvers<ContextType>;
    FeedbackActivityDetails?: FeedbackActivityDetailsResolvers<ContextType>;
    FinancialDetails?: FinancialDetailsResolvers<ContextType>;
    FrenchPortal?: FrenchPortalResolvers<ContextType>;
    FrenchPortalPublicationConfig?: FrenchPortalPublicationConfigResolvers<ContextType>;
    FrenchPortalPublicationSettings?: FrenchPortalPublicationSettingsResolvers<ContextType>;
    GenerateDescriptionPayload?: GenerateDescriptionPayloadResolvers<ContextType>;
    GenericAccountPublicationSettings?: GenericAccountPublicationSettingsResolvers<ContextType>;
    GenericPublicationConfig?: GenericPublicationConfigResolvers<ContextType>;
    GeoJSONData?: GeoJsonDataResolvers<ContextType>;
    GeoJSONLocationPreference?: GeoJsonLocationPreferenceResolvers<ContextType>;
    GeoTemplate?: GeoTemplateResolvers<ContextType>;
    GeoTemplatesDeletePayload?: GeoTemplatesDeletePayloadResolvers<ContextType>;
    GeoTemplatesPagination?: GeoTemplatesPaginationResolvers<ContextType>;
    GeoTemplatesPayload?: GeoTemplatesPayloadResolvers<ContextType>;
    GetFrenchPortalsPayload?: GetFrenchPortalsPayloadResolvers<ContextType>;
    HistogramBin?: HistogramBinResolvers<ContextType>;
    ImmowebPublicationConfig?: ImmowebPublicationConfigResolvers<ContextType>;
    ImmowebPublicationSettings?: ImmowebPublicationSettingsResolvers<ContextType>;
    JSON?: GraphQLScalarType;
    Label?: LabelResolvers<ContextType>;
    LabelsMeta?: LabelsMetaResolvers<ContextType>;
    LabelsPagination?: LabelsPaginationResolvers<ContextType>;
    LabelsPayload?: LabelsPayloadResolvers<ContextType>;
    Lead?: LeadResolvers<ContextType>;
    LeadExpressedInterestActivity?: LeadExpressedInterestActivityResolvers<ContextType>;
    LeadSource?: LeadSourceResolvers<ContextType>;
    LegalEntities?: LegalEntitiesResolvers<ContextType>;
    LegalEntitiesConnection?: LegalEntitiesConnectionResolvers<ContextType>;
    LegalEntitiesDeletePayload?: LegalEntitiesDeletePayloadResolvers<ContextType>;
    LegalEntitiesEdge?: LegalEntitiesEdgeResolvers<ContextType>;
    LegalEntity?: LegalEntityResolvers<ContextType>;
    LocalizedText?: LocalizedTextResolvers<ContextType>;
    LocationPreference?: LocationPreferenceResolvers<ContextType>;
    LocationPreferenceConnection?: LocationPreferenceConnectionResolvers<ContextType>;
    LocationPreferenceEdge?: LocationPreferenceEdgeResolvers<ContextType>;
    MatchingConfigPayload?: MatchingConfigPayloadResolvers<ContextType>;
    MatchingEmailsPayload?: MatchingEmailsPayloadResolvers<ContextType>;
    MatchingFlagsPayload?: MatchingFlagsPayloadResolvers<ContextType>;
    MatchingFlagsPayloadData?: MatchingFlagsPayloadDataResolvers<ContextType>;
    MatchingRequirementsPayload?: MatchingRequirementsPayloadResolvers<ContextType>;
    MatchingRequirementsPayloadData?: MatchingRequirementsPayloadDataResolvers<ContextType>;
    MessageActivityDetails?: MessageActivityDetailsResolvers<ContextType>;
    MissingField?: MissingFieldResolvers<ContextType>;
    MissingFieldsConnection?: MissingFieldsConnectionResolvers<ContextType>;
    MissingFieldsEdge?: MissingFieldsEdgeResolvers<ContextType>;
    Mutation?: MutationResolvers<ContextType>;
    Note?: NoteResolvers<ContextType>;
    Offer?: OfferResolvers<ContextType>;
    OfferBuyer?: OfferBuyerResolvers<ContextType>;
    OfferOwner?: OfferOwnerResolvers<ContextType>;
    OffersStats?: OffersStatsResolvers<ContextType>;
    Office?: OfficeResolvers<ContextType>;
    OfficeSettings?: OfficeSettingsResolvers<ContextType>;
    OldUnitsMissingFields?: OldUnitsMissingFieldsResolvers<ContextType>;
    PageInfo?: PageInfoResolvers<ContextType>;
    PendingRequest?: PendingRequestResolvers<ContextType>;
    PendingRequestEdge?: PendingRequestEdgeResolvers<ContextType>;
    PendingRequestsConnection?: PendingRequestsConnectionResolvers<ContextType>;
    PostalCodesData?: PostalCodesDataResolvers<ContextType>;
    PostalCodesLocationPreference?: PostalCodesLocationPreferenceResolvers<ContextType>;
    PreferenceBudget?: PreferenceBudgetResolvers<ContextType>;
    PriceAggregation?: PriceAggregationResolvers<ContextType>;
    PropertyReference?: PropertyReferenceResolvers<ContextType>;
    PropertyReferenceAssignee?: PropertyReferenceAssigneeResolvers<ContextType>;
    PropertyReferenceTeam?: PropertyReferenceTeamResolvers<ContextType>;
    PropertyStats?: PropertyStatsResolvers<ContextType>;
    PropertyStatus?: PropertyStatusResolvers<ContextType>;
    PropertyTimeline?: PropertyTimelineResolvers<ContextType>;
    PropertyVisitedActivity?: PropertyVisitedActivityResolvers<ContextType>;
    PublicationErrorContext?: PublicationErrorContextResolvers<ContextType>;
    PublicationWarningContext?: PublicationWarningContextResolvers<ContextType>;
    PublishPropertyPayload?: PublishPropertyPayloadResolvers<ContextType>;
    Query?: QueryResolvers<ContextType>;
    RefreshPublicationPayload?: RefreshPublicationPayloadResolvers<ContextType>;
    RegisterEstateActivityPayload?: RegisterEstateActivityPayloadResolvers<ContextType>;
    RemoveIntegrationPayload?: RemoveIntegrationPayloadResolvers<ContextType>;
    RemoveLeadPreferencePayload?: RemoveLeadPreferencePayloadResolvers<ContextType>;
    RemoveLocationPayload?: RemoveLocationPayloadResolvers<ContextType>;
    RemoveLocationTemplatePayload?: RemoveLocationTemplatePayloadResolvers<ContextType>;
    RetryPublicationPayload?: RetryPublicationPayloadResolvers<ContextType>;
    ScheduleCount?: ScheduleCountResolvers<ContextType>;
    ScheduleEvent?: ScheduleEventResolvers<ContextType>;
    ScheduleEventAttendee?: ScheduleEventAttendeeResolvers<ContextType>;
    ScheduleEventAuction?: ScheduleEventAuctionResolvers<ContextType>;
    ScheduleEventOpenHome?: ScheduleEventOpenHomeResolvers<ContextType>;
    ScheduleEventVisit?: ScheduleEventVisitResolvers<ContextType>;
    ScheduleVisitPayload?: ScheduleVisitPayloadResolvers<ContextType>;
    SendVendorReportPayload?: SendVendorReportPayloadResolvers<ContextType>;
    TeamMembersConnection?: TeamMembersConnectionResolvers<ContextType>;
    TeamMembersConnectionEdge?: TeamMembersConnectionEdgeResolvers<ContextType>;
    TranslatePayload?: TranslatePayloadResolvers<ContextType>;
    TwitterPublicationConfig?: TwitterPublicationConfigResolvers<ContextType>;
    TwitterPublicationSettings?: TwitterPublicationSettingsResolvers<ContextType>;
    UnarchiveEstatePayload?: UnarchiveEstatePayloadResolvers<ContextType>;
    UnassignBuyersToEstatePayload?: UnassignBuyersToEstatePayloadResolvers<ContextType>;
    UnassignLegalEntityToPayload?: UnassignLegalEntityToPayloadResolvers<ContextType>;
    UnassignVendorsToEstatePayload?: UnassignVendorsToEstatePayloadResolvers<ContextType>;
    UnitEdge?: UnitEdgeResolvers<ContextType>;
    UnitPublicationErrors?: UnitPublicationErrorsResolvers<ContextType>;
    UnitPublicationWarnings?: UnitPublicationWarningsResolvers<ContextType>;
    UnitsConnection?: UnitsConnectionResolvers<ContextType>;
    UpdateContactAssignmentsPayload?: UpdateContactAssignmentsPayloadResolvers<ContextType>;
    UpdateContactMutationPayload?: UpdateContactMutationPayloadResolvers<ContextType>;
    UpdateEstatePayload?: UpdateEstatePayloadResolvers<ContextType>;
    UpdateLeadPreferencePayload?: UpdateLeadPreferencePayloadResolvers<ContextType>;
    UpdateLocationPreferencePayload?: UpdateLocationPreferencePayloadResolvers<ContextType>;
    UpdateLocationTemplatePayload?: UpdateLocationTemplatePayloadResolvers<ContextType>;
    UpdatePropertyVisibilityPayload?: UpdatePropertyVisibilityPayloadResolvers<ContextType>;
    UpdateUserPayload?: UpdateUserPayloadResolvers<ContextType>;
    UpdateVisitStatusPayload?: UpdateVisitStatusPayloadResolvers<ContextType>;
    User?: UserResolvers<ContextType>;
    UserError?: UserErrorResolvers<ContextType>;
    Vendor?: VendorResolvers<ContextType>;
    VendorEstatesConnection?: VendorEstatesConnectionResolvers<ContextType>;
    VendorEstatesConnectionEdge?: VendorEstatesConnectionEdgeResolvers<ContextType>;
    VendorReportEmailedActivity?: VendorReportEmailedActivityResolvers<ContextType>;
    Visit?: VisitResolvers<ContextType>;
    VisitScheduledActivity?: VisitScheduledActivityResolvers<ContextType>;
};

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;
