import { call, put } from 'redux-saga/effects';
import { resetPagination, resetCompanyAdmins, fetchCompany } from '../../actions';
import CompaniesRequest from '../../../requests/CompaniesRequests';
import { formatInviteErrors } from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';

export default function* onInviteCompanyAdmins({ attributes: { resolve, reject, invites, language, companyId } }) {
    try {
        yield apiCall(new CompaniesRequest().inviteAdmin, companyId, {
            invites,
            language,
        });

        yield [
            put(resetPagination('company/admins')),
            put(resetCompanyAdmins(companyId)),
            put(fetchCompany(companyId)),
        ];

        yield call(resolve);
    } catch (error) {
        yield call(reject, formatInviteErrors(invites, error));
    }
}
