import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { OfficeGeoContext } from '../context';

const OfficeGeoDelete = () => {
    const intl = useIntl();

    const {
        refetch,
        activeEntity,
        setActiveEntity,
        setVisible,
        visibleDelete,
        setVisibleDelete,
        deleteGeoTemplate,
    } = useContext(OfficeGeoContext);

    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        setVisibleDelete(false);
        setVisible(activeEntity?.type || null);
    };

    const handleSubmit = () => {
        setLoading(true);

        deleteGeoTemplate({
            variables: {
                officeId: activeEntity?.office_id,
                input: { _id: activeEntity?._id, title: activeEntity?.title },
            },
        }).finally(() => {
            refetch();

            setLoading(false);
            setVisibleDelete(false);
            setVisible(null);
            setActiveEntity(undefined);
        });
    };

    const content = {
        title: intl.formatMessage({ id: 'office.geo.delete.title' }),
        text: intl.formatMessage({ id: 'office.geo.delete.text' }),
        cancel: intl.formatMessage({ id: `general.action.cancel` }),
        delete: intl.formatMessage({ id: `general.action.delete` }),
    };

    const isDisabled = loading;

    return (
        <Modal show={visibleDelete} onHide={() => {}}>
            <Modal.Header>
                <Modal.Title>{content.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{content.text}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCancel} disabled={isDisabled}>
                    {content.cancel}
                </Button>
                <Button variant="danger" onClick={handleSubmit} disabled={isDisabled}>
                    {content.delete}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OfficeGeoDelete;
