import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List, Map, OrderedSet } from 'immutable';
import { createStructuredSelector } from 'reselect';
import { getUser } from '@/app.redux/selectors/UsersSelectors';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import { isCompanyAdmin } from '@/app.data';
import { getCompany, getOffices, getPlans, getStatus } from '@/app.redux/selectors';
import { fetchCompany, fetchPlans, fetchCompanyCard } from '@/app.redux/actions';
import CompanyAside from '@/app.components/asides/CompanyAside';

const CompanyLayout = ({
    company = Map(),
    offices,
    user,
    location,
    children,
    status,
    plans,
    fetchPlans,
    fetchCompanyCard,
}) => {
    const isSubscriptionPage = location.pathname.includes('/subscription');

    React.useEffect(() => {
        fetchCompany();

        if (isSubscriptionPage) {
            if (plans?.isEmpty()) {
                fetchPlans(company?.get('id'));
            }

            fetchCompanyCard(company?.get('id'));
        }
    }, [company, plans, fetchPlans, fetchCompanyCard, isSubscriptionPage]);

    return (
        <LayoutContainer>
            <CompanyAside
                isAdmin={isCompanyAdmin(user)}
                company={company}
                offices={offices}
                location={location.pathname}
            />

            <LayoutColumn>
                {company.has('id') &&
                    React.isValidElement(children) &&
                    React.cloneElement(children, {
                        company,
                        status,
                        plans,
                    })}
            </LayoutColumn>
        </LayoutContainer>
    );
};

CompanyLayout.propTypes = {
    children: PropTypes.node,
    company: PropTypes.instanceOf(Map),
    location: PropTypes.object.isRequired,
    offices: PropTypes.instanceOf(OrderedSet),
    user: PropTypes.instanceOf(Map),
    router: PropTypes.object.isRequired,
    plans: PropTypes.instanceOf(List),
    status: PropTypes.instanceOf(Map),
    fetchCompanyCard: PropTypes.func,
    fetchPlans: PropTypes.func,
};

export default connect(
    createStructuredSelector({
        company: getCompany,
        offices: getOffices,
        user: getUser,
        plans: getPlans,
        status: getStatus,
    }),
    { fetchCompany, fetchPlans, fetchCompanyCard },
)(CompanyLayout);
