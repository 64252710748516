import { paramCase } from 'change-case';
import * as queryString from 'querystring';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Dispatch } from 'redux';
import { change as changeField, reset, stopSubmit } from 'redux-form';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl-sweepbright';
import Button from '@/app.components/elements/Buttons/Button';
import weInvestLogo from '@/../../resources/assets/img/testimonials/we-invest.png';
import jonathanPicture from '@/../../resources/assets/img/testimonials/jonathan.jpg';
import logo from '@/../../resources/assets/img/logo-black.svg';
import AuthRequests from '../../../requests/AuthRequests';
import formatApiErrors from '../../../app.redux/sagas/Helpers/formatApiErrors';
import { SuccessAlert } from '../../../app.components/elements/Alerts/Alerts';
import CountrySelector from '../../../app.components/forms/CountrySelector';
import LanguageSelector from '../../../app.components/forms/LanguageSelector';
import Icon from '../../../app.components/icons/Icon';
import Input from '../../../app.components/forms/Input/Input';
import { useSelectLocale } from '../../../app.components/localize/Localized';

type InputProps = {
    value: string;
    onChange: (evtOrValue: React.ChangeEvent<HTMLInputElement> | string | boolean) => void;
    valid?: boolean;
};

type Props = {
    loginUrl: string;
    success: boolean;
    enterprise?: string;
    dispatch: Dispatch<any>;
    fields: {
        email: InputProps;
        first_name: InputProps;
        company_name: InputProps;
        country: InputProps;
        last_name: InputProps;
        locale: InputProps;
    };
    submitting: boolean;
    submitFailed: boolean;
    handleSubmit: () => void;
    valid: boolean;
    selectLocale: ReturnType<typeof useSelectLocale>;
};

type State = {
    startedRegistering: boolean;
    isCheckingUniqueness: boolean;
    error: null | string;
};

class RegisterForm extends Component<Props, State> {
    state = {
        startedRegistering: false,
        isCheckingUniqueness: false,
        error: null,
    };

    componentDidMount() {
        const { referrer = '' } = queryString.parse(location.search) as { referrer: string | undefined };
        this.props.dispatch(changeField('register', 'referrer', referrer));
    }

    hasCompletedRegistration() {
        return this.props.success && !this.props.submitting && !this.props.submitFailed;
    }

    onContinueRegistration = () => {
        // Reset form to cancel out failed state if needed
        const email = this.props.fields.email.value;
        this.props.dispatch(reset('register'));
        this.props.dispatch(changeField('register', 'email', email));

        this.setState({
            isCheckingUniqueness: false,
            startedRegistering: true,
        });
    };
    /**
     * Do a preliminary request to check uniqueness of email
     */
    handleStartRegistration = async evt => {
        evt.preventDefault();
        this.setState({ isCheckingUniqueness: true, error: null });

        try {
            await new AuthRequests().register({ email: this.props.fields.email.value });
            this.onContinueRegistration();
        } catch (errorResponse) {
            this.setState({ isCheckingUniqueness: false });
            const errors = formatApiErrors(errorResponse);

            if (errors.email) {
                this.props.dispatch(
                    stopSubmit('register', {
                        email: errors.email,
                    }),
                );
            } else if (errors._error) {
                this.setState({ error: errors._error as string });

                return;
            } else {
                // when using this endpoint
                // for registering, it also returns
                // errors for the other fields, like name, company name, etc
                // we just ignore them at the moment

                // this is not a good approach
                // but at this time we don't have a
                // dedicate endpoint to validate the email
                this.onContinueRegistration();
            }
        }
    };

    renderSubmitBtnText = () => {
        if (this.state.isCheckingUniqueness) {
            return (
                <FormattedMessage
                    id="forms.register.button.submitting"
                    defaultMessage="Submitting..."
                    tagName={React.Fragment}
                />
            );
        }

        return (
            <FormattedMessage
                id="forms.register.button.start_now"
                defaultMessage="Start Now"
                tagName={React.Fragment}
            />
        );
    };

    handleChangeLocale = (lang: string) => {
        this.props.selectLocale(lang);
        this.props.fields.locale.onChange(lang);
    };

    renderForm() {
        const { fields, handleSubmit, submitting, valid, submitFailed } = this.props;

        if (!this.state.startedRegistering) {
            return (
                <form onSubmit={this.handleStartRegistration}>
                    {this.state.error && <Alert bsStyle="danger">{this.state.error}</Alert>}
                    <Input
                        withIcon={<Icon icon="mail" />}
                        type="email"
                        placeholder={<FormattedMessage id="forms.register.labels.email" defaultMessage="Work E-mail" />}
                        {...fields.email}
                        //@ts-ignore
                        error={submitFailed && fields.email.error}
                        autoFocus
                    />
                    <Button
                        variant="primary"
                        type="submit"
                        className="btn-block onboarding-submit"
                        disabled={!fields.email.valid || this.state.isCheckingUniqueness}
                        data-testid="submit_btn"
                    >
                        {this.renderSubmitBtnText()}
                    </Button>
                    <p className="onboarding-toc">
                        <FormattedHTMLMessage
                            id="forms.register.legal_notice"
                            values={{
                                tocUrl: 'https://sweepbright.com/terms-of-service/',
                                gdprUrl: 'https://sweepbright.com/terms-of-service/#dataprocessing',
                            }}
                            defaultMessage={`By clicking "Start Now" you agree with our
                              <a href="{tocUrl}" rel="nofollow noreferrer" target="_blank"&gt;General Terms &amp; \
                              Conditions of Service</a>
                              and you acknowledge having read the
                              <a href="{gdprUrl}" rel="nofollow noreferrer" target="_blank">Data Processing and \
                              Personal Data protection clause</a>.`}
                        />
                    </p>
                </form>
            );
        }

        return (
            <form onSubmit={handleSubmit}>
                {!valid && submitFailed && (
                    <Alert bsStyle="danger">
                        <FormattedMessage
                            id="forms.register.alerts.errors"
                            defaultMessage={"Looks like something's wrong. Please correct the highlighted fields."}
                            tagName="p"
                        />
                    </Alert>
                )}
                <div className="onboarding-flex-grid">
                    <div className="onboarding-flex-column">
                        <Input
                            type="text"
                            label={<FormattedMessage id="forms.profile.first_name" defaultMessage="First Name" />}
                            {...fields.first_name}
                            autoFocus
                            touched={submitFailed}
                            name="first_name"
                        />
                    </div>
                    <div className="onboarding-flex-column">
                        <Input
                            type="text"
                            label={<FormattedMessage id="forms.profile.last_name" defaultMessage="Last Name" />}
                            {...fields.last_name}
                            touched={submitFailed}
                            name="last_name"
                        />
                    </div>
                </div>
                <Input
                    type="text"
                    label={<FormattedMessage id="forms.register.labels.company_name" defaultMessage="Agency Name" />}
                    {...fields.company_name}
                    touched={submitFailed}
                    name="company_name"
                />
                {fields.company_name.value && (
                    <div className="onboarding-balloon">
                        <FormattedHTMLMessage
                            id="forms.register.url"
                            defaultMessage="Your agency website will be https://<strong>{name}</strong>.agency.re"
                            values={{
                                name: paramCase(fields.company_name.value),
                            }}
                        />
                    </div>
                )}
                <CountrySelector
                    {...fields.country}
                    touched={submitFailed}
                    variant="lg"
                    label={<FormattedMessage id="location.country" defaultMessage="Country" />}
                />
                <LanguageSelector
                    {...fields.locale}
                    onChange={this.handleChangeLocale}
                    touched={submitFailed}
                    variant="lg"
                    label={<FormattedMessage id="general.language.title" defaultMessage="Language" />}
                />
                <Button
                    type="submit"
                    variant="primary"
                    className="onboarding-submit"
                    disabled={submitting || this.hasCompletedRegistration()}
                >
                    {submitting ? (
                        <FormattedMessage id="forms.register.cta.progress" defaultMessage="Thank You..." />
                    ) : (
                        <FormattedMessage id="forms.register.cta" defaultMessage="Let's Go!" />
                    )}
                </Button>
                <p className="onboarding-toc">
                    <FormattedHTMLMessage
                        id="forms.register.terms"
                        defaultMessage="By clicking &quot;Let\'s Go!&quot; you agree with our \
                        <a href='https://sweepbright.com/terms-of-service/' rel='nofollow noreferrer'  target='_blank'>\
                        General Terms &amp; Conditions of Service</a>."
                    />
                </p>
            </form>
        );
    }

    renderSuccessMessage() {
        return (
            <div>
                <SuccessAlert
                    borderless
                    title={
                        <FormattedMessage
                            id="forms.register.alerts.success.title"
                            defaultMessage="Sign Up Successful"
                        />
                    }
                    body={
                        <FormattedHTMLMessage
                            id="forms.register.alerts.success.body"
                            defaultMessage="We will now log you in to SweepBright."
                        />
                    }
                />
                <p className="text-center">
                    <LinkContainer to={this.props.loginUrl}>
                        <Button variant="primary" className="onboarding-submit">
                            <FormattedMessage id="general.action.login" defaultMessage="Login" />
                        </Button>
                    </LinkContainer>
                </p>
            </div>
        );
    }

    renderTestimonials() {
        return (
            <div className="c-testimonial">
                <p className="c-testimonial__byline">
                    <img src={jonathanPicture} width={64} height={64} alt="Jonathan Pham avatar" />
                    Jonathan Pham, We Invest
                </p>
                <blockquote className="c-testimonial__text">
                    <FormattedMessage
                        id="register.testimonial.body"
                        defaultMessage="This app is everything you need as an agent, in one place. It's user-friendly; you simply need the
                    tap of a button to get things going. It does lots of clever things which our agents will greatly
                    benefit from."
                    />
                </blockquote>
                <p className="c-testimonial__byline">
                    <img src={weInvestLogo} width={64} height={64} alt="WeInvest Logo" />
                </p>
            </div>
        );
    }

    render() {
        const hasStartedRegistering = this.state.startedRegistering;

        return (
            <div className="onboarding-container">
                <div className="onboarding-header">
                    <img
                        //@ts-ignore
                        src={logo}
                        className="onboarding-logo"
                        alt="onboarding logo"
                    />
                    {!hasStartedRegistering && (
                        <div className="container">
                            <h1 className="onboarding-title">
                                <FormattedMessage id="register.title" defaultMessage="Let's get started" />
                            </h1>
                            <p className="onboarding-subtitle">
                                {this.props.enterprise ? null : (
                                    <FormattedMessage
                                        id="forms.register.subtitle"
                                        defaultMessage="You can use SweepBright free of charge, during 15 days. {break}
                                        No credit card needed."
                                        values={{
                                            break: <br />,
                                        }}
                                    />
                                )}
                            </p>
                        </div>
                    )}
                    {hasStartedRegistering && !this.hasCompletedRegistration() && (
                        <div className="container">
                            <h1 className="onboarding-title">
                                <FormattedMessage
                                    id="register.fill_details.title"
                                    defaultMessage="You’re 10 seconds away"
                                />
                            </h1>
                            <p className="onboarding-subtitle">
                                <FormattedMessage
                                    id="register.fill_details.subtitle"
                                    defaultMessage="from having your agency up and running!"
                                />
                            </p>
                        </div>
                    )}
                </div>
                <div className="container">
                    <div className="onboarding-sheet">
                        {this.hasCompletedRegistration() ? this.renderSuccessMessage() : this.renderForm()}
                    </div>
                    {!hasStartedRegistering && this.renderTestimonials()}
                    <div className="c-spacer-l text-center onboarding-footer">
                        <FormattedHTMLMessage
                            id="copyright"
                            defaultMessage={'&copy; Copyright SweepBright NV {year}'}
                            values={{ year: new Date().getFullYear() }}
                            tagName="p"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default RegisterForm;
