import AbstractEstatesRequests from './AbstractEstatesRequests';

export default class ProjectsRequests extends AbstractEstatesRequests {
    resource = 'projects';
    units(projectId: string, params: { page?: number; limit?: number; archived?: boolean }) {
        return this.withMeta()
            .withQueryParameters(params)
            .get(`/projects/${projectId}/units`);
    }
}
