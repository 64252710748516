export default function(callback) {
    if (window.Intl) {
        callback();
    } else {
        require.ensure(['intl', 'intl/locale-data/jsonp/en.js'], require => {
            require('intl');
            require('intl/locale-data/jsonp/en.js');

            callback();
        });
    }
}
