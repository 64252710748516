import React, { createContext } from 'react';
import { SearchViewType } from '@/app.components/forms/Search/AdvancedSearch';

export const ResourceContext = createContext<{
    tabs?: any;
    error?: any;
    entities: any;
    columns?: any[];
    loading: boolean;
    officeId?: string;
    setView?: Function;
    totalCount: number;
    hideCount?: boolean;
    initialTab?: string;
    selected?: string[];
    hideSearch?: boolean;
    hasEntities: boolean;
    searchSettings?: any;
    action?: JSX.Element;
    showMapView?: boolean;
    hasNextPage?: boolean;
    filters?: JSX.Element;
    view?: SearchViewType;
    setSelected?: Function;
    listExtra?: JSX.Element;
    sortOptions?: Array<any>;
    listHeader?: JSX.Element;
    getEmptyState: () => any;
    countAction?: JSX.Element;
    handleFetchMore: Function;
    resourceClassName?: string;
    setSearchSettings: Function;
    tableWrapperHeight?: string;
    renderListCard?: (entity: any) => JSX.Element;
    content: {
        [key: string]: JSX.Element | string | undefined;
        title: JSX.Element | string;
        noEntities?: JSX.Element | string;
        errorTitle: JSX.Element | string;
        errorSubtitle?: JSX.Element | string;
        emptyTitle: JSX.Element | string;
        count?: JSX.Element | string;
    };
}>({
    columns: [],
    entities: [],
    totalCount: 0,
    loading: false,
    setView: () => {},
    hasEntities: false,
    showMapView: false,
    content: {
        title: <></>,
        count: <></>,
        noEntities: <></>,
        errorTitle: <></>,
        emptyTitle: <></>,
    },
    setSelected: () => {},
    getEmptyState: () => {},
    handleFetchMore: () => {},
    setSearchSettings: () => {},
});
