import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl-sweepbright';
import { useToasts } from '@sweepbright/notifications';
import useLocalStorage from '@/app.hooks/useLocalStorage';
import { GENERATE_DESCRIPTION, TRANSLATE_TEXT } from '@/graphql/mutations/openAI/openAIMutations';
import {
    GenerateDescriptionMutation,
    GenerateDescriptionMutationVariables,
    TranslateMutation,
    TranslateMutationVariables,
} from '@/graphql/generated/types';

type Props = {
    property: any;
    description: any;
    setVisible: Function;
    supportedLangs: string[];
    generateSettings: {
        showLocation: boolean;
        showPrice: boolean;
        tag1?: boolean;
        tag2?: boolean;
        tag3?: boolean;
        tag4?: boolean;
        tag5?: boolean;
    };
    setGenerateLoading: Function;
    setTranslateLoading: Function;
    content: { [key: string]: string };
};

export const usePropertiesDescriptionAI = (props: Props) => {
    const {
        content,
        property,
        setVisible,
        description,
        supportedLangs,
        generateSettings,
        setGenerateLoading,
        setTranslateLoading,
    } = props;

    const { addError } = useToasts();

    const [valueAILength, setValueAILength] = useLocalStorage<number>('properties.description.length', 450);

    const [generateText] = useMutation<GenerateDescriptionMutation, GenerateDescriptionMutationVariables>(
        GENERATE_DESCRIPTION,
    );

    const [translateText] = useMutation<TranslateMutation, TranslateMutationVariables>(TRANSLATE_TEXT);

    const languagesShort = {
        en: 'english',
        fr: 'french',
        nl: 'dutch',
    };

    const languagesLong = {
        english: 'en',
        french: 'fr',
        dutch: 'nl',
    };

    const handleGenerate = (fieldName: string, extraText: string) => {
        setGenerateLoading(true);

        const languageName = fieldName.replace('description.description.', '');

        const newExtraText = [
            ...(generateSettings.showPrice ? [] : [content.hidePrice]),
            ...(generateSettings.showLocation ? [] : [content.hideLocation]),
            ...(generateSettings.tag1 ? [content.tag1Text] : []),
            ...(generateSettings.tag2 ? [content.tag2Text] : []),
            ...(generateSettings.tag3 ? [content.tag3Text] : []),
            ...(generateSettings.tag4 ? [content.tag4Text] : []),
            ...(generateSettings.tag5 ? [content.tag5Text] : []),
            extraText,
        ].join('');

        const maxOutputLength = Number(valueAILength) >= 255 && Number(valueAILength) <= 1000 && Number(valueAILength);

        if (!maxOutputLength) {
            setValueAILength(255);
        }

        generateText({
            variables: {
                generateInput: {
                    extraText: newExtraText,
                    propertyId: property.get('id'),
                    maxOutputLength: maxOutputLength || 255,
                    inputLanguage: languagesShort[languageName],
                    outputLanguage: languagesShort[languageName],
                },
            },
        })
            .then(res => {
                const newDescription = res?.data?.generateDescription?.text;

                description.description[languageName].onChange(newDescription);

                setVisible('');
            })
            .catch(() => {
                addError({
                    message: (
                        <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />
                    ),
                });
            })
            .finally(() => {
                setGenerateLoading(false);
            });
    };

    const handleTranslate = (fieldName: string) => {
        setTranslateLoading(true);

        const languageName = fieldName.replace('description.description.', '');

        const arrayToChange: any[] = Object.values(description.description)
            .filter((el: any) => {
                return el.name !== fieldName;
            })
            .filter((el: any) => {
                return supportedLangs.includes(el.name.replace('description.description.', ''));
            });

        const text = description.description[languageName].value;

        Promise.all([
            translateText({
                variables: {
                    translateInput: {
                        text,
                        inputLanguage: languagesShort[languageName],
                        outputLanguage: languagesShort[arrayToChange[0].name.replace('description.description.', '')],
                    },
                },
            }),
            ...(arrayToChange[1]
                ? [
                      translateText({
                          variables: {
                              translateInput: {
                                  text,
                                  inputLanguage: languagesShort[languageName],
                                  outputLanguage:
                                      languagesShort[arrayToChange[1].name.replace('description.description.', '')],
                              },
                          },
                      }),
                  ]
                : []),
        ])
            .then(res => {
                const resArray = res.map(el => el?.data?.translate);

                resArray.forEach(el => {
                    const newDescription = el?.text;
                    const newLanguage = languagesLong[el?.outputLanguage || ''];

                    description.description[newLanguage].onChange(newDescription);
                });
            })
            .catch(() => {
                addError({
                    message: (
                        <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />
                    ),
                });
            })
            .finally(() => {
                setTranslateLoading(false);
            });
    };

    return {
        handleGenerate,
        handleTranslate,
    };
};
