import gql from 'graphql-tag';

export const UserFragment = gql`
    fragment UserFragment on User {
        id
        office {
            id
            name
            settings {
                currency
                numeralSystem
            }
        }
    }
`;
