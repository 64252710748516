import { List, Map } from 'immutable';
import { InjectedIntl } from 'react-intl-sweepbright';
import NegotiatorRepository from '../Repositories/NegotiatorRepository';

export function formatNegotiatorsList({
    negotiators = List(),
    user,
    intl,
}: {
    negotiators?: any;
    user?: Map<string, any>;
    intl?: InjectedIntl;
} = {}) {
    const negotiatorsList: List<any> = negotiators.toList();
    if (negotiatorsList.isEmpty()) {
        return negotiatorsList;
    }

    // eslint-disable-next-line eqeqeq
    const getIsCurrentUser = negotiator => negotiator.get('id') == user?.get('id');

    return negotiatorsList
        .filterNot(negotiator => negotiator.get('pending_invitation'))

        .map(negotiator => {
            const isCurrentUser = getIsCurrentUser(negotiator);

            return Map({
                label: new NegotiatorRepository(negotiator).getLabel({ isCurrentUser, intl }),
                value: negotiator.get('id'),
                raw: negotiator,
                isCurrentUser,
            });
        })

        .sortBy((negotiatorOption: Map<string, any>) => {
            // sort the entries, so the first value is the current user
            return negotiatorOption.get('isCurrentUser') ? 0 : 1;
        }) as List<any>;
}
