import React from 'react';
import moment from 'moment';
import Icon, { ICON_SIZE_SMALL, IconName } from '../../icons/Icon';
import './ActivityCard.scss';

export default function ActivityCard({
    title,
    subtitle,
    icon,
    children,
    date,
    action,
}: {
    children?: React.ReactNode;
    title: React.ReactNode;
    subtitle: React.ReactNode;
    action?: React.ReactNode;
    icon: IconName;
    date: string;
}) {
    return (
        <div className="c-activity-card">
            <div className="c-activity-card-content">
                <div className="c-activity-card-content__left">
                    <div className="c-activity-card__icon">
                        <Icon icon={icon} size={ICON_SIZE_SMALL} />
                    </div>
                    <div>
                        <div className="c-activity-card__title">{title}</div>
                        <div className="c-activity-card__subtitle text-muted">{subtitle}</div>
                        {action ? <div className="c-activity-card__action">{action}</div> : null}
                    </div>
                </div>
                <div className="c-activity-card-content__right">
                    <p className="text-muted">
                        <TimeStamp date={date} />
                    </p>
                </div>
            </div>

            {children ? <div className="c-activity-card__details">{children}</div> : null}
        </div>
    );
}

function TimeStamp({ date }: { date: string }) {
    return <span>{moment(date).format('DD MMM, HH:mm')}</span>;
}
