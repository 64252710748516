// @ts-nocheck
import React from 'react';

type MessageDescriptor = {
    id: string;
    title?: React.ReactNode;
    body: React.ReactNode;
    borderless?: boolean;
    action?: React.ReactNode;
};

// const DEFAULT_MESSAGE_DURATION_MS = 2500; // 2.5 s

const MessagesContext = React.createContext(null);

export function MessagesProvider({ children }) {
    const messagesState = React.useState<MessageDescriptor[]>([]);

    return <MessagesContext.Provider value={messagesState}>{children}</MessagesContext.Provider>;
}

export default function useMessages(): {
    messages: MessageDescriptor[];
    setMessages: (messages: MessageDescriptor[]) => void;
    setActive: (active: boolean) => void;
} {
    const [messages, setMessages] = React.useContext(MessagesContext);

    return {
        messages,
        setMessages,
        setActive: () => {},
    };
}
