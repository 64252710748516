import gql from 'graphql-tag';

export const REMOVE_LOCATION_PREFERENCE = gql`
    mutation RemoveLocationPreference($leadId: ID!, $preferenceId: ID, $locationPreferenceId: ID!) {
        removeLocationPreference(
            leadId: $leadId
            preferenceId: $preferenceId
            locationPreferenceId: $locationPreferenceId
        ) {
            id
            lead {
                id
            }
        }
    }
`;
