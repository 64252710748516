// @ts-nocheck
import React from 'react';
import { fromJS } from 'immutable';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl-sweepbright';
import PagePane from '@/app.layouts/PagePane/PagePane';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import PropertyTitle from '@/app.domains/properties/PropertyTitle';
import useRouter from '@/app.hooks/useRouter';
import { CONTACT_TIMELINE } from '@/app.routing/routes';
import { useToasts } from '@sweepbright/notifications';
import { GET_PROPERTY_QUERY } from '@/graphql/queries/properties/getProperty';
import RegisterActivityModal from '@/app.components/modals/RegisterContactActivityModal';
import RegisterActivityAction from '@/app.components/activity/RegisterActivityAction/RegisterActivityAction';
import { events, track } from '@/app.utils/analytics';
import { GetPropertyQuery, GetPropertyQueryVariables } from '@/graphql/generated/types';
import BackButton from '@/app.components/elements/Buttons/BackButton';
import { ContactTimeline } from '../../../../app.domains/contacts/timeline/components/ContactTimeline';

export default function PropertyLeadTimelinePage({ params }: { params: { contact: string; property: string } }) {
    const contactId: string = params.contact;
    const propertyId: string = params.property;
    const { addSuccess } = useToasts();

    const [visibleRegistrationModalType, setVisibleRegistrationModalType] = React.useState('');
    const timelineRef = React.useRef();

    const { data: propertyData } = useQuery<GetPropertyQuery, GetPropertyQueryVariables>(GET_PROPERTY_QUERY, {
        variables: {
            id: propertyId,
        },
        returnPartialData: true,
    });

    const router = useRouter();

    function handleRegisterActivity(type: 'visit' | 'call' | 'message' | 'mail') {
        track(events.CONTACT_ACTIVITY_REGISTER_BTN_CLICKED, { activityType: type });
        setVisibleRegistrationModalType(type);
    }

    return (
        <PagePane
            backAction={
                <BackButton
                    onClick={() =>
                        router.replace({
                            pathname: CONTACT_TIMELINE(contactId),
                            state: { fromPropertyTimeline: true },
                        })
                    }
                />
            }
            title={
                propertyData?.estate?.id ? (
                    <span>
                        <FormattedMessage
                            id="activity.with_property"
                            defaultMessage="Activity with {property}"
                            values={{
                                property: <PropertyTitle property={fromJS(propertyData.estate)} />,
                            }}
                        />
                    </span>
                ) : (
                    <FormattedMessage id="general.state.loading" defaultMessage="Loading..." />
                )
            }
            actions={[<RegisterActivityAction key="action" onRegisterActivity={handleRegisterActivity} />]}
        >
            {visibleRegistrationModalType ? (
                <RegisterActivityModal
                    show={true}
                    contactId={contactId}
                    propertyId={propertyId}
                    onClose={() => setVisibleRegistrationModalType('')}
                    type={visibleRegistrationModalType}
                    onActivityRegistered={() => {
                        /* eslint-disable no-unused-expressions */
                        timelineRef.current?.refresh();
                        /* eslint-enable no-unused-expressions */

                        addSuccess({
                            title: (
                                <FormattedMessage
                                    id={`timeline.activity_registered_success.title.${visibleRegistrationModalType}`}
                                    defaultMessage={`timeline.activity_registered_success.title.${visibleRegistrationModalType}`}
                                />
                            ),
                            message: (
                                <FormattedMessage
                                    id="timeline.activity_registered_success.confirmation"
                                    defaultMessage="The new activity will show up in the timeline"
                                />
                            ),
                        });

                        setVisibleRegistrationModalType('');
                    }}
                />
            ) : null}
            <ErrorBoundary>
                <ContactTimeline contactId={contactId} propertyId={propertyId} ref={timelineRef} />
            </ErrorBoundary>
        </PagePane>
    );
}
