import React, { useContext } from 'react';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { SearchViewType } from '@/app.components/forms/Search/AdvancedSearch';
import { ResourceContext } from './utils';
import ResourceSearch from './ResourceSearch';
import ResourceList from './ResourceList';
import ResourceTable from './ResourceTable';

type Props = {
    children?: JSX.Element;
};

const Resource: React.FC<Props> = props => {
    const { children } = props;

    const { view, listExtra, listHeader, resourceClassName } = useContext(ResourceContext);

    return (
        <div
            className={`w-full flex-1 min-h-0 min-w-0${listHeader ? ' h-full' : ''}${
                resourceClassName ? ` ${resourceClassName}` : ''
            }`}
        >
            <div className="px-8 lg:px-12 h-full flex flex-col">
                <ResourceSearch />

                <div className={`flex-1 min-h-0 ${view === SearchViewType.Map ? 'mt-4 mb-4' : 'mt-8'}`}>
                    {children}

                    {view === SearchViewType.List && <ResourceList />}
                    {(view === SearchViewType.Table || !view) && (
                        <ErrorBoundary>
                            <ResourceTable />
                        </ErrorBoundary>
                    )}
                    {view === SearchViewType.Map && listExtra}
                </div>
            </div>
        </div>
    );
};

export default Resource;
