import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Input } from '@/app.components';
import { getOptionValue } from '../FilterGeo';
import FilterGeoSelect from '../FilterGeoSelect';

type Props = {
    tempSearchSettings: any;
    setTempSearchSettings: Function;
};

const FilterGeoForm: React.FC<Props> = props => {
    const { tempSearchSettings, setTempSearchSettings } = props;

    const intl = useIntl();

    const content = {
        geo: intl.formatMessage({
            id: 'properties.filter.geo.geo',
        }),
        postcodes: intl.formatMessage({
            id: 'properties.filter.geo.postcodes',
        }),
    };

    const postalValue = tempSearchSettings?.postal_codes;
    const postalGeoValue = tempSearchSettings?.geo_filter;

    const [tempOption, setTempOption] = useState<'geo' | 'postcodes' | ''>(getOptionValue(postalValue, postalGeoValue));

    return (
        <>
            <Input
                type="radio"
                value="metric"
                label={content.geo}
                onChange={() => {
                    setTempOption('geo');

                    setTempSearchSettings({
                        ...tempSearchSettings,
                        postal_codes: undefined,
                    });
                }}
                checked={tempOption === 'geo'}
            />

            {tempOption === 'geo' && (
                <FilterGeoSelect
                    type="geometry"
                    tempSearchSettings={tempSearchSettings}
                    setTempSearchSettings={setTempSearchSettings}
                />
            )}

            <Input
                type="radio"
                value="metric"
                label={content.postcodes}
                onChange={() => {
                    setTempOption('postcodes');

                    setTempSearchSettings({
                        ...tempSearchSettings,
                        geo_filter: undefined,
                    });
                }}
                checked={tempOption === 'postcodes'}
            />

            {tempOption === 'postcodes' && (
                <FilterGeoSelect
                    type="postalCode"
                    marginBottom
                    tempSearchSettings={tempSearchSettings}
                    setTempSearchSettings={setTempSearchSettings}
                />
            )}
        </>
    );
};

export default FilterGeoForm;
