import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, HelpBlock } from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage } from 'react-intl-sweepbright';
import CommunicationIntegrationModal from '@/app.components/modals/Integrations/CommunicationIntegrationModal';
import { updateCompanySettings } from '../../../app.redux/actions/CompaniesActions';
import { getCompany } from '../../../app.redux/selectors/CompaniesSelector';
import PortalConnection from '../../channels/PortalConnection';
import FormPane from '../../../app.components/forms/FormPane';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import Icon from '../../../app.components/icons/Icon';
import { SuccessAlert } from '../../../app.components/elements/Alerts/Alerts';

class CommunicationIntegration extends Component {
    static propTypes = {
        company: PropTypes.instanceOf(Map),
        emptyStateText: PropTypes.node.isRequired,
        field: PropTypes.object.isRequired,
        fieldText: PropTypes.node.isRequired,
        formHelpText: PropTypes.node.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        placeholder: PropTypes.node,
        service: PropTypes.string.isRequired,
        submitting: PropTypes.bool.isRequired,
    };

    state = {
        integrationConnected: false,
    };

    renderAlert() {
        if (!this.state.integrationConnected) {
            return;
        }

        const title = (
            <FormattedMessage
                id="titles.company.integrations.connected"
                defaultMessage="Integration connected successfully!"
            />
        );

        return <SuccessAlert title={title} />;
    }

    render() {
        const { field, service, handleSubmit, fieldText, emptyStateText, formHelpText, company, ...props } = this.props;
        const values = { service };
        const isConnected = Boolean(field.initialValue);
        const onSubmit = attributes => {
            return new Promise((resolve, reject) => {
                props.updateCompanySettings(company.get('id'), {
                    resolve,
                    reject,
                    ...attributes,
                });
            }).then(() => {
                this.setState({
                    integrationConnected: true,
                });

                return this.formModal.close();
            });
        };

        const messages = isConnected
            ? {
                  title: (
                      <FormattedMessage
                          id="integrations.communication.forms.title.edit"
                          defaultMessage="Edit {service}"
                          values={values}
                      />
                  ),
                  action: <FormattedMessage id="integrations.communication.forms.action.edit" defaultMessage="Save" />,
              }
            : {
                  title: (
                      <FormattedMessage
                          id="integrations.communication.forms.title.add"
                          defaultMessage="Add {service}"
                          values={values}
                      />
                  ),
                  action: <FormattedMessage id="integrations.communication.forms.action.add" defaultMessage="Add" />,
              };

        return (
            <FormPane
                title={
                    <FormattedMessage
                        id="titles.company.integrations.communication"
                        defaultMessage="Talk to your visitors with {service}"
                        values={values}
                    />
                }
            >
                {isConnected && this.renderAlert()}
                {isConnected ? (
                    <PortalConnection
                        identifier={field.initialValue}
                        service={service}
                        onDisconnect={() => onSubmit({ [field.name]: null })}
                    >
                        <Button variant="link" onClick={() => this.formModal.open()} icon={<Icon icon="edit" />}>
                            <FormattedMessage id="portal.connection.edit" defaultMessage="Edit Connection" />
                        </Button>
                    </PortalConnection>
                ) : (
                    <div>
                        <Alert>{emptyStateText}</Alert>
                        <FormPanel>
                            <p>
                                <Button variant="primary" onClick={() => this.formModal.open()}>
                                    <FormattedMessage
                                        id="integrations.communication.login"
                                        defaultMessage="Connect {service}"
                                        values={values}
                                    />
                                </Button>
                            </p>
                            <HelpBlock>
                                <FormattedMessage
                                    id="integrations.communication.forms.help"
                                    defaultMessage={"Add your company's {service} account."}
                                    values={values}
                                />
                            </HelpBlock>
                        </FormPanel>
                    </div>
                )}

                <CommunicationIntegrationModal
                    ref={modal => (this.formModal = modal)}
                    field={field}
                    title={messages.title}
                    action={messages.action}
                    label={
                        fieldText || <FormattedMessage id="integrations.communication.app_id" defaultMessage="App ID" />
                    }
                    help={formHelpText}
                    onSubmit={handleSubmit(onSubmit)}
                    placeholder={this.props.placeholder}
                    submitting={this.props.submitting}
                />
            </FormPane>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    company: getCompany,
});

export default connect(mapStateToProps, { updateCompanySettings })(CommunicationIntegration);
