import gql from 'graphql-tag';
import { UserFragment } from '../../fragments/user';

export const getCurrentUser = gql`
    query getCurrentUser {
        me {
            ...UserFragment
        }
    }
    ${UserFragment}
`;
