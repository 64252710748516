import gql from 'graphql-tag';
import locationPreference from '@/graphql/fragments/locationPreference';

export const GET_LOCATION_PREFERENCES_FOR_LEAD_PREFERENCE_QUERY = gql`
    query GetLocationPreferencesForLeadPreference(
        $contactId: ID!
        $preferenceId: ID!
        $includeLocationData: Boolean! = true
    ) {
        contact(id: $contactId) {
            id
            ... on Lead {
                preference(id: $preferenceId) {
                    id
                    locations {
                        totalCount
                        pageInfo {
                            currentPage
                            hasNextPage
                        }
                        edges {
                            node {
                                ...locationPreference
                            }
                        }
                    }
                }
            }
        }
    }
    ${locationPreference}
`;
