import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import find from 'lodash/find';
import Button from '@sweepbright/uikit/build/esm/button';
import BorderedList from '@/app.components/forms/BorderedList.New';
import { FormattedMessage } from 'react-intl-sweepbright';
import useOffice from '@/app.hooks/useOffice';
import useField from '@/app.hooks/useField';
import useDebounce from '@/app.hooks/useDebounce';
import { GetContactsQuery } from '@/graphql/generated/types';
import { DEFAULT_LOCALE } from '@sweepbright/webapp-shared/Config/intl';
import { GET_OFFICE_CONTACTS_QUERY, updateContactsQuery } from '@/graphql/queries/contacts/getContacts';
import useSelectField from '@/app.hooks/useSelectField';
import useCompany from '@/app.hooks/useCompany';
import ContactCard from '@/shared/contacts/ContactCard';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import Input from '../../../app.components/forms/Input/Input';
import Icon from '../../../app.components/icons/Icon';
import EmptyState from '../../../app.components/empty/EmptyState';
import './ContactPicker.scss';

// uncontrolled component to pick a contact

export default function ContactPicker({
    onSelect,
    onCreateNew,
    type,
    excluded = [],
    propertyOfficeId,
    ...props
}: {
    initialValue?: string[];
    onSelect: (contacts: any[]) => void;
    onCreateNew: () => void;
    createLabel: React.ReactNode;
    propertyOfficeId?: string;
    type: 'lead' | 'vendor';
    // an array of excluded contact ids
    excluded?: string[];
}) {
    const office = useOffice();

    const company = useCompany();
    const locale = company.get('locale', DEFAULT_LOCALE);

    const atlasSearch = useFeatureFlag('contacts-atlas-search');
    const { createLabel = <FormattedMessage id={`modal.${type}.new`} defaultMessage={`modal.${type}.new`} /> } = props;
    const searchField = useField('');
    const query = useDebounce(searchField.value);
    const officeId = office.get('id');

    const variables = {
        page: 1,
        query,
        locale,
        ...(query
            ? {}
            : {
                  sortOrder: 'desc',
                  sortField: 'updated_at',
              }),
        filters: {
            archived: false,
            type: type?.toUpperCase(),
            officeId: propertyOfficeId || officeId,
        },
        useAtlasSearch: atlasSearch,
    };

    const { data, loading, fetchMore } = useQuery<GetContactsQuery>(GET_OFFICE_CONTACTS_QUERY, {
        variables,
        notifyOnNetworkStatusChange: true,
    });

    const handleFetchMore = React.useCallback(() => {
        if (loading || !data) {
            // bail out
            return;
        }

        fetchMore({
            variables: { ...variables, page: data!.contacts.pageInfo.currentPage + 1 },
            updateQuery: updateContactsQuery,
        });
    }, [data, loading]);

    const totalCount = data?.contacts?.totalCount;

    const contacts = data?.contacts.edges?.map(e => e.node) ?? [];

    const options = React.useMemo(() => {
        const allOptions = contacts.map(contact => {
            return {
                value: contact.id,
                option: <ContactCard contact={contact} showTitleInfo />,
            };
        });

        // filter out the excluded ids
        return allOptions.filter(option => !excluded.includes(option.value));
    }, [data, excluded]);

    const selectField = useSelectField([], {
        onSelected: ids => {
            const selectedContacts = ids.map(id => find(contacts, { id }));
            onSelect(selectedContacts);
        },
    });

    return (
        <div className="flex flex-column">
            <Input
                wrapperClassName="form-group has-feedback"
                {...searchField}
                placeholder={
                    <FormattedMessage id="search.contact_picker.placeholder" defaultMessage='Search "John Doe"' />
                }
            />
            {!loading && options.length === 0 && renderEmptyState()}
            <BorderedList
                onFetchMore={handleFetchMore}
                name={type}
                selectField={selectField}
                highlights
                loading={loading}
                options={options}
                hasMore={data?.contacts.pageInfo.hasNextPage}
                className="h-full c-contact-picker__list"
            />
            <Button icon={<Icon icon="add" />} block onClick={onCreateNew} style={{ flexShrink: 0 }} variant="primary">
                {createLabel}
            </Button>
        </div>
    );

    function renderEmptyState() {
        if (totalCount === 0) {
            return (
                <EmptyState
                    title={<FormattedMessage id={`modal.${type}.empty`} defaultMessage={`modal.${type}.empty`} />}
                />
            );
        }

        return (
            <EmptyState
                title={<FormattedMessage id={`modal.${type}.no-results`} defaultMessage={`modal.${type}.no-results`} />}
            />
        );
    }
}
