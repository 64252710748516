import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import PagePane from '@/app.layouts/PagePane/PagePane';
import useRouter from '@/app.hooks/useRouter';
import { useToasts } from '@sweepbright/notifications';
import { PROPERTY_TIMELINE, PROPERTY_UNIT_TIMELINE } from '@/app.routing/routes';
import RegisterActivityAction from '@/app.components/activity/RegisterActivityAction/RegisterActivityAction';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import RegisterActivityModal from '@/app.components/modals/RegisterPropertyActivityModal';
import { events, track } from '@/app.utils/analytics';
import useContact from '@/app.hooks/useContact';
import BackButton from '@/app.components/elements/Buttons/BackButton';
import { ActivityType } from '@/app.domains/properties/timeline/types';
import PropertyTimeline from '../../../../app.domains/properties/timeline/components/PropertyTimeline';

export default function PropertyContactTimelinePage({
    params,
}: {
    params: {
        contact: string;
        property: string;
        unit?: string;
    };
}) {
    const contactId = params.contact;
    const propertyId = params.unit ?? params.property;
    const projectId = params.unit ? params.property : null;
    const { addSuccess } = useToasts();
    const timelineRef = React.useRef();

    const [visibleRegistrationModalType, setVisibleRegistrationModalType] = React.useState('');

    const { contact } = useContact(contactId);

    const router = useRouter();
    function handleRegisterActivity(type: ActivityType) {
        track(events.PROPERTY_ACTIVITY_REGISTER_BTN_CLICKED, { activityType: type });
        setVisibleRegistrationModalType(type);
    }

    return (
        <PagePane
            backAction={
                <BackButton
                    onClick={() =>
                        router!.replace({
                            pathname: projectId
                                ? PROPERTY_UNIT_TIMELINE(projectId, propertyId)
                                : PROPERTY_TIMELINE(propertyId),
                            state: { fromLeadTimeline: true },
                        })
                    }
                />
            }
            title={
                contact ? (
                    <FormattedMessage
                        id="activity.with_contact"
                        defaultMessage="Activity with {contact}"
                        values={{
                            contact:
                                !contact.firstName && !contact.lastName ? (
                                    <FormattedMessage id="general.unknown" />
                                ) : (
                                    `${contact.firstName} ${contact.lastName}`
                                ),
                        }}
                    />
                ) : (
                    <FormattedMessage id="general.state.loading" defaultMessage="Loading..." />
                )
            }
            actions={[<RegisterActivityAction key="action" onRegisterActivity={handleRegisterActivity} />]}
        >
            {visibleRegistrationModalType ? (
                <RegisterActivityModal
                    show={true}
                    propertyId={propertyId}
                    contactId={contactId}
                    onClose={() => setVisibleRegistrationModalType('')}
                    type={visibleRegistrationModalType}
                    onActivityRegistered={() => {
                        /* eslint-disable no-unused-expressions */
                        //@ts-ignore
                        timelineRef.current?.refresh();
                        /* eslint-enable no-unused-expressions */

                        addSuccess({
                            title: (
                                <FormattedMessage
                                    id={`timeline.activity_registered_success.title.${visibleRegistrationModalType}`}
                                    defaultMessage={`timeline.activity_registered_success.title.${visibleRegistrationModalType}`}
                                />
                            ),
                            message: (
                                <FormattedMessage
                                    id="timeline.activity_registered_success.confirmation"
                                    defaultMessage="The new activity will show up in the timeline"
                                />
                            ),
                        });

                        setVisibleRegistrationModalType('');
                    }}
                />
            ) : null}
            <ErrorBoundary>
                <PropertyTimeline propertyId={propertyId} contactId={contactId} ref={timelineRef} />
            </ErrorBoundary>
        </PagePane>
    );
}
