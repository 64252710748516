import { combineReducers } from 'redux';
import keyBy from 'lodash/keyBy';
import union from 'lodash/union';
import produce from 'immer';
import {
    ContactsAction,
    MERGE_CONTACTS,
    RESET_CONTACT_INTERACTIONS,
    RESET_CONTACTS,
    SET_CONTACT,
    SET_CONTACT_INTERACTIONS,
    SET_CONTACTS,
    SET_LOCATION_PREFERENCES,
    SET_VENDOR_ESTATES,
} from '@/app.redux/actions/ContactsActions';
import contactReducer, { sanitizer as contactSanitizer } from './Subreducers/ContactReducer';

function allIds(state = [], action: ContactsAction) {
    switch (action.type) {
        case SET_CONTACTS: {
            return action.contacts.data.map(contact => contact.id);
        }
        case MERGE_CONTACTS: {
            return union(
                state,
                action.contacts.data.map(contact => contact.id),
            );
        }
        case RESET_CONTACTS: {
            return [];
        }
        default:
            return state;
    }
}

const byId = produce((state = {}, action: ContactsAction) => {
    switch (action.type) {
        case SET_CONTACTS: {
            return keyBy(action.contacts.data, 'id');
        }
        case MERGE_CONTACTS: {
            const contactsToMergeById = keyBy(action.contacts.data, 'id');

            return { ...state, ...contactsToMergeById };
        }
        case SET_CONTACT: {
            return { ...state, [action.contact.id]: contactSanitizer(action.contact) };
        }
        case SET_VENDOR_ESTATES:
        case SET_CONTACT_INTERACTIONS:
        case SET_LOCATION_PREFERENCES:
        case RESET_CONTACT_INTERACTIONS: {
            const { contactId } = action;
            // @ts-ignore
            state[contactId] = contactReducer(state[contactId], action);

            return state;
        }

        default:
            return state;
    }
});

export default combineReducers({ allIds, byId });
