import { put } from 'redux-saga/effects';
import { CompaniesRequests } from '../../../requests/index';
import { setPlans } from '../../actions';
import apiCall from '../Effects/apiCall';

export default function* onFetchPlans() {
    const plans = yield apiCall(new CompaniesRequests().plans);

    yield put(setPlans(plans));
}
