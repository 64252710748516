import React from 'react';
import { Map } from 'immutable';
import CompanyContext from '@/app.domains/company/CompanyContext';

export default function useCompany() {
    return React.useContext<Map<string, any>>(CompanyContext);
}

export function withCompany(WrappedComponent) {
    function WrapperComponent(props, ref) {
        const company = useCompany();

        return <WrappedComponent ref={ref} company={company} {...props} />;
    }

    return React.forwardRef(WrapperComponent);
}
