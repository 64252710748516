import React from 'react';
import { InputProps } from 'redux-form';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import CurrencyAddonForOffice from '../../../app.components/forms/CurrencyAddonForOffice';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import Input from '../../../app.components/forms/Input/Input';
import './PriceRangeForm.scss';

type Props = {
    maxPriceField: InputProps;
    minPriceField: InputProps;
    negotiationType: 'let' | 'sale';
    style?: React.CSSProperties;
    allowedRange: [number, number];
    // set to true when shown inside a popup
    // it will become the default soon
    popup: boolean;
    error?: string;
    active?: boolean;
    showHistogram?: boolean;
    priceHistogram: Array<{
        from: number;
        to: number;
        count: number;
    }>;
    action?: any;
};

const PriceRangeForm = ({
    negotiationType,
    minPriceField,
    maxPriceField,
    style,
    allowedRange,
    popup = false,
    error,
    active = true,
    action,
}: Props) => {
    return (
        <FormPanel
            title={<FormattedMessage id="modals.common.price_panel.title" defaultMessage="Price / Rent" />}
            style={style}
            popup={popup}
            action={action}
        >
            {error && !active ? <Alert bsStyle="danger"> {error} </Alert> : null}
            <ManualEntry
                minPriceField={minPriceField}
                maxPriceField={maxPriceField}
                negotiationType={negotiationType}
                max={allowedRange[1]}
            />
        </FormPanel>
    );
};

function ManualEntry({ max, maxPriceField, minPriceField, negotiationType }) {
    return (
        <div className="c-price-range">
            <Input
                type="price"
                decimalScale={2}
                label={<FormattedMessage id="modals.common.price_panel.min_label" defaultMessage="Min." />}
                {...minPriceField}
                value={minPriceField.value <= 0 ? '' : minPriceField.value}
                placeholder={<FormattedMessage id="general.any" defaultMessage="Any" />}
                addonAfter={<CurrencyAddonForOffice toLet={negotiationType === 'let'} />}
            />
            <span className="c-price-range__separator"> ⎼ </span>
            <Input
                type="price"
                decimalScale={2}
                label={<FormattedMessage id="modals.common.price_panel.max_label" defaultMessage="Max." />}
                {...maxPriceField}
                value={maxPriceField.value >= max ? '' : maxPriceField.value}
                placeholder={<FormattedMessage id="general.any" defaultMessage="Any" />}
                max={max}
                addonAfter={<CurrencyAddonForOffice toLet={negotiationType === 'let'} />}
            />
        </div>
    );
}

export default withErrorBoundary(PriceRangeForm);
