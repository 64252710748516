import { defineMessages } from 'react-intl';

export const locationMessages = defineMessages({
    location: { id: 'group.location', defaultMessage: '--- LOCATION ---' },
    'location.street': {
        id: 'properties.fields.location.street',
        defaultMessage: 'Street (required)',
    },
    'location.street_2': {
        id: 'properties.fields.location.street_2',
        defaultMessage: 'Street 2',
    },
    'location.number': {
        id: 'properties.fields.location.number',
        defaultMessage: 'Number (required)',
    },
    'location.addition': {
        id: 'properties.fields.location.addition',
        defaultMessage: 'Addition',
    },
    'location.box': {
        id: 'properties.fields.location.box',
        defaultMessage: 'Box',
    },
    'location.postal_code': {
        id: 'properties.fields.location.postal_code',
        defaultMessage: 'Postcode / Zip (required)',
    },
    'location.city': {
        id: 'properties.fields.location.city',
        defaultMessage: 'City / Municipality (required unless "Borough / District / Town / Suburb" is imported)',
    },
    'location.borough_or_district': {
        id: 'properties.fields.location.borough_or_district',
        defaultMessage: 'Borough / District / Town / Suburb (required unless "City / Municipality" is imported)',
    },
    'location.province_or_state': {
        id: 'properties.fields.location.province_or_state',
        defaultMessage: 'State / Province / County / Territory / Locality',
    },
    'location.country': {
        id: 'properties.fields.location.country',
        defaultMessage: 'Country (required)',
    },
    'location.floor': {
        id: 'properties.fields.location.floor',
        defaultMessage: 'Floor',
    },
});

export const structureMessages = defineMessages({
    structure: { id: 'group.structure', defaultMessage: '--- STRUCTURE ---' },
    type: {
        id: 'properties.fields.type',
        defaultMessage: 'Property Type (required)',
    },
    sub_type: {
        id: 'properties.fields.sub_type',
        defaultMessage: 'Property Subtype',
    },
    general_condition: {
        id: 'properties.fields.general_condition',
        defaultMessage: 'General Condition',
    },
    'construction.building.year': {
        id: 'properties.fields.construction.building.year',
        defaultMessage: 'Year Built',
    },

    // Rooms
    'property.fields.structure.living_room': {
        id: 'property.fields.structure.living_room',
        defaultMessage: 'Living Rooms',
    },
    'property.fields.structure.kitchens': {
        id: 'property.fields.structure.kitchens',
        defaultMessage: 'Kitchens',
    },
    'property.fields.structure.bedrooms': {
        id: 'property.fields.structure.bedrooms',
        defaultMessage: 'Bedrooms',
    },
    'property.fields.structure.bathrooms': {
        id: 'property.fields.structure.bathrooms',
        defaultMessage: 'Bathrooms',
    },
    'property.fields.structure.toilets': {
        id: 'property.fields.structure.toilets',
        defaultMessage: 'WCs',
    },
    'property.fields.structure.floors': {
        id: 'property.fields.structure.floors',
        defaultMessage: 'Floors',
    },
    'property.fields.structure.parking_spaces': {
        id: 'property.fields.structure.parking_spaces',
        defaultMessage: 'Parking spaces',
    },
    'property.fields.structure.showrooms': {
        id: 'property.fields.structure.showrooms',
        defaultMessage: 'Showrooms',
    },
    'property.fields.structure.manufacturing_areas': {
        id: 'property.fields.structure.manufacturing_areas',
        defaultMessage: 'Manufacturing Areas',
    },
    'property.fields.structure.storage_rooms': {
        id: 'property.fields.structure.storage_rooms',
        defaultMessage: 'Storage Rooms',
    },
    'property.fields.structure.meeting_rooms': {
        id: 'property.fields.structure.meeting_rooms',
        defaultMessage: 'Meeting Rooms',
    },
    'property.fields.structure.conference_rooms': {
        id: 'property.fields.structure.conference_rooms',
        defaultMessage: 'Conference Rooms',
    },
    'property.fields.structure.open_office_spaces': {
        id: 'property.fields.structure.open_office_spaces',
        defaultMessage: 'Open Office Spaces',
    },
    'property.fields.structure.closed_office_spaces': {
        id: 'property.fields.structure.closed_office_spaces',
        defaultMessage: 'Closed Office Spaces',
    },
    'property.fields.structure.shower_rooms': {
        id: 'property.fields.structure.shower_rooms',
        defaultMessage: 'Shower Rooms',
    },

    // Rooms Import (For differences between structure rooms and import properties screen)
    'property.fields.structure.import.bedrooms': {
        id: 'property.fields.structure.import.bedrooms',
        defaultMessage: 'Bedrooms (required for apartments and houses)',
    },

    // Areas
    'structure.gross_area.size': {
        id: 'properties.fields.structure.gross_area.size',
        defaultMessage: 'Gross',
    },
    'structure.net_area.size': {
        id: 'properties.fields.structure.net_area.size',
        defaultMessage: 'Net',
    },
    'structure.plot_area.size': {
        id: 'properties.fields.structure.plot_area.size',
        defaultMessage: 'Plot',
    },
    'structure.liveable_area.size': {
        id: 'properties.fields.structure.liveable_area.size',
        defaultMessage: 'Liveable',
    },
    'structure.loi_carrez_area.size': {
        id: 'properties.fields.structure.loi_carrez_area.size',
        defaultMessage: 'Liveable (Carrez act)',
    },
    'structure.display_window_length.size': {
        id: 'properties.fields.structure.display_window_length.size',
        defaultMessage: 'Display Window',
    },
    'structure.ceiling_height.size': {
        id: 'properties.fields.structure.ceiling_height.size',
        defaultMessage: 'Ceiling Height',
    },

    // Areas Rooms
    'property.fields.structure.areas.living_room': {
        id: 'property.fields.structure.areas.living_room',
        defaultMessage: 'Living Room',
    },
    'property.fields.structure.areas.kitchens': {
        id: 'property.fields.structure.areas.kitchens',
        defaultMessage: 'Kitchen',
    },
    'property.fields.structure.areas.bedrooms': {
        id: 'property.fields.structure.areas.bedrooms',
        defaultMessage: 'Bedroom',
    },
    'property.fields.structure.areas.bathrooms': {
        id: 'property.fields.structure.areas.bathrooms',
        defaultMessage: 'Bathroom',
    },
    'property.fields.structure.areas.toilets': {
        id: 'property.fields.structure.areas.toilets',
        defaultMessage: 'WC',
    },
    'property.fields.structure.areas.floors': {
        id: 'property.fields.structure.areas.floors',
        defaultMessage: 'Floor',
    },
    'property.fields.structure.areas.parking_spaces': {
        id: 'property.fields.structure.areas.parking_spaces',
        defaultMessage: 'Parking space',
    },
    'property.fields.structure.areas.showrooms': {
        id: 'property.fields.structure.areas.showrooms',
        defaultMessage: 'Showroom',
    },
    'property.fields.structure.areas.manufacturing_areas': {
        id: 'property.fields.structure.areas.manufacturing_areas',
        defaultMessage: 'Manufacturing Area',
    },
    'property.fields.structure.areas.storage_rooms': {
        id: 'property.fields.structure.areas.storage_rooms',
        defaultMessage: 'Storage Room',
    },
    'property.fields.structure.areas.meeting_rooms': {
        id: 'property.fields.structure.areas.meeting_rooms',
        defaultMessage: 'Meeting Room',
    },
    'property.fields.structure.areas.conference_rooms': {
        id: 'property.fields.structure.areas.conference_rooms',
        defaultMessage: 'Conference Room',
    },
    'property.fields.structure.areas.open_office_spaces': {
        id: 'property.fields.structure.areas.open_office_spaces',
        defaultMessage: 'Open Office Space',
    },
    'property.fields.structure.areas.closed_office_spaces': {
        id: 'property.fields.structure.areas.closed_office_spaces',
        defaultMessage: 'Closed Office Space',
    },
    'property.fields.structure.areas.shower_rooms': {
        id: 'property.fields.structure.areas.shower_rooms',
        defaultMessage: 'Shower Room',
    },

    // Areas Amenities
    'property.fields.structure.areas.server_room': {
        id: 'property.fields.structure.areas.server_room',
        defaultMessage: 'Server Room',
    },
    'property.fields.structure.areas.storage_space': {
        id: 'property.fields.structure.areas.storage_space',
        defaultMessage: 'Storage Space',
    },
    'property.fields.structure.areas.print_and_copy_area': {
        id: 'property.fields.structure.areas.print_and_copy_area',
        defaultMessage: 'Print And Copy Area',
    },
    'property.fields.structure.areas.water_access': {
        id: 'property.fields.structure.areas.water_access',
        defaultMessage: 'Water Access',
    },
    'property.fields.structure.areas.fenced': {
        id: 'property.fields.structure.areas.fenced',
        defaultMessage: 'Fenced',
    },
    'property.fields.structure.areas.utilities_access': {
        id: 'property.fields.structure.areas.utilities_access',
        defaultMessage: 'Utilities Access',
    },
    'property.fields.structure.areas.sewer_access': {
        id: 'property.fields.structure.areas.sewer_access',
        defaultMessage: 'Sewer Access',
    },
    'property.fields.structure.areas.drainage': {
        id: 'property.fields.structure.areas.drainage',
        defaultMessage: 'Drainage',
    },
    'property.fields.structure.areas.road_access': {
        id: 'property.fields.structure.areas.road_access',
        defaultMessage: 'Road Access',
    },
    'property.fields.structure.areas.attic': {
        id: 'property.fields.structure.areas.attic',
        defaultMessage: 'Attic',
    },
    'property.fields.structure.areas.guesthouse': {
        id: 'property.fields.structure.areas.guesthouse',
        defaultMessage: 'Guesthouse',
    },
    'property.fields.structure.areas.electrical_gate': {
        id: 'property.fields.structure.areas.electrical_gate',
        defaultMessage: 'Electrical Gate',
    },
    'property.fields.structure.areas.manual_gate': {
        id: 'property.fields.structure.areas.manual_gate',
        defaultMessage: 'Manual Gate',
    },
    'property.fields.structure.areas.fence': {
        id: 'property.fields.structure.areas.fence',
        defaultMessage: 'Fence',
    },
    'property.fields.structure.areas.remote_control': {
        id: 'property.fields.structure.areas.remote_control',
        defaultMessage: 'Remote Control',
    },
    'property.fields.structure.areas.code': {
        id: 'property.fields.structure.areas.code',
        defaultMessage: 'Code',
    },
    'property.fields.structure.areas.climate_control': {
        id: 'property.fields.structure.areas.climate_control',
        defaultMessage: 'Climate Control',
    },
    'property.fields.structure.areas.key_card': {
        id: 'property.fields.structure.areas.key_card',
        defaultMessage: 'Key Card',
    },
    'property.fields.structure.areas.pool': {
        id: 'property.fields.structure.areas.pool',
        defaultMessage: 'Pool',
    },
    'property.fields.structure.areas.cooling_room': {
        id: 'property.fields.structure.areas.cooling_room',
        defaultMessage: 'Cooling Room',
    },
    'property.fields.structure.areas.display_window': {
        id: 'property.fields.structure.areas.display_window',
        defaultMessage: 'Display Window',
    },
    'property.fields.structure.areas.reception_area': {
        id: 'property.fields.structure.areas.reception_area',
        defaultMessage: 'Reception Area',
    },
    'property.fields.structure.areas.waiting_area': {
        id: 'property.fields.structure.areas.waiting_area',
        defaultMessage: 'Waiting Area',
    },
    'property.fields.structure.areas.basement': {
        id: 'property.fields.structure.areas.basement',
        defaultMessage: 'Basement',
    },
    'property.fields.structure.areas.terrace': {
        id: 'property.fields.structure.areas.terrace',
        defaultMessage: 'Terrace',
    },
    'property.fields.structure.areas.garden': {
        id: 'property.fields.structure.areas.garden',
        defaultMessage: 'Garden',
    },
    'property.fields.structure.areas.balcony': {
        id: 'property.fields.structure.areas.balcony',
        defaultMessage: 'Balcony',
    },
    'property.fields.structure.areas.parking': {
        id: 'property.fields.structure.areas.parking',
        defaultMessage: 'Parking',
    },
    'property.fields.structure.areas.interior_parking': {
        id: 'property.fields.structure.areas.interior_parking',
        defaultMessage: 'Interior Parking',
    },
    'property.fields.structure.areas.exterior_parking': {
        id: 'property.fields.structure.areas.exterior_parking',
        defaultMessage: 'Exterior Parking',
    },
    'property.fields.structure.areas.lift': {
        id: 'property.fields.structure.areas.lift',
        defaultMessage: 'Lift',
    },
});

export const priceMessages = defineMessages({
    price_rent: {
        id: 'group.price_rent',
        defaultMessage: '--- PRICE/RENT ---',
    },
    'price.published_price.amount': {
        id: 'properties.fields.price.published_price.amount',
        defaultMessage: 'Price | Rent/month (required)',
    },
    'price.costs': {
        id: 'properties.fields.price.costs',
        defaultMessage: 'Costs',
    },
    'price.taxes': {
        id: 'properties.fields.price.taxes',
        defaultMessage: 'Taxes',
    },
});

export const descriptionMessages = defineMessages({
    description: {
        id: 'group.description',
        defaultMessage: '--- DESCRIPTION ---',
    },
    'description.title': {
        id: 'properties.fields.description.title',
        defaultMessage: 'Title',
    },
    'description.description': {
        id: 'properties.fields.description.description',
        defaultMessage: 'Description',
    },
});

export const legalMessages = defineMessages({
    legal_docs: {
        id: 'group.legal_docs',
        defaultMessage: '--- LEGAL & DOCS ---',
    },
    'energy.epc_value': {
        id: 'properties.fields.energy.epc_value',
        defaultMessage: 'EPC Value',
    },
    'energy.epc_category': {
        id: 'properties.fields.energy.epc_category',
        defaultMessage: 'EPC Level',
    },
    'energy.nathers': {
        id: 'properties.fields.energy.nathers',
        defaultMessage: 'NATHERS',
    },
    'energy.nabers': {
        id: 'properties.fields.energy.nabers',
        defaultMessage: 'NABERS',
    },
    'energy.legal_mentions': {
        id: 'properties.fields.energy.legal_mentions',
        defaultMessage: 'Legal Mentions',
    },
    'occupancy.available_from': {
        id: 'properties.fields.occupancy.available_from',
        defaultMessage: 'Available From',
    },
});

export const featuresMessages = defineMessages({
    features: { id: 'group.features', defaultMessage: '--- FEATURES ---' },
    'features.heating_cooling.central_heating': {
        id: 'properties.fields.features.heating_cooling.central_heating',
        defaultMessage: 'Central Heating',
    },
    'features.heating_cooling.floor_heating': {
        id: 'properties.fields.features.heating_cooling.floor_heating',
        defaultMessage: 'Floor Heating',
    },
    'features.heating_cooling.individual_heating': {
        id: 'properties.fields.features.heating_cooling.individual_heating',
        defaultMessage: 'Individual Heating',
    },
    'features.heating_cooling.air_conditioning': {
        id: 'properties.fields.features.heating_cooling.air_conditioning',
        defaultMessage: 'Air Conditioning',
    },
    'features.energy.gas': {
        id: 'properties.fields.features.energy.gas',
        defaultMessage: 'Gas',
    },
    'features.energy.fuel': {
        id: 'properties.fields.features.energy.fuel',
        defaultMessage: 'Fuel oil',
    },
    'features.energy.electricity': {
        id: 'properties.fields.features.energy.electricity',
        defaultMessage: 'Electricity',
    },
    'features.energy.heat_pump': {
        id: 'properties.fields.features.energy.heat_pump',
        defaultMessage: 'Heat pump',
    },
    'settings.internal_note': {
        id: 'properties.fields.settings.internal_note',
        defaultMessage: 'Notes',
    },
});

export const settingsMessages = defineMessages({
    settings: { id: 'group.settings', defaultMessage: '--- SETTINGS ---' },
    status: {
        id: 'properties.fields.status',
        defaultMessage: 'Transaction Status',
    },
    'settings.mandate.start_date': {
        id: 'properties.fields.settings.mandate.start_date',
        defaultMessage: 'Mandate - Start Date',
    },
    'settings.mandate.end_date': {
        id: 'properties.fields.settings.mandate.end_date',
        defaultMessage: 'Mandate - End Date',
    },
    'settings.mandate.exclusive': {
        id: 'properties.fields.settings.mandate.exclusive',
        defaultMessage: 'Mandate - Exclusive',
    },
    vendor_email: {
        id: 'properties.fields.vendor_email',
        defaultMessage: 'Vendor E-mail',
    },
    negotiator_email: {
        id: 'properties.fields.negotiator_email',
        defaultMessage: 'Negotiator E-mail',
    },
    'settings.agency_commission.fixed_fee': {
        id: 'properties.fields.settings.agency_commission.fixed_fee',
        defaultMessage: 'Agency Commission Fixed Fee',
    },
    'settings.agency_commission.percentage': {
        id: 'properties.fields.settings.agency_commission.percentage',
        defaultMessage: 'Agency Commission Percentage',
    },
    'settings.office.reference': {
        id: 'modals.import.fields.settings.office.reference',
        defaultMessage: 'Reference',
    },
    buyer: {
        id: 'modals.import.fields.settings.buyer',
        defaultMessage: 'Buyer/Tenant E-mail',
    },
});
