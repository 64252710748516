import React from 'react';
import classNames from 'classnames';

const LayoutContainer: React.FunctionComponent<{ className?: string; flex?: boolean }> = ({
    children,
    className,
    flex,
    ...props
}) => {
    const style: React.CSSProperties = { position: 'absolute', left: '0', right: 0, top: 0, bottom: 0 };
    if (flex) {
        style.flex = 1;
    }

    return (
        <div className={classNames('flex', className)} style={style} {...props}>
            {children}
        </div>
    );
};

export default LayoutContainer;
