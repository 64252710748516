import React, { Component } from 'react';
import { LoadingIndicator, Button } from '@sweepbright/uikit';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router';
import { propTypes, reduxForm as form } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import { INTEGRATION_TYPE } from '@/app.routing';
import IntegrationsRequest from '../../requests/IntegrationsRequest';
import { getCompanyFeatures } from '../../app.redux/selectors';
import FormPanel from '../forms/FormPanel/FormPanel';
import Icon, { ICON_SIZE_MEDIUM } from '../icons/Icon';
import MediaCard from '../card/MediaCard/MediaCard';
import Zapier from '../../app.domains/properties/Preview/Zapier';

const icons = {
    mandate_for_renting: 'doc-offer',
    mandate_for_sales: 'doc-agreement-exclusive',
    compromis: 'doc-agreement-non-exclusive',
    offer: 'notes',
    renting_engagement: 'doc-contract',
    renting_contract: 'document',
    property_folder: 'folder',
};

@form(
    {
        form: 'estates/create-document',
        fields: ['type'],
    },
    createStructuredSelector({
        features: getCompanyFeatures,
    }),
)
export default class CreateDocument extends Component {
    static propTypes = {
        ...propTypes,
        close: PropTypes.func.isRequired,
        estate: PropTypes.instanceOf(Map),
        features: PropTypes.instanceOf(Map),
    };

    state = {
        step: 'select-type',
        documents: [],
        loading: true,
    };

    componentDidMount() {
        new IntegrationsRequest()
            .documents()
            .then(this.handleDocumentsLoaded)
            .catch(null);
    }

    handleDocumentsLoaded = documents => {
        this.setState({ documents, loading: false });
    };

    handleSubmit = () => {
        this.props
            .handleSubmit()
            .then(() => this.props.close())
            .catch(null);
    };

    renderDocument = document => {
        return (
            <li key={document.id}>
                <MediaCard
                    title={document.name}
                    image={<Icon size={ICON_SIZE_MEDIUM} icon={icons[document.id]} />}
                    subtitles={[document.description]}
                    onClick={() => {
                        this.setState({ step: 'confirm' });
                        this.props.fields.type.onChange(document.id);
                    }}
                />
            </li>
        );
    };

    renderStepContent() {
        const { features, estate } = this.props;

        switch (this.state.step) {
            case 'confirm':
                return <Zapier estate={estate} />;
            case 'select-type':
            default:
                const documents = this.state.documents.filter(document => features.get(`zapier_${document.id}`));

                if (this.state.loading) {
                    return <LoadingIndicator />;
                }

                if (!documents.length) {
                    return (
                        <Alert>
                            <FormattedMessage
                                id="modals.create-document.body.title"
                                defaultMessage="Create documents using property data."
                                tagName="p"
                            />
                            <FormattedMessage
                                id="modals.create-document.body.help"
                                defaultMessage="But first configure {link}."
                                tagName="p"
                                values={{
                                    link: (
                                        <Link to={INTEGRATION_TYPE('documents')}>
                                            <FormattedMessage
                                                defaultMessage="your document integrations."
                                                id="modals.create-document.body.help_link"
                                            />
                                        </Link>
                                    ),
                                }}
                            />
                        </Alert>
                    );
                }

                return (
                    <FormPanel>
                        <div className="bc-bordered-list">{documents.map(this.renderDocument)}</div>
                    </FormPanel>
                );
        }
    }

    renderStepAction() {
        switch (this.state.step) {
            case 'confirm':
                return (
                    <Button
                        type="submit"
                        variant="primary"
                        disabled={this.props.submitting}
                        onClick={this.handleSubmit}
                    >
                        <FormattedMessage id="modals.create-document.cta" defaultMessage="Create document" />
                    </Button>
                );
            default:
                return null;
        }
    }

    render() {
        return (
            <div className="max-h-screen flex flex-col">
                <Modal.Header>
                    <Modal.Title>
                        <FormattedMessage id="modals.create-document.title" defaultMessage="Create document" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="overflow-y-auto">{this.renderStepContent()}</Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.close}>
                        <FormattedMessage id="modals.create-document.cancel" defaultMessage="Cancel" />
                    </Button>
                    {this.renderStepAction()}
                </Modal.Footer>
            </div>
        );
    }
}
