// @ts-nocheck
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Channel } from '@/graphql/generated/types';
import { GET_CHANNEL_ACCOUNT } from '@/graphql/queries/channels/getChannelAccount';
import useCompany from '@/app.hooks/useCompany';
import formatApiErrors from '@/app.redux/sagas/Helpers/formatApiErrors';

const SAVE_CHANNEL_ACCOUNT = gql`
    mutation SaveChannelAccount($companyId: String!, $account: ChannelAccountInput!) {
        saveChannelAccount(companyId: $companyId, input: $account) {
            id
            name
            accessToken
            accessTokenSecret
            credentials {
                accessToken
                accessTokenSecret
                additionalAccessId
                name
            }
        }
    }
`;

export function useSaveChannelAccount(channel: Channel) {
    const company = useCompany();
    const [saveChannelAccount, state] = useMutation(SAVE_CHANNEL_ACCOUNT);

    return [
        function(attributes: {
            id?: string;
            name: string;
            officeId?: string;
            accessToken?: string;
            accessTokenSecret?: string;
            additionalAccessId?: string;
            credentials?: {
                accessToken?: string;
                accessTokenSecret?: string;
                pageName?: string;
                username?: string;
                pageAccessToken?: string;
            };
        }) {
            const newAccount = {
                channel: channel.id,
                officeId: attributes.office_id,
                accessToken: attributes.accessToken,
                accessTokenSecret: attributes.accessTokenSecret,
                name: attributes.name,
                additionalAccessId: attributes?.additionalAccessId,
            };
            if (attributes.webhook) {
                newAccount.webhook = attributes.webhook;
            }
            if (attributes.credentials) {
                newAccount.accessToken = attributes.credentials.accessToken;
                newAccount.accessTokenSecret = attributes.credentials.accessTokenSecret;
                // facebook stuff
                newAccount.facebookPageName = attributes.credentials.pageName;
                newAccount.facebookPageAccessToken = attributes.credentials.pageAccessToken;
                // twitter stuff
                newAccount.twitterUsername = attributes.credentials.username;
            }

            // if an id is passed, it is just an update
            if (attributes.id) {
                newAccount.id = attributes.id;
            }
            const companyId = company.get('id');
            const accountId = newAccount.id;

            return saveChannelAccount({
                variables: {
                    companyId,
                    account: newAccount,
                },
                refetchQueries: [
                    'GetAllAccounts',
                    accountId ? { query: GET_CHANNEL_ACCOUNT, variables: { companyId, accountId } } : null,
                ].filter(_ => _),
                awaitRefetchQueries: true,
            }).catch(err => {
                const serverErrors = get(err, 'graphQLErrors[0].extensions.exception.response.data');
                if (serverErrors) {
                    const formattedErrors = formatApiErrors(serverErrors);

                    throw remapFormFields(formattedErrors);
                } else {
                    // a general form error
                    throw {
                        _error: 'Something went wrong, please try again later',
                    };
                }
            });
        },
        state,
    ];
}

const REMOVE_CHANNEL_ACCOUNT = gql`
    mutation RemoveChannelAccount($companyId: String!, $accountId: String!) {
        removeChannelAccount(companyId: $companyId, accountId: $accountId)
    }
`;

export function useRemoveChannelAccount(account) {
    const company = useCompany();
    const [removeChannelAccount] = useMutation(REMOVE_CHANNEL_ACCOUNT, {
        variables: {
            companyId: company.get('id'),
            accountId: account.id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [`GetPortalsAccounts`, `GetPortals`, `GetSocialChannels`, `GetSocialAccounts`],
    });

    return removeChannelAccount;
}

const keyMap = {
    immovlan_customer_id: 'accessToken',
    immoweb_ftp_username: 'accessToken',
    immoweb_ftp_password: 'accessTokenSecret',
    onthemarket_api_key: 'accessToken',
    rightmove_api_key: 'accessToken',
    rea_agent_id: 'accessToken',
    zoopla_api_key: 'accessToken',
    domain_agency_id: 'accessToken',
};

export function remapFormFields(obj) {
    return Object.keys(obj).reduce((acc, key) => {
        acc[keyMap[key]] = obj[key];

        return acc;
    }, {});
}
