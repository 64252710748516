import React from 'react';
import classnames from 'classnames';

const Tile: React.FunctionComponent<{ image?: boolean; className?: string }> = ({
    children,
    image,
    className,
    ...tileProps
}) => {
    const classes = classnames(
        'c-tile-list__tile',
        {
            'image-tile': image,
        },
        className,
    );

    return (
        <li className={classes} {...tileProps}>
            {children}
        </li>
    );
};

export default Tile;
