import { extractData } from '@sweepbright/margaret-fetcher';

import SweepbrightCrudRequest from './SweepbrightCrudRequest';

type CSVAttributes = {
    file: File;
    type: string;
    overwrite?: boolean;
    fields: string[];
};

export default class OfficesRequests extends SweepbrightCrudRequest {
    resource = 'offices';

    query = {
        includes: ['head_negotiator', 'settings'],
    };

    office = (officeId: string, { includes }: { includes?: string[] } = {}): Promise<any> => {
        return this.withMeta()
            .setQueryParameters({ includes })
            .get(`${this.resource}/${officeId}`);
    };

    team = (teamId: string): Promise<any> => {
        return this.withMeta()
            .setQueryParameters({ includes: ['head_negotiator', 'settings'] })
            .get(`${this.resource}/${teamId}`);
    };

    getLocationTemplates(officeId: string, parameters: { query?: string; page?: number; limit?: number }) {
        let request = this.withMeta();

        if (parameters.query && parameters.query.trim().length > 0) {
            request = request.withQueryParameter('query', parameters.query);
        }
        request = request.withQueryParameter('page', parameters.page || 1);

        return request.get(`/offices/${officeId}/location-preference-templates`);
    }

    updateLocationTemplate = (
        officeId: string,
        locationPreferenceId: string,
        preference: { type: 'geojson' | 'postal_codes'; name: string },
        data: any,
    ) => {
        return this.put(`/offices/${officeId}/location-preference-templates/${locationPreferenceId}`, {
            name: preference.name,
            type: preference.type,
            data,
        });
    };

    getLocationTemplate = (officeId: string, locationPreferenceId: string) => {
        return this.get(`/offices/${officeId}/location-preference-templates/${locationPreferenceId}`);
    };

    addLocationTemplate = (officeId: string, preference: any, data) => {
        return this.post(`/offices/${officeId}/location-preference-templates`, {
            name: preference.name,
            type: preference.type,
            data,
        });
    };

    removeLocationTemplate = (officeId: string, locationPreferenceId: string) => {
        return this.delete(`/offices/${officeId}/location-preference-templates/${locationPreferenceId}`);
    };

    getLegalEntities(officeId: string, parameters: { page?: number; limit?: number }) {
        let request = this.withMeta();

        request = request.withQueryParameter('office_id', officeId || '');
        request = request.withQueryParameter('page', parameters.page || 1);

        return request.get(`/legal-entities`);
    }

    getLegalEntity = (officeId: string, id: string) => {
        return this.withMeta()
            .withQueryParameter('office_id', officeId || '')
            .get(`/legal-entities/${id}`);
    };

    addLegalEntity = (officeId: string, name: string) => {
        return this.post(`/legal-entities`, {
            name,
            office_id: officeId,
        });
    };

    updateLegalEntity = (officeId: string, name: string, id: string) => {
        return this.put(`/legal-entities/${id}`, {
            name,
            office_id: officeId,
        });
    };

    deleteLegalEntity = (_officeId: string, id: string) => {
        return this.delete(`/legal-entities/${id}`);
    };

    updateHeadNegotiator = (officeId: string, negotiatorId: string): Promise<any> => {
        return this.put(`${this.resource}/${officeId}/head-negotiator`, {
            office_member: negotiatorId,
        });
    };

    updateSettings = (officeId: string, settings: {}): Promise<any> => {
        return this.put(`${this.resource}/${officeId}/settings`, settings);
    };

    updateMemberRole = (officeId: string, memberId: string, role: any) => {
        return this.put(`${this.resource}/${officeId}/members/${memberId}/roles`, {
            role,
        });
    };

    members = (officeId: string, params: Record<string, any>): Promise<any> => {
        return this.withMeta()
            .setQueryParameters(params)
            .get(`${this.resource}/${officeId}/members`);
    };

    negotiators = (
        officeId: string,
        params: { query?: string; page?: number; limit?: number; includes?: string } = {},
    ): Promise<any> => {
        return this.withMeta()
            .setQueryParameters({ ...params, teams: [officeId], status: 'not_archived' })
            .get(`users`);
    };

    negotiatorsByIds = (ids: string[]): Promise<any> => {
        return this.withMeta()
            .setQueryParameters({ ids: ids.join(',') })
            .get(`negotiators`);
    };

    teamMembers = (officeId: string, params: Record<string, any> = {}) => {
        return this.withMeta()
            .setQueryParameters(params)
            .get(`${this.resource}/${officeId}/members`);
    };

    companyMembers = (_officeId: string, params: Record<string, any> = {}) => {
        return this.withMeta()
            .setQueryParameters(params)
            .get(`users`);
    };

    estatesGeojsonData = (officeId: string) => {
        return this.setQueryParameters({})
            .withMeta()
            .get(`/offices/${officeId}/properties/geojson`);
    };

    inviteMembers = (officeId: string, payload: object) => {
        return this.post(`${this.resource}/${officeId}/members`, payload);
    };

    assignLeads = (officeId: string, leadIds: string[]) => {
        return this.post(`${this.resource}/${officeId}/assigned-leads`, {
            ids: leadIds,
        });
    };

    uploadCsv = (endpoint: string, officeId: string, attributes: CSVAttributes) => {
        const body = this.createBody();

        body.append('file', attributes.file);
        body.append('fields', attributes.fields.join(','));

        if (attributes.type) {
            body.append('type', attributes.type);
        }

        if (attributes.overwrite) {
            body.append('overwrite', true);
        }

        const payload = {
            body,
            method: 'POST',
            headers: {
                Authorization: this.options.headers!['Authorization'],
                'X-Sweepbright-Office': officeId,
            },
        };

        let fullEndpoint = `${this.resource}/${officeId}/${endpoint}`;

        return this.fetch(fullEndpoint, payload).then(extractData);
    };
}
