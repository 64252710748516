import React, { FC } from 'react';
import { EstatePublicationError, EstatePublicationWarning } from '@/graphql/generated/types';
import { FormattedMessage } from 'react-intl-sweepbright';

interface PublicationErrorTextProps {
    error: EstatePublicationError | EstatePublicationWarning;
}

export const PublicationErrorText: FC<PublicationErrorTextProps> = props => {
    const { error } = props;

    return error.translationKey ? (
        <FormattedMessage id={error.translationKey} defaultMessage={error.message} />
    ) : (
        <span>{error.message}</span>
    );
};
