import Button from '@sweepbright/uikit/build/esm/button';
import { FilterChip } from '@sweepbright/uikit/build/esm/filterchip';
import classNames from 'classnames';
import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { events, trackHandler } from '@/app.utils/analytics';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import Input from '@/app.components/forms/Input/Input';
import { NegotiatorSelector } from '@/app.components/forms/elements/NegotiatorSelector';
import useOffice from '@/app.hooks/useOffice';
import useUser from '@/app.hooks/useUser';
import { useNegotiator } from '@/app.hooks/useNegotiator';

enum Option {
    No,
    Me,
    Other,
}

interface Values {
    negotiator: Maybe<string>;
    noNegotiator?: Maybe<boolean>;
}

interface OwnershipFilterProps {
    defaultValues: Values;
    values: Values;
    onChange: (v: Values) => void;
    showUnassignedOption?: boolean;
    mineLabel: ReactElement;
    ownershipFormatter: (name: string) => ReactElement;
    trackSearchKey: string;
}

export const OwnershipFilter: FC<OwnershipFilterProps> = ({
    defaultValues = { negotiator: '', noNegotiator: false },
    values,
    onChange,
    showUnassignedOption,
    mineLabel,
    ownershipFormatter,
    trackSearchKey,
}) => {
    const userId = `${useUser().get('id')}`;
    const getOwnershipValueLabel = (values: Values) => {
        if (values.noNegotiator) {
            return <FormattedMessage id="filters.labels.ownership.unassigned" defaultMessage="Unassigned" />;
        }
        if (values.negotiator) {
            if (values.negotiator === userId) {
                return mineLabel;
            }

            return <OwnershipLabel negotiatorId={values.negotiator} formatter={ownershipFormatter} />;
        }

        return '';
    };

    return (
        <FilterChip
            size="small"
            testId="ownership-filter-chip"
            placeholder={<FormattedMessage id="filters.labels.ownership" defaultMessage="Ownership" />}
            onClear={() => {
                onChange(defaultValues);
            }}
            value={getOwnershipValueLabel(values)}
            popupHeader={null}
            renderPopupContent={({ closePopup }) => {
                return (
                    <Form
                        showUnassignedOption={!!showUnassignedOption}
                        trackSearchKey={trackSearchKey}
                        onSubmit={newValues => {
                            onChange(newValues);
                            closePopup();
                        }}
                        initialValues={values}
                        userId={userId}
                        mineLabel={mineLabel}
                    />
                );
            }}
        />
    );
};

const Form: FC<{
    initialValues: Values;
    onSubmit: (v: Values) => void;
    userId: string;
    mineLabel: ReactElement;
    trackSearchKey: string;
    showUnassignedOption: boolean;
}> = ({ initialValues, onSubmit, userId, mineLabel, trackSearchKey, showUnassignedOption }) => {
    userId = `${userId}`;

    const [negotiator, setNegotiator] = useState<Maybe<string>>(initialValues.negotiator);
    const [noNegotiator, setNoNegotiator] = useState<Maybe<boolean>>(initialValues.noNegotiator);

    const office = useOffice();

    const initialOption = useMemo(() => {
        if (initialValues.noNegotiator) {
            return Option.No;
        }
        if (initialValues.negotiator === userId) {
            return Option.Me;
        }

        return Option.Other;
    }, [initialValues.negotiator, initialValues.noNegotiator, userId]);

    const [option, _setOption] = useState<Option>(initialOption);

    const setOption = useCallback(
        (option: Option) => {
            _setOption(option);
            switch (option) {
                case Option.Me: {
                    setNegotiator(`${userId}`);
                    setNoNegotiator(false);
                    break;
                }
                case Option.No: {
                    setNegotiator('');
                    setNoNegotiator(true);
                    break;
                }
                case Option.Other: {
                    setNegotiator('');
                    setNoNegotiator(false);
                    break;
                }
            }
        },
        [userId],
    );

    return (
        <form
            onSubmit={evt => {
                evt.preventDefault();
                onSubmit({ negotiator, noNegotiator });
            }}
            className="w-64"
        >
            <FormPanel title={<FormattedMessage id="filters.labels.ownership" defaultMessage="Ownership" />} popup>
                {showUnassignedOption ? (
                    <Input
                        checked={option === Option.No}
                        type="radio"
                        onChange={() => setOption(Option.No)}
                        label={
                            <FormattedMessage
                                id="modals.properties_search.ownership_pane.no_negotiator_label"
                                defaultMessage="No Negotiator"
                            />
                        }
                    />
                ) : null}
                <Input
                    checked={option === Option.Me}
                    type="radio"
                    onChange={() => setOption(Option.Me)}
                    label={mineLabel}
                />
                <Input
                    checked={option === Option.Other}
                    type="radio"
                    onChange={() => setOption(Option.Other)}
                    label={
                        <FormattedMessage
                            id="modals.properties_search.ownership_pane.with_negotiator_label"
                            defaultMessage="Choose Negotiator"
                        />
                    }
                />
                <div
                    className={classNames('mb-4', {
                        hidden: option !== Option.Other,
                    })}
                >
                    <NegotiatorSelector onChange={setNegotiator} value={negotiator} officeId={office.get('id')} />
                </div>
                <Button
                    variant="primary"
                    type="submit"
                    block
                    onClick={trackHandler(events.SEARCH_FILTER_CHIP_APPLY_BTN_CLICKED, {
                        search: trackSearchKey,
                        filter: 'ownership',
                    })}
                >
                    <FormattedMessage id="buttons.apply" defaultMessage="Apply" />
                </Button>
            </FormPanel>
        </form>
    );
};

const OwnershipLabel: FC<{ negotiatorId: string; formatter: (name: string) => ReactElement }> = ({
    negotiatorId,
    formatter,
}) => {
    const { loading, negotiator } = useNegotiator(negotiatorId);

    if (loading) {
        return (
            <FormattedMessage
                id="modals.properties_search.ownership_pane.loading_placeholder"
                defaultMessage="Loading..."
            />
        );
    }

    const name = (negotiator?.firstName ?? negotiator?.lastName ?? 'Unknown')?.split(/\s/)[0];

    return formatter(name);
};
