// @ts-nocheck
import React from 'react';
import Input from '@/app.components/forms/Input/Input';
import { FormattedMessage } from 'react-intl-sweepbright';
import { WithLanguage } from '@/app.domains/channels/Website/elements/WithLanguage';

type Props = {
    fieldName: string;
    label: React.ReactNode;
    fields: { [fieldWithLocalePrefix: string]: any };
};

const MultilingualTextAreas = ({ fieldName, label, fields }: Props) => {
    return (
        <WithLanguage fields={fields}>
            {language => (
                <Input
                    key={language.value}
                    componentClass="textarea"
                    {...fields[`${fieldName}_${language.value}`]}
                    type="rich-text"
                    label={
                        <>
                            {label} (
                            <FormattedMessage
                                id={`general.locales.${language.value}`}
                                defaultMessage={language.label}
                            />
                            )
                        </>
                    }
                />
            )}
        </WithLanguage>
    );
};

export default MultilingualTextAreas;
