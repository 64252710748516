import moment from 'moment-timezone';
import { useMemo } from 'react';
import useCompanyLanguages from '@/app.hooks/useCompanyLanguages';
import useOffice from '@/app.hooks/useOffice';
import useCompany from '@/app.hooks/useCompany';

export function getZoneAbbreviation(zoneName: string) {
    const zone = moment.tz.zone(zoneName);
    const now = moment();
    let untilIndex = 0;
    for (let until of zone?.untils || []) {
        if (moment(until).isAfter(now)) {
            break;
        }
        untilIndex++;
    }
    const abbr = zone?.abbrs[untilIndex];
    // we can compute the offset as well (in minutes)
    // const offset = zone.offsets[untilIndex];

    return abbr ? `(${abbr})` : null;
}

export const useVisitMessageTemplates = () => {
    const office = useOffice();
    const officeSettings = office.getIn(['settings', 'data'])?.toJS();
    const langs = useCompanyLanguages();
    const company = useCompany().toJS();

    const companyDefaultLocale = company?.settings?.data?.default_locale;

    return useMemo(() => {
        const templatesByLang = Object.fromEntries(
            langs
                .map(({ value: langCode }) => [langCode, officeSettings?.[`email_lead_visit_${langCode}`]])
                .filter(([, template]: [string, string?]) => template && template.trim().length),
        );

        const defaultTemplate = getDefaultMessageTemplate(companyDefaultLocale, langs, templatesByLang);

        return {
            templatesByLang,
            defaultTemplate,
        };
    }, [langs, officeSettings]);
};

function getDefaultMessageTemplate(
    companyDefaultLocale: string,
    langs: { value: string }[],
    templatesByLang: { [lang: string]: string },
) {
    if (companyDefaultLocale) {
        const template = templatesByLang[companyDefaultLocale];
        if (template) {
            return template;
        }
    }

    const firstLangWithTemplate = langs.find(lang => templatesByLang[lang.value]);
    if (!firstLangWithTemplate) {
        return '';
    }

    return templatesByLang[firstLangWithTemplate.value];
}
