import React, { FC, useEffect, useState, useMemo } from 'react';
import { useDebounce } from 'react-use';
import { FormattedMessage } from 'react-intl-sweepbright';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import { useOfficeNegotiatorsForSelect } from '@/app.hooks/useOfficeNegotiatorsForSelect';

interface NegotiatorSelectorProps {
    onChange: (eventOrValue?: string) => void;
    value: Maybe<string>;
    style?: React.CSSProperties;
    officeId: string;
    disabled?: boolean;
    clearable?: boolean;
    'data-testid'?: string;
    hasError?: boolean;
    hasCalendar?: boolean;
    setCurrentUser?: boolean;
}

export const NegotiatorSelector: FC<NegotiatorSelectorProps> = ({
    officeId,
    onChange,
    style,
    value,
    disabled,
    clearable = false,
    'data-testid': testId,
    hasError,
    hasCalendar,
    setCurrentUser,
}) => {
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [scrolled, setScrolled] = useState(false);
    const [finished, setFinished] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [debouncedQuery, setDebouncedQuery] = useState('');

    useDebounce(
        () => {
            setPage(1);
            setFinished(false);
            setDebouncedQuery(query);
        },
        500,
        [query],
    );

    const { selectOptions, loading } = useOfficeNegotiatorsForSelect({
        page,
        officeId,
        query: debouncedQuery,
        additionalNegotiatorId: value,
    });

    useEffect(() => {
        if (scrolled && !finished) {
            setPage(prevPage => prevPage + 1);
        }
    }, [scrolled, finished]);

    const dataHash = useMemo(() => {
        return data.map(el => el.value).join(',');
    }, [data]);

    useEffect(() => {
        if (!loading) {
            if (selectOptions.length > 0) {
                if (page === 1) {
                    setData(selectOptions);
                }

                if (page > 1) {
                    const filteredSelectOptions = selectOptions.filter(
                        (option: any) => !data.find(el => el.value === option.value),
                    );
                    setData(prevData => [...(prevData || []), ...filteredSelectOptions]);
                }
            }

            if (selectOptions.length === 0) {
                setFinished(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, loading, selectOptions, dataHash]);

    const filteredData = useMemo(() => {
        return data.filter(el => {
            if (hasCalendar) {
                return el.raw.hasCalendarIntegration;
            }

            return true;
        });
    }, [data, hasCalendar]);

    const activeUser = filteredData.find(el => el.isCurrentUser);

    useEffect(() => {
        if (setCurrentUser && hasCalendar && activeUser && !value) {
            onChange(activeUser.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, activeUser, hasCalendar, setCurrentUser]);

    return (
        <Select
            truncate
            //@ts-ignore
            style={style}
            value={value}
            testId={testId}
            loading={loading}
            hasError={hasError}
            disabled={disabled}
            clearable={clearable}
            options={filteredData}
            onInputChange={setQuery}
            onChange={selectedNegotiator => {
                onChange?.(selectedNegotiator?.value ?? '');
            }}
            onScroll={isScrollEnd => {
                setScrolled(isScrollEnd);
            }}
            placeholder={
                loading ? (
                    <FormattedMessage
                        id="modals.properties_search.ownership_pane.negotiator_field.loading_placeholder"
                        defaultMessage="Loading negotiators..."
                    />
                ) : (
                    <FormattedMessage
                        id="modals.properties_search.ownership_pane.negotiator_field.placeholder"
                        defaultMessage="Select..."
                    />
                )
            }
        />
    );
};
