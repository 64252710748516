import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { replace as replaceLocation, push } from 'react-router-redux';
import AuthManager from 'auth-manager';
import urlParser from 'url-parse';
import CookieService from '@/app.utils/services/CookieService';
import logger from '@/app.utils/services/logger';

import {
    AUTHORIZE,
    LOGIN,
    LOGGED_IN,
    REGISTER,
    LOGOUT_ATTEMPT,
    VALIDATE_CODE,
    authorize,
    loggedIn,
    logoutAttempt,
} from '@/app.redux/actions/AuthActions';
import { LOGIN_SENT, PROPERTIES } from '../../app.routing/routes';
import AuthRequests from '../../requests/AuthRequests';
import UserRequests from '../../requests/UserRequests';
import { setMessage, resetMessages, setUser, setOffice, setCompany } from '../actions';
import { onRegister, onLogout } from './Authentication';
import formatApiErrors from './Helpers/formatApiErrors';
import apiCall from './Effects/apiCall';

export function* onLogin({ email, resolve, reject }) {
    try {
        yield put(resetMessages());
        yield call(new AuthRequests().login, {
            email,
        });

        yield put(push(LOGIN_SENT));
        // eslint-disable-next-line no-unused-expressions
        resolve?.();
    } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 400) {
            // eslint-disable-next-line no-unused-expressions
            reject?.({
                email: 'email.validation_errors.no_account',
            });
        } else {
            const formErrors = formatApiErrors(error);

            // eslint-disable-next-line no-unused-expressions
            reject?.(formErrors);
        }
    }
}

export function* onAuthorize(action) {
    try {
        yield put(resetMessages());

        yield call(AuthManager.setToken.bind(AuthManager), action.token, action.expiresIn);
        yield call(new UserRequests().withBearerToken, action.token.access_token);

        const user = yield apiCall(new UserRequests().me);

        yield put(setOffice(user.office.data));
        yield put(setCompany(user.company.data));
        yield put(setUser(user));

        yield put(loggedIn(user, action.expiresIn));
    } catch (error) {
        const message = formatApiErrors(error.response, 'message').message;

        yield put(setMessage(message, 'danger'));
    }
}

export function* onValidateCode({ code, resolve, reject }) {
    try {
        yield put(resetMessages());
        const token = yield call(new AuthRequests().validateCode, code);

        // eslint-disable-next-line no-unused-expressions
        resolve?.(token);

        yield put(authorize(token, token.expires_in));
    } catch (error) {
        if (error.response?.status === 400 || error.response?.status === 401) {
            // eslint-disable-next-line no-unused-expressions
            reject?.({
                _error: 'login.errors.auth_code.invalid',
            });
        } else {
            const formattedErrors = formatApiErrors(error);
            // eslint-disable-next-line no-unused-expressions
            reject?.(formattedErrors);
        }
    }
}

export function* onLoggedIn({ user, expiresIn }) {
    yield call(AuthManager.login.bind(AuthManager), { id: user.id }, expiresIn);

    const redirectToAfterLogin = getRedirectLocation();
    // remove the redirect cookie
    yield call(removeCookie, 'redirect_to');

    // call the replace action creator from 'react-router-redux'
    yield put(replaceLocation(redirectToAfterLogin));
}

export function* onRefreshToken() {
    try {
        const token = yield call(AuthManager.getToken.bind(AuthManager));
        const response = yield call(new AuthRequests().refreshToken, token.refresh_token);

        yield put(authorize(response, response.expires_in));

        return token;
    } catch (error) {
        logger.error('failed to refresh token, logging out the user', {
            error: error.message,
        });
        yield put(logoutAttempt());
    }
}

export default function* AuthenticationSaga() {
    yield [
        fork(takeEvery, LOGIN, onLogin),
        fork(takeEvery, LOGGED_IN, onLoggedIn),
        fork(takeEvery, REGISTER, onRegister),
        fork(takeEvery, AUTHORIZE, onAuthorize),
        fork(takeEvery, LOGOUT_ATTEMPT, onLogout),
        fork(takeEvery, VALIDATE_CODE, onValidateCode),
    ];
}

export function getRedirectLocation() {
    const redirectTo = CookieService().get('redirect_to');
    logger.debug(`fetched redirect cookie: ${redirectTo}`);
    const { pathname } = urlParser(redirectTo, {});
    logger.debug(`redirecting to pathname ${pathname}`);

    return pathname || PROPERTIES;
}

export function removeCookie(cookieName) {
    const cookieService = CookieService();
    cookieService.remove(cookieName, { path: '/' });
}
