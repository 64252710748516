import { put, take } from 'redux-saga/effects';
import { SET_CONTACT_INTERACTIONS } from '@/app.redux/actions/ContactsActions';
import { resetPagination, setContact } from '../../actions';
import ContactsRequests from '../../../requests/ContactsRequests';
import apiCall from '../Effects/apiCall';

export type APIInteraction = {
    id: string;
    interaction_type: string;
    source_type: string;
    comment: string | null;
    human_status: string;
    created_at: string;
};

export default function* onRegisterInteraction({
    contactId,
    propertyId,
    attributes: { type, comment, send_to_owner: sendToOwner },
    resolve,
    reject,
}: {
    contactId: string;
    propertyId: string;
    attributes: { type: string; comment: string; send_to_owner: boolean };
    resolve?: (interaction: APIInteraction) => void;
    reject: (error: Error) => void;
}) {
    try {
        const interaction: APIInteraction = yield apiCall(
            new ContactsRequests().registerInteraction,
            contactId,
            propertyId,
            type,
            comment,
            sendToOwner,
        );

        // fetches the contact so the last interaction field gets updated
        //
        const updatedContact = yield apiCall(new ContactsRequests().show, contactId);
        yield put(setContact(updatedContact));

        // reload all the interactions for this contact
        // TODO: we can do better here, just add the property to the list of interactions
        yield [
            put(resetPagination(`contact/${contactId}/active-interests`)),
            put(resetPagination(`contact/${contactId}/inactive-interests`)),
        ];

        // wait for the new contact interactions/interests to be set
        yield take(SET_CONTACT_INTERACTIONS);

        if (resolve) {
            resolve(interaction);
        }
    } catch (err) {
        if (reject) {
            reject(new Error('Failed to register interaction'));
        }
    }
}
