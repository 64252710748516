import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const TextBlock = ({ children, padding }) => {
    const classes = classnames('text-block', {
        'text-block--nopadding': !padding,
    });

    return <div className={classes}>{children}</div>;
};

TextBlock.propTypes = {
    children: PropTypes.node.isRequired,
    padding: PropTypes.bool,
};

export default TextBlock;
