import { fromJS, OrderedSet, Map } from 'immutable';
import { createReducerWithSanitizer } from 'redux-reducer-factory';
import { sanitizeLeads } from '@/app.redux/reducers/Helpers/sanitizers/leads';
import {
    SET_COMPANY_ADMINS,
    REMOVE_COMPANY_ADMIN,
    SET_COMPANY_LEADS,
    REMOVE_COMPANY_LEAD,
    RESET_COMPANY_LEADS,
    SET_COMPANY,
    SET_COMPANY_TOKENS,
    SET_HIGHLIGHTS,
    SET_COMPANY_CARD,
    SET_COMPANY_FEATURES,
} from '../../actions';
import concatSetWith from '../Helpers/concatSetWith';
import sanitizeOrderedSet from '../Helpers/sanitizeOrderedSet';

function sanitizer(company) {
    return fromJS(company)
        .update('admins', admins => admins || OrderedSet())
        .update('leads', leads => leads || OrderedSet())
        .update('tokens', tokens => tokens || OrderedSet())
        .update('highlights', value => value || OrderedSet())
        .update('features', value => value || Map())
        .update('card', value => value || Map());
}

export default createReducerWithSanitizer({}, sanitizer, {
    [SET_COMPANY]: (company, action, sanitize) => {
        return sanitize(action.company)
            .set('tokens', company.get('tokens'))
            .set('admins', company.get('admins'))
            .set('leads', company.get('leads'))
            .set('highlights', company.get('highlights'));
    },
    [SET_COMPANY_ADMINS]: (company, { admins }) => company.update('admins', concatSetWith(admins)),
    [SET_COMPANY_TOKENS]: (company, { tokens }) => company.set('tokens', sanitizeOrderedSet(tokens)),
    [REMOVE_COMPANY_ADMIN]: (company, { memberId }) =>
        company.update('admins', admins => admins.filterNot(admin => admin.get('id') === memberId)),
    [SET_COMPANY_LEADS]: (company, { leads }) => company.update('leads', concatSetWith(sanitizeLeads(leads))),
    [REMOVE_COMPANY_LEAD]: (company, { leadId }) =>
        company.update('leads', leads => leads.filterNot(lead => lead.get('id') === leadId)),
    [RESET_COMPANY_LEADS]: company => company.set('leads', new OrderedSet()),
    [SET_HIGHLIGHTS]: (company, { highlights }) => company.update('highlights', concatSetWith(highlights)),
    [SET_COMPANY_CARD]: (company, { card }) => company.set('card', fromJS(card)),
    [SET_COMPANY_FEATURES]: (company, { features }) => company.set('features', fromJS(features)),
});
