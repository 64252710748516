import React, { FC } from 'react';
import { EstatePublicationError, EstatePublicationWarning } from '@/graphql/generated/types';
import { PublicationErrorText } from './PublicationErrorText';
import { PublicationErrorDetailsItemLayout } from './PublicationErrorDetailsItemLayout';

interface PublicationErrorDetailsItemProps {
    type: 'error' | 'warning';
    error: EstatePublicationError | EstatePublicationWarning;
}

export const PublicationErrorDetailsItem: FC<PublicationErrorDetailsItemProps> = props => {
    const { type, error } = props;

    return (
        <PublicationErrorDetailsItemLayout type={type}>
            <PublicationErrorText error={error} />
        </PublicationErrorDetailsItemLayout>
    );
};
