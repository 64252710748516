import React from 'react';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import OfficesLegalEntities from '@/new.domains/offices/Offices/OfficesLegalEntities';
import TeamWrapper from '@/new.domains/teams/TeamWrapper';

const PageOfficesLegalEntities = props => {
    const officeId = props.params?.office;

    const legalEntitiesEnabled = useFeatureFlag('legal-entities-enabled');

    if (!legalEntitiesEnabled) {
        return null;
    }

    return (
        <TeamWrapper {...props}>
            <OfficesLegalEntities officeId={officeId} />
        </TeamWrapper>
    );
};

export default PageOfficesLegalEntities;
