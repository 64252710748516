import gql from 'graphql-tag';
import locationPreference from '@/graphql/fragments/locationPreference';
export const GET_OFFICE = gql`
    query getOffice(
        $id: ID!
        $locationTemplatesPage: Int
        $locationTemplatesQuery: String
        $includeLocationData: Boolean! = true
    ) {
        office(id: $id) {
            id
            locationTemplates(page: $locationTemplatesPage, query: $locationTemplatesQuery) {
                totalCount
                pageInfo {
                    currentPage
                    hasNextPage
                }
                edges {
                    node {
                        ...locationPreference
                    }
                }
            }
        }
    }
    ${locationPreference}
`;
