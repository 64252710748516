// @ts-nocheck
import CookieService from '@/app.utils/services/CookieService';
import logger from '@/app.utils/services/logger';

export function clearUserCookies() {
    logger.warn('user cookies are going to be removed');
    const cookieService = CookieService();
    cookieService.remove('user');
    cookieService.remove('token');
    cookieService.remove('office_id');
}
