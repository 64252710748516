import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LoginWithCode from '../../app.domains/auth/login/LoginWithCode';
import { validateCode } from '../../app.redux/actions/AuthActions';
import { getCurrentMessages } from '../../app.redux/selectors';

const mapActionsToProps = { validateCode };
const mapStateToProps = createStructuredSelector({
    messages: getCurrentMessages,
});

// @ts-ignore
export default connect(mapStateToProps, mapActionsToProps)(LoginWithCode);
