import { List, fromJS } from 'immutable';

const toList = iterable => (List.isList(iterable) ? iterable : fromJS(iterable).toList());

export default (toConcat, prioritizeCurrent = false) => current => {
    const joined = toList(current).concat(toList(toConcat));
    const method = prioritizeCurrent ? 'findLastIndex' : 'findIndex';
    const unique = joined.filter((entity, key) => {
        if (!entity.get || !entity.get('id')) {
            return true;
        }

        return key === joined[method](other => other.get('id') === entity.get('id'));
    });

    return unique.toOrderedSet();
};
