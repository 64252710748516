// @ts-nocheck
import React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Collapse } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import { useToasts } from '@sweepbright/notifications';
import { GET_ACTIVITY_QUERY } from '@/graphql/queries/activities/getActivity';
import {
    ContactCalledActivity,
    ContactEmailedActivity,
    ContactMessagedActivity,
    GetActivityQuery,
    GetActivityQueryVariables,
    LeadExpressedInterestActivity,
    PropertyVisitedActivity,
    VendorReportEmailedActivity,
    VisitScheduledActivity,
} from '@/graphql/generated/types';
import { track, events, trackHandler } from '@/app.utils/analytics';
import MessageDetails from '@/app.components/card/ActivityCard/details/MessageDetails';
import { DetailsAction } from '../../elements/DetailsAction/DetailsAction';
import { IconName } from '../../icons/Icon';
import { FeedbackAction } from './ActivityAction/ActivityAction';
import ActivityCard from './ActivityCard';
import FeedbackDetails from './details/FeedbackDetails';

type Activity =
    | ContactCalledActivity
    | ContactEmailedActivity
    | ContactMessagedActivity
    | PropertyVisitedActivity
    | LeadExpressedInterestActivity
    | VisitScheduledActivity
    | VendorReportEmailedActivity;

const DETAILS_COMPONENTS = {
    FeedbackActivityDetails: FeedbackDetails,
    MessageActivityDetails: MessageDetails,
};

export default function ActivityCardWithAction({
    activity,
    ...rest
}: {
    activity: Activity;
    children?: React.ReactNode;
    title: React.ReactNode;
    subtitle: React.ReactNode;
    icon: IconName;
    date: string;
}) {
    const [getActivity, { data, refetch, called }] = useLazyQuery<GetActivityQuery, GetActivityQueryVariables>(
        GET_ACTIVITY_QUERY,
        {
            variables: {
                id: activity!.id,
            },
        },
    );

    const { addSuccess } = useToasts();
    const [showDetails, setShowDetails] = React.useState(false);

    // fetch the activity card details
    React.useEffect(() => {
        if (showDetails && !called) {
            getActivity();
        }
    }, [showDetails, called]);

    function renderDetails() {
        if (data?.activity?.details?.__typename) {
            const DetailsComponent = DETAILS_COMPONENTS[data?.activity?.details?.__typename];

            return <DetailsComponent details={data?.activity?.details} />;
        }

        return null;
    }

    return (
        <ActivityCard action={getActionComponent()} {...rest}>
            <Collapse in={showDetails} transitionAppear>
                <div>{renderDetails()}</div>
            </Collapse>
        </ActivityCard>
    );

    function getActionComponent() {
        switch (activity.action) {
            case 'FEEDBACK': {
                return (
                    <FeedbackAction
                        onClick={trackHandler(events.ACTIVITY_CARD_ADD_FEEDBACK_BTN_CLICKED)}
                        onAddedFeedback={() => {
                            addSuccess({
                                title: (
                                    <FormattedMessage
                                        id="activity.register_feedback.added"
                                        defaultMessage="Feedback added"
                                    />
                                ),
                                message: (
                                    <FormattedMessage
                                        id="activity.register_feedback.confirmation"
                                        defaultMessage="The details of the activity will show up in the timeline"
                                    />
                                ),
                            });
                            // refetch the activity, so we get the details
                            if (called) {
                                refetch();
                            } else {
                                getActivity();
                            }
                        }}
                        contactId={getContactInContext(activity)}
                        interactionId={activity!.id}
                        propertyId={getPropertyInContext(activity)}
                    />
                );
            }
            case 'DETAILS': {
                return (
                    <DetailsAction
                        expanded={showDetails}
                        onToggle={() => {
                            track(
                                showDetails
                                    ? events.ACTIVITY_CARD_SHOW_DETAILS_BTN_CLICKED
                                    : events.ACTIVITY_CARD_HIDE_DETAILS_BTN_CLICKED,
                            );
                            setShowDetails(show => !show);
                        }}
                    />
                );
            }
            default:
                return null;
        }
    }
}

function getPropertyInContext(activity: Activity): Maybe<string> {
    switch (activity?.__typename) {
        case 'ContactMessagedActivity':
            return activity.about?.id;
        case 'LeadExpressedInterestActivity':
            return activity.propertyInterestedIn?.id;
        case 'PropertyVisitedActivity':
            return activity.property?.id;
        case 'VisitScheduledActivity':
            return activity.visit?.property?.id;
        case 'ContactCalledActivity':
            return activity.about?.id;
        case 'ContactEmailedActivity':
            return activity.about?.id;
        default:
            return null;
    }
}

function getContactInContext(activity: Activity): Maybe<string> {
    switch (activity?.__typename) {
        case 'ContactMessagedActivity':
            return activity.messagedContact?.id;
        case 'LeadExpressedInterestActivity':
            return activity.interestedLead?.id;
        case 'PropertyVisitedActivity':
            return activity.visitedBy && activity.visitedBy[0] && activity.visitedBy[0]?.id;
        case 'VisitScheduledActivity':
            return activity.visit.attendees && activity.visit.attendees[0] && activity.visit.attendees[0]?.id;
        case 'ContactCalledActivity':
            return activity.calledContact?.id;
        case 'ContactEmailedActivity':
            return activity.emailedContact?.id;
        default:
            return null;
    }
}
