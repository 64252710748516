// @ts-nocheck
import { APIContact, APIEstate } from '@sweepbright/webapp-server/graphql/resolvers/helpers/types';
import { PaginatedResponse } from '@/requests/types';
import AbstractEstatesRequests from './AbstractEstatesRequests';

export default class PropertiesRequests extends AbstractEstatesRequests {
    resource = 'properties';

    show = (id: string) => {
        return super.get<APIEstate>(`/properties/${id}`);
    };

    copy = (propertyId: string) => {
        return this.post(this.resource, {
            from: propertyId,
        });
    };

    getReference = (id: string) => {
        return this.withMeta().get(`/estates/${id}/point-of-reference`);
    };

    visitors = (propertyId: string, payload: Record<string, any>) => {
        return this.withMeta()
            .setQueryParameters(payload)
            .get<PaginatedResponse<APIContact>>(`${this.resource}/${propertyId}/visitors`);
    };

    visits = (propertyId: string) => {
        return this.get(`${this.resource}/${propertyId}/visits`);
    };

    getVendors = (propertyId: string) => {
        // NOTE: this is extremely inefficient but there is no better
        // way to do it at the moment
        return this.show(propertyId).then(estate => {
            return estate.vendors?.data ?? [];
        });
    };

    duplicate = (propertyId: string) => {
        return this.post(`/properties/${propertyId}/clone`);
    };
}
