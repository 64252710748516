// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { createStructuredSelector } from 'reselect';
import { WithRouterProps, withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl-sweepbright';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import ContactRepository from '@/app.utils/services/Repositories/ContactRepository';
import { contactTypes } from '@/app.data';
import { getContact } from '@/app.redux/selectors/ContactsSelectors';
import { getFeatures } from '../../../../app.redux/selectors/FeaturesSelector';
import * as routes from '../../../../app.routing/routes';
import { SideMenu, SideMenuLink } from '../../../../app.components';
import { IconName } from '../../../../app.components/icons/Icon';
import LayoutContainer from '../../../../app.components/layouts/LayoutContainer';

type Props = {
    features: Map<string, any>;
    contact: Map<string, any>;
} & WithRouterProps;

const ContactDetailsPage: React.FunctionComponent<Props> = ({ location, features, children, params, contact }) => {
    const contactId = params.contact;

    const locationPath = location.pathname;
    const navigation = React.useMemo(() => {
        const navigation: Array<{ id: string; to: string; label: React.ReactNode; icon: IconName }> = [
            {
                id: 'contact',
                to: routes.CONTACT_DETAILS_INFO(contactId),
                label: (
                    <FormattedMessage id="pages.contacts.section_labels.contact_info" defaultMessage="Contact Info" />
                ),
                icon: 'person-circle',
            },
            {
                id: 'properties',
                to: routes.CONTACT_DETAILS_PROPERTIES(contactId),
                label: <FormattedMessage id="pages.contacts.section_labels.properties" defaultMessage="Properties" />,
                icon: 'units',
            },
        ];

        navigation.push({
            id: 'notes',
            to: routes.CONTACT_DETAILS_NOTES(contactId),
            label: <FormattedMessage id="pages.contacts.section_labels.notes" defaultMessage="Notes" />,
            icon: 'notes',
        });

        navigation.push({
            id: 'settings',
            to: routes.CONTACT_DETAILS_SETTINGS(contactId),
            label: <FormattedMessage id="pages.contacts.section_labels.settings" defaultMessage="Settings" />,
            icon: 'settings',
        });

        return navigation;
    }, [features, contactId]);

    return (
        <LayoutContainer>
            <LayoutColumn size="small">
                <SideMenu color="gray-lightest">
                    {navigation
                        .filter(({ id }) => !isBlacklisted(id, contact))
                        .map(navigationEntry => (
                            <SideMenuLink
                                key={navigationEntry.id}
                                location={locationPath}
                                {...navigationEntry}
                                fuzzyMatch
                            />
                        ))}
                </SideMenu>
            </LayoutColumn>
            <LayoutColumn>
                <ErrorBoundary>{children}</ErrorBoundary>
            </LayoutColumn>
        </LayoutContainer>
    );
};

export default withRouter(
    connect(
        createStructuredSelector({
            features: getFeatures,
            contact: getContact,
        }),
    )(ContactDetailsPage),
);

function isBlacklisted(link: string, contact: Map<string, any>) {
    const blacklist = (function() {
        switch (new ContactRepository(contact).getType()) {
            case contactTypes.VENDOR:
                return ['interactions', 'preferences', 'properties'].filter(Boolean);

            default:
                return ['properties', 'report'].filter(Boolean);
        }
    })();

    return blacklist.includes(link);
}
