import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import SideMenu from './SideMenu/SideMenu';

export default class LeftNavBar extends Component {
    static propTypes = {
        children: PropTypes.node,
        location: PropTypes.string,
    };

    shouldComponentUpdate(nextProps) {
        return this.props.location !== nextProps.location || this.props.children !== nextProps.children;
    }

    render() {
        return (
            <LayoutColumn size="tiny">
                <SideMenu centered shadow={null}>
                    {this.props.children}
                </SideMenu>
            </LayoutColumn>
        );
    }
}
