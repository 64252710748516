import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import { SendMatchingEmailsMutation, SendMatchingEmailsMutationVariables } from '@/graphql/generated/types';
import { SEND_MATCHING_EMAILS } from '@/graphql/mutations/match/sendMatchingEmails';

const useMatchEmails = (
    options: MutationHookOptions<SendMatchingEmailsMutation, SendMatchingEmailsMutationVariables>,
) => {
    return useMutation<SendMatchingEmailsMutation, SendMatchingEmailsMutationVariables>(SEND_MATCHING_EMAILS, options);
};

export default useMatchEmails;
