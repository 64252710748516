import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import CreditCardForm from '@/app.domains/subscription/CardForm';
import CreditCard from '@/app.domains/subscription/Card';
import { FormFields } from '@sweepbright/webapp-shared/utils/types';
import useCompany from '@/app.hooks/useCompany';
import { FormattedMessage } from 'react-intl-sweepbright';

const CardPaymentOption = ({
    fields,
    newCard,
    setNewCard,
}: {
    fields: FormFields<{
        card: {
            number: string;
            cvc: string;
            expiry: string;
        };
    }>;
    newCard: boolean;
    setNewCard: (isNewCard: boolean) => void;
}) => {
    const company = useCompany();
    const card = company.get('card');
    const hasSavedCard = card && card.get('brand');
    const onChangeCard = (isNewCard: boolean) => {
        setNewCard(isNewCard);
        fields.card.number.onChange('');
        fields.card.cvc.onChange('');
        fields.card.expiry.onChange('');
    };

    if (!newCard && hasSavedCard) {
        return <CreditCard card={card} onChangeCard={() => onChangeCard(true)} />;
    }

    return (
        <React.Fragment>
            <CreditCardForm field={fields.card} />
            {hasSavedCard ? (
                <Button variant="link" onClick={() => onChangeCard(false)}>
                    <FormattedMessage id="subscription.card.use_saved" defaultMessage="Use Saved Card" />
                </Button>
            ) : null}
        </React.Fragment>
    );
};

export default CardPaymentOption;
