import React from 'react';
import classnames from 'classnames';
import WithTooltip from '@/app.components/forms/WithTooltip';

import IconNoInterest from '@/../../resources/assets/svg/conditions/face-lead_expressed_no_interest_small.svg';
import IconMoreInfo from '@/../../resources/assets/svg/conditions/face-lead_requested_info_small.svg';
import IconVisit from '@/../../resources/assets/svg/conditions/face-lead_requested_visit_small.svg';
import IconBid from '@/../../resources/assets/svg/conditions/face-lead_made_bid_small.svg';
import IconDeal from '@/../../resources/assets/svg/conditions/face-lead_made_deal_small.svg';

const PrivateLock = () => (
    <WithTooltip message="Private, we never publish this">
        <i className=" glyphicon glyphicon-lock-locked">&nbsp;</i>
    </WithTooltip>
);

const PrivateComment = ({ text }) =>
    text ? (
        <p className="c-feedback__comment--private">
            <PrivateLock />
            {text}
        </p>
    ) : null;

const feedbackTypes = ['no-interest', 'more-info', 'visit', 'bid', 'deal'];

const feedbackLabel = {
    'more-info': 'Info',
    visit: 'Visit',
    bid: 'Bid',
    deal: 'Deal',
    'no-interest': 'No Interest',
};

type Props = {
    type?: any;
    title?: any;
    label?: any;
    comment?: any;
    privateComment?: any;
};

const PropertiesFeedback: React.FC<Props> = props => {
    const { type, title, label, comment, privateComment } = props;

    const className = classnames('c-feedback', {
        'c-feedback--no-interest': type === 'no-interest',
        'c-feedback--more-info': type === 'more-info',
        'c-feedback--visit': type === 'visit',
        'c-feedback--bid': type === 'bid',
        'c-feedback--deal': type === 'deal',
    });

    const feedbackTypeIndex = feedbackTypes.indexOf(type);

    const icons = {
        'no-interest': IconNoInterest,
        'more-info': IconMoreInfo,
        visit: IconVisit,
        bid: IconBid,
        deal: IconDeal,
    };

    return (
        <div className={className}>
            {feedbackTypeIndex >= 0 ? (
                <div className="c-feedback__status">
                    <img src={icons[type]} alt={type} className="c-feedback__icon" />
                    <div className="c-feedback__status-label">{label ?? feedbackLabel[type]}</div>
                </div>
            ) : null}
            <div className="c-feedback__text">
                {title ? <p className="c-feedback__title">{title}</p> : null}
                {comment ? <p className="c-feedback__comment">{comment}</p> : null}
                <PrivateComment text={privateComment} />
            </div>
        </div>
    );
};

export default PropertiesFeedback;
