import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Status from '../../app.components/elements/Status/Status';

export default class ContactAvatar extends Component {
    static propTypes = {
        backgroundColor: PropTypes.string,
        className: PropTypes.string,
        image: PropTypes.string,
        large: PropTypes.bool,
        status: PropTypes.string,
    };

    static defaultProps = {
        backgroundColor: '#D7D8D9',
    };

    render() {
        const { className, status, image, backgroundColor } = this.props;

        let styles = { backgroundColor };
        let placeholder = null;
        if (image && (image.includes('http') || image.includes('data'))) {
            styles = { backgroundImage: `url("${image}")` };
        } else {
            placeholder = image;
        }

        const avatarClasses = classnames('c-avatar', {
            'c-avatar-large': this.props.large,
        });

        return (
            <div className={className}>
                <div style={styles} className={avatarClasses}>
                    {placeholder}
                </div>
                {status && <Status status={status} />}
            </div>
        );
    }
}
