import { put } from 'redux-saga/effects';
import { CompaniesRequests } from '../../../requests/index';
import { setCompanyTokens } from '../../actions';
import apiCall from '../Effects/apiCall';

export default function* onFetchCompanyTokens({ companyId, resolve }) {
    const tokens = yield apiCall(new CompaniesRequests().tokens, companyId);
    yield put(setCompanyTokens(companyId, tokens));

    if (resolve) {
        resolve();
    }
}
