import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PortalOverview from '@/app.domains/channels/PortalOverview';
import { getChannelsInfoByType, getCompany } from '../../../app.redux/selectors';

const ChannelsPortalsCreatePage = props => {
    return <PortalOverview {...props} />;
};

const mapStateToProps = createStructuredSelector({
    channels: getChannelsInfoByType('portal'),
    company: getCompany,
});

export default connect(mapStateToProps)(ChannelsPortalsCreatePage);
