import gql from 'graphql-tag';
import { PreferenceFormFragment } from '@/graphql/fragments/contactPreference';

export const GET_LEAD_PREFERENCE_QUERY = gql`
    query GetLeadPreference($contactId: ID!, $preferenceId: ID!) {
        contact(id: $contactId) {
            id
            archived
            ... on Lead {
                preference(id: $preferenceId) {
                    ...PreferenceFormFragment
                }
            }
        }
    }
    ${PreferenceFormFragment}
`;
