import { Button } from '@sweepbright/uikit';
import React, { FC, useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { exhaustiveCheck } from 'ts-exhaustive-check';
import { FormattedMessage } from 'react-intl-sweepbright';
import { ScheduleEventType } from '@/graphql/generated/types';

const messages = defineMessages({
    cancelVisit: {
        id: 'modals.event-card-confirmation.cancelVisit',
        defaultMessage: 'Do you want to cancel this visit?',
    },
    cancelOpenHome: {
        id: 'modals.event-card-confirmation.cancelOpenHome',
        defaultMessage: 'Do you want to cancel this open home?',
    },
    cancelAuction: {
        id: 'modals.event-card-confirmation.cancelAuction',
        defaultMessage: 'Do you want to cancel this auction?',
    },
});

export const EventCardCancelConfirmation: FC<{
    onConfirm: () => void;
    onReject: () => void;
    eventType: ScheduleEventType;
}> = ({ onConfirm, onReject, eventType }) => {
    const messageText = useMemo(() => {
        switch (eventType) {
            case ScheduleEventType.Auction:
                return messages.cancelAuction;
            case ScheduleEventType.Visit:
                return messages.cancelVisit;
            case ScheduleEventType.Openhome:
                return messages.cancelOpenHome;
            default: {
                return exhaustiveCheck(eventType);
            }
        }
    }, [eventType]);

    return (
        <div className="flex flex-col items-center p-4">
            <p>
                <FormattedMessage {...messageText} />
            </p>
            <div className="flex space-x-4">
                <Button variant="danger" onClick={onConfirm}>
                    <FormattedMessage id="modals.event-card-confirmation.yes" defaultMessage="Yes" />
                </Button>
                <Button onClick={onReject}>
                    <FormattedMessage id="modals.event-card-confirmation.no" defaultMessage="No" />
                </Button>
            </div>
        </div>
    );
};
