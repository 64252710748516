// @ts-nocheck
// eslint-disable: no-shadowed-variable
import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { load } from 'exifreader';
import filesize from 'file-size';
import { fromJS, List } from 'immutable';
import { Image } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import Input from '../forms/Input/Input';
import { useModal } from './AbstractModal';

type Props = {
    title: React.ReactNode;
    onSubmit: (values: { file: File; fileDataUrl: ArrayBuffer; equirectangular: boolean }) => void;
    allowedFileTypes: string[];
    limits: {
        size: number;
    };
};

const defaultProps = {
    allowedFileTypes: [],
    limits: {
        size: 25,
    },
};

const FileUploadModal: React.FunctionComponent<Props> = (props, ref) => {
    const { limits = defaultProps.limits, allowedFileTypes = defaultProps.allowedFileTypes } = props;
    const [file, setFile] = React.useState<File | ''>('');
    const [fileDataUrl, setFileDataUrl] = React.useState<ArrayBuffer | null>(null);
    const [equirectangular, setEquirectangular] = React.useState<boolean>(false);
    /**
     * Data URLs of images that have been
     * uploaded
     */
    const fetchedRefs = React.useRef<{ [filename: string]: string | null }>({});

    const [uploading, setUploading] = React.useState(false);
    const [errors, setErrors] = React.useState(List());
    // expose imperative handlers for the parent to control the modal
    // this way of handling the modals should be removeds
    const { show, handleClose } = useModal(false, ref);

    const onSubmit = event => {
        event.preventDefault();

        // Check for files that exceed the limit

        let errors = List();
        if (filesize(file!.size).to('MB') > limits.size) {
            errors = errors.push(
                <span className="help-block">
                    <FormattedMessage
                        id="forms.upload-file.errors.size"
                        defaultMessage="Your file size is over the maximum limit of {size}MB"
                        values={{ size: limits.size }}
                    />
                </span>,
            );
        }

        if (allowedFileTypes.length && !allowedFileTypes.includes(file!.type)) {
            errors = errors.push(
                <span className="help-block">
                    <FormattedMessage
                        id="forms.upload-file.errors.type"
                        defaultMessage="{name} is not allowed"
                        values={{ name: file!.name }}
                    />
                </span>,
            );
        }

        // Update errors
        setErrors(errors);
        if (errors.count() > 0) {
            return;
        }

        // Submit form
        handleClose();
        props.onSubmit({
            file: file!,
            fileDataUrl: fileDataUrl!,
            equirectangular,
        });
    };

    const handleCancel = () => {
        handleClose();
        setFileDataUrl(null);
        setFile('');
        setUploading(false);
    };

    /**
     * Get the contents of the currently picked image
     * as a data URL
     */
    const getImageDataUrl = fileObject => {
        if (!fileObject || !fileObject.type.includes('image')) {
            setUploading(false);

            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(fileObject);
        reader.onload = () => {
            fetchedRefs.current[fileObject.name] = reader.result ? reader.result.toString() : null;
            setFileDataUrl(reader.result);
            setUploading(false);
        };
        reader.onerror = () => {
            setUploading(false);
        };

        // Parse exif data from image;
        const exifReader = new FileReader();
        exifReader.onload = (event: ProgressEvent) => {
            try {
                if (event.target !== null) {
                    const exif = fromJS(load((event.target as FileReader).result));

                    if (exif.getIn(['ProjectionType', 'value']) === 'equirectangular') {
                        setEquirectangular(true);
                    }
                }
            } catch (error) {
                //
            }
        };
        // https://github.com/mattiasw/ExifReader/blob/master/examples/html/main.js#L37
        exifReader.readAsArrayBuffer(fileObject.slice(0, 128 * 1024));
    };

    const onFileChange = event => {
        event.persist();
        const fileObject: File = event.currentTarget.files[0];
        const filename: string = fileObject ? fileObject.name : '';
        const fileType = (fileObject && fileObject.type) || '';

        // Clear errors and start the loader if we haven't
        // parsed this image already (and if it's an image)
        setErrors(List());
        setUploading(Boolean(filename) && !fetchedRefs.current[filename] && fileType.includes('image'));
        setFile(fileObject);
        getImageDataUrl(fileObject);
    };

    const classes = { labelClassName: 'col-sm-3', wrapperClassName: 'col-sm-9' };

    return (
        <Modal show={show} onHide={handleClose}>
            <form onSubmit={onSubmit} className="form-horizontal">
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ImagePreview uploading={uploading} fileDataUrl={fileDataUrl} />
                    <Input
                        type="file"
                        accept={allowedFileTypes}
                        label={<FormattedMessage id="forms.upload-file.file" defaultMessage="File" />}
                        value=""
                        onChange={onFileChange}
                        onBlur={null}
                        {...classes}
                        error={errors.count() > 0 && errors.first()}
                        touched
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCancel}>
                        <FormattedMessage id="forms.upload-file.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button type="submit" variant="primary" disabled={!file}>
                        <FormattedMessage id="forms.upload-file.cta" defaultMessage="Upload" />
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default React.forwardRef(FileUploadModal);

function ImagePreview({ uploading, fileDataUrl }: { uploading: boolean; fileDataUrl: string }) {
    if (uploading) {
        return <LoadingIndicator />;
    }

    if (fileDataUrl) {
        return <Image src={fileDataUrl as string} thumbnail className="center-block mb-2" />;
    }

    return null;
}
