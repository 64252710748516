// @ts-nocheck
import React, { ReactNode } from 'react';
import { OrderedSet, Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { useField } from 'formik';
import { withProps } from 'recompose';
import { useToasts } from '@sweepbright/notifications';
import EmptyAlert from '@/app.components/empty/EmptyAlert';
import FileRepository from '@/app.utils/services/Repositories/FileRepository';
import wrapWithPromise from '@/app.utils/services/wrapWithPromise';
import FormPanel, { ActionItem } from '@/app.components/forms/FormPanel/FormPanel';
import Resumable from '@/app.components/forms/Resumable';
import Requests from '@/app.domains/properties/Requests';
import CreateDocument from '@/app.components/modals/CreateDocument';
import FileEditionModal from '@/app.components/modals/FileEditionModal';
import FileRequestModal from '@/app.components/modals/FileRequestModal';
import {
    PROPERTY_UNIT_DETAILS_DOCUMENTS_REQUESTS,
    PROPERTY_UNIT_DETAILS_PLANS_REQUESTS,
    PROPERTY_DETAILS_DOCUMENTS_REQUESTS,
    PROPERTY_DETAILS_PLANS_REQUESTS,
} from '@/app.routing/routes';
import { FileData } from '@/requests/SweepbrightCrudRequest';
import withFormikFileUpload, { WithFileUploadProps } from '@/app.utils/Decorators/withFormikFileUpload';
import withViewed, { WithViewedProps } from '@/app.utils/Decorators/withViewed';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import { ModalProps } from '@/app.components/modals/AbstractModal';
import { FileUploadLimit, limits } from '@/app.config/uploads';
import useUser from '@/app.hooks/useUser';
import useOffice from '@/app.hooks/useOffice';
import Document from '../../../app.components/card/DocumentCard';

type Props = {
    property: Map<string, any>;
    editingDisabled?: boolean;
    handleSubmit: (attributes: Record<string, any>) => void;
    setDisabled: (args: boolean) => void;
    createBucket: (args: any) => void;
    createDocumentForEstate: (estateId: string, attributes: Record<string, any>) => void;
    config: Config;
} & WithViewedProps &
    WithFileUploadProps;

const DocumentsListManager: React.FunctionComponent<Props> = props => {
    const {
        property,
        editingDisabled,
        open,
        viewed = OrderedSet<string>(),
        config: {
            fieldName,
            documentType,
            createBucketFileType,
            requestModalProps,
            requestsType,
            editModalTitle,
            requestSuccessToast,
            formPanel,
            emptyAlertText,
            fileUploadLimit,
        },
    } = props;
    const [{ value: documents }] = useField(fieldName);
    const { addSuccess, addError } = useToasts();
    const openCreateDocumentsModal = () => open('create_document');
    const openRequestFile = () => open('file_request');

    const user = useUser();
    const office = useOffice(user.getIn(['office', 'data', 'id']));

    const initialMessage = office.getIn(['settings', 'data', 'email_property_documents_request']) || '';

    const resumableRef = React.useRef<ModalProps>();
    const documentsRef = React.useRef<{
        [identifier: string]: ModalProps;
    }>({});

    const actions = ([
        documentType === 'certificates'
            ? {
                  label: <FormattedMessage id="forms.actions.documents.create" defaultMessage="Create Documents" />,
                  handler: openCreateDocumentsModal,
              }
            : null,
        {
            label: <FormattedMessage id="forms.actions.documents.request" defaultMessage="Request Via Email" />,
            handler: openRequestFile,
        },
        {
            divider: true,
        },
        {
            label: <FormattedMessage id="forms.actions.documents.upload" defaultMessage="Upload..." />,
            handler: () => resumableRef.current && resumableRef.current!.open(),
        },
    ] as (ActionItem | null)[]).filter((it): it is ActionItem => it !== null);

    const repository = new EstateRepository(property);

    const requestsPagePath = getRequestsPath(repository, documentType);

    const validDocuments = documents?.filter(document => !document.deleted) ?? [];

    const handleCreateDocument = (attributes: Record<string, any>) => {
        props.createDocumentForEstate?.(property.get('id'), attributes);
    };

    const addDocument = (file: any) => {
        // disable the form submission while the document is been uploaded
        props.setDisabled(true);
        props.handleAddFile(file, { private: true });
    };

    const handleDocumentCreated = () => {
        addSuccess({
            title: <FormattedMessage id="modal.create-document.success.title" defaultMessage="Document Created" />,
            message: (
                <FormattedMessage
                    id="modal.create-document.success.body"
                    defaultMessage="The document will be sent to you via email or via an alternative delivery method depending on your document management application."
                />
            ),
        });
        props.close();
    };

    const handleSubmitRequest = (attributes: Record<string, any>) => {
        props.createBucket({
            ...attributes,
            file_type: createBucketFileType,
            estate_id: property.get('id'),
        });
    };

    const handleSubmittedRequest = () => {
        addSuccess(requestSuccessToast);
        props.close();
    };

    const handleForbiddenRequest = () => {
        addError({
            message: <FormattedMessage id="unauthorised_403" />,
        });
        props.close();
    };

    const handleFailedRequest = () => {
        addError({
            message: <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />,
        });
        props.close();
    };

    const renderDocument = (document: FileData, key: number) => {
        const repository = new FileRepository(property.get('id'), document);
        const identifier = repository.getIdentifier();
        const hasBeenViewed = viewed.includes(document.id);

        return (
            <li key={key}>
                <Document
                    type={documentType}
                    document={document}
                    property={property}
                    onClick={documentsRef.current[identifier]?.open}
                    unread={!hasBeenViewed}
                    testId={`document-${key}`}
                />
                <FileEditionModal
                    type={documentType}
                    disabled={editingDisabled}
                    ref={(modal: ModalProps) => (documentsRef.current[identifier] = modal)}
                    file={document}
                    title={editModalTitle}
                    onSubmit={props.handleFileAttributesUpdated}
                    property={property}
                    onFileDelete={props.handleFileDeleted}
                    onEntered={() => {
                        props.view(document.id);
                    }}
                />
            </li>
        );
    };

    return (
        <>
            <div className="c-spacer-top-l">
                <Requests to={requestsPagePath} propertyId={property.get('id')} type={requestsType} />
                <Resumable
                    ref={(ref: ModalProps) => (resumableRef.current = ref)}
                    url={`/estates/${property.get('id')}/${documentType}`}
                    fileTypes={fileUploadLimit.type}
                    maxFiles={fileUploadLimit.maxFiles}
                    maxFileSize={fileUploadLimit.size}
                    onFileAdded={addDocument}
                    onSuccess={() => {
                        // called when the file is uploaded
                        props.setDisabled(false);
                    }}
                    onProgress={props.handleFileProgress}
                    onFileError={props.handleFileError}
                    onError={() => {
                        props.handleUploadFailed();

                        addError({
                            message: <FormattedMessage id="unauthorised_403" />,
                        });
                    }}
                    testId="documents-upload-file-input"
                />
            </div>
            <FormPanel
                disableAction={editingDisabled}
                title={formPanel.title}
                action={formPanel.action}
                onAction={actions}
            >
                <div className="bc-bordered-list">
                    {validDocuments.map(renderDocument)}
                    {validDocuments.length === 0 ? <EmptyAlert body={emptyAlertText} /> : null}
                </div>
            </FormPanel>
            {props.modal(
                'file_request',
                <FileRequestModal
                    {...requestModalProps}
                    onSubmitSuccess={() => {}}
                    onSubmit={args => {
                        const wrappedSubmit = wrapWithPromise(handleSubmitRequest);

                        wrappedSubmit(args)
                            .then(() => {
                                handleSubmittedRequest();
                            })
                            .catch(error => {
                                const errorStatus =
                                    (typeof error === 'string' ? error : error?.response?.status) ||
                                    (error?._error === 'authorization' && '403') ||
                                    '';

                                if (`${errorStatus}`.includes('403')) {
                                    handleForbiddenRequest();
                                } else {
                                    handleFailedRequest();
                                }
                            });
                    }}
                    {...(initialMessage ? { initialValues: { message: initialMessage } } : {})}
                />,
            )}
            {props.modal(
                'create_document',
                <CreateDocument
                    estate={property}
                    onSubmitSuccess={() => {}}
                    onSubmit={args => {
                        const wrappedSubmit = wrapWithPromise(handleCreateDocument);

                        wrappedSubmit(args)
                            .then(() => {
                                handleDocumentCreated();
                            })
                            .catch(error => {
                                const errorStatus =
                                    (typeof error === 'string' ? error : error?.response?.status) ||
                                    (error?._error === 'authorization' && '403') ||
                                    '';

                                if (`${errorStatus}`.includes('403')) {
                                    handleForbiddenRequest();
                                } else {
                                    handleFailedRequest();
                                }
                            });
                    }}
                />,
            )}
        </>
    );
};

function getRequestsPath(repository: EstateRepository, documentType: 'certificates' | 'floor-plans'): string {
    if (repository.isUnit()) {
        if (documentType === 'certificates') {
            return PROPERTY_UNIT_DETAILS_DOCUMENTS_REQUESTS(repository.getProject().get('id'), repository.getId());
        }
        if (documentType === 'floor-plans') {
            return PROPERTY_UNIT_DETAILS_PLANS_REQUESTS(repository.getProject().get('id'), repository.getId());
        }
        throw new Error(`Document type ${documentType} is not supported`);
    }
    if (documentType === 'certificates') {
        return PROPERTY_DETAILS_DOCUMENTS_REQUESTS(repository.getId());
    }
    if (documentType === 'floor-plans') {
        return PROPERTY_DETAILS_PLANS_REQUESTS(repository.getId());
    }
}

interface Config {
    fieldName: 'documents' | 'floor_plans';
    documentType: 'certificates' | 'floor-plans';
    requestModalProps: {
        title: ReactNode;
        description: ReactNode;
        action: ReactNode;
    };
    createBucketFileType: 'floor-plan' | 'certificate';
    requestsType: 'floor-plan' | 'certificate';
    editModalTitle: ReactNode;
    requestSuccessToast: {
        title: ReactNode;
        message: ReactNode;
    };
    formPanel: {
        title: ReactNode;
        action: ReactNode;
    };
    emptyAlertText: ReactNode;
    fileUploadLimit: FileUploadLimit;
}

const documentsListConfig: Config = {
    fieldName: 'documents',
    documentType: 'certificates',
    requestModalProps: {
        title: <FormattedMessage id="modal.request-documents.title" defaultMessage="Request documents" />,
        description: (
            <FormattedMessage
                id="modal.request-documents.body"
                defaultMessage="This person will receive a link where they can upload documents. You can review the uploaded documents under Legal & Docs in this property."
            />
        ),
        action: <FormattedMessage id="modal.request-documents.cta" defaultMessage="Request Documents" />,
    },
    createBucketFileType: 'certificate',
    requestsType: 'certificate',
    editModalTitle: <FormattedMessage id="forms.legal.edit.title" defaultMessage="Edit document" />,
    requestSuccessToast: {
        title: <FormattedMessage id="modal.request-documents.success.title" defaultMessage="Request Sent" />,
        message: (
            <FormattedMessage
                id="modal.request-documents.success.body"
                defaultMessage="You will be notified when the documents are uploaded."
            />
        ),
    },
    formPanel: {
        title: <FormattedMessage id="forms.legal.documents.title" defaultMessage="Documents" />,
        action: <FormattedMessage id="forms.legal.documents.cta" defaultMessage="Add Documents" />,
    },
    emptyAlertText: <FormattedMessage id="property.documents.empty" defaultMessage="No documents yet" />,
    fileUploadLimit: limits.certificate,
};

const plansListConfig: Config = {
    fieldName: 'floor_plans',
    documentType: 'floor-plans',
    requestModalProps: {
        title: <FormattedMessage id="modal.request-plans.title" defaultMessage="Request plans" />,
        description: (
            <FormattedMessage
                id="modal.request-plans.body"
                defaultMessage="This person will receive a link where they can upload plans. You can review the uploaded plans unde rPlans in this property."
            />
        ),
        action: <FormattedMessage id="modal.request-plans.cta" defaultMessage="Request Plans" />,
    },
    createBucketFileType: 'floor-plan',
    requestsType: 'floor-plan',
    editModalTitle: <FormattedMessage id="forms.plan.edit.title" defaultMessage="Edit plan" />,
    requestSuccessToast: {
        title: <FormattedMessage id="modal.request-plans.success.title" defaultMessage="Request Sent" />,
        message: (
            <FormattedMessage
                id="modal.request-plans.success.body"
                defaultMessage="You will be notified when the plans are uploaded."
            />
        ),
    },
    formPanel: {
        title: <FormattedMessage id="forms.plans.title" defaultMessage="Plans" />,
        action: <FormattedMessage id="forms.plans.cta" defaultMessage="Add Plans" />,
    },
    emptyAlertText: <FormattedMessage id="property.plans.empty" defaultMessage="No plans yet" />,
    fileUploadLimit: limits['floor-plan'],
};

export const DocumentsList = withProps(() => ({
    config: documentsListConfig,
}))(withViewed(withFormikFileUpload<Props>('documents')(DocumentsListManager)));

export const PlansList = withProps(() => ({
    config: plansListConfig,
}))(withViewed(withFormikFileUpload<Props>('floor_plans')(DocumentsListManager)));

export default DocumentsList;
