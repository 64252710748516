import { fromJS, List, Map } from 'immutable';
import moment from 'moment';
import { languages } from '@/app.data/Localization';

export default class CompanyRepository {
    item: Map<string, any>;

    constructor(item: Map<string, any>) {
        this.item = fromJS(item || {});
    }

    getEnabledLanguages() {
        const enabledLanguages: List<string> = this.getSettings().get('languages', List());
        const companyLanguages = enabledLanguages.size
            ? languages.filter(language => enabledLanguages.includes(language.value))
            : languages;

        return List(companyLanguages);
    }

    getEnterpriseId(): string {
        return this.item.getIn(['enterprise', 'data', 'id']);
    }

    isCompany() {
        const settings = this.getSettings();

        if (settings.get('invoice_company_name')) {
            return true;
        }

        if (settings.get('invoice_first_name') || settings.get('invoice_last_name')) {
            return false;
        }

        return true;
    }

    getInvoiceName() {
        const settings = this.getSettings();

        if (!this.isCompany()) {
            return [settings.get('invoice_first_name'), settings.get('invoice_last_name')].filter(Boolean).join(' ');
        }

        return settings.get('invoice_company_name');
    }

    getInvoiceDetails() {
        const settings = this.getSettings();

        return {
            name: this.getInvoiceName(),
            vat: settings.get('invoice_vat_number'),
            email: settings.get('invoice_email'),
            address: settings.get('invoice_address'),
            county: settings.get('invoice_county'),
            country: settings.get('invoice_country'),
            zip: settings.get('invoice_zip'),
        };
    }

    isAtMaximumCapacity() {
        return this.item.get('member_count') >= this.item.get('member_allowed');
    }

    getCreatedDate() {
        return moment(this.item.get('created_at')).format('L');
    }

    getPlan() {
        return this.item.getIn(['plan', 'data'], Map());
    }

    getSettings() {
        return this.item.getIn(['settings', 'data'], Map());
    }

    getCountry() {
        return this.item.getIn(['settings', 'data', 'contact_country']);
    }

    getPostalAddress = () => {
        const settings = this.getSettings().toJS();

        const requiredFields = ['contact_city', 'contact_country', 'contact_street', 'contact_zip'];
        // check if the required address fields are present
        if (requiredFields.every(field => settings[field])) {
            // TODO: improve this method to take into account the extra fields
            // use a library perhaps
            return `${settings.contact_street}, ${settings.contact_zip} ${settings.contact_city}, ${settings.contact_country}`;
        }

        return null;
    };
}
