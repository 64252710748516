import { put } from 'redux-saga/effects';
import { CompaniesRequests } from '../../../requests/index';
import { setCompany } from '../../actions';
import apiCall from '../Effects/apiCall';

export default function* onFetchCompany({ companyId }) {
    const company = yield apiCall(new CompaniesRequests().show, companyId);

    yield put(setCompany(company));
}
