import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl-sweepbright';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import { PROPERTY } from '@/app.routing/routes';
import { useToasts } from '@sweepbright/notifications';
import useRouter from '@/app.hooks/useRouter';
import { EstateArchiverConfirmationModal } from '@/app.components/modals/EstateArchiverConfirmationModal';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { Estate } from '@/graphql/generated/types';
import { events, track } from '@/app.utils/analytics';

const DUPLICATE_ESTATE_MUTATION = gql`
    mutation DuplicateEstateMutation($estateId: ID!) {
        duplicateEstate(input: { estateId: $estateId }) {
            estate {
                id
                type
                status
                negotiation
                internalType
                projectId
                isUnit
                attributes
                createdAt
                updatedAt
                negotiator {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;
export function DuplicatePropertyMenuItem({ propertyId, isArchived }: { propertyId: string; isArchived: boolean }) {
    const { addSuccess, addError } = useToasts();
    const router = useRouter();

    const [duplicateProperty] = useMutation(DUPLICATE_ESTATE_MUTATION, {
        variables: {
            estateId: propertyId,
        },
        awaitRefetchQueries: true,
        refetchQueries: res => {
            const { isUnit } = res.data?.duplicateEstate.estate;
            if (isUnit) {
                return ['GetProjectUnits'];
            }

            return [];
        },
    });

    const handleDuplicateProperty = () => {
        track(events.PROPERTY_MENU_DUPLICATE_ITEM_CLICKED, {
            propertyId,
        });
        duplicateProperty().then(
            res => {
                const property: Estate = res.data.duplicateEstate.estate;

                addSuccess({
                    message: (
                        <FormattedMessage
                            id="toasts.notification.property_duplicated"
                            defaultMessage="Property duplicated"
                        />
                    ),
                });

                if (!property.projectId) {
                    // eslint-disable-next-line no-unused-expressions
                    router?.push(PROPERTY(property.id));
                }
            },
            err => {
                getBugsnagClient().notify(err);
                addError({
                    message: (
                        <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />
                    ),
                });
            },
        );
    };

    return (
        <Dropdown.MenuItem key="duplicate" onSelect={handleDuplicateProperty} disabled={isArchived}>
            <FormattedMessage id="actions.duplicate" defaultMessage="Duplicate" />
        </Dropdown.MenuItem>
    );
}

export function ArchivePropertyMenuItem({
    isArchived,
    disabled,
    toggleArchived,
}: {
    isArchived: boolean;
    disabled: boolean;
    toggleArchived: () => Promise<void>;
}) {
    const [showArchiveConfirmation, setShowArchivedConfirmation] = React.useState(false);

    return (
        <>
            <Dropdown.MenuItem
                key="archive"
                onSelect={() => setShowArchivedConfirmation(true)}
                disabled={disabled}
                data-testid="properties.table_view.archived_menu_option"
            >
                {isArchived ? (
                    <FormattedMessage id="actions.unarchive" defaultMessage="Unarchive" />
                ) : (
                    <FormattedMessage id="actions.archive" defaultMessage="Archive" />
                )}
            </Dropdown.MenuItem>
            <EstateArchiverConfirmationModal
                show={showArchiveConfirmation}
                onCancel={() => setShowArchivedConfirmation(false)}
                archived={isArchived}
                onConfirm={() => {
                    return toggleArchived().then(() => {
                        setShowArchivedConfirmation(false);
                    });
                }}
            />
        </>
    );
}
