import React from 'react';
import { Set } from 'immutable';
import PropTypes from 'prop-types';
import logo from '@/../../resources/assets/img/logo-black.svg';
import LoginFooter from '@/app.domains/footer/LoginFooter';
import Messages from '../../../app.components/elements/Messages/Messages';

const OnboardingForm = ({ children, handleSubmit, messages }) => (
    <div className="h-full flex flex-column">
        <form onSubmit={handleSubmit} className="login-main" noValidate>
            <div className="container login-container">
                <img src={logo} className="onboarding-logo login-logo" alt="Sweepbright Logo" />
                <div className="onboarding-sheet onboarding-sheet-no-margin">
                    <Messages messages={messages} />
                    {children}
                </div>
            </div>
        </form>
        <LoginFooter />
    </div>
);

OnboardingForm.defaultProps = {
    messages: new Set(),
};

OnboardingForm.propTypes = {
    children: PropTypes.node.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    messages: PropTypes.instanceOf(Set),
};

export default OnboardingForm;
