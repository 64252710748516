// @ts-nocheck
import React from 'react';
import produce from 'immer';
import { fromJS } from 'immutable';
import { useQuery } from '@apollo/react-hooks';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import { CONTACT_TIMELINE_PROPERTY } from '@/app.routing/routes';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import PropertyCard, { adaptProperty } from '@/app.domains/properties/PropertyCard';
import EmptyState from '@/app.components/empty/EmptyState';
import { GET_INTERACTED_PROPERTIES_QUERY } from '@/graphql/queries/contacts/getInteractedProperties';

const ContactInteractedPropertiesList = React.forwardRef(function ContactInteractedPropertiesList(
    {
        contactId,
    }: {
        contactId: string;
    },
    forwardedRef,
) {
    const { data, loading, fetchMore } = useQuery(GET_INTERACTED_PROPERTIES_QUERY, {
        variables: {
            contactId,
        },
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    });

    const interestedPropertiesConnection = data?.contact.interestedProperties;
    const notInterestedPropertiesConnection = data?.contact.notInterestedProperties;

    React.useImperativeHandle(
        forwardedRef,
        () => {
            return {
                refresh: fetchMoreBefore,
            };

            function fetchMoreBefore() {
                fetchMore({
                    variables: {
                        beforeInterestedProperties: interestedPropertiesConnection?.pageInfo.startCursor,
                        beforeNotInterestedProperties: notInterestedPropertiesConnection?.pageInfo.startCursor,
                    },
                    updateQuery: updateFetchMoreBeforeQuery,
                });
            }
        },
        [interestedPropertiesConnection, notInterestedPropertiesConnection, fetchMore],
    );

    return (
        <>
            {!loading &&
                interestedPropertiesConnection?.nodes.length === 0 &&
                notInterestedPropertiesConnection?.nodes.length === 0 && (
                    <EmptyState
                        title={
                            <FormattedMessage
                                id="contact.activity.timeline_section.empty.title"
                                defaultMessage="Whoops, no activity yet"
                            />
                        }
                        body={
                            <FormattedMessage
                                id="property.activity.timeline_section.empty.subtitle"
                                defaultMessage="Activities per property will show up here."
                            />
                        }
                    />
                )}
            <PropertySection
                title={<FormattedMessage id="modals.activity.leads.interested.section" defaultMessage="interested" />}
                contactId={contactId}
                properties={interestedPropertiesConnection?.nodes}
                loading={loading}
                hasMore={interestedPropertiesConnection?.pageInfo.hasNextPage}
                onFetchMore={() => {
                    fetchMore({
                        variables: {
                            afterInterestedProperties: interestedPropertiesConnection.pageInfo.endCursor,
                        },
                        updateQuery: updateFetchMoreQuery,
                    });
                }}
            />
            <PropertySection
                title={<FormattedMessage id="interaction.lead_not_interested" defaultMessage="not interested" />}
                contactId={contactId}
                properties={notInterestedPropertiesConnection?.nodes}
                loading={loading}
                hasMore={notInterestedPropertiesConnection?.pageInfo.hasNextPage}
                onFetchMore={() => {
                    fetchMore({
                        variables: {
                            afterNotInterestedProperties: notInterestedPropertiesConnection.pageInfo.endCursor,
                        },
                        updateQuery: updateFetchMoreQuery,
                    });
                }}
            />
        </>
    );
});

export { ContactInteractedPropertiesList };

/// components
function PropertySection({ properties = [], contactId, title, loading, hasMore, onFetchMore }) {
    if (properties.length === 0) {
        return null;
    }

    return (
        <FormPanel title={title}>
            <ul className="bc-bordered-list">
                {properties.map(property => {
                    return (
                        <li key={property.id}>
                            <PropertyCard
                                property={fromJS(adaptProperty(property))}
                                to={CONTACT_TIMELINE_PROPERTY(contactId, property.id)}
                            />
                        </li>
                    );
                })}
            </ul>
            {hasMore && !loading ? (
                <div className="text-center">
                    <Button onClick={onFetchMore} variant="link">
                        <FormattedMessage id="interaction.contact_list.load_more" defaultMessage="Load More" />
                    </Button>
                </div>
            ) : null}
        </FormPanel>
    );
}

function updateFetchMoreQuery(prevResult, { fetchMoreResult, variables }) {
    const { afterNotInterestedProperties } = variables;

    return produce(prevResult, draft => {
        let listToUpdate = afterNotInterestedProperties ? 'notInterestedProperties' : 'interestedProperties';
        // afterInterestedProperties
        draft.contact[listToUpdate].nodes = draft.contact[listToUpdate].nodes.concat(
            fetchMoreResult.contact.interestedProperties.nodes,
        );

        draft.contact[listToUpdate].pageInfo = fetchMoreResult.contact[listToUpdate].pageInfo;
    });
}

function updateFetchMoreBeforeQuery(prevResult, { fetchMoreResult }) {
    return produce(prevResult, draft => {
        for (let listToUpdate of ['notInterestedProperties', 'interestedProperties']) {
            // afterInterestedProperties
            draft.contact[listToUpdate].nodes = fetchMoreResult.contact[listToUpdate].nodes.concat(
                draft.contact[listToUpdate].nodes,
            );

            if (fetchMoreResult.contact[listToUpdate].pageInfo.startCursor) {
                draft.contact[listToUpdate].pageInfo.startCursor =
                    fetchMoreResult.contact[listToUpdate].pageInfo.startCursor;
            }
        }
    });
}
