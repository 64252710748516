import * as Immutable from 'immutable';
import { contactTypes } from '../../app.data';
import ContactRepository from './Repositories/ContactRepository';

const DEFAULT_SUPPORTED_LANGS = ['en'];

type StringSchema = {
    type: 'string';
};

type IntegerSchema = {
    type: 'integer';
};

type NumberSchema = {
    type: 'number';
};

type ObjectSchema = {
    type: 'object';
    properties: {
        [propertyId: string]: ObjectSchema | StringSchema | IntegerSchema | NumberSchema;
    };
};

export default class SchemaConverter {
    static ignored = ['geo'];

    /**
     * Get the JSON schema for a property
     */
    static getContactSchema(contact: Immutable.Map<any, unknown>, supportedLangs: string[] = DEFAULT_SUPPORTED_LANGS) {
        let type = new ContactRepository(contact).getType();
        type = type === contactTypes.INVESTOR ? contactTypes.LEAD : type;
        const schema = require(`@sweepbright/api-schemas/schemas/contacts/${type}.json`);
        const converted = SchemaConverter.convertSchema(schema, supportedLangs);

        if (converted.properties.preferences && converted.properties.preferences.type === 'object') {
            converted.properties.preferences.properties.types.type = 'object';
        }

        return converted;
    }

    /**
     * Get the JSON schema for a property
     */
    static getPropertySchema(property: Immutable.Map<string, any>, supportedLangs: string[] = DEFAULT_SUPPORTED_LANGS) {
        const type = property.get('type');
        const negotiation = property.get('negotiation');

        const schema = require(`@sweepbright/api-schemas/schemas/${type}-${negotiation}.json`);

        return SchemaConverter.convertSchema(schema, supportedLangs);
    }

    /**
     * Convert a schema to an usable one
     */
    static convertSchema(
        _schema: Immutable.Map<string, any>,
        supportedLangs: string[] = DEFAULT_SUPPORTED_LANGS,
    ): ObjectSchema {
        let schema = Immutable.fromJS(_schema);
        if (schema.get('properties') || schema.get('patternProperties')) {
            schema = schema.update('patternProperties', properties =>
                SchemaConverter.convertProperties(properties, supportedLangs),
            );
            schema = schema.update('properties', properties =>
                SchemaConverter.convertProperties(properties, supportedLangs),
            );
        }

        schema = SchemaConverter.convertProperty(schema, supportedLangs);

        return schema.toJS();
    }

    /**
     * Convert properties to usable properties
     */
    static convertProperties(
        properties: Immutable.Map<string, any>,
        supportedLangs: string[] = DEFAULT_SUPPORTED_LANGS,
    ) {
        if (!properties) {
            return properties;
        }

        return properties.map((_value, key: string) => {
            if (SchemaConverter.ignored.includes(key)) {
                return Immutable.Map();
            }

            if (!['string', 'boolean'].includes(typeof _value)) {
                const value = Immutable.fromJS(_value);
                const reference = value.get('$ref');
                if (reference) {
                    return SchemaConverter.convertReference(reference, supportedLangs);
                }
            }

            return SchemaConverter.convertProperty(_value, supportedLangs);
        });
    }

    /**
     * Replace a reference in a schema by its value
     */
    static convertReference(reference: string, supportedLangs: string[] = DEFAULT_SUPPORTED_LANGS): object {
        const path = reference.replace('https://api.sweepbright.com/', '');
        const subschema = require(`@sweepbright/api-schemas/${path}.json`);
        // TODO: Find a better solution than using the title of the json schema
        if (subschema.title === 'Localized non empty string') {
            const patternKey = Object.keys(subschema.patternProperties).find((key: string) => key.startsWith('^['));
            if (patternKey) {
                subschema.patternProperties = {
                    [`^[${supportedLangs.join('|')}]{2}$`]: subschema.patternProperties[patternKey],
                };
            } else {
                throw new Error('pattern key not found');
            }
        }

        return Immutable.fromJS(SchemaConverter.convertSchema(subschema, supportedLangs));
    }

    /**
     * Fix some JSON schema compatibilities issues
     */
    static convertProperty(
        _property: Immutable.Map<string, any>,
        supportedLangs: string[] = DEFAULT_SUPPORTED_LANGS,
    ): Immutable.Map<string, any> {
        let property = _property;
        const required = property.get('required');

        // Unwrap required[] arrays to per-property required attributes
        if (required && typeof required !== 'boolean') {
            required.forEach(key => {
                property = property.setIn(['properties', key, 'required'], true);
            });
        } else if (required && typeof required === 'boolean') {
            property = property.set('allowEmpty', !required);
        }

        // Since revalidator has issues with enums, replace
        // with a pattern instead
        const enums = property.getIn(['enum']);
        if (enums) {
            property = property.set('type', 'string');
            property = property.set('required', true);
            property = property.set('pattern', `(${enums.join('|')})`);
        }

        // Since we don't have IDs yet when validating the form
        // convert keyed objects to arrays
        const keyRegex = '^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$';
        const item = Immutable.fromJS(property.getIn(['patternProperties', keyRegex]));
        if (item && item.count()) {
            property = property.set('type', 'array');
            property = property.set('items', item);
            property = property.remove('patternProperties');
        }

        // Recursive conversion
        if (property.has('properties')) {
            property = property.update('properties', properties =>
                SchemaConverter.convertProperties(properties, supportedLangs),
            );
        }

        return property;
    }
}
