import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl-sweepbright';

function getLabelForValue(value) {
    if (value === 100) {
        return <FormattedMessage id="progress.labels.done" defaultMessage="Done" />;
    }

    if (value === 0) {
        return <FormattedMessage id="progress.labels.starting" defaultMessage="Starting" />;
    }

    return <FormattedMessage id="progress.labels.progress" defaultMessage="Uploading" />;
}

const Progress = ({ value }) => (
    <div
        className={classNames('bc-progress-bar', {
            'bc-progress-bar-empty': !value,
            'bc-progress-bar-complete': value === 100,
        })}
    >
        <div className="bc-progress-bar-container">
            <div className="bc-progress-bar-container-background" />
            <div
                className="bc-progress-bar-container-value transition-all duration-75 ease-out"
                style={{ width: `${value}%` }}
            />
        </div>
        <div className="bc-progress-bar-label">{getLabelForValue(value)}</div>
    </div>
);

Progress.propTypes = {
    value: PropTypes.number,
};

export default Progress;
