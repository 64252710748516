import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl-sweepbright';
import { NegotiatorRepository } from '../../../app.utils/services';
import toPhoneNumber from '../../../app.redux/reducers/Helpers/toPhoneNumber';
import Email from './Email';

const PortalEmail = ({ body, user, ...props }) => {
    const repository = new NegotiatorRepository(user);
    const company = user.getIn(['company', 'data'], new Map());
    const settings = company.getIn(['settings', 'data'], new Map());

    body = [
        <FormattedMessage key="hi" id="emails.greeting" defaultMessage="Hi" />,
        '',
        body,
        <FormattedMessage
            key="name"
            e
            id="emails.portals.company.name"
            defaultMessage="Agency name: {value}"
            values={{ value: company.get('name') }}
        />,
        <FormattedMessage
            key="email"
            id="emails.portals.company.email"
            defaultMessage="Agency email: {value}"
            values={{ value: settings.get('contact_email') }}
        />,
        <FormattedMessage
            key="phone"
            id="emails.portals.company.phone"
            defaultMessage="Agency phone: {value}"
            values={{ value: toPhoneNumber(company.get('contact_phone')) }}
        />,
        '',
        <FormattedMessage
            key="street"
            id="emails.portals.company.street"
            defaultMessage="Agency street: {value}"
            values={{ value: settings.get('contact_street') }}
        />,
        <FormattedMessage
            key="number"
            id="emails.portals.company.number"
            defaultMessage="Agency number: {value}"
            values={{ value: settings.get('contact_number') }}
        />,
        <FormattedMessage
            key="postcode"
            id="emails.portals.company.postcode"
            defaultMessage="Agency postcode: {value}"
            values={{ value: settings.get('contact_zip') }}
        />,
    ];

    body.push(
        <FormattedMessage
            key="city"
            id="emails.portals.company.city"
            defaultMessage="Agency city: {value}"
            values={{ value: settings.get('contact_city') }}
        />,
    );

    body.push(
        '',
        <FormattedMessage key="bye" id="emails.goodbye" defaultMessage="Kind regards" />,
        '',
        repository.getLabel(),
    );

    return <Email {...props} body={body} rel="noopener noreferrer" target="_blank" />;
};

PortalEmail.propTypes = {
    body: PropTypes.object.isRequired,
    portal: PropTypes.instanceOf(Map),
    user: PropTypes.instanceOf(Map),
};

export default PortalEmail;
