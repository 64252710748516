import React from 'react';
import { useIntl } from 'react-intl';
import useOffice from '@/app.hooks/useOffice';
import ResourceFilter from '@/shared/resource/ResourceFilter';
import useGeoTemplate from '@/app.hooks/useGeoTemplate';
import { ResourceDomainType } from '@/shared/resource/types';
import FilterGeoForm from './FilterGeoForm';

type Props = {
    searchSettings?: any;
    setSearchSettings: Function;
    domainType: ResourceDomainType;
};

const FilterGeo: React.FC<Props> = props => {
    const { domainType, searchSettings, setSearchSettings } = props;

    const intl = useIntl();

    const office = useOffice();
    const officeId = office.get('id');

    const content = {
        title: intl.formatMessage({
            id: 'properties.filter.geo',
        }),
        geo: intl.formatMessage({
            id: 'properties.filter.geo.geo',
        }),
        postcodes: intl.formatMessage({
            id: 'properties.filter.geo.postcodes',
        }),
        loading: intl.formatMessage({
            id: 'general.state.loading',
        }),
    };

    const postalValue = searchSettings?.postal_codes;
    const postalGeoValue = searchSettings?.geo_filter;

    const optionType = getOptionValue(postalValue, postalGeoValue);

    const valueLabel = content[optionType];

    const totalArray = [...(postalValue || []), ...(postalGeoValue || [])];

    const { geoTemplate: activeGeoTemplate, loading: activeLoading } = useGeoTemplate(
        officeId,
        totalArray && totalArray[0] && totalArray[0]._id,
    );

    const value = totalArray.length ? (
        <>
            {activeLoading ? (
                content.loading
            ) : (
                <>
                    {valueLabel}: {activeGeoTemplate?.title}
                    {totalArray.length > 1 ? ` (+${totalArray.length - 1})` : ''}
                </>
            )}
        </>
    ) : null;

    return (
        <ResourceFilter
            id="geo"
            label={content.title}
            domainType={domainType}
            params={searchSettings}
            customActiveLabel={() => {
                return value || null;
            }}
            setParams={setSearchSettings}
            filters={[
                {
                    type: 'custom',
                    paramName: optionType === 'geo' ? 'geo_filter' : 'postal_codes',
                    children: (params, setParams) => (
                        <div>
                            <FilterGeoForm tempSearchSettings={params} setTempSearchSettings={setParams} />
                        </div>
                    ),
                },
            ]}
        />
    );
};

export const getOptionValue = (postalValue, geoValue) => {
    if (postalValue?.length) {
        return 'postcodes';
    }

    if (geoValue?.length) {
        return 'geo';
    }

    return 'geo';
};

export default FilterGeo;
