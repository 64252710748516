import { useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect } from 'react';
import { Subject } from 'rxjs';
import { GET_PROPERTY_SCHEDULE } from '@/graphql/queries/schedule/getPropertySchedule';
import {
    GetContactScheduleQuery,
    GetContactScheduleQueryVariables,
    GetPropertyScheduleQuery,
    GetPropertyScheduleQueryVariables,
} from '@/graphql/generated/types';
import { GET_CONTACT_SCHEDULE } from '@/graphql/queries/schedule/getContactSchedule';

type ScheduleEvent = GetPropertyScheduleQuery['propertySchedule'][number];

function getLocation(event: ScheduleEvent) {
    switch (event.__typename) {
        case 'ScheduleEventVisit':
            return event.location;
        case 'ScheduleEventAuction':
        case 'ScheduleEventOpenHome':
            return event.estateLocation;
        default:
            return '';
    }
}
function getOrganizerId(event: ScheduleEvent) {
    if (event.__typename === 'ScheduleEventVisit') {
        return event.organizerId;
    }

    return null;
}

function getAttendees(event: ScheduleEvent) {
    if (event.__typename === 'ScheduleEventVisit') {
        return event.attendees;
    }

    return [];
}

function getStatus(event: ScheduleEvent) {
    if (event.__typename === 'ScheduleEventVisit') {
        return event.status;
    }

    return null;
}

const getNormalizedEvent = (event: ScheduleEvent) => {
    return {
        ...event,
        location: getLocation(event),
        organizerId: getOrganizerId(event),
        attendees: getAttendees(event),
        status: getStatus(event),
    };
};

export type NormalizedScheduleEvent = ReturnType<typeof getNormalizedEvent>;

const normalizePropertySchedule = (data: GetPropertyScheduleQuery) => {
    return {
        schedule: data.propertySchedule.map(getNormalizedEvent),
        pendingConfirmationCount: data.propertySchedulePendingConfirmation.count,
    };
};
const normalizeContactSchedule = (data: GetContactScheduleQuery) => {
    return {
        schedule: data.contactSchedule.map(getNormalizedEvent),
        pendingConfirmationCount: data.contactSchedulePendingConfirmation.count,
    };
};

const refetchSubject = new Subject<void>();

export const useRefetchPropertySchedule = () => {
    const refetch = useCallback(() => refetchSubject.next(), []);

    return refetch;
};

export const usePropertySchedule = (params: GetPropertyScheduleQueryVariables) => {
    const result = useQuery<GetPropertyScheduleQuery, GetPropertyScheduleQueryVariables>(GET_PROPERTY_SCHEDULE, {
        variables: params,
        fetchPolicy: 'cache-first',
    });

    const { refetch } = result;
    useEffect(
        () => {
            const subscription = refetchSubject.subscribe(() => {
                refetch();
            });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return {
        ...result,
        data: result.data ? normalizePropertySchedule(result.data) : null,
    };
};

export const useContactSchedule = (params: GetContactScheduleQueryVariables) => {
    const result = useQuery<GetContactScheduleQuery, GetContactScheduleQueryVariables>(GET_CONTACT_SCHEDULE, {
        variables: params,
        fetchPolicy: 'cache-first',
    });

    const { refetch } = result;
    useEffect(
        () => {
            const subscription = refetchSubject.subscribe(() => {
                refetch();
            });

            return () => {
                if (subscription) {
                    subscription.unsubscribe();
                }
            };
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return {
        ...result,
        data: result.data ? normalizeContactSchedule(result.data) : null,
    };
};
