import React, { useMemo } from 'react';
import { Button } from '@sweepbright/uikit';
import { queryCache } from 'react-query';
import PagePane from '@/app.layouts/PagePane/PagePane';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '@/app.components/icons/Icon';
import ScheduleVisitModal from '@/app.components/modals/ScheduleVisitModal/ScheduleVisitModalV2';
import ContactSchedule from '@/app.domains/contacts/ContactSchedule/ContactShedule';
import useContact from '@/app.hooks/useContact';
import useUser from '@/app.hooks/useUser';
import { isTeamMember } from '@/app.data';

export default function ContactSchedulePage({ params }) {
    const contactId = params.contact;

    const user = useUser();

    const { contact } = useContact(contactId, { cacheFirst: true });

    const contactIsArchived = contact?.archived;

    const disabled =
        isTeamMember(user) && !contact?.assignedNegotiators?.some(el => el?.id === user?.get('id'))
            ? true
            : contactIsArchived;

    const [showScheduleModal, setShowScheduleModal] = React.useState(false);

    const actions = useMemo(() => {
        const actions: React.ReactNode[] = [];
        if (!contactIsArchived) {
            actions.push(
                <Button
                    variant="primary"
                    disabled={disabled}
                    key="schedule_visit"
                    icon={<Icon icon="add-visit" size={20} />}
                    onClick={() => setShowScheduleModal(true)}
                >
                    <FormattedMessage id="pages.contact_schedule.schedule_visit" defaultMessage="Schedule Visit" />
                </Button>,
            );
        }

        return actions;
    }, [disabled, contactIsArchived]);

    return (
        <PagePane
            title={<FormattedMessage id="pages.contact_schedule.schedule" defaultMessage="Schedule" />}
            actions={actions}
        >
            <ContactSchedule contactId={contactId} />
            <ScheduleVisitModal
                show={showScheduleModal}
                onCancel={() => setShowScheduleModal(false)}
                initialValues={{
                    attendees: [contactId],
                }}
                onVisitScheduled={async () => {
                    setShowScheduleModal(false);
                    await queryCache.refetchQueries(`/contacts/${contactId}/schedule`);
                    // TODO: add notification
                }}
            />
        </PagePane>
    );
}
