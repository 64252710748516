import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';

const Row = ({ label, value }) => (
    <tr>
        {label && <th style={{ width: '30%' }}>{label}</th>}
        <td>
            {value ? (
                value
            ) : (
                <span className="text-muted">
                    <FormattedMessage id="estate.transaction_preview.no_info" defaultMessage="No info" />
                </span>
            )}
        </td>
    </tr>
);

Row.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.node,
};

export default Row;
