import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { upperCaseFirst } from 'change-case';
import { STATUS_ICONS } from '@/app.domains/channels/Portals';
import { FormattedMessage } from 'react-intl-sweepbright';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import PortalStatusWarning from '@/app.domains/channels/elements/PortalStatusWarning';
import Status from '@/app.components/elements/Status/Status';
import FormAction from '../../app.components/forms/FormAction';

class PortalConnection extends Component {
    static propTypes = {
        children: PropTypes.node,
        identifier: PropTypes.string,
        name: PropTypes.string,
        onDisconnect: PropTypes.func.isRequired,
        service: PropTypes.string.isRequired,
        showNameField: PropTypes.bool,
        status: PropTypes.string,
    };

    render() {
        const { service, children, identifier, name, showNameField, status } = this.props;

        const showName = Boolean(showNameField && name);
        const isChannelActive = status === 'active';

        return (
            <React.Fragment>
                {status && !isChannelActive && (
                    <PortalStatusWarning iconStatus={STATUS_ICONS[status]} status={status} />
                )}
                {identifier && (
                    <p>
                        <FormattedMessage id="connection.connected_as" defaultMessage="Connected as:" />{' '}
                        <small className="text-uppercase text-info">{identifier}</small>
                    </p>
                )}
                {showName && (
                    <p>
                        <FormattedMessage id="connection.channel_account_name" defaultMessage="Channel name:" />{' '}
                        <small className="text-uppercase text-info">{name}</small>
                    </p>
                )}
                {status && (
                    <p>
                        <FormattedMessage id="general.status" defaultMessage="Status" />
                        <div className="flex items-center capitalize my-1">
                            <Status
                                status={STATUS_ICONS[status]}
                                size={20}
                                style={{
                                    position: 'static',
                                    marginRight: '5px',
                                }}
                            />
                            <FormattedMessage id={`channel.status.${status}`} defaultMessage={status} />
                        </div>
                    </p>
                )}
                {children}
                <hr />
                <FormAction
                    variant="ghost-danger"
                    confirmation={
                        <FormattedMessage
                            id="components.portal_connection.disconnect_confirmation_label"
                            defaultMessage="I understand I will remove {channel} as a Publish Channel for everyone"
                            values={{
                                channel: upperCaseFirst(service),
                            }}
                        />
                    }
                    label={
                        <FormattedMessage
                            id="connection.disconnect"
                            defaultMessage="Disconnect {service}"
                            values={{ service: upperCaseFirst(service) }}
                        />
                    }
                    onClick={() => this.props.onDisconnect(service)}
                />
            </React.Fragment>
        );
    }
}

export default connect(
    createStructuredSelector({
        // if the multiple accounts feature is on,
        // we show the name field, otherwise, it is invisible to the user
        // even though the name is still set
        showNameField: getFeature('portals.multipleAccounts.enabled'),
    }),
)(PortalConnection);
