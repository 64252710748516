// @ts-nocheck
import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import Modal from '@/app.components/elements/Modal';
import { FormattedMessage } from 'react-intl-sweepbright';
import moveBotlerLegalTermsHtml from './LegalTermsMoveBotler.html';

export default function MoveBotlerLegalTermsModal({ show, onAccept, onCancel }) {
    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage
                        id="modals.movebotler.terms.title"
                        defaultMessage="Accept additional terms & conditions"
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: moveBotlerLegalTermsHtml }} />
            </Modal.Body>
            <Modal.Footer className="text-center">
                <Button onClick={onCancel} type="button">
                    Cancel
                </Button>
                <Button variant="primary" onClick={onAccept}>
                    <FormattedMessage id="modals.movebotler.terms.accept" defaultMessage="Accept" />
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
