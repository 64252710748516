import React from 'react';
import { useEstateArchiver } from '@/app.domains/properties/components/ArchiveEstateSection';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import Icon from '@/app.components/icons/Icon';
import { ArchivePropertyMenuItem, DuplicatePropertyMenuItem } from '@/app.domains/properties/PropertyContextActions';
import { EstateType } from '@/graphql/generated/types';

export default function EstateMenuCell({ estateId, estateType }: { estateId: string; estateType: EstateType }) {
    const { isArchived, toggleArchived, loading } = useEstateArchiver({ estateId });

    return (
        <Dropdown id="status_dropdown" data-testid="property-status-dropdown">
            <Dropdown.Toggle
                as="button"
                className="bg-transparent cursor-default flex items-center focus:outline-none focus:shadow-outline rounded"
                type="button"
            >
                <Icon icon="options" size={24} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="pull-right">
                {estateType !== EstateType.Project && (
                    <DuplicatePropertyMenuItem propertyId={estateId} isArchived={isArchived} />
                )}
                <ArchivePropertyMenuItem isArchived={isArchived} toggleArchived={toggleArchived} disabled={loading} />
            </Dropdown.Menu>
        </Dropdown>
    );
}
