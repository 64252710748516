import React from 'react';
import { useIntl } from 'react-intl';
import Input from '@/app.components/forms/Input/Input';
import { ResourceDomainType, ResourceFilterType } from '@/shared/resource/types';

type Props = {
    id: string;
    tempParams: any;
    setTempParams: any;
    filter: ResourceFilterType;
    intlKey: ResourceDomainType;
};

const ResourceFilterFormBoolean: React.FC<Props> = props => {
    const { id, intlKey, filter, tempParams, setTempParams } = props;

    const intl = useIntl();

    const isChecked = tempParams?.[filter.paramName];

    const label = intl.formatMessage({ id: `${intlKey}.filter.${id}.true` });

    return (
        <div className="resource-filter-form__list">
            <div className="resource-filter-form__item">
                <Input
                    label={label}
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => {
                        setTempParams(prevState => ({
                            ...prevState,
                            [filter.paramName]: !prevState?.[filter.paramName],
                        }));
                    }}
                />
            </div>
        </div>
    );
};

export default ResourceFilterFormBoolean;
