// @ts-nocheck
import React from 'react';
import { Col, FormGroup, HelpBlock, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import companySettings, { propTypes } from '../../../app.utils/Decorators/companySettings';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import FormPane from '../../../app.components/forms/FormPane';
import Input from '../../../app.components/forms/Input/Input';
import { getFeature } from '../../../app.redux/selectors/FeaturesSelector';

const ContactSettings = ({ fields, route, errors, status, handleSubmit, canShowLinkedInField }) => (
    <FormPane title={route.title} status={status} onSubmit={handleSubmit} disabled={Object.keys(errors).length > 0}>
        <FormPanel
            title={<FormattedMessage id="pages.website.user_contact_info.title" defaultMessage="Contact Info" />}
        >
            <Input
                type="email"
                label={
                    <FormattedMessage
                        id="pages.website.user_contact_info.email_label"
                        defaultMessage={'Send "Contact Us" Requests To'}
                    />
                }
                {...fields.email}
            />
        </FormPanel>
        <FormPanel
            title={
                <FormattedMessage
                    id="pages.website.social_links_panel.title"
                    defaultMessage="Footer links to social media"
                />
            }
        >
            <Input
                type="text"
                label={
                    <FormattedMessage
                        id="pages.website.social_links_panel.twitter_label"
                        defaultMessage="Link to Twitter"
                    />
                }
                {...fields.links_twitter}
                placeholder="e.g. https://twitter.com/username"
                horizontal
            />
            <Input
                type="text"
                label={
                    <FormattedMessage
                        id="pages.website.social_links_panel.instagram_label"
                        defaultMessage="Link to Instagram"
                    />
                }
                {...fields.links_instagram}
                placeholder="e.g. https://instagram.com/username"
                horizontal
            />
            <Input
                type="text"
                label={
                    <FormattedMessage
                        id="pages.website.social_links_panel.facebook_label"
                        defaultMessage="Link to Facebook"
                    />
                }
                {...fields.links_facebook}
                placeholder="e.g. https://facebook.com/username"
                horizontal
            />
            {canShowLinkedInField && (
                <Input
                    type="text"
                    label={
                        <FormattedMessage
                            id="pages.website.social_links_panel.linkedin_label"
                            defaultMessage="Link to LinkedIn"
                        />
                    }
                    {...fields.links_linkedin}
                    placeholder="e.g. https://linkedin.com/username"
                    horizontal
                />
            )}
            <FormGroup>
                <Row>
                    <Col sm={8} smPush={4}>
                        <HelpBlock>
                            <FormattedMessage
                                id="pages.website.social_links_panel.help_block"
                                defaultMessage="We display these in the footer of your website. It is okay if you leave one or all of them empty."
                            />
                        </HelpBlock>
                    </Col>
                </Row>
            </FormGroup>
        </FormPanel>
    </FormPane>
);

ContactSettings.propTypes = {
    ...propTypes,
};

export default companySettings(
    'contact',
    [
        'email',
        'links_twitter',
        'links_instagram',
        'links_facebook',
        'links_linkedin',
        'registration_number',
        'contact_email',
        'contact_phone',
        'contact_street',
        'contact_street_2',
        'contact_number',
        'contact_additional',
        'contact_box',
        'contact_zip',
        'contact_city',
        'contact_borough_or_district',
        'contact_county',
        'contact_country',
    ],
    {
        canShowLinkedInField: getFeature('footer-links-linkedin'),
    },
)(ContactSettings);
