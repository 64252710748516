import React from 'react';
import NumberFormat from 'react-number-format';
import { FormattedMessage } from 'react-intl-sweepbright';
import { getNumberSettings } from '@/app.config/accounting';

export default function Area(props) {
    const { size, units, hideUnit } = props;
    const { decimal, thousand } = getNumberSettings();
    if (!size) {
        return null;
    }

    return (
        <span>
            <NumberFormat value={size} displayType="text" thousandSeparator={thousand} decimalSeparator={decimal} />
            {!hideUnit && (
                <>
                    {' '}
                    <AreaUnitsLabel units={units} />
                </>
            )}
        </span>
    );
}

const defaultLabels = {
    are: 'are',
    sq_ft: 'sq. ft',
    sq_m: 'm<sup>2</sup>',
};

export function AreaUnitsLabel({ units }) {
    if (units === 'sq_m') {
        return (
            <FormattedMessage
                id="area.units.sq_m"
                defaultMessage={defaultLabels[units]}
                values={{
                    // eslint-disable-next-line react/display-name
                    sup: text => <sup>{text}</sup>,
                }}
            />
        );
    }

    return units ? <FormattedMessage id={`area.units.${units}`} defaultMessage={defaultLabels[units]} /> : null;
}
