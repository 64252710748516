import React from 'react';
import gql from 'graphql-tag';
import { Map } from 'immutable';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import FormPane from '@/app.components/forms/FormPane';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import UploadPhoto from '@/app.components/forms/UploadPhoto';
import Input from '@/app.components/forms/Input/Input';
import PhoneInput from '@/app.components/forms/PhoneInput/PhoneInput';
import { LanguageSwitcher } from '@/app.domains/profile/LanguageSwitcher';
import FormikWithConfirmation from '@/app.components/forms/helpers/FormikWithConfirmation';
import { fullName } from '@/app.utils/services/Helpers';
import UserRequests from '@/requests/UserRequests';
import { fetchUser } from '@/app.redux/actions';
import { useToasts } from '@sweepbright/notifications';
import { getBugsnagClient } from '@/app.config/bugsnag';

const GET_USER_QUERY = gql`
    query GetCurrentUser {
        me {
            id
            firstName
            lastName
            email
            phone
            locale
            photo
        }
    }
`;

const UPDATE_USER_MUTATION = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
            user {
                id
                firstName
                lastName
                email
                phone
                photo
                locale
            }
        }
    }
`;

const ProfileEdit = props => {
    const dispatch = useDispatch();
    const { data, loading } = useQuery(GET_USER_QUERY);

    const [updateUser] = useMutation(UPDATE_USER_MUTATION);
    const { addSuccess, addError } = useToasts();

    if (loading) {
        return null;
    }

    return (
        <FormikWithConfirmation
            initialValues={{
                firstName: data?.me.firstName ?? '',
                lastName: data?.me.lastName ?? '',
                email: data?.me.email ?? '',
                phone: data?.me.phone ?? '',
                locale: data?.me.locale ?? '',
                photoUrl: data?.me.photo?.data?.url,
                photoFileToUpload: null,
                photoRemove: false,
            }}
            onSubmit={async values => {
                try {
                    if (values.photoFileToUpload) {
                        await new UserRequests().photos('me', values.photoFileToUpload);
                    } else if (values.photoRemove && values.photoUrl) {
                        await new UserRequests().removePhoto('me');
                    }

                    await updateUser({
                        variables: {
                            input: {
                                attributes: {
                                    first_name: values.firstName,
                                    last_name: values.lastName,
                                    email: values.email,
                                    phone: values.phone,
                                    locale: values.locale,
                                },
                            },
                        },
                    });
                    addSuccess({
                        message: (
                            <FormattedMessage
                                id="notifications.save_user.success"
                                defaultMessage="User profile saved successfully"
                            />
                        ),
                    });
                } catch (err) {
                    getBugsnagClient().notify(err);
                    addError({
                        message: (
                            <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />
                        ),
                    });
                } finally {
                    // we still have the user on redux
                    // so refetch it here
                    dispatch(fetchUser());
                }
            }}
            route={props.route}
            enableReinitialize
        >
            {({ values, handleChange, setFieldValue, handleSubmit, isSubmitting }) => {
                function onAddPhoto(file) {
                    setFieldValue('photoFileToUpload', { file, content_type: 'image/png' });
                    setFieldValue('photoRemove', false);
                }

                function onRemovePhoto() {
                    setFieldValue('photoFileToUpload', null);
                    setFieldValue('photoRemove', true);
                }

                return (
                    <LayoutColumn>
                        <FormPane
                            {...({} as any)}
                            title={<FormattedMessage id="forms.profile.title" defaultMessage="My profile" />}
                            onSubmit={handleSubmit}
                            status={Map({ is_saving: isSubmitting })}
                        >
                            <FormPanel horizontal>
                                <div className="form-horizontal">
                                    <FormGroup>
                                        <Col className="col-sm-4">
                                            <ControlLabel>
                                                <FormattedMessage id="profile.picture" defaultMessage="Picture" />
                                            </ControlLabel>
                                        </Col>
                                        <Col className="col-sm-8">
                                            <UploadPhoto
                                                photo={values.photoUrl}
                                                fallback={fullName(values.firstName, values.lastName)}
                                                uploadedAt={null}
                                                onRemove={onRemovePhoto}
                                                onAdd={onAddPhoto}
                                            />
                                        </Col>
                                    </FormGroup>
                                </div>
                                <Input
                                    type="text"
                                    value={values.firstName}
                                    name="firstName"
                                    onChange={handleChange}
                                    horizontal
                                    label={
                                        <FormattedMessage id="forms.profile.first_name" defaultMessage="First Name" />
                                    }
                                />
                                <Input
                                    type="text"
                                    value={values.lastName}
                                    name="lastName"
                                    onChange={handleChange}
                                    horizontal
                                    label={<FormattedMessage id="forms.profile.last_name" defaultMessage="Last Name" />}
                                />
                                <Input
                                    horizontal
                                    label={<FormattedMessage id="forms.profile.phone" defaultMessage="Phone" />}
                                >
                                    <PhoneInput
                                        {...({} as any)}
                                        value={values.phone}
                                        name="phone"
                                        onChange={newValue => {
                                            setFieldValue('phone', newValue);
                                        }}
                                    />
                                </Input>
                                <Input
                                    type="text"
                                    value={values.email}
                                    name="email"
                                    onChange={handleChange}
                                    horizontal
                                    readOnly
                                    label={<FormattedMessage id="forms.profile.email" defaultMessage="E-mail" />}
                                />
                                <LanguageSwitcher
                                    value={values.locale}
                                    onChange={locale => setFieldValue('locale', locale)}
                                />
                            </FormPanel>
                        </FormPane>
                    </LayoutColumn>
                );
            }}
        </FormikWithConfirmation>
    );
};

export default ProfileEdit;
