import gql from 'graphql-tag';

export const GENERATE_DESCRIPTION = gql`
    mutation GenerateDescription($generateInput: GenerateDescriptionInput!) {
        generateDescription(generateInput: $generateInput) {
            propertyId
            text
        }
    }
`;

export const TRANSLATE_TEXT = gql`
    mutation Translate($translateInput: TranslateInput!) {
        translate(translateInput: $translateInput) {
            outputLanguage
            text
        }
    }
`;
