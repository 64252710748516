import React from 'react';
import omit from 'lodash/omit';
import { Link as BaseLink, withRouter, WithRouterProps } from 'react-router';

// eslint-disable-next-line: no-var-requires
const logger = require('debug')('webapp');

const Link: React.FunctionComponent<{
    to: string;
} & WithRouterProps &
    React.HTMLAttributes<HTMLAnchorElement>> = React.forwardRef(function Link(
    { to, className, router, onClick, children, ...props },
    ref,
) {
    return (
        <BaseLink
            ref={ref}
            {...omit(props, ['params', 'location', 'routes'])}
            to={to}
            className={className}
            onClick={e => {
                if (e.metaKey || e.ctrlKey) {
                    return;
                }

                // call the passed on click handler
                if (onClick) {
                    onClick(e);
                }

                // if the event default behaviour was not prevented, lets do our thing
                if (!e.defaultPrevented) {
                    e.preventDefault();

                    if (window.swUpdate) {
                        logger('forcing a hard refresh to get the latest updates');
                        window.location = (to as any) as Location;
                    } else if (to) {
                        router.push(to);
                    }
                }
            }}
        >
            {children}
        </BaseLink>
    );
});

export default withRouter(Link);
