import { toStr } from '../../../utils/formatter';

export const formatContactsQuery = (args: any) => {
    const { page = 1, limit = 10 } = args;

    const filters = {
        query: toStr(args?.query),
        labels: toStr(args?.filters?.labels),
        archived: toStr(args?.filters?.archived),
        minPrice: toStr(args?.filters?.minPrice),
        maxPrice: toStr(args?.filters?.maxPrice),
        officeId: toStr(args?.filters?.officeId),
        pricePoint: toStr(args?.filters?.price_point),
        offerStatus: toStr(args?.filters?.offerStatus),
        type: toStr(args?.filters?.type?.toLowerCase()),
        noNegotiator: toStr(args?.filters?.noNegotiator),
        negotiatorIds: toStr(args?.filters?.negotiator_ids),
        preferenceType: toStr(args?.filters?.preference_type),
        preferenceNetArea: toStr(args?.filters?.preferenceNetArea),
        preferenceSubtype: toStr(args?.filters?.preference_subtype),
        preferenceAmenity: toStr(args?.filters?.preference_amenity),
        preferencePlotArea: toStr(args?.filters?.preferencePlotArea),
        negotiation: toStr(args?.filters?.negotiation?.toLowerCase()),
        preferenceGrossArea: toStr(args?.filters?.preferenceGrossArea),
        noInterestPropertyId: toStr(args?.filters?.noInterestPropertyId),
        preferenceMinBedrooms: toStr(args?.filters?.preference_min_bedrooms),
        preferenceLiveableArea: toStr(args?.filters?.preferenceLiveableArea),
        preferenceMinCondition: toStr(args?.filters?.preference_min_condition),
        negotiatorId: toStr(args?.filters?.noNegotiator ? undefined : args?.filters?.negotiator),
    };

    const geoFilter = args?.filters?.preferenceGeoPoint;
    const postalCodes = args?.filters?.preference_postal_code;

    const isBothGeoFilter = Boolean(geoFilter?.length && postalCodes?.length);

    return {
        sortField: args?.sortField,
        sortOrder: args?.sortOrder,
        page: toStr(args.page || page),
        limit: toStr(args.limit || limit),
        useAtlasSearch: toStr(args.useAtlasSearch),
        filters: isBothGeoFilter
            ? [
                  { ...filters, preferenceGeoPoint: toStr(geoFilter) },
                  { ...filters, preferencePostalCode: toStr(postalCodes) },
              ]
            : [{ ...filters, preferenceGeoPoint: toStr(geoFilter), preferencePostalCode: toStr(postalCodes) }],
    };
};
