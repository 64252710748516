import React, { useState } from 'react';
import { useToasts } from '@sweepbright/notifications';
import AssignContactForm from '@/app.domains/properties/AssignContactForm';
import { FormattedMessage } from 'react-intl-sweepbright';
import EmptyBlock from '@/app.components/empty/EmptyBlock';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { useEstateVendors } from '@/app.hooks/useEstateVendors';
import { useEstateBuyers } from '@/app.hooks/useEstateBuyers';

type Props = {
    property: any;
    onChange: Function;
    disabled?: boolean;
    type: 'buyer' | 'owner';
};

const PropertyFormSettingsContacts: React.FC<Props> = props => {
    const { type, property, onChange, disabled } = props;

    const [loadingType, setLoadingType] = useState('');

    const isSale = property.get('negotiation') === 'sale';

    const ids = type === 'buyer' ? property?.get('buyer_ids')?.toJS() : property?.get('owner_ids')?.toJS();

    const { assignVendors, unassignVendors, loading: loadingOwners } = useEstateVendors(property.get('id'), true);

    const { assignBuyers, unassignBuyers, loading: loadingBuyers } = useEstateBuyers(property.get('id'), true);

    const { addError } = useToasts();

    const assignType = {
        buyer: 'lead',
        owner: 'vendor',
    };

    const dataByIds = (ids || []).map(el => {
        return { id: el, type: assignType[type] };
    });

    const loading = type === 'buyer' ? loadingBuyers : loadingOwners;
    const assign = type === 'buyer' ? assignBuyers : assignVendors;
    const unassign = type === 'buyer' ? unassignBuyers : unassignVendors;

    const contentBuyer = {
        title: isSale ? (
            <FormattedMessage id="forms.titles.estate.settings.buyers" defaultMessage="Buyer" />
        ) : (
            <FormattedMessage id="forms.titles.estate.settings.tenants" defaultMessage="Tenant" />
        ),
        action: isSale ? (
            <FormattedMessage id="forms.actions.estate.settings.buyers" defaultMessage="Add Buyer" />
        ) : (
            <FormattedMessage id="forms.actions.estate.settings.tenants" defaultMessage="Add Tenant" />
        ),
        empty: isSale ? (
            <FormattedMessage id="forms.actions.estate.buyer_empty" defaultMessage="No buyer added" />
        ) : (
            <FormattedMessage id="forms.actions.estate.tenant_empty" defaultMessage="No tenant added" />
        ),
    };

    const contentOwner = {
        title: <FormattedMessage id="forms.titles.estate.settings.vendors" defaultMessage="Vendor" />,
        action: <FormattedMessage id="forms.actions.estate.settings.vendors" defaultMessage="Add Vendor" />,
        empty: <FormattedMessage id="forms.actions.estate.vendor_empty" defaultMessage="No vendor added" />,
    };

    const content = {
        title: type === 'buyer' ? contentBuyer.title : contentOwner.title,
        action: type === 'buyer' ? contentBuyer.action : contentOwner.action,
        empty: type === 'buyer' ? contentBuyer.empty : contentOwner.empty,
        error: <FormattedMessage id="form.status.error" />,
        errorForbidden: <FormattedMessage id="unauthorised_403" />,
    };

    const handleError = (err: any) => {
        const isForbidden = err?.message?.includes('403');

        addError({
            message: isForbidden ? content.errorForbidden : content.error,
        });
    };

    return (
        <ErrorBoundary>
            <AssignContactForm
                ids={ids}
                disabled={disabled}
                property={property}
                contacts={dataByIds}
                title={content.title}
                action={content.action}
                type={assignType[type]}
                submitting={loadingType}
                empty={<EmptyBlock text={content.empty} />}
                loading={loading || loadingType === type}
                onContactAdded={(contactId: string) => {
                    setLoadingType(type);

                    Promise.all([assign([contactId]), onChange()])
                        .catch(handleError)
                        .finally(() => {
                            setLoadingType('');
                        });
                }}
                onContactsRemoved={(contactIds: string[]) => {
                    setLoadingType(type);

                    Promise.all([unassign(contactIds), onChange()])
                        .catch(handleError)
                        .finally(() => {
                            setLoadingType('');
                        });
                }}
                propertyOfficeId={type === 'buyer' ? property.get('office_id') : undefined}
            />
        </ErrorBoundary>
    );
};

export default PropertyFormSettingsContacts;
