import { select } from 'redux-saga/effects';
import difference from 'lodash/difference';
import { getContactById } from '../../selectors';
import { ContactsRequests } from '../../../requests/index';
import apiCall from '../Effects/apiCall';

export default function* onUpdateContactLocationPreferences({
    contactId,
    ...attributes
}: {
    contactId: string;
    location: string[];
}) {
    const contact = yield select(getContactById(contactId));

    // get the ids of the selected locations
    const newLocations = attributes.location;
    const currentLocations = contact.getIn(['preferences', 'location']).toJS();

    if (!contact) {
        throw new Error(`Contact with id ${contactId} not found in the redux store`);
    }

    const removedLocations = difference(currentLocations, newLocations);
    const addedLocations = difference(newLocations, currentLocations);

    try {
        yield addedLocations.map(areaId => apiCall(new ContactsRequests().bindArea, contactId, areaId));
    } catch (err) {
        // do nothing for now
    }

    try {
        yield removedLocations.map(areaId => apiCall(new ContactsRequests().removeArea, contactId, areaId));
    } catch (err) {
        // do nothing for now
    }
}
