import React from 'react';
import { fromJS, Map } from 'immutable';
import { Checkbox } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { Estate } from '@/graphql/generated/types';
import EstateRepository, {
    propertyArea,
    numBedrooms,
    propertyCoverImage,
} from '@/app.utils/services/Repositories/EstateRepository';
import PropertyTitle from '@/app.domains/properties/PropertyTitle';
import { StatusChip } from '@/app.domains/properties/StatusForm';
import Area from '@/app.domains/properties/Area';
import useCompany from '@/app.hooks/useCompany';
import { LastActivity } from '@/app.shared/properties/LastActivity';
import PropertyType from '@/app.domains/properties/PropertyType';
import { FormattedLocation } from '@/app.components/localize/FormattedLocation';
import PropertyImage from '@/new.domains/properties/PropertyShow/PropertyImage';
import MediaCard from '../../app.components/card/MediaCard/MediaCard';
import PropertyNegotiator from './PropertyNegotiator';

type EstateCardData = Pick<Estate, 'id' | 'attributes' | 'type' | 'negotiation' | 'archived'>;

type Props = {
    to?: string;
    testId?: string;
    actions?: any[];
    disabled?: boolean;
    selected?: boolean;
    selectable?: boolean;
    onSelect?: () => void;
    showInteraction?: boolean;
    onToggleSelect?: () => void;
    notPassSelectable?: boolean;
    property: Map<string, any> | EstateCardData;
    onClick?: (evt?: React.MouseEvent<HTMLElement>) => void;
};

const PropertyCard: React.FunctionComponent<Props> = ({ property: p, ...props }) => {
    const property: Map<string, any> = React.useMemo(() => {
        if (!Map.isMap(p)) {
            return adaptProperty(p as Estate);
        }

        return p;
    }, [p]);

    const company = useCompany();
    const repository = new EstateRepository(property, company);
    const type = repository.getType();
    const image = propertyCoverImage(property.toJS(), 'thumbnail');

    const subtitles = [
        <PropertyInfo key="line_1" property={property} />,
        <FormattedLocation key="line_2" location={property.getIn(['attributes', 'location'])?.toJS()} />,
        <LastActivity key="line_3" property={property} />,
        <ErrorBoundary key="line_4">
            <div className="flex items-center space-x-2">
                <StatusChip
                    status={property.getIn(['attributes', 'status'])}
                    negotiation={property.get('negotiation')}
                />
                <PropertyNegotiator propertyId={property.get('id')} />
            </div>
        </ErrorBoundary>,
    ];

    const checkbox = (
        <div
            style={{ padding: 4 }}
            onClick={evt => {
                evt.stopPropagation();
                evt.preventDefault();
                // eslint-disable-next-line no-unused-expressions
                props?.onToggleSelect?.();
            }}
        >
            <Checkbox value={property.get('id')} checked={props?.selected} size="lg" disabled={props.disabled} />
        </div>
    );

    return (
        <MediaCard
            useLink
            {...props}
            subtitles={subtitles}
            title={
                <div className="truncate">
                    <PropertyTitle property={property} />
                </div>
            }
            image={
                <div className="flex items-center h-full">
                    {props.selectable && checkbox}
                    <PropertyImage size="medium" src={image} type={type} />
                </div>
            }
            {...(props.notPassSelectable ? { selected: undefined, selectable: undefined } : {})}
        />
    );
};

export default React.memo(PropertyCard);

const PropertyInfo = React.memo(function PropertyInfo({ property }: { property: Map<string, any> }) {
    const items = [
        <span key="type">
            <PropertyType property={property.toJS()} />
        </span>,
    ];

    if (propertyArea(property.toJS())?.size) {
        items.push(
            <span key="area">
                <Area key="area" {...propertyArea(property.toJS())} />
            </span>,
        );
    }

    if (numBedrooms(property)) {
        items.push(
            <FormattedMessage
                id="property.features.bedrooms"
                defaultMessage="{count} {count, plural, one {bed}  other {beds}}"
                values={{ count: numBedrooms(property) ?? 0 }}
                key="numBedrooms"
            />,
        );
    }

    return <div className="space-x-2 truncate">{items}</div>;
});

export function adaptProperty(property: Estate) {
    return fromJS({
        ...property,
        internal_type: property.internalType?.toLowerCase() ?? 'standalone',
        project_id: property.projectId,
        is_project: property.isProject,
        created_at: property.createdAt,
        updated_at: property.updatedAt,
        is_archived: property.archived,
        last_interaction: property.lastInteraction
            ? {
                  data: property.lastInteraction,
              }
            : undefined,
        negotiator: {
            data: property.negotiator,
        },
    });
}
