// @ts-nocheck
import React from 'react';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import Link from '@/app.components/elements/Link';
import IconButton from '@/app.components/elements/Buttons/IconButton';

export default function BackButton({
    onClick,
    to,
}: {
    to?: string;
    onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
}) {
    const tag = to ? Link : 'button';

    return (
        <ButtonIcon<{ to?: string }>
            to={to}
            variant="link"
            type="arrow-left"
            onClick={onClick}
            tag={tag}
            size={IconButton.SIZE_SMALL}
        />
    );
}
