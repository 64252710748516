import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { GetMatchingRequirementsQuery, GetMatchingRequirementsQueryVariables } from '@/graphql/generated/types';
import { GET_MATCHING_REQUIREMENTS } from '@/graphql/queries/match/getMatchingRequirements';

const useMatchRequirements = (
    options: QueryHookOptions<GetMatchingRequirementsQuery, GetMatchingRequirementsQueryVariables>,
) => {
    return useQuery<GetMatchingRequirementsQuery, GetMatchingRequirementsQueryVariables>(
        GET_MATCHING_REQUIREMENTS,
        options,
    );
};

export default useMatchRequirements;
