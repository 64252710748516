import { fork, takeEvery } from 'redux-saga/effects';
import { REGISTER_INTERACTION_FEEDBACK } from '@/app.redux/actions/InteractionActions';
import { onRegisterInteractionFeedback } from '@/app.redux/sagas/Sagas/onRegisterInteractionFeedback';
import {
    ADD_CONTACT,
    ASSIGN_VENDOR_ESTATE,
    FETCH_CONTACT_INTERACTIONS_FOR_PROPERTY,
    FETCH_CONTACT_INTERESTS,
    FETCH_PROPERTY_INTERACTIONS,
    FETCH_VENDOR_ESTATES,
    REGISTER_INTERACTION,
    UPDATE_CONTACT,
} from '../actions';
import {
    onAddContact,
    onAssignVendorEstate,
    onFetchContactInteractionsForProperty,
    onFetchContactInterests,
    onFetchPropertyInteractions,
    onFetchVendorEstates,
    onRegisterInteraction,
    onUpdateContact,
} from './Sagas';

export default function* ContactsSaga() {
    yield [
        fork(takeEvery, ADD_CONTACT, onAddContact),
        fork(takeEvery, ASSIGN_VENDOR_ESTATE, onAssignVendorEstate),
        fork(takeEvery, FETCH_CONTACT_INTERESTS, onFetchContactInterests),
        fork(takeEvery, FETCH_CONTACT_INTERACTIONS_FOR_PROPERTY, onFetchContactInteractionsForProperty),
        fork(takeEvery, FETCH_PROPERTY_INTERACTIONS, onFetchPropertyInteractions),
        fork(takeEvery, FETCH_VENDOR_ESTATES, onFetchVendorEstates),
        fork(takeEvery, REGISTER_INTERACTION, onRegisterInteraction),
        fork(takeEvery, UPDATE_CONTACT, onUpdateContact),
        fork(takeEvery, REGISTER_INTERACTION_FEEDBACK, onRegisterInteractionFeedback),
    ];
}
