import React, { useEffect } from 'react';
import { Map } from 'immutable';
import Loadable from 'react-loadable';
import { useDebounce } from 'react-use';
import useGoogleMaps from '@/app.hooks/useGoogleMaps';
import { getCountryCoords } from '@/app.utils/services/Helpers/address';
import { LatLng } from '@/app.utils/services/Helpers/types';
import Geocoder from '../../app.utils/services/Geocoder';

const MapboxMap = Loadable({
    loader: () => import('../../app.components/map/MapboxMap'),
    loading: () => null,
});

function isValidCoords({ lat, lng }: Partial<LatLng> = {}) {
    if (!lat || Number.isNaN(lat)) {
        return false;
    }

    if (!lng || Number.isNaN(lng)) {
        return false;
    }

    return true;
}

type Props = {
    editing?: boolean;
    addressComponents: Map<string, any>;
    initialCoordinates?: LatLng;
    isDirty?: boolean;
    onGeolocationFailure?: (err: Error) => void;
    country?: string;
    // the type of property (house, apartment, land, etc)
    type: string;
};

const mapOptions = { draggable: false };

const PropertyMap: React.FunctionComponent<Props> = props => {
    const { editing, addressComponents, onGeolocationFailure, type } = props;

    const { initialCoordinates = getCountryCoords(addressComponents.get('country')) } = props;

    const [coords, setCoords] = React.useState<LatLng | undefined>(initialCoordinates);

    const geocodeAddress = React.useCallback<() => Promise<LatLng | void>>(() => {
        const components = addressComponents.filter(Boolean);

        if (components.count() > 0) {
            return Geocoder.getCoordinatesFromComponents(components);
        }

        return Promise.resolve();
    }, [addressComponents]);

    // this loads the google maps library
    const [loaded, error] = useGoogleMaps();

    const handleGeoCode = () => {
        geocodeAddress()
            .then(geocodedCoords => {
                if (geocodedCoords && isValidCoords(geocodedCoords)) {
                    setCoords(geocodedCoords);
                } else {
                    throw new Error('No valid coordinates were fetched for the given address');
                }
            })
            .catch(err => {
                if (onGeolocationFailure) {
                    onGeolocationFailure(err);
                }
            });
    };

    useEffect(() => {
        if (loaded) {
            handleGeoCode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded]);

    useDebounce(
        () => {
            if (!editing && loaded) {
                handleGeoCode();
            }
        },
        1000,
        [addressComponents, geocodeAddress, loaded, editing],
    );

    const markers = React.useMemo(
        () => [
            {
                coords,
                icon: type,
            },
        ],
        [coords, type],
    );

    if (loaded && !error) {
        return (
            <div className="c-map">
                <MapboxMap zoom={15} center={coords} options={mapOptions} markers={markers} />
            </div>
        );
    }

    return null;
};

export default PropertyMap;
