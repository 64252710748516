import React from 'react';
import ContactCard from '@/shared/contacts/ContactCard';
import ContactActions from '@/app.domains/contacts/ContactActions';
import { CONTACT } from '@/app.routing/routes';
import { ContactCardData } from '@/new.domains/contacts/types';

type Props = {
    contact: ContactCardData;
    onDelete: () => void;
    selectable?: boolean;
    selected?: boolean;
    onToggleSelect: () => void;
};

const ContactRow: React.FunctionComponent<Props> = ({ contact, selectable, selected, onToggleSelect }: Props) => {
    return (
        <ContactCard
            selectable={selectable}
            selected={selected}
            onToggleSelect={onToggleSelect}
            to={CONTACT(contact.id)}
            contact={contact}
            actions={[<ContactActions key="contact_actions" contactId={contact.id} />]}
        />
    );
};

export default ContactRow;
