export const FETCH_FEATURES = 'FEATURES/FETCH_FEATURES';
export const SET_FEATURES = 'FEATURES/SET_FEATURES';
export const UPDATE_FEATURES = 'FEATURES/UPDATE_FEATURES';
export const SET_FEATURE = 'FEATURES/SET_FEATURE';

export function fetchFeatures() {
    return { type: FETCH_FEATURES };
}

export function setFeatures(features) {
    return { type: SET_FEATURES, payload: features };
}

export function updateFeatures(features) {
    return { type: UPDATE_FEATURES, payload: features };
}

export function setFeature({ feature, value }) {
    return { type: SET_FEATURE, feature, value };
}
