import React, { useState } from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import RadioListInput from '@/app.components/forms/Radio/RadioListInput';
import { events, trackHandler } from '@/app.utils/analytics';
import { handleActiveAreaType } from '@/new.domains/properties/utils';
import { PropertySearchType } from '../../../types';
import PropertyFilterAreaRange from '../PropertyFilterAreaRange';

type Props = {
    old?: boolean;
    title: string;
    options: string[];
    fullOptions: string[];
    initActiveAreaType: string;
    initialValues?: PropertySearchType;
    onSubmit(values?: PropertySearchType);
};

export const ALLOWED_AREA_RANGE = {
    min_area: 0,
    max_area: 10000000,
};

const allowedRange: [number, number] = [ALLOWED_AREA_RANGE.min_area, ALLOWED_AREA_RANGE.max_area];

const PropertyFilterAreaForm: React.FC<Props> = props => {
    const { old, title, initialValues, onSubmit, options, fullOptions, initActiveAreaType } = props;

    const [tempSearchSettings, setTempSearchSettings] = useState<PropertySearchType | undefined>(initialValues);

    const activeAreaType = handleActiveAreaType(tempSearchSettings, options) || initActiveAreaType;

    const minValue = Number(tempSearchSettings?.[`min_${activeAreaType}_area`]) || 0;
    const maxValue = Number(tempSearchSettings?.[`max_${activeAreaType}_area`]) || 0;

    return (
        <form
            className="max-w-sm w-full"
            onSubmit={evt => {
                evt.preventDefault();
                onSubmit(tempSearchSettings);
            }}
        >
            <FormPanel
                popup
                title={title}
                action={
                    <Button
                        variant="link"
                        type="submit"
                        onClick={() => {
                            trackHandler(events.SEARCH_FILTER_CHIP_APPLY_BTN_CLICKED, {
                                search: 'properties',
                                filter: `${options.join('/')} area`,
                            });
                        }}
                        disabled={minValue && maxValue && maxValue < minValue}
                    >
                        <FormattedMessage id="buttons.apply" defaultMessage="Apply" />
                    </Button>
                }
            >
                <PropertyFilterAreaRange
                    popup
                    old={old}
                    areaType={activeAreaType}
                    max={allowedRange[1]}
                    style={{ paddingBottom: 0, marginBottom: 0 }}
                    minField={{
                        name: `min_${activeAreaType}_area`,
                        value: tempSearchSettings?.[`min_${activeAreaType}_area`],
                        onChange: value => {
                            // @ts-ignore
                            setTempSearchSettings(prevState => {
                                return { ...prevState, [`min_${activeAreaType}_area`]: value };
                            });
                        },
                    }}
                    maxField={{
                        name: `max_${activeAreaType}_area`,
                        value: tempSearchSettings?.[`max_${activeAreaType}_area`],
                        onChange: value => {
                            // @ts-ignore
                            setTempSearchSettings(prevState => {
                                return { ...prevState, [`max_${activeAreaType}_area`]: value };
                            });
                        },
                    }}
                />

                <RadioListInput
                    {...({} as any)}
                    value={activeAreaType}
                    onChange={changedAreaType => {
                        const oldAreaTypes = Object.fromEntries(
                            fullOptions.filter(el => !el.includes(changedAreaType)).map(el => [el, undefined]),
                        );

                        if (changedAreaType !== activeAreaType) {
                            // @ts-ignore
                            setTempSearchSettings(prevState => {
                                return {
                                    ...prevState,
                                    ...oldAreaTypes,
                                    [`min_${changedAreaType}_area`]:
                                        tempSearchSettings?.[`min_${activeAreaType}_area`] ||
                                        ALLOWED_AREA_RANGE.min_area,
                                    [`max_${changedAreaType}_area`]:
                                        tempSearchSettings?.[`max_${activeAreaType}_area`] ||
                                        ALLOWED_AREA_RANGE.max_area,
                                };
                            });
                        }
                    }}
                    label={<FormattedMessage id="area_type" defaultMessage="Area Type" />}
                    options={options.map(el => {
                        return {
                            value: el,
                            label: `properties.fields.structure.${el}_area.size`,
                        };
                    })}
                />
            </FormPanel>
        </form>
    );
};

export default PropertyFilterAreaForm;
