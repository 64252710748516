import gql from 'graphql-tag';

export const GET_ALL_ACCOUNTS = gql`
    query GetAllAccounts($companyId: String) {
        channelAccounts(companyId: $companyId) {
            id
            name
            office_id
            accessToken
            accessTokenSecret
            status
            channel {
                id
                name
                slug
                logoUrl
                type
            }
        }
    }
`;
