import React from 'react';
import { Link, withRouter, WithRouterProps } from 'react-router';
import { compose } from 'recompose';
import { Estate } from '@/graphql/generated/types';
import * as routes from '@/app.routing/routes';
import { FormattedMessage } from 'react-intl-sweepbright';
import { useToasts } from '@sweepbright/notifications';
import { HasModalProps } from '@/app.utils/Decorators/withModals';

import AddProperty from '@/app.shared/properties/AddPropertyForm';
import AddContact from '@/app.components/modals/AddContactModalBody';
import AddOffice from '@/app.components/forms/AddOfficeForm';
import AddVisit from '@/app.components/modals/ScheduleVisitModal/ScheduleVisitModalV2';

type Props = {
    visible: string | boolean;
    setVisible: (visible: string | boolean) => void;
    fetchCompanyOffices: (companyId: string) => void;
    handleAddContact: () => Promise<any>;
    handleAddOffice: () => Promise<any>;
    loggedIn: boolean;
    user: Map<string, any>;
    agencyWebsiteVisitButton: boolean;
};

const PaletteModals: React.FunctionComponent<Props & HasModalProps & WithRouterProps> = props => {
    const { visible, setVisible } = props;

    const { addSuccess } = useToasts();

    const onClose = () => {
        setVisible(false);
    };

    return (
        <>
            <>
                {props.modalWithPromise(
                    'palette-property',
                    // @ts-ignore
                    <AddProperty />,
                    // @ts-ignore
                    value => Promise.resolve(value),
                    { onClose },
                    ({ estate }: { estate: Estate }) => {
                        props.router.push(routes.PROPERTY_DETAILS(estate.id));
                    },
                )}

                {props.modalWithPromise(
                    'palette-contact',
                    <AddContact />,
                    props.handleAddContact,
                    { onClose },
                    contact => {
                        props.router.push(routes.CONTACT_DETAILS(contact.id));
                    },
                )}

                {props.modalWithPromise('palette-office', <AddOffice />, props.handleAddOffice, { onClose })}
            </>

            <AddVisit
                onCancel={onClose}
                show={visible === 'palette-visit'}
                onVisitScheduled={(visit: any) => {
                    addSuccess({
                        message: (
                            <span>
                                <FormattedMessage
                                    id="general.interactions.visit_scheduled"
                                    defaultMessage="Visit scheduled"
                                />{' '}
                                (<Link to={routes.PROPERTY_SCHEDULE(visit.propertyId)}>View details</Link>)
                            </span>
                        ),
                    });
                    setVisible(false);
                }}
            />
        </>
    );
};

export default compose(withRouter)(PaletteModals);
