// @ts-nocheck
import React from 'react';
import { Map } from 'immutable';
import { InputProps } from 'redux-form';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { FormattedMessage } from 'react-intl-sweepbright';
import PropertyCard from '../../app.domains/properties/PropertyCard';
import Input from './Input/Input';
import LocalizedInput from './LocalizedInput';

type Property = Map<string, any>;

type Props = {
    field: InputProps | Record<string, InputProps>;
    properties: Array<Property>;
    submitFailed: boolean;
};

const ComposeEmailForm: React.FunctionComponent<Props> = ({ submitFailed, field, properties }) => {
    return (
        <div>
            {field.name ? (
                <Input
                    type="autosize-textarea"
                    label={<FormattedMessage id="forms.label.match" defaultMessage="Message" />}
                    rows="5"
                    placeholder={<FormattedMessage id="forms.placeholders.match" defaultMessage="Enter your message" />}
                    {...field}
                    touched={submitFailed}
                    error={field.error}
                />
            ) : (
                <LocalizedInput
                    type="autosize-textarea"
                    label={<FormattedMessage id="forms.label.match" defaultMessage="Message" />}
                    rows="5"
                    placeholder={<FormattedMessage id="forms.placeholders.match" defaultMessage="Enter your message" />}
                    field={field}
                    touched={submitFailed}
                />
            )}
            <ErrorBoundary>
                <PropertiesList properties={properties} />
            </ErrorBoundary>
        </div>
    );
};

function PropertiesList({ properties }) {
    return (
        <div className="c-util-height-md overflow-auto">
            {properties.map((property: Property) => (
                <PropertyCard property={property} key={property.get('id')} />
            ))}
        </div>
    );
}

export default ComposeEmailForm;
