import React from 'react';

type Handler = () => void;
type Props = {
    children: (props: { handleItemClick: (idx: number) => Handler; openIndex: number }) => React.ReactNode;
    initialIndex: number;
};

type State = { openIndex: number };

class Accordion extends React.Component<Props, State> {
    static defaultProps = {
        initialIndex: 0,
    };

    state = {
        openIndex: this.props.initialIndex >= 0 ? this.props.initialIndex : 0,
    };

    handleItemClick = (itemIndex: number) => () => {
        this.setState({ openIndex: itemIndex });
    };

    render() {
        const { openIndex } = this.state;

        return this.props.children({ openIndex, handleItemClick: this.handleItemClick });
    }
}

export default Accordion;
