import { Map } from 'immutable';
import { InteractionData } from '@/app.data/Interactions';

export default class InteractionRepository {
    interaction: InteractionData;

    constructor(interaction: InteractionData | Map<string, any>) {
        if (Map.isMap(interaction)) {
            this.interaction = (interaction as Map<string, any>).toJS();
        } else {
            this.interaction = interaction as InteractionData;
        }
    }

    getType() {
        return this.interaction.interaction_type || 'legacy_feedback_interaction';
    }

    getCreationDate() {
        return this.interaction.created_at;
    }
}
