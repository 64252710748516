// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import FileRepository, { FileData } from '@/app.utils/services/Repositories/FileRepository';
import Progress from '../Progress';

type ThumbnailProps = {
    children: React.ReactNode;
    image: FileData;
    onClick?: (evt: any) => void;
    panorama?: boolean;
    propertyId: string;
    selectable?: boolean;
    type: string;
};

const Thumbnail: React.FunctionComponent<ThumbnailProps> = (
    { image, propertyId, type, panorama, children, selectable, onClick },
    forwardedRef,
) => {
    const repository = new FileRepository(propertyId, image);
    const getImagePath = () => {
        const imagePath = repository.getFileUrl(type, 'thumbnail');

        return imagePath || '';
    };

    const containerClasses = classNames('image-tile-container', {
        'image-tile-container--panorama': panorama,
    });

    const inner = repository.isUploading() ? (
        <div className="image-tile-container image-tile-container--progress">
            <div className="c-square-thumbnail">
                <Progress value={repository.getProgress()} />
            </div>
        </div>
    ) : (
        <div className={containerClasses} onClick={onClick} role="button" tabIndex={0}>
            <div
                style={{
                    backgroundImage: `url(${getImagePath()})`,
                }}
                className={classNames('c-square-thumbnail', {
                    'c-square-thumbnail--draggable': !selectable,
                })}
            />
            {children}
        </div>
    );

    return (
        <li className="c-tile-list__tile image-tile" ref={forwardedRef}>
            {inner}
        </li>
    );
};

export default React.forwardRef(Thumbnail);
