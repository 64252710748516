import apartmentStructure from '@sweepbright/api-schemas/schemas/structures/apartment.json';
import commercialStructure from '@sweepbright/api-schemas/schemas/structures/commercial.json';
import houseStructure from '@sweepbright/api-schemas/schemas/structures/house.json';
import officeStructure from '@sweepbright/api-schemas/schemas/structures/office.json';
import landStructure from '@sweepbright/api-schemas/schemas/structures/land.json';
import parkingStructure from '@sweepbright/api-schemas/schemas/structures/parking.json';
import Immutable, { List } from 'immutable';
import { defineMessages } from 'react-intl';
import '@/app.data/Translations/Rooms';
import sortBySet from './Helpers/sortBySet';

export const baseAreasByPropertyType = {
    apartment: ['liveable_area', 'gross_area', 'loi_carrez_area'],
    commercial: ['gross_area', 'plot_area', 'display_window_length', 'ceiling_height'],
    house: ['liveable_area', 'plot_area', 'loi_carrez_area'],
    office: ['net_area', 'gross_area'],
    land: ['plot_area'],
    parking: ['gross_area'],
};

const areaOrderByType = Immutable.Map<keyof typeof baseAreasByPropertyType, List<string>>(baseAreasByPropertyType);

export const units = {
    sq_m: 'm2',
    sq_ft: 'sq ft',
    cm: 'cm',
    inch: 'inch',
    are: 'are',
    acre: 'acre',
    km: 'km',
    mi: 'mi',
};

export const unitsBySystem = Immutable.fromJS({
    imperial: {
        area: 'sq_ft',
        outside: 'acre',
        height: 'inch',
    },
    metric: {
        area: 'sq_m',
        outside: 'sq_m',
        height: 'cm',
    },
});

export const defaultUnit = 'sq_m';

export const getDefaultUnitForArea = (areaType: string, measurementSystem = 'metric', propertyType) => {
    const systemUnits = unitsBySystem.get(measurementSystem);

    switch (areaType) {
        case 'ceiling_height':
            return systemUnits.get('height');
        case 'display_window_length':
            return systemUnits.get('area');
        case 'terrace':
            return systemUnits.get('area');
        case 'liveable_area':
            return systemUnits.get('area');
        case 'net_area':
            return systemUnits.get('area');
        case 'plot_area':
            return systemUnits.get('outside');
        case 'gross_area': {
            if (!['commercial', 'apartment', 'parking'].includes(propertyType)) {
                return systemUnits.get('outside');
            }

            return systemUnits.get('area');
        }
        case 'loi_carrez_area':
            return systemUnits.get('area');
        case 'balcony': {
            return systemUnits.get('area');
        }
        case 'interior_parking': {
            return systemUnits.get('area');
        }
        case 'exterior_parking': {
            return systemUnits.get('area');
        }
        default:
            return systemUnits.get('outside');
    }
};

const structures = Immutable.fromJS({
    apartment: apartmentStructure.properties,
    commercial: commercialStructure.properties,
    house: houseStructure.properties,
    office: officeStructure.properties,
    parking: parkingStructure.properties,
    land: landStructure.properties,
});

[apartmentStructure, commercialStructure, houseStructure, officeStructure, parkingStructure].forEach(structure => {
    // Rooms added by the user are not being added to structure
    Reflect.deleteProperty(structure.properties, 'rooms');

    // We don't handle anything related to areas here. So we remove all of these
    Object.keys(structure.properties).forEach(structureType => {
        const ref = structure.properties[structureType].$ref || '';
        if (ref.indexOf('/size') > -1) {
            Reflect.deleteProperty(structure.properties, structureType);
        }
    });
});

const Rooms = Immutable.fromJS({
    apartment: Object.keys(apartmentStructure.properties),
    commercial: Object.keys(commercialStructure.properties),
    house: Object.keys(houseStructure.properties),
    office: Object.keys(officeStructure.properties),
    parking: Object.keys(parkingStructure.properties),
});

// Creates an unique list with all the room types
export const roomTypesList = Rooms.reduce((combined, types) => combined.union(types), Immutable.Set());
export default Rooms;

// Areas
export const areasByType = structures.map((properties, propertyType) =>
    sortBySet(
        properties
            .filter(property => property.has('$ref') && property.get('$ref').includes('size'))
            .keySeq()
            .toOrderedSet(),
        areaOrderByType.get(propertyType, List<string>([])),
    ),
);
export const areas = areasByType.reduce(
    (reduction, areasForType) => reduction.union(areasForType),
    Immutable.OrderedSet(),
);

// Rooms/Amentities for which an area surface is not required, grouped by property type.
export const roomsWithoutAreaLinePerType = Immutable.fromJS({
    apartment: [
        'toilets',
        'floors',
        'pool',
        'attic',
        'basement',
        'parking',
        'interior_parking',
        'exterior_parking',
        'lift',
        'guesthouse',
    ],
    commercial: [
        'toilets',
        'bathrooms',
        'parking_spaces',
        'cooling_room',
        'display_window',
        'reception_area',
        'waiting_area',
        'lift',
        'basement',
    ],
    house: [
        'toilets',
        'floors',
        'pool',
        'attic',
        'basement',
        'parking',
        'interior_parking',
        'exterior_parking',
        'lift',
        'guesthouse',
        'sewer_access',
    ],
    office: [
        'kitchens',
        'toilets',
        'bathrooms',
        'server_room',
        'print_and_copy_area',
        'lift',
        'basement',
        'parking_spaces',
        'reception_area',
        'waiting_area',
        'storage_space',
    ],
    land: ['water_access', 'fenced', 'utilities_access', 'sewer_access', 'drainage', 'road_access'],
    parking: [
        'electrical_gate',
        'manual_gate',
        'fence',
        'remote_control',
        'key_card',
        'code',
        'lift',
        'climate_control',
    ],
});

export const roomsOrderPerType = Immutable.Map({
    house: [
        'living_room',
        'kitchens',
        'bedrooms',
        'bathrooms',
        'toilets',
        'floors',
        'pool',
        'attic',
        'basement',
        'terrace',
        'garden',
        'parking',
        'interior_parking',
        'exterior_parking',
        'lift',
        'guesthouse',
    ],
    apartment: [
        'living_room',
        'kitchens',
        'bedrooms',
        'bathrooms',
        'toilets',
        'pool',
        'basement',
        'terrace',
        'garden',
        'parking',
        'interior_parking',
        'exterior_parking',
        'lift',
    ],
    parking: [],
    land: [],
    commercial: ['showrooms', 'manufacturing_areas', 'storage_rooms', 'kitchens', 'terrace', 'garden'],
    office: ['meeting_rooms', 'conference_rooms', 'open_office_spaces', 'closed_office_spaces'],
});

interface RoomOption {
    id: string;
    items?: {
        [value: string]: { id: string };
    };
}

export const roomsOptions: {
    [group: string]: RoomOption;
} = {
    negotiation: { id: 'properties.fields.negotiation' },

    location: {
        id: 'group.location',
        items: {
            'location.street': { id: 'properties.fields.location.street' },
            'location.street_2': { id: 'properties.fields.location.street_2' },
            'location.number': { id: 'properties.fields.location.number' },
            'location.addition': { id: 'properties.fields.location.addition' },
            'location.box': { id: 'properties.fields.location.box' },
            'location.postal_code': {
                id: 'properties.fields.location.postal_code',
            },
            'location.city': { id: 'properties.fields.location.city' },
            'location.borough_or_district': {
                id: 'properties.fields.location.borough_or_district',
            },
            'location.province_or_state': {
                id: 'properties.fields.location.province_or_state',
            },
            'location.country': { id: 'properties.fields.location.country' },
            'location.floor': { id: 'properties.fields.location.floor' },
        },
    },

    structure: {
        id: 'group.structure',
        items: {
            type: { id: 'properties.fields.type' },
            sub_type: { id: 'properties.fields.sub_type' },
            general_condition: { id: 'properties.fields.general_condition' },
            'construction.building.year': {
                id: 'properties.fields.construction.building.year',
            },

            // Rooms
            'structure.living_room': {
                id: 'property.fields.structure.living_room',
            },
            'structure.kitchens': { id: 'property.fields.structure.kitchens' },
            'structure.bedrooms': {
                id: 'property.fields.structure.import.bedrooms',
            },
            'structure.bathrooms': {
                id: 'property.fields.structure.bathrooms',
            },
            'structure.toilets': { id: 'property.fields.structure.toilets' },
            'structure.floors': { id: 'property.fields.structure.floors' },
            'structure.parking_spaces': {
                id: 'property.fields.structure.parking_spaces',
            },
            'structure.showrooms': {
                id: 'property.fields.structure.showrooms',
            },
            'structure.manufacturing_areas': {
                id: 'property.fields.structure.manufacturing_areas',
            },
            'structure.storage_rooms': {
                id: 'property.fields.structure.storage_rooms',
            },
            'structure.meeting_rooms': {
                id: 'property.fields.structure.meeting_rooms',
            },
            'structure.conference_rooms': {
                id: 'property.fields.structure.conference_rooms',
            },
            'structure.open_office_spaces': {
                id: 'property.fields.structure.open_office_spaces',
            },
            'structure.closed_office_spaces': {
                id: 'property.fields.structure.closed_office_spaces',
            },

            // Areas
            'structure.gross_area.size': {
                id: 'properties.fields.structure.gross_area.size',
            },
            'structure.net_area.size': {
                id: 'properties.fields.structure.net_area.size',
            },
            'structure.plot_area.size': {
                id: 'properties.fields.structure.plot_area.size',
            },
            'structure.liveable_area.size': {
                id: 'properties.fields.structure.liveable_area.size',
            },
            'structure.display_window_length.size': {
                id: 'properties.fields.structure.display_window_length.size',
            },
            'structure.ceiling_height.size': {
                id: 'properties.fields.structure.ceiling_height.size',
            },

            // Amenities
            'structure.pool': { id: 'property.fields.structure.pool' },
            'structure.attic': { id: 'property.fields.structure.attic' },
            'structure.basement': { id: 'property.fields.structure.basement' },
            'structure.terrace': { id: 'property.fields.structure.terrace' },
            'structure.garden': { id: 'property.fields.structure.garden' },
            'structure.parking': { id: 'property.fields.structure.parking' },
            'structure.lift': { id: 'property.fields.structure.lift' },
            'structure.guesthouse': {
                id: 'property.fields.structure.guesthouse',
            },
            'structure.cooling_room': {
                id: 'property.fields.structure.cooling_room',
            },
            'structure.electrical_gate': {
                id: 'property.fields.structure.electrical_gate',
            },
            'structure.manual_gate': {
                id: 'property.fields.structure.manual_gate',
            },
            'structure.fence': { id: 'property.fields.structure.fence' },
            'structure.remote_control': {
                id: 'property.fields.structure.remote_control',
            },
            'structure.key_card': { id: 'property.fields.structure.key_card' },
            'structure.code': { id: 'property.fields.structure.code' },
            'structure.climate_control': {
                id: 'property.fields.structure.climate_control',
            },
            'structure.water_access': {
                id: 'property.fields.structure.water_access',
            },
            'structure.fenced': { id: 'property.fields.structure.fenced' },
            'structure.utilities_access': {
                id: 'property.fields.structure.utilities_access',
            },
            'structure.sewer_access': {
                id: 'property.fields.structure.sewer_access',
            },
            'structure.drainage': { id: 'property.fields.structure.drainage' },
            'structure.road_access': {
                id: 'property.fields.structure.road_access',
            },
            'structure.print_and_copy_area': {
                id: 'property.fields.structure.print_and_copy_area',
            },
            'structure.reception_area': {
                id: 'property.fields.structure.reception_area',
            },
            'structure.server_room': {
                id: 'property.fields.structure.server_room',
            },
            'structure.storage_space': {
                id: 'property.fields.structure.storage_space',
            },
            'structure.waiting_area': {
                id: 'property.fields.structure.waiting_area',
            },
            'structure.display_window': {
                id: 'property.fields.structure.display_window',
            },
        },
    },

    price_rent: {
        id: 'group.price_rent',
        items: {
            // Price
            'price.published_price.amount': {
                id: 'properties.fields.price.published_price.amount',
            },
            'price.costs': { id: 'properties.fields.price.costs' },
            'price.taxes': { id: 'properties.fields.price.taxes' },
            'price.custom_price': { id: 'properties.fields.custom_price' },
        },
    },

    description: {
        id: 'group.description',
        items: {
            'description.title': { id: 'properties.fields.description.title' },
            'description.description': {
                id: 'properties.fields.description.description',
            },
        },
    },

    legal_docs: {
        id: 'group.legal_docs',
        items: {
            'energy.epc_value': { id: 'properties.fields.energy.epc_value' },
            'energy.epc_category': {
                id: 'properties.fields.energy.epc_category',
            },
            'energy.nathers': { id: 'properties.fields.energy.nathers' },
            'energy.nabers': { id: 'properties.fields.energy.nabers' },
            'energy.legal_mentions': {
                id: 'properties.fields.energy.legal_mentions',
            },
            'occupancy.available_from': {
                id: 'properties.fields.occupancy.available_from',
            },
            'energy.epc_reference': {
                id: 'properties.fields.epc_reference',
            },
            'legal.cadastral_income': {
                id: 'properties.fields.legal.cadastral_income',
            },
        },
    },

    features: {
        id: 'group.features',
        items: {
            'features.heating_cooling.central_heating': {
                id: 'properties.fields.features.heating_cooling.central_heating',
            },
            'features.heating_cooling.floor_heating': {
                id: 'properties.fields.features.heating_cooling.floor_heating',
            },
            'features.heating_cooling.individual_heating': {
                id: 'properties.fields.features.heating_cooling.individual_heating',
            },
            'features.heating_cooling.air_conditioning': {
                id: 'properties.fields.features.heating_cooling.air_conditioning',
            },
            'features.energy.gas': {
                id: 'properties.fields.features.energy.gas',
            },
            'features.energy.fuel': {
                id: 'properties.fields.features.energy.fuel',
            },
            'features.energy.electricity': {
                id: 'properties.fields.features.energy.electricity',
            },
            'features.energy.heat_pump': {
                id: 'properties.fields.features.energy.heat_pump',
            },
            'settings.internal_note': {
                id: 'properties.fields.settings.internal_note',
            },
        },
    },

    settings: {
        id: 'group.settings',
        items: {
            status: { id: 'properties.fields.status' },
            'settings.mandate.start_date': {
                id: 'properties.fields.settings.mandate.start_date',
            },
            'settings.mandate.end_date': {
                id: 'properties.fields.settings.mandate.end_date',
            },
            'settings.mandate.exclusive': {
                id: 'properties.fields.settings.mandate.exclusive',
            },
            buyer_email: { id: 'modals.import.fields.settings.buyer' },
            vendor_email: { id: 'properties.fields.vendor_email' },
            negotiator_email: { id: 'properties.fields.negotiator_email' },
            'settings.agency_commission.fixed_fee': {
                id: 'properties.fields.settings.agency_commission.fixed_fee',
            },
            'settings.agency_commission.percentage': {
                id: 'properties.fields.settings.agency_commission.percentage',
            },
            archived_at: { id: 'modal.import.fields.archived_at' },
            'settings.office.reference': { id: 'modals.import.fields.settings.office.reference' },
        },
    },
};

// negotiation
defineMessages({
    negotiation: {
        id: 'properties.fields.negotiation',
        defaultMessage: 'For Sale / To Let (required)',
    },
});
