import React from 'react';
import { Map } from 'immutable';
import { LinkContainer } from 'react-router-bootstrap';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage } from 'react-intl-sweepbright';
import { PROPERTY_MATCH } from '../../../app.routing/routes';
import DismissableAlert from './DismissableAlert';

const EstatePriceDropped: React.FunctionComponent<{ estate: Map<string, any> }> = ({ estate }) => (
    <DismissableAlert
        className="will-animate"
        title={<FormattedMessage id="alerts.estate.price-dropped.title" defaultMessage="Price Dropped, Match again?" />}
        action={
            <LinkContainer to={PROPERTY_MATCH(estate.get('id'))}>
                <Button variant="primary">
                    <FormattedMessage id="alerts.estate.price-dropped.cta" defaultMessage="Go to Match" />
                </Button>
            </LinkContainer>
        }
    >
        <p className="m0">
            <FormattedMessage
                id="alerts.estate.price-dropped.body"
                defaultMessage="Seems like the price of this property dropped. Do you want to match again?"
            />
        </p>
    </DismissableAlert>
);

export default EstatePriceDropped;
