import React from 'react';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import SideMenuLink from '@/app.components/navigations/SideMenu/SideMenuLink';
import { FormattedMessage } from 'react-intl-sweepbright';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import SideMenu from '@/app.components/navigations/SideMenu/SideMenu';
import { PROPERTY_SCHEDULE, PROPERTY_UNIT_SCHEDULE } from '@/app.routing';
import { useAuthCheck } from '@/app.hooks/auth/useAuthCheck';

export default function PropertyScheduleLayout({
    children,
    params,
}: {
    params: { property: string; unit?: string };
    children: React.ReactChildren;
}) {
    const propertyId = params.unit || params.property;

    const { data } = useAuthCheck({
        skip: !propertyId,
        variables: {
            input: [
                {
                    operation: 'view',
                    resourceId: propertyId,
                    requestId: 'settings-view',
                    resourceType: 'scheduleSettings',
                },
            ],
        },
    });

    const view = data?.authCheck?.find((item: any) => item.requestId === 'settings-view');

    const settingsView = view?.allowed;

    return (
        <LayoutContainer>
            <LayoutColumn size="small">
                <SideMenu color="gray-lighter">
                    <SideMenuLink
                        label={
                            <FormattedMessage id="navigation.property_schedule.schedule" defaultMessage="Schedule" />
                        }
                        icon="calendar"
                        to={
                            params.unit
                                ? PROPERTY_UNIT_SCHEDULE(params.property, params.unit)
                                : PROPERTY_SCHEDULE(propertyId)
                        }
                    />
                    {settingsView && (
                        <SideMenuLink
                            fuzzyMatch
                            label={
                                <FormattedMessage
                                    id="navigation.property_schedule.settings"
                                    defaultMessage="Settings"
                                />
                            }
                            icon="settings"
                            to={
                                (params.unit
                                    ? PROPERTY_UNIT_SCHEDULE(params.property, params.unit)
                                    : PROPERTY_SCHEDULE(propertyId)) + '/settings'
                            }
                        />
                    )}
                </SideMenu>
            </LayoutColumn>
            <LayoutColumn>{children}</LayoutColumn>
        </LayoutContainer>
    );
}
