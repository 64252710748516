export const handleActiveAreaType = (searchSettings: any | undefined, options: string[]) => {
    return options.find(el => {
        const value = searchSettings?.[`min_${el}_area`] || searchSettings?.[`max_${el}_area`];

        const isNumber = typeof value === 'number' && value > 0;
        const isString = typeof value === 'string' && value.length;

        if (isNumber || isString) {
            return el
                .replace('min_', '')
                .replace('max_', '')
                .replace('_area', '');
        }

        return undefined;
    });
};
