import React, { useEffect, useState, useMemo } from 'react';
import { useDebounce } from 'react-use';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl-sweepbright';
import { useOfficeNegotiatorsForSelect } from '@/app.hooks/useOfficeNegotiatorsForSelect';
import useOffice from '@/app.hooks/useOffice';
import isScrollEnd from '@/utils/isScrollEnd';

type Props = {
    selected: string[];
    setSelected: (newSelected: string[]) => void;
};

const FilterNegotiatorsSelect: React.FC<Props> = props => {
    const { selected, setSelected } = props;

    const office = useOffice();
    const officeId = office.get('id');

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [scrolled, setScrolled] = useState(false);
    const [finished, setFinished] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [debouncedQuery, setDebouncedQuery] = useState('');

    useDebounce(
        () => {
            setPage(1);
            setFinished(false);
            setDebouncedQuery(query);
        },
        500,
        [query],
    );

    const { selectOptions, loading } = useOfficeNegotiatorsForSelect({
        page,
        officeId,
        query: debouncedQuery,
        additionalIds: selected,
    });

    useEffect(() => {
        if (scrolled && !finished) {
            setPage(prevPage => prevPage + 1);
        }
    }, [scrolled, finished]);

    const dataHash = useMemo(() => {
        return data.map(el => el.value).join(',');
    }, [data]);

    useEffect(() => {
        if (!loading) {
            if (selectOptions.length > 0) {
                if (page === 1) {
                    setData(selectOptions);
                }

                if (page > 1) {
                    const filteredSelectOptions = selectOptions.filter(
                        (option: any) => !data.find(el => el.value === option.value),
                    );
                    setData(prevData => [...(prevData || []), ...filteredSelectOptions]);
                }
            }

            if (selectOptions.length === 0) {
                setFinished(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, loading, selectOptions, dataHash]);

    const filteredData = useMemo(() => {
        return data;
    }, [data]);

    const content = {
        noResults: <FormattedMessage id="general.state.no_results" defaultMessage="No results" />,
        loading: (
            <FormattedMessage
                id="modals.properties_search.ownership_pane.negotiator_field.loading_placeholder"
                defaultMessage="Loading negotiators..."
            />
        ),
        placeholder: (
            <FormattedMessage
                id="modals.properties_search.ownership_pane.negotiator_field.placeholder"
                defaultMessage="Select..."
            />
        ),
    };

    const isDisabled = data.length === 0 && loading;

    return (
        <Select
            allowClear
            showSearch
            mode="multiple"
            disabled={isDisabled}
            loading={loading}
            onSearch={setQuery}
            options={filteredData}
            maxTagCount="responsive"
            optionFilterProp="label"
            onChange={newSelected => {
                setSelected(newSelected);
            }}
            value={isDisabled ? [] : selected}
            className="filter-negotiators-select"
            popupClassName="filter-negotiators-select__popup"
            placeholder={loading ? content.loading : content.placeholder}
            notFoundContent={
                <span className="filter-negotiators-select-notfound">
                    {loading ? content.loading : content.noResults}
                </span>
            }
            onPopupScroll={event => {
                setScrolled(isScrollEnd(event));
            }}
        />
    );
};

export default FilterNegotiatorsSelect;
