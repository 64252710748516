import { useQuery } from '@apollo/react-hooks';
import { GetUserByIdQuery, GetUserByIdQueryVariables } from '@/graphql/generated/types';
import { GET_USER_BY_ID_QUERY } from '../../graphql/queries/users/getUserById';

export const useUserById = (id: string) => {
    return useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GET_USER_BY_ID_QUERY, {
        variables: { id },
        fetchPolicy: 'no-cache',
    });
};
