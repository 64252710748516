import '@/../../resources/assets/sass/styles.scss';

// Polyfill some features
import 'proxy-polyfill/proxy.min';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';

// Import dependencies
import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { initializePhraseAppEditor } from 'react-intl-phraseapp';
import AuthManager from 'auth-manager';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { createApolloClient } from '@sweepbright/webapp-shared/graphql/client';
import '@/app.config/revalidator';
import '@/app.config/accounting';
import '@/app.config/bootstrap';
import '@/app.config/stripe';
import createStore from '@/app.redux/store';
import { initBugsnag, getBugsnagClient } from '@/app.config/bugsnag';
import withIntlPolyfill from '../app.utils/services/withIntlPolyfill';
import App from '../App';
import runInitialSagas from '../app.redux/runInitialSagas';
import { registerTheme } from '../app.utils/theming/ThemeContext';

registerTheme('sweepbright', () => import('@sweepbright/uikit/build/theme_sweepbright.min.css'));
registerTheme('c21', () => import('@sweepbright/uikit/build/theme_c21.min.css'));
registerTheme('segundamano', () => import('@sweepbright/uikit/build/theme_segundamano.min.css'));

if (__CLIENT__ && __ENABLE_PHRASEAPP__) {
    const config = {
        projectId: '28e33421c97bcb506c46f5b6e88bda46',
        phraseEnabled: true,
        prefix: '[[__',
        suffix: '__]]',
        fullReparse: true,
    };
    initializePhraseAppEditor(config);
}
const apolloClient = createApolloClient();
const store = createStore();

if (__CLIENT__ && __DEVTOOLS__) {
    window.localStorage.debug = '*';
}

initBugsnag();

runInitialSagas([], AuthManager.isLoggedIn())
    .then(() => {
        withIntlPolyfill(() => {
            render(
                <ErrorBoundary>
                    <ApolloProvider client={apolloClient}>
                        <ReduxProvider store={store}>
                            <App store={store} />
                        </ReduxProvider>
                    </ApolloProvider>
                </ErrorBoundary>,
                document.querySelector('#app'),
            );
        });
    })
    .catch(err => {
        getBugsnagClient().notify(err);
    });
