import { Button } from '@sweepbright/uikit';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';

export const EventCardVisitConfirmation: FC<{ onConfirm: () => void; onReject: () => void }> = ({
    onConfirm,
    onReject,
}) => {
    return (
        <div className="flex items-center justify-between p-5 border border-solid border-gray-lighter mt-3 bg-white rounded-sm">
            <span className="text-primary">
                <FormattedMessage
                    id="modals.schedule_visit.visit_took_place_question"
                    defaultMessage="Did this visit take place?"
                />
            </span>
            <div className="flex space-x-4">
                <Button variant="primary" onClick={onConfirm}>
                    <FormattedMessage id="modals.schedule_visit.yes" defaultMessage="Yes" />
                </Button>
                <Button onClick={onReject}>
                    <FormattedMessage id="modals.schedule_visit.no" defaultMessage="No" />
                </Button>
            </div>
        </div>
    );
};
