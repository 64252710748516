import withFacultativePayload from '../../app.utils/services/withFacultativePayload';

export const SET_COMPANIES = 'companies/SET_COMPANIES';
export const FETCH_COMPANY = 'companies/FETCH_COMPANY';
export const SET_COMPANY = 'companies/SET_COMPANY';

// Features
export const FETCH_COMPANY_FEATURES = 'companies/FETCH_FEATURES';
export const SET_COMPANY_FEATURES = 'companies/SET_FEATURES';

// Admins
export const FETCH_COMPANY_ADMINS = 'companies/FETCH_COMPANY_ADMINS';
export const SET_COMPANY_ADMINS = 'companies/SET_COMPANY_ADMINS';
export const INVITE_COMPANY_ADMINS = 'companies/INVITE_COMPANY_ADMINS';
export const ARCHIVE_COMPANY_ADMIN = 'companies/ARCHIVE_COMPANY_ADMIN';
export const REMOVE_COMPANY_ADMIN = 'companies/REMOVE_COMPANY_ADMIN';
export const RESET_COMPANY_ADMINS = 'companies/RESET_COMPANY_ADMINS';

// Offices
export const FETCH_COMPANY_OFFICES = 'companies/FETCH_COMPANY_OFFICES';
export const ADD_COMPANY_OFFICE = 'companies/ADD_COMPANY_OFFICE';

// Leads
export const SET_COMPANY_LEADS = 'companies/SET_COMPANY_LEADS';
export const RESET_COMPANY_LEADS = 'companies/RESET_COMPANY_LEADS';
export const REMOVE_COMPANY_LEAD = 'companies/REMOVE_COMPANY_LEAD';

// Settings
export const UPDATE_COMPANY_SETTINGS = 'companies/UPDATE_COMPANY_SETTINGS';
export const FETCH_COMPANY_TOKENS = 'companies/FETCH_COMPANY_TOKENS';
export const SET_COMPANY_TOKENS = 'companies/SET_COMPANY_TOKENS';

export const SAVE_COMPANY_TOKEN = 'companies/SAVE_COMPANY_TOKEN';
export const DELETE_COMPANY_TOKEN = 'companies/DELETE_COMPANY_TOKEN';

export const UPDATE_PORTAL_CONFIGURATION = 'companies/UPDATE_PORTAL_CONFIGURATION';
export const DISCONNECT_CHANNEL = 'companies/DISCONNECT_CHANNEL';

// Highlights
export const FETCH_HIGHLIGHTS = 'companies/FETCH_HIGHLIGHTS';
export const SET_HIGHLIGHTS = 'companies/SET_HIGHLIGHTS';

export function fetchCompany(companyId) {
    return { type: FETCH_COMPANY, companyId };
}

export function setCompanies(companies) {
    return { type: SET_COMPANIES, companies };
}

export function setCompany(company) {
    return { type: SET_COMPANY, company };
}

export function fetchCompanyOffices(companyId) {
    return { type: FETCH_COMPANY_OFFICES, companyId };
}

export function addCompanyOffice(office) {
    return { type: ADD_COMPANY_OFFICE, office };
}

export function fetchCompanyAdmins(companyId, attributes) {
    return { type: FETCH_COMPANY_ADMINS, companyId, attributes };
}

export function setCompanyAdmins(companyId, admins) {
    return { type: SET_COMPANY_ADMINS, companyId, admins };
}

export function inviteCompanyAdmins(attributes) {
    return { type: INVITE_COMPANY_ADMINS, attributes };
}

export function archiveCompanyAdmin(companyId, memberId) {
    return { type: ARCHIVE_COMPANY_ADMIN, companyId, memberId };
}

export function removeCompanyAdmin(companyId, memberId) {
    return { type: REMOVE_COMPANY_ADMIN, companyId, memberId };
}

export function resetCompanyAdmins(companyId) {
    return { type: RESET_COMPANY_ADMINS, companyId };
}

export function setCompanyLeads(companyId, leads) {
    return { type: SET_COMPANY_LEADS, companyId, leads };
}

export function resetCompanyLeads(companyId) {
    return { type: RESET_COMPANY_LEADS, companyId };
}

export function removeCompanyLead(companyId, leadId) {
    return { type: REMOVE_COMPANY_LEAD, companyId, leadId };
}

export function updateCompanySettings(companyId, attributes) {
    return { type: UPDATE_COMPANY_SETTINGS, companyId, attributes };
}

export function fetchCompanyTokens(attributes) {
    return withFacultativePayload(FETCH_COMPANY_TOKENS, attributes, 'companyId');
}

export function setCompanyTokens(companyId, tokens) {
    return { type: SET_COMPANY_TOKENS, companyId, tokens };
}

export function fetchHighlights(companyId, ids = []) {
    return { type: FETCH_HIGHLIGHTS, companyId, ids };
}

export function setHighlights(companyId, highlights) {
    return { type: SET_HIGHLIGHTS, companyId, highlights };
}

export function fetchCompanyFeatures(companyId) {
    return { type: FETCH_COMPANY_FEATURES, companyId };
}

export function setCompanyFeatures(companyId, features) {
    return { type: SET_COMPANY_FEATURES, companyId, features };
}

export function saveCompanyToken(companyId, token) {
    return { type: SAVE_COMPANY_TOKEN, companyId, token };
}

export function deleteCompanyToken(companyId, service) {
    return { type: DELETE_COMPANY_TOKEN, companyId, service };
}
