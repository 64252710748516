// @ts-nocheck
import React from 'react';
import Loadable from 'react-loadable';
import { Tooltip, Navbar } from '@sweepbright/uikit';
import styled from '@emotion/styled';
import { Map } from 'immutable';
import { connect, useSelector } from 'react-redux';
import { withRouter, WithRouterProps, InjectedRouter } from 'react-router';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import withModals, { HasModalProps } from '@/app.utils/Decorators/withModals';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import useUser from '@/app.hooks/useUser';
import { track, events } from '@/app.utils/analytics';
import { Estate } from '@/graphql/generated/types';
import { useToasts } from '@sweepbright/notifications';
import Link from '@/app.components/elements/Link';
import Palette from '@/app.shared/palette';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { AGENCY_WEBSITE_PATH } from '@/app.utils/constants';
import * as routes from '../../app.routing/routes';
import ButtonLink from '../elements/Buttons/ButtonLink';
import Icon, { ICON_SIZE_SMALL } from '../icons/Icon';
import Logo from './Logo';
import OfficeMenu from './elements/OfficeMenu';
import UserProfileMenu from './elements/UserProfileMenu';
import { MAIN_LINKS, NavLink } from './links';
import MainNavigationMenu from './elements/MainNavigationMenu';

const AddPropertyForm = Loadable({
    loader: () => import('../../app.shared/properties/AddPropertyForm'),
    loading: () => null,
});

const AddContactModalBody = Loadable({
    loader: () => import('../modals/AddContactModalBody'),
    loading: () => null,
});

const ScheduleVisitModal = Loadable({
    loader: () => import('@/app.components/modals/ScheduleVisitModal/ScheduleVisitModalV2'),
    loading: () => null,
});

type Props = {
    fetchCompanyOffices: (companyId: string) => void;
    handleAddContact: () => Promise<any>;
    handleAddOffice: () => Promise<any>;
    loggedIn: boolean;
    user: Map<string, any>;
    agencyWebsiteVisitButton: boolean;
};

const getActiveLink = (user: Map<string, any>, router: InjectedRouter) => {
    const activeLink = MAIN_LINKS.find((link: NavLink) => {
        return !link.divider && link.isAllowedFor!(user) && link.isActive!(link.to!(user), router);
    });

    return activeLink;
};

const Navigation: React.FunctionComponent<Props & HasModalProps & WithRouterProps> = props => {
    const { fetchCompanyOffices } = props;

    const user = useUser();
    const showPalette = useFeatureFlag('command-palette');

    const companyId = user.getIn(['company', 'data', 'id']);

    React.useEffect(() => {
        if (companyId && fetchCompanyOffices) {
            fetchCompanyOffices(companyId);
        }
    }, [companyId, fetchCompanyOffices]);

    const activeLink = getActiveLink(user, props.router);

    const renderLeftNavigation = () => {
        switch (true) {
            case Boolean(activeLink && activeLink.home):
                return <OfficeMenu user={user} />;
            default:
                return null;
        }
    };

    const handleAddProperty = () => {
        props.open('property');
    };

    const handleAddContact = () => {
        props.open('contact');
    };

    const renderRightNavigation = () => {
        switch (true) {
            case activeLink && activeLink.home:
                return (
                    <React.Fragment>
                        <AddProperty onAddProperty={handleAddProperty} />
                        <AddContact onAddContact={handleAddContact} />
                        <ScheduleVisit />
                        {props.modalWithPromise(
                            'property',
                            <AddPropertyForm />,
                            value => Promise.resolve(value),
                            {},
                            ({ estate }: { estate: Estate }) => {
                                props.router.push(routes.PROPERTY_DETAILS(estate.id));
                            },
                        )}
                        {props.modalWithPromise(
                            'contact',
                            <AddContactModalBody />,
                            props.handleAddContact,
                            {},
                            contact => {
                                props.router.push(routes.CONTACT_DETAILS(contact.id));
                            },
                        )}
                    </React.Fragment>
                );

            default:
                return null;
        }
    };

    const renderAgencyWebsiteLink = () => {
        const { agencyWebsiteVisitButton } = props;

        if (!user || !user.get('id')) {
            return null;
        }
        if (!agencyWebsiteVisitButton) {
            return null;
        }

        const agencyWebsiteUrl = user.getIn(AGENCY_WEBSITE_PATH);

        return (
            agencyWebsiteUrl && [
                <div key="divider" className="bc-navbar-divider-border" />,
                <div key="visit-agency-site" className="bc-navbar-divider">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`http://${agencyWebsiteUrl}`}
                        className="bc-navbar-btn-stretch bc-navbar-btn-stretch-inverse bc-navbar-btn-icon"
                    >
                        <Icon icon="website" size={ICON_SIZE_SMALL} />{' '}
                        <span className="hidden-md hidden-sm hidden-xs">
                            <FormattedMessage id="navigation.visit-agency-site" defaultMessage="Visit Agency Site" />
                        </span>
                    </a>
                </div>,
            ]
        );
    };

    return (
        <Navbar>
            {showPalette && <Palette {...props} />}

            <div className="container-fluid">
                <div className="bc-navbar-left">
                    <div className="bc-navbar-divider">
                        <Logo onClick={() => track(events.NAVIGATION_LOGO_CLICKED)} />
                    </div>
                    <MainNavigationMenu user={user} activeLink={activeLink} />
                    {renderLeftNavigation()}
                </div>
                <div className="bc-navbar-right bc-navbar-right-offset">
                    {renderRightNavigation()}
                    {renderAgencyWebsiteLink()}
                    <UserProfileMenu />
                </div>
            </div>
        </Navbar>
    );
};

export default compose(
    withRouter,
    connect(
        createStructuredSelector({
            agencyWebsiteVisitButton: getFeature('navigation.agencyWebsiteVisitButton.enabled'),
        }),
    ),
    withModals,
    React.memo,
)(Navigation);

const HideSmall: React.FunctionComponent<{ than: number }> = styled.span`
    @media (max-width: ${props => props.than}px) {
        display: none;
    }
`;

function AddProperty({ onAddProperty }) {
    const scheduleActionVisible = useSelector(getFeature('properties.visitScheduler.enabled'));

    return (
        <div className="bc-navbar-divider relative" key="add-property">
            <Tooltip label={<FormattedMessage id="navigation.add-property" defaultMessage="Add Property" />}>
                <ButtonLink
                    id="add-property"
                    data-testId="add-property-button"
                    onClick={onAddProperty}
                    className="bc-navbar-btn-stretch bc-navbar-btn-stretch-inverse bc-navbar-btn-icon"
                >
                    <Icon icon="add-property" size={ICON_SIZE_SMALL} />
                    {!scheduleActionVisible && (
                        <HideSmall than={900}>
                            <FormattedMessage id="navigation.add-property" defaultMessage="Add Property" />
                        </HideSmall>
                    )}
                </ButtonLink>
            </Tooltip>
        </div>
    );
}

function AddContact({ onAddContact }: { onAddContact: () => void }) {
    const scheduleActionVisible = useSelector(getFeature('properties.visitScheduler.enabled'));

    return (
        <div className="bc-navbar-divider relative" key="add-contact">
            <Tooltip label={<FormattedMessage id="navigation.add-contact" defaultMessage="Add Contact" />}>
                <ButtonLink
                    id="add-contact"
                    onClick={onAddContact}
                    className="bc-navbar-btn-stretch bc-navbar-btn-stretch-inverse bc-navbar-btn-icon"
                >
                    <Icon icon="add-contact" size={ICON_SIZE_SMALL} />{' '}
                    {!scheduleActionVisible && (
                        <HideSmall than={900}>
                            <FormattedMessage id="navigation.add-contact" defaultMessage="Add Contact" />
                        </HideSmall>
                    )}
                </ButtonLink>
            </Tooltip>
        </div>
    );
}

function ScheduleVisit() {
    const [adding, setAdding] = React.useState(false);
    const { addSuccess } = useToasts();
    const visible = useSelector(getFeature('properties.visitScheduler.enabled'));

    if (!visible) {
        return null;
    }

    return (
        <div className="bc-navbar-divider relative" key="add-visit">
            <Tooltip label={<FormattedMessage id="navigation.add-visit" defaultMessage="Schedule a visit" />}>
                <ButtonLink
                    id="add-visit"
                    onClick={() => setAdding(true)}
                    className="bc-navbar-btn-stretch bc-navbar-btn-stretch-inverse bc-navbar-btn-icon"
                >
                    <Icon icon="add-visit" size={32} />{' '}
                </ButtonLink>
            </Tooltip>
            <ScheduleVisitModal
                show={adding}
                onCancel={() => setAdding(false)}
                onVisitScheduled={(visit: any) => {
                    addSuccess({
                        message: (
                            <span>
                                <FormattedMessage
                                    id="general.interactions.visit_scheduled"
                                    defaultMessage="Visit scheduled"
                                />{' '}
                                (<Link to={routes.PROPERTY_SCHEDULE(visit.propertyId)}>View details</Link>)
                            </span>
                        ),
                    });
                    setAdding(false);
                }}
            />
        </div>
    );
}
