import React from 'react';
import { useIntl } from 'react-intl';
import { ControlLabel } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import Input from '@/app.components/forms/Input/Input';
import { ResourceFilterType } from '@/shared/resource/types';
import useOffice from '@/app.hooks/useOffice';
import { getDefaultUnitForArea } from '@/app.data';
import { AreaUnitsLabel } from '@/app.domains/properties/Area';

type Props = {
    id: string;
    intlKey: string;
    prefix?: string;
    tempParams: any;
    setTempParams: any;
    filter: ResourceFilterType;
};

const ResourceFilterFormRange: React.FC<Props> = props => {
    const { id, intlKey, filter, prefix, tempParams, setTempParams } = props;

    const intl = useIntl();

    const max = prefix === 'euro' ? Infinity : 10000000;

    const office = useOffice();

    const currency = office.getIn(['settings', 'data', 'currency'], 'EUR');
    const measurementSystem = office.getIn(['settings', 'data', 'measurement_system']);
    const units = getDefaultUnitForArea(filter.paramName, measurementSystem, 'house');

    const minValue = tempParams?.[`min_${filter.paramName}`];
    const maxValue = tempParams?.[`max_${filter.paramName}`];

    const content = {
        min: <FormattedMessage id="modals.common.price_panel.min_label" defaultMessage="Min." />,
        max: <FormattedMessage id="modals.common.price_panel.max_label" defaultMessage="Max." />,
        above: <FormattedMessage id="general.above" defaultMessage="above" />,
        below: <FormattedMessage id="general.below" defaultMessage="below" />,
    };

    const addonAfter =
        (prefix === 'sq_m' && <AreaUnitsLabel units={units} />) || (prefix === 'euro' && currency) || undefined;

    const title = filter.showTitle
        ? intl.formatMessage({ id: `${intlKey}.filter.${id}.${filter.paramName}` })
        : undefined;

    return (
        <div className="flex flex-column">
            {!!title && <ControlLabel>{title}</ControlLabel>}

            <div className="flex items-center">
                <Input
                    max={max}
                    type="price"
                    value={minValue}
                    decimalScale={2}
                    addonAfter={addonAfter}
                    label={prefix === 'sq_m' ? content.above : content.min}
                    placeholder={<FormattedMessage id="general.any" defaultMessage="Any" />}
                    onChange={newValue => {
                        if (newValue != null && parseFloat(newValue)) {
                            setTempParams({ ...tempParams, [`min_${filter.paramName}`]: parseFloat(newValue) });
                        } else {
                            setTempParams({ ...tempParams, [`min_${filter.paramName}`]: undefined });
                        }
                    }}
                />
                <span className="px-2 py-0 font-sm text-gray-dark"> ⎼ </span>
                <Input
                    max={max}
                    type="price"
                    value={maxValue}
                    decimalScale={2}
                    addonAfter={addonAfter}
                    label={prefix === 'sq_m' ? content.below : content.max}
                    placeholder={<FormattedMessage id="general.any" defaultMessage="Any" />}
                    onChange={newValue => {
                        if (newValue != null && parseFloat(newValue)) {
                            setTempParams({ ...tempParams, [`max_${filter.paramName}`]: parseFloat(newValue) });
                        } else {
                            setTempParams({ ...tempParams, [`max_${filter.paramName}`]: undefined });
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default ResourceFilterFormRange;
