import isMatchWith from 'lodash/isMatchWith';
import omit from 'lodash/omit';

// Transform the filter fields to a string
// for easy comparision
// Some conversions
// null => ''
// false => ''
// undefined => ''
export function toValueString(inputField?: string[] | { [key: string]: any } | boolean | number): string {
    if (Array.isArray(inputField)) {
        return inputField
            .map(item => item.trim())
            .filter(Boolean)
            .sort()
            .join(',');
    } else if (inputField == null || inputField === false) {
        return '';
    } else if (typeof inputField === 'object') {
        return Object.keys(inputField)
            .reduce((acc, key) => {
                if (inputField[key]) {
                    acc.push(key);
                }

                return acc;
            }, [] as string[])
            .sort()
            .join(',');
    } else if (typeof inputField === 'number') {
        if (inputField === 0) {
            return '';
        }

        return inputField.toString();
    } else if (typeof inputField === 'boolean') {
        return String(inputField);
    }

    return inputField;
}

type FilterSettings = {
    filterByStatus: boolean;
    status: string[];
};

type CustomizerFunction = (objValue: any, srcValue: any, indexOrKey: string | number | symbol) => boolean;

export const propertiesFiltersComparator = (ignoredFields: string[] = []) => (
    defaultFilterValue: any,
    currentFilterValue: any,
    field: string,
    defaultFilters: FilterSettings,
    currentFilters: FilterSettings,
): boolean => {
    if (ignoredFields.indexOf(field) >= 0) {
        return true;
    }
    switch (field) {
        case 'status':
            if (currentFilters.filterByStatus === false) {
                return defaultFilters.filterByStatus === false;
            }

            return toValueString(currentFilterValue) === toValueString(defaultFilterValue);
        case 'filterByStatus':
            return currentFilterValue === defaultFilterValue;
        case 'type':
            return toValueString(currentFilterValue) === toValueString(defaultFilterValue);
        default:
            return toValueString(currentFilterValue) === toValueString(defaultFilterValue);
    }
};

export const contactsFiltersComparator = (ignoredFields: string[] = []) => (
    defaultFilterValue: any,
    currentFilterValue: any,
    field: string,
) => {
    if (ignoredFields.indexOf(field) >= 0) {
        return true;
    }

    return toValueString(currentFilterValue) === toValueString(defaultFilterValue);
};

export const getPropertyFiltersComparator = (ignoredFields: string[] = []) => (
    currentValues: FilterSettings,
    defaultValues: FilterSettings,
) => {
    const comp = propertiesFiltersComparator(ignoredFields);

    for (let field in defaultValues) {
        if (ignoredFields.includes(field)) {
            continue;
        }
        const currentVal = currentValues[field];
        const defaultValue = defaultValues[field];
        const areEqual = comp(defaultValue, currentVal, field, defaultValues, currentValues);
        if (!areEqual) {
            console.warn(`filter ${field} is active (currentValue: ${currentVal}, defaultValue: ${defaultValue})`);

            // we found one field that is been filtered, thats enough
            return false;
        }
    }

    return true;
};

export const getContactsFiltersComparator = (ignoredFields: string[] = []) => (
    currentValues: FilterSettings,
    defaultValues: FilterSettings,
) => {
    return isMatchWith(
        omit(defaultValues, ['query', 'filter_identifier']),
        omit(currentValues, ['query', 'filter_identifier']),
        propertiesFiltersComparator(ignoredFields) as CustomizerFunction,
    );
};
