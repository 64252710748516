// @ts-nocheck
import accounting from 'accounting';

export default class NumberFormatSanitizer {
    public readonly decimal: string;
    public readonly thousand: string;

    constructor(decimal: string, thousand: string) {
        this.decimal = decimal;
        this.thousand = thousand;
    }

    public sanitize(value?: string | number): string {
        if (typeof value === 'string') {
            // eslint-disable-next-line security/detect-non-literal-regexp
            return value.replace(new RegExp(`\\${this.decimal}|\\${this.thousand}`, 'g'), match => {
                if (match === this.thousand) {
                    return '';
                }

                return match === this.decimal ? '.' : '';
            });
        }

        return value ? value.toString() : '';
    }

    public static sanitizeFromAccountingSettings(value?: string | number): string {
        if (typeof value === 'undefined' || value === null) {
            return '';
        }

        const { decimal, thousand } = accounting.settings.number;
        const sanitizer = new NumberFormatSanitizer(decimal, thousand);

        return sanitizer.sanitize(value);
    }

    public static replaceDecimalSeparator(value?: string) {
        if (!value) {
            return value;
        }

        const { decimal } = accounting.settings.number;

        return `${value}`.replace('.', decimal);
    }
}
