import { createContext } from 'react';
import { GeoTemplate, GeoTemplatesPagination } from '@/graphql/generated/types';

export const OfficeGeoContext = createContext<{
    loading: boolean;
    officeId: string;
    refetch: Function;
    fetchMore: Function;
    data: GeoTemplate[];
    setVisible: Function;
    visibleDelete: boolean;
    setVisibleDelete: Function;
    setActiveEntity: Function;
    activeEntity?: GeoTemplate;
    pagination?: GeoTemplatesPagination;
    visible: 'postalCode' | 'geometry' | null;
    addGeoTemplate: Function;
    updateGeoTemplate: Function;
    deleteGeoTemplate: Function;
}>({
    data: [],
    loading: true,
    officeId: '',
    visible: null,
    refetch: () => {},
    fetchMore: () => {},
    visibleDelete: false,
    setVisible: () => {},
    setActiveEntity: () => {},
    setVisibleDelete: () => {},
    addGeoTemplate: () => {},
    updateGeoTemplate: () => {},
    deleteGeoTemplate: () => {},
});
