import { call, put } from 'redux-saga/effects';
import { resetPagination, resetOfficeMembers, fetchCompany } from '../../actions';
import OfficesRequests from '../../../requests/OfficesRequests';
import { formatInviteErrors } from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';

export default function* onInviteOfficeMembers({
    attributes: { resolve, reject, invites, officeId, language, companyId },
}) {
    try {
        yield apiCall(new OfficesRequests().inviteMembers, officeId, {
            invites,
            language,
        });

        yield [
            put(resetPagination(`office/${officeId}/members`)),
            put(resetOfficeMembers(officeId)),
            put(fetchCompany(companyId)),
        ];

        yield call(resolve);
    } catch (error) {
        yield call(reject, formatInviteErrors(invites, error));
    }
}
