import React from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { Input } from '@/app.components';
import { OfferCardFragmentFragment } from '@/graphql/generated/types';

const OfferModalNotes = () => {
    const { values, setFieldValue } = useFormikContext<OfferCardFragmentFragment>();

    const content = {
        title: <FormattedMessage id="offers.modals.note" defaultMessage="Note" />,
        placeholder: <FormattedMessage id="offers.modals.note.placeholder" defaultMessage="Write a custom message" />,
    };

    return (
        <>
            <FormPanel title={content.title}>
                <Input
                    name="notes"
                    value={values.notes}
                    type="autosize-textarea"
                    placeholder={content.placeholder}
                    onChange={event => {
                        setFieldValue('notes', event.target.value);
                    }}
                />
            </FormPanel>
        </>
    );
};

export default OfferModalNotes;
