import React, { useState } from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { useContactPreferences } from '@/app.hooks/useContactPreferences';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import { PreferenceLink } from '@/app.domains/contacts/PreferenceCard';
import SideMenu from '@/app.components/navigations/SideMenu/SideMenu';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import ContactMatch from '@/new.domains/contacts/ContactShow/ContactMatch';

type ExternalProps = {
    params: {
        contact: string;
    };
};

type ConnectedProps = {
    searchSettings: any;
    resetSearch: () => void;
    setPropertiesSearch: (attributes: object) => void;
};

type Props = ConnectedProps & ExternalProps;

const ContactMatches: React.FunctionComponent<Props> = props => {
    const contactId = props.params.contact;

    const { preferencesConnection, loading } = useContactPreferences(contactId);

    const [activePreferenceIdx, setActivePreferenceIdx] = useState(0);

    const preference = preferencesConnection?.nodes[activePreferenceIdx];

    return (
        <LayoutContainer>
            {preferencesConnection?.totalCount > 1 && (
                <LayoutColumn size="small">
                    <SideMenu color="gray-lightest">
                        {preferencesConnection?.nodes.map((preference, idx) => {
                            return (
                                <PreferenceLink
                                    key={preference.id}
                                    preference={preference}
                                    active={idx === activePreferenceIdx}
                                    testId={`preference_${preference.id}`}
                                    onClick={() => setActivePreferenceIdx(idx)}
                                />
                            );
                        })}
                    </SideMenu>
                </LayoutColumn>
            )}
            <LayoutColumn className={classNames({ 'bc-bg-shadow-left': preferencesConnection?.totalCount > 1 })}>
                <ErrorBoundary>
                    <ContactMatch
                        contactId={contactId}
                        preference={preference}
                        loadingPreference={loading}
                        domainType="contact_matches"
                    />
                </ErrorBoundary>
            </LayoutColumn>
        </LayoutContainer>
    );
};

export default withRouter(ContactMatches);
