import gql from 'graphql-tag';

export const GET_TEAMS = gql`
    query getTeams($companyId: ID!, $page: Int, $limit: Int, $query: String) {
        teams(companyId: $companyId, page: $page, limit: $limit, query: $query) {
            meta {
                pagination {
                    current_page
                    total_pages
                    total
                    per_page
                }
            }
            data {
                id
                name
                settings {
                    data
                }
            }
        }
    }
`;
