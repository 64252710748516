import React, { FC } from 'react';
import Status from '@/app.components/elements/Status';

type Props = {
    type: 'error' | 'warning';
    children: React.ReactNode;
};

export const PublicationErrorDetailsItemLayout: FC<Props> = props => {
    const { children, type } = props;

    return (
        <div className="my-2 p-2 rounded border border-gray-light bg-gray-100 flex">
            <Status
                status={type === 'error' ? 'error' : 'warning-yellow'}
                style={{ position: 'static', marginRight: '5px' }}
            />
            <div>{children}</div>
        </div>
    );
};
