/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { Map } from 'immutable';
import snakecase from 'lodash/snakeCase';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import ContactPicker from '@/app.shared/contacts/ContactPicker/ContactPicker';
import { EstateRepository } from '../../app.utils/services';

type ContactData = {
    id: string;
    first_name: string;
    last_name: string;
    phone?: string;
    email?: string;
    photo: {
        data: {
            url?: string | null;
        };
    };
};

const SelectContactForm: React.FunctionComponent<{
    type: 'lead' | 'vendor';
    excluded: string[];
    property: Map<string, any>;
    propertyOfficeId?: string;
    onContactSelected: (contactFieldValues: ContactData) => void;
    close: () => void;
    onAddVendor: () => void;
}> = props => {
    const { excluded, type, onContactSelected, property, propertyOfficeId } = props;
    const [selectedContact, setSelectedContact] = React.useState<any>(null);
    const isLet = new EstateRepository(property).isLet();

    const getTitle = () => {
        if (type === 'lead' && isLet) {
            return <FormattedMessage id="modal.tenant.title" defaultMessage="Select tenant from leads" />;
        }

        return <FormattedMessage id={`modal.${type}.title`} defaultMessage={`modal.${type}.title`} />;
    };

    const createNewContactLabel = () => {
        return <FormattedMessage id="modal.select-contact.add-new" />;
    };

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        if (selectedContact) {
            onContactSelected(toContactField(selectedContact));
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Modal.Header>
                <Modal.Title>{getTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContactPicker
                    onSelect={([selectedContact]) => setSelectedContact(selectedContact)}
                    onCreateNew={props.onAddVendor}
                    propertyOfficeId={propertyOfficeId}
                    createLabel={createNewContactLabel()}
                    excluded={excluded}
                    type={type}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.close}>
                    <FormattedMessage id="forms.add-contact.cancel" defaultMessage="Cancel" />
                </Button>
                <Button type="submit" variant="primary" disabled={!selectedContact}>
                    <FormattedMessage id="forms.add-contact.cta" defaultMessage="Add" />
                </Button>
            </Modal.Footer>
        </form>
    );
};

export default withErrorBoundary(SelectContactForm);

function toContactField(contact: { id: string; photo?: { url: string } | null }): ContactData {
    const fieldValues = Object.keys(contact).reduce((contactField, key) => {
        contactField[snakecase(key)] = contact[key];

        return contactField;
    }, {} as ContactData);

    fieldValues.photo = {
        data: {
            url: contact.photo && contact.photo.url,
        },
    };

    return fieldValues;
}
