import gql from 'graphql-tag';

export const OfferCardFragment = gql`
    fragment OfferCardFragment on Offer {
        financialDetails {
            agencyTotalFee
            transactionAmount
            ownerFixedFee
            direction
            currency
            ownerPercentageFee
            ownerTotalFee
            ownerNetAmount
            buyerFixedFee
            buyerPercentageFee
            buyerGrossAmount
            buyerTotalFee
        }

        createdBy
        id
        notes
        creator {
            firstName
            lastName
        }
        property {
            attributes
            negotiator {
                id
                firstName
                lastName
            }
            negotiation
        }
        propertyId
        parentId
        buyers {
            id
        }
        owners {
            id
        }
        status

        archivedAt
        createdAt
        updatedAt
        validUntil
    }
`;
