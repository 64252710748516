import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Resumablejs from 'resumablejs';
import uuid from 'uuid/v4';
import { Alert } from 'react-bootstrap';
import fileSize from 'file-size';
import { FormattedMessage } from 'react-intl-sweepbright';
import { getAuthProvider } from '@sweepbright/webapp-shared/auth';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';

class Resumable extends Component {
    static propTypes = {
        fileTypes: PropTypes.arrayOf(PropTypes.string),
        headers: PropTypes.object,
        maxFileSize: PropTypes.number,
        maxFiles: PropTypes.number,
        onError: PropTypes.func,
        isShortTypes: PropTypes.bool,
        onFilesAdded: PropTypes.func.isRequired,
        onFileError: PropTypes.func,
        onFileSuccess: PropTypes.func,
        onProgress: PropTypes.func,
        onSuccess: PropTypes.func,
        renderErrors: PropTypes.func,
        simultaneousUploads: PropTypes.number,
        uploadOnAdd: PropTypes.bool,
        url: PropTypes.string.isRequired,
        defaultVisibility: PropTypes.oneOf(['private', 'public']),
        resumableRef: PropTypes.func,
        // when true, the image is uploaded as a preview first
        // and needs to be saved later by other means, for example when saving the form
        // it only works for property images at the moment
        preview: PropTypes.bool,
    };

    static defaultProps = {
        maxFileSize: 1 * 1024 * 1024,
        fileTypes: [],
        onError: () => ({}),
        onProgress: () => ({}),
        onSuccess: () => ({}),
        onFileError: () => ({}),
        onFileSuccess: () => ({}),
        uploadOnAdd: true,
        simultaneousUploads: 1,
        renderErrors: errors => (
            <Alert bsStyle="warning" className="alert-extensive">
                <p className="c-spacer-bottom">
                    <FormattedMessage
                        id="forms.errors.upload.general"
                        defaultMessage="Oops, we could not upload all files."
                    />
                </p>
                <ul>
                    {errors.map((error, key) => (
                        <li key={key}>{error}</li>
                    ))}
                </ul>
            </Alert>
        ),
        headers: null,
    };

    state = {
        errors: [],
    };

    componentDidMount() {
        const {
            fileTypes,
            maxFiles,
            maxFileSize,
            isShortTypes,
            headers,
            simultaneousUploads,
            defaultVisibility = 'private',
            preview,
        } = this.props;

        const authHeader = `Bearer ${getAuthProvider().getToken()}`;
        const url = sanitizeUrlPath(this.props.url);

        this.field = new Resumablejs({
            chunkSize: 1 * 1024 * 1024,
            maxFileSize,
            maxFiles,
            target: `${API_PROTOCOL}://${API_URL}/${url}`,
            headers: headers || {
                Authorization: authHeader,
            },
            generateUniqueIdentifier: () => uuid(),
            testChunks: false,
            ...(isShortTypes ? { fileType: fileTypes.map(el => el.split('/')[1]) } : { fileTypes }),
            permanentErrors: [400, 401, 403, 404, 405, 413, 415, 422, 500, 501],
            maxFilesErrorCallback: this.handleMaxFilesError,
            maxFileSizeErrorCallback: this.handleMaxFileSizeError,
            simultaneousUploads,
            maxChunkRetries: 2,
            query: file => {
                const queryParams = {
                    filename: file.fileName,
                    private: defaultVisibility === 'private',
                };

                if (preview) {
                    queryParams.preview = true;
                }

                return queryParams;
            },
        });

        this.field.assignBrowse(this.input);
        this.field.on('filesAdded', this.handleFilesAdded);
        this.field.on('fileError', this.handleFileError);
        this.field.on('fileSuccess', this.handleFileSuccess);
        this.field.on('complete', this.props.onSuccess);
        this.field.on('fileProgress', this.handleFileProgress);
        if (this.props.resumableRef) {
            this.props.resumableRef(this.field);
        }
    }

    componentWillUnmount() {
        this.field = null;
        if (this.props.resumableRef) {
            this.props.resumableRef(null);
        }
    }

    handleMaxFilesError = () => {
        this.setState({
            errors: [
                <FormattedMessage
                    key="error.max_files"
                    id="forms.errors.upload.limit"
                    defaultMessage="Maximum number of files exceeded"
                />,
            ],
        });
    };

    handleMaxFileSizeError = file => {
        this.setState({
            errors: [
                <span key="error.max_file_size">
                    <strong>{file.name}</strong>:{' '}
                    <FormattedMessage
                        id="forms.errors.upload.file_size"
                        defaultMessage="this file is over {size}mb"
                        values={{
                            size: fileSize(this.props.maxFileSize).to('MB'),
                        }}
                    />
                </span>,
            ],
        });
    };

    handleFileError = (file, error) => {
        this.props.onFileError(file, error);

        if (!this.field.isUploading()) {
            this.props.onError();
        }
    };

    queue = [Promise.resolve()];

    handleFilesAdded = files => {
        this.setState({ errors: [] });
        // eslint-disable-next-line no-unused-expressions
        if (this.props.onFilesAdded) {
            this.props.onFilesAdded(files);
        } else if (this.props.onFileAdded) {
            files.forEach(this.props.onFileAdded);
        }

        if (this.props.uploadOnAdd) {
            this.submit();
        }
    };

    // we need to do this to avoid stale closures
    handleFileProgress = file => {
        this.props.onProgress(file);
    };

    handleFileSuccess = file => {
        this.props.onFileSuccess(file);
    };

    open() {
        this.input.click();
    }

    submit() {
        this.field.upload();
    }

    render() {
        const { errors } = this.state;
        const { testId } = this.props;

        return (
            <div>
                {errors.length > 0 && this.props.renderErrors(errors)}
                <input
                    data-testid={testId}
                    style={{ display: 'none' }}
                    ref={ref => (this.input = ref)}
                    type="file"
                    className="c-util-hidden"
                    multiple
                    accept={this.props.fileTypes}
                />
            </div>
        );
    }
}

export default withErrorBoundary(Resumable);

function sanitizeUrlPath(urlPath = '') {
    return urlPath.startsWith('/') ? urlPath.slice(1) : urlPath;
}
