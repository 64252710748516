import gql from 'graphql-tag';

export const GET_TEAM = gql`
    query GetTeam($teamId: ID!) {
        team(teamId: $teamId) {
            data {
                id
                name
                head_negotiator {
                    data
                }
                settings {
                    data
                }
            }
        }
    }
`;
