import { fork, takeEvery } from 'redux-saga/effects';
import { SIGN_IN_TO_CHANNEL, FETCH_FACEBOOK_ACCOUNTS } from '../actions';
import { onSignInToChannel, onFetchFacebookAccounts } from './Channels';

export default function* ChannelsSaga() {
    yield [
        fork(takeEvery, SIGN_IN_TO_CHANNEL, onSignInToChannel),
        fork(takeEvery, FETCH_FACEBOOK_ACCOUNTS, onFetchFacebookAccounts),
    ];
}
