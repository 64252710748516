import React from 'react';

const ButtonLink: React.FunctionComponent<{
    onClick?: (evt: React.SyntheticEvent<any>) => void;
    className: string;
    id?: string;
} & React.HTMLProps<HTMLAnchorElement>> = React.forwardRef(function ButtonLink(
    { onClick, children, ...props },
    ref: React.Ref<HTMLAnchorElement>,
) {
    return (
        <a
            ref={ref}
            href="#"
            onClick={evt => {
                evt.preventDefault();
                onClick?.(evt);
            }}
            {...props}
        >
            {children}
        </a>
    );
});

export default ButtonLink;
