// @ts-nocheck
import React from 'react';
import { Alert } from 'react-bootstrap';
import Button from '@sweepbright/uikit/build/esm/button';
import Loadable from 'react-loadable';
import { InputProps } from 'redux-form';
import isArray from 'lodash/isArray';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import Input from '@/app.components/forms/Input/Input';
import { CoordinateTuple } from '@/app.utils/services/GoogleMapsConverter';
import { FormErrors } from '@/app.components/modals/LocationPreferences/DrawRegionModal';
import FormPanel from '../../forms/FormPanel/FormPanel';

const DrawRegionMap = (Loadable({
    loader: () => import('../../map/DrawRegionMap'),
    loading: () => null,
}) as any) as React.FunctionComponent<any>;

export type FutureAreaFields = {
    id: InputProps;
    name: InputProps;
    data: {
        type: InputProps;
        coordinates: InputProps;
    };
};

type Props = {
    editing: boolean;
    onCancel: () => void;
    onResetForm: () => void;
    handleSubmit: (evt: React.FormEvent<HTMLFormElement>) => void;
    fields: FutureAreaFields;
    onOverlayComplete: (type: string, coordinates: Array<CoordinateTuple[]>) => void;
    onRemoveLocation: () => void;
    errors?: FormErrors;
    visible?: boolean;
    submitFailed?: boolean;
    type: 'template' | 'preference';
};

function getShapes(fields: FutureAreaFields) {
    if (isArray(fields.data.coordinates.value) && fields.data.coordinates.value.length > 0) {
        const coordinates = (fields.data.coordinates.value as any) as Array<CoordinateTuple[]>;

        // convert the field into a shapes object, only ONE shape
        return [
            {
                type: fields.data.type.value,
                coordinates,
            },
        ];
    }

    return [];
}

const labels = {
    save_btn: {
        template: <FormattedMessage id="location.templates.actions.save" defaultMessage="Save Template" />,
        preference: <FormattedMessage id="preferences.actions.save" defaultMessage="Save Preference" />,
    },
    add_btn: {
        template: <FormattedMessage id="location.templates.actions.add" defaultMessage="Add Template" />,
        preference: <FormattedMessage id="misc.add_preference" defaultMessage="Add Preference" />,
    },
    delete_btn: {
        template: <FormattedMessage id="location.templates.actions.delete" defaultMessage="Delete Template" />,
        preference: <FormattedMessage id="preferences.actions.delete_preference" defaultMessage="Delete Preference" />,
    },
    name_placeholder: {
        template: <FormattedMessage id="location.templates.name_placeholder" defaultMessage="Template Name" />,
        preference: <FormattedMessage id="preferences.name_placeholder" defaultMessage="Preference Name" />,
    },
};

const DrawRegionForm: React.FunctionComponent<Props> = ({
    editing,
    onCancel,
    onResetForm,
    handleSubmit,
    fields,
    onRemoveLocation,
    // this prop is uses to control the rendering of the map
    // because the modal is animated and we need to wait for it to
    // be fully visible so the div on the map have a height > 0
    visible,
    submitFailed,
    type = 'preference',
}) => {
    const handleUpdateRegion = React.useCallback(
        (type: string, coordinates: Array<CoordinateTuple[]>) => {
            // Append to GeoJSON shape
            fields.data.type.onChange(type);
            fields.data.coordinates.onChange(coordinates);
        },
        [fields],
    );

    return (
        <form
            onSubmit={handleSubmit}
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
            }}
        >
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="common.forms.actions.preferences.draw_on_map" defaultMessage="Draw On Map" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="flex" style={{ minHeight: '70vh' }}>
                <FormPanel flex>
                    <Input
                        type="text"
                        placeholder={labels.name_placeholder[type]}
                        {...fields.name}
                        error={submitFailed && fields.name.error}
                    />
                    {fields.data.coordinates.error && fields.data.coordinates.touched ? (
                        <Alert bsStyle="danger">{fields.data.coordinates.error}</Alert>
                    ) : null}
                    {visible ? (
                        <DrawRegionMap
                            flex
                            onUpdateRegion={handleUpdateRegion}
                            region={getShapes(fields)}
                            editing={editing}
                        />
                    ) : null}
                </FormPanel>
            </Modal.Body>
            <Modal.Footer>
                <div className="pull-left">
                    {editing && (
                        <Button variant="danger" onClick={onRemoveLocation}>
                            {labels.delete_btn[type]}
                        </Button>
                    )}
                </div>
                <Button onClick={onCancel}>
                    <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="danger" onClick={onResetForm}>
                    <FormattedMessage id="general.action.reset" defaultMessage="Reset" />
                </Button>
                <Button type="submit" variant="primary" data-testId="add-region-btn">
                    {editing ? labels.save_btn[type] : labels.add_btn[type]}
                </Button>
            </Modal.Footer>
        </form>
    );
};

export default DrawRegionForm;
