import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import { replace } from 'react-router-redux';
import { getUser } from '@/app.redux/selectors/UsersSelectors';
import Loading from '@/app.components/loading/Loading';
import { PROFILE } from '../routes';
import { getIsLoggingIn } from '../../app.redux/selectors';
import { isCompanyAdmin } from '../../app.data';

const isAuthenticatedAsCompanyAdmin = connectedReduxRedirect({
    authenticatingSelector: getIsLoggingIn,
    authenticatedSelector: store => {
        const user = getUser(store);

        return user && isCompanyAdmin(user);
    },
    redirectPath: PROFILE,
    LoadingComponent: Loading,
    redirectAction: replace,
    wrapperDisplayName: 'CompanyAdmin',
});

export default isAuthenticatedAsCompanyAdmin;
