import PropTypes from 'prop-types';
import React from 'react';
import Avatar from '@sweepbright/uikit/build/esm/avatar';
import moment from 'moment';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import FileUploadModal from '../modals/FileUploadModal';

export default function UploadPhoto(props) {
    const [photoUrl, setPhotoUrl] = React.useState(props.photo);
    const { uploadedAt, fallback, addLabel, removeLabel } = props;
    const modal = React.useRef(null);

    return (
        <div className="c-util-flex-block c-util-flex-block-align-middle">
            {(photoUrl || fallback) && (
                <div className="c-util-flex-block-element c-spacer-right-l">
                    <Avatar image={photoUrl} name={fallback} type="lg" />
                </div>
            )}
            <div className="c-util-flex-block-element">
                {!photoUrl ? (
                    <Button variant="ghost" onClick={() => modal.current.open()}>
                        {addLabel}
                    </Button>
                ) : (
                    <div className="text-center">
                        {uploadedAt && (
                            <div className="text-muted c-spacer-bottom-s">
                                <FormattedMessage
                                    id="photo.upload.uploaded_at"
                                    defaultMessage="Uploaded {date}"
                                    values={{
                                        date: moment(uploadedAt).fromNow(),
                                    }}
                                />
                            </div>
                        )}
                        <Button
                            onClick={() => {
                                setPhotoUrl(null);
                                props.onRemove();
                            }}
                            variant="ghost-danger"
                        >
                            {removeLabel}
                        </Button>
                    </div>
                )}
            </div>
            <FileUploadModal
                ref={ref => (modal.current = ref)}
                title={addLabel}
                onSubmit={({ file, fileDataUrl }) => {
                    setPhotoUrl(fileDataUrl);
                    props.onAdd(file);
                }}
            />
        </div>
    );
}

UploadPhoto.propTypes = {
    addLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    fallback: PropTypes.string,
    field: PropTypes.object,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    photo: PropTypes.string,
    removeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    uploadedAt: PropTypes.string,
};

UploadPhoto.defaultProps = {
    addLabel: <FormattedMessage id="photo.upload.add" defaultMessage="Add Picture" />,
    removeLabel: <FormattedMessage id="photo.upload.remove" defaultMessage="Remove Picture" />,
};
