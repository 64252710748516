// @ts-nocheck
import React from 'react';
import { List, Map } from 'immutable';
import { UnreachableCaseError } from '@sweepbright/webapp-shared/utils/UnreachableCaseError';
import { defineMessages, FormattedMessage } from 'react-intl-sweepbright';
import { InteractionFeedbackType } from '@/app.data/Conditions';

export type InteractionType =
    | 'lead_visited'
    | 'user_called_lead'
    | 'user_sms_lead'
    | 'lead_email_sent'
    | 'lead_expressed_interest'
    | 'lead_visit_scheduled'
    | 'legacy_feedback_interaction'
    | 'edited'
    | 'created'
    | 'contact_emailed';

const interactionTypesMessages: { [type in InteractionType]: { id: string; defaultMessage: string } } = defineMessages({
    lead_email_sent: { id: 'general.interactions.lead_email_sent', defaultMessage: 'E-mail sent' },
    lead_visited: { id: 'general.interactions.lead_visited', defaultMessage: 'Visited' },
    user_called_lead: { id: 'general.interactions.user_called_lead', defaultMessage: 'Called' },
    user_sms_lead: { id: 'general.interactions.user_sms_lead', defaultMessage: 'Message sent' },
    lead_visit_scheduled: {
        id: 'general.interactions.visit_scheduled',
        defaultMessage: 'Visit scheduled',
    },
    // this interaction only exists here, the actual interaction type is going to be null
    legacy_feedback_interaction: {
        id: 'general.interactions.legacy_feedback_interaction',
        defaultMessage: 'Feedback added',
    },
    lead_expressed_interest: {
        id: 'general.interactions.lead_expressed_interest',
        defaultMessage: 'Lead expressed interest',
    },
    edited: {
        id: 'general.interactions.edited',
        defaultMessage: 'Edited',
    },
    created: {
        id: 'general.interactions.created',
        defaultMessage: 'Created',
    },
    contact_emailed: {
        id: 'general.interactions.lead_email_sent',
        defaultMessage: 'E-mail sent',
    },
});

export default interactionTypesMessages;

export const INTERACTION_FEEDBACK_COLOR: { [T in InteractionFeedbackType]: string } = {
    lead_expressed_no_interest: 'poor-secondary',
    lead_requested_info: 'fair-secondary',
    lead_requested_visit: 'good-secondary',
    lead_made_deal: 'new-secondary',
    lead_made_bid: 'mint-secondary',
};

export const interactionIcons = ['lead_not_interested', 'lead_wants_info', 'lead_wants_visit', 'lead_deal', 'lead_bid'];

export type InteractionData = {
    id: string;
    interaction_type: InteractionType | null;
    source_type: string;
    updated_at: string;
    created_at: string;
    human_status: string;
    comment?: string;
    external_source: string;
    contact: { data: { first_name: string; last_name: string } };
    feedback_external_comment?: string;
    feedback_internal_comment?: string;
    feedback_type?: InteractionFeedbackType;
};

export const interactionsForReport = List<InteractionType>([
    'lead_visited',
    'lead_scheduled_visit',
    'user_called_lead',
    'user_sms_lead',
    'lead_email_sent',
    'lead_expressed_interest',
]);

export function getInteractionTypeCount(interactions, type: InteractionType) {
    return interactions
        .filter(interaction => interaction.get('interaction_type').includes(type))
        .reduce((sum, interaction) => sum + interaction.get('count'), 0);
}

export function negotiatorInfoTranslation(
    interactionType: InteractionType,
): {
    id: string;
    defaultMessage: string;
} | null {
    switch (interactionType) {
        case 'lead_visited':
            return {
                id: 'components.interaction_row.lead_visited.negotiator_info',
                defaultMessage: 'with {first_name} {last_name}',
            };

        case 'user_sms_lead':
            return {
                id: 'components.interaction_row.user_sms_lead.negotiator_info',
                defaultMessage: 'by {first_name} {last_name}',
            };
        case 'lead_email_sent':
            return {
                id: 'components.interaction_row.user_sms_lead.negotiator_info',
                defaultMessage: 'by {first_name} {last_name}',
            };
        case 'user_called_lead':
            return {
                id: 'components.interaction_row.user_sms_lead.negotiator_info',
                defaultMessage: 'by {first_name} {last_name}',
            };
        case 'lead_visit_scheduled':
            return {
                id: 'components.interaction_row.user_sms_lead.negotiator_info',
                defaultMessage: 'by {first_name} {last_name}',
            };
        case 'legacy_feedback_interaction':
            return {
                id: 'components.interaction_row.legacy_feedback_interaction.negotiator_info',
                defaultMessage: 'by {first_name} {last_name}',
            };
        // will never happen
        case 'lead_expressed_interest':
            return {
                id: 'components.interaction_row.lead_expressed_interest.negotiator_info',
                defaultMessage: '',
            };
        default:
            throw new UnreachableCaseError(interactionType);
    }
}

export type InteractionMap = Map<keyof InteractionData, any>;

export const feedbackTitles: { [k in InteractionFeedbackType]: React.ReactNode } = {
    lead_expressed_no_interest: (
        <FormattedMessage
            id="pages.vendor_report.interaction.feedback.lead_expressed_no_interest"
            defaultMessage="No interest"
        />
    ),
    lead_requested_info: (
        <FormattedMessage
            id="pages.vendor_report.interaction.feedback.lead_requested_info"
            defaultMessage="Wants more info"
        />
    ),
    lead_requested_visit: (
        <FormattedMessage
            id="pages.vendor_report.interaction.feedback.lead_requested_visit"
            defaultMessage="Wants to visit"
        />
    ),
    lead_made_bid: (
        <FormattedMessage id="pages.vendor_report.interaction.feedback.lead_made_bid" defaultMessage="We have a bid" />
    ),
    lead_made_deal: (
        <FormattedMessage
            id="pages.vendor_report.interaction.feedback.lead_made_deal"
            defaultMessage="We have a deal"
        />
    ),
};

export const feedbackLabels = {
    'more-info': <FormattedMessage id="pages.vendor_report.interaction.info" defaultMessage="Info" />,
    visit: <FormattedMessage id="pages.vendor_report.interaction.visit" defaultMessage="Visit" />,
    bid: <FormattedMessage id="pages.vendor_report.interaction.bid" defaultMessage="Bid" />,
    deal: <FormattedMessage id="pages.vendor_report.interaction.deal" defaultMessage="Deal" />,
    'no-interest': <FormattedMessage id="pages.vendor_report.interaction.no_interest" defaultMessage="No Interest" />,
};
