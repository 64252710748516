import React from 'react';
import { useIntl } from 'react-intl';
import { useLocalStorage } from 'react-use';
import Button from '@sweepbright/uikit/build/esm/button';
import useOffice from '@/app.hooks/useOffice';
import Resource from '@/shared/resource';
import Link from '@/app.components/elements/Link';
import { ResourceContext } from '@/shared/resource/utils';
import EmptyState from '@/app.components/empty/EmptyState';
import { CHANNELS_PORTAL_CREATE } from '@/app.routing';
import { useChannels } from '../hooks/useChannels';
import { useChannelsColumns } from '../hooks/useChannelsColumns';

import { ChannelsSearchType } from '../types';

const ChannelsList = () => {
    const intl = useIntl();

    const office = useOffice();
    const officeId = office.get('id');

    const [searchSettings, setSearchSettings] = useLocalStorage<ChannelsSearchType>('channels.filters', {
        query: '',
    });

    const { loading, error, channels, totalCount, hasNextPage, handleFetchMore } = useChannels(searchSettings);

    const columns = useChannelsColumns(searchSettings);

    const filteredChannels = channels.filter(el => {
        return (
            el.name.toLowerCase().includes(searchSettings?.query?.toLowerCase()) ||
            el.channel.name.toLowerCase().includes(searchSettings?.query?.toLowerCase())
        );
    });

    const content = {
        title: intl.formatMessage({ id: 'portals.title' }),
        noEntities: intl.formatMessage({ id: 'portals.empty' }),
        errorTitle: intl.formatMessage({ id: 'portals.error.title' }),
        errorSubtitle: intl.formatMessage({ id: 'portals.error.subtitle' }),
        emptyTitle: intl.formatMessage({ id: 'portals.empty.title' }),
        count: intl.formatMessage({ id: 'portals.count' }, { count: filteredChannels.length }),
        add: intl.formatMessage({ id: 'portals.invite' }),
    };

    const tabs = [
        {
            label: '',
            id: 'channels',
            filters: <></>,
            onTabSelected: () => {},
        },
    ];

    const getEmptyState = () => {
        return error ? (
            <EmptyState title={content.errorTitle} body={content.errorSubtitle} />
        ) : (
            <EmptyState title={content.emptyTitle} />
        );
    };

    return (
        <ResourceContext.Provider
            value={{
                tabs,
                error,
                columns,
                loading,
                content,
                officeId,
                hasNextPage,
                getEmptyState,
                searchSettings,
                handleFetchMore,
                setSearchSettings,
                initialTab: tabs[0].id,
                entities: filteredChannels,
                hasEntities: totalCount > 0,
                totalCount: filteredChannels.length,
                tableWrapperHeight: 'calc(100vh - 214px)',
                action: (
                    <Link key="add" to={CHANNELS_PORTAL_CREATE}>
                        <Button variant="primary" className="pull-right">
                            {content.add}
                        </Button>
                    </Link>
                ),
            }}
        >
            <Resource />
        </ResourceContext.Provider>
    );
};

export default ChannelsList;
