import { put } from 'redux-saga/effects';
import CompaniesRequests from '../../../requests/CompaniesRequests';
import { setCompanyAdmins } from '../../actions';
import withPagination from '../Helpers/withPagination';
import apiCall from '../Effects/apiCall';

export default function* onFetchCompanyAdmins({ companyId, attributes }) {
    yield withPagination('company/admins', attributes, function* onFetch(settings) {
        const admins = yield apiCall(new CompaniesRequests().admins, companyId, settings);

        yield put(setCompanyAdmins(companyId, admins.data));

        return admins;
    });
}
