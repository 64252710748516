const houseSubTypeSchema = require('@sweepbright/api-schemas/schemas/sub_types/house.json');
const apartmentSubTypeSchema = require('@sweepbright/api-schemas/schemas/sub_types/apartment.json');
const commercialSubTypeSchema = require('@sweepbright/api-schemas/schemas/sub_types/commercial.json');
const landSubTypeSchema = require('@sweepbright/api-schemas/schemas/sub_types/land.json');
const officeSubTypeSchema = require('@sweepbright/api-schemas/schemas/sub_types/office.json');
const parkingSubTypeSchema = require('@sweepbright/api-schemas/schemas/sub_types/parking.json');

const subTypeSchemas = {
    house: houseSubTypeSchema,
    apartment: apartmentSubTypeSchema,
    commercial: commercialSubTypeSchema,
    land: landSubTypeSchema,
    office: officeSubTypeSchema,
    parking: parkingSubTypeSchema,
};

export function checkSubTypes(type, subType) {
    const subTypeSchema = subTypeSchemas[type];

    if (subTypeSchema) {
        const valid = (subTypeSchema.items.enum ?? []).indexOf(subType) >= 0;

        return valid ? subType : 'none';
    }

    throw new Error(`Property type: "${type}" is not valid`);
}
