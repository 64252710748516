import gql from 'graphql-tag';

export const PreferenceFormFragment = gql`
    fragment PreferenceFormFragment on BuyerPreference {
        id
        type
        subtypes
        isInvestor
        amenities
        condition
        negotiation
        budget {
            maxPrice
            minPrice
        }
        max_liveable_area
        min_liveable_area
        max_plot_area
        min_plot_area
        max_net_area
        min_net_area
        max_gross_area
        min_gross_area
        ... on BuyerPreferenceForHouse {
            minBedrooms
        }
        ... on BuyerPreferenceForApartment {
            minBedrooms
        }
        ... on BuyerPreferenceForApartment {
            minArea
        }
        ... on BuyerPreferenceForOffice {
            minArea
        }
        ... on BuyerPreferenceForLand {
            minArea
        }
        ... on BuyerPreferenceForCommercial {
            minArea
        }
        ... on BuyerPreferenceForHouse {
            minArea
        }
        ... on BuyerPreferenceForParking {
            minArea
        }
    }
`;

export const PreferenceCardFragment = gql`
    fragment PreferenceCardFragment on BuyerPreference {
        id
        isInvestor
        budget {
            maxPrice
            minPrice
        }
        subtypes
        amenities
        condition
        negotiation
        type
        max_liveable_area
        min_liveable_area
        max_plot_area
        min_plot_area
        max_net_area
        min_net_area
        max_gross_area
        min_gross_area
        ... on BuyerPreferenceForHouse {
            minBedrooms
        }
        ... on BuyerPreferenceForApartment {
            minBedrooms
        }
        locations(limit: 10) {
            nodes {
                id
                name
                type
                ... on GeoJSONLocationPreference {
                    data {
                        type
                        coordinates
                    }
                }
                ... on PostalCodesLocationPreference {
                    data {
                        country
                        postalCodes
                    }
                }
            }
            totalCount
        }
    }
`;
