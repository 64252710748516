import gql from 'graphql-tag';

export const GET_OFFICE_NEGOTIATORS_FOR_SELECT_QUERY = gql`
    query GetOfficeNegotiatorsForSelect(
        $page: Int!
        $officeId: ID!
        $query: String!
        $includeAdditionalNegotiator: Boolean!
        $additionalNegotiatorId: ID!
        $includeIds: Boolean!
        $additionalIds: [ID!]!
    ) {
        office(id: $officeId) {
            id
            negotiators(query: $query, page: $page) {
                id
                firstName
                lastName
                email
                photo
                role
                errorStatus
                hasCalendarIntegration
            }
            negotiatiorsByIds(ids: $additionalIds) @include(if: $includeIds) {
                id
                firstName
                lastName
                email
                photo
                role
                errorStatus
                hasCalendarIntegration
            }
        }
        user(id: $additionalNegotiatorId, allowNotFound: true) @include(if: $includeAdditionalNegotiator) {
            id
            firstName
            lastName
            email
            photo
            role
            errorStatus
            hasCalendarIntegration
        }
    }
`;
