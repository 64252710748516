import gql from 'graphql-tag';

export const ARCHIVE_LEADS_MUTATION = gql`
    mutation ArchiveLeads($input: ArchiveLeadsInput!) {
        archiveLeads(input: $input) {
            company {
                id
            }
        }
    }
`;
