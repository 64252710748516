import React, { useState } from 'react';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import Icon from '@/app.components/icons/Icon';
import { FormattedMessage } from 'react-intl-sweepbright';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { useContactArchiver } from '@/app.hooks/useContactArchiver';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { ContactCardData } from '@/new.domains/contacts/types';
import ContactLabelsModal from '@/shared/contacts/ContactLabelsModal';
import ContactCard from '../../shared/contacts/ContactCard';
import ContactActions from './ContactActions';

type Props = {
    to?: string;
    contact: ContactCardData;
    onClick?: (evt?: React.MouseEvent<HTMLElement>) => void;
};

const ContactListCard: React.FunctionComponent<Props> = ({ contact, onClick, to }) => {
    return (
        <ContactCard
            contact={contact}
            actions={[
                <ErrorBoundary key="actions">
                    <ContactActions contactId={contact.id} />
                </ErrorBoundary>,
                <ContactCardMenu key="menu" contactId={contact.id} />,
            ]}
            onClick={onClick}
            to={to}
        />
    );
};

export default ContactListCard;

function ContactCardMenu({ contactId }: { contactId: string }) {
    const showLabels = useFeatureFlag('contact.labels.enabled');

    const [visible, setVisible] = useState<'labels' | undefined>(undefined);

    const { isArchived, toggleArchived, loading } = useContactArchiver(contactId);

    return (
        <div
            className="flex items-center"
            onClick={(evt: React.MouseEvent<any>) => {
                evt.preventDefault();
                evt.stopPropagation();
            }}
        >
            <Dropdown id="status_dropdown" data-testid="property-status-dropdown">
                <Dropdown.Toggle
                    as="button"
                    className="bg-transparent cursor-default flex items-center focus:outline-none focus:shadow-outline rounded"
                    type="button"
                >
                    <Icon icon="options" size={24} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {showLabels && (
                        <Dropdown.MenuItem
                            key="labels"
                            onSelect={() => {
                                setVisible('labels');
                            }}
                            className="focus:outline-none"
                        >
                            <FormattedMessage id="contact.labels.modal" defaultMessage="Edit Labels" />
                        </Dropdown.MenuItem>
                    )}
                    <Dropdown.MenuItem key="archive" onSelect={toggleArchived} disabled={loading}>
                        {isArchived ? (
                            <FormattedMessage id="actions.unarchive" defaultMessage="Unarchive" />
                        ) : (
                            <FormattedMessage id="actions.archive" defaultMessage="Archive" />
                        )}
                    </Dropdown.MenuItem>
                </Dropdown.Menu>
            </Dropdown>

            {showLabels && (
                <ContactLabelsModal visible={visible === 'labels'} setVisible={setVisible} contactId={contactId} />
            )}
        </div>
    );
}
