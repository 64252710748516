import React from 'react';
import Loadable from 'react-loadable';
import './styles.scss';

const Select = Loadable({
    loader: () => import(/* webpackChunkName: "Select" */ '@sweepbright/uikit/build/esm/select'),
    loading: () => null,
});

// eslint-disable-next-line react/display-name
export default props => <Select simpleValue {...props} />;
