// @ts-nocheck
import { put } from 'redux-saga/effects';
import { setContactInteractions } from '@/app.redux/actions/ContactsActions';
import { InteractionData } from '@/app.data/Interactions';
import { PropertyData } from '@/app.utils/services/PatchBuilders/PropertyPatchBuilder';
import apiCall from '../Effects/apiCall';
import ContactsRequests from '../../../requests/ContactsRequests';
import withPagination from '../Helpers/withPagination';

type PropertyInteractionData = PropertyData & {
    active?: boolean;
};

export default function* onFetchContactInterests({
    contactId,
    interests = 'active',
    attributes,
}: {
    contactId?: string;
    interests: 'active' | 'unactive';
    attributes: any;
}) {
    if (!contactId) {
        return;
    }

    yield withPagination(`contact/${contactId}/${interests}-interests`, attributes, function* onFetch() {
        const isActive = interests === 'active';
        const request = isActive ? new ContactsRequests().activeInterests : new ContactsRequests().inactiveInterests;
        const response = yield apiCall(request, contactId, attributes);
        const rawInteractions: Array<{
            estate: { data: PropertyData };
        } & InteractionData> = response.data;

        const interactions = rawInteractions.map(({ estate, ...interaction }) => {
            const property: PropertyInteractionData = estate.data;
            property.interactions = [interaction];
            property.active = isActive;

            return property;
        });

        yield put(setContactInteractions(contactId, interactions, interests));

        return response;
    });
}
