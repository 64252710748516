import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import MultiDragList from '@/components/MultiDragList';

type Props = {
    type: 'multiple' | 'single';
    value: string | string[] | null;
    onChange: Function;
};

const CompanyFormMatching: React.FC<Props> = props => {
    const { type, value, onChange } = props;

    const intl = useIntl();

    const options = {
        multiple: ['matching-contact-assignee', 'logged-in-user'],
        single: ['property-assignee', 'matching-contact-assignee', 'owner-assignee', 'logged-in-user'],
    };

    const initItemsFirst = value && Array.isArray(value) ? value : [];
    const initItemsSecond = options[type].filter(el => !initItemsFirst.includes(el));

    const [itemsFirst, setItemsFirst] = useState<string[]>(initItemsFirst);
    const [itemsSecond, setItemsSecond] = useState<string[]>(initItemsSecond);

    const content = {
        title: intl.formatMessage({ id: 'company.form.matching' }),
        subtitle: intl.formatMessage({ id: `company.form.matching.${type}.subtitle` }),
        labelFirst: intl.formatMessage({ id: 'company.form.matching.selected' }),
        labelSecond: intl.formatMessage({ id: 'company.form.matching.options' }),
        info: intl.formatMessage({ id: `company.form.matching.${type}.help` }),
    };

    return (
        <div className="company-form-matching">
            <label className="control-label" style={type === 'single' ? { marginTop: '24px' } : {}}>
                <span>{content.subtitle}</span>
            </label>

            <MultiDragList
                labelFirst={content.labelFirst}
                labelSecond={content.labelSecond}
                itemsFirst={itemsFirst.map(el => {
                    return {
                        value: el,
                        label: intl.formatMessage({ id: `company.form.matching.options.${el}` }),
                    };
                })}
                setItemsFirst={newValue => {
                    const ids = newValue.map(el => el.value);

                    onChange(ids);
                    setItemsFirst(ids);
                }}
                itemsSecond={itemsSecond.map(el => {
                    return {
                        value: el,
                        label: intl.formatMessage({ id: `company.form.matching.options.${el}` }),
                    };
                })}
                setItemsSecond={newValue => {
                    setItemsSecond(newValue.map(el => el.value));
                }}
            />

            <div className="company-form-matching__help">
                <span className="hint help-block">{content.info}</span>
            </div>
        </div>
    );
};

export default CompanyFormMatching;
