import gql from 'graphql-tag';

export const GET_OFFICE_NEGOTIATORS_QUERY = gql`
    query GetOfficeNegotiators($id: ID!, $query: String) {
        office(id: $id) {
            id
            negotiators(query: $query) {
                id
                firstName
                lastName
                email
                photo
                hasCalendarIntegration
            }
        }
    }
`;
