// @ts-nocheck
import React, { useEffect } from 'react';
import { reduxForm, FormProps } from 'redux-form';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import { FormFields } from '@sweepbright/webapp-shared/utils/types';
import useCompany from '@/app.hooks/useCompany';
import Button from '@/app.components/elements/Buttons/Button';
import { PostalCodesLocationPreference } from '@/graphql/generated/types';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import CountrySelector from '@/app.components/forms/CountrySelector';
import ButtonIcon from '../../../app.components/elements/Buttons/ButtonIcon';
import Icon from '../../icons/Icon';
import Input from '../../forms/Input/Input';
import './AddZipCodeModal.scss';

type Props = {
    fields: FormFields<PostalCodesLocationPreference>;
    show: boolean;
    onClose: () => void;
    onRemoveLocation: (locationPreferenceId: string) => void;
    type: 'template' | 'preference';
} & FormProps;

const labels = {
    modal_title_edit: {
        template: <FormattedMessage id="location.templates.actions.edit" defaultMessage="Edit Template" />,
        preference: <FormattedMessage id="preferences.actions.edit_preference" defaultMessage="Edit Preference" />,
    },
    modal_title_add: {
        template: <FormattedMessage id="location.templates.actions.create" defaultMessage="Create Template" />,
        preference: <FormattedMessage id="preferences.actions.create_preference" defaultMessage="Create Preference" />,
    },
    save_btn: {
        template: <FormattedMessage id="location.templates.actions.save" defaultMessage="Save Template" />,
        preference: <FormattedMessage id="preferences.actions.save" defaultMessage="Save Preference" />,
    },
    add_btn: {
        template: <FormattedMessage id="location.templates.actions.add" defaultMessage="Add Template" />,
        preference: <FormattedMessage id="misc.add_preference" defaultMessage="Add Preference" />,
    },
    delete_btn: {
        template: <FormattedMessage id="location.templates.actions.delete" defaultMessage="Delete Template" />,
        preference: <FormattedMessage id="preferences.actions.delete_preference" defaultMessage="Delete Preference" />,
    },
    name_placeholder: {
        template: <FormattedMessage id="location.templates.name_placeholder" defaultMessage="Template Name" />,
        preference: <FormattedMessage id="preferences.name_placeholder" defaultMessage="Preference Name" />,
    },
};

const AddZipcodeModal: React.FunctionComponent<Props> = props => {
    const { fields, type = 'preference' } = props;

    const company = useCompany();

    const invoiceCountry = company.getIn(['settings', 'data', 'invoice_country']);

    useEffect(() => {
        if (!editing && invoiceCountry) {
            fields.data.country.onChange(invoiceCountry);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editing, invoiceCountry]);

    const onAddLocation = () => {
        fields.data.postalCodes.addField();
    };

    const editing = Boolean(fields.id.value);

    return (
        <Modal show={props.show} onHide={props.onClose} testId="add-postcode-modal">
            <form onSubmit={props.handleSubmit} noValidate>
                <Modal.Header>
                    <Modal.Title>{editing ? labels.modal_title_edit[type] : labels.modal_title_add[type]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={15}>
                            <Input
                                type="text"
                                required
                                {...fields.name}
                                error={props.submitFailed && fields.name.error}
                                label={labels.name_placeholder[type]}
                            />
                            <CountrySelector
                                {...fields.data.country}
                                label={<FormattedMessage id="location.country" defaultMessage="Country" />}
                                error={props.submitFailed && fields.data.country.error}
                            />
                            <fieldset>
                                <ControlLabel>
                                    <FormattedMessage id="location.postal_code" defaultMessage="Postcode / Zip" />
                                </ControlLabel>
                                {fields.data.postalCodes.map((postalCode, key) => (
                                    <div key={`${key}${fields.data.postalCodes.length}`}>
                                        <Input
                                            {...postalCode}
                                            error={props.submitFailed && postalCode.error}
                                            type="text"
                                            addonAfter={
                                                <ButtonIcon
                                                    className="btn__addon"
                                                    variant="link"
                                                    onClick={() => fields.data.postalCodes.removeField(key)}
                                                    disabled={fields.data.postalCodes.length === 1}
                                                    type="trash"
                                                />
                                            }
                                        />
                                    </div>
                                ))}
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={15}>
                            <Button variant="default" className="pull-right" onClick={onAddLocation}>
                                <Icon icon="add" />
                                <FormattedMessage
                                    id="location.actions.add_postal_code"
                                    defaultMessage="Add Postcode / Zip"
                                />
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className="pull-left">
                        {editing && (
                            <Button
                                variant="danger"
                                onClick={() => props.onRemoveLocation(fields.id.value)}
                                disabled={props.submitting}
                            >
                                {labels.delete_btn[type]}
                            </Button>
                        )}
                    </div>
                    <Button onClick={props.onClose} disabled={Boolean(props.submitting)}>
                        <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        disabled={Boolean(props.submitting)}
                        data-testid="add-zipcode-preference-btn"
                    >
                        {editing ? labels.save_btn[type] : labels.add_btn[type]}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default reduxForm({
    form: 'add_zip_code_modal',
    fields: ['id', 'name', 'data.country', 'data.postalCodes[]'],
    initialValues: {
        name: '',
        data: {
            postalCodes: [''],
        },
    },
    validate: (values: PostalCodesLocationPreference) => {
        const errors: Record<string, any> = {
            data: {
                postalCodes: [],
            },
        };

        if (!values.name || values.name.trim().length === 0) {
            errors.name = <FormattedMessage id="forms.rules.required" defaultMessage="This field is required" />;
        }
        if (!values.data.country) {
            errors.data = {
                ...errors.data,
                country: (
                    <FormattedMessage
                        id="location.validation_errors.country_required"
                        defaultMessage="The country field is required"
                    />
                ),
            };
        }

        values.data.postalCodes.forEach((value, index) => {
            if (!value || value.trim().length === 0)
                errors.data.postalCodes[index] = (
                    <FormattedMessage
                        id="location.validation_errors.empty_postal_code"
                        defaultMessage="The postal code can not be empty"
                    />
                );
        });

        return errors;
    },
})(AddZipcodeModal);
