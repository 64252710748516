import React from 'react';
import { Map } from 'immutable';
import classnames from 'classnames';
import { InjectedIntl } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl-sweepbright';
import useCompany from '@/app.hooks/useCompany';
import { EstateRepository } from '../../app.utils/services';
import { getFeature } from '../../app.redux/selectors/FeaturesSelector';
import Tile from '../../app.components/elements/Tile/Tile';

const PropertyTile: React.FunctionComponent<{ estate: Map<string, any>; sortable: boolean; intl: InjectedIntl }> = ({
    estate,
    children,
    sortable,
    intl,
}) => {
    const company = useCompany();

    const classes = classnames({
        'c-tile-list__tile--draggable': sortable,
    });

    const content = {
        unknown: intl.formatMessage({ id: 'general.unknown' }),
    };

    if (estate.get('id') && !estate.get('type')) {
        return (
            <Tile className={classes}>
                <PropertyImage src={null} />
                <div className="c-spacer-top c-spacer-bottom">
                    <strong>{content.unknown}</strong>
                    <br />
                    <span>-</span>
                    <br />
                    <span className="text-muted">-</span>
                </div>
                {children}
            </Tile>
        );
    }

    const repository = new EstateRepository(estate, company);

    const description =
        getFeature('price-custom-price') && repository.getCustomPrice() !== null
            ? repository.getCustomPriceDescription(intl)
            : repository.getDescription(intl);

    const src = repository.getImage('agency-website-thumbnail');

    return (
        <Tile className={classes}>
            <PropertyImage src={src} />
            <div className="c-spacer-top c-spacer-bottom">
                <strong>{repository.getComposedTitle(intl)}</strong>
                <br />
                <span>{description}</span>
                <br />
                <span className="text-muted">
                    {repository.getNegotiation() === 'sale' ? (
                        <FormattedMessage id="common.property_negotiation.sale" defaultMessage="For sale" />
                    ) : (
                        <FormattedMessage id="common.property_negotiation.let" defaultMessage="To let" />
                    )}
                </span>
            </div>
            {children}
        </Tile>
    );
};

export default injectIntl(PropertyTile);

const PropertyImage = ({ src }) => (
    <div className="image-tile-container">
        <div
            style={{
                backgroundImage: `url(${src})`,
            }}
            className="c-square-thumbnail"
        />
    </div>
);
