import React from 'react';
import xor from 'lodash/xor';

export default function useSelectField(
    initialValue: string[] = [],
    { onSelected }: { onSelected?: (values: string[]) => void } = {},
) {
    const [selectedIds, setSelectedIds] = React.useState(initialValue);
    const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked, type } = evt.target;
        let newVal;
        if (type === 'radio') {
            newVal = checked ? [value] : [];
        } else {
            newVal = xor(selectedIds, [value]);
        }

        setSelectedIds(newVal);
        onSelected?.(newVal);
    };

    return { value: selectedIds, onChange, setValue: setSelectedIds };
}
