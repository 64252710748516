import React from 'react';
import { Waypoint } from 'react-waypoint';

function useLoadingPage(currentPage) {
    const [loadingPage, setLoadingPage] = React.useState(currentPage);

    React.useEffect(() => {
        setLoadingPage(currentLoadingPage => {
            if (currentLoadingPage === currentPage) {
                return null;
            }

            return currentLoadingPage;
        });
    }, [currentPage]);

    return [loadingPage, setLoadingPage];
}

export type Props = {
    currentPage: number;
    totalPages?: number;
    hasMorePages?: () => Maybe<boolean>;
    containerTag?: React.ComponentType<any>;
    resetPagination?: () => void;
    onFetch: (attr: { page: number }) => void;
    loading: boolean;
};

const InfiniteScroll: React.FunctionComponent<Props> = props => {
    const {
        currentPage = 0,
        totalPages = 1,
        children,
        containerTag: Container = 'div',
        resetPagination,
        hasMorePages = () => currentPage < totalPages,
        loading,
    } = props;

    const [loadingPage, setLoadingPage] = useLoadingPage(currentPage);

    React.useEffect(() => {
        // this will reset the pagination
        // when the component unmounts if
        // the resetPagination prop is provided
        return resetPagination;
    }, []);

    const handleScrollToBottom = () => {
        const nextPage = currentPage + 1;
        if (nextPage !== loadingPage && hasMorePages()) {
            props.onFetch({ page: nextPage });
            setLoadingPage(nextPage);
        }
    };

    const containerProps = {};
    if (Container !== React.Fragment) {
        containerProps['data-testid'] = 'scroll-list';
    }

    return (
        <Container {...containerProps}>
            {children}
            {!loading && <Waypoint key={currentPage} onEnter={handleScrollToBottom} />}
        </Container>
    );
};

export default InfiniteScroll;
