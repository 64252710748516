import { fromJS } from 'immutable';
import { useQuery } from '@apollo/react-hooks';
import { GET_TEAM } from '@/graphql/queries/teams/getTeam';
import { GetTeamQuery, GetTeamQueryVariables } from '@/graphql/generated/types';
import useUser from './useUser';

const useOffice = (teamId?: string, canRefetch?: boolean, skip?: boolean) => {
    const user = useUser();

    const activeTeamId = user?.toJS()?.office_id;

    const { data, refetch } = useQuery<GetTeamQuery, GetTeamQueryVariables>(GET_TEAM, {
        variables: { teamId: teamId || activeTeamId },
        skip: skip ? true : !teamId && !activeTeamId,
        fetchPolicy: 'cache-and-network',
    });

    const officeObject = data?.team?.data || {};

    const officeResponse = data?.team?.data
        ? fromJS({ ...officeObject, lead_negotiator: data?.team?.data?.head_negotiator })
        : fromJS(officeObject);

    if (canRefetch) {
        return {
            office: officeResponse,
            refetch,
        };
    }

    return officeResponse;
};

export default useOffice;
