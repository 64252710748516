import { fork, takeEvery } from 'redux-saga/effects';
import {
    FETCH_COMPANY_OFFICES,
    ADD_COMPANY_OFFICE,
    FETCH_COMPANY_ADMINS,
    INVITE_COMPANY_ADMINS,
    ARCHIVE_COMPANY_ADMIN,
    UPDATE_COMPANY_SETTINGS,
    FETCH_COMPANY,
    FETCH_COMPANY_TOKENS,
    FETCH_HIGHLIGHTS,
    UPDATE_COMPANY_CARD,
    FETCH_COMPANY_CARD,
    FETCH_COMPANY_FEATURES,
    SAVE_COMPANY_TOKEN,
    DELETE_COMPANY_TOKEN,
    UPGRADE_SUBSCRIPTION,
} from '../actions';
import {
    onFetchCompanyOffices,
    onAddOffice,
    onFetchCompanyAdmins,
    onArchiveCompanyAdmin,
    onUpdateCompanySettings,
    onFetchCompany,
    onFetchCompanyTokens,
} from './Sagas';
import {
    onFetchHighlights,
    onUpdateCompanyCard,
    onFetchCompanyCard,
    onFetchCompanyFeatures,
    onSaveCompanyToken,
    onDeleteCompanyToken,
    onUpgradeSubscription,
    onInviteCompanyAdmins,
} from './Companies';

export default function* CompaniesSaga() {
    yield [
        fork(takeEvery, FETCH_COMPANY, onFetchCompany),
        fork(takeEvery, FETCH_COMPANY_OFFICES, onFetchCompanyOffices),
        fork(takeEvery, ADD_COMPANY_OFFICE, onAddOffice),
        fork(takeEvery, FETCH_COMPANY_ADMINS, onFetchCompanyAdmins),
        fork(takeEvery, INVITE_COMPANY_ADMINS, onInviteCompanyAdmins),
        fork(takeEvery, ARCHIVE_COMPANY_ADMIN, onArchiveCompanyAdmin),
        fork(takeEvery, UPDATE_COMPANY_SETTINGS, onUpdateCompanySettings),
        fork(takeEvery, FETCH_COMPANY_TOKENS, onFetchCompanyTokens),
        fork(takeEvery, FETCH_HIGHLIGHTS, onFetchHighlights),
        fork(takeEvery, UPDATE_COMPANY_CARD, onUpdateCompanyCard),
        fork(takeEvery, FETCH_COMPANY_CARD, onFetchCompanyCard),
        fork(takeEvery, FETCH_COMPANY_FEATURES, onFetchCompanyFeatures),
        fork(takeEvery, SAVE_COMPANY_TOKEN, onSaveCompanyToken),
        fork(takeEvery, DELETE_COMPANY_TOKEN, onDeleteCompanyToken),
        fork(takeEvery, UPGRADE_SUBSCRIPTION, onUpgradeSubscription),
    ];
}
