import React from 'react';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { validateWithRules } from '@/app.utils/services/Validator';
import useCompany from '@/app.hooks/useCompany';
import Formik from '@/app.components/forms/helpers/Formik';
import { ContactRepository } from '@/app.utils/services';
import { ComposeEmailForm } from '@/app.components';
import { localizeValidationRule } from '@/app.components/forms/LocalizedInput';
import CompanyRepository from '@/app.utils/services/Repositories/CompanyRepository';

type Props = {
    label?: Node;
    multiple?: boolean;
    onCancel: () => void;
    handleSubmit: Function;
    contact: Map<string, any>;
    mailTextPreset: Record<string, any>;
    properties?: Array<Map<string, any>>;
};

const getValidation = ({ contact, company, multiple }) => attributes => {
    const language = new ContactRepository(contact, company).getLanguage();
    const contents = {
        type: 'object',
        required: true,
        properties: {
            [language]: {
                type: 'string',
                required: true,
                allowEmpty: false,
                messages: {
                    allowEmpty: 'forms.match.contents.required',
                    required: 'forms.match.contents.required',
                },
            },
        },
    };

    if (multiple) {
        const rule = {
            type: 'string',
            required: true,
            allowEmpty: false,
            messages: {
                allowEmpty: 'forms.match.contents.required',
                required: 'forms.match.contents.required',
            },
        };

        const languages = new CompanyRepository(company).getEnabledLanguages();

        return validateWithRules({
            contents: {
                type: 'object',
                required: true,
                properties: localizeValidationRule(
                    rule,
                    // @ts-ignore
                    languages,
                ),
            },
        })(attributes);
    }

    return validateWithRules({ contents })(attributes, { humanizeErrorMessage: false });
};

const MatchListModalForm: React.FC<Props> = props => {
    const { label, contact, multiple, handleSubmit, properties = [], onCancel, mailTextPreset } = props;

    const company = useCompany();

    const locale = new ContactRepository(contact, company).getLanguage();

    const validate = React.useCallback(getValidation({ contact, company, multiple }), [contact, company, multiple]);

    return (
        <Formik
            {...({} as any)}
            enableReinitialize
            validate={validate}
            onSubmit={handleSubmit}
            initialValues={mailTextPreset}
            initialErrors={validate(mailTextPreset)}
        >
            {({ values, handleSubmit, isSubmitting, handleChange, submitCount, errors }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal.Header>
                            <Modal.Title>
                                {label || <FormattedMessage id="matches.modal.title" defaultMessage="Send mail" />}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ComposeEmailForm
                                properties={properties}
                                submitFailed={submitCount > 0}
                                // @ts-ignore
                                field={
                                    multiple
                                        ? Object.fromEntries(
                                              ['en', 'fr', 'nl'].map(language => {
                                                  return [
                                                      language,
                                                      {
                                                          onChange: handleChange,
                                                          name: `contents.${language}`,
                                                          value: values?.contents?.[language],
                                                          error: errors?.contents?.[language],
                                                      },
                                                  ];
                                              }),
                                          )
                                        : {
                                              onChange: handleChange,
                                              name: `contents.${locale}`,
                                              value: values.contents[locale],
                                              error: errors.contents?.[locale],
                                          }
                                }
                            />
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button onClick={onCancel} type="button" disabled={isSubmitting}>
                                <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting} data-testid="send-mail-btn">
                                {isSubmitting ? (
                                    <FormattedMessage id="general.state.sending" defaultMessage="Sending..." />
                                ) : (
                                    label || <FormattedMessage id="matches.modal" defaultMessage="Send Mail" />
                                )}
                            </Button>
                        </Modal.Footer>
                    </form>
                );
            }}
        </Formik>
    );
};

export default MatchListModalForm;
