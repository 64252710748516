import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import Button from '@sweepbright/uikit/build/esm/button';
import MediaCard from '@/app.components/card/MediaCard/MediaCard';
import Icon, { ICON_SIZE_SMALL } from '@/app.components/icons/Icon';
import ContactLabel from '@/shared/contacts/ContactLabel';
import { Label } from '@/graphql/generated/types';
import { CopyButton } from '@/app.components/elements/Buttons/CopyButton';
import { CompanyLabelsContext } from '../../context';

type Props = {
    entity: Label;
};

const CompanyLabelsListItem: React.FC<Props> = props => {
    const { entity } = props;

    const intl = useIntl();

    const { setVisible, setActiveEntity } = useContext(CompanyLabelsContext);

    return (
        <li>
            <MediaCard
                title={
                    <div className="company-labels-list-item-title">
                        <ContactLabel color={entity.color} label={entity.name} />
                        <CopyButton
                            iconName="copy-m"
                            value={entity.id}
                            title={intl.formatMessage({ id: 'company.labels.copy' })}
                            className="text-muted company-labels-list-item-title__copy"
                        />
                    </div>
                }
                actions={[
                    <Button
                        key="edit"
                        variant="link"
                        onClick={evt => {
                            evt.stopPropagation();

                            setVisible(true);
                            setActiveEntity(entity);
                        }}
                        className="c-location-preference__button"
                    >
                        <Icon icon="edit" size={ICON_SIZE_SMALL} />
                    </Button>,
                ]}
            />
        </li>
    );
};

export default CompanyLabelsListItem;
