import FileReaderInput from 'react-file-reader-input';
import React from 'react';
import Button from '@/app.components/elements/Buttons/Button';
import Icon from '@/app.components/icons/Icon';
import { FormattedMessage } from 'react-intl-sweepbright';

const UploadButton: React.FunctionComponent<{ onChange: () => void }> = ({ onChange }) => {
    const fileUploadProps = {
        onChange,
    };

    return (
        <FileReaderInput {...fileUploadProps}>
            <Button variant="link" icon={<Icon icon="add" />}>
                <FormattedMessage id="forms.labels.homepage_cover.action" defaultMessage="Upload" />
            </Button>
        </FileReaderInput>
    );
};

export default UploadButton;
