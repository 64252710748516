// @ts-nocheck
import React from 'react';
import { Button } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import Modal from '@/app.components/elements/Modal';

type Props = {
    onRevert: () => void;
    onSave: () => void;
    onCancel: () => void;
    saving: boolean;
    show: boolean;
    onHide: () => void;
};

const UnsavedChangesConfirmationModal: React.FunctionComponent<Props> = ({
    saving,
    onRevert,
    onSave,
    onCancel,
    ...props
}) => {
    return (
        <Modal {...props}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="modals.confirmation.title" defaultMessage="Save changes?" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage
                    id="modals.confirmation.body"
                    defaultMessage="Leaving this page will discard your changes. \
                        You can save your changes now, discard your changes or cancel and stay on this page."
                />
            </Modal.Body>
            <Modal.Footer className="flex justify-between">
                <Button variant="danger" onClick={onRevert} disabled={saving}>
                    <FormattedMessage id="modals.confirmation.actions.revert" defaultMessage="Discard Changes" />
                </Button>
                <div className="space-x-1">
                    <Button onClick={onCancel} disabled={saving}>
                        <FormattedMessage id="modals.common.actions.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="primary" onClick={onSave} disabled={saving}>
                        {saving ? (
                            <FormattedMessage
                                tagName={React.Fragment}
                                id="modals.confirmation.actions.cta_busy"
                                defaultMessage="Saving Changes ..."
                            />
                        ) : (
                            <FormattedMessage
                                tagName={React.Fragment}
                                id="modals.confirmation.actions.cta"
                                defaultMessage="Save Changes"
                            />
                        )}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default React.forwardRef(UnsavedChangesConfirmationModal);
