import produce from 'immer';
import uniqBy from 'lodash/uniqBy';
import { createReducerWithSanitizer } from 'redux-reducer-factory';
import {
    SET_PROPERTY,
    SET_PROPERTY_INTERACTIONS,
    SET_PROPERTY_VISITS,
    SET_ESTATE_REQUESTS,
    RESET_ESTATE_REQUESTS,
    CANCEL_ESTATE_REQUEST,
} from '../../actions';

// do nothing
const sanitizer = property => property;

export default createReducerWithSanitizer({}, sanitizer, {
    [SET_PROPERTY]: (property, action) => {
        return produce(action.property, draftProperty => {
            const originalPrice = property.attributes?.price?.published_price?.amount;
            const newPrice = action.property?.attributes?.price?.published_price?.amount;
            if (originalPrice && newPrice) {
                draftProperty.priceDropped = originalPrice / newPrice > 1.05;
            }
            // if we are updating a property,
            // we need to keep the project link
            if (property && property.project) {
                draftProperty.project = property.project;
            }
        });
    },
    [SET_PROPERTY_VISITS]: (property, { visits }) => {
        return produce(property, draftProperty => {
            draftProperty.visits = uniqBy(visits, 'id');
        });
    },
    [SET_PROPERTY_INTERACTIONS]: (property, { interactions }) => {
        return produce(property, draftProperty => {
            if (!draftProperty.interactions) {
                draftProperty.interactions = [];
            }
            draftProperty.interactions = uniqBy([...draftProperty.interactions, ...interactions], 'id');
        });
    },
    // Requests
    [SET_ESTATE_REQUESTS]: (property, { requests }) => {
        return produce(property, draftProperty => {
            if (!draftProperty.requests) {
                draftProperty.requests = [];
            }
            draftProperty.requests = uniqBy([...draftProperty.requests, ...requests], 'id');
        });
    },
    [RESET_ESTATE_REQUESTS]: property => {
        return produce(property, draftProperty => {
            draftProperty.requests = [];
        });
    },
    [CANCEL_ESTATE_REQUEST]: (property, { requestId }) => {
        return produce(property, draftProperty => {
            if (!draftProperty.requests) {
                draftProperty.requests = [];
            }
            draftProperty.requests = draftProperty.requests.filter(request => request.id !== requestId);
        });
    },
});
