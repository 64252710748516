// @ts-nocheck
import React, { Component, FunctionComponent, ReactElement } from 'react';
import { useField } from 'formik';
import accounting from 'accounting';
import classNames from 'classnames';
import get from 'lodash/get';
import Loadable from 'react-loadable';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InjectedIntl } from 'react-intl';
import { Radio, Checkbox, DateInput, Slider } from '@sweepbright/uikit';
import debounce from 'lodash/debounce';
import Textarea from 'react-autosize-textarea';
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl-sweepbright';
import { toTitle } from '@/app.utils/services/String';
import { errorMessages } from '@/app.components/errors/ErrorMessages';
import Stepper from '../Stepper';

// import some custom tweaks for Safari
// eslint-disable-next-line: no-var-requires
require('./Input.scss');

const RichTextArea: any = Loadable({
    loader: () => import(/* webpackChunkName: "RichTextArea" */ '../RichTextArea'),
    loading() {
        return (
            <div>
                <FormattedMessage id="general.state.loading" defaultMessage="Loading..." />
            </div>
        );
    },
});

export type Props = {
    [prop: string]: any;
    'data-testid'?: string;
    addonAfter?: any;
    addonBefore?: any;
    allowClear?: boolean;
    bsStyle?: 'success' | 'warning' | 'error';
    children?: React.ReactNode;
    componentClass?: string;
    decimalScale?: number;
    error?: string;
    feedback?: ReactElement<any>;
    fixedDecimalScale: boolean;
    help?: string;
    horizontal?: boolean;
    inline?: boolean;
    inputRef?: (instance: HTMLInputElement) => void;
    intl: InjectedIntl;
    label?: string | ReactElement<any>;
    labelClassName?: string;
    mask?: { mask: string; format: string };
    name?: string;
    placeholder?: string | ReactElement<any>;
    renderWrapper: FunctionComponent<any>;
    srOnly?: boolean;
    // when passed the submitError prop, the error is shown
    // even is the field is not touched.
    submitError?: string;
    touched?: boolean;
    type: 'price' | 'radio' | 'text' | 'autosize-textarea' | 'date' | 'stepper' | 'rich-text' | 'range';
    withIcon?: ReactElement<any>;
    wrapperClassName?: string;
    onChange: (evtOrValue: React.ChangeEvent<HTMLInputElement> | string) => void;
};

const reduxFormProps = [
    'fixedDecimalScale',
    'decimalScale',
    'autofill',
    'error',
    'horizontal',
    'initialValue',
    'labelClassName',
    'label',
    'onUpdate',
    'touched',
    'visited',
    'dirty',
    'invalid',
    'pristine',
    'active',
    'autofilled',
    'wrapperClassName',
    'intl',
    'addonAfter',
    'help',
    'withIcon',
    'srOnly',
    'mask',
    'bsStyle',
    'feedback',
    'renderWrapper',
    'valid',
    'submitError',
];

const NumberFormatEnhanced = NumberFormat as React.ComponentClass<NumberFormatProps & { name?: string }, any>;

export function excludeReduxFormProps(props: Props) {
    // Filter out redux-form specific props to avoid 500 warnings
    const filteredProps: { [prop: string]: any } = {};
    Object.keys(props).forEach(key => {
        if (!reduxFormProps.includes(key)) {
            filteredProps[key] = props[key];
        }
    });

    return filteredProps;
}

class Input extends Component<Props> {
    static defaultProps = {
        renderWrapper: (props: Props) => (
            <FormGroup validationState={props.bsStyle} className={classNames({ 'has-feedback': props.feedback })}>
                {props.children}
            </FormGroup>
        ),
        inputRef: () => {
            /* do nothing */
        },
        decimalScale: 0,
        fixedDecimalScale: false,
    };

    isCheckable() {
        return ['checkbox', 'radio'].includes(this.props.type);
    }

    renderInput(props: Props) {
        const filteredProps = excludeReduxFormProps(props);

        switch (props.type) {
            case 'date':
                return (
                    <DateInput
                        {...filteredProps}
                        onChange={filteredProps.onChange}
                        minDate={filteredProps.minDate}
                        locale={props.intl.locale}
                    />
                );
            case 'rich-text':
                return <RichTextArea {...filteredProps} onChange={debounce(filteredProps.onChange, 500)} />;
            case 'stepper':
                filteredProps.componentClass = Stepper;
                filteredProps.value = filteredProps.value || 0;

                break;
            case 'autosize-textarea':
                filteredProps.componentClass = Textarea;
                filteredProps.style = { resize: 'none' };
                filteredProps.rows = 3;
                filteredProps.id = filteredProps.name;
                break;
            default:
                break;
        }

        // If we get passed a `FormattedMessage` do some magic to support it in a placeholder
        if (filteredProps.placeholder && typeof filteredProps.placeholder === 'object') {
            // TODO: add the defaultMessage prop, I removed it so I can see the translation keys
            filteredProps.placeholder = this.props.intl.formatMessage({
                id: filteredProps.placeholder.props.id,
                defaultMessage: filteredProps.placeholder.props.defaultMessage,
            });
        }

        let input = props.children || (
            <FormControl
                {...filteredProps}
                inputRef={this.props.inputRef}
                data-testid={props['data-testid']}
                autoCapitalize="off"
                autoCorrect="off"
                autoComplete="off"
                spellCheck="false"
                id={props.name}
            />
        );

        if (props.type === 'range') {
            input = <Slider {...filteredProps} min={props.min} max={props.max} />;
        }

        if (props.type === 'price') {
            const { decimal, thousand } = accounting.settings.number;
            // Replace the correct decimal separator with the one we want to display.
            // eslint-disable-next-line
            function onValueChange({ floatValue }: { floatValue: number }) {
                if (Number.isFinite(floatValue)) {
                    props.onChange(String(floatValue));
                } else {
                    props.onChange('');
                }
            }

            input = (
                <NumberFormatEnhanced
                    thousandSeparator={thousand}
                    decimalSeparator={decimal}
                    decimalScale={this.props.decimalScale}
                    fixedDecimalScale={this.props.fixedDecimalScale}
                    data-testid={this.props['data-testid']}
                    className="form-control"
                    value={!isNaN(filteredProps.value) ? filteredProps.value : ''}
                    onFocus={evt => {
                        if (filteredProps.onFocus) {
                            filteredProps.onFocus(evt);
                        }
                    }}
                    disabled={props.disabled}
                    onBlur={evt => {
                        if (filteredProps.onBlur) {
                            const numericValue = convertFormattedFloat(
                                get(evt, 'currentTarget.value', ''),
                                decimal,
                                thousand,
                            );
                            evt.target = { ...evt.target, value: numericValue };

                            filteredProps.onBlur(evt);
                        }
                    }}
                    displayType="input"
                    type="text"
                    onValueChange={onValueChange}
                    isNumericString
                    getInputRef={this.props.inputRef}
                    allowEmptyFormatting={false}
                    placeholder={filteredProps.placeholder}
                    name={props.name}
                    id={props.name}
                    isAllowed={({ floatValue }) => {
                        if (props.max != null && props.min != null && floatValue) {
                            return floatValue >= props.min && floatValue <= props.max;
                        }

                        if (props.max != null && floatValue) {
                            return floatValue <= props.max;
                        }

                        if (props.min != null && floatValue) {
                            return floatValue > props.min;
                        }

                        return true;
                    }}
                />
            );
        }

        if (this.props.allowClear) {
            return (
                <InputGroup className="c-input-clear">
                    {input}
                    {!!filteredProps.value && (
                        <div
                            role="button"
                            className="c-input-clear__zone"
                            onClick={() => {
                                props.onChange({ target: { name: props.name, value: '' } });
                            }}
                        >
                            <ClearIcon className="c-input-clear__icon" />
                        </div>
                    )}
                </InputGroup>
            );
        }

        if (this.props.mask) {
            input = (
                <NumberFormat
                    {...filteredProps}
                    className="form-control"
                    format={this.props.mask.format}
                    mask={this.props.mask.mask}
                    getInputRef={this.props.inputRef}
                />
            );
        }

        if (this.props.withIcon) {
            return (
                <div className="input-with-icon">
                    {input}
                    <div className="c-icon">{this.props.withIcon}</div>
                </div>
            );
        }

        if (!this.props.addonAfter && !this.props.addonBefore) {
            return input;
        }

        return (
            <InputGroup>
                {this.props.addonBefore && <InputGroup.Addon>{this.props.addonBefore}</InputGroup.Addon>}
                {input}
                {this.props.addonAfter && (
                    <InputGroup.Addon className={this.props.addonAfterClassName}>
                        {this.props.addonAfter}
                    </InputGroup.Addon>
                )}
            </InputGroup>
        );
    }

    render() {
        const { horizontal, srcOnly } = this.props;

        const {
            wrapperClassName = horizontal ? 'col-sm-8' : undefined,
            labelClassName = horizontal ? 'col-sm-4' : undefined,
            label = horizontal ? this.props.name && toTitle(this.props.name.replace('_', ' ')) : undefined,
            placeholder = srcOnly ? label : undefined,
        } = this.props;

        let errorMessage = this.props.submitError || this.props.error;

        const showError = Boolean(this.props.touched && errorMessage);

        if (showError) {
            errorMessage = Object.keys(errorMessages).includes(errorMessage as string)
                ? this.props.intl.formatMessage(errorMessages[errorMessage as string], { fieldName: label })
                : errorMessage;
        }

        if (this.isCheckable()) {
            return <CheckableInput {...this.props} />;
        } else {
            const input = this.renderInput({ ...this.props, placeholder });
            const Wrapper = this.props.renderWrapper;
            if (!Wrapper) {
                return input;
            }

            const content = (
                <Wrapper {...this.props} bsStyle={showError ? 'error' : this.props.bsStyle}>
                    {label && (
                        <Col className={labelClassName}>
                            <ControlLabel
                                className={classNames({ 'sr-only': this.props.srOnly })}
                                htmlFor={this.props.name}
                            >
                                {label}
                            </ControlLabel>
                        </Col>
                    )}
                    <Col className={wrapperClassName}>
                        {input}
                        {this.props.help && <FormControl.Feedback />}
                        {showError && <HelpBlock>{errorMessage}</HelpBlock>}
                        {this.props.help && <HelpBlock className="hint">{this.props.help}</HelpBlock>}
                    </Col>
                    {this.props.feedback}
                </Wrapper>
            );

            return this.props.horizontal ? <div className="form-horizontal">{content}</div> : content;
        }
    }
}

const InputWithIntl = injectIntl(Input);

export default InputWithIntl;

function CheckableInput(props) {
    let errorMessage = props.submitError || props.error;
    const showError = Boolean(props.touched && errorMessage);

    const InputComponent = props.type === 'radio' ? Radio : Checkbox;

    const input = (
        <InputComponent
            checked={props.checked}
            onChange={props.onChange}
            onBlur={props.onBlur}
            disabled={props.disabled}
            value={props.value}
            name={props.name}
            className={classNames('control-label', {
                'text-muted': props.disabled,
            })}
            id={props.id}
        >
            {props.label}
        </InputComponent>
    );

    return props.inline ? (
        input
    ) : (
        <Col
            className={classNames(props.wrapperClassName, {
                'has-error': showError,
            })}
        >
            {input}
            {showError && <HelpBlock>{errorMessage}</HelpBlock>}
            {props.help && <HelpBlock className="hint">{props.help}</HelpBlock>}
        </Col>
    );
}

const separatorsRegex = {
    '.': /\./g,
    ',': /,/g,
};

function convertFormattedFloat(value: string, decimalSeparator: string = '.', thousandSeparator: string = ',') {
    if (value) {
        return parseFloat(
            value
                .slice()
                .replace(separatorsRegex[thousandSeparator], '')
                .replace(decimalSeparator, '.'),
        );
    }

    return '';
}

export function InputForFormik({ type, name, ...props }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField(name);
    const { onChange, value, checked } = field;
    // there are some type of fields that only pass back the value
    // directly on the onBlur and onChangeStatus handlers,
    // this is not what Formik expects, so we need to do a little transformation here
    const typesWithoutDOMEvent = ['date', 'price'];

    function handleChange(evtOrValue: any) {
        if (typesWithoutDOMEvent.includes(type)) {
            return helpers.setValue(evtOrValue);
        }

        return onChange(evtOrValue);
    }

    return (
        <InputWithIntl
            {...field}
            type={type}
            name={name}
            value={value}
            error={meta.error}
            touched={meta.touched}
            checked={checked}
            {...props}
            onChange={handleChange}
        />
    );
}

function ClearIcon({ className }) {
    return (
        <svg x="0px" y="0px" viewBox="0 0 11 11" enableBackground="new 0 0 11 11" className={className}>
            <g id="_x2E_png_134_">
                <path
                    fill="currentColor"
                    d="M5.5,0C2.4678,0,0,2.4678,0,5.5S2.4678,11,5.5,11S11,8.5322,11,5.5S8.5322,0,5.5,0z M7.5947,6.8877 c0.1953,0.1953,0.1953,0.5117,0,0.707C7.4971,7.6924,7.3691,7.7412,7.2412,7.7412S6.9854,7.6924,6.8877,7.5947L5.4912,6.1982 L4.0947,7.5947C3.9971,7.6924,3.8691,7.7412,3.7412,7.7412S3.4854,7.6924,3.3877,7.5947c-0.1953-0.1953-0.1953-0.5117,0-0.707 l1.3965-1.3965L3.3877,4.0947c-0.1953-0.1953-0.1953-0.5117,0-0.707s0.5117-0.1953,0.707,0l1.3965,1.3965l1.3965-1.3965 c0.1953-0.1953,0.5117-0.1953,0.707,0s0.1953,0.5117,0,0.707L6.1982,5.4912L7.5947,6.8877z"
                ></path>
            </g>
        </svg>
    );
}
