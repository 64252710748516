import Immutable from 'immutable';
import { languages } from '../../../app.data';
import AbstractPatchBuilder from './AbstractPatchBuilder';

export default class CompanyPublishSettingsPatchBuilder extends AbstractPatchBuilder {
    addLanguagesOperations() {
        if (!this.attributes.languages) {
            return;
        }

        this.addEnumFromObjects(
            'languages',
            languages.map(language => language.value),
        );
        this.update('default_locale', this.attributes.default_locale);
    }

    addIntegrationsOperations() {
        const attributes = Immutable.fromJS(this.attributes);

        if (!attributes.has('integration_document_management_sweepbright_documents')) {
            return;
        }

        const value = attributes.get('integration_document_management_sweepbright_documents');
        this.update('integration_document_management_sweepbright_documents', value === 'on' || value === true);
    }

    /**
     * @returns {Immutable.List}
     */
    getOperations() {
        const attributes = Immutable.fromJS(this.attributes);

        attributes.forEach((value: string, key: string) => {
            this.update(`/${key}`, value);
        });

        this.addLanguagesOperations();
        this.addIntegrationsOperations();

        return this.operations;
    }
}
