import React from 'react';
import map from 'lodash/map';
import { InputProps } from 'redux-form';
import { InjectedIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import availableConditions, { PROPERTY_CONDITIONS, LAND_CONDITIONS, USER_CONDITIONS } from '@/app.data/Conditions';
import { INTERACTION_FEEDBACK_COLOR } from '../../app.data';
import './Condition.scss';

type Props = {
    field: InputProps;
    icon: string;
    intl?: InjectedIntl;
    type?: typeof LAND_CONDITIONS | typeof PROPERTY_CONDITIONS | typeof USER_CONDITIONS;
    steps?: number;
};

const ConditionSelector: React.FunctionComponent<Props> = ({ field, icon, type = PROPERTY_CONDITIONS }) => {
    // array or object
    const conditions = availableConditions[type];
    const current: string = (field.value || conditions[0]).replace(' ', '-');

    const image = require(`@/../../resources/assets/svg/conditions/${icon}-${current}.svg`);

    const translationKeyPrefix = type === USER_CONDITIONS ? 'general.feedback_condition' : 'general.property_condition';
    const translationKeyPrefixForTitle =
        type === USER_CONDITIONS ? 'pages.vendor_report.interaction.feedback' : 'general.property_condition';

    return (
        <div className="center-block-contents">
            <h2
                className={`title-${INTERACTION_FEEDBACK_COLOR[current] || current} capitalize`}
                data-testid="selected-condition"
            >
                <FormattedMessage id={`${translationKeyPrefixForTitle}.${current}`} defaultMessage={current} />
            </h2>
            <img src={image} className="condition__icon c-spacer-bottom" alt="" />
            <div className="form-group" data-testid="condition-options">
                {map(conditions, (conditionDescription: string, condition: string | number) => {
                    // adapt for the array case
                    condition = typeof condition === 'number' ? conditionDescription : condition;

                    return (
                        <label
                            htmlFor={`${field.name}_condition_option_${condition}`}
                            key={condition}
                            className={`radio-inline bc-radio-${INTERACTION_FEEDBACK_COLOR[condition] ||
                                condition} capitalize`}
                        >
                            <input
                                id={`${field.name}_condition_option_${condition}`}
                                name={field.name}
                                type="radio"
                                onChange={field.onChange}
                                value={condition}
                                checked={field.value === condition}
                            />
                            <FormattedMessage id={`${translationKeyPrefix}.${condition}`} defaultMessage={condition} />
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

export default ConditionSelector;
