import set from 'lodash/set';
import get from 'lodash/get';
import produce, { Draft } from 'immer';

export const updateConnection = (path: string) =>
    produce(
        <Query>(
            prev: Draft<Query>,
            {
                fetchMoreResult,
            }: {
                fetchMoreResult?: Query;
            },
        ) => {
            if (!fetchMoreResult) return prev;

            const edgesPath = `${path}.edges`;
            const pageInfoPath = `${path}.pageInfo`;
            const nodesPath = `${path}.nodes`;

            set(prev, edgesPath, get(prev, edgesPath, []).concat(get(fetchMoreResult, edgesPath, [])));
            set(prev, nodesPath, get(prev, nodesPath, []).concat(get(fetchMoreResult, nodesPath, [])));
            set(prev, pageInfoPath, get(fetchMoreResult, pageInfoPath));

            return prev;
        },
    );
