// @ts-nocheck
/* eslint-disable no-use-before-define */
import React from 'react';
import { Map } from 'immutable';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Row, Col } from 'react-bootstrap';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl-sweepbright';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import useField from '@/app.hooks/useField';
import AddChannelAccountTile from '@/app.domains/channels/elements/AddChannelAccountTile';
import { useToasts } from '@sweepbright/notifications/src';
import { getBugsnagClient } from '@/app.config/bugsnag';
import CountrySelector from '@/app.components/forms/CountrySelector';
import Input from '@/app.components/forms/Input/Input';
import { Icon } from '@/app.components';
import TileList from '@/app.components/elements/Tile/TileList';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import PagePane from '@/app.layouts/PagePane/PagePane';
import BackButton from '@/app.components/elements/Buttons/BackButton';
import { CHANNELS_PORTAL, CHANNELS_PORTALS } from '@/app.routing';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import useRouter from '@/app.hooks/useRouter';

const GET_ALL_PORTALS = gql`
    query GetAllPortals {
        channels {
            id
            countries
            name
            logoUrl
            settings {
                allowsMultipleAccounts
            }
            accounts {
                id
            }
        }
    }
`;

// checks is the channel is
function isChannelAvailableInCountry(currentCountry, channel) {
    if (!currentCountry) {
        return true;
    }

    if (channel.countries.length === 0) {
        return true;
    }

    return channel.countries.includes(currentCountry);
}

type Props = {
    company: Map<string, any>;
    open: () => void;
    close: () => void;
    modalWithPromise: () => void;
    isMultipleAccountsEnabled: boolean;
};

const PortalOverview = (props: Props) => {
    const router = useRouter();
    const queryField = useField('');
    const countryField = useField(props.company.getIn(['settings', 'data', 'contact_country']));
    const { addSuccess } = useToasts();

    const twitterEnabled = useFeatureFlag('social.connectTwitterAccount.enabled');

    const { loading, data } = useQuery(GET_ALL_PORTALS, {
        fetchPolicy: 'cache-and-network',
        onError(error) {
            getBugsnagClient().notify(error);
        },
    });

    const channels = !loading && data ? data.channels : [];

    const filteredChannels = React.useMemo(() => {
        return channels
            .filter(el => {
                if (el.id === 'twitter') {
                    return twitterEnabled;
                }

                return true;
            })
            .filter(byCountry(countryField.value))
            .filter(canCreateAccount(props.isMultipleAccountsEnabled))
            .filter(byQuery(queryField.value));
    }, [countryField.value, queryField.value, channels]);

    const handleAccountAdded = (channel, id) => {
        if (id) {
            router.push(CHANNELS_PORTAL(id));
        } else {
            router.push(CHANNELS_PORTALS);
        }

        addSuccess({
            title: <FormattedMessage id="portals.alerts.configured.title" defaultMessage="Portal Added" />,
            message: (
                <FormattedMessage
                    id="portals.alerts.configured.body"
                    defaultMessage="Great! You have successfully added {portal}."
                    values={{ portal: channel.name }}
                />
            ),
        });
    };

    return (
        <PagePane
            cols={{ lg: 9, md: 15 }}
            backAction={<BackButton to={CHANNELS_PORTALS} />}
            title={<FormattedMessage id="titles.portals.overview" defaultMessage="Add real estate portals" />}
        >
            <FormPanel>
                <Row>
                    <Col sm={9}>
                        <Input
                            feedback={<Icon icon="search" className="form-control-feedback" />}
                            placeholder={
                                <FormattedMessage
                                    id="portals.forms.search.placeholder"
                                    defaultMessage="Search portals"
                                />
                            }
                            {...queryField}
                        />
                    </Col>
                    <Col sm={6}>
                        <CountrySelector {...countryField} />
                    </Col>
                </Row>

                <TileList grid bordered size="lg">
                    {filteredChannels.map(channel => (
                        <AddChannelAccountTile
                            key={channel.id}
                            channel={channel}
                            company={props.company}
                            onAccountAdded={handleAccountAdded}
                        />
                    ))}
                </TileList>
            </FormPanel>
        </PagePane>
    );
};

export default compose(
    withErrorBoundary,
    connect(
        createStructuredSelector({
            isMultipleAccountsEnabled: getFeature('portals.multipleAccounts.enabled'),
        }),
    ),
)(PortalOverview);

// filters
const byCountry = country => channel => {
    if (!isChannelAvailableInCountry(country, channel)) {
        return false;
    }

    return true;
};

const byQuery = query => channel => {
    return channel.name.toLowerCase().includes(query);
};

// wether an account can be created or not for that channel
const canCreateAccount = isMultipleAccountsEnabled => channel => {
    if (channel.settings.allowsMultipleAccounts && isMultipleAccountsEnabled) {
        return true;
    }
    // has no accounts at all
    if (channel.accounts.length === 0) {
        return true;
    }

    return false;
};
