import React from 'react';
import { Waypoint } from 'react-waypoint';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { FormattedMessage } from 'react-intl-sweepbright';
import Timeline from '@/app.components/elements/Timeline/Timeline';
import TimelineItem from '@/app.components/elements/Timeline/TimelineItem';
import { getCardForActivity } from '@/app.components/card/ActivityCard';
import EmptyState from '@/app.components/empty/EmptyState';
import { useTimelineData } from '../hooks/useTimelineData';

const ContactTimeline = React.forwardRef(function ContactTimeline(
    { contactId, propertyId }: { contactId: string; propertyId?: string },
    forwardedRef,
) {
    const { activities, pageInfo, loading, handleFetchAfter, handleFetchBefore } = useTimelineData({
        contactId,
        propertyId,
    });

    React.useImperativeHandle(
        forwardedRef,
        () => ({
            refresh: handleFetchBefore,
        }),
        [pageInfo, activities, loading],
    );

    return (
        <>
            {activities.length > 0 ? (
                <Timeline title={<FormattedMessage id="timeline.title" defaultMessage="Past" />}>
                    {activities.map(activity => {
                        const Card = getCardForActivity(activity);

                        return (
                            <TimelineItem key={activity.id}>
                                <Card activity={activity} viewAs="contact" />
                            </TimelineItem>
                        );
                    })}
                    {pageInfo.hasNextPage && !loading ? (
                        <Waypoint key={pageInfo.endCursor!} onEnter={handleFetchAfter} />
                    ) : null}
                </Timeline>
            ) : null}
            {loading && <LoadingIndicator />}
            {!loading && activities.length === 0 && (
                <EmptyState
                    title={
                        <FormattedMessage
                            id="contact.activity.timeline_section.empty.title"
                            defaultMessage="Whoops, no activity yet"
                        />
                    }
                    body={
                        <FormattedMessage
                            id="contact.activity.timeline_section.empty.subtitle"
                            defaultMessage="All activities for this contact will show up here."
                        />
                    }
                />
            )}
        </>
    );
});

export { ContactTimeline };
