import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import Icon from '../../icons/Icon';

const SuccessAlert = ({ title, body, borderless, action }) => {
    const alertRef = React.useRef(null);
    React.useEffect(() => {
        const ref = alertRef;
        setTimeout(() => {
            if (ref.current) {
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
                ref.current.focus();
            }
        }, 300);
    }, [title, body]);

    return (
        <div tabIndex={0} ref={alertRef}>
            <Alert bsStyle="success" className={classnames({ 'alert-borderless': borderless })}>
                <Icon icon="check" className="alert-success__icon" />
                <h2 className="alert-title">{title}</h2>
                {body && <p>{body}</p>}
                {action ? <div className="c-spacer-s" /> : null}
                {action ? <div className="alert__action">{action}</div> : null}
            </Alert>
        </div>
    );
};

SuccessAlert.propTypes = {
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    borderless: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    action: PropTypes.node,
};

const ErrorAlert = ({ title, body, borderless }) => {
    const alertRef = React.useRef(null);
    React.useEffect(() => {
        const ref = alertRef;
        setTimeout(() => {
            if (ref.current) {
                ref.current.scrollIntoView({
                    behavior: 'smooth',
                });
                ref.current.focus();
            }
        }, 300);
    }, []);

    return (
        <div tabIndex={0} ref={alertRef}>
            <Alert bsStyle="danger-subtle" className={classnames({ 'alert-borderless': borderless })}>
                <Icon icon="warning" className="alert-danger__icon" />
                <h2 className="alert-title">{title}</h2>
                {body && <p>{body}</p>}
            </Alert>
        </div>
    );
};

SuccessAlert.propTypes = {
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    borderless: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

ErrorAlert.propTypes = {
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    borderless: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const HIDE_ALERT_TIMEOUT_DEFAULT = 5000;

export { SuccessAlert, ErrorAlert, Alert, HIDE_ALERT_TIMEOUT_DEFAULT };
