import gql from 'graphql-tag';

export const GET_OFFICE_ESTATES_QUERY = gql`
    query GetOfficeEstates(
        $query: String
        $filters: EstateFilters!
        $page: Int
        $limit: Int
        $locale: String
        $useAtlasSearch: Boolean
        $usePostRequest: Boolean
    ) {
        estates(
            query: $query
            filters: $filters
            page: $page
            limit: $limit
            locale: $locale
            useAtlasSearch: $useAtlasSearch
            usePostRequest: $usePostRequest
        ) {
            edges {
                node {
                    id
                    type
                    rentPeriod
                    internalType
                    isProject
                    negotiation
                    createdAt
                    updatedAt
                    attributes
                    archived
                    status
                    office_id
                    negotiator {
                        id
                        firstName
                        lastName
                        photo
                    }
                    offersStats {
                        number_of_pending_offers
                        highest_offer_bid
                        has_accepted_offer
                        closest_offer_expirations
                        currency
                    }
                    visibility
                    error_publications_count
                    successful_publications_count
                    first_publication_date
                }
            }
            pageInfo {
                currentPage
                hasNextPage
                itemsPerPage
            }
            totalCount
        }
    }
`;
