import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Modal, Row, Col, Alert } from 'react-bootstrap';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import Button from '@/app.components/elements/Buttons/Button';
import { Input } from '../../app.components';
import { Validator } from '../../app.utils/services';
import { getRequestInitialValues } from '../../app.redux/selectors';

class FileRequestModal extends Component {
    static propTypes = {
        action: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        close: PropTypes.func.isRequired,
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    };

    render() {
        const { fields, title, description, action, close, handleSubmit } = this.props;
        const classes = {
            labelClassName: 'col-sm-4 control-label',
            wrapperClassName: 'col-sm-11',
        };

        return (
            <form
                onSubmit={evt => {
                    // https://github.com/redux-form/redux-form/issues/3701
                    // prevent submit of "parent" form
                    evt.stopPropagation();
                    handleSubmit(evt);
                }}
                className="form-horizontal"
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        type="text"
                        label={<FormattedMessage id="forms.labels.email" defaultMessage="E-mail" />}
                        {...classes}
                        {...fields.email}
                        placeholder={
                            <FormattedMessage id="forms.placeholder.to" defaultMessage="Enter e-mail address" />
                        }
                    />
                    <Input
                        type="text"
                        label={<FormattedMessage id="forms.labels.name" defaultMessage="Name" />}
                        {...classes}
                        {...fields.name}
                        placeholder={<FormattedMessage id="forms.placeholder.name" defaultMessage="Enter name" />}
                    />
                    <Input
                        type="autosize-textarea"
                        label={<FormattedMessage id="forms.labels.message" defaultMessage="Message" />}
                        {...classes}
                        {...fields.message}
                        placeholder={
                            <FormattedMessage id="forms.placeholder.message" defaultMessage="Type a message" />
                        }
                    />
                    {description && (
                        <Row>
                            <Col sm={11} smPush={4}>
                                <Alert bsStyle="info">
                                    <p>{description}</p>
                                </Alert>
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={close}>
                        <FormattedMessage id="forms.request-file.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button type="submit" variant="primary">
                        {action}
                    </Button>
                </Modal.Footer>
            </form>
        );
    }
}

export default reduxForm(
    {
        form: 'file_request',
        fields: ['email', 'name', 'message'],
        validate: Validator.validateWithRules(
            {
                email: {
                    required: true,
                    format: 'email',
                    messages: {
                        required: 'general.validation.field_required',
                        format: 'email.validation_errors.invalid',
                    },
                },
                name: {
                    required: true,
                    allowEmpty: false,
                    messages: {
                        required: 'general.validation.field_required',
                        allowEmpty: 'general.validation.field_required',
                    },
                },
                message: {
                    required: true,
                    allowEmpty: false,
                    messages: {
                        required: 'general.validation.field_required',
                        allowEmpty: 'general.validation.field_required',
                    },
                },
            },
            { humanizeErrorMessage: false },
        ),
    },
    createStructuredSelector({
        initialValues: getRequestInitialValues,
    }),
)(FileRequestModal);
