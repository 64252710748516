import { put } from 'redux-saga/effects';
import { EstatesRequests } from '../../../requests/index';
import { fetchProperty } from '../../actions';
import apiCall from '../Effects/apiCall';

export default function* onRemovePropertyNegotiator({ propertyId, negotiatorId }) {
    if (negotiatorId) {
        yield apiCall(new EstatesRequests().removeNegotiator, propertyId, negotiatorId);
        yield put(fetchProperty(propertyId));
    }
}
