//@ts-nocheck
import { Button, Tabs } from '@sweepbright/uikit';
import pick from 'lodash/pick';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { FormattedMessage } from 'react-intl-sweepbright';
import { createBucket, createDocumentForEstate } from '@/app.redux/actions/index';
import PagePane from '@/app.layouts/PagePane/PagePane';
import FormikWithConfirmation from '@/app.components/forms/helpers/FormikWithConfirmation';
import DocumentsTabContent, { PlansList } from '@/app.domains/properties/elements/DocumentsListManager';
import { nullifyEmptyFields } from '@/app.utils/services/Helpers/forms';
import { withPropertyDetails } from '../../../../../app.domains/properties/withPropertyDetails';

enum TabIndex {
    Documents = 0,
    Plans = 1,
}

//TODO: remove any
function DocumentsAndPlansForm(props: any) {
    const {
        location: { hash },
        editingDisabled,
    } = props;
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [currentTab, setCurrentTab] = useState(hash === '#plans' ? TabIndex.Plans : TabIndex.Documents);

    return (
        <FormikWithConfirmation
            skipConfirmation={editingDisabled}
            route={props.route}
            onSubmit={(values, formikBag) => {
                return props
                    .onSubmit({
                        ...values,
                        ...nullifyEmptyFields(
                            pick(values, 'legal', 'occupancy', 'tenant_contract', 'settings', 'documents'),
                        ),
                    })
                    .catch(errors => {
                        formikBag.setErrors(errors);
                    });
            }}
            enableReinitialize
            initialValues={props.initialValues}
        >
            {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate className="h-full w-full min-w-0">
                    <PagePane
                        title={
                            <FormattedMessage id="titles.property.docs-and-plans" defaultMessage="Add docs and plans" />
                        }
                        actions={[
                            <Button
                                key="submit"
                                type="submit"
                                variant="success"
                                data-testid="submit_btn"
                                disabled={isSubmitting || disableSubmit || editingDisabled}
                            >
                                {editingDisabled ? (
                                    <FormattedMessage id="property.status.archived" defaultMessage="Archived" />
                                ) : isSubmitting ? (
                                    <FormattedMessage id="form.status.saving" defaultMessage="Saving..." />
                                ) : (
                                    <FormattedMessage id="form.status.save" defaultMessage="Save" />
                                )}
                            </Button>,
                        ]}
                    >
                        <Tabs index={currentTab} onChange={setCurrentTab}>
                            <Tabs.TabList>
                                <Tabs.Tab type="button" data-testid="documents-tab">
                                    <FormattedMessage id="forms.legal.tabs.documents" defaultMessage="Documents" />
                                </Tabs.Tab>
                                <Tabs.Tab type="button" data-testid="plans-tab">
                                    <FormattedMessage id="forms.legal.tabs.plans" defaultMessage="Plans" />
                                </Tabs.Tab>
                            </Tabs.TabList>

                            <Tabs.TabPanels>
                                <Tabs.TabPanel data-testid="documents-tab-content">
                                    <DocumentsTabContent
                                        editingDisabled={editingDisabled}
                                        property={props.property}
                                        handleSubmit={props.handleSubmit}
                                        setDisabled={setDisableSubmit}
                                        createBucket={props.createBucket}
                                        createDocumentForEstate={props.createDocumentForEstate}
                                    />
                                </Tabs.TabPanel>
                                <Tabs.TabPanel data-testid="plans-tab-content">
                                    <PlansList
                                        editingDisabled={editingDisabled}
                                        property={props.property}
                                        handleSubmit={props.handleSubmit}
                                        setDisabled={setDisableSubmit}
                                        createBucket={props.createBucket}
                                    />
                                </Tabs.TabPanel>
                            </Tabs.TabPanels>
                        </Tabs>
                    </PagePane>
                </form>
            )}
        </FormikWithConfirmation>
    );
}

export default compose(
    withProps((props: any) => ({
        estateId: props.params.unit || props.params.property,
    })),
    withPropertyDetails('documents'),
    connect(null, { createBucket, createDocumentForEstate }),
)(DocumentsAndPlansForm);
