import React from 'react';
import sumBy from 'lodash/sumBy';
import isObject from 'lodash/isObject';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { LoadingIndicator } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { Estate } from '@/graphql/generated/types';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import { ErrorBadge } from '@/app.components/errors/ErrorBadge';
import useProjectUnits from '@/app.hooks/useProjectUnits';

export function UnitsTable({
    projectId,
    missingFieldsByUnitId,
    activeUnitId,
}: {
    projectId: string;
    missingFieldsByUnitId: Record<string, string[]>;
    activeUnitId: string;
}) {
    const { units, loading, pageInfo, fetchMore } = useProjectUnits(projectId);
    const { errors } = useFormikContext();
    const formikErrorsCount = countErrors(errors);

    // fetch all the units
    // as the endpoint is paginated
    // we dont use infinite scrolling here
    React.useEffect(() => {
        if (!loading && pageInfo.hasNextPage) {
            fetchMore();
        }
    }, [pageInfo.hasNextPage, loading]);

    return (
        <div className="w-full h-full max-w-sm pr-2 border-r border-gray-light mr-6 overflow-y-auto">
            <FormPanel title={<FormattedMessage id="titles.property.units" defaultMessage="units" />}>
                <ul className="m-0 p-0 -mt-2">
                    <li className="grid grid-cols-3 p-2 leading-none border-b border-gray-light mb-1">
                        <div className=" text-muted text-sm text-right pr-5">
                            <FormattedMessage id="price.published_price.amount" defaultMessage="Price" />
                        </div>
                        <div className=" text-muted text-sm">
                            <FormattedMessage id="structure.bedrooms" defaultMessage="Bedrooms" />
                        </div>
                        <div className=" text-muted text-sm">
                            <FormattedMessage id="publish.errors.section.title" defaultMessage="Errors" />
                        </div>
                    </li>
                    {/* eslint-disable-next-line react/display-name */}
                    {units
                        .filter(unit => !unit.archived)
                        .map((unit: Estate) => {
                            const missingFields = missingFieldsByUnitId[unit.id];

                            return (
                                <UnitRowEntry
                                    key={unit.id}
                                    unit={unit}
                                    active={activeUnitId === unit.id}
                                    missingFieldsCount={missingFields?.length ?? 0}
                                    activeErrorCount={formikErrorsCount}
                                />
                            );
                        })}
                    {loading && <LoadingIndicator />}
                </ul>
            </FormPanel>
        </div>
    );
}

function UnitRowEntry({
    unit,
    missingFieldsCount,
    active,
    activeErrorCount,
}: {
    active: boolean;
    missingFieldsCount: number;
    unit: Estate;
    activeErrorCount: number;
}) {
    const repository = new EstateRepository(unit);

    return (
        <li
            key={unit.id}
            className={classNames('grid grid-cols-3 p-2 rounded hover:bg-gray-light', {
                'bg-gray-lightest': active,
            })}
        >
            <span className="leading-5 text-sm text-right pr-5">
                {repository.getPrice({ type: 'published_price', fallback: '-' })}
            </span>
            <span className="leading-5 text-sm">{unit.attributes.structure.bedrooms ?? 0}</span>
            <span className="leading-5 text-sm">
                <UnitRowMissingFields
                    key={unit.id}
                    active={active}
                    activeErrorCount={activeErrorCount}
                    missingFieldsCount={missingFieldsCount}
                />
            </span>
        </li>
    );
}

function UnitRowMissingFields(props: { active: boolean; activeErrorCount: number; missingFieldsCount: number }) {
    const errorCount = props.active ? props.activeErrorCount : props.missingFieldsCount;

    return <ErrorBadge count={errorCount} hideIfZeroCount={false} />;
}

// get the number of errors
// taking into account its nested structure
function countErrors(errors) {
    return sumBy(Object.values(errors), value => {
        if (isObject(value)) {
            return countErrors(value);
        }

        return 1;
    });
}
