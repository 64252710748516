/* eslint-disable filenames/match-regex */
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getDefaultsSearchSettings } from '@/app.redux/selectors/SearchSelectors';
import PropertiesSearchModal from '../../app.components/modals/PropertiesSearchModal.V2';
import Search from '../../app.components/navigations/SideMenu/SearchV2';
import { getPropertyFiltersComparator } from '../../app.utils/services/Helpers/filtersComparator';

type Props = {
    className?: string;
    // default filter values
    defaultValues: Record<string, any>;
    excludeProjects?: boolean;
    // slot for the modal component
    filtersModal?: React.ComponentType;
    initialValues: Record<string, any>;
    modalProps?: any;
    onSubmit: (attributes: Record<string, any>) => void;
    text?: React.ReactNode;
};

const PropertiesSearch = ({
    defaultValues,
    initialValues,
    onSubmit,
    filtersModal,
    excludeProjects,
    modalProps,
    ...props
}: Props) => {
    const defaults = {
        ...defaultValues,
        property_type: excludeProjects ? 'properties' : defaultValues.property_type,
    };

    const initials = {
        ...initialValues,
        property_type: excludeProjects ? 'properties' : defaultValues.property_type,
    };

    return (
        <Search
            visible
            {...props}
            filterable
            filtersModal={filtersModal}
            onChange={onSubmit}
            defaultValues={defaults}
            initialValues={initials}
            modalProps={{ ...modalProps, excludeProjects }}
            equalityChecker={getPropertyFiltersComparator(excludeProjects ? ['property_type'] : [])}
        />
    );
};

PropertiesSearch.defaultProps = {
    filtersModal: PropertiesSearchModal,
};

const mapStateToProps = createStructuredSelector({
    defaultValues: getDefaultsSearchSettings('properties'),
});

export default connect(mapStateToProps)(PropertiesSearch);
