import React from 'react';
import { Waypoint } from 'react-waypoint';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { LoadingIndicator } from '@sweepbright/uikit';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import PreferenceCard from '@/app.domains/contacts/PreferenceCard';
import { PreferenceCardFragment } from '@/graphql/fragments/contactPreference';
import { CONTACT_DETAILS_PREFERENCE } from '@/app.routing/routes';
import PagePane from '@/app.layouts/PagePane/PagePane';
import { getBugsnagClient } from '@/app.config/bugsnag';
import EmptyState from '@/app.components/empty/EmptyState';
import { events, track } from '@/app.utils/analytics';
import { useContactPreferences } from '@/app.hooks/useContactPreferences';
import useContact from '@/app.hooks/useContact';
import { useToasts } from '@sweepbright/notifications';

const ADD_LEAD_PREFERENCE_MUTATION = gql`
    mutation AddLeadPreferenceMutation($contactId: ID!) {
        addLeadPreference(input: { contactId: $contactId }) {
            preference {
                ...PreferenceCardFragment
            }
        }
    }
    ${PreferenceCardFragment}
`;

export default function PreferencesPage({ params, router }) {
    const contactId = params.contact;

    const { contact } = useContact(contactId);

    const { loading, preferencesConnection, fetchMore } = useContactPreferences(contactId);

    const { addSuccess, addError } = useToasts();
    const [addPreference, { loading: addingPreference }] = useMutation(ADD_LEAD_PREFERENCE_MUTATION, {
        notifyOnNetworkStatusChange: true,
        onError(err) {
            getBugsnagClient().notify(err);
            addError({
                message: <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />,
            });
        },
        onCompleted() {
            addSuccess({
                message: <FormattedMessage id="preferences.added" defaultMessage="Preference added" />,
            });
        },
    });

    const handleAddPreference = async () => {
        track(events.CONTACT_ADD_PREFERENCE_BTN_CLICKED);
        const { data } = await addPreference({
            variables: {
                contactId,
            },
        });
        router.push(CONTACT_DETAILS_PREFERENCE(contactId, data.addLeadPreference.preference.id));
    };

    const pageInfo = preferencesConnection?.pageInfo;
    const preferences = preferencesConnection?.nodes ?? [];
    const { currentPage = 1, hasNextPage } = pageInfo ?? {};

    const handleFetchMore = () => {
        if (!loading) {
            fetchMore(currentPage + 1);
        }
    };

    return (
        <PagePane
            title={<FormattedMessage id="pages.contacts.section_labels.preferences" defaultMessage="Preferences" />}
            actions={[
                <Button
                    key="add_preference"
                    variant="success"
                    onClick={handleAddPreference}
                    disabled={addingPreference || contact?.archived}
                    data-testid="add_preference"
                >
                    <FormattedMessage id="misc.add_preference" defaultMessage="Add Preference" />
                </Button>,
            ]}
        >
            {preferences.length > 0 && (
                <ul className="bc-bordered-list">
                    {/* eslint-disable-next-line react/display-name */}
                    {preferences.map(preference => (
                        <li key={preference.id}>
                            <PreferenceCard
                                preference={preference}
                                to={CONTACT_DETAILS_PREFERENCE(contactId, preference.id)}
                            />
                        </li>
                    ))}
                    {!loading && hasNextPage && <Waypoint key={currentPage} onEnter={handleFetchMore} />}
                </ul>
            )}
            {!loading && preferences.length === 0 && (
                <EmptyState
                    title={
                        <FormattedMessage
                            id="contact_preferences.empty_view.title"
                            defaultMessage="No preferences yet"
                        />
                    }
                    body={
                        <FormattedMessage
                            id="contact_preferences.empty_view.description"
                            defaultMessage="Preferences for this contact will show up here"
                        />
                    }
                />
            )}
            {loading && <LoadingIndicator />}
        </PagePane>
    );
}
