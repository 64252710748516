import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';

// validation rule for the name of the channel account
export const nameValidationRule = {
    type: 'string',
    allowEmpty: false,
    required: true,
    maxLength: 40,
    messages: {
        maxLength: 'channels.validation.name_too_long',
        required: 'channels.validation.name_required',
        allowEmpty: 'channels.validation.name_required',
    },
};

const hasFields = (obj, field) => obj.hasOwnProperty(`${field}`) || obj?.credentials.hasOwnProperty(`${field}`);

export function validateSocialPortals(values: Record<string, any>, modalProps: { [prop: string]: any }) {
    const errors: {
        name?: React.ReactNode;
        _error?: React.ReactNode;
        credentials?: {
            accessToken?: React.ReactNode;
        };
    } = {};

    const channelName = modalProps.portal.name;

    if (!values.name) {
        errors.name = <FormattedMessage id="channels.validation.name_required" defaultMessage="Name is required" />;
    } else if (values.name.trim().length > nameValidationRule.maxLength) {
        errors.name = (
            <FormattedMessage
                id="channels.validation.name_too_long"
                defaultMessage="Name is too long (maximum is 40 characters)"
                values={{ maxLength: nameValidationRule.maxLength }}
            />
        );
    }

    if (hasFields(values.credentials, 'accessTokenSecret') && !values.credentials?.accessTokenSecret) {
        errors._error = (
            <FormattedMessage
                id="channels.validation.login_required"
                defaultMessage="You need to login to {channel} first"
                values={{ channel: channelName }}
            />
        );
    }

    if (hasFields(values.credentials, 'accessToken') && !values.credentials?.accessToken) {
        errors.credentials = {
            accessToken: (
                <FormattedMessage
                    id="channels.validation.page_required"
                    defaultMessage="{channel} Page is required"
                    values={{ channel: channelName }}
                />
            ),
        };
    }

    return errors;
}
