import uuid from 'uuid';
import { extractData } from '@sweepbright/margaret-fetcher';
import {
    APIContactAssignedNegotiatorsInput,
    APIEstate,
} from '@sweepbright/webapp-server/graphql/resolvers/helpers/types';
import { InteractionType } from '@/app.data/Interactions';
import AbstractArchivableRequest from './AbstractArchivableRequest';
import { PaginatedResponse } from './types';

export default class ContactsRequests extends AbstractArchivableRequest {
    resource = 'contacts';

    index = (
        settings: {
            type?: Maybe<string>;
            query?: Maybe<string>;
            page?: Maybe<number>;
            limit?: Maybe<number>;
            archived?: boolean;
            interest?: Maybe<string[]>;
            sort_order?: Maybe<string>;
            sort_field?: Maybe<string>;
            negotiation?: Maybe<string>;
            min_price?: Maybe<number>;
            max_price?: Maybe<number>;
            filter_identifier?: 'contacts';
        } = {},
        includes: string[] = [],
    ) => {
        return this.withMeta()
            .withQueryParameters(settings)
            .withQueryParameter('includes', includes)
            .get(this.resource);
    };

    show = (id: string) => this.showWithIncludes(id, []);

    showWithIncludes = (id: string, includes: string[]) =>
        this.withoutMiddlewares()
            .withMiddleware(extractData)
            .withQueryParameter('includes', ['office', ...includes])
            .get(`/${this.resource}/${id}`);

    getReference = (id: string) => {
        return this.withMeta().get(`/${this.resource}/${id}/point-of-reference`);
    };

    csv = (settings: { office: string; type: string }) => {
        return this.withoutMiddlewares()
            .setQueryParameters(settings)
            .withOptions({
                headers: {
                    Accept: 'application/vnd.sweepbright.v1+csv',
                    'X-Sweepbright-Office': settings.office,
                },
            })
            .get(this.resource);
    };

    photos = (contactId: string, file: any) => {
        return this.storeFile(`${contactId}/photo`, file);
    };

    estates = (contactId: string, params: { page?: number; limit?: number }) => {
        return this.withMeta()
            .setQueryParameters(params)
            .get(`vendors/${contactId}/estates`);
    };

    removePhoto = (contactId: string) => {
        return this.delete(`${this.resource}/${contactId}/photo`);
    };

    address = (contactId: string, preferences: {}) => {
        return this.put(`${this.resource}/${contactId}/address`, preferences);
    };

    getPreferences = (contactId: string, params: { page?: Maybe<number>; limit?: number }) => {
        return this.withMeta()
            .setQueryParameters(params)
            .get(`/contacts/${contactId}/preferences`);
    };

    getPreference = (contactId: string, preferenceId: string) => {
        return this.get(`/contacts/${contactId}/preferences/${preferenceId}`);
    };

    createPreference = (contactId: string) => {
        return this.post(`/contacts/${contactId}/preferences`, {});
    };

    updatePreference = (contactId: string, preferenceId: string, attributes: FixMeType) => {
        return this.put(`/contacts/${contactId}/preferences/${preferenceId}`, attributes);
    };

    removePreference = (contactId: string, preferenceId: string) => {
        return this.delete(`/contacts/${contactId}/preferences/${preferenceId}`);
    };

    interacted = (contactId: string) => {
        return this.get(`${this.resource}/${contactId}/interacted`);
    };

    // TODO: remove this method when the interaction section is
    // removed
    activeInterests = (contactId: string, attributes: {}) => {
        return this.withMeta()
            .setQueryParameters(attributes)
            .get(`leads/${contactId}/active-interests`);
    };

    // TODO: remove this method when the interaction section is
    // removed
    inactiveInterests = (contactId: string, attributes: {}) => {
        return this.withMeta()
            .setQueryParameters(attributes)
            .get(`leads/${contactId}/inactive-interests`);
    };

    storeLead = (attributes: {
        first_name: string;
        last_name: string;
        phone: string;
        email: string;
        locale: string;
    }) => {
        return this.updateLead(uuid.v4(), attributes);
    };

    storeVendor = (attributes: {}) => {
        return this.updateVendor(uuid.v4(), attributes);
    };

    updateLead = (contactId: string, attributes: {}) => {
        return this.put(`leads/${contactId}`, attributes);
    };

    updateVendor = (contactId: string, attributes: {}) => {
        return this.put(`vendors/${contactId}`, attributes);
    };

    locationPreferences = (
        contactId: string,
        attributes: {
            limit?: number;
            page?: number;
        },
    ) => {
        return this.withoutMiddlewares()
            .withQueryParameters(attributes)
            .get(`leads/${contactId}/location-preferences`);
    };

    locationPreferencesV2 = (
        contactId: string,
        preferenceId: string,
        attributes: {
            limit?: number;
            page?: number;
        },
    ) => {
        return this.withMeta()
            .withQueryParameters(attributes)
            .get(`contacts/${contactId}/preferences/${preferenceId}/location-preferences`);
    };

    bindArea = (contactId: string, areaId: string) => {
        return this.put(`leads/${contactId}/location-preferences/${areaId}`);
    };

    removeArea = (contactId: string, areaId: string) => {
        return this.delete(`leads/${contactId}/location-preferences/${areaId}`);
    };

    scheduleVisit = (
        contactId: string,
        propertyId: string,
        dateTime: Date | string,
        negotiatorId: string,
        message: string,
        duration?: Maybe<number>,
    ) => {
        return this.post(`properties/${propertyId}/visits`, {
            contact_id: contactId,
            negotiator_id: negotiatorId,
            date_time: dateTime,
            message,
            duration,
        });
    };

    registerInteraction = (
        contactId: string,
        propertyId: string,
        type: InteractionType,
        comment?: string,
        sendToOwner?: boolean,
    ) => {
        return this.post(`${this.resource}/${contactId}/estates/${propertyId}/interactions`, {
            interaction_type: type,
            comment,
            send_to_owner: sendToOwner,
        });
    };

    interactionsOverviewForProperty = (contactId: string, propertyId: string) => {
        return this.get<PaginatedResponse<any>>(
            `${this.resource}/${contactId}/properties/${propertyId}/interactions-overview`,
        );
    };

    interactionsForProperty = (contactId: string, propertyId: string, settings: {}) => {
        return this.withMeta()
            .setQueryParameters(settings)
            .get(`${this.resource}/${contactId}/properties/${propertyId}/interactions`);
    };

    createReport = (contactId: string, attributes: Record<string, any>) => {
        return this.post(`vendors/${contactId}/reports`, attributes);
    };

    getTimeline = (
        contactId: string,
        {
            limit,
            after,
            before,
            propertyId,
        }: {
            limit?: number;
            after?: string;
            before?: string;
            propertyId?: string;
        },
    ) => {
        const parameters: {
            limit?: number;
            after?: string;
            before?: string;
            estate?: string;
        } = {};

        if (limit) {
            parameters.limit = limit;
        }

        if (after) {
            parameters.after = after;
        }

        if (before) {
            parameters.before = before;
        }

        if (propertyId) {
            parameters.estate = propertyId;
        }

        return this.withMeta()
            .withQueryParameters(parameters)
            .get(`/contacts/${contactId}/timeline`);
    };

    getInteractedProperties(
        id: string,
        parameters: { limit?: number; after?: string; before?: string; is_interesting?: boolean; includes?: string },
    ) {
        return this.withMeta()
            .withQueryParameters(parameters)
            .get<PaginatedResponse<APIEstate>>(`/contacts/${id}/interacted-estates`);
    }

    deprecated__updateLocationPreference = (
        leadId: string,
        locationPreferenceId: string,
        preference: { type: 'geojson' | 'postal_codes'; name: string },
        data: any,
    ) => {
        return this.put(`/leads/${leadId}/location-preferences/${locationPreferenceId}`, {
            name: preference.name,
            type: preference.type,
            data,
        });
    };

    updateLocationPreference = (
        contactId: string,
        preferenceId: string,
        locationPreferenceId: string,
        attributes: FixMeType,
    ) => {
        return this.put(
            `/contacts/${contactId}/preferences/${preferenceId}/location-preferences/${locationPreferenceId}`,
            attributes,
        );
    };

    deprecated__addLocationPreference = (leadId: string, preference: any, data) => {
        return this.post(`/leads/${leadId}/location-preferences`, {
            name: preference.name,
            type: preference.type,
            data,
        });
    };

    addLocationPreference = (
        contactId: string,
        preferenceId: string,
        attributes: { type: FixMeType; name: string; data: FixMeType },
    ) => {
        return this.post(`/contacts/${contactId}/preferences/${preferenceId}/location-preferences`, attributes);
    };

    deprecated__removeLocationPreference = (leadId: string, locationPreferenceId: string) => {
        return this.delete(`/leads/${leadId}/location-preferences/${locationPreferenceId}`);
    };

    removeLocationPreference = (contactId: string, preferenceId: string, locationPreferenceId: string) => {
        return this.delete(
            `/contacts/${contactId}/preferences/${preferenceId}/location-preferences/${locationPreferenceId}`,
        );
    };

    deprecated__getLocationPreference = (leadId: string, locationPreferenceId: string) => {
        return this.get(`/leads/${leadId}/location-preferences/${locationPreferenceId}`);
    };

    getLocationPreference = (contactId: string, preferenceId: string, locationPreferenceId: string) => {
        return this.get(
            `/contacts/${contactId}/preferences/${preferenceId}/location-preferences/${locationPreferenceId}`,
        );
    };

    eligibleEstates = (contactId: string, queryParams: { query?: string; limit: number; page: number }) => {
        return this.setQueryParameters(queryParams)
            .withMeta()
            .get<PaginatedResponse<APIEstate>>(`/contacts/${contactId}/eligible-estates`);
    };

    getAssignments(contactId: string) {
        return this.get(`/contacts/${contactId}/negotiators`);
    }

    setAssignments(contactId: string, assignments: APIContactAssignedNegotiatorsInput) {
        return this.post<void>(`/contacts/${contactId}/negotiators`, { negotiators: assignments });
    }

    getLabels(contactId: string) {
        return this.get(`/contacts/${contactId}/labels`);
    }

    updateLabels(contactId: string, labels: string[]) {
        return this.put(`/contacts/${contactId}/labels`, { labels });
    }
}
