import useCompany from '@/app.hooks/useCompany';

type CompanySetting = 'default_locale' | 'signup_country';

export default function useCompanySetting(settingName: CompanySetting): any {
    const company = useCompany();

    const settings = company?.getIn(['settings', 'data'])?.toJS() ?? {};

    return settings[settingName];
}
