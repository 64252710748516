import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Helmet from 'react-helmet';
import { Alert } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl-sweepbright';
import FormPane from '../../../app.components/forms/FormPane';

const ViaZapier = ({ integration }) => (
    <FormPane title={<FormattedMessage id={`integrations.${integration.get('id')}.title`} />} cols={{ md: 15 }}>
        <Helmet>
            <script
                type="text/javascript"
                src={`https://zapier.com/zapbook/embed/widget.js?guided_zaps=${integration
                    .get('templates')
                    .join(',')}&html_id=zaps`}
            />
        </Helmet>
        <Alert>
            <FormattedHTMLMessage id={`integrations.${integration.get('id')}.help`} />
        </Alert>
        <div id="zaps" />
        <Alert>
            <FormattedHTMLMessage
                id="integrations.zapier.more_apps"
                defaultMessage={
                    'Connect SweepBright to many more apps and create your own automated workflows.\n' +
                    'Zapier moves info between SweepBright and thousands of apps automatically, so you can focus on your most important work.\n' +
                    '<br><br>' +
                    'Discover more on <a href="https://zapier.com/zapbook/sweepbright/" target="_blank">Zapier.</a>'
                }
            />
        </Alert>
    </FormPane>
);

ViaZapier.propTypes = {
    integration: PropTypes.instanceOf(Map),
};

export default ViaZapier;
