import React, { useMemo } from 'react';
import ResourceFilter from '@/shared/resource/ResourceFilter';
import TeamsSelect from '@/shared/teams/TeamsSelect';
import TeamsLabel from '@/shared/teams/TeamsLabel';

import { MembersSearchType } from '../types';

export const useMembersFilters = (searchSettings: MembersSearchType | undefined, setSearchSettings: Function) => {
    const teamLabel = searchSettings?.team ? <TeamsLabel teamId={searchSettings.team} /> : null;

    const filters = useMemo(
        () => (
            <>
                <ResourceFilter
                    id="role"
                    domainType="members"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'radio',
                            paramName: 'role',
                            simpleOptions: ['team-member', 'editor', 'team-admin', 'company-admin'],
                        },
                    ]}
                />

                <ResourceFilter
                    id="team"
                    domainType="members"
                    params={searchSettings}
                    customActiveLabel={() => {
                        return teamLabel;
                    }}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'custom',
                            paramName: 'team',
                            children: (params, setParams) => (
                                <TeamsSelect
                                    noMargin
                                    value={params?.team}
                                    onChange={newValue => {
                                        setParams(prevState => {
                                            return { ...prevState, team: newValue };
                                        });
                                    }}
                                />
                            ),
                        },
                    ]}
                />
            </>
        ),
        [teamLabel, searchSettings, setSearchSettings],
    );

    return filters;
};
