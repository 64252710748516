import { Map } from 'immutable';
import _ from 'lodash';
import invariant from 'tiny-invariant';
import { compose, uniq, flatMap } from 'lodash/fp';
import { DEFAULT_LOCALE } from '@sweepbright/webapp-shared/Config/intl';

type LangDict = {
    readonly [lang: string]: string;
};

export const AVAILABLE_LOCALES_WITH_VARIATIONS: { readonly [key: string]: LangDict } = {
    en: {
        default: DEFAULT_LOCALE,
        GB: 'en-GB',
    },
    fr: {
        default: 'fr',
        BE: 'fr-BE',
    },
    nl: {
        default: 'nl',
    },
};

export const USER_LOCALES_WITH_VARIATIONS = AVAILABLE_LOCALES_WITH_VARIATIONS;

export const AVAILABLE_LOCALES = Object.keys(AVAILABLE_LOCALES_WITH_VARIATIONS);

// extract the locale codes
export const AVAILABLE_LOCALES_CODES = compose(
    uniq,
    flatMap(lang => Object.values(AVAILABLE_LOCALES_WITH_VARIATIONS[lang])),
)(Object.keys(AVAILABLE_LOCALES_WITH_VARIATIONS));

// extract the locale codes
export const AVAILABLE_USER_LOCALES_CODES = compose(
    uniq,
    flatMap(lang => Object.values(USER_LOCALES_WITH_VARIATIONS[lang])),
)(Object.keys(USER_LOCALES_WITH_VARIATIONS));

export function getActiveLocale(
    locale: string = DEFAULT_LOCALE,
): {
    locale: string;
    fallbackLocale: string;
} {
    const [lang = DEFAULT_LOCALE, variation = 'default'] = locale.split('-');
    const variations = AVAILABLE_LOCALES_WITH_VARIATIONS[lang];

    let lc = DEFAULT_LOCALE;
    let fallbackLocale = DEFAULT_LOCALE;
    if (variations) {
        lc = variations[variation] ?? variations['default'];
        if (variations['default']) {
            fallbackLocale = variations['default'];
        }
    }

    return { locale: lc, fallbackLocale };
}

type Messages = {
    [id: string]: string;
};

export const getMessagesForLocale = (locale: string, allMessages: Record<string, Messages>) => {
    const { locale: activeLocale, fallbackLocale } = getActiveLocale(locale);
    const messages = {
        ...allMessages[DEFAULT_LOCALE],
        ...allMessages[fallbackLocale],
        ...allMessages[activeLocale],
    };

    invariant(Object.keys(messages).length > 0, 'no translations found for locale ' + locale);

    return messages;
};

export const getLocaleForCompany = (company: Map<string, any>): string => {
    return company.get('locale') || DEFAULT_LOCALE;
};

export function loadMessages() {
    return _.get(window, '__SERVER_DATA__.messages', {});
}

export function getInitialLocale() {
    // either the locale comes from
    // the server or we get the locale from
    // the user navigator language
    return _.get(window, '__SERVER_DATA__.locale', getUserPreferredLocale());
}

export function getMessagesWithCountryVariation(locale, country) {
    const allLocalesMessages = loadMessages();
    const localeVariation = `${locale}-${country}`;

    return getMessagesForLocale(localeVariation, allLocalesMessages);
}

export function getUserPreferredLocale(): string {
    // get the list of user preferred locales
    const userPreferredLocales = window.navigator?.languages ?? [];
    // find the intersection between the user locales and the available locales
    const [locale = DEFAULT_LOCALE] = _.intersection(userPreferredLocales, AVAILABLE_USER_LOCALES_CODES);

    return locale;
}
