import React, { useState } from 'react';
import { Select } from 'antd';
import { useIntl } from 'react-intl';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import useLabels from '@/app.hooks/useLabels';
import ContactLabel from '@/shared/contacts/ContactLabel';
import isScrollEnd from '@/utils/isScrollEnd';

type Props = {
    onCancel: Function;
    onSumbit: Function;
    initLabels: string[];
};

const ContactLabelsModalForm: React.FC<Props> = props => {
    const { onCancel, onSumbit, initLabels } = props;

    const intl = useIntl();

    const isSubmitting = false;

    const [query, setQuery] = useState('');

    const { data, loading, fetchMore } = useLabels({
        query,
    });

    const [tempSelected, setTempSelected] = useState<string[]>(initLabels || []);

    const content = {
        title: intl.formatMessage({ id: 'contact.labels.modal' }),
        loading: intl.formatMessage({ id: 'general.state.loading' }),
        label: intl.formatMessage({ id: 'contact.labels.modal.apply' }),
        noResults: intl.formatMessage({ id: 'general.state.no_results' }),
        placeholder: intl.formatMessage({ id: 'contact.labels.modal.filter' }),
        cancel: intl.formatMessage({ id: 'general.action.cancel' }),
        save: intl.formatMessage({ id: 'form.status.save' }),
        saving: intl.formatMessage({ id: 'form.status.saving' }),
    };

    const filteredData = (data || [])?.filter(el => !tempSelected.includes(el.id));

    const isDisabled = filteredData ? filteredData?.length === 0 && loading : false;

    const options = filteredData?.map(el => {
        return {
            key: el?.id,
            value: el?.id,
            label: el.name,
        };
    });

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
            }}
            className="contact-labels-modal-form"
        >
            <Modal.Header>
                <Modal.Title>{content.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <FormGroup>
                        <ControlLabel>{content.label}</ControlLabel>

                        <Select
                            showSearch
                            value={null}
                            options={options}
                            filterOption={false}
                            disabled={isDisabled}
                            onSearch={newValue => {
                                setQuery(newValue);
                            }}
                            style={{ width: '100%' }}
                            loading={loading}
                            onPopupScroll={event => {
                                if (isScrollEnd(event) && !loading) {
                                    fetchMore();
                                }
                            }}
                            onChange={newValue => {
                                setTempSelected([...tempSelected, newValue || '']);
                            }}
                            notFoundContent={
                                <span className="filter-geo-select-notfound">
                                    {loading ? content.loading : content.noResults}
                                </span>
                            }
                            placeholder={loading ? content.loading : content.placeholder}
                            optionRender={option => {
                                const activeLabel = data?.find(label => label.id === option.value);

                                return <ContactLabel label={activeLabel?.name} color={activeLabel?.color} />;
                            }}
                        />
                    </FormGroup>

                    {!!tempSelected.length && (
                        <div className="contact-labels-modal-form__tags">
                            {tempSelected.map(el => {
                                const activeLabel = data?.find(label => label.id === el);

                                return (
                                    <ContactLabel
                                        key={el}
                                        label={activeLabel?.name}
                                        color={activeLabel?.color}
                                        id={activeLabel ? undefined : el}
                                        onClick={() => {
                                            setTempSelected(tempSelected.filter(item => item !== el));
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className="text-center">
                <Button onClick={onCancel} type="button" disabled={isSubmitting}>
                    {content.cancel}
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    onClick={() => {
                        onSumbit(tempSelected);
                    }}
                    disabled={isSubmitting || loading}
                >
                    {isSubmitting ? content.saving : content.save}
                </Button>
            </Modal.Footer>
        </form>
    );
};

export default ContactLabelsModalForm;
