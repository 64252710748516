import { getCompany } from '@/app.redux/selectors/CompaniesSelector';
import { getUser } from '@/app.redux/selectors/UsersSelectors';
import { LOGIN } from '../../app.routing/routes';
import createSelector from './createImmutableSelector';

export const getAuth = state => state.auth;
export const getIsLoggingIn = createSelector(getAuth, auth => auth.get('is_logging_in'));
export const getIsloggedIn = createSelector(getAuth, getUser, getCompany, (auth, user, company) => {
    return auth.get('logged_in') && user?.get('id') != null && company?.get('id') != null;
});
export const getLoginUrl = createSelector(getAuth, auth => `${LOGIN}/${auth.get('code')}`);
