// @ts-nocheck
import React from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import pick from 'lodash/pick';
import { LoadingIndicator, Button } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import { createBucket, createDocumentForEstate } from '@/app.redux/actions/index';
import PagePane from '@/app.layouts/PagePane/PagePane';
import LegalTabContent from '@/app.domains/properties/elements/LegalTabContent';
import FormikWithConfirmation from '@/app.components/forms/helpers/FormikWithConfirmation';
import { nullifyEmptyFields } from '@/app.utils/services/Helpers/forms';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';

type LegalFormProps = {
    activeTab?: string;
    createBucket: (arg: any) => void;
    createDocumentForEstate: (estateId: string, arg2: Record<string, any>) => void;
    onSubmit: (attributes: Record<string, any>) => Promise<void>;
    property: Map<string, any>;
    editingDisabled: boolean;
    setActiveTab: (tab: string) => void;
    setDisabled: (arg: boolean) => void;
    updateProperty: (propertyId: string, attributes: any, resolve: () => void, reject: () => void) => void;
    initialValues: any;
    route: any;
};

function LegalForm(props: LegalFormProps) {
    const propertyCountry = props.property.getIn(['attributes', 'location', 'country']);
    const { editingDisabled } = props;

    if (!propertyCountry) {
        return <LoadingIndicator />;
    }

    return (
        <FormikWithConfirmation
            skipConfirmation={editingDisabled}
            route={props.route}
            onSubmit={(values, formikBag) => {
                return props
                    .onSubmit({
                        ...values,
                        ...nullifyEmptyFields(
                            pick(values, 'legal', 'occupancy', 'tenant_contract', 'settings', 'documents'),
                        ),
                    })
                    .catch(errors => {
                        formikBag.setErrors(errors);
                    });
            }}
            enableReinitialize
            initialValues={props.initialValues}
        >
            {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate className="h-full w-full min-w-0">
                    <PagePane
                        title={<FormattedMessage id="titles.property.legal-and-docs" defaultMessage="Add legal info" />}
                        actions={[
                            <Button
                                key="submit"
                                type="submit"
                                variant="success"
                                data-testid="submit_btn"
                                disabled={isSubmitting || editingDisabled}
                            >
                                {editingDisabled ? (
                                    <FormattedMessage id="property.status.archived" defaultMessage="Archived" />
                                ) : isSubmitting ? (
                                    <FormattedMessage id="form.status.saving" defaultMessage="Saving..." />
                                ) : (
                                    <FormattedMessage id="form.status.save" defaultMessage="Save" />
                                )}
                            </Button>,
                        ]}
                    >
                        <LegalTabContent property={props.property} />
                    </PagePane>
                </form>
            )}
        </FormikWithConfirmation>
    );
}

export default compose(
    withProps(props => ({
        estateId: props.params.unit || props.params.property,
    })),
    withPropertyDetails('legal-and-docs'),
    connect(null, { createBucket, createDocumentForEstate }),
)(LegalForm);
