import React, { useState } from 'react';
import { Button } from '@sweepbright/uikit';
import { useFormikContext } from 'formik';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '@/app.components/icons/Icon';
import { ContactType, OfferCardFragmentFragment } from '@/graphql/generated/types';
import ContactCard from '@/shared/contacts/ContactCard';
import CloseIconButton from '@/app.components/elements/Buttons/CloseIconButton';
import ContactSelect from '@/shared/contacts/ContactSelect';

type Props = {
    mode: 'ADD' | 'EDIT';
    type: 'lead' | 'vendor';
    propertyOfficeId?: string;
    propertyNegotiation: 'let' | 'sale';
};

const OfferModalContacts: React.FC<Props> = props => {
    const { mode, type, propertyOfficeId, propertyNegotiation } = props;

    const { values, initialValues, setFieldValue } = useFormikContext<OfferCardFragmentFragment>();

    const [showSelectContact, setShowSelectContact] = useState(false);

    const contactType = type === 'lead' && propertyNegotiation === 'let' ? 'tenant' : type;

    const content = {
        title: <FormattedMessage id={`offers.modals.contacts.${contactType}`} defaultMessage="Buyers" />,
        add: <FormattedMessage id={`offers.modals.contacts.${contactType}.add`} defaultMessage="Add Buyer" />,
        empty: (
            <FormattedMessage
                defaultMessage="Add a Buyer to create this Offer."
                id={type === 'vendor' ? 'offer.owners.empty' : `offers.modals.contacts.${contactType}.empty`}
            />
        ),
    };

    const dataType = type === 'lead' ? 'buyers' : 'owners';

    const contacts: { id: string }[] = type === 'lead' ? values.buyers : values.owners;
    const initContacts: { id: string }[] = type === 'lead' ? initialValues.buyers : initialValues.owners;

    const isEmpty = !contacts || contacts.length === 0;

    const canRemoveContact = mode === 'ADD';

    const isDisabled = dataType === 'owners' && !propertyOfficeId;

    return (
        <div className="offer-modal-contacts">
            <FormPanel
                title={content.title}
                action={
                    type === 'vendor' ? (
                        undefined
                    ) : (
                        <Button
                            key="add"
                            variant="link"
                            disabled={isDisabled}
                            onClick={() => {
                                setShowSelectContact(true);
                            }}
                            icon={<Icon icon="circle-add" />}
                        >
                            {content.add}
                        </Button>
                    )
                }
            >
                <>
                    {showSelectContact && (
                        <ContactSelect
                            type={type}
                            filterByUser
                            propertyOfficeId={propertyOfficeId}
                            excluded={(contacts || []).map(contact => contact.id)}
                            onSelect={newContact => {
                                setFieldValue(dataType, [...(contacts || []), newContact]);

                                setShowSelectContact(false);
                            }}
                        />
                    )}

                    {isEmpty && !showSelectContact && (
                        <div className="offer-modal-contacts__empty">
                            <Icon size={16} icon="circle-info" />
                            <span>{content.empty}</span>
                        </div>
                    )}

                    {!isEmpty && (
                        <div className="offer-modal-contacts__list">
                            {contacts.map(el => {
                                return (
                                    <ContactCard
                                        key={el.id}
                                        // @ts-ignore
                                        contact={{
                                            ...el,
                                            type: type === 'lead' ? ContactType.Lead : ContactType.Vendor,
                                        }}
                                        actions={
                                            (canRemoveContact ||
                                                !initContacts.map(initEl => initEl.id).includes(el.id)) &&
                                            type === 'lead'
                                                ? [
                                                      <CloseIconButton
                                                          key="remove"
                                                          onClick={() => {
                                                              setFieldValue(
                                                                  dataType,
                                                                  contacts.filter(elContact => elContact.id !== el.id),
                                                              );
                                                          }}
                                                      />,
                                                  ]
                                                : []
                                        }
                                    />
                                );
                            })}
                        </div>
                    )}
                </>
            </FormPanel>
        </div>
    );
};

export default OfferModalContacts;
