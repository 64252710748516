// @ts-nocheck
import React from 'react';
import { Map } from 'immutable';
import { withRouter, WithRouterProps } from 'react-router';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import * as Routes from '@/app.routing/routes';
import PropertyDetailsSideMenu from '@/app.domains/properties/PropertyDetailsSideMenu';
import useProperty from '@/app.hooks/useProperty';

const PropertyDetailsPage: React.FunctionComponent<{
    property: Map<string, any>;
    location: any;
} & WithRouterProps> = props => {
    const { children, location } = props;
    const propertyId = props.params.unit || props.params.property;
    const { property } = useProperty(propertyId);

    // Compute routes
    const routes = getRoutes();

    return (
        <>
            <LayoutColumn size="small" key="navigation">
                {renderSideMenu()}
            </LayoutColumn>
            <LayoutColumn key="page">{property?.id && children}</LayoutColumn>
        </>
    );

    function getRoutes() {
        const isUnit = property?.isUnit;
        const projectId = props.params.property;

        return {
            LOOP: isUnit
                ? Routes.PROPERTY_UNIT_DETAILS_LOOP(projectId, propertyId)
                : Routes.PROPERTY_DETAILS_LOOP(propertyId),
            LOCATION: isUnit
                ? Routes.PROPERTY_UNIT_DETAILS_LOCATION(projectId, propertyId)
                : Routes.PROPERTY_DETAILS_LOCATION(propertyId),
            ROOMS: isUnit
                ? Routes.PROPERTY_UNIT_DETAILS_ROOMS(projectId, propertyId)
                : Routes.PROPERTY_DETAILS_ROOMS(propertyId),
            FEATURES: isUnit
                ? Routes.PROPERTY_UNIT_DETAILS_FEATURES(projectId, propertyId)
                : Routes.PROPERTY_DETAILS_FEATURES(propertyId),
            PRICE: isUnit
                ? Routes.PROPERTY_UNIT_DETAILS_PRICE(projectId, propertyId)
                : Routes.PROPERTY_DETAILS_PRICE(propertyId),
            LEGAL: isUnit
                ? Routes.PROPERTY_UNIT_DETAILS_LEGAL(projectId, propertyId)
                : Routes.PROPERTY_DETAILS_LEGAL(propertyId),
            IMAGES: isUnit
                ? Routes.PROPERTY_UNIT_DETAILS_IMAGES(projectId, propertyId)
                : Routes.PROPERTY_DETAILS_IMAGES(propertyId),
            DESCRIPTION: isUnit
                ? Routes.PROPERTY_UNIT_DETAILS_DESCRIPTION(projectId, propertyId)
                : Routes.PROPERTY_DETAILS_DESCRIPTION(propertyId),
            PLANS: isUnit
                ? Routes.PROPERTY_UNIT_DETAILS_DOCUMENTS(projectId, propertyId)
                : Routes.PROPERTY_DETAILS_DOCUMENTS(propertyId),
            SETTINGS: isUnit
                ? Routes.PROPERTY_UNIT_DETAILS_SETTINGS(projectId, propertyId)
                : Routes.PROPERTY_DETAILS_SETTINGS(propertyId),
        };
    }

    function renderSideMenu() {
        const linkProps = {
            location: location.pathname,
        };

        return <PropertyDetailsSideMenu property={property} linkProps={linkProps} paths={routes} />;
    }
};

export default withRouter(PropertyDetailsPage);
