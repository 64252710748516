import React from 'react';
import './TimelineItem.scss';

export default function TimelineItem({ children }) {
    return (
        <div className="c-timeline__item">
            <div className="c-timeline__bullet" />
            {children}
        </div>
    );
}
