import PropTypes from 'prop-types';
import React from 'react';
import { findIntegration } from '../../../../../app.data/Integrations';
import Intercom from '../../../../../app.domains/settings/integrations/Communication/Intercom';
import Livechat from '../../../../../app.domains/settings/integrations/Communication/Livechat';
import Dotloop from '../../../../../app.domains/settings/integrations/Documents/Dotloop';
import SweepBright from '../../../../../app.domains/settings/integrations/Documents/Sweepbright';
import ViaZapier from '../../../../../app.domains/settings/integrations/ViaZapier';

const IntegrationProxy = props => {
    const integrationName = props.route.path;
    const integration = findIntegration(integrationName);

    switch (integrationName) {
        case 'intercom':
            return <Intercom {...props} />;
        case 'livechat':
            return <Livechat {...props} />;
        case 'dotloop':
            return <Dotloop {...props} />;
        case 'sweepbright':
            return <SweepBright {...props} />;
        default:
            if (integration.get('via_zapier')) {
                return <ViaZapier {...props} integration={integration} />;
            }

            return <div />;
    }
};

IntegrationProxy.propTypes = {
    route: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }),
};

export default IntegrationProxy;
