import React, { ReactNode } from 'react';
import Alert from 'react-bootstrap/lib/Alert';

const EmptyBlock: React.FunctionComponent<{ text: string | ReactNode }> = ({ text }) => (
    <Alert bsStyle="info">
        <p className="text-muted"> {text} </p>
    </Alert>
);

export default EmptyBlock;
