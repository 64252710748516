import React from 'react';
import { FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import useOffice from '@/app.hooks/useOffice';
import useField from '@/app.hooks/useField';
import EmptyAlert from '@/app.components/empty/EmptyAlert';
import Link from '@/app.components/elements/Link';
import useLocationTemplates from '@/app.hooks/useLocationTemplates';
import Modal from '@/app.components/elements/Modal';
import Select from '@/app.components/selects/AdvancedSelect/Select';

export default function ChooseLocationTemplateModal(props: {
    show: boolean;
    onClose: () => void;
    onSelect: (selectedId: string) => Promise<void> | void;
}) {
    const office = useOffice();
    const selectedTemplateIdField = useField('');
    const [adding, setAdding] = React.useState(false);
    const { loading, data, fetchMore, queryField } = useLocationTemplates(office.get('id'), false);
    const locations = data?.office?.locationTemplates.edges.map(edge => edge.node) ?? [];
    const options = orderBy(locations, ['createdAt'], ['desc']).map(location => ({
        value: location.id,
        label: location.name,
    }));

    const totalCount = data?.office?.locationTemplates.totalCount ?? Number.MAX_VALUE;

    return (
        <Modal
            show={props.show}
            onHide={() => {
                // do nothing
            }}
            onExited={() => {
                selectedTemplateIdField.onChange(null);
            }}
        >
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="location_preferences.choose_template" defaultMessage="Choose Template" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup>
                    <ControlLabel>
                        <FormattedMessage id="location.templates.selector.label" defaultMessage="Template" />
                    </ControlLabel>
                    <Select
                        options={options}
                        disabled={adding}
                        simpleValue
                        {...selectedTemplateIdField}
                        loading={loading}
                        onMenuScrollToBottom={fetchMore}
                        onInputChange={queryField.onChange}
                    />
                </FormGroup>
                {totalCount === 0 && !queryField.value && !loading ? (
                    <EmptyAlert
                        body={
                            <FormattedMessage
                                id="location.templates.empty"
                                defaultMessage="Looks like you don’t have any templates yet"
                            />
                        }
                    />
                ) : null}
                <HelpBlock>
                    <FormattedMessage
                        id="location.templates.empty.help_block"
                        defaultMessage="You can configure templates in your {link}"
                        values={{
                            link: (
                                <Link to={`/office/${office.get('id')}/location-templates`}>
                                    <FormattedMessage
                                        id="navigation.office.settings_section"
                                        defaultMessage="Team Settings"
                                    />
                                </Link>
                            ),
                        }}
                    />
                </HelpBlock>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        props.onClose();
                    }}
                    disabled={adding}
                >
                    <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                </Button>
                <Button
                    variant="primary"
                    disabled={adding || !selectedTemplateIdField.value}
                    onClick={async () => {
                        const selectedId = selectedTemplateIdField.value;
                        if (!selectedId) {
                            return;
                        }
                        try {
                            setAdding(true);
                            await props.onSelect(selectedId);
                        } finally {
                            setAdding(false);
                        }
                    }}
                >
                    {adding ? (
                        <FormattedMessage id="general.state.adding" defaultMessage="Adding..." />
                    ) : (
                        <FormattedMessage id="misc.add_preference" defaultMessage="Add Preference" />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
