import React, { FC } from 'react';
import Icon from '@/app.components/icons/Icon';
import Status from '@/app.components/elements/Status/Status';
import { ScheduleEventType, VisitStatus } from '@/graphql/generated/types';

export const EventCardIcon: FC<{ eventType: ScheduleEventType; status?: VisitStatus | null }> = ({
    eventType,
    status,
}) => {
    if (eventType === ScheduleEventType.Visit) {
        return (
            <div className="relative">
                <Icon icon="visit" size={20} />
                {status === 'confirmed' && <Status status="checked" size={10} />}
                {status === 'missed' && <Status status="error" size={12} style={{ top: -4 }} />}
            </div>
        );
    }
    if (eventType === ScheduleEventType.Openhome) {
        return <Icon icon="house" size={20} />;
    }

    if (eventType === ScheduleEventType.Auction) {
        return <Icon icon="price" size={20} />;
    }

    return null;
};
