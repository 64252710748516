import gql from 'graphql-tag';
import produce, { Draft } from 'immer';
import { GetContactsQuery } from '@/graphql/generated/types';

export const GET_OFFICE_CONTACTS_QUERY = gql`
    query GetContacts(
        $filters: ContactFiltersInput
        $query: String
        $sortOrder: String
        $sortField: String
        $limit: Int
        $page: Int
        $locale: String
        $useAtlasSearch: Boolean
    ) {
        contacts(
            query: $query
            sortOrder: $sortOrder
            sortField: $sortField
            filters: $filters
            limit: $limit
            page: $page
            locale: $locale
            useAtlasSearch: $useAtlasSearch
        ) {
            totalCount
            pageInfo {
                currentPage
                hasNextPage
            }
            edges {
                node {
                    id
                    firstName
                    lastName
                    email
                    phone
                    formattedPhone: phone(format: INTERNATIONAL)
                    type
                    note
                    locale
                    archived
                    subscribed
                    photo {
                        id
                        url
                    }
                    createdAt
                    updatedAt
                    ... on Lead {
                        lastAction
                        lastInteraction
                    }
                    offersStats {
                        number_of_pending_offers
                        highest_offer_bid
                        has_accepted_offer
                        closest_offer_expirations
                        currency
                    }
                    assignedNegotiators {
                        id
                        first_name
                        last_name
                        picture_url
                    }
                    officeId
                }
            }
        }
    }
`;

export const updateContactsQuery = produce(
    (prevDraft: Draft<GetContactsQuery>, { fetchMoreResult }: { fetchMoreResult?: GetContactsQuery }) => {
        if (!fetchMoreResult) return prevDraft;

        prevDraft.contacts.edges = prevDraft.contacts.edges.concat(fetchMoreResult.contacts.edges);
        prevDraft.contacts.pageInfo = fetchMoreResult.contacts.pageInfo;

        return prevDraft;
    },
);
