// @ts-nocheck
import uuid from 'uuid';
import axios, { AxiosInstance } from 'axios';
import geocountries from '@/app.data/geocountries.json';
import { LatLng } from './types';

// if an invalid country code is provided, we just fallback
// to Belgium
const FALLBACK_COUNTRY = 'BE';

export type Suggestion = {
    description: string;
    id: string;
    matched_substrings: Array<{ length: number; offset: number }>;
    place_id: string;
};

type AddressComponentType =
    | 'locality'
    | 'country'
    | 'route'
    | 'street_number'
    | 'postal_town'
    | 'administrative_area_level_1'
    | 'administrative_area_level_2'
    | 'postal_code';

export type PlaceDetail = {
    address_components: Array<{ long_name: string; short_name: string; types: AddressComponentType[] }>;
    geometry: {
        location: { lat: number; lng: number };
    };
    formatted_address: string;
    id: string;
};

export function getAddressComponent(
    place: PlaceDetail,
    componentType: string,
): Maybe<{ long_name: string; short_name: string }> {
    const result = place.address_components.find(component => {
        return component.types.includes(componentType);
    });

    return result;
}

export function formattedDescription(place: Suggestion): string {
    const originalString = place.description.slice(0);

    const result = place.matched_substrings.reduce(
        ({ prevOffset, description }, { length, offset }) => {
            const highlight = originalString.slice(offset, offset + length);

            return {
                description: `${description}${originalString.slice(prevOffset, offset)}<strong>${highlight}</strong>`,
                prevOffset: offset + length,
                length,
            };
        },
        {
            prevOffset: 0,
            length: 0,
            description: '',
        },
    );
    if (result.prevOffset < originalString.length) {
        result.description += originalString.slice(result.prevOffset);
    }

    return result.description;
}

export interface PlacesAutocompleteService {
    getQuerySuggestions(query: string, options: { country?: string; language: string }): Promise<Suggestion[]>;

    getPlaceDetails(placeId: string, options: { language: string }): Promise<PlaceDetail>;
}

export class GooglePlacesAutocompleteService implements PlacesAutocompleteService {
    static SERVICE_URL = '/api/places/';
    session: string;
    httpClient: AxiosInstance;

    constructor() {
        this.session = uuid.v4();
        this.httpClient = axios.create({
            baseURL: GooglePlacesAutocompleteService.SERVICE_URL,
            params: {
                key: GOOGLE_CLIENT_API_KEY,
            },
        });
    }

    async getQuerySuggestions(query: string, options: { country?: string; language: string }): Promise<Suggestion[]> {
        const params: any = {
            input: query,
            types: 'address',
            sessiontoken: this.session,
            language: options.language,
        };

        if (options.country) {
            params.components = `country:${options.country}`;
        }
        try {
            const response = await this.httpClient.get('/autocomplete/json', { params });

            return response.data.predictions;
        } catch (err) {
            return [];
        }
    }

    async getPlaceDetails(placeId: string, options: { language: string }): Promise<GMapPlaceDetail> {
        const response = await this.httpClient.get('/details/json', {
            params: {
                placeid: placeId,
                language: options.language,
            },
        });

        return response.data.result;
    }
}

type MapboxFeature = {
    id: string;
    place_name: string;
    geometry: {
        coordinates: [number, number];
    };
    context: Array<{
        id: string;
        text: string;
        short_code?: string;
    }>;
    /// house number
    address?: string;
    properties: {
        accuracy: 'street' | 'point';
    };
    text: string;
};

export class MapboxPlacesAutocompleteService implements PlacesAutocompleteService {
    places: Map<string, PlaceDetail> = new Map();
    httpClient: AxiosInstance;

    constructor() {
        this.httpClient = axios.create({
            baseURL: 'https://api.mapbox.com',
            params: {
                access_token: MAPBOX_CLIENT_API_KEY,
            },
        });
    }

    getQuerySuggestions(
        query: string,
        options: { country?: string | undefined; language: string },
    ): Promise<Suggestion[]> {
        const { language = 'en', country = FALLBACK_COUNTRY } = options;
        this.places.clear();

        return this.httpClient
            .get(`/geocoding/v5/mapbox.places/${query}.json`, {
                params: {
                    autocomplete: true,
                    country,
                    language,
                    limit: 5,
                    types: 'address',
                },
            })
            .then(res => res.data.features)
            .then((features: MapboxFeature[]) => {
                features.forEach(feature => {
                    this.places.set(feature.id, this.extractPlaceDetails(feature));
                });

                return features.map(this.transformFeature);
            });
    }

    async getPlaceDetails(placeId: string): Promise<PlaceDetail> {
        return this.places.get(placeId)!;
    }

    extractPlaceDetails(feature: MapboxFeature): PlaceDetail {
        const details = {
            id: feature.id,
            address_components: feature.context.map(contextEntry => {
                return {
                    long_name: contextEntry.text,
                    short_name: contextEntry.short_code || contextEntry.text,
                    types: this.transformAddressComponentTypes(contextEntry.id),
                };
            }),
            geometry: {
                location: {
                    lat: feature.geometry.coordinates[1],
                    lng: feature.geometry.coordinates[0],
                },
            },
        };

        if (feature.text) {
            details.address_components.push({
                long_name: feature.text,
                short_name: feature.text,
                types: ['route'],
            });
        }

        if (feature.properties.accuracy === 'point' && feature.address) {
            details.address_components.push({
                long_name: feature.address,
                short_name: feature.address,
                types: ['street_number'],
            });
        }

        return details;
    }

    transformAddressComponentTypes(contextEntryId: string): AddressComponentType[] {
        const [type] = contextEntryId.split('.');
        switch (type) {
            case 'country': {
                return ['country'];
            }
            case 'postcode': {
                return ['postal_code'];
            }
            case 'region': {
                return ['administrative_area_level_1'];
            }
            case 'place': {
                return ['locality'];
            }
            default: {
                return [];
            }
        }
    }

    transformFeature(feature: MapboxFeature): Suggestion {
        return {
            id: feature.id,
            description: feature.place_name,
            place_id: feature.id,
            matched_substrings: [],
        };
    }
}

export function getCountryCoords(countryCode: string = FALLBACK_COUNTRY): LatLng {
    // @ts-ignore
    const [lat, lng] = (geocountries[countryCode] || geocountries[FALLBACK_COUNTRY]).coordinates;

    return {
        lat,
        lng,
    };
}

export class GeoJsonService {
    static SERVICE_URL = '/api/data/properties.geojson';
    httpClient: AxiosInstance;

    constructor() {
        this.httpClient = axios.create();
    }

    getGeoJson(body) {
        return this.httpClient.post(GeoJsonService.SERVICE_URL, body);
    }
}
