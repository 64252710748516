/* eslint-disable no-nested-ternary */

import React from 'react';

import { Row } from 'react-bootstrap';
import Button from '@sweepbright/uikit/build/esm/button';
import { withRouter, InjectedRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { destroy } from 'redux-form';
import { FormattedMessage } from 'react-intl-sweepbright';
import { SUBSCRIPTION } from '@/app.routing/routes';
import CompanyContext from '@/app.domains/company/CompanyContext';
import EstimateCalculator from '@/app.domains/subscription/common/EstimateCalculator';
import Icon from '@/app.components/icons/Icon';
import { UpgradeHeader } from '@/app.domains/subscription/common/UpgradeHeader';

const HORZ_PADDING = 16;

const Container: React.FunctionComponent<{}> = props => (
    <div style={{ maxWidth: '600px', margin: '0 auto', width: `calc(100% - ${HORZ_PADDING * 2}px)` }}>
        {props.children}
    </div>
);

export const UPGRADE_FORM_NAME = 'subscription/upgrade';

class UpgradeWizzard extends React.Component<
    {
        children: Node;
        destroyForm: () => void;
        dispatch: () => void;
        router: InjectedRouter;
    },
    {
        step: number;
        submitting: boolean;
    }
> {
    state = {
        step: 0,
        submitting: false,
    };

    componentWillUnmount() {
        this.props.destroyForm();
    }

    activeForm = React.createRef<HTMLFormElement>();

    onSubmitSuccess = () => {
        this.setState({ submitting: false });
        const { step } = this.state;
        if (step < this.getSteps() - 1) {
            this.goForward();
        } else {
            // end of the process
            this.props.destroyForm();
            this.props.router.replace({ pathname: SUBSCRIPTION, state: { success: true } });
        }
    };

    onSubmitFailure = () => {
        this.setState({ submitting: false });
    };

    goForward = () => {
        this.setState(state => {
            return { step: state.step + 1 };
        });
    };

    goBack = () => {
        this.setState(state => {
            return { step: state.step - 1 };
        });
    };

    onNextClick = () => {
        // execute after the on submit state is set
        const afterCommit = () => {
            if (this.activeForm.current) {
                this.activeForm.current.submit();
            } else {
                this.setState({ submitting: false });
            }
        };

        this.setState({ submitting: true }, afterCommit);
    };

    isLastStep = () => {
        const numSteps = this.getSteps();

        return this.state.step >= numSteps - 1;
    };

    renderStepper = ({ calculating, submitting, errors }) => {
        const invalidUsersAmount = Boolean(errors.users_amount);
        const showNextBtn = invalidUsersAmount === false && !this.isLastStep();
        const disableBtns = submitting || calculating;

        return (
            <Container>
                <hr className="hr" />
                {this.state.step > 0 ? (
                    <Button
                        variant="ghost"
                        className="pull-left"
                        onClick={this.goBack}
                        disabled={disableBtns}
                        icon={<Icon icon="arrow-left" />}
                    >
                        <FormattedMessage id="subscription.steps.previous" defaultMessage="Previous" />
                    </Button>
                ) : null}

                {showNextBtn ? (
                    <Button variant="primary" className="pull-right" onClick={this.onNextClick} disabled={disableBtns}>
                        {this.renderNextText(calculating, submitting)}
                    </Button>
                ) : null}
            </Container>
        );
    };

    renderNextText = (calculating: boolean, submitting: boolean) => {
        if (submitting) {
            return <FormattedMessage id="general.state.submitting" defaultMessage="Submitting..." />;
        }
        if (calculating) {
            return <FormattedMessage id="forms.upgrade.labels.summary.calculating" defaultMessage="Calculating..." />;
        }

        return (
            <React.Fragment>
                <FormattedMessage id="subscription.steps.next" defaultMessage="Next Step" /> <Icon icon="arrow-right" />
            </React.Fragment>
        );
    };

    getSteps = () => {
        return React.Children.count(this.props.children);
    };

    renderStep = ({ calculating, errors, ...props }) => {
        const { step, submitting } = this.state;

        const stepComponent: any = React.Children.toArray(this.props.children)[step];

        return (
            <>
                <Row>
                    <Container>
                        <UpgradeHeader />
                        <hr className="hr" />
                    </Container>
                </Row>
                <Row>
                    <Container>
                        {React.cloneElement(stepComponent, {
                            ...props,
                            formRef: this.activeForm,
                            onSubmitSuccess: this.onSubmitSuccess,
                            onSubmitFail: this.onSubmitFailure,
                            calculating,
                            estimatesErrors: errors,
                        })}
                    </Container>
                </Row>
                <Row>
                    {this.renderStepper({
                        calculating,
                        submitting,
                        errors,
                    })}
                </Row>
            </>
        );
    };

    render() {
        const { step } = this.state;

        return (
            <CompanyContext.Consumer>
                {company => <EstimateCalculator key={step} companyId={company.get('id')} render={this.renderStep} />}
            </CompanyContext.Consumer>
        );
    }
}

export default compose(
    withRouter,
    connect(null, {
        destroyForm: () => destroy(UPGRADE_FORM_NAME),
    }),
)(UpgradeWizzard);
