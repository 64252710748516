import gql from 'graphql-tag';

export const GET_CHANNEL_ACCOUNT = gql`
    query GetChannelAccount($companyId: String, $accountId: String) {
        channelAccount(companyId: $companyId, accountId: $accountId) {
            id
            name
            accessToken
            accessTokenSecret
            status
            office_id
            credentials {
                name
                accessToken
                accessTokenSecret
                additionalAccessId
            }
            webhook
            channel {
                id
                name
                slug
                logoUrl
                type
                countries
            }
        }
    }
`;
