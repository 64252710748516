// @ts-nocheck
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BlockPicker } from 'react-color';
import withProps from 'recompose/withProps';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage } from 'react-intl-sweepbright';
import { lookAndFeel, themes } from '../../../app.data';
import companySettings, { propTypes } from '../../../app.utils/Decorators/companySettings';
import CustomizationOption from '../../../app.components/card/CustomizationOption';
import { FormPane, SuccessAlert } from '../../../app.components';
import BorderedList from '../../../app.components/forms/BorderedList';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';

@companySettings('theme', [
    'photo.data.url',
    'future_photo.data_url',
    'future_photo.file',
    'theme',
    'theme_typeface',
    'theme_custom_color',
    'theme_buttons',
])
@withProps({ themes })
export default class LookAndFeel extends Component {
    static propTypes = {
        ...propTypes,
        themes: PropTypes.instanceOf(List),
    };

    state = {
        showColorPickerForTheme: '',
        modal: null,
    };

    renderCustomizationGroup = group => {
        const field = this.props.fields[group.id];
        const current = group.options.find(option => option.id === field.value) || group.options[0];
        const onShow = () => {
            this.setState({ modal: group.name });
        };
        const onHide = () => this.setState({ modal: null });
        const onReset = () => {
            onHide();
            field.onChange(field.initialValue);
        };

        return (
            <li key={group.id}>
                <CustomizationOption
                    selectable
                    title={group.name}
                    subtitle={current.name}
                    image={current.image}
                    actions={[
                        <Button variant="link" key="action" onClick={onShow}>
                            {group.action}
                        </Button>,
                    ]}
                />
                <Modal show={this.state.modal === group.name} onHide={onHide}>
                    <Modal.Header>
                        <Modal.Title>{group.modalName || group.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BorderedList
                            name={group.name}
                            field={{ ...field, value: current.id }}
                            options={group.options.map(this.formatCustomizationOptions.bind(this))}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onReset}>
                            <FormattedMessage id="modals.common.actions.cancel" defaultMessage="Cancel" />
                        </Button>
                        <Button onClick={onHide} variant="primary">
                            <FormattedMessage id="modals.look_and_feel.actions.select" defaultMessage="Select" />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </li>
        );
    };

    formatCustomizationOptions({ id, name, image }) {
        const field = this.props.fields.theme_custom_color;
        const props = { title: name, image };

        if (id.includes('custom')) {
            props.actions = [
                <Button key="button" onClick={() => this.setState({ showColorPickerForTheme: id })}>
                    <FormattedMessage id="themes.pick" defaultMessage="Pick a Color" />
                </Button>,
                this.state.showColorPickerForTheme === id ? (
                    <div key="picker" className="c-color-picker__popover">
                        <div
                            role="button"
                            tabIndex="0"
                            className="c-color-picker__cover"
                            onClick={() => this.setState({ showColorPickerForTheme: null })}
                        />
                        <BlockPicker
                            colors={[
                                '#D9E3F0',
                                '#F47373',
                                '#697689',
                                '#37D67A',
                                '#2CCCE4',
                                '#555555',
                                '#DCE775',
                                '#FF8A65',
                                '#BA68C8',
                            ]}
                            onChange={({ hex }) => field.onChange(hex)}
                            color={field.value}
                        />
                    </div>
                ) : null,
                <input
                    disabled
                    key="color"
                    type="color"
                    value={field.value}
                    className="c-color-picker__field form-control"
                />,
            ];
        }

        return { value: id, option: <CustomizationOption {...props} /> };
    }

    render() {
        const { fields, route, errors, status, handleSubmit, company } = this.props;
        let avatar = fields.photo.data.url.value;
        const uploadedAt = company.getIn(['photo', 'data', 'uploaded_at']);

        // If the avatar is a URL we add an extra query parameter to ensure we always have the latest image.
        if (avatar && avatar.includes('http')) {
            avatar = `${avatar}?fm=png&uploaded_at=${uploadedAt}`;
        }

        return (
            <FormPane
                title={route.title}
                status={status}
                onSubmit={handleSubmit}
                disabled={Object.keys(errors).length > 0}
            >
                {this.props.saved && (
                    <SuccessAlert
                        title={
                            <FormattedMessage
                                id="alerts.theme.success.title"
                                defaultMessage="Cool - Look & Feel Changed"
                            />
                        }
                        body={
                            <FormattedMessage
                                id="alerts.theme.success.body"
                                defaultMessage=" It can take five minutes to update your website."
                            />
                        }
                    />
                )}
                <FormPanel
                    title={
                        <FormattedMessage id="pages.website.look_and_feel_panel.title" defaultMessage="Look & Feel" />
                    }
                >
                    <ul className="bc-bordered-list">{lookAndFeel.map(this.renderCustomizationGroup)}</ul>
                </FormPanel>
            </FormPane>
        );
    }
}
