import { List } from 'immutable';

export default class RoomSorter {
    private order: string[];
    private items: List<any>;

    public constructor(items: string[], order: string[]) {
        this.items = List(items);
        this.order = order;
    }

    getOrdinalFor(item: any) {
        if (!this.order.includes(item)) {
            return this.items.size;
        }

        return this.items
            .push(item)
            .sort((a, b) => {
                return this.order.indexOf(a) - this.order.indexOf(b);
            })
            .findLastKey(value => value === item);
    }
}
