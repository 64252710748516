import React, { FC } from 'react';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import Icon from '@/app.components/icons/Icon';
import { FormattedMessage } from 'react-intl-sweepbright';

export const EventCardDropdown: FC<{
    onEdit: () => void;
    onDelete: () => void;
    canEdit?: boolean;
    canDelete?: boolean;
}> = ({ onEdit, onDelete, canEdit, canDelete }) => {
    return (
        <Dropdown id="actions_dropdown">
            <Dropdown.Toggle
                as="button"
                className="bg-transparent cursor-default flex items-center focus:outline-none focus:shadow-outline rounded"
                type="button"
            >
                <Icon icon="options" size={24} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="pull-right">
                <Dropdown.MenuItem onSelect={onEdit} disabled={!canEdit}>
                    <FormattedMessage id="modals.schedule_visit.edit" defaultMessage="Edit" />
                </Dropdown.MenuItem>
                <Dropdown.MenuItem onSelect={onDelete} disabled={!canDelete}>
                    <FormattedMessage id="modals.schedule_visit.cancel" defaultMessage="Cancel" />
                </Dropdown.MenuItem>
            </Dropdown.Menu>
        </Dropdown>
    );
};
