import React from 'react';
import styled from '@emotion/styled';
import Shimmer from '@sweepbright/uikit/build/esm/shimmer';
import { Map } from 'immutable';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl-sweepbright';
import PropertyTitle from '@/app.domains/properties/PropertyTitle';
import useCompany from '@/app.hooks/useCompany';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import PropertyNegotiator from '@/app.domains/properties/PropertyNegotiator';
import PropertyType from '@/app.domains/properties/PropertyType';
import './PropertyInfoCard.scss';
import { FormattedLocation } from '@/app.components/localize/FormattedLocation';
import PropertiesImage from '@/new.domains/properties/PropertyShow/PropertyImage';
import StatusForm from './StatusForm';
import Area from './Area';

const bgImageUrl = ({ src }: { src: string | null }) => (src ? `url("${src}")` : 'none');

export const PropertyImage = styled.div<{ src: string | null; disabled?: boolean }>`
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), ${props => bgImageUrl(props)};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 190px;
    filter: ${props => (props.disabled ? 'grayscale(100%)' : 'none')};
`;

function PropertyInfoCard({
    property,
    children,
    className,
    readonly,
    footer,
}: {
    className?: string;
    readonly?: boolean;
    children?: React.ReactNode;
    property: Map<string, any>;
    footer?: React.ReactNode;
}) {
    const company = useCompany();
    const loading = !Boolean(property && property.get('id'));
    const repository = new EstateRepository(property, company);
    const coverImage = repository.getImageUrlWithPreset('preview');

    const type = repository.getType();
    const area = repository.getLiveableArea();
    const bedrooms = repository.getNumBedrooms();

    return (
        <div className={classNames('c-property-info__container', className)}>
            <div className="c-property-info__media">
                <PropertiesImage
                    type={type}
                    size="large"
                    src={coverImage}
                    disabled={['sold', 'rented'].includes(property.get('status'))}
                />
                {children}
                {!loading && (
                    <div className="c-property-info__status">
                        <StatusForm
                            propertyId={property.get('id')}
                            readonly={readonly}
                            negotiation={property.get('negotiation')}
                        />
                    </div>
                )}
            </div>
            <div className="c-property-info__content">
                <Shimmer isDataLoaded={!loading}>
                    <h4 className="c-property-info__title truncate">
                        <PropertyTitle property={property} />
                    </h4>
                    <div className="my-2 text-lg">
                        <PropertyType property={property.toJS()} />
                        {!!area?.size && (
                            <span className="ml-2">
                                <Area {...area} />
                            </span>
                        )}
                        {!!bedrooms && (
                            <span className="ml-2">
                                <FormattedMessage
                                    id="property.features.bedrooms"
                                    defaultMessage="{count} {count, plural, one {bed}  other {beds}}"
                                    values={{ count: bedrooms }}
                                />
                            </span>
                        )}
                    </div>
                </Shimmer>
                <Shimmer width="80%" isDataLoaded={!loading}>
                    <p className="text-muted truncate">
                        <FormattedLocation location={property.getIn(['attributes', 'location'])?.toJS()} />
                    </p>
                </Shimmer>
                <PropertyNegotiator propertyId={property.get('id')} />
            </div>
            {footer ? <div className="c-property-info__footer"> {footer} </div> : null}
        </div>
    );
}

export default PropertyInfoCard;
