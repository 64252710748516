import gql from 'graphql-tag';
import { PropertyCardFragment } from '@/graphql/fragments/propertyCard';

export const UPDATE_ESTATE_MUTATION = gql`
    mutation UpdateEstate($estateId: ID!, $operations: JSON!, $estateType: EstateType!) {
        updateEstate(estateId: $estateId, operations: $operations, estateType: $estateType) {
            estate {
                id
                ...PropertyCardFragment
            }
            success
            userErrors {
                field
                message
            }
        }
    }
    ${PropertyCardFragment}
`;
