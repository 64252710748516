import { put } from 'redux-saga/effects';
import { EstatesRequests } from '../../../requests/index';
import { setHighlights } from '../../actions';
import apiCall from '../Effects/apiCall';

export default function* onFetchHighlights({ companyId, ids }) {
    const highlights = yield ids.map(id => apiCall(new EstatesRequests().show, id, { ignore_errors: true }));

    const formattedHighlights = highlights.map((el, index) => {
        if (el) {
            return el;
        }

        return {
            id: ids[index],
        };
    });

    yield put(setHighlights(companyId, formattedHighlights));
}
