import gql from 'graphql-tag';
import { PreferenceFormFragment } from '@/graphql/fragments/contactPreference';

export const UPDATE_LEAD_PREFERENCE_MUTATION = gql`
    mutation UpdateLeadPreferenceMutation($input: UpdateLeadPreferenceInput!) {
        updateLeadPreference(input: $input) {
            success
            preference {
                ...PreferenceFormFragment
            }
            userErrors {
                field
                message
            }
        }
    }
    ${PreferenceFormFragment}
`;
