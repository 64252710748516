// @ts-nocheck
import React from 'react';

const IntersectionContext = React.createContext();

const DEFAULT_INTERSECTION_OPTIONS = {
    // defaults to the browser viewport
    root: null,
    rootMargin: '0px',
    threshold: [0, 0.25, 0.5, 0.75, 1],
};

export function IntersectionProvider({
    children,
    onIntersect,
    rootRef,
    options,
}: {
    children: React.ReactElement;
    onIntersect: IntersectionObserverCallback;
    options?: IntersectionObserverInit;
    rootRef: React.RefObject<HTMLElement>;
}) {
    const [, forceUpdate] = React.useState(0);
    const observer = React.useRef<Maybe<IntersectionObserver>>(null);

    React.useLayoutEffect(() => {
        observer.current = new IntersectionObserver(
            onIntersect,
            Object.assign({}, DEFAULT_INTERSECTION_OPTIONS, options, {
                root: rootRef.current,
            }),
        );
        forceUpdate(val => ++val);
    }, []);

    return <IntersectionContext.Provider value={observer.current}>{children}</IntersectionContext.Provider>;
}

export function useIntersectionObserver(): IntersectionObserver {
    return React.useContext(IntersectionContext);
}
