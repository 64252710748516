// @ts-nocheck
import { select, put, call } from 'redux-saga/effects';
import omit from 'lodash/omit';
import ContactsRequests from '@/requests/ContactsRequests';
import { getContactById } from '../../selectors';
import { setContact, resetPagination } from '../../actions';
import formatApiErrors from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';
import onUpdateContactLocationPreferences from './onUpdateContactLocationPreferences';

export default function* onUpdateContact({ contactId, attributes }: { contactId: string; attributes: any }) {
    const contact = yield select(getContactById(contactId));
    const isLead = contact.get('type') === 'lead';

    try {
        // Update contact preferences
        const { preferences } = attributes;
        if (preferences) {
            // Update location preferences
            yield call(onUpdateContactLocationPreferences, {
                contactId,
                location: preferences.location,
            });
        }

        // Update address
        if (attributes.address) {
            yield apiCall(new ContactsRequests().address, contactId, attributes.address);
        }

        // Update avatar
        const photo = attributes.photo;
        if (photo && photo.file) {
            yield apiCall(new ContactsRequests().photos, contactId, photo);
        } else if (photo && !photo.file && !photo.data.url && contact.getIn(['photo', 'data', 'url'])) {
            yield apiCall(new ContactsRequests().removePhoto, contactId);
        }

        // Updated contact
        // we remove all the includes here
        // that are part of the contact data
        const updatedContact = omit(
            {
                ...contact.toJS(),
                ...attributes,
            },
            ['interactions', 'latest_interaction', 'office', 'preferences', 'address'],
        );

        const method = isLead ? new ContactsRequests().updateLead : new ContactsRequests().updateVendor;
        const updated = yield apiCall(method, contactId, updatedContact);

        yield [put(resetPagination('areas')), put(setContact(updated))];

        // eslint-disable-next-line no-unused-expressions
        attributes?.resolve();
    } catch (response) {
        const errors = formatApiErrors(response);

        // eslint-disable-next-line no-unused-expressions
        attributes?.reject(errors);
    }
}
