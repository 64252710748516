import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Area from '@/app.domains/properties/Area';
import PropertyType from '@/app.domains/properties/PropertyType';
import { propertyArea } from '@/app.utils/services/Repositories/EstateRepository';
import { Estate } from '@/graphql/generated/types';

type Props = {
    property: Estate;
};

const PropertyCardInfo: React.FC<Props> = props => {
    const { property } = props;

    const numBedrooms = property?.attributes?.structure?.bedrooms || 0;

    const items = [
        <span key="type">
            <PropertyType property={property} />
        </span>,
    ];

    if (propertyArea(property)?.size) {
        items.push(
            <span key="area">
                <Area key="area" {...propertyArea(property)} />
            </span>,
        );
    }

    if (numBedrooms) {
        items.push(
            <FormattedMessage
                id="property.features.bedrooms"
                defaultMessage="{count} {count, plural, one {bed}  other {beds}}"
                values={{ count: numBedrooms }}
                key="numBedrooms"
            />,
        );
    }

    return <div className="space-x-2 truncate">{items}</div>;
};

export default PropertyCardInfo;
