// @ts-nocheck
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getViews } from '@/app.redux/selectors/UsersSelectors';
import { markFileAsViewed, fetchUserViews } from '../../app.redux/actions';

export type WithViewedProps = {
    viewed: string[];
    view: (id: string) => void;
};

export default withViewed;

function withViewed<T extends WithViewedProps = WithViewedProps>(WrappedComponent: React.ComponentType<T>) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    function ComponentWithViewed(props: Optionalize<T, WithViewedProps>) {
        const viewsIds: string[] = useSelector(getViews)
            ?.map(viewedFile => viewedFile.get('id'))
            .toJS();
        const dispatch = useDispatch();

        // fetch the views on mount
        React.useEffect(() => {
            dispatch(fetchUserViews());
        }, []);

        const view = React.useCallback((fileId: string) => {
            dispatch(markFileAsViewed(fileId));
        }, []);

        return <WrappedComponent viewed={viewsIds} view={view} {...props} />;
    }

    ComponentWithViewed.displayName = `withViewed(${displayName})`;

    return ComponentWithViewed;
}
