import React from 'react';
import { withRouter } from 'react-router';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import PropertyMatch from '@/new.domains/properties/PropertyShow/PropertyMatch';

const PropertyMatchPage: React.FunctionComponent<{
    params: {
        unit?: string;
        property: string;
    };
}> = props => {
    const { params } = props;
    const propertyId = params.unit || params.property;

    return (
        <>
            <LayoutColumn size="wide" key="matches">
                <ErrorBoundary>
                    <PropertyMatch propertyId={propertyId} domainType="property_matches" />
                </ErrorBoundary>
            </LayoutColumn>
        </>
    );
};

export default withRouter(PropertyMatchPage);
