import React from 'react';
import PropTypes from 'prop-types';
import UserContext from '@/app.shared/members/UserContext';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import ProfileAside from '@/app.components/asides/ProfileAside';

const ProfileLayout = props => {
    const user = React.useContext(UserContext);

    return (
        <LayoutContainer>
            <ProfileAside user={user} location={props.location.pathname} />
            {React.cloneElement(props.children, { user })}
        </LayoutContainer>
    );
};

ProfileLayout.propTypes = {
    children: PropTypes.node,
    location: PropTypes.object.isRequired,
};
export default ProfileLayout;
