import { PriceAggregations } from '@/requests/types';
import { ALLOWED_PRICE_RANGE } from '@/app.data/Properties';
import { SET_PRICE_AGGREGATIONS, AggregationAction } from '../actions/AggregationActions';

type AggregationsState = {
    [key: string]: PriceAggregations;
};

const TO_LET_OFFSET = 100;
const TO_SALE_OFFSET = 10000;

const DEFAULT_PROPERTIES_AGGREGATION = {
    sale: {
        count: 0,
        min_price: ALLOWED_PRICE_RANGE.min_price,
        max_price: ALLOWED_PRICE_RANGE.max_price,
    },
    let: {
        count: 0,
        min_price: ALLOWED_PRICE_RANGE.min_price,
        max_price: ALLOWED_PRICE_RANGE.max_price,
    },
    all: {
        count: 0,
        min_price: ALLOWED_PRICE_RANGE.min_price,
        max_price: ALLOWED_PRICE_RANGE.max_price,
    },
    price_range: [],
};

export default (
    state: AggregationsState = {
        properties: DEFAULT_PROPERTIES_AGGREGATION,
    },
    action: AggregationAction,
) => {
    switch (action.type) {
        case SET_PRICE_AGGREGATIONS: {
            return {
                ...state,
                [action.key]: {
                    let: {
                        count: action.aggregations.let.count,
                        max_price: action.aggregations.let.max_price + TO_LET_OFFSET,
                        min_price: 0,
                    },
                    sale: {
                        doc_count: action.aggregations.sale.count,
                        max_price: action.aggregations.sale.max_price + TO_SALE_OFFSET,
                        min_price: 0,
                    },
                    all: {
                        doc_count: action.aggregations.let.count + action.aggregations.sale.count,
                        max_price:
                            Math.max(action.aggregations.let.max_price, action.aggregations.sale.max_price) +
                            TO_SALE_OFFSET,
                        min_price: 0,
                    },
                    price_range: action.aggregations.price_range,
                },
            };
        }
        default: {
            return state;
        }
    }
};
