import React from 'react';
import Dropdown from '@sweepbright/uikit/build/esm/dropdown';
import { MenuItem } from 'react-bootstrap';
import ToggleButton from '@/app.components/elements/Buttons/ToggleButton';
import Icon from '@/app.components/icons/Icon';
import { FormattedMessage } from 'react-intl-sweepbright';
import './RegisterActivityAction.scss';

const DEFAULT_ACTIVITIES = [
    {
        id: 'visit',
        label: <FormattedMessage id="pages.property_timeline.register_activity_options.visit" defaultMessage="Visit" />,
    },
    {
        id: 'message',
        label: (
            <FormattedMessage id="pages.property_timeline.register_activity_options.message" defaultMessage="Message" />
        ),
    },
    {
        id: 'mail',
        label: <FormattedMessage id="pages.property_timeline.register_activity_options.mail" defaultMessage="E-mail" />,
    },
    {
        id: 'call',
        label: <FormattedMessage id="pages.property_timeline.register_activity_options.call" defaultMessage="Call" />,
    },
];

export default function RegisterActivityAction({ onRegisterActivity, activities = DEFAULT_ACTIVITIES }) {
    return (
        <Dropdown className="c-register-activity-action">
            <ToggleButton
                bsRole="toggle"
                variant="primary"
                className="flex items-center"
                icon={<Icon icon="add-activity" />}
            >
                <FormattedMessage
                    id="pages.property_timeline.register_activity_action"
                    defaultMessage="Register Activity"
                />
            </ToggleButton>
            <Dropdown.Menu>
                {activities.map(({ id, label }) => (
                    <MenuItem key={id} onSelect={() => onRegisterActivity(id)}>
                        {label}
                    </MenuItem>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}
