import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Link from '@/app.components/elements/Link';
import { CONTACT, PROPERTY, PROPERTY_UNIT } from '@/app.routing/routes';
import { fullName } from '@/app.utils/services/Helpers';
import { Contact, Estate } from '@/graphql/generated/types';

export function ContactLink({
    contact,
    onClick,
}: {
    contact: Contact;
    onClick?: (evt: React.MouseEvent<HTMLAnchorElement>) => void;
}) {
    return (
        <Link to={CONTACT(contact.id)} onClick={onClick}>
            {fullName(contact.firstName, contact.lastName)}
        </Link>
    );
}

export function PropertyLink({ property }: { property: Estate }) {
    const link =
        property.isUnit && property.projectId ? PROPERTY_UNIT(property.projectId, property.id) : PROPERTY(property.id);

    return (
        <Link to={link}>
            {property.attributes.location.formatted ?? (
                <FormattedMessage id="address.no_location_defined" defaultMessage="No location defined" />
            )}
        </Link>
    );
}
