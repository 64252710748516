// @ts-nocheck
import React from 'react';
import produce from 'immer';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { useMutation, MutationTuple } from '@apollo/react-hooks';
import { useToasts } from '@sweepbright/notifications';
import { REMOVE_LOCATION_PREFERENCE } from '@/graphql/mutations/contacts/removeLocationPreference';
import { UPDATE_LOCATION_PREFERENCE } from '@/graphql/mutations/contacts/updateLocationPreference';
import {
    GetLeadQuery,
    UpdateLocationPreferenceMutation,
    UpdateLocationPreferenceMutationVariables,
    RemoveLocationPreferenceMutationVariables,
    RemoveLocationPreferenceMutation,
} from '@/graphql/generated/types';
import { GET_LEAD_QUERY } from '@/graphql/queries/contacts/getLead';
import LocationPreferenceCard from '@/app.components/card/LocationPreferenceCard/LocationPreferenceCard';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { FormattedMessage } from 'react-intl-sweepbright';

export default function LocationPreferenceListItem({
    location,
    leadId,
    preferenceId,
    onDeleted,
}: {
    preferenceId?: string;
    leadId: string;
    location: FixMeType;
    onDeleted?: () => void;
}) {
    const { addSuccess, addError } = useToasts();
    const [updateLocationPreference]: MutationTuple<
        UpdateLocationPreferenceMutation,
        UpdateLocationPreferenceMutationVariables
    > = useMutation(UPDATE_LOCATION_PREFERENCE, {
        errorPolicy: 'all',
        onCompleted() {
            addSuccess({
                message: (
                    <FormattedMessage id="location.preferences.updated" defaultMessage="Location preference updated" />
                ),
            });
        },
        onError(error) {
            getBugsnagClient().notify(error);
            addError({
                message: <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />,
            });
        },
    });

    const [removeLocationPreference]: MutationTuple<
        RemoveLocationPreferenceMutation,
        RemoveLocationPreferenceMutationVariables
    > = useMutation(REMOVE_LOCATION_PREFERENCE, {
        update: getUpdateOnRemove(),
        onCompleted() {
            addSuccess({
                message: (
                    <FormattedMessage id="location.preferences.deleted" defaultMessage="Location preference deleted" />
                ),
            });
            // eslint-disable-next-line no-unused-expressions
            onDeleted?.();
        },
        onError(error) {
            getBugsnagClient().notify(error);
            addError({
                message: <FormattedMessage id="general.something_went_wrong" defaultMessage="Something went wrong" />,
            });
        },
    });

    const handleRemoveLocation = (locationVariables: Omit<RemoveLocationPreferenceMutationVariables, 'leadId'>) => {
        return removeLocationPreference({
            variables: {
                leadId,
                preferenceId,
                locationPreferenceId: location.id,
                ...locationVariables,
            },
        });
    };

    const handleUpdateLocation = (
        locationVariables: Omit<UpdateLocationPreferenceMutationVariables, 'leadId' | 'locationPreferenceId'>,
    ) => {
        return updateLocationPreference({
            variables: {
                leadId,
                preferenceId,
                locationPreferenceId: location.id,
                ...locationVariables,
            },
        });
    };

    return (
        <li>
            <LocationPreferenceCard
                location={location}
                onUpdate={handleUpdateLocation}
                onRemove={handleRemoveLocation}
            />
        </li>
    );
}

function getUpdateOnRemove() {
    return function update(cache: InMemoryCache, { data: { removeLocationPreference } }) {
        // get the lead from the cache
        const leadId = removeLocationPreference.lead.id;
        const data: GetLeadQuery | null = cache.readQuery({
            query: GET_LEAD_QUERY,
            variables: {
                id: leadId,
            },
        });

        const removedLocationPreferenceId = removeLocationPreference.id;

        const newData = produce(data, draftData => {
            const newEdges = draftData!.lead!.preferences.locations.edges.filter(
                edge => edge.node.id !== removedLocationPreferenceId,
            );
            draftData!.lead!.preferences.locations.edges = newEdges;
            draftData!.lead!.preferences.locations.totalCount++;
        });

        cache.writeQuery({
            query: GET_LEAD_QUERY,
            variables: {
                id: leadId,
            },
            data: newData,
        });
    };
}
