import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Icon from '@/app.components/icons/Icon';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import ContactLabelsModal from '@/shared/contacts/ContactLabelsModal';

type Props = {
    contactId: string;
};

const ContactProfileAction: React.FC<Props> = props => {
    const { contactId } = props;

    const intl = useIntl();

    const [visible, setVisible] = useState<'labels' | undefined>(undefined);

    const content = {
        title: intl.formatMessage({ id: 'contact.labels.modal' }),
    };

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle
                    as="button"
                    className="text-white bg-transparent flex items-center focus:outline-none focus:shadow-outline rounded cursor-default"
                >
                    <Icon icon="options" size={24} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="pull-right">
                    <Dropdown.MenuItem
                        key="labels"
                        onSelect={() => {
                            setVisible('labels');
                        }}
                        className="focus:outline-none"
                    >
                        {content.title}
                    </Dropdown.MenuItem>
                </Dropdown.Menu>
            </Dropdown>

            <ContactLabelsModal contactId={contactId} setVisible={setVisible} visible={visible === 'labels'} />
        </>
    );
};

export default ContactProfileAction;
