import { call, put, take } from 'redux-saga/effects';
import { promisify } from '@/app.utils/services/Helpers/promises';
import { CompaniesRequests } from '../../../requests/index';
import { fetchCompany, fetchCompanyCard, SET_COMPANY_CARD, isSaving } from '../../actions';
import formatApiErrors from '../Helpers/formatApiErrors';
import { createToken } from '../Helpers/stripe';
import apiCall from '../Effects/apiCall';

export default function* onUpdateCompanyCard({ attributes: { companyId, reject, resolve, ...attributes } }) {
    try {
        yield put(isSaving());
        const token = yield call(promisify(createToken), {
            number: attributes.number,
            cvc: attributes.cvc,
            exp: attributes.expiry,
        });

        yield apiCall(new CompaniesRequests().cards, companyId, token.id);

        yield [put(fetchCompany(companyId)), put(fetchCompanyCard(companyId))];
        // wait for the company cards to be set before resolving the promise
        yield take(SET_COMPANY_CARD);
        yield call(resolve);
    } catch (error) {
        yield call(reject, formatApiErrors(error.response, '_error'));
    } finally {
        yield put(isSaving(false));
    }
}
