import gql from 'graphql-tag';

const EstateChannelAccountEdge = gql`
    fragment EstateChannelAccount on EstateChannelAccountConnectionEdge {
        id
        publicationStatus
        configurationStatus
        configurationStatusMessageI18Key
        outdated
        missingFields {
            __typename
            id
            estateId
            node {
                path
                errorMessage
            }
        }
        unitsMissingFields {
            edges {
                __typename
                id
                estateId
                node {
                    path
                    errorMessage
                }
            }
        }
        canPublish
        lastPublication {
            id
            publishedAt
            link
            publishedBy {
                id
                firstName
                lastName
            }
            config {
                showPrice
                showLocation
                ... on ImmowebPublicationConfig {
                    adSize
                }
                ... on FacebookPublicationConfig {
                    post
                }
                ... on TwitterPublicationConfig {
                    message
                }
                ... on EstateAgentFeedsPublicationConfig {
                    priceType
                    features
                }
                ... on FrenchPortalPublicationConfig {
                    portals
                }
            }
            errors {
                self {
                    message
                    translationKey
                }
                units {
                    unitId
                    errors {
                        message
                        translationKey
                    }
                }
            }
            warnings {
                self {
                    message
                    translationKey
                }
                units {
                    unitId
                    warnings {
                        message
                        translationKey
                    }
                }
            }
        }
        node {
            id
            name
            status
            channel {
                id
                countries
                name
                logoUrl
                slug
                supportsUnpublishing
                supportsRepublishing
            }
        }
    }
`;

export const ESTATE_CHANNELS_ACCOUNTS_QUERY = gql`
    query GetEstateChannelAccounts(
        $propertyId: ID!
        $isOldPublishing: Boolean = false
        $isAdStatus: Boolean = false
        $status: [String]
        $isStatusEnabled: Boolean = false
    ) {
        estate(id: $propertyId) {
            id
            publications {
                id
                channelAccounts(isAdStatus: $isAdStatus, status: $status, isStatusEnabled: $isStatusEnabled)
                    @skip(if: $isOldPublishing) {
                    edges {
                        ...EstateChannelAccount
                    }
                    pageInfo {
                        currentPage
                        hasNextPage
                    }
                }
            }
        }
    }
    ${EstateChannelAccountEdge}
`;

export const ESTATE_CHANNEL_ACCOUNT_QUERY = gql`
    query GetEstateChannelAccount($estateId: ID!, $channelAccountId: ID!, $lastModified: String) {
        estate(id: $estateId) {
            id
            channelAccount(id: $channelAccountId, lastModified: $lastModified) {
                ...EstateChannelAccount
            }
        }
    }
    ${EstateChannelAccountEdge}
`;
