// @ts-nocheck
import React from 'react';
import noop from 'lodash/noop';
import isFunction from 'lodash/isFunction';
import Modal from '@/app.components/elements/Modal';

export type HasModalProps = {
    open: (modalName: string) => void;
    modalWithPromise: (
        modalName: string,
        modalContent: React.ReactNode,
        onSubmit: () => Promise<any>,
        extraProps?: object,
        onSubmitSuccess?: (values: any) => void,
    ) => void;
};

export default function withModals(WrappedComponent) {
    class EnhancedComponent extends React.Component {
        state = {
            shownModal: false,
        };

        close = () => {
            this.setState({ shownModal: false });
        };

        open = modal => {
            this.setState({ shownModal: modal });
        };

        opened = modal => {
            return this.state.shownModal === modal;
        };

        modal = (name, modalNode, props = {}) => {
            const handleClose = () => {
                if (props?.onClose) {
                    props.onClose();
                }

                this.close();
            };

            return (
                <Modal show={this.opened(name)} onHide={handleClose} key={name} {...props}>
                    {React.cloneElement(modalNode, {
                        close: this.close,
                        onCancel: this.close,
                    })}
                </Modal>
            );
        };

        modalWithPromise = (
            name: string,
            modalNode: any,
            onSubmit: (attributes: any) => Promise<void> | void,
            props: Record<string, any> = {},
            callback = noop,
        ) => {
            return this.modal(
                name,
                React.cloneElement(modalNode, {
                    onSubmitSuccess: result => {
                        // close the modal and execute the callback
                        this.close();
                        // eslint-disable-next-line no-unused-expressions
                        callback?.(result);
                    },
                    onSubmit: (attributes: any) => {
                        return new Promise((resolve, reject) => {
                            const result = onSubmit({ ...attributes, resolve, reject });
                            if (result?.then && isFunction(result.then)) {
                                return result.then(resolve, reject);
                            } else {
                                return result;
                            }
                        });
                    },
                }),
                props,
            );
        };

        render() {
            return (
                <WrappedComponent
                    close={this.close}
                    onCancel={this.close}
                    open={this.open}
                    modal={this.modal}
                    modalWithPromise={this.modalWithPromise}
                    shownModal={this.state.shownModal}
                    {...this.props}
                />
            );
        }
    }

    return EnhancedComponent;
}
