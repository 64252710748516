import React, { useMemo } from 'react';
import { compare } from 'fast-json-patch';
import { useSelector } from 'react-redux';
import produce from 'immer';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import differenceBy from 'lodash/differenceBy';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '@/app.components/icons/Icon';
import { STATUSES_FOR_NEGOTIATION_QUERY } from '@/graphql/queries/properties/getStatuses';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import ConfirmDealDetailsModal from '@/app.components/modals/ConfirmDealDetailsModal';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import { track, events } from '@/app.utils/analytics';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import useProperty, { useUpdatePropertyWithOperations } from '@/app.hooks/useProperty';
import { useEstateBuyers } from '@/app.hooks/useEstateBuyers';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import { Negotiation } from '@/app.data/Settings';
import { usePropertyStatus } from '@/app.hooks/usePropertyStatus';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { PropertyData } from '@/app.utils/services/PatchBuilders/PropertyPatchBuilder';
import StatusLabel from '../../app.components/forms/StatusLabel';

function useNegotiationStatuses(negotiation?: string) {
    const showEstimate = useFeatureFlag('property.status.estimate');

    const { data: statusesData, loading } = useQuery(STATUSES_FOR_NEGOTIATION_QUERY, {
        variables: {
            negotiation: negotiation && negotiation.toUpperCase(),
        },
        fetchPolicy: 'cache-first',
        skip: !negotiation,
    });

    const statuses: { id: string }[] = useMemo(() => {
        return statusesData ? statusesData?.statuses?.filter(el => (el.id === 'estimate' ? showEstimate : true)) : [];
    }, [showEstimate, statusesData]);

    return { statuses, loading };
}

export const GET_ESTATE_STATUS_QUERY = gql`
    query GetEstateNegotationStatus($estateId: ID!) {
        estate(id: $estateId) {
            id
            negotiation
            status
        }
    }
`;

const StatusForm = React.memo(function StatusForm({
    propertyId,
    readonly,
    negotiation,
}: {
    propertyId: string;
    readonly?: boolean;
    negotiation: Negotiation;
}) {
    const [status, setStatus] = usePropertyStatus(propertyId);

    if (status) {
        return (
            <StatusDropdown
                propertyId={propertyId}
                status={status}
                negotiation={negotiation}
                readonly={readonly}
                onChange={setStatus}
            />
        );
    }

    return null;
});

export default withErrorBoundary(StatusForm);
//

const ChevronDownIcon = props => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <path fill="currentColor" d="M7 10l5 5 5-5z" />
    </svg>
);

const IDLE_STATE = {
    state: 'idle',
};

const Chip = React.forwardRef(function Chip(
    {
        label,
        leftIcon,
        rightIcon,
        onClick,
        disabled,
    }: {
        label: React.ReactNode;
        leftIcon?: React.ReactNode;
        rightIcon?: React.ReactNode;
        disabled?: boolean;
        onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
    },
    ref: React.Ref<HTMLButtonElement>,
) {
    return (
        <button
            ref={ref}
            onClick={onClick}
            type="button"
            data-testid="status-button"
            className={classNames(
                'bg-white inline-flex items-center rounded-full border border-solid text-sm px-1 py-0 focus:outline-none cursor-default',
                {
                    'border-gray-light': !disabled,
                    'border-gray-lightest': disabled,
                },
            )}
        >
            {leftIcon}
            <span
                className={classNames('mx-1 truncate flex items-center', {
                    'text-gray-dark': !disabled,
                    'text-muted': disabled,
                })}
            >
                {label}
            </span>
            {rightIcon}
        </button>
    );
});

function StatusDropdown({ status, onChange, negotiation, propertyId, readonly }) {
    if (readonly) {
        return (
            <Chip
                leftIcon={status && <StatusDot status={status} />}
                label={
                    status ? (
                        <StatusLabel negotiation={negotiation} status={status} />
                    ) : (
                        <FormattedMessage id="general.state.loading" defaultMessage="Loading...." />
                    )
                }
            />
        );
    }

    return (
        <>
            <Dropdown id="status_dropdown" data-testid="property-status-dropdown">
                <Dropdown.Toggle
                    as={Chip}
                    leftIcon={status && <StatusDot status={status} />}
                    label={
                        status ? (
                            <StatusLabel negotiation={negotiation} status={status} />
                        ) : (
                            <FormattedMessage id="general.state.loading" defaultMessage="Loading...." />
                        )
                    }
                    rightIcon={<ChevronDownIcon className="w-6 h-6 text-muted" />}
                />
                <Dropdown.Menu data-testid="status-options">
                    <StatusDropdownItems
                        propertyId={propertyId}
                        negotiation={negotiation}
                        onChangeStatus={onChange}
                        status={status}
                    />
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

export function StatusDropdownItems({
    propertyId,
    negotiation,
    onChangeStatus,
    status,
}: {
    propertyId: string;
    negotiation: Negotiation;
    onChangeStatus: (status: string) => void;
    status;
}) {
    const { statuses = [] } = useNegotiationStatuses(negotiation);

    const [state, setState] = React.useState<{ state: string; targetStatus?: string }>(IDLE_STATE);

    React.useEffect(() => {
        setState(IDLE_STATE);
    }, [status]);

    return (
        <>
            {statuses.map(option => {
                return (
                    <Dropdown.MenuItem
                        key={option.id}
                        onSelect={() => {
                            if (option.id !== status) {
                                track(events.PROPERTY_STATUS_DROPDOWN_ITEM_CLICKED, {
                                    status: option.id,
                                });
                                setTimeout(() => {
                                    setState({
                                        state: 'confirming',
                                        targetStatus: option.id,
                                    });
                                }, 0);
                            }
                        }}
                        className="flex justify-between items-center"
                    >
                        <StatusLabel status={option.id} negotiation={negotiation} />
                        {option.id === status && <Icon icon="check" className="ml-2" />}
                    </Dropdown.MenuItem>
                );
            })}
            {state.state === 'confirming' && (
                <ConfirmChangePropertyStatus
                    propertyId={propertyId}
                    onConfirm={() => {
                        setState(IDLE_STATE);
                        onChangeStatus(state.targetStatus!);
                    }}
                    targetStatus={state.targetStatus}
                    onCancel={() => setState(IDLE_STATE)}
                />
            )}
        </>
    );
}

function ConfirmChangePropertyStatus({ targetStatus, onConfirm, onCancel, propertyId }) {
    const { property } = useProperty(propertyId);
    const updatePropertyWithOperations = useUpdatePropertyWithOperations();
    const { assignBuyers, unassignBuyers } = useEstateBuyers(propertyId, true);

    React.useLayoutEffect(() => {
        if (!isConfirmationNeeded(targetStatus)) {
            // no confirmation needed, just confirm right away
            onConfirm();
        }
    }, [targetStatus]);

    const confirmationEnabled = useSelector(getFeature('property.deal_details.enabled'));

    const repository = new EstateRepository(fromJS(property));

    const ids = property?.buyer_ids || [];

    const currentBuyers = (ids || []).map(el => {
        return { id: el, type: 'lead' };
    });

    return isConfirmationNeeded(targetStatus) ? (
        <ConfirmDealDetailsModal
            isLet={property?.negotiation === 'let'}
            show={true}
            propertyId={propertyId}
            initialValues={{
                transactionPrice: repository.getPriceAmount('current_price'),
                // @ts-ignore
                buyers: currentBuyers,
            }}
            onConfirm={async ({ transactionPrice, buyers }) => {
                const addedBuyersIds = differenceBy(buyers, currentBuyers, 'id').map(buyer => buyer.id);
                const removedBuyersIds = differenceBy(currentBuyers, buyers, 'id').map(buyer => buyer.id);
                const patchOperations = getCurrentPriceChangeOperations(
                    property!.attributes,
                    parseFloat(transactionPrice),
                );
                await Promise.all([
                    updatePropertyWithOperations(propertyId, patchOperations, property?.internalType!),
                    assignBuyers(addedBuyersIds),
                    unassignBuyers(removedBuyersIds),
                ]);
                onConfirm();
            }}
            onCancel={onCancel}
        />
    ) : null;

    function isConfirmationNeeded(status) {
        if (!confirmationEnabled) {
            return false;
        }
        // we don't ask confirmation for projects
        if (property?.isProject) {
            return false;
        }

        return ['sold', 'rented', 'agreement'].includes(status);
    }
}

const STATUS_COLORS = {
    prospect: '#ED2D98',
    available: '#47C9AD',
    option: '#E0CF50',
    under_contract: '#7523CF',
    rented: '#17A8E6',
    sold: '#17A8E6',
    lost: '#8E8E93',
    agreement: '#FF574E',
    estimate: '#3CC2DD',
};

function StatusDot({ status }) {
    return <div className="rounded w-2 h-2 mx-1" style={{ background: STATUS_COLORS[status] }} />;
}

export function StatusChip({ status, negotiation }: { status: string; negotiation: Negotiation }) {
    const showEstimate = useFeatureFlag('property.status.estimate');

    return (
        <div className="bg-gray-lightest rounded-full px-1 h-5 text-xs flex items-center cursor-default flex-shrink-0">
            {status && <StatusDot status={!showEstimate && status === 'estimate' ? 'prospect' : status} />}
            <span className="pr-1 text-gray-dark cursor-default">
                {status ? (
                    <StatusLabel
                        status={!showEstimate && status === 'estimate' ? 'prospect' : status}
                        negotiation={negotiation}
                    />
                ) : (
                    <FormattedMessage id="general.state.loading" defaultMessage="Loading...." />
                )}
            </span>
        </div>
    );
}

function getCurrentPriceChangeOperations(attributes: PropertyData, transactionPrice: number) {
    const updatedAttributes = produce(draftAttributes => {
        if (!draftAttributes.price) {
            draftAttributes.price = {};
        }
        if (!draftAttributes.price.current_price) {
            draftAttributes.price.current_price = {};
        }
        draftAttributes.price.current_price.amount = transactionPrice;
    })(attributes);

    const formattedAttributes = compare(attributes, updatedAttributes);

    const includesReplaceOperation = formattedAttributes.some(
        operation => operation.op === 'replace' && operation.path === '/price/current_price/amount',
    );

    if (includesReplaceOperation) {
        return [
            {
                op: 'add',
                path: '/price/current_price/amount',
                value: '',
            },
            ...formattedAttributes,
        ];
    }

    return formattedAttributes;
}
