import CacheableCrudRequest from './CacheableCrudRequest';

export default class EmbedlyRequests extends CacheableCrudRequest {
    apiKey?: string;
    constructor() {
        super();
        this.rootUrl = 'https://api.embedly.com/1';
        this.apiKey = process.env.EMBEDLY_API_KEY;
        if (!this.apiKey) {
            throw new Error('EMBEDLY_API_KEY is missing');
        }
    }

    oembed(url: string) {
        return this.setQueryParameters({
            key: this.apiKey,
            url,
        }).get('oembed');
    }
}
