import { InteractionFeedbackType } from '@/app.data/Conditions';
import SweepbrightCrudRequest from './SweepbrightCrudRequest';

export type FeedbackPayload = {
    type: InteractionFeedbackType;
    external_comments: string;
    internal_comments: string;
    send_to_vendor: boolean;
};

export type VendorFeedbackPayload = {
    type: InteractionFeedbackType;
    internal_comments: string;
};

export default class InteractionsRequests extends SweepbrightCrudRequest {
    registerFeedback = (interactionId: string, feedback: FeedbackPayload) => {
        return this.put(`interactions/${interactionId}/feedback`, {
            feedback_type: feedback.type,
            feedback_internal_comment: feedback.internal_comments,
            feedback_external_comment: feedback.external_comments,
            send_to_vendor: feedback.send_to_vendor,
        });
    };
}
