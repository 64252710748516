import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { MutationTuple, useMutation, useQuery } from '@apollo/react-hooks';
import { QueryResult } from '@apollo/react-common';
import { GET_LABELS } from '@/graphql/queries/company/getLabels';
import {
    GetLabelsQuery,
    GetLabelsQueryVariables,
    UpdateLabelMutation,
    UpdateLabelMutationVariables,
    AddLabelMutation,
    AddLabelMutationVariables,
    DeleteLabelMutation,
    DeleteLabelMutationVariables,
    Label,
} from '@/graphql/generated/types';
import { ADD_LABEL } from '@/graphql/mutations/companies/addLabel';
import { UPDATE_LABEL } from '@/graphql/mutations/companies/updateLabel';
import { DELETE_LABEL } from '@/graphql/mutations/companies/deleteLabel';

type Props = {
    skip?: boolean;
    query?: string;
};

const useLabels = (props?: Props) => {
    const { skip } = props || {};

    const [page, setPage] = useState(1);
    const [labels, setLabels] = useState<Label[]>([]);

    const [debouncedQuery, setDebouncedQuery] = useState('');

    useDebounce(
        () => {
            setPage(1);
            setDebouncedQuery(props?.query || '');
        },
        400,
        [props?.query],
    );

    const { data, loading }: QueryResult<GetLabelsQuery, GetLabelsQueryVariables> = useQuery(GET_LABELS, {
        skip: !page || skip,
        fetchPolicy: 'no-cache',
        variables: {
            page,
            query: debouncedQuery || undefined,
        },
    });

    const pagination = data?.labels?.meta?.pagination;

    useEffect(() => {
        if (!loading) {
            const newData = data?.labels?.data || [];

            if (newData.length === 0 && page === 1) {
                setLabels([]);
            }

            if (newData.length > 0) {
                if (page === 1) {
                    setLabels(newData);
                }

                if (page > 1) {
                    setLabels(prevData => [...(prevData || []), ...newData]);
                }
            }
        }
    }, [page, loading, data]);

    const currentPage = pagination?.current_page || 1;
    const totalPages = pagination?.total_pages || 1;
    const hasNextPage = pagination ? currentPage < totalPages : false;

    const fetchMore = () => {
        if (hasNextPage && !loading) {
            setPage(prevState => prevState + 1);
        }
    };

    const refetch = () => {
        setPage(0);
        setPage(1);
    };

    const [addLabel]: MutationTuple<AddLabelMutation, AddLabelMutationVariables> = useMutation(ADD_LABEL, {
        update: () => {},
    });

    const [updateLabel]: MutationTuple<UpdateLabelMutation, UpdateLabelMutationVariables> = useMutation(UPDATE_LABEL, {
        update: () => {},
    });

    const [deleteLabel]: MutationTuple<DeleteLabelMutation, DeleteLabelMutationVariables> = useMutation(DELETE_LABEL, {
        update: () => {},
    });

    return {
        refetch,
        loading,
        addLabel,
        pagination,
        updateLabel,
        deleteLabel,
        hasNextPage,
        data: labels,
        fetchMore: fetchMore,
    };
};

export default useLabels;
