import { fromJS } from 'immutable';

export const templates = {
    default: [
        'number, street_1, street_2',
        'city',
        'borough_or_district',
        'postal_code',
        'province_or_state',
        'country',
    ],
    CA: ['box number street_1 street_2', 'city province_or_state', 'country'],
    DK: ['street_1 street_2 number box', 'postal_code city', 'country'],
    FI: ['street_1 street_2 number', 'postal_code city', 'country'],
    NO: ['box', 'street street_2 number', 'postal_code city', 'country'],
    NL: ['street_1 street_2 number box', 'postal_code city', 'country'],
    SE: ['street_1 street_2, number box', 'postal_code city', 'country'],
    UK: ['street_2 number street_1 province_or_state', 'city', 'postal_code', 'country'],
    US: ['number box street_1 street_2', 'city, province_or_state, postal_code', 'country'],
};

export default function formatAddress(_address) {
    const address = fromJS(_address);

    const country = address.get('country', 'default');
    const template = templates[country] || templates.default;

    const components = [
        'street',
        'street_2',
        'number',
        'addition',
        'box',
        'floor',
        'postal_code',
        'city',
        'province_or_state',
        'borough_or_district',
        'country',
    ];

    if (!address.size) {
        return '';
    }

    const formatted = components.reduce((reduction, _key) => {
        const value = address.get(_key) || '';
        const key = _key === 'street' ? 'street_1' : _key;

        return reduction.replace(key, value);
    }, template.join('\n'));

    return formatted
        .split('\n')
        .map(piece =>
            piece
                .trim()
                .replace(/^[ ,\n]+|[ ,\n]+$/g, '')
                .replace(/[\n ,]^/, ''),
        )
        .filter(Boolean)
        .join('\n');
}
