import { put, select } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { OfficesRequests } from '../../../requests/index';
import { setErrors, isSaving, fetchOffice } from '../../actions';
import { getOfficeFormValues } from '../../selectors';
import formatApiErrors from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';

export default function* onUpdateOffice({ officeId, attributes }) {
    try {
        yield put(isSaving());

        const defaultAttributes = yield select(getOfficeFormValues, {
            params: {
                office: officeId,
            },
        });

        if (attributes.name) {
            yield apiCall(new OfficesRequests().update, officeId, {
                name: attributes.name,
            });
        }

        if (attributes.lead_negotiator) {
            yield apiCall(new OfficesRequests().updateHeadNegotiator, officeId, attributes.lead_negotiator);
        }

        yield apiCall(new OfficesRequests().updateSettings, officeId, {
            ...defaultAttributes.settings,
            ...attributes.settings,

            // Ensure backward-compatibility attributes are not sent back
            legal_mentions: null,
            description: null,
        });

        yield [put(fetchOffice(officeId)), put(isSaving(false))];
    } catch (response) {
        yield [
            put(stopSubmit('office/edit', formatApiErrors(response))),
            put(setErrors(response.data ? response.data.errors : response)),
        ];
    }
}
