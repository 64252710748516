import React from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';
import useOffice from '@/app.hooks/useOffice';
import Input from '@/app.components/forms/Input/Input';
import { ResourceDomainType, ResourceFilterType } from '@/shared/resource/types';

type Props = {
    id: string;
    prefix?: string;
    tempParams: any;
    setTempParams: any;
    filter: ResourceFilterType;
    intlKey: ResourceDomainType;
};

const ResourceFilterFormNumber: React.FC<Props> = props => {
    const { id, filter, prefix, intlKey, tempParams, setTempParams } = props;

    const intl = useIntl();

    const office = useOffice();
    const currency = office.getIn(['settings', 'data', 'currency'], 'EUR');

    const title = filter.showTitle
        ? intl.formatMessage({ id: `${intlKey}.filter.${id}.${filter.paramName}` })
        : undefined;

    return (
        <Input
            type="price"
            label={title}
            className="m-0"
            key={filter.paramName}
            value={tempParams?.[filter.paramName]}
            addonAfter={prefix === 'euro' ? currency : undefined}
            placeholder={<FormattedMessage id="general.any" defaultMessage="Any" />}
            onChange={newValue => {
                if (newValue != null && parseFloat(newValue)) {
                    setTempParams({ ...tempParams, [filter.paramName]: parseFloat(newValue) });
                } else {
                    setTempParams({ ...tempParams, [filter.paramName]: undefined });
                }
            }}
        />
    );
};

export default ResourceFilterFormNumber;
