import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Input from '@/app.components/forms/Input/Input';
import useUser from '@/app.hooks/useUser';
import FilterNegotiatorsSelect from '../FilterNegotiatorsSelect';

type Props = {
    mineLabel?: any;
    initialValues?: any;
    tempSearchSettings?: any;
    showUnassignedOption?: boolean;
    setTempSearchSettings: Function;
};

const FilterNegotiatorsForm: React.FC<Props> = props => {
    const { mineLabel, tempSearchSettings, setTempSearchSettings, showUnassignedOption } = props;

    const user = useUser();
    const userId = `${user.get('id')}`;

    const value = tempSearchSettings?.negotiator_ids;

    const getInitTempOption = () => {
        if (value === null && showUnassignedOption) {
            return 'none';
        }

        if (value && value.length) {
            if (value.length === 1 && value.includes(userId)) {
                return 'mine';
            }
        }

        return 'choose';
    };

    const [tempOption, setTempOption] = useState<'none' | 'mine' | 'choose'>(getInitTempOption());

    return (
        <>
            {showUnassignedOption ? (
                <Input
                    type="radio"
                    checked={tempOption === 'none'}
                    onChange={() => {
                        setTempOption('none');
                        setTempSearchSettings({
                            ...tempSearchSettings,
                            negotiator_ids: null,
                        });
                    }}
                    label={
                        <FormattedMessage
                            id="modals.properties_search.ownership_pane.no_negotiator_label"
                            defaultMessage="No Negotiator"
                        />
                    }
                />
            ) : null}
            <Input
                type="radio"
                label={mineLabel}
                checked={tempOption === 'mine'}
                onChange={() => {
                    setTempOption('mine');
                    setTempSearchSettings({
                        ...tempSearchSettings,
                        negotiator_ids: [userId],
                    });
                }}
            />
            <Input
                type="radio"
                checked={tempOption === 'choose'}
                onChange={() => {
                    setTempOption('choose');
                    setTempSearchSettings({
                        ...tempSearchSettings,
                        negotiator_ids: [],
                    });
                }}
                label={
                    <FormattedMessage
                        id="modals.properties_search.ownership_pane.with_negotiator_label"
                        defaultMessage="Choose Negotiator"
                    />
                }
            />

            {tempOption === 'choose' && (
                <FilterNegotiatorsSelect
                    selected={tempSearchSettings.negotiator_ids}
                    setSelected={(newSelected: string[]) => {
                        setTempSearchSettings({
                            ...tempSearchSettings,
                            negotiator_ids: newSelected,
                        });
                    }}
                />
            )}
        </>
    );
};

export default FilterNegotiatorsForm;
