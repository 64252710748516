import gql from 'graphql-tag';

export const ContactCardFragment = gql`
    fragment ContactCardFragment on Contact {
        __typename
        id
        firstName
        lastName
        email
        phone
        type
        archived
        subscribed
        updatedAt
        ... on Lead {
            id
            lastAction
            lastInteraction
        }
        photo {
            id
            url
            uploadedAt
        }
        pronouns
        assignedNegotiators {
            id
            first_name
            last_name
            picture_url
        }
        officeId
    }
`;
