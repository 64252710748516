import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '@/app.components/icons/Icon';

const typeMap = {
    lead: <FormattedMessage id="contact.type.lead" defaultMessage="Lead" />,
    vendor: (
        <span>
            <Icon icon="key" /> <FormattedMessage id="contact.type.vendor" defaultMessage="Vendor" />
        </span>
    ),
};

export default function ContactType({ type = '' }) {
    return <div className="text-muted">{typeMap[type.toLowerCase()]}</div>;
}
