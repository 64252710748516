import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { toTitle } from '../../app.utils/services/String';
import FormAction from './FormAction';

type Props = {
    type: string;
    action: () => void;
    isArchived: boolean;
    confirmation?: FixMeType;
    unArchiveConfirmation?: object | string;
};

function getActionButtonLabel(type, isArchived) {
    if (type === 'property') {
        if (isArchived) {
            return <FormattedMessage id="common.actions.reactivate_property" defaultMessage="Reactivate" />;
        }

        return <FormattedMessage id="common.actions.archive_property" defaultMessage="Archive" />;
    }

    const titleType = (
        <FormattedMessage
            id={`contact.type.${type.toLowerCase()}`}
            defaultMessage={toTitle(type)}
            tagName={React.Fragment}
        />
    );

    if (isArchived) {
        return (
            <FormattedMessage
                id="common.actions.reactivate_item"
                defaultMessage="Reactivate {titleType}"
                values={{
                    titleType,
                }}
            />
        );
    }

    return (
        <FormattedMessage
            id="common.actions.archive_item"
            defaultMessage="Archive {titleType}"
            values={{
                titleType,
            }}
        />
    );
}

const ArchiveAction = ({ type, action, isArchived, confirmation, unArchiveConfirmation }: Props) => {
    const label = getActionButtonLabel(type, isArchived);

    return (
        <FormAction
            confirmation={isArchived ? unArchiveConfirmation : confirmation}
            title={<FormattedMessage id="common.archive_panel.title" defaultMessage="Archive" />}
            variant={isArchived ? 'info' : 'ghost-danger'}
            label={label}
            onClick={action}
        />
    );
};

export default ArchiveAction;
