import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { resetMessages } from '@/app.redux/actions';
import { validateCode } from '@/app.redux/actions/AuthActions';
import LoginSentForm from '../../app.domains/auth/login/LoginSentForm';
import { getCurrentMessages } from '../../app.redux/selectors';

const mapActionsToProps = { validateCode, resetMessages };
const mapStateToProps = createStructuredSelector({
    messages: getCurrentMessages,
});

// @ts-ignore
export default connect(mapStateToProps, mapActionsToProps)(LoginSentForm);
