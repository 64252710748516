import React, { useMemo } from 'react';
import { Map } from 'immutable';
import { useField } from 'formik';
import groupBy from 'lodash/groupBy';
import { FormattedMessage } from 'react-intl-sweepbright';
import Input from '@/app.components/forms/Input/Input';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import legalFields from '@/app.data/legalFields.json';
import useOffice from '@/app.hooks/useOffice';
import SelectInput from '@/app.components/forms/SelectInput';
import MultilingualInput from '@/app.components/forms/MultilingualInput';
import RadioGroupInput from '@/app.components/forms/Radio/RadioGroupInput';
import RadioListInput from '@/app.components/forms/Radio/RadioListInput';
import { rulesMatcher } from '@/app.utils/services/Fields/rulesMatcher';

type LegalFieldDescriptor = {
    id: string;
    label: string;
    placeholder?: string;
} & (
    | {
          type: 'text';
      }
    | { type: 'select'; options?: Array<{ value: string; label: string }>; unit?: string; clearable: boolean }
    | { type: 'radio-group'; options?: Array<{ value: string; label: string }> }
    | {
          type: 'number';
          unit?: string;
          valueIsObject?: boolean;
          unitPosition?: 'before' | 'after';
      }
    | {
          type: 'autosize-textarea';
          multilingual: boolean;
      }
    | { type: 'date' }
);

const LegalInfoPanel: React.FunctionComponent<{
    property: Map<string, any>;
}> = ({ property }) => {
    const applicableFields = useMemo(() => {
        const propertyRepository = new EstateRepository(property);
        const context = propertyRepository.getVisibilityContext();

        return (legalFields as LegalFieldDescriptor[]).filter(
            ({ id }) => !!rulesMatcher.getSchema(context, `attributes.${id}`),
        );
    }, [property]);

    const fieldsPerSection: Record<string, LegalFieldDescriptor[]> = groupBy(applicableFields, field =>
        field?.subId ? field.subId.split('.')[1] : field.id.split('.')[1],
    );

    return (
        <>
            {Object.keys(fieldsPerSection).map(section => {
                const fieldsInSection = fieldsPerSection[section];
                const isLegalMentionsSection = fieldsInSection.every(
                    field => field.type === 'autosize-textarea' && field.multilingual,
                );

                return (
                    <FormPanel
                        key={section}
                        horizontal={!isLegalMentionsSection && section !== 'property_and_land'}
                        title={
                            <FormattedMessage
                                id={`property.legal.section.${section}.title`}
                                defaultMessage={`property.legal.section.${section}.title`}
                            />
                        }
                    >
                        {fieldsInSection.map(field => {
                            const { label, type, id, ...props } = field;

                            return (
                                <LegalFieldInput
                                    horizontal
                                    key={id}
                                    id={id}
                                    name={id}
                                    label={<FormattedMessage id={label} defaultMessage={label} />}
                                    type={type}
                                    {...props}
                                />
                            );
                        })}
                    </FormPanel>
                );
            })}
        </>
    );
};

export default React.memo(LegalInfoPanel);

function LegalFieldInput({ type, name, ...props }) {
    const office = useOffice();

    const [field, meta, helpers] = useField(name);

    function onChange(evtOrValue) {
        if (evtOrValue?.target) {
            field.onChange(evtOrValue);
        } else {
            helpers.setValue(evtOrValue);
        }
    }

    switch (type) {
        case 'radio-list': {
            const { label, options } = props;

            return (
                <RadioListInput
                    name={name}
                    label={label}
                    options={options}
                    value={field.value ?? ''}
                    onChange={onChange}
                />
            );
        }
        case 'radio-group': {
            const { label, options } = props;

            return (
                <RadioGroupInput
                    name={name}
                    label={label}
                    options={options}
                    value={field.value ?? ''}
                    onChange={onChange}
                />
            );
        }
        case 'select': {
            const { label, options, clearable, sorted } = props;

            return (
                <SelectInput
                    value={field.value}
                    label={label}
                    name={name}
                    clearable={clearable}
                    onChange={onChange}
                    options={options}
                    sorted={sorted}
                    isSearchable={false}
                />
            );
        }
        default: {
            const { unit, label, multilingual, unitPosition } = props;
            if (multilingual) {
                return (
                    <MultilingualInput
                        label={label}
                        name={name}
                        type={type}
                        value={field.value}
                        onChange={field.onChange}
                    />
                );
            }

            return (
                <Input
                    horizontal
                    type={type}
                    label={label}
                    checked={field.checked}
                    onChange={onChange}
                    error={meta.error}
                    touched={meta.touched}
                    name={props.valueIsObject ? `${name}.amount` : name}
                    value={props.valueIsObject ? field?.value?.amount : field.value}
                    addonBefore={unitPosition === 'addonBefore' ? getUnitLabel(unit) : undefined}
                    addonAfter={unitPosition !== 'addonBefore' ? getUnitLabel(unit) : undefined}
                />
            );
        }
    }

    function getUnitLabel(unit: string) {
        if (unit === 'currency') {
            return office?.getIn(['settings', 'data', 'currency']);
        }

        return unit;
    }
}
