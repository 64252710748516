import { fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { FETCH_RELATED_PROPERTIES } from '@/app.redux/actions/PropertiesActions';
import { onFetchRelatedProperties } from '@/app.redux/sagas/Sagas/onFetchProperties';
import {
    CREATE_BUCKET,
    FETCH_PROPERTIES,
    FETCH_PROPERTY,
    FETCH_PROPERTY_VISITS,
    REMOVE_PROPERTY_NEGOTIATOR,
    CREATE_DOCUMENT_FOR_ESTATE,
    FETCH_ESTATE_REQUESTS,
    CANCEL_ESTATE_REQUEST,
    RESEND_ESTATE_REQUEST,
    UPDATE_ESTATE_LOOP,
} from '../actions';
import {
    onCreateBucket,
    onFetchProperties,
    onFetchProperty,
    onFetchPropertyVisits,
    onRemovePropertyNegotiator,
} from './Sagas';
import { onCreateDocument, onUpdateLoop, onFetchRequests, onCancelRequest, onResendRequest } from './Estates';

export default function* PropertiesSaga() {
    yield [
        fork(takeEvery, FETCH_PROPERTY, onFetchProperty),
        fork(takeLatest, FETCH_PROPERTIES, onFetchProperties),
        fork(takeLatest, FETCH_RELATED_PROPERTIES, onFetchRelatedProperties),
        fork(takeEvery, FETCH_PROPERTY_VISITS, onFetchPropertyVisits),
        fork(takeEvery, CREATE_BUCKET, onCreateBucket),
        fork(takeEvery, REMOVE_PROPERTY_NEGOTIATOR, onRemovePropertyNegotiator),
        fork(takeEvery, CREATE_DOCUMENT_FOR_ESTATE, onCreateDocument),
        fork(takeEvery, FETCH_ESTATE_REQUESTS, onFetchRequests),
        fork(takeEvery, CANCEL_ESTATE_REQUEST, onCancelRequest),
        fork(takeEvery, RESEND_ESTATE_REQUEST, onResendRequest),
        fork(takeEvery, UPDATE_ESTATE_LOOP, onUpdateLoop),
    ];
}
