import React from 'react';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage } from 'react-intl-sweepbright';

type Props = {
    portal: any;
    close: (evt?: any) => void;
    submitting: boolean;
    editing: boolean;
    onSubmit: (evt: React.FormEvent<HTMLFormElement>) => void;
};

const PortalConfigModalBody: React.FunctionComponent<Props> = ({
    portal,
    close,
    onSubmit,
    children,
    submitting,
    editing,
}) => (
    <form onSubmit={onSubmit}>
        <Modal.Header>
            <Modal.Title>
                {editing ? (
                    <FormattedMessage
                        id="portals.modal.edit.title"
                        defaultMessage="Edit {portal}"
                        values={{ portal: portal.name }}
                    />
                ) : (
                    <FormattedMessage
                        id="portals.modal.add.title"
                        defaultMessage="Add {portal}"
                        values={{ portal: portal.name }}
                    />
                )}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
            <Button onClick={close}>
                <FormattedMessage id="portals.modal.add.cancel" defaultMessage="Cancel" />
            </Button>
            <Button type="submit" variant="primary" disabled={submitting}>
                {editing ? (
                    <FormattedMessage id="portals.modal.edit.cta" defaultMessage="Save" />
                ) : (
                    <FormattedMessage id="portals.modal.add.cta" defaultMessage="Add" />
                )}
            </Button>
        </Modal.Footer>
    </form>
);

PortalConfigModalBody.defaultProps = {
    onSubmit: event => event.preventDefault(),
};

export default PortalConfigModalBody;
