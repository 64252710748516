import React from 'react';
import { PROPERTIES } from '@/app.routing/routes';

function HomePage({ router }) {
    // redirect to the new or old properties page
    React.useEffect(() => {
        router.replace(PROPERTIES);
    }, [router]);

    return null;
}

export default HomePage;
