import React, { FC } from 'react';
import { Link } from 'react-router';
import { EstatePublicationError, EstatePublicationWarning } from '@/graphql/generated/types';
import { PROPERTY_UNIT_DETAILS } from '@/app.routing';
import { FormattedMessage } from 'react-intl-sweepbright';
import { PublicationErrorText } from './PublicationErrorText';
import { PublicationErrorDetailsItemLayout } from './PublicationErrorDetailsItemLayout';

interface UnitPublicationErrorsListProps {
    type: 'error' | 'warning';
    errors: EstatePublicationError[] | EstatePublicationWarning[];
    unitId: string;
    projectId: string;
}

export const UnitPublicationErrorsList: FC<UnitPublicationErrorsListProps> = ({ type, errors, unitId, projectId }) => {
    const unitUrl = PROPERTY_UNIT_DETAILS(projectId, unitId);

    return (
        <PublicationErrorDetailsItemLayout type={type}>
            <div>
                <Link to={unitUrl}>
                    <FormattedMessage id="publish.unit_error.label.view_unit" defaultMessage="View Unit" />
                </Link>
            </div>
            {errors.map((error, idx) => (
                <div key={idx}>
                    <PublicationErrorText error={error} />
                </div>
            ))}
        </PublicationErrorDetailsItemLayout>
    );
};
