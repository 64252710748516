import React, { useContext } from 'react';
import DataTable from '@/app.components/table/DataTable/DataTable';
import ResourceLoading from '../ResourceLoading';
import { ResourceContext } from '../utils';

const ResourceTable = () => {
    const { columns, entities, tableWrapperHeight } = useContext(ResourceContext);

    return (
        <div
            className="overflow-y-auto h-full"
            {...(tableWrapperHeight ? { style: { maxHeight: tableWrapperHeight } } : {})}
        >
            {entities.length > 0 && (
                <DataTable hover columns={columns} dataSource={entities} className="c-data-table" />
            )}

            <ResourceLoading />
        </div>
    );
};

export default ResourceTable;
