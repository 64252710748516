//@ts-nocheck
import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { Map, Set as ImmutableSet, fromJS } from 'immutable';
import { WithRouterProps } from 'react-router';
import moment from 'moment';
import { reduxForm as form, InputProps } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import Loadable from 'react-loadable';
import uuid from 'uuid/v4';
import withHooks from 'hook-hoc';
import { useIntl } from 'react-intl';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl-sweepbright';
import { withToasts, useToasts } from '@sweepbright/notifications';
import { FormFields, ComposedDate, ComposedDateField } from '@sweepbright/webapp-shared/utils/types';
import sortByDateDesc from '@/app.data/Helpers/sortByDateDesc';
import { PROFILE } from '@/app.routing/routes';
import { isDateEmpty, isObjectEmpty } from '@/app.utils/services/Helpers/emptyValidators';
import { validateDateRange, validateDatetime } from '@/app.utils/services/Validator';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import TimeInput from '@/app.components/forms/TimeInput';
import WithPrivateTooltip from '@/app.components/forms/WithPrivateTooltip';
import Input from '@/app.components/forms/Input/Input';
import { addedItemWithKey, removedItemWithKey } from '@/app.utils/services/Helpers';
import CurrencyAddonForOffice from '@/app.components/forms/CurrencyAddonForOffice';
import { getFeatures } from '@/app.redux/selectors/FeaturesSelector';
import useUser from '@/app.hooks/useUser';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';
import FormPane from '@/app.components/forms/FormPane';
import { useEstateNegotiator } from '@/app.domains/properties/PropertyNegotiator';
import { EstateRepository } from '@/app.utils/services';
import {
    PropertyFieldVisibility,
    usePropertyFieldsVisibility,
    usePropertyFieldsVisibilityByPrefix,
} from '@/app.utils/services/Fields/PropertyFieldVisibility';
import { NegotiatorSelector } from '@/app.components/forms/elements/NegotiatorSelector';
import { withActivePropertyConfirmation } from '@/app.utils/Decorators/withActivePropertyConfirmation';
import PropertyFormLegalEntity from '@/new.domains/properties/PropertyForm/PropertyFormLegalEntity';
import PropertyFormVisibility from '@/new.domains/properties/PropertyForm/PropertyFormVisibility';
import usePropertyVisibility from '@/app.hooks/usePropertyVisibility';
import useLegalEntities from '@/app.hooks/useLegallEntities';
import PropertyFormSettingsContacts from '@/new.domains/properties/PropertyForm/PropertyFormSettings/PropertyFormSettingsContacts';
import ArchiveEstateSection from '../../../../app.domains/properties/components/ArchiveEstateSection';

const OpenHomeList = Loadable({
    loader: () => import(/* webpackChunkName: "OpenHomeList" */ '@/app.domains/properties/OpenHomeList'),
    loading({ pastDelay }) {
        if (pastDelay) {
            return (
                <div>
                    <FormattedMessage id="general.state.loading" defaultMessage="Loading..." />{' '}
                </div>
            );
        }

        return null;
    },
});

export const validateSettings = (attributes: any) => {
    const auction = attributes.settings.auction;
    let errors = Map();

    if (attributes.settings.open_homes.length) {
        const openHomesErrors = (attributes.settings.open_homes || []).map(validateDateRange);
        if (openHomesErrors.filter(Boolean).length) {
            errors = errors.setIn(['settings', 'open_homes'], openHomesErrors);
        }
    }

    const startDate = auction && { ...auction.start_date };

    if (!isDateEmpty(startDate)) {
        const startDateErrors = validateDatetime(startDate);
        if (!isObjectEmpty(startDateErrors)) {
            errors = errors.setIn(['settings', 'auction'], {
                start_date: startDateErrors,
            });
        }
    }

    return isObjectEmpty(errors.toJS()) ? null : errors.toJS();
};

export const isValidComposedDateField = (datetime: ComposedDateField): boolean =>
    Object.values(datetime)
        .map((field: InputProps) => field.error)
        .filter(Boolean).length > 0;

type Props = {
    addContact: () => void;
    buyers: ImmutableSet<Map<string, any>>;
    buyersPagination?: Map<string, any>;
    buyersSearch?: Record<string, any>;
    errors: {
        status: string;
    };
    features: Map<string, any>;
    fetchVendors: () => void;
    fields: FormFields<{
        settings: {
            auction: {
                start_date: ComposedDate;
            };
            open_homes: Array<{
                end_date: ComposedDate;
                start_date: ComposedDate;
                id: string;
            }>;
            appointment_service_url: string;
            agency_commission: {
                fixed_fee: number;
                percentage: number;
                buyer: {
                    fixed_fee: number;
                    percentage: number;
                };
                seller: {
                    fixed_fee: number;
                    percentage: number;
                };
            };
            mandate: {
                start_date: ComposedDate;
                exclusive: boolean;
                end_date: ComposedDate;
                number: string;
            };
            office: {
                reference: string;
            };
        };
        vendors: unknown[];
        buyers: unknown[];
        status: string;
        dirty: boolean;
    }>;
    handleSubmit: () => void;
    pagination?: Map<string, any>;
    property: Map<string, any>;
    resetBuyers: () => void;
    resetBuyersPagination: () => void;
    resetPagination: () => void;
    searchPropertyBuyers: (number, Object) => void;
    setBuyersSearch: () => void;
    setVendorsSearch: () => void;
    vendorsSearch?: Record<string, any>;
    submitting: boolean;
    propertyRepository: EstateRepository;
    shouldRenderMandate: boolean;
    shouldRenderAgencyCommission: boolean;
    shouldRenderTeamSettings: boolean;
};

const PropertyDetailsSettingsPage: React.FC<Props & WithRouterProps> = props => {
    const {
        features,
        property,
        handleSubmit,
        editingDisabled,
        status,
        shouldRenderMandate,
        shouldRenderAgencyCommission,
        shouldRenderTeamSettings,
    } = props;

    const MAX_OPEN_HOMES = 10;

    const classesDatePickers = {
        labelClassName: 'col-sm-4',
        wrapperClassName: 'col-sm-5',
    };

    const intl = useIntl();
    const { addError } = useToasts();

    const [loading, setLoading] = useState(false);

    const { updatePropertyVisibility } = usePropertyVisibility();
    const { negotiator: propertyNegotiator, setNegotiator: setPropertyNegotiator } = useEstateNegotiator(
        props.property.get('id'),
    );
    const { assignLegalEntity, unassignLegalEntity } = useLegalEntities();

    const [visibility, setVisibility] = useState(props?.propertyRepository?.getVisibility());
    const [tempVisibility, setTempVisibility] = useState(props?.propertyRepository?.getVisibility());

    const [negotiator, setNegotiator] = useState(propertyNegotiator?.id || null);
    const [tempNegotiator, setTempNegotiator] = useState(propertyNegotiator?.id || null);

    const [legalEntity, setLegalEntity] = useState<string | null>(
        props?.propertyRepository?.getLegalEntity()?.id || null,
    );
    const [tempLegalEntity, setTempLegalEntity] = useState<string | null>(
        props?.propertyRepository?.getLegalEntity()?.id || null,
    );

    const canAddOpenHome = () => {
        return props.fields.settings.open_homes.length < MAX_OPEN_HOMES;
    };

    const handleAddOpenHome = () => {
        if (canAddOpenHome()) {
            const id = props.fields.settings.open_homes.length;

            props.fields.dirty.onChange(addedItemWithKey([...props.fields.dirty.value], id));
            props.fields.settings.open_homes.addField({
                id: uuid(),
            });
        }
    };

    const updateDirtyState = (entryIndex: number) => {
        // We keep a dirty field as a part
        // of the form and update it when `addField`, `deleteField`
        // operations occur.
        // We need to do this because some known bugs
        // in redux-form v5
        // Reported here:
        // - https://github.com/erikras/redux-form/issues/1543
        // - https://github.com/erikras/redux-form/issues/391

        const {
            fields: { dirty },
        } = props;

        const values = typeof dirty.value === 'object' ? Array.of(...dirty.value) : [];
        const newDirty = removedItemWithKey(values, entryIndex);
        dirty.onChange(newDirty.length ? newDirty : dirty.defaultValue);
    };

    const handleDeleteOpenHome = (listIndex: number) => {
        const {
            fields: { settings },
        } = props;

        updateDirtyState(listIndex);

        settings.open_homes.removeField(listIndex);
    };

    const renderAgencyCommission = () => {
        const { fields, propertyRepository } = props;
        const { settings } = fields;

        return (
            <FormPanel
                title={
                    <FormattedMessage
                        id="pages.properties.agency_commission_panel.title"
                        defaultMessage="Agency Commission"
                    />
                }
                horizontal
            >
                <PropertyFieldVisibility
                    property={propertyRepository}
                    path="attributes.settings.agency_commission.fixed_fee"
                >
                    <Input
                        type="price"
                        label={
                            <WithPrivateTooltip>
                                <FormattedMessage
                                    id="pages.properties.agency_commission_panel.fixed_fee_label"
                                    defaultMessage="Fixed Fee"
                                />
                            </WithPrivateTooltip>
                        }
                        {...classesDatePickers}
                        {...settings.agency_commission.fixed_fee}
                        decimalScale={2}
                        addonAfter={<CurrencyAddonForOffice />}
                        horizontal
                    />
                </PropertyFieldVisibility>
                <PropertyFieldVisibility
                    property={propertyRepository}
                    path="attributes.settings.agency_commission.percentage"
                >
                    <Input
                        type="price"
                        label={
                            <WithPrivateTooltip>
                                <FormattedMessage
                                    id="forms.labels.agency.commission.type.percentage"
                                    defaultMessage="Percentage"
                                />
                            </WithPrivateTooltip>
                        }
                        {...classesDatePickers}
                        {...settings.agency_commission.percentage}
                        decimalScale={2}
                        addonAfter="%"
                        horizontal
                    />
                </PropertyFieldVisibility>
                <PropertyFieldVisibility
                    property={propertyRepository}
                    path="attributes.settings.agency_commission.buyer.fixed_fee"
                >
                    <Input
                        type="price"
                        label={
                            <FormattedMessage
                                defaultMessage="Buyer Fixed Fee"
                                id={`forms.labels.agency_commission.buyer.fixed_fee_label.${propertyRepository.getNegotiation()}`}
                            />
                        }
                        {...classesDatePickers}
                        {...settings.agency_commission.buyer.fixed_fee}
                        decimalScale={2}
                        addonAfter={<CurrencyAddonForOffice />}
                        horizontal
                    />
                </PropertyFieldVisibility>
                <PropertyFieldVisibility
                    property={propertyRepository}
                    path="attributes.settings.agency_commission.buyer.percentage"
                >
                    <Input
                        type="price"
                        label={
                            <FormattedMessage
                                defaultMessage="Buyer Percentage"
                                id={`forms.labels.agency_commission.buyer.percentage_label.${propertyRepository.getNegotiation()}`}
                            />
                        }
                        {...classesDatePickers}
                        {...settings.agency_commission.buyer.percentage}
                        decimalScale={2}
                        addonAfter="%"
                        horizontal
                    />
                </PropertyFieldVisibility>
                <PropertyFieldVisibility
                    property={propertyRepository}
                    path="attributes.settings.agency_commission.seller.fixed_fee"
                >
                    <Input
                        type="price"
                        label={
                            <WithPrivateTooltip>
                                <FormattedMessage
                                    id="forms.labels.agency_commission.owner.fixed_fee_label"
                                    defaultMessage="Vendor Fixed Fee"
                                />
                            </WithPrivateTooltip>
                        }
                        {...classesDatePickers}
                        {...settings.agency_commission.seller.fixed_fee}
                        decimalScale={2}
                        addonAfter={<CurrencyAddonForOffice />}
                        horizontal
                    />
                </PropertyFieldVisibility>
                <PropertyFieldVisibility
                    property={propertyRepository}
                    path="attributes.settings.agency_commission.seller.percentage"
                >
                    <Input
                        type="price"
                        label={
                            <WithPrivateTooltip>
                                <FormattedMessage
                                    id="forms.labels.agency_commission.owner.percentage_label"
                                    defaultMessage="Vendor Percentage"
                                />
                            </WithPrivateTooltip>
                        }
                        {...classesDatePickers}
                        {...settings.agency_commission.seller.percentage}
                        decimalScale={2}
                        addonAfter="%"
                        horizontal
                    />
                </PropertyFieldVisibility>
            </FormPanel>
        );
    };

    const renderMandate = () => {
        const { settings } = props.fields;
        const { propertyRepository } = props;

        return (
            <FormPanel
                title={
                    <WithPrivateTooltip>
                        <FormattedMessage id="pages.properties.mandate_panel.title" defaultMessage="Mandate" />
                    </WithPrivateTooltip>
                }
                horizontal
            >
                <PropertyFieldVisibility path="attributes.settings.mandate.number" property={propertyRepository}>
                    <Input
                        type="text"
                        label={
                            <FormattedMessage
                                id="pages.properties.mandate_panel.number_label"
                                defaultMessage="Number"
                            />
                        }
                        {...classesDatePickers}
                        {...settings.mandate.number}
                        horizontal
                    />
                </PropertyFieldVisibility>

                <PropertyFieldVisibility path="attributes.settings.mandate.start_date" property={propertyRepository}>
                    <Input
                        type="date"
                        label={
                            <FormattedMessage
                                id="pages.properties.mandate_panel.start_date_label"
                                defaultMessage="Start Date"
                            />
                        }
                        {...classesDatePickers}
                        {...settings.mandate.start_date}
                    />
                </PropertyFieldVisibility>

                <PropertyFieldVisibility path="attributes.settings.mandate.end_date" property={propertyRepository}>
                    <Input
                        type="date"
                        label={
                            <FormattedMessage
                                id="pages.properties.mandate_panel.end_date_label"
                                defaultMessage="End Date"
                            />
                        }
                        {...classesDatePickers}
                        {...settings.mandate.end_date}
                    />
                </PropertyFieldVisibility>

                <PropertyFieldVisibility path="attributes.settings.mandate.exclusive" property={propertyRepository}>
                    <Input
                        type="checkbox"
                        label={
                            <FormattedMessage
                                id="pages.properties.mandate_panel.exclusive_label"
                                defaultMessage="Exclusive"
                            />
                        }
                        {...settings.mandate.exclusive}
                    />
                </PropertyFieldVisibility>
            </FormPanel>
        );
    };

    const hideSchedulingSettings = () => {
        return props.features.get('properties.visitScheduler.enabled');
    };

    const renderAuction = () => {
        const { features, fields } = props;

        const auction = features.get('openhome-auction') && fields.settings.auction.start_date;

        if (hideSchedulingSettings()) {
            return null;
        }

        return (
            <FormPanel
                title={<FormattedMessage id="pages.properties.auction_panel.title" defaultMessage="Auction date" />}
                horizontal
                className={classnames({ 'has-error': isValidComposedDateField(auction) })}
            >
                <Input
                    type="date"
                    label={<FormattedMessage id="pages.properties.auction_panel.date_label" defaultMessage="Date" />}
                    {...classesDatePickers}
                    {...auction.date}
                    minDate={moment()}
                />
                <Input
                    label={
                        <FormattedMessage
                            id="pages.properties.auction_panel.start_time_label"
                            defaultMessage="Start Time"
                        />
                    }
                    {...classesDatePickers}
                >
                    <TimeInput fields={{ hour: auction.hour, minutes: auction.minutes }} />
                </Input>
            </FormPanel>
        );
    };

    const renderVendors = () => {
        return (
            <PropertyFormSettingsContacts
                type="owner"
                property={props.property}
                onChange={props.handleSubmit}
                disabled={props.editingDisabled}
            />
        );
    };

    const renderBuyers = () => {
        return (
            <PropertyFormSettingsContacts
                type="buyer"
                property={props.property}
                onChange={props.handleSubmit}
                disabled={props.editingDisabled}
            />
        );
    };

    const renderTeamSettings = () => {
        const { fields, features, propertyRepository, editingDisabled } = props;
        const { settings } = fields;
        let appointmentServiceUrlFieldHelp;

        const classes = {
            labelClassName: 'col-sm-4',
            wrapperClassName: 'col-sm-9',
        };

        if (!settings.appointment_service_url.error) {
            appointmentServiceUrlFieldHelp = (
                <FormattedHTMLMessage
                    id="forms.help.estate.appointment_service_url"
                    defaultMessage={
                        '<p>The Calendar link set in <a href="{profile}">Your Profile</a> will be used as default if no specific link is entered above. <a href="{help}" target="_blank" rel="noopener noreferrer">Learn more about scheduling integrations in Help & Learning.</p>'
                    }
                    values={{
                        profile: PROFILE,
                        help:
                            'http://get.sweepbright.help/integrations/add-your-calendly-or-youcanbookme-link-to-your-listings',
                    }}
                />
            );
        }

        return (
            <FormPanel
                title={
                    <FormattedMessage id="pages.properties.team_settings_panel.title" defaultMessage="Team Settings" />
                }
                horizontal
            >
                <PropertyFieldVisibility property={propertyRepository} path="visibility">
                    <PropertyFormVisibility
                        disabled={editingDisabled}
                        property={propertyRepository}
                        onChange={setTempVisibility}
                        isProject={props.property.get('isProject')}
                    />
                </PropertyFieldVisibility>

                <PropertyFieldVisibility property={propertyRepository} path="attributes.settings.office.negotiator">
                    <PropertyNegotiator
                        disabled={editingDisabled}
                        value={tempNegotiator}
                        propertyId={props.property.get('id')}
                        officeId={propertyRepository.getOfficeId()}
                        onChange={setTempNegotiator}
                    />
                </PropertyFieldVisibility>

                <PropertyFormLegalEntity
                    disabled={editingDisabled}
                    property={propertyRepository}
                    onChange={setTempLegalEntity}
                />

                <PropertyFieldVisibility property={propertyRepository} path="attributes.settings.office.reference">
                    <Input
                        type="text"
                        label={
                            <WithPrivateTooltip>
                                <FormattedMessage
                                    id="pages.properties.team_settings_panel.reference_label"
                                    defaultMessage="Reference"
                                />
                            </WithPrivateTooltip>
                        }
                        {...classes}
                        {...settings.office.reference}
                    />
                </PropertyFieldVisibility>
                {features.get('integrations-calendar') && (
                    <PropertyFieldVisibility
                        property={propertyRepository}
                        path="attributes.settings.appointment_service_url"
                    >
                        <Input
                            type="text"
                            {...classes}
                            {...settings.appointment_service_url}
                            label={
                                <FormattedMessage
                                    id="forms.labels.estate.appointment_service_url"
                                    defaultMessage="Calendar"
                                />
                            }
                            placeholder={
                                <FormattedMessage
                                    id="forms.placeholder.estate.appointment_service_url"
                                    defaultMessage="Paste link to calendar"
                                />
                            }
                            help={appointmentServiceUrlFieldHelp}
                        />
                    </PropertyFieldVisibility>
                )}
            </FormPanel>
        );
    };

    const renderOpenHomes = () => {
        if (hideSchedulingSettings()) {
            return null;
        }
        const { settings } = props.fields;
        const sortedOpenHomes = settings.open_homes
            .map((i, k) => ({ ...i, formKey: k }))
            .sort(sortByDateDesc(item => item.start_date.date.value, true));

        const openHomeAction = canAddOpenHome()
            ? {
                  action: <FormattedMessage id="pages.properties.oh_panel.action" defaultMessage="Add Open Home" />,
                  onAction: handleAddOpenHome,
              }
            : { action: 'Maximum reached' };

        return (
            <OpenHomeList
                openHomes={sortedOpenHomes}
                onDelete={handleDeleteOpenHome}
                formActionProps={openHomeAction}
            />
        );
    };

    return (
        <FormPane
            status={loading ? fromJS({ is_saving: true }) : status}
            onSubmit={async () => {
                setLoading(true);

                const requests = [
                    ...(tempVisibility !== visibility
                        ? [
                              updatePropertyVisibility({
                                  variables: {
                                      propertyId: props?.propertyRepository?.getId(),
                                      visibility: tempVisibility ? 'company' : 'team',
                                  },
                              }),
                          ]
                        : []),
                    ...(tempLegalEntity !== legalEntity
                        ? [
                              tempLegalEntity
                                  ? assignLegalEntity({
                                        variables: {
                                            input: {
                                                estateId: props?.propertyRepository?.getId(),
                                                legalEntityId: tempLegalEntity,
                                            },
                                        },
                                    })
                                  : unassignLegalEntity({
                                        variables: {
                                            input: {
                                                estateId: props?.propertyRepository?.getId(),
                                            },
                                        },
                                    }),
                          ]
                        : []),
                ];

                try {
                    await Promise.all(requests);

                    if (tempVisibility !== visibility) {
                        setVisibility(tempVisibility);
                    }

                    if (tempLegalEntity !== legalEntity) {
                        setLegalEntity(tempLegalEntity);
                    }
                } catch (error) {
                    const isForbidden = error.message.includes('403');

                    addError({
                        message: isForbidden
                            ? intl.formatMessage({ id: 'unauthorised_403' })
                            : intl.formatMessage({ id: 'general.something_went_wrong' }),
                    });
                }

                await handleSubmit();

                try {
                    if (tempNegotiator !== negotiator) {
                        await setPropertyNegotiator(tempNegotiator);

                        setNegotiator(tempNegotiator);
                    }
                } catch (error) {
                    const isForbidden = error.message.includes('403');

                    addError({
                        message: isForbidden
                            ? intl.formatMessage({ id: 'unauthorised_403.assignee' })
                            : intl.formatMessage({ id: 'general.something_went_wrong.assignee' }),
                    });
                }

                setLoading(false);
            }}
            disabled={editingDisabled || loading}
            disabledText={
                editingDisabled && <FormattedMessage id="property.status.archived" defaultMessage="Archived" />
            }
            title={<FormattedMessage id="titles.property.settings" defaultMessage="Define the settings" />}
        >
            <div className="space-y-4">
                {shouldRenderMandate && renderMandate()}
                {shouldRenderAgencyCommission && renderAgencyCommission()}
                {features.get('openhome-auction') && renderAuction()}
                {features.get('openhome-auction') && renderOpenHomes()}
                {renderVendors()}
                {!property.get('isProject') && renderBuyers()}
                {shouldRenderTeamSettings && renderTeamSettings()}
                <ArchiveEstateSection
                    estateId={property.get('id')}
                    isArchived={property.get('archived')}
                    internalType={property.get('internalType')}
                />
            </div>
        </FormPane>
    );
};

const mapStateToProps = createStructuredSelector({
    features: getFeatures,
});

function getVisibilities({
    property,
}: {
    property: Map<string, any>;
}): {
    propertyRepository: EstateRepository;
    shouldRenderMandate: boolean;
    shouldRenderAgencyCommission: boolean;
    shouldRenderTeamSettings: boolean;
} {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const propertyRepository = useMemo(() => new EstateRepository(property), [property]);

    const shouldRenderMandate =
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePropertyFieldsVisibilityByPrefix(propertyRepository, 'attributes.settings.mandate').length > 0;

    const shouldRenderAgencyCommission =
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePropertyFieldsVisibilityByPrefix(propertyRepository, 'attributes.settings.agency_commission').length > 0;

    const shouldRenderTeamSettings =
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePropertyFieldsVisibility(propertyRepository, [
            'attributes.settings.appointment_service_url',
            'attributes.settings.office.negotiator',
            'attributes.settings.office.reference',
        ]).length > 0;

    return {
        propertyRepository,
        shouldRenderMandate,
        shouldRenderAgencyCommission,
        shouldRenderTeamSettings,
    };
}

export default compose(
    withProps(props => ({
        estateId: props.params.unit || props.params.property,
    })),
    withPropertyDetails('settings'),
    withHooks(getVisibilities),
    withToasts,
    connect(mapStateToProps),
    form({
        form: 'properties/settings',
        validate: validateSettings,
        fields: [
            'dirty',
            'settings.mandate.number',
            'settings.mandate.start_date',
            'settings.mandate.end_date',
            'settings.mandate.exclusive',
            'settings.auction.start_date.date',
            'settings.auction.start_date.hour',
            'settings.auction.start_date.minutes',
            'settings.auction.start_date.datetime',
            'settings.open_homes[].id',
            'settings.open_homes[].start_date.date',
            'settings.open_homes[].start_date.hour',
            'settings.open_homes[].start_date.minutes',
            'settings.open_homes[].start_date.datetime',
            'settings.open_homes[].end_date.date',
            'settings.open_homes[].end_date.hour',
            'settings.open_homes[].end_date.minutes',
            'settings.open_homes[].end_date.datetime',
            'settings.office.reference',
            'settings.appointment_service_url',
            'settings.agency_commission.fixed_fee',
            'settings.agency_commission.percentage',
            'settings.agency_commission.buyer.fixed_fee',
            'settings.agency_commission.buyer.percentage',
            'settings.agency_commission.seller.fixed_fee',
            'settings.agency_commission.seller.percentage',
        ],
    }),
    withActivePropertyConfirmation,
)(PropertyDetailsSettingsPage);

function PropertyNegotiator({
    disabled,
    officeId,
    onChange,
    value,
}: {
    value: string;
    propertyId?: string;
    disabled?: boolean;
    officeId?: string;
    onChange: (value: string) => void;
}) {
    const user = useUser();

    const userRole = user.get('role_id');

    const isSameUser = `${user.get('id')}` === `${value}`;

    return (
        <div className="form-group flex items-center">
            <label className="form-label col-sm-4">
                <FormattedMessage
                    id="pages.properties.team_settings_panel.negotiator_label"
                    defaultMessage="Negotiator"
                />
            </label>
            <div className="col-sm-8">
                <NegotiatorSelector
                    data-testid="negotiator"
                    clearable={false}
                    officeId={officeId}
                    value={value}
                    onChange={onChange}
                    disabled={!canChangeNegotiator(userRole, isSameUser) || disabled}
                />
            </div>
        </div>
    );
}

function canChangeNegotiator(userRole: 'company-admin' | 'team-admin' | 'editor' | 'team-member', isSameUser: boolean) {
    if (userRole === 'company-admin' || userRole === 'team-admin' || userRole === 'editor') {
        return true;
    }

    if (isSameUser) {
        return true;
    }

    return false;
}
