import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { EstateType } from '@/graphql/generated/types';
import Dropdown from '@/app.components/selects/Dropdown/Dropdown';
import Icon from '@/app.components/icons/Icon';
import { useEstateArchiver } from '@/app.domains/properties/components/ArchiveEstateSection';
import { Negotiation } from '@/app.data/Settings';
import { StatusDropdownItems } from '@/app.domains/properties/StatusForm';
import { usePropertyStatus } from '@/app.hooks/usePropertyStatus';
import { ArchivePropertyMenuItem, DuplicatePropertyMenuItem } from '@/app.domains/properties/PropertyContextActions';

const PropertiesListCardAction = ({
    propertyId,
    negotiation,
    estateType,
    onLocalize,
}: {
    propertyId: string;
    negotiation: Negotiation;
    estateType: EstateType;
    onLocalize?: (propertyId: string) => void;
}) => {
    const { isArchived, toggleArchived, loading } = useEstateArchiver({ estateId: propertyId });
    const [status, setStatus] = usePropertyStatus(propertyId);

    return (
        <div
            className="flex items-center"
            onClick={(evt: React.MouseEvent<any>) => {
                evt.preventDefault();
                evt.stopPropagation();
            }}
        >
            <Dropdown id="status_dropdown" data-testid="property-status-dropdown">
                <Dropdown.Toggle
                    as="button"
                    className="bg-transparent cursor-default flex items-center focus:outline-none focus:shadow-outline rounded"
                    type="button"
                >
                    <Icon icon="options" size={24} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="pull-right">
                    <Dropdown.SubMenu
                        key="status"
                        label={
                            <FormattedMessage
                                id="property_card.context_menu.change_status"
                                defaultMessage="Change Status"
                            />
                        }
                        disabled={isArchived}
                    >
                        <StatusDropdownItems
                            status={status}
                            propertyId={propertyId}
                            negotiation={negotiation}
                            onChangeStatus={setStatus}
                        />
                    </Dropdown.SubMenu>
                    {estateType !== EstateType.Project && (
                        <DuplicatePropertyMenuItem propertyId={propertyId} isArchived={isArchived} />
                    )}
                    <ArchivePropertyMenuItem
                        disabled={loading}
                        isArchived={isArchived}
                        toggleArchived={toggleArchived}
                    />
                    {onLocalize && (
                        <Dropdown.MenuItem
                            key="localize"
                            onClick={() => {
                                onLocalize(propertyId);
                            }}
                        >
                            <FormattedMessage id="general.locate" />
                        </Dropdown.MenuItem>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default PropertiesListCardAction;
