import React from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Alert, ControlLabel } from 'react-bootstrap';
import { Icon, Input } from '@/app.components';
import { GeoTemplate } from '@/graphql/generated/types';
import Button from '@/app.components/elements/Buttons/Button';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';

type Props = {
    error?: string;
    tempEntity?: GeoTemplate;
    setTempEntity: Function;
};

const OfficeGeoModalPostcode: React.FC<Props> = props => {
    const { error, tempEntity, setTempEntity } = props;

    const intl = useIntl();

    const content = {
        title: intl.formatMessage({ id: 'office.geo.modal.postcode.title' }),
        code: intl.formatMessage({ id: 'office.geo.modal.postcode.code' }),
        add: intl.formatMessage({ id: 'office.geo.modal.postcode.add' }),
    };

    return (
        <>
            <Row>
                <Col sm={15}>
                    {error && <Alert bsStyle="danger">{error}</Alert>}

                    <Input
                        type="text"
                        label={content.title}
                        value={tempEntity?.title || ''}
                        onChange={event => {
                            setTempEntity({ ...tempEntity, title: event?.target?.value });
                        }}
                    />
                    <fieldset>
                        <ControlLabel>{content.code}</ControlLabel>

                        {(tempEntity?.postalCodes || ['']).map((el, index) => {
                            return (
                                <Input
                                    key={index}
                                    type="text"
                                    value={el || ''}
                                    onChange={event => {
                                        if (!tempEntity?.postalCodes?.length) {
                                            setTempEntity({ ...tempEntity, postalCodes: [event?.target?.value] });
                                        } else {
                                            setTempEntity({
                                                ...tempEntity,
                                                postalCodes: tempEntity.postalCodes.map((subEl, i) =>
                                                    i === index ? event?.target?.value : subEl,
                                                ),
                                            });
                                        }
                                    }}
                                    addonAfter={
                                        <ButtonIcon
                                            type="trash"
                                            variant="link"
                                            className="btn__addon"
                                            disabled={(tempEntity?.postalCodes || []).length < 2}
                                            onClick={() => {
                                                const newPostalCodes = tempEntity?.postalCodes?.filter(
                                                    (_, i) => i !== index,
                                                );

                                                setTempEntity({ ...tempEntity, postalCodes: newPostalCodes });
                                            }}
                                        />
                                    }
                                />
                            );
                        })}
                    </fieldset>
                </Col>
            </Row>
            <Row>
                <Col sm={15}>
                    <Button
                        variant="default"
                        className="pull-right"
                        onClick={() => {
                            setTempEntity({ ...tempEntity, postalCodes: [...(tempEntity?.postalCodes || ['']), ''] });
                        }}
                    >
                        <Icon icon="add" />
                        {content.add}
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default OfficeGeoModalPostcode;
