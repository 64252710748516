import AuthManager from 'auth-manager';
import CacheableCrudRequest from './CacheableCrudRequest';

const scopes = [
    'areas:read',
    'areas:write',
    'contacts:read',
    'contacts:write',
    'properties:read',
    'properties:write',
    'companies:read',
    'companies:write',
    'channels:read',
];

export type RegisterAttributes = {
    company_name: string;
    country: string;
    email: string;
    locale: string;
    enterprise: string;
    first_name: string;
    last_name: string;
    referrer: string;
};

export type LoginAttributesType = object;

export default class AuthRequests extends CacheableCrudRequest {
    constructor() {
        super();
        this.rootUrl = this.getRootUrl();
    }

    getRootUrl = () => {
        if (__CLIENT__) {
            // use proxy server
            return `${window.location.origin}/api`;
        } else {
            return `${API_PROTOCOL}://${API_URL}`;
        }
    };

    getTokenFromResponse = (response: any) => {
        AuthManager.setToken(response, response.expires_in);
        this.withBearerToken(response.access_token);

        return response;
    };

    login = (attributes: object) => {
        return this.post('/oauth/token', {
            ...attributes,
            grant_type: 'login_link',
        });
    };

    register = (attributes: Partial<RegisterAttributes>) => {
        return this.post('/oauth/register', {
            includes: ['company'],
            ...attributes,
        });
    };

    validateCode = (code: string) => {
        return this.post('/oauth/token', {
            code,
            grant_type: 'code',
            scope: scopes.join(' '),
        });
    };

    refreshToken = (refreshToken: string) => {
        return this.post('/oauth/token', {
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
        });
    };

    getClientToken() {
        return this.post('/oauth/token', {
            grant_type: 'client_credentials',
            scope: 'register',
        });
    }

    getClientTokenForBuckets() {
        return this.post('/oauth/token', {
            grant_type: 'client_credentials',
            scope: 'buckets',
        });
    }

    verifyAuthToken(token: string): Promise<{ user_id: string; company_id: string; office_id: string }> {
        return this.withBearerToken(token)
            .post('/services/token/verify', {
                token,
            })
            .then(res => res.data);
    }
}
