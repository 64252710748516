// @ts-nocheck
import React from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import Input from '@/app.components/forms/Input/Input';
import LogoUpload from '@/app.domains/company/LogoUpload';
import UploadedLogo from '@/app.domains/company/UploadedLogo';
import { FormFields } from '@sweepbright/webapp-shared/utils/types';

type Props = {
    error?: string;
    setLogoFile: (file: string) => void;
    logoUploadedAt: number;
} & FormFields<{
    logo: string;
    photoFileId: string;
    brandNameField: string;
}>;

const getUrl = (fileId: string) => {
    if (!fileId) {
        return '';
    }

    return `${STATIC_ASSETS_CDN_URL}/companies/presets/200x200/${fileId}`;
};

const BrandAndLogo = (props: Props) => {
    const [error, setError] = React.useState<string>('');
    const [uploadedAt, setUploadedAt] = React.useState<number | ''>(props.logoUploadedAt);
    const [image, setImage] = React.useState<string>(getUrl(props.photoFileId.value || props.photoFileId.initialValue));

    return (
        <FormPanel
            title={<FormattedMessage id="forms.titles.company_settings.brand_and_logo" defaultMessage="logo & brand" />}
        >
            {error ? <Alert bsStyle="danger"> {error} </Alert> : null}
            <Row>
                <Col sm={6}>
                    <Input
                        label={<FormattedMessage id="company.settings.brand_name.title" defaultMessage="Brand Name" />}
                        help={
                            <FormattedMessage
                                id="company.settings.brand_name.description"
                                defaultMessage="We use this for your website and e-mails"
                            />
                        }
                        {...props.brandNameField}
                    />
                </Col>
                <Col sm={9}>
                    {image ? (
                        <UploadedLogo
                            image={image}
                            onRemove={() => {
                                setImage('');
                                props.logo.onChange('');
                                props.setLogoFile('');
                                setUploadedAt('');
                            }}
                            uploadedAt={uploadedAt}
                        />
                    ) : (
                        <LogoUpload
                            onUpload={({ dataURL, url, file }) => {
                                setImage(dataURL);
                                props.logo.onChange(url);
                                props.setLogoFile(file);
                                setUploadedAt(Date.now());
                            }}
                            error={props.error}
                            setError={setError}
                        />
                    )}
                </Col>
            </Row>
        </FormPanel>
    );
};

export default BrandAndLogo;
