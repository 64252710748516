import { useCallback, useRef } from 'react';

interface PollingState {
    delay: number;
    iterations: number;
}

interface PollingOptions {
    initialDelay?: number;
    maxDelay?: number;
    maxIterations?: number;
    factor?: number;
}

//Number of effect calls is limited to `maxIterations` (defaults to 5).
export function useExponentialPolling(
    effect: () => void,
    { initialDelay = 500, maxDelay = 60000, maxIterations = 8, factor = 2 }: PollingOptions = {},
): [
    //startPolling
    () => void,
    //stopPolling
    () => void,
] {
    const timeoutRef = useRef<number | undefined>();

    const stopPolling = useCallback(() => {
        clearTimeout(timeoutRef.current);
    }, []);

    const callEffect = useCallback(
        ({ delay, iterations }: PollingState) => {
            effect();

            const newState: PollingState = {
                delay: Math.min(delay * factor, maxDelay),
                iterations: iterations + 1,
            };

            if (newState.iterations >= maxIterations) {
                return;
            }

            const timeout = window.setTimeout(() => callEffect(newState), newState.delay);
            //set the timeoutRef to the current timeout so we can clear it later
            timeoutRef.current = timeout;
        },
        [effect, factor, maxDelay, maxIterations],
    );

    const startPolling = useCallback(() => {
        callEffect({ delay: initialDelay, iterations: 0 });
    }, [callEffect, initialDelay]);

    return [startPolling, stopPolling];
}
