// @ts-nocheck
import React from 'react';
import { fromJS } from 'immutable';
import Shimmer from '@sweepbright/uikit/build/esm/shimmer';
import { InjectedIntl } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl-sweepbright';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import Popover from '@/app.components/elements/Popover';
import { fullName } from '@/app.utils/services/Helpers';
import { CallAction, MailAction } from '@/app.domains/contacts/ContactActions';
import Icon, { IconName } from '@/app.components/icons/Icon';
import { toTitle } from '@/app.utils/services/String';
import { PROPERTY_CONDITION_COLOR } from '@/app.data/Conditions';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import '@/app.domains/properties/PropertyFeatures.scss';
import { BuyerLead, BuyerPreference, ContactRequest } from '@/graphql/generated/types';
import Price from '@/app.components/elements/Price/Price';

export default function ContactRequestDetailsCell({ contact }: { contact: BuyerLead }) {
    const ref = React.useRef(null);
    const [showPopover, setShowPopover] = React.useState(false);

    return (
        <div>
            <ButtonIcon
                ref={ref}
                type="person-circle"
                variant="link"
                className="inline"
                onClick={() => setShowPopover(true)}
                style={{ fontSize: 18 }}
            />
            <Popover
                className="c-popover__content c-popover__content--medium p-0"
                show={showPopover}
                placement="left"
                referenceElement={ref.current}
                onClickOutside={() => {
                    setShowPopover(false);
                }}
            >
                <ErrorBoundary>
                    <ContactRequestPopupContent contact={contact} />
                </ErrorBoundary>
            </Popover>
        </div>
    );
}

const iconForPropertyType: Record<string, IconName> = {
    house: 'house',
    apartment: 'apartments',
    parking: 'parking',
    land: 'land',
    commercial: 'retail',
    office: 'office',
};

function ContactRequestPopupContent({ contact }: { contact: BuyerLead & { contactRequest: ContactRequest } }) {
    return (
        <div>
            {contact.contactRequest.preference && (
                <div className="w-full bg-gray-lighter flex items-center justify-center" style={{ height: 150 }}>
                    <Icon
                        icon={iconForPropertyType[contact.contactRequest.preference.type]}
                        className="text-5xl"
                        inline
                    />
                </div>
            )}
            <div>
                <div className="flex items-center border-bottom p-4">
                    <div className="flex-grow">
                        <div>{fullName(contact?.firstName, contact?.lastName)}</div>
                        <div className="text-muted">
                            <FormattedMessage id="company.leads.lead" defaultMessage="Lead" />
                        </div>
                    </div>
                    <div className="flex items-center">
                        {contact && <CallAction contact={fromJS(contact)} />}
                        {contact && <MailAction contact={fromJS({ ...contact, subscribed: true })} />}
                    </div>
                </div>
                {contact.contactRequest.message && <div className="py-2 px-4">{contact.contactRequest.message}</div>}
                {contact.contactRequest.preference && (
                    <div className="p-4">
                        <Shimmer isDataLoaded={true}>
                            <PreferenceDetails preference={contact.contactRequest.preference} />
                        </Shimmer>
                    </div>
                )}
            </div>
        </div>
    );
}

const PreferenceDetails: React.ComponentType<{ preference: BuyerPreference }> = injectIntl(function PreferenceFeatures({
    preference,
    intl,
}: {
    preference: BuyerPreference;
    intl: InjectedIntl;
}) {
    return (
        <div className="flex flex-col">
            {preference.condition && (
                <Feature
                    icon={`house-${preference.condition}` as IconName}
                    label={toTitle(
                        intl.formatMessage({
                            id: `general.property_condition.${preference.condition}`,
                            defaultMessage: preference.condition,
                        }),
                    )}
                    color={PROPERTY_CONDITION_COLOR[preference.condition]}
                />
            )}
            {preference.minBedrooms != null && (
                <Feature
                    icon="rooms"
                    label={
                        <FormattedMessage
                            id="preferences.min_bedrooms_number"
                            defaultMessage="Min. {number} {number, plural, one {bedroom}, other {bedrooms}}"
                            values={{ number: parseInt(preference.minBedrooms) }}
                        />
                    }
                />
            )}
            {preference.budget?.minPrice && (
                <FormattedMessage
                    id="contact.multiple_preferences.price_description.max"
                    values={{
                        max_price: <Price amount={preference.budget.maxPrice!} />,
                    }}
                />
            )}
            {preference.budget?.minPrice && (
                <FormattedMessage
                    id="contact.multiple_preferences.price_description.min"
                    values={{
                        min_price: <Price amount={preference.budget.minPrice!} />,
                    }}
                />
            )}
            {preference?.amenities && (
                <ul>
                    {/* eslint-disable-next-line react/display-name */}
                    {preference?.amenities.map(function(amenity: string) {
                        return <span key={amenity}>{amenity}</span>;
                    })}
                </ul>
            )}
            <LocationDetails locations={preference.locations} />
        </div>
    );
});

function LocationDetails({ locations }) {
    if (locations?.nodes?.length > 0) {
        return (
            <div>
                <div>
                    <FormattedMessage id="general.location" defaultMessage="Location" />:
                </div>
                {/* eslint-disable-next-line react/display-name */}
                {locations?.nodes.map(location => {
                    if (location.type === 'postal_codes') {
                        return (
                            <div key={location.id}>
                                <FormattedMessage id={`country.labels.${location.data!.country}`} /> :{' '}
                                {location.data.postalCodes.join(', ')}
                            </div>
                        );
                    }

                    return null;
                })}
            </div>
        );
    }

    return null;
}

function Feature({ label, icon, color }: { label: React.ReactNode; icon: IconName; color?: string }) {
    return (
        <div className="c-property-feature" style={{ color }}>
            <span className="c-property-feature__icon">
                <Icon icon={icon} useSvg />
            </span>
            <span className="c-property-feature__label">{label}</span>
        </div>
    );
}
