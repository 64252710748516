import bugsnag from '@bugsnag/js';
import pkg from '@/../../package.json';

const BUGSNAG_API_KEY = '917324fe195fa9886fe5df2c8cf0ab62';

// eslint-disable-next-line import/no-mutable-exports
let bugsnagClient = null;

function initBugsnag(config = {}) {
    bugsnagClient = bugsnag({
        apiKey: BUGSNAG_API_KEY,
        notifyReleaseStages: ['development', 'staging', 'production', 'testing'],
        releaseStage: APP_ENV,
        appVersion: pkg.version,
        ...config,
        beforeSend(report) {
            const responseData = report.originalError?.response?.data;

            // ignore ResizeObserver loop errors
            if (report?.errorMessage?.includes('ResizeObserver loop')) {
                return false;
            }

            if (responseData) {
                report.metaData.responseData = responseData;
            }
        },
    });
}

function getBugsnagClient() {
    if (!bugsnagClient) {
        initBugsnag();
    }

    return bugsnagClient;
}

export { initBugsnag, getBugsnagClient };
