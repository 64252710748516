import { extractData } from '@sweepbright/margaret-fetcher';
import CacheableCrudRequest from './CacheableCrudRequest';

export default class FacebookRequests extends CacheableCrudRequest {
    rootUrl = 'https://graph.facebook.com/v3.0';
    constructor() {
        super();
        this.setMiddlewares([extractData]);
    }

    facebookAccounts = () => {
        return this.withQueryParameter('limit', 100).get('me/accounts');
    };

    facebookPermissions = (facebookAccessToken: string) => {
        return this.withQueryParameter('access_token', facebookAccessToken).get('me/permissions');
    };
}
