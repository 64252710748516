import React, { useState } from 'react';
import { HelpBlock } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { useToasts } from '@sweepbright/notifications';
import Button from '@/app.components/elements/Buttons/Button';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl-sweepbright';
import withModals from '@/app.utils/Decorators/withModals';
import withConfirmation from '@/app.utils/Decorators/withConfirmation';
import { roles } from '@/app.data';
import useUser from '@/app.hooks/useUser';
import UpdateMemberRightsModal from '@/app.components/modals/UpdateMemberRightsModal';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import Input from '@/app.components/forms/Input/Input';
import { useUpdateUserRole } from '@/app.hooks/user/useUpdateRole';
import { getCurrentUserRole } from '@/graphql/queries/users/getCurrentUserRole';
import { SimpleUser } from '@/app.shared/members/types';

type Props = { member?: SimpleUser; open: any; close: any; modal: any; officeId?: string };

const MembersPageRole: React.FC<Props> = props => {
    const { member, open, close, modal, officeId } = props;

    const intl = useIntl();
    const user = useUser();
    const { addError, addSuccess } = useToasts();

    const { data } = useQuery(getCurrentUserRole);

    const [checked, setChecked] = useState(member?.role);

    const currentRole = checked
        ?.split('-')
        .map(el => {
            let arr = el.split('');
            arr[0] = arr[0].toUpperCase();

            return arr.join('');
        })
        .join('');

    const [updateRole] = useUpdateUserRole({
        officeId: officeId || '',
        userId: member?.id || '',
        // @ts-ignore
        role: currentRole,
    });

    const handleSubmit = () => {
        updateRole()
            .then(() => {
                addSuccess({ message: intl.formatMessage({ id: 'members.saved' }) });
            })
            .catch(() => {
                addError({ message: intl.formatMessage({ id: 'general.something_went_wrong' }) });
            });
    };

    const disabled =
        `${user.get('id')}` === member?.id || (data?.me?.role === 'team-admin' && member?.role === 'company-admin');

    return (
        <FormPanel
            containerClassName="mt-4"
            title={<FormattedMessage id="forms.role.title" defaultMessage="Member Rights" />}
        >
            <Input
                type="radio"
                disabled={disabled}
                value={roles.TeamMember}
                onChange={e => setChecked(e.target.value)}
                label={<FormattedMessage id="forms.role.roles.team-member" defaultMessage="Team Member" />}
                checked={checked === roles.TeamMember}
            />
            <Input
                type="radio"
                disabled={disabled}
                value={roles.Editor}
                onChange={e => setChecked(e.target.value)}
                label={<FormattedMessage id="forms.role.roles.editor" defaultMessage="Editor" />}
                checked={checked === roles.Editor}
            />
            <Input
                type="radio"
                disabled={disabled}
                value={roles.TeamAdmin}
                onChange={e => setChecked(e.target.value)}
                label={<FormattedMessage id="forms.role.roles.team-admin" defaultMessage="Team Administrator" />}
                checked={checked === roles.TeamAdmin}
            />

            <Input
                type="radio"
                value={roles.CompanyAdmin}
                onChange={e => setChecked(e.target.value)}
                disabled={disabled || data?.me?.role === 'team-admin'}
                label={<FormattedMessage id="forms.role.roles.company-admin" defaultMessage="Company Administrator" />}
                checked={checked === roles.CompanyAdmin}
            />

            <p>
                <Button
                    disabled={disabled}
                    onClick={open.bind(this, 'update-member')}
                    className="btn-block"
                    variant="primary"
                >
                    <FormattedMessage id="forms.role.cta" defaultMessage="Update Rights" />
                </Button>
            </p>
            <HelpBlock className="text-center">
                <FormattedHTMLMessage
                    id="forms.role.help"
                    defaultMessage={
                        '<a href="https://sweepbright.com/tutorials/invite-and-manage-team-members/">&nbsp;Learn more about user rights</a> &nbsp;in Help &amp; Learning.'
                    }
                />
            </HelpBlock>
            {modal(
                'update-member',
                <UpdateMemberRightsModal
                    close={() => {
                        close();
                    }}
                    onSubmit={() => {
                        handleSubmit();
                        close();
                    }}
                />,
            )}
        </FormPanel>
    );
};

export default withConfirmation(withModals(MembersPageRole));
