import { MutationTuple, useMutation } from '@apollo/react-hooks';
import { UpdatePropertyVisibilityMutation, UpdatePropertyVisibilityMutationVariables } from '@/graphql/generated/types';
import { UPDATE_PROPERTY_VISIBILITY } from '@/graphql/mutations/properties/updatePropertyVisibility';

const usePropertyVisibility = () => {
    const [updatePropertyVisibility]: MutationTuple<
        UpdatePropertyVisibilityMutation,
        UpdatePropertyVisibilityMutationVariables
    > = useMutation(UPDATE_PROPERTY_VISIBILITY, {
        update: () => {},
    });

    return {
        updatePropertyVisibility,
    };
};

export default usePropertyVisibility;
