import React from 'react';
import { fromJS } from 'immutable';
import set from 'lodash/set';
import Tabs from '@sweepbright/uikit/build/esm/tabs';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { LoadingIndicator } from '@sweepbright/uikit';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormikWithConfirmation from '@/app.components/forms/helpers/FormikWithConfirmation';
import GeneralTabPanel from '@/app.domains/contacts/contacts-form/elements/preferences/GeneralTabPanel';
import LocationTabPanel from '@/app.domains/contacts/contacts-form/elements/preferences/LocationTabPanel';
import AmenitiesTabPanel from '@/app.domains/contacts/contacts-form/elements/preferences/AmenitiesTabPanel';
import BackButton from '@/app.components/elements/Buttons/BackButton';
import { CONTACT_PREFERENCES } from '@/app.routing/routes';
import useRouter from '@/app.hooks/useRouter';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { events, track } from '@/app.utils/analytics';
import {
    GetLeadPreferenceQuery,
    GetLeadPreferenceQueryVariables,
    Negotiation,
    UpdateLeadPreferenceMutationMutation,
    UpdateLeadPreferenceMutationMutationVariables,
    UserError,
} from '@/graphql/generated/types';
import { GET_LEAD_PREFERENCE_QUERY } from '@/graphql/queries/contacts/getLeadPreference';
import { UPDATE_LEAD_PREFERENCE_MUTATION } from '@/graphql/mutations/contacts/updateLeadPreference';
import { useToasts } from '@sweepbright/notifications';
import FormPane from '../../../../app.components/forms/FormPane';

type PreferenceValues = {
    isInvestor: boolean;
    negotiation: string;
    type: Maybe<string>;
    subtypes: string[];
    condition: string;
    amenities: string[];
    minBedrooms: Maybe<number>;
    budget: Maybe<{
        minPrice?: Maybe<number>;
        maxPrice?: Maybe<number>;
    }>;
    minArea?: Maybe<number>;
    max_liveable_area?: Maybe<number>;
    min_liveable_area?: Maybe<number>;
    max_plot_area?: Maybe<number>;
    min_plot_area?: Maybe<number>;
    max_net_area?: Maybe<number>;
    min_net_area?: Maybe<number>;
    max_gross_area?: Maybe<number>;
    min_gross_area?: Maybe<number>;
};

function PreferencesFormV2(props) {
    const { route, params } = props;
    const { contact: contactId, preference: preferenceId } = params;
    const router = useRouter()!;
    const { data, loading } = useQuery<GetLeadPreferenceQuery, GetLeadPreferenceQueryVariables>(
        GET_LEAD_PREFERENCE_QUERY,
        {
            variables: {
                contactId,
                preferenceId,
            },
            fetchPolicy: 'cache-and-network',
        },
    );

    const { addError, addSuccess } = useToasts();

    const [updatePreference] = useMutation<
        UpdateLeadPreferenceMutationMutation,
        UpdateLeadPreferenceMutationMutationVariables
    >(UPDATE_LEAD_PREFERENCE_MUTATION, {
        onError(error) {
            getBugsnagClient().notify(error);
            addError({
                message: <FormattedMessage id="form.status.error" defaultMessage="Could not save" />,
            });
        },
    });

    const handleSubmit = async (values: PreferenceValues, { setErrors }) => {
        track(events.CONTACT_SAVE_PREFERENCE_BTN_CLICKED, {
            type: values.type,
        });

        const result = await updatePreference({
            variables: {
                input: {
                    contactId,
                    preferenceId,
                    isInvestor: values.isInvestor,
                    negotiation: values.negotiation.toUpperCase() as Negotiation,
                    type: values.type!,
                    subtypes: values.subtypes,
                    condition: values.condition,
                    amenities: values.amenities,
                    minBedrooms: values.minBedrooms,
                    max_liveable_area: values.max_liveable_area,
                    min_liveable_area: values.min_liveable_area,
                    max_plot_area: values.max_plot_area,
                    min_plot_area: values.min_plot_area,
                    max_net_area: values.max_net_area,
                    min_net_area: values.min_net_area,
                    max_gross_area: values.max_gross_area,
                    min_gross_area: values.min_gross_area,
                    budget: {
                        minPrice: values.budget?.minPrice,
                        maxPrice: values.budget?.maxPrice,
                    },
                },
            },
        });
        const payload = result.data?.updateLeadPreference;
        if (payload?.success) {
            addSuccess({
                message: <FormattedMessage id="preferences.updated" defaultMessage="Preference updated successfully" />,
            });
        } else {
            setErrors(parseUserErrors(payload?.userErrors ?? []));
            addError({
                message: <FormattedMessage id="form.status.error" defaultMessage="Could not save" />,
            });
        }
    };

    const editingDisabled = data?.contact?.archived;

    const preference = data?.contact?.__typename === 'Lead' ? data?.contact?.preference : null;

    return (
        <FormikWithConfirmation<PreferenceValues>
            route={route}
            initialValues={{
                negotiation: preference?.negotiation?.toLowerCase() ?? 'buy',
                isInvestor: !!preference?.isInvestor,
                budget: preference?.budget,
                condition: preference?.condition || 'poor',
                minBedrooms: preference && 'minBedrooms' in preference ? preference?.minBedrooms : undefined,
                minArea: preference && 'minArea' in preference ? preference?.minArea : undefined,
                type: preference?.type,
                subtypes: preference?.subtypes ?? [],
                amenities: preference?.amenities ?? [],
                max_liveable_area: preference?.max_liveable_area,
                min_liveable_area: preference?.min_liveable_area,
                max_plot_area: preference?.max_plot_area,
                min_plot_area: preference?.min_plot_area,
                max_net_area: preference?.max_net_area,
                min_net_area: preference?.min_net_area,
                max_gross_area: preference?.max_gross_area,
                min_gross_area: preference?.min_gross_area,
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
            skipConfirmation={editingDisabled}
        >
            {({ values, handleChange, setFieldValue, handleSubmit, isSubmitting, errors, touched }) => {
                return (
                    // @ts-ignore
                    <FormPane
                        title={
                            <FormattedMessage
                                id="pages.contacts.section.preferences.title"
                                defaultMessage="Set preferences"
                            />
                        }
                        onSubmit={handleSubmit}
                        disabled={editingDisabled || loading}
                        disabledText={<FormattedMessage id="contact.status.archived" defaultMessage="Archived" />}
                        status={fromJS({ is_saving: isSubmitting })}
                        backAction={<BackButton to={CONTACT_PREFERENCES(contactId)} />}
                    >
                        <Tabs>
                            <Tabs.TabList>
                                <Tabs.Tab>
                                    <FormattedMessage id="general.general_title" defaultMessage="General" />
                                </Tabs.Tab>
                                <Tabs.Tab>
                                    <FormattedMessage
                                        id="navigation.properties.side_menu.location"
                                        defaultMessage="Location"
                                    />
                                </Tabs.Tab>
                                <Tabs.Tab>
                                    <FormattedMessage
                                        id="preferences.amenities_conditions"
                                        defaultMessage="Amenities & Conditions"
                                    />
                                </Tabs.Tab>
                            </Tabs.TabList>
                            <Tabs.TabPanels>
                                <Tabs.TabPanel>
                                    {loading ? (
                                        <LoadingIndicator />
                                    ) : (
                                        <GeneralTabPanel
                                            values={values}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            contactId={contactId}
                                            preferenceId={preferenceId}
                                            onDeleted={() => {
                                                router.replace(CONTACT_PREFERENCES(contactId));
                                            }}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    )}
                                </Tabs.TabPanel>
                                <Tabs.TabPanel>
                                    {loading ? (
                                        <LoadingIndicator />
                                    ) : (
                                        <LocationTabPanel contactId={contactId} preferenceId={preferenceId} />
                                    )}
                                </Tabs.TabPanel>
                                <Tabs.TabPanel>
                                    {loading ? (
                                        <LoadingIndicator />
                                    ) : (
                                        <AmenitiesTabPanel {...{ values, handleChange, setFieldValue }} />
                                    )}
                                </Tabs.TabPanel>
                            </Tabs.TabPanels>
                        </Tabs>
                    </FormPane>
                );
            }}
        </FormikWithConfirmation>
    );
}

export default PreferencesFormV2;

function parseUserErrors(userErrors: UserError[]) {
    return userErrors.reduce((acc, error) => {
        if (error.field) {
            set(acc, error.field, error.message);
        }

        return acc;
    }, {});
}
