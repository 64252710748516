import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { propTypes } from 'redux-form';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl-sweepbright';
import Button from '@/app.components/elements/Buttons/Button';

import { COMPANY_MEMBERS, SUBSCRIPTION_UPGRADE } from '@/app.routing/routes';
import { SuccessAlert } from '@/app.components/elements/Alerts/Alerts';
import Email from '@/app.components/elements/Email/Email';
import FormPane from '@/app.components/forms/FormPane';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';

import Stats from './Stats';

export default class Plan extends Component {
    static propTypes = {
        ...propTypes,
        company: PropTypes.instanceOf(Map).isRequired,
        location: PropTypes.object,
        route: PropTypes.object,
        updateCompanySettings: PropTypes.func.isRequired,
    };

    isOnTrial() {
        return this.props.company.getIn(['plan', 'data', 'id']) === 'trial';
    }

    isOnExpiredTrial() {
        return this.isOnTrial() && this.props.company.get('disabled_at');
    }

    renderAlerts() {
        const { location } = this.props;

        if (location.state && location.state.success) {
            return (
                <SuccessAlert
                    title={
                        <FormattedMessage
                            id="subscription.messages.subscription_updated"
                            defaultMessage="Subscription was updated"
                        />
                    }
                    body={
                        <FormattedMessage
                            id="subscription.messages.subscription_updated.body"
                            defaultMessage="You can now enjoy SweepBright."
                        />
                    }
                />
            );
        }

        if (this.isOnExpiredTrial()) {
            return (
                <Alert bsStyle={null}>
                    <p>
                        <FormattedHTMLMessage
                            id="subscription.messages.on_expired_trial"
                            defaultMessage="Your free trial expired."
                        />
                    </p>
                    <div className="mt-4">
                        <LinkContainer to={SUBSCRIPTION_UPGRADE}>
                            <Button variant="primary" block>
                                <FormattedMessage id="subscription.upgrade.cta" defaultMessage={"Let's Upgrade"} />
                            </Button>
                        </LinkContainer>
                    </div>
                </Alert>
            );
        }

        if (this.isOnTrial()) {
            return (
                <Alert bsStyle={null}>
                    <p>
                        <FormattedMessage
                            id="subscription.messages.on_trial"
                            defaultMessage="You are on a trial plan."
                        />
                    </p>
                    <div className="mt-4">
                        <LinkContainer to={SUBSCRIPTION_UPGRADE}>
                            <Button variant="primary" block>
                                <FormattedMessage
                                    id="subscription.messages.on_trial.cta"
                                    defaultMessage="Upgrade Subscription"
                                />
                            </Button>
                        </LinkContainer>
                    </div>
                </Alert>
            );
        }

        return null;
    }

    render() {
        const { company, route } = this.props;

        return (
            <FormPane
                title={route.title}
                cols={{
                    sm: 10,
                    lg: 7,
                }}
            >
                {this.renderAlerts()}
                {!this.isOnTrial() && (
                    <FormPanel>
                        <div>
                            <Stats
                                currentCount={company.get('member_count')}
                                maxAllowed={company.get('member_allowed')}
                            />
                        </div>
                        <div className="mt-4">
                            <LinkContainer to={SUBSCRIPTION_UPGRADE}>
                                <Button variant="primary" block>
                                    <FormattedMessage
                                        id="navigation.subscription.upgrade_plan"
                                        defaultMessage="Change Subscription"
                                    />
                                </Button>
                            </LinkContainer>
                            <LinkContainer to={COMPANY_MEMBERS}>
                                <Button variant="ghost" block>
                                    <FormattedMessage
                                        id="navigation.subscription.manage_users"
                                        defaultMessage="Manage Users"
                                    />
                                </Button>
                            </LinkContainer>
                        </div>
                    </FormPanel>
                )}
                <FormPanel
                    title={
                        <FormattedMessage
                            id="forms.titles.company.subscription.downgrade_close_account"
                            defaultMessage="Downgrade / Close account"
                        />
                    }
                >
                    <p>
                        <Email
                            variant="default"
                            block
                            subject={
                                <FormattedMessage
                                    id="subscription.cancel.email.subject"
                                    defaultMessage="I would like to close my account"
                                />
                            }
                            to={['support@sweepbright.com']}
                            icon="mail"
                            linkText={
                                <FormattedMessage id="subscription.cancel.button" defaultMessage="Contact Support" />
                            }
                        />
                    </p>
                </FormPanel>
            </FormPane>
        );
    }
}
