import React, { useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import MatchList from '@/new.domains/match/MatchList';
import { ResourceDomainType } from '@/shared/resource/types';
import useOffice from '@/app.hooks/useOffice';
import useProperty from '@/app.hooks/useProperty';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import useMatchPropertyFilters from '@/new.domains/match/hooks/useMatchPropertyFilters';
import { formatContactsSearch } from '@/new.domains/channels/utils/formatContactsSearch';
import { formatContactsQuery } from '../../../../../server/domains/contacts/utils/formatContactsQuery';

type Props = {
    propertyId?: string;
    domainType: ResourceDomainType;
};

const PropertyMatch: React.FC<Props> = props => {
    const { propertyId, domainType } = props;

    const office = useOffice();
    const officeId = office.get('id');

    const propertyObject = useProperty(propertyId);

    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState<string[]>([]);

    const atlasSearch = useFeatureFlag('properties-atlas-search');
    const showAreas = useFeatureFlag('contact.preferences.areas.enabled');

    const [searchSettings, setSearchSettings] = useLocalStorage<any>(`${domainType}.filters`, {});

    const property = useMemo(() => {
        return propertyObject?.property;
    }, [propertyObject]);

    const filters = useMatchPropertyFilters(searchSettings, setSearchSettings, domainType, property?.type || 'house');

    const defaultSearchParams = {
        ...searchSettings,
        type: 'lead',
        sort_order: 'desc',
        sort_field: 'updated_at',
        noInterestPropertyId: propertyId,
    };

    const formattedSearchParams = formatContactsQuery(
        formatContactsSearch({
            page,
            officeId,
            showAreas,
            atlasSearch: atlasSearch,
            searchSettings: defaultSearchParams,
        }),
    );

    useEffect(() => {
        if (property) {
            const geo = property?.attributes?.location?.geo;
            const geoArray = geo?.latitude && geo?.longitude ? [geo?.longitude, geo?.latitude] : undefined;

            const subtype = property?.attributes?.sub_type;

            setSearchSettings(prevState => ({
                ...prevState,
                preferenceGeoPoint: geoArray,
                preference_type: property?.type,
                negotiation: property?.negotiation,
                preference_amenity: property?.attributes?.amenities,
                preference_subtype: subtype === 'none' ? undefined : subtype,
                price_point: property?.attributes?.price?.published_price?.amount,
                preference_min_condition: property?.attributes?.general_condition,
                preference_min_bedrooms: property?.attributes?.structure?.bedrooms,
                preference_postal_code: property?.attributes?.location?.postal_code,
                ...(showAreas
                    ? {
                          preferenceNetArea: property?.attributes?.structure?.net_area?.size,
                          preferencePlotArea: property?.attributes?.structure?.plot_area?.size,
                          preferenceGrossArea: property?.attributes?.structure?.gross_area?.size,
                          preferenceLiveableArea: property?.attributes?.structure?.liveable_area?.size,
                      }
                    : {
                          preferenceNetArea: undefined,
                          preferencePlotArea: undefined,
                          preferenceGrossArea: undefined,
                          preferenceLiveableArea: undefined,
                      }),
            }));
        }
    }, [property, showAreas, setSearchSettings]);

    return (
        <MatchList
            page={page}
            setPage={setPage}
            filters={filters}
            selected={selected}
            domainType={domainType}
            propertyId={propertyId}
            setSelected={setSelected}
            searchSettings={searchSettings}
            setSearchSettings={setSearchSettings}
            defaultSearchParams={defaultSearchParams}
            formattedSearchParams={formattedSearchParams}
        />
    );
};

export default PropertyMatch;
