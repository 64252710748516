import { orderBy } from 'lodash';
import { PropertyType, roomsOrderPerType as iRoomsOrderPerType } from '@/app.data';
import { Room, RoomsSyncCommand } from './types';

const roomsOrderPerType = iRoomsOrderPerType.toJS();

/**
 * Applies commands generated by `RoomsSyncCommandSource` to a plain array of rooms, returning new array of rooms
 *
 * @param propertyType
 * @param initialRooms
 * @param commands add/remove commands
 * @returns array of rooms after add/remove commands applied and ordinals recalculated, ordered by ordinal
 */
export function aggregateRoomsSyncCommands(
    propertyType: PropertyType,
    initialRooms: Room[],
    commands: RoomsSyncCommand[],
) {
    const roomsAfterCommandsApplied = commands.reduce((rooms, command) => {
        switch (command.type) {
            case 'add': {
                return [
                    ...rooms,
                    {
                        ...command.payload,
                        size: '',
                    },
                ];
            }
            case 'remove': {
                const index = rooms.findIndex(
                    room => room.type === command.payload.type && room.size === command.payload.size,
                );
                if (index === -1) {
                    return rooms;
                }

                return [...rooms.slice(0, index), ...rooms.slice(index + 1)];
            }
        }
    }, initialRooms);

    const roomsReordered = sortByOrderRules(propertyType, roomsAfterCommandsApplied);

    return recalculateOrdinals(roomsReordered);
}

function sortByOrderRules(propertyType: PropertyType, rooms: Room[]): Room[] {
    const order: string[] = roomsOrderPerType[propertyType];

    return orderBy(rooms, [(room: Room) => order.indexOf(room.type)]);
}

function recalculateOrdinals(rooms: Room[]) {
    return rooms.map((room, i) => ({ ...room, ordinal: i }));
}
