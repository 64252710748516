import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl-sweepbright';
import useLegalEntities from '@/app.hooks/useLegallEntities';
import useOffice from '@/app.hooks/useOffice';
import isScrollEnd from '@/utils/isScrollEnd';

type Props = {
    value: string[];
    firstEntity?: any;
    onChange: (newValue: string[]) => void;
};

const FilterLegalEntitySelect: React.FC<Props> = props => {
    const { value, onChange, firstEntity } = props;

    const [scrolled, setScrolled] = useState(false);

    const office = useOffice();
    const officeId = office.get('id');

    const { data, loading, fetchMore } = useLegalEntities(officeId);

    useEffect(() => {
        if (scrolled) {
            fetchMore();
        }
    }, [scrolled, fetchMore]);

    const filteredData = data?.office?.legalEntities?.edges?.map(el => {
        return {
            value: el.node.id,
            label: el.node.name,
        };
    });

    const isFirstEntity =
        value && value.length > 0 && firstEntity && filteredData?.find(el => el.value === firstEntity.id);

    const options = isFirstEntity
        ? filteredData
        : [...(firstEntity ? [{ value: firstEntity?.id, label: firstEntity?.name }] : []), ...(filteredData || [])];

    return (
        <Select
            allowClear
            showSearch
            value={value}
            mode="multiple"
            loading={loading}
            options={options}
            onChange={onChange}
            optionFilterProp="label"
            maxTagCount="responsive"
            className="filter-geo-select"
            onPopupScroll={event => {
                setScrolled(isScrollEnd(event));
            }}
            placeholder={
                loading ? (
                    <FormattedMessage
                        id="pages.properties.team_settings_panel.legal_entity.loading"
                        defaultMessage="Loading legal entities..."
                    />
                ) : (
                    <FormattedMessage
                        id="pages.properties.team_settings_panel.legal_entity.placeholder"
                        defaultMessage="Select..."
                    />
                )
            }
        />
    );
};

export default FilterLegalEntitySelect;
