import React from 'react';
import classNames from 'classnames';

export function ErrorBadge({ count, hideIfZeroCount = true }: { count: number; hideIfZeroCount?: boolean }) {
    if (count === 0 && hideIfZeroCount) {
        return null;
    }

    return (
        <span
            className={classNames('mx-1 px-2 text-sm font-semibold rounded-full inline-block leading-5', {
                'bg-red-500  text-white': count > 0,
                'bg-gray-lighter text-gray-600': count === 0,
            })}
        >
            {count}
        </span>
    );
}
