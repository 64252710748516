import { useQuery } from '@apollo/react-hooks';
import { GetPropertyReferenceQuery, GetPropertyReferenceQueryVariables } from '@/graphql/generated/types';
import { GET_PROPERTY_REFERENCE } from '@/graphql/queries/properties/getPropertyReference';

export const usePropertyReference = (id?: string) => {
    const { data } = useQuery<GetPropertyReferenceQuery, GetPropertyReferenceQueryVariables>(GET_PROPERTY_REFERENCE, {
        skip: !id,
        variables: { id: id || '' },
    });

    return { data };
};
