import React, { useContext } from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { PropertyFormDescriptionContext } from '@/new.domains/properties/contexts/PropertyFormDescriptionContext';
import PropertyFormDescriptionAIModal from './PropertyFormDescriptionAIModal';

const PropertyFormDescriptionAI: React.FC = () => {
    const { visible, setVisible, content, fieldName, showTranslate, handleTranslate, translateLoading } = useContext(
        PropertyFormDescriptionContext,
    );

    return (
        <>
            <div className="properties-description-ai">
                <Button
                    variant="link"
                    disabled={translateLoading}
                    onClick={() => {
                        setVisible(fieldName);
                    }}
                >
                    {content.generate}
                </Button>
                {showTranslate && (
                    <Button
                        variant="link"
                        disabled={translateLoading}
                        onClick={() => {
                            handleTranslate(fieldName);
                        }}
                    >
                        {content.translate}
                        {translateLoading && <LoadingIndicator />}
                    </Button>
                )}
            </div>

            {!!visible && <PropertyFormDescriptionAIModal />}
        </>
    );
};

export default PropertyFormDescriptionAI;
