import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { GetOfficeMembersQuery } from '@/graphql/generated/types';
import { useCompanyMembers } from '@/app.hooks/user/useCompanyMembers';
import { MembersSearchType } from '../types';

type MemberEdges = Always<Always<GetOfficeMembersQuery['office']>['members']>['edges'];

export const useMembers = (searchSettings?: MembersSearchType, skip?: boolean) => {
    const [query, setQuery] = useState('');
    const [team, setTeam] = useState('');
    const [role, setRole] = useState('');

    const [pageMembers, setPageMembers] = useState(1);

    const [members, setMembers] = useState<MemberEdges>([]);

    const { data: dataMembers, loading: loadingMembers } = useCompanyMembers(
        {
            officeId: '',
            page: pageMembers,
            query,
            limit: 20,
            ...(team && { team }),
            ...(role && { role }),
        },
        skip,
    );

    const totalCountMembers = dataMembers?.office?.companyMembers?.totalCount;

    const pageInfoMembers = dataMembers?.office?.companyMembers?.pageInfo;

    useDebounce(
        () => {
            setPageMembers(1);
            setQuery(searchSettings?.query || '');
        },
        400,
        [searchSettings?.query],
    );

    useDebounce(
        () => {
            setPageMembers(1);
            setTeam(searchSettings?.team || '');
        },
        100,
        [searchSettings?.team],
    );

    useDebounce(
        () => {
            setPageMembers(1);
            setRole(searchSettings?.role || '');
        },
        100,
        [searchSettings?.role],
    );

    useEffect(() => {
        if (!loadingMembers) {
            const newData = dataMembers?.office?.companyMembers?.edges || [];

            if (newData.length === 0 && pageMembers === 1) {
                setMembers([]);
            }

            if (newData.length > 0) {
                if (pageMembers === 1) {
                    setMembers(newData);
                }

                if (pageMembers > 1) {
                    setMembers(prevData => [...(prevData || []), ...newData]);
                }
            }
        }
    }, [pageMembers, dataMembers, loadingMembers]);

    const fetchMore = () => {
        if (pageInfoMembers?.hasNextPage && !loadingMembers) {
            setPageMembers(prevState => prevState + 1);
        }
    };

    const formattedUsers = members.map(({ node }) => node);

    return {
        error: false,
        members: formattedUsers,
        loading: loadingMembers,
        currentPage: pageMembers,
        handleFetchMore: fetchMore,
        totalCount: totalCountMembers || 0,
        hasNextPage: pageInfoMembers?.hasNextPage || false,
    };
};
