import React, { Component } from 'react';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl-sweepbright';
import { FormFields } from '@sweepbright/webapp-shared/utils/types';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import { PropertyData } from '@/app.utils/services/PatchBuilders/PropertyPatchBuilder';
import PostalCodeInput from '@/app.components/forms/Location/PostalCodeInput';
import { PropertyFieldVisibilityContext } from '@/app.data/FieldVisibilityContext';
import EstateRepository from '@/app.utils/services/Repositories/EstateRepository';
import { getLabelsForCountry, isFieldVisible, isFieldVisibleInCountry } from '../../../app.data/Translations/Location';
import { statesByCountry } from '../../../app.data/Countries';
import Input from '../Input/Input';
import CountrySelector from '../CountrySelector';

const AddressAutocomplete = React.lazy(() => import('../AddressAutocomplete'));

interface BaseLocationProps {
    onBlur?: Function;
    location: FormFields<Required<PropertyData['location']>>;
}
interface PropertyLocationProps extends BaseLocationProps {
    entityType: 'property';
    property: Map<string, any>;
}
interface OtherLocationProps extends BaseLocationProps {
    entityType: 'contact' | 'company';
}

type Props = PropertyLocationProps | OtherLocationProps;

type LocationData = {
    addition: string;
    box: string;
    city: string;
    country: string;
    district: string;
    floor: string;
    postal_code: string;
    state: { long_name: string; short_name: string };
    street: string;
    streetNumber: string;
};

class Location extends Component<Props> {
    getPropertyFieldsVisibilityContext(): PropertyFieldVisibilityContext {
        if (this.props.entityType !== 'property') {
            throw new Error('Wrong entity type');
        }
        const { property, location } = this.props;

        //Unwrap Redux form field values
        const locationValues = Object.fromEntries(Object.entries(location).map(([key, { value }]) => [key, value]));

        const propertyRepo = new EstateRepository(property.setIn(['attributes', 'location'], Map(locationValues)));

        return propertyRepo.getVisibilityContext();
    }

    getLabels() {
        return getLabelsForCountry(this.props.location.country.value);
    }

    countryHasSelectableStates() {
        const { location } = this.props;

        return Object.keys(statesByCountry).includes(location.country.value);
    }

    renderStateField() {
        const { location } = this.props;

        const localizedLabels = this.getLabels();

        if (this.countryHasSelectableStates()) {
            return (
                <Input label={localizedLabels.province_or_state} {...location.province_or_state}>
                    <Select
                        value={location.province_or_state?.value}
                        //@ts-ignore
                        onChange={({ value }: { value?: string } = {}) => {
                            // eslint-disable-next-line no-unused-expressions
                            location.province_or_state?.onChange(value!);
                        }}
                        clearable={false}
                        autoComplete={false}
                        options={statesByCountry[location.country.value]}
                        onBlur={this.props.onBlur ? () => this.props.onBlur && this.props.onBlur() : null}
                    />
                </Input>
            );
        }

        return (
            <Input
                type="text"
                label={localizedLabels.province_or_state}
                {...location.province_or_state}
                onBlur={this.props.onBlur}
            />
        );
    }

    isFieldShown = (field: string) => {
        const {
            location,
            location: {
                country: { value: country },
            },
            entityType,
        } = this.props;

        //if a field descriptior is not passed from the outside, then no reason to show a field
        if (!location?.[field]) {
            return false;
        }

        //For property locations we use more than just a country to determine if a field is visible
        if (entityType === 'property') {
            const propertyFieldsVisibilityContext = this.getPropertyFieldsVisibilityContext();

            return isFieldVisible(field, propertyFieldsVisibilityContext);
        }

        //For contact or company locations a country is enough
        return isFieldVisibleInCountry(field, country);
    };

    handleSetLocation = ({
        street,
        city,
        // eslint-disable-next-line camelcase
        postal_code,
        streetNumber,
        box,
        floor,
        addition,
        state,
        district,
    }: LocationData) => {
        this.props.location.postal_code.onChange(postal_code);
        this.props.location.street.onChange(street);
        this.props.location.street_2.onChange('');
        this.props.location.city.onChange(city);
        this.props.location.number.onChange(streetNumber);
        this.props.location.box.onChange(box);
        this.props.location.floor.onChange(floor);
        this.props.location.addition.onChange(addition);

        // set the state field
        if (this.countryHasSelectableStates()) {
            this.props.location.province_or_state.onChange(state.short_name);
        } else {
            this.props.location.province_or_state.onChange(state.long_name);
        }

        this.props.location.borough_or_district.onChange(district);
    };

    render() {
        const { location } = this.props;
        const localizedLabels = this.getLabels();

        return (
            <div>
                {this.isFieldShown('street') && (
                    <React.Suspense
                        fallback={
                            <Input
                                type="text"
                                label={localizedLabels.street}
                                {...location.street}
                                data-testid={location.street.name}
                                onBlur={this.props.onBlur}
                            />
                        }
                    >
                        <AddressAutocomplete
                            label={localizedLabels.street}
                            {...location.street}
                            onLocationSelected={this.handleSetLocation}
                            country={this.props.location.country.value}
                            data-testid={location.street.name}
                            onBlur={this.props.onBlur}
                        />
                    </React.Suspense>
                )}
                {this.isFieldShown('street_2') && (
                    <Input
                        type="text"
                        label={localizedLabels.street_2}
                        {...location.street_2}
                        data-testid={location.street_2.name}
                        onBlur={this.props.onBlur}
                    />
                )}
                <div className="grid grid-cols-1 lg:grid-cols-4 col-gap-4">
                    {this.isFieldShown('number') && (
                        <Input
                            type="text"
                            label={localizedLabels.number}
                            {...location.number}
                            data-testid={location.number.name}
                            onBlur={this.props.onBlur}
                        />
                    )}

                    {this.isFieldShown('addition') && (
                        <Input
                            type="text"
                            label={localizedLabels.addition}
                            {...location.addition}
                            data-testid={location.addition.name}
                            onBlur={this.props.onBlur}
                        />
                    )}
                    {this.isFieldShown('box') && (
                        <Input
                            type="text"
                            label={localizedLabels.box}
                            {...location.box}
                            data-testid={location.box.name}
                            onBlur={this.props.onBlur}
                        />
                    )}

                    {this.isFieldShown('floor') && (
                        <Input
                            type="text"
                            label={localizedLabels.floor}
                            {...location.floor}
                            data-testid={location.floor.name}
                            onBlur={this.props.onBlur}
                        />
                    )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 col-gap-4">
                    {this.isFieldShown('postal_code') && (
                        //@ts-ignore
                        <PostalCodeInput
                            emptyValue=""
                            label={localizedLabels.postal_code}
                            {...location.postal_code}
                            data-testid={location.postal_code.name}
                            country={location.country.value}
                            onBlur={this.props.onBlur}
                        />
                    )}
                    {this.isFieldShown('city') && (
                        <Input
                            type="text"
                            label={localizedLabels.city}
                            {...location.city}
                            data-testid={location.city.name}
                            onBlur={this.props.onBlur}
                        />
                    )}
                    {this.isFieldShown('borough_or_district') && (
                        <Input
                            type="text"
                            label={localizedLabels.borough_or_district}
                            {...location.borough_or_district}
                            data-testid={location.borough_or_district.name}
                            onBlur={this.props.onBlur}
                        />
                    )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 col-gap-4">
                    {this.isFieldShown('province_or_state') && this.renderStateField()}
                    <CountrySelector
                        {...location.country}
                        handleBlur={this.props.onBlur}
                        label={<FormattedMessage id="location.country" defaultMessage="Country" />}
                        onChange={event => {
                            location.country.onChange(event);
                            location.province_or_state.onChange('');
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default Location;
