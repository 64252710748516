import React from 'react';
import { useIntl } from 'react-intl';
import { FilterChip } from '@sweepbright/uikit/build/esm/filterchip';
import useOffice from '@/app.hooks/useOffice';
import { ResourceDomainType, ResourceFilterType } from '../types';
import ResourceFilterForm from './ResourceFilterForm';
import { formatFilter } from './utils';

type Props = {
    id: string;
    label?: string;
    prefix?: string;
    disabled?: boolean;
    setParams: Function;
    showSearch?: boolean;
    onClear?: () => void;
    size?: 'small' | 'large';
    filters: ResourceFilterType[];
    translationFirstPart?: string;
    domainType: ResourceDomainType;
    params?: { [key: string]: any };
    translationDomainType?: ResourceDomainType;
    customActiveLabel?: () => string | JSX.Element | false | null | undefined;
};

const ResourceFilter: React.FC<Props> = props => {
    const {
        id,
        label,
        prefix,
        params,
        filters,
        onClear,
        disabled,
        setParams,
        domainType,
        showSearch,
        size = 'small',
        customActiveLabel,
        translationFirstPart,
        translationDomainType,
    } = props;

    const intl = useIntl();

    const office = useOffice();
    const currency = office.getIn(['settings', 'data', 'currency'], 'EUR');

    const intlKey = translationDomainType || domainType;

    const content = {
        min: intl.formatMessage({ id: 'general.min' }),
        max: intl.formatMessage({ id: 'general.max' }),
        above: intl.formatMessage({ id: 'general.above' }),
        below: intl.formatMessage({ id: 'general.below' }),
        equal: intl.formatMessage({ id: 'general.equal' }),
        label:
            label ||
            intl.formatMessage({
                id: `${intlKey}.filter.${id}`,
            }),
        minCapitalize: intl.formatMessage({ id: 'modals.common.price_panel.min_label' }),
        maxCapitalize: intl.formatMessage({ id: 'modals.common.price_panel.max_label' }),
    };

    const chipLabel = formatFilter({
        id,
        intl,
        prefix,
        params,
        content,
        intlKey,
        filters,
        currency,
        customActiveLabel,
        translationFirstPart,
    });

    return (
        <FilterChip
            size="small"
            popupHeader={null}
            disabled={disabled}
            value={disabled ? undefined : chipLabel}
            testId={`${domainType}-${id}-filter-chip`}
            placeholder={(disabled && chipLabel) || content.label}
            renderPopupContent={({ closePopup }) => {
                return (
                    <ResourceFilterForm
                        id={id}
                        size={size}
                        prefix={prefix}
                        intlKey={intlKey}
                        content={content}
                        filters={filters}
                        showSearch={showSearch}
                        domainType={domainType}
                        onSubmit={newValues => {
                            setParams(newValues);
                            closePopup();
                        }}
                        initialValues={{
                            ...Object.fromEntries(
                                filters
                                    .filter(filter => filter.defaultValue)
                                    .map(filter => [filter.paramName, filter.defaultValue]),
                            ),
                            ...params,
                        }}
                    />
                );
            }}
            onClear={() => {
                if (onClear) {
                    onClear();
                } else {
                    setParams({
                        ...params,
                        ...Object.fromEntries(
                            filters.map(filter => {
                                if (filter.type === 'range' || filter.type === 'radio-number') {
                                    return [`min_${filter.paramName}`, undefined];
                                }

                                return [filter.paramName, filter.defaultValue || undefined];
                            }),
                        ),
                        ...Object.fromEntries(
                            filters
                                .filter(filter => filter.type === 'range' || filter.type === 'radio-number')
                                .map(filter => {
                                    return [`max_${filter.paramName}`, undefined];
                                }),
                        ),
                    });
                }
            }}
        />
    );
};

export default ResourceFilter;
