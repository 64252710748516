import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect';
import { replace } from 'react-router-redux';
import CookieService from '@/app.utils/services/CookieService';
import Loading from '@/app.components/loading/Loading';
import { LOGIN } from '../routes';
import { getIsLoggingIn, getIsloggedIn as getIsLoggedIn } from '../../app.redux/selectors';

const isAuthenticated = connectedReduxRedirect({
    authenticatingSelector: getIsLoggingIn,
    authenticatedSelector: getIsLoggedIn,
    redirectPath: (state, props) => {
        const cookieService = CookieService();
        cookieService.save('redirect_to', `${props.location.pathname}${props.location.search}`, { path: '/' });

        return LOGIN;
    },
    // the loading component makes the initial request to the server
    // for the user with the `fetchUser` action
    AuthenticatingComponent: Loading,
    redirectAction: replace,
    wrapperDisplayName: 'Authenticated',
});

export default isAuthenticated;
