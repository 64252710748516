// @ts-nocheck
import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Field } from 'formik';
import ConditionSelector from '@/app.components/forms/ConditionSelector';
import WithPrivateTooltip from '@/app.components/forms/WithPrivateTooltip';
import Modal from '@/app.components/elements/Modal';
import Button from '@/app.components/elements/Buttons/Button';
import { USER_CONDITIONS } from '@/app.data/Conditions';
import { FormattedMessage } from '@/app.utils/services/Helpers/reactIntlWrapper';
import { InputForFormik } from '@/app.components/forms/Input/Input';
import { VendorFeedbackPayload } from '@/requests/InteractionsRequests';
import { registerInteractionFeedback } from '../../app.redux/actions/InteractionActions';

type Props = {
    close: () => void;
    interactionId: string;
    onAddedFeedback: (attributes: VendorFeedbackPayload) => void;
};

const VendorInteractionFeedbackModal: React.FunctionComponent<Props> = props => {
    const { onAddedFeedback, interactionId } = props;

    const dispatch = useDispatch();

    const onSubmit = async (payload: VendorFeedbackPayload) => {
        // Create interaction
        await new Promise((resolve, reject) => {
            dispatch(registerInteractionFeedback({ interactionId, payload, resolve, reject }));
        });
        onAddedFeedback(payload);
        props.close();
    };

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                type: 'lead_requested_info',
                internal_comments: '',
            }}
            render={({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Field name="type">
                                {({ field }) => <ConditionSelector field={field} type={USER_CONDITIONS} icon="face" />}
                            </Field>
                            <InputForFormik
                                name="internal_comments"
                                type="autosize-textarea"
                                rows={5}
                                label={
                                    <WithPrivateTooltip>
                                        <FormattedMessage id="misc.comment" defaultMessage="Comment" />
                                    </WithPrivateTooltip>
                                }
                            />
                        </Modal.Body>
                        <Modal.Footer className="text-center">
                            <Button onClick={props.close}>
                                <FormattedMessage id="modals.common.actions.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button type="submit" variant="primary" data-testid="submit-btn">
                                <FormattedMessage
                                    id="modals.visit_feedback.actions.save"
                                    defaultMessage="Register Feedback"
                                />
                            </Button>
                        </Modal.Footer>
                    </form>
                );
            }}
        />
    );
};

export default VendorInteractionFeedbackModal;
