export const CREATE_BUCKET = 'buckets/CREATE_BUCKET';
export const RESEND_ESTATE_REQUEST = 'buckets/RESEND_REQUEST';

// Contacts
//////////////////////////////////////////////////////////////////////
export function createBucket(attributes: object) {
    return { type: CREATE_BUCKET, attributes } as const;
}

export function resendEstateRequest(estateId: string, requestId: string, attributes: object) {
    return { type: RESEND_ESTATE_REQUEST, estateId, requestId, attributes } as const;
}

export type BucketsAction = ReturnType<typeof createBucket | typeof resendEstateRequest>;
