import React from 'react';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import ContactLabel from '@/shared/contacts/ContactLabel';
import { useContactLabels } from '@/new.domains/contacts/hooks/useContactLabels';

type Props = {
    contactId: string;
};

const ContactsTableLabels: React.FC<Props> = props => {
    const { contactId } = props;

    const showLabels = useFeatureFlag('contact.labels.enabled');

    const { data: contactLabels } = useContactLabels(showLabels ? contactId : undefined);

    if (showLabels && contactLabels?.length) {
        return (
            <div className="contacts-table-labels">
                {contactLabels.map(el => (
                    <ContactLabel tooltip key={el.id} label={el.name} color={el.color} description={el.description} />
                ))}
            </div>
        );
    }

    return <span className="text-muted">-</span>;
};

export default ContactsTableLabels;
