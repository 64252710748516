import { QueryEstatesArgs } from '@/graphql/generated/types';
import { toStr } from '../../../utils/formatter';

export const formatPropertiesQuery = (args: QueryEstatesArgs & any) => {
    const filters = {
        query: toStr(args.query),
        type: toStr(args.filters?.type),
        status: toStr(args.filters?.status),
        officeId: toStr(args.filters?.officeId),
        maxPrice: toStr(args.filters?.maxPrice),
        features: toStr(args.filters?.features),
        minPrice: toStr(args.filters?.minPrice),
        subtypes: toStr(args.filters?.subtypes),
        condition: toStr(args.filters?.condition),
        amenities: toStr(args.filters?.amenities),
        minFloors: toStr(args.filters?.min_floors),
        maxFloors: toStr(args.filters?.max_floors),
        negotiator: toStr(args.filters?.ownership),
        visibility: toStr(args.filters?.visibility),
        negotiation: toStr(args.filters?.negotiation),
        minNetArea: toStr(args.filters?.min_net_area),
        maxNetArea: toStr(args.filters?.max_net_area),
        offerStatus: toStr(args.filters?.offerStatus),
        legalEntity: toStr(args.filters?.legalEntity),
        minBedrooms: toStr(args.filters?.min_bedrooms),
        maxBedrooms: toStr(args.filters?.max_bedrooms),
        minPlotArea: toStr(args.filters?.min_plot_area),
        maxPlotArea: toStr(args.filters?.max_plot_area),
        propertyType: toStr(args.filters?.propertyType),
        propertyState: toStr(args.filters?.propertyState),
        minGrossArea: toStr(args.filters?.min_gross_area),
        maxGrossArea: toStr(args.filters?.max_gross_area),
        negotiatorIds: toStr(args.filters?.negotiator_ids),
        minLivingRooms: toStr(args.filters?.min_living_rooms),
        maxLivingRooms: toStr(args.filters?.max_living_rooms),
        minAddressFloor: toStr(args.filters?.min_address_floor),
        maxAddressFloor: toStr(args.filters?.max_address_floor),
        minLiveableArea: toStr(args.filters?.min_liveable_area),
        maxLiveableArea: toStr(args.filters?.max_liveable_area),
        minLoiCarrezArea: toStr(args.filters?.min_loi_carrez_area),
        maxLoiCarrezArea: toStr(args.filters?.max_loi_carrez_area),
        noInterestContactId: toStr(args.filters?.noInterestContactId),
        propertyInternalType: toStr(args.filters?.property_internal_type),
    };

    const filteredFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => !!value));

    const geoFilter = args?.filters?.geo_filter;
    const postalCodes = args?.filters?.postal_codes;

    const isBothGeoFilter = Boolean(geoFilter?.length && postalCodes?.length);

    return {
        page: toStr(args.page),
        limit: toStr(args.limit),
        sortField: args.filters?.sortField,
        sortOrder: args.filters?.sortOrder,
        useAtlasSearch: toStr(args.useAtlasSearch),
        filters: isBothGeoFilter
            ? [
                  { ...filteredFilters, geoFilter: toStr(geoFilter) },
                  { ...filteredFilters, postalCodes: toStr(postalCodes) },
              ]
            : [{ ...filteredFilters, geoFilter: toStr(geoFilter), postalCodes: toStr(postalCodes) }],
    };
};
