// @ts-nocheck
import React from 'react';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { InputProps } from 'redux-form';
import { Col, FormGroup, HelpBlock } from 'react-bootstrap';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage, InjectedIntl, injectIntl } from 'react-intl-sweepbright';
import Select from '@/app.components/selects/Select/Select';
import useUser from '@/app.hooks/useUser';
import FormPanel from '../../../app.components/forms/FormPanel/FormPanel';
import { formatNegotiatorsList } from '../../../app.utils/services/Helpers/negotiators';
import InfiniteScroll from '../../../app.layouts/InfiniteScroll';
import Input from '../../../app.components/forms/Input/Input';
import { fetchCompanyAdmins, resetPagination, updateCompanySettings } from '../../../app.redux/actions';
import { getPagination, getCompany, getCompanySettings } from '../../../app.redux/selectors';

const UnassignedLeadsNegotiator = ({
    inputProps,
    fetchAdmins,
    adminPagination,
    company,
    resetPagination, // eslint-disable-line no-shadow
    intl,
}: {
    adminPagination: Map<string, any>;
    company: Map<string, any>;
    fetchAdmins: (companyId: string, attributes: Record<string, any>) => void;
    inputProps: InputProps;
    resetPagination: (paginationKey: string) => void;
    intl: InjectedIntl;
}) => {
    const user = useUser();
    const admins = formatNegotiatorsList({ negotiators: company.get('admins'), user, intl }).toJS();

    const adminInfiniteScrollProps = {
        onFetch: attributes => fetchAdmins(company.get('id'), attributes),
        currentPage: adminPagination.get('current_page', 0),
        totalPages: adminPagination.get('total_pages', 1),
        resetPagination: resetPagination.bind(null, 'company/admins'),
    };

    return (
        <FormPanel title={<FormattedMessage id="forms.leads.title" defaultMessage="Buyer Leads" />}>
            <InfiniteScroll {...adminInfiniteScrollProps} />
            <Input
                {...inputProps}
                label={<FormattedMessage id="forms.leads.notification.label" defaultMessage="Send Notification To" />}
                horizontal
                labelClassName="col-sm-6"
                wrapperClassName="col-sm-9"
            >
                <Select {...inputProps} onBlur={null} options={admins} clearable={false} />
            </Input>
            <FormGroup>
                <Col className="col-sm-9 col-sm-push-6">
                    <HelpBlock>
                        <FormattedMessage
                            id="forms.leads.notification.help"
                            defaultMessage="This person will receive a notification when a new lead arrives via the “Get Personal Updates” form on the website."
                        />
                    </HelpBlock>
                </Col>
            </FormGroup>
        </FormPanel>
    );
};

const mapActionsToProps = {
    fetchAdmins: fetchCompanyAdmins,
    getPagination,
    resetPagination,
    onSubmit: (attributes, dispatch, props) => updateCompanySettings(props.company.get('id'), attributes),
};

const mapStateToProps = createStructuredSelector({
    company: getCompany,
    adminPagination: getPagination('company/admins'),
    initialValues: getCompanySettings,
});

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(UnassignedLeadsNegotiator));
