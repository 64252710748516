import { IndexRedirect, Route } from 'react-router';
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import WebsiteLayout from '@/app.domains/channels/Website';

import * as Pages from '../allPagesComponents';

const homeRoute = (
    <Route
        path="home"
        component={Pages.ChannelsWebsiteHome}
        title={<FormattedMessage id="titles.website.home" defaultMessage="Personalise homepage" />}
    />
);

const settingsRoute = (
    <Route
        path="text"
        component={Pages.ChannelsWebsiteText}
        title={<FormattedMessage id="titles.website.text" defaultMessage="Add website descriptions" />}
    />
);
const logoRoute = (
    <Route
        path="logo"
        component={Pages.ChannelsWebsiteLogo}
        title={<FormattedMessage id="titles.website.color_theme" defaultMessage="Personalise your website" />}
    />
);

const contactRoute = (
    <Route
        path="contact-settings"
        component={Pages.ChannelsWebsiteContactSettings}
        title={<FormattedMessage id="titles.website.contact_settings" defaultMessage="Add contact details" />}
    />
);

const domainRoute = <Route path="domain" component={Pages.ChannelsWebsiteDomain} />;

const websiteRoutes = (
    <Route path="website" component={WebsiteLayout}>
        <IndexRedirect to="home" />
        {homeRoute}
        {settingsRoute}
        {logoRoute}
        {contactRoute}
        {domainRoute}
    </Route>
);

export { homeRoute, settingsRoute, logoRoute, contactRoute, domainRoute };

export default websiteRoutes;
