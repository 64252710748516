import React from 'react';
import moment from 'moment';
import { RulesMatcher } from '@sweepbright/fields-js';
import { PropertyFDL } from '@sweepbright/fields-data';
import { Avatar, Checkbox } from '@sweepbright/uikit';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import { FormattedMessage } from 'react-intl-sweepbright';
import StatusForm from '@/app.domains/properties/StatusForm';
import Price from '@/app.components/elements/Price/Price';
import { PROPERTY } from '@/app.routing/routes';
import Link from '@/app.components/elements/Link';
import { propertyCoverImage } from '@/app.utils/services/Repositories/EstateRepository';
import Area from '@/app.domains/properties/Area';
import { LastActivity } from '@/app.shared/properties/LastActivity';
import { adaptProperty } from '@/app.domains/properties/PropertyCard';
import { Estate } from '@/graphql/generated/types';
import { Icon } from '@/app.components';
import EstateMenuCell from '@/app.domains/properties/PropertiesTable/EstateMenuCell';
import { Negotiation } from '@/app.data/Settings';
import PropertyType from '@/app.domains/properties/PropertyType';
import interactionTypesMessages from '@/app.data/Interactions';
import { NegotiatorRepository } from '@/app.utils/services';
import { rulesMatcher } from '@/app.utils/services/Fields/rulesMatcher';
import PropertyImage from './PropertyShow/PropertyImage';
import { PropertySearchType } from './types';

export const renderPrice = ({
    id,
    office,
    property,
    selectEnabled,
    selected,
    setSelected,
    showPropertySharedFilter,
}: {
    id: string;
    office: any;
    property: Estate;
    selectEnabled?: boolean;
    selected?: string[];
    setSelected?: Function;
    showPropertySharedFilter?: boolean;
}) => {
    const price = getPrice();

    const content = {
        visibility: <FormattedMessage id="pages.properties.team_settings_panel.visibility" />,
        company: <FormattedMessage id="pages.properties.team_settings_panel.visibility.company" />,
    };

    const officeId = office?.get('id');

    const isShared =
        showPropertySharedFilter && property?.visibility === 'company' && officeId
            ? property?.office_id !== officeId
            : false;

    return (
        <div className="properties-list-table__price">
            {selectEnabled && (
                <Checkbox
                    name="select"
                    className="mr-2"
                    checked={(selected || []).includes(property.id)}
                    onChange={() => {
                        if (setSelected) {
                            if (selected?.includes(property.id)) {
                                setSelected(selected.filter(el => el !== property.id));
                            } else {
                                setSelected([...(selected || []), property.id]);
                            }
                        }
                    }}
                />
            )}

            <Link
                to={PROPERTY(id)}
                className="flex items-center space-x-2  no-underline text-gray-dark"
                data-testid="properties.table_view.price_cell"
            >
                <PropertyImage size="small" type={property.type} src={propertyCoverImage(property, 'thumbnail')} />

                <span style={{ width: 'max-content' }}>{price || <span className="text-muted">-</span>}</span>

                {isShared && (
                    <Tooltip
                        label={
                            <>
                                {content.visibility}: {content.company}
                            </>
                        }
                    >
                        <Icon icon="globe" size={14} style={{ fill: '#8e8e93' }} />
                    </Tooltip>
                )}
            </Link>
        </div>
    );

    function getPrice() {
        if (!property.attributes.price?.published_price?.amount) {
            return null;
        }

        const isInt = Number.isInteger(property?.attributes?.price?.published_price?.amount);

        const precisionLength = !isInt
            ? `${property?.attributes?.price?.published_price?.amount || 0}`.split('.')[1]?.length
            : 0;

        if (property.negotiation === 'sale') {
            return <Price {...property.attributes.price.published_price} precision={precisionLength} />;
        }
        if (property.negotiation === 'let') {
            return (
                <>
                    <Price {...property.attributes.price?.published_price} precision={precisionLength} />/
                    <FormattedMessage
                        id={`property.rent_period.${property?.rentPeriod || 'month'}`}
                        defaultMessage={`property.rent_period.${property?.rentPeriod || 'month'}`}
                    />
                </>
            );
        }
        throw new Error('invalid negotiation ' + property.negotiation);
    }
};

export const renderType = (id: string, property: Estate) => {
    return (
        <Link
            to={PROPERTY(id)}
            className="w-32 truncate block no-underline text-gray-dark"
            data-testid="properties.table_view.type_cell"
        >
            <PropertyType property={property} />
        </Link>
    );
};

export const renderSubtype = (id: string, property) => {
    if (property.attributes.sub_type && property.attributes.sub_type !== 'none') {
        return (
            <Link to={PROPERTY(id)} className="capitalize w-32 truncate block no-underline text-gray-dark">
                <FormattedMessage
                    id={`pages.properties.property_subtype_panel.options.${property.attributes.sub_type}`}
                    defaultMessage={`pages.properties.property_subtype_panel.options.${property.attributes.sub_type}`}
                />
            </Link>
        );
    }

    return (
        <Link to={PROPERTY(id)} className="capitalize w-32 truncate block no-underline text-gray-dark">
            <span className="text-muted">-</span>
        </Link>
    );
};

export const renderCity = (id, property) => {
    return (
        <Link
            to={PROPERTY(id)}
            className="w-32 truncate block no-underline text-gray-dark"
            data-testid="properties.table_view.city_cell"
        >
            {property?.attributes?.location?.city ?? <span className="text-muted">-</span>}
        </Link>
    );
};

export const renderStatus = (id, { negotiation }: Estate) => {
    return <StatusForm propertyId={id} negotiation={negotiation as Negotiation} />;
};

export const renderStreet = (id: string, property: Estate) => {
    const street = [property.attributes.location?.number, property.attributes.location?.street]
        .filter(Boolean)
        .join(' ');

    return (
        <Link
            to={PROPERTY(id)}
            className="max-w-xs truncate block no-underline text-gray-dark"
            title={street}
            data-testid="properties.table_view.street_cell"
        >
            {street || <span className="text-muted">-</span>}
        </Link>
    );
};

export const renderPostalCode = (id: string, property) => {
    return (
        <Link
            to={PROPERTY(id)}
            className="capitalize w-20 block no-underline text-gray-dark"
            data-testid="properties.table_view.postal_code_cell"
        >
            {property.attributes.location?.postal_code || <span className="text-muted">-</span>}
        </Link>
    );
};

export const renderLastActivity = (id: string, property: Estate) => {
    return (
        <Link to={PROPERTY(id)} className="w-48 truncate block no-underline text-gray-dark">
            <LastActivity property={adaptProperty(property)} />
        </Link>
    );
};

export const renderMenu = (estateId: string, estate) => {
    return <EstateMenuCell estateId={estateId} estateType={estate.internalType} />;
};

export const renderPublishCount = (_id: string, property: Estate) => {
    const value = property.successful_publications_count;

    return value ? (
        <span>
            {value}{' '}
            <FormattedMessage
                defaultMessage="Published"
                id={`properties_table.columns.publish.count.${value > 1 ? 'other' : 'one'}`}
            />
        </span>
    ) : (
        <span className="text-muted">-</span>
    );
};

export const renderPublishErrors = (_id: string, property: Estate) => {
    const value = property.error_publications_count;

    return value ? (
        <span className="font-semibold" style={{ color: '#ee4b41' }}>
            {value}{' '}
            <FormattedMessage
                defaultMessage="Publish Errors"
                id={`properties_table.columns.publish.errors.${value > 1 ? 'other' : 'one'}`}
            />
        </span>
    ) : (
        <span className="text-muted">-</span>
    );
};

export const renderDetails = (
    type:
        | 'mandate_number'
        | 'mandate_start_date'
        | 'mandate_end_date'
        | 'mandate_exclusive'
        | 'buyer_fixed_fee'
        | 'buyer_percentage'
        | 'seller_fixed_fee'
        | 'seller_percentage'
        | 'epc_value'
        | 'co2_emissions'
        | 'dpe'
        | 'greenhouse_emissions'
        | 'created_at'
        | 'negotiator'
        | 'liveable_area'
        | 'loi_carrez_area'
        | 'gross_area'
        | 'plot_area'
        | 'net_area'
        | 'first_publication_date',
    property: Estate | null,
    currency: string,
    intl?: ReactIntl.InjectedIntl,
    user?: Map<string, any>,
) => {
    if (type === 'mandate_number') {
        const value = property?.attributes?.settings?.mandate?.number;

        if (value) {
            return value;
        }
    }

    if (type === 'mandate_start_date' || type === 'mandate_end_date') {
        const value =
            property?.attributes?.settings?.mandate?.[type === 'mandate_start_date' ? 'start_date' : 'end_date'];

        if (value) {
            return value;
        }
    }

    if (type === 'mandate_exclusive') {
        const value = property?.attributes?.settings?.mandate?.exclusive;

        return (
            <span className="whitespace-no-wrap">
                <FormattedMessage id={`properties_table.columns.mandate_exclusive.${!!value}`} />
            </span>
        );
    }

    if (type === 'buyer_fixed_fee') {
        const value = property?.attributes?.settings?.agency_commission?.buyer?.fixed_fee;

        if (value) {
            return <Price amount={value} currency={currency} />;
        }
    }

    if (type === 'buyer_percentage') {
        const value = property?.attributes?.settings?.agency_commission?.buyer?.percentage;

        if (value) {
            return `${value} %`;
        }
    }

    if (type === 'seller_fixed_fee') {
        const value = property?.attributes?.settings?.agency_commission?.seller?.fixed_fee;

        if (value) {
            return <Price amount={value} currency={currency} />;
        }
    }

    if (type === 'seller_percentage') {
        const value = property?.attributes?.settings?.agency_commission?.seller?.percentage;

        if (value) {
            return `${value} %`;
        }
    }

    if (type === 'epc_value') {
        const value = property?.attributes?.legal?.energy?.epc_value;

        if (value) {
            return `${value} kWh/m²`;
        }
    }

    if (type === 'co2_emissions') {
        const value = property?.attributes?.legal?.energy?.co2_emissions;

        if (value) {
            return `${value} kg CO₂/m²`;
        }
    }

    if (type === 'dpe' || type === 'greenhouse_emissions') {
        const value = property?.attributes?.legal?.energy?.[type];

        if (value) {
            if (value === 'empty' || value === 'not_applicable') {
                return (
                    <span className="whitespace-no-wrap">
                        {intl?.formatMessage({
                            id: `property.legal.value.${value}`,
                        })}
                    </span>
                );
            }

            return value;
        }
    }

    if (type === 'created_at') {
        const value = property?.createdAt;

        if (value) {
            return (
                <div data-testid="created-time" className="truncate">
                    <FormattedMessage {...interactionTypesMessages.created} />{' '}
                    <time dateTime={moment(value).format('YYYY-MM-DD HH:mm')}>{moment(value).fromNow()}</time>
                </div>
            );
        }
    }

    if (type === 'first_publication_date') {
        const value = property?.first_publication_date;

        if (value) {
            return (
                <div data-testid="created-time" className="truncate">
                    <time dateTime={moment(value).format('YYYY-MM-DD HH:mm')}>
                        {moment(value).format('DD MMM YYYY HH:mm')}
                    </time>
                </div>
            );
        }
    }

    if (type === 'negotiator') {
        const value = property?.negotiator;

        const label = new NegotiatorRepository(value).getLabel({
            isCurrentUser: value?.id === `${user?.get('id')}`,
            intl,
        });

        if (label) {
            return (
                <span className="flex items-center no-underline text-gray-dark">
                    <Avatar type="sm" name={label} image={value?.photo?.data?.url} />
                    <div className="max-w-xs truncate">
                        <div className="mx-2 flex items-center">{label}</div>
                    </div>
                </span>
            );
        }
    }

    if (['liveable_area', 'gross_area', 'plot_area', 'net_area', 'loi_carrez_area'].includes(type)) {
        const value = property?.attributes?.structure?.[type];

        if (value?.size) {
            return (
                <Link
                    to={PROPERTY(property?.id || '')}
                    data-testid={`properties.table_view.${type}_cell`}
                    className="w-20 truncate no-underline text-gray-dark block"
                >
                    <Area size={value.size} units={value.units} />
                </Link>
            );
        }
    }

    return <span className="text-muted">-</span>;
};

export const getPropertiesSubtypes = (country?: string) => {
    const getSubtypesByType = (type: string) => {
        const rulesMatcher = new RulesMatcher<{
            type: string;
            country?: string;
        }>(PropertyFDL);

        const result = rulesMatcher.getSchema({ type, country: country || 'default' }, 'attributes.sub_type');

        // @ts-ignore
        return result?.items?.enum || [];
    };

    return {
        house: getSubtypesByType('house'),
        apartment: getSubtypesByType('apartment'),
        parking: getSubtypesByType('parking'),
        land: getSubtypesByType('land'),
        commercial: getSubtypesByType('commercial'),
        office: getSubtypesByType('office'),
    };
};

export const getPropertiesSubtypesGroups = () => {
    return Object.entries(getPropertiesSubtypes()).map(([groupKey, groupValues]) => {
        return {
            id: groupKey,
            sort: true,
            sortOther: true,
            label: `modals.common.types_panel.${groupKey}`,
            options: groupValues.map(el => {
                return {
                    value: el,
                    translateKey: `pages.properties.property_subtype_panel.options.${el}`,
                };
            }),
        };
    });
};

export const getPropertiesAmenities = () => {
    const getAmenitiesByType = (type: string) => {
        const rulesMatcher = new RulesMatcher<{
            type: string;
            country?: string;
            internalType?: string;
        }>(PropertyFDL);

        const result = rulesMatcher.getSchema(
            { type, country: 'default', internalType: 'standalone' },
            'attributes.amenities',
        );

        // @ts-ignore
        return result?.items?.enum || [];
    };

    return {
        house: getAmenitiesByType('house'),
        apartment: getAmenitiesByType('apartment'),
        commercial: getAmenitiesByType('commercial'),
        office: getAmenitiesByType('office'),
        parking: getAmenitiesByType('parking'),
        land: getAmenitiesByType('land'),
    };
};

export const getPropertiesAmenitiesGroups = () => {
    return Object.entries(getPropertiesAmenities()).map(([groupKey, groupValues]) => {
        return {
            id: groupKey,
            label: `modals.common.types_panel.${groupKey}`,
            options: groupValues.map(el => {
                return {
                    value: el,
                    translateKey: `property.fields.structure.${el}`,
                };
            }),
        };
    });
};

export const getPropertiesFeatures = (showNewFields?: boolean) => {
    const newFields = [
        'septic_tank',
        'accessibility',
        'triple_glazing',
        'ceiling_heating',
        'wood_stove_standalone',
        'wood_stove_insert',
        'pellets_standalone',
        'pellets_insert',
        'geothermal',
        'central_heating_building',
        'district_heating',
        'insert_pellets_stove',
        'building_central_heating',
        'individual_central_heating',
        'room_specific_heating',
    ];

    const allFields = rulesMatcher.getAllFieldsByPathPrefix('attributes.features') || [];

    const filteredFields = allFields.filter(path => {
        const fieldName = path.replace(/\w+\.\w+\.\w.+\./, '');

        return !showNewFields ? !newFields.includes(fieldName) : true;
    });

    const extractFeatures: (features: string[]) => { [key: string]: string[] } = features => {
        const featureObject = {};

        features.forEach(path => {
            const parts = path.split('.');
            if (parts.length > 2) {
                const category = parts[2]; // e.g., 'comfort', 'energy', etc.
                const detail = parts.slice(3).join('.'); // Sub features

                if (!featureObject[category]) {
                    featureObject[category] = [];
                }

                if (detail) {
                    featureObject[category].push(detail);
                }
            }
        });

        return featureObject;
    };

    return extractFeatures(filteredFields);
};

export const handleActiveAreaType = (searchSettings: PropertySearchType | undefined, options: string[]) => {
    return options.find(el => {
        const value = searchSettings?.[`min_${el}_area`] || searchSettings?.[`max_${el}_area`];

        const isNumber = typeof value === 'number' && value > 0;
        const isString = typeof value === 'string' && value.length;

        if (isNumber || isString) {
            return el
                .replace('min_', '')
                .replace('max_', '')
                .replace('_area', '');
        }

        return undefined;
    });
};

export const getPropertyPermissions = ({
    activeTeam,
    propertyTeam,
    activeUser,
    propertyUser,
    propertyVisibility,
    userRole,
}: {
    activeTeam: string;
    propertyTeam: string;
    activeUser: string;
    propertyUser: string;
    propertyVisibility: string;
    userRole: string;
}) => {
    const isSameTeam = activeTeam === propertyTeam;
    const isAssignedToUser = activeUser === propertyUser;

    if (propertyVisibility === 'company') {
        const canMemberEdit = userRole === 'member' ? isSameTeam && isAssignedToUser : true;
        const canEditorEdit = userRole === 'editor' ? isSameTeam : true;
        const canTeamAdminEdit = userRole === 'team-admin' ? isSameTeam : true;
        const canCompanyAdminEdit = userRole === 'company-admin' ? true : true;

        return {
            canView: true,
            canEdit: canMemberEdit && canEditorEdit && canTeamAdminEdit && canCompanyAdminEdit,
        };
    }

    const canMemberView = userRole === 'member' ? isSameTeam : true;
    const canEditorView = userRole === 'editor' ? isSameTeam : true;
    const canTeamAdminView = userRole === 'team-admin' ? isSameTeam : true;
    const canCompanyAdminView = userRole === 'company-admin' ? true : true;

    const canMemberEdit = userRole === 'member' ? isSameTeam && isAssignedToUser : true;
    const canEditorEdit = userRole === 'editor' ? isSameTeam : true;
    const canTeamAdminEdit = userRole === 'team-admin' ? isSameTeam : true;
    const canCompanyAdminEdit = userRole === 'company-admin' ? true : true;

    return {
        canView: canMemberView && canEditorView && canTeamAdminView && canCompanyAdminView,
        canEdit: canMemberEdit && canEditorEdit && canTeamAdminEdit && canCompanyAdminEdit,
    };
};
