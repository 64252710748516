import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { PropertyCardFragment } from '@/graphql/fragments/propertyCard';
import { updateConnection } from '@/graphql/utils';

export const DEFAULT_UNITS_FILTERS = {
    archived: false,
};

export const PROJECT_UNITS_QUERY = gql`
    query GetProjectUnits($projectId: ID!, $page: Int, $limit: Int, $archived: Boolean) {
        estate(id: $projectId) {
            id
            __typename
            units(page: $page, limit: $limit, archived: $archived) {
                nodes {
                    id
                    projectId
                    isUnit
                    isProject
                    ...PropertyCardFragment
                    __typename
                }
                pageInfo {
                    hasNextPage
                    currentPage
                }
                totalCount
            }
        }
    }
    ${PropertyCardFragment}
`;

export default function useProjectUnits(
    projectId: Maybe<string>,
    options: { limit?: number; page?: number; filters?: { archived: boolean } } = {},
    { onCompleted }: { onCompleted?: (data: any) => void } = {},
) {
    const { data, loading, fetchMore } = useQuery(PROJECT_UNITS_QUERY, {
        variables: {
            projectId: projectId!,
            limit: options.limit ?? 20,
            page: options.page ?? 1,
            archived: options.filters?.archived ?? DEFAULT_UNITS_FILTERS.archived,
        },
        skip: !projectId,
        onCompleted(data) {
            // eslint-disable-next-line no-unused-expressions
            onCompleted?.(data.estate);
        },
        onError(err) {
            getBugsnagClient().notify(err);
        },
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        errorPolicy: 'all',
    });
    const units = data?.estate?.units.nodes ?? [];

    const pageInfo = data?.estate?.units.pageInfo ?? {
        currentPage: 1,
        hasNextPage: false,
    };

    const handleFetchMore = () => {
        if (pageInfo.hasNextPage) {
            fetchMore({
                variables: {
                    page: pageInfo.currentPage + 1,
                },
                updateQuery: updateConnection('estate.units'),
            });
        }
    };

    return {
        units,
        loading,
        pageInfo,
        fetchMore: handleFetchMore,
    };
}
