import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl-sweepbright';

const NotFound = () => (
    <div className="text-center" style={{ padding: '2rem' }}>
        <i className="glyphicon glyphicon-face-01" style={{ fontSize: 150 }} />
        <h1>
            <FormattedMessage id="not_found.title" defaultMessage="Nothing to see here." />
        </h1>
        <h2>
            <Link to="/" onlyActiveOnIndex>
                <FormattedMessage id="not_found.home_link.label" defaultMessage="Click here to go back home" />
            </Link>
        </h2>
    </div>
);

export default NotFound;
