import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getStatus, getCompany, getCompanyInvoiceSettings } from '@/app.redux/selectors';
import { updateCompanySettings } from '../../app.redux/actions';
import Invoice from '../../app.domains/subscription/Invoice';

const mapStateToProps = createStructuredSelector({
    status: getStatus,
    company: getCompany,
    initialValues: getCompanyInvoiceSettings,
});

export default connect(mapStateToProps, {
    updateCompanySettings,
})(Invoice);
