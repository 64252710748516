import { put } from 'redux-saga/effects';
import { OfficesRequests } from '../../../requests/index';
import { setOfficeMembers } from '../../actions';
import withPagination from '../Helpers/withPagination';
import apiCall from '../Effects/apiCall';

export default function* onFetchOfficeMembers({ officeId, attributes }) {
    yield withPagination(`office/${officeId}/members`, attributes, function* onFetch(settings) {
        const members = yield apiCall(new OfficesRequests().members, officeId, settings);
        yield put(setOfficeMembers(officeId, members.data, members.meta.pagination.total));

        return members;
    });
}
