import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { useQuery } from '@apollo/react-hooks';
import { GET_OFFICE_CONTACTS_QUERY } from '@/graphql/queries/contacts/getContacts';
import { GetContactsQuery, GetContactsQueryVariables } from '@/graphql/generated/types';
import { updateConnection } from '@/graphql/utils';
import { ContactFilterSettings } from '@/app.domains/contacts/types';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { formatContactsSearch } from '@/new.domains/channels/utils/formatContactsSearch';

type Props = {
    skip?: boolean;
    officeId: string;
    oldLogic?: boolean;
    handlePage?: (page: number) => void;
    searchSettings?: ContactFilterSettings;
};

const useContacts = (props: Props) => {
    const { skip, officeId, oldLogic, handlePage, searchSettings } = props;

    const [page, setPage] = useState(1);
    const [data, setData] = useState<any[]>([]);

    const atlasSearch = useFeatureFlag('contacts-atlas-search');
    const showAreas = useFeatureFlag('contact.preferences.areas.enabled');

    const variables = formatContactsSearch({
        page: 1,
        officeId,
        showAreas,
        atlasSearch,
        searchSettings,
    });

    let { data: dataContacts, loading: loadingContacts, fetchMore, refetch, error } = useQuery<
        GetContactsQuery,
        GetContactsQueryVariables
    >(GET_OFFICE_CONTACTS_QUERY, {
        skip,
        variables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: oldLogic ? 'cache-and-network' : 'no-cache',
    });

    useDebounce(
        () => {
            if (!oldLogic) {
                setPage(1);
            }
        },
        oldLogic ? undefined : 100,
        [oldLogic ? undefined : searchSettings],
    );

    useEffect(() => {
        if (!oldLogic) {
            if (!loadingContacts) {
                const newData = dataContacts?.contacts?.edges || [];

                if (newData.length === 0 && page === 1) {
                    setData([]);
                }

                if (newData.length > 0) {
                    if (page === 1) {
                        setData(newData);
                    }

                    if (page > 1) {
                        setData(prevData => [...(prevData || []), ...newData]);
                    }
                }
            }
        }
    }, [page, oldLogic, dataContacts, loadingContacts]);

    useEffect(() => {
        if (!oldLogic) {
            if (error) {
                setData([]);
                setPage(1);
            }
        }
    }, [oldLogic, error]);

    useEffect(() => {
        if (handlePage) {
            handlePage(page);
        }
    }, [page, handlePage]);

    const totalCount = dataContacts?.contacts?.totalCount || 0;
    const currentPage = dataContacts?.contacts?.pageInfo?.currentPage;
    const hasNextPage = dataContacts?.contacts?.pageInfo?.hasNextPage || false;

    const handleFetchMore = () => {
        if (hasNextPage && !loadingContacts) {
            setPage(prevState => prevState + 1);
        }
    };

    const formattedData = data.map(el => el.node);

    const oldRefetch = () => {
        refetch({
            ...variables,
            page: 0,
        });
    };

    const handleOldFetchMore = useCallback(() => {
        if (!loadingContacts) {
            fetchMore({
                variables: {
                    page: (currentPage || 0) + 1,
                },
                updateQuery: updateConnection('contacts'),
            });
        }
    }, [currentPage, fetchMore, loadingContacts]);

    return {
        error,
        totalCount,
        hasNextPage,
        loading: loadingContacts,
        refetch: oldLogic ? oldRefetch : refetch,
        fetchMore: oldLogic ? handleOldFetchMore : handleFetchMore,
        data: oldLogic ? dataContacts?.contacts?.edges?.map(e => e?.node) || [] : formattedData,
    };
};

export default useContacts;
