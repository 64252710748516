import gql from 'graphql-tag';
import timeline from '@/graphql/fragments/timeline';

export const GET_PROPERTY_TIMELINE = gql`
    query GetPropertyTimeline($propertyId: ID!, $after: String, $before: String, $contactId: String) {
        estate(id: $propertyId) {
            id
            timeline {
                activities(after: $after, before: $before, contactId: $contactId) {
                    ...timelineActivities
                }
            }
        }
    }
    ${timeline}
`;
