import React from 'react';
import { Waypoint } from 'react-waypoint';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { FormattedMessage } from 'react-intl-sweepbright';
import Timeline from '@/app.components/elements/Timeline/Timeline';
import TimelineItem from '@/app.components/elements/Timeline/TimelineItem';
import { getCardForActivity } from '@/app.components/card/ActivityCard';
import EmptyState from '@/app.components/empty/EmptyState';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { useTimelineData } from '../hooks/useTimelineData';

export default React.forwardRef(function PropertyTimeline(
    { propertyId, contactId }: { propertyId: string; contactId?: string },
    forwardRef,
) {
    const { activities, pageInfo, loading, handleFetchAfter, handleFetchBefore } = useTimelineData({
        propertyId,
        contactId,
    });

    React.useImperativeHandle(
        forwardRef,
        () => ({
            refresh: handleFetchBefore,
        }),
        [handleFetchBefore],
    );

    return (
        <>
            {activities.length > 0 ? (
                <Timeline title={<FormattedMessage id="timeline.title" defaultMessage="Past" />}>
                    {activities.map(activity => {
                        const Card = getCardForActivity(activity);

                        return (
                            <ErrorBoundary key={activity.id}>
                                <TimelineItem>
                                    <Card activity={activity} viewAs="property" />
                                </TimelineItem>
                            </ErrorBoundary>
                        );
                    })}
                    {pageInfo.hasNextPage && !loading ? (
                        <Waypoint key={pageInfo.endCursor!} onEnter={handleFetchAfter} />
                    ) : null}
                </Timeline>
            ) : null}
            {loading && <LoadingIndicator />}
            {!loading && activities.length === 0 && (
                <EmptyState
                    title={
                        <FormattedMessage
                            id="property.activity.timeline_section.empty.title"
                            defaultMessage="Whoops, no activity yet"
                        />
                    }
                    body={
                        <FormattedMessage
                            id="property.activity.timeline_section.empty.subtitle"
                            defaultMessage="All activities for this property will show up here."
                        />
                    }
                />
            )}
        </>
    );
});
