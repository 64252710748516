import { combineReducers } from 'redux';
import produce from 'immer';
import uniq from 'lodash/uniq';
import {
    MERGE_PROPERTIES,
    REMOVE_PROPERTY,
    RESET_PROPERTIES,
    SET_PROPERTIES,
    SET_PROPERTY,
    SET_PROPERTY_INTERACTIONS,
    SET_PROPERTY_VISITS,
    SET_ESTATE_REQUESTS,
    RESET_ESTATE_REQUESTS,
    CANCEL_ESTATE_REQUEST,
    LOGOUT,
} from '../actions';
import propertyReducer from './Subreducers/PropertyReducer';

function byId(state = {}, action) {
    return produce(state, draftState => {
        switch (action.type) {
            case LOGOUT: {
                return {};
            }
            case SET_PROPERTIES: {
                draftState = {};
            }
            case MERGE_PROPERTIES: {
                return action.properties.data.reduce((acc, property) => {
                    acc[property.id] = produce(property, draftProperty => {
                        if (property.properties) {
                            let unitIds = [];
                            property.properties.data.forEach(unit => {
                                const unitId = unit.id;
                                // set the project reference
                                acc[unitId] = {
                                    ...unit,
                                    project: { data: { id: property.id } },
                                };
                                unitIds.push(unitId);
                            });
                            draftProperty.properties.data = unitIds;
                        }
                    });

                    return acc;
                }, draftState);
            }
            case SET_PROPERTY: {
                const { property } = action;
                draftState[property.id] = produce(propertyReducer(draftState[property.id], action), draftProperty => {
                    if (property.is_project || property.properties) {
                        // normalize the units
                        let unitIds = [];
                        property.properties.data.forEach(unit => {
                            // we keep a link with the project
                            draftState[unit.id] = {
                                ...unit,
                                project: { data: { id: property.id } },
                            };
                            unitIds.push(unit.id);
                        });
                        draftProperty.properties.data = unitIds;
                    }
                });
                break;
            }
            case REMOVE_PROPERTY: {
                delete draftState[action.propertyId];
                break;
            }
            case SET_PROPERTY_INTERACTIONS:
            case SET_PROPERTY_VISITS:
            case SET_ESTATE_REQUESTS:
            case RESET_ESTATE_REQUESTS:
            case CANCEL_ESTATE_REQUEST: {
                const propertyId =
                    (action.property && action.property.id) ||
                    action.propertyId ||
                    action.estateId ||
                    action.matchableId ||
                    action.projectId;

                draftState[propertyId] = propertyReducer(state[propertyId], action);
                break;
            }
        }
    });
}

function allIds(state = [], action) {
    switch (action.type) {
        case LOGOUT: {
            return [];
        }
        case MERGE_PROPERTIES: {
            return uniq([...state, ...action.properties.data.map(property => property.id)]);
        }
        case SET_PROPERTIES: {
            return action.properties.data.map(property => property.id);
        }
        case REMOVE_PROPERTY: {
            return state.filter(propertyId => propertyId !== action.propertyId);
        }
        case RESET_PROPERTIES: {
            // TODO: figure out where this action shouldn't be dispached
            return [];
        }
        default:
            return state;
    }
}

export default combineReducers({ allIds, byId });
