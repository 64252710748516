import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useDebounce } from 'react-use';
import { computeBuyerToOwnerOffer, computeOwnerToBuyerOffer } from '@sweepbright/offer-fees';
import { CreateOfferInput, OfferDirection } from '@/graphql/generated/types';
import Input from '@/app.components/forms/Input/Input';
import { Icon } from '@/app.components';
import { refineFinancialDetailsInput } from '@/app.shared/offers/utils';

type Props = {
    info: JSX.Element;
    nameFirst: string;
    nameSecond: string;
    labelFirst: JSX.Element;
    labelSecond: JSX.Element;
    afterSecond?: string;
    disabledFirst?: boolean;
    disabledSecond?: boolean;
    minNumberSecond?: number;
    maxNumberSecond?: number;
    icon: 'offer-more' | 'offer-less' | 'offer-plus';
};

const OfferModalDetailsBlock: React.FC<Props> = props => {
    const {
        info,
        icon,
        nameFirst,
        nameSecond,
        labelFirst,
        labelSecond,
        afterSecond,
        disabledFirst,
        disabledSecond,
        minNumberSecond,
        maxNumberSecond,
    } = props;

    const { values, setFieldValue } = useFormikContext<CreateOfferInput>();

    const [showZeroFirst, setShowZeroFirst] = useState(false);
    const [showZeroSecond, setShowZeroSecond] = useState(false);

    const direction = values?.financialDetails?.direction;
    const currency = values?.financialDetails?.currency || 'EUR';

    const valueFirst = values.financialDetails && values.financialDetails[nameFirst];
    const valueSecond = values.financialDetails && values.financialDetails[nameSecond];

    useDebounce(
        () => {
            const refined = refineFinancialDetailsInput(values.financialDetails);

            if (!refined) {
                return;
            }

            const newValues =
                refined.direction === OfferDirection.BuyerToOwner
                    ? computeBuyerToOwnerOffer(refined)
                    : computeOwnerToBuyerOffer(refined);

            setFieldValue('financialDetails', {
                ...values.financialDetails,
                ...newValues,
            });
        },
        400,
        [valueFirst, valueSecond, direction],
    );

    return (
        <div className="offer-modal-details-block">
            <Input
                help={info}
                type="price"
                name={nameFirst}
                decimalScale={2}
                label={labelFirst}
                addonAfter={currency}
                disabled={disabledFirst}
                value={valueFirst ? valueFirst : (showZeroFirst && '0') || ''}
                onChange={newValue => {
                    setShowZeroFirst(newValue !== '');
                    setFieldValue(`financialDetails.${nameFirst}`, Number(newValue));
                }}
            />

            <span className="offer-modal-details-block__icon">
                <Icon icon={icon} />
            </span>

            <Input
                type="price"
                decimalScale={2}
                name={nameSecond}
                label={labelSecond}
                min={minNumberSecond}
                max={maxNumberSecond}
                disabled={disabledSecond}
                addonAfter={afterSecond || currency}
                value={valueSecond ? valueSecond : (showZeroSecond && '0') || ''}
                onChange={newValue => {
                    setShowZeroSecond(newValue !== '');
                    setFieldValue(`financialDetails.${nameSecond}`, Number(newValue));
                }}
            />
        </div>
    );
};

export default OfferModalDetailsBlock;
