import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import LayoutColumn from '@/app.components/layouts/LayoutColumn';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';

// map channel statuses to icon statuses expected by Status component
export const STATUS_ICONS = {
    active: 'checked',
    inactive: 'error',
    pending: 'busy',
};

const Portals = ({ children }) => {
    return (
        <LayoutContainer flex>
            <LayoutColumn>{children}</LayoutColumn>
        </LayoutContainer>
    );
};

export default compose(
    withErrorBoundary,
    connect(
        createStructuredSelector({
            // if the multiple accounts feature is on,
            // we show the name field, otherwise, it is invisible to the user
            // even though the name is still set
            showAccountName: getFeature('portals.multipleAccounts.enabled'),
        }),
    ),
)(Portals);
