import InteractionsRequests, { FeedbackPayload } from '@/requests/InteractionsRequests';
import apiCall from '@/app.redux/sagas/Effects/apiCall';

export function* onRegisterInteractionFeedback({
    interactionId,
    payload,
    resolve,
    reject,
}: {
    interactionId: string;
    payload: FeedbackPayload;
    resolve: () => void;
    reject: (err?: Error) => void;
}) {
    try {
        yield apiCall(new InteractionsRequests().registerFeedback, interactionId, payload);
        if (resolve) {
            resolve();
        }
    } catch (err) {
        if (reject) {
            reject(err);
        }
    }
}
