import React from 'react';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import { ContactCardData } from '@/new.domains/contacts/types';
import { ContactReference } from '@/graphql/generated/types';
import ContactCardAssigneeImage from './ContactCardAssigneeImage';

type Props = {
    contact?: ContactCardData;
    contactReference?: ContactReference;
};

const ContactCardAssignee: React.FC<Props> = props => {
    const { contact, contactReference } = props;

    const assignee = (contact?.assignedNegotiators && contact?.assignedNegotiators[0]) || contactReference?.assignee;

    if (!assignee) {
        return null;
    }

    const name = [assignee.first_name, assignee.last_name].filter(Boolean).join(' ');
    // @ts-ignore
    const email = assignee.email;
    // @ts-ignore
    const phone = assignee.phone;

    const isOnlyName = !email && !phone;

    return (
        <Tooltip
            label={
                <div className={`contact-card-assignee__content${isOnlyName ? ' only-name' : ''}`}>
                    <ContactCardAssigneeImage large assignee={assignee} />

                    <div className="contact-card-assignee__info">
                        <span className="contact-card-assignee__name">{name}</span>
                        {email && <span className="contact-card-assignee__text">{email}</span>}
                        {phone && <span className="contact-card-assignee__text">{phone}</span>}
                    </div>
                </div>
            }
        >
            <span className="contact-card-assignee">
                <ContactCardAssigneeImage assignee={assignee} />
            </span>
        </Tooltip>
    );
};

export default ContactCardAssignee;
