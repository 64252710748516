import gql from 'graphql-tag';
import locationPreference from '@/graphql/fragments/locationPreference';

export const ADD_LOCATION_PREFERENCE = gql`
    mutation AddLocationPreference(
        $leadId: ID!
        $preferenceId: ID
        $locationPreference: LocationPreferenceInput!
        $geoJSONData: GeoJSONDataInput
        $postalCodesData: PostalCodesDataInput
        $includeLocationData: Boolean! = true
    ) {
        addLocationPreference(
            input: {
                leadId: $leadId
                locationPreference: $locationPreference
                geoJSONData: $geoJSONData
                postalCodesData: $postalCodesData
                preferenceId: $preferenceId
            }
        ) {
            locationPreference {
                ...locationPreference
            }
        }
    }
    ${locationPreference}
`;
