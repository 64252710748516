import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ResourceFilter from '@/shared/resource/ResourceFilter';
import useNegotiationStatuses from '@/app.hooks/useNegotiationStatuses';
import FilterNegotiators from '@/shared/filter/FilterNegotiators';
import PropertyFilterAmenities from '@/new.domains/properties/PropertyFilter/PropertyFilterAmenities';
import FilterLegalEntity from '@/shared/filter/FilterLegalEntity';
import FilterArea from '@/shared/filter/FilterArea';
import { PROPERTY_CONDITION_OPTIONS } from '@/app.data/Conditions';
import { ResourceDomainType } from '@/shared/resource/types';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import {
    getPropertiesAmenitiesGroups,
    getPropertiesFeatures,
    getPropertiesSubtypesGroups,
} from '@/new.domains/properties/utils';
import { PreferenceFieldVisibility } from '@/app.utils/services/Fields/PreferenceFieldVisibility';
import PropertyFilterAreaOld from '@/new.domains/properties/PropertyFilter/PropertyFilterArea/PropertyFilterAreaOld';

const useMatchContactFilters = (
    searchSettings: any | undefined,
    setSearchSettings: Function,
    domainType: ResourceDomainType,
    propertyType: string,
    negotiation?: string,
) => {
    const intl = useIntl();

    const statuses = useNegotiationStatuses(domainType !== 'contact_matches');

    const showAreas = useFeatureFlag('contact.preferences.areas.enabled');
    const showNewFields = useFeatureFlag('property.feature.fields.june2024');
    const showPropertyFeatures = useFeatureFlag('property.features.filter.enabled');
    const showPropertySharedFilter = useFeatureFlag('property.shared.enabled');
    const showLegalEntityFilter = useFeatureFlag('property.legalEntity.filter.enabled');

    const filters = useMemo(() => {
        const allStatuses = statuses?.all || [];

        const content = {
            no_interest: intl.formatMessage({ id: `${domainType}.filter.no_interest` }),
            negotiation: intl.formatMessage({ id: `forms.add-property.sale-or-let.${negotiation || 'let'}.title` }),
        };

        return (
            <>
                {showPropertySharedFilter && (
                    <ResourceFilter
                        id="visibility"
                        domainType="contact_matches"
                        params={searchSettings}
                        setParams={setSearchSettings}
                        translationDomainType="properties"
                        filters={[
                            {
                                type: 'checkbox',
                                paramName: 'visibility',
                                simpleOptions: ['team', 'company'],
                                defaultValue: ['team', 'company'],
                            },
                        ]}
                    />
                )}

                <ResourceFilter
                    id="status"
                    domainType="contact_matches"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    filters={[
                        {
                            type: 'checkbox',
                            paramName: 'status',
                            simpleOptions: allStatuses,
                            disabledValue: ['sold', 'rented', 'lost'],
                            defaultValue: ['under_contract', 'available'],
                        },
                    ]}
                />

                {showAreas && (
                    <>
                        <PreferenceFieldVisibility
                            // @ts-ignore
                            type={propertyType}
                            path="min_liveable_area"
                        >
                            <FilterArea
                                domainType="contact_matches"
                                searchSettings={searchSettings}
                                translationDomainType="properties"
                                options={['liveable', 'loi_carrez']}
                                setSearchSettings={setSearchSettings}
                            />
                        </PreferenceFieldVisibility>

                        <PreferenceFieldVisibility
                            // @ts-ignore
                            type={propertyType}
                            path="min_net_area"
                        >
                            <ResourceFilter
                                size="large"
                                id="net_area"
                                prefix="sq_m"
                                params={searchSettings}
                                domainType="contact_matches"
                                setParams={setSearchSettings}
                                translationDomainType="properties"
                                filters={[
                                    {
                                        type: 'range',
                                        paramName: 'net_area',
                                    },
                                ]}
                            />
                        </PreferenceFieldVisibility>

                        <PreferenceFieldVisibility
                            // @ts-ignore
                            type={propertyType}
                            path="min_plot_area"
                        >
                            <ResourceFilter
                                size="large"
                                prefix="sq_m"
                                id="plot_area"
                                params={searchSettings}
                                domainType="contact_matches"
                                setParams={setSearchSettings}
                                translationDomainType="properties"
                                filters={[
                                    {
                                        type: 'range',
                                        paramName: 'plot_area',
                                    },
                                ]}
                            />
                        </PreferenceFieldVisibility>

                        <PreferenceFieldVisibility
                            // @ts-ignore
                            type={propertyType}
                            path="min_gross_area"
                        >
                            <ResourceFilter
                                size="large"
                                prefix="sq_m"
                                id="gross_area"
                                params={searchSettings}
                                domainType="contact_matches"
                                setParams={setSearchSettings}
                                translationDomainType="properties"
                                filters={[
                                    {
                                        type: 'range',
                                        paramName: 'gross_area',
                                    },
                                ]}
                            />
                        </PreferenceFieldVisibility>
                    </>
                )}

                {!showAreas && (
                    <PropertyFilterAreaOld
                        searchSettings={searchSettings}
                        setSearchSettings={setSearchSettings}
                        options={['loi_carrez', 'liveable', 'net']}
                    />
                )}

                <ResourceFilter
                    id="price"
                    size="large"
                    prefix="euro"
                    params={searchSettings}
                    domainType="contact_matches"
                    setParams={setSearchSettings}
                    translationDomainType="properties"
                    filters={[
                        {
                            type: 'range',
                            paramName: searchSettings?.negotiation === 'let' ? 'let_price' : 'sale_price',
                        },
                    ]}
                />

                <ResourceFilter
                    id="condition"
                    domainType="contact_matches"
                    params={searchSettings}
                    setParams={setSearchSettings}
                    translationDomainType="properties"
                    filters={[
                        {
                            type: 'checkbox',
                            paramName: 'condition',
                            simpleOptions: [...PROPERTY_CONDITION_OPTIONS],
                            defaultValue: [...PROPERTY_CONDITION_OPTIONS],
                        },
                    ]}
                />

                {showPropertyFeatures && (
                    <ResourceFilter
                        showSearch
                        id="features"
                        params={searchSettings}
                        setParams={setSearchSettings}
                        domainType="contact_matches"
                        translationDomainType="properties"
                        translationFirstPart="property.features"
                        filters={[
                            {
                                type: 'checkbox',
                                overflow: true,
                                allowEmpty: true,
                                hideOnlyButton: true,
                                paramName: 'features',
                                options: Object.values(getPropertiesFeatures(showNewFields))
                                    .flat()
                                    .map(el => {
                                        return {
                                            value: el,
                                            label: intl.formatMessage({ id: `property.features.${el}` }),
                                        };
                                    })
                                    .sort((a, b) => a.label.localeCompare(b.label)),
                            },
                        ]}
                    />
                )}

                <ResourceFilter
                    id="rooms"
                    params={searchSettings}
                    domainType="contact_matches"
                    setParams={setSearchSettings}
                    translationDomainType="properties"
                    filters={[
                        {
                            type: 'range',
                            showTitle: true,
                            paramName: 'bedrooms',
                        },
                        {
                            type: 'range',
                            showTitle: true,
                            paramName: 'living_rooms',
                        },
                    ]}
                />

                {showPropertyFeatures ? (
                    <ResourceFilter
                        showSearch
                        id="amenities"
                        params={searchSettings}
                        domainType="contact_matches"
                        setParams={setSearchSettings}
                        translationDomainType="properties"
                        filters={[
                            {
                                type: 'checkbox',
                                overflow: true,
                                allowEmpty: true,
                                hideOnlyButton: true,
                                paramName: 'amenities',
                                groupOptions: getPropertiesAmenitiesGroups(),
                            },
                        ]}
                    />
                ) : (
                    <PropertyFilterAmenities
                        // @ts-ignore
                        values={searchSettings}
                        onChange={changedSettings => {
                            // @ts-ignore
                            setSearchSettings({ ...searchSettings, ...changedSettings });
                        }}
                        title={<FormattedMessage id="properties.filter.amenities" defaultMessage="Amenities" />}
                    />
                )}

                <ResourceFilter
                    id="address_floor"
                    params={searchSettings}
                    domainType="contact_matches"
                    setParams={setSearchSettings}
                    translationDomainType="properties"
                    filters={[
                        {
                            type: 'radio-number',
                            paramName: 'address_floor',
                            options: [
                                { value: 'below', translateKey: 'general.below' },
                                { value: 'above', translateKey: 'general.above' },
                                { value: 'equal', translateKey: 'general.equal' },
                            ],
                        },
                    ]}
                />

                <ResourceFilter
                    id="floor"
                    params={searchSettings}
                    domainType="contact_matches"
                    setParams={setSearchSettings}
                    translationDomainType="properties"
                    filters={[
                        {
                            type: 'range',
                            paramName: 'floors',
                        },
                    ]}
                />

                {!showAreas && (
                    <PropertyFilterAreaOld
                        searchSettings={searchSettings}
                        setSearchSettings={setSearchSettings}
                        options={['plot', 'gross']}
                    />
                )}

                <ResourceFilter
                    id="offer_status"
                    params={searchSettings}
                    domainType="contact_matches"
                    setParams={setSearchSettings}
                    translationDomainType="properties"
                    filters={[
                        {
                            type: 'radio',
                            paramName: 'offer_status',
                            options: [
                                { value: undefined, key: 'all' },
                                { value: 'accepted', key: 'accepted', displayKey: true },
                                { value: 'pending', key: 'pending', displayKey: true },
                            ],
                        },
                    ]}
                />

                <ResourceFilter
                    id="subtypes"
                    params={searchSettings}
                    domainType="contact_matches"
                    setParams={setSearchSettings}
                    translationDomainType="properties"
                    filters={[
                        {
                            overflow: true,
                            type: 'checkbox',
                            allowEmpty: true,
                            hideOnlyButton: true,
                            paramName: 'subtypes',
                            groupOptions: getPropertiesSubtypesGroups(),
                        },
                    ]}
                />

                <FilterNegotiators
                    domainType="contact_matches"
                    searchSettings={searchSettings}
                    translationDomainType="properties"
                    setSearchSettings={setSearchSettings}
                />

                {showLegalEntityFilter && (
                    <FilterLegalEntity
                        domainType="contact_matches"
                        searchSettings={searchSettings}
                        translationDomainType="properties"
                        setSearchSettings={setSearchSettings}
                    />
                )}

                <ResourceFilter
                    disabled
                    filters={[]}
                    id="no_interest"
                    params={searchSettings}
                    label={content.no_interest}
                    domainType="contact_matches"
                    setParams={setSearchSettings}
                />

                <ResourceFilter
                    disabled
                    id="type"
                    filters={[]}
                    params={searchSettings}
                    domainType="contact_matches"
                    setParams={setSearchSettings}
                    translationDomainType="properties"
                    label={intl.formatMessage({
                        id: `property.type.${
                            searchSettings?.type && searchSettings?.type[0] ? searchSettings?.type[0] : 'house'
                        }`,
                    })}
                />

                <ResourceFilter
                    disabled
                    params={{}}
                    filters={[]}
                    id="negotiation"
                    setParams={() => {}}
                    label={content.negotiation}
                    domainType="contact_matches"
                />
            </>
        );
    }, [
        intl,
        showAreas,
        domainType,
        statuses,
        negotiation,
        propertyType,
        showNewFields,
        searchSettings,
        setSearchSettings,
        showPropertyFeatures,
        showLegalEntityFilter,
        showPropertySharedFilter,
    ]);

    return filters;
};

export default useMatchContactFilters;
