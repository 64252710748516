import { MutationHookOptions, useMutation } from '@apollo/react-hooks';
import {
    AcceptOfferMutation,
    AcceptOfferMutationVariables,
    ArchiveOfferMutation,
    ArchiveOfferMutationVariables,
    CancelOfferMutation,
    CancelOfferMutationVariables,
    CreateOfferMutation,
    CreateOfferMutationVariables,
    RefuseOfferMutation,
    RefuseOfferMutationVariables,
    RestoreOfferMutation,
    RestoreOfferMutationVariables,
    UpdateOfferMutation,
    UpdateOfferMutationVariables,
} from '@/graphql/generated/types';
import {
    ACCEPT_OFFER,
    ARCHIVE_OFFER,
    CANCEL_OFFER,
    CREATE_OFFER,
    REFUSE_OFFER,
    RESTORE_OFFER,
    UPDATE_OFFER,
} from '@/graphql/mutations/offers/offerMutations';

export const useUpdateOffer = (options?: MutationHookOptions<UpdateOfferMutation, UpdateOfferMutationVariables>) => {
    return useMutation<UpdateOfferMutation, UpdateOfferMutationVariables>(UPDATE_OFFER, options);
};

export const useCreateOffer = (options?: MutationHookOptions<CreateOfferMutation, CreateOfferMutationVariables>) => {
    return useMutation<CreateOfferMutation, CreateOfferMutationVariables>(CREATE_OFFER, options);
};

export const useArchiveOffer = (options?: MutationHookOptions<ArchiveOfferMutation, ArchiveOfferMutationVariables>) => {
    return useMutation<ArchiveOfferMutation, ArchiveOfferMutationVariables>(ARCHIVE_OFFER, options);
};

export const useRestoreOffer = (options?: MutationHookOptions<RestoreOfferMutation, RestoreOfferMutationVariables>) => {
    return useMutation<RestoreOfferMutation, RestoreOfferMutationVariables>(RESTORE_OFFER, options);
};

export const useAcceptOffer = (options?: MutationHookOptions<AcceptOfferMutation, AcceptOfferMutationVariables>) => {
    return useMutation<AcceptOfferMutation, AcceptOfferMutationVariables>(ACCEPT_OFFER, options);
};

export const useRefuseOffer = (options?: MutationHookOptions<RefuseOfferMutation, RefuseOfferMutationVariables>) => {
    return useMutation<RefuseOfferMutation, RefuseOfferMutationVariables>(REFUSE_OFFER, options);
};

export const useCancelOffer = (options?: MutationHookOptions<CancelOfferMutation, CancelOfferMutationVariables>) => {
    return useMutation<CancelOfferMutation, CancelOfferMutationVariables>(CANCEL_OFFER, options);
};
