// @ts-nocheck
import React from 'react';
import { ModalProps } from 'react-bootstrap';
import { PlainRoute } from 'react-router';
import { FormikConfig, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { Location, LocationDescriptor } from 'history';
import ConfirmationModal from '@/app.components/modals/UnsavedChangesConfirmationModal';
import { useModal } from '@/app.components/modals/AbstractModal';
import useRouter from '@/app.hooks/useRouter';
import Formik from '@/app.components/forms/helpers/Formik';

const FormWithConfirmation: React.FunctionComponent<{
    route: PlainRoute;
    confirmationModalProps?: ModalProps;
    children: (formikProps: FormikProps<FormikValues>) => React.ReactNode;
} & FormikProps<FormikValues>> = ({ route, children, ...props }) => {
    const { getModalProps, handleClose, handleOpen } = useModal(false);
    const router = useRouter();
    const [destination, setDestination] = React.useState<Location | null | undefined>(null);
    const [navigationConfirmed, setNavigationConfirmed] = React.useState(false);
    const [submittingFromModal, setSubmittingFromModal] = React.useState(false);
    const { dirty, skipConfirmation } = props;

    React.useEffect(() => {
        const removeHook = router.setRouteLeaveHook(route, nextLocation => {
            if (dirty && !navigationConfirmed && !skipConfirmation) {
                handleOpen();
                setDestination(nextLocation);

                return false;
            }

            return true;
        });

        return () => {
            removeHook();
        };
    }, [dirty, handleOpen, navigationConfirmed, props, route, router, skipConfirmation]);

    React.useEffect(() => {
        function handleBeforeUnload(evt) {
            if (dirty && !window.Cypress && !skipConfirmation) {
                evt.preventDefault();
                evt.returnValue = '';
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [dirty, skipConfirmation]);

    React.useEffect(() => {
        if (navigationConfirmed) {
            router.push(destination as LocationDescriptor);
        }
    }, [destination, navigationConfirmed, router]);

    React.useEffect(() => {
        if (!props.isSubmitting && submittingFromModal) {
            confirmNavigation();
        }
    }, [confirmNavigation, props.isSubmitting, submittingFromModal]);

    function confirmNavigation() {
        handleClose();
        setNavigationConfirmed(true);
    }

    const handleSave = () => {
        setSubmittingFromModal(true);
        props.submitForm();
    };

    return (
        <React.Fragment>
            <ConfirmationModal
                onRevert={confirmNavigation}
                onSave={handleSave}
                saving={props.isSubmitting}
                onCancel={handleClose}
                {...getModalProps()}
            />
            {children(props)}
        </React.Fragment>
    );
};

type FormikWithConfirmationProps<Values> = FormikConfig<Values> & {
    route: PlainRoute;
    children: (props: FormikProps<FormikValues>) => React.ReactNode;
    onSubmitFailed?: (err: Error, helpers: FormikHelpers<Values>) => void;
    skipConfirmation?: boolean;
};

export default function FormikWithConfirmation<Values = FormikValues>({
    route,
    onSubmit,
    children,
    skipConfirmation,
    ...props
}: FormikWithConfirmationProps<Values>) {
    return (
        <Formik {...props} onSubmit={onSubmit}>
            {formikProps => (
                <FormWithConfirmation {...formikProps} skipConfirmation={skipConfirmation} route={route}>
                    {children}
                </FormWithConfirmation>
            )}
        </Formik>
    );
}
