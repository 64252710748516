import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { AreaUnitsLabel } from '@/app.domains/properties/Area';
import useOffice from '@/app.hooks/useOffice';
import { getDefaultUnitForArea } from '@/app.data/Rooms';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import Input from '@/app.components/forms/Input/Input';

type Props = {
    old?: boolean;
    popup?: boolean;
    areaType: string;
    title?: JSX.Element;
    minField: any;
    maxField: any;
    max: number;
    style?: any;
};

const FilterAreaRange: React.FC<Props> = props => {
    const { old, popup = false, areaType, title, minField, maxField, max, style } = props;

    const measurementSystem = useOffice().getIn(['settings', 'data', 'measurement_system']);
    const units = getDefaultUnitForArea(areaType, measurementSystem, 'house');

    return (
        <FormPanel title={title} popup={popup} style={style}>
            <div className="flex items-center">
                <Input
                    type="price"
                    max={max}
                    decimalScale={2}
                    label={
                        old ? (
                            <FormattedMessage id="modals.common.price_panel.min_label" defaultMessage="Min." />
                        ) : (
                            <FormattedMessage id="general.above" defaultMessage="above" />
                        )
                    }
                    {...minField}
                    value={minField.value <= 0 ? '' : minField.value}
                    placeholder={<FormattedMessage id="general.any" defaultMessage="Any" />}
                    addonAfter={<AreaUnitsLabel units={units} />}
                />
                <span className="px-2 py-0 font-sm text-gray-dark"> ⎼ </span>
                <Input
                    type="price"
                    decimalScale={2}
                    label={
                        old ? (
                            <FormattedMessage id="modals.common.price_panel.max_label" defaultMessage="Max." />
                        ) : (
                            <FormattedMessage id="general.below" defaultMessage="below" />
                        )
                    }
                    {...maxField}
                    value={!maxField.value || maxField.value >= max ? '' : maxField.value}
                    placeholder={<FormattedMessage id="general.any" defaultMessage="Any" />}
                    max={max}
                    addonAfter={<AreaUnitsLabel units={units} />}
                />
            </div>
        </FormPanel>
    );
};

export default FilterAreaRange;
