import React from 'react';
import { QueryResult } from '@apollo/react-common';
import { useQuery } from '@apollo/react-hooks';
import { GetOfficeQuery, GetOfficeQueryVariables } from '@/graphql/generated/types';
import { GET_OFFICE } from '@/graphql/queries/office/getOffice';
import { updateConnection } from '@/graphql/utils';
import useDebounce from './useDebounce';
import useField from './useField';

export default function useLocationTemplates(officeId?: string, includeData: boolean = true) {
    const queryField = useField('');
    const [fetchingMore, setFetchingMore] = React.useState(false);
    // get a debounced value of the query field for search
    const query = useDebounce((queryField.value ?? '').trim(), 200);

    const { data, loading, fetchMore }: QueryResult<GetOfficeQuery, GetOfficeQueryVariables> = useQuery(GET_OFFICE, {
        variables: {
            id: officeId || '',
            locationTemplatesQuery: query.trim(),
            includeLocationData: includeData,
        },
        skip: !officeId,
        notifyOnNetworkStatusChange: true,
    });

    const { hasNextPage, currentPage } = data?.office?.locationTemplates.pageInfo ?? {
        hasMorePages: false,
        currentPage: 1,
    };

    const memoizedFetchMore = React.useCallback(
        // either you can pass the page you want or we just request the next one
        async ({ page = currentPage + 1 } = {}) => {
            // bail out if a search is already on progress or no more data is available

            if (fetchingMore || !hasNextPage) return;
            setFetchingMore(true);
            let result;
            try {
                result = await fetchMore({
                    variables: {
                        id: officeId,
                        locationTemplatesPage: page,
                        locationTemplatesQuery: query,
                    },
                    updateQuery: updateConnection('office.locationTemplates'),
                });
            } finally {
                setFetchingMore(false);
            }

            return result;
        },
        [query, officeId, fetchMore, fetchingMore, hasNextPage, currentPage],
    );

    return { queryField, data, loading, fetchMore: memoizedFetchMore, query };
}
