import { fromJS, Map, OrderedSet } from 'immutable';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';

export { default as unique } from './unique';
export { isObjectEmpty } from './emptyValidators';
export * from './promises';

// merge two sets and remove duplicated objects
// based on a key, by defualt the key is assumed to the `id` prop of the element in
// the collection, but you can pase any other by mean
// of the `keyExtractor` function
export function mergeSetsUnique(
    intialSet: OrderedSet<any>,
    additionalItemSet: OrderedSet<any>,
    keyExtractor: ({ id }: { id: string }) => string = item => item.id,
): OrderedSet<any> {
    return fromJS(uniqBy([...intialSet.toJS(), ...additionalItemSet.toJS()], keyExtractor)).toOrderedSet();
}

const propertyPaths = {
    city: 'attributes.location.city',
    updated_at: 'updated_at',
    published_at: 'published_at',
    first_published_at: 'published_at',
    price: 'attributes.price.published_price.amount',
};

export function sortProperties(
    properties: Map<string, any>,
    searchSettings: {
        sort_field: string;
        sort_order: 'asc' | 'desc';
    },
): Map<string, any> {
    return fromJS(orderBy(properties.toJS(), propertyPaths[searchSettings.sort_field], searchSettings.sort_order));
}

export function addedItemWithKey(records: number[], key: number): number[] {
    return records.concat(key);
}

export function removedItemWithKey(records: number[], key: number): number[] {
    // We keep a dirty field as a part
    // of the form and update it when `addField`, `deleteField`
    // operations occur.
    // We need to do this because some known bugs
    // in redux-form v5
    // Reported here:
    // - https://github.com/erikras/redux-form/issues/1543
    // - https://github.com/erikras/redux-form/issues/391

    // remove the field from the dirty list
    const values: number[] = Array.of(...records);
    const dirtyFieldIndex = values.indexOf(key);
    if (dirtyFieldIndex >= 0) {
        // and added row was removed
        const dirtyFields = [...values.slice(0, dirtyFieldIndex), ...values.slice(dirtyFieldIndex + 1).map(k => k - 1)];

        return dirtyFields;
    }
    // the row was removed and existed before
    // so add it to the dirty list

    return values.map((k: number) => k - 1).concat(-(key + 1));
}

export function toFixedNumber(value: number, digits: number = 2) {
    return parseFloat(Number(value).toFixed(digits));
}

export const wrapEvent = (handler, cb) => event => {
    handler?.(event);
    if (!event.defaultPrevented) {
        return cb(event);
    }
};

export function fullName(firstName?: Maybe<string>, lastName?: Maybe<string>) {
    return [firstName, lastName]
        .filter(Boolean)
        .map(w => w!.trim())
        .join(' ');
}
