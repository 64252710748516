import React from 'react';
import produce from 'immer';
import { useQuery } from '@apollo/react-hooks';
import { ESTATE_CHANNELS_ACCOUNTS_QUERY } from '@/graphql/queries/properties/getEstateChannelAccounts';
import {
    EstateChannelAccountEdgeFragmentFragment,
    GetEstateChannelAccountsQuery,
    GetEstateChannelAccountsQueryVariables,
    PublicationStatus,
} from '@/graphql/generated/types';
import useFeatureFlag from './useFeatureFlag';

export default function useEstateChannelAccounts(
    propertyId,
    isOldPublishing = false,
    isAdStatus = false,
    status = [] as any[],
) {
    const isStatusEnabled = useFeatureFlag('properties.publication.status.enabled');

    const { data, loading, updateQuery } = useQuery<
        GetEstateChannelAccountsQuery,
        GetEstateChannelAccountsQueryVariables
    >(ESTATE_CHANNELS_ACCOUNTS_QUERY, {
        variables: {
            propertyId: propertyId,
            isOldPublishing,
            isAdStatus,
            status,
            isStatusEnabled,
        },
        fetchPolicy: 'cache-and-network',
        skip: !propertyId,
    });

    const channelAccountsConnection = data?.estate?.publications.channelAccounts;

    const addScheduledPublication = React.useCallback(
        (channelAccountEdgeId?: string) => {
            const mapFn = produce((draft: GetEstateChannelAccountsQuery) => {
                if (channelAccountEdgeId) {
                    const scheduledEdge: Maybe<EstateChannelAccountEdgeFragmentFragment> = draft.estate?.publications.channelAccounts.edges.find(
                        edge => edge.id === channelAccountEdgeId,
                    );
                    if (scheduledEdge) {
                        scheduledEdge.publicationStatus = PublicationStatus.Publishing;
                    }
                }

                return draft;
            });
            updateQuery(mapFn);
        },
        [updateQuery],
    );

    return {
        channelAccountsConnection,
        loading,
        addScheduledPublication,
    };
}
