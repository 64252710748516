/*
    Unifies cookies interface for server (with Express) and client into a single API
    In the client we use the 'js-cookie' module
    and in the serve we rely on 'cookie-parser
 */

import * as cookies from 'js-cookie';
import logger from '@/app.utils/services/logger';
import { CookieService as CookieService_ } from './types';

let cookieServiceSingleton: CookieService_ | null = null;

export function ClientCookieFactory(): CookieService_ {
    return {
        remove(cookieName: string, options: object) {
            logger.debug(`removing cookie ${cookieName}`);

            cookies.remove(cookieName, options);
        },
        get(cookieName: string) {
            return cookies.get(cookieName);
        },
        getAll() {
            return cookies.get();
        },
        save(cookieName: string, value: string, options: object) {
            cookies.set(cookieName, value, options);
        },
    };
}

function CookieService(): CookieService_ {
    if (!cookieServiceSingleton) {
        cookieServiceSingleton = ClientCookieFactory();
    }

    return cookieServiceSingleton;
}

export default CookieService;
