import { useRect } from '@reach/rect';
import { Checkbox, Tooltip } from '@sweepbright/uikit';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import { Calendar, momentLocalizer, Navigate } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useToggle } from 'react-use';
import { FormattedMessage } from 'react-intl-sweepbright';
import { useIntersectionObserver } from '@/app.hooks/useIntersectionObserver';
import {
    fetchAvailableSlots,
    fetchPropertySchedule,
    fetchUserSchedule,
} from '@/app.domains/properties/Schedule/visits_requests';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import './ScheduleVisitDayViewCalendar.scss';

const DraggableCalendar = withDragAndDrop(Calendar);

export type CronofyEvent = {
    event_id: string;
    location: {
        description: string;
    };
    summary: string;
    attendees: Array<{ display_name?: string; email: string }>;
    start: Date;
    end: Date;
    type: 'visit' | 'slot' | 'event';
};

const components = {
    event: Event,
    toolbar: Toolbar,
};

function ScheduleVisitDayView({
    date,
    onChangeDate,
    onChangeTime,
    visitEvent,
    negotiatorId,
    propertyId,
}: {
    propertyId: string;
    negotiatorId?: any;
    date: any;
    onChangeDate: (date: string) => void;
    onChangeTime: (values: { hour: string; minutes: string; duration: string }) => void;
    visitEvent: Partial<CronofyEvent>;
}) {
    const now = React.useMemo(() => moment().toDate(), []);

    const localizer = React.useMemo(() => momentLocalizer(moment), []);
    const calendarContainerRef = React.useRef();

    const startOfWeek = moment(date)
        .startOf('week')
        .toISOString();

    const { events: negotiatorCalendarEvents, loading: loadingNegotiatorCalendarEvents } = useNegotiatorSchedule(
        negotiatorId,
        startOfWeek,
    );

    const availabilitySlotMinimumDuration = 5; //minutes
    const { events: availabilitySlots } = useAvailableSlots(
        propertyId,
        negotiatorId,
        startOfWeek,
        availabilitySlotMinimumDuration,
    );

    const { events: propertyEvents, loading: loadingPropertyEvents } = usePropertySchedule(propertyId, startOfWeek);
    const [showAvailabilitySlots] = useToggle(true);
    const [showNegotiatorEvents, toggleShowNegotiatorEvents] = useToggle(true);
    const [showPropertyEvents, toggleShowPropertyEvents] = useToggle(true);

    const eventIsNotCurrent = useCallback(
        event => visitEvent.event_id === undefined || event.event_id !== visitEvent.event_id,
        [visitEvent.event_id],
    );

    const events = React.useMemo(() => {
        return [
            ..._.uniqBy(
                [visitEvent, ...(showNegotiatorEvents ? negotiatorCalendarEvents.filter(eventIsNotCurrent) : [])],
                'event_uid',
            ),
            ...(showAvailabilitySlots ? availabilitySlots : []),
            ...(showPropertyEvents ? propertyEvents.filter(eventIsNotCurrent) : []),
        ];
    }, [
        visitEvent,
        showNegotiatorEvents,
        negotiatorCalendarEvents,
        eventIsNotCurrent,
        showAvailabilitySlots,
        availabilitySlots,
        showPropertyEvents,
        propertyEvents,
    ]);

    const handleChangeEvent = React.useCallback(
        event => {
            if (moment(event.start).diff(moment(), 'minutes') > 0) {
                onChangeDate(moment((event.slots && event.slots[0]) || event.end).format('YYYY-MM-DD'));

                onChangeTime(getTimeAndDuration(event));
            }
        },
        [onChangeTime, onChangeDate],
    );
    const formats = React.useMemo(() => {
        return {
            eventTimeRangeFormat: ({ start, end }) => {
                const evt = events.find(evt => evt.start === start && evt.end === end);
                if (evt) {
                    return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')} ${evt?.summary?.trim() ??
                        ''}`;
                }

                return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`;
            },
            timeGutterFormat: date => {
                return moment(date).format('HH:mm');
            },
        };
    }, [events]);

    return (
        <div
            //@ts-ignore
            ref={calendarContainerRef}
            className="h-full flex flex-col min-h-0 overflow-hidden"
        >
            <div className="flex-1 min-h-0">
                <DraggableCalendar
                    step={5}
                    selectable
                    view="week"
                    date={date}
                    timeslots={12}
                    events={events}
                    views={['week']}
                    formats={formats}
                    resizable={false}
                    scrollToTime={now}
                    localizer={localizer}
                    titleAccessor="summary"
                    components={components}
                    showMultiDayTimes={true}
                    resizableAccessor="isVisit"
                    draggableAccessor="isVisit"
                    dayLayoutAlgorithm="no-overlap"
                    onEventDrop={handleChangeEvent}
                    onSelectSlot={handleChangeEvent}
                    eventPropGetter={getEventStyles}
                    onEventResize={handleChangeEvent}
                    onNavigate={newDate => {
                        onChangeDate(moment(newDate).format('YYYY-MM-DD'));
                    }}
                    onSelecting={({ start }) => moment(start).diff(moment(), 'minutes') > 0}
                />
            </div>
            <div className="flex items-center" style={{ gap: '20px', margin: '4px 0 4px 10px' }}>
                <div className="flex justify-between items-center -my-2" style={{ gap: '5px' }}>
                    <Checkbox checked={showPropertyEvents} onChange={toggleShowPropertyEvents}>
                        <FormattedMessage
                            id="components.schedule_visit_day_view.checkbox.property"
                            defaultMessage="Property"
                        />
                    </Checkbox>
                    {loadingPropertyEvents && showAvailabilitySlots && <div className="spinner" />}
                </div>
                {negotiatorId && (
                    <div className="flex justify-between items-center -my-2" style={{ gap: '5px' }}>
                        <Checkbox checked={showNegotiatorEvents} onChange={toggleShowNegotiatorEvents}>
                            <FormattedMessage id="modals.schedule_visit.labels.organiser" />
                        </Checkbox>
                        {loadingNegotiatorCalendarEvents && showNegotiatorEvents && <div className="spinner" />}
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(ScheduleVisitDayView);

function getEventStyles(event) {
    if (event.type === 'visit') {
        return {
            className: 'bg-brand-primary rounded-sm border border-white',
        };
    } else if (event.type === 'event') {
        return {
            className: `rounded-sm border border-white rbc-event--${event.calendar}-event`,
        };
    } else if (event.type === 'slot') {
        return {
            className: 'rbc-event--slot',
        };
    }
    throw new Error('invalid event type ' + event.type);
}

function Event({ event }) {
    const evtRef = React.useRef<HTMLDivElement | null>(null);
    const rect = useRect(evtRef);
    const observer = useIntersectionObserver();

    // here I had to wrap
    // the event inside another component
    // so I could set a key attribute properly
    // because the Calendar component
    // assigns keys to the events using array indexing
    // which prevented the didMount lifecycle method
    // from being called for each event card
    return <EventImpl key={event.event_id} event={event} observer={observer} rect={rect} evtRef={evtRef} />;
}

class EventImpl extends React.Component<{
    observer: IntersectionObserver;
    evtRef: React.RefObject<HTMLDivElement>;
    event: CronofyEvent;
    rect: ClientRect | null;
}> {
    componentDidMount(): void {
        const { evtRef, observer } = this.props;
        if (evtRef.current) {
            // eslint-disable-next-line no-unused-expressions
            observer?.observe(evtRef.current);
        }
    }

    componentWillUnmount(): void {
        const { evtRef, observer } = this.props;

        if (evtRef.current) {
            // eslint-disable-next-line no-unused-expressions
            observer?.unobserve(evtRef.current);
        }
    }

    render() {
        const { evtRef, event, rect } = this.props;

        return (
            <div ref={evtRef} className="h-full" data-eventId={event.event_id}>
                {rect?.height && rect?.height > 20 && (
                    <div className="opacity-50 text-white text-xs truncate" style={{ marginTop: '2px' }}>
                        {event.location?.description ?? ''}
                    </div>
                )}
            </div>
        );
    }
}

// this renders the calendar toolbar for the day view
// we dont allow to go to days in the past
// and also we don't allow to go to more than
// 40 days in the future
function Toolbar({ date, onNavigate }) {
    const diffFromTodayInHours = moment(date).diff(moment().startOf('day'), 'hours');

    return (
        <div className="flex items-center py-1 px-4">
            <h5 className="uppercase font-semibold text-muted" style={{ fontSize: 12 }}>
                {moment(date)
                    .startOf('week')
                    .format('dddd, MMMM Do')}
                {' - '}
                {moment(date)
                    .endOf('week')
                    .format('dddd, MMMM Do')}
            </h5>
            <div className="ml-auto">
                <Tooltip label="Go to previous day">
                    <ButtonIcon
                        type="caret-left"
                        variant="link"
                        className="hover:bg-gray-lightest rounded-sm"
                        onClick={() => onNavigate(Navigate.PREVIOUS)}
                        disabled={diffFromTodayInHours < 24}
                    />
                </Tooltip>
                <Tooltip label="Go to next day">
                    <ButtonIcon
                        type="caret-right"
                        variant="link"
                        className="hover:bg-gray-lightest rounded-sm"
                        onClick={() => onNavigate(Navigate.NEXT)}
                        disabled={diffFromTodayInHours / 24 >= 40}
                    />
                </Tooltip>
            </div>
        </div>
    );
}

// get the list of existing events
// in the calendar of the current negotiator

function useNegotiatorSchedule(
    negotiatorId: string | undefined,
    date: string,
): { events: CronofyEvent[]; loading: boolean } {
    const { data: events = [], isLoading } = useQuery(
        [`/negotiators/${negotiatorId}/events`, negotiatorId, date],
        fetchUserSchedule,
        {
            enabled: !!negotiatorId,
        },
    );

    return { events, loading: isLoading };
}

// get available slots for booking a visit
// taking into account the negotiator calendar
// and the property availability calendar
function useAvailableSlots(
    propertyId: string | undefined,
    negotiatorId: string,
    date: string,
    duration = 100,
): { events: CronofyEvent[]; loading: boolean } {
    const { data, isLoading } = useQuery(
        [`/properties/${propertyId}/availability/slots`, propertyId, negotiatorId, date, duration],
        fetchAvailableSlots,
        {
            enabled: !!propertyId,
        },
    );

    const intl = useIntl();

    const events = React.useMemo(
        () =>
            data?.map(slot => {
                return {
                    event_id: moment(slot.start).unix(),
                    type: 'slot',
                    summary: intl.formatMessage({
                        id: 'components.schedule_visit_day_view.label.propertyAvailable',
                        defaultMessage: 'Property is available for visits',
                    }),
                    start: moment(slot.start).toDate(),
                    end: moment(slot.end).toDate(),
                };
            }) ?? [],
        [data, intl],
    );

    return { events, loading: isLoading };
}

function usePropertySchedule(propertyId: string, date: string): { events: CronofyEvent[]; loading: boolean } {
    const params = React.useMemo(() => {
        return { after: date };
    }, [date]);

    const { data, isLoading } = useQuery(
        [`/properties/${propertyId}/schedule`, propertyId, params],
        fetchPropertySchedule,
    );

    const events = React.useMemo(
        () =>
            data?.map(event => {
                return {
                    event_id: event.id,
                    type: 'event',
                    calendar: 'property',
                    summary: event.title,
                    start: moment(event.start).toDate(),
                    end: moment(event.end).toDate(),
                };
            }) ?? [],
        [data],
    );

    return { events, loading: isLoading };
}

function getTimeAndDuration({ start, end }: { start: Date; end: Date }) {
    const hour = moment(start).format('HH');
    const minutes = moment(start).format('mm');
    const duration = moment(end)
        .diff(moment(start), 'minutes')
        .toString();

    return { hour, minutes, duration };
}
