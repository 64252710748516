import React from 'react';
import WrapperAsideProfileImage from './WrapperAsideProfileImage';
import WrapperAsideProfileInfo from './WrapperAsideProfileInfo';

type Props = {
    imageSrc?: string;
    imageAlt?: string;
    imageLink?: string;
    imageAction?: JSX.Element;
    info?: JSX.Element | JSX.Element[];
    imageLinkText?: string | JSX.Element;
    imageFooter?: JSX.Element | JSX.Element[];
};

const WrapperAsideProfile: React.FC<Props> = props => {
    const { info, imageSrc, imageAlt, imageLink, imageAction, imageLinkText, imageFooter } = props;

    return (
        <div className="c-wrapper-aside-profile">
            {!!(imageSrc || imageAlt) && (
                <WrapperAsideProfileImage
                    src={imageSrc}
                    alt={imageAlt}
                    link={imageLink}
                    footer={imageFooter}
                    action={imageAction}
                    linkText={imageLinkText}
                />
            )}
            {!!info && <WrapperAsideProfileInfo>{info}</WrapperAsideProfileInfo>}
        </div>
    );
};

export default WrapperAsideProfile;
