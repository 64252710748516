import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import companySettings, { propTypes } from '../../../../app.utils/Decorators/companySettings';
import FormPane from '../../../../app.components/forms/FormPane';
import FormPanel from '../../../../app.components/forms/FormPanel/FormPanel';
import Input from '../../../../app.components/forms/Input/Input';

@companySettings('documents/sweepbright', ['integration_document_management_sweepbright_documents'])
export default class Sweepbright extends Component {
    static propTypes = {
        ...propTypes,
    };

    render() {
        const {
            fields: { integration_document_management_sweepbright_documents: field },
            route,
            status,
            handleSubmit,
            errors,
        } = this.props;

        return (
            <FormPane
                title={route.title}
                status={status}
                onSubmit={handleSubmit}
                disabled={Object.keys(errors).length > 0}
                cols={{ md: 15 }}
            >
                <FormPanel>
                    <Input
                        label={
                            <FormattedMessage
                                id="forms.documents.sweepbright.title"
                                defaultMessage="Use SweepBright Documents"
                            />
                        }
                    >
                        <Input
                            type="radio"
                            label={
                                <FormattedMessage
                                    id="forms.documents.sweepbright.labels.yes"
                                    defaultMessage="Yes (this will override any existing document management integrations you might have)"
                                />
                            }
                            {...field}
                            value="on"
                            checked={field.value === 'on' || field.value}
                        />
                        <Input
                            type="radio"
                            label={
                                <FormattedMessage
                                    id="forms.documents.sweepbright.labels.no"
                                    defaultMessage="No, I will configure my own document management integration using one of the provided apps."
                                />
                            }
                            {...field}
                            value="off"
                            onChange={() => field.onChange(false)}
                            checked={field.value === 'off' || !field.value}
                        />
                    </Input>
                </FormPanel>
            </FormPane>
        );
    }
}
