import React from 'react';
import { Button } from '@sweepbright/uikit';
import PagePane from '@/app.layouts/PagePane/PagePane';
import { FormattedMessage } from 'react-intl-sweepbright';
import Icon from '@/app.components/icons/Icon';
import ScheduleVisitModal from '@/app.components/modals/ScheduleVisitModal/ScheduleVisitModalV2';
import { PropertySchedule } from '@/app.domains/properties/Schedule/PropertySchedule';
import { useToasts } from '@sweepbright/notifications';
import useProperty from '@/app.hooks/useProperty';

export default function PropertySchedulePage({ params }) {
    const propertyId = params.unit || params.property;
    const { property } = useProperty(propertyId);
    const editingDisabled = property?.archived;

    const [showScheduleModal, setShowScheduleModal] = React.useState(false);
    const toasts = useToasts();

    return (
        <PagePane
            title={<FormattedMessage id="pages.property_schedule.schedule" defaultMessage="Schedule" />}
            actions={[
                <Button
                    disabled={editingDisabled}
                    variant="primary"
                    key="schedule_visit"
                    icon={<Icon icon="add-visit" size={20} />}
                    onClick={() => setShowScheduleModal(true)}
                >
                    {editingDisabled ? (
                        <FormattedMessage id="property.status.archived" defaultMessage="Archived" />
                    ) : (
                        <FormattedMessage
                            id="pages.property_schedule.schedule_appointment"
                            defaultMessage="Schedule Appointment"
                        />
                    )}
                </Button>,
            ]}
        >
            <PropertySchedule propertyId={propertyId} />
            <ScheduleVisitModal
                show={showScheduleModal}
                onCancel={() => setShowScheduleModal(false)}
                initialValues={{
                    propertyId,
                }}
                onVisitScheduled={() => {
                    setShowScheduleModal(false);
                    toasts.addSuccess({
                        message: (
                            <FormattedMessage
                                id="general.interactions.visit_scheduled"
                                defaultMessage="Visit scheduled"
                            />
                        ),
                    });
                }}
            />
        </PagePane>
    );
}
