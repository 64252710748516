import { fromJS, OrderedSet } from 'immutable';
import { createReducerWithSanitizer } from 'redux-reducer-factory';
import {
    SET_OFFICE_MEMBERS,
    SET_OFFICE,
    REMOVE_OFFICE_MEMBER,
    SET_OFFICE_MEMBER,
    RESET_OFFICE_MEMBERS,
} from '../../actions';
import concatSetWith from '../Helpers/concatSetWith';
import handleWithSubreducer from '../Helpers/handleWithSubreducer';
import memberReducer from './MemberReducer';

function sanitize(office) {
    return fromJS(office)
        .update('members', members => members || OrderedSet())
        .update('negotiators', entities => entities || OrderedSet());
}

export default createReducerWithSanitizer({}, sanitize, {
    [SET_OFFICE]: (office, action) => office.mergeDeep(sanitize(action.office)),
    [SET_OFFICE_MEMBERS]: (office, { members, total }) =>
        office.set('members_count', total).update('members', concatSetWith(members)),
    [REMOVE_OFFICE_MEMBER]: (office, { memberId }) =>
        office.update('members', members => members.filterNot(member => member.get('id') === memberId)),
    [RESET_OFFICE_MEMBERS]: office => office.set('members', new OrderedSet()),
    [[SET_OFFICE_MEMBER]]: (office, action) => {
        return office.update('members', members => {
            return handleWithSubreducer(
                memberReducer,
                members,
                action,
                action.memberId || (action.member && action.member.id),
            );
        });
    },
});
