import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { PropertyVisitedActivity } from '@/graphql/generated/types';
import { trackHandler, events } from '@/app.utils/analytics';
import { fullName } from '@/app.utils/services/Helpers';
import { ContactLink, PropertyLink } from './elements/elements';
import ActivityCardWithAction from './ActivityCardWithAction';

export default function PropertyVisitedActivityCard({
    activity,
    viewAs,
}: {
    activity: PropertyVisitedActivity;
    viewAs: 'property' | 'contact';
}) {
    const title =
        viewAs === 'property' ? (
            <span>
                <FormattedMessage
                    id="interaction.visited_with.parameterised"
                    defaultMessage="Visited with {contact}"
                    values={{
                        contact: activity.visitedBy && activity.visitedBy[0] && (
                            <ContactLink
                                contact={activity.visitedBy[0]}
                                onClick={trackHandler(events.ACTIVITY_CARD_CONTACT_LINK_CLICKED, {
                                    activityType: activity.__typename,
                                })}
                            />
                        ),
                    }}
                />
            </span>
        ) : (
            <span>
                <FormattedMessage id="general.interactions.lead_visited" defaultMessage="Visited" />{' '}
                {activity.property && <PropertyLink property={activity.property} />}
            </span>
        );

    const registeredByText = (
        <FormattedMessage
            id="interaction.registered_a_visit.parameterised"
            defaultMessage="Visit registered by {contact}"
            values={{
                contact: fullName(activity.registeredBy.firstName, activity.registeredBy.lastName),
            }}
        />
    );

    const subtitle =
        viewAs === 'property' ? (
            <>
                {activity?.property?.attributes?.location?.formatted ?? (
                    <FormattedMessage id="address.no_location_defined" defaultMessage="No location defined" />
                )}
                {' — '}
                {registeredByText}
            </>
        ) : (
            <>
                {`${fullName(
                    activity.visitedBy && activity.visitedBy[0] && activity.visitedBy[0].firstName,
                    activity.visitedBy && activity.visitedBy[0] && activity.visitedBy[0].lastName,
                )} - `}
                {registeredByText}
            </>
        );

    return (
        <ActivityCardWithAction
            icon="house"
            title={title}
            subtitle={subtitle}
            date={activity.timestamp}
            activity={activity}
        />
    );
}
