import React from 'react';
import { defineMessages } from 'react-intl';
import { FormattedMessage } from 'react-intl-sweepbright';

defineMessages({
    'forms.labels.price.rent.period.month': {
        id: 'forms.labels.price.rent.period.month',
        defaultMessage: 'Month',
    },
    'forms.labels.price.rent.period.week': {
        id: 'forms.labels.price.rent.period.week',
        defaultMessage: 'Week',
    },
    'forms.labels.max_price.rent.period.month': {
        id: 'forms.labels.max_price.rent.period.month',
        defaultMessage: 'Max. Rent/Month',
    },
    'forms.labels.min_price.rent.period.month': {
        id: 'forms.labels.min_price.rent.period.month',
        defaultMessage: 'Min. Rent/Month',
    },
    'forms.labels.max_price.rent.period.week': {
        id: 'forms.labels.max_price.rent.period.week',
        defaultMessage: 'Max. Rent/Week',
    },
    'forms.labels.min_price.rent.period.week': {
        id: 'forms.labels.min_price.rent.period.week',
        defaultMessage: 'Min. Rent/Week',
    },
});

export const getPriceLabels = isSale => {
    if (isSale) {
        return {
            price: <FormattedMessage id="forms.labels.price_sell" defaultMessage="Asking Price" />,
            current_price: <FormattedMessage id="forms.labels.current_price_sell" defaultMessage="Negotiated Price" />,
        };
    }

    return {
        price: <FormattedMessage id="forms.labels.price_let" defaultMessage="Requested Rent" />,
        current_price: <FormattedMessage id="forms.labels.current_price_let" defaultMessage="Negotiated Rent" />,
    };
};
