export const FETCH_PROPERTIES = 'properties/FETCH_PROPERTIES';
export const FETCH_RELATED_PROPERTIES = 'properties/FETCH_RELATED_PROPERTIES';
export const FETCH_PROPERTY = 'properties/FETCH_PROPERTY';
export const FETCH_PROPERTY_INTERACTIONS = 'contacts/FETCH_PROPERTY_INTERACTIONS';
export const FETCH_PROPERTY_VISITS = 'contacts/FETCH_PROPERTY_VISITS';
export const MERGE_PROPERTIES = 'properties/MERGE_PROPERTIES';
export const REMOVE_PROPERTY = 'properties/REMOVE_PROPERTY';
export const RESET_PROPERTIES = 'properties/RESET_PROPERTIES';
export const SET_PROPERTIES = 'properties/SET_PROPERTIES';
export const SET_PROPERTY = 'properties/SET_PROPERTY';
export const SET_PROPERTY_INTERACTIONS = 'contacts/SET_PROPERTY_INTERACTIONS';
export const SET_PROPERTY_VISITS = 'contacts/SET_PROPERTY_VISITS';
export const UPDATE_PROPERTY = 'properties/UPDATE_PROPERTY';
export const CREATE_DOCUMENT_FOR_ESTATE = 'estates/CREATE_DOCUMENT';
export const UPDATE_ESTATE_LOOP = 'estates/UPDATE_LOOP';

export const FETCH_ESTATE_REQUESTS = 'estates/FETCH_REQUESTS';
export const RESET_ESTATE_REQUESTS = 'estates/RESET_REQUESTS';
export const SET_ESTATE_REQUESTS = 'estates/SET_REQUESTS';
export const CANCEL_ESTATE_REQUEST = 'estates/CANCEL_REQUEST';

// Visitors
export const FETCH_PROPERTY_VISITORS = 'properties/FETCH_PROPERTY_VISITORS';

// Negotiators
export const REMOVE_PROPERTY_NEGOTIATOR = 'properties/REMOVE_PROPERTY_NEGOTIATOR';

// Import
type PropertyType = 'apartment' | 'parking' | 'house';
type Negotiation = 'let' | 'sale';

interface Property {
    created_at: Date;
    id: string;
    is_archived: boolean;
    is_project: boolean;
    is_published: boolean;
    locale: string;
    negotiation: Negotiation;
    published_url: string;
    type: PropertyType;
    updated_at: Date;
}

// Properties
//////////////////////////////////////////////////////////////////////

export function fetchProperties(attributes: object, paginationKey: string = 'properties', merge: boolean = true) {
    return { type: FETCH_PROPERTIES, attributes, paginationKey, merge } as const;
}

export function setProperties(properties: Property[]) {
    return { type: SET_PROPERTIES, properties } as const;
}

export function mergeProperties(properties: Property[]) {
    return { type: MERGE_PROPERTIES, properties } as const;
}

export function resetProperties() {
    return { type: RESET_PROPERTIES } as const;
}

export function removeProperty(propertyId: string) {
    return { type: REMOVE_PROPERTY, propertyId } as const;
}

// Property
//////////////////////////////////////////////////////////////////////

export function fetchProperty(propertyId: string, options = { force: false }) {
    return { type: FETCH_PROPERTY, propertyId, options } as const;
}

export function setProperty(property: Property): any {
    return { type: SET_PROPERTY, property } as const;
}

export function setPropertyVisits(propertyId: string, visits: any[]) {
    return { type: SET_PROPERTY_VISITS, propertyId, visits } as const;
}

export function setPropertyInteractions(propertyId: string, interactions: any[]) {
    return { type: SET_PROPERTY_INTERACTIONS, propertyId, interactions } as const;
}

export function createDocumentForEstate(estateId: string, attributes: object) {
    return { type: CREATE_DOCUMENT_FOR_ESTATE, estateId, attributes } as const;
}

export function fetchEstateRequests(estateId: string, attributes: object) {
    return { type: FETCH_ESTATE_REQUESTS, estateId, attributes } as const;
}

export function setEstateRequests(estateId: string, requests: any) {
    return { type: SET_ESTATE_REQUESTS, estateId, requests } as const;
}

export function resetEstateRequests(estateId: string) {
    return { type: RESET_ESTATE_REQUESTS, estateId } as const;
}

export function cancelEstateRequest(estateId: string, requestId: string) {
    return { type: CANCEL_ESTATE_REQUEST, estateId, requestId } as const;
}
