import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Button from '@sweepbright/uikit/build/esm/button';
import Radio from '@sweepbright/uikit/build/esm/radio';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import { defineMessages, FormattedMessage } from 'react-intl-sweepbright';
import PhoneInput from '@/app.components/forms/PhoneInput/PhoneInput';
import Modal from '@/app.components/elements/Modal';
import useCompanySetting from '@/app.hooks/useCompanySetting';
import ContactTypes from '../../app.data/ContactTypes';
import ContactLocales from '../forms/ContactLocales';
import FormPanel from '../forms/FormPanel/FormPanel';
import Input from '../forms/Input/Input';
import RadioGroupInput from '../forms/Radio/RadioGroupInput';

defineMessages({
    'contact.type.lead': {
        id: 'contact.type.lead',
        defaultMessage: 'Lead',
    },
    'contact.type.vendor': {
        id: 'contact.type.vendor',
        defaultMessage: 'Vendor',
    },
    'forms.add-contact.title': {
        id: 'forms.add-contact.title',
        defaultMessage: 'Add contact',
    },
    'forms.add-lead.title': {
        id: 'forms.add-lead.title',
        defaultMessage: 'Add lead',
    },
    'forms.add-vendor.title': {
        id: 'forms.add-vendor.title',
        defaultMessage: 'Add vendor',
    },
});

const validationSchema = Yup.object().shape(
    {
        first_name: Yup.string(),
        last_name: Yup.string().required(),
        email: Yup.string()
            .email()
            .when('phone', {
                is: phone => !phone || phone.length === 0,
                then: Yup.string()
                    .email()
                    .required(),
                otherwise: Yup.string(),
            }),
        phone: Yup.string().when('email', {
            is: email => !email || email.length === 0,
            then: Yup.string().required(),
            otherwise: Yup.string(),
        }),
        locale: Yup.string()
            .length(2)
            .required(),
    },
    [['email', 'phone']],
);

export default function AddContactModalBody(props) {
    const { type, close } = props;
    const classes = {
        labelClassName: 'col-sm-4',
        wrapperClassName: 'col-sm-9',
    };

    const defaultLocale = useCompanySetting('default_locale');

    const { values, handleChange, setFieldValue, handleSubmit, isSubmitting, isValid } = useFormik({
        initialValues: {
            type: type || 'lead',
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            locale: defaultLocale,
            pronouns: null,
        },
        onSubmit: values => {
            return props.onSubmit(values).then(result => {
                return props.onSubmitSuccess?.(result);
            });
        },
        validationSchema,
        isInitialValid: false,
    });

    return (
        <form onSubmit={handleSubmit}>
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage id="forms.add-contact.title" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!type && (
                    <FormPanel title={<FormattedMessage id="forms.add-contact.type" defaultMessage="Type" />}>
                        <div className="form-group">
                            {[ContactTypes.LEAD, ContactTypes.VENDOR].map(contactType => (
                                <Radio
                                    inline
                                    key={contactType}
                                    name="type"
                                    onChange={handleChange}
                                    checked={values.type === contactType}
                                    value={contactType}
                                >
                                    <FormattedMessage id={`contact.type.${contactType}`} />
                                </Radio>
                            ))}
                        </div>
                    </FormPanel>
                )}
                <FormPanel
                    title={<FormattedMessage id="forms.add-contact.details" defaultMessage="Details" />}
                    horizontal
                >
                    <Input
                        type="text"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        {...classes}
                        label={<FormattedMessage id="forms.labels.first_name" defaultMessage="First Name" />}
                    />
                    <Input
                        type="text"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        {...classes}
                        label={<FormattedMessage id="forms.labels.last_name" defaultMessage="Last Name" />}
                    />
                    <Input {...classes} label={<FormattedMessage id="forms.labels.phone" defaultMessage="Phone" />}>
                        <PhoneInput
                            name="phone"
                            onChange={newValue => {
                                setFieldValue('phone', newValue);
                            }}
                            value={values.phone}
                        />
                    </Input>
                    <Input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        {...classes}
                        label={<FormattedMessage id="forms.labels.email" defaultMessage="Email" />}
                    />
                    <RadioGroupInput
                        name="pronouns"
                        value={values.pronouns}
                        onChange={newValue => {
                            setFieldValue('pronouns', newValue);
                        }}
                        label={<FormattedMessage id="common.forms.labels.pronouns" defaultMessage="Pronouns" />}
                        options={[
                            {
                                value: 'male',
                                label: 'common.forms.labels.pronouns.male',
                            },
                            {
                                value: 'female',
                                label: 'common.forms.labels.pronouns.female',
                            },
                            {
                                value: 'neutral',
                                label: 'common.forms.labels.pronouns.neutral',
                            },
                        ]}
                    />
                </FormPanel>
                <FormPanel title={<FormattedMessage id="forms.add-contact.language" defaultMessage="Language" />}>
                    <ContactLocales value={values.locale} onChange={handleChange} inline />
                </FormPanel>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={close} disabled={isSubmitting}>
                    <FormattedMessage id="forms.add-contact.cancel" defaultMessage="Cancel" />
                </Button>
                <Button type="submit" variant="primary" disabled={!isValid || isSubmitting}>
                    {isSubmitting ? (
                        <FormattedMessage id="forms.add-contact.cta_busy" defaultMessage="Adding..." />
                    ) : (
                        <FormattedMessage id="forms.add-contact.cta" defaultMessage="Add" />
                    )}
                </Button>
            </Modal.Footer>
        </form>
    );
}

AddContactModalBody.propTypes = {
    close: PropTypes.func,
    type: PropTypes.oneOf([ContactTypes.LEAD, ContactTypes.VENDOR]),
};

const ADD_CONTACT_MUTATION = gql`
    mutation AddContact($input: AddContactMutationInput!) {
        addContact(input: $input) {
            contact {
                id
                firstName
                lastName
                email
                phone
                locale
                pronouns
            }
        }
    }
`;

export function AddContactModal({ show, onClose, onAdded, type }) {
    const [addContact] = useMutation(ADD_CONTACT_MUTATION);

    return (
        <Modal show={show} onHide={onClose}>
            <AddContactModalBody
                type={type}
                close={onClose}
                onSubmit={values => {
                    return addContact({
                        variables: {
                            input: {
                                type: values.type,
                                firstName: values.first_name,
                                lastName: values.last_name,
                                email: values.email,
                                phone: values.phone,
                                locale: values.locale,
                                pronouns: values.pronouns,
                            },
                        },
                    }).then(res => res.data.addContact.contact);
                }}
                onSubmitSuccess={onAdded}
            />
        </Modal>
    );
}
