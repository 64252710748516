import { put } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { OfficesRequests } from '../../../requests/index';
import { setOfficeMember } from '../../actions';
import formatApiErrors from '../Helpers/formatApiErrors';
import apiCall from '../Effects/apiCall';

export default function* onUpdateOfficeMember({ officeId, memberId, attributes }) {
    try {
        yield apiCall(new OfficesRequests().updateMemberRole, officeId, memberId, attributes.role);
        yield put(
            setOfficeMember(officeId, {
                id: memberId,
                role_id: attributes.role,
            }),
        );
    } catch (response) {
        yield [put(stopSubmit('office/members', formatApiErrors(response)))];
    }
}
