import React from 'react';
import { useIntl } from 'react-intl';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage } from 'react-intl-sweepbright';
import Input from '@/app.components/forms/Input/Input';
import { ResourceDomainType, ResourceFilterOptionType, ResourceFilterType } from '@/shared/resource/types';

type Props = {
    id: string;
    query: string;
    locale: string;
    tempParams: any;
    setTempParams: any;
    filter: ResourceFilterType;
    intlKey: ResourceDomainType;
    option: ResourceFilterOptionType;
};

const ResourceFilterFormMultipleItem: React.FC<Props> = props => {
    const { id, option, query, locale, intlKey, filter, tempParams, setTempParams } = props;

    const intl = useIntl();

    const label = !option.render
        ? option.label ||
          ((!!option.translateKey || !!option.key || !!option.value) &&
              intl.formatMessage({
                  id: option.translateKey || `${intlKey}.filter.${id}.${option.key || option.value}`,
              })) ||
          ''
        : '';

    const includesDisabledValue =
        Array.isArray(filter.disabledValue) && filter.disabledValue.includes(option.value || '');

    const isChecked =
        filter.multiple || filter.type === 'checkbox'
            ? tempParams?.[filter.paramName]?.includes(option.value)
            : tempParams?.[filter.paramName] === option.value;

    const isDisabled =
        (filter.type === 'checkbox'
            ? isChecked && !filter?.allowEmpty && tempParams?.[filter.paramName]?.length === 1
            : false) || includesDisabledValue;

    if (query && !label?.toLowerCase().includes(query.toLowerCase())) {
        return null;
    }

    return (
        <div key={option.value} className={`resource-filter-form__item group ${locale}`}>
            <Input
                value={option}
                type={filter.type}
                checked={isChecked}
                disabled={isDisabled}
                label={option?.render ? option.render(option.value || '') : label}
                onChange={() => {
                    setTempParams({
                        ...tempParams,
                        ...(filter.type === 'radio'
                            ? {
                                  [filter.paramName]: filter.multiple ? [option.value] : option.value,
                              }
                            : {}),
                        ...(filter.type === 'checkbox'
                            ? {
                                  [filter.paramName]: isChecked
                                      ? (tempParams?.[filter.paramName] || []).filter(
                                            (item: string) => item !== option.value,
                                        )
                                      : [...(tempParams?.[filter.paramName] || []), option.value],
                              }
                            : {}),
                    });
                }}
            />

            {filter.type === 'checkbox' && !filter?.hideOnlyButton && (
                <Button
                    variant="link"
                    disabled={isDisabled}
                    onClick={() => {
                        setTempParams({
                            ...tempParams,
                            [filter.paramName]: [option.value],
                        });
                    }}
                    className="hidden group-hover:block resource-filter-form__only"
                >
                    <FormattedMessage id="buttons.only" defaultMessage="Only" />
                </Button>
            )}
        </div>
    );
};

export default ResourceFilterFormMultipleItem;
