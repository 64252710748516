import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { isPromise } from '@/app.utils/services/Helpers/promises';
import ConfirmationModal from '../../app.components/modals/UnsavedChangesConfirmationModal';
import setAsyncRouteLeaveHook from '../../app.routing/Utils/setAsyncRouteLeaveHook';

/**
 *   @type {
 *       (options: { shouldRequestSave: (props: any) => boolean; })
 *          => <P extends object>(Composed: React.ComponentType<P>) => React.ComponentType<P> }
 */
export const createWithConfirmation = ({ shouldRequestSave = () => true } = {}) => Composed => {
    class WithConfirmation extends Component {
        static displayName = `WithConfirmation(${Composed.displayName || Composed.name})`;

        static propTypes = {
            dirty: PropTypes.bool,
            fields: PropTypes.object,
            handleSubmit: PropTypes.func.isRequired,
            isSaving: PropTypes.bool,
            resetForm: PropTypes.func.isRequired,
            route: PropTypes.object.isRequired,
            valid: PropTypes.bool,
        };

        static contextTypes = {
            router: PropTypes.object,
        };

        state = {
            callback: null,
            saving: false,
            show: false,
        };

        handlePageUnload = evt => {
            if (this.hasUnsavedChanges() && !window.Cypress) {
                // Cancel the event as stated by the standard.
                evt.preventDefault();
                // Chrome requires returnValue to be set.
                evt.returnValue = '';
            }
        };

        hasUnsavedChanges = () => {
            if (!shouldRequestSave(this.props)) {
                return false;
            }
            const { dirty, fields } = this.props;

            return dirty || fields.dirty?.value;
        };

        componentDidMount() {
            if (this.props.route) {
                this.removeLeaveHook = setAsyncRouteLeaveHook(this.context.router, this.props.route, () => {
                    return new Promise(resolve => {
                        const { isSaving } = this.props;
                        if (!this.props.fields) {
                            return resolve(false);
                        }

                        if (!isSaving && this.hasUnsavedChanges()) {
                            this.setState({
                                callback: resolve.bind(null, true),
                            });
                            this.showPrompt();

                            return;
                        }

                        resolve(true);
                    });
                });

                window.addEventListener('beforeunload', this.handlePageUnload);
            }
        }

        componentWillUnmount() {
            if (this.removeLeaveHook) {
                this.removeLeaveHook();
            }

            window.removeEventListener('beforeunload', this.handlePageUnload);
        }

        onSave = async () => {
            this.setState({ saving: true });
            let executeCallback = false;
            try {
                const possibleSubmitPromise = this.props.handleSubmit();
                if (isPromise(possibleSubmitPromise)) {
                    await possibleSubmitPromise;

                    if (this.props.valid) {
                        executeCallback = true;
                    }
                } else {
                    // we just navigate away, even if the form could potentially be
                    // invalid or the submission could fails
                    // but we don't have a way to wait for the submission to finish
                    executeCallback = true;
                }
            } catch (err) {
                // form was invalid
                // just stay in the same page
                getBugsnagClient().notify(err);
            } finally {
                this.setState({ saving: false });
                this.hidePrompt();
                if (executeCallback) {
                    this.state.callback();
                }
            }
        };

        // discard the changes on the form and
        // navigate to the desired page
        onRevert = () => {
            this.props.resetForm();
            this.hidePrompt();
            this.state.callback();
        };

        hidePrompt = () => {
            this.setState({ show: false });
        };

        showPrompt = () => {
            this.setState({ show: true });
        };

        render() {
            return (
                <React.Fragment>
                    <Composed {...this.props} />
                    <ConfirmationModal
                        show={this.state.show}
                        onSave={this.onSave}
                        onCancel={this.hidePrompt}
                        onRevert={this.onRevert}
                        saving={this.state.saving}
                    />
                </React.Fragment>
            );
        }
    }

    return WithConfirmation;
};

const withConfirmation = createWithConfirmation();
export default withConfirmation;
