/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import ResourceFilter from '@/shared/resource/ResourceFilter';
import { ResourceDomainType } from '@/shared/resource/types';
import useLabels from '@/app.hooks/useLabels';
import ContactLabel from '@/shared/contacts/ContactLabel';
import useLabel from '@/app.hooks/useLabel';
import { Input } from '@/app.components';

type Props = {
    searchSettings: any;
    setSearchSettings: any;
    domainType: ResourceDomainType;
    translationDomainType: ResourceDomainType;
};

const FilterLabels: React.FC<Props> = props => {
    const { domainType, searchSettings, setSearchSettings, translationDomainType } = props;

    const intl = useIntl();

    const [query, setQuery] = useState('');

    const { data, loading, fetchMore } = useLabels({
        query,
    });

    const { data: labelData, error: labelError } = useLabel(searchSettings?.labels && searchSettings?.labels[0]);

    const labelName = labelData?.name;

    const content = {
        loading: intl.formatMessage({ id: 'general.state.loading' }),
        noResults: intl.formatMessage({ id: 'general.state.no_results' }),
        placeholder: intl.formatMessage({ id: 'contact.labels.modal.filter' }),
    };

    return (
        <ResourceFilter
            id="labels"
            size="large"
            domainType={domainType}
            params={searchSettings}
            setParams={setSearchSettings}
            translationDomainType={translationDomainType}
            customActiveLabel={() => {
                const labels = searchSettings?.labels || [];

                if (labelName) {
                    if (labels.length > 1) {
                        return `${labelName} (+${labels.length - 1})`;
                    }

                    return labelName || '';
                }

                if (labelError) {
                    if (labels?.length > 1) {
                        return `- (+${labels.length - 1})`;
                    }

                    if (labels?.length === 1) {
                        return '-';
                    }
                }

                return null;
            }}
            filters={[
                {
                    id: 'input',
                    type: 'custom',
                    paramName: 'labels',
                    children: () => {
                        return (
                            <Input
                                value={query}
                                onChange={e => {
                                    setQuery(e.target.value);
                                }}
                                placeholder={content.placeholder}
                            />
                        );
                    },
                },
                {
                    grid: true,
                    overflow: true,
                    type: 'checkbox',
                    allowEmpty: true,
                    paramName: 'labels',
                    showLoading: loading,
                    onScrollEnd: isScrollEnd => {
                        if (isScrollEnd && !loading) {
                            fetchMore();
                        }
                    },
                    showEmptyAlert: data.length === 0 && !loading,
                    options: data.map(el => {
                        return {
                            value: el.id,
                            render: (id: string) => {
                                return (
                                    <ContactLabel
                                        tooltip
                                        key={id}
                                        truncate
                                        label={el.name}
                                        color={el.color}
                                        description={el.description}
                                    />
                                );
                            },
                        };
                    }),
                },
            ]}
        />
    );
};

export default FilterLabels;
