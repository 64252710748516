import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import { reduxForm as form } from 'redux-form';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl-sweepbright';
import useLocalStorage from '@/app.hooks/useLocalStorage';
import { getEnabledLocales } from '@/app.redux/selectors/PropertiesSelectors';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';
import FormPane from '@/app.components/forms/FormPane';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { withErrorBoundary } from '@/app.components/errors/ErrorBoundary';
import { withActivePropertyConfirmation } from '@/app.utils/Decorators/withActivePropertyConfirmation';
import PropertiesDescriptionAI from '@/new.domains/properties/PropertyForm/PropertyFormDescription/PropertiesDescriptionAI';
import { PropertyFormDescriptionContext } from '@/new.domains/properties/contexts/PropertyFormDescriptionContext';
import { usePropertiesDescriptionAI } from '@/new.domains/properties/hooks/usePropertiesDescriptionAI';
import Validator from '../../../../app.utils/services/Validator';
import CharactersCountingInput from '../../../../app.components/forms/CharactersCountingInput';
import FormPanel from '../../../../app.components/forms/FormPanel/FormPanel';
import LocalizedInput, { localizeToFieldsObject } from '../../../../app.components/forms/LocalizedInput';
import Separator from '../../../../app.components/elements/Separator';

type Props = {
    fields: {
        description: any;
    };
    handleSubmit: any;
    status: any;
    property: any;
    editingDisabled: any;
    supportedLangs: any[];
};

const DescriptionForm: React.FC<Props> = props => {
    const {
        fields: { description },
        handleSubmit,
        status,
        property,
        editingDisabled,
        supportedLangs,
    } = props;

    const intl = useIntl();

    const showOpenAI = useFeatureFlag('descriptionAI');

    const [visible, setVisible] = useState('');

    const [generateSettings, setGenerateSettings] = useLocalStorage<{
        showLocation: boolean;
        showPrice: boolean;
        tag1?: boolean;
        tag2?: boolean;
        tag3?: boolean;
        tag4?: boolean;
        tag5?: boolean;
    }>('properties.description.settings', {
        showLocation: true,
        showPrice: true,
    });

    const [generateLoading, setGenerateLoading] = useState(false);
    const [translateLoading, setTranslateLoading] = useState(false);

    const content = {
        generate: intl.formatMessage({
            id: 'description.ai.generate',
        }),
        translate: intl.formatMessage({
            id: 'description.ai.translate',
        }),
        showLocation: intl.formatMessage({
            id: 'forms.publish.settings.location',
        }),
        showPrice: intl.formatMessage({
            id: 'forms.publish.settings.price',
        }),
        extraText: intl.formatMessage({
            id: 'description.ai.text',
        }),
        save: intl.formatMessage({
            id: 'general.action.save',
        }),
        saving: intl.formatMessage({
            id: 'form.status.saving',
        }),
        cancel: intl.formatMessage({
            id: 'general.action.cancel',
        }),
        hidePrice: intl.formatMessage({
            id: 'description.ai.hidePrice',
        }),
        hideLocation: intl.formatMessage({
            id: 'description.ai.hideLocation',
        }),
        tag1: intl.formatMessage({
            id: 'description.ai.tag.1',
        }),
        tag2: intl.formatMessage({
            id: 'description.ai.tag.2',
        }),
        tag3: intl.formatMessage({
            id: 'description.ai.tag.3',
        }),
        tag4: intl.formatMessage({
            id: 'description.ai.tag.4',
        }),
        tag5: intl.formatMessage({
            id: 'description.ai.tag.5',
        }),
        tag1Text: intl.formatMessage({
            id: 'description.ai.tag.1.text',
        }),
        tag2Text: intl.formatMessage({
            id: 'description.ai.tag.2.text',
        }),
        tag3Text: intl.formatMessage({
            id: 'description.ai.tag.3.text',
        }),
        tag4Text: intl.formatMessage({
            id: 'description.ai.tag.4.text',
        }),
        tag5Text: intl.formatMessage({
            id: 'description.ai.tag.5.text',
        }),
    };

    const { handleGenerate, handleTranslate } = usePropertiesDescriptionAI({
        content,
        property,
        setVisible,
        description,
        supportedLangs,
        generateSettings,
        setGenerateLoading,
        setTranslateLoading,
    });

    const showTranslate = supportedLangs.length > 1;

    return (
        <FormPane
            {...({} as any)}
            status={status}
            onSubmit={handleSubmit}
            disabled={editingDisabled}
            disabledText={<FormattedMessage id="property.status.archived" defaultMessage="Archived" />}
            title={
                property.get('isProject') ? (
                    <FormattedMessage id="titles.project.description" defaultMessage="Describe the project" />
                ) : (
                    <FormattedMessage id="titles.property.description" defaultMessage="Describe the property" />
                )
            }
        >
            <FormPanel>
                <LocalizedInput
                    // @ts-ignore
                    maxLength={255}
                    componentClass="input"
                    field={description.title}
                    component={CharactersCountingInput}
                    label={<FormattedMessage id="forms.labels.title" defaultMessage="Title" />}
                />
                <Separator />
                <LocalizedInput
                    // @ts-ignore
                    type="autosize-textarea"
                    field={description.description}
                    component={CharactersCountingInput}
                    extraBottom={
                        showOpenAI && !editingDisabled
                            ? fieldName => {
                                  return (
                                      <PropertyFormDescriptionContext.Provider
                                          value={{
                                              content,
                                              visible,
                                              fieldName,
                                              setVisible,
                                              showTranslate,
                                              handleGenerate,
                                              handleTranslate,
                                              generateLoading,
                                              translateLoading,
                                              generateSettings,
                                              setGenerateSettings,
                                          }}
                                      >
                                          <PropertiesDescriptionAI />
                                      </PropertyFormDescriptionContext.Provider>
                                  );
                              }
                            : undefined
                    }
                    label={<FormattedMessage id="forms.labels.description" defaultMessage="Description" />}
                />
            </FormPanel>
        </FormPane>
    );
};

export default compose(
    withErrorBoundary,
    withProps((props: any) => ({
        estateId: props.params.unit || props.params.property,
    })),
    withPropertyDetails('description'),
    connect(
        createStructuredSelector({
            supportedLangs: getEnabledLocales,
        }),
    ),
    form({
        form: 'properties/description',
        fields: localizeToFieldsObject(['description.title', 'description.description']),
        validate: Validator.validateWithLocalizedPropertySchema,
    }),
    withActivePropertyConfirmation,
)(DescriptionForm);
