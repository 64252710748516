import { fromJS } from 'immutable';

/**
 * Passes attributes through a sequence of middlewares
 * before passing them to the configured onSubmit
 */
export default (handleSubmit, ...middlewares) => onSubmit =>
    handleSubmit(_attributes => {
        let attributes = fromJS(_attributes);
        attributes = middlewares.reduce((reduction, middleware) => middleware(reduction), attributes);

        return onSubmit(attributes.toJS());
    });
