// @ts-nocheck
import React from 'react';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import { visitDurationOptions } from '@/app.data/visits';

type Props = {
    value: number;
    onChange?: (value: number) => void;
    id?: string;
    name?: string;
};

function DurationSelector({ value, onChange, id, name }: Props) {
    return (
        <Select
            value={value}
            options={visitDurationOptions}
            isSearchable={false}
            simpleValue
            onChange={onChange}
            id={id}
            name={name}
            testId="visit_duration"
        />
    );
}

export default React.memo(DurationSelector);
