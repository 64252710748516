import { FormikErrors } from 'formik';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';

interface TimeRangeFormValues {
    date: string;
    start: string;
    end: string;
}

export function validateTimeRange(values: TimeRangeFormValues): FormikErrors<TimeRangeFormValues> | undefined {
    const errors: FormikErrors<TimeRangeFormValues> = {};

    if (values.date === '') {
        errors.date = forceString(
            <FormattedMessage id="forms.errors.missing_date" defaultMessage="Please enter date" />,
        );
    }

    if (values.start === '') {
        errors.start = forceString(
            <FormattedMessage id="forms.errors.missing_time" defaultMessage="Please enter time" />,
        );
    }
    if (values.end === '') {
        errors.end = forceString(
            <FormattedMessage id="forms.errors.missing_time" defaultMessage="Please enter time" />,
        );
    }

    if (isEndBeforeStart(values)) {
        errors.end = forceString(
            <FormattedMessage
                id="forms.errors.time_after"
                defaultMessage="The end time must be after the start time."
            />,
        );
    }

    return errors;
}

function forceString(value: any): string {
    return value;
}

function isEndBeforeStart({ date, end, start }: TimeRangeFormValues): boolean {
    const mStart = getTimeFromDateAndTimeInput(date, start);
    const mEnd = getTimeFromDateAndTimeInput(date, end);

    if (!mStart || !mEnd) {
        return false;
    }

    return mEnd.isBefore(mStart) || mEnd.isSame(mStart);
}

export function formatDate(m: Moment | undefined): string {
    return m?.isValid() ? m.format('MMMM DD, YYYY') : '';
}

export function formatDateForPicker(m: Moment | undefined): string {
    return m?.isValid() ? m.format('YYYY-MM-DD') : '';
}

export function formatTime(m: Moment | undefined): string {
    return m?.isValid() ? m.format('HH:mm') : '';
}

function getTimeFromDateAndTimeInput(date: string | undefined, time: string | undefined): Moment | null {
    if (!date?.length || !time?.length) {
        return null;
    }

    return moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm');
}

export function getTimeFromDateAndMinutes(
    shortDate: string | undefined,
    minutesShift: number | undefined = 0,
): Moment | undefined {
    if (!shortDate) {
        return undefined;
    }
    const baseDate = moment.utc(shortDate, 'YYYY-MM-DD');

    const result = baseDate
        .clone()
        .add(minutesShift, 'minutes')
        .local();

    return result;
}
