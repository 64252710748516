import React from 'react';
import { useIntl } from 'react-intl';
import Link from '@/app.components/elements/Link';
import { Icon } from '@/app.components';
import { TEAMS } from '@/app.routing';

type Props = {
    office: any;
};

const TeamWrapperInfo: React.FC<Props> = props => {
    const { office } = props;

    const intl = useIntl();

    return (
        <>
            <div
                className="properties-image team-wrapper-info"
                style={{
                    height: '190px',
                    backgroundColor: '#8e8e93',
                    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
                }}
            >
                <Link to={TEAMS} className="c-property-header__back-btn">
                    <ArrowLeftIcon /> <span>{intl.formatMessage({ id: 'teams.title' })}</span>
                </Link>

                <Icon icon="units" size={96} style={{ color: '#AAAAAE' }} />
            </div>
            <div className="c-property-info__content">
                <h4 className="c-property-info__title truncate">{office.get('name')}</h4>
            </div>
        </>
    );
};

function ArrowLeftIcon() {
    return (
        <svg
            height="18"
            viewBox="0 0 22 18"
            width="22"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <path
                    id="a"
                    d="m20 11.5h-14.8l5.1-5.1c.2-.2.2-.5 0-.7s-.5-.2-.7 0l-6 6s-.1.1-.1.2c-.1.1-.1.3 0 .4 0 .1.1.1.1.2l6 6c.1.1.2.1.4.1s.3 0 .4-.1c.2-.2.2-.5 0-.7l-5.1-5.1h14.7c.3 0 .5-.2.5-.5s-.2-.7-.5-.7z"
                />
                <mask id="b" fill="#fff">
                    <use fill="#fff" fillRule="evenodd" xlinkHref="#a" />
                </mask>
            </defs>
            <g fill="#fff" fillRule="evenodd" transform="translate(-2 -4)">
                <use xlinkHref="#a" />
                <path d="m0 0h24v24h-24z" mask="url(#b)" />
            </g>
        </svg>
    );
}

export default TeamWrapperInfo;
