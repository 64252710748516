export const FETCH_PLANS = 'plans/FETCH_PLANS';
export const SET_PLANS = 'plans/SET_PLANS';
export const FETCH_COMPANY_CARD = 'subscription/FETCH_COMPANY_CARD';
export const SET_COMPANY_CARD = 'subscription/SET_COMPANY_CARD';
export const UPDATE_COMPANY_CARD = 'subscription/UPDATE_COMPANY_CARD';

export const UPGRADE_SUBSCRIPTION = 'subscription/UPGRADE';

export function updateCompanyCard(attributes) {
    return { type: UPDATE_COMPANY_CARD, attributes };
}

export function fetchCompanyCard(companyId) {
    return { type: FETCH_COMPANY_CARD, companyId };
}

export function setCompanyCard(companyId, card) {
    return { type: SET_COMPANY_CARD, companyId, card };
}

export function fetchPlans(companyId) {
    return { type: FETCH_PLANS, companyId };
}

export function setPlans(plans) {
    return { type: SET_PLANS, payload: plans };
}

export function upgradeSubscription(attributes) {
    return { type: UPGRADE_SUBSCRIPTION, attributes };
}
