import { DialogContent, DialogOverlay } from '@reach/dialog';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import { useTransition } from '@/app.hooks/useCSSTransition';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';

const Header = function ModalHeader({ children }: { children: React.ReactNode }) {
    return <div className="p-4 border-b border-gray-300">{children}</div>;
};

const Body = function ModalBody({
    children,
    className,
    style,
}: {
    className?: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
}) {
    return (
        <div
            className={classNames('p-4 overflow-y-auto', className)}
            style={{ maxHeight: 'calc(100vh - 150px)', ...style }}
        >
            {children}
        </div>
    );
};

const Footer = function ModalFooter({
    children,
    className,
    style,
}: {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}) {
    return (
        <div className={classNames('p-4 border-t border-gray-300 text-right space-x-1', className)} style={style}>
            {children}
        </div>
    );
};

const Title: FC<React.HTMLProps<HTMLHeadElement> & { component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h6' }> = ({
    children,
    className,
    component: C = 'h4',
}) => {
    return <C className={classNames('m-0 p-0 font-normal', className)}>{children}</C>;
};

const overlayTransitionClasses = {
    enter: 'transition ease-out duration-150 transform opacity-0',
    enterActive: 'transition ease-out duration-150 transform opacity-100',
    enterDone: 'opacity-100',
    exit: 'transition ease-in duration-150 transform opacity-100',
    exitActive: 'transition ease-in duration-150 transform opacity-0',
    exitDone: 'opacity-0',
};

const transitionClasses = {
    enter: 'transition ease-out duration-150 transform -translate-y-10',
    enterActive: 'transition ease-out duration-150 transform translate-y-0',
    enterDone: 'translate-y-0',
    exit: 'transition ease-in duration-150 transform',
    exitActive: 'transition ease-in duration-150 transform translate-y-1',
    exitDone: 'translate-y-1',
};

type ModalProps = {
    show: boolean;
    onHide: () => void;
    children: React.ReactNode;
    dialogClassName?: string;
    className?: string;
    onEntered?: () => void;
    onExiting?: () => void;
    onExited?: () => void;
    onEnter?: () => void;
    onEntering?: () => void;
    onExit?: () => void;
    testId?: string;
    autoFocus?: boolean;
    style?: React.CSSProperties;
    width?: 'default' | 'xl' | 'full' | '2xl' | '3xl' | number;
};

type Modal = React.FunctionComponent<ModalProps> & {
    Title: typeof Title;
    Header: typeof Header;
    Footer: typeof Footer;
    Body: typeof Body;
};

const Modal: Modal = (function Modal({
    children,
    show,
    onHide,
    dialogClassName,
    className,
    onEnter,
    onEntering,
    onEntered,
    onExiting,
    onExited,
    onExit,
    testId,
    autoFocus = false,
    style,
    width = 'default',
}) {
    const [status, setStatus] = React.useState('exitDone');

    // The reach dialog component focus the first focusable element
    // by default, this is generally the desired behavior but
    // in order to keep compatibility with the boostrap modals
    // behavior we are disabling it but moving the focus to the whole dialog
    const focusRef = React.useRef<HTMLDivElement>(null);

    useTransition({
        in: show,
        timeout: 300,
        onEnter: () => {
            // eslint-disable-next-line no-unused-expressions
            onEnter?.();
            setStatus('enter');
        },
        onEntering: () => {
            // eslint-disable-next-line no-unused-expressions
            onEntering?.();
            setStatus('enterActive');
        },
        onEntered: () => {
            setStatus('enterDone');
            // eslint-disable-next-line no-unused-expressions
            onEntered?.();
        },
        onExit: () => {
            setStatus('exit');
            // eslint-disable-next-line no-unused-expressions
            onExit?.();
        },
        onExiting: () => {
            setStatus('exitActive');
            // eslint-disable-next-line no-unused-expressions
            onExiting?.();
        },
        onExited: () => {
            setStatus('exitDone');
            // eslint-disable-next-line no-unused-expressions
            onExited?.();
        },
    });

    const dialogContentStyle = useMemo(
        () => ({ ...(style || {}), maxWidth: typeof width === 'number' ? width : undefined }),
        [style, width],
    );

    return status !== 'exitDone' ? (
        <DialogOverlay
            style={{ backgroundColor: 'rgba(0,0,0,0.50)' }}
            className={classNames(
                'absolute z-10 top-0 left-0 w-full h-full flex flex-col items-center justify-center px-20',
                dialogClassName,
                overlayTransitionClasses[status],
            )}
            initialFocusRef={autoFocus ? undefined : focusRef}
            onDismiss={onHide}
        >
            <DialogContent
                ref={focusRef}
                className={classNames(
                    'outline-none bg-white w-full h-auto max-h-screen rounded-md overflow-hidden shadow',
                    {
                        'max-w-2xl': width === 'default',
                        'max-w-5xl': width === 'xl',
                        'max-w-6xl': width === '2xl',
                        'max-w-7xl': width === '3xl',
                        'max-w-full': width === 'full',
                    },
                    className,
                    transitionClasses[status],
                )}
                data-testid={testId}
                style={dialogContentStyle}
            >
                <ErrorBoundary>{children}</ErrorBoundary>
            </DialogContent>
        </DialogOverlay>
    ) : null;
} as unknown) as Modal;

Modal.Title = Title;
Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
