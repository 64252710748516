import React, { useRef, useState } from 'react';
import ButtonIcon from '@/app.components/elements/Buttons/ButtonIcon';
import Popover from '@/app.components/elements/Popover';
import '@/app.domains/properties/PropertyFeatures.scss';

type Props = {
    note: string;
};

const ContactsTableNote: React.FC<Props> = props => {
    const { note } = props;

    const ref = useRef<any>(null);

    const [showPopover, setShowPopover] = useState(false);

    const value = note.trim().length > 300 ? `${note.slice(0, 300).trim()}...` : note;

    return (
        <div className="contacts-table-note">
            <ButtonIcon
                ref={ref}
                variant="link"
                type="circle-info"
                onClick={() => setShowPopover(true)}
                className="contacts-table-note__icon"
            />
            <Popover
                show={showPopover}
                placement="left"
                onClickOutside={() => {
                    setShowPopover(false);
                }}
                referenceElement={ref.current}
                className="c-popover__content c-popover__content--medium"
            >
                <div className="contacts-table-note__content">{value}</div>
            </Popover>
        </div>
    );
};

export default ContactsTableNote;
