import { useQuery } from '@apollo/react-hooks';
import classNames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-sweepbright';
import { ESTATE_CHANNEL_ACCOUNT_QUERY } from '@/graphql/queries/properties/getEstateChannelAccounts';
import {
    ConfigurationStatus,
    GetEstateChannelAccountQuery,
    GetEstateChannelAccountQueryVariables,
} from '@/graphql/generated/types';
import ChannelImage from '@/app.shared/channels/ChannelImage';
import { getBugsnagClient } from '@/app.config/bugsnag';
import MediaCard from '@/app.components/card/MediaCard/MediaCard';

defineMessages({
    [Symbol()]: {
        id: 'ad_status.validation.immoweb_v2.project.min_2_units_error',
        defaultMessage: 'The project needs at least two units',
    },
});

export enum ChannelStatuses {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    PENDING = 'pending',
}

const statusIcons = {
    // publish status
    PUBLISHED: 'checked',
    PUBLISHING: 'busy',
    OUTDATED: 'changed',
    // config status
    MISSING_FIELDS: 'error',
    NOT_SUPPORTED: 'error',
    MISSING_UNITS: 'error',
};

export function ChannelCard({
    propertyId,
    unitId,
    channelAccountId,
}: {
    channelAccountId: string;
    propertyId: string;
    unitId?: string;
}) {
    const [field] = useField('channelAccountId');

    const { data } = useQuery<GetEstateChannelAccountQuery, GetEstateChannelAccountQueryVariables>(
        ESTATE_CHANNEL_ACCOUNT_QUERY,
        {
            variables: {
                estateId: unitId || propertyId,
                channelAccountId: channelAccountId,
            },
            onError(error) {
                getBugsnagClient().notify(error);
            },
            fetchPolicy: 'cache-and-network',
            returnPartialData: true,
            partialRefetch: true,
            notifyOnNetworkStatusChange: true,
        },
    );

    const estateChannelAccount = data?.estate?.channelAccount;

    if (!estateChannelAccount) {
        return null;
    }

    const {
        node: { status },
        node: account,
        configurationStatus,
        configurationStatusMessageI18Key,
    } = estateChannelAccount;

    // Pending status of the channel account should be also handled here.
    // Currently, API doesn't provide one.
    const disabled =
        configurationStatus === ConfigurationStatus.NotSupported ||
        configurationStatus === ConfigurationStatus.MissingUnits ||
        status === ChannelStatuses.INACTIVE;

    const statusIcon = statusIcons[configurationStatus];
    const image = <ChannelImage key="image" channel={account.channel} status={statusIcon} disabled={disabled} />;

    const title = <span className="inline-flex items-baseline">{account.name}</span>;

    return (
        <li
            className={classNames('flex items-center h-20', {
                'cursor-not-allowed': disabled,
            })}
        >
            <input
                type="radio"
                id={channelAccountId}
                name="channelAccountId"
                value={channelAccountId}
                checked={field.value === channelAccountId}
                onChange={field.onChange}
                disabled={disabled}
            />
            <label
                htmlFor={channelAccountId}
                className={classNames('w-full', {
                    'pointer-events-none': disabled,
                })}
            >
                <div>
                    <MediaCard image={image} title={title} subtitles={[null, getStatus()]} />
                </div>
            </label>
        </li>
    );

    function getStatus() {
        if (configurationStatus === ConfigurationStatus.NotSupported) {
            // FixMe: for now this is correct but in the future,
            // it could be that the NotSupported status is due to a different thing
            return (
                <FormattedMessage
                    id="publish.project.cant_publish_projects"
                    defaultMessage="Can not publish projects"
                />
            );
        }

        if (configurationStatus === ConfigurationStatus.MissingUnits) {
            if (configurationStatusMessageI18Key) {
                return <FormattedMessage id={configurationStatusMessageI18Key} defaultMessage="Missing units" />;
            }

            return (
                <FormattedMessage
                    id="publish.project.no_units_error"
                    defaultMessage="The project needs at least one unit"
                />
            );
        }

        return <FormattedMessage id="property.unpublished" defaultMessage="Not published" />;
    }
}
