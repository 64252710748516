import React, { useState, useEffect } from 'react';
import { useDebounce } from 'react-use';
import { useQuery } from '@apollo/react-hooks';
import PropertyCard, { adaptProperty } from '@/app.domains/properties/PropertyCard';
import EmptyAlert from '@/app.components/empty/EmptyAlert';
import useContact from '@/app.hooks/useContact';
import { FormattedMessage } from 'react-intl-sweepbright';
import { MAX_QUERY_LENGTH } from '@/app.config/search';
import useOffice from '@/app.hooks/useOffice';
import useProperty from '@/app.hooks/useProperty';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { GET_OFFICE_ESTATES_QUERY } from '@/graphql/queries/properties/getOfficeEstates';
import Select from '../../../app.components/selects/AdvancedSelect/Select';

function propertyToItem(property) {
    if (property) {
        property = adaptProperty(property?.node || property);

        return {
            value: property.get('id'),
            label: property.get('id'),
            card: <PropertyCard property={property} />,
            property,
        };
    }

    return undefined;
}

function PropertySelect({
    onSelect,
    disabled,
    hasError,
    contactId,
    excluded = [],
    value,
    disabledShared,
}: {
    contactId?: string;
    onSelect: (property) => void;
    // an array of excluded property ids
    excluded?: string[];
    disabled?: boolean;
    hasError?: boolean;
    value: string;
    disabledShared?: boolean;
}) {
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [scrolled, setScrolled] = useState(false);
    const [finished, setFinished] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [debouncedQuery, setDebouncedQuery] = useState('');

    useDebounce(
        () => {
            setPage(1);
            setFinished(false);
            setDebouncedQuery(query);
        },
        500,
        [query],
    );

    const { contact } = useContact(contactId);
    const { property } = useProperty(value);
    const { estates = [], loading = true } = useEstates({ page, query: debouncedQuery, disabledShared });

    useEffect(() => {
        if (scrolled && !finished) {
            setPage(prevPage => prevPage + 1);
        }
    }, [scrolled, finished]);

    useEffect(() => {
        if (!loading) {
            if (estates.length > 0) {
                if (page === 1) {
                    setData(estates);
                }

                if (page > 1) {
                    setData(prevData => [...(prevData || []), ...estates]);
                }
            }

            if (estates.length === 0) {
                setFinished(true);
            }
        }

        // eslint-disable-next-line
    }, [page, loading, estates]);

    // TODO: handle the case when there are no properties on the office

    const formattedData = data
        .filter(property => !excluded.includes(property?.node?.id || property?.id))
        .map(propertyToItem);

    if (formattedData.length === 0 && !loading && !query) {
        return (
            <EmptyAlert>
                <FormattedMessage
                    id="vendor.no_linked_properties"
                    defaultMessage="Oops, looks like there are no properties linked to this vendor yet."
                />
            </EmptyAlert>
        );
    }

    const placeholder = <FormattedMessage id="property_picker.placeholder" defaultMessage='Search "Abbey Road"' />;

    return (
        <Select
            // @ts-ignore
            options={formattedData}
            hasError={hasError}
            disabled={disabled}
            onScroll={isScrollEnd => {
                setScrolled(isScrollEnd);
            }}
            optionRenderer={option => option.card}
            filterOptions={false}
            maxInputLength={MAX_QUERY_LENGTH}
            simpleValue
            onChange={onSelect}
            selectedItem={propertyToItem(property)}
            inputValue={query}
            onInputChange={(newQuery: string) => {
                setQuery(newQuery);
            }}
            loading={loading}
            valueRenderer={(option, { isOpen }) => {
                if (isOpen) {
                    return <span className="text-muted">{placeholder}</span>;
                }

                // @ts-ignore
                return option?.card ?? null;
            }}
            noResultsText={({ inputValue }: { inputValue?: string }) => {
                if ((inputValue?.length ?? 0) >= 3) {
                    return (
                        <>
                            <FormattedMessage id="general.state.no_results" defaultMessage="No results." />
                            {contact?.type === 'VENDOR' && <br />}
                            {contact?.type === 'VENDOR' && (
                                <span className="text-muted">
                                    <FormattedMessage
                                        id="vendor.search_properties.no_results.help"
                                        defaultMessage="Make sure the property is associated with this vendor."
                                    />{' '}
                                </span>
                            )}
                        </>
                    );
                }

                if (!inputValue || inputValue.length === 0) {
                    return <FormattedMessage id="forms.contacts.properties.empty" defaultMessage="No properties" />;
                }

                return (
                    <FormattedMessage
                        id="vendor.search_properties.min_query_length"
                        defaultMessage="Type at least 3 characters"
                    />
                );
            }}
        />
    );
}

export default React.memo(PropertySelect);

function useEstates({ page, query, disabledShared }: { page?: number; query?: string; disabledShared?: boolean }) {
    const officeId = useOffice().get('id');

    const atlasSearch = useFeatureFlag('properties-atlas-search');
    const showInternalTypeFilter = useFeatureFlag('property.new.internalType.enabled');
    const showPropertySharedFilter = useFeatureFlag('property.shared.enabled');

    const allEstatesQueryResult = useQuery(GET_OFFICE_ESTATES_QUERY, {
        variables: {
            query,
            filters: {
                officeId,
                archived: false,
                ...(showInternalTypeFilter
                    ? {
                          property_internal_type: ['standalone', 'unit'],
                      }
                    : {}),
                ...(showPropertySharedFilter || typeof disabledShared === 'boolean'
                    ? {
                          visibility: disabledShared ? ['team'] : ['team', 'company'],
                      }
                    : {}),
                ...(query
                    ? {}
                    : {
                          sortOrder: 'desc',
                          sortField: 'updated_at',
                      }),
            },
            limit: 10,
            page,
            useAtlasSearch: atlasSearch,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    });

    return {
        estates: allEstatesQueryResult.data?.estates?.edges,
        loading: allEstatesQueryResult.loading,
    };
}
