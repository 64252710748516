// @ts-nocheck
import React from 'react';
import { reduxForm, FormProps } from 'redux-form';
import { Map } from 'immutable';
import { HelpBlock, Alert } from 'react-bootstrap';
import Button from '@sweepbright/uikit/build/esm/button';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl-sweepbright';
import { FormFields } from '@/../shared/utils/types';
import { signInToChannel } from '@/app.redux/actions';
import { wrapWithPromise } from '../../../app.utils/services';
import FormPanel from '../../forms/FormPanel/FormPanel';
import Input from '../../forms/Input/Input';
import PortalConfigModalBody from './PortalConfigModalBody';
import { validateSocialPortals } from './utils';

type Props = {
    fields: FormFields<{
        name: string;
        credentials: {
            accessToken: string;
            accessTokenSecret: string;
            name: string;
        };
    }>;
    portal: Map<string, any>;
    editing: boolean;
    close: (evt?: any) => void;
    showNameField?: boolean;
    signIn: (attributes: { channel: string; missingPermissions?: boolean }) => void;
};

const TwitterImpl: React.FunctionComponent<Props & FormProps> = ({
    fields,
    error,
    submitFailed,
    handleSubmit,
    editing,
    ...props
}) => {
    function handleLoginToTwitter() {
        wrapWithPromise(props.signIn)({
            channel: 'twitter',
        })
            .then(
                (res: {
                    access_token: string;
                    secret: string;
                    profile: { username: string; displayName: string; photos: [{ value: string }] };
                }) => {
                    fields.credentials.accessToken.onChange(res.access_token);
                    fields.credentials.accessTokenSecret.onChange(res.secret);
                    fields.credentials.name.onChange(`@${res.profile.username}`);
                },
            )
            .catch(() => {
                // TODO: handle error
            });
    }

    return (
        <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
            {submitFailed && error ? <Alert bsStyle="danger">{error} </Alert> : null}
            <FormPanel>
                {!fields.credentials.accessTokenSecret.value ? (
                    <>
                        <Button onClick={handleLoginToTwitter} variant="primary">
                            <FormattedMessage
                                id="forms.social.link.cta"
                                defaultMessage="Connect {channel}"
                                values={{ channel: 'Twitter' }}
                            />
                        </Button>
                        <HelpBlock>
                            <FormattedMessage
                                id="forms.social.link.help"
                                defaultMessage="Add your company’s {channel} account as one of the channels you will publish your listings to."
                                values={{ channel: 'Twitter' }}
                            />
                        </HelpBlock>
                        <div className="c-spacer-s" />
                    </>
                ) : (
                    <TwitterProfile username={fields.credentials.name.value} />
                )}
                <Input
                    {...fields.name}
                    label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                    help={
                        <FormattedMessage
                            id="channels.name.description"
                            defaultMessage="This is the name you will see in the Publish section of SweepBright."
                        />
                    }
                />
            </FormPanel>
        </PortalConfigModalBody>
    );
};

const Twitter = reduxForm(
    {
        form: 'channels/social',
        formKey: 'twitter',
        fields: ['name', 'credentials.accessToken', 'credentials.accessTokenSecret', 'credentials.name'],
        validate: validateSocialPortals,
    },
    null,
    {
        signIn: signInToChannel,
    },
)(TwitterImpl);

Twitter.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
            credentials {
                accessToken
                accessTokenSecret
                name
            }
        }
    `,
};

export default Twitter;

function TwitterProfile({ username }) {
    return (
        <div>
            <p>
                <FormattedMessage id="connection.connected_as" defaultMessage="Connected as:" />{' '}
                <span className="text-uppercase text-info">{username}</span>
            </p>
        </div>
    );
}
