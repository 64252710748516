import Checkbox from '@sweepbright/uikit/build/esm/checkbox';
import Radio from '@sweepbright/uikit/build/esm/radio';
import React from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl-sweepbright';
import useOffice from '@/app.hooks/useOffice';
import { PropertyTypeSection } from '@/app.components/forms/PropertyTypesSection';
import Input from '@/app.components/forms/Input/Input';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import useCompany from '@/app.hooks/useCompany';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { events, track } from '@/app.utils/analytics';
import { useToasts } from '@sweepbright/notifications';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { PreferenceFieldVisibility } from '@/app.utils/services/Fields/PreferenceFieldVisibility';

export default function GeneralTabPanel({
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    contactId,
    preferenceId,
    onDeleted,
}) {
    const showAreas = useFeatureFlag('contact.preferences.areas.enabled');

    const rentPeriod = useCompany().getIn(['settings', 'data', 'rent_period']);
    const currency = useOffice().getIn(['settings', 'data', 'currency']);
    const isSale = values.negotiation === 'sale';
    const step = isSale ? 1000 : 100;

    const maxRentPriceLabel = (
        <FormattedMessage
            id={`pages.contacts.forms.labels.max_price.rent.period.${rentPeriod}`}
            defaultMessage={`pages.contacts.forms.labels.max_price.rent.period.${rentPeriod}`}
        />
    );
    const minRentPriceLabel = (
        <FormattedMessage
            id={`pages.contacts.forms.labels.min_price.rent.period.${rentPeriod}`}
            defaultMessage={`pages.contacts.forms.labels.min_price.rent.period.${rentPeriod}`}
        />
    );

    const allAreas = [
        'max_liveable_area',
        'min_liveable_area',
        'max_plot_area',
        'min_plot_area',
        'max_net_area',
        'min_net_area',
        'max_gross_area',
        'min_gross_area',
    ];

    return (
        <>
            <FormPanel horizontal title="General">
                <Input
                    label={
                        <FormattedMessage id="pages.contacts.forms.labels.negotiation" defaultMessage="Looking for" />
                    }
                    value={values.negotiation}
                    horizontal
                >
                    <Radio
                        inline
                        value="sale"
                        name="negotiation"
                        onChange={handleChange}
                        checked={values.negotiation === 'sale'}
                    >
                        <FormattedMessage id="pages.contacts.forms.inputs.buy" defaultMessage="Buy" />
                    </Radio>
                    <Radio
                        inline
                        value="let"
                        name="negotiation"
                        onChange={handleChange}
                        checked={values.negotiation === 'let'}
                    >
                        <FormattedMessage id="pages.contacts.forms.inputs.rent" defaultMessage="Rent" />
                    </Radio>
                </Input>
            </FormPanel>
            <PropertyTypeSection
                value={values.type}
                onChange={evt => {
                    handleChange(evt);

                    if (showAreas) {
                        allAreas.forEach(area => {
                            setFieldValue(area, null);
                        });
                    }
                }}
            />
            <FormPanel
                horizontal
                title={<FormattedMessage id="modals.contacts_search.price_panel.title" defaultMessage="Budget" />}
            >
                <Input
                    type="price"
                    value={values.budget?.maxPrice}
                    label={
                        isSale ? (
                            <FormattedMessage id="pages.contacts.forms.labels.max_price" defaultMessage="Max. Price" />
                        ) : (
                            maxRentPriceLabel
                        )
                    }
                    data-testid="budget_max_value"
                    addonAfter={currency}
                    horizontal
                    onChange={value => {
                        if (value != null && parseFloat(value)) {
                            setFieldValue('budget.maxPrice', parseFloat(value));
                        } else {
                            setFieldValue('budget.maxPrice', null);
                        }
                    }}
                    step={step}
                    decimalScale={2}
                    touched={touched.budget?.maxPrice}
                    error={errors.budget?.maxPrice}
                />
                <Input
                    type="price"
                    value={values.budget?.minPrice}
                    label={
                        isSale ? (
                            <FormattedMessage id="pages.contacts.forms.labels.min_price" defaultMessage="Min. Price" />
                        ) : (
                            minRentPriceLabel
                        )
                    }
                    data-testid="budget_min_value"
                    addonAfter={currency}
                    horizontal
                    onChange={value => {
                        if (value != null && parseFloat(value)) {
                            setFieldValue('budget.minPrice', parseFloat(value));
                        } else {
                            setFieldValue('budget.minPrice', null);
                        }
                    }}
                    step={step}
                    decimalScale={2}
                />
            </FormPanel>
            {showAreas && (
                <FormPanel horizontal title={<FormattedMessage id="pages.properties.areas_panel.title" />}>
                    <>
                        {allAreas.map(area => {
                            return (
                                <PreferenceFieldVisibility key={area} path={area} type={values.type}>
                                    <Input
                                        horizontal
                                        type="price"
                                        data-testid={area}
                                        error={errors[area]}
                                        value={values[area]}
                                        touched={touched[area]}
                                        onChange={value => {
                                            if (value != null && parseFloat(value)) {
                                                setFieldValue(area, parseFloat(value));
                                            } else {
                                                setFieldValue(area, null);
                                            }
                                        }}
                                        label={<FormattedMessage id={`contact_preferences.${area}`} />}
                                    />
                                </PreferenceFieldVisibility>
                            );
                        })}
                    </>
                </FormPanel>
            )}
            <DeleteSection contactId={contactId} preferenceId={preferenceId} onDeleted={onDeleted} />
        </>
    );
}

const REMOVE_BUYER_PREFERENCE_MUTATION = gql`
    mutation RemoveBuyerPreference($contactId: ID!, $preferenceId: ID!) {
        removeLeadPreference(input: { contactId: $contactId, preferenceId: $preferenceId }) {
            contact {
                id
            }
        }
    }
`;

function DeleteSection({
    contactId,
    preferenceId,
    onDeleted,
}: {
    contactId: string;
    preferenceId: string;
    onDeleted: () => void;
}) {
    const [removePreference, { loading }] = useMutation(REMOVE_BUYER_PREFERENCE_MUTATION, {
        variables: {
            contactId,
            preferenceId,
        },
    });

    const { addSuccess, addError } = useToasts();

    const [disabled, setDisabled] = React.useState(true);

    return (
        <FormPanel title={<FormattedMessage id="general.action.delete" defaultMessage="Delete" />}>
            <Checkbox checked={!disabled} onChange={() => setDisabled(!disabled)}>
                <FormattedMessage
                    id="preferences.delete_description"
                    defaultMessage="This will permanently remove the preference from the contact's preference list"
                />
            </Checkbox>
            <Button
                block
                variant="ghost-danger"
                disabled={disabled || loading}
                onClick={async () => {
                    track(events.CONTACT_DELETE_PREFERENCE_BTN_CLICKED);
                    setDisabled(true);
                    try {
                        await removePreference();

                        addSuccess({
                            message: <FormattedMessage id="preferences.deleted" defaultMessage="Preference deleted" />,
                        });

                        onDeleted();
                    } catch (err) {
                        getBugsnagClient().notify(err);
                        addError({
                            message: (
                                <FormattedMessage
                                    id="general.something_went_wrong"
                                    defaultMessage="Something went wrong"
                                />
                            ),
                        });
                    }
                }}
            >
                <FormattedMessage id="preferences.actions.delete_preference" defaultMessage="Delete Preference" />
            </Button>
        </FormPanel>
    );
}
