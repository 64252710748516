import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useDebounce } from 'react-use';
import { GET_TEAMS } from '@/graphql/queries/teams/getTeams';
import { CompanyTeam, GetTeamsQuery, GetTeamsQueryVariables } from '@/graphql/generated/types';
import useCompany from '@/app.hooks/useCompany';

export const useTeams = (searchSettings?: any, skip?: boolean) => {
    const [query, setQuery] = useState('');

    const company = useCompany();

    const [pageTeams, setPageTeams] = useState(1);

    const [teams, setTeams] = useState<CompanyTeam[]>([]);

    const { data: dataTeams, loading: loadingTeams } = useQuery<GetTeamsQuery, GetTeamsQueryVariables>(GET_TEAMS, {
        skip,
        variables: {
            limit: 10,
            query: query,
            page: pageTeams,
            companyId: company.get('id'),
        },
        fetchPolicy: 'no-cache',
    });

    const pagination = dataTeams?.teams?.meta?.pagination;

    const currentPage = pagination?.current_page || 1;
    const totalPages = pagination?.total_pages || 1;
    const totalCount = pagination?.total || 0;
    const hasNextPage = pagination ? currentPage < totalPages : false;

    useDebounce(
        () => {
            setPageTeams(1);
            setQuery(searchSettings?.query || '');
        },
        400,
        [searchSettings?.query],
    );

    useEffect(() => {
        if (!loadingTeams) {
            const newData = dataTeams?.teams?.data || [];

            if (newData.length === 0 && pageTeams === 1) {
                setTeams([]);
            }

            if (newData.length > 0) {
                if (pageTeams === 1) {
                    // @ts-ignore
                    setTeams(newData);
                }

                if (pageTeams > 1) {
                    // @ts-ignore
                    setTeams(prevData => [...(prevData || []), ...newData]);
                }
            }
        }
    }, [pageTeams, dataTeams, loadingTeams]);

    const fetchMore = () => {
        if (hasNextPage && !loadingTeams) {
            setPageTeams(prevState => prevState + 1);
        }
    };

    return {
        teams: teams,
        error: false,
        loading: loadingTeams,
        currentPage: pageTeams,
        handleFetchMore: fetchMore,
        totalCount: totalCount || 0,
        hasNextPage: hasNextPage || false,
    };
};
