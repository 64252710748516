import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import TeamsSelect from '@/shared/teams/TeamsSelect';
import { Icon } from '@/app.components';
import { CompanyTeam } from '@/graphql/generated/types';

type Props = {
    teams: CompanyTeam[];
    tempTeams: any[];
    hasNextPage: boolean;
    setTempTeams: (teams: any[]) => void;
    handleFetchMore: () => void;
};

const MembersPageTeams: React.FC<Props> = props => {
    const { teams, hasNextPage, tempTeams, setTempTeams, handleFetchMore } = props;

    const showMoreButton = hasNextPage;

    const handleShowMore = () => {
        handleFetchMore();
    };

    return (
        <FormPanel containerClassName="mt-4" title={<FormattedMessage id="teams.title" defaultMessage="Teams" />}>
            <TeamsSelect
                value={undefined}
                onChange={(_newValue, option) => {
                    if (
                        !tempTeams.map(el => el.value).includes(option.value) &&
                        !teams.map(el => el.id).includes(option.value)
                    ) {
                        setTempTeams([...tempTeams, option]);
                    }
                }}
            />

            {(!!teams?.length || !!tempTeams.length) && (
                <div className="flex flex-wrap gap-1">
                    {teams.map(el => {
                        return (
                            <button
                                key={el.id}
                                type="button"
                                style={{ fontSize: '14px' }}
                                className="c-chip c-chip--small c-chip--primary"
                            >
                                <span className="mx-1 truncate flex items-center">{el.name}</span>
                            </button>
                        );
                    })}
                    {showMoreButton && (
                        <button
                            type="button"
                            onClick={handleShowMore}
                            style={{ fontSize: '14px' }}
                            className="c-chip c-chip--small c-chip--default"
                        >
                            <span className="mx-1 truncate flex items-center">
                                <FormattedMessage id="general.more" defaultMessage="Show more" />
                            </span>
                        </button>
                    )}
                    {tempTeams.map(el => {
                        return (
                            <button
                                type="button"
                                key={el.value}
                                style={{ fontSize: '14px' }}
                                className="c-chip c-chip--small c-chip--primary c-chip--with-icon"
                            >
                                <span className="mx-1 truncate flex items-center gap-1">
                                    {el.label}
                                    <Icon
                                        icon="delete"
                                        size={16}
                                        className="c-icon c-icon--circle"
                                        onClick={() => {
                                            setTempTeams(tempTeams.filter(subEl => subEl.value !== el.value));
                                        }}
                                    />
                                </span>
                            </button>
                        );
                    })}
                </div>
            )}
        </FormPanel>
    );
};

export default MembersPageTeams;
