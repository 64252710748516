import React, { useEffect, useMemo, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl-sweepbright';
import Input from '@/app.components/forms/Input/Input';
import useOffice from '@/app.hooks/useOffice';
import { updateOffice } from '../../../../../app.redux/actions';
import { getStatus } from '../../../../../app.redux/selectors';
import EmptyAlert from '../../../../../app.components/empty/EmptyAlert';
import FormPane from '../../../../../app.components/forms/FormPane';
import LocalizedInput from '../../../../../app.components/forms/LocalizedInput';
import Icon from '../../../../../app.components/icons/Icon';
import { emailSections } from '.';

defineMessages({
    'forms.presets.descriptions.email_matching_lead_description': {
        id: 'forms.presets.descriptions.email_matching_lead_description',
        defaultMessage: 'Default e-mail for matching lead',
    },
    'forms.presets.descriptions.email_lead_visit': {
        id: 'forms.presets.descriptions.email_lead_visit',
        defaultMessage: 'Default e-mail for scheduling visit',
    },
    'forms.presets.descriptions.email_report_description': {
        id: 'forms.presets.descriptions.email_report_description',
        defaultMessage: 'Default e-mail for vendor report',
    },
    'forms.presets.descriptions.email_property_images_request': {
        id: 'forms.presets.descriptions.email_property_images_request',
        defaultMessage: 'Default e-mail text',
    },
    'forms.presets.descriptions.email_property_plans_request': {
        id: 'forms.presets.descriptions.email_property_plans_request',
        defaultMessage: 'Default e-mail text',
    },
    'forms.presets.descriptions.email_property_documents_request': {
        id: 'forms.presets.descriptions.email_property_documents_request',
        defaultMessage: 'Default e-mail text',
    },
});

const EmailTemplates = props => {
    const {
        params: { type, office: officeId },
        updateOffice,
    } = props;

    const { office, refetch } = useOffice(officeId, true);

    const [lastOfficeId, setLastOfficeId] = useState('');

    const [formFields, setFormFields] = useState<any>({});

    const officePlain = useMemo(() => {
        return office.toJS();
    }, [office]);

    useEffect(() => {
        if (officeId !== lastOfficeId) {
            if (officePlain?.id) {
                setLastOfficeId(officeId);

                setFormFields({
                    settings: {
                        ...officePlain.settings,
                        data: {
                            ...officePlain?.settings?.data,
                        },
                    },
                });
            }
        }
    }, [officeId, lastOfficeId, officePlain]);

    const isLocalized = !type.includes('_request');
    const label = <FormattedMessage id={`forms.presets.descriptions.${type}`} />;

    const alert = (
        <span>
            <Icon icon="information" />{' '}
            <FormattedMessage
                id="forms.templates.emails.alert"
                defaultMessage="You can always edit this text before you send an e-mail."
            />
        </span>
    );

    const languages = ['en', 'fr', 'nl'];

    const fields = isLocalized
        ? languages.map(lang => {
              return `${type}_${lang}`;
          })
        : type;

    return (
        <FormPane
            {...({} as any)}
            status={props.status}
            title={
                <FormattedMessage
                    id="forms.templates.emails.title"
                    defaultMessage="{type} e-mail template"
                    values={{ type: emailSections[type] }}
                />
            }
            onSubmit={() => {
                updateOffice(officeId, {
                    settings: {
                        ...formFields?.settings?.data,
                        default_assignee: formFields?.settings?.data?.default_assignee?.id,
                    },
                });

                refetch();
            }}
        >
            <EmptyAlert body={alert} />
            {isLocalized ? (
                <LocalizedInput
                    key={type}
                    // @ts-ignore
                    type="autosize-textarea"
                    field={type}
                    label={label}
                    // @ts-ignore
                    fields={Object.fromEntries(
                        fields.map(el => {
                            return [
                                el,
                                {
                                    value: formFields?.settings?.data[el],
                                    onChange: e => {
                                        setFormFields({
                                            settings: {
                                                ...formFields.settings,
                                                data: {
                                                    ...formFields?.settings?.data,
                                                    [el]: e.target.value,
                                                },
                                            },
                                        });
                                    },
                                },
                            ];
                        }),
                    )}
                />
            ) : (
                <Input
                    key={type}
                    label={label}
                    type="autosize-textarea"
                    value={formFields?.settings?.data[type]}
                    onChange={e => {
                        setFormFields({
                            settings: {
                                ...formFields.settings,
                                data: {
                                    ...formFields?.settings?.data,
                                    [type]: e.target.value,
                                },
                            },
                        });
                    }}
                />
            )}
        </FormPane>
    );
};

const mapStateToProps = createStructuredSelector({
    status: getStatus,
});

const mapActionsToProps = {
    updateOffice,
};

export default compose(connect(mapStateToProps, mapActionsToProps))(EmailTemplates);
