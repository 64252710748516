import { createReducerWithSanitizer } from 'redux-reducer-factory';
import { LOGOUT } from '@/app.redux/actions/AuthActions';
import { SET_USERS, SET_USER, SET_USER_VIEWS } from '../actions';
import sanitizer from './Helpers/sanitizeOrderedSet';
import handleWithSubreducer from './Helpers/handleWithSubreducer';
import userReducer from './Subreducers/UserReducer';

export default createReducerWithSanitizer([], sanitizer, {
    [SET_USERS]: (state, { users }, sanitize) => state.concat(sanitize(users)),
    [LOGOUT]: (state, action, sanitize) => sanitize([]),
    [[SET_USER, SET_USER_VIEWS]]: (users, action) =>
        handleWithSubreducer(userReducer, users, action, action.userId || action.user.id),
});
