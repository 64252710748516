import React, { useContext, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { FormattedMessage } from 'react-intl-sweepbright';
import Input from '@/app.components/forms/Input/Input';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import EmptyAlert from '@/app.components/empty/EmptyAlert';
import InfiniteScroll from '@/app.layouts/InfiniteScroll';
import { OfficeGeoContext } from '../context';
import OfficeGeoListItem from './OfficeGeoListItem';

const OfficeGeoList = () => {
    const { data, loading, pagination, fetchMore } = useContext(OfficeGeoContext);

    const [search, setSearch] = useState('');

    const currentPage = pagination?.current_page || 1;
    const hasMorePages = currentPage < (pagination?.total_pages || 1);
    const totalCount = data ? pagination?.total || 0 : Number.MAX_VALUE;

    const filteredData = data.filter(el => {
        return el.title.toLowerCase().includes(search.toLowerCase());
    });

    return (
        <FormPanel>
            <FormGroup>
                <Input
                    value={search}
                    onChange={(e: any) => {
                        setSearch(e.target.value);
                    }}
                    placeholder={
                        <FormattedMessage
                            id="location.templates.search_title"
                            defaultMessage="Search in location templates"
                        />
                    }
                />
            </FormGroup>
            {totalCount !== 0 && (
                <InfiniteScroll
                    loading={loading}
                    onFetch={() => {
                        fetchMore();
                    }}
                    currentPage={currentPage}
                    hasMorePages={() => hasMorePages}
                >
                    <div className="bc-bordered-list">
                        {filteredData.map(el => {
                            return <OfficeGeoListItem key={el._id} entity={el} />;
                        })}
                    </div>
                </InfiniteScroll>
            )}
            {loading || hasMorePages ? <LoadingIndicator /> : null}
            {!loading && filteredData.length === 0 ? (
                <EmptyAlert
                    icon="face-01"
                    body={
                        <FormattedMessage
                            id="location.templates.no_templates"
                            defaultMessage="No location templates yet"
                        />
                    }
                />
            ) : null}
        </FormPanel>
    );
};

export default OfficeGeoList;
