import { createReducer } from 'redux-reducer-factory';
import {
    SET_PROPERTIES,
    FETCH_PROPERTIES,
    FETCH_CONTACTS,
    MERGE_PROPERTIES,
    MERGE_CONTACTS,
    SET_CONTACTS,
    FETCH_PROPERTY_VISITORS,
    FETCH_COMPANY_ADMINS,
    FETCH_OFFICE_MEMBERS,
    SET_COMPANY_ADMINS,
    SET_OFFICE_MEMBERS,
    FETCH_CONTACT_INTERESTS,
    SET_CONTACT_INTERACTIONS,
    SET_CONTACT_INTERACTIONS_FOR_PROPERTY,
    FETCH_CONTACT_INTERACTIONS_FOR_PROPERTY,
    RESET_PROPERTIES,
} from '../actions';

export default createReducer(
    {
        properties: true,
    },
    {
        // Properties
        [[FETCH_PROPERTIES, RESET_PROPERTIES]]: state => state.set('properties', true),
        [[SET_PROPERTIES, MERGE_PROPERTIES]]: state => state.set('properties', false),
        // Contacts
        [[FETCH_CONTACTS, FETCH_PROPERTY_VISITORS]]: state => state.set('contacts', true),
        [[SET_CONTACTS, MERGE_CONTACTS]]: state => state.set('contacts', false),
        [[FETCH_CONTACT_INTERESTS]]: (state, { contactId, interests }) =>
            state.set(`contacts/${contactId}/${interests}-interests`, true),
        [[SET_CONTACT_INTERACTIONS]]: (state, { contactId, interests }) =>
            state.set(`contacts/${contactId}/${interests}-interests`, false),
        [[FETCH_CONTACT_INTERACTIONS_FOR_PROPERTY]]: (state, { contactId }) =>
            state.set(`contacts/${contactId}/interactions`, true),
        [[SET_CONTACT_INTERACTIONS_FOR_PROPERTY]]: (state, { contactId }) =>
            state.set(`contacts/${contactId}/interactions`, false),
        // Members
        [[FETCH_OFFICE_MEMBERS, FETCH_COMPANY_ADMINS]]: state => state.set('members', true),
        [[SET_OFFICE_MEMBERS, SET_COMPANY_ADMINS]]: state => state.set('members', false),
    },
);
