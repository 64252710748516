type PlainFunction<ArgsType extends object> = (args: ArgsType) => any;

export default <ArgsType extends object>(fn: PlainFunction<ArgsType>) => {
    function promisifiedFunction(args: ArgsType): Promise<any> {
        return new Promise((resolve, reject) => {
            fn({ ...args, resolve, reject });
        });
    }

    return promisifiedFunction;
};
