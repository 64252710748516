// @ts-nocheck
import React from 'react';
import { Map } from 'immutable';
import { Button, Tabs } from '@sweepbright/uikit';
import { useFormikContext } from 'formik';
import findIndex from 'lodash/findIndex';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage, injectIntl } from 'react-intl-sweepbright';
import Formik from '@/app.components/forms/helpers/Formik';
import Modal from '@/app.components/elements/Modal';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import { RoomAreasSection, RoomsSection } from '@/app.pages/properties/[property]/details/rooms';
import Location from '@/app.components/forms/Location/Location';
import useCompanyLanguages from '@/app.hooks/useCompanyLanguages';
import LocalizedInput from '@/app.components/forms/LocalizedInput';
import CharactersCountingInput from '@/app.components/forms/CharactersCountingInput';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import Input from '@/app.components/forms/Input/Input';
import { getPriceLabels } from '@/app.data/Translations/Prices';
import CurrencyAddonForOffice from '@/app.components/forms/CurrencyAddonForOffice';
import ImageGrids from '@/app.domains/properties/elements/ImageGrids';
import { PropertyData } from '@/app.utils/services/PatchBuilders/PropertyPatchBuilder';
import { UnitsTable } from '@/app.domains/properties/components/NewPublicationModal/InvalidFields/UnitsTable';
import { ErrorBadge } from '@/app.components/errors/ErrorBadge';
import { ESTATE_CHANNEL_ACCOUNT_QUERY } from '@/graphql/queries/properties/getEstateChannelAccounts';
import {
    GetEstateChannelAccountQuery,
    GetEstateChannelAccountQueryVariables,
    UpdateEstateMutation,
} from '@/graphql/generated/types';
import { getBugsnagClient } from '@/app.config/bugsnag';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';
import { convertMissingFieldsIntoErrors, getEventValue, transformFields, validateEstateFields } from '../utils';

function useChannelAccountStatus(estateId: string, channelAccountId: string) {
    const { data } = useQuery<GetEstateChannelAccountQuery, GetEstateChannelAccountQueryVariables>(
        ESTATE_CHANNEL_ACCOUNT_QUERY,
        {
            variables: {
                estateId,
                channelAccountId,
            },
            onError(error) {
                getBugsnagClient().notify(error);
            },
        },
    );

    const channelAccountEdge = data?.estate?.channelAccount;

    return {
        channelAccountEdge,
    };
}

function transformMissingFields(acc, { estateId, node: missingFields }) {
    acc[estateId] = missingFields;

    return acc;
}

export function FixInvalidFieldsStep({
    channelAccountId,
    propertyId,
    onSubmit,
    onCancel,
}: {
    channelAccountId: string;
    propertyId: string;
    onSubmit: () => void;
    onCancel: () => void;
}) {
    const { channelAccountEdge } = useChannelAccountStatus(propertyId, channelAccountId);
    const { missingFields, unitsMissingFields } = channelAccountEdge ?? {};

    const unitsCount = unitsMissingFields?.edges.length ?? 0;
    const hasUnits = unitsCount > 0;

    const channelId = channelAccountEdge?.node.channel.id;
    const missingFieldsByUnitId = React.useMemo(() => unitsMissingFields?.edges.reduce(transformMissingFields, {}), [
        unitsMissingFields,
    ]);

    const [state, setState] = React.useState<{
        validatingUnits: boolean;
        activeUnitIdx: number;
    }>(() => {
        // we use a function here to avoid the expensive calculation of
        // the initial state

        return {
            // we go straight to the units if no project missing fields
            validatingUnits: missingFields?.node.length === 0,
            activeUnitIdx: getIndexOfNextUnitWithErrors(),
        };
    });

    const activePropertyId = state.validatingUnits
        ? unitsMissingFields?.edges[state.activeUnitIdx]?.estateId
        : propertyId;

    const initialErrors = convertMissingFieldsIntoErrors({
        errors: state.validatingUnits ? missingFieldsByUnitId[activePropertyId!] : missingFields?.node,
    });

    return (
        <div className="flex w-full">
            <FixInvalidFieldsForm
                key={activePropertyId}
                initialErrors={initialErrors}
                channelId={channelId}
                channelAccountId={channelAccountEdge?.node?.id}
                estateId={activePropertyId}
                onInvalidFieldsFixed={() => {
                    if (!state.validatingUnits && hasUnits) {
                        const nextUnit = getIndexOfNextUnitWithErrors();
                        if (nextUnit < unitsCount) {
                            setState({
                                validatingUnits: true,
                                activeUnitIdx: getIndexOfNextUnitWithErrors(),
                            });
                        } else {
                            onSubmit();
                        }
                    } else {
                        // validatingUnits == true
                        // hasUnits = false
                        const nextUnit = getIndexOfNextUnitWithErrors(state.activeUnitIdx + 1);
                        if (state.validatingUnits && nextUnit < unitsCount) {
                            setState({
                                validatingUnits: true,
                                activeUnitIdx: nextUnit,
                            });
                        } else {
                            onSubmit();
                        }
                    }
                }}
                onCancel={onCancel}
                sidebar={
                    state.validatingUnits ? (
                        <ErrorBoundary>
                            <UnitsTable
                                projectId={propertyId}
                                missingFieldsByUnitId={missingFieldsByUnitId}
                                activeUnitId={activePropertyId!}
                            />
                        </ErrorBoundary>
                    ) : null
                }
            />
        </div>
    );

    function getIndexOfNextUnitWithErrors(offset = 0) {
        const indexOfNextUnitWithErrors = findIndex(
            unitsMissingFields?.edges ?? [],
            edge => {
                const missingFieldsCount = edge.node.length;

                return missingFieldsCount > 0;
            },
            offset,
        );

        if (~indexOfNextUnitWithErrors) {
            return indexOfNextUnitWithErrors;
        }

        return unitsCount;
    }
}

export const FixInvalidFieldsForm = withPropertyDetails()(function FixInvalidFieldsForm({
    initialValues,
    onInvalidFieldsFixed,
    initialErrors,
    channelId,
    channelAccountId,
    onSubmit,
    onCancel,
    sidebar,
    property,
    loading,
}: {
    initialValues: any;
    onSubmit: (values: any, options) => Promise<void>;
    onInvalidFieldsFixed: () => void;
    estateId: string;
    property: Map<string, any>;
    channelId: string;
    channelAccountId: string;
    onCancel: () => void;
    initialErrors: any;
    sidebar: React.ReactNode;
    loading?: boolean;
}) {
    const [uploadingImages, setUploadingImages] = React.useState(false);

    if (loading) {
        return <FormattedMessage id="general.state.loading" defaultMessage="Loading..." />;
    }

    return (
        <Formik
            onSubmit={async attributes => {
                await onSubmit(attributes, {
                    awaitRefetchQueries: true,
                    refetchQueries: ({ data }: { data: UpdateEstateMutation }) => {
                        const { success, estate } = data.updateEstate;
                        if (!success) {
                            return [];
                        }

                        return [
                            {
                                query: ESTATE_CHANNEL_ACCOUNT_QUERY,
                                variables: {
                                    estateId: estate!.id,
                                    channelAccountId,
                                    lastModified: estate!.updatedAt,
                                },
                            },
                        ];
                    },
                });
                onInvalidFieldsFixed();
            }}
            enableReinitialize
            initialValues={initialValues}
            initialErrors={initialErrors}
            validate={validateEstateFields(channelId, property.toJS())}
        >
            {({ handleSubmit, errors, isValid, isSubmitting, isValidating }) => {
                return (
                    <form onSubmit={handleSubmit} className="w-full">
                        <Modal.Header>
                            <Modal.Title>
                                <FormattedMessage id="missing_fields.title" defaultMessage="Missing Fields" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="flex grow p-0" style={{ minHeight: 400 }}>
                            <div className="flex w-full overflow-y-hidden">
                                {sidebar}
                                <div className="w-full overflow-y-auto pr-5 pl-2">
                                    <Tabs>
                                        <Tabs.TabList className="relative">
                                            <Tabs.Tab type="button" data-testid="fix_invalid_fields.location_tab">
                                                <FormattedMessage
                                                    id="modals.new_publication.invalid_fields.location_tab.title"
                                                    defaultMessage="Location"
                                                />
                                                <ErrorBadge count={getLocationErrorCount(errors)} />
                                            </Tabs.Tab>
                                            <Tabs.Tab type="button" data-testid="fix_invalid_fields.description_tab">
                                                <FormattedMessage
                                                    id="modals.new_publication.invalid_fields.description_tab.title"
                                                    defaultMessage="Price & Description"
                                                />

                                                <ErrorBadge count={getDescriptionErrorCount(errors)} />
                                            </Tabs.Tab>
                                            <Tabs.Tab type="button" data-testid="fix_invalid_fields.images_tab">
                                                <FormattedMessage
                                                    id="modals.new_publication.invalid_fields.images_tab.title"
                                                    defaultMessage="Images"
                                                />
                                                <ErrorBadge count={getPriceAndMediaErrorCount(errors)} />
                                            </Tabs.Tab>
                                            {property && !property?.get('isProject') && (
                                                <Tabs.Tab type="button" data-testid="fix_invalid_fields.structure_tab">
                                                    <FormattedMessage
                                                        id="modals.new_publication.invalid_fields.structure_tab.title"
                                                        defaultMessage="Structure"
                                                    />{' '}
                                                    <ErrorBadge count={getStructureErrorCount(errors)} />
                                                </Tabs.Tab>
                                            )}
                                        </Tabs.TabList>
                                        <Tabs.TabPanels>
                                            <Tabs.TabPanel>
                                                {property && (
                                                    <ErrorBoundary>
                                                        <LocationTabPanel property={property} />
                                                    </ErrorBoundary>
                                                )}
                                            </Tabs.TabPanel>
                                            <Tabs.TabPanel>
                                                <ErrorBoundary>
                                                    <PriceAndDescriptionTabPanel
                                                        isSale={property?.get('negotiation') === 'sale'}
                                                        isProject={property?.get('isProject')}
                                                    />
                                                </ErrorBoundary>
                                            </Tabs.TabPanel>
                                            <Tabs.TabPanel>
                                                <ErrorBoundary>
                                                    <ImagesTabPanel
                                                        propertyId={property?.get('id')}
                                                        onUploadingStatusChanged={setUploadingImages}
                                                    />
                                                </ErrorBoundary>
                                            </Tabs.TabPanel>
                                            {property && !property?.get('isProject') && (
                                                <Tabs.TabPanel>
                                                    <ErrorBoundary>
                                                        <StructureTabPanel
                                                            property={property}
                                                            propertyType={property?.get('type')}
                                                        />
                                                    </ErrorBoundary>
                                                </Tabs.TabPanel>
                                            )}
                                        </Tabs.TabPanels>
                                    </Tabs>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={onCancel}>
                                <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={!isValid || isSubmitting || isValidating || uploadingImages}
                            >
                                {renderSubmitButtonTitle({ isSubmitting, isValidating, uploadingImages })}
                            </Button>
                        </Modal.Footer>
                    </form>
                );
            }}
        </Formik>
    );

    function getLocationErrorCount(errors) {
        return Object.keys(errors?.location ?? {}).length;
    }

    function getPriceAndMediaErrorCount(errors) {
        const imageErrors = !!errors?.images ? 1 : 0;

        return imageErrors;
    }

    function getDescriptionErrorCount(errors) {
        const priceErrors = Object.keys(errors?.price ?? {}).length;
        const descriptionErrors = Object.keys(errors?.description?.description ?? {}).length;
        const titleErrors = Object.keys(errors?.description?.title ?? {}).length;

        return priceErrors + descriptionErrors + titleErrors;
    }

    function getStructureErrorCount(errors) {
        return Object.keys(errors?.structure ?? {}).length;
    }

    function renderSubmitButtonTitle({ uploadingImages, isValidating, isSubmitting }) {
        if (uploadingImages) {
            return <FormattedMessage id="property.form.uploading" defaultMessage="Uploading..." />;
        }
        if (isValidating) {
            return <FormattedMessage id="form.status.saving" defaultMessage="Validating..." />;
        }
        if (isSubmitting) {
            return <FormattedMessage id="form.status.saving" defaultMessage="Saving..." />;
        }

        return <FormattedMessage id="general.action.save" defaultMessage="Save" />;
    }
});

const StructureTabPanel: React.ComponentType<{
    property: Map<string, any>;
    propertyType: string;
}> = injectIntl(function StructureTabPanel({
    intl,
    property,
    propertyType,
}: {
    property: Map<string, any>;
    propertyType: string;
}) {
    const { values, setFieldValue, errors } = useFormikContext<PropertyData>();

    const fields = {
        structure: transformFields(values.structure ?? {}, setFieldValue, 'structure', errors),
    };

    fields.structure.rooms = [];

    return (
        <div className="mt-4">
            <ErrorBoundary>
                <RoomAreasSection property={property} fields={fields} propertyType={propertyType} />
                <RoomsSection
                    property={property}
                    propertyType={propertyType}
                    fields={fields}
                    intl={intl}
                    onChange={(event, onChange) => onChange(event)}
                />
            </ErrorBoundary>
        </div>
    );
});

function LocationTabPanel({ property }) {
    const { values, setFieldValue, errors } = useFormikContext<PropertyData>();
    const { location = {} } = values;

    return (
        <Location
            property={property}
            entityType="property"
            location={{
                street: {
                    name: 'location.street',
                    value: location.street ?? '',
                    onChange: evtOrValue => {
                        setFieldValue('location.street', getEventValue(evtOrValue), true);
                    },
                    error: errors.location?.street,
                    touched: true,
                },
                country: {
                    name: 'location.country',
                    value: location.country ?? '',
                    onChange: evtOrValue => {
                        setFieldValue('location.country', getEventValue(evtOrValue), true);
                    },
                    error: errors.location?.country,
                    touched: true,
                },
                province_or_state: {
                    name: 'location.province_or_state',
                    value: location.province_or_state ?? '',
                    onChange: evtOrValue => {
                        setFieldValue('location.province_or_state', getEventValue(evtOrValue), true);
                    },
                    error: errors.location?.province_or_state,
                    touched: true,
                },
                city: {
                    name: 'location.city',
                    value: location.city ?? '',
                    onChange: evtOrValue => {
                        setFieldValue('location.city', getEventValue(evtOrValue), true);
                    },
                    error: errors.location?.city,
                    touched: true,
                },
                postal_code: {
                    name: 'location.postal_code',
                    value: location.postal_code ?? '',
                    onChange: evtOrValue => {
                        setFieldValue('location.postal_code', getEventValue(evtOrValue), true);
                    },
                    error: errors.location?.postal_code,
                    touched: true,
                },
                box: {
                    name: 'location.box',
                    value: location.box ?? '',
                    onChange: evtOrValue => {
                        setFieldValue('location.box', getEventValue(evtOrValue), true);
                    },
                    error: errors.location?.box ?? '',
                    touched: true,
                },
                floor: {
                    name: 'location.floor',
                    value: location.floor ?? '',
                    onChange: evtOrValue => {
                        setFieldValue('location.floor', getEventValue(evtOrValue), true);
                    },
                    error: errors.location?.floor,
                    touched: true,
                },
                addition: {
                    name: 'location.addition',
                    value: location.addition ?? '',
                    onChange: evtOrValue => {
                        setFieldValue('location.addition', getEventValue(evtOrValue), true);
                    },
                },
                number: {
                    name: 'location.number',
                    value: location.number ?? '',
                    onChange: evtOrValue => {
                        setFieldValue('location.number', getEventValue(evtOrValue), true);
                    },
                    error: errors.location?.number,
                    touched: true,
                },
                street_2: {
                    name: 'location.street_2',
                    value: location.street_2 ?? '',
                    onChange: evtOrValue => {
                        setFieldValue('location.street_2', getEventValue(evtOrValue), true);
                    },
                },
                borough_or_district: {
                    name: 'location.borough_or_district',
                    value: location.borough_or_district ?? '',
                    onChange: evtOrValue => {
                        if (evtOrValue.target) {
                            evtOrValue = evtOrValue.target.value;
                        }
                        setFieldValue('location.borough_or_district', getEventValue(evtOrValue), true);
                    },
                    error: errors.location?.borough_or_district,
                    touched: true,
                },
            }}
        />
    );
}

function PriceAndDescriptionTabPanel({ isSale, isProject }: { isSale: boolean; isProject: boolean }) {
    const { values, setFieldValue, errors } = useFormikContext();
    let companyLanguages = useCompanyLanguages();

    const descriptionField = companyLanguages.reduce((field, lang) => {
        const langCode = lang.value.toLowerCase();
        field[langCode] = {
            value: values.description?.description?.[langCode] ?? '',
            onChange: evtOrValue => {
                if (evtOrValue.target) {
                    evtOrValue = evtOrValue.target.value;
                }
                setFieldValue(`description.description.${langCode}`, evtOrValue);
            },
            error: errors.description?.description?.[langCode],
            touched: true,
        };

        return field;
    }, {});

    const titleField = companyLanguages.reduce((field, lang) => {
        const langCode = lang.value.toLowerCase();
        field[langCode] = {
            value: values.description?.title?.[langCode] ?? '',
            onChange: evtOrValue => {
                if (evtOrValue.target) {
                    evtOrValue = evtOrValue.target.value;
                }
                setFieldValue(`description.title.${langCode}`, evtOrValue);
            },
            error: errors.description?.title?.[langCode],
            touched: true,
        };

        return field;
    }, {});

    return (
        <>
            {!isProject && (
                <FormPanel
                    title={<FormattedMessage id="missing_fields.price.published_price.amount" defaultMessage="Price" />}
                >
                    <Input
                        horizontal
                        type="price"
                        decimalScale={2}
                        label={getPriceLabels(isSale).price}
                        value={values.price?.published_price?.amount}
                        onChange={value => setFieldValue('price.published_price.amount', value)}
                        addonAfter={<CurrencyAddonForOffice toLet={!isSale} />}
                        name="asking_price"
                        error={errors.price?.published_price?.amount}
                        touched
                    />
                </FormPanel>
            )}
            <FormPanel title={<FormattedMessage id="missing_fields.description.title" defaultMessage="Title" />}>
                <LocalizedInput
                    component={CharactersCountingInput}
                    componentClass="input"
                    field={titleField}
                    label={<FormattedMessage id="forms.labels.title" defaultMessage="Title" />}
                />
            </FormPanel>
            <FormPanel
                title={<FormattedMessage id="missing_fields.description.description" defaultMessage="Description" />}
            >
                <LocalizedInput
                    component={CharactersCountingInput}
                    type="autosize-textarea"
                    field={descriptionField}
                    label={<FormattedMessage id="forms.labels.description" defaultMessage="Description" />}
                />
            </FormPanel>
        </>
    );
}

function ImagesTabPanel({
    propertyId,
    onUploadingStatusChanged,
}: {
    propertyId?: string;
    onUploadingStatusChanged: (uploading: boolean) => void;
}) {
    const { values, setFieldValue, errors, isSubmitting } = useFormikContext<PropertyData>();

    const fields = {
        images: values.images.map((image, idx) => {
            return {
                value: image,
                onChange: value => {
                    setFieldValue(`images.${idx}`, value);
                },
            };
        }),
    };

    return (
        <ErrorBoundary>
            <ImageGrids
                fields={fields}
                propertyId={propertyId}
                isSaving={isSubmitting}
                errors={{
                    public: errors.images ? (
                        <FormattedMessage
                            id="missing_fields.public_images_error"
                            defaultMessage="At least one public image is required"
                        />
                    ) : (
                        undefined
                    ),
                }}
                setDisabled={onUploadingStatusChanged}
                allowExternalRequests={false}
            />
        </ErrorBoundary>
    );
}
