import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';
import LayoutContainer from '@/app.components/layouts/LayoutContainer';

import * as Pages from '../allPagesComponents';

export default function contactRoutes() {
    return (
        <Route path=":contact" component={Pages.Contact}>
            <Route path="timeline">
                <IndexRoute component={Pages.ContactTimeline} />
                <Route path=":property" component={Pages.ContactTimelineProperty} />
            </Route>
            <Route path="offers" component={Pages.ContactOffers} />
            <Route path="details" component={Pages.ContactDetails}>
                <IndexRedirect to="informations" />
                <Route path="informations" component={Pages.ContactDetailsInformations} />
                <Route path="notes" component={Pages.ContactDetailsNotes} />
                <Route path="settings" component={Pages.ContactDetailsSettings} />
            </Route>
            <Route path="preferences">
                <IndexRoute component={Pages.ContactPreferences} />
                <Route path=":preference" component={Pages.ContactPreference} />
            </Route>
            <Route path="report" component={Pages.ContactReport} />
            <Route path="match" component={Pages.ContactMatch} />
            <Route path="schedule" component={Pages.ContactSchedule} />
            <Route path="properties" component={LayoutContainer}>
                <IndexRoute component={Pages.ContactProperties} />
            </Route>
        </Route>
    );
}
