import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import useOffice from '@/app.hooks/useOffice';
import { defineMessages, FormattedMessage } from 'react-intl-sweepbright';
import { updateOffice } from '../../../../../app.redux/actions';
import { getStatus } from '../../../../../app.redux/selectors';
import EmptyAlert from '../../../../../app.components/empty/EmptyAlert';
import FormPane from '../../../../../app.components/forms/FormPane';
import LocalizedInput from '../../../../../app.components/forms/LocalizedInput';
import Icon from '../../../../../app.components/icons/Icon';
import Separator from '../../../../../app.components/elements/Separator';

defineMessages({
    'forms.presets.descriptions.house': {
        id: 'forms.presets.descriptions.house',
        defaultMessage: 'Default House Description',
    },
    'forms.presets.descriptions.apartment': {
        id: 'forms.presets.descriptions.apartment',
        defaultMessage: 'Default Apartment Description',
    },
    'forms.presets.descriptions.parking': {
        id: 'forms.presets.descriptions.parking',
        defaultMessage: 'Default Parking Description',
    },
    'forms.presets.descriptions.land': {
        id: 'forms.presets.descriptions.land',
        defaultMessage: 'Default Land Description',
    },
    'forms.presets.descriptions.office': {
        id: 'forms.presets.descriptions.office',
        defaultMessage: 'Default Office Description',
    },
    'forms.presets.descriptions.commercial_property': {
        id: 'forms.presets.descriptions.commercial_property',
        defaultMessage: 'Default Commercial Property Description',
    },
    'forms.presets.regulations.house': {
        id: 'forms.presets.regulations.house',
        defaultMessage: 'Default House Regulations & Legal Mentions',
    },
    'forms.presets.regulations.apartment': {
        id: 'forms.presets.regulations.apartment',
        defaultMessage: 'Default Apartment Regulations & Legal Mentions',
    },
    'forms.presets.regulations.parking': {
        id: 'forms.presets.regulations.parking',
        defaultMessage: 'Default Parking Regulations & Legal Mentions',
    },
    'forms.presets.regulations.land': {
        id: 'forms.presets.regulations.land',
        defaultMessage: 'Default Land Regulations & Legal Mentions',
    },
    'forms.presets.regulations.office': {
        id: 'forms.presets.regulations.office',
        defaultMessage: 'Default Office Regulations & Legal Mentions',
    },
    'forms.presets.regulations.commercial_property': {
        id: 'forms.presets.regulations.commercial_property',
        defaultMessage: 'Default Commercial Regulations & Legal Mentions',
    },
});

const PropertyTemplates = props => {
    const {
        updateOffice,
        params: { type, office: officeId },
    } = props;
    const { office, refetch } = useOffice(officeId, true);

    const [lastOfficeId, setLastOfficeId] = useState('');

    const [formFields, setFormFields] = useState<any>({});

    const officePlain = useMemo(() => {
        return office.toJS();
    }, [office]);

    useEffect(() => {
        if (officeId !== lastOfficeId) {
            if (officePlain?.id) {
                setLastOfficeId(officeId);

                setFormFields({
                    settings: {
                        ...officePlain.settings,
                        data: {
                            ...officePlain?.settings?.data,
                        },
                    },
                });
            }
        }
    }, [officeId, lastOfficeId, officePlain]);

    const translationKey = type === 'commercial' ? 'commercial_property' : type;

    const alert = (
        <span>
            <Icon icon="information" />{' '}
            <FormattedMessage
                id="forms.templates.properties.alert"
                defaultMessage="You can edit this text when you add a property."
            />
        </span>
    );

    const languages = ['en', 'fr', 'nl'];

    return (
        <FormPane
            {...({} as any)}
            status={props.status}
            title={
                <FormattedMessage
                    id="forms.templates.properties.title"
                    defaultMessage="{type} templates"
                    values={{ type: <FormattedMessage id={`property.type.${type}`} defaultMessage={type} /> }}
                />
            }
            onSubmit={() => {
                updateOffice(officeId, {
                    settings: {
                        ...formFields?.settings?.data,
                        default_assignee: formFields?.settings?.data?.default_assignee?.id,
                    },
                });

                refetch();
            }}
        >
            <EmptyAlert body={alert} />
            <LocalizedInput
                key={`${type}_description`}
                // @ts-ignore
                type="autosize-textarea"
                field={`${type}_description`}
                // @ts-ignore
                fields={Object.fromEntries(
                    languages.map(el => {
                        const key = `${type}_description_${el}`;

                        return [
                            key,
                            {
                                value: formFields?.settings?.data[key],
                                onChange: e => {
                                    setFormFields({
                                        settings: {
                                            ...formFields.settings,
                                            data: {
                                                ...formFields?.settings?.data,
                                                [key]: e.target.value,
                                            },
                                        },
                                    });
                                },
                            },
                        ];
                    }),
                )}
                label={<FormattedMessage id={`forms.presets.descriptions.${translationKey}`} />}
            />
            <Separator />
            <LocalizedInput
                key={`${type}_legal_mentions`}
                // @ts-ignore
                type="autosize-textarea"
                field={`${type}_legal_mentions`}
                // @ts-ignore
                fields={Object.fromEntries(
                    languages.map(el => {
                        const key = `${type}_legal_mentions_${el}`;

                        return [
                            key,
                            {
                                value: formFields?.settings?.data[key],
                                onChange: e => {
                                    setFormFields({
                                        settings: {
                                            ...formFields.settings,
                                            data: {
                                                ...formFields?.settings?.data,
                                                [key]: e.target.value,
                                            },
                                        },
                                    });
                                },
                            },
                        ];
                    }),
                )}
                label={<FormattedMessage id={`forms.presets.regulations.${translationKey}`} />}
            />
        </FormPane>
    );
};

const mapStateToProps = createStructuredSelector({
    status: getStatus,
});

const mapActionsToProps = {
    updateOffice,
};

export default compose(connect(mapStateToProps, mapActionsToProps))(PropertyTemplates);
