import CacheableCrudRequest from './CacheableCrudRequest';
import { EmbedlyResponse } from './Embedly/types';

export default class ProxyRequests extends CacheableCrudRequest {
    getRootUrl() {
        if (__CLIENT__) {
            // use proxy server
            return `${window.location.origin}/api`;
        } else {
            return API_URL;
        }
    }

    constructor() {
        super();
        this.rootUrl = this.getRootUrl();
    }

    oembed(url: string): Promise<EmbedlyResponse> {
        return this.setQueryParameters({ url }).get('embedly/oembed');
    }
}
