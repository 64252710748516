import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_ACCOUNTS } from '@/graphql/queries/portals/getAccounts';
import useCompany from '@/app.hooks/useCompany';
import { ChannelsSearchType } from '../types';

export const useChannels = (searchSettings?: ChannelsSearchType, skip?: boolean) => {
    const company = useCompany();

    const [, setQuery] = useState('');
    const [pageChannels, setPageChannels] = useState(1);
    const [channels, setChannels] = useState<any[]>([]);

    const { data: dataChannels, loading: loadingChannels } = useQuery(GET_ALL_ACCOUNTS, {
        variables: {
            companyId: company.get('id'),
        },
        fetchPolicy: 'no-cache',
        skip: !company.get('id') || skip,
    });

    const totalCountChannels = dataChannels?.channelAccounts?.length;
    const pageInfoChannels = { hasNextPage: false };

    useDebounce(
        () => {
            setPageChannels(1);
            setQuery(searchSettings?.query || '');
        },
        400,
        [searchSettings?.query],
    );

    useEffect(() => {
        if (!loadingChannels) {
            const newData = dataChannels?.channelAccounts || [];

            if (newData.length === 0 && pageChannels === 1) {
                setChannels([]);
            }

            if (newData.length > 0) {
                if (pageChannels === 1) {
                    setChannels(newData);
                }

                if (pageChannels > 1) {
                    setChannels(prevData => [...(prevData || []), ...newData]);
                }
            }
        }
    }, [pageChannels, dataChannels, loadingChannels]);

    const fetchMore = () => {
        if (pageInfoChannels?.hasNextPage && !loadingChannels) {
            setPageChannels(prevState => prevState + 1);
        }
    };

    return {
        error: false,
        channels: channels,
        loading: loadingChannels,
        currentPage: pageChannels,
        handleFetchMore: fetchMore,
        totalCount: totalCountChannels || 0,
        hasNextPage: pageInfoChannels?.hasNextPage || false,
    };
};
