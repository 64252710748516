import PropTypes from 'prop-types';
import React from 'react';
import { Map } from 'immutable';
import { reduxForm, reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Alert } from 'react-bootstrap';
import { withProps, compose } from 'recompose';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl-sweepbright';
import CardPaymentOption from '@/app.domains/subscription/common/CardPaymentOption';
import creditCardValidator from '@/app.utils/services/Validators/creditCard';
import { getRequestInitialValues } from '@/app.redux/selectors';
import { wrapWithPromise } from '@/app.utils/services';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import FormPane from '@/app.components/forms/FormPane';

const Payment = ({ company, status, fields, handleSubmit, error, untouchAll }) => (
    <FormPane
        title={<FormattedMessage id="forms.titles.subscriptions.payment" defaultMessage="Payment details" />}
        onSubmit={handleSubmit}
        cols={{ sm: 10, lg: 7 }}
        status={status}
    >
        <FormPanel
            title={<FormattedMessage id="forms.titles.subscriptions.payment.card" defaultMessage="Credit Card" />}
        >
            {error ? <Alert bsStyle="danger">{error}</Alert> : null}
            <CardPaymentOption
                company={company}
                fields={fields}
                key={company.getIn(['card', 'id'])}
                newCard={fields.new_card.value === true}
                setNewCard={val => {
                    fields.new_card.onChange(val);
                    untouchAll();
                }}
            />
        </FormPanel>
    </FormPane>
);

Payment.propTypes = {
    company: PropTypes.instanceOf(Map),
    error: PropTypes.string,
    fields: PropTypes.object.isRequired,
    status: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    untouchAll: PropTypes.func.isRequired,
};

const FORM_NAME = 'subscription/payment';

export default compose(
    withProps({
        initialValues: {
            new_card: false,
        },
    }),
    connect(
        createStructuredSelector({
            initialValues: getRequestInitialValues,
        }),
    ),
    reduxForm({
        form: FORM_NAME,
        fields: ['card.number', 'card.expiry', 'card.cvc', 'new_card'],
        touchOnBlur: false,
        onSubmit: (attributes, dispatch, props) => {
            if (attributes.new_card) {
                return wrapWithPromise(props.updateCompanyCard)({
                    companyId: props.company.get('id'),
                    ...attributes.card,
                }).then(() => {
                    dispatch(reset(FORM_NAME));
                });
            }

            return Promise.resolve();
        },
        validate: attributes => {
            const errors = {};
            if (attributes.new_card) {
                errors.card = creditCardValidator(attributes.card);
            }

            return errors;
        },
    }),
)(Payment);
