import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchOffice } from '@/app.redux/actions';
import { TEAM_PRESETS_FOR_EMAIL } from '@/app.routing/routes';
import useOffice from '@/app.hooks/useOffice';
import { FormattedMessage } from 'react-intl-sweepbright';
import { LanguagesDropdown } from './LanguagesDropdown';
import { useVisitMessageTemplates } from './utils';

interface IMessageTemplateSelectorProps {
    onSelect: (nextTemplate: string) => void;
}

export const VisitMessageTemplateSelector: FC<IMessageTemplateSelectorProps> = ({ onSelect }) => {
    const { templatesByLang } = useVisitMessageTemplates();

    const dispatch = useDispatch();
    const [isEditingTemplates, setEditingTemplates] = useState(false);
    const availableTemplateLanguages = useMemo(() => new Set(Object.keys(templatesByLang)), [templatesByLang]);
    const officeId = useOffice().get('id');

    const handleSelect = useCallback(
        (langCode: string) => {
            const template = templatesByLang[langCode];
            if (template) {
                onSelect(template);
            }
        },
        [onSelect, templatesByLang],
    );

    return (
        <LanguagesDropdown
            onToggle={nextShow => {
                if (nextShow && isEditingTemplates) {
                    dispatch(fetchOffice(officeId));
                    setEditingTemplates(false);
                }
            }}
            fallback={
                <span className="btn-link">
                    <FormattedMessage
                        id="components.visit_message_template_selector.manage_templates"
                        defaultMessage="Manage Templates"
                    />
                </span>
            }
            onFallbackSelect={() => {
                setEditingTemplates(true);
                window.open(TEAM_PRESETS_FOR_EMAIL(officeId, 'email_lead_visit'));
            }}
            availableLanguages={availableTemplateLanguages}
            onSelect={handleSelect}
        />
    );
};
