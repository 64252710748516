import React, { useContext } from 'react';
import Button from '@sweepbright/uikit/build/esm/button';
import MediaCard from '@/app.components/card/MediaCard/MediaCard';
import mapThumbnail from '@/../../resources/assets/img/map-thumbnail-1.png';
import zipThumbnail from '@/../../resources/assets/img/map-thumbnail-3.png';
import Icon, { ICON_SIZE_SMALL } from '@/app.components/icons/Icon';
import { GeoTemplate } from '@/graphql/generated/types';
import { OfficeGeoContext } from '../../context';

type Props = {
    entity: GeoTemplate;
};

const OfficeGeoListItem: React.FC<Props> = props => {
    const { entity } = props;

    const { setVisible, setActiveEntity } = useContext(OfficeGeoContext);

    return (
        <li>
            <MediaCard
                title={entity.title || 'No name defined'}
                image={entity.type === 'geometry' ? mapThumbnail : zipThumbnail}
                actions={[
                    <Button
                        key="edit"
                        variant="link"
                        onClick={evt => {
                            evt.stopPropagation();

                            setActiveEntity(entity);
                            setVisible(entity.type);
                        }}
                        className="c-location-preference__button"
                    >
                        <Icon icon="edit" size={ICON_SIZE_SMALL} />
                    </Button>,
                ]}
            />
        </li>
    );
};

export default OfficeGeoListItem;
