export const FETCH_OFFICE = 'offices/FETCH_OFFICE';
export const SET_OFFICES = 'offices/SET_OFFICES';
export const SET_OFFICE = 'offices/SET_OFFICE';
export const OFFICE_WAS_SET = 'offices/OFFICE_WAS_SET';
export const RESET_OFFICES = 'offices/RESET_OFFICES';

export const UPDATE_OFFICE = 'offices/UPDATE_OFFICE';

// Negotiators
export const SET_OFFICE_NEGOTIATORS = 'offices/SET_OFFICE_NEGOTIATORS';

// Members
export const FETCH_OFFICE_MEMBERS = 'offices/FETCH_OFFICE_MEMBERS';
export const SET_OFFICE_MEMBERS = 'offices/SET_OFFICE_MEMBERS';
export const UPDATE_OFFICE_MEMBER = 'offices/UPDATE_OFFICE_MEMBER';
export const ARCHIVE_OFFICE_MEMBER = 'offices/ARCHIVE_OFFICE_MEMBER';
export const REMOVE_OFFICE_MEMBER = 'offices/REMOVE_OFFICE_MEMBER';
export const INVITE_OFFICE_MEMBERS = 'offices/INVITE_OFFICE_MEMBERS';
export const SET_OFFICE_MEMBER = 'offices/SET_OFFICE_MEMBER';
export const RESET_OFFICE_MEMBERS = 'offices/RESET_OFFICE_MEMBERS';

export function fetchOffice(officeId) {
    return { type: FETCH_OFFICE, officeId };
}

export function fetchOfficeMembers(officeId, attributes) {
    return { type: FETCH_OFFICE_MEMBERS, officeId, attributes };
}

export function setOfficeMembers(officeId, members, total) {
    return { type: SET_OFFICE_MEMBERS, officeId, members, total };
}

export function updateOfficeMember(officeId, memberId, attributes) {
    return { type: UPDATE_OFFICE_MEMBER, officeId, memberId, attributes };
}

export function setOfficeMember(officeId, member) {
    return { type: SET_OFFICE_MEMBER, officeId, member };
}

export function archiveOfficeMember(officeId, memberId) {
    return { type: ARCHIVE_OFFICE_MEMBER, officeId, memberId };
}

export function removeOfficeMember(officeId, memberId) {
    return { type: REMOVE_OFFICE_MEMBER, officeId, memberId };
}

export function inviteOfficeMembers(attributes) {
    return { type: INVITE_OFFICE_MEMBERS, attributes };
}

export function resetOfficeMembers(officeId) {
    return { type: RESET_OFFICE_MEMBERS, officeId };
}

export function setOffices(offices) {
    return { type: SET_OFFICES, offices };
}

export function setOffice(office) {
    return { type: SET_OFFICE, office };
}

export function officeWasSet(officeId) {
    return { type: OFFICE_WAS_SET, officeId };
}

export function updateOffice(officeId, attributes) {
    return { type: UPDATE_OFFICE, officeId, attributes };
}
