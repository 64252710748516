import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { useIntl } from 'react-intl';

type Props = {
    id: string;
    label: string;
    children?: JSX.Element | JSX.Element[];
};

const ResourceFilterFormMultipleGroup: React.FC<Props> = props => {
    const { id, label, children } = props;

    const intl = useIntl();

    if (!children) {
        return null;
    }

    return (
        <FormGroup key={id}>
            <ControlLabel>{intl.formatMessage({ id: label })}</ControlLabel>
            <div>{children}</div>
        </FormGroup>
    );
};

export default ResourceFilterFormMultipleGroup;
