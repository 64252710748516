import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { Negotiation } from '@/app.data/Settings';

type Props = {
    negotiation: Negotiation;
    status: string;
};

const StatusLabel: React.FunctionComponent<Props> = ({ status, negotiation }) => {
    return (
        <FormattedMessage id={`property.status.${negotiation}.${status}`} defaultMessage={status}>
            {value => <span className="text-capitalize cursor-default">{value}</span>}
        </FormattedMessage>
    );
};

export default StatusLabel;
