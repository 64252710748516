import React from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, Avatar } from '@sweepbright/uikit';
import MediaCard from '@/app.components/card/MediaCard/MediaCard';
import { ContactLastAction } from '@/app.domains/contacts/ContactLastActivity';
import { fullName } from '@/app.utils/services/Helpers';
import useContact from '@/app.hooks/useContact';
import { ContactType } from '@/graphql/generated/types';
import ErrorBoundary from '@/app.components/errors/ErrorBoundary';
import ContactTitle from '@/app.domains/contacts/ContactTitle';
import { ContactCardData } from '@/new.domains/contacts/types';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { useContactReference } from '@/new.domains/contacts/hooks/useContactReference';
import { useContactLabels } from '@/new.domains/contacts/hooks/useContactLabels';
import ContactLabel from '../ContactLabel';
import ContactCardAssignee from './ContactCardAssignee';
import ContactCardType from './ContactCardType';

type Props = {
    to?: string;
    selected?: boolean;
    disabled?: boolean;
    selectable?: boolean;
    onClick?: () => void;
    showTitleInfo?: boolean;
    title?: React.ReactNode;
    contact?: ContactCardData;
    onToggleSelect?: () => void;
    showLastInteraction?: boolean;
    actions?: (React.ComponentType | React.ReactElement)[];
};

const ContactCard: React.FunctionComponent<Props> = props => {
    const {
        to,
        title,
        contact,
        onClick,
        selected,
        children,
        selectable,
        actions = [],
        showTitleInfo,
        onToggleSelect,
        showLastInteraction = true,
    } = props;

    const intl = useIntl();

    const showLabels = useFeatureFlag('contact.labels.enabled');

    const { contact: contactData, error: contactError } = useContact(contact?.updatedAt ? undefined : contact?.id);

    const { data: reference } = useContactReference(contactError ? contact?.id : undefined);

    const { data: contactLabels } = useContactLabels(!contactError && showLabels ? contact?.id : undefined);

    const content = {
        unknown: intl.formatMessage({ id: 'general.unknown' }),
    };

    const entity = (contact?.updatedAt ? contact : contactData) || undefined;

    const type = contact?.type || contactData?.type || ContactType.Lead;

    const image = (
        <Avatar
            image={entity?.photo?.url}
            name={
                entity?.firstName || entity?.lastName ? fullName(entity?.firstName, entity?.lastName) : content.unknown
            }
        />
    );

    const checkbox = entity?.updatedAt ? (
        <div
            style={{ padding: 4 }}
            onClick={evt => {
                evt.stopPropagation();
                evt.preventDefault();
                // eslint-disable-next-line no-unused-expressions
                onToggleSelect?.();
            }}
        >
            <Checkbox value={entity.id} checked={selected} size="lg" disabled={props.disabled} />
        </div>
    ) : (
        undefined
    );

    return (
        <>
            <MediaCard
                useLink
                actions={actions}
                onClick={onClick}
                to={entity?.updatedAt ? to : undefined}
                className={`contact-card${entity?.updatedAt ? '' : ' padding'}`}
                image={
                    <div className="contact-card__avatar-container">
                        {selectable && checkbox}
                        {image}
                    </div>
                }
                subtitles={[
                    children ? (
                        children
                    ) : (
                        <>
                            {entity?.updatedAt ? (
                                <ErrorBoundary key="last_action">
                                    <div className="truncate">
                                        <ContactLastAction
                                            // @ts-ignore
                                            type={type}
                                            createdAt={entity.createdAt}
                                            updatedAt={entity.updatedAt}
                                            isArchived={entity.archived}
                                            // @ts-ignore
                                            lastAction={entity.lastAction}
                                            // @ts-ignore
                                            lastInteraction={entity.lastInteraction}
                                            showLastInteraction={showLastInteraction}
                                        />
                                    </div>
                                </ErrorBoundary>
                            ) : (
                                undefined
                            )}
                        </>
                    ),
                    <div key="type" className="contact-card__sub">
                        <ContactCardAssignee
                            contact={entity}
                            // @ts-ignore
                            contactReference={reference?.contactReference}
                        />
                        <ContactCardType
                            // @ts-ignore
                            contact={{ ...entity, type }}
                        />
                        {showLabels &&
                            contactLabels?.map(el => {
                                return (
                                    <ContactLabel
                                        tooltip
                                        key={el.id}
                                        label={el.name}
                                        color={el.color}
                                        description={el.description}
                                    />
                                );
                            })}
                    </div>,
                ]}
                title={
                    title || (
                        <div className="truncate">
                            <ContactTitle
                                contact={entity}
                                showTitleInfo={showTitleInfo}
                                teamName={reference?.contactReference?.team?.name}
                            />
                        </div>
                    )
                }
            />
        </>
    );
};

export default ContactCard;
