// http://stackoverflow.com/a/35793421
export default (router, route, hook) => {
    let withinHook = false;
    let finalResult;
    let finalResultSet = false;

    return router.setRouteLeaveHook(route, nextLocation => {
        withinHook = true;
        if (!finalResultSet) {
            hook(nextLocation)
                .then(result => {
                    finalResult = result;
                    finalResultSet = true;
                    if (!withinHook && nextLocation) {
                        // Re-schedule the navigation
                        router.push(nextLocation);
                    }

                    return finalResult;
                })
                .catch(null);
        }

        const result = finalResultSet ? finalResult : false;
        withinHook = false;
        finalResult = null;
        finalResultSet = false;

        return result;
    });
};
