export function isPromise(obj) {
    return obj && typeof obj.then === 'function';
}

export function promisify(fn) {
    function promissified(...args) {
        return new Promise((resolve, reject) => {
            fn(...args, (err, result) => {
                if (err) {
                    return reject(err);
                }

                return resolve(result);
            });
        });
    }

    return promissified;
}
