import VendorReportEmailedActivityCard from '@/app.components/card/ActivityCard/VendorReportEmailedActivityCard';
import ContactEmailedActivityCard from './ContactEmailedActivityCard';
import ContactCalledActivityCard from './ContactCalledActivityCard';
import VisitScheduledActivityCard from './VisitScheduledActivityCard';
import ContactMessagedActivityCard from './ContactMessagedActivityCard';
import PropertyVisitedActivityCard from './PropertyVisitedActivityCard';
import LeadExpressedInterestActivityCard from './LeadExpressedInterestActivityCard';

const cardComponents = {
    ContactEmailedActivity: ContactEmailedActivityCard,
    VisitScheduledActivity: VisitScheduledActivityCard,
    ContactCalledActivity: ContactCalledActivityCard,
    ContactMessagedActivity: ContactMessagedActivityCard,
    PropertyVisitedActivity: PropertyVisitedActivityCard,
    LeadExpressedInterestActivity: LeadExpressedInterestActivityCard,
    VendorReportEmailedActivity: VendorReportEmailedActivityCard,
    default: () => null,
};

export function getCardForActivity({ __typename }: { __typename: string }) {
    return cardComponents[__typename] || cardComponents.default;
}
