import React from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import { MEMBERS } from '@/app.routing/routes';
import SideMenuLink from '../navigations/SideMenu/SideMenuLink';
import LeftNavBar from '../navigations/LeftNavBar';

const MembersAside: React.FC<{ location: string }> = props => {
    const { location } = props;

    return (
        <LeftNavBar location={location}>
            <SideMenuLink
                to={MEMBERS}
                icon="users"
                fuzzyMatch
                location={location}
                iconActive="users-fill"
                label={<FormattedMessage id="members.aside" defaultMessage="Members" />}
            />
        </LeftNavBar>
    );
};

export default MembersAside;
