import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { GetEstatesOptionsQuery, GetEstatesOptionsQueryVariables } from '@/graphql/generated/types';
import { GET_ESTATES_OPTIONS_QUERY } from '@/graphql/queries/properties/getEstateOptions';
import { RELEVANCE_SORT_OPTION } from '@/app.components/forms/Search/AdvancedSearch';

export const usePropertiesSorts = () => {
    const intl = useIntl();

    const { data } = useQuery<GetEstatesOptionsQuery, GetEstatesOptionsQueryVariables>(GET_ESTATES_OPTIONS_QUERY);

    const sorts = useMemo(() => {
        return data?.estatesOptions?.sorts?.map(el => {
            return {
                value: el.key,
                label: intl.formatMessage({ defaultMessage: el.key, id: `properties.sort.${el.key}` }),
            };
        });
    }, [intl, data]);

    return [RELEVANCE_SORT_OPTION, ...(sorts || [])];
};
