import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';

const identity = val => val;

export const withApolloQuery = (query, config) => WrappedComponent => {
    function WrapperComponent(ownProps) {
        const { options = identity, props = identity } = config;

        const result = useQuery(query, options(ownProps));

        return <WrappedComponent {...ownProps} {...props(result, ownProps)} />;
    }

    return WrapperComponent;
};

export const withApolloMutation = (mutation, config) => WrappedComponent => {
    function WrapperComponent(ownProps) {
        const { options = identity, props = identity } = config;

        const [mutate, result] = useMutation(mutation, options(ownProps));

        return <WrappedComponent {...ownProps} {...props(mutate, result, ownProps)} mutate={mutate} />;
    }

    return WrapperComponent;
};
