import AuthManager from 'auth-manager';
import { createSelector } from 'reselect';
import uuid from 'uuid/v4';
import moment from 'moment';
import { Map } from 'immutable';
import toPhoneNumber from '../reducers/Helpers/toPhoneNumber';
import { findById } from './CommonSelectors';
import { ReduxStoreState } from './types';

export const getUsers: (state: ReduxStoreState, props?: any) => Map<string, any> = state => state.users;

export const getUser = findById<ReduxStoreState, Map<string, any> | undefined>(getUsers, getCurrentUserId);

export const getUserTheme = createSelector(getUser, user => {
    if (user) {
        return user.getIn(['company', 'data', 'enterprise', 'data', 'theme'], 'default');
    }

    return 'default';
});

export const getUserLocale = createSelector(getUser, user => user?.get('locale'));

export const getViews = createSelector<ReduxStoreState, Map<string, any> | undefined, Map<string, any>>(
    getUser,
    (user: Map<string, any>) => user?.get('views'),
);

export const isInOffice = (officeId: string) =>
    createSelector<ReduxStoreState, Map<string, any> | undefined, boolean>(getUser, (user: Map<string, any>) => {
        return officeId === user?.getIn(['office', 'data', 'id']);
    });

export const getProfileValues = createSelector<ReduxStoreState, Map<string, any> | undefined, {} | undefined>(
    getUser,
    user => {
        if (user) {
            return {
                id: user.get('id'),
                first_name: user.get('first_name'),
                last_name: user.get('last_name'),
                email: user.get('email'),
                phone: toPhoneNumber(user.get('phone')),
                photo: user.get('photo', Map()).toJS(),
                appointment_service_url: user.get('appointment_service_url'),
                locale: user.get('locale'),
            };
        }

        return;
    },
);

export const getLaunchDarklyUser = createSelector<ReduxStoreState, Map<string, any> | undefined, {}>(getUser, user => {
    if (!user || !user.has('id')) {
        return {
            key: uuid(),
            anonymous: true,
            custom: {
                client: 'webapp',
            },
        };
    }

    const company = user.getIn(['company', 'data'], Map());
    const office = user.getIn(['office', 'data'], Map());

    return {
        hash: user.get('launchdarkly_hash'),
        key: `${user.get('id')}`,
        avatar: user.getIn(['photo', 'data', 'url']),
        firstName: user.get('first_name'),
        lastName: user.get('last_name'),
        name: user.get('full_name'),
        email: user.get('email'),
        country: company.getIn(['settings', 'data', 'signup_country']),
        custom: {
            company_id: company.get('id'),
            company_name: company.get('name'),
            office_id: office.get('id'),
            office_name: office.get('name'),
            client: 'webapp',
        },
    };
});

type IntercomData = {
    user_id: string;
    user_hash: string;
    email: string;
    phone: string;
    name: string;
    created_at: number;
    role: string;
    'Office name': string;
    company: any;
};

export const getIntercomValues = createSelector<ReduxStoreState, Map<string, any> | undefined, IntercomData | null>(
    getUser,
    user => {
        if (!user || !user.has('id')) {
            return null;
        }

        const company = user.getIn(['company', 'data']);
        const office = user.getIn(['office', 'data']);

        return {
            app_id: company.get('intercom_app_id'),
            api_base: company.get('intercom_api_base'),
            user_id: user.get('id'),
            user_hash: user.get('intercom_hash'),
            email: user.get('email'),
            phone: user.get('phone'),
            name: user.get('full_name'),
            created_at: moment(user.get('created_at')).unix(),
            role: user.get('role_id'),
            'Office name': office.get('name'),
            'SweepBright Language': user.get('locale'),
            company: {
                id: company.get('id'),
                name: company.get('name'),
                website: company.getIn(['settings', 'data', 'agency_website_hostname']),
                created_at: moment(company.get('created_at')).unix(),
                source: company.get('referrer'),
                max_users: company.get('member_allowed', 0),
                'Number of users': company.get('member_count', 1),
                'Number of channels': company.get('channels_count', 0),
                intercom_app_id: company.get('intercom_app_id'),
                intercom_api_base: company.get('intercom_api_base'),
                custom_support_url: company.get('custom_support_url'),
            },
        };
    },
);

/// utils
function getCurrentUserId(): string | undefined {
    const user = AuthManager.getUser();

    return user?.id;
}
