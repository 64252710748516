import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ContactCardFragment } from '@/graphql/fragments/contactCard';

export function useEstateVendors(estateId?: string, skip?: boolean) {
    const { data, loading } = useQuery(
        gql`
            query GetEstateVendors($estateId: ID!) {
                estate(id: $estateId) {
                    id
                    vendors {
                        nodes {
                            ...ContactCardFragment
                        }
                    }
                }
            }
            ${ContactCardFragment}
        `,
        {
            variables: { estateId },
            skip: !estateId || skip,
        },
    );

    const [assignVendorsMutation] = useMutation(gql`
        mutation AssignVendorsToEstates($input: AssignVendorsToEstateInput!) {
            assignVendorsToEstate(input: $input) {
                estate {
                    id
                    vendors {
                        nodes {
                            ...ContactCardFragment
                        }
                    }
                }
            }
        }
        ${ContactCardFragment}
    `);

    const [unassignVendorsMutation] = useMutation(gql`
        mutation UnssignVendorsToEstates($input: UnassignVendorsToEstateInput!) {
            unassignVendorsToEstate(input: $input) {
                estate {
                    id
                    vendors {
                        nodes {
                            ...ContactCardFragment
                        }
                    }
                }
            }
        }
        ${ContactCardFragment}
    `);

    const assignVendors = React.useCallback(
        (vendorIds: string[]) => {
            return assignVendorsMutation({
                variables: {
                    input: {
                        estateId,
                        vendorIds,
                    },
                },
            });
        },
        [estateId],
    );

    const unassignVendors = React.useCallback(
        (vendorIds: string[]) => {
            return unassignVendorsMutation({
                variables: {
                    input: {
                        estateId,
                        vendorIds,
                    },
                },
            });
        },
        [estateId],
    );

    const vendors = data?.estate?.vendors?.nodes ?? [];

    return { vendors, loading, assignVendors, unassignVendors };
}
