import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback } from 'react';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import { Link } from 'react-router';
import Tooltip from '@sweepbright/uikit/build/esm/tooltip';
import Select from '@/app.components/selects/AdvancedSelect/Select';
import AddressAutocomplete from '@/app.components/forms/AddressAutocomplete';
import TimeInput from '@/app.components/forms/TimeInput';
import Input from '@/app.components/forms/Input/Input';
import { useLocale } from '@/app.components/localize/Localized';
import PropertyCard from '@/app.domains/properties/PropertyCard';
import PropertySelect from '@/shared/properties/PropertySelect';
import { visitDurationOptions } from '@/app.data/visits';
import useProperty from '@/app.hooks/useProperty';
import { PROFILE_CALENDARS } from '@/app.routing/routes';
import { FormattedMessage } from 'react-intl-sweepbright';
import { NegotiatorSelector } from '@/app.components/forms/elements/NegotiatorSelector';
import useOffice from '@/app.hooks/useOffice';
import useFeatureFlag from '@/app.hooks/useFeatureFlag';
import { InfoIcon } from '../../../../notifications/src/component/icons/InfoIcon';
import { VisitMessageTemplateSelector } from './VisitMessageTemplateSelector';
import { VisitAttendees } from './ScheduleVisitModalV2';

const groupClasses = (error: Maybe<string>) => classNames('form-group', { 'has-error': error });

const configureCalendarLink = (
    <Link to={PROFILE_CALENDARS}>
        <FormattedMessage
            id="components.schedule_visit_modal.connect_calendar_link"
            defaultMessage="connect your online calendar"
        />
    </Link>
);

type Props = {
    isPropertyFixed: boolean;
    editing: boolean;
    submitFailed: boolean;
    canScheduleVisitForHimself: boolean;
    errors: any;
    values: any;
    onChange: any;
    validNegotiators: any;
    handleSetAttendees: any;
};

export default function VisitMainSettings({
    values,
    submitFailed,
    errors,
    onChange,
    canScheduleVisitForHimself,
    editing,
    isPropertyFixed,
    handleSetAttendees,
}: Props) {
    // errors
    const negotiatorError = submitFailed && errors.negotiatorId;
    const dateError = submitFailed && errors.date;
    const timeError = submitFailed && errors.hour;
    const propertyError = submitFailed && errors.propertyId;
    const messageError = submitFailed && errors.message;
    const locationRef = React.useRef<HTMLInputElement>();
    const { property } = useProperty(values.propertyId);
    // get the user locale
    const locale = useLocale();

    const office = useOffice();

    const disabledShared = useFeatureFlag('disable-shared-properties-schedule');

    React.useEffect(() => {
        // when editing, the property is fixed
        // so we dont want to change the data
        if (!editing && property?.attributes.location?.formatted_address?.[locale]) {
            onChange('location')(property?.attributes.location?.formatted_address?.[locale]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [property, locale, editing]);

    const handleSelectProperty = React.useCallback(
        (propertyId: string) => {
            onChange('propertyId')(propertyId);
            // eslint-disable-next-line no-unused-expressions
            locationRef.current?.focus();
        },
        [onChange],
    );

    const handleMessageTemplateLanguageSelect = useCallback(
        (template: string) => {
            onChange('message')(template);
        },
        [onChange],
    );

    return (
        <div className="flex-grow px-4 overflow-y-auto max-w-md w-full flex-1 min-h-0">
            <Input name="title" onChange={onChange} error={errors.title} value={values.title} touched={submitFailed} />

            <Input
                type="date"
                clearable={false}
                error={dateError}
                minDate={moment()}
                value={values.date}
                onChange={onChange('date')}
            />
            <div className="grid grid-cols-2 col-gap-4">
                <FormGroup className={classNames({ 'has-error': timeError })}>
                    <div className="form-inline">
                        <TimeInput
                            fields={{
                                hour: {
                                    name: 'hour',
                                    value: values.hour,
                                    onChange: onChange('hour'),
                                },
                                minutes: {
                                    name: 'minutes',
                                    value: values.minutes,
                                    onChange: onChange('minutes'),
                                },
                            }}
                            clearable={false}
                            hasError={Boolean(timeError)}
                        />
                    </div>
                    {timeError && <HelpBlock>{timeError}</HelpBlock>}
                </FormGroup>
                <FormGroup>
                    <div className="form-inline w-full">
                        <Select
                            value={values.duration}
                            options={visitDurationOptions}
                            isSearchable={false}
                            simpleValue
                            onChange={value => onChange('duration')(String(value))}
                        />
                    </div>
                </FormGroup>
            </div>
            <div className="py-2">
                <FormGroup className={classNames({ 'has-error': propertyError })}>
                    {!editing && !isPropertyFixed && (
                        <PropertySelect
                            hasError={!!propertyError}
                            disabledShared={disabledShared}
                            onSelect={handleSelectProperty}
                            value={values.propertyId ?? ''}
                        />
                    )}
                    {propertyError && <HelpBlock>{propertyError}</HelpBlock>}
                    {(editing || isPropertyFixed) && property && <PropertyCard property={property} />}
                </FormGroup>
            </div>
            <AddressAutocomplete
                allowClear
                inputRef={locationRef}
                name="location"
                value={values.location}
                onChange={onChange('location')}
                onLocationSelected={value => {
                    onChange('location')(value.formattedAddress);
                }}
                touched={submitFailed}
                error={errors.location}
            />
            <div className={classNames('form-group', { 'has-error': negotiatorError })}>
                <ControlLabel className="control-label">
                    <FormattedMessage id="modals.schedule_visit.labels.organiser" />

                    <Tooltip
                        label={
                            <FormattedMessage
                                id="negotiators.cant_find_user"
                                defaultMessage="Can’t find someone? Make sure to connect their online calendar. Visit My Profile to connect a calendar."
                            />
                        }
                    >
                        <span>
                            <InfoIcon style={{ width: '20px', height: '20px', transform: 'translateY(5px)' }} />
                        </span>
                    </Tooltip>
                </ControlLabel>
                <NegotiatorSelector
                    hasCalendar
                    setCurrentUser
                    hasError={negotiatorError}
                    officeId={office.get('id')}
                    value={values.negotiatorId}
                    onChange={onChange('negotiatorId')}
                />
                {negotiatorError && <HelpBlock>{negotiatorError}</HelpBlock>}
                {!canScheduleVisitForHimself && (
                    <HelpBlock className="text-gray-dark">
                        <FormattedMessage
                            id="components.schedule_visit_modal.help_calendar_not_connected"
                            defaultMessage="Can’t find yourself? Make sure to"
                        />{' '}
                        {configureCalendarLink}
                    </HelpBlock>
                )}
            </div>

            <VisitAttendees
                propertyId={values.propertyId}
                attendees={values.attendees}
                onSetAttendees={handleSetAttendees}
                errors={submitFailed && errors.attendees}
            />

            <FormGroup className={groupClasses(messageError)}>
                <ControlLabel className="inline-block">
                    <FormattedMessage id="modals.schedule_visit.labels.message" defaultMessage="Message" />
                </ControlLabel>
                <div className="pull-right">
                    <VisitMessageTemplateSelector onSelect={handleMessageTemplateLanguageSelect} />
                </div>
                <Input
                    type="autosize-textarea"
                    value={values.message}
                    onChange={onChange('message')}
                    rows={3}
                    style={{ resize: 'none' }}
                />
                {messageError && <HelpBlock>{messageError}</HelpBlock>}
            </FormGroup>
        </div>
    );
}
