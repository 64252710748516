import gql from 'graphql-tag';
import { UserFragment } from '@/graphql/fragments/user';

export const UPDATE_USER_OFFICE = gql`
    mutation updateUserOffice($officeId: ID!) {
        updateUserOffice(officeId: $officeId) {
            ...UserFragment
        }
    }
    ${UserFragment}
`;
