import React from 'react';
import { Button, LoadingIndicator } from '@sweepbright/uikit';
import PropTypes from 'prop-types';
import { OrderedSet, Map } from 'immutable';
import { compose } from 'recompose';
import xor from 'lodash/xor';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl-sweepbright';
import useField from '@/app.hooks/useField';
import { addContact } from '@/app.redux/actions/ContactsActions';
import Icon from '@/app.components/icons/Icon';
import Formik from '@/app.components/forms/helpers/Formik';
import useUser from '@/app.hooks/useUser';
import useOffice from '@/app.hooks/useOffice';
import { getPropertyPermissions } from '@/new.domains/properties/utils';
import withModals from '../../app.utils/Decorators/withModals';
import FormPanel from '../../app.components/forms/FormPanel/FormPanel';
import WithPrivateTooltip from '../../app.components/forms/WithPrivateTooltip';
import SelectContactForm from '../../app.components/forms/SelectContactForm';
import AddVendorForm from '../../app.components/forms/AddVendorForm';
import AddLeadForm from '../../app.components/forms/AddLeadForm';
import ContactRow from './elements/ContactRow';
import { useEstateNegotiator } from './PropertyNegotiator';

const AssignContactForm = ({
    title,
    action,
    type,
    contacts = [],
    loading,
    submitting,
    onContactsRemoved,
    disabled,
    ...props
}) => {
    const futureContactField = useField('');
    const [selecting, setSelecting] = React.useState(false);

    const user = useUser();
    const office = useOffice();
    const { negotiator } = useEstateNegotiator(props.property.get('id'));

    const setSelectedContact = contact => {
        props.onContactAdded(contact.id);
    };

    const handleAssignContact = contact => {
        setSelectedContact(contact);

        props.close('select');
    };

    const handleAddContactSuccess = contact => {
        setSelectedContact(contact);
        props.close('add');
    };

    const modal = type === 'lead' ? <AddLeadForm /> : <AddVendorForm />;

    const { canEdit } = getPropertyPermissions({
        activeTeam: office.get('id'),
        propertyTeam: props.property.get('office_id'),
        activeUser: `${user.get('id')}`,
        propertyUser: `${negotiator?.id}`,
        propertyVisibility: props.property.get('visibility'),
        userRole: user.get('role_id'),
    });

    return (
        <Formik
            initialValues={{
                selectedIds: [],
            }}
            onSubmit={({ selectedIds }, { resetForm }) => {
                onContactsRemoved(selectedIds);
                resetForm();
            }}
        >
            {({ values, submitForm, setFieldValue }) => {
                return (
                    <FormPanel
                        title={<WithPrivateTooltip>{title} </WithPrivateTooltip>}
                        extraActions={
                            <div className="bc-toolbar-btn-group">
                                {selecting && values.selectedIds.length > 0 && (
                                    <Button
                                        disabled={disabled || submitting}
                                        variant="link"
                                        onClick={() => {
                                            submitForm();
                                            setSelecting(false);
                                        }}
                                    >
                                        <FormattedMessage id="general.action.remove" defaultMessage="Remove" />
                                    </Button>
                                )}
                                {contacts.length > 0 && (
                                    <Button
                                        disabled={disabled || submitting || !canEdit}
                                        variant="link"
                                        onClick={() => {
                                            setSelecting(!selecting);
                                            if (selecting) {
                                                setFieldValue('selectedIds', []);
                                            }
                                        }}
                                        icon={!selecting && <Icon icon="circle-check" />}
                                    >
                                        {selecting ? (
                                            <FormattedMessage id="general.action.cancel" defaultMessage="Cancel" />
                                        ) : (
                                            <FormattedMessage id="general.action.select" defaultMessage="Select" />
                                        )}
                                    </Button>
                                )}
                                {!selecting && (
                                    <Button
                                        icon={<Icon icon="add" />}
                                        disabled={disabled || submitting || !canEdit}
                                        variant="link"
                                        onClick={() => props.open('select')}
                                    >
                                        {action}
                                    </Button>
                                )}
                            </div>
                        }
                    >
                        {contacts.map((contact, key) => {
                            return (
                                <ContactRow
                                    key={key}
                                    selectable={selecting}
                                    selected={values.selectedIds.includes(contact.id)}
                                    contact={contact}
                                    onToggleSelect={() =>
                                        setFieldValue('selectedIds', xor(values.selectedIds, [contact.id]))
                                    }
                                />
                            );
                        })}
                        {contacts.length === 0 && !loading && props.empty}
                        {props.modal(
                            'select',
                            <SelectContactForm
                                property={props.property}
                                excluded={contacts.map(contact => contact.id)}
                                onContactSelected={handleAssignContact}
                                field={futureContactField}
                                onAddVendor={() => props.open('add')}
                                type={type}
                                {...props}
                            />,
                        )}
                        {loading && (
                            <div className="my-2">
                                <LoadingIndicator />
                            </div>
                        )}
                        {props.modalWithPromise('add', modal, props.onAddContact, {}, handleAddContactSuccess)}
                    </FormPanel>
                );
            }}
        </Formik>
    );
};

AssignContactForm.propTypes = {
    action: PropTypes.object.isRequired,
    contacts: PropTypes.instanceOf(OrderedSet).isRequired,
    empty: PropTypes.node,
    propertyOfficeId: PropTypes.string,
    onAddContact: PropTypes.func.isRequired,
    onContactAdded: PropTypes.func.isRequired,
    onContactRemoved: PropTypes.func.isRequired,
    property: PropTypes.instanceOf(Map),
    title: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['lead', 'vendor']).isRequired,
    open: PropTypes.func,
    close: PropTypes.func,
    modal: PropTypes.func,
    modalWithPromise: PropTypes.func,
};

export default compose(
    withModals,
    connect(null, {
        onAddContact: addContact,
    }),
)(AssignContactForm);
