import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchCompanyCard } from '@/app.redux/actions';

import Upgrade from '@/app.domains/subscription/Upgrade';
import { getCompany } from '@/app.redux/selectors';

const mapActionsToProps = {
    fetchCompanyCard,
};

export default connect(
    createStructuredSelector({
        company: getCompany,
    }),
    mapActionsToProps,
)(Upgrade);
