import React, { useContext } from 'react';
import LoadingIndicator from '@sweepbright/uikit/build/esm/loading';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import EmptyAlert from '@/app.components/empty/EmptyAlert';
import InfiniteScroll from '@/app.layouts/InfiniteScroll';
import { CompanyLabelsContext } from '../context';
import CompanyLabelsListItem from './CompanyLabelsListItem';

const CompanyLabelsList = () => {
    const { data, loading, pagination, fetchMore } = useContext(CompanyLabelsContext);

    const currentPage = pagination?.current_page || 1;
    const hasMorePages = currentPage < (pagination?.total_pages || 1);
    const totalCount = data ? pagination?.total || 0 : Number.MAX_VALUE;

    return (
        <FormPanel>
            {totalCount !== 0 && (
                <InfiniteScroll
                    loading={loading}
                    onFetch={() => {
                        fetchMore();
                    }}
                    currentPage={currentPage}
                    hasMorePages={() => hasMorePages}
                >
                    <div className="bc-bordered-list">
                        {data.map(el => {
                            return <CompanyLabelsListItem key={el.id} entity={el} />;
                        })}
                    </div>
                </InfiniteScroll>
            )}
            {loading || hasMorePages ? <LoadingIndicator /> : null}
            {!loading && data.length === 0 ? (
                <EmptyAlert icon="face-01" body={<FormattedMessage id="general.state.no_results" />} />
            ) : null}
        </FormPanel>
    );
};

export default CompanyLabelsList;
