import React from 'react';
import PropTypes from 'prop-types';
import Button from '@sweepbright/uikit/build/esm/button';
import { FormattedMessage, injectIntl } from 'react-intl-sweepbright';
import EmailBuilder from '../../../app.utils/services/Email';
import Icon from '../../icons/Icon';

const Email = ({ to, cc, subject, body, className, linkText, icon, variant = 'link', block, intl }) => {
    body = body
        .map(line => {
            if (typeof line === 'object') {
                return intl.formatHTMLMessage(line.props, line.props.values);
            }

            return line;
        })
        .join('\n');

    const email = new EmailBuilder(intl.formatMessage(subject.props, subject.props.values || {}), body);

    return (
        <Button
            variant={variant}
            href={email
                .to(to)
                .withCc(cc)
                .toUrl()}
            className={className}
            icon={icon && <Icon icon="mail" />}
            block={block}
        >
            {linkText ? linkText : <FormattedMessage id="email.here" defaultMessage="here" />}
        </Button>
    );
};

Email.propTypes = {
    body: PropTypes.array,
    cc: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    icon: PropTypes.string,
    linkText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    subject: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    to: PropTypes.arrayOf(PropTypes.string),
};

Email.defaultProps = {
    body: [],
};

export default injectIntl(Email);
