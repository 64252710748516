// @ts-nocheck
import React from 'react';
import produce from 'immer';
import { useMutation } from '@apollo/react-hooks';
import { Hr, Button } from '@sweepbright/uikit';
import Modal from '@/app.components/elements/Modal';
import { FormattedMessage } from 'react-intl-sweepbright';
import FormPanel from '@/app.components/forms/FormPanel/FormPanel';
import useContact from '@/app.hooks/useContact';
import useProperty from '@/app.hooks/useProperty';
import { REGISTER_ESTATE_ACTIVITY } from '@/graphql/mutations/properties/registerEstateActivity';
import { GET_INTERACTED_CONTACTS_QUERY } from '@/graphql/queries/properties/getInteractedLeads';
import {
    RegisterEstateActivityMutation,
    RegisterEstateActivityMutationVariables,
    GetInteractedContactsQuery,
    GetInteractedContactsQueryVariables,
} from '@/graphql/generated/types';
import ContactSelect from '@/shared/contacts/ContactSelect';
import ContactCard from '../../shared/contacts/ContactCard';
import './RegisterPropertyActivityModal.scss';
import CloseIconButton from '../elements/Buttons/CloseIconButton';
import PropertyCard, { adaptProperty } from '../../app.domains/properties/PropertyCard';

export default function RegisterActivityModal({
    show,
    propertyId,
    contactId,
    onClose,
    type,
    onActivityRegistered,
}: {
    show?: boolean;
    propertyId: string;
    contactId?: string;
    onClose: () => void;
    type: string;
    onActivityRegistered: () => void;
}) {
    const { property } = useProperty(propertyId);
    const [selectedContactId, setSelectedContactId] = React.useState(contactId);
    const { contact } = useContact(selectedContactId);

    const [registerActivity, { loading }] = useMutation<
        RegisterEstateActivityMutation,
        RegisterEstateActivityMutationVariables
    >(REGISTER_ESTATE_ACTIVITY, {
        notifyOnNetworkStatusChange: true,
        onCompleted(result) {
            onActivityRegistered(result.registerEstateActivity.activity.id);
        },
        update(store, mutationResult: { data: RegisterEstateActivityMutation }) {
            try {
                const data = store.readQuery<GetInteractedContactsQuery, GetInteractedContactsQueryVariables>({
                    query: GET_INTERACTED_CONTACTS_QUERY,
                    variables: {
                        propertyId,
                    },
                });

                // eslint-disable-next-line no-use-before-define
                const updatedQueryData = removeLeadFromCache(
                    data,
                    mutationResult.data.registerEstateActivity.contact?.id,
                );

                store.writeQuery({
                    query: GET_INTERACTED_CONTACTS_QUERY,
                    data: updatedQueryData,
                    variables: {
                        propertyId,
                    },
                });
            } catch (err) {}
        },
    });

    function handleRegister() {
        registerActivity({
            variables: {
                input: {
                    estateId: propertyId,
                    contactId: selectedContactId!,
                    type,
                },
            },
        });
    }

    const canSelectContact = !contactId;

    return (
        <Modal
            show={show}
            onHide={onClose}
            onExited={() => setSelectedContactId(contactId)}
            onEntering={() => setSelectedContactId(contactId)}
        >
            <Modal.Header>
                <Modal.Title>
                    <FormattedMessage
                        id={`modals.register_activity.${type}.title`}
                        defaultMessage={`modals.register_activity.${type}.title`}
                    />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormPanel
                    title={<FormattedMessage id="modals.register_activity.property.title" defaultMessage="Property" />}
                >
                    {property && <PropertyCard property={adaptProperty(property)} />}
                    <Hr />
                </FormPanel>
                <FormPanel
                    title={<FormattedMessage id="modals.register_activity.contact.title" defaultMessage="Contact" />}
                >
                    {canSelectContact && (
                        <ContactSelect
                            filterByUser
                            disabled={!!contact}
                            onSelect={contact => setSelectedContactId(contact.id)}
                        />
                    )}
                    {contact && (
                        <div className="mx-2 my-0">
                            <ContactCard
                                contact={contact}
                                actions={[
                                    canSelectContact && (
                                        <CloseIconButton key="remove" onClick={() => setSelectedContactId(null)} />
                                    ),
                                ]}
                            />
                        </div>
                    )}
                </FormPanel>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>
                    <FormattedMessage id="modals.common.actions.cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="primary" disabled={!contact || loading || !type} onClick={handleRegister}>
                    {loading ? (
                        <FormattedMessage
                            id="modals.interaction_feedback.registering"
                            defaultMessage="Registering..."
                        />
                    ) : (
                        <FormattedMessage id="activity.register" defaultMessage="Register" />
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const removeLeadFromCache = produce((cachedQuery: GetInteractedContactsQuery, contactId: string) => {
    const interestedContactIndex = cachedQuery?.estate?.interestedLeads.nodes.findIndex(
        contact => contact.id === contactId,
    );
    const notInterestedContactIndex = cachedQuery?.estate?.notInterestedLeads.nodes.findIndex(
        contact => contact.id === contactId,
    );

    if (interestedContactIndex != null && interestedContactIndex >= 0) {
        // eslint-disable-next-line no-unused-expressions
        cachedQuery?.estate?.interestedLeads.nodes.splice(interestedContactIndex, 1);
    }

    if (notInterestedContactIndex != null && notInterestedContactIndex >= 0) {
        // eslint-disable-next-line no-unused-expressions
        cachedQuery?.estate?.notInterestedLeads.nodes.splice(notInterestedContactIndex, 1);
    }
});
