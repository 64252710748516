import { Map, List } from 'immutable';
import { Selector } from 'reselect';
import createSelector from './createImmutableSelector';

type Entities<T> = Map<string, T> | List<T>;

export function findById<State, Result extends Map<string, any> | undefined>(
    entitiesSelector: Selector<State, Entities<Result>>,
    currentSelector: Selector<State, string | undefined>,
) {
    return createSelector<State, Entities<Result>, string | undefined, Result | undefined>(
        entitiesSelector,
        currentSelector,
        (entities, current) => entities.find((entity: Result) => (entity ? entity.get('id') === current : false)),
    );
}

export function findOrFirst<State, Result extends Map<string, any> | undefined>(
    entitiesSelector: Selector<State, Entities<Result>>,
    currentSelector: Selector<State, string>,
) {
    return createSelector(
        [entitiesSelector, currentSelector],
        (entities: Entities<Result> | undefined, current?: string): Result | undefined => {
            if (entities) {
                return current
                    ? entities.find((entity: Result) => (entity ? entity.get('id') === current : false))
                    : entities.first();
            }

            return undefined;
        },
    );
}

export function orSelector<State, Result>(
    firstSelector: Selector<State, Result>,
    secondSelector: Selector<State, Result>,
) {
    return createSelector<State, Result | undefined, Result | undefined, Result | undefined>(
        firstSelector,
        secondSelector,
        (first, second) => first || second,
    );
}
