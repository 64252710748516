import moment from 'moment';
import { INVALID_DATE } from '../../../app.data/Datetime';

export default class DatetimeSanitizer {
    static sanitize(value) {
        if (!value || value === INVALID_DATE) {
            return;
        }

        const date = moment(value);

        return {
            hour: date.hour(),
            minutes: date.minutes(),
            date: date.format('YYYY-MM-DD'),
            datetime: value,
        };
    }
}
