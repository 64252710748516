import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export default function TileList({ grid, bordered, children, selectable, sortable, tight, cards, size, className }) {
    const classes = classNames(
        'c-tile-list',
        {
            'c-tile-list--tight': tight,
            'c-tile-list--grid': grid,
            'c-tile-list--bordered': bordered,
            'c-tile-list--selectable': selectable,
            'c-tile-list--cards': cards,
            '__prototype-sortable-items': sortable,
            [`c-tile-list--${size}`]: size,
        },
        className,
    );

    return <ul className={classes}>{children}</ul>;
}

TileList.propTypes = {
    bordered: PropTypes.bool,
    cards: PropTypes.bool,
    children: PropTypes.node,
    grid: PropTypes.bool,
    selectable: PropTypes.bool,
    size: PropTypes.oneOf(['lg']),
    sortable: PropTypes.bool,
    tight: PropTypes.bool,
    className: PropTypes.string,
};
