import React, { FC, useState } from 'react';
import { compose, withProps } from 'recompose';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';
import OfferPage from '@/app.shared/offers/OfferPage';
import { useEstateOffers } from '@/app.hooks/offer/useEstateOffers';

const PropertyOffersPage: FC<{ property: any }> = ({ property }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [offersLoading, setOffersLoading] = useState(false);

    const variables = {
        estateId: property.get('id'),
        archived: activeTabIndex === 1 ? true : false,
    };

    const { loading, data, refetch } = useEstateOffers({
        variables,
        errorPolicy: 'ignore',
    });

    const offersData = data?.estate?.offers || [];

    const handleRefetch = (newTabIndex?: number) => {
        const tabIndex = newTabIndex || activeTabIndex;

        setOffersLoading(true);

        refetch({ ...variables, archived: tabIndex === 1 ? true : false }).finally(() => {
            setOffersLoading(false);
        });
    };

    const handleActiveTabIndex = (newTabIndex: number) => {
        setActiveTabIndex(newTabIndex);

        handleRefetch(newTabIndex);
    };

    return (
        <OfferPage
            data={offersData}
            refetch={handleRefetch}
            activeTabIndex={activeTabIndex}
            propertyId={property.get('id')}
            loading={loading || offersLoading}
            setActiveTabIndex={handleActiveTabIndex}
        />
    );
};

export default compose(
    withProps<{ estateId: string }, { params: { unit?: string; property?: string } }>(props => ({
        estateId: props.params.unit ?? props.params.property ?? '',
    })),
    withPropertyDetails(),
)(PropertyOffersPage);
