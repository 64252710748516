import compose from 'recompose/compose';
import { reduxForm as form, propTypes } from 'redux-form';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { withProps } from 'recompose';
import { FormattedMessage } from 'react-intl-sweepbright';
import { validateWithRules } from '@/app.utils/services/Validator';
import { getFeature } from '@/app.redux/selectors/FeaturesSelector';
import NumberedStep from '@/app.components/modals/Channels/elements/NumberedStep';
import Input from '../../forms/Input/Input';
import PortalConfigModalBody from './PortalConfigModalBody';
import { nameValidationRule } from './utils';

const ReaImpl = ({ fields, handleSubmit, editing, ...props }) => (
    <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
        <div className="space-y-4">
            <NumberedStep
                step={1}
                title={<FormattedMessage id="channels.rea.title" defaultMessage="Change uploader" />}
            >
                <p className="text-muted m-0 py-2 leading-none">
                    <FormattedMessage
                        id="channels.rea.description"
                        defaultMessage="Set SweepBright as the Uploader in your <a>realestate.com.au</a> admin portal"
                        values={{
                            // eslint-disable-next-line react/display-name
                            a: chunks => (
                                <a href="https://www.realestate.com.au/" target="_blank" rel="noopener noreferrer">
                                    {chunks}
                                </a>
                            ),
                        }}
                    />
                </p>
            </NumberedStep>
            <NumberedStep
                step={2}
                title={<FormattedMessage id="channels.details_step.title" defaultMessage="Fill in your details" />}
                last
            >
                <Input
                    label={<FormattedMessage id="forms.labels.rea_agency_id" defaultMessage="Agency Id" />}
                    type="text"
                    {...fields.accessToken}
                    disabled={editing}
                />

                {props.showNameField && (
                    <Input
                        {...fields.name}
                        label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                        help={
                            <FormattedMessage
                                id="channels.name.description"
                                defaultMessage="This is the name you will see in the Publish section of SweepBright."
                            />
                        }
                        placeholder="realestate.com.au"
                    />
                )}
            </NumberedStep>
        </div>
    </PortalConfigModalBody>
);

ReaImpl.propTypes = {
    ...propTypes,
    editing: PropTypes.bool,
};

const Rea = compose(
    withProps(({ initialValues }) => {
        /// set the enabled REA checkbox to true by default
        return {
            initialValues: {
                ...initialValues,
                enable_rea: true,
            },
        };
    }),
    form(
        {
            form: 'channels/portal',
            formKey: 'rea',
            fields: ['enable_rea', 'accessToken', 'name'],
            validate: validateWithRules(
                {
                    enable_rea: {
                        type: 'boolean',
                        required: true,
                        // eslint-disable-next-line eqeqeq
                        conform: v => v == true,
                        messages: {
                            // will become Enable REA account to proceed
                            required: 'account to proceed',
                            conform: 'account to proceed',
                        },
                    },
                    accessToken: {
                        type: 'string',
                        required: true,
                        allowEmpty: false,
                        messages: {
                            required: 'general.validation.field_required',
                            allowEmpty: 'general.validation.field_required',
                        },
                    },
                    name: nameValidationRule,
                },
                { humanizeErrorMessage: false },
            ),
        },
        createStructuredSelector({
            // if the multiple accounts feature is on,
            // we show the name field, otherwise, it is invisible to the user
            // even though the name is still set
            showNameField: getFeature('portals.multipleAccounts.enabled'),
        }),
    ),
)(ReaImpl);

Rea.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
            accessToken
        }
    `,
};

export default Rea;
