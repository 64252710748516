import PropTypes from 'prop-types';
import { Map } from 'immutable';
import compose from 'recompose/compose';
import { reduxForm as form, propTypes } from 'redux-form';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl-sweepbright';
import { UrlGenerator, Validator } from '../../../app.utils/services';
import { getEnabledPortalFields, getFeature } from '../../../app.redux/selectors';
import FormPanel from '../../forms/FormPanel/FormPanel';
import Input from '../../forms/Input/Input';
import PortalConfigModalBody from './PortalConfigModalBody';
import { nameValidationRule } from './utils';

const FeedImpl = ({ fields, handleSubmit, editing, company, ...props }) => {
    const slug = props.portal.slug;
    const title = props.portal.name;

    const url = new UrlGenerator().to(`companies/${company.get('id')}/feeds/${slug}`);

    return (
        <PortalConfigModalBody editing={editing} {...props} onSubmit={handleSubmit}>
            <FormPanel>
                <Input
                    type="checkbox"
                    label={
                        <FormattedMessage
                            id="forms.portal.enabled"
                            defaultMessage="I have a {portal} account"
                            values={{ portal: title }}
                        />
                    }
                    {...fields[`enable_${props.portal.id}`]}
                    error={null}
                />
                <Input
                    type="text"
                    label={<FormattedMessage id="forms.portal.feed_url" defaultMessage="Feed URL" />}
                    readOnly
                    value={url}
                    disabled={editing}
                />
                {props.showNameField && (
                    <Input
                        {...fields.name}
                        label={<FormattedMessage id="channels.name.title" defaultMessage="Channel Name" />}
                        help={
                            <FormattedMessage
                                id="channels.name.description"
                                defaultMessage="This is the name you will see in the Publish section of SweepBright."
                            />
                        }
                    />
                )}
            </FormPanel>
        </PortalConfigModalBody>
    );
};

FeedImpl.propTypes = {
    ...propTypes,
    company: PropTypes.instanceOf(Map),
    editing: PropTypes.bool,
};

const Feed = compose(
    form(
        {
            form: 'channels/portal',
            formKey: 'feed',
            fields: ['zillow'],
            validate: (attributes, props) => {
                const { portal } = props;

                return Validator.validateWithRules({
                    [`enable_${portal.id}`]: {
                        type: 'boolean',
                        enum: [true],
                    },
                    name: nameValidationRule,
                })(attributes);
            },
        },
        createStructuredSelector({
            fields: getEnabledPortalFields,
            showNameField: getFeature('portals.multipleAccounts.enabled'),
        }),
    ),
)(FeedImpl);

Feed.fragments = {
    channelAccountEdit: gql`
        fragment ChannelAccountEdit on ChannelAccount {
            id
            name
        }
    `,
};

export default Feed;
