import { useMutation as useMutationApollo } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl-sweepbright';
import ScheduleVisitModal from '@/app.components/modals/ScheduleVisitModal/ScheduleVisitModalV2';
import {
    MutationUpdateVisitStatusArgs,
    ScheduleEventAttendee,
    ScheduleEventType,
    UpdateVisitStatusPayload,
    VisitStatus,
} from '@/graphql/generated/types';
import useRouter from '@/app.hooks/useRouter';
import { useToasts } from '@sweepbright/notifications';
import { EventCardCenter } from './EventCard/EventCardCenter';
import { EventCardCancelConfirmation } from './EventCard/EventCardCancelConfirmation';
import { EventCardDropdown } from './EventCard/EventCardDropdown';
import { EventCardIcon } from './EventCard/EventCardIcon';
import { EventCardPopover, useEventPopover } from './EventCard/EventCardPopover';
import { EventCardPopoverBody } from './EventCard/EventCardPopoverBody';
import { EventCardVisitConfirmation } from './EventCard/EventCardVisitConfirmation';
import { Event } from './types';

const UPDATE_VISIT_STATUS_MUTATION = gql`
    mutation UpdateVisitStatus($input: UpdateVisitStatusInput!) {
        updateVisitStatus(input: $input) {
            success
        }
    }
`;

type Props = {
    event: Event;
    onDelete: () => void;
    onUpdate: () => void;
    viewDisabled?: boolean;
    editDisabled?: boolean;
};

export const EventCard: FC<Props> = props => {
    const { event, onDelete, onUpdate, viewDisabled, editDisabled } = props;

    const [editing, setEditing] = React.useState(false);
    const router = useRouter();
    const toasts = useToasts();

    const { addError } = useToasts();

    const detailsPopover = useEventPopover(React.useRef<HTMLDivElement>(null));
    const cancellationPopover = useEventPopover(React.useRef<HTMLDivElement>(null));

    const showsDetailsPopover = { onMouseEnter: detailsPopover.show, onMouseLeave: detailsPopover.hide };

    const handleDelete = useCallback(() => {
        cancellationPopover.show();
    }, [cancellationPopover]);

    const [updateVisitStatusMutation] = useMutationApollo<UpdateVisitStatusPayload, MutationUpdateVisitStatusArgs>(
        UPDATE_VISIT_STATUS_MUTATION,
    );

    const updateVisitStatus = useCallback(
        async (status: VisitStatus) => {
            await updateVisitStatusMutation({
                variables: {
                    input: {
                        visitID: event.id,
                        createActivities: true,
                        visitStatus: status,
                    },
                },
            }).catch(err => {
                const isForbidden = err?.message?.includes('403');

                addError({
                    message: isForbidden ? (
                        <FormattedMessage id="unauthorised_403" />
                    ) : (
                        <FormattedMessage id="form.status.error" defaultMessage="Could not save" />
                    ),
                });
            });
            onUpdate();
        },
        [event.id, onUpdate, addError, updateVisitStatusMutation],
    );

    const handleEditEvent = () => {
        if (event.type === ScheduleEventType.Visit) {
            setEditing(true);
        } else {
            // eslint-disable-next-line no-unused-expressions
            router?.push(`/properties/${event.propertyId}/schedule/settings?eventId=${event.id}`);
        }
    };

    const handleDeleteEvent = useCallback(() => {
        cancellationPopover.hide();
        onDelete();
    }, [cancellationPopover, onDelete]);

    const isVisitOnThePast = moment(event.end).isBefore(moment());

    return (
        <>
            <div className="p-4 border-b border-solid border-gray-lighter hover:bg-gray-100" ref={detailsPopover.ref}>
                <div className="flex">
                    <div className="text-muted w-32" {...showsDetailsPopover}>
                        {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
                    </div>
                    <div className="flex-1">
                        <div className="flex">
                            <div className="flex flex-1" {...showsDetailsPopover}>
                                <div className="ml-5">
                                    <EventCardIcon eventType={event.type} status={event.status} />
                                </div>
                                <EventCardCenter event={event} />
                            </div>
                            <div ref={cancellationPopover.ref}>
                                <EventCardDropdown
                                    onDelete={handleDelete}
                                    onEdit={handleEditEvent}
                                    canDelete={!editDisabled}
                                    canEdit={!editDisabled && !moment(event.start).isBefore(moment())}
                                />
                            </div>
                        </div>
                        {event.__typename === 'ScheduleEventVisit' && isVisitOnThePast && event.status === 'pending' && (
                            <div>
                                <EventCardVisitConfirmation
                                    onConfirm={() => updateVisitStatus(VisitStatus.Confirmed)}
                                    onReject={() => updateVisitStatus(VisitStatus.Missed)}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {event.__typename === 'ScheduleEventVisit' && (
                    <ScheduleVisitModal
                        show={editing}
                        editingEventWithId={event.id}
                        onCancel={() => setEditing(false)}
                        initialValues={{
                            id: event.id,
                            title: event.title ?? '',
                            message: event.description ?? '',
                            location: event.location ?? '',
                            propertyId: event.propertyId,
                            date: event.start ?? '',
                            hour: moment(event.start).get('hour'),
                            minutes: moment(event.start).get('minutes'),
                            negotiatorId: event.organizerId ?? '',
                            duration: moment(event.end).diff(moment(event.start), 'minutes'),
                            attendees: (event.attendees ?? [])
                                .filter(
                                    (attendee): attendee is ScheduleEventAttendee =>
                                        !!attendee && attendee.resource_type === 'contact',
                                )
                                .map(attendee => attendee.id),
                        }}
                        onVisitScheduled={() => {
                            toasts.addSuccess({
                                message: (
                                    <FormattedMessage
                                        id="modals.schedule_visit.save_visit.success"
                                        defaultMessage="Successfully updated"
                                    />
                                ),
                            });
                            setEditing(false);
                        }}
                    />
                )}
            </div>
            {!viewDisabled && (
                <EventCardPopover popover={detailsPopover}>
                    {() => <EventCardPopoverBody event={event} />}
                </EventCardPopover>
            )}
            <EventCardPopover popover={cancellationPopover}>
                {() => (
                    <EventCardCancelConfirmation
                        eventType={event.type}
                        onConfirm={handleDeleteEvent}
                        onReject={cancellationPopover.hide}
                    />
                )}
            </EventCardPopover>
        </>
    );
};
