import gql from 'graphql-tag';

export const GET_ESTATE_STATS = gql`
    query GetEstateStats($filter: PropertyStatsFilter!) {
        propertyStats(filter: $filter) {
            numberOfPendingOffers
            closestOfferExpirations
            hasAcceptedOffer
            highestOfferBid
        }
    }
`;
