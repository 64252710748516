import { FC, useEffect } from 'react';
import { WithRouterProps } from 'react-router';
import {
    PROPERTY_DETAILS_DOCUMENTS,
    PROPERTY_DETAILS_LEGAL,
    PROPERTY_UNIT_DETAILS_DOCUMENTS,
    PROPERTY_UNIT_DETAILS_LEGAL,
} from '@/app.routing';

function getRedirectUrl(hash: string | undefined, property: string, unit?: string): string {
    if ((hash ?? '').includes('#documents')) {
        return unit ? PROPERTY_UNIT_DETAILS_DOCUMENTS(property, unit) : PROPERTY_DETAILS_DOCUMENTS(property);
    }

    return unit ? PROPERTY_UNIT_DETAILS_LEGAL(property, unit) : PROPERTY_DETAILS_LEGAL(property);
}

export const LegalAndDocsRedirect: FC<WithRouterProps<{ property: string; unit?: string }, {}>> = ({
    location: { hash },
    params: { property, unit },
    router: { replace },
}) => {
    useEffect(() => {
        replace(getRedirectUrl(hash, property, unit));
    });

    return null;
};
