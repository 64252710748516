// @ts-nocheck
import React from 'react';
import { fromJS, List } from 'immutable';
import Button from '@sweepbright/uikit/build/esm/button';
import MediaCard from '@/app.components/card/MediaCard/MediaCard';
import mapThumbnail from '@/../../resources/assets/img/map-thumbnail-1.png';
import zipThumbnail from '@/../../resources/assets/img/map-thumbnail-3.png';
import DeleteLocationModal from '@/app.components/modals/LocationPreferences/DeleteLocationModal';
import DrawRegionModal from '@/app.components/modals/LocationPreferences/DrawRegionModal';
import AddZipcodeModal from '@/app.components/modals/LocationPreferences/AddZipcodeModal';
import Icon, { ICON_SIZE_SMALL } from '@/app.components/icons/Icon';
import { GeoJsonLocationPreference, PostalCodesLocationPreference } from '@/graphql/generated/types';
import './LocationPreferenceCard.scss';

export default function LocationPreferenceCard({ location, onUpdate, onRemove, type = 'preference' }) {
    const LocationUpdateModal = isGeojsonLocation(location) ? DrawRegionModal : AddZipcodeModal;

    const [visibleAreaModal, setVisibleAreaModal] = React.useState(false);
    const [visibleDeleteAreaModal, setVisibleDeleteAreaModal] = React.useState(false);

    // Transform the shapes to pass to the DrawRegionMap
    let shapes = List();

    if (isGeojsonLocation(location) && location.data.coordinates.length) {
        shapes = fromJS([
            {
                coordinates: location.data.coordinates[0],
            },
        ]);
    }

    const handleRemoveLocation = () => {
        // hide the edit modal
        setVisibleAreaModal(false);
        // open the delete confirmation modals
        setVisibleDeleteAreaModal(true);
    };

    return (
        <>
            {visibleAreaModal ? (
                <LocationUpdateModal
                    formKey={location.id}
                    show={visibleAreaModal}
                    onClose={() => {
                        setVisibleAreaModal(false);
                    }}
                    type={type}
                    initialValues={location}
                    shapes={shapes}
                    onSubmit={async (updatedLocation: GeoJsonLocationPreference | PostalCodesLocationPreference) => {
                        const data: any = {};
                        if (location.type === 'geojson') {
                            data.geoJSONData = updatedLocation.data;
                        } else if (location.type === 'postal_codes') {
                            data.postalCodesData = updatedLocation.data;
                        }

                        await onUpdate({
                            preference: {
                                name: updatedLocation.name,
                                type: location.type,
                            },
                            ...data,
                        });
                        setVisibleAreaModal(false);
                    }}
                    onRemoveLocation={handleRemoveLocation}
                />
            ) : null}
            <DeleteLocationModal
                type={type}
                show={visibleDeleteAreaModal}
                onSubmit={async () => {
                    await onRemove();
                    setVisibleDeleteAreaModal(false);
                }}
                onClose={() => {
                    // go back to edit dialog
                    // 1) hide the delete modal
                    setVisibleDeleteAreaModal(false);
                    // 2) open again the edit modal
                    setVisibleAreaModal(true);
                }}
            />
            <MediaCard
                title={location.name || 'No name defined'}
                image={isGeojsonLocation(location) ? mapThumbnail : zipThumbnail}
                actions={[
                    <Button
                        className="c-location-preference__button"
                        key="edit"
                        variant="link"
                        onClick={evt => {
                            // prevent it from checking the card
                            evt.stopPropagation();
                            setVisibleAreaModal(true);
                        }}
                    >
                        <Icon icon="edit" size={ICON_SIZE_SMALL} />
                    </Button>,
                ]}
            />
        </>
    );
}

function isGeojsonLocation(
    area: GeoJsonLocationPreference | PostalCodesLocationPreference,
): area is GeoJsonLocationPreference {
    return area.type === 'geojson';
}
