import React, { FC } from 'react';
import { compose, withProps } from 'recompose';
import { useQuery } from '@apollo/react-hooks';
import { withPropertyDetails } from '@/app.domains/properties/withPropertyDetails';
import SimilarPropertyCard from '@/app.pages/properties/[property]/similar/similar-property-card';
import { GET_SIMILAR_PROPERTIES_QUERY } from '@/graphql/queries/properties/getSimilarProperties';
import { FormattedMessage } from 'react-intl-sweepbright';

const SimilarPropertiesPage: FC<{ property: any }> = ({ property }) => {
    const { data, loading, error } = useQuery(GET_SIMILAR_PROPERTIES_QUERY, {
        variables: {
            id: property.get('id'),
        },
    });

    const estateData = data as { estate: { similarProperties: Array<{ id: string; score: number }> } };

    return (
        <div className="w-full overflow-y-auto">
            <div className="sticky top-0 bg-white p-4 z-10">
                <h2 className="bc-navbar-page-heading bg-white">
                    <FormattedMessage id="similar.title" defaultMessage="Similar properties" />
                </h2>
                <hr className="h-px mt-2 mb-0 bg-gray-200 border-0" />
            </div>
            <div className="px-4">
                <div className="grid grid-cols-3 gap-4">
                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p>Something went wrong</p>
                    ) : !data ||
                      !data.estate ||
                      !data.estate.similarProperties ||
                      data.estate.similarProperties.length === 0 ? (
                        <p>No similar properties found.</p>
                    ) : (
                        estateData.estate.similarProperties.map(p => {
                            return <SimilarPropertyCard key={p.id} estateId={p.id} score={p.score} />;
                        })
                    )}
                </div>
            </div>
        </div>
    );
};

export default compose(
    withProps<{ estateId: string }, { params: { unit?: string; property?: string } }>(props => ({
        estateId: props.params.unit ?? props.params.property ?? '',
    })),
    withPropertyDetails(),
)(SimilarPropertiesPage);
