import { createSelector } from 'reselect';
import { PriceAggregations } from '@/requests/types';

export const getAggregations = (state: any) => state.aggregations;

export const getPropertiesAggregations = createSelector(
    getAggregations,
    (aggregations: { properties: PriceAggregations }) => {
        return aggregations.properties;
    },
);

export const getPropertiesPriceRange = (negotiation: 'sale' | 'let') =>
    createSelector(getPropertiesAggregations, (aggregations: PriceAggregations) => {
        return aggregations[negotiation];
    });

export const getPriceHistogram = createSelector(getAggregations, (aggregations: { properties: PriceAggregations }) => {
    return aggregations.properties.price_range;
});
