import { createReducerWithSanitizer } from 'redux-reducer-factory';
import { Map, fromJS, OrderedSet } from 'immutable';
import mergeByKey from '@/app.redux/reducers/Helpers/mergeByKey';
import {
    SET_CONTACT_INTERACTIONS,
    SET_LOCATION_PREFERENCES,
    SET_VENDOR_ESTATES,
    RESET_CONTACT_INTERACTIONS,
} from '../../actions';
import sanitizeOrderedSet from '../Helpers/sanitizeOrderedSet';
import concatSetWith from '../Helpers/concatSetWith';

export function sanitizer(contact = {}) {
    return fromJS(contact)
        .update('areas', areas => areas || new OrderedSet())
        .update('interactions', interactions => sanitizeOrderedSet(interactions || []))
        .update('preferences', value => value || Map())
        .updateIn(['preferences', 'location'], value => sanitizeOrderedSet(value || []));
}

export default createReducerWithSanitizer({}, sanitizer, {
    [SET_LOCATION_PREFERENCES]: (state, { active }, sanitize) =>
        state.setIn(['preferences', 'location'], sanitize(active)),
    [SET_CONTACT_INTERACTIONS]: (state, { interactions }) =>
        state.update('interactions', sanitizeOrderedSet([]), concatSetWith(interactions)),
    [RESET_CONTACT_INTERACTIONS]: state => state.set('interactions', sanitizeOrderedSet([])),
    [SET_VENDOR_ESTATES]: (state, { estates }) => {
        const updatedProperties = mergeByKey('id', state.get('estates', OrderedSet()).toJS(), estates);

        return state.set('estates', fromJS(Object.values(updatedProperties)).toOrderedSet());
    },
});
